import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Header, Grid, Button, Checkbox, Modal,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import routes from '../route-names';
import wellcomeServices from './services';
import Toast from '../common/components/toast';
import PdfViz from '../cross-viewer';
import '../style.css';

class Wellcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      disabledButton: true,
      readOnlyCheck: false,
    };
  }

  handleChangeTerms = () => {
    const { checked } = this.state;
    if (checked === true) this.setState({ disabledButton: true });
    else this.setState({ disabledButton: false });
    this.setState({ checked: !checked });
  }

  handleSave = async isCompleted => {
    const { t, updateStep, history } = this.props;
    const { checked } = this.state;
    if (isCompleted === true) history.push(routes.profile.route);
    if (isCompleted === false && checked === true) {
      const body = { stepId: 1, status: 1 };
      const stepToUpdate = { key: 1, completed: true };
      await wellcomeServices.updateStep(body)
        .then(async () => {
          await updateStep(stepToUpdate);
          history.push(routes.profile.route);
        })
        .catch(error => {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        });
    }
  }

  render() {
    const { t, steps } = this.props;
    let { checked, disabledButton, readOnlyCheck } = this.state;
    let isCompleted = false;
    if (steps.length > 0) {
      if (steps[0].completed === true) {
        checked = true; disabledButton = false;
        readOnlyCheck = true;
        isCompleted = true;
      }
    }
    return (
      <Grid className="container" container>
        <Grid.Row centered>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header color="blue" textAlign="center" as="h2">{t('onboarding.welcome')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={14} computer={14} tablet={16} mobile={16}>
            <Header textAlign="center" as="h4">{t('onboarding.welcomeMessage')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} centered>
          <Grid.Column largeScreen={1} computer={1} tablet={2} mobile={2}>
            <Header textAlign="center" as="h4">
              <Checkbox onClick={this.handleChangeTerms} checked={checked} readOnly={readOnlyCheck} />
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={6} computer={7} tablet={9} mobile={9}>
            <Modal trigger={<Header textAlign="left" color="blue" as="h5" style={{ cursor: 'pointer', textDecoration: 'underline' }}>{t('onboarding.terms')}</Header>} closeIcon>
              <Header as="h3" color="blue" content="Términos y condiciones" />
              <Modal.Content style={{ height: '41rem', width: '22rem' }}>
                <PdfViz originId={2} />
              </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} centered>
          <Grid.Column largeScreen={3} computer={3} tablet={2} mobile={6}>
            <Button
              color="blue"
              className="buttonStart"
              onClick={() => this.handleSave(isCompleted)}
              disabled={disabledButton}
            >
              {t('start')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Wellcome.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  steps: PropTypes.arrayOf(PropTypes.shape({
    completed: PropTypes.bool,
  })),
  t: PropTypes.func,
  updateStep: PropTypes.func,
};

Wellcome.defaultProps = {
  history: () => {},
  steps: [],
  t: () => {},
  updateStep: () => {},
};

export default translate('common', { wait: true })(Wellcome);
