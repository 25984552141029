import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const validateInput = (value, field, t) => {
  let error = '';
  if ((field === 'authorizationNumber' || field === 'opp' || field === 'sbNumber') && value.length > 0) {
    if (!validator.isAlphanumeric(value)) error = t('multibrand.errors.notSymbol');
    if (value.length > 15) error = t('multibrand.errors.toLong');
  }
  if (field === 'authorizationNumber' && (value.length > 0 && value.length < 5)) error = t('multibrand.errors.toShort');
  return error;
};

const InputControlled = props => {
  const {
    id = '', initialData = '', maxLength = 35, label = '', initialError = '', onChange, hint = '', required = false, t, disabled,
  } = props;
  const [localData, setData] = useState('');
  const [localError, setError] = useState('');

  useEffect(() => {
    setData(initialData);
    setError(initialError);
  }, [initialData, initialError]);

  const handleBlur = () => {
    onChange(localData, localError);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column onBlur={handleBlur} largeScreen={16} computer={16} tablet={16} mobile={16}>
          <TextField
            fullWidth
            maxLength={maxLength}
            label={label}
            error={localError || ''}
            value={localData || ''}
            onChange={e => {
              setData(e.target.value);
              setError(validateInput(e.target.value, id, t));
            }}
            hint={hint}
            required={required}
            disabled={disabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

InputControlled.propTypes = {
  disabled: PropTypes.bool,
  initialError: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  initialData: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputControlled.defaultProps = {
  disabled: false,
  required: false,
  hint: '',
  id: '',
  initialError: '',
  initialData: '',
  label: '',
  maxLength: 35,
  onChange: () => { },
};

const AutorizathionKeys = props => {
  const {
    t, makers, opp, authorizationNum, sbNumber, isQuotation, handleChangeAuthorizationNumber, handleChangeOPP, handleChangeSBNumber,
  } = props;
  return (
    <Grid container>
      <Grid.Row columns={1}>
        <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={16}>
          <Header>{t('multibrand.authorizationKeys')}</Header>
        </Grid.Column>
      </Grid.Row>
      {makers.Microsoft
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={6} computer={7} tablet={9} mobile={16}>
              <InputControlled
                id="authorizationNumber"
                maxLength={15}
                label={t('multibrand.authorizationNumber')}
                initialError={authorizationNum.error}
                initialData={authorizationNum.value}
                onChange={handleChangeAuthorizationNumber}
                hint={t('multibrand.numberDescription')}
                disabled={isQuotation}
                t={t}
              />
            </Grid.Column>
            {makers.IBM
              ? (
                <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={16}>
                  <InputControlled
                    id="opp"
                    maxLength={15}
                    label={t('multibrand.opp')}
                    initialError={opp.error}
                    initialData={opp.value}
                    onChange={handleChangeOPP}
                    disabled={isQuotation}
                    required
                    t={t}
                  />
                </Grid.Column>
              )
              : ''}
          </Grid.Row>
        )
        : ''}
      {makers.IBM
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={6} computer={7} tablet={9} mobile={16}>
              <InputControlled
                id="sbNumber"
                maxLength={15}
                label={t('multibrand.sbNumber')}
                initialError={sbNumber.error}
                initialData={sbNumber.value}
                onChange={handleChangeSBNumber}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : ''}
    </Grid>
  );
};

AutorizathionKeys.propTypes = {
  authorizationNum: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  handleChangeAuthorizationNumber: PropTypes.func.isRequired,
  handleChangeOPP: PropTypes.func.isRequired,
  handleChangeSBNumber: PropTypes.func.isRequired,
  isQuotation: PropTypes.bool.isRequired,
  makers: PropTypes.shape({
    IBM: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    Microsoft: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  opp: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  sbNumber: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default translate('common', { wait: true })(AutorizathionKeys);
