/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { translate } from 'react-i18next';

import { CartContext } from './cart-context';

export default function fixClassComponent(Component) {
  function MiddlewareContext(props) {
    const { updateCartCount } = useContext(CartContext);

    return (
      <Component
        {...props}
        updateCartCount={updateCartCount}
      />
    );
  }

  return translate('common', { wait: true })(MiddlewareContext);
}
