import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const finalUsers = {};

finalUsers.getFinalUser = () => (
  request({
    route: apiRoutes.getFinalUsers(),
    method: 'GET',
  })
);

finalUsers.addFinalUser = body => (
  request({
    route: apiRoutes.addFinalUsers(),
    method: 'POST',
    body,
  })
);

finalUsers.disableEndUser = userId => (
  request({
    route: apiRoutes.disableEndUser(userId),
    method: 'DELETE',
  })
);

finalUsers.updateEndUser = body => (
  request({
    route: apiRoutes.updateFinalUser(),
    method: 'PUT',
    body,
  })
);

finalUsers.getFinalUserByRFC = rfc => (
  request({
    route: apiRoutes.getFinalUsersByRFC(rfc),
    method: 'GET',
  })
);

finalUsers.getIndustries = () => (
  request({
    route: apiRoutes.getIndustries(),
    method: 'GET',
  })
);

finalUsers.getAddressByPostalCode = postalCode => (
  request({
    route: apiRoutes.validatePostalCode(postalCode),
    method: 'GET',
  })
);

export default finalUsers;
