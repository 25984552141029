import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const services = {};

services.getAllProductTags = () => (
  request({
    route: apiRoutes.baseRouteTags(),
    method: 'GET',
  })
);

services.deleteTags = body => (
  request({
    route: apiRoutes.baseRouteTags(),
    method: 'PATCH',
    body,
  })
);

services.mergeTags = body => (
  request({
    route: apiRoutes.baseRouteTags(),
    method: 'POST',
    body,
  })
);

services.generateTags = body => (
  request({
    route: apiRoutes.generateTags(),
    method: 'POST',
    body,
  })
);

services.generateMissingTags = () => (
  request({
    route: apiRoutes.generateMissingTags(),
    method: 'POST',
  })
);

services.addTags = body => (
  request({
    route: apiRoutes.baseRouteTags(),
    method: 'PUT',
    body,
  })
);

export default services;
