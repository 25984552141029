import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button,
  Segment,
  Label,
  Header,
  Checkbox,
  List,
  Input,
  Container,
  Popup,
} from 'semantic-ui-react';

import { getContentSource } from '../../commons';
import { CONTENT_SOURCE } from '../../constants';

const { CONTENT_PROVIDER, ERP, MANUAL } = CONTENT_SOURCE;

const translatePath = 'productConfig.menus.editContent.tabs.info';

function EditContentField(props) {
  const {
    t,
    currentContent,
    erpValue,
    icecatValue,
    contentName,
    onChangeContent,
  } = props;

  const [content, setContent] = useState({
    value: currentContent[contentName].value,
    source: currentContent[contentName].source,
  });
  const [isIcecatContent, setIsIcecatContent] = useState(content.source === CONTENT_PROVIDER);
  const [isIntelisisContent, setIsItelisisContent] = useState(content.source === ERP);
  const [inputContent, setInputContent] = useState(false);
  const [newContentValue, setNewContentValue] = useState(content.value);

  const saveNewContent = newContent => {
    onChangeContent(contentName, newContent);
    setContent(newContent);
  };

  const handleSetContent = () => {
    if (newContentValue !== content.value) {
      setIsIcecatContent(false);
      setIsItelisisContent(false);
      saveNewContent({ value: newContentValue, source: MANUAL });
    }
    setInputContent(false);
  };

  const handleSourceChange = (contentSource, checked) => {
    if (contentSource === CONTENT_PROVIDER && checked && Boolean(icecatValue)) {
      setIsIcecatContent(checked);
      setIsItelisisContent(!checked);
      setNewContentValue(icecatValue);
      saveNewContent({ value: icecatValue, source: CONTENT_PROVIDER });
    }
    if (contentSource === ERP && checked && Boolean(erpValue)) {
      setIsIcecatContent(!checked);
      setIsItelisisContent(checked);
      setNewContentValue(erpValue);
      saveNewContent({ value: erpValue, source: ERP });
    }
  };

  const handleCancelContent = () => {
    setInputContent(false);
    setNewContentValue(content.value);
  };

  return (
    <Container style={{ marginBottom: '20px' }}>
      <Header as="h4" color="blue">
        {t(`productConfig.menus.editContent.item.${contentName}`)}
        {' '}
        <Label size="mini">{getContentSource(content.source, t)}</Label>
      </Header>
      <Segment>
        <List>
          <List.Item>
            <List.Header as="a">
              {t('productConfig.menus.editContent.contentOptions.current')}
              {!inputContent && (
                <Popup
                  inverted
                  content={t(`${translatePath}.popup.manual`)}
                  trigger={(
                    <Button
                      primary
                      icon="edit"
                      size="mini"
                      floated="right"
                      onClick={() => setInputContent(true)}
                    />
                  )}
                />
              )}
            </List.Header>
            <List.Description>
              {
                inputContent
                  ? (
                    <Input
                      fluid
                      action
                      size="small"
                      value={newContentValue}
                      onChange={(e, { value }) => setNewContentValue(value)}
                    >
                      <input />
                      <Button.Group size="small">
                        <Popup
                          inverted
                          content={t(`${translatePath}.popup.cancel`)}
                          trigger={<Button icon="close" onClick={handleCancelContent} />}
                        />
                        <Popup
                          inverted
                          content={t(`${translatePath}.popup.save`)}
                          trigger={<Button primary icon="save" onClick={handleSetContent} />}
                        />
                      </Button.Group>
                    </Input>
                  )
                  : content.value
              }
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header as="a">
              {t('productConfig.menus.editContent.contentOptions.contentProvider')}
              <Popup
                inverted
                content={t(`${translatePath}.popup.contentProvider`)}
                trigger={(
                  <Checkbox
                    checked={isIcecatContent}
                    style={{ float: 'right' }}
                    label={t(`${translatePath}.selectContent`)}
                    onChange={(e, { checked }) => handleSourceChange(CONTENT_PROVIDER, checked)}
                  />
                )}
              />
            </List.Header>
            <List.Description>
              {icecatValue}
            </List.Description>
          </List.Item>
          <List.Item>
            <List.Header as="a">
              {t('productConfig.menus.editContent.contentOptions.erp')}
              <Popup
                inverted
                content={t(`${translatePath}.popup.erp`)}
                trigger={(
                  <Checkbox
                    checked={isIntelisisContent}
                    style={{ float: 'right' }}
                    label={t(`${translatePath}.selectContent`)}
                    onChange={(e, { checked }) => handleSourceChange(ERP, checked)}
                  />
                )}
              />
            </List.Header>
            <List.Description>
              {erpValue}
            </List.Description>
          </List.Item>
        </List>
      </Segment>
    </Container>
  );
}

EditContentField.propTypes = {
  currentContent: PropTypes.shape({
    family: PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onChangeContent: PropTypes.func.isRequired,
  contentName: PropTypes.string.isRequired,
  icecatValue: PropTypes.string.isRequired,
  erpValue: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(EditContentField);
