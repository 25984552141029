import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Header, Divider, Image, Icon, Popup, Menu,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { getUserImage } from '../../../common/utils';
import routeNames from '../../../route-names';
import * as session from '../../../common/sessions';

const urlAWS = process.env.REACT_APP_URL_AWS;

class PopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlImgProfile: `${urlAWS}/imagenes/defaultAvatar.png`,
    };
  }

  componentDidMount() {
    this.getAvatarImage();
    this.handleAccessPermission();
  }

  getAvatarImage = () => {
    const { userId } = session.get();
    getUserImage(null, 'user-profile/pictures', userId)
      .then(urlImg => this.setState({ urlImgProfile: urlImg }));
  };

  handleAccessPermission = () => {
    const { access } = session.get();
    const hasAccessValidation = securityService.validate(access, [
      securityService.getAccess.autorizarRevisiones,
    ]);
    this.setState({ hasAccess: hasAccessValidation });
  }

  renderMenu = () => {
    const { t, history } = this.props;
    const { hasAccess } = this.state;
    const { customerName, userName } = session.get();

    const goProfile = () => {
      history.push(routeNames.profile.route);
    };
    const goToConfiguration = () => {
      history.push(routeNames.adminConfiguration.route);
    };

    return (
      <Menu vertical fluid text>
        <Header textAlign="left" as="h4">
          {t('menu.hello')}
          {userName}
          !
        </Header>
        <Header textAlign="left" as="h5" style={{ marginTop: '0' }}>{customerName}</Header>

        <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-12px' }}>{t('menu.settings')}</Header>
        <Divider inverted />

        <Menu.Item onClick={goProfile}>
          {' '}
          <Icon name="user" color="blue" />
          {' '}
          {t('menu.profile')}
        </Menu.Item>
        {hasAccess
          ? (
            <Menu.Item onClick={goToConfiguration}>
              {' '}
              <Icon name="cog" color="blue" />
              {' '}
              {t('Configuración')}
            </Menu.Item>
          )
          : ''}
        {/* <Header textAlign="left" as="h5" color="blue" style={{ marginBottom: '-12px' }} >{t('menu.links')}</Header>
      <Divider inverted />
       <Menu.Item onClick={goDashboard}> <Icon name="dashboard" color="blue" /> {t('menu.dashboard')}</Menu.Item> */}

        <Divider inverted />
        <Menu.Item onClick={() => session.logout(false)}>
          {' '}
          <Icon name="sign-out" color="yellow" />
          {' '}
          {t('menu.logout')}
        </Menu.Item>

      </Menu>
    );
  }

  render() {
    const { urlImgProfile } = this.state;
    return (
      <Popup
        trigger={<Image src={urlImgProfile} style={{ fontSize: '1.4em', cursor: 'pointer' }} avatar />}
        content={this.renderMenu()}
        on="click"
        position="bottom right"
        style={{ width: '17em' }}
      />
    );
  }
}

export default translate('common', { wait: true })(PopMenu);
