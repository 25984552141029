/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import {
  Header, Grid, Dropdown, Dimmer, Loader, Button, Divider,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import * as session from '../common/sessions';
import routes from '../route-names';
import Toast from '../common/components/toast';
import Service from './services';

class CouponsSearch extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      search: '',
      sku: '',
      customerId: '',
      searchError: '',
      messageTable: '',
      // productLoader: false,
      typeId: '',
      arrayTypes: [
        { key: 1, value: 1, text: 'Por distribuidor' },
        { key: 2, value: 2, text: 'Por SKU' },
      ],
      arrayCustomers: [],
      arrayProducts: [],
      couponsArray: [],
      couponsArrayFiltered: [],
      couponsHeaders: [
        { key: 'id', isKey: true },
        { key: 'coupon', name: t('Código') },
        { key: 'description', name: t('Descripción') },
        { key: 'segment', name: t('Comportamiento') },
        { key: 'discountType', name: t('Tipo (% o monto)') },
        { key: 'startValidity', name: t('Fecha de inicio') },
        { key: 'endValidity', name: t('Fecha de fin') },
        { key: 'quantity', name: t('Cantidad') },
        {
          key: 'open',
          name: 'Abrir',
          format: (cell, row) => (row.id ? <Button color="blue" icon="file outline" onClick={() => props.history.push(`${routes.orderDetails.route.replace(':orderId?', '')}${row.uuid}`)} /> : null), // eslint-dsable-line
        },
      ],
      loading: false,
    };
  }

  componentDidMount = () => {
    this.handleAccessPermission();
  }

  handleAccessPermission = () => {
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);
    if (hasAccess) {
      this.handleGetProductsList();
      this.handleGetCustomers();
    }
  }

  handleGetCoupons = value => {
    const { t } = this.props;
    const { typeId } = this.state;
    this.setState({ loading: true });
    if (typeId === 1) {
      Service.getCouponsByCustomerId(value)
        .then(response => {
          this.setState({ couponsArray: response.coupons, couponsArrayFiltered: response.coupons, loading: false });
        }).catch(error => {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          if (error.code !== 1000) Toast(messageError, 'error');
          this.setState({ loading: false, couponsArray: [], couponsArrayFiltered: [] });
        });
    } else {
      Service.getCouponsBySku(value)
        .then(response => {
          this.setState({ couponsArray: response.coupons, couponsArrayFiltered: response.coupons, loading: false });
        }).catch(error => {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          if (error.code !== 1000) Toast(messageError, 'error');
          this.setState({ loading: false, couponsArray: [], couponsArrayFiltered: [] });
        });
    }
  }

  handleGetCustomers = () => {
    const { t } = this.props;
    const arrayUsers = [];
    const { customerId } = session.get();
    Service.getUsers(customerId)
      .then(response => {
        response.users.map(user => {
          if (/* user.confirmedAccount === 1 && */ user.name && user.name.length > 1) {
            arrayUsers.push({ key: user.id, text: `${user.name} ${user.surname}`, value: user.id });
          }
          return user;
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetCustomers = async () => {
    const { customers } = await Service.getCustomers();
    const customerFormatted = customers.map(customer => ({
      key: customer.id,
      text: `${customer.id} - ${customer.tradingName}`,
      value: customer.id,
    }));
    this.setState({ arrayCustomers: customerFormatted });
  }

  handleGetProductsList = () => {
    Service.getAllListProducts()
      .then(productList => {
        const formatedProducts = productList.product.map(product => {
          const productConfig = {
            key: product.sku,
            text: product.sku,
            value: product.sku,
          };
          return productConfig;
        });
        this.setState({ arrayProducts: formatedProducts });
      })
      .catch(() => {
        Toast('Error al cargar los productos', 'error');
        // this.setState({ productLoader: false });
      });
  }

  validations = event => {
    const { t } = this.props;
    const maxLength = 40;
    let mistakes = false;
    const { value: search } = event.target;
    if ((search.trim()).length > maxLength) {
      this.setState({ search, searchError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) this.setState({ search, searchError: '' }); this.filterGeneral(search, 1);
  }

  filterGeneral = (value = '', typeFilter) => {
    const { couponsArray } = this.state;
    let couponsArrayFiltered = JSON.parse(JSON.stringify(couponsArray));
    let { search } = this.state;
    const filter = String(value).toLowerCase();
    if (typeFilter === 1) search = filter;
    if (search) couponsArrayFiltered = this.filterForSearch(search, couponsArrayFiltered);
    this.setState({ couponsArrayFiltered });
  }

  filterForSearch = (filter, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => (filterInfo.total && String(filterInfo.total).indexOf(filter) >= 0)
    || (filterInfo.quotation && filterInfo.quotation.toLowerCase().indexOf(filter) >= 0)
    || (filterInfo.uuid && filterInfo.uuid.toLowerCase().indexOf(filter) >= 0)
    || (filterInfo.intelisisId && filterInfo.intelisisId.indexOf(filter) >= 0)
    || (filterInfo.id && filterInfo.id.indexOf(filter) >= 0)
    || (filterInfo.endUser && filterInfo.endUser.toLowerCase().indexOf(filter) >= 0)
    || (filterInfo.agent && filterInfo.agent.toLowerCase().indexOf(filter) >= 0));
    return foundData;
  }

  handleChangeCustomer = (e, { value }) => {
    this.setState({ customerId: value });
    this.handleGetCoupons(value);
  }

  handleChangeSku = (e, { value }) => {
    this.setState({ sku: value });
    this.handleGetCoupons(value);
  }

  handleChangeType = (e, { value }) => {
    this.setState({ typeId: value });
  }

  render() {
    const { t } = this.props;
    const {
      sku, customerId, search, loading, searchError, messageTable, couponsArrayFiltered, couponsHeaders,
      arrayProducts, arrayCustomers, typeId, arrayTypes,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1rem' }}>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('discounts.couponsSearch')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('discounts.couponsSearchDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={15}>
            <Dropdown
              options={arrayTypes}
              placeholder={t('Buscar por...')}
              onChange={this.handleChangeType}
              value={typeId}
              selection
              search
            />
          </Grid.Column>
          {typeId === 1 && arrayCustomers.length > 0 ? (
            <Grid.Column largeScreen={6} computer={6} tablet={4} mobile={15}>
              <Dropdown
                options={arrayCustomers}
                placeholder={t('discounts.selectCustomer')}
                onChange={this.handleChangeCustomer}
                value={customerId}
                selection
                search
              />
            </Grid.Column>
          ) : ''}
          {typeId === 2 && arrayProducts.length > 0 ? (
            <Grid.Column largeScreen={6} computer={6} tablet={4} mobile={15}>
              <Dropdown
                options={arrayProducts}
                placeholder={t('discounts.selectSku')}
                onChange={this.handleChangeSku}
                value={sku}
                selection
                search
              />
            </Grid.Column>
          ) : ''}
        </Grid.Row>
        {loading ? (
          <Grid.Row>
            <Dimmer active inverted><Loader active size="big" /></Dimmer>
          </Grid.Row>
        ) : (
          <Grid.Row columns={4}>
            <Grid.Column largeScreen={6} computer={6} tablet={4} mobile={15}>
              <TextField
                fullWidth
                maxLength={40}
                value={search || ''}
                error={searchError || ''}
                label={t('orders.search')}
                onChange={this.validations}
              />
            </Grid.Column>
            <Grid.Column largeScreen={12} computer={12} tablet={11} mobile={15} />
          </Grid.Row>
        )}
        <Grid.Row centered>
          <Grid.Column style={{ overflowX: 'auto' }}>
            <SiclikTable
              data={couponsArrayFiltered}
              headers={couponsHeaders}
              pageSize={10}
              noDataMessage={messageTable}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(CouponsSearch);
