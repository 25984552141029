import i18n from 'i18next';
import XHR from 'i18next-http-backend';

i18n
  .use(XHR)
  .init({
    fallbackLng: 'es',
    ns: ['common'],
    defaultNS: 'common',
    backend: {
      // Ruta para cargar la traduccion en el potacio.
      // loadPath: 'https://s3.amazonaws.com/siclik-app/locales/{{lng}}/{{ns}}.json',
    },
    debug: false,
    returnObjects: true,
  });

export default i18n;
