import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Grid, Header, Dimmer, Loader, Dropdown, Confirm, Modal,
} from 'semantic-ui-react';
import * as session from '../common/sessions';
import AnnualPlanService from './services';
import Toast from '../common/components/toast';
import Objective from './components/objective';
import AddIndicator from './components/add-indicator';
import AddStrategy from './components/add-strategy';
import AddObjective from './components/add-objective';

const currentDate = moment();

class PlanArea extends Component {
  constructor(props) {
    super(props);
    const { userId } = session.get();
    this.state = {
      optionsMakers: [],
      makerId: '',
      dataPlanArea: [],
      activeIndex: '',
      activeIndicator: '',
      objective: '',
      userId,
      openAddIndicator: false,
      indicatorId: '',
      openAddStrategy: false,
      openObjectiveDelete: false,
      objectiveToDelete: '',
      openIndicatorDelete: false,
      update: false,
      strategy: '',
      strategyId: '',
      attendant: '',
      date: '',
      progress: '',
      clean: false,
    };
  }

  componentDidMount() {
    this.handleGetDataMakers();
  }

  componentDidUpdate(prevProps) {
    const { customerId, year } = this.props;
    const { makerId } = this.state;
    if (((customerId !== prevProps.customerId) || (year !== prevProps.year)) && makerId) {
      this.handleGetDataAnnualPlan();
    }
  }

  handleGetDataMakers = async () => {
    const makersObject = await AnnualPlanService.getMakers().catch(error => this.handleGetErrorAddUpdate(error));
    if (makersObject) {
      const allMakers = makersObject.makers.map(maker => {
        const formatedMaker = { key: maker.id, value: maker.id, text: maker.name };
        return formatedMaker;
      });
      this.setState({ optionsMakers: allMakers });
    }
  }

  handleChangeMaker = (e, { value }) => this.setState({ makerId: value }, () => this.handleGetDataAnnualPlan());

  handleGetDataAnnualPlan = async () => {
    const { year, customerId } = this.props;
    const { makerId } = this.state;
    let dataPlanArea = [];
    if (year) {
      this.setState({ loading: true });
      dataPlanArea = await AnnualPlanService.getAnnualPlanByCustomerId(customerId, year, makerId)
        .catch(error => {
          this.handleGetError(error);
          this.setState({ dataPlanArea: [], loading: false });
        });
      if (dataPlanArea && dataPlanArea.goals) {
        this.setState({ dataPlanArea: dataPlanArea.goals, loading: false });
      }
    }
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  handleClickIndicators = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndicator } = this.state;
    const newIndex = activeIndicator === index ? -1 : index;
    this.setState({ activeIndicator: newIndex });
  }

  handleGetObjectiveToAdd = async (objective, progress) => {
    const { year, customerId } = this.props;
    const { makerId, dataPlanArea } = this.state;
    const body = ({
      number: (dataPlanArea ? dataPlanArea.length + 1 : 1),
      objective,
      maker: makerId,
      date: year,
      progress: Number(progress),
      customerId,
    });
    const addedId = await AnnualPlanService.addCorporateGoals(body).catch(error => this.handleGetErrorAddUpdate(error));
    if (addedId.id) this.handleObjectiveAdded(body, addedId.id);
  }

  handleObjectiveAdded = (body, id) => {
    const { dataPlanArea } = this.state;
    const { customerId, t } = this.props;
    const { userId } = this.state;
    dataPlanArea.push({
      customerId,
      date: body.year,
      goalId: id,
      number: body.number,
      objective: body.objective,
      userId,
      progress: body.progress,
      indicators: [],
    });
    this.setState({
      dataPlanArea, loading: false, objectiveId: '', objective: '', clean: true,
    });
    Toast(t('business-plan.successAddedObjective'), 'success');
  }

  handleClickAddIndicator = objectiveId => {
    this.setState({ objectiveId, openAddIndicator: true });
  }

  handleClickAddStrategy = (indicatorId, objectiveId) => {
    this.setState({ indicatorId, objectiveId, openAddStrategy: true });
  }

  handleGetIndicatorToAdd = async indicator => {
    const { customerId } = this.props;
    const { objectiveId } = this.state;
    const body = ({
      objectiveId,
      indicator,
      customerId,
    });
    const addedId = await AnnualPlanService.addGoalIndicators(body).catch(error => this.handleGetErrorAddUpdate(error));
    if (addedId.id) this.handleIndicadorAdded(body, addedId.id);
  }

  handleIndicadorAdded = (body, id) => {
    const { dataPlanArea } = this.state;
    const { t } = this.props;
    const index = dataPlanArea.findIndex(goal => goal.goalId === body.objectiveId);
    dataPlanArea[index].indicators.push({
      keyResult: body.indicator,
      keyResultId: id,
      strategys: [],
    });
    this.setState({ dataPlanArea, objectiveId: '', openAddIndicator: false });
    Toast(t('business-plan.successAddedIndicator'), 'success');
  }

  handleGetStrategyToAdd = async strategy => {
    const { indicatorId } = this.state;
    const body = ({
      indicatorId,
      ...strategy,
    });
    const addedId = await AnnualPlanService.addIndicatorStrategy(body).catch(error => this.handleGetErrorAddUpdate(error));
    if (addedId.id) this.handleStrategyAdded(body, addedId.id);
  }

  handleStrategyAdded = (body, id) => {
    const { dataPlanArea, objectiveId } = this.state;
    const { t } = this.props;
    const index = dataPlanArea.findIndex(goal => goal.goalId === objectiveId);
    const indexIndicator = dataPlanArea[index].indicators.findIndex(indicator => indicator.keyResultId === body.indicatorId);
    dataPlanArea[index].indicators[indexIndicator].strategys.push({
      strategyId: id,
      strategy: body.strategy,
      responsable: body.responsable,
      date: body.date,
      progress: body.progress,
    });
    this.setState({ dataPlanArea, objectiveId: '', openAddStrategy: false });
    Toast(t('business-plan.successAddedStrategy'), 'success');
  }

  handleOpenDeleteObjective = (objective, objectiveId) => {
    this.setState({ objectiveToDelete: objective, objectiveId, openObjectiveDelete: true });
  }

  handleClickDeleteObjective = async () => {
    const { objectiveId } = this.state;
    const deleted = await AnnualPlanService.deleteCorporateGoals(objectiveId).catch(error => this.handleGetErrorAddUpdate(error));
    if (deleted && deleted.changedRows) this.handleObjectiveDeleted(objectiveId);
  }

  handleObjectiveDeleted = id => {
    const { dataPlanArea } = this.state;
    const { t } = this.props;
    const index = dataPlanArea.findIndex(goal => goal.goalId === id);
    dataPlanArea.splice(index, 1);
    Toast(t('business-plan.successDeleteObjective'), 'success');
    this.setState({
      dataPlanArea, openObjectiveDelete: false, objective: '', objectiveId: '', objectiveToDelete: '',
    });
  }

  handleOpenDeleteIndicator = (objectiveId, indicator, indicatorId) => {
    this.setState({
      objectiveId, indicator, indicatorId, openIndicatorDelete: true,
    });
  }

  handleClickDeleteIndicator = async () => {
    const { objectiveId, indicatorId } = this.state;
    const deleted = await AnnualPlanService.deleteIndicator(indicatorId).catch(error => this.handleGetErrorAddUpdate(error));
    if (deleted && deleted.changedRows) this.handleIndicatorDeleted(objectiveId, indicatorId);
  }

  handleIndicatorDeleted = (objectiveId, indicatorId) => {
    const { dataPlanArea } = this.state;
    const { t } = this.props;
    const index = dataPlanArea.findIndex(goal => goal.goalId === objectiveId);
    const indexIndicator = dataPlanArea[index].indicators.findIndex(indicator => indicator.keyResultId === indicatorId);
    dataPlanArea[index].indicators.splice(indexIndicator, 1);
    Toast(t('business-plan.successDeleteIndicator'), 'success');
    this.setState({ dataPlanArea, openIndicatorDelete: false });
  }

  handleOpenDeleteStrategy = (objectiveId, row) => {
    this.setState({
      objectiveId, strategy: row.strategy, strategyId: row.strategyId, openStrategyDelete: true,
    });
  }

  handleClickDeleteStrategy = async () => {
    const { objectiveId, activeIndicator, strategyId } = this.state;
    const deleted = await AnnualPlanService.deleteStrategy(strategyId).catch(error => this.handleGetErrorAddUpdate(error));
    if (deleted && deleted.changedRows) this.handleStrategyDeleted(objectiveId, activeIndicator, strategyId);
  }

  handleStrategyDeleted = (objectiveId, indicatorId, strategyId) => {
    const { dataPlanArea } = this.state;
    const { t } = this.props;
    const index = dataPlanArea.findIndex(goal => goal.goalId === objectiveId);
    const indexIndicator = dataPlanArea[index].indicators.findIndex(indicator => indicator.keyResultId === indicatorId);
    const indexStrategy = dataPlanArea[index].indicators[indexIndicator].strategys.findIndex(strategy => strategy.strategyId === strategyId);
    dataPlanArea[index].indicators[indexIndicator].strategys.splice(indexStrategy, 1);
    Toast(t('business-plan.successDeleteStrategy'), 'success');
    this.setState({ dataPlanArea, openStrategyDelete: false });
  }

  handleClickUpdateObjective = (objectiveId, objective, progress) => this.setState({
    objectiveId,
    objective,
    progress,
    update: true,
  });

  handleUpdateObjective = async (objective, progress) => {
    const { customerId, year } = this.props;
    const { objectiveId, dataPlanArea, makerId } = this.state;
    const body = ({
      objetiveId: objectiveId,
      number: (dataPlanArea ? dataPlanArea.length + 1 : 1),
      objective,
      maker: makerId,
      date: year,
      progress: Number(progress),
      customerId,
    });
    const updated = await AnnualPlanService.updateCorporateGoals(body).catch(error => this.handleGetErrorAddUpdate(error));
    if (updated && updated.changedRows) this.handleObjectiveUpdated(body);
  }

  handleObjectiveUpdated = body => {
    const { dataPlanArea } = this.state;
    const { t } = this.props;
    const elementToUpdate = dataPlanArea.findIndex(element => element.goalId === body.objetiveId);
    dataPlanArea[elementToUpdate].objective = body.objective;
    dataPlanArea[elementToUpdate].progress = body.progress;
    Toast(t('business-plan.successUpdateObjective'), 'success');
    this.setState({
      dataPlanArea, objective: '', objectiveId: '', progress: '', update: false,
    });
  }

  handleClickUpdateIndicator = (objectiveId, indicatorId, indicator) => {
    this.setState({
      objectiveId, indicatorId, indicator, openAddIndicator: true,
    });
  }

  handleUpdateIndicator = async indicator => {
    const { customerId } = this.props;
    const { objectiveId, indicatorId } = this.state;
    const body = ({
      objectiveId,
      indicatorId,
      indicator,
      customerId,
    });
    const updated = await AnnualPlanService.updateIndicators(body).catch(error => this.handleGetErrorAddUpdate(error));
    if (updated.changedRows) this.handleIndicatorUpdated(body);
  }

  handleIndicatorUpdated = body => {
    const { dataPlanArea } = this.state;
    const { t } = this.props;
    const objectiveToUpdate = dataPlanArea.findIndex(element => element.goalId === body.objectiveId);
    const indicatorToUpdate = dataPlanArea[objectiveToUpdate].indicators.findIndex(element => element.keyResultId === body.indicatorId);
    dataPlanArea[objectiveToUpdate].indicators[indicatorToUpdate].keyResult = body.indicator;
    this.setState({
      dataPlanArea, indicator: '', objectiveId: '', indicatorId: '', update: false, openAddIndicator: false,
    });
    Toast(t('business-plan.successUpdateIndicator'), 'success');
  }

  handleClickUpdateStrategy = data => {
    this.setState({
      objectiveId: data.objectiveId,
      strategyId: data.strategyId,
      strategy: data.strategy,
      attendant: data.responsable,
      date: data.date,
      progress: data.progress,
      openAddStrategy: true,
    });
  }

  handleUpdateStrategy = async data => {
    const { activeIndicator } = this.state;
    const body = ({
      tacticId: data.strategyId,
      indicatorId: activeIndicator,
      strategy: data.strategy,
      responsable: data.responsable,
      progress: data.progress,
      date: data.date,
    });
    const updated = await AnnualPlanService.updateStrategy(body).catch(error => this.handleGetErrorAddUpdate(error));
    if (updated.changedRows) this.handleStrategyUpdated(body);
  }

  handleStrategyUpdated = body => {
    const { dataPlanArea, objectiveId } = this.state;
    const { t } = this.props;
    const objectiveToUpdate = dataPlanArea.findIndex(element => element.goalId === objectiveId);
    const indicatorToUpdate = dataPlanArea[objectiveToUpdate].indicators
      .findIndex(element => element.keyResultId === body.indicatorId);
    const strategyToUpdate = dataPlanArea[objectiveToUpdate].indicators[indicatorToUpdate].strategys.findIndex(element => element.strategyId === body.tacticId);
    dataPlanArea[objectiveToUpdate].indicators[indicatorToUpdate].strategys[strategyToUpdate].strategy = body.strategy;
    dataPlanArea[objectiveToUpdate].indicators[indicatorToUpdate].strategys[strategyToUpdate].responsable = body.responsable;
    dataPlanArea[objectiveToUpdate].indicators[indicatorToUpdate].strategys[strategyToUpdate].progress = body.progress;
    dataPlanArea[objectiveToUpdate].indicators[indicatorToUpdate].strategys[strategyToUpdate].date = body.date;
    this.setState({
      dataPlanArea, indicator: '', objectiveId: '', indicatorId: '', openAddStrategy: false,
    });
    Toast(t('business-plan.successUpdateStrategy'), 'success');
  }

  handleGetError = error => {
    const { t } = this.props;
    let messageError = '';
    if (!error) {
      messageError = t('error-codes.default');
    } else if (Number(error.code) === 1005 || Number(error.code) === 1000) {
      // this.setState({ messageTable: t('identity-pages.emptyTable') });
    } else { messageError = t(`error-codes.${error.code}`); }
    if (messageError) Toast(messageError, 'error');
  }

  handleGetErrorAddUpdate = error => {
    const { t } = this.props;
    let messageError = '';
    if (!error) {
      messageError = t('error-codes.default');
    } else {
      messageError = t(`error-codes.${error.code}`);
    }
    if (messageError) Toast(messageError, 'error');
  }

  handleClickCancelObjective = () => {
    this.setState({
      update: false,
      objectiveId: '',
      objective: '',
    });
  }

  handleClickCancelIndicator = () => {
    this.setState({
      openAddIndicator: false,
      indicatorId: '',
      indicator: '',
      objective: '',
      objectiveId: '',
    });
  }

  handleClickCancelStrategy = () => {
    this.setState({
      openStrategyDelete: false,
      openAddStrategy: false,
      strategy: '',
      strategyId: '',
      indicator: '',
      indicatorId: '',
      objective: '',
      objectiveId: '',
      attendant: '',
      date: '',
      progress: '',
    });
  }

  render() {
    const { t } = this.props;
    const {
      loading, dataPlanArea, activeIndex, activeIndicator, objective, objectiveId, update, optionsMakers, makerId,
      openAddIndicator, openAddStrategy, openObjectiveDelete, objectiveToDelete, indicator, clean,
      openIndicatorDelete, openStrategyDelete, indicatorId, strategy, strategyId, attendant, date, progress,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row />
        <Grid.Row columns={4}>
          <Grid.Column style={{ paddingLeft: '2%', paddingRight: '0' }} largeScreen={4} computer={4} tablet={4} mobile={12}>
            <Header size="headline" type="base">{t('business-plan.areaPlan')}</Header>
          </Grid.Column>
          <Grid.Column style={{ paddingLeft: '.5rem' }} largeScreen={6} computer={6} tablet={6} mobile={12}>
            <Dropdown
              options={optionsMakers}
              placeholder={t('business-plan.objectiveInstructions')}
              value={makerId}
              fluid
              selection
              search
              onChange={this.handleChangeMaker}
            />
          </Grid.Column>
        </Grid.Row>
        {!loading && !update && dataPlanArea.length < 1 && makerId
          ? (
            <Header size="h5" style={{ marginTop: '5%', marginLeft: '10%' }}>
              {t('business-plan.newObjective')}
            </Header>
          )
          : null}
        {!loading && !update && makerId
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <AddObjective
                  handleGetObjectiveToAdd={this.handleGetObjectiveToAdd}
                  handleUpdateObjective={this.handleUpdateObjective}
                  objective={objective}
                  objectiveId={objectiveId}
                  clean={clean}
                  handleClickCancelObjective={this.handleClickCancelObjective}
                  changeClean={() => this.setState({ clean: false })}
                  progress={progress}
                  tr={t}
                />
              </Grid.Column>
            </Grid.Row>
          ) : null}
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Modal open={update}>
              <Modal.Content>
                <AddObjective
                  handleGetObjectiveToAdd={this.handleGetObjectiveToAdd}
                  handleUpdateObjective={this.handleUpdateObjective}
                  objective={objective}
                  objectiveId={objectiveId}
                  clean={clean}
                  handleClickCancelObjective={this.handleClickCancelObjective}
                  changeClean={() => this.setState({ clean: false })}
                  progress={progress}
                  tr={t}
                />
              </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        {!loading
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                {dataPlanArea && dataPlanArea.length > 0
                  ? (
                    <Objective
                      goals={dataPlanArea}
                      handleClick={this.handleClick}
                      handleClickIndicators={this.handleClickIndicators}
                      activeIndex={activeIndex}
                      activeIndicator={activeIndicator}
                      handleClickAddIndicator={this.handleClickAddIndicator}
                      handleClickAddStrategy={this.handleClickAddStrategy}
                      handleOpenDeleteObjective={this.handleOpenDeleteObjective}
                      handleOpenDeleteIndicator={this.handleOpenDeleteIndicator}
                      handleOpenDeleteStrategy={this.handleOpenDeleteStrategy}
                      handleClickUpdateObjective={this.handleClickUpdateObjective}
                      handleClickUpdateIndicator={this.handleClickUpdateIndicator}
                      handleClickUpdateStrategy={this.handleClickUpdateStrategy}
                    />
                  ) : null}
              </Grid.Column>
            </Grid.Row>
          ) : null}
        {loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          )
          : (
            <Grid.Row columns={2}>
              <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={12} />
            </Grid.Row>
          )}
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Modal open={openAddIndicator}>
              <Modal.Content>
                <AddIndicator
                  handleGetIndicatorToAdd={this.handleGetIndicatorToAdd}
                  handleUpdateIndicator={this.handleUpdateIndicator}
                  indicator={indicator}
                  indicatorId={indicatorId}
                  handleClickCancelIndicator={this.handleClickCancelIndicator}
                  tr={t}
                />
              </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal open={openAddStrategy}>
            <Modal.Content>
              <AddStrategy
                handleGetStrategyToAdd={this.handleGetStrategyToAdd}
                strategyId={strategyId}
                strategy={strategy}
                attendant={attendant}
                date={date ? moment(date).format('YYYY-MM-DD') : ''}
                progress={progress}
                handleUpdateStrategy={this.handleUpdateStrategy}
                handleClickCancelStrategy={this.handleClickCancelStrategy}
                tr={t}
              />
            </Modal.Content>
          </Modal>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openObjectiveDelete}
            header={t('business-plan.confirmDeleteObjective')}
            content={objectiveToDelete}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ objectiveId, objective: '', openObjectiveDelete: false })}
            onConfirm={this.handleClickDeleteObjective}
          />
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openIndicatorDelete}
            header={t('business-plan.confirmDeleteIndicator')}
            content={indicator}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ indicator: '', indicatorId: '', openIndicatorDelete: false })}
            onConfirm={this.handleClickDeleteIndicator}
          />
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openStrategyDelete}
            header={t('business-plan.confirmDeleteStrategy')}
            content={strategy}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleClickCancelStrategy}
            onConfirm={this.handleClickDeleteStrategy}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(PlanArea);

PlanArea.defaultProps = {
  year: currentDate.year(),
  customerId: '',
  t: null,
};

PlanArea.propTypes = {
  year: PropTypes.number,
  customerId: PropTypes.string,
  t: PropTypes.func,
};
