import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Icon, Button, Header, Responsive, Confirm, GridRow,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import validator from 'validator';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import '../style.css';
import customerUsers from './services';
import routes from '../route-names';
import Toast from '../common/components/toast';
import wellcomeServices from '../welcome/services';
import UserData from './components/add';
import * as session from '../common/sessions';

const SERVICE_ACCOUNT_USER = 'd9c2b0e7-ff59-45e5-98ea-beb09f8f290d';
const ROLE_NAMES = {
  1: { id: 1, role: 'Admin Distribuidor' },
  2: { id: 2, role: 'Ventas' },
  3: { id: 3, role: 'Compras' },
};

class AddUser extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);

    if (!hasAccess) props.history.push('/perfil');

    this.state = {
      userEmail: '',
      emailError: '',
      messageTable: '',
      filter: '',
      accessType: [],
      arrayUsers: [],
      headerUsersArray: [
        { key: 'id', isKey: true },
        { key: 'name', name: 'Nombre' },
        { key: 'email', name: 'Correo' },
        { key: 'role', name: 'Acceso' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <GridRow>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleUpdateUser(row)} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleDeleteUser(row)} />
            </GridRow>
          ),
        },
      ],
      userId: '',
      shouldClean: false,
      update: false,
      save: true,
      confirmDelete: false,
      open: false,
      userToDelete: '',
      arrayUsersFiltered: [],
    };
  }

  componentDidMount() {
    this.handleGetUsers();
    this.handleGetAccessType();
  }

  handleGetAccessType = async () => {
    const { t } = this.props;
    const userRolesArray = await customerUsers.getUserRolesGeneral()
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005) this.setState({ accessByError: t('user.errors.permissionsNotFound') });
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });

    const accessArray = userRolesArray.roles.map(roles => ({ value: roles.id, label: roles.name }));
    this.setState({ accessType: accessArray });
  }

  handleDeleteUser = row => this.setState({
    userToDelete: row.email, confirmDelete: true, userId: row.id,
  });

  handleDeleteConfirmed = () => {
    const { userId, arrayUsers, filter } = this.state;
    const { t } = this.props;
    customerUsers.deleteUser(userId).then(() => {
      Toast(t('user.userDelted'), 'success');
      const userToDeleted = arrayUsers.findIndex(user => user.id === userId);
      arrayUsers.splice(userToDeleted, 1);
      this.handleFilter(filter, arrayUsers);
      this.setState({
        arrayUsers,
        userEmail: '',
        accessBy: '',
        accessByError: '',
        confirmDelete: false,
      });
    }).catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
  }

  handleAddUser = async isCompleted => {
    const { t } = this.props;
    const {
      arrayUsers, userEmail, accessBy, filter,
    } = this.state;
    const accessValue = ROLE_NAMES[accessBy].role || '';
    const body = {
      email: userEmail,
      roleId: accessBy,
    };
    const userAdded = await customerUsers.addUser(body)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
        return ({ id: null });
      });
    if (userAdded.id) {
      arrayUsers.push({
        id: userAdded.id, name: '', surname: '', email: userEmail, role: accessValue,
      });
      if (isCompleted === false) this.handleUpdateStep();
      this.setState({
        arrayUsers, userEmail: '', accessBy: '', open: false,
      });
      this.handleFilter(filter, arrayUsers);
      Toast(t('user.userAdded'), 'success');
    }
  }

  handleUpdateStep = () => {
    const { t, updateStep } = this.props;
    const body = { stepId: 3, status: 1 };
    const stepToUpdate = { key: 3, completed: true };
    wellcomeServices.updateStep(body)
      .then(() => {
        updateStep(stepToUpdate);
        Toast(t('user.stepCompleted'), 'success');
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetUsers = async () => {
    const { t } = this.props;
    const { customerId } = session.get();
    const usersArray = await customerUsers.getUsers(customerId)
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005) this.setState({ messageTable: t('tableEmpty') });
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    const arrayUsersFiltered = usersArray.users.filter(user => user.id !== SERVICE_ACCOUNT_USER);
    this.setState({ arrayUsers: arrayUsersFiltered, arrayUsersFiltered });
    if (arrayUsersFiltered.length > 0) this.handleUpdateStep();
  }

  handleEmailChange = event => {
    const { t } = this.props;
    const maxLength = 55;
    const minLength = 10;
    const { value: userEmail } = event.target;
    if (userEmail && userEmail.length > 0) {
      if (!validator.isEmail(userEmail)) {
        this.setState({ userEmail, emailError: t('user.errors.notEmail') });
        return;
      }
      if (userEmail.length > maxLength) {
        this.setState({ userEmail, emailError: t('user.errors.toLong') });
        return;
      }
      if (userEmail.length < minLength) {
        this.setState({ userEmail, emailError: t('user.errors.toShort') });
        return;
      }
    }
    this.setState({ userEmail, emailError: '' });
  }

  validations = isCompleted => {
    let mistakes = false;
    const { userEmail, accessBy } = this.state;
    let { emailError, accessByError } = this.state;
    const { t } = this.props;
    if (userEmail) {
      if (userEmail.trim().length === 0) {
        emailError = t('user.errors.empty');
        mistakes = true;
      }
    }
    if (!accessBy) {
      accessByError = t('user.errors.empty');
      mistakes = true;
    }
    if (emailError) mistakes = true;
    if (accessByError) mistakes = true;
    if (!mistakes) this.handleSaveClick(isCompleted);
    else this.setState({ emailError, accessByError });
  }

  handleSaveClick = isCompleted => this.handleAddUser(isCompleted);

  goBack = () => {
    const { history } = this.props;
    history.push(routes.profile.route);
  }

  goNext = nextStep => {
    const { history } = this.props;
    history.push(nextStep);
  }

  handleUpdateUser = userData => {
    const accessBy = Number(userData.role.replace(ROLE_NAMES[1].role, 1).replace(ROLE_NAMES[2].role, 2).replace(ROLE_NAMES[3].role, 3));
    this.setState({
      open: true,
      userId: userData.id,
      userEmail: userData.email,
      accessBy,
      emailError: '',
      accessByError: '',
      update: true,
      save: false,
    });
  }

  handleClickUpdate = async () => {
    const { t } = this.props;
    const {
      userEmail, accessBy, userId, filter,
    } = this.state;
    const accessValue = ROLE_NAMES[accessBy].role || '';
    let { arrayUsers } = this.state;
    const body = { email: userEmail, roleId: accessBy };
    const userUpdated = await customerUsers.updateCustomerUsers(userId, body)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (userUpdated.changedRows) {
      arrayUsers = arrayUsers.map(currentUser => {
        const user = { ...currentUser };
        if (user.id === userId) {
          user.email = userEmail;
          user.role = accessValue;
        }
        return user;
      });
      this.setState({
        arrayUsers,
        userId: '',
        userEmail: '',
        accessBy: '',
        emailError: '',
        accessByError: '',
        update: false,
        save: true,
        open: false,
      });
      this.handleFilter(filter, arrayUsers);
      Toast(t('user.userUpdated'), 'success');
    }
  }

  handleCancel = () => this.setState({ confirmDelete: false });

  handleClickCancel = () => this.setState({
    userEmail: '',
    accessType: [],
    emailError: '',
    accessBy: '',
    accessByError: '',
    update: false,
    save: true,
  });

  handleFilterUsers = event => {
    const { arrayUsers } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, arrayUsers);
  }

  handleFilter = (filter, arrayUsers) => {
    const arrayUsersCopy = JSON.parse(JSON.stringify(arrayUsers));
    let foundData = [];
    if (!filter) {
      foundData = arrayUsers;
    } else {
      foundData = arrayUsersCopy.filter(filterInfo => (filterInfo.name && filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.email && filterInfo.email.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.role && filterInfo.role.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    }
    this.setState({ filter, arrayUsersFiltered: foundData });
  }

  render() {
    const { t, steps } = this.props;
    const {
      userEmail, accessType, emailError, accessBy, accessByError, arrayUsersFiltered, headerUsersArray,
      messageTable, shouldClean, update, save, confirmDelete, userToDelete, filter, filteError,
      open,
    } = this.state;
    let nextStep = '';
    if (steps.length > 3) {
      nextStep = (steps.filter(step => step.key > 3))[0].href;
    } else {
      nextStep = routes.dashboard.route;
    }
    const currentKey = steps.find(step => step.key === 3);

    return (
      <Grid container>
        <Grid.Row columns={3} centered>
          { currentKey && currentKey.key === 3 && currentKey.completed === false
            && (
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={4}>
              <Header textAlign="center">
                <Icon onClick={this.goBack} class="arrow" name="arrow circle left" size="huge" />
              </Header>
              <Responsive as={Header} minWidth={768}>
                <Header textAlign="center" as="h4">{t('back')}</Header>
              </Responsive>
            </Grid.Column>
            )}
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={8}>
            <Header color="blue" textAlign="center" as="h2">{t('user.title')}</Header>
          </Grid.Column>
          { currentKey && currentKey.key === 3 && currentKey.completed === false
            && (
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={4}>
              <Header textAlign="center">
                <Icon onClick={() => this.goNext(nextStep)} class="arrow" name="arrow circle right" size="huge" />
              </Header>
              <Responsive as={Header} minWidth={768}>
                <Header as="h4" textAlign="center">{t('next')}</Header>
              </Responsive>
            </Grid.Column>
            )}
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={14}>
            <Header className="userMessage" as="h4">{t('user.info')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14} />
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
            <Button onClick={() => this.setState({ open: true })} content={t('add')} labelPosition="left" icon="add user" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('user.filter')}
              onChange={this.handleFilterUsers}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6} />
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column style={{ overflowX: 'scroll' }}>
            <SiclikTable
              selectable
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              data={arrayUsersFiltered}
              headers={headerUsersArray}
              pageSize={5}
              noDataMessage={messageTable}
              singleSelection
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={confirmDelete}
            header={t('user.confirmDeleteUser')}
            content={userToDelete}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleDeleteConfirmed}
          />
        </Grid.Row>
        <Grid.Row>
          <UserData
            open={open}
            userEmail={userEmail}
            accessType={accessType}
            emailError={emailError}
            accessBy={accessBy}
            accessByError={accessByError}
            update={update}
            save={save}
            onItemClick={({ target }) => this.setState({
              accessBy: target.value, accessByError: '',
            })}
            validations={this.validations}
            handleEmailChange={this.handleEmailChange}
            handleClickUpdate={this.handleClickUpdate}
            handleClickCancel={() => this.setState({ accessBy: '', userEmail: '', open: false })}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

AddUser.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  updateStep: PropTypes.func,
};

AddUser.defaultProps = {
  history: () => { },
  steps: () => { },
  t: () => { },
  updateStep: () => { },
};

export default translate('common', { wait: true })(AddUser);
