import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const multiBrand = {};

multiBrand.getCustomerData = customerId => (
  request({
    route: apiRoutes.getCustomerById(customerId),
    method: 'GET',
  })
);

multiBrand.getFinalUser = (customerId = undefined) => (
  request({
    route: apiRoutes.getFinalUsers(customerId),
    method: 'GET',
  })
);

multiBrand.getBranchOfficeAddress = (customerFromOrder = undefined) => (
  request({
    route: apiRoutes.getBranchOfficeAddress(customerFromOrder),
    method: 'GET',
  })
);

multiBrand.getColony = postalCode => (
  request({
    route: apiRoutes.validatePostalCode(postalCode),
    method: 'GET',
  })
);
multiBrand.getUser = userId => (
  request({
    route: apiRoutes.getUserInformation(userId),
    method: 'GET',
  })
);
multiBrand.addFormData = body => (
  request({
    route: apiRoutes.addBrandsData(),
    method: 'POST',
    body,
  })
);

multiBrand.getDataFormMakers = orderId => (
  request({
    route: apiRoutes.getDataFormMakers(orderId),
    method: 'GET',
  })
);

multiBrand.getFormCustomerFinalUser = (finalUserId, customerId) => (
  request({
    route: apiRoutes.getFormCustomerFinalUser(finalUserId, customerId),
    method: 'GET',
  })
);

multiBrand.getFormCustomerFinalUser = (finalUserId, customerId) => (
  request({
    route: apiRoutes.getFormCustomerFinalUser(finalUserId, customerId),
    method: 'GET',
  })
);

multiBrand.addFormCustomerFinalUser = body => (
  request({
    route: apiRoutes.addFormCustomerFinalUser(),
    method: 'POST',
    body,
  })
);

multiBrand.updateFormCustomerFinalUser = body => (
  request({
    route: apiRoutes.updateFormCustomerFinalUser(),
    method: 'PUT',
    body,
  })
);

multiBrand.getGroupsCustomerId = customerId => (
  request({
    route: apiRoutes.getGroupsByCustomerId(customerId),
    method: 'GET',
  })
);

multiBrand.addReview = (orderId, payload) => (
  request({
    route: apiRoutes.addReview(orderId),
    method: 'POST',
    body: payload,
  })
);

multiBrand.getReviews = orderId => (
  request({
    route: apiRoutes.getQuoteReviews(orderId),
    method: 'GET',
  })
);

multiBrand.deleteReviewById = reviewId => (
  request({
    route: apiRoutes.deleteReviewById(),
    method: 'POST',
    body: reviewId,
  })
);

multiBrand.sendReviewNotification = body => (
  request({
    route: apiRoutes.sendReviewNotification(),
    method: 'POST',
    body,
  })
);
export default multiBrand;
