import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const intelligenceDashboard = {};

intelligenceDashboard.getProductsReport = body => (
  request({
    route: apiRoutes.getProductsReport(body),
  })
);

intelligenceDashboard.getCouponsByOrderReport = body => (
  request({
    route: apiRoutes.getCouponsByOrderReport(body),
  })
);

intelligenceDashboard.getBestBuyers = body => (
  request({
    route: apiRoutes.getBestBuyers(body),
  })
);

intelligenceDashboard.getCommonSearchs = body => (
  request({
    route: apiRoutes.getCommonSearchs(body),
  })
);

intelligenceDashboard.getMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

intelligenceDashboard.getLogSessionsReport = body => (
  request({
    route: apiRoutes.getLogSessionsReport(body),
  })
);

intelligenceDashboard.getSurveyReport = body => (
  request({
    route: apiRoutes.getSurveyReport(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.disableSurvey = platformId => (
  request({
    route: apiRoutes.disableSurvey(platformId),
    method: 'PUT',
  })
);

intelligenceDashboard.getSurveyResponse = body => (
  request({
    route: apiRoutes.getSurveyResponse(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.addLogRestartSurvey = body => (
  request({
    route: apiRoutes.addLogRestartSurvey(),
    method: 'POST',
    body,
  })
);

intelligenceDashboard.getLogRestartSurvey = body => (
  request({
    route: apiRoutes.getLogRestartSurvey(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.getLogUserReport = body => (
  request({
    route: apiRoutes.getLogUserReport(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.getImagesReport = body => (
  request({
    route: apiRoutes.getImagesReport(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.getUnmeasuredProductReport = body => (
  request({
    route: apiRoutes.getUnmeasuredProductReport(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.getBrands = body => (
  request({
    route: apiRoutes.getBrands(),
    method: 'GET',
    body,
  })
);

intelligenceDashboard.getAbandonedCarts = () => (
  request({
    route: apiRoutes.getAbandonedCarts(),
    method: 'GET',
  })
);

export default intelligenceDashboard;
