/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import moment from 'moment';
import {
  Header, Grid, Dropdown, Dimmer, Loader, Button,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import SiclikTable from '../common/components/table';
import { TextField } from '../common/components/materials';
import * as session from '../common/sessions';
import Months from '../common/components/months';
import Years from '../common/components/years';
import routes from '../route-names';
import Toast from '../common/components/toast';
import orderService from './services';
import { currencyFormat, formatDecimalNumber } from '../common/utils';

class OrdersMain extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      search: '',
      year: '',
      month: '',
      searchError: '',
      messageTable: '',
      ordersArray: [],
      ordersArrayFiltered: [],
      userIdLoged: '',
      ordersHeaders: [
        { key: 'id', isKey: true },
        { key: 'movId', name: t('Id pedido') },
        { key: 'dateFormated', name: t('orders.appointment') },
        { key: 'amount', name: props.t('orders.amount') },
        { key: 'endUser', name: t('orders.endUser') },
        { key: 'agent', name: t('orders.agent') },
        { key: 'status', name: t('orders.status') },
        {
          key: 'open',
          name: 'Abrir',
          format: (cell, row) => (row.id ? <Button color="blue" icon="file outline" onClick={() => props.history.push(`${routes.orderDetailsAgent.route.replace(':orderId?', '')}${row.id}`)} /> : null),
        },
      ],
      sortOptionsArray: [
        { key: 1, text: 'Id Pedido (ascendente)', value: 1 },
        { key: 2, text: 'Id Pedido (descendente)', value: 2 },
        { key: 3, text: 'Fecha de pedido (ascendente)', value: 3 },
        { key: 4, text: 'Fecha de pedido (descendente)', value: 4 },
        { key: 5, text: 'Importe (ascendente)', value: 5 },
        { key: 6, text: 'Importe (descendente)', value: 6 },
        { key: 7, text: 'Usuario final A-Z', value: 7 },
        { key: 8, text: 'Usuario final Z-A', value: 8 },
        { key: 9, text: 'Agente A-Z', value: 9 },
        { key: 10, text: 'Agente Z-A', value: 10 },
        { key: 11, text: 'Estatus A-Z', value: 11 },
        { key: 12, text: 'Estatus Z-A', value: 12 },
      ],
      statusArray: [],
      arrayUsers: [],
      column: 4,
      status: '',
      loading: true,
      userId: '',
      filterOptions: [
        { key: 1, text: 'Mis pedidos', value: 1 },
        { key: 2, text: 'Por distribuidor', value: 2 },
        { key: 3, text: 'Por colaborador', value: 3 },
        // { key: 3, text: 'Todos', value: 3 },
      ],
      customerIdToSearch: '',
    };
  }

  componentDidMount = () => {
    this.handleAccessPermission();
    this.handleGetCustomers();
  }

  handleGetCustomers = async () => {
    let { arrayCustomers } = this.state;
    arrayCustomers = await orderService.getCustomers()
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (arrayCustomers && arrayCustomers.customers) {
      arrayCustomers = arrayCustomers.customers.map(customer => (
        {
          key: customer.id,
          text: `${customer.id} - ${customer.name}`,
          value: customer.id,
        }
      ));
    }
    this.setState({ arrayCustomers });
  }

  handleAccessPermission = () => {
    const { access, userId } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);
    if (hasAccess) {
      this.handleGetUsers();
    }
    this.setState({ userId, userIdLoged: userId });
    this.handleGetOrders(userId);
  }

  handleGetOrders = userId => {
    const { t } = this.props;
    const statusArray = [];
    this.setState({ loading: true });
    orderService.getOrdersByAgent(userId)
      .then(response => {
        response.orders.map(currentOrder => {
          const order = { ...currentOrder };
          order.dateFormated = order.createdAt ? moment(order.createdAt).format('DD/MM/YYYY HH:mm') : null;
          order.amountNumber = order.amount;
          if (order.currency && order.currency === 'Dolar MS') order.currencyFormat = 'USD';
          else if (order.currency && (order.currency === 'Dolares' || order.currency === 'Dólares')) order.currencyFormat = 'USD';
          else order.currencyFormat = 'MXN';
          if (order.amount && order.currency) {
            order.amount = `${currencyFormat(order.amount, order.currencyFormat)}`;
          } else if (order.amount) {
            order.amount = `${formatDecimalNumber(order.amount)}`;
          } else { order.amount = ''; }
          if (order.status && order.status.length > 0) statusArray.push(order.status);
          order.movId = String(order.movId);
          return order;
        });
        this.setState({ ordersArray: response.orders, ordersArrayFiltered: response.orders, loading: false });
        this.handleGetStatus(statusArray);
        this.handleSortedByDate('asc');
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code !== 1000) Toast(messageError, 'error');
        this.setState({ loading: false, ordersArray: [], ordersArrayFiltered: [] });
      });
  }

  handleGetOrdersByCustomer = customerId => {
    const { t } = this.props;
    const statusArray = [];
    this.setState({ loading: true });
    orderService.getOrdersByCustomerId(customerId)
      .then(response => {
        response.orders.map(currentOrder => {
          const order = { ...currentOrder };
          order.dateFormated = order.createdAt ? moment(order.createdAt).format('DD/MM/YYYY HH:mm') : null;
          order.amountNumber = order.amount;
          if (order.currency && order.currency === 'Dolar MS') order.currencyFormat = 'USD';
          else if (order.currency && (order.currency === 'Dolares' || order.currency === 'Dólares')) order.currencyFormat = 'USD';
          else order.currencyFormat = 'MXN';
          if (order.amount && order.currency) {
            order.amount = `${currencyFormat(order.amount, order.currencyFormat)}`;
          } else if (order.amount) {
            order.amount = `${formatDecimalNumber(order.amount)}`;
          } else { order.amount = ''; }
          if (order.status && order.status.length > 0) statusArray.push(order.status);
          order.movId = String(order.movId);
          return order;
        });
        response.orders = response.orders.filter(order => order.id && order.id.length > 0);
        this.setState({ ordersArray: response.orders, ordersArrayFiltered: response.orders, loading: false });
        this.handleGetStatus(statusArray);
        this.handleSortedByDate('asc');
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code !== 1000) Toast(messageError, 'error');
        this.setState({ loading: false, ordersArray: [], ordersArrayFiltered: [] });
      });
  }

  handleGetAllOrders = () => {
    const { t } = this.props;
    const statusArray = [];
    this.setState({ loading: true });
    orderService.getAllOrders()
      .then(response => {
        response.orders.map(currentOrder => {
          const order = { ...currentOrder };
          order.dateFormated = order.createdAt ? moment(order.createdAt).format('DD/MM/YYYY HH:mm') : null;
          order.amountNumber = order.amount;
          if (order.currency && order.currency === 'Dolar MS') order.currencyFormat = 'USD';
          else if (order.currency && (order.currency === 'Dolares' || order.currency === 'Dólares')) order.currencyFormat = 'USD';
          else order.currencyFormat = 'MXN';
          if (order.amount && order.currency) {
            order.amount = `${currencyFormat(order.amount, order.currencyFormat)}`;
          } else if (order.amount) {
            order.amount = `${formatDecimalNumber(order.amount)}`;
          } else { order.amount = ''; }
          if (order.status && order.status.length > 0) statusArray.push(order.status);
          order.movId = String(order.movId);
          return order;
        });
        response.orders = response.orders.filter(order => order.id && order.id.length > 0);
        this.setState({ ordersArray: response.orders, ordersArrayFiltered: response.orders, loading: false });
        this.handleGetStatus(statusArray);
        this.handleSortedByDate('asc');
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code !== 1000) Toast(messageError, 'error');
        this.setState({ loading: false, ordersArray: [], ordersArrayFiltered: [] });
      });
  }

  handleGetStatus = statusItems => {
    const status = statusItems.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
    const statusArray = status.map(order => {
      const formatedStatus = { key: order, text: order, value: order };
      return formatedStatus;
    });
    this.setState({ statusArray });
  }

  handleGetUsers = () => {
    const { t } = this.props;
    const arrayUsers = [];
    const { customerId } = session.get();
    orderService.getUsers(customerId)
      .then(response => {
        response.users.map(user => {
          if (/* user.confirmedAccount === 1 && */ user.name && user.name.length > 1) {
            arrayUsers.push({ key: user.id, text: `${user.name} ${user.surname}`, value: user.id });
          }
          return user;
        });
        arrayUsers.push({ key: 0, text: 'Todos los pedidos', value: 0 });
        this.setState({ arrayUsers });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  validations = event => {
    const { t } = this.props;
    const maxLength = 31;
    let mistakes = false;
    const { value: search } = event.target;
    if ((search.trim()).length > maxLength) {
      this.setState({ search, searchError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) this.setState({ search, searchError: '' });
    this.filterGeneral(search, 1);
  }

  handleYearChange = year => {
    this.setState({ year });
    this.filterGeneral(year, 2);
  }

  handleMonthChange = month => {
    this.setState({ month });
    this.filterGeneral(month, 3);
  }

  handleStatusChange = (e, { value }) => {
    this.setState({ status: value });
    this.filterGeneral(value, 4);
  }

  filterGeneral = (value, typeFilter) => {
    const { ordersArray } = this.state;
    let ordersArrayFiltered = JSON.parse(JSON.stringify(ordersArray));
    let {
      search, year, month, status,
    } = this.state;
    if (typeFilter === 1) search = value;
    if (typeFilter === 2) year = value;
    if (typeFilter === 3) month = value;
    if (typeFilter === 4) status = value;
    if (search) ordersArrayFiltered = this.filterForSearch(search, ordersArrayFiltered);
    if (year) ordersArrayFiltered = this.filterForYear(year, ordersArrayFiltered);
    if (month) ordersArrayFiltered = this.filterForMonth(month, ordersArrayFiltered);
    if (status) ordersArrayFiltered = this.filterForStatus(status, ordersArrayFiltered);
    this.setState({ ordersArrayFiltered });
  }

  filterForSearch = (filter, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => (filterInfo.amount && filterInfo.amount.replace('$', '').replace(',', '').toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.movId && filterInfo.movId.indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.endUser && filterInfo.endUser.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.agent && filterInfo.agent.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    return foundData;
  }

  filterForYear = (year, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => filterInfo.dateFormated && filterInfo.dateFormated.length >= 10 && Number(filterInfo.dateFormated.substring(6, 10)) === Number(year));
    return foundData;
  }

  filterForMonth = (month, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => filterInfo.dateFormated && filterInfo.dateFormated.length > 9 && Number(filterInfo.dateFormated.substring(3, 5)) === Number(month));
    return foundData;
  }

  filterForStatus = (status, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => filterInfo.status === status);
    return foundData;
  }

  handleChangeUser = (e, { value }) => {
    this.setState({ userId: value });
    this.handleGetOrders(value);
  }

  handleSortChange = (e, { value }) => {
    this.setState({ column: value });
    switch (value) {
      case 1: this.handleSortedByIntelisisId('desc'); break;
      case 2: this.handleSortedByIntelisisId('asc'); break;
      case 3: this.handleSortedByDate('desc'); break;
      case 4: this.handleSortedByDate('asc'); break;
      case 5: this.handleSortedByAmount('desc'); break;
      case 6: this.handleSortedByAmount('asc'); break;
      case 7: this.handleSortedByEndUser('desc'); break;
      case 8: this.handleSortedByEndUser('asc'); break;
      case 9: this.handleSortedByAgent('desc'); break;
      case 10: this.handleSortedByAgent('asc'); break;
      case 11: this.handleSortedByStatus('desc'); break;
      case 12: this.handleSortedByStatus('asc'); break;
      default: this.handleSortedByIntelisisId('asc'); break;
    }
  }

  handleSortedByIntelisisId = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.movId;
        const y = b.movId;
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );

    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByDate = direction => {
    const { ordersArrayFiltered } = this.state;
    let array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = moment(b.dateFormated, 'DD-MM-YYYY');
        const y = moment(a.dateFormated, 'DD-MM-YYYY');
        const sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        return sorted;
      },
    );
    if (direction === 'desc') {
      array = array.reverse();
    }
    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByAmount = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = Number(a.amountNumber);
        const y = Number(b.amountNumber);
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );

    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByEndUser = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.endUser.toLowerCase();
        const y = b.endUser.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );
    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByAgent = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.agent.toLowerCase();
        const y = b.agent.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );
    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByStatus = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.status.toLowerCase();
        const y = b.status.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );
    this.setState({ ordersArrayFiltered: array });
  }

  handleChangeFilter = (e, { value }) => {
    const { userIdLoged } = this.state;
    this.setState({ optionId: value });
    if (value === 1) this.handleGetOrders(userIdLoged);
  }

  handleChangeCustomer = (e, { value }) => {
    this.setState({ customerIdToSearch: value });
    this.handleGetOrdersByCustomer(value);
  }

  render() {
    const { t } = this.props;
    const {
      userId, search, loading, loadingStatus, searchError, messageTable, ordersArrayFiltered, ordersHeaders,
      statusArray, status, arrayUsers, column, sortOptionsArray, filterOptions, optionId, arrayCustomers, customerIdToSearch,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1rem' }}>
        <Grid.Row>
          <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={15}>
            <Header color="blue" as="h1">{t('orders.orders')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={5} tablet={5}>
            <Dropdown
              options={filterOptions}
              placeholder={t('quotesMainAgent.chooseCategory')}
              onChange={this.handleChangeFilter}
              value={optionId}
              selection
            />
          </Grid.Column>
          {optionId === 3 && arrayUsers && arrayUsers.length > 0
            ? (
              <Grid.Column>
                <Dropdown
                  options={arrayUsers}
                  placeholder={t('orders.selectOrders')}
                  onChange={this.handleChangeUser}
                  value={userId}
                  selection
                  search
                />
              </Grid.Column>
            ) : ''}
          {optionId !== 2 && optionId !== 3
            ? <Grid.Column largeScreen={4} computer={5} tablet={5} /> : null}
          {optionId === 2 && arrayCustomers && arrayCustomers.length > 0
            ? (
              <Grid.Column largeScreen={4} computer={5} tablet={5}>
                <Dropdown
                  options={arrayCustomers}
                  placeholder={t('orders.selectOrders')}
                  onChange={this.handleChangeCustomer}
                  value={customerIdToSearch}
                  search
                  selection
                />
              </Grid.Column>
            )
            : null}
        </Grid.Row>
        {loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          )
          : (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={6} computer={6} tablet={4} mobile={15}>
                <TextField
                  fullWidth
                  maxLength={30}
                  value={search || ''}
                  error={searchError || ''}
                  label={t('orders.search')}
                  onChange={this.validations}
                />
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={11}>
                <Months change={this.handleMonthChange} tr={t} />
              </Grid.Column>
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={5}>
                <Years min={2017} max={2030} change={this.handleYearChange} tr={t} />
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={4} tablet={2} mobile={15}>
                <Dropdown
                  clearable
                  selection
                  value={status}
                  options={statusArray}
                  placeholder={t('orders.status')}
                  onChange={this.handleStatusChange}
                  loading={loadingStatus}
                />
              </Grid.Column>
              <Grid.Column largeScreen={12} computer={12} tablet={11} mobile={15} />
              <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
                {t('orders.shortBy')}
                <Dropdown
                  selection
                  value={column}
                  options={sortOptionsArray}
                  placeholder={t('orders.shortBy')}
                  onChange={this.handleSortChange}
                />
              </Grid.Column>
            </Grid.Row>
          )}
        {
          loading
            ? ''
            : (
              <Grid.Row centered>
                <Grid.Column style={{ overflowX: 'auto' }}>
                  <SiclikTable
                    data={ordersArrayFiltered}
                    headers={ordersHeaders}
                    pageSize={10}
                    noDataMessage={messageTable}
                  />
                </Grid.Column>
              </Grid.Row>
            )
        }
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(OrdersMain);
