import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import DescriptionComponent from './description-component';
import ImageComponent from './imageComponent';
import '../../styles.css';

const ProductDescription = props => {
  const {
    arrayImages, hasNotImages, arrayObjectStock, arrayObjectStockOc,
    currencyPrice, getShoppingCartCount, hasNotStock,
    maker, makersPercents, price, product, productId, productName, promotionDeadline, promotionPrice,
    sku, t, deliverDate,
  } = props;
  return (
    <Grid>
      <Grid.Row centered>
        <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15} className="padding-header">
          <DescriptionComponent
            arrayObjectStock={arrayObjectStock}
            arrayObjectStockOc={arrayObjectStockOc}
            currencyPrice={currencyPrice}
            getShoppingCartCount={getShoppingCartCount}
            hasNotStock={hasNotStock}
            maker={maker}
            makersPercents={makersPercents}
            price={price}
            product={product}
            productId={productId}
            productName={productName}
            promotionDeadline={promotionDeadline}
            promotionPrice={promotionPrice}
            sku={sku}
            t={t}
            deliverDate={deliverDate}
          />
        </Grid.Column>
        <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15}>
          <ImageComponent arrayImages={arrayImages} hasNotImages={hasNotImages} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProductDescription.defaultProps = {
  getShoppingCartCount: () => { },
  t: () => { },
};

ProductDescription.propTypes = {
  arrayImages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arrayObjectStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arrayObjectStockOc: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyPrice: PropTypes.string.isRequired,
  getShoppingCartCount: PropTypes.func,
  hasNotImages: PropTypes.bool.isRequired,
  hasNotStock: PropTypes.bool.isRequired,
  maker: PropTypes.string.isRequired,
  makersPercents: PropTypes.shape({}).isRequired,
  price: PropTypes.number.isRequired,
  product: PropTypes.shape({
    category: PropTypes.string,
    maker: PropTypes.string,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  promotionDeadline: PropTypes.string.isRequired,
  promotionPrice: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  t: PropTypes.func,
  deliverDate: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(ProductDescription);
