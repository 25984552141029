import { request } from '../../common/fetch-utils';
import apiRoutes from '../../apiRoutes';

const service = {};

service.getCategories = () => (
  request({
    route: apiRoutes.getCategories(),
    method: 'GET',
  })
);

service.getTotalItems = () => (
  request({
    route: apiRoutes.getShoppingCartTotalItems(),
    method: 'GET',
  })
);

service.getWishList = () => (
  request({
    route: apiRoutes.getWishLists(),
    method: 'GET',
  })
);

service.getAttributesByKeywords = keyword => (
  request({
    route: apiRoutes.searchSuggestions(keyword),
    method: 'GET',
  })
);

service.getItemsWishList = async wishListId => {
  const { wishList } = await request({
    route: apiRoutes.itemsWishList(wishListId),
    method: 'GET',
  });
  return wishList;
};

service.getAllListProducts = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

export default service;
