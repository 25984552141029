import { fromJS } from 'immutable';

import {
  FETCH_WISH_LIST_ITEMS,
  ITEMS_LOADER,
  UPDATE_ITEM_QUANTITY,
  FILTER_ITEMS,
  REMOVE_ITEM,
} from './constants';

const items = 'items';
const filteredItems = 'filteredItems';
const itemsLoader = 'itemsLoader';
const quantityloader = 'quantityloader';

export const initialState = fromJS({
  [items]: [],
  [filteredItems]: null,
  [quantityloader]: false,
  [itemsLoader]: false,
});

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_WISH_LIST_ITEMS:
      return state
        .set(items, payload.items)
        .set(filteredItems, payload.items)
        .set(itemsLoader, false);
    case ITEMS_LOADER:
      return state
        .set(itemsLoader, payload.loader);
    case FILTER_ITEMS:
      return state
        .set(filteredItems, payload.itemsFound);
    case UPDATE_ITEM_QUANTITY: {
      const { items: wishListItems } = state.toJS();
      const uncheckItemData = wishListItems.map(item => (
        item.sku === payload.item.sku
          ? { ...item, quantity: payload.item.quantity }
          : item
      ));
      return state
        .set(quantityloader, false)
        .set(items, uncheckItemData)
        .set(filteredItems, uncheckItemData);
    }
    case REMOVE_ITEM: {
      const { items: wishListItems } = state.toJS();
      const updatedItems = wishListItems.filter(item => item.sku !== payload.sku);
      return state
        .set(items, updatedItems)
        .set(filteredItems, updatedItems);
    }
    default:
      return state;
  }
}
