import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const orders = {};

orders.getOrdersStatus = () => (
  request({
    route: apiRoutes.getOrdersStatus(),
    method: 'GET',
  })
);

orders.getUsers = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
    method: 'GET',
  })
);

orders.getOrdersByAgent = agentId => (
  request({
    route: apiRoutes.getOrdersByAgent(agentId),
    method: 'GET',
  })
);

orders.getAllOrders = () => (
  request({
    route: apiRoutes.getAllOrdersAgent(),
    method: 'GET',
  })
);

orders.getOrdersByCustomerId = customerId => (
  request({
    route: apiRoutes.getOrdersByCustomerId(customerId),
    method: 'GET',
  })
);

orders.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

export default orders;
