import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const discount = {};

discount.getDiscountsList = (userId, getAll) => (
  request({
    route: apiRoutes.getDiscountsList(userId, getAll),
    method: 'GET',
  })
);

discount.deleteDiscount = discountId => (
  request({
    route: apiRoutes.deleteDiscountById(discountId),
    method: 'DELETE',
  })
);

discount.addReview = body => (
  request({
    route: apiRoutes.addDiscountReview(),
    method: 'POST',
    body,
  })
);

discount.getAllMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

discount.getUsers = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
    method: 'GET',
  })
);

discount.getUsersHasPermission = () => (
  request({
    route: apiRoutes.getUsersHasPermission(),
    method: 'GET',
  })
);

discount.getCouponsByUen = uenId => (
  request({
    route: apiRoutes.getCouponsByUen(uenId),
    method: 'GET',
  })
);

export default discount;
