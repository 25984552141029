import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { Card } from 'semantic-ui-react';

import ProductCardListMode from './product-card-list';
import ProductCardGridMode from './product-card-grid';

import { VIEW_GRID, VIEW_LIST } from '../const';

function parseItemObject(item) {
  return {
    currency: item.prices.currency,
    image: item.image,
    isVirtual: item.isVirtual,
    brand: item.brand.name,
    brandLogo: item.brand.logo,
    title: item.title,
    category: item.category,
    outletStock: item.outlet.reduce((acc, { quantity }) => acc + quantity, 0),
    stock: item.inventory.reduce((acc, { quantity }) => acc + quantity, 0),
    price: item.prices.listPrice,
    promotionPrice: item.prices.promotionPrice,
    outletPrice: item.prices.outletPrice,
    sku: item.sku,
    sku2: item.sku2,
    promotion: [],
    hasVariants: item.hasVariants,
    minQuantity: item.minQuantity,
    maxQuantity: item.maxQuantity,
  };
}

function ProductList(props) {
  const {
    items,
    viewMode,
    makersPercents,
    isAvailableRedhat,
    lockViewMode,
    windowWidth,
  } = props;

  const [itemsPerRow, setItemsPerRow] = useState(viewMode === VIEW_GRID ? 4 : 1);

  function getCardComponent(item) {
    const views = {
      [VIEW_GRID]: () => (
        <ProductCardGridMode
          key={item.sku}
          makersPercents={makersPercents}
          isAvailableRedhat={isAvailableRedhat}
          product={parseItemObject(item)}
        />
      ),
      [VIEW_LIST]: () => (
        <ProductCardListMode
          key={item.sku}
          makersPercents={makersPercents}
          isAvailableRedhat={isAvailableRedhat}
          product={parseItemObject(item)}
        />
      ),
    };

    return views[viewMode]();
  }

  useEffect(() => {
    const handleResize = () => {
      if (windowWidth >= 1700) {
        setItemsPerRow(viewMode === VIEW_GRID ? 4 : 1);
        lockViewMode(false);
      } else if (windowWidth < 1699 && windowWidth >= 1200) {
        setItemsPerRow(viewMode === VIEW_GRID ? 3 : 1);
        lockViewMode(false);
      } else if (windowWidth < 1199 && windowWidth >= 768) {
        setItemsPerRow(viewMode === VIEW_GRID ? 2 : 1);
        lockViewMode(true);
      } else {
        setItemsPerRow(1);
        lockViewMode(true);
      }
    };

    handleResize();
  }, [windowWidth, lockViewMode, viewMode]);

  return (
    <Card.Group stackable itemsPerRow={itemsPerRow}>
      {items.map(getCardComponent)}
    </Card.Group>
  );
}

ProductList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  viewMode: PropTypes.oneOf([VIEW_GRID, VIEW_LIST]),
  makersPercents: PropTypes.shape({}),
  isAvailableRedhat: PropTypes.bool,
  lockViewMode: PropTypes.func.isRequired,
  windowWidth: PropTypes.number.isRequired,
};

ProductList.defaultProps = {
  items: [],
  viewMode: VIEW_GRID,
  makersPercents: {},
  isAvailableRedhat: false,
};

export default translate('common', { wait: true })(ProductList);
