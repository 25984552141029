export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};
export const sheetHeaders = {
  user: { header: 'Usuario', width: 12 },
  email: { header: 'Correo', width: 40 },
  customerId: { header: 'Cliente', width: 12 },
  comments: { header: 'Comentarios', width: 50 },
  rating: { header: 'Calificación', width: 12 },
  date: { header: 'Fecha de registro', width: 40 },
};
export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
