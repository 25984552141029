import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import uniqBy from 'lodash/uniqBy';

import {
  Message,
  Segment,
  Card,
  Image,
  Icon,
  Button,
  Checkbox,
  List,
} from 'semantic-ui-react';

import ModalUploadImage from '../../upload-images/modal-upload-image';

const translatePath = 'productConfig.menus.uploadBulkImages.itemGroupSegment';

function ItemGroups(props) {
  const {
    itemGroup,
    onDismiss,
    onEdit,
    onLoadImages,
    groupNumber,
    loading,
    t,
  } = props;
  const [images, setImages] = useState([]);
  const [openImageModal, setOpenImageModal] = useState(false);

  const onOpenModal = () => {
    setOpenImageModal(true);
  };

  const handleUploadImages = newImages => {
    const allImages = [...images, ...newImages];
    const sanitizedImages = uniqBy(allImages.map(file => ({
      ...file,
      isMain: file?.isMain || false,
    })), 'name');
    setImages(sanitizedImages);
    onLoadImages(
      itemGroup.id,
      sanitizedImages,
    );
  };

  const handleDeleteImage = image => {
    const sanitizedImages = images.filter(currentImage => currentImage.name !== image.name);
    setImages(sanitizedImages);
    onLoadImages(
      itemGroup.id,
      sanitizedImages,
    );
  };

  const handleMainImage = (image, checked) => {
    const sanitizedImages = images.map(currentImage => ({
      ...currentImage,
      isMain: currentImage.name === image.name ? checked : false,
    }));
    setImages(sanitizedImages);
    onLoadImages(
      itemGroup.id,
      sanitizedImages,
    );
  };

  return (
    <div>
      <Message
        attached
        content={(
          <List>
            <List.Item>
              <strong>{t(`${translatePath}.header`, { groupNumber })}</strong>
              {' '}
              {t(`${translatePath}.content`, { selectedProductsLength: itemGroup.selectedProducts.length })}
            </List.Item>
            <List.Item>
              <Button
                icon
                size="tiny"
                labelPosition="right"
                onClick={() => onEdit(itemGroup.id)}
              >
                {t('edit')}
                <Icon name="pencil" />
              </Button>
            </List.Item>
          </List>
        )}
        icon={{ name: 'images outline' }}
        size="tiny"
        floating
        style={{ textAlign: 'left' }}
        onDismiss={() => onDismiss(itemGroup.id)}
      />
      <Segment attached raised loading={loading}>
        <Card.Group>
          {images.length > 0 && images.map(image => (
            <Card
              key={image.name}
              style={{
                maxHeight: '260px',
                padding: '5px 5px 0 5px',
              }}
            >
              <Image
                src={image.preview}
                style={{
                  height: '170px',
                  objectFit: 'contain',
                  backgroundColor: '#ffffff',
                }}
              />
              <Card.Content extra>
                <Checkbox
                  toggle
                  style={{ fontSize: '.9em', paddingBottom: '5px' }}
                  label={t(`${translatePath}.mainImage`)}
                  checked={image.isMain}
                  onChange={(e, { checked }) => handleMainImage(image, checked)}
                />
                <Button
                  fluid
                  primary
                  size="mini"
                  content={t('delete')}
                  onClick={() => handleDeleteImage(image)}
                />
              </Card.Content>
            </Card>
          ))}
          <Card
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              minHeight: '220px',
            }}
            onClick={onOpenModal}
          >
            <Icon name="add" size="huge" />
            {t(`${translatePath}.addImages`)}
          </Card>
        </Card.Group>
      </Segment>
      <ModalUploadImage
        isImageType
        open={openImageModal}
        onClose={() => setOpenImageModal(false)}
        uploadImages={handleUploadImages}
      />
    </div>
  );
}

ItemGroups.propTypes = {
  groupNumber: PropTypes.number.isRequired,
  itemGroup: PropTypes.shape({
    id: PropTypes.string.isRequired,
    selectedProducts: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onLoadImages: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ItemGroups);
