import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const shippingRoutes = {};
shippingRoutes.getBranchOfficeAddress = (customerFromOrder = undefined) => (
  request({
    route: apiRoutes.getBranchOfficeAddress(customerFromOrder),
    method: 'GET',
  })
);
shippingRoutes.getManeuver = branchOfficeId => (
  request({
    route: apiRoutes.getManeuvers(branchOfficeId),
    method: 'GET',
  })
);
shippingRoutes.getComments = branchOfficeId => (
  request({
    route: apiRoutes.getComments(branchOfficeId),
    method: 'GET',
  })
);

shippingRoutes.getParcelPrice = body => (
  request({
    route: apiRoutes.getParcelPrice(body),
    method: 'POST',
    body,
  })
);
shippingRoutes.contactAgent = body => (
  request({
    route: apiRoutes.contactAgent(body),
    method: 'POST',
    body,
  })
);
shippingRoutes.getItems = () => (
  request({
    route: apiRoutes.getItems(),
    method: 'GET',
  })
);
shippingRoutes.updateProductQuantity = (sku, body) => (
  request({
    route: apiRoutes.updateProductQuantity(sku),
    method: 'PUT',
    body,
  })
);
shippingRoutes.addDeliveryConfig = body => (
  request({
    route: apiRoutes.addDeliveryConfig(body),
    method: 'POST',
    body,
  })
);
shippingRoutes.getDeliveryConfig = body => (
  request({
    route: apiRoutes.getDeliveryConfig(body),
    method: 'GET',
  })
);
shippingRoutes.updateShippingPriceByAgent = body => (
  request({
    route: apiRoutes.updateShippingPriceByAgent(body),
    method: 'PUT',
    body,
  })
);
shippingRoutes.getParcels = () => (
  request({
    route: apiRoutes.getParcels(),
    method: 'GET',
  })
);

export default shippingRoutes;
