import React from 'react';
import {
  Header, Grid, Divider,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { currencyFormat } from '../../common/utils';

const baseUrl = process.env.REACT_APP_ENDPOINT;

const TitleStyle = {
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
};

function ListItems({ item, t }) {
  const {
    sku, name, quantity, currency, price,
  } = item;
  const encodedSku = encodeURIComponent(sku);
  const detailPath = `${baseUrl}/producto/detalle/${encodedSku}`;
  const currencyPrice = currency === 'Pesos' ? 'MXN' : 'USD';
  return (
    <Grid key={sku}>
      <Grid.Row className="no-padding">
        {/* <Grid.Column verticalAlign="middle" textAlign="middle" mobile={5} tablet={5} computer={5} largeScreen={5} widescreen={5}>
          <Image
            src={image.replace('http://', 'https://')}
            size="small"
            as="a"
            href={name}
            alt=""
            bordered
            centered
            style={{ cursor: 'pointer' }}
            onClick={() => window.open(detailPath, '_blank')}
          />
        </Grid.Column> */}
        {/* <Grid.Column verticalAlign="middle" textAlign="middle" mobile={10} tablet={10} computer={10} largeScreen={10} widescreen={5}>
          <Grid>
            <Grid.Row> */}
        <Grid.Column className="padding-gral-5" verticalAlign="middle" textAlign="left" mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
          <Header as="h5" color="blue" style={TitleStyle} onClick={() => window.open(detailPath, '_blank')}>{name}</Header>
        </Grid.Column>
        <Grid.Column className="padding-gral-5" verticalAlign="middle" textAlign="right" mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
          <Header as="h5" className="titles-news-gothic">
            {t('orderDetail.sku')}
            :&nbsp;
            <p style={{ display: 'inline' }}>{sku}</p>
          </Header>
        </Grid.Column>
        <Grid.Column className="padding-gral-5" verticalAlign="middle" textAlign="left" mobile={10} tablet={10} computer={10} largeScreen={10} widescreen={10}>
          <Header as="h5" className="titles-news-gothic">
            {t('orderDetail.price')}
            :&nbsp;
            <p className="greenText" style={{ display: 'inline' }}>{currencyFormat(price, currencyPrice)}</p>
          </Header>
        </Grid.Column>
        <Grid.Column className="padding-gral-5" verticalAlign="middle" textAlign="right" mobile={6} tablet={6} computer={6} largeScreen={6} widescreen={6}>
          <Header as="h5" className="titles-news-gothic">
            {t('orderDetail.quantity')}
            :&nbsp;
            <p style={{ display: 'inline' }}>{quantity}</p>
          </Header>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
          <Divider />
        </Grid.Column>
        {/* </Grid.Row>
          </Grid>
        </Grid.Column> */}
      </Grid.Row>
    </Grid>
  );
}

ListItems.propTypes = {
  item: PropTypes.shape({
    currency: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
    sku: PropTypes.string.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ListItems);
