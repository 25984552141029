import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Image, Icon, Card, Divider,
} from 'semantic-ui-react';

const urlAWS = process.env.REACT_APP_URL_AWS;
const assetUrlSiclik = `${urlAWS}/imagenes/assets/siclik/isotipos/`;
const isoUniversidadCs = `${assetUrlSiclik}universidad_CompuSoluciones.png`;
const isoCompuSoluciones = `${assetUrlSiclik}distintivo_CompuSoluciones.png`;
const isoProgramas = `${assetUrlSiclik}programa_desarrollo.png`;
const isoConsultor = `${assetUrlSiclik}atencion_consultor.png`;
const isoEventos = `${assetUrlSiclik}eventos_especiales.png`;
const isoIndustria = `${assetUrlSiclik}estudios_industrias.png`;

const PremierBenefits = () => (
  <>
    <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
      <Card.Group itemsPerRow={5} centered style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Icon name="credit card" size="big" style={{ color: '#BDBDBD' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              35 días de crédito
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Icon name="percent" size="big" style={{ color: '#BDBDBD' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Tasa de financiamiento - 22%
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Icon name="briefcase" size="big" style={{ color: '#BDBDBD' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Tasa de moratorios - 36%
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Icon name="cart arrow down" size="big" style={{ color: '#BDBDBD' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              3 días para reservar productos
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Icon name="tags" size="big" style={{ color: '#BDBDBD' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              15% de descuento para Convención
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
      <Divider />
      <Header textAlign="center" as="h4">Además cuentas con:</Header>
      <Card.Group itemsPerRow={5} style={{ paddingTop: '1rem' }} centered>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Image className="siclik-Comercio" src={isoUniversidadCs} size="mini" style={{ display: 'inline', width: '80px', height: '80px' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Universidad CompuSoluciones
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Image className="siclik-Comercio" src={isoCompuSoluciones} size="mini" style={{ display: 'inline', width: '80px', height: '80px' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Uso del logotipo y distintivo de CompuSoluciones
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Image className="siclik-Comercio" src={isoProgramas} size="mini" style={{ display: 'inline', width: '80px', height: '80px' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Programas especiales Desarrollo Empresarial
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Image className="siclik-Comercio" src={isoConsultor} size="mini" style={{ display: 'inline', width: '80px', height: '80px' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Atención mediante Televenta
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Image className="siclik-Comercio" src={isoEventos} size="mini" style={{ display: 'inline', width: '80px', height: '80px' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Participación eventos especiales
            </Card.Description>
          </Card.Content>
        </Card>
        <Card
          style={{
            width: '250px',
            height: 'auto',
            boxShadow: 'none',
            backgroundColor: '#FAFAFA',
          }}
          raised
          link
        >
          <Card.Content>
            <Card.Header textAlign="center">
              <Image className="siclik-Comercio" src={isoIndustria} size="mini" style={{ display: 'inline', width: '80px', height: '80px' }} />
            </Card.Header>
            <Card.Description textAlign="center">
              Participación en estudios de la industria
            </Card.Description>
          </Card.Content>
        </Card>
      </Card.Group>
    </Grid.Column>
  </>
);

export default translate('common', { wait: true })(PremierBenefits);
