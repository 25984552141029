export const CREDIT = 1;
export const BANK_CARD = 2;
export const BANK_REFERENCE = 3;
export const SPEI = 4;
export const PAYPAL = 5;
export const WIRE_TRANSFER = 6;
export const CREDIT_CARD = 7;
export const DEBIT_CARD = 8;
export const FINANCING_HPEFS = 9;
export const RESERVED = 10;
