/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import validator from 'validator';
import { translate } from 'react-i18next';
import {
  Grid, Divider, Button, Dropdown, Modal, Dimmer, Loader,
} from 'semantic-ui-react';
import multiBrandServices from './services';
import Toast from '../common/components/toast';
import FinalUser from './components/final-user';
import Customer from './components/customer';
import AuthorizationKeys from './components/authorization-keys';
import ProductsRestrictions from './components/products-restrictions';
import CarePacks from './components/care-packs';
import AddSerials from './components/add-serials';
import Note from './components/note';
import AppleDeep from '../apple-dep';
import AutoDesk from './components/autodesk-component';
import * as session from '../common/sessions';
import AddVeeamDiscountPrice from './components/add-veeam-discount-price';
import AddAppleCareSerials from './components/add-appleCare-serials';

import { END_USER_ID } from '../common/const-ids';

class MultiBrandData extends Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      customerIdLoged: customerId,
      VMWARE: false,
      aruba: false,
      hpe: false,
      redHat: false,
      veeam: false,
      hpInc: false,
      apple: false,
      Microsoft: false,
      IBM: false,
      TRELLIX: true,
      Autodesk: false,
      email: '',
      phone: '',
      mobile: '',
      contact: '',
      mobileError: '',
      phoneError: '',
      contactError: '',
      emailError: '',
      finalUserArray: [],
      finalUserError: '',
      finalUser: '',
      addressArray: [],
      addressArrayCopy: [],
      addressError: '',
      addressId: '',
      loading: true,
      resellerId: '',
      resellerIdError: '',
      site: '',
      siteError: '',
      redhatError: false,
      agreement: '',
      agreementError: '',
      emailCustomer: '',
      emailCustomerError: '',
      phoneCustomer: '',
      phoneCustomerError: '',
      contactCustomer: '',
      contactCustomerError: '',
      opp: '',
      sbNumber: '',
      authorizationNum: '',
      discount: '',
      discountError: '',
      orderNumberVeeam: '',
      orderNumberVeeamError: '',
      veeamProPartnerID: '',
      veeamProPartnerIDError: '',
      veeamContractID: '',
      veeamContractIDError: '',
      dealReg: '',
      loadingSave: false,
      dealRegError: '',
      modalViatics: false,
      headers: [
        { key: 'sku', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'name', name: props.t('multibrand.licenseDescription') },
        { key: 'price', name: props.t('multibrand.listPrice') },
        { key: 'quantity', name: props.t('multibrand.quantity') },
        { key: 'subsDate', name: 'Fechas de suscripción' },
        { key: 'endDate', name: 'Número de contrato' },
      ],
      headersCarePacks: [
        { key: 'sku', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'quantity', name: props.t('multibrand.quantity') },
        { key: 'invoiceDate', name: props.t('multibrand.invoiceDate') },
        { key: 'invoiceNumber', name: props.t('multibrand.invoiceNumber') },
        { key: 'series', name: props.t('multibrand.serialNumber') },
        {
          key: 'botton',
          name: props.t('multibrand.linkedSerials'),
          format: (cell, row) => {
            let options = [
              { key: 1, text: props.t('multibrand.linkedInvoiceSerials'), value: { option: 1, row } },
              { key: 2, text: props.t('multibrand.writeSerials'), value: { option: 2, row } },
              { key: 3, text: props.t('multibrand.unlinked'), value: { option: 3, row } },
            ];
            if (row.maker && row.maker === 'Apple') {
              options = [{ key: 1, text: props.t('multibrand.linkedInvoiceSerials'), value: { option: 1, row } },
                { key: 3, text: props.t('multibrand.unlinked'), value: { option: 3, row } }];
            }
            if (row.maker && row.maker === 'HP') {
              options = [{ key: 1, text: props.t('multibrand.linkedInvoiceSerials'), value: { option: 1, row } },
                { key: 2, text: props.t('multibrand.writeSerials'), value: { option: 2, row } }];
            }
            return (
              <Grid.Row>
                <Dropdown
                  options={options}
                  placeholder={props.t('multibrand.chooseOption')}
                  selection
                  onChange={this.handleChangeOpt}
                  value={row.option ? { option: row.option, row } : ''}
                />
              </Grid.Row>
            );
          },
        },

      ],
      products: [],
      redHatProducts: [],
      note: '',
      noteError: '',
      icn: '',
      icnError: '',
      loggin: '',
      logginAuxData: '',
      logginError: '',
      start: '',
      end: '',
      startError: '',
      endError: '',
      orderNumber: '',
      orderNumberError: '',
      emailOwner: '',
      emailOwnerError: '',
      carePacks: [],
      openSeries: false,
      openPurchase: false,
      row: {},
      productsHpInc: [],
      productsAruba: [],
      productsHp: [],
      productsApple: [],
      productsAutodesk: [],
      groups: [],
      makers: [],
      productsVeeam: [],
      productsDicountVeeamPrices: [],
      veeamNSQ: '',
      veeamNSQError: '',
      hasDealOrNSQid: false,
      veeamIdError: '',
      visibleVeeamError: false,
      veeamDiscountPricesResponse: [],
      endUserRelatedProyect: '',
      endUserRelatedProyectError: '',
      selectDealRegReview: false,
      licensingVeeamSelected: false,
      partnerLevels: [
        { value: 'Enrolled', label: 'Enrolled' },
        { value: 'Authorized', label: 'Authorized' },
        { value: 'Partner', label: 'Partner' },
        { value: 'Advanced', label: 'Advanced' },
        { value: 'Principal', label: 'Principal' },
      ],
      partnerLevelError: '',
      partnerLevelId: '',
      partnerId: '',
      partnerIdError: '',
      courseType: [
        {
          key: 'presencial', value: 'presencial', flag: 'presencial', text: 'Presencial',
        },
        {
          key: 'online', value: 'online', flag: 'online', text: 'Online',
        },
      ],
      productsAppleCare: [],
      appleCareResponse: [],
      applecareError: '',
      disabledButton: false,
      idCustomerFinalUser: false,
      imageForm: false,
      ShowLicensingVeeam: false,
    };
  }

  componentDidMount = () => {
    const { orderId } = this.props;
    this.handleValidateBrand();
    this.handleGetAddress();
    this.handleGetFinalUsers();
    this.handleGetFinalUser();
    this.handleGetCustomerData();
    this.handleGetUserInformation();
    this.handleGetProducts();
    this.handleGetGroups();
    if (orderId && orderId.length > 5) this.handleGetDataForm();
    this.handleGetReviews();
    this.handleImageForm();
  }

  handleImageForm = () => {
    const { productsByMaker } = this.props;
    const makerProducts = Object.values(productsByMaker).find(maker => (maker.length > 0));
    this.setState({ imageForm: makerProducts ? makerProducts[0].logoMaker : false });
  }

  handleChangeOpt = (e, { value }) => {
    const { carePacks } = this.state;
    let { openPurchase, openSeries } = this.state;
    const index = carePacks.findIndex(element => element.sku === value.row.sku);
    carePacks[index].option = value.option;
    switch (value.option) {
      case 1:
        openPurchase = true;
        openSeries = false;
        break;
      case 2:
        openSeries = true;
        openPurchase = false;
        break;
      default:
        openPurchase = false;
        openSeries = false;
        break;
    }
    this.setState({
      openPurchase,
      openSeries,
      row: value.row,
      carePacks,
    });
    this.handleFilterCarePacks(carePacks);
  }

  handleValidateBrand = () => {
    const { arrayBrand } = this.props;
    let {
      Microsoft, IBM, TRELLIX, aruba, hpe, redHat, veeam, hpInc, apple, Autodesk, VMWARE,
    } = this.state;
    const makers = [];
    let saveButton = false;
    if (arrayBrand.length > 0) {
      arrayBrand.map(brand => {
        if (brand.name === 'Microsoft') {
          Microsoft = true;
          makers.push('Microsoft');
        }
        if (brand.name === 'IBM') {
          IBM = true;
          makers.push('IBM');
        }
        if (brand.name === 'Trellix') {
          TRELLIX = true;
          makers.push('Trellix');
        }
        if (brand.name === 'Aruba') {
          aruba = true;
          makers.push('Aruba');
        }
        if (brand.name === 'HP') {
          hpe = true;
          makers.push('HPE');
        }
        if (brand.name === 'Red Hat') {
          redHat = true;
          makers.push('RedHat');
        }
        if (brand.name === 'VEEAM') {
          veeam = true;
          makers.push('VEEAM');
        }
        if (brand.name === 'HP Inc') {
          hpInc = true;
          makers.push('HPInc');
        }
        if (brand.name === 'Apple') {
          apple = true;
          makers.push('Apple');
          saveButton = true;
        }
        if (brand.name === 'AUTODESK' || brand.name === 'Autodesk') {
          Autodesk = true;
          makers.push('Autodesk');
        }
        if (brand.name === 'MFG-RENOVACION') {
          Autodesk = true;
          makers.push('Autodesk');
        }
        if (brand.name === 'MFG-LICENCIA') {
          Autodesk = true;
          makers.push('Autodesk');
        }
        if (brand.name === 'SP-SERVICIOS') {
          Autodesk = true;
          makers.push('Autodesk');
        }
        if (brand.name === 'VMware' || brand.name === 'VMWARE') {
          VMWARE = true;
          makers.push('VMware');
        }
        return brand;
      });
      this.setState({
        Microsoft,
        IBM,
        TRELLIX,
        aruba,
        hpe,
        redHat,
        veeam,
        hpInc,
        apple,
        Autodesk,
        makers,
        VMWARE,
        disabledButton: saveButton,
      });
    }
  }

  handleGetFinalUser = () => {
    const { finalUser } = this.props;
    if (finalUser) this.setState({ finalUser, finalUserError: '' });
  }

  handleIdFormFinalUserCustomer = (finalUserParam, customerParam) => {
    let { idCustomerFinalUser } = this.state;
    multiBrandServices.getFormCustomerFinalUser(finalUserParam, customerParam).then(response => {
      if (response.id) idCustomerFinalUser = response.id;
      this.setState({
        idCustomerFinalUser,
      });
    });
  }

  handleDataFormFinalUserCustomer = (finalUserAux = false) => {
    const { customerId } = this.props;
    const { finalUser, redHat } = this.state;
    let {
      contact, email, phone, addressId, mobile, loggin, idCustomerFinalUser, logginAuxData,
    } = this.state;
    const finalUserToSend = finalUserAux !== false ? finalUserAux : finalUser;
    if (finalUser || finalUserAux) {
      multiBrandServices.getFormCustomerFinalUser(finalUserToSend, customerId).then(response => {
        if (response.id) idCustomerFinalUser = response.id;
        if (response.contact) contact = response.contact;
        if (response.email) email = response.email;
        if (response.phone) phone = response.phone;
        mobile = response.cellphone ? response.cellphone : '';
        if (response.adressFinalUser) addressId = response.adressFinalUser;
        loggin = response.userRedHat && redHat ? response.userRedHat : '';
        logginAuxData = response.userRedHat ? response.userRedHat : '';
        this.setState({
          contact,
          email,
          phone,
          mobile,
          addressId,
          loggin,
          idCustomerFinalUser,
          logginAuxData,
        });
      }).catch(() => {
        this.setState({
          contact: '',
          email: '',
          phone: '',
          mobile: '',
          addressId: '',
          loggin: '',
          idCustomerFinalUser: false,
          logginAuxData: '',
        });
      });
    }
  }

  handleGetDataForm = () => {
    const {
      t, orderId, formId, doNotShowWarning,
    } = this.props;
    let {
      contact, email, phone, finalUser, addressId, mobile, resellerId, contactCustomer, agreement,
      emailCustomer, phoneCustomer, site, opp, sbNumber, authorizationNum, discount, emailOwner, partnerId, partnerLevelId,
      start, end, orderNumber, icn, loggin, note, dealReg, orderNumberVeeam, veeamNSQ, veeamDiscountPricesResponse,
      endUserRelatedProyect, veeamProPartnerID, veeamContractID, licensingVeeamSelected, appleCareResponse,
    } = this.state;
    this.setState({ loading: true });
    multiBrandServices.getDataFormMakers(orderId)
      .then(response => {
        this.handleIdFormFinalUserCustomer(response.endUserId, response.customerId);
        if (formId) formId(response.makerFormId);
        if (response.contact) contact = response.contact;
        if (response.endUserRelatedProyect) endUserRelatedProyect = response.endUserRelatedProyect;
        if (response.email) email = response.email;
        if (response.phone) phone = response.phone;
        if (response.cellPhone) mobile = response.cellPhone;
        if (response.endUserId) {
          finalUser = response.endUserId;
          this.handleAddressByFinalUser(response.endUserId);
        }
        if (response.customerId) resellerId = response.customerId;
        if (response.customerContact) contactCustomer = response.customerContact;
        if (response.customerEmail) emailCustomer = response.customerEmail;
        if (response.agreementNumber) agreement = response.agreementNumber;
        if (response.ibmSite) site = response.ibmSite;
        if (response.customerPhone) phoneCustomer = response.customerPhone;
        if (response.endUserAddressId) addressId = response.endUserAddressId;
        if (response.ibmOpp) opp = response.ibmOpp;
        if (response.ibmSb) sbNumber = response.ibmSb;
        if (response.msAuthorization) authorizationNum = response.msAuthorization;
        if (response.emailOwner) emailOwner = response.emailOwner;
        if (response.icn) icn = response.icn;
        if (response.startDate) start = moment(response.startDate).format('YYYY-MM-DD');
        if (response.endDate) end = moment(response.endDate).format('YYYY-MM-DD');
        if (response.loggin) loggin = response.loggin;
        if (response.dealReg) dealReg = response.dealReg;
        if (response.veeamNSQ) veeamNSQ = response.veeamNSQ;
        if (response.orderNumberVeeam) orderNumberVeeam = response.orderNumberVeeam;
        if (response.veeamProPartnerID) veeamProPartnerID = response.veeamProPartnerID;
        if (response.veeamContractID) veeamContractID = response.veeamContractID;
        if (response.veeamContractID && response.veeamContractID !== '') licensingVeeamSelected = true;
        if (response.purchaseOrderNumber) orderNumber = response.purchaseOrderNumber;
        if (response.contractDiscount && response.contractDiscount.length > 0) discount = response.contractDiscount;
        if (response.carePacks && response.carePacks.length > 0) this.handleGetCarePacksSaved(response.carePacks);
        if (response.autodeskPacks.length) this.handleGetAutodeskCoursesSaved(response.autodeskPacks);
        if (response.autodeskPacks.length) this.setFormItems(response.autodeskPacks);
        if (response.note) note = response.notes;
        if (response.partnerId) partnerId = response.partnerId;
        if (response.partnerLevelId) partnerLevelId = response.partnerLevelId;
        if (response.veeamDiscountPrice) veeamDiscountPricesResponse = response.veeamDiscountPrice;
        if (response.appleCare) appleCareResponse = response.appleCare;
        this.setState({
          contact,
          email,
          phone,
          mobile,
          finalUser,
          addressId,
          resellerId,
          contactCustomer,
          agreement,
          emailCustomer,
          phoneCustomer,
          site,
          opp,
          sbNumber,
          authorizationNum,
          discount,
          note,
          loading: false,
          finalUserError: '',
          emailOwner,
          start,
          end,
          orderNumber,
          icn,
          loggin,
          dealReg,
          veeamNSQ,
          orderNumberVeeam,
          veeamDiscountPricesResponse,
          endUserRelatedProyect,
          partnerId,
          partnerLevelId,
          veeamProPartnerID,
          veeamContractID,
          licensingVeeamSelected,
          appleCareResponse,
        });
      })
      .catch(error => {
        let messageError = '';
        this.handleDataFormFinalUserCustomer();
        if (!error) {
          messageError = t('error-codes.default');
        } else if (error.code === 1005) {
          if (!doNotShowWarning) {
            messageError = t(`multibrand.errors.${error.code}`);
            Toast(messageError, 'warning');
          }
        } else if (error.code !== 13) {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
        this.setState({ loading: false });
      });
  }

  handleGetCarePacksSaved = carePacksSaved => {
    let { carePacks } = this.state;
    carePacks = carePacks.map(currentCarePack => {
      const carePack = { ...currentCarePack };
      const id = Number(carePacksSaved.findIndex(element => element.sku === carePack.sku));
      if (carePacksSaved[id] && carePacksSaved[id].serials) {
        carePack.series = carePacksSaved[id].serials.join(', ');
        carePack.serials = carePacksSaved[id].serials.map((serial, index) => ({ id: index, serial, error: '' }));
        carePack.invoiceDate = carePacksSaved[id].invoiceDate;
        carePack.invoiceNumber = carePacksSaved[id].invoiceNumber;
      }
      return carePack;
    });
    this.setState(carePacks);
    this.handleFilterCarePacks(carePacks);
  }

  handleGetAutodeskCoursesSaved = autodeskCoursesSaved => {
    const { productsAutodesk } = this.state;
    const items = productsAutodesk.map(currentAutodeskCourse => {
      const autodeskCourse = { ...currentAutodeskCourse };
      const id = Number(autodeskCoursesSaved.findIndex(element => element.sku === autodeskCourse.sku));
      if (autodeskCoursesSaved[id]) {
        autodeskCourse.startDate = moment(autodeskCoursesSaved[id].startDate).format('YYYY-MM-DD');
        autodeskCourse.endDate = moment(autodeskCoursesSaved[id].endDate).format('YYYY-MM-DD');
        autodeskCourse.courseType = autodeskCoursesSaved[id].courseType;
      }
      return autodeskCourse;
    });
    this.setState({ productsAutodesk: items });
  }

  setFormItems = formItems => {
    const { productsByMaker } = this.props;
    const cartItems = productsByMaker.redHat;
    const redHatItems = cartItems.map(item => {
      const matchItem = formItems.find(({ sku }) => sku === item.sku);
      return {
        ...item,
        startDate: matchItem && matchItem?.startDate ? moment(matchItem.startDate).format('YYYY-MM-DD') : '',
        endDate: matchItem && matchItem?.endDate ? moment(matchItem.endDate).format('YYYY-MM-DD') : '',
        courseType: matchItem && matchItem?.courseType ? matchItem.courseType : '',
      };
    });

    this.setState({ redHatProducts: redHatItems });
  }

  handleGetFinalUsers = () => {
    const { customerId } = this.props;
    const { productsAppleCare } = this.state;
    multiBrandServices.getFinalUser(customerId)
      .then(response => {
        let finalUserArray = response.endUsers.map(user => {
          const formatedUser = { key: user.id, value: user.id, text: user.tradingName };
          return formatedUser;
        });
        finalUserArray = finalUserArray.filter(user => user.id !== END_USER_ID);
        if (productsAppleCare && productsAppleCare.length > 0) finalUserArray = finalUserArray.filter(filter => filter.label.toLowerCase() !== 'stock');
        this.setState({ finalUserArray, loading: false });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleGetAddress = () => {
    const { t, customerId } = this.props;
    let customerIdFromOrder = '';
    if (customerId) customerIdFromOrder = customerId;
    multiBrandServices.getBranchOfficeAddress(customerIdFromOrder)
      .then(response => {
        const addressArray = response.branchOffices;
        const filteredAddress = addressArray.filter(branchOffice => branchOffice.isValidated);
        const addressArrayCopy = filteredAddress.map(address => {
          const formatedAddress = {
            key: address.id, id: address.id, value: address.id, text: address.name, endUser: address.endUser,
          };
          return formatedAddress;
        });
        this.setState({ addressArrayCopy, addressArray: addressArrayCopy });
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleAddressByFinalUser = finalUser => {
    const { addressArrayCopy } = this.state;
    let { addressId } = this.state;
    const addressByFinalUser = JSON.parse(JSON.stringify(addressArrayCopy.filter(address => (address.endUser && address.endUser.id === finalUser))));
    const generalAddresses = JSON.parse(JSON.stringify(addressArrayCopy.filter(address => (!address.endUser || (address.endUser && address.endUser.id !== finalUser)))));
    let addressArray = [];
    if (addressByFinalUser && addressByFinalUser.length > 0) {
      addressArray = addressByFinalUser.concat(generalAddresses);
      addressId = addressArray[0].id;
    } else {
      addressArray = generalAddresses;
    }
    if (addressArray.length > 0) {
      this.setState({
        addressArray, addressError: '', addressId,
      });
    }
  }

  handleChangeMobile = (mobile, error) => this.setState({ mobile, mobileError: error });

  handleChangePhone = (phone, error) => this.setState({ phone, phoneError: error });

  handleChangeContact = (contact, error) => this.setState({ contact, contactError: error });

  handleChangeEmail = (email, error) => this.setState({ email, emailError: error });

  handleChangePartnerId = (partnerId, error) => this.setState({ partnerId, partnerIdError: error });

  handleChangeRelatedProyect = (endUserName, error) => this.setState({ endUserRelatedProyect: endUserName, endUserRelatedProyectError: error });

  handleGetNewAddress = newAddress => {
    const { addressArray } = this.state;
    addressArray.push({
      key: newAddress.key, id: newAddress.key, value: newAddress.key, text: newAddress.text, endUser: '',
    });
    this.setState({ addressArray });
  };

  handleGetNewUser = newUser => {
    const { finalUserArray } = this.state;
    finalUserArray.push({
      ...newUser,
    });
    this.setState({ finalUserArray });
  };

  handleChangeEmailCustomer = (emailCustomer, error) => this.setState({ emailCustomer, emailCustomerError: error });

  handleChangeEmailOwner = (emailOwner, error) => this.setState({ emailOwner, emailOwnerError: error });

  handleChangePhoneCustomer = (phoneCustomer, error) => this.setState({ phoneCustomer, phoneCustomerError: error });

  handleChangeOrderNumber = (orderNumber, error) => this.setState({ orderNumber, orderNumberError: error });

  handleChangeOrderNumberVeeam = (orderNumberVeeam, error) => this.setState({ orderNumberVeeam, orderNumberVeeamError: error });

  handleChangeveeamProPartnerID = (veeamProPartnerID, error) => this.setState({ veeamProPartnerID, veeamProPartnerIDError: error });

  handleChangeveeamContractID = (veeamContractID, error) => this.setState({ veeamContractID, veeamContractIDError: error });

  handleChangeResellerId = (resellerId, error) => this.setState({ resellerId, resellerIdError: error });

  handleChangeAgreement = (agreement, error) => this.setState({ agreement, agreementError: error });

  handleChangeDealReg = (dealReg, error) => {
    let { hasDealOrNSQid } = this.state;
    if (dealReg !== '') hasDealOrNSQid = true;
    else hasDealOrNSQid = false;
    this.setState({ dealReg, dealRegError: error, hasDealOrNSQid });
  }

  handleChangeSite = (site, error) => this.setState({ site, siteError: error });

  handleChangeContactCustomer = contactCustomer => this.setState({ contactCustomer, contactCustomerError: '' });

  handleChangeVeeamNSQ = (veeamNSQ, error) => {
    let { hasDealOrNSQid } = this.state;
    if (veeamNSQ !== '') hasDealOrNSQid = true;
    else hasDealOrNSQid = false;
    this.setState({ veeamNSQ, veeamNSQError: error, hasDealOrNSQid });
  }

  handleSelectDealRegReview = (e, { checked }) => {
    if (checked === true) this.handleAddReview();
    if (checked === false) this.handleDeleteReview();
    this.setState({ selectDealRegReview: checked });
  }

  handleLicensingVeeam = (e, { checked }) => {
    this.setState({ licensingVeeamSelected: checked });
  }

  handleGetUserInformation = () => {
    const { userId } = session.get();
    const { t } = this.props;
    this.setState({ loading: true });
    multiBrandServices.getUser(userId)
      .then(response => {
        this.setState({
          contactCustomer: `${response.name} ${response.surname} ${response.secondSurname}`,
          emailCustomer: response.email,
          phoneCustomer: response.cellPhone,
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetCustomerData = () => {
    const { t } = this.props;
    const { customerId } = session.get();
    multiBrandServices.getCustomerData(customerId)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ finalUserError: messageError });
      });
  }

  handleChangeOPP = (opp, error) => this.setState({ opp, oppError: error });

  handleChangeAuthorizationNumber = (authorizationNum, error) => this.setState({ authorizationNum, authorizationNumError: error });

  handleChangeSBNumber = (sbNumber, error) => this.setState({ sbNumber, sbNumberError: error });

  handleChangeLoggin = (loggin, error) => this.setState({ loggin, logginError: error });

  handleChangeIcn = (icn, error) => this.setState({ icn, icnError: error });

  handleGetProducts = () => {
    const { productsByMaker } = this.props;
    let products = [];
    let redHatProducts = [];
    let carePacks = [];
    let productsAutodesk = [];
    let productsVeeam = [];
    let productsAppleCare = [];
    if (productsByMaker.microsoft) products = productsByMaker.microsoft;
    if (productsByMaker.redHat) redHatProducts = productsByMaker.redHat;
    if (productsByMaker.carePacks) productsAutodesk = productsByMaker.autodesk;
    if (productsByMaker.carePacks) {
      carePacks = productsByMaker.carePacks.map(product => {
        const serials = [];
        for (let i = 0; i < product.quantity; i++) {
          serials.push({ id: i + 1, serial: '', error: '' });
        }
        const formatedProduct = { ...product, serials };
        return formatedProduct;
      });
    }
    if (productsByMaker.veeam) {
      const hasDiscountPrice = false;
      productsVeeam = productsByMaker.veeam.map(product => {
        const formatedProduct = { ...product, hasDiscountPrice };
        return formatedProduct;
      });
    }
    if (productsByMaker.appleCare) {
      productsAppleCare = productsByMaker.appleCare;
    }
    this.handleFilterCarePacks(carePacks);
    this.setState({
      products, redHatProducts, carePacks, productsAutodesk, productsVeeam, productsAppleCare,
    });
  }

  handleChangeDiscount = (discount, error) => this.setState({ discount, discountError: error });

  handleChangeNote = note => {
    if (!validator.isAlpha(note.replace(/ /g, ''), ['es-ES'])) {
      this.setState({ note });
      return;
    }
    if (note.length > 100) {
      this.setState({ note });
      return;
    }
    this.setState({ note });
  }

  validateRedHatSubs = redHatProducts => {
    const VARIANT_SEPARATOR = '->';
    const VARIANTS_RENEWAL = ['R', 'RR'];

    return redHatProducts.every(product => {
      const varianType = product.sku.split(VARIANT_SEPARATOR)[1];
      const isRenewal = VARIANTS_RENEWAL.includes(varianType);
      if (isRenewal) {
        if (!product.startDate || !product.endDate || !product.courseType) return false;
        return true;
      }
      return true;
    });
  };

  handleDataEmpty = isGoToPayment => {
    const { t } = this.props;
    let completed = true;
    const {
      addressId, email, phone, contact, finalUser, IBM, emailCustomer, resellerId, site, agreement, phoneCustomer,
      contactCustomer, authorizationNumError, opp, sbNumber, redHat, icn, veeam, veeamProPartnerID,
      veeamContractID, licensingVeeamSelected, productsAppleCare, apple, loggin, redHatProducts,
    } = this.state;
    let {
      finalUserError, emailError, phoneError, contactError, addressError, emailCustomerError, agreementError, siteError,
      resellerIdError, phoneCustomerError, contactCustomerError, oppError, sbNumberError, icnError,
      veeamProPartnerIDError, veeamContractIDError, applecareError, logginError,
    } = this.state;
    let productsFormatted = productsAppleCare;
    if (!email || email.length === 0) {
      emailError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!phone || phone.length === 0) {
      phoneError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!contact || contact.length === 0) {
      contactError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!addressId) {
      addressError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!finalUser) {
      finalUserError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!emailCustomer || emailCustomer.length === 0) {
      emailCustomerError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!phoneCustomer || phoneCustomer.length === 0) {
      phoneCustomerError = t('multibrand.errors.empty');
      completed = false;
    }
    if (!contactCustomer || (contactCustomer.length === 0)) {
      contactCustomerError = t('multibrand.errors.empty');
      completed = false;
    }
    if (IBM && (!resellerId || resellerId.length === 0)) {
      resellerIdError = t('multibrand.errors.empty');
      completed = false;
    }
    if (IBM && (!site || site.length === 0)) {
      siteError = t('multibrand.errors.empty');
      completed = false;
    }
    if (IBM && (!agreement || agreement.length === 0)) {
      agreementError = t('multibrand.errors.empty');
      completed = false;
    }
    if (IBM && (!opp || opp.length === 0)) {
      oppError = t('multibrand.errors.empty');
      completed = false;
    }
    if (IBM && (!sbNumber || sbNumber.length === 0)) {
      sbNumberError = t('multibrand.errors.empty');
      completed = false;
    }
    if (IBM && (!icn || icn.length === 0)) {
      icnError = t('multibrand.errors.empty');
      completed = false;
    }
    if (redHat && (!loggin || loggin.length === 0)) {
      logginError = t('multibrand.errors.empty');
      completed = false;
    }
    if (redHat && !this.validateRedHatSubs(redHatProducts)) {
      this.setState({ redhatError: true });
      completed = false;
    }
    if (veeam && (!veeamProPartnerID || veeamProPartnerID.length === 0)) {
      veeamProPartnerIDError = t('multibrand.errors.empty');
      completed = false;
    }
    if (veeam && (!veeamContractID || veeamContractID.length === 0) && licensingVeeamSelected === true) {
      veeamContractIDError = t('multibrand.errors.empty');
      completed = false;
    }
    if (apple) {
      productsFormatted = productsFormatted.map(arraydata => {
        const skuToUpdate = productsFormatted.findIndex(findElement => findElement.sku === arraydata.sku);
        arraydata.appleCare.map(item => {
          const appleCareToUpdate = arraydata.appleCare.findIndex(findElement => findElement.sku === item.sku && findElement.id === item.id);
          if (item.serial === '') {
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].serialError = t('multibrand.errors.empty');
            applecareError = t('multibrand.errors.empty');
            completed = false;
          }
          if (item.purchaseDate === '') {
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].purchaseDateError = t('multibrand.errors.empty');
            applecareError = t('multibrand.errors.empty');
            completed = false;
          }
          if (item.orderNumber === '') {
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].orderNumberError = t('multibrand.errors.empty');
            applecareError = t('multibrand.errors.empty');
            completed = false;
          }
          return item;
        });
        return arraydata;
      });
    }
    if (redHat && finalUser === END_USER_ID) {
      finalUserError = 'Para articulos de Red Hat es necesario seleccionar un usuario final diferente a "Stock"';
      completed = false;
    }
    if (completed !== true) {
      this.setState({
        finalUserError,
        emailError,
        phoneError,
        contactError,
        addressError,
        emailCustomerError,
        agreementError,
        siteError,
        resellerIdError,
        phoneCustomerError,
        contactCustomerError,
        authorizationNumError,
        veeamProPartnerIDError,
        veeamContractIDError,
        oppError,
        sbNumberError,
        logginError,
        icnError,
        applecareError,
        productsAppleCare: productsFormatted,
      });
      Toast(t('multibrand.errors.incompleteData'), 'error');
    } else {
      this.setState({ redhatError: false });
      this.handleValidateFormat(isGoToPayment);
    }
  }

  handleValidateFormat = isGoToPayment => {
    const { t } = this.props;
    let mistakes = false;
    const {
      email, phone, contact, mobile, IBM, veeam, emailCustomer, agreement, site, resellerId, contactCustomer,
      phoneCustomer, authorizationNum, opp, sbNumber, Microsoft, start, end, dealReg, veeamNSQ, productsDicountVeeamPrices,
      hasDealOrNSQid, endUserRelatedProyect, veeamProPartnerID, veeamContractID, licensingVeeamSelected, apple, productsAppleCare,
    } = this.state;
    let {
      emailError, contactError, mobileError, phoneError, emailCustomerError, agreementError, siteError,
      resellerError, contactCustomerError, phoneCustomerError, authorizationNumError, oppError, sbNumberError,
      startError, dealRegError, veeamNSQError, veeamIdError, visibleVeeamError, endUserRelatedProyectError,
      veeamProPartnerIDError, veeamContractIDError,
    } = this.state;
    if (email.length < 5) {
      emailError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (contact.length < 5) {
      contactError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (mobile && mobile.length < 10) {
      mobileError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (mobile && mobile.length > 10) {
      mobileError = t('multibrand.errors.toLong');
      mistakes = true;
    }
    if (phone && phone.length < 10) {
      phoneError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (phone && phone.length > 10) {
      phoneError = t('multibrand.errors.toLong');
      mistakes = true;
    }
    if (endUserRelatedProyect && endUserRelatedProyect.length > 60) {
      endUserRelatedProyectError = t('multibrand.errors.toLong');
      mistakes = true;
    }
    if (emailCustomer.length < 5) {
      emailCustomerError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (contactCustomer.length < 5) {
      contactCustomerError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (IBM && agreement.length < 2) {
      agreementError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (IBM && site.length < 2) {
      siteError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (IBM && resellerId.length < 2) {
      resellerError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (phoneCustomer && phoneCustomer.length < 10) {
      phoneCustomerError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (phoneCustomer && phoneCustomer.length > 10) {
      phoneCustomerError = t('multibrand.errors.toLong');
      mistakes = true;
    }
    if (Microsoft && authorizationNum.length > 0 && authorizationNum.length < 5) {
      authorizationNumError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (IBM && opp.length < 5) {
      oppError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (IBM && sbNumber.length < 12) {
      sbNumberError = t('multibrand.errors.toShort');
      mistakes = true;
    }
    if (start > end) {
      startError = true;
      mistakes = true;
    }
    if (veeam && dealReg && !/^DRG\-[a-zA-Z\d\-]{1,255}$/.test(dealReg)) {
      dealRegError = t('El formato no es el correcto - Ej. DRG-111111-112233445566-P-C1');
      mistakes = true;
    }
    if (veeam && veeamProPartnerID && !/PRA\-\d{6}/.test(veeamProPartnerID)) {
      veeamProPartnerIDError = t('El formato no es el correcto - Ejemplo - PRA-078269');
      mistakes = true;
    }
    if (veeam && veeamContractID && !/[\d]{8,10}$/.test(veeamContractID) && licensingVeeamSelected === true) {
      veeamContractIDError = t('El formato no es el correcto - Ejemplo - 422481');
      mistakes = true;
    }
    if (veeam && veeamNSQ && !/^Q\-[\d\-]{5,10}$/.test(veeamNSQ)) {
      veeamNSQError = t('El formato no es el correcto - Q-01234');
      mistakes = true;
    }
    if (hasDealOrNSQid && productsDicountVeeamPrices.length === 0) {
      mistakes = true;
      visibleVeeamError = true;
      veeamIdError = t('Ingresa un precio de acuerdo al descuento (DealReg o NSQ)');
    }
    if (apple) {
      productsAppleCare.map(arraydata => {
        arraydata.appleCare.map(item => {
          if (item.serialError && item.serialError !== '') {
            mistakes = true;
            Toast(t('multibrand.errors.dataErrors'), 'error');
          }
          if (item.purchaseDateError && item.purchaseDateError !== '') {
            mistakes = true;
            Toast(t('multibrand.errors.dataErrors'), 'error');
          }
          if (item.purchaseDateError && item.orderNumberError !== '') {
            mistakes = true;
            Toast(t('multibrand.errors.dataErrors'), 'error');
          }
          return item;
        });
        return arraydata;
      });
    }
    if (mistakes === false) {
      this.handleGetData(isGoToPayment);
    } else {
      this.setState({
        startError,
        emailError,
        contactError,
        mobileError,
        phoneError,
        emailCustomerError,
        agreementError,
        siteError,
        resellerError,
        contactCustomerError,
        phoneCustomerError,
        authorizationNumError,
        oppError,
        sbNumberError,
        dealRegError,
        veeamProPartnerIDError,
        veeamContractIDError,
        veeamNSQError,
        veeamIdError,
        visibleVeeamError,
        endUserRelatedProyectError,
      });
      Toast(t('multibrand.errors.dataErrors'), 'error');
    }
  }

  handleGetDetails = () => {
    const { products, redHatProducts } = this.state;
    let details = [...products, ...redHatProducts];
    if (details && details.length > 0) {
      details = details.map(product => {
        const productFormated = {
          sku: product.sku,
          name: product.name,
          price: product.price,
          quantity: product.quantity,
          maker: product.maker,
          startDate: product?.startDate,
          endDate: product?.endDate,
          courseType: product?.courseType,
        };
        return productFormated;
      });
    }
    return details;
  }

  handleAddOrUpdateFormFinalUserCustomer = () => {
    const { customerId } = this.props;
    const {
      finalUser, contact, email, phone, addressId,
      mobile, loggin, contactCustomer, idCustomerFinalUser,
      emailCustomer, phoneCustomer, logginAuxData,
    } = this.state;

    if (idCustomerFinalUser) {
      const body = {
        id: idCustomerFinalUser,
        idAdress: addressId,
        contact,
        email,
        phone,
        cellphone: mobile,
        clientContact: contactCustomer,
        emailClient: emailCustomer,
        phoneClient: phoneCustomer,
        userRedHat: !loggin ? logginAuxData : loggin,
      };
      multiBrandServices.updateFormCustomerFinalUser(body);
    } else {
      const body = {
        finalUserId: finalUser,
        idAdress: addressId,
        contact,
        email,
        phone,
        cellphone: mobile,
        customerId,
        clientContact: contactCustomer,
        emailClient: emailCustomer,
        phoneClient: phoneCustomer,
        userRedHat: !loggin ? logginAuxData : loggin,
      };

      multiBrandServices.addFormCustomerFinalUser(body);
    }
  }

  handleGetData = isGoToPayment => {
    const { orderId, t } = this.props;
    const {
      productsAutodesk, finalUser, addressId, email, phone, contact, mobile, emailCustomer, agreement, site, resellerId, contactCustomer,
      phoneCustomer, authorizationNum, opp, sbNumber, discount, note, icn, loggin, start, end, orderNumber,
      emailOwner, makers, hpe, dealReg, orderNumberVeeam, veeamNSQ, productsDicountVeeamPrices, veeamProPartnerID, veeamContractID,
      endUserRelatedProyect, partnerId, partnerLevelId,
    } = this.state;
    const carePacksArray = this.handleGetCarePacksData();
    const details = this.handleGetDetails();
    const appleCareArray = this.handleGetAppleCareData();
    const careAutodeskArray = [];
    let continueAutodesk = true;
    for (let index = 0; index < productsAutodesk.length; index++) {
      if (productsAutodesk[index].startDate !== undefined && productsAutodesk[index].endDate !== undefined && productsAutodesk[index].courseType !== undefined) {
        careAutodeskArray.push({
          sku: productsAutodesk[index].sku,
          startDate: productsAutodesk[index].startDate,
          endDate: productsAutodesk[index].endDate,
          courseType: productsAutodesk[index].courseType,
        });
      } else {
        continueAutodesk = false;
      }
    }
    for (let index = 0; index < productsAutodesk.length; index++) {
      if (productsAutodesk[index].startDate > productsAutodesk[index].endDate) {
        continueAutodesk = false;
      }
    }
    if (hpe && (!carePacksArray || carePacksArray.length < 1) && continueAutodesk === false) {
      if (continueAutodesk === false) Toast(t('multibrand.emptyCarePacksAutodesk'), 'warning');
      Toast(t('multibrand.emptyCarePacksHPE'), 'warning');
    } else {
      const allData = {
        orderId,
        endUserId: finalUser,
        contact,
        email,
        phone,
        cellPhone: mobile,
        endUserAddressId: addressId,
        customerId: resellerId,
        customerContact: contactCustomer,
        customerEmail: emailCustomer,
        customerPhone: phoneCustomer,
        agreementNumber: agreement,
        ibmSite: site,
        ibmOpp: opp,
        ibmSb: sbNumber,
        msAuthorization: authorizationNum,
        contractDiscount: discount === '' ? '0' : discount,
        notes: note,
        icn,
        loggin,
        startDate: start,
        dealReg,
        orderNumberVeeam,
        veeamProPartnerID,
        veeamContractID,
        endDate: end,
        purchaseOrderNumber: orderNumber,
        emailOwner,
        carePacks: carePacksArray,
        autodeskPacks: careAutodeskArray,
        details,
        makers,
        veeamNSQ,
        veeamDiscountPrice: productsDicountVeeamPrices,
        endUserRelatedProyect,
        partnerId,
        partnerLevelId,
        appleCare: appleCareArray,
      };
      if (!mobile) delete allData.cellPhone;
      if (!resellerId) delete allData.customerId;
      if (!agreement) delete allData.agreementNumber;
      if (!site) delete allData.ibmSite;
      if (!opp) delete allData.ibmOpp;
      if (!sbNumber) delete allData.ibmSb;
      if (!authorizationNum) delete allData.msAuthorization;
      if (!discount) delete allData.contractDiscount;
      if (!icn) delete allData.icn;
      if (!loggin) delete allData.loggin;
      if (!start) delete allData.startDate;
      if (!end) delete allData.endDate;
      if (!orderNumber) delete allData.purchaseOrderNumber;
      if (!emailOwner) delete allData.emailOwner;
      if (!orderNumberVeeam) delete allData.orderNumberVeeam;
      if (!veeamProPartnerID) delete allData.veeamProPartnerID;
      if (!veeamContractID) delete allData.veeamContractID;
      if (!dealReg) delete allData.dealReg;
      if (!veeamNSQ) delete allData.veeamNSQ;
      if (!endUserRelatedProyect) delete allData.endUserRelatedProyect;
      if (!note) delete allData.notes;
      if (!productsDicountVeeamPrices) delete allData.veeamDiscountPrice;
      if (!partnerId) delete allData.partnerId;
      if (!partnerLevelId) delete allData.partnerLevelId;
      if (!appleCareArray) delete allData.appleCareArray;
      this.handleSaveData(allData, isGoToPayment);
      this.handleAddOrUpdateFormFinalUserCustomer();
    }
  }

  handleSaveData = async (allData, isGoToPayment) => {
    const {
      t, formId, saveAsQuote, handleGoToPayment, handleSaveQuotation, onClose, savedForm,
    } = this.props;
    this.setState({ loadingSave: true });
    multiBrandServices.addFormData(allData).then(response => {
      if (formId) formId(response.id);
      if (isGoToPayment && formId) {
        handleGoToPayment(true);
      } else if (isGoToPayment) {
        handleGoToPayment();
      }
      if (saveAsQuote) handleSaveQuotation();
      if (!isGoToPayment) onClose();
      if (response) {
        Toast(t('multibrand.successSaveData'), 'success');
        savedForm(true, allData);
      }
      this.setState({ loadingSave: false });
    }).catch(error => {
      let messageError = '';
      if (!error) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
      savedForm(false, allData);
      this.setState({ loadingSave: false });
    });
  };

  handleDate = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value, startError: '' });
  };

  handleGetSerials = skuData => {
    const { carePacks } = this.state;
    const indexSku = carePacks.findIndex(product => product.sku === skuData.sku);
    carePacks[indexSku].series = skuData.serials.join(', ');
    carePacks[indexSku].invoiceDate = skuData.invoiceDate;
    carePacks[indexSku].invoiceNumber = skuData.invoiceNumber;
    this.handleFilterCarePacks(carePacks);
    this.setState(carePacks);
  };

  handleGetSerialsAppleDeep = skuData => {
    const { carePacks } = this.state;
    skuData.map(skuItem => {
      const serials = [];
      const indexSku = carePacks.findIndex(product => product.sku === skuItem.skuCarePack);
      carePacks[indexSku].invoiceDate = moment(skuItem.billingDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
      carePacks[indexSku].invoiceNumber = skuItem.billingMovId;
      skuItem.serials.map(item => serials.push(item.serial));
      carePacks[indexSku].series = serials.join(', ');
      return skuItem;
    });
    this.handleFilterCarePacks(carePacks);
    this.setState({ carePacks, openPurchase: false });
  };

  handleFilterCarePacks = carePacksArray => {
    let {
      productsHpInc, productsAruba, productsHp, productsApple, productsAutodesk,
    } = this.state;
    productsAruba = carePacksArray.filter(product => product.maker === 'Aruba');
    productsApple = carePacksArray.filter(product => product.maker === 'Apple');
    productsHp = carePacksArray.filter(product => product.maker === 'HP');
    productsHpInc = carePacksArray.filter(product => product.maker === 'HP Inc');
    productsAutodesk = productsAutodesk.filter(product => product.maker === 'Autodesk');
    this.setState({
      productsHpInc, productsAruba, productsHp, productsApple, productsAutodesk,
    });
  }

  handleGetCarePacksData = () => {
    const {
      productsHpInc, productsAruba, productsHp, productsApple, hpe, productsAutodesk,
    } = this.state;
    let carePacksHpInc = productsHpInc.map(product => {
      const serialsArray = [];
      if (product.serials && product.serials.length > 0) product.serials.map(serial => serialsArray.push(serial.serial));
      const formatedCare = {
        sku: product.sku,
        invoiceDate: product.invoiceDate,
        invoiceNumber: product.invoiceNumber,
        serials: serialsArray,
        maker: 'HPInc',
      };
      return formatedCare;
    });
    carePacksHpInc = carePacksHpInc.filter(product => product.invoiceDate && product.invoiceDate.length > 0);

    let carePacksHp = productsHp.map(product => {
      const serialsArray = [];
      if (product.serials && product.serials.length > 0) product.serials.map(serial => serialsArray.push(serial.serial));
      const formatedCare = {
        sku: product.sku,
        invoiceDate: product.invoiceDate,
        invoiceNumber: product.invoiceNumber,
        serials: serialsArray,
        maker: 'HPE',
      };
      return formatedCare;
    });
    carePacksHp = carePacksHp.filter(product => product.invoiceDate && product.invoiceDate.length > 0);

    let carePacksAruba = productsAruba.map(product => {
      const serialsArray = [];
      if (product.serials && product.serials.length > 0) product.serials.map(serial => serialsArray.push(serial.serial));
      const formatedCare = {
        sku: product.sku,
        invoiceDate: product.invoiceDate,
        invoiceNumber: product.invoiceNumber,
        serials: serialsArray,
        maker: 'Aruba',
      };
      return formatedCare;
    });
    carePacksAruba = carePacksAruba.filter(product => product.invoiceDate && product.invoiceDate.length > 0);

    let carePacksApple = productsApple.map(product => {
      const serialsArray = [];
      if (product.serials && product.serials.length > 0) product.serials.map(serial => serialsArray.push(serial.serial));
      const formatedCare = {
        sku: product.sku,
        invoiceDate: product.invoiceDate,
        invoiceNumber: product.invoiceNumber,
        serials: product.series,
        maker: 'Apple',
      };
      return formatedCare;
    });
    carePacksApple = carePacksApple.filter(product => product.invoiceDate && product.invoiceDate.length > 0);

    let careAutodesk = productsAutodesk.map(product => {
      const formatedCare = {
        sku: product.sku,
        startDate: product.invoiceDate,
        endDate: product.invoiceNumber,
        courseType: product.series,
        maker: 'Autodesk',
      };
      return formatedCare;
    });
    careAutodesk = careAutodesk.filter(product => product.invoiceDate && product.invoiceDate.length > 0);

    return (hpe && (!carePacksHp || carePacksHp.length < 1)) ? []
      : [...carePacksHpInc, ...carePacksHp, ...carePacksAruba, ...carePacksApple, ...careAutodesk];
  }

  handleGetGroups = () => {
    const { customerId } = this.props;
    multiBrandServices.getGroupsCustomerId(customerId)
      .then(response => {
        this.setState({ groups: response.groups });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  handleCancelSerials = sku => {
    let { carePacks } = this.state;
    carePacks = carePacks.map(element => {
      if (element.sku === sku) return ({ ...element, option: '' });
      return element;
    });
    this.handleFilterCarePacks(carePacks);
    this.setState({
      carePacks, openSeries: false, openPurchase: false, row: {},
    });
  }

  handleDateChange = ({ target }) => {
    const { productsAutodesk } = this.state;
    const { value, name, id } = target;
    const index = productsAutodesk.findIndex(item => item.sku === name);
    if (id === 'startDate') {
      productsAutodesk[index].startDate = value;
    }
    if (id === 'endDate') {
      productsAutodesk[index].endDate = value;
    }
    this.setState(productsAutodesk);
  };

  handleChangeCourseType = (event, target) => {
    const { productsAutodesk } = this.state;
    const index = productsAutodesk.findIndex(item => item.sku === target.name);
    productsAutodesk[index].courseType = target.value;
    this.setState(productsAutodesk);
  }

  modalViaticsClose = () => this.setState({ modalViatics: false });

  getArrayVeeamSkuPrices = values => {
    let discountArray = values.filter(filter => filter.hasDiscountPrice === true);
    discountArray = discountArray.map(product => {
      const newConfig = {
        sku: product.sku,
        discountPrice: product.discountPrice,
      };
      return newConfig;
    });
    this.setState({ productsDicountVeeamPrices: discountArray, productsVeeam: values });
  }

  handleGetAppleCareConfig = (values, checked) => {
    this.setState({ productsAppleCare: values, disabledButton: checked });
  }

  handleDismiss = () => {
    this.setState({ visibleVeeamError: false });
  }

  handleAddReview = () => {
    const { t } = this.props;
    const { orderId } = this.props;
    const UEN_SERVICES = 51;
    multiBrandServices.addReview(orderId, {
      uen: UEN_SERVICES,
      reason: 'Solicitud de precio DealReg ó NSQ',
    })
      .then(() => {
        this.handleSendReviewNotification();
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleGetReviews = () => {
    const { t, orderId } = this.props;
    multiBrandServices.getReviews(orderId).then(response => {
      const hasVeeamReview = response.orderReviews.some(review => review.uen === 51);
      if (hasVeeamReview) this.setState({ selectDealRegReview: true });
      this.setState({ reviewsArray: response.orderReviews });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      if (error.code && error.code !== 1005) Toast(messageError, 'error');
    });
  }

  handleDeleteReview = async () => {
    const { reviewsArray } = this.state;
    const { t } = this.props;
    if (reviewsArray.length > 0) {
      const veeamReview = reviewsArray.filter(uen => uen.uen === 51);
      if (veeamReview.length > 0) {
        const id = Number(veeamReview.map(idReview => idReview.id));
        try {
          await multiBrandServices.deleteReviewById({ reviewId: id });
        } catch (error) {
          let messageError = '';
          if (!error.code) {
            messageError = t('error-codes.default');
          } else {
            messageError = t(`error-codes.${error.code}`);
          }
          Toast(messageError, 'error');
        }
      }
    }
  }

  handleSendReviewNotification = () => {
    const { t } = this.props;
    const { orderId } = this.props;
    multiBrandServices.sendReviewNotification({ orderId })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleGetAppleCareData = () => {
    const { productsAppleCare, apple } = this.state;
    let joinAll = [];
    if (apple) {
      const serialsAppleCare = [];
      productsAppleCare.map(config => {
        if (config.appleCare.length > 0) {
          serialsAppleCare.push(
            config.appleCare.map(item => item),
          );
        }
        return config;
      });
      joinAll = serialsAppleCare.reduce((accumulatedArray, allSerials) => accumulatedArray.concat(allSerials));
    }
    if (joinAll.length > 0) {
      joinAll = joinAll.map(data => {
        const formattedArray = {
          sku: data.sku,
          serial: data.serial,
          orderNumber: data.orderNumber,
          purchaseDate: data.purchaseDate,
        };
        return formattedArray;
      });
    }
    return joinAll;
  }

  handleChangeItemProps = (sku, field, value) => {
    const { redHatProducts } = this.state;
    const newredHatProducts = redHatProducts.map(product => {
      const matchItem = product.sku === sku;
      if (matchItem) {
        return { ...product, [field]: value };
      }
      return product;
    });

    this.setState({ redHatProducts: newredHatProducts });
  }

  render() {
    const {
      t, isQuotation, saveAsQuote, isGoToPayment, history, addFinalUser,
    } = this.props;
    const {
      modalViatics, productsAutodesk, redHat, veeam, Microsoft, IBM, TRELLIX, finalUserArray, finalUser, finalUserError, email, emailError, phone, phoneError,
      mobile, mobileError, contact, contactError, addressArray, addressError, addressId, loading,
      resellerId, resellerIdError, site, siteError, agreement, agreementError, emailCustomer, emailCustomerError, phoneCustomer,
      phoneCustomerError, contactCustomer, contactCustomerError, opp, oppError, sbNumber, sbNumberError,
      authorizationNum, authorizationNumError, discount, discountError, headers, products, note, noteError, loggin, logginError,
      icn, icnError, start, end, startError, endError, orderNumber, orderNumberError, redHatProducts, emailOwner, emailOwnerError,
      productsHp, headersCarePacks, productsAruba, productsHpInc, openSeries, row, openPurchase, groups,
      orderNumberVeeam, orderNumberVeeamError, productsApple, dealReg, dealRegError, loadingSave, productsVeeam,
      veeamNSQ, veeamNSQError, veeamIdError, visibleVeeamError, veeamDiscountPricesResponse, endUserRelatedProyect,
      partnerLevels, partnerLevelError, partnerLevelId, partnerId, partnerIdError, courseType,
      endUserRelatedProyectError, VMWARE, selectDealRegReview, customerIdLoged, veeamProPartnerID, veeamProPartnerIDError,
      licensingVeeamSelected, veeamContractID, veeamContractIDError, productsAppleCare, appleCareResponse, apple,
      applecareError, disabledButton, imageForm, ShowLicensingVeeam, redhatError,
    } = this.state;
    let showMessageRedHat = this.state;
    if (groups && groups.length > 0 && groups.some(group => group.id === 1)) showMessageRedHat = false;
    return (
      <Grid container>
        <Grid.Row>
          {!loading
            ? (
              <FinalUser
                loading={loading}
                makers={{
                  IBM, TRELLIX, redHat, veeam, VMWARE, apple,
                }}
                email={{ value: email, error: emailError }}
                phone={{ value: phone, error: phoneError }}
                mobile={{ value: mobile, error: mobileError }}
                contact={{ value: contact, error: contactError }}
                loggin={{ value: loggin, error: logginError }}
                relatedEndUser={{ value: endUserRelatedProyect, error: endUserRelatedProyectError }}
                icn={{ value: icn, error: icnError }}
                finalUser={{
                  id: finalUser, array: finalUserArray, error: finalUserError,
                }}
                address={{
                  array: addressArray, error: addressError, id: addressId,
                }}
                imageForm={imageForm}
                handleChangeEmail={this.handleChangeEmail}
                handleChangePhone={this.handleChangePhone}
                handleChangeMobile={this.handleChangeMobile}
                handleChangeContact={this.handleChangeContact}
                handleGetNewAddress={this.handleGetNewAddress}
                handleGetNewUser={this.handleGetNewUser}
                addFinalUser={addFinalUser}
                handleChangeIcn={this.handleChangeIcn}
                handleChangeLoggin={this.handleChangeLoggin}
                handleDate={this.handleDate}
                handleChangeRelatedProyect={this.handleChangeRelatedProyect}
                startDate={{ value: start, error: startError }}
                endDate={{ value: end, error: endError }}
                onItemClickFinalUser={(e, { value }) => {
                  this.setState({ finalUser: value, finalUserError: '' });
                  this.handleAddressByFinalUser(value);
                  this.handleDataFormFinalUserCustomer(value);
                }}
                getNewAddress={this.handleGetNewAddress}
                onItemClickAddress={(e, { value }) => {
                  this.setState({ addressId: value, addressError: '' });
                }}
              />
            ) : null}
        </Grid.Row>
        {loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          ) : null }
        {!loading ? <Divider section /> : null }
        {!loading
          ? (
            <Grid.Row>
              <Customer
                loading={loading}
                makers={{
                  IBM, veeam, redHat, VMWARE,
                }}
                showLicensingVeeam={ShowLicensingVeeam}
                email={{ value: emailCustomer, error: emailCustomerError }}
                phone={{ value: phoneCustomer, error: phoneCustomerError }}
                resellerId={{ value: resellerId, error: resellerIdError }}
                agreement={{ value: agreement, error: agreementError }}
                dealReg={{ value: dealReg, error: dealRegError }}
                veeamNSQ={{ value: veeamNSQ, error: veeamNSQError }}
                site={{ value: site, error: siteError }}
                contact={{ value: contactCustomer, error: contactCustomerError }}
                orderNumber={{ value: orderNumber, error: orderNumberError }}
                orderNumberVeeam={{ value: orderNumberVeeam, error: orderNumberVeeamError }}
                veeamProPartnerID={{ value: veeamProPartnerID, error: veeamProPartnerIDError }}
                veeamContractID={{ value: veeamContractID, error: veeamContractIDError }}
                partnerLevel={{
                  array: partnerLevels, error: partnerLevelError, id: partnerLevelId,
                }}
                partnerId={{ value: partnerId, error: partnerIdError }}
                handleChangePartnerId={this.handleChangePartnerId}
                handleChangeOrderNumber={this.handleChangeOrderNumber}
                handleChangeOrderNumberVeeam={this.handleChangeOrderNumberVeeam}
                handleChangeEmail={this.handleChangeEmailCustomer}
                handleChangePhone={this.handleChangePhoneCustomer}
                handleChangeAgreement={this.handleChangeAgreement}
                handleChangeSite={this.handleChangeSite}
                handleChangeResellerId={this.handleChangeResellerId}
                handleChangeContact={this.handleChangeContactCustomer}
                handleChangeDealReg={this.handleChangeDealReg}
                handleChangeVeeamNSQ={this.handleChangeVeeamNSQ}
                handleSelectDealRegReview={this.handleSelectDealRegReview}
                handleChangeveeamProPartnerID={this.handleChangeveeamProPartnerID}
                handleLicensingVeeam={this.handleLicensingVeeam}
                handleChangeveeamContractID={this.handleChangeveeamContractID}
                onItemClickPartnerLevel={({ target }) => this.setState({ partnerLevelId: target.value, partnerLevelError: '' })}
                selectDealRegReview={selectDealRegReview}
                isQuotation={isQuotation}
                customerId={customerIdLoged}
                licensingVeeamSelected={licensingVeeamSelected}
              />
            </Grid.Row>
          ) : null}
        {!loading && veeam && customerIdLoged === 'G000000'
          ? <Divider section />
          : null}
        { !loading && veeam && customerIdLoged === 'G000000'
          ? (
            <Grid.Row>
              <AddVeeamDiscountPrice
                productsVeeam={productsVeeam}
                getArrayVeeamSkuPrices={values => this.getArrayVeeamSkuPrices(values)}
                veeamIdError={veeamIdError}
                visible={visibleVeeamError}
                handleDismiss={this.handleDismiss}
                veeamDiscountPricesResponse={veeamDiscountPricesResponse}
                tr={t}
              />
            </Grid.Row>
          )
          : null}
        { !loading && apple
          ? (
            <Grid.Row>
              <AddAppleCareSerials
                productsAppleCare={productsAppleCare}
                handleGetAppleCareConfig={(values, checked) => this.handleGetAppleCareConfig(values, checked)}
                applecareError={applecareError}
                // visible={visibleApplecareError}
                handleDismiss={this.handleDismiss}
                appleCareResponse={appleCareResponse}
                history={history}
                tr={t}
              />
            </Grid.Row>
          )
          : null}
        {Microsoft
          ? <Divider section />
          : null}
        {(Microsoft || IBM) && !loading
          ? (
            <Grid.Row>
              <AuthorizationKeys
                makers={{ IBM, Microsoft }}
                opp={{ value: opp, error: oppError }}
                authorizationNum={{ value: authorizationNum, error: authorizationNumError }}
                sbNumber={{ value: sbNumber, error: sbNumberError }}
                handleChangeSBNumber={this.handleChangeSBNumber}
                handleChangeOPP={this.handleChangeOPP}
                handleChangeAuthorizationNumber={this.handleChangeAuthorizationNumber}
                isQuotation={isQuotation}
              />
            </Grid.Row>
          )
          : null}
        {(Microsoft || IBM) && !loading
          ? <Divider section />
          : null}
        {Microsoft && !loading
          ? (
            <Grid.Row>
              <ProductsRestrictions
                maker="Microsoft"
                discount={{ value: discount, error: discountError }}
                headers={headers}
                products={products}
                handleChangeDiscount={this.handleChangeDiscount}
                isQuotation={isQuotation}
              />
            </Grid.Row>
          )
          : null}
        { redHat && !loading
          ? <Divider section />
          : null}
        {redHat && !loading
          ? (
            <Grid.Row>
              <ProductsRestrictions
                maker="Red Hat"
                showMessage={showMessageRedHat}
                discount={{ value: discount, error: discountError }}
                headers={headers}
                products={redHatProducts}
                handleChangeDiscount={this.handleChangeDiscount}
                isQuotation={isQuotation}
                emailOwner={{ value: emailOwner, error: emailOwnerError }}
                handleChangeEmailOwner={this.handleChangeEmailOwner}
                handleChangeItemProps={this.handleChangeItemProps}
                redhatError={redhatError}
              />
            </Grid.Row>
          )
          : null}
        {Microsoft && !loading
          ? <Divider section />
          : null}
        <Grid.Row>
          <AddSerials
            open={openSeries}
            tr={t}
            onClose={sku => this.handleCancelSerials(sku)}
            row={row}
            handleGetSerials={this.handleGetSerials}
          />
        </Grid.Row>
        <Modal open={openPurchase}>
          <Modal.Content>
            <Grid.Row>
              <AppleDeep
                handleGetSerials={this.handleGetSerialsAppleDeep}
                data={{ sku: row.sku, maker: row.maker, quantity: row.quantity }}
                isMultibrand
                limitGuarantees={row.quantity}
                maker={row.maker}
                skuCarePack={row.sku}
                handleCancel={() => this.handleCancelSerials(row.sku)}
              />
            </Grid.Row>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.handleCancelSerials(row.sku)}>{t('cancel')}</Button>
          </Modal.Actions>
        </Modal>
        { productsHp && productsHp.length > 0 && !loading
          ? (
            <Grid.Row>
              <CarePacks maker="HP" headers={headersCarePacks} products={productsHp} />
            </Grid.Row>
          )
          : null}
        { productsHpInc && productsHpInc.length > 0 && !loading
          ? (
            <Grid.Row>
              <CarePacks maker="HP Inc" headers={headersCarePacks} products={productsHpInc} />
            </Grid.Row>
          )
          : null}
        { productsAruba && productsAruba.length > 0 && !loading
          ? (
            <Grid.Row>
              <CarePacks maker="Aruba" headers={headersCarePacks} products={productsAruba} />
            </Grid.Row>
          )
          : null}
        { productsApple && productsApple.length > 0 && !loading
          ? (
            <Grid.Row>
              <CarePacks maker="Apple" headers={headersCarePacks} products={productsApple} />
            </Grid.Row>
          )
          : null}
        {IBM && !loading
          ? (
            <Grid.Row>
              <Note
                note={{ value: note, error: noteError }}
                handleChangeNote={this.handleChangeNote}
                isQuotation={isQuotation}
              />
            </Grid.Row>
          )
          : null}
        {!loading && productsAutodesk
          ? <Divider section />
          : null}
        { !loading && productsAutodesk && productsAutodesk.length > 0 && !loading
          ? (
            <AutoDesk
              courseType={courseType}
              productsAutodesk={productsAutodesk}
              physicalCourse={false}
              modalViatics={modalViatics}
              modalViaticsClose={this.modalViaticsClose}
              modalViaticsOpen={this.modalViaticsClose}
              handleChangeCourseType={this.handleChangeCourseType}
              handleDateChange={this.handleDateChange}
              t={t}
            />
          )
          : null}
        {IBM && !loading
          ? <Divider section />
          : null}
        {!loading && !isQuotation
          ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={7}>
                <Button fluid loading={loadingSave} disabled={apple ? !disabledButton : loadingSave} onClick={() => this.handleDataEmpty(false)} color="blue">
                  {' '}
                  {t('save')}
                  {' '}
                </Button>
              </Grid.Column>
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} />
              {!loading && isGoToPayment
                ? (
                  <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={7}>
                    <Button fluid loading={loadingSave} disabled={apple ? !disabledButton : loadingSave} onClick={() => this.handleDataEmpty(true)} color="green">
                      {' '}
                      {t('Proceder al pago')}
                      {' '}
                    </Button>
                  </Grid.Column>
                )
                : null}
              {!loading && saveAsQuote
                ? (
                  <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={7}>
                    <Button fluid loading={loadingSave} disabled={apple ? !disabledButton : loadingSave} onClick={() => this.handleDataEmpty(false)}>
                      {' '}
                      {t('Guardar como cotizacion')}
                      {' '}
                    </Button>
                  </Grid.Column>
                )
                : null}
            </Grid.Row>
          )
          : null}
      </Grid>
    );
  }
}

MultiBrandData.defaultProps = {
  orderId: '',
  finalUser: '',
  customerId: '',
  arrayBrand: [],
  productsByMaker: {
    microsoft: [],
    redHat: [],
    autodesk: [],
    carePacks: [],
    veeam: [],
    appleCare: [],
  },
  formId: null,
  isQuotation: false,
  onClose: () => {},
  handleGoToPayment: () => {},
  addFinalUser: () => {},
  handleSaveQuotation: () => {},
  doNotShowWarning: PropTypes.bool,
};

MultiBrandData.propTypes = {
  arrayBrand: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  customerId: PropTypes.string,
  finalUser: PropTypes.string,
  formId: PropTypes.func,
  handleGoToPayment: PropTypes.func,
  addFinalUser: PropTypes.func,
  handleSaveQuotation: PropTypes.func,
  isGoToPayment: PropTypes.bool.isRequired,
  isQuotation: PropTypes.bool,
  onClose: PropTypes.func,
  orderId: PropTypes.string,
  productsByMaker: PropTypes.shape({
    autodesk: PropTypes.arrayOf(PropTypes.shape({})),
    carePacks: PropTypes.arrayOf(PropTypes.shape({})),
    microsoft: PropTypes.arrayOf(PropTypes.shape({})),
    redHat: PropTypes.arrayOf(PropTypes.shape({})),
    veeam: PropTypes.arrayOf(PropTypes.shape({})),
    appleCare: PropTypes.arrayOf(PropTypes.shape({})),
    vmware: PropTypes.arrayOf(PropTypes.shape({})),
    trellix: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  saveAsQuote: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  savedForm: PropTypes.func.isRequired,
  doNotShowWarning: false,
};

export default translate('common', { wait: true })(MultiBrandData);
