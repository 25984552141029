import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { translate } from 'react-i18next';
import { Grid, Button } from 'semantic-ui-react';
import { TextField, LinearLoader } from '../../../common/components/materials';
import Toast from '../../../common/components/toast';
import ClientTable from './client-table';
import service from '../../services';

function ClientCount(props) {
  const {
    handleAdd, t, categories, handleDelete: handleFatherDelete, clientNumbers, customerId, year, loadDone, activeLoader,
  } = props;

  const [state, setState] = useState({
    categoryId: null,
    itemId: null,
    description: null,
    categoryIdError: '',
    descriptionError: '',
  });

  function cleanErrors() {
    setState({
      ...state,
      categoryIdError: '',
      descriptionError: '',
    });
  }

  function cleanState() {
    setState({
      categoryId: null,
      itemId: null,
      description: null,
      categoryIdError: '',
      descriptionError: '',
    });
  }

  function isValidBody() {
    const { description, categoryId } = state;
    const errors = {};
    if (!categoryId) {
      errors.categoryIdError = t('clientPlan.errors.empty');
    }
    if (!description) {
      errors.descriptionError = t('clientPlan.errors.empty');
    } else if (Number(description) < 0) {
      errors.percentError = t('clientPlan.errors.integer');
    }
    if (Object.keys(errors).length > 0) {
      setState({ ...state, ...errors });
      return false;
    }
    cleanErrors();
    return true;
  }

  function addClientCount() {
    const { description, categoryId } = state;
    const body = {
      customerId, year, categoryId, description,
    };
    if (!isValidBody()) return;
    activeLoader();
    service.addBusinessPlanItem(body)
      .then(response => {
        handleAdd({ ...body, id: response.id });
        cleanState();
      })
      .catch(({ code }) => {
        if (code === callError.general.INVALID_REQUEST) {
          Toast(t('clientPlan.errors.add'), 'error');
        }
        handleAdd();
      });
  }

  function updateClientCount() {
    const { description, itemId, categoryId } = state;
    const body = { itemId, description };
    if (!isValidBody()) return;
    activeLoader();
    service.updateBusinessPlanItem(body)
      .then(() => {
        handleAdd({ description, categoryId, itemId });
        cleanState();
      })
      .catch(() => {
        Toast(t('clientPlan.errors.update'), 'error');
        handleAdd();
      });
  }

  const handleSave = () => {
    if (!state.itemId) addClientCount();
    else updateClientCount();
  };

  const handleChange = ({ target }) => {
    const { value, id, name } = target;
    const nameOrId = id || name;
    setState({ ...state, [nameOrId]: value, [`${nameOrId}Error`]: '' });
  };

  const handleUpdate = element => {
    const { categoryId, description, id } = element;
    setState({
      ...state, categoryId, description, itemId: id,
    });
  };

  const handleDelete = ({ id }) => {
    if (!id) return;
    activeLoader();
    service.deleteBusinessPlanItem(id)
      .then(({ status }) => {
        if (!status) return;
        handleFatherDelete(id);
      })
      .catch(() => {
        Toast(t('clientPlan.errors.delete'), 'error');
        handleFatherDelete();
      });
  };

  const onKeyPress = e => {
    if (e.which === 13) {
      handleSave();
    }
  };

  return (
    <Grid>
      <Grid.Row className="no-padding-bottom">
        <Grid.Column
          largeScreen={7}
          computer={7}
          tablet={7}
          mobile={16}
        >
          <TextField
            id="categoryId"
            name="categoryId"
            select
            fullWidth
            error={state.categoryIdError || ''}
            label="Categoría"
            disabled={state.itemId}
            value={state.categoryId || ''}
            onChange={handleChange}
            options={categories}
          />
        </Grid.Column>
        <Grid.Column
          onKeyPress={onKeyPress}
          largeScreen={7}
          computer={7}
          tablet={7}
          mobile={14}
        >
          <TextField
            id="description"
            fullWidth
            type="number"
            inputProps={{ min: 0, step: 1 }}
            label="Valor"
            error={state.descriptionError || ''}
            value={state.description || ''}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column
          largeScreen={2}
          computer={2}
          tablet={2}
          mobile={2}
          verticalAlign="middle"
        >
          <Button onClick={handleSave}>{state.itemId ? t('edit') : t('add')}</Button>
        </Grid.Column>
      </Grid.Row>
      {
        loadDone
          ? (
            <Grid.Row className="no-padding-y">
              <Grid.Column>
                <LinearLoader color="yellow" />
              </Grid.Column>
            </Grid.Row>
          )
          : null
      }
      <Grid.Row className="no-padding-top">
        <Grid.Column
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
          verticalAlign="middle"
        >
          <ClientTable t={t} clientNumbers={clientNumbers} handleUpdate={handleUpdate} handleDelete={handleDelete} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ClientCount.defaultProps = {
  categories: [],
  clientNumbers: [],
  t: null,
  handleAdd: () => { },
  customerId: '',
  year: 2021,
  loadDone: false,
  activeLoader: () => { },
  handleDelete: () => { },
};

ClientCount.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  clientNumbers: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  handleAdd: PropTypes.func,
  customerId: PropTypes.string,
  year: PropTypes.number,
  activeLoader: PropTypes.func,
  handleDelete: PropTypes.func,
  loadDone: PropTypes.bool,
};

export default translate('common', { wait: true })(ClientCount);
