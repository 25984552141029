import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Button, Grid, GridRow, Header, Icon, Confirm,
} from 'semantic-ui-react';
import validator from 'validator';
import deliveryAddress from '../services';
import { TextField } from '../../common/components/materials';
import TableManeuver from '../../common/components/table';
import Toast from '../../common/components/toast';

class DetailManeuver extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maneuver: '',
      maneuverError: '',
      addManeuver: [],
      headers: [
        { key: 'id', isKey: true },
        { key: 'type', name: 'Tipo' },
        { key: 'maneuver', name: 'Maniobra' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <GridRow>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
            </GridRow>
          ), // eslint-dsable-line
        },
      ],
      maneuverType: [
        { value: 1, label: 'Rampa' },
        { value: 2, label: 'Patio' },
        { value: 3, label: 'Andén' },
        { value: 4, label: 'Otro' },
        { value: 5, label: 'Arrastre' },
        { value: 6, label: 'Montacargas' },
        { value: 7, label: 'Patín' },
        { value: 8, label: 'Descarga' },
        { value: 9, label: 'Entrega a detalle' },
        { value: 10, label: 'Entrega en feria de comercio' },
        { value: 11, label: 'Acceso limitado' },
        { value: 12, label: 'Llamar antes de entregar' },
        { value: 13, label: 'Ordenamiento y clasificación de las piezas (costo adicional)' },
        { value: 14, label: 'Entrega interna' },
        { value: 15, label: 'Servicio compuerta mecánica' },
      ],
      maneuverTypeBy: '',
      messageTable: '',
      maneuverId: '',
      maneuversSelected: [],
      disabledDelete: true,
      disabled: false,
      clean: false,
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    if (id.length > 1) this.handleGetManeuvers();
  }

  handleGetManeuvers = async () => {
    const { t, id } = this.props;
    const arrayMeneuvers = await deliveryAddress.getManeuver(id)
      .catch(error => {
        if (!error.code) {
          Toast(t('error-codes.default'), 'error');
        } else if (Number(error.code) === 1005 || (Number(error.code) === 1000)) {
          this.setState({ messageTable: t('branchOffice.message.table') });
        } else {
          Toast(t(`error-codes.${error.code}`), 'error');
        }
      });
    if (arrayMeneuvers && arrayMeneuvers.maneuvers) this.setState({ addManeuver: arrayMeneuvers.maneuvers });
  }

  handleAddManeuver = () => {
    const { t, id } = this.props;
    const {
      addManeuver, maneuver, maneuverType, maneuverTypeBy,
    } = this.state;
    const body = {
      maneuverTypeId: maneuverTypeBy,
      maneuver,
    };
    const { label } = maneuverType.find(item => item.value === maneuverTypeBy);
    deliveryAddress.addManeuver(id, body).then(response => {
      addManeuver.push({ id: response.id, type: label, maneuver });
      Toast(t('branchOffice.message.confirmManeuverAdd'), 'success');
      this.setState({ addManeuver, maneuver: '', maneuverTypeBy: '' });
    })
      .catch(error => {
        if (!error.code) {
          Toast(t('error-codes.default'), 'error');
        } else if (Number(error.code) === 1005 && Number(error.code) === 1000) {
          this.setState({ messageTable: t('branchOffice.message.table') });
        } else {
          Toast(t(`error-codes.${error.code}`), 'error');
        }
      });
  };

  handleManeuverChange = event => {
    const { t } = this.props;
    const maxLength = 50;
    const minLength = 4;
    const { value: maneuver } = event.target;
    if (maneuver && maneuver > 0) {
      if (!validator.isAlphanumeric(maneuver.replace(/ /g, ''), ['es-ES'])) { this.setState({ maneuver, maneuverError: t('branchOffice.errors.notManeuver') }); return; }
      if (maneuver.length > maxLength) { this.setState({ maneuver, maneuverError: t('branchOffice.errors.toLong') }); return; }
      if (maneuver.length < minLength) { this.setState({ maneuver, maneuverError: t('branchOffice.errors.toShort') }); return; }
    }
    this.setState({ maneuver, maneuverError: '' });
  }

  validations = action => {
    let mistakes = false;
    let { maneuverError, maneuverTypeError } = this.state;
    const { maneuver, maneuverTypeBy } = this.state;
    const { t } = this.props;

    if (validator.isEmpty(maneuver) || (maneuver.trim()).length === 0) {
      maneuverError = t('branchOffice.errors.empty');
      mistakes = true;
    }
    if (!maneuverTypeBy) {
      maneuverTypeError = t('branchOffice.errors.empty');
      mistakes = true;
    }
    if (!validator.isEmpty(maneuverError)) mistakes = true;
    if (!validator.isEmpty(maneuverTypeError)) mistakes = true;
    if (mistakes === false) {
      if (action === 1) this.handleAddManeuver();
      if (action === 2) this.handleUpdateManeuver();
    } else {
      this.setState({ maneuverError, maneuverTypeError });
    }
  }

  handleClickUpdate = maneuver => {
    const maneuverTypeBy = Number((maneuver.type)
      .replace('Rampa', 1)
      .replace('Patio', 2)
      .replace('Andén', 3)
      .replace('Otro', 4)
      .replace('Arrastre', 5)
      .replace('Montacargas', 6)
      .replace('Patín', 7)
      .replace('Descarga', 8)
      .replace('Entrega a detalle', 9)
      .replace('Entrega en feria de comercio', 10)
      .replace('Acceso limitado', 11)
      .replace('Llamar antes de entregar', 12)
      .replace('Ordenamiento y clasificación de las piezas (costo adicional)', 13)
      .replace('Andén', 14)
      .replace('Otro', 15));
    this.setState({
      disabled: true,
      maneuverError: '',
      maneuverTypeError: '',
      maneuver: maneuver.maneuver,
      maneuverTypeBy,
      maneuverId: maneuver.id,
    });
  }

  handleSelectManeuver = maneuver => {
    if (maneuver.length === 1) {
      const maneuverTypeBy = Number((maneuver[0].type)
        .replace('Rampa', 1)
        .replace('Patio', 2)
        .replace('Andén', 3)
        .replace('Otro', 4)
        .replace('Arrastre', 5)
        .replace('Montacargas', 6)
        .replace('Patín', 7)
        .replace('Descarga', 8)
        .replace('Entrega a detalle', 9)
        .replace('Entrega en feria de comercio', 10)
        .replace('Acceso limitado', 11)
        .replace('Llamar antes de entregar', 12)
        .replace('Ordenamiento y clasificación de las piezas (costo adicional)', 13)
        .replace('Andén', 14)
        .replace('Otro', 15));
      this.setState({
        maneuverError: '',
        maneuverTypeError: '',
        maneuversSelected: maneuver,
        maneuver: maneuver[0].maneuver,
        maneuverTypeBy,
        maneuverId: maneuver[0].id,
        disabled: true,
        disabledDelete: false,
      });
    } else if (maneuver.length > 1) {
      this.setState({
        maneuverError: '',
        maneuverTypeError: '',
        disabled: false,
        disabledDelete: false,
        maneuverTypeBy: '',
        maneuver: '',
        maneuversSelected: maneuver,
      });
    } else {
      this.setState({
        maneuverError: '',
        maneuverTypeError: '',
        disabled: false,
        disabledDelete: true,
        maneuverTypeBy: '',
        maneuver: '',
      });
    }
  }

  handleUpdateManeuver = () => {
    const { t, id } = this.props;
    const {
      maneuver, maneuverTypeBy, maneuverId, addManeuver: stateAddManeuver,
    } = this.state;
    const body = {
      maneuverTypeId: maneuverTypeBy,
      maneuver,
    };
    deliveryAddress.addManeuver(id, body).then(() => {
      const addManeuver = stateAddManeuver.map(currentItem => {
        const item = { ...currentItem };
        if (Number(item.id) === Number(maneuverId)) item.maneuver = maneuver;
        return item;
      });
      Toast(t('branchOffice.message.confirmManeuverUpdate'), 'success');
      this.setState({
        disabled: false, clean: true, addManeuver, maneuver: '', maneuverTypeBy: '',
      });
    })
      .catch(error => {
        if (!error.code) {
          Toast(t('error-codes.default'), 'error');
        } else if (Number(error.code) === 1005) {
          this.setState({ messageTable: t('tableEmpty') });
        } else {
          Toast(t(`error-codes.${error.code}`), 'error');
        }
      });
  }

  handleDeleteManeuvers = () => {
    const { maneuversSelected } = this.state;
    if (maneuversSelected.length >= 1) this.setState({ open: true });
  }

  handleConfirm = () => {
    const { maneuversSelected } = this.state;
    const { id } = this.props;

    maneuversSelected.map(currentManeuver => {
      const maneuver = { ...currentManeuver };
      deliveryAddress.deleteManeuver(id, maneuver.id).then(() => {
        this.handleUpdateManeuversArray(maneuver.id);
      }).catch(error => {
        maneuver.error = error;
        Toast(`Ocurrio un error al eliminar el tipo de maniobra: ${maneuver.type}, favor de intentarlo de nuevo`, 'error');
      });
      return maneuver;
    });

    this.setState({
      maneuverTypeBy: '',
      maneuverError: '',
      maneuverTypeError: '',
      clean: true,
      maneuver: '',
      disabled: false,
      disabledDelete: true,
      open: false,
      maneuversSelected: [],
    });
  }

  handleUpdateManeuversArray = id => {
    const { addManeuver } = this.state;
    const { t } = this.props;
    const maneuverIndex = addManeuver.findIndex(maneuver => maneuver.id === id);
    addManeuver.splice(maneuverIndex, 1);
    this.setState(addManeuver);
    Toast(t('branchOffice.message.confirmManeuverDelete'), 'success');
  }

  handleCancel = () => this.setState({ open: false, maneuversSelected: [], clean: true });

  handleClickCancelUpdate = () => this.setState({
    maneuverTypeBy: '',
    maneuverError: '',
    maneuverTypeError: '',
    clean: true,
    maneuver: '',
    disabled: false,
    disabledDelete: true,
    open: false,
    maneuversSelected: [],
  });

  render() {
    const { t } = this.props;
    const {
      headers, maneuver, maneuverType, maneuverTypeBy, maneuverError, maneuversSelected,
      maneuverTypeError, addManeuver, messageTable, disabled, clean, disabledDelete, open,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row columns={3}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h3">{t('branchOffice.title3')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={16}>
            <TextField
              select
              fullWidth
              label={t('branchOffice.maneuverType')}
              disabled={disabled}
              value={maneuverTypeBy || ''}
              error={maneuverTypeError || ''}
              onChange={({ target }) => this.setState({ maneuverTypeBy: target.value, maneuverTypeError: '' })}
              options={maneuverType}
              required
            />
          </Grid.Column>
          <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={16}>
            <TextField
              fullWidth
              maxLength={50}
              value={maneuver || ''}
              error={maneuverError || ''}
              label={t('branchOffice.maneuver')}
              onChange={this.handleManeuverChange}
              required
            />
          </Grid.Column>
          {!disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
                <Button color="blue" onClick={() => this.validations(1)} disabled={disabled}>{t('add')}</Button>
              </Grid.Column>
            )
            : null}
          {disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={7}>
                <Button color="blue" content={t('update')} onClick={() => this.validations(2)} />
              </Grid.Column>
            )
            : null}
          {disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
                <Button color="blue" content={t('cancel')} onClick={this.handleClickCancelUpdate} />
              </Grid.Column>
            )
            : null}
          <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={2}>
            <Button color="blue" disabled={disabledDelete} icon onClick={this.handleDeleteManeuvers}>
              <Icon name="trash alternate" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <GridRow>
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16}>
            <TableManeuver
              headers={headers}
              data={addManeuver}
              noDataMessage={messageTable}
              getSelectedRows={this.handleSelectManeuver}
              clean={clean}
              updateClean={() => this.setState({ clean: !clean })}
            />
          </Grid.Column>
        </GridRow>
        <Grid.Row>
          <Confirm
            open={open}
            header={t('branchOffice.deleteManeuver')}
            content={maneuversSelected.map(item => <p>{item.type}</p>)}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

DetailManeuver.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default translate('common', { wait: true })(DetailManeuver);
