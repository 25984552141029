import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const adminServices = {};

adminServices.getAllListProducts = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

adminServices.uploadCatalogImage = productData => (
  request({
    route: apiRoutes.uploadCatalogImage(),
    method: 'PUT',
    body: productData,
  })
);

adminServices.getAllImages = sku => (
  request({
    route: apiRoutes.getAllImages(sku),
    method: 'GET',
  })
);

adminServices.dropImage = sku => (
  request({
    route: apiRoutes.dropImage(sku),
    method: 'DELETE',
  })
);

adminServices.updateMainImage = mainImageBody => (
  request({
    route: apiRoutes.updateMainImage(),
    method: 'PUT',
    body: mainImageBody,
  })
);

adminServices.getProductDescription = sku => (
  request({
    route: apiRoutes.getProductDescription(sku),
    method: 'GET',
  })
);

adminServices.addProductDescription = description => (
  request({
    route: apiRoutes.addProductDescription(),
    method: 'POST',
    body: description,
  })
);

adminServices.getPromotionalMessage = sku => (
  request({
    route: apiRoutes.getPromotionalMessage(sku),
    method: 'GET',
  })
);

adminServices.addPromotionalMessage = message => (
  request({
    route: apiRoutes.addPromotionalMessage(),
    method: 'POST',
    body: message,
  })
);

adminServices.addProductsSpecifications = attributes => (
  request({
    route: apiRoutes.addProductsSpecifications(),
    method: 'PUT',
    body: attributes,
  })
);

adminServices.updateProductsSpecificationsById = attributes => (
  request({
    route: apiRoutes.updateProductsSpecificationsById(),
    method: 'PUT',
    body: attributes,
  })
);

adminServices.getProductsSpecificationsBySku = sku => request({
  route: apiRoutes.getProductsSpecificationsBySku(sku),
  method: 'GET',
});

adminServices.disableProductsSpecificationsById = itemId => (
  request({
    route: apiRoutes.disableProductsSpecificationsById(),
    method: 'PUT',
    body: itemId,
  })
);

adminServices.addInternRelated = (sku, body) => (
  request({
    route: apiRoutes.addInternRelated(sku),
    method: 'POST',
    body,
  })
);

adminServices.getInternRelated = sku => request({
  route: apiRoutes.getInternRelated(sku),
  method: 'GET',
});

adminServices.deleteInternRelated = id => (
  request({
    route: apiRoutes.deleteInternRelated(id),
    method: 'DELETE',
  })
);

adminServices.getServicesAccounts = () => request({
  route: apiRoutes.getServiceAccounts(),
  method: 'GET',
});

adminServices.disableServiceAccount = customerId => (
  request({
    route: apiRoutes.disableServiceAcount(customerId),
    method: 'DELETE',
  })
);

adminServices.addServiceAccount = customerId => (
  request({
    route: apiRoutes.addServiceAccount(customerId),
    method: 'POST',
  })
);

adminServices.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

adminServices.requestAllBrands = () => (
  request({
    route: apiRoutes.getBrands(),
    method: 'GET',
  })
);

adminServices.getItemsByBrand = brand => (
  request({
    route: apiRoutes.getItemsContentByBrand(brand),
    method: 'GET',
  })
);

adminServices.syncContentItems = (contentSource, { syncBy, syncValue, fields }) => (
  request({
    route: apiRoutes.syncItemsByContent(),
    method: 'POST',
    body: {
      [syncBy]: syncValue,
      contentSource,
      contentElements: fields,
    },
  })
);

adminServices.getItemContentBySource = (sku, brand, source) => (
  request({
    route: apiRoutes.getItemContentBySource(sku, brand, source),
    method: 'GET',
  })
);

adminServices.getItemContent = sku => (
  request({
    route: apiRoutes.getItemContent(sku),
    method: 'GET',
  })
);

adminServices.searchItemSuggestions = keyword => (
  request({
    route: apiRoutes.searchSuggestions(keyword),
    method: 'GET',
  })
);

adminServices.updateItemContent = item => (
  request({
    route: apiRoutes.updateItemContent(),
    method: 'PUT',
    body: item,
  })
);

adminServices.generateSignedUrl = files => (
  request({
    route: apiRoutes.generateSignedUrl(),
    method: 'POST',
    body: { files },
  })
);

adminServices.uploadImage = (signedUrl, file) => {
  const image = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');
  return fetch(signedUrl, {
    method: 'PUT',
    body: image,
    headers: {
      Accept: '*/*',
      'content-type': 'image/jpeg',
      'Content-Encoding': 'base64',
    },
  });
};

adminServices.addImages = images => (
  request({
    route: apiRoutes.addImage(),
    method: 'POST',
    body: images,
  })
);

adminServices.updateImages = payload => (
  request({
    route: apiRoutes.addImage(),
    method: 'PUT',
    body: payload,
  })
);

adminServices.deleteImage = imageId => (
  request({
    route: apiRoutes.removeImage(imageId),
    method: 'DELETE',
  })
);

adminServices.searchItem = sku => (
  request({
    route: apiRoutes.search(`?sku=${sku}`),
    method: 'GET',
  })
);

adminServices.getItemBySku = sku => (
  request({
    route: apiRoutes.getProductCatalogBySku(sku),
    method: 'GET',
  })
);

adminServices.getCatalogAttributes = attribute => (
  request({
    route: apiRoutes.getCatalogAttributes(attribute),
    method: 'GET',
  })
);

adminServices.getItemsByAttribute = (attribute, value) => (
  request({
    route: apiRoutes.getItemsByAttribute(attribute, value),
    method: 'GET',
  })
);

adminServices.getAttributesPerBrand = (brand, attribute) => (
  request({
    route: apiRoutes.getAttributesPerBrand(brand, attribute),
    method: 'GET',
  })
);

export default adminServices;
