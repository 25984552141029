import React, { useState } from 'react';
import { Popup, Button, List } from 'semantic-ui-react';
import { translate } from 'react-i18next';

function Help(props) {
  const { t } = props;
  const [open, setOpen] = useState(false);

  function Content() {
    return (
      <List bulleted>
        <List.Header as="h5">{t('wishList.buyNow.help.header')}</List.Header>
        <List.Item>{t('wishList.buyNow.help.list.one')}</List.Item>
        <List.Item>{t('wishList.buyNow.help.list.two')}</List.Item>
        <List.Item>{t('wishList.buyNow.help.list.three')}</List.Item>
        <List.Item>{t('wishList.buyNow.help.list.four')}</List.Item>
      </List>
    );
  }

  return (
    <Popup
      content={<Content />}
      on="hover"
      position="top left"
      inverted
      size="small"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      openOnTriggerClick
      open={open}
      trigger={(
        <Button
          floated="left"
          icon="help circle"
          primary
        />
      )}
    />
  );
}

export default translate('common', { wait: true })(Help);
