import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import {
  Segment,
  Grid,
  Header,
  Container,
  Divider,
  Button,
} from 'semantic-ui-react';

import ModalEditContentSource from './modal-edit-content-source';
import ContentBySource from './content-by-source';
import ItemOptions from './item-options';
import ItemLoader from './item-loader';
import MenuBar from '../menu-bar';

import Toast from '../../../common/components/toast';
import { CONTENT_SOURCE } from '../../constants';
import service from '../../services';

const translatePath = 'productConfig.menus.editContent';

function ProductContent({ t }) {
  const [saveNewContentLoader, setSaveNewContentLoader] = useState(false);
  const [currentItemLoader, setCurrentItemLoader] = useState(false);
  const [icecatItemLoader, setIcecatItemLoader] = useState(false);
  const [openEditContent, setOpenEditContent] = useState(false);
  const [erpItemLoader, setErpItemLoader] = useState(false);
  const [icecatContent, setIcecatContent] = useState(null);
  const [item, setItem] = useState({ sku: '', brand: '' });
  const [itemContent, setItemContent] = useState(null);
  const [erpContent, setErpContent] = useState(null);

  const getCurrentItemContent = async sku => {
    try {
      setCurrentItemLoader(true);
      const { items } = await service.getItemContent(sku);
      setItemContent(items[0]);
    } catch (error) {
      setItemContent(null);
    } finally {
      setCurrentItemLoader(false);
    }
  };

  const getCurrentIcecatContent = async (sku, brand) => {
    try {
      setIcecatItemLoader(true);
      const items = await service.getItemContentBySource(
        sku,
        brand,
        CONTENT_SOURCE.CONTENT_PROVIDER,
      );
      setIcecatContent(items);
    } catch (error) {
      setIcecatContent(null);
    } finally {
      setIcecatItemLoader(false);
    }
  };

  const getCurrentErpContent = async (sku, brand) => {
    try {
      setErpItemLoader(true);
      const items = await service.getItemContentBySource(
        sku,
        brand,
        CONTENT_SOURCE.ERP,
      );
      setErpContent(items);
    } catch (error) {
      setErpContent(null);
    } finally {
      setErpItemLoader(false);
    }
  };

  const getItemContents = async (sku, brand) => {
    await getCurrentItemContent(sku);
    await getCurrentIcecatContent(sku, brand);
    await getCurrentErpContent(sku, brand);
  };

  const handleSelectNewMainImage = async imageId => {
    try {
      const newImageConfig = itemContent.images.map(image => {
        const changedImage = image.value.id === imageId;
        return {
          source: image.source,
          value: { ...image.value, isMain: changedImage },
        };
      });
      const newItemContent = { ...itemContent, images: newImageConfig };
      await service.updateItemContent(newItemContent);
      setItemContent(newItemContent);
      Toast(t('toast.success.general'), 'success');
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
      setItemContent(itemContent);
    }
  };

  const handleItemLoader = (Component, loader, itemInfo) => {
    if (!loader && itemInfo) {
      return (
        <Component
          item={itemInfo}
          changeMainImage={handleSelectNewMainImage}
        />
      );
    }
    return loader && !itemInfo
      ? <ItemLoader />
      : <Container fluid>{t(`${translatePath}.noContent`)}</Container>;
  };

  const handleSaveItemContent = async newItemContent => {
    try {
      setSaveNewContentLoader(true);
      await service.updateItemContent(newItemContent);
      setItemContent(newItemContent);
      Toast(t('toast.success.general'), 'success');
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
      setItemContent(itemContent);
    } finally {
      setSaveNewContentLoader(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sku = params.get('sku');
    const brand = params.get('brand');
    if (sku && brand) {
      setItem({ sku, brand });
      getItemContents(sku, brand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t(`${translatePath}.title`)}</Header>
          <Divider />
          <p>{t(`${translatePath}.description`)}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment style={{ backgroundColor: '#F5F5F5' }}>
            <Grid
              style={{
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 'auto',
              }}
            >
              <Grid.Row>
                <MenuBar
                  item={item}
                  setItemContent={getItemContents}
                  setItem={setItem}
                  loading={currentItemLoader}
                />
              </Grid.Row>
              <Grid.Row>
                <Container fluid>
                  <Segment>
                    <Header floated="left" as="h4">{t(`${translatePath}.contentOptions.current`)}</Header>
                    <Button
                      primary
                      size="mini"
                      floated="right"
                      disabled={!itemContent}
                      content={t(`${translatePath}.editButton`)}
                      onClick={() => setOpenEditContent(true)}
                    />
                    <Divider clearing />
                    {handleItemLoader(ItemOptions, currentItemLoader, itemContent)}
                  </Segment>
                </Container>
              </Grid.Row>
              <Grid.Row>
                <Container fluid>
                  <Segment>
                    <Header as="h4">{t(`${translatePath}.contentOptions.contentProvider`)}</Header>
                    <Divider clearing />
                    {handleItemLoader(ContentBySource, icecatItemLoader, icecatContent)}
                  </Segment>
                </Container>
              </Grid.Row>
              <Grid.Row>
                <Container fluid>
                  <Segment>
                    <Header as="h4">{t(`${translatePath}.contentOptions.erp`)}</Header>
                    <Divider clearing />
                    {handleItemLoader(ContentBySource, erpItemLoader, erpContent)}
                  </Segment>
                </Container>
              </Grid.Row>
            </Grid>
            {itemContent && (
              <ModalEditContentSource
                open={openEditContent}
                loading={saveNewContentLoader}
                onClose={() => setOpenEditContent(false)}
                onSaveItemContent={handleSaveItemContent}
                currentContent={itemContent}
                icecatContent={icecatContent || {}}
                erpContent={erpContent || {}}
              />
            )}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(ProductContent);
