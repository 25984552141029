import React, { Component } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import cookie from 'js-cookie';
import {
  Grid, Icon, Button, Confirm, Header, Dropdown, Divider,
} from 'semantic-ui-react';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import Toast from '../common/components/toast';
import Group from './group';
import groupsService from './services';

class AdminGroups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayGroups: [],
      filter: '',
      filterError: '',
      options: [
        { key: 1, text: 'Clientes', value: 1 },
        { key: 2, text: 'Articulos', value: 2 },
      ],
      headersGroups: [
        { key: 'id', isKey: true },
        { key: 'group', name: 'Grupo' },
        { key: 'description', name: 'Descripción' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button icon="trash alternate" onClick={() => this.handleClickDelete(row)} />
            </Grid.Row>
          ), // eslint-dsable-line
        },
      ],
      groupName: '',
      error: '',
      groupId: '',
      groupType: 1,
      description: '',
      descriptionError: '',
      filteredGroups: [],
    };
  }

  componentDidMount = () => {
    this.handleGetGroups(1);
  }

  handleGetGroups = groupType => {
    cookie.set('element', 'Grupos', { expires: 1 });
    groupsService.getGroups(groupType).then(response => this.setState({ arrayGroups: response.groups, filteredGroups: response.groups }))
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        if (error.code !== 1005 && error.code !== 1005) Toast(messageError, 'error');
      });
  }

  handleClickDelete = group => this.setState({ groupId: group.id, groupName: group.group, open: true });

  handleConfirmDelete = () => {
    const { t } = this.props;
    const {
      groupType, groupId, arrayGroups, filter,
    } = this.state;
    groupsService.deleteGroup(groupType, groupId).then(response => {
      if (response.id) {
        arrayGroups.map((group, index) => {
          if (Number(group.id) === Number(groupId)) {
            arrayGroups.splice(index, 1);
            Toast(t('groups.groupDeleted'), 'success');
          }
          return group;
        });
        this.setState({ arrayGroups, open: false });
        this.handleFilterChange(filter, false, arrayGroups);
      }
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleClickAdd = () => this.setState({
    openAddGroup: true,
    groupId: '',
    groupName: '',
    description: '',
  });

  handleClickUpdate = group => {
    this.setState({
      openAddGroup: true,
      groupId: group.id,
      groupName: group.group,
      description: group.description,
    });
  }

  handleClickSave = () => {
    const { t } = this.props;
    const {
      groupId, arrayGroups, groupName, description,
    } = this.state;
    let { error, descriptionError } = this.state;
    let nameExist = '';
    if (arrayGroups && arrayGroups.length > 0) nameExist = arrayGroups.find(member => member.group === groupName);
    let mistakes = false;
    let message = '';

    if (!groupName || (groupName.replace(/ /g, '')).length < 1) error = t('groups.errors.empty');
    if (!description || (description.replace(/ /g, '')).length < 1) descriptionError = t('groups.errors.empty');

    if (!error && !descriptionError) {
      if (!groupId && !nameExist) this.handleAddGroups();
      if (!groupId && nameExist) mistakes = true;
      message = t('groups.warningGroupExist');
      if (groupId) {
        arrayGroups.map(currentGroup => {
          const group = { ...currentGroup };
          if ((group.id === groupId) && (group.group === groupName)) {
            group.group = groupName;
            group.description = description;
            this.handleUpdateGroups(arrayGroups);
          }
          if ((group.id === groupId) && !nameExist) {
            group.group = groupName;
            group.description = description;
            this.handleUpdateGroups(arrayGroups);
          }
          if ((group.id === groupId) && (group.group !== groupName) && nameExist) {
            mistakes = true;
            message = t('groups.warningGroupExist');
          }
          return group;
        });
      }
    } else {
      this.setState({ error, descriptionError });
    }
    if (mistakes) Toast(message, 'error');
  }

  handleAddGroups = () => {
    const { t } = this.props;
    const {
      arrayGroups, groupType, groupName, description, filter,
    } = this.state;
    const groupData = { type: groupType, group: groupName, description };

    groupsService.addGroup(groupData).then(response => {
      if (response.id) arrayGroups.push({ id: Number(response.id), group: groupName, description });
      this.setState({ arrayGroups, groupId: response.id });
      this.handleFilterChange(filter, false, arrayGroups);
      Toast(t('groups.groupAdd'), 'success');
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleUpdateGroups = arrayGroups => {
    const { t } = this.props;
    const {
      groupId, groupType, groupName, description, filter,
    } = this.state;
    const groupData = {
      id: groupId, type: groupType, group: groupName, description,
    };
    groupsService.updateGroup(groupData).then(response => {
      if (response) {
        Toast(t('groups.groupUpdate'), 'success');
        this.setState({ arrayGroups });
        this.handleFilterChange(filter, false, arrayGroups);
      }
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleChangeName = event => {
    const { t } = this.props;
    const maxLength = 20;
    const { value: groupName } = event.target;
    if (groupName && groupName.length > 0) {
      if (!validator.isAlphanumeric(groupName.replace(/ /g, ''), ['es-ES'])) {
        this.setState({ groupName, error: t('groups.errors.notSymbol') });
        return;
      }
      if (groupName.length > maxLength) {
        this.setState({ groupName, error: t('groups.errors.toLong') });
        return;
      }
    }
    this.setState({ groupName, error: '' });
  }

  handleChangeGroupType = (e, { value }) => {
    this.handleGetGroups(value);
    this.setState({ groupType: value });
  }

  handleChangeDescription = event => {
    const { t } = this.props;
    const maxLength = 30;
    const { value: description } = event.target;
    if (description && description.length > 0) {
      if (!validator.isAlphanumeric(description.replace(/ /g, ''), ['es-ES'])) {
        this.setState({ description, descriptionError: t('groups.errors.notSymbol') });
        return;
      }
      if (description.length > maxLength) {
        this.setState({ description, descriptionError: t('groups.errors.toLong') });
        return;
      }
    }
    this.setState({ description, descriptionError: '' });
  }

  validations = event => {
    const { t } = this.props;
    const { arrayGroups } = this.state;
    const maxLength = 30;
    let mistakes;
    const { value: filter } = event.target;
    if ((filter.trim()).length > maxLength) {
      this.setState({ filter, filterError: t('discounts.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) {
      this.setState({ filter, filterError: '' });
      this.handleFilterChange(filter, mistakes, arrayGroups);
    }
  }

  handleFilterChange = (filter, mistakes, arrayGroups) => {
    let foundData = [];
    if (mistakes !== true) {
      if (filter) {
        foundData = arrayGroups.filter(filterInfo => (
          (filterInfo.description && (filterInfo.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0))
          || (filterInfo.group && (filterInfo.group.toLowerCase().indexOf(filter.toLowerCase()) >= 0))));
      } else {
        foundData = arrayGroups;
      }
      this.setState({ filteredGroups: foundData });
    }
  }

  render() {
    const { t } = this.props;
    const {
      open, groupId, headersGroups, groupName, openAddGroup, error, options, groupType, description, descriptionError,
      filteredGroups, filter, filterError,
    } = this.state;
    return (
      <Grid padded>
        {!openAddGroup
          ? (
            <>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Header className="titles-menu" color="blue">{t('groups.couponsGroup')}</Header>
                </Grid.Column>
              </Grid.Row>
              <Divider className="margin-divider" />
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <p>{t('groups.couponsGroupDescription')}</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row verticalAlign="middle">
                <Grid.Column width={13} textAlign="center">
                  <Dropdown
                    selection
                    fluid
                    options={options}
                    value={groupType}
                    onChange={this.handleChangeGroupType}
                    placeholder={t('groups.chooseGroup')}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Button color="blue" onClick={this.handleClickAdd} icon>
                    <Icon.Group size="large">
                      <Icon name="users" />
                      <Icon corner name="add" />
                    </Icon.Group>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </>
          )
          : null}
        {!openAddGroup
          ? (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={14} computer={14} tablet={15} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={30}
                  value={filter || ''}
                  error={filterError || ''}
                  label={t('discounts.filter')}
                  onChange={this.validations}
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {!openAddGroup
          ? (
            <Grid.Row style={{ overflowX: 'auto' }} largeScreen={15} computer={15} tablet={15} mobile={15}>
              <SiclikTable
                selectable
                data={filteredGroups}
                headers={headersGroups}
                pageSize={10}
              />
            </Grid.Row>
          )
          : null}
        <Grid.Row>
          <Confirm
            open={open}
            header={t('discounts.confirmGroupDelete')}
            content={groupName}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ open: false })}
            onConfirm={this.handleConfirmDelete}
          />
        </Grid.Row>
        {openAddGroup
          ? (
            <Group
              id={groupId}
              type={groupType}
              groupName={groupName}
              error={error}
              description={description}
              descriptionError={descriptionError}
              handleChangeName={this.handleChangeName}
              handleChangeDescription={this.handleChangeDescription}
              onSaveClick={this.handleClickSave}
              onCloseClick={status => this.setState({ openAddGroup: status })}
            />
          ) : null}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AdminGroups);
