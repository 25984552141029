import React from 'react';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Grid, Image, Card,
} from 'semantic-ui-react';
import MainHeader from '../common/components/MainHeader';
import brands from './images/index';
import './style.css';

const BrandsComercio = () => (
  <Grid padded>
    <MainHeader title="TODAS LAS MARCAS" colorText="blueText" />
    <Grid.Row centered columns="equal" className="padding-btw-text general-padding-children">
      { brands.map(element => {
        const { image, imageGray, redirect } = element;
        return (
          <Grid.Column
            textAlign="center"
            className="card-padding"
            key={image}
            largeScreen="3"
            computer="3"
            tablet="4"
            mobile="8"
          >
            <Card fluid className="card-brand" centered textAlign="center">
              <Link to={`/productos?type=brand&value=${redirect}`}>
                <Image
                  alt={imageGray}
                  fluid
                  src={imageGray}
                />
                <Image
                  alt={image}
                  fluid
                  src={image}
                  className="img-top"
                />
              </Link>
            </Card>
          </Grid.Column>
        );
      })}
    </Grid.Row>
  </Grid>
);

export default translate('common', { wait: true })(BrandsComercio);
