import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const deliveryAddress = {};

deliveryAddress.getAddressDetail = branchOfficeId => (
  request({
    route: apiRoutes.getAddressById(branchOfficeId),
    method: 'GET',
  })
);
deliveryAddress.addAddress = body => (
  request({
    route: apiRoutes.addAddress(),
    method: 'POST',
    body,
  })
);
deliveryAddress.updateAddress = body => (
  request({
    route: apiRoutes.updateAddress(),
    method: 'PUT',
    body,
  })
);
deliveryAddress.getColony = postalCode => (
  request({
    route: apiRoutes.validatePostalCode(postalCode),
    method: 'GET',
  })
);
deliveryAddress.getSchedule = branchOfficeId => (
  request({
    route: apiRoutes.getSchedule(branchOfficeId),
    method: 'GET',
  })
);
deliveryAddress.addSchedule = (branchOfficeId, body) => (
  request({
    route: apiRoutes.addSchedule(branchOfficeId),
    method: 'POST',
    body,
  })
);
deliveryAddress.getDocuments = branchOfficeId => (
  request({
    route: apiRoutes.getDocuments(branchOfficeId),
    method: 'GET',
  })
);
deliveryAddress.addDocuments = (branchOfficeId, body) => (
  request({
    route: apiRoutes.addDocuments(branchOfficeId),
    method: 'POST',
    body,
  })
);
deliveryAddress.updateDocuments = (branchOfficeId, body) => (
  request({
    route: apiRoutes.updateDocuments(branchOfficeId),
    method: 'PUT',
    body,
  })
);
deliveryAddress.deleteDocument = (branchOfficeId, documentId) => (
  request({
    route: apiRoutes.deleteDocuments(branchOfficeId, documentId),
    method: 'DELETE',
  })
);
deliveryAddress.getManeuver = branchOfficeId => (
  request({
    route: apiRoutes.getManeuvers(branchOfficeId),
    method: 'GET',
  })
);
deliveryAddress.addManeuver = (branchOfficeId, body) => (
  request({
    route: apiRoutes.addManeuver(branchOfficeId),
    method: 'POST',
    body,
  })
);
deliveryAddress.updateManeuver = (branchOfficeId, body) => (
  request({
    route: apiRoutes.updateManeuver(branchOfficeId),
    method: 'PUT',
    body,
  })
);
deliveryAddress.deleteManeuver = (branchOfficeId, maneuverId) => (
  request({
    route: apiRoutes.deleteManeuver(branchOfficeId, maneuverId),
    method: 'DELETE',
  })
);
deliveryAddress.getComments = branchOfficeId => (
  request({
    route: apiRoutes.getComments(branchOfficeId),
    method: 'GET',
  })
);
deliveryAddress.addComment = (branchOfficeId, body) => (
  request({
    route: apiRoutes.addComment(branchOfficeId),
    method: 'POST',
    body,
  })
);
deliveryAddress.updateComment = (branchOfficeId, body) => (
  request({
    route: apiRoutes.updateComment(branchOfficeId),
    method: 'PUT',
    body,
  })
);
deliveryAddress.deleteComment = (branchOfficeId, commentId) => (
  request({
    route: apiRoutes.deleteComment(branchOfficeId, commentId),
    method: 'DELETE',
  })
);

export default deliveryAddress;
