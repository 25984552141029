import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Grid, Header, Divider, Button, Input, Radio,
} from 'semantic-ui-react';
import BoxList from './components/BoxList';
import ExtendedAreas from './components/ExtendedAreas';
import service from './services';
import Toast from '../../../common/components/toast';
import { scrollToRef, currencyFormat, decimalFormat } from '../../../common/utils';

function getTableHeader(handleUpdate) {
  return ([
    { key: 'id', isKey: true },
    { key: 'name', name: 'Caja' },
    { key: 'priceZoneA', name: 'Precio zona A', format: cell => (currencyFormat(cell)) },
    { key: 'priceZoneB', name: 'Precio zona B', format: cell => (currencyFormat(cell)) },
    { key: 'boxWidth', name: 'Ancho', format: cell => (`${decimalFormat(cell)} cm`) },
    { key: 'boxHeight', name: 'Alto', format: cell => (`${decimalFormat(cell)} cm`) },
    { key: 'boxLength', name: 'Largo', format: cell => (`${decimalFormat(cell)} cm`) },
    { key: 'minWeight', name: 'Peso mínimo', format: cell => (`${decimalFormat(cell)} kg`) },
    { key: 'maxWeight', name: 'Peso máximo', format: cell => (`${decimalFormat(cell)} kg`) },
    {
      key: 'actions',
      name: '',
      format: (cell, row) => (
        <Grid.Row>
          <Button color="blue" icon="pencil alternate" onClick={() => handleUpdate(row)} />
        </Grid.Row>
      ),
    },
  ]);
}

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.state = {
      boxes: [],
      headers: getTableHeader(this.handleUpdate),
      id: null,
      name: '',
      priceZoneA: '',
      priceZoneB: '',
      boxWidth: '',
      boxHeight: '',
      boxLength: '',
      minWeight: '',
      maxWeight: '',
      boxType: 'dimensions',
      shouldClean: false,
    };
  }

  componentDidMount() {
    scrollToRef(this.headerRef);
    this.getShippingBoxes();
  }

  getShippingBoxes = async () => {
    const { shippingBoxPrices } = await service.getBoxesConfiguration();
    this.setState({ boxes: shippingBoxPrices });
  }

  handleUpdate = row => {
    const {
      id, name, priceZoneA, priceZoneB, boxWidth, boxHeight, boxLength, minWeight, maxWeight,
    } = row;
    const boxType = (boxWidth && boxHeight && boxLength) ? 'dimensions' : 'weight';
    this.setState({
      id, name, priceZoneA, priceZoneB, boxWidth, boxHeight, boxLength, minWeight, maxWeight, boxType,
    });
  }

  handleClean = () => {
    this.setState({
      id: null,
      name: '',
      priceZoneA: '',
      priceZoneB: '',
      boxWidth: '',
      boxHeight: '',
      boxLength: '',
      minWeight: '',
      maxWeight: '',
    });
  }

  handleInput = (e, { name, value }) => {
    this.setState({ [name]: value });
  }

  isValidData = data => {
    const {
      id, name, priceZoneA, priceZoneB, boxWidth, boxHeight, boxLength, minWeight, maxWeight,
    } = data;
    return (
      (id && name && priceZoneA && priceZoneB)
      && (Number(priceZoneA) > 0 && Number(priceZoneB) > 0)
      && ((Number(boxWidth) > 0 && Number(boxHeight) > 0 && Number(boxLength) > 0) || (Number(minWeight) >= 0 && Number(maxWeight) > Number(minWeight)))
    );
  }

  handleSave = () => {
    const { t } = this.props;
    const {
      id, name, priceZoneA, priceZoneB, boxWidth, boxHeight, boxLength, minWeight, maxWeight,
    } = this.state;
    if (this.isValidData(this.state)) {
      const formatedData = {
        name, priceZoneA, priceZoneB, boxWidth, boxHeight, boxLength, minWeight, maxWeight,
      };
      service.updateBoxConfiguration(id, formatedData)
        .then(() => {
          this.getShippingBoxes();
          this.handleClean();
          Toast(t('Actualizado correctamente'), 'success');
        })
        .catch(() => Toast('Error al actualizar caja', 'error'));
    }
  }

  render() {
    const { t } = this.props;
    const {
      id, name, priceZoneA, priceZoneB, boxWidth, boxHeight, boxLength, minWeight, maxWeight, boxes, shouldClean, headers, boxType,
    } = this.state;
    const disableSave = !id || !name || !priceZoneA || !priceZoneB;
    return (
      <Grid>
        <Grid.Row>
          <div ref={this.headerRef} />
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('standardShipping.title')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('standardShipping.moduleDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        {
          id
          && ([
            <Grid.Row>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <Radio
                  label={t('standardShipping.label.dimensions')}
                  name="boxType"
                  value="dimensions"
                  checked={boxType === 'dimensions'}
                  onChange={this.handleInput}
                  disabled
                  style={{ marginRight: '1em' }}
                />
                <Radio
                  label={t('standardShipping.label.weight')}
                  name="boxType"
                  value="weight"
                  checked={boxType === 'weight'}
                  onChange={this.handleInput}
                  disabled
                />
              </Grid.Column>
            </Grid.Row>,
            <Grid.Row>
              <Grid.Column computer={2} tablet={7} mobile={15}>
                <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.name')}</Header>
                <Input
                  name="name"
                  value={name}
                  onChange={this.handleInput}
                  disabled
                  fluid
                />
              </Grid.Column>
              <Grid.Column computer={2} tablet={7} mobile={15}>
                <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.priceZoneA')}</Header>
                <Input
                  name="priceZoneA"
                  value={priceZoneA}
                  onChange={this.handleInput}
                  fluid
                />
              </Grid.Column>
              <Grid.Column computer={2} tablet={7} mobile={15}>
                <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.priceZoneB')}</Header>
                <Input
                  name="priceZoneB"
                  value={priceZoneB}
                  onChange={this.handleInput}
                  fluid
                />
              </Grid.Column>
              {
                boxType === 'dimensions'
                  ? ([
                    <Grid.Column computer={2} tablet={7} mobile={15}>
                      <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.boxWidth')}</Header>
                      <Input
                        name="boxWidth"
                        value={boxWidth}
                        onChange={this.handleInput}
                        fluid
                      />
                    </Grid.Column>,
                    <Grid.Column computer={2} tablet={7} mobile={15}>
                      <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.boxHeight')}</Header>
                      <Input
                        name="boxHeight"
                        value={boxHeight}
                        onChange={this.handleInput}
                        fluid
                      />
                    </Grid.Column>,
                    <Grid.Column computer={2} tablet={7} mobile={15}>
                      <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.boxLength')}</Header>
                      <Input
                        name="boxLength"
                        value={boxLength}
                        onChange={this.handleInput}
                        fluid
                      />
                    </Grid.Column>,
                  ])
                  : ([
                    <Grid.Column computer={2} tablet={7} mobile={15}>
                      <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.minWeight')}</Header>
                      <Input
                        name="minWeight"
                        value={minWeight}
                        onChange={this.handleInput}
                        fluid
                      />
                    </Grid.Column>,
                    <Grid.Column computer={2} tablet={7} mobile={15}>
                      <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.maxWeight')}</Header>
                      <Input
                        name="maxWeight"
                        value={maxWeight}
                        onChange={this.handleInput}
                        fluid
                      />
                    </Grid.Column>,
                  ])
              }
              <Grid.Column computer={4} tablet={16} mobile={16} verticalAlign="bottom">
                <Button
                  size="medium"
                  onClick={this.handleClean}
                >
                  <span>{t('cancel')}</span>
                </Button>
                <Button
                  size="medium"
                  color="blue"
                  onClick={this.handleSave}
                  disabled={disableSave}
                >
                  <span>{!id ? t('save') : t('edit')}</span>
                </Button>
              </Grid.Column>
            </Grid.Row>,
            <Divider className="no-margin-top no-margin-bottom" section />])
        }
        <Grid.Row>
          <Grid.Column>
            <BoxList
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              boxes={boxes}
              headers={headers}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ExtendedAreas />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Notifications.defaultProps = {
  t: () => { },
};

Notifications.propTypes = {
  t: PropTypes.func,
};

export default translate('common', { wait: true })(Notifications);
