import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useContext } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { translate } from 'react-i18next';
// import { currencyFormat } from '../common/utils';
import * as session from '../common/sessions';
import OrderContent from './components/order-content';
import Toast from '../common/components/toast';
import UserOrder from './components/user-order';
import routes from '../route-names';
import services from './services';
import couponTypes from '../common/discount-types';

import { CartContext } from '../context/cart-context';

const initialState = {
  CFDIError: '',
  access: '',
  attachments: [],
  checkoutData: [],
  checkoutLoader: true,
  comment: '',
  commentError: '',
  couponsApplied: [],
  creditAvailable: false,
  currency: 'MXN',
  currencyError: '',
  currencyOptions: [
    { key: 1, text: 'MXN', value: 1 },
    { key: 2, text: 'USD', value: 2 },
  ],
  currentCurrency: 1,
  currentPaymentMethod: 6,
  customerId: '',
  customerIdFromOrder: '',
  customerIdOfQuote: '',
  deliveryConfig: {},
  deliveryError: '',
  discount: 0,
  endUserName: '',
  endUsers: [],
  errorCode: 1,
  errorExist: false,
  finalUserError: '',
  items: [],
  itemsError: '',
  iva: 0,
  leaseOptionSelected: 0,
  leaseOptionsHeaders: [],
  leaseQuote: [],
  leaseQuoteLoader: true,
  loader: true,
  odersData: [],
  openAttach: false,
  openAuthorizationModal: false,
  openMultiBrand: false,
  payment: 1,
  paymentError: '',
  paymentMethods: [],
  quantityError: '',
  quotationName: '',
  quoteId: '',
  subtotal: 0,
  total: 0,
  totalAmount: '',
  userId: '',
  userName: '',
  userNameLoged: '',
  leaseItemsHeaders: [],
  leaseItemsData: [],
  leaseSubtotal: 0,
  quoteAgainFlag: true,
  loaderTest: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'orderLoadDone': {
      const { purchaseOrder, loader } = action.payload;
      return {
        ...state,
        ...purchaseOrder,
        checkoutData: [purchaseOrder],
        checkoutLoader: false,
        comments: purchaseOrder.comments,
        currentCurrency: purchaseOrder.currencyId,
        customerIdFromOrder: purchaseOrder.customerId,
        discount: purchaseOrder.discount,
        endUserName: purchaseOrder.endUserName,
        iva: purchaseOrder.iva,
        loader,
        odersData: purchaseOrder.orders,
        orderId: purchaseOrder.id,
        subtotal: purchaseOrder.subtotal,
        total: purchaseOrder.total,
        totalAmount: purchaseOrder.total,
      };
    }
    case 'attachesLoadDone': {
      const { attaches } = action.payload;
      return {
        ...state,
        attachments: attaches.attaches,
      };
    }
    case 'bodyQuotation': {
      const { currentPaymentMethod, currentCurrency } = action.payload;
      return {
        ...state,
        currentCurrency,
        currentPaymentMethod,
      };
    }
    case 'orderLoadFail': {
      const { errorCode } = action.payload;
      return { ...state, errorCode };
    }
    case 'attachesLoadFail': {
      const { errorCode, loader } = action.payload;
      return {
        ...state, errorCode, loader, checkoutLoader: false,
      };
    }
    case 'fieldChange': {
      const { value, field } = action.payload;
      const customFields = {};
      customFields[field] = value;
      return { ...state, ...customFields };
    }
    case 'leaseQuoteDone': {
      const { leaseQuote } = action.payload;
      return {
        ...state,
        leaseQuote,
      };
    }
    case 'loadLeaseHeaders': {
      const { leaseOptionsHeaders } = action.payload;
      return {
        ...state,
        leaseOptionsHeaders,
        leaseQuoteLoader: false,
      };
    }
    case 'loadLeaseItemsHeaders': {
      const { leaseItemsHeaders, leaseItemsData, leaseSubtotal } = action.payload;
      return {
        ...state,
        leaseItemsHeaders,
        leaseItemsData,
        leaseSubtotal,
      };
    }
    default:
      throw new Error();
  }
}

const Loading = ({ disable, message }) => (
  <Dimmer active={!disable} inverted>
    <Loader active>
      {message}
    </Loader>
  </Dimmer>
);

Loading.propTypes = {
  disable: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

function ShoppingCart(props) {
  const {
    t, match, history,
  } = props;
  const { params } = match;
  const { quoteId: paramsQuoteId } = params;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { updateCartCount } = useContext(CartContext);
  const {
    checkoutData,
    // currency,
    customerIdOfQuote,
    endUserName,
    errorCode,
    hasAgentPrice,
    loader,
    odersData,
    openMultiBrand,
    quoteId,
    singleDelivery,
    userId,
    userName,
    userNameLoged,
    currencyOptions,
    orderId,
    paymentMethods,
    currentCurrency,
    currentPaymentMethod,
    attachments,
    leaseOptionsHeaders,
    leaseQuote,
    leaseQuoteLoader,
    leaseItemsHeaders,
    leaseItemsData,
    leaseSubtotal,
    quoteAgainFlag,
    customerIdFromOrder,
    isLeaseQuote,
    userOrderId,
    items,
  } = state;

  async function getActiveOrder(payment, currencyParam) {
    const body = {
      orderId: paramsQuoteId,
      paymentId: !payment ? currentPaymentMethod : payment,
      currencyId: !currencyParam ? currentCurrency : currencyParam,
    };
    try {
      const purchaseOrder = await services.quoteCheckout(body);
      const allItemsArrays = [];
      const allOrders = purchaseOrder.orders.map(order => {
        let creditDays;
        order.items.forEach(item => {
          const creditDaysDiscounts = item.coupons.filter(coupon => coupon.type === couponTypes.creditDays.discount);
          if (creditDaysDiscounts.length) {
            creditDays = creditDaysDiscounts.toString();
          }
        });
        return { ...order, creditDays };
      });
      allOrders.forEach(order => allItemsArrays.push(order.items));
      const allItems = allItemsArrays.reduce((accumulatedArray, currentItem) => accumulatedArray.concat(currentItem));
      if (purchaseOrder.isLeaseQuote) {
        Toast('Por el momento no se pueden procesar las ordenes de financiamiento HPEFS', 'error');
      }
      dispatch({
        type: 'orderLoadDone',
        payload: { purchaseOrder, loader: false },
      });
      dispatch({
        type: 'fieldChange',
        payload: { field: 'items', value: allItems },
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
    }
  }

  async function getAttaches() {
    const attaches = await services.getAttaches(paramsQuoteId);
    try {
      dispatch({
        type: 'attachesLoadDone',
        payload: { attaches },
      });
    } catch (error) {
      dispatch({
        type: 'orderLoadFail',
        payload: { errorCode: error.code, loader: false },
      });
    }
  }

  const handlePaymentMethodChange = (e, { value }) => {
    if (currentPaymentMethod === value) return;
    const availableCurrency = (value === 1 || value === 6) ? currentCurrency : 1;
    dispatch({
      type: 'bodyQuotation',
      payload: {
        currentPaymentMethod: value,
        currentCurrency: availableCurrency,
      },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'loader', value: true },
    });
    getActiveOrder(value, availableCurrency);
  };

  const handleCurrencyChange = (e, { value }) => {
    const availableCurrency = (currentPaymentMethod === 1 || currentPaymentMethod === 6) ? value : 1;
    if (currentCurrency === availableCurrency) return;
    dispatch({
      type: 'bodyQuotation',
      payload: {
        currentPaymentMethod,
        currentCurrency: availableCurrency,
      },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'loader', value: true },
    });
    getActiveOrder(currentPaymentMethod, availableCurrency);
  };

  // function putLeaseHeaders(leaseQuoteData) {
  //   const { items, paymentAmount, leaseTerm } = leaseQuoteData;
  //   const leaseOptionsHeadersValues = [
  //     { key: 'id', isKey: true },
  //     { key: 'leaseTerm', name: 'Duración', format: '' },
  //     { key: 'paymentFrecuency', name: 'Frecuencia de pago', format: (cell, row) => (row.paymentFrecuency === 'MON' ? 'Mensual' : 'Cuatrimestral') },
  //     { key: 'paymentAmount', name: 'Cantidad por pago', format: (cell, row) => ((currency === 1 || currency === 'MXN') ? `${currencyFormat(row.paymentAmount, 'MXN')}` : `$ ${currencyFormat(row.paymentAmount, 'USD')}`) },
  //     { key: 'totalAmount', name: 'Cantidad Total a pagar', format: (cell, row) => ((currency === 1 || currency === 'MXN') ? `${currencyFormat(row.totalAmount, 'MXN')}` : `${currencyFormat(row.totalAmount, 'USD')}`) },
  //   ];
  //   const leaseItemsHeadersValues = [
  //     { key: 'id', isKey: true },
  //     { key: 'sku', name: 'SKU' },
  //     { key: 'quantity', name: 'Cantidad' },
  //     { key: 'price', name: 'Precio', format: cell => (currencyFormat(cell, currency)) },
  //     {
  //       key: 'finalPrice',
  //       name: 'Precio con margen',
  //       format: (cell, row) => <TextField type="number" error={row.fieldError} value={cell} disabled label={(currency === 1 || currency === 'MXN') ? currencyFormat(cell, 'MXN') : currencyFormat(cell, 'USD')} />,
  //     },
  //   ];
  //   const leaseSubtotalAmounts = items.map(item => item.finalPrice);
  //   const leaseSubtotal = leaseSubtotalAmounts.reduce((accumulator, currentValue) => accumulator + currentValue);
  //   dispatch({ type: 'loadLeaseHeaders', payload: { leaseOptionsHeaders: leaseOptionsHeadersValues } });
  //   dispatch({ type: 'leaseQuoteDone', payload: { leaseQuote: { ...leaseQuoteData, totalAmount: (paymentAmount * parseFloat(leaseTerm)) } } });
  //   dispatch({ type: 'loadLeaseItemsHeaders', payload: { leaseItemsHeaders: leaseItemsHeadersValues, leaseSubtotal, leaseItemsData: items } });
  // }

  async function getPaymentMethods() {
    try {
      const methods = await services.getPaymentMethods();
      const paymentMethodOptions = await (methods.paymentMethods.map(options => {
        const paymentOptions = {
          key: options.id,
          text: options.name,
          value: options.id,
        };
        return paymentOptions;
      }));
      dispatch({
        type: 'fieldChange',
        payload: { field: 'paymentMethods', value: paymentMethodOptions },
      });
    } catch (error) {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
    }
  }

  function intialDataLoad() {
    const {
      userId: user, customerId: id, access, completeName: name,
    } = session.get();
    const quote = paramsQuoteId;
    let customerIdOwner = '';
    if (id === 'G000000') customerIdOwner = match.params.customerId;
    dispatch({
      type: 'fieldChange',
      payload: { field: 'customerId', value: (!id ? '' : id) },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'customerIdOfQuote', value: (!customerIdOwner ? '' : customerIdOwner) },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'access', value: access },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'userId', value: user },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'userNameLoged', value: name },
    });
    dispatch({
      type: 'fieldChange',
      payload: { field: 'quoteId', value: quote },
    });
    getPaymentMethods();
    getAttaches();
    getActiveOrder();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(intialDataLoad, []);

  const handleBoolField = (fieldName, currentValue) => dispatch({
    type: 'fieldChange',
    payload: { field: fieldName, value: !currentValue },
  });

  const handleModalState = (fieldName, modalState = true) => dispatch({
    type: 'fieldChange',
    payload: { field: fieldName, value: modalState },
  });

  const handleFormId = id => {
    dispatch({
      type: 'fieldChange',
      payload: { field: 'formId', value: id },
    });
  };

  const handleGoToMultiShipping = () => history.push(`${(routes.multiShipping.route).replace(':orderId?', '')}${quoteId}`);
  return (
    <OrderContent
      history={history}
      handleModalState={handleModalState}
      handleGoToShippingAgent={() => history.push(`${(routes.logisticsAgent.route).replace(':orderId?', '')}${quoteId}`)}
      handleGoToShipping={() => history.push(`${(routes.multiShipping.route).replace(':orderId?', '')}${quoteId}`)}
      handleGoToMultiShipping={handleGoToMultiShipping}
      handleGoToBack={() => history.push(routes.quotations.route)}
      handleGoToBackAgent={() => history.push(routes.quotationsAgent.route)}
      quoteId={quoteId}
      openMultiBrand={openMultiBrand}
      userId={userId}
      userName={userName}
      userNameLoged={userNameLoged}
      endUserName={endUserName}
      customerIdByToken={session.get().customerId}
      customerIdOfQuote={customerIdOfQuote}
      tr={t}
      show={errorCode > 1}
      handleFormId={handleFormId}
      getShoppingCartCount={updateCartCount}
      handleBoolField={handleBoolField}
      odersData={odersData}
      checkoutData={checkoutData}
      customerIdFromOrder={customerIdFromOrder}
      isLeaseQuote={isLeaseQuote}
      userOrderId={userOrderId}
      items={items}
    >

      {loader ? (
        <Loading disable={!loader} message={t('loading')} />
      ) : (
        <UserOrder
          handleModalState={handleModalState}
          handleGoToMultiShipping={handleGoToMultiShipping}
          handleBoolField={handleBoolField}
          handlePaymentMethodChange={handlePaymentMethodChange}
          handleCurrencyChange={handleCurrencyChange}
          handleGoToShippingAgent={() => history.push(`${routes.logisticsAgent.route}/${quoteId}`)}
          dispatch={dispatch}
          singleDelivery={singleDelivery}
          hasAgentPrice={hasAgentPrice}
          t={t}
          quoteId={quoteId}
          errorCode={errorCode}
          currencyOptions={currencyOptions}
          orderId={orderId}
          checkoutData={checkoutData}
          paymentMethods={paymentMethods}
          odersData={odersData}
          ordersLoader={false}
          currentCurrency={currentCurrency}
          currentPaymentMethod={currentPaymentMethod}
          attachments={attachments}
          loader={loader}
          leaseOptionsHeaders={leaseOptionsHeaders}
          leaseQuote={leaseQuote}
          leaseQuoteLoader={leaseQuoteLoader}
          leaseItemsHeaders={leaseItemsHeaders}
          leaseItemsData={leaseItemsData}
          leaseSubtotal={leaseSubtotal}
          quoteAgainFlag={quoteAgainFlag}
        />
      )}
    </OrderContent>
  );
}

export default translate('common', { wait: true })(ShoppingCart);
