import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const users = {};

users.getUsersByCustomer = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
  })
);

users.getUsersHasPermission = () => (
  request({
    route: apiRoutes.getUsersHasPermission(),
    method: 'GET',
  })
);

users.deleteUser = id => (
  request({
    route: apiRoutes.deleteUsersHasPermission(id),
    method: 'DELETE',
  })
);

users.addUser = body => (
  request({
    route: apiRoutes.addUsersHasPermission(),
    method: 'POST',
    body,
  })
);

export default users;
