import React, { useState, useEffect } from 'react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { translate } from 'react-i18next';
import propTypes from 'prop-types';
import { nanoid } from 'nanoid';
import uniq from 'lodash/uniq';

import {
  Segment,
  Icon,
  Dropdown,
  List,
  Label,
  Modal,
  Button,
  Input,
} from 'semantic-ui-react';

import service from '../../../services';
import toast from '../../../../common/components/toast';

const translatePath = 'productConfig.menus.uploadBulkImages';
const translatePathItemGroup = `${translatePath}.addItemGroupModal`;

function parseOptionList(option) {
  const values = {
    category: 'erpCategory',
    family: 'erpFamily',
    brand: 'brand',
    sku: 'sku',
  };
  return values[option];
}

async function getAttributesPerBrand(brand, attribute) {
  const response = await service.getAttributesPerBrand(
    brand,
    parseOptionList(attribute),
  );
  return response.brand.values.map(attributeName => ({
    key: nanoid(10),
    text: attributeName,
    value: attributeName,
  }));
}

function AddItems(props) {
  const {
    open,
    onClose,
    onConfirm,
    itemGroup,
    t,
  } = props;
  const [attribute, setAttribute] = useState('sku');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [attributeValues, setAttributeValues] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [brandloader, setBrandLoader] = useState(false);
  const [attributeLoader, setAttributeLoader] = useState(false);
  const [attributeValue, setAttributeValue] = useState('');

  useEffect(() => {
    if (itemGroup) {
      setSelectedProducts(itemGroup.selectedProducts);
    }
  }, [itemGroup]);

  const getOptionListText = option => {
    const values = {
      sku: t(`${translatePath}.itemGroupOptions.sku`),
      category: t(`${translatePath}.itemGroupOptions.category`),
      family: t(`${translatePath}.itemGroupOptions.family`),
      brand: t(`${translatePath}.itemGroupOptions.brand`),
    };
    return values[option];
  };

  const options = [
    { key: 'sku', text: 'sku', value: 'sku' },
    { key: 'category', text: t('categorizations.category'), value: 'category' },
    { key: 'family', text: t('categorizations.family'), value: 'family' },
    { key: 'brand', text: t('categorizations.brand'), value: 'brand' },
  ];

  const handleAddProduct = async (e, { value }) => {
    try {
      setAttributeValue(value);
      const { items: skus } = await service.getItemsByAttribute(
        parseOptionList(attribute),
        value,
      );
      setSelectedProducts(currentProducts => (
        uniq([...currentProducts, ...skus])
      ));
      if (!skus.length) {
        toast(t(`${translatePath}.toast.noItemsFound`), 'warning');
      }
    } catch (error) {
      toast(t(`error-codes.${error.code || 'default'}`), 'error');
    }
  };

  const handleDeleteProduct = sku => {
    setSelectedProducts(currentProducts => (
      currentProducts.filter(currentSku => currentSku !== sku)
    ));
  };

  const handleConfirm = () => {
    const hasProducts = selectedProducts.length > 0;
    if (hasProducts) {
      onConfirm({
        id: itemGroup?.id || nanoid(10),
        selectedProducts,
      });
      setSelectedProducts([]);
      setSearchQuery('');
      setAttributeValue('');
      setAttribute('sku');
      onClose(true);
    } else {
      toast(t(`${translatePath}.toast.noItems`), 'error');
    }
  };

  const handleOnFilterBrand = async (e, { value }) => {
    try {
      setSelectedBrand(value);
      setAttributeLoader(true);
      const result = await getAttributesPerBrand(value, attribute);
      setAttributeValues(result);
    } catch (error) {
      toast(t(`error-codes.${error.code || 'default'}`), 'error');
    } finally {
      setAttributeLoader(false);
    }
  };

  const handleOnSelectAttribute = async (e, { value }) => {
    if (value === 'sku') {
      setAttributeValues([]);
      setSelectedBrand('');
    }
    setAttribute(value);
    if (value !== 'sku' && !brands.length) {
      setBrandLoader(true);
      const response = await service.requestAllBrands();
      const result = response.brands
        .map(brand => ({
          key: brand.id,
          text: brand.name,
          value: brand.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
      setBrands(result);
      setBrandLoader(false);
      setSelectedBrand('');
    }
    if (value !== 'sku' && selectedBrand) {
      await handleOnFilterBrand(null, { value: selectedBrand });
    }
  };

  const handleSearch = async () => {
    try {
      setAttributeLoader(true);
      const response = await service.getItemBySku(searchQuery);
      setSelectedProducts(uniq([...selectedProducts, response.sku]));
      setSearchQuery('');
    } catch (error) {
      if (error?.code === callError.products.INVALID_SKU) {
        toast(t(`${translatePath}.toast.noItemFound`, { sku: searchQuery }), 'error');
      } else {
        toast(t(`error-codes.${error.code || 'default'}`), 'error');
      }
    } finally {
      setAttributeLoader(false);
    }
  };

  const handleClose = () => {
    onClose();
    setSelectedProducts([]);
    setSearchQuery('');
    setAttributeValue('');
    setAttribute('sku');
  };

  return (
    <Modal open={open} onClose={handleClose} closeOnEscape closeIcon>
      <Modal.Header>{t(`${translatePathItemGroup}.title`)}</Modal.Header>
      <Modal.Content>
        <Segment textAlign="center" color="green">
          <Segment.Inline style={{ paddingBottom: '10px' }}>
            <Icon name="search" size="big" />
          </Segment.Inline>
          <Segment.Inline style={{ paddingBottom: '10px' }}>
            {t(`${translatePathItemGroup}.description`)}
            {' '}
            <Dropdown
              inline
              options={options}
              defaultValue={attribute}
              onChange={handleOnSelectAttribute}
            />
          </Segment.Inline>
          <Segment.Inline>
            {attribute !== 'sku' && attribute !== 'brand' && (
              <Dropdown
                placeholder={t(`${translatePathItemGroup}.brandFilterPlaceholder`)}
                scrolling
                search
                selection
                loading={brandloader}
                options={brands}
                noResultsMessage={t(`${translatePathItemGroup}.noResultsMessage`)}
                onChange={handleOnFilterBrand}
                style={{ marginRight: '10px' }}
              />
            )}
            {attribute === 'sku' && (
              <Input
                size="small"
                placeholder={t(`${translatePathItemGroup}.itemGroupOptionsInput.write`)}
                onChange={e => setSearchQuery(e.target.value)}
                action={{ icon: 'plus', onClick: handleSearch }}
                loading={attributeLoader}
                value={searchQuery}
                onKeyPress={e => e.key === 'Enter' && handleSearch()}
              />
            )}
            {attribute === 'brand' && (
              <Dropdown
                button
                floating
                labeled
                className="icon pointer"
                icon="add"
                search
                value={attributeValue}
                noResultsMessage={t(`${translatePathItemGroup}.noResultsMessage`)}
                options={brands}
                loading={attributeLoader}
                placeholder={t(`${translatePathItemGroup}.itemGroupOptionsInput.select`, { option: getOptionListText(attribute) })}
                onChange={handleAddProduct}
              />
            )}
            {attribute !== 'sku' && attribute !== 'brand' && (
              <Dropdown
                button
                floating
                labeled
                disabled={brands.length === 0}
                className="icon pointer"
                icon="add"
                search
                value={attributeValue}
                clearable
                noResultsMessage={t(`${translatePathItemGroup}.noResultsMessage`)}
                options={attributeValues}
                loading={attributeLoader}
                placeholder={t(`${translatePathItemGroup}.itemGroupOptionsInput.select`, { option: getOptionListText(attribute) })}
                onChange={handleAddProduct}
              />
            )}
          </Segment.Inline>
        </Segment>
        <Segment textAlign="center" color="blue" style={{ minHeight: '150px' }}>
          <Segment.Inline style={{ paddingBottom: '10px' }}>
            <Icon name="list alternate outline" size="big" />
          </Segment.Inline>
          <Segment.Inline style={{ paddingBottom: '10px' }}>
            {t(`${translatePathItemGroup}.content`)}
          </Segment.Inline>
          <Segment.Inline style={{ paddingBottom: '10px' }}>
            <Button
              color="red"
              content={t(`${translatePathItemGroup}.cleanButton`)}
              size="small"
              onClick={() => {
                setSelectedProducts([]);
                setAttributeValue('');
              }}
            />
          </Segment.Inline>
          <Segment vertical>
            <List horizontal>
              {selectedProducts.map(sku => (
                <List.Item key={sku}>
                  <List.Content>
                    <Label as="a" color="green">
                      {sku}
                      <Icon name="delete" onClick={() => handleDeleteProduct(sku)} />
                    </Label>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('cancel')}
          onClick={handleClose}
        />
        <Button
          content={t('save')}
          onClick={handleConfirm}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

AddItems.propTypes = {
  t: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  onConfirm: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  itemGroup: propTypes.shape({
    id: propTypes.string,
    selectedProducts: propTypes.arrayOf(propTypes.shape({
      key: propTypes.string,
      text: propTypes.string,
      value: propTypes.string,
    })),
  }),
};

AddItems.defaultProps = {
  itemGroup: null,
};

export default translate('common', { wait: true })(AddItems);
