import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Popup, Label, Loader, Message, Placeholder, Button, List,
} from 'semantic-ui-react';
import moment from 'moment';
import { currencyFormat } from '../../../common/utils';
import '../../styles.css';
import SubstituteProducts from './substitute';

const newsGothicFont = {
  fontFamily: 'News Gothic MT',
  fontWeight: '500',
};

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pieceMinimun: false,
    };
  }

  componentDidMount() {
    const { steppedPriceHp } = this.props;
    if (steppedPriceHp !== undefined) {
      this.getPiecesMin();
    }
  }

  componentDidUpdate(prevProps) {
    const { steppedPriceHp } = this.props;
    if (steppedPriceHp !== prevProps.steppedPriceHp) {
      this.getPiecesMin();
    }
  }

  loadOrNull = isValidPrice => {
    const { t } = this.props;
    return (
      !isValidPrice
        ? null
        : (
          <Grid.Row>
            <Loader active inline="centered">{t('productDetails.loading')}</Loader>
          </Grid.Row>
        )
    );
  }

  getPiecesMin = () => {
    const { steppedPriceHp } = this.props;
    // eslint-disable-next-line react/prop-types
    const piecesMin = steppedPriceHp[0].min || 'Sin Datos';
    if (piecesMin === '1 PZAS') {
      this.setState({ pieceMinimun: true });
    }
  }

  render() {
    const {
      t, currencyPrice: currency, sku, sku2, price, isValidPrice, arrayObjectStock, promotionPrice,
      promotionalMessage, arrayObjectStockOc, hasNotStock, promotionDeadline, descriptionBySku,
      arraySubstituteProducts, getShoppingCartCount, promotion, deliverDate, maker, isAvailableRedhat,
    } = this.props;
    const { pieceMinimun } = this.state;
    return (
      <Grid padded centered>
        <Grid.Row>
          <Grid.Column className="no-padding-x">
            <Header as="h4" style={newsGothicFont} color="fullBlack">
              {t('productDetails.SKU')}
              :
              {' '}
              {!sku ? '' : sku}
            </Header>
            <Header hidden={!sku2 || sku2 === ''} as="h5" style={newsGothicFont} color="fullBlack">
              SKU2
              :
              {' '}
              {!sku2 ? '' : sku2}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {
          (!descriptionBySku)
            ? null
            : (
              <Grid.Row>
                <Grid.Column className="no-padding-x">
                  <Label basic color="blue">{descriptionBySku}</Label>
                </Grid.Column>
              </Grid.Row>
            )
        }
        {
          ((!promotionPrice) || (Number(promotionPrice) === 0) || (promotionPrice === ''))
            ? null
            : (
              <Grid.Row>
                <Grid.Column className="no-padding-x">
                  {
                  !isValidPrice
                    ? null
                    : (
                      <Header as="h4" style={newsGothicFont} className="header_title_previousprice" color="fullBlack">
                        {t('productDetails.previousprice')}
                        :
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} className="header_previousprice" color="fullBlack">
                          <i>
                            <strike>
                              {!promotionPrice || Number(price) === 0 ? '' : `${currencyFormat(price, currency)}`}
                            </strike>
                          </i>
                        </Header>
                      </Header>
                    )
                }
                </Grid.Column>
              </Grid.Row>
            )
        }
        {
          (price == null || !isValidPrice)
            ? this.loadOrNull(isValidPrice)
            : (
              <Grid.Row>
                <Grid.Column className="no-padding-x">
                  <Header style={newsGothicFont} className="header_title_price" color="green">
                    {t('productDetails.price')}
                    :
                  </Header>
&nbsp;
                  {
                  Number(price) === 0
                    ? ''
                    : (
                      <Header className="header_price" color="green">
                        {
                        (!promotionPrice || promotionPrice === '0' || promotionPrice === '')
                          ? `${currencyFormat(price, currency)}` : `${currencyFormat(promotionPrice, currency)}`
                      }
                      </Header>
                    )
                  }
                  { pieceMinimun ? (
                    <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={4}>
                      <Label as="a" color="yellow">{t('El precio aplica ingresando Map ID')}</Label>
                    </Grid.Column>
                  )
                    : ''}

                </Grid.Column>
              </Grid.Row>
            )
        }
        { (!promotionDeadline || promotionDeadline == null) ? ''
          : (
            <Grid.Row>
              <Grid.Column className="no-padding-x">
                <Button size="tiny" color="red">
                  (
                  {t('productDetails.deadline')}
                  {' '}
                  {moment(promotionDeadline).format('DD/MM/YYYY')}
                  )
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        { promotion && promotion.length > 0
          ? (
            <Grid.Row>
              <Grid.Column textAlign="left">
                <Message compact color="green">
                  <Header as="h5">Este producto tiene descuento(s) aplicados:</Header>
                  <List>
                    {promotion.map(item => (<List.Item icon="tag" content={`Promoción ${item.code} descuento de ${item.quantity} ${item.discountType === 2 ? '%' : 'MXN'}`} />))}
                  </List>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {
          isValidPrice
            ? null
            : (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} className="no-padding-x">
                  <Header className="header_price" style={newsGothicFont} color="red">
                    {t('products.invalidPrice')}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )
        }
        <Grid.Row>
          {arrayObjectStock.totalStock === null && !hasNotStock
            ? (
              <Grid.Column>
                <Placeholder fluid>
                  <Placeholder.Line full />
                  <Placeholder.Line full />
                </Placeholder>
              </Grid.Column>
            )
            : (
              <Grid.Column
                className="no-padding-x"
                largeScreen={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                computer={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                tablet={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                mobile={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                floated="left"
              >
                <Popup
                  size="huge"
                  trigger={(
                    <Label as="a">
                      <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">
                        {t('productDetails.available')}
                        :
                        &nbsp;
                        <Header as="h5" className="available" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">
                          <u>{arrayObjectStock.totalStock == null || Number(arrayObjectStock.totalStock) <= 0 ? t('products.notAvailable') : arrayObjectStock.totalStock}</u>
                        </Header>
                      </Header>
                    </Label>
              )}
                  flowing
                  hoverable
                >
                  {
                  arrayObjectStock.totalStock == null
                    ? <Header className="header_price" color="fullBlack">{t('products.notAvailable')}</Header>
                    : ''
                }
                  {
                arrayObjectStock.totalStock == null || arrayObjectStock.hasElectronic
                  ? ''
                  : (
                    <Grid centered divided columns={4}>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.guadalajara')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockGdl == null || Number(arrayObjectStock.totalStockGdl) <= 0 ? 0 : arrayObjectStock.totalStockGdl}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.monterrey')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockMty == null || Number(arrayObjectStock.totalStockMty) <= 0 ? 0 : arrayObjectStock.totalStockMty}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.mexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockMx == null || Number(arrayObjectStock.totalStockMx) <= 0 ? 0 : arrayObjectStock.totalStockMx}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.edomexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockEm == null || Number(arrayObjectStock.totalStockEm) <= 0 ? 0 : arrayObjectStock.totalStockEm}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                    </Grid>
                  )
              }

                  {
                arrayObjectStock.totalStock == null || !arrayObjectStock.hasElectronic
                  ? ''
                  : (
                    <Grid centered divided columns={4}>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.guadalajara')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockGdlElec == null || Number(arrayObjectStock.totalStockGdlElec) <= 0 ? 0 : arrayObjectStock.totalStockGdlElec}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.monterrey')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockMtyElec == null || Number(arrayObjectStock.totalStockMtyElec) <= 0 ? 0 : arrayObjectStock.totalStockMtyElec}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.mexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStock.totalStockMxElec == null || Number(arrayObjectStock.totalStockMxElec) <= 0 ? 0 : arrayObjectStock.totalStockMxElec}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                    </Grid>
                  )
              }
                </Popup>
              </Grid.Column>
            )}
          {
            !arrayObjectStockOc || !arrayObjectStockOc.totalStockOc
              ? ''
              : (
                <Grid.Column
                  className="no-padding-x"
                  largeScreen={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                  computer={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                  tablet={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                  mobile={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                >
                  <Popup
                    size="huge"
                    trigger={(
                      <Label as="a">
                        <Header as="h5" color="fullBlack" style={{ fontFamily: 'News Gothic MT' }}>
                          {t('productDetails.toArrive')}
                          :
                          &nbsp;
                          <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} className="available" color="fullBlack">
                            <u>{arrayObjectStockOc.totalStockOc}</u>
                          </Header>
                        </Header>
                      </Label>
                  )}
                    flowing
                    hoverable
                  >
                    <Grid centered divided columns={4}>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.guadalajara')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockGdlOc == null ? 0 : arrayObjectStockOc.totalStockGdlOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.monterrey')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockMtyOc == null ? 0 : arrayObjectStockOc.totalStockMtyOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.mexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockMxOc == null ? 0 : arrayObjectStockOc.totalStockMxOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.edomexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockEmOc == null ? 0 : arrayObjectStockOc.totalStockEmOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Popup>
                </Grid.Column>
              )
          }
          {
            !arrayObjectStockOc || !arrayObjectStockOc.totalStockOc || deliverDate === false || maker !== 'Apple'
              ? ''
              : (
                <Grid.Column className="no-padding-x" largeScreen={6} computer={6} tablet={6} mobile={6}>
                  <Label basic>
                    <Header as="h5" color="fullBlack" style={{ fontFamily: 'News Gothic MT' }}>
                      {t('productDetails.estimatedDate')}
                      :
                      &nbsp;
                      <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} className="available" color="fullBlack">
                        {deliverDate}
                      </Header>
                    </Header>
                  </Label>
                </Grid.Column>
              )
          }
        </Grid.Row>
        {
          (!isAvailableRedhat && maker === 'Red Hat' && (arrayObjectStock.totalStock != null || hasNotStock))
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={15} mobile={16}>
                  <Label color="blue" attached="top left">
                    {t('productDetails.invalidRedHatCustomer')}
                  </Label>
                </Grid.Column>
              </Grid.Row>
            )
            : null
        }
        <Grid.Row>
          {
            arrayObjectStock.totalStockEm == null || Number(arrayObjectStock.totalStockEm) <= 0
              ? (
                <Grid.Column largeScreen={16} computer={16} tablet={15} mobile={16}>
                  <SubstituteProducts
                    arraySubstituteProducts={arraySubstituteProducts}
                    getShoppingCartCount={getShoppingCartCount}
                  />
                </Grid.Column>
              )
              : null
          }
        </Grid.Row>
        {promotionalMessage
          ? (
            <Grid.Row>
              <Grid.Column>
                <Message info>
                  {promotionalMessage}
                </Message>
              </Grid.Column>
            </Grid.Row>
          )
          : ''}
      </Grid>
    );
  }
}

ProductDetails.propTypes = {
  arrayObjectStock: PropTypes.shape({
    totalStock: PropTypes.number,
    totalStockEm: PropTypes.number,
    totalStockGdl: PropTypes.number,
    totalStockMty: PropTypes.number,
    totalStockMx: PropTypes.number,
    totalStockElec: PropTypes.number,
    totalStockGdlElec: PropTypes.number,
    totalStockMtyElec: PropTypes.number,
    totalStockMxElec: PropTypes.number,
    hasElectronic: PropTypes.bool,
  }).isRequired,
  arrayObjectStockOc: PropTypes.shape({
    totalStockEmOc: PropTypes.number,
    totalStockGdlOc: PropTypes.number,
    totalStockMtyOc: PropTypes.number,
    totalStockMxOc: PropTypes.number,
    totalStockOc: PropTypes.number,
  }).isRequired,
  deliverDate: PropTypes.string.isRequired,
  maker: PropTypes.string.isRequired,
  arraySubstituteProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  promotion: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyPrice: PropTypes.string.isRequired,
  getShoppingCartCount: PropTypes.func.isRequired,
  hasNotStock: PropTypes.bool.isRequired,
  isValidPrice: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  promotionDeadline: PropTypes.string.isRequired,
  promotionPrice: PropTypes.string.isRequired,
  promotionalMessage: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  sku2: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isAvailableRedhat: PropTypes.bool.isRequired,
  descriptionBySku: PropTypes.string.isRequired,
  steppedPriceHp: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default translate('common', { wait: true })(ProductDetails);
