import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, List, Divider,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

const GeneralInformation = props => {
  const { t, customerArray } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
          <Header as="h4" color="blue">{t('customerDirectory.identity')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.Id')}</Header>
                <List.Description>
                  {customerArray.id}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.Name')}</Header>
                <List.Description>
                  {customerArray.name}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={9}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.RFC')}</Header>
                <List.Description>
                  {customerArray.rfc}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.tradingName')}</Header>
                <List.Description>
                  {customerArray.tradingName}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={8} textAlign="justified">
          <Header as="h4" color="blue">{t('customerDirectory.address')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.street')}</Header>
                <List.Description>
                  {customerArray.address}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.ext')}</Header>
                <List.Description>
                  {customerArray.number}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        {/* <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large" >
            <List.Item>
              <List.Content>
                <Header as="h4" color="black">ciudad</Header>
                <List.Description>
                  {customerArray.city}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column> */}
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.phone')}</Header>
                <List.Description>
                  {customerArray.phone}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {/* <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large" >
            <List.Item>
              <List.Content>
                <Header as="h4" color="black">Colonia</Header>
                <List.Description>
                  {customerArray.colony}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column> */}
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">{t('customerDirectory.postalCode')}</Header>
                <List.Description>
                  {customerArray.postalCode}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        {/* <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large" >
            <List.Item>
              <List.Content>
                <Header as="h4" color="black">Estado</Header>
                <List.Description>
                  {customerArray.state}
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column> */}
      </Grid.Row>
      <Divider section />
    </Grid>
  );
};
GeneralInformation.propTypes = {
  customerArray: PropTypes.arrayOf(PropTypes.shape({})),
};

GeneralInformation.defaultProps = {
  customerArray: [],
};
export default translate('common', { wait: true })(GeneralInformation);
