/* eslint-disable prefer-template */
import React, { Component } from 'react';
import { Grid, Search } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { LinearLoader } from './materials';
import service from '../../admin-configuration/services';
import Toast from './toast';

const formatProducts = product => {
  const hasSKU2 = product.sku2 && product.sku2 !== '';
  const sku2Description = `${product.sku2} - ${product.name}`;
  const productConfig = {
    key: product.sku,
    title: product.sku,
    description: hasSKU2 ? sku2Description : product.name,
  };
  return productConfig;
};
class SearchBySku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayProducts: [],
      results: [],
      loader: false,
      filter: '',
      filteredInfoKeyWords: [],
    };
  }

  componentDidMount() {
    this.handleGetProductsList();
  }

  handleGetProductsList = () => {
    this.setState({ loader: true });
    const { products } = this.props;
    if (!products || !products.length) {
      service.getAllListProducts()
        .then(productList => {
          const arrayOptions = productList.product.map(formatProducts);
          this.setState({ arrayProducts: productList.product, results: arrayOptions, loader: false });
        })
        .catch(error => {
          const messageError = '';
          Toast(messageError, error);
        });
    } else {
      const arrayOptions = products.map(formatProducts);
      this.setState({ arrayProducts: [...products], results: arrayOptions, loader: false });
    }
  }

  handleResultSelect = (e, { result }) => {
    const { arrayProducts } = this.state;
    const { search } = this.props;
    const arrayProduct = arrayProducts.filter(filter => filter.sku === result.title);
    const makerName = arrayProduct.map(product => product.maker);
    this.setState({ filter: result.title });
    search({ value: result.title, maker: makerName[0] });
  };

  handleSearchStringChange = (e, { value }) => {
    this.setState({ filter: value });
    this.handleFilterChange(value);
  }

  handleFilterChange = searchString => {
    const { results } = this.state;
    const keyWordsForFilter = JSON.parse(JSON.stringify(results));
    let foundData = [];
    const arraySize = 10;
    foundData = keyWordsForFilter.filter(filterInfo => filterInfo.title.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
      || filterInfo.description.toLowerCase().indexOf(searchString.toLowerCase()) >= 0);
    this.setState({ filteredInfoKeyWords: foundData.slice(0, arraySize) });
  }

  render() {
    const { loader, filteredInfoKeyWords, filter } = this.state;
    const { label } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Search
              input={{ fluid: true }}
              fluid
              aligned="center"
              icon="search"
              placeholder={label}
              onResultSelect={this.handleResultSelect}
              onSearchChange={this.handleSearchStringChange}
              value={filter}
              noResultsMessage=""
              onKeyPress={this.onKeyPress}
              results={filteredInfoKeyWords}
            />
          </Grid.Column>
        </Grid.Row>
        {
          loader
            ? (
              <Grid.Row className="no-padding-y">
                <Grid.Column>
                  <LinearLoader color="blue" />
                </Grid.Column>
              </Grid.Row>
            )
            : null
        }
      </Grid>
    );
  }
}
export default translate('common', { wait: true })(SearchBySku);

SearchBySku.defaultProps = {
  products: null,
};

SearchBySku.propTypes = {
  search: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string.isRequired,
};
