import { lightenDarkenColor } from '../../utils';

const GREEN = '#3F9C35';
const BLUE = '#00549F';
const TEAL = '#00b1f3';
const YELLOW = '#FFA100';
const RED = '#E00034';
const GRAY = '#989798';
const ERRORCOLOR = RED;

const baseColors = [
  { name: 'green', color: GREEN },
  { name: 'blue', color: BLUE },
  { name: 'teal', color: TEAL },
  { name: 'yellow', color: YELLOW },
  { name: 'red', color: RED },
  { name: 'gray', color: GRAY },
  { name: 'primary', color: BLUE },
  { name: 'secondary', color: GREEN },
  { name: '', color: GRAY },
];

export default function getColors() {
  const colors = baseColors.reduce((hashColors, { color, name }) => {
    const dark = lightenDarkenColor(color, -10);
    const light = lightenDarkenColor(color, 10);
    return ({
      ...hashColors,
      [name]: {
        main: color,
        dark,
        light,
        error: ERRORCOLOR,
      },
    });
  }, {});
  return colors;
}
