import React, { Component } from 'react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { translate } from 'react-i18next';
import { Grid, Button, Header } from 'semantic-ui-react';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import '../style.css';
import routes from '../route-names';
import * as session from '../common/sessions';

class FlexAttachList extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);

    if (!hasAccess) props.history.push('/perfil');

    this.state = {
      messageTable: '',
      filter: '',
      arrayProducts: [],
      headersProductsArray: [
        { key: 'id', isKey: true },
        { key: 'product', name: 'Articulo' },
        { key: 'type', name: 'Tipo' },
        { key: 'discount', name: 'Descuento' },
        { key: 'rp', name: 'Rp' },
      ],
      shouldClean: false,
    };
  }

  componentDidMount() {
    // this.handleGetFlexAttachList();
  }

  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  goBack = () => {
    const { history } = this.props;
    history.push(routes.profile.route);
  }

  goNext = nextStep => {
    const { history } = this.props;
    history.push(nextStep);
  }

  handleFilterProduct = event => {
    const { arrayProducts } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, arrayProducts);
  }

  render() {
    const { t } = this.props;
    const {
      headersProductsArray, messageTable, shouldClean, filter, filteError,
      arrayProductFiltered,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Grid.Row>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14} />
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={8}>
            <Header color="blue" textAlign="left" as="h2">{t('flexAttach.flexTitle')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={14}>
            <Header className="userMessage" as="h4">{t('flexAttach.flexDescription')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('flexAttach.searchProduct')}
              onChange={this.handleFilterProduct}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
            <Button onClick={() => this.setState({})} content={t('add')} labelPosition="left" icon="add List" />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ overflowX: 'scroll' }}>
            <SiclikTable
              selectable
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              data={arrayProductFiltered}
              headers={headersProductsArray}
              pageSize={5}
              noDataMessage={messageTable}
              singleSelection
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(FlexAttachList);
