import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Step, Responsive } from 'semantic-ui-react';

const ProcessMonitoring = ({ steps }) => (
  <Grid.Row>
    <Grid.Column>
      <Responsive as={Step.Group} minWidth={770}>
        <Step.Group items={steps} widths={5} />
      </Responsive>
      <Responsive as={Step.Group} maxWidth={768}>
        <Step.Group stackable="tablet" items={steps} />
      </Responsive>
    </Grid.Column>
  </Grid.Row>
);

ProcessMonitoring.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})),
};

ProcessMonitoring.defaultProps = {
  steps: [],
};

export default ProcessMonitoring;
