import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Modal,
  Image,
  Divider,
  Button,
  Segment,
  Label,
  Checkbox,
} from 'semantic-ui-react';

import { DEFAULT_IMAGE } from '../../constants';

const TMB_SIZE = 'thumbnail';
const LOW_SIZE = 'low';
const MEDIUM_SIZE = 'medium';
const BIG_SIZE = 'big';
const translatePath = 'productConfig.menus.editContent.images.sizeDetails';

function ImageSizes(props) {
  const {
    t,
    itemImageSizes,
    open,
    setOpen,
    changeMainImage,
    isImageType,
  } = props;

  const [mainImage, setMainImage] = useState(itemImageSizes.value.isMain);
  const [deleteImage, setDeleteImage] = useState({
    [TMB_SIZE]: false,
    [LOW_SIZE]: false,
    [MEDIUM_SIZE]: false,
    [BIG_SIZE]: false,
  });

  const defineImage = (image, size) => (
    image && image.value[size]
      ? image.value[size]
      : DEFAULT_IMAGE
  );

  const handleOnChange = (e, { checked }) => {
    setMainImage(checked);
    changeMainImage(itemImageSizes);
  };

  const handleOnClose = () => {
    setOpen(false);
    setDeleteImage(
      Object
        .keys(deleteImage)
        .reduce((acc, key) => ({ ...acc, [key]: false }), {}),
    );
  };

  return (
    <Modal
      closeIcon
      open={open}
      onClose={handleOnClose}
    >
      <Modal.Header>
        {t(`${translatePath}.title`)}
        {isImageType && (
          <Checkbox
            toggle
            style={{ float: 'right', fontSize: '.6em' }}
            label={t(`${translatePath}.checkbox`)}
            checked={mainImage}
            readOnly={mainImage}
            onChange={handleOnChange}
          />
        )}
      </Modal.Header>
      <Modal.Content>
        <div>
          <div>
            <Segment raised>
              <Label color="blue" attached="top left">{t(`${translatePath}.thumbnail`)}</Label>
              <Image
                centered
                bordered
                fluid
                src={defineImage(itemImageSizes, 'thumbnail')}
                size="small"
              />
            </Segment>
          </div>
          <Divider hidden />
          <div>
            <Segment raised>
              <Label color="blue" attached="top left">{t(`${translatePath}.small`)}</Label>
              <Image
                centered
                bordered
                fluid
                src={defineImage(itemImageSizes, 'low')}
                size="medium"
              />
            </Segment>
          </div>
          <Divider hidden />
          <div hidden={!isImageType}>
            <Segment raised>
              <Label color="blue" attached="top left">{t(`${translatePath}.medium`)}</Label>
              <Image
                centered
                bordered
                fluid
                src={defineImage(itemImageSizes, 'medium')}
                size="large"
              />
            </Segment>
          </div>
          <Divider hidden />
          <div hidden={!isImageType}>
            <Segment raised>
              <Label color="blue" attached="top left">{t(`${translatePath}.large`)}</Label>
              <Image
                centered
                bordered
                fluid
                src={defineImage(itemImageSizes, 'big')}
                size="massive"
              />
            </Segment>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t(`${translatePath}.close`)}
          onClick={handleOnClose}
        />
      </Modal.Actions>
    </Modal>
  );
}

ImageSizes.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  changeMainImage: PropTypes.func.isRequired,
  isImageType: PropTypes.bool,
  itemImageSizes: PropTypes.shape({
    source: PropTypes.string.isRequired,
    value: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMain: PropTypes.bool.isRequired,
      thumbnail: PropTypes.string,
      low: PropTypes.string,
      medium: PropTypes.string,
      big: PropTypes.string,
    }),
  }).isRequired,
};

ImageSizes.defaultProps = {
  isImageType: false,
};

export default translate('common', { wait: true })(ImageSizes);
