import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Grid, Label, Segment, Header, Transition, Message, Menu, Placeholder, Dropdown,
} from 'semantic-ui-react';
import ProductDescription from './components/product-descriptions';
import Specifications from './components/specifications';
import Descriptions from './components/description';
import Features from './components/features';
import Toast from '../common/components/toast';
import products from './services';
import './styles.css';

function GeneralPlaceholder() {
  return (
    <Grid padded>
      <Grid.Row centered>
        <Grid.Column width={16} stretched>
          <Placeholder.Line full />
        </Grid.Column>
        <Grid.Column width={16}>
          <Placeholder>
            <Placeholder.Line full />
            <Placeholder.Line full />
            <Placeholder.Line full />
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} floated="left">
          <Placeholder>
            <Placeholder.Image square />
          </Placeholder>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

const formatProducts = product => {
  const productConfig = {
    key: product.sku,
    value: product.sku,
    text: `${product.sku} - ${product.name}`,
  };
  return productConfig;
};

class CompareProducts extends Component {
  constructor(props) {
    super(props);
    const {
      location, firstSku, secondSku,
    } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const firstProduct = firstSku || decodeURIComponent(urlParams.get('first')) || '';
    const secondProduct = secondSku || decodeURIComponent(urlParams.get('second')) || '';
    this.state = {
      firstProduct,
      secondProduct,
      visible: false,
      loaderAllProducts: true,
      allProducts: [],
      errorMessage: '',
      typeMessage: '',
      productsData: {},
      makersPercents: {},
      activeItem: 'description',
    };
  }

  componentDidMount() {
    this.getAllProducts();
    this.getMakersTable();
    this.getBothProducts();
    window.scrollTo(0, 0);
  }

  getAllProducts = () => {
    products.getAllProducts()
      .then(productList => {
        const arrayOptions = productList.product.map(formatProducts);
        this.setState({ allProducts: arrayOptions, loaderAllProducts: false });
      })
      .catch(error => {
        const messageError = '';
        Toast(messageError, error);
        this.setState({ loaderAllProducts: false });
      });
  }

  getBothProducts = async () => {
    const { firstProduct, secondProduct } = this.state;
    if (firstProduct) await this.getArrayProducts(firstProduct);
    if (secondProduct) await this.getArrayProducts(secondProduct);
  }

  getMakersTable = async () => {
    const { makers } = await products.getAllMakers();
    const makersPercents = makers.reduce((hashMakers, { minPercent, name }) => ({
      ...hashMakers,
      [name]: minPercent,
    }), {});
    this.setState({ makersPercents });
  }

  getImages = async (arrayImagesRequest, productId) => {
    const { t } = this.props;
    const sku = productId;
    let cnetImages = arrayImagesRequest;
    if (cnetImages && cnetImages.length > 1) {
      const arrayCSImages = await products.getAllImages(encodeURIComponent(sku));
      const filterVideos = arrayCSImages.images.filter(data => data.url.slice(data.url.lastIndexOf('.')) === '.mp4');
      const urlVideos = filterVideos.map(video => video.url);
      cnetImages = arrayImagesRequest.slice(0, 7).map(data => data.big);
      const combinedFiles = cnetImages.concat(urlVideos);
      return { arrayImages: combinedFiles };
    }
    try {
      const arrayCatalogImages = await products.getAllImages(encodeURIComponent(sku));
      const catalogImages = arrayCatalogImages.images.slice(0, 7).map(image => image.url);
      return ({ arrayImages: catalogImages });
    } catch (error) {
      Toast(t(`error-codes.${error.code}`, 'error'));
      return ({ arrayImages: [] });
    }
  }

  setProductInformation = async productId => {
    const { productsData } = this.state;
    const { t } = this.props;
    const data = { ...productsData };
    const item = await products.getProductInformation(productId)
      .catch(error => {
        let messageError = '';
        if (error.code === 14) {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
    const {
      title: productName, brand, sku, price, promotionPrice, description, images,
      specifications, expPromotionPrice: promotionDeadline, currency, deliverDate,
    } = item;
    const hasDescription = Boolean(description);
    data[productId] = {
      productId,
      product: item,
      productName,
      maker: brand,
      sku,
      price,
      promotionPrice,
      description: hasDescription ? description : productName,
      specifications,
      promotionDeadline,
      currency,
      arrayImages: images,
      hasNotImages: Boolean(images.length),
      hasDescription: true,
      hasNotStock: Boolean(item.inventory.stock.length),
      deliverDate,
    };
    if (item.inventory.stock) {
      const { arrayObjectStock } = this.getStock(item.inventory.stock);
      data[productId].arrayObjectStock = arrayObjectStock;
    }
    if (item.inventory.requestedStock) {
      const { arrayObjectStockOc } = this.getStockOc(item.inventory.requestedStock);
      data[productId].arrayObjectStockOc = arrayObjectStockOc;
    }
    this.setState({ productsData: data });
  }

  getArrayProducts = async productId => {
    const { productsData } = this.state;
    const data = { ...productsData };
    if (data[productId]) return;
    if (productId && productId !== 'null') {
      await this.setProductInformation(productId);
    }
  };

  getStock = arrayStock => {
    const stockGdl = arrayStock.filter(data => data.warehouse === 'GI01');
    const stockMty = arrayStock.filter(data => data.warehouse === 'YI01');
    const stockMx = arrayStock.filter(data => data.warehouse === 'MI01');
    const stockEm = arrayStock.filter(data => data.warehouse === 'MI55');
    return this.getTotalStock(stockGdl, stockMty, stockMx, stockEm);
  };

  getTotalStock = (stockGdl, stockMty, stockMx, stockEm) => {
    let total = 0;
    let totalstockGdl = stockGdl.map(data => data.stock);
    let totalstockMty = stockMty.map(data => data.stock);
    let totalstockMx = stockMx.map(data => data.stock);
    let totalstockEm = stockEm.map(data => data.stock);
    if (totalstockGdl.length === 0) totalstockGdl = 0;
    if (totalstockMty.length === 0) totalstockMty = 0;
    if (totalstockMx.length === 0) totalstockMx = 0;
    if (totalstockEm.length === 0) totalstockEm = 0;

    total += Number(totalstockGdl) + Number(totalstockMty) + Number(totalstockMx) + Number(totalstockEm);
    const totalgdl = Number(totalstockGdl);
    const totalmty = Number(totalstockMty);
    const totalmx = Number(totalstockMx);
    const totalem = Number(totalstockEm);
    return ({
      arrayObjectStock: {
        totalStock: total, totalStockGdl: totalgdl, totalStockMty: totalmty, totalStockMx: totalmx, totalStockEm: totalem,
      },
    });
  }

  getStockOc = arrayStockOc => {
    const stockOcGdl = arrayStockOc.filter(data => data.warehouse === 'GI01');
    const stockOcMty = arrayStockOc.filter(data => data.warehouse === 'YI01');
    const stockOcMx = arrayStockOc.filter(data => data.warehouse === 'MI01');
    const stockOcEm = arrayStockOc.filter(data => data.warehouse === 'MI55');
    return this.getTotalStockOc(stockOcGdl, stockOcMty, stockOcMx, stockOcEm);
  };

  getTotalStockOc = (stockGdl, stockMty, stockMx, stockEm) => {
    let total = 0;
    let totalstockGdl = stockGdl.map(data => data.quantity);
    let totalstockMty = stockMty.map(data => data.quantity);
    let totalstockMx = stockMx.map(data => data.quantity);
    let totalstockEm = stockEm.map(data => data.quantity);
    if (totalstockGdl.length === 0) totalstockGdl = 0;
    if (totalstockMty.length === 0) totalstockMty = 0;
    if (totalstockMx.length === 0) totalstockMx = 0;
    if (totalstockEm.length === 0) totalstockEm = 0;

    total += Number(totalstockGdl) + Number(totalstockMty) + Number(totalstockMx) + Number(totalstockEm);
    const totalgdl = Number(totalstockGdl);
    const totalmty = Number(totalstockMty);
    const totalmx = Number(totalstockMx);
    const totalem = Number(totalstockEm);

    return ({
      arrayObjectStockOc: {
        totalStockOc: total, totalStockGdlOc: totalgdl, totalStockMtyOc: totalmty, totalStockMxOc: totalmx, totalStockEmOc: totalem,
      },
    });
  }

  handleSelection = (e, { name }) => this.setState({ activeItem: name });

  handleChangeProduct = (value, field) => {
    this.setState({ [field]: value });
    this.getArrayProducts(value);
  }

  render() {
    const {
      productsData, firstProduct, secondProduct, allProducts, loaderAllProducts,
      visible, errorMessage, typeMessage, makersPercents, activeItem,
    } = this.state;

    const { t, updateCartCount } = this.props;
    const firstProductData = productsData[firstProduct];
    const secondProductData = productsData[secondProduct];
    let componentView;

    if (activeItem === 'description' && firstProductData) {
      componentView = (
        <Grid.Row>
          <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment>
              <Label attached="top">
                <Header as="h3" textAlign="center" color="blue">
                  {t('productDetails.description')}
                </Header>
              </Label>
              <Grid.Row style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
                <Descriptions firstProduct={firstProductData} secondProduct={secondProductData} />
              </Grid.Row>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    }
    if (activeItem === 'features' && firstProductData) {
      componentView = (
        <Grid.Row>
          <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment>
              <Label attached="top">
                <Header as="h3" textAlign="center" color="blue">
                  {t('productDetails.features')}
                </Header>
              </Label>
              <Grid.Row style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
                <Features firstProduct={firstProductData} secondProduct={secondProductData} />
              </Grid.Row>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    }
    if (activeItem === 'specifications' && firstProductData) {
      componentView = (
        <Grid.Row>
          <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment padded="very">
              <Label attached="top">
                <Header as="h3" textAlign="center" color="blue">
                  {t('productDetails.specifications')}
                </Header>
              </Label>
              <Specifications productsData={productsData} firstProduct={firstProduct} secondProduct={secondProduct} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    }

    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column width={8}>
            {
              ([
                (!loaderAllProducts && (
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column computer={14} tablet={14} mobile={14}>
                        <Dropdown
                          options={allProducts}
                          selection
                          fluid
                          value={firstProduct}
                          onChange={(e, { value }) => this.handleChangeProduct(value, 'firstProduct')}
                          placeholder={t('Producto')}
                          search
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )),
                firstProductData
                  ? (
                    <ProductDescription
                      t={t}
                      productId={firstProductData.productId}
                      arrayImages={firstProductData.arrayImages}
                      productName={firstProductData.productName}
                      sku={firstProductData.sku}
                      maker={firstProductData.maker}
                      product={firstProductData.product}
                      price={firstProductData.price}
                      promotionPrice={firstProductData.promotionPrice}
                      promotionDeadline={firstProductData.promotionDeadline}
                      currencyPrice={firstProductData.currency}
                      arrayObjectStock={firstProductData.arrayObjectStock}
                      arrayObjectStockOc={firstProductData.arrayObjectStockOc}
                      arraySteppedPrice={firstProductData.arraySteppedPrice}
                      getShoppingCartCount={updateCartCount}
                      makersPercents={makersPercents}
                      hasNotImages={firstProductData.hasNotImages}
                      hasNotStock={firstProductData.hasNotStock}
                      deliverDate={firstProductData.deliverDate}
                    />
                  )
                  : <GeneralPlaceholder />,
              ])
            }
          </Grid.Column>
          <Grid.Column width={8}>
            {
              ([
                (!loaderAllProducts && (
                  <Grid>
                    <Grid.Row centered>
                      <Grid.Column computer={14} tablet={14} mobile={14}>
                        <Dropdown
                          options={allProducts}
                          selection
                          fluid
                          value={secondProduct}
                          onChange={(e, { value }) => this.handleChangeProduct(value, 'secondProduct')}
                          placeholder={t('Producto')}
                          search
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )),
                secondProductData
                  ? (
                    <ProductDescription
                      t={t}
                      productId={secondProductData.productId}
                      arrayImages={secondProductData.arrayImages}
                      productName={secondProductData.productName}
                      sku={secondProductData.sku}
                      maker={secondProductData.maker}
                      product={secondProductData.product}
                      price={secondProductData.price}
                      promotionPrice={secondProductData.promotionPrice}
                      promotionDeadline={secondProductData.promotionDeadline}
                      currencyPrice={secondProductData.currency}
                      arrayObjectStock={secondProductData.arrayObjectStock}
                      arrayObjectStockOc={secondProductData.arrayObjectStockOc}
                      arraySteppedPrice={secondProductData.arraySteppedPrice}
                      updateCartCount={updateCartCount}
                      makersPercents={makersPercents}
                      steppedPriceHp={secondProductData.steppedPriceHp}
                      hasNotImages={secondProductData.hasNotImages}
                      hasNotStock={secondProductData.hasNotStock}
                      deliverDate={secondProductData.deliverDate}
                    />
                  )
                  : <GeneralPlaceholder />,
              ])
            }
          </Grid.Column>
        </Grid.Row>
        <Transition.Group animation="fly down" duration={{ hide: 0, show: 5000 }}>
          {
            visible
              ? (
                <Grid.Row>
                  <Message negative size="small" color={typeMessage} onDismiss={this.handleDismiss} content={errorMessage} />
                </Grid.Row>
              ) : null
          }
        </Transition.Group>
        <Menu fluid attached="top" tabular stackable color="blue">
          <Menu.Item
            name="description"
            active={activeItem === 'description'}
            onClick={this.handleSelection}
            content={t('productDetails.description')}
          />
          {
            (firstProductData && firstProductData.features && firstProductData.features.length > 0)
              ? (
                <Menu.Item
                  name="features"
                  active={activeItem === 'features'}
                  onClick={this.handleSelection}
                  content={t('productDetails.features')}
                />
              )
              : null
          }
          {
            (firstProductData && firstProductData.specifications && firstProductData.specifications.length > 0)
              ? (
                <Menu.Item
                  name="specifications"
                  active={activeItem === 'specifications'}
                  onClick={this.handleSelection}
                  content={t('productDetails.specifications')}
                />
              )
              : null
          }
        </Menu>
        {componentView}
      </Grid>
    );
  }
}

CompareProducts.defaultProps = {
  updateCartCount: () => { },
  t: () => { },
  firstSku: '',
  secondSku: '',
};

CompareProducts.propTypes = {
  firstSku: PropTypes.string,
  secondSku: PropTypes.string,
  updateCartCount: PropTypes.func,
  t: PropTypes.func,
};

export default CompareProducts;
