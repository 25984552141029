import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid, Image, Dropdown } from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';

class BundleOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilityHeaders: [
        { key: 'id', isKey: true },
        { key: 'component', name: 'componente' },
        { key: 'componentName', name: 'nombre' },
        {
          key: 'componentImage',
          format: cell => (cell ? <Image src={cell} size="small" /> : <Image spaced="rigth" size="small"> </Image>),
          name: 'imagen',
        },
      ],
      // hpMapIdSelected: false,
      // mapIdHpInputView: false,
      // hpMapIdError: '',
      // hpMapId: '',
    };
  }

  render() {
    const { availabilityHeaders } = this.state;
    const { arrayBundleOptions } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Dropdown.Menu>
              <Dropdown.Header
                content={(
                  <SiclikTable
                    selectable
                    singleSelection
                    data={arrayBundleOptions}
                    headers={availabilityHeaders}
                  />
                  )}
              />
            </Dropdown.Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

BundleOptions.propTypes = {
  arrayBundleOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default BundleOptions;
