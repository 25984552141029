import securityService from '@compusoluciones-pdigitales/siclik-security';

const {
  general,
  cotizacion: quotation,
  compra: purchase,
  pedidos: orders,
  envios: shipping,
  finanzas: finance,
  reportes: reports,
  usuarios: users,
  generarTokens: generateTokens,
  usuariosFinales: finalUsers,
  distribuidores: customers,
  cupones: coupons,
  autorizarRevisiones: authorizeReviews,
  autorizarCupones: authorizeCoupons,
  carrito: shoppingCart,
  marketing,
} = securityService.getAccess;

const roles = {
  customerAdmin: [general, quotation, purchase, orders, shipping, finance, reports, users, finalUsers, customers],
  sales: [general, quotation, reports, users, coupons, shoppingCart],
  buyer: [general, quotation, purchase, orders, shipping, reports, users, finalUsers, shoppingCart],
  adminCS: [general, quotation, purchase, orders, shipping, finance, reports, users, generateTokens, finalUsers, customers, coupons, authorizeReviews, authorizeCoupons, marketing],
  logisticAgent: [shipping],
  guest: [general, quotation, orders, finance, reports, users, finalUsers, customers, shoppingCart],
};

export default roles;
