import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import moment from 'moment';
import {
  Accordion, Icon, Grid, Header,
} from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';

const ObjectiveItem = props => {
  const {
    activeIndicator, handleClick, indicators, handleClickAddStrategy, objectiveId, handleOpenDeleteIndicator,
    handleOpenDeleteStrategy, handleClickUpdateIndicator, handleClickUpdateStrategy,
  } = props;

  const headers = [
    { key: 'id', isKey: true },
    { key: 'strategy', name: 'Táctica' },
    { key: 'responsable', name: 'Responsable' },
    { key: 'date', name: 'Fecha', format: cell => (`${moment(cell).format('DD/MM/YYYY')}`) },
    { key: 'progress', name: 'Avance' },
    {
      key: 'actions',
      name: '',
      format: (cell, row) => (
        <Grid.Row>
          <Icon onClick={() => handleOpenDeleteStrategy(objectiveId, row)} color="blue" name="trash alternate" />
          <Icon onClick={() => handleClickUpdateStrategy({ objectiveId, ...row })} color="blue" name="pencil alternate" />
        </Grid.Row>
      ),
    },
  ];
  return (
    indicators.map(indicator => (
      <Accordion styled fluid exclusive={false}>
        <Accordion.Title index={indicator.keyResultId} active={activeIndicator === indicator.keyResultId} onClick={handleClick}>
          <Grid container>
            <Grid.Row>
              <Grid.Column><Icon name="dropdown" /></Grid.Column>
              <Grid.Column largeScreen={12} computer={12} tablet={12}><Header as="h5">{indicator.keyResult}</Header></Grid.Column>
              <Grid.Column>
                <Icon
                  onClick={() => handleClickAddStrategy(indicator.keyResultId, objectiveId)}
                  name="plus"
                />
              </Grid.Column>
              <Grid.Column>
                <Icon
                  onClick={() => handleOpenDeleteIndicator(objectiveId, indicator.keyResult, indicator.keyResultId)}
                  name="trash"
                />
              </Grid.Column>
              <Grid.Column>
                <Icon
                  onClick={() => handleClickUpdateIndicator(objectiveId, indicator.keyResultId, indicator.keyResult)}
                  name="pencil"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={activeIndicator === indicator.keyResultId} style={{ overflowX: 'auto' }}>
          <SiclikTable data={indicator.strategys} headers={headers} />
        </Accordion.Content>
      </Accordion>
    ))
  );
};
export default translate('common', { wait: true })(ObjectiveItem);

ObjectiveItem.defaultProps = {
  activeIndicator: 0,
  handleClick: null,
  indicators: [],
  objectiveId: 0,
  handleClickAddStrategy: null,
  handleOpenDeleteIndicator: null,
  handleOpenDeleteStrategy: null,
  handleClickUpdateStrategy: null,
};

ObjectiveItem.propTypes = {
  activeIndicator: PropTypes.number,
  handleClick: PropTypes.func,
  indicators: PropTypes.arrayOf(),
  objectiveId: PropTypes.number,
  handleClickAddStrategy: PropTypes.func,
  handleOpenDeleteIndicator: PropTypes.func,
  handleOpenDeleteStrategy: PropTypes.func,
  handleClickUpdateStrategy: PropTypes.func,
};
