const redirectCommerce = '/login/comercio';
const redirectConvencion = '/convencion';

const publicRoutes = {
  login: { route: '/login/:platform?', permission: [], isPublic: true },
  loginCS: { route: '/plataformas', permission: [], isPublic: true },
  pdfViewer: { route: '/pdf/:name?', permission: [], isPublic: true },
  HPEViewer: { route: '/comercio/tecnologiaaldia', permission: [], isPublic: true },
  home: { route: '/', permission: [], isPublic: true },
  reset: { route: '/usuario/recuperar', permission: [], isPublic: true },
  agentDirectory: { route: '/directorio-agentes', permission: [], isPublic: true },
};

const privateRoutes = {
  addBranchOffice: { route: '/sucursales/:branchOfficeId?', permission: [], isOnboarding: true },
  addUser: { route: '/usuarios', permission: [], isOnboarding: true },
  adminConfiguration: { route: '/configuracion/administrador', permission: [], redirect: redirectCommerce },
  productConfiguration: { route: '/configuracion/productos', permission: [], redirect: redirectCommerce },
  agentBusinessPlan: { route: '/agente/plan/negocios', permission: [], redirect: redirectCommerce },
  agents: { route: '/administrar/agentes', permission: [], redirect: redirectCommerce },
  appleDep: { route: '/apple/DEP', permission: [], redirect: redirectCommerce },
  brandsComercio: { route: '/marcas', permission: [], redirect: redirectCommerce },
  businessPlan: { route: '/plan/negocios', permission: [], redirect: redirectCommerce },
  checkout: { route: '/revision/:currency', permission: [], redirect: redirectCommerce },
  clientPlan: { route: '/plan/clientes', permission: [], redirect: redirectCommerce },
  compareProducts: { route: '/producto/comparar', permission: [], redirect: redirectCommerce },
  crossViewer: { route: '/finanzas', permission: [] },
  customerDirectory: { route: '/directorio/clientes', permission: [], redirect: redirectCommerce },
  powerBiDirectory: { route: '/directorio/powerbi/:idCustomer?', permission: [], redirect: redirectCommerce },
  dashboard: { route: '/tablero', permission: [] },
  deliveryAddress: { route: '/direcciones', permission: [], isOnboarding: true },
  discount: { route: '/descuentos/', permission: [], redirect: redirectCommerce },
  discountDetails: { route: '/descuento/:discountId?', permission: [], redirect: redirectCommerce },
  downloadBilling: { route: '/descargas/:customerId/:movId', permission: [], redirect: redirectCommerce },
  familyAttributes: { route: '/alta/atributos', permission: [], redirect: redirectCommerce },
  finalUser: { route: '/clientes', permission: [], isOnboarding: true },
  flexAttachList: { route: '/lista/flex', permission: [], redirect: redirectCommerce },
  fodaForm: { route: '/formulario/foda', permission: [], redirect: redirectCommerce },
  homeComercio: { route: '/comercio', permission: [], redirect: redirectCommerce },
  iquote: { route: '/iquote', permission: [], redirect: redirectCommerce },
  leaseOptions: { route: '/cotizacion/financiamiento', permission: [], redirect: redirectCommerce },
  logisticsAgent: { route: '/configuracion/envio/:orderId?', permission: [] },
  multiShipping: { route: '/multi/envio/:orderId?', permission: [], redirect: redirectCommerce },
  onBoarding: { route: '/abordaje', permission: [], isOnboarding: true },
  openPay: { route: '/openpay', permission: [], redirect: redirectCommerce },
  orderDetails: { route: '/pedidos/detalle/:orderId?', permission: [], redirect: redirectCommerce },
  orderDetailsAgent: { route: '/agente/pedidos/detalle/:orderId?', permission: [], redirect: redirectCommerce },
  orders: { route: '/pedidos', permission: [], redirect: redirectCommerce },
  ordersAgent: { route: '/agente/pedidos', permission: [], redirect: redirectCommerce },
  productDetails: { route: '/producto/detalle/:sku', permission: [], redirect: redirectCommerce },
  products: { route: '/productos', permission: [], redirect: redirectCommerce },
  stockProducts: { route: '/productos/:availability', permission: [], redirect: redirectCommerce },
  profile: { route: '/perfil', permission: [], isOnboarding: true },
  quotationDetail: { route: '/cotizaciones/detalle/:quoteId?', permission: [], redirect: redirectCommerce },
  quotationDetailAgent: { route: '/cotizaciones/agente/detalle/:quoteId/:customerId', permission: [], redirect: redirectCommerce },
  quotations: { route: '/cotizaciones', permission: [], redirect: redirectCommerce },
  quotationsAgent: { route: '/cotizaciones/agente', permission: [], redirect: redirectCommerce },
  shoppingCart: { route: '/carrito', permission: [], redirect: redirectCommerce },
  wishList: { route: '/usuario/favoritos', permission: [], redirect: redirectCommerce },
  payment: { route: '/recibo/:type', permission: [], redirect: redirectCommerce },
  intelligenceDashboard: { route: '/agente/inteligencia', permission: [], redirect: redirectCommerce },
  couponsSearch: { route: '/agente/cupones/buscador', permission: [], redirect: redirectCommerce },
  testApisSiclik: { route: '/test/apis/siclik', permission: [], redirect: redirectCommerce },
};

const convencionRoutes = {
  homeConvencion: { route: '/convencion', redirect: redirectConvencion, isPublic: true },
  conventionRegister: { route: '/convencion/registro', redirect: redirectConvencion, isPublic: true },
  conventionDestination: { route: '/convencion/destino', redirect: redirectConvencion, isPublic: true },
  conventionFAQ: { route: '/convencion/preguntas', redirect: redirectConvencion, isPublic: true },
};

export default {
  ...publicRoutes,
  ...privateRoutes,
  ...convencionRoutes,
};
