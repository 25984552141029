import cookie from 'js-cookie';
import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const user = {};

user.getUser = userId => (
  request({
    route: apiRoutes.getUserInformation(userId),
    method: 'GET',
  })
);
user.updateUser = body => (
  request({
    route: apiRoutes.updateUser(),
    method: 'PUT',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookie.get('token')}`,
    },
  })
);
user.updatePassword = (body, token) => (
  request({
    route: apiRoutes.updatePassword(),
    method: 'PUT',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
);
user.updateStep = body => (
  request({
    route: apiRoutes.updateStep(),
    method: 'PUT',
    body,
  })
);

user.authenticationCode = () => (
  request({
    route: apiRoutes.authenticationCode(),
    method: 'POST',
    body: { originId: 1 },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookie.get('token')}`,
    },
  })
);

user.validateCode = (secretKey, userId) => (
  request({
    route: apiRoutes.validateCode(secretKey, userId),
    method: 'GET',
  })
);

export default user;
