export const CONTENT_SOURCE = {
  ERP: 'erp',
  CONTENT_PROVIDER: 'content_provider',
  MANUAL: 'manual',
};

export const DEFAULT_IMAGE = 'https://react.semantic-ui.com/images/wireframe/image.png';

export const IMAGE_SIZES = [
  {
    index: 1,
    size: 'thumbnail',
    position: 'horizontal',
    width: 75,
    height: 75,
  },
  {
    index: 1,
    size: 'thumbnail',
    position: 'vertical',
    width: 75,
    height: 75,
  },
  {
    index: 1,
    size: 'thumbnail',
    position: 'square',
    width: 75,
    height: 75,
  },
  {
    index: 2,
    size: 'low',
    position: 'horizontal',
    width: 250,
    height: 180,
  },
  {
    index: 2,
    size: 'low',
    position: 'vertical',
    width: 180,
    height: 250,
  },
  {
    index: 2,
    size: 'low',
    position: 'square',
    width: 180,
    height: 180,
  },
  {
    index: 3,
    size: 'medium',
    position: 'horizontal',
    width: 500,
    height: 300,
  },
  {
    index: 3,
    size: 'medium',
    position: 'vertical',
    width: 300,
    height: 500,
  },
  {
    index: 3,
    size: 'medium',
    position: 'square',
    width: 300,
    height: 300,
  },
  {
    index: 4,
    size: 'big',
    position: 'horizontal',
    width: 1000,
    height: 560,
  },
  {
    index: 4,
    size: 'big',
    position: 'vertical',
    width: 560,
    height: 1000,
  },
  {
    index: 4,
    size: 'big',
    position: 'square',
    width: 560,
    height: 560,
  },
];

export const LOGO_SIZES = [
  {
    index: 1,
    size: 'thumbnail',
    position: 'horizontal',
    width: 75,
    height: 75,
  },
  {
    index: 1,
    size: 'thumbnail',
    position: 'vertical',
    width: 75,
    height: 75,
  },
  {
    index: 1,
    size: 'thumbnail',
    position: 'square',
    width: 75,
    height: 75,
  },
  {
    index: 2,
    size: 'low',
    position: 'horizontal',
    width: 300,
    height: 130,
  },
  {
    index: 2,
    size: 'low',
    position: 'vertical',
    width: 130,
    height: 300,
  },
  {
    index: 2,
    size: 'low',
    position: 'square',
    width: 130,
    height: 130,
  },
];
