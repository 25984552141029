import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid, Image, Label } from 'semantic-ui-react';

function Message(props) {
  const {
    fromMe: propsFromMe, username, date, userEmitImage, message, ownImage,
  } = props;
  const fromMe = propsFromMe ? 'from-me' : '';
  return (
    <Grid container className={`chat-message ${fromMe}`}>
      <Grid.Row className="chat-without-spaces">
        <Grid.Column className="message-info">
          <b>{fromMe ? '' : username}</b>
          {' '}
          {date}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="chat-message-table">
        <Grid.Column className="chat-without-spaces" floated="left" largeScreen={1} computer={1} tablet={1} mobile={2} textAlign="left">
          <Image src={userEmitImage} style={{ display: fromMe ? 'none' : 'grid', margin: '0', borderRadius: '500rem' }} />
        </Grid.Column>
        <Grid.Column className="chat-without-spaces" largeScreen={14} computer={14} tablet={14} mobile={12} textAlign={fromMe ? 'right' : 'left'}>
          <Label basic color={fromMe ? 'green' : 'blue'} pointing={fromMe ? 'right' : 'left'} size="big" style={{ textAlign: 'justify' }}>
            {message}
          </Label>
        </Grid.Column>
        <Grid.Column className="chat-without-spaces" floated="right" largeScreen={1} computer={1} tablet={1} mobile={2} textAlign="left">
          <Image id="ownImage" src={ownImage} style={{ display: fromMe ? 'flex' : 'none', margin: '0', borderRadius: '500rem' }} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Message.propTypes = {
  date: PropTypes.string,
  fromMe: PropTypes.bool,
  message: PropTypes.string,
  ownImage: PropTypes.string,
  userEmitImage: PropTypes.string,
  username: PropTypes.string,
};

Message.defaultProps = {
  message: '',
  username: '',
  fromMe: false,
  ownImage: '',
  userEmitImage: '',
  date: '',
};

export default translate('common', { wait: true })(Message);
