/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { translate } from 'react-i18next';
import {
  Grid,
  Header,
  Form,
  Button,
  Accordion,
  Icon,
  Segment,
  Label,
  Divider,
} from 'semantic-ui-react';

import ProductList from './components/product-list';
import BrandList from './components/brand-list';
import Toast from '../common/components/toast';

import { syncIcecatProducts, syncProductCatalog } from './services';

const ICECAT_SYNC = 'icecat';
const ERP_SYNC = 'erp';
const GROUP_BY_SKU = 'sku';
const GROUP_BY_BRAND = 'brand';
const BUTTON_SYNC = 'sync';
const BUTTON_SYNCING = 'syncing';

const DropdownObject = {
  value: '',
  error: false,
};

function ProductSynchronization(props) {
  const { t } = props;
  const [syncType, setSyncType] = useState(DropdownObject);
  const [itemGroup, setItemGroup] = useState(DropdownObject);
  const [selectedBrand, setSelectedBrand] = useState(DropdownObject);
  const [selectedItems, setSelectedItems] = useState({ value: [], error: false });
  const [actionButtonType, setActionButtonType] = useState(BUTTON_SYNC);
  const [activeIndex, setActiveIndex] = useState(false);
  const [syncResults, setSyncResults] = useState(false);
  const [syncedItems, setSyncedItems] = useState([]);

  const syncTypes = [
    { key: 1, value: ERP_SYNC, text: t('synchronization.syncTypes.erp') },
    { key: 2, value: ICECAT_SYNC, text: t('synchronization.syncTypes.icecat') },
  ];
  const itemGroups = [
    { key: 1, value: GROUP_BY_SKU, text: t('synchronization.itemGroups.items') },
    { key: 2, value: GROUP_BY_BRAND, text: t('synchronization.itemGroups.brand') },
  ];

  const validateInputs = () => {
    let hasValidInputs = true;
    if (!syncType.value) {
      setSyncType({ error: true, value: '' });
      hasValidInputs = false;
    } if (!itemGroup.value) {
      setItemGroup({ error: true, value: '' });
      hasValidInputs = false;
    } if (itemGroup.value === GROUP_BY_BRAND && !selectedBrand.value) {
      setSelectedBrand({ error: true, value: '' });
      hasValidInputs = false;
    } if (itemGroup.value === GROUP_BY_SKU && !selectedItems.value.length) {
      setSelectedItems({ error: true, value: [] });
      hasValidInputs = false;
    }
    return hasValidInputs;
  };

  const handleCurrentSync = (e, { value }) => {
    setSyncType({ value });
  };

  const handleCurrentItemGroup = (e, { value }) => {
    setSelectedBrand({ value: '' });
    setSelectedItems({ value: [] });
    setItemGroup({ value });
  };

  const handleSyncRequest = async (request, { propName, propValue }) => {
    try {
      setActionButtonType(BUTTON_SYNCING);
      setSyncResults(false);
      const { added, deleted, edited } = await request({ [propName]: propValue });
      const results = [...added.skus, ...deleted.skus, ...edited.skus];
      if (results.length) {
        setSyncedItems(results.map(sku => <Label>{sku}</Label>));
      } else {
        setSyncedItems([t('synchronization.results.noChanges')]);
      }
    } catch (err) {
      Toast(t('toast.error.general'), 'error');
    } finally {
      setActionButtonType(BUTTON_SYNC);
      setSyncResults(true);
    }
  };

  const handleSync = async () => {
    const hasValidatedInputs = validateInputs();
    if (hasValidatedInputs) {
      const payload = {
        propName: itemGroup.value === GROUP_BY_BRAND ? 'brand' : 'skus',
        propValue: itemGroup.value === GROUP_BY_BRAND ? selectedBrand.value : selectedItems.value,
      };
      if (syncType.value === ICECAT_SYNC) {
        await handleSyncRequest(syncIcecatProducts, payload);
      } else if (syncType.value === ERP_SYNC) {
        await handleSyncRequest(syncProductCatalog, payload);
      }
    }
  };

  const handleSelectedBrand = value => {
    setSelectedBrand({ value });
  };

  const handleSelectedItems = value => {
    setSelectedItems({ value });
  };

  const loadSelectedSyncComponent = () => {
    switch (itemGroup.value) {
      case GROUP_BY_BRAND:
        return <BrandList handleSelectedBrand={handleSelectedBrand} error={selectedBrand.error} />;
      case GROUP_BY_SKU:
        return <ProductList handleSelectedItems={handleSelectedItems} error={selectedItems.error} />;
      default:
        return null;
    }
  };

  const actionButtons = () => {
    if (actionButtonType === BUTTON_SYNC) {
      return (
        <Button
          primary
          content={t('synchronization.sync')}
          icon="sync"
          labelPosition="right"
          floated="right"
          onClick={handleSync}
        />
      );
    }
    return (
      <Button.Group floated="right">
        <Button primary attached="left">{t('synchronization.synced')}</Button>
        <Button primary attached="right" loading>s</Button>
      </Button.Group>
    );
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('synchronization.title')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider className="margin-divider" />
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <p>
            {t('synchronization.description')}
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Form>
            <Form.Group widths="equal">
              <Form.Field error={syncType.error}>
                <label htmlFor="a">{t('synchronization.syncTypes.title')}</label>
                <Form.Dropdown
                  placeholder={t('synchronization.syncTypes.placeholder')}
                  options={syncTypes}
                  value={syncType.value}
                  fluid
                  onChange={handleCurrentSync}
                  selection
                />
              </Form.Field>
              <Form.Field error={itemGroup.error}>
                <label htmlFor="a">{t('synchronization.itemGroups.title')}</label>
                <Form.Dropdown
                  placeholder={t('synchronization.itemGroups.placeholder')}
                  options={itemGroups}
                  value={itemGroup.value}
                  fluid
                  onChange={handleCurrentItemGroup}
                  selection
                />
              </Form.Field>
            </Form.Group>
            {loadSelectedSyncComponent()}
            {actionButtons()}
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Segment style={syncResults ? {} : { visibility: 'hidden' }}>
            <Header as="h4">{t('synchronization.results.header')}</Header>
            <Accordion fluid>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={handleClick}
              >
                <Icon name="dropdown" />
                {t('synchronization.results.title')}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <p>{syncedItems}</p>
              </Accordion.Content>
            </Accordion>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(ProductSynchronization);
