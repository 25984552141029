import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Header, Button, Grid, Image, Icon, Popup, Modal, Message,
} from 'semantic-ui-react';
import cookie from 'js-cookie';
import validator from 'validator';
import zxcvbn from 'zxcvbn';
import userInformation from './services';
import UploadPanel from '../upload-s3/components/upload-modal-profile';
import * as session from '../common/sessions';
import routes from '../route-names';
import Authentication from './components/authentication';
import Toast from '../common/components/toast';
import { getUserImage } from '../common/utils';
import { TextField } from '../common/components/materials';
import PasswordSecurity from '../password-security/PasswordSecurity';
import '../style.css';

const urlAWS = process.env.REACT_APP_URL_AWS;

class Profile extends Component {
  constructor(props) {
    super(props);
    const {
      userId, customerId, profileComplete,
    } = session.get();
    const showStartExperience = !(profileComplete || (customerId === 'G000000'));
    const { twoFactor } = session.decode(cookie.get('token'));
    this.state = {
      userId,
      company: customerId,
      disabledButton: false,
      email: '',
      name: '',
      surname: '',
      secondSurname: '',
      cellphone: '',
      officephone: '',
      newPassword: '',
      confirmPassword: '',
      confirmPasswordError: '',
      showNewPassword: false,
      showConfirmPassword: false,
      activeNewPassword: showStartExperience,
      profile: profileComplete,
      emailError: '',
      nameError: '',
      surnameError: '',
      secondSurnameError: '',
      cellphoneError: '',
      officephoneError: '',
      openModal: false,
      serviceError: false,
      auth2phase: false,
      nextAction: '',
      errorsValidation: false,
      urlImgProfile: `${urlAWS}/imagenes/defaultAvatar.png`,
      lastCellphone: '',
      showStartExperience,
      passwordScore: 0,
      twoFactorFlag: twoFactor,
    };
  }

  componentDidMount() {
    this.handleGetUserInformation();
    // this.getAvatarImage();
  }

  getAvatarImage = async newUrl => {
    const { userId } = this.state;
    const urlImg = await getUserImage(newUrl, 'user-profile/pictures', userId);
    const date = new Date().getTime();
    this.setState({ urlImgProfile: `${urlImg}?${date}` });
  }

  handleGetUserInformation = () => {
    const { userId, urlImgProfile } = this.state;
    const { t } = this.props;
    userInformation.getUser(userId)
      .then(response => {
        this.setState({
          name: response.name,
          email: response.email,
          surname: response.surname,
          secondSurname: response.secondSurname,
          officephone: response.phone,
          cellphone: response.cellPhone,
          lastCellphone: response.cellPhone,
          newPassword: '',
          confirmPassword: '',
          urlImgProfile: response.image ? response.image : urlImgProfile,
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleUpdateUser = () => {
    const { t } = this.props;
    const {
      name, surname, secondSurname, officephone, cellphone, twoFactorFlag,
    } = this.state;
    const body = {
      name,
      surname,
      secondSurname,
      phone: officephone,
      cellPhone: cellphone,
      twoFactor: twoFactorFlag,
    };
    userInformation.updateUser(body)
      .then(() => {
        // if (!this.state.newPasswordError && !this.state.confirmPasswordError) { this.updatePassword(); }
        Toast(t('Se han modificado tus datos'), 'success');
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  handleUpdatePassword = url => {
    const { t, history } = this.props;
    const { newPassword } = this.state;
    const token = cookie.get('token');
    const body = {
      password: newPassword,
    };
    userInformation.updatePassword(body, token)
      .then(() => {
        Toast(t('Contraseña actualizada'), 'success');
        history.push(url);
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  handleEmailChange = event => {
    const { t } = this.props;
    const maxLength = 55;
    const minLength = 10;
    const { value: email } = event.target;
    if (!validator.isEmail(email)) {
      this.setState({ email, emailError: t('profile.errors.notEmail') });
      return;
    }
    if (email.length > maxLength) {
      this.setState({ emailError: t('profile.errors.toLong') });
      return;
    }
    if (email.length > 0 && email.length < minLength) {
      this.setState({ email, emailError: t('profile.errors.toShort') });
      return;
    }
    if (email.length > 0 && email.length < minLength) {
      this.setState({ email, emailError: t('profile.errors.toShort') });
      return;
    }
    this.setState({ email, emailError: '' });
  }

  handleNameChange = event => {
    const { t } = this.props;
    const maxLength = 35;
    const minLength = 3;
    const { value: name } = event.target;
    if (name && name.length > 0) {
      if (!validator.isAlpha(name.replace(/ /g, ''), ['es-ES'])) {
        this.setState({ name, nameError: t('profile.errors.notAlpha') });
        return;
      }
      if (name.replace(/ /g, '').length > maxLength) {
        this.setState({ nameError: t('profile.errors.toLong') });
        return;
      }
      if (name.replace(/ /g, '').length < minLength) {
        this.setState({ name, nameError: t('profile.errors.toShort') });
        return;
      }
    }
    this.setState({ name, nameError: '' });
  }

  handleSurnameChange = event => {
    const { t } = this.props;
    const maxLength = 35;
    const minLength = 3;
    const { value: surname } = event.target;
    if (surname && surname.length > 0) {
      if (!validator.isAlpha(surname.replace(/ /g, ''), ['es-ES'])) {
        this.setState({ surname, surnameError: t('profile.errors.notAlpha') });
        return;
      }
      if (surname.length > maxLength) {
        this.setState({ surnameError: t('profile.errors.toLong') });
        return;
      }
      if (surname.length < minLength) {
        this.setState({ surname, surnameError: t('profile.errors.toShort') });
        return;
      }
    }
    this.setState({ surname, surnameError: '' });
  }

  handleSecondSurnameChange = event => {
    const { t } = this.props;
    const maxLength = 35;
    const minLength = 3;
    const { value: secondSurname } = event.target;
    if (secondSurname && secondSurname.length > 0) {
      if (!validator.isAlpha(secondSurname.replace(/ /g, ''), ['es-ES'])) {
        this.setState({ secondSurname, secondSurnameError: t('profile.errors.notAlpha') });
        return;
      }
      if (secondSurname.length > maxLength) {
        this.setState({ secondSurnameError: t('profile.errors.toLong') });
        return;
      }
      if (secondSurname.length < minLength) {
        this.setState({ secondSurname, secondSurnameError: t('profile.errors.toLong') });
        return;
      }
    }
    this.setState({ secondSurname, secondSurnameError: '' });
  }

  handleCellphoneChange = event => {
    const { t } = this.props;
    const maxLength = 10;
    const minLength = 10;
    const { value: cellphone } = event.target;
    if (cellphone && cellphone.length > 0) {
      if (cellphone.length > maxLength) {
        this.setState({ cellphone, cellphoneError: t('profile.errors.toLong') });
        return;
      }
      if (cellphone.length < minLength) {
        this.setState({ cellphone, cellphoneError: t('profile.errors.toShort') });
        return;
      }
    }
    this.setState({ cellphone, cellphoneError: '' });
  }

  handleOfficePhoneChange = event => {
    const { t } = this.props;
    const maxLength = 20;
    const minLength = 8;
    const { value: officephone } = event.target;
    if (officephone && officephone.length > 0) {
      if (officephone.length > maxLength) {
        this.setState({ officephone, officephoneError: t('profile.errors.toLong') });
        return;
      }
      if (officephone.length < minLength) {
        this.setState({ officephone, officephoneError: t('profile.errors.toShort') });
        return;
      }
    }
    this.setState({ officephone, officephoneError: '' });
  }

  handleNewPasswordChange = event => {
    const { t } = this.props;
    const { profile } = this.state;
    const minLength = 8;
    const { value: newPassword } = event.target;
    this.securityScore(newPassword);
    if (newPassword && newPassword.length > 0) {
      if ((newPassword.trim()).length === 0 && !profile) {
        this.setState({ newPassword, newPasswordError: t('profile.errors.empty') });
        return;
      }
      if (newPassword.length < minLength && !profile) {
        this.setState({ newPassword, newPasswordError: t('profile.errors.toShort') });
        return;
      }
    }
    this.setState({ newPassword, newPasswordError: '' });
  }

  securityScore = newPassword => {
    const securityResult = zxcvbn(newPassword);
    this.setState({ passwordScore: securityResult.score });
  }

  handleConfirmPasswordChange = event => {
    const { t } = this.props;
    const { value: confirmPassword } = event.target;
    const { newPassword, profile } = this.state;
    if (confirmPassword && confirmPassword.length > 0) {
      if (newPassword !== confirmPassword) {
        this.setState({ confirmPassword, confirmPasswordError: t('profile.errors.passwordsDontMatch') });
        return;
      }
      if ((confirmPassword.trim()).length === 0 && !profile) {
        this.setState({ confirmPassword, confirmPasswordError: t('profile.errors.empty') });
        return;
      }
    }
    this.setState({ confirmPassword, confirmPasswordError: '' });
  }

  validations = async () => {
    let mistakes = false;
    const { t } = this.props;
    const {
      email, name, surname, cellphone, newPassword, confirmPassword, secondSurnameError, profile, passwordScore,
    } = this.state;
    let {
      emailError, nameError, surnameError, cellphoneError, newPasswordError, confirmPasswordError,
    } = this.state;

    if (validator.isEmpty(email) || (email.trim()).length === 0) {
      emailError = t('user.errors.empty');
      mistakes = true;
    }
    if (validator.isEmpty(name)) {
      nameError = t('user.errors.empty');
      mistakes = true;
    }
    if (validator.isEmpty(surname)) {
      surnameError = t('user.errors.empty');
      mistakes = true;
    }
    if (!cellphone) {
      cellphoneError = t('profile.errors.empty');
      mistakes = true;
    }
    if (!newPassword && !profile) {
      newPasswordError = t('profile.errors.empty');
      mistakes = true;
    }
    if (!confirmPassword && !profile) {
      confirmPasswordError = t('profile.errors.empty');
      mistakes = true;
    }
    if (newPassword !== confirmPassword) {
      mistakes = true;
      confirmPasswordError = t('profile.errors.passwordsDontMatch');
    }
    if (passwordScore < 3 && !profile) {
      newPasswordError = t('profile.errors.securePass');
      mistakes = true;
    }
    if (!validator.isEmpty(emailError)) mistakes = true;
    if (!validator.isEmpty(nameError)) mistakes = true;
    if (!validator.isEmpty(surnameError)) mistakes = true;
    if (!validator.isEmpty(cellphoneError)) mistakes = true;
    if (newPasswordError === 'undefined' && !profile) mistakes = true;
    if (confirmPasswordError === 'undefined' && !profile) mistakes = true;

    await this.setState({ errorsValidation: mistakes });
    if (mistakes) {
      this.setState({
        emailError, nameError, surnameError, secondSurnameError, cellphoneError, newPasswordError, confirmPasswordError,
      });
    }
  }

  handleUpdateStep = () => {
    const { t, updateStep } = this.props;
    const body = { stepId: 2, status: 1 };
    const stepToUpdate = { key: 2, completed: true };
    userInformation.updateStep(body)
      .then(() => {
        updateStep(stepToUpdate);
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  sendAuthCode = () => {
    userInformation.authenticationCode()
      .catch(() => this.setState({}));
  };

  handleStartClick = async isCompleted => {
    const { serviceError, errorsValidation } = this.state;
    await this.validations();
    if (serviceError === false) {
      if (isCompleted === false) this.handleUpdateStep();
      if (!errorsValidation) {
        this.sendAuthCode();
        this.setState({ auth2phase: true, nextAction: '/tablero' });
        // this.props.history.push(routes.dashboard.route);
      }
    }
  }

  handleNextClick = async (nextStep, isCompleted) => {
    const {
      serviceError, profile, newPassword, lastCellphone, cellphone, errorsValidation,
    } = this.state;

    await this.validations();

    if (!serviceError) {
      if (!isCompleted) this.handleUpdateStep();
      if (!errorsValidation) {
        this.setState({ nextAction: 'next' });
        if ((profile && (newPassword !== '' || lastCellphone !== cellphone)) || (!profile)) {
          this.handleUpdateUser();
          this.sendAuthCode();
          this.setState({ auth2phase: true });
        } else {
          this.handleUpdateUser();
        }
      }
    }
  }

  panelState = visible => {
    this.setState({ openModal: visible });
  }

  nextText = () => {
    const { t } = this.props;
    const { profile, company } = this.state;
    if (profile || (company === 'G000000')) {
      return t('profile.save');
    }
    return t('profile.next');
  }

  showStartExperience = () => {
    const { profile } = this.state;
    if (profile) {
      return false;
    }
    return true;
  }

  handleChangePasswordView = () => {
    const { activeNewPassword, showStartExperience } = this.state;
    if (showStartExperience) return;
    if (activeNewPassword) this.setState({ newPassword: '', confirmPassword: '', confirmPasswordError: '' });
    this.setState({ activeNewPassword: !activeNewPassword });
  }

  handleTwoFactorChange = (event, { checked }) => {
    this.setState({ twoFactorFlag: checked });
  }

  render() {
    const { t, steps, history } = this.props;
    let nextStep = '';
    const {
      email, emailError, name, nameError, surname, surnameError, secondSurname,
      secondSurnameError, cellphone, cellphoneError, officephone, officephoneError, activeNewPassword,
      newPassword, newPasswordError, confirmPassword, confirmPasswordError, showNewPassword,
      showConfirmPassword, openModal, auth2phase, showStartExperience, urlImgProfile, passwordScore, company, nextAction,
    } = this.state;
    let { disabledButton } = this.state;
    let isCompleted = false;

    if (steps.length > 1) {
      if ((steps[0].completed === false) && (company !== 'G000000')) disabledButton = true;
      if (steps[1].completed === true) isCompleted = true;
      if (steps.length > 2) nextStep = steps[2].href;
      else nextStep = routes.dashboard.route;
    }

    return (
      <Grid className="container-profile" container>
        <Grid.Row centered>
          <Grid.Column largescreen={6} computer={6} tablet={8} mobile={14}>
            <Header textAlign="center" color="blue" as="h2">{t('profile.header')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={10} computer={10} tablet={12} mobile={14}>
            <Header textAlign="center" as="h4">{t('profile.info')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={2} computer={2} tablet={3} mobile={6}>
            <Image src={urlImgProfile} size="medium" circular />
            <Popup
              trigger={(
                <Icon
                  name="upload"
                  bordered
                  circular
                  className="icon-upload-image-profile"
                  onClick={() => this.panelState(true)}
                />
              )}
              content="Agregar o cambiar imagen"
              position="bottom left"
            />
            <Modal open={openModal}>
              <Modal.Header as="header" color="blue">{t('profile.editProfileImage')}</Modal.Header>
              <Modal.Content>
                <UploadPanel panelState={this.panelState} getAvatarImage={this.getAvatarImage} style={{ width: '100%' }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color="black" onClick={() => this.setState({ openModal: false })}>Cancelar</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
            <TextField
              fullWidth
              maxLength={55}
              value={email || ''}
              error={emailError || ''}
              label={t('profile.email')}
              onChange={this.handleEmailChange}
              type="email"
              required
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
            <TextField
              fullWidth
              maxLength={35}
              value={name || ''}
              error={nameError || ''}
              label={t('profile.name')}
              onChange={this.handleNameChange}
              type="text"
              required
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={4} computer={4} tablet={12} mobile={15}>
            <TextField
              fullWidth
              maxLength={35}
              value={surname || ''}
              error={surnameError || ''}
              label={t('profile.surname')}
              onChange={this.handleSurnameChange}
              type="text"
              required
            />
          </Grid.Column>
          <Grid.Column largescreen={4} computer={4} tablet={12} mobile={15}>
            <TextField
              fullWidth
              value={secondSurname || ''}
              error={secondSurnameError || ''}
              maxLength={35}
              label={t('profile.secondSurname')}
              onChange={this.handleSecondSurnameChange}
              type="text"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={4} computer={4} tablet={12} mobile={15}>
            <TextField
              fullWidth
              value={cellphone || ''}
              error={cellphoneError || ''}
              maxLength={10}
              label={t('profile.cellphone')}
              onChange={this.handleCellphoneChange}
              type="number"
              required
            />
          </Grid.Column>
          <Grid.Column largescreen={4} computer={4} tablet={12} mobile={15}>
            <TextField
              fullWidth
              value={officephone || ''}
              error={officephoneError || ''}
              maxLength={20}
              label={t('profile.officephone')}
              onChange={this.handleOfficePhoneChange}
              type="number"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15} textAlign="left">
            <Header
              size="small"
              style={{ fontWeight: 'normal', cursor: 'pointer', textDecoration: 'underline' }}
              onClick={this.handleChangePasswordView}
            >
              {`${t('profile.changePassword')}${activeNewPassword ? '' : '...'}`}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {
          activeNewPassword
            ? (
              <Grid.Row centered>
                <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
                  <Grid.Row centered>
                    <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
                      <TextField
                        fullWidth
                        value={newPassword || ''}
                        error={newPasswordError || ''}
                        type="password"
                        InputProps={{ autoComplete: 'new-password' }}
                        showPassword={showNewPassword}
                        label={t('profile.newPassword')}
                        onChange={this.handleNewPasswordChange}
                        required
                      />
                      <PasswordSecurity securityResult={passwordScore} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
                      <TextField
                        fullWidth
                        value={confirmPassword || ''}
                        error={confirmPasswordError || ''}
                        type="password"
                        InputProps={{ autoComplete: 'new-password' }}
                        showPassword={showConfirmPassword}
                        label={t('profile.confirmPassword')}
                        onChange={this.handleConfirmPasswordChange}
                        required
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row centered>
                    <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
                      <Message>
                        <Message.Header>{t('passwordRules.title')}</Message.Header>
                        <Message.List>
                          <Message.Item>{t('passwordRules.1')}</Message.Item>
                          <Message.Item>{t('passwordRules.2')}</Message.Item>
                          <Message.Item>{t('passwordRules.3')}</Message.Item>
                        </Message.List>
                      </Message>
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            ) : null
        }
        <Grid.Row centered>
          {
            showStartExperience
              ? (
                <Grid.Column className="columnButtonStart" largescreen={3} computer={4} tablet={5} mobile={8}>

                  <Button
                    color="blue"
                    disabled={disabledButton}
                    hidden
                    onClick={() => this.handleStartClick(isCompleted)}
                  >
                    {t('profile.start')}
                  </Button>
                </Grid.Column>
              ) : null
          }
          <Grid.Column textAlign="center" centered largescreen={3} computer={3} tablet={3} mobile={6}>
            <Button
              color="blue"
              onClick={() => this.handleNextClick(nextStep, isCompleted)}
              disabled={disabledButton}
            >
              {this.nextText()}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largescreen={8} computer={8} tablet={12} mobile={15}>
            <Modal open={auth2phase}>
              <Authentication
                history={history}
                t={t}
                newPassword={newPassword}
                sendAuthCode={this.sendAuthCode}
                updatePassword={this.handleUpdatePassword}
                nextAction={nextAction}
                validateStep={this.validateStep}
                handleUpdateStep={this.handleUpdateStep}
              />
              <Modal.Actions>
                <Button color="black" style={{ width: '130px' }} onClick={() => this.setState({ auth2phase: false })}>{t('cancel')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Profile.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    completed: PropTypes.bool,
    href: PropTypes.string,
  })).isRequired,
  t: PropTypes.func.isRequired,
  updateStep: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(Profile);
