/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Loader, Container, Dropdown,
} from 'semantic-ui-react';
import { TextField } from '../../../common/components/materials';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

class noImageProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledTextField: false,
      report: [],
      loading: false,
      makers: [{ key: 0, value: 0, text: 'Todos' }],
      makerId: 0,
      makersHash: {},
      headers: [
        { key: 'sku', isKey: true },
        { key: 'brand', name: 'Marca' },
        { key: 'sku', name: 'Sku' },
        { key: 'title', name: 'Descripción' },
      ],
    };
  }

  componentDidMount = () => {
    this.search();
    this.handleGetDataMakers();
  }

  handleGetNoImageReport = async () => {
    const { t } = this.props;
    this.setState({ report: [], loading: true });
    try {
      const { items } = await service.getImagesReport();
      const itemsTotal = items.filter(item => item.images.length < 1);
      const resultArray = itemsTotal.map(item => {
        const newConfig = {
          sku: item.sku,
          brand: item.maker.brand,
          title: item.title,
        };
        return newConfig;
      });
      return (resultArray.length > 0 ? { report: resultArray } : { report: [] });
    } catch (error) {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      if (error.code === 1005) {
        Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
      } else {
        Toast(messageError, 'error');
      }
      return ({ report: [] });
    }
  };

  handleGetDataMakers = async () => {
    const { makers, makersHash } = await service.getMakers()
      .then(({ makers: responseMakers }) => (
        responseMakers.reduce((currentResponse, maker) => {
          const { name, id } = maker;
          return ({ makers: [...currentResponse.makers, { key: id, value: name, text: name }], makersHash: { ...currentResponse.makersHash, [id]: maker } });
        }, { makers: [{ key: 0, value: '', text: 'Todos' }], makersHash: { 0: { key: 0, value: '', text: 'Todos' } } })
      ))
      .catch(() => ({ makers: [{ key: 0, value: '', text: 'Todos' }], makersHash: { 0: { key: 0, value: '', text: 'Todos' } } }));
    this.setState({ makers, makersHash });
  }

  search = async () => {
    const { report } = await this.handleGetNoImageReport();
    this.setState({ report, loading: false, filteredResults: report });
  };

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!filter) {
      this.setState({ filter, filteredResults: allReport });
    } else {
      const filteredResults = allReport.filter(filterInfo => ((filterInfo.sku && filterInfo.sku.toLowerCase().includes(baseFilter))
        || (filterInfo.brand && filterInfo.brand.toLowerCase().includes(baseFilter))
        || (filterInfo.title && filterInfo.title.toLowerCase().includes(baseFilter))));
      this.setState({ filter, filteredResults, makerId: 0 });
    }
  };

  handleChangeMaker = (e, { value }) => {
    this.setState({ makerId: value });
    const baseFilter = value.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!value) {
      this.setState({ value, filteredResults: allReport });
    } else {
      const filteredResults = allReport.filter(filterInfo => (filterInfo.brand && filterInfo.brand.toLowerCase().includes(baseFilter)));
      this.setState({ value, filteredResults });
    }
  }

  render() {
    const { t } = this.props;
    const {
      report, headers, loading, filteredResults, filter, disabledTextField, makers, makerId,
    } = this.state;
    const fileName = 'Reporte_productos_sin_imagen';

    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('intelligenceDashboard.noImageProducts')}

          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={7} tablet={6} mobile={16}>
            <TextField
              fullWidth
              maxLength={40}
              value={filter || ''}
              label={t('intelligenceDashboard.filter')}
              onChange={this.handleFilter}
              disabled={disabledTextField}
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={16}>
            <Dropdown
              options={makers}
              placeholder={t('intelligenceDashboard.maker')}
              value={makerId}
              fluid
              selection
              search
              onChange={this.handleChangeMaker}
            />
          </Grid.Column>
          {(loading || !report || report.length === 0)
            ? null
            : (
              <Grid.Column computer={3} tablet={6} mobile={16} style={{ marginLeft: '7rem' }}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={filteredResults || report}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('intelligenceDashboard.download')}
                  sheetName="ProductosSinImagen"
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  data={filteredResults || report}
                  headers={headers}
                  pageSize={15}
                />
              </Container>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(noImageProducts);
