import PropTypes from 'prop-types';
import React from 'react';
import {
  Icon, Modal, Header, Button,
} from 'semantic-ui-react';
import DeliverAddress from '../../delivery-address';

const AddDeliveryAddress = ({ t, getNewAddress }) => (
  <Modal
    size="fullscreen"
    trigger={(
      <Button>
        <Icon name="plus circle" className="icon-addDeliveryAddress" />
        {t('multi-shipping.newShippingAddress')}
      </Button>
)}
    closeIcon
  >
    <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('multi-shipping.shippingAddress')}</Header>} />
    <Modal.Content>
      <br />
      <DeliverAddress getNewAddress={getNewAddress} onboarding={false} />
    </Modal.Content>
  </Modal>
);

AddDeliveryAddress.propTypes = {
  getNewAddress: PropTypes.func,
};

AddDeliveryAddress.defaultProps = {
  getNewAddress: () => {},
};

export default AddDeliveryAddress;
