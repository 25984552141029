import React from 'react';
import { Input } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

function SearchItem({ search, filter, label }) {
  const handleSearch = event => {
    search(event.target.value);
  };

  return (
    <Input
      fluid
      aligned="center"
      icon="search"
      placeholder={label}
      onChange={handleSearch}
      value={filter || ''}
      size="small"
    />
  );
}

SearchItem.propTypes = {
  search: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  filter: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(SearchItem);
