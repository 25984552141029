import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid, TextArea, Button, Icon, Confirm,
} from 'semantic-ui-react';
import services from '../services';
import Toast from '../../common/components/toast';
import SiclikTable from '../../common/components/table';
import routes from '../../route-names';

class Authorization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewId: '',
      uen: '',
      comment: '',
      reviewsArray: [],
      uenArray: [
        { id: 2, name: 'Autodesk' },
        { id: 3, name: 'Desarrollo Emp' },
        { id: 4, name: 'Espacios Mex' },
        { id: 6, name: 'Servicios Financieros' },
        { id: 14, name: 'HP Inc' },
        { id: 15, name: 'IBM' },
        { id: 17, name: 'Microsoft' },
        { id: 19, name: 'AWS' },
        { id: 20, name: 'Oracle' },
        { id: 22, name: 'Seguridad' },
        { id: 25, name: 'Corporativo' },
        { id: 26, name: 'MKT CRP' },
        { id: 28, name: 'Lenovo' },
        { id: 29, name: 'Red Hat' },
        { id: 33, name: 'TI' },
        { id: 34, name: 'APPLE' },
        { id: 35, name: 'Transforma' },
        { id: 36, name: 'Aruba' },
        { id: 38, name: 'Compusoluciones Ventures' },
        { id: 39, name: 'Iunngo' },
        { id: 40, name: 'ID' },
        { id: 41, name: 'VMWare' },
        { id: 51, name: 'Centro de Datos' },
        { id: 46, name: 'Logística' },
      ],
      headersAgent: [
        { key: 'id', isKey: true },
        { key: 'uenName', name: 'Célula' },
        { key: 'registryDate', name: 'Fecha de solicitud' },
        {
          key: 'comments',
          name: 'Comentarios',
          format: (cell, row) => <TextArea id={row.id} value={cell} onChange={this.handleChangeComment} rows={3} />,
        },
        {
          key: 'actions',
          name: 'Aprobar/ Rechazar',
          format: (cell, row) => (
            <Grid.Row columns={2}>
              <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                <Button color="blue" icon="check" onClick={() => this.handleClickApprove(row)} />
              </Grid.Column>
              <Grid.Column mobile={8} tablet={8} computer={8} largeScreen={8} widescreen={8}>
                <Button color="blue" icon="times" onClick={() => this.handleClickReject(row)} />
              </Grid.Column>
            </Grid.Row>
          ),
        },
        { key: 'resolutionDate', name: 'Fecha de revisión' },
        { key: 'name', name: 'Revisada por' },
        {
          key: 'resolution',
          name: 'Estatus',
          format: (cell, row) => {
            let cellFormat = 'Pendiente';
            if (row.idResolution && cell === true) cellFormat = <Icon color="green" name="checkmark" size="large" />;
            if (row.idResolution && cell !== true) cellFormat = <Icon name="times" size="large" />;
            return cellFormat;
          },
        },
      ],
      headersCustomer: [
        { key: 'id', isKey: true },
        { key: 'uenName', name: 'Célula' },
        { key: 'registryDate', name: 'Fecha de solicitud' },
        { key: 'comments', name: 'Comentarios' },
        { key: 'resolutionDate', name: 'Fecha de revisión' },
        { key: 'name', name: 'Revisada por' },
        {
          key: 'resolution',
          name: 'Estatus',
          format: (cell, row) => {
            let cellFormat = 'Pendiente';
            if (row.idResolution && cell === true) cellFormat = <Icon color="green" name="checkmark" size="large" />;
            if (row.idResolution && cell !== true) cellFormat = <Icon name="times" size="large" />;
            return cellFormat;
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.getReviews();
  }

  getReviews = () => {
    const { uenArray } = this.state;
    const { reviewsArray } = this.props;
    const reviews = reviewsArray.map(review => {
      const reviewCopy = { ...review };
      const uenName = uenArray.filter(uen => uen.id === review.uen);
      if (uenName && uenName.length > 0) reviewCopy.uenName = uenName[0].name;
      if (review.registryDate && review.registryDate.length > 6) reviewCopy.registryDate = moment(review.registryDate).format('DD-MM-YYYY');
      else reviewCopy.registryDate = '';
      if (review.resolutionDate && review.resolutionDate.length > 6) reviewCopy.resolutionDate = moment(review.resolutionDate).format('DD-MM-YYYY');
      else reviewCopy.resolutionDate = '';
      return reviewCopy;
    });
    this.setState({ reviewsArray: reviews });
  }

  updateReview = review => (
    services.updateReview(review)
  )

  leaseQuoteToOrder = config => (
    services.buy(config)
  )

  updateReviews = async () => {
    const {
      t, userId: userIdentifier, quoteId, changeLoader,
    } = this.props;
    const {
      reviewId, comment, openAccept, reviewsArray,
    } = this.state;
    changeLoader(true, reviewsArray);
    const currentReview = reviewsArray.find(review => review.id === reviewId);
    const pendingReviews = this.hadPendingReviews(reviewsArray);
    const { uen } = currentReview;
    if (uen === 6 && pendingReviews) {
      Toast(t('quotation-detail.allResolutionsToLeaseError'), 'error');
      return;
    }
    const userId = userIdentifier;
    let resolution = '';
    if (openAccept) resolution = true;
    else resolution = false;
    const orderId = quoteId;
    const reviewToUptate = {
      orderId,
      reviewId,
      resolution,
      userId,
      comment,
    };
    try {
      const response = await this.updateReview(reviewToUptate);
      if (uen === 6) await this.processLeaseQuotation();
      if (openAccept) this.handleApproved(response.id);
      else this.handleRejected(response.id);
    } catch (error) {
      changeLoader(false, reviewsArray);
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
    }
  }

  handleChangeComment = (e, { value, id }) => {
    let { reviewsArray } = this.state;
    const index = reviewsArray.findIndex(findElement => findElement.id === id);
    reviewsArray = reviewsArray.map(review => {
      if (review.id === id) reviewsArray[index].comments = value;
      return review;
    });
    this.setState(reviewsArray);
  }

  handleClickApprove = row => {
    const { t } = this.props;
    if (!row.comments) {
      Toast(t('Es necesario ingresar un comentario', 'warning'));
    } else {
      this.setState({
        reviewId: row.id,
        uen: row.uenName,
        comment: row.comments,
        openAccept: true,
      });
    }
  }

  handleClickReject = row => {
    const { t } = this.props;
    if (!row.comments) {
      Toast(t('Es necesario ingresar un comentario', 'warning'));
    } else {
      this.setState({
        reviewId: row.id,
        uen: row.uenName,
        comment: row.comments,
        openReject: true,
      });
    }
  }

  handleApproved = async idResolution => {
    const { userName, changeLoader } = this.props;
    const { reviewId } = this.state;
    let { reviewsArray } = this.state;
    reviewsArray = reviewsArray.map(review => {
      const reviewCopy = { ...review };
      if (review.id === reviewId) {
        reviewCopy.resolution = true;
        reviewCopy.idResolution = idResolution;
        reviewCopy.resolutionDate = moment().format('DD-MM-YYYY');
        reviewCopy.name = userName;
      }
      return reviewCopy;
    });
    await this.setState({ reviewsArray, openAccept: false });
    changeLoader(false, reviewsArray);
  }

  handleRejected = async idResolution => {
    const { userName, changeLoader } = this.props;
    let { reviewsArray } = this.state;
    const { reviewId } = this.state;
    reviewsArray = reviewsArray.map(review => {
      const reviewCopy = { ...review };
      if (review.id === reviewId) {
        reviewCopy.resolution = false;
        reviewCopy.idResolution = idResolution;
        reviewCopy.resolutionDate = moment().format('DD-MM-YYYY');
        reviewCopy.name = userName;
      }
      return reviewCopy;
    });
    changeLoader(false, reviewsArray);
  }

  hadPendingReviews = reviews => {
    const results = reviews.map(review => {
      if (review.idResolution !== 0 && review.resolution) return true;
      if (review.uen === 6) return true;
      return false;
    });
    const flag = results.some(element => element === false);
    return flag;
  }

  processLeaseQuotation = async () => {
    const {
      t, userOrder, customerOrder, history, handleBoolField,
      customerIdByToken, quoteId: orderId, changeLoader,
    } = this.props;
    const { reviewsArray } = this.state;
    handleBoolField('loader', true);
    try {
      const config = { paymentId: 1, currencyId: 1 };
      const result = await this.leaseQuoteToOrder({
        ...config, orderId, userId: userOrder, customerId: customerOrder,
      });
      if (result) {
        Toast('Se generó el pedido correctamente', 'success');
        const route = customerIdByToken === 'G000000' ? routes.quotationsAgent.route : routes.quotations.route;
        handleBoolField('loader', false);
        history.push(route);
      }
    } catch (error) {
      changeLoader(false, reviewsArray);
      handleBoolField('loader', false);
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
    }
  }

  render() {
    const { t, customerId } = this.props;
    const {
      reviewsArray, openAccept, openReject, uen, headersAgent, headersCustomer,
    } = this.state;
    let headers = [];
    if (customerId === 'G000000') headers = headersAgent;
    else headers = headersCustomer;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column style={{ overflowX: 'auto' }} largeScreen={16} computer={16} tablet={15} mobile={16}>
            <SiclikTable
              data={reviewsArray}
              headers={headers}
              pageSize={5}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openAccept}
            header={t('quotation-detail.approveQuotation')}
            content={uen}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ openAccept: false })}
            onConfirm={this.updateReviews}
          />
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openReject}
            header={t('quotation-detail.rejectQuotation')}
            content={uen}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ openReject: false })}
            onConfirm={this.updateReviews}
          />
        </Grid.Row>
      </Grid>
    );
  }
}
Authorization.propTypes = {
  reviewsArray: PropTypes.arrayOf(PropTypes.shape({})),
  userId: PropTypes.string,
  t: PropTypes.func,
  quoteId: PropTypes.string,
  userName: PropTypes.string,
  userOrder: PropTypes.string,
  customerOrder: PropTypes.string,
  handleBoolField: PropTypes.func,
  customerIdByToken: PropTypes.string,
  customerId: PropTypes.string,
};

Authorization.defaultProps = {
  userId: '',
  reviewsArray: [],
  quoteId: '',
  userName: '',
  userOrder: '',
  customerOrder: '',
  customerIdByToken: '',
  customerId: '',
  t: () => { },
  handleBoolField: () => { },
};

Authorization.propTypes = {
  reviewsArray: PropTypes.arrayOf(PropTypes.shape({})),
  userId: PropTypes.string,
  quoteId: PropTypes.string,
  userName: PropTypes.string,
  customerId: PropTypes.string,
  customerIdByToken: PropTypes.string,
  customerOrder: PropTypes.string,
  userOrder: PropTypes.string,
  changeLoader: PropTypes.func,
  t: PropTypes.func,
  handleBoolField: PropTypes.func,
};

Authorization.defaultProps = {
  reviewsArray: [],
  userId: '',
  userName: '',
  customerId: '',
  customerIdByToken: '',
  customerOrder: '',
  userOrder: '',
  changeLoader: () => { },
  t: () => { },
  handleBoolField: () => { },
};

export default translate('common', { wait: true })(Authorization);
