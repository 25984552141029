/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Grid, Header, Icon, Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

// import SearchBar from '../../common/components/SearchBar';
import SearchItem from '../../common/components/searchItem';
import { fuse } from '../../common/utils';

// const marginBottomCorrectionStyle = {
//   marginBottom: '-20px',
// };

// const pointerCursorStyle = {
//   cursor: 'pointer',
// };

// const fontWeightStyle = {
//   fontWeight: 700,
// };

function OptionsBar(props) {
  const {
    t, items: propItems, filteredItems, onClickOpenModal, sendItemsToCart, onOpenBuyNowModal, onOpenRenameListModal, onOpenDeleteListModal, wishListName,
  } = props;
  const [filter, setFilter] = useState('');
  const [loader, setLoader] = useState(false);

  const handleSearch = keyword => {
    setFilter(keyword);
    const allItems = propItems || [];
    const items = fuse(allItems, ['sku', 'name']);
    const searchResult = items.search(keyword);
    const result = keyword === '' ? allItems : searchResult;
    filteredItems(result.length ? result : false);
  };

  const handleOpenModalAddItem = () => {
    onClickOpenModal();
  };

  const handleOnSendToCart = async () => {
    try {
      setLoader(true);
      await sendItemsToCart();
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleModal = () => {
    onOpenBuyNowModal();
  };

  const handleRenameList = () => {
    onOpenRenameListModal();
  };

  const handleDeleteList = () => {
    onOpenDeleteListModal();
  };

  return (
    <Grid>
      <Grid.Row verticalAlign="middle" className="container-title">
        <Grid.Column verticalAlign="middle" largeScreen={10} computer={10} tablet={5} mobile={12}>
          <Header style={{ fontSize: '1.5rem' }} color="blue">{wishListName}</Header>
        </Grid.Column>
        <Grid.Column verticalAlign="middle" textAlign="right" only="largeScreen computer tablet" largeScreen={3} computer={3} tablet={6}>
          <Button size="small" basic color="grey" onClick={handleRenameList}>
            <Icon color="grey" name="edit" />
            <b>{t('wishList.rename')}</b>
          </Button>
        </Grid.Column>
        <Grid.Column verticalAlign="middle" textAlign="right" only="mobile" mobile={2}>
          <Icon color="grey" name="edit" size="large" onClick={handleRenameList} />
        </Grid.Column>
        <Grid.Column verticalAlign="middle" textAlign="right" only="largeScreen computer tablet" largeScreen={3} computer={3} tablet={5}>
          <Button size="small" basic color="grey" onClick={handleDeleteList}>
            <Icon color="grey" name="trash" />
            <b>{t('wishList.optionsBar.deleteList')}</b>
          </Button>
        </Grid.Column>
        <Grid.Column verticalAlign="middle" textAlign="right" only="mobile" mobile={2}>
          <Icon color="grey" name="trash" size="large" onClick={handleDeleteList} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column className="padding-y-5" verticalAlign="middle" largeScreen={5} computer={5} tablet={8} mobile={16}>
          <SearchItem
            search={handleSearch}
            filter={filter}
            label={t('wishList.optionsBar.search')}
          />
        </Grid.Column>
        <Grid.Column className="padding-y-5" verticalAlign="middle" largeScreen={4} computer={4} tablet={8} mobile={16}>
          <Button fluid size="small" color="grey" onClick={handleOpenModalAddItem}>
            <Icon color="white" name="add" />
            <b>{t('wishList.optionsBar.addItem')}</b>
          </Button>
        </Grid.Column>
        <Grid.Column className="padding-y-5" verticalAlign="middle" largeScreen={3} computer={3} tablet={8} mobile={16}>
          <Button fluid size="small" color="green" onClick={handleModal}>
            <Icon className="whiteText" name="shipping fast" />
            <b>{t('wishList.buyNow.header')}</b>
          </Button>
        </Grid.Column>
        <Grid.Column className="padding-y-5" verticalAlign="middle" textAlign="right" largeScreen={4} computer={4} tablet={8} mobile={16}>
          <Button fluid size="small" color="blue" onClick={handleOnSendToCart} loading={loader}>
            <Icon color="white" name="shopping cart" />
            <b>{t('wishList.optionsBar.sendListToCart')}</b>
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

OptionsBar.propTypes = {
  wishListName: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  filteredItems: PropTypes.func.isRequired,
  onClickOpenModal: PropTypes.func.isRequired,
  sendItemsToCart: PropTypes.func.isRequired,
  onOpenBuyNowModal: PropTypes.func.isRequired,
  onOpenRenameListModal: PropTypes.func.isRequired,
  onOpenDeleteListModal: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(OptionsBar);
