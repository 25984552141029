import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Popup, Label, Divider,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { currencyFormat } from '../../common/utils';
import couponTypes from '../../common/discount-types';

const Payment = props => {
  const { t, currency: propCurrency, checkoutData } = props;
  const purchaseOrder = checkoutData[0];
  const currency = propCurrency === 2 ? 'USD' : 'MXN';
  const appliedSubtotalCoupons = purchaseOrder?.coupons.filter(coupon => coupon.type === couponTypes.subtotal.discount);
  const subtotalDiscount = purchaseOrder?.appliedDiscounts.find(discount => discount.detail === couponTypes.subtotal.discount) || { discount: 0 };
  const oldSubtotal = appliedSubtotalCoupons && appliedSubtotalCoupons.length ? (purchaseOrder?.subtotal + subtotalDiscount.discount) : 0;
  const shippingCoupons = purchaseOrder?.coupons.filter(coupon => coupon.type === couponTypes.shipping.discount);
  const appliedShippingDiscount = purchaseOrder?.appliedDiscounts.filter(coupon => coupon.detail === couponTypes.shipping.discount);

  const handleRenderCouponDetails = coupons => (
    <Popup
      size="huge"
      trigger={<Label pointing="left" color="blue">{t('checkout.seeCoupon')}</Label>}
      flowing
      on="click"
      pointing
    >
      {coupons.map(item => (
        <Grid padded columns={2}>
          <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={7}>
            <Header as="h4" color="blue">
              {t('checkout.couponCode')}
              &nbsp;
              <Header as="h5" color="black">
                {' '}
                {item.code}
              </Header>
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9}>
            <Header as="h4" color="blue">
              {item.type === 'shipping' ? t('checkout.discountShippingApplied') : t('checkout.discountApplied')}
              &nbsp;
              <Header as="h5" color="green">
                {' '}
                {item.discount ? currencyFormat(item.discount, currency) : item.days}
                {shippingCoupons.length && item.discount === 0 && appliedShippingDiscount.length ? 'Gratis' : ''}
              </Header>
            </Header>
          </Grid.Column>
          <Divider horizontal />
        </Grid>
      ))}
    </Popup>
  );

  return (
    <Grid container centered className="styleSummary">
      {checkoutData.map(data => (
        <Grid>
          {(appliedSubtotalCoupons && appliedSubtotalCoupons.length > 0)
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h5" color="black">
                    {t('checkout.subtotal')}
                  </Header>
                  {handleRenderCouponDetails(appliedSubtotalCoupons)}
                </Grid.Column>
                <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
                  <Header as="h5">
                    <i><strike>{currencyFormat(oldSubtotal, currency)}</strike></i>
                  </Header>
                  <Header color="blue" as="h5">{currencyFormat(data.subtotal, currency)}</Header>
                </Grid.Column>
              </Grid.Row>
            )
            : null}
          {(appliedSubtotalCoupons && appliedSubtotalCoupons.length < 1)
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h5" color="black">{t('checkout.subtotal')}</Header>
                </Grid.Column>
                <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
                  <Header as="h5">{currencyFormat(data.subtotal, currency)}</Header>
                </Grid.Column>
              </Grid.Row>
            )
            : null}
          <Grid.Row>
            <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
              <Header as="h5" color="black">{t('checkout.shipping')}</Header>
              {shippingCoupons.length && appliedShippingDiscount.length ? handleRenderCouponDetails(shippingCoupons) : ''}
            </Grid.Column>
            <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
              <Header as="h5" color={shippingCoupons.length && appliedShippingDiscount.length ? 'blue' : 'gray'}>
                {(shippingCoupons.length && data.shipping === 0 && appliedShippingDiscount.length) || (data.shipping <= 0.1 && data.shipping > 0) ? 'Gratis' : currencyFormat(data.shipping, currency)}
                *
              </Header>
            </Grid.Column>
          </Grid.Row>
          {(data.paymentId === 2 || data.paymentId === 3)
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h5" color="black">{t('checkout.electronicServiceTotal')}</Header>
                </Grid.Column>
                <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
                  <Header as="h5">{currencyFormat(data.electronicService, currency)}</Header>
                </Grid.Column>
              </Grid.Row>
            )
            : ''}
          <Grid.Row>
            <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
              <Header as="h5" color="black">{t('checkout.IVA')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
              <Header as="h5">{currencyFormat(data.iva, currency)}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} className="no-padding">
              <Divider style={{ margin: '0.5rem' }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="no-padding-top">
            <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
              <Header as="h4" color="green">{t('checkout.total')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
              <Header as="h4" color="green">{currencyFormat(data.total, currency)}</Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
    </Grid>
  );
};

Payment.propTypes = {
  currency: PropTypes.number,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({
    subtotal: PropTypes.number,
    coupons: PropTypes.arrayOf(PropTypes.shape({})),
    appliedDiscounts: PropTypes.arrayOf(PropTypes.shape({})),
  })),
};

Payment.defaultProps = {
  currency: 1,
  checkoutData: [],
};
export default translate('common', { wait: true })(Payment);
