import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import Forgot from './components/forgot-password';
import SetPassword from './components/new-password';
import request from './services';

class Reset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetToken: '',
      isValidToken: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    this.validateToken(queryParams.resetToken);
    this.setState({ resetToken: queryParams.resetToken });
  }

  validateToken = async resetToken => {
    try {
      await request.validateToken(resetToken);
      this.setState({ isValidToken: true });
    } catch (error) {
      // if (error.code === callError.users.INVALID_TOKEN) {
      //   Toast(t('login.tokenExpired'), 'error');
      // } else Toast(t(`error-codes.${error.code}`), 'error');
      this.setState({ isValidToken: false });
    }
  };

  render() {
    const { resetToken, isValidToken } = this.state;
    return (
      <Grid padded>
        <Helmet
          title="siclik - recuperar contraseña"
          meta={[
            {
              name: 'description',
              content: '¿Olvidaste tu contraseña? Recupérala en un simple y sencillo paso.',
            },
          ]}
        />
        <Grid.Row>
          <Grid.Column>
            {
              !isValidToken
                ? <Forgot />
                : <SetPassword resetToken={resetToken} />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>

    );
  }
}

export default translate('common', { wait: true })(Reset);
