import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import DatePicker from '@material-ui/core/TextField';
import {
  Grid, Header, Dropdown, Divider, Message, Modal, Button, Icon,
} from 'semantic-ui-react';

const AutoDesk = props => {
  const {
    t, handleDateChange, handleChangeCourseType, modalViatics, modalViaticsClose, productsAutodesk, courseType,
  } = props;
  return (
    <Grid columns="equal" divided inverted padded>
      <Header>Información Autodesk</Header>
      <Message
        warning
        header={t('multibrand.termnsAutodesk')}
        list={[
          t('multibrand.firstTerm'),
          t('multibrand.secondTerm'),
          t('multibrand.thirdTerm'),
          t('multibrand.fourthTerm'),
          t('multibrand.fifthTerm'),
          t('multibrand.sixthTerm'),
          t('multibrand.seventhTerm'),
        ]}
      />
      <Grid.Row textAlign="left">
        <Grid.Column>
          <Header as="h4">{t('multibrand.courseName')}</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">{t('multibrand.startDateAutodesk')}</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">{t('multibrand.endDateAutodesk')}</Header>
        </Grid.Column>
        <Grid.Column>
          <Header as="h4">{t('multibrand.courseType')}</Header>
        </Grid.Column>
      </Grid.Row>
      {productsAutodesk.map(element => (
        <Grid.Row textAlign="left">
          <Grid.Column>
            <p>{element.name}</p>
          </Grid.Column>
          <Grid.Column>
            <DatePicker
              id="startDate"
              name={element.sku}
              type="date"
              format="dd/mm/yyyy"
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              value={element.startDate}
            />
          </Grid.Column>
          <Grid.Column>
            <DatePicker
              id="endDate"
              name={element.sku}
              type="date"
              format="dd/mm/yyyy"
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              value={element.endDate}
            />
          </Grid.Column>
          <Grid.Column>
            <Dropdown
              name={element.sku}
              placeholder="Elegir opción"
              fluid
              search
              selection
              options={courseType}
              onChange={handleChangeCourseType}
              value={element.courseType}
            />
          </Grid.Column>
        </Grid.Row>
      ))}
      <Divider />
      <Modal
        open={modalViatics}
        onClose={modalViaticsClose}
        basic
        size="small"
      >
        <Header icon="browser" content="Información Importante" />
        <Modal.Content>
          <h2>{t('multibrand.addSkuModal')}</h2>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={modalViaticsClose} inverted>
            <Icon name="checkmark" />
            {' '}
            {t('multibrand.checkMartSku')}
          </Button>
        </Modal.Actions>
      </Modal>
    </Grid>
  );
};

AutoDesk.propTypes = {
  courseType: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeCourseType: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  modalViatics: PropTypes.bool.isRequired,
  modalViaticsClose: PropTypes.bool.isRequired,
  productsAutodesk: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default translate('common', { wait: true })(AutoDesk);
