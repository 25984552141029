import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const users = {};

users.getUsersHasPermission = () => (
  request({
    route: apiRoutes.getUsersHasPermission(),
    method: 'GET',
  })
);

users.deleteUser = body => (
  request({
    route: apiRoutes.disableUserByCustomer(),
    method: 'POST',
    body,
  })
);

users.getAllUsers = () => (
  request({
    route: apiRoutes.getAllUsers(),
    method: 'GET',
  })
);

users.getCustomersByUser = body => (
  request({
    route: apiRoutes.getCustomersByUser(),
    method: 'POST',
    body,
  })
);

export default users;
