import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from 'react-i18next';
import DatePicker from '@material-ui/core/TextField';
import {
  Grid, Header, Dropdown, Divider, Button, List, Image,
  Modal, Dimmer, Loader, Popup, Icon, Input,
} from 'semantic-ui-react';
import UploadImage from './components/upload-image';
import ActionDetail from './components/action-detail';
import PromotionalList from './components/PromotionalList';
import TypeDetail from './components/type-detail';
import service from './services';
import Toast from '../common/components/toast';
import { scrollToRef } from '../common/utils';

const IMAGE_ACTION_ID = 1;
const NO_ACTION_ID = 4;

const ORDERS = [
  { key: 1, text: 'Alta', value: 1 },
  { key: 3, text: 'Media', value: 3 },
  { key: 5, text: 'Baja', value: 5 },
];

function getTableHeader(handleUpdate, handleDelete) {
  return ([
    { key: 'id', isKey: true },
    { key: 'name', name: 'Nombre' },
    { key: 'type', name: 'Categoría', format: cell => cell.name },
    { key: 'order', name: 'Orden' },
    {
      key: 'start',
      name: 'Inicia',
      format: cell => (`${moment(cell).format('DD/MM/YYYY')}`),
    },
    {
      key: 'end',
      name: 'Fin',
      format: cell => (`${moment(cell).format('DD/MM/YYYY')}`),
    },
    {
      key: 'action',
      name: 'Acción',
      format: cell => {
        const { label, value } = cell;
        return (`${label}: ${value}`);
      },
    },
    {
      key: 'actions',
      name: '',
      format: (cell, row) => (
        <Grid.Row>
          <Modal trigger={<Button color="blue" icon="eye" />}>
            <Modal.Header>Recursos</Modal.Header>
            <Modal.Content>
              {
                row.images.map(({ sizeId, url }) => (
                  <Image key={sizeId} src={url} />
                ))
              }
            </Modal.Content>
          </Modal>
          <Button color="blue" icon="pencil alternate" onClick={() => handleUpdate(row)} />
          <Button color="blue" icon="trash alternate" onClick={() => handleDelete(row)} />
        </Grid.Row>
      ),
    },
  ]);
}

class Promotionals extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();
    this.state = {
      actions: [],
      formatedActions: [],
      bannerTypes: [],
      formatedTypes: [],
      images: [],
      promotionals: [],
      promotionalsHeader: getTableHeader(this.handleClickUpdate, this.handleDeletePromotional),
      action: null,
      type: null,
      bannerId: null,
      order: 3,
      name: '',
      value: '',
      shouldClean: false,
      messageTable: '',
      start: '',
      end: '',
      startError: '',
      endError: '',
      openImageModal: false,
      disableAttach: false,
      loader: true,
    };
  }

  componentDidMount() {
    this.getCatalogs();
    this.getPromotionals();
  }

  getCatalogs = async () => {
    const actions = await service.getBannerActions().then(response => response.actions);
    const bannerTypes = await service.getBannerTypes().then(response => response.bannerTypes);
    const hashActions = {};
    const hashTypes = {};
    const formatedActions = actions.map(action => {
      hashActions[action.id] = action;
      return {
        key: action.id,
        text: action.label,
        value: action.id,
      };
    });
    const formatedTypes = bannerTypes.map(type => {
      hashTypes[type.id] = type;
      return {
        key: type.id,
        text: type.name,
        value: type.id,
      };
    });
    this.setState({
      actions: hashActions,
      bannerTypes: hashTypes,
      formatedActions,
      formatedTypes,
      loader: false,
    });
  }

  getPromotionals = async () => {
    const promotionals = await service.getDetailedPromotionals()
      .then(response => response.banners);
    this.setState({ promotionals });
  }

  handleClickUpdate = row => {
    this.loadState(row);
    scrollToRef(this.headerRef);
  };

  handleDeletePromotional = row => {
    const { t } = this.props;
    const { promotionals } = this.state;
    service.deleteBanner(row.id)
      .then(() => {
        const filteredPromotionals = promotionals.filter(({ id }) => id !== row.id);
        this.setState({ promotionals: filteredPromotionals });
        Toast(t('marketing.promotionalDeleted'), 'success');
      })
      .catch(() => Toast(t('marketing.error.deleteFail'), 'warning'));
  }

  handleBannerTypeChange = (e, { value }) => {
    this.setState({ type: value });
  }

  handleOrderChange = (e, { value }) => {
    this.setState({ order: value });
  }

  handleActionChange = (e, { value: actionId }) => {
    const { value: actionValue } = this.state;
    const value = actionId === NO_ACTION_ID ? 'Sin acción' : actionValue;
    this.setState({ action: actionId, value });
  }

  handleInput = (e, { name, value }) => {
    this.setState({ [name]: value });
  }

  panelState = visible => {
    this.setState({ openImageModal: visible });
  }

  handleDate = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  handleUploadedImages = uploadedImages => {
    const { value: currentValue, action } = this.state;
    let value = currentValue;
    const images = uploadedImages.filter(image => image.sizeId !== 'extra');
    if (action === 1) {
      const extraImage = uploadedImages.find(image => image.sizeId === 'extra');
      value = extraImage.url;
    }
    this.setState({ value, images, disableAttach: true });
  };

  isValidData = () => {
    const { t } = this.props;
    const {
      type, action, value, name, start, end, images,
    } = this.state;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    if (!type || !name || !action || !images.length || !start || !end || !value) {
      Toast(t('marketing.error.empty'), 'error');
      return false;
    }
    if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
      Toast(t('marketing.error.biggerThanEnd'), 'error');
      return false;
    }
    return true;
  }

  handleAddBanner = bannerData => {
    const { t } = this.props;
    service.addBanner(bannerData)
      .then(() => {
        Toast(t('marketing.promotionalAdded'), 'success');
        this.cleanState();
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  handleUpdateBanner = bannerData => {
    const { t } = this.props;
    service.updateBanner(bannerData)
      .then(() => {
        Toast(t('marketing.promotionalUpdated'), 'success');
        this.cleanState();
        this.getPromotionals();
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  cleanState = () => {
    this.setState({
      images: [],
      action: null,
      type: null,
      bannerId: null,
      order: 3,
      name: '',
      value: '',
      start: '',
      end: '',
      startError: '',
      endError: '',
      openImageModal: false,
      disableAttach: false,
    });
  }

  loadState = row => {
    const {
      id,
      name,
      images,
      action,
      order,
      type,
      start,
      end,
    } = row;
    this.setState({
      images,
      action: action.id,
      type: type.id,
      bannerId: id,
      order,
      name,
      value: action.value,
      start: moment(start).format('YYYY-MM-DD'),
      end: moment(end).format('YYYY-MM-DD'),
      startError: '',
      endError: '',
      openImageModal: false,
      disableAttach: false,
    });
  }

  handleSave = () => {
    const {
      type, action, value, name, start, end, images, order, bannerId,
    } = this.state;
    if (this.isValidData()) {
      const formatedData = {
        typeId: type,
        actionId: action,
        value,
        name,
        start: moment(start).format('DD/MM/YYYY'),
        end: moment(end).format('DD/MM/YYYY'),
        order,
        images,
      };
      if (bannerId) this.handleUpdateBanner({ bannerId, ...formatedData });
      else this.handleAddBanner(formatedData);
    }
  }

  render() {
    const { t } = this.props;
    const {
      formatedActions, formatedTypes, action, type, name, value, end, endError,
      bannerTypes, actions, openImageModal, loader, images, startError, start, disableAttach,
      shouldClean, messageTable, promotionals, promotionalsHeader, order, bannerId,
    } = this.state;
    const disableSave = !type || !name || !action || !images.length || !start || !end || !value;
    return (
      <Grid>
        <Grid.Row>
          <div ref={this.headerRef} />
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('marketing.headerAdd')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('marketing.moduleDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('marketing.label.name')}</Header>
            <Input
              name="name"
              value={name}
              onChange={this.handleInput}
              fluid
            />
          </Grid.Column>
          {
            loader === false
              ? (
                <Grid.Column largeScreen={4} computer={4} tablet={7} mobile={15} textAlign="left">
                  <Header className="no-margin-bottom" as="h5">{t('marketing.label.type')}</Header>
                  <Dropdown
                    placeholder={t('marketing.label.type')}
                    options={formatedTypes}
                    value={type}
                    onChange={this.handleBannerTypeChange}
                    selection
                  />
                  <Popup
                    trigger={<Icon className="info-icon" name="info" color="blue" size="small" circular />}
                    content={<TypeDetail type={bannerTypes[type]} />}
                  />
                </Grid.Column>
              )
              : (
                <Dimmer active inverted>
                  <Loader active size="medium" />
                </Dimmer>
              )
          }
          <Grid.Column largeScreen={4} computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('marketing.label.start')}</Header>
            <DatePicker
              type="date"
              name="start"
              format="dd/mm/yyyy"
              value={start}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={startError}
            />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('marketing.label.end')}</Header>
            <DatePicker
              type="date"
              name="end"
              format="dd/mm/yyyy"
              value={end}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={endError}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {
            loader === false
              ? (
                <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15} textAlign="left">
                  <Header className="no-margin-bottom" as="h5">{t('marketing.label.actionClick')}</Header>
                  <Dropdown
                    placeholder={t('marketing.label.actionClick')}
                    options={formatedActions}
                    onChange={this.handleActionChange}
                    value={action}
                    selection
                  />
                  <Popup
                    trigger={<Icon className="info-icon" name="info" color="blue" size="small" circular />}
                    content={<ActionDetail action={actions[action]} />}
                  />
                </Grid.Column>
              )
              : (
                <Dimmer active inverted>
                  <Loader active size="medium" />
                </Dimmer>
              )
          }
          <Grid.Column largeScreen={3} computer={3} tablet={5} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('marketing.label.actionValue')}</Header>
            <Input
              disabled={(action === IMAGE_ACTION_ID || action === NO_ACTION_ID)}
              name="value"
              value={value}
              onChange={this.handleInput}
              fluid
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={5} mobile={15} textAlign="left">
            <Header className="no-margin-bottom" as="h5">{t('marketing.label.order')}</Header>
            <Dropdown
              placeholder={t('marketing.label.order')}
              options={ORDERS}
              value={order}
              onChange={this.handleOrderChange}
              selection
            />
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('marketing.label.attachments')}</Header>
            <List verticalAlign="middle">
              {
                images.map(image => {
                  const imageSize = bannerTypes[type].sizes.find(size => size.id === image.sizeId);
                  return (
                    <List.Item>
                      <Image size="small" src={image.url} verticalAlign="middle" />
                      <List.Content>
                        <List.Header as="a">{imageSize.name.toUpperCase()}</List.Header>
                        <List.Description>
                          {`${imageSize.width || '(ancho libre)'}x${imageSize.height || '(alto libre)'}`}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })
              }
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column textAlign="center" largeScreen={8} computer={8} tablet={14} mobile={15}>
            <Button
              size="medium"
              color="green"
              onClick={() => this.setState({ openImageModal: true })}
              disabled={!type || !name || !action || disableAttach}
            >
              <span>{t('marketing.label.attachmentButton')}</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
            <Button
              size="medium"
              onClick={this.cleanState}
            >
              <span>{t('cancel')}</span>
            </Button>
            <Button
              size="medium"
              color="blue"
              onClick={this.handleSave}
              disabled={disableSave}
            >
              <span>{!bannerId ? t('save') : t('edit')}</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider className="no-margin-top no-margin-bottom" section />
        <Grid.Row>
          <Grid.Column>
            <PromotionalList
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              promotionals={promotionals}
              headers={promotionalsHeader}
              messageTable={messageTable}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal open={openImageModal} closeIcon onClose={() => this.setState({ openImageModal: false })}>
            <Modal.Header
              content={<Header color="blue" textAlign="center" as="h3">{t('marketing.label.uploadImages')}</Header>}
            />
            <Modal.Content>
              <UploadImage
                type={bannerTypes[type]}
                action={actions[action]}
                name={name}
                handleModalState={this.panelState}
                uploadedImages={this.handleUploadedImages}
              />
            </Modal.Content>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }
}

Promotionals.defaultProps = {
  t: () => {},
};

Promotionals.propTypes = {
  t: PropTypes.func,
};

export default translate('common', { wait: true })(Promotionals);
