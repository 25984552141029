import React, { Component } from 'react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import {
  Grid, Header, Image, Icon, List, Menu, Divider,
} from 'semantic-ui-react';
import * as session from '../common/sessions';
import AgentDirectory from './components/AgentDirectory';
import BusinessPlan from '../business-plan';
import PlatinoBenefits from './components/PlatinoBenefits';
import EliteBenefits from './components/EliteBenefits';
import PremierBenefits from './components/PremierBenefits';
import SelectBenefits from './components/SelectBenefits';

import Report from './components/Report';
import serviceDashboard from './services';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      accessToken: '',
      activeItem: 'Beneficios',
      agent: '',
      agentsArray: [],
      allAgents: [],
      customerId,
      data: [],
      dataFiltered: [],
      embedId: process.env.REACT_APP_POWERBI_REPORT_ID,
      embedType: 'report',
      embedUrl: 'https://app.powerbi.com/reportEmbed',
      filter: '',
      filterPaneEnabled: 'filter-false',
      loadError: '',
      maker: 'All',
      makersArray: [],
      navContentPaneEnabled: 'nav-false',
      permissions: 'All',
      tokenType: 'Embed',
      viewReport: false,
      segment: '',
      headers: [
        { key: 'id', isKey: true },
        { key: 'maker', name: 'Marca' },
        {
          key: 'image',
          format: cell => (cell ? <Image src={cell} size="small" /> : (
            <Image spaced="rigth" size="small">
              {' '}
              <Icon color="blue" name="user" size="huge" />
              {' '}
            </Image>
          )),
        },
        {
          key: 'agent',
          name: 'Agente',
          format: (cell, row) => (
            <Grid>
              <Grid.Row>
                <List>
                  <List.Item>{cell}</List.Item>
                  <List.Item>{`${row.rol} ${row.maker}`}</List.Item>
                  <List.Item>{row.email}</List.Item>
                  <List.Item>{row.cellPhone}</List.Item>
                </List>
              </Grid.Row>
            </Grid>
          ),
        },
        {
          key: 'mail',
          format: (cell, row) => (<Icon as="a" href={`mailto:${row.email}`} link color="blue" name="mail outline" size="big" />),
        },
      ],
    };
  }

  componentDidMount() {
    this.handleGetAgents();
    this.handleAccessPermission();
    this.getSegment();
  }

  setActionMenuBenefits = benefits => {
    this.setState({ activeItem: benefits });
  }

  onLoadFilters = report => {
    const { t } = this.props;
    const { filter } = this.state;
    report.setFilters(filter)
      .catch(errors => {
        this.setState({ loadError: t('dashboard.PowerbiError') });
        return errors;
      });
    this.report = report;
  }

  setFullscreen = () => {
    if (this.report) this.report.fullscreen();
  }

  getPowerBIToken = () => {
    serviceDashboard.getAccesTokenPowerBI()
      .then(response => {
        this.setState({ accessToken: response.token });
      });
  }

  handleAccessPermission = () => {
    const { access } = session.get();
    const hasAccessValidation = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.cotizacion,
      securityService.getAccess.compra,
      securityService.getAccess.pedidos,
      securityService.getAccess.envios,
      securityService.getAccess.reportes,
      securityService.getAccess.usuariosFinales,
    ]);
    this.setState({ hasAccess: hasAccessValidation });
  }

  showDirectory = (e, { name }) => {
    this.setState({ activeItem: name });
    this.setState({ viewReport: false });
  }

  loadReport = (e, { name }) => {
    const { accessToken, customerId } = this.state;
    const { history } = this.props;
    this.setState({ activeItem: name });
    this.setState({ viewReport: true });
    const { profileComplete, acceptedTerms } = session.get();

    if (!accessToken) {
      if (acceptedTerms) {
        if (profileComplete) {
          const filter = [{
            $schema: 'https://powerbi.com/product/schema#basic',
            target: {
              table: 'Cte',
              column: 'Cliente',
            },
            operator: 'In',
            values: [customerId],
          },
          ];
          this.getPowerBIToken();
          this.setState({ filter });
        } else {
          history.push('/perfil');
        }
      } else {
        history.push('/abordaje');
      }
    }
  }

  handleGetAgents = () => {
    const { t } = this.props;
    let { data } = this.state;
    const { makersArray, allAgents, customerId } = this.state;
    serviceDashboard.getAgents(customerId).then(agentsResponse => {
      agentsResponse.agents.map(agent => {
        makersArray.push(agent.maker);
        allAgents.push({ agent: agent.agent, maker: agent.maker });
        return agent;
      });
      data = agentsResponse.agents;
      this.handleGetMakers(makersArray);
      this.setState({ data, dataFiltered: data });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      return messageError;
    });
  }

  handleGetMakers = makersItems => {
    const { maker: makerState } = this.state;
    const makerDistinct = makersItems.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
    const makersArray = makerDistinct.map(maker => { const formatedMakers = { key: maker, text: maker, value: maker }; return formatedMakers; });
    makersArray.push({ key: 'All', text: 'Todas las marcas', value: 'All' });
    this.setState({ makersArray });
    this.handleGetAgentsByMaker(makerState);
  }

  handleChangeMaker = (e, { value }) => {
    this.setState({ maker: value });
    this.handleGetAgentsByMaker(value);
  }

  handleGetAgentsByMaker = value => {
    const { allAgents } = this.state;
    const agentsArrayByMaker = [];
    let agentArrayFilter = [];
    if (value && value !== 'All') {
      allAgents.map(agent => {
        if (agent.maker === value) agentsArrayByMaker.push(agent.agent);
        return agent;
      });
      const agentMaker = agentsArrayByMaker.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
      agentArrayFilter = agentMaker.map(name => { const formatedAgents = { key: name, text: name, value: name }; return formatedAgents; });
    } else {
      allAgents.map(agent => {
        agentsArrayByMaker.push(agent.agent);
        return agent;
      });
      const agentMaker = agentsArrayByMaker.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
      agentArrayFilter = agentMaker.map(name => { const formatedAgents = { key: name, text: name, value: name }; return formatedAgents; });
    }
    this.setState({ agentsArray: agentArrayFilter, agent: '' });
    this.filterForMaker(value);
  }

  handleChangeAgent = (e, { value }) => {
    this.setState({ agent: value });
    this.filterForAgent(value);
  }

  filterForMaker = maker => {
    const { data: agentsToFilter } = this.state;
    let foundData = [];
    if (!maker || maker === 'All') {
      foundData = agentsToFilter;
    } else {
      foundData = agentsToFilter.filter(filterInfo => filterInfo.maker === maker);
    }
    this.setState({ dataFiltered: foundData });
  }

  filterForAgent = agent => {
    const { data: agentsToFilter } = this.state;
    let foundData = [];
    if (!agent) {
      foundData = agentsToFilter;
    } else {
      foundData = agentsToFilter.filter(filterInfo => filterInfo.agent === agent);
    }
    this.setState({ dataFiltered: foundData });
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name, viewReport: false });

  getSegment = async () => {
    const { customerId } = this.state;
    const { segment } = await serviceDashboard.getSegmentByCustomer(customerId);
    return segment.length === 0 ? this.setState({ segment: 'Select' }) : this.setState({ segment });
  }

  render() {
    const {
      accessToken,
      activeItem,
      agent,
      agentsArray,
      dataFiltered,
      embedId,
      embedType,
      embedUrl,
      hasAccess,
      headers,
      loadError,
      maker,
      makersArray,
      permissions,
      tokenType,
      viewReport,
      filterPaneEnabled,
      navContentPaneEnabled,
      segment,
    } = this.state;
    const style = {
      report: {},
    };
    const { t } = this.props;
    const extraSettings = {
      filterPaneEnabled: filterPaneEnabled === 'filter-true',
      navContentPaneEnabled: navContentPaneEnabled === 'nav-true',
    };
    return (
      <div>
        <Menu fluid widths={4} style={{ marginTop: '1rem' }}>
          <Menu.Item
            name="Beneficios"
            active={activeItem === 'Beneficios'}
            onClick={this.handleItemClick}
          />
          <Menu.Item
            name="Directorio"
            active={activeItem === 'Directorio'}
            onClick={this.showDirectory}
          />
          {hasAccess
            ? (
              <Menu.Item
                name="Tablero"
                active={activeItem === 'Tablero'}
                onClick={this.loadReport}
              />
            )
            : ''}
          <Menu.Item
            name="Plan de negocios"
            content="Plan de negocios"
            active={activeItem === 'Plan de negocios'}
            onClick={this.handleItemClick}
          />
        </Menu>

        {!viewReport
          ? (
            <div>

              <div>
                {activeItem === 'Directorio'
                  ? (
                    <div>
                      <Grid container centered>
                        <Grid.Row style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                            <AgentDirectory
                              headers={headers}
                              data={dataFiltered}
                              makersArray={makersArray}
                              maker={maker}
                              handleChangeMaker={this.handleChangeMaker}
                              agentsArray={agentsArray}
                              agent={agent}
                              handleChangeAgent={this.handleChangeAgent}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </div>
                  )
                  : <div />}
              </div>

            </div>
          )
          : (
            <div>
              {(activeItem === 'Tablero' && !accessToken) || loadError
                ? (
                  <div>
                    <Grid>
                      <Grid.Row centered style={{ paddingTop: '5rem', paddingBottom: '3rem' }}>
                        <Grid.Column largeScreen={5} computer={5} tablet={12} mobile={15}>
                          <Header textAlign="center" as="h4">{t('dashboard.loadingReport')}</Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>

                  </div>
                )
                : (
                  <div className="PWBZone">
                    <div className="containerPWBI">
                      <Report
                        embedType={embedType}
                        tokenType={tokenType}
                        accessToken={accessToken}
                        embedUrl={embedUrl}
                        embedId={embedId}
                        extraSettings={extraSettings}
                        permissions={permissions}
                        style={style.report}
                        onLoad={this.onLoadFilters}
                      />
                      <div className="config">
                        <span className="interactions">
                          <button
                            type="button"
                            id="fullscreenButton"
                            className="interaction-btn"
                            onClick={this.setFullscreen}
                            aria-label="Set fullscreen"
                          >
                            <img src="images/full.png" alt="Activar pantalla completa" width="100%" />
                          </button>
                        </span>
                      </div>

                    </div>
                  </div>
                )}

            </div>
          )}
        {activeItem === 'Beneficios' && segment === 'Platino'
          ? (
            <Grid container centered>
              <Header style={{ paddingTop: '2rem', paddingBottom: '0' }} textAlign="left" as="h3">{segment}</Header>
              <Grid.Row style={{ paddingTop: '1rem' }}>
                <Header style={{ paddingBottom: '2rem' }} textAlign="center" as="h4">¡Conoce todos los beneficios que tenemos para ti!</Header>
                <Divider />
                <PlatinoBenefits />
              </Grid.Row>
            </Grid>
          )
          : ''}
        {activeItem === 'Beneficios' && segment === 'Elite'
          ? (
            <Grid container centered>
              <Header style={{ paddingTop: '2rem', paddingBottom: '0' }} textAlign="left" as="h3">{segment}</Header>
              <Grid.Row style={{ paddingTop: '1rem' }}>
                <Header textAlign="center" as="h4">¡Conoce todos los beneficios que tenemos para ti!</Header>
                <Divider />
                <EliteBenefits />
              </Grid.Row>
            </Grid>
          )
          : ''}
        {activeItem === 'Beneficios' && segment === 'Premier'
          ? (
            <Grid container centered>
              <Header style={{ paddingTop: '2rem', paddingBottom: '0' }} textAlign="left" as="h3">{segment}</Header>
              <Grid.Row style={{ paddingTop: '1rem' }}>
                <Header textAlign="center" as="h4">¡Conoce todos los beneficios que tenemos para ti!</Header>
                <Divider />
                <PremierBenefits />
              </Grid.Row>
            </Grid>
          )
          : ''}
        {activeItem === 'Beneficios' && segment === 'Select'
          ? (
            <Grid container centered>
              <Header style={{ paddingTop: '2rem', paddingBottom: '0' }} textAlign="left" as="h3">{segment}</Header>
              <Grid.Row style={{ paddingTop: '1rem' }}>
                <Header textAlign="center" as="h4">¡Conoce todos los beneficios que tenemos para ti!</Header>
                <Divider />
                <SelectBenefits />
              </Grid.Row>
            </Grid>
          )
          : ''}
        {
          activeItem === 'Plan de negocios'
            ? (
              <Grid centered>
                <Grid.Row style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
                  <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                    <BusinessPlan />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )
            : null
        }
      </div>

    );
  }
}

export default translate('common', { wait: true })(Dashboard);
