import { fromJS } from 'immutable';

import {
  TOGGLE_SWITCH_BUY_MODAL,
  TOGGLE_SWITCH_ITEM_MODAL,
  TOGGLE_SWITCH_DELETE_LIST_MODAL,
} from './constants';

const modalToggleItem = 'modalToggleItem';
const modalToggleBuy = 'modalToggleBuy';
const modalToggleDeleteList = 'modalToggleDeleteList';
const branchOffices = 'branchOffices';

export const initialState = fromJS({
  [modalToggleItem]: false,
  [modalToggleBuy]: false,
  [branchOffices]: [],
});

export default function reducer(state = initialState, { type }) {
  switch (type) {
    case TOGGLE_SWITCH_ITEM_MODAL:
      return state
        .set(modalToggleItem, !state.toJS().modalToggleItem);
    case TOGGLE_SWITCH_BUY_MODAL:
      return state
        .set(modalToggleBuy, !state.toJS().modalToggleBuy);
    case TOGGLE_SWITCH_DELETE_LIST_MODAL:
      return state
        .set(modalToggleDeleteList, !state.toJS().modalToggleDeleteList);
    default:
      return state;
  }
}
