/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Container,
  Segment,
  Header,
  Card,
  Image,
  Button,
  Icon,
  List,
} from 'semantic-ui-react';

const translatePath = 'productConfig.menus.uploadImages.loadImages';

const thumbsContainer = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#f9fafb',
  borderStyle: 'dashed',
  backgroundColor: '#f9fafb',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const mimeTypes = [
  'image/jpeg',
  'image/png',
  'image/webp',
];
const MAX_FILE_SIZE = 3000000;
const minImageSizeRequired = { vertically: [500, 300], horizontally: [300, 500], square: [300, 300] };
const minLogoSizeRequired = { vertically: [130, 300], horizontally: [300, 130], square: [130, 130] };

function getIconFile(mimeType) {
  const availableMimeTypes = [
    { mimeType: 'image/jpeg', icon: 'image' },
    { mimeType: 'image/png', icon: 'image' },
    { mimeType: 'image/jpg', icon: 'image' },
    { mimeType: 'image/webp', icon: 'image' },
    { mimeType: 'application/pdf', icon: 'pdf' },
    { mimeType: 'application/msword', icon: 'word' },
    { mimeType: 'application/vnd.ms-excel', icon: 'excel' },
  ];
  return availableMimeTypes.find(m => m.mimeType === mimeType)?.icon
    || 'file';
}

function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';
  const kilobyte = 1024;
  const toDecimals = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB'];
  const toSize = Math.floor(Math.log(bytes) / Math.log(kilobyte));
  return `${parseFloat((bytes / (kilobyte ** toSize)).toFixed(toDecimals))} ${sizes[toSize]}`;
}

function sizeTextError(imageSizePosition) {
  const [width, height] = imageSizePosition;
  return `${width} x ${height}px`;
}

function getLimitSizeByImageType(isImageType) {
  return isImageType
    ? minImageSizeRequired
    : minLogoSizeRequired;
}

function LoadImages(props) {
  const {
    t,
    accepted,
    rejected,
    isImageType,
  } = props;
  const [files, setFiles] = useState(accepted.images || []);

  const handleOnDrop = images => {
    rejected.setImages([]);
    setFiles([...files, ...images.map(file => ({
      file,
      name: file.name,
      size: file.size,
      type: file.type,
      path: file.path,
      preview: URL.createObjectURL(file),
      width: 0,
      height: 0,
    }))]);
  };

  const handleRemove = file => {
    const newFiles = files.filter(f => f.path !== file.path);
    setFiles(newFiles);
    accepted.setImages(newFiles);
  };

  const handleOnDropRejected = images => {
    rejected.setImages(images.map(image => ({
      name: image.name,
      size: image.size,
      type: image.type,
      path: image.path,
      preview: URL.createObjectURL(image),
    })));
  };

  const validateMinImageSize = imageSize => {
    const { vertically, horizontally, square } = getLimitSizeByImageType(isImageType);
    return {
      isValidHorizontalSize() {
        const [width, height] = horizontally;
        return imageSize.width >= width && imageSize.height >= height;
      },
      isValidVerticalSize() {
        const [width, height] = vertically;
        return imageSize.width >= width && imageSize.height >= height;
      },
      isValidSquareSize() {
        const [width, height] = square;
        return imageSize.width >= width && imageSize.height >= height;
      },
    };
  };

  const hasValidSize = imageSize => {
    if (imageSize.width && imageSize.height) {
      const { isValidHorizontalSize, isValidVerticalSize, isValidSquareSize } = validateMinImageSize(imageSize);
      const hasMinHorizontalSize = isValidHorizontalSize();
      const hasMinVerticalSize = isValidVerticalSize();
      const hasMinSquareSize = isValidSquareSize();
      return hasMinHorizontalSize || hasMinVerticalSize || hasMinSquareSize;
    }
    return true;
  };

  const handleOnLoadImage = ({ target }, file) => {
    const height = target.naturalHeight;
    const width = target.naturalWidth;
    if (hasValidSize({ height, width })) {
      const images = files.map(image => {
        const isSameImage = image.path === file.path;
        return isSameImage ? { ...image, width, height } : image;
      });
      setFiles(images);
      accepted.setImages(images);
    } else {
      handleRemove(file);
      rejected.setImages([...rejected.images, { ...file, width, height }]);
    }
  };

  const getFileErrors = file => {
    const errors = [];
    const isValidMimeType = mimeTypes.includes(file.type);
    if (file.size > MAX_FILE_SIZE) {
      errors.push(t(`${translatePath}.rejectedFiles.errors.maxFileSize`));
    }
    if (!isValidMimeType) {
      errors.push(t(`${translatePath}.rejectedFiles.errors.invalidMimeType`));
    }
    if (!hasValidSize(file) && isValidMimeType) {
      const { vertically, horizontally } = getLimitSizeByImageType(isImageType);
      const verticalSize = sizeTextError(vertically);
      const horizontalSize = sizeTextError(horizontally);
      errors.push(`${t(`${translatePath}.rejectedFiles.errors.minImageSize`, { horizontalSize, verticalSize })}`);
    }
    return (
      <List.List>
        {errors.map(error => (
          <List.Item key={`${file.path}-${error}`} as="a">
            -
            {' '}
            {error}
          </List.Item>
        ))}
      </List.List>
    );
  };

  return (
    <Container style={{ marginBottom: '20px' }}>
      <Segment placeholder style={{ background: '#f9fafb' }}>
        <Header size="small" icon>
          <Icon name="images outline" />
          {t(`${translatePath}.title`)}
        </Header>
        <div className="dropzone">
          <Dropzone
            onDrop={handleOnDrop}
            onDropRejected={handleOnDropRejected}
            accept={mimeTypes}
            maxSize={MAX_FILE_SIZE}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={thumbsContainer}>
                <input {...getInputProps()} />
                <p>{t(`${translatePath}.dropzone.title`)}</p>
                <Button
                  fluid
                  primary
                  content={t(`${translatePath}.dropzone.addButton`)}
                  size="mini"
                />
              </div>
            )}
          </Dropzone>
        </div>
      </Segment>
      <Segment>
        <Header as="h4" color="blue">{t(`${translatePath}.accepedFiles.title`)}</Header>
        <Segment>
          {files.length > 0 ? (
            <Card.Group doubling itemsPerRow={5}>
              {files.map(file => (
                <Card key={file.path}>
                  <Card.Content textAlign="center">
                    <Image
                      size="small"
                      src={file.preview}
                      onLoad={image => handleOnLoadImage(image, file)}
                    />
                  </Card.Content>
                  <Card.Content extra>
                    <Button
                      fluid
                      primary
                      content={t(`${translatePath}.accepedFiles.deleteButton`)}
                      size="mini"
                      onClick={() => handleRemove(file)}
                    />
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          ) : <p>{t(`${translatePath}.accepedFiles.content`)}</p>}
        </Segment>
      </Segment>
      <Segment>
        <Header as="h4" color="blue">{t(`${translatePath}.rejectedFiles.title`)}</Header>
        <Segment>
          {rejected.images.length > 0 ? (
            <div>
              <List>
                {rejected.images.map(file => (
                  <List.Item key={file.path}>
                    <List.Icon name={`file ${getIconFile(file.type)}`} />
                    <List.Content>
                      <List.Header>{file.path}</List.Header>
                      <List.Description>
                        {formatBytes(file.size, 2)}
                        {' '}
                        {t(`${translatePath}.rejectedFiles.sizeMessage`)}
                      </List.Description>
                      {getFileErrors(file)}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </div>
          ) : <p>{t(`${translatePath}.rejectedFiles.content`)}</p>}
        </Segment>
      </Segment>
    </Container>
  );
}

LoadImages.propTypes = {
  isImageType: PropTypes.bool.isRequired,
  accepted: PropTypes.shape({
    setImages: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      preview: PropTypes.string.isRequired,
    })),
  }),
  rejected: PropTypes.shape({
    setImages: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      preview: PropTypes.string.isRequired,
    })),
  }),
};

LoadImages.defaultProps = {
  accepted: {
    images: [],
  },
  rejected: {
    images: [],
  },
};

export default translate('common', { wait: true })(LoadImages);
