import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Confirm, Header, Dropdown, Dimmer, Loader, Divider,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import * as session from '../common/sessions';
import Toast from '../common/components/toast';
import SiclikTable from '../common/components/table';
import service from './services';
import roles from '../role-permissions';

class AdminCouponsUsers extends Component {
  constructor(props) {
    const { access, userId } = session.get();
    const hasAccess = securityService.validate(access, roles.customerAdmin);
    if (!hasAccess) props.history.push('/cotizaciones/agente');
    super(props);
    this.state = {
      headers: [
        { key: 'id', isKey: true },
        { key: 'name', name: 'Nombre' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (<Button icon="trash alternate" onClick={() => this.handleClickDelete(row)} />),
        },
      ],
      usersArray: [],
      usersOptions: [],
      userId: '',
      userName: '',
      open: false,
      loading: false,
      userIdLoguer: userId,
    };
  }

  componentDidMount = () => {
    this.getUsersHasPermission();
  }

  getError = error => {
    const { t } = this.props;
    let messageError = '';
    if (!error.code) {
      messageError = t('error-codes.default');
    } else {
      messageError = t(`error-codes.${error.code}`);
    }
    if (messageError) Toast(messageError, 'error');
    this.setState({ loading: false });
  }

  getUsersHasPermission = async () => {
    this.setState({ loading: true });
    let agentsList = await service.getAdminCouponsUsers().catch(this.getError);
    let usersList = await service.getUsersByCustomer('G000000').catch(this.getError);
    if (agentsList.users) {
      agentsList = agentsList.users.map(agent => {
        const userFound = usersList.users.filter(userElement => agent.id === userElement.id)[0];
        const userFormated = { id: agent.id, name: userFound ? `${userFound.name} ${userFound.surname}` : '' };
        return userFormated;
      }).filter(userItem => userItem.name && userItem.name.trim().length > 0);
    }
    if (usersList.users) {
      usersList = usersList.users.map(agent => {
        const userFormated = { key: agent.id, text: `${agent.name} ${agent.surname}`, value: agent.id };
        return userFormated;
      });
      usersList = usersList.filter(userItem => userItem.text && userItem.text.trim().length > 0);
      this.setState({ loading: false });
    }
    this.setState({ usersArray: agentsList, usersOptions: usersList });
  };

  handleClickDelete = user => this.setState({ userId: user.id, userName: user.name, open: true });

  handleAddUser = () => {
    const { t } = this.props;
    const {
      usersArray, userId, userName, userIdLoguer,
    } = this.state;
    const userData = { userId };
    const havePermission = usersArray.filter(user => user.id === userIdLoguer);
    if (havePermission && havePermission.length > 0) {
      service.addAdminCoupons(userData).then(response => {
        if (response.id) usersArray.push({ id: userId, name: userName });
        this.setState({ usersArray, userId: '', userName: '' });
        Toast(t('authorizers.userAdded'), 'success');
      }).catch(this.getError);
    } else {
      Toast(t('error-codes.7'), 'warning');
    }
  }

  handleConfirmDelete = () => {
    const { t } = this.props;
    const { userId: id, usersArray, userIdLoguer } = this.state;
    const havePermission = usersArray.filter(user => user.id === userIdLoguer);
    if (havePermission && havePermission.length > 0) {
      service.disableAdminCoupons(id).then(response => {
        if (response) {
          usersArray.map((user, index) => {
            if (user.id === id) {
              usersArray.splice(index, 1);
              Toast(t('authorizers.userDeleted'), 'success');
            }
            return user;
          });
          this.setState({ usersArray, open: false });
        }
      })
        .catch(this.getError);
    } else {
      Toast(t('error-codes.7'), 'warning');
    }
  }

  handleChangeUsers = (e, { value }) => {
    const { usersOptions } = this.state;
    const user = usersOptions.filter(item => item.key === value)[0];
    this.setState({ userId: value, userName: user.text });
  }

  render() {
    const { t } = this.props;
    const {
      headers, usersArray, usersOptions, userId, userName, open, loading,
    } = this.state;
    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('authorizers.admin')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('authorizers.adminDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        { loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          ) : null}
        { !loading
          ? (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={12}>
                <Dropdown
                  options={usersOptions}
                  selection
                  fluid
                  value={userId}
                  onChange={this.handleChangeUsers}
                  placeholder={t('authorizers.chooseUser')}
                  search
                />
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={1}>
                <Button color="blue" onClick={this.handleAddUser}>{t('add')}</Button>
              </Grid.Column>
            </Grid.Row>
          ) : null }
        { !loading
          ? (
            <Grid.Row style={{ overflowX: 'auto' }} largeScreen={15} computer={15} tablet={15} mobile={15}>
              <SiclikTable
                selectable
                data={usersArray}
                headers={headers}
                pageSize={10}
              />
            </Grid.Row>
          ) : null }
        <Grid.Row>
          <Confirm
            open={open}
            header={t('authorizers.confirmDeleteUser')}
            content={userName}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ open: false })}
            onConfirm={this.handleConfirmDelete}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AdminCouponsUsers);
