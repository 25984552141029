import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const marketing = {};

marketing.getBannerTypes = () => (
  request({
    route: apiRoutes.getBannerTypes(),
  })
);

marketing.getBannerActions = () => (
  request({
    route: apiRoutes.getBannerActions(),
  })
);

marketing.addBanner = bannerData => (
  request({
    route: apiRoutes.addBanner(),
    method: 'POST',
    body: bannerData,
  })
);

marketing.getBannersByType = bannerTypeId => (
  request({
    route: apiRoutes.getBannersByType(bannerTypeId),
    method: 'GET',
  })
);

marketing.getDetailedPromotionals = () => (
  request({
    route: apiRoutes.getDetailedPromotionals(),
  })
);

marketing.updateBanner = body => (
  request({
    route: apiRoutes.updateBanner(),
    method: 'PUT',
    body,
  })
);

marketing.deleteBanner = bannerId => (
  request({
    route: apiRoutes.deleteBanner(bannerId),
    method: 'DELETE',
  })
);

export default marketing;
