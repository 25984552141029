import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Icon, Dropdown, Modal, Button, Header, List, Dimmer, Loader, Form, Checkbox,
} from 'semantic-ui-react';
import { currencyFormat } from '../../common/utils';
import testData from '../../multi-shipping/testDataMultiShipping';
import shippingRoutes from '../services';
import quotationService from '../../multi-shipping/services';
import Toast from '../../common/components/toast';

class SingleDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branchOfficeId: '',
      branchOfficesInformation: [],
      arrayBranchOfficeAddress: [],
      openModal: false,
      priceUps: '',
      upsInsurance: '',
      pricePE: '',
      priceCS: '',
      loader: true,
      requesting: false,
      currentParcel: false,
      insuredChecked: false,
      defaultAddressId: '',
      warehouseInformation: [],
      openModalWarehouse: false,
    };
  }

  componentDidMount() {
    this.handleGetDefaultBranchOffice();
    this.handleGetWarehouseByCS();
  }

  handleBranchOfficesOnChange = (e, { value }) => {
    this.quotationForSingleDelivery(value);
    this.setState({ branchOfficeId: value });
  }

  handleGetAddressInformation = () => {
    const { tr } = this.props;
    const { branchOfficeId } = this.state;
    shippingRoutes.getBranchOfficeAddress()
      .then(response => {
        const arraybranchOffices = response.branchOffices;
        arraybranchOffices.map(alter => {
          const alterCopy = { ...alter };
          if (alter.appointment === false) {
            alterCopy.appointment = 'No';
          } else {
            alterCopy.appointment = 'Si';
          }
          if (alter.parking === false) {
            alterCopy.parking = 'No';
          } else {
            alterCopy.parking = 'Si';
          }
          return alterCopy;
        });
        const arrayAddressInformation = arraybranchOffices.filter(addressInfo => addressInfo.id === branchOfficeId);
        this.setState({ branchOfficesInformation: arrayAddressInformation });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = tr('error-codes.default');
        else messageError = tr(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetDefaultBranchOffice = () => {
    const { t, orderIdFromQuotations } = this.props;
    let order = {};
    const arrayBranchOfficesByCs = testData.branchOfficesByCs;
    const formatedBranchOfficesByCs = arrayBranchOfficesByCs.map(warehouse => {
      const warehouseCopy = { ...warehouse };
      if (warehouse.id === 'MI01') warehouseCopy.text = 'CS CDMX';
      if (warehouse.id === 'GI01') warehouseCopy.text = 'CS GDL';
      if (warehouse.id === 'YI01') warehouseCopy.text = 'CS Mty';
      warehouseCopy.key = warehouse.id;
      warehouseCopy.value = warehouse.id;
      return warehouseCopy;
    });
    this.setState({ branchOfficesByCs: formatedBranchOfficesByCs });
    if (orderIdFromQuotations) order = orderIdFromQuotations;
    else order = '';
    shippingRoutes.getDeliveryConfig(order).then(response => {
      const deliveryConfig = response.items;
      const allBrancheOffices = deliveryConfig.map(item => (item.deliveryConfig.map(branchOffice => ({ branchOfficeId: branchOffice.branchOfficeId })))).flat();
      this.quotationForSingleDelivery(allBrancheOffices[0].branchOfficeId, response.customerId);
      this.handleGetBranchOfficeAddress(response.customerId);
      this.setState({
        defaultAddressId: allBrancheOffices[0].branchOfficeId,
        branchOfficeId: allBrancheOffices[0].branchOfficeId,
      });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  quotationForSingleDelivery = (branchOficce, customer) => {
    const { t, orderIdFromQuotations } = this.props;
    const isSingleDelivery = true;
    let order = {};
    if (orderIdFromQuotations) order = orderIdFromQuotations;
    else order = '';
    shippingRoutes.getDeliveryConfig(order).then(response => {
      const deliveryConfig = response.items;
      if (isSingleDelivery === true) {
        const skusArray = deliveryConfig.map(item => ({ sku: item.sku, quantity: item.quantity })).flat();
        const allParcelConfigurations = deliveryConfig.map(item => (item.deliveryConfig.map(courierServiceId => ({ courierServiceId: courierServiceId.courierServiceId })))).flat();
        const allWarehousesConfigurations = deliveryConfig.map(item => (item.deliveryConfig.map(pickWarehouse => ({ pickWarehouse: pickWarehouse.pickWarehouse })))).flat();
        const body = {
          customerId: customer,
          addressId: branchOficce,
          upsSafe: '1',
          skus: skusArray,
        };
        this.setState({
          loader: true,
          requesting: true,
          currentParcel: allParcelConfigurations[0].courierServiceId,
          warehouseCs: allWarehousesConfigurations[0].pickWarehouse,
        });
        if (allParcelConfigurations[0].courierServiceId !== 4) {
          quotationService.getParcelPrice(body).then(quotationResponse => {
            this.setState({
              loader: false,
              requesting: false,
              priceUps: quotationResponse.priceUps,
              upsInsurance: quotationResponse.upsInsured,
              pricePE: quotationResponse.pricePE,
              priceCS: quotationResponse.priceCS,
              origins: quotationResponse.origin,
            });
          })
            .catch(error => {
              let messageError = '';
              if (!error.code) messageError = t('error-codes.default');
              else messageError = t(`error-codes.${error.code}`);
              Toast(messageError, 'error');
            });
        } else {
          this.setState({
            loader: false,
            requesting: false,
            priceUps: 0,
            upsInsurance: 0,
            pricePE: 0,
            priceCS: 0,
            origins: [],
          });
        }
      }
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  handleGetBranchOfficeAddress = customerFromOrder => {
    const { t } = this.props;
    shippingRoutes.getBranchOfficeAddress(customerFromOrder)
      .then(response => {
        this.setState({
          arrayBranchOfficeAddress: response.branchOffices.map(branchOffice => (
            {
              key: branchOffice.id,
              text: branchOffice.name,
              value: branchOffice.id,
            }
          )),
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetWarehouseInformation = () => {
    const { branchOfficesByCs, warehouseCs } = this.state;
    const arrayInformation = branchOfficesByCs.filter(addressInfo => addressInfo.id === warehouseCs);
    this.setState({ warehouseInformation: arrayInformation, openModalWarehouse: true });
  }

  handleGetWarehouseByCS = () => {
    const arrayBranchOfficesByCs = testData.branchOfficesByCs;
    const formatedBranchOfficesByCs = arrayBranchOfficesByCs.map(warehouse => {
      const warehouseCopy = { ...warehouse };
      if (warehouse.id === 'MI01') warehouseCopy.text = 'CS CDMX';
      if (warehouse.id === 'GI01') warehouseCopy.text = 'CS GDL';
      if (warehouse.id === 'YI01') warehouseCopy.text = 'CS Mty';
      warehouseCopy.key = warehouse.id;
      warehouseCopy.value = warehouse.id;
      return warehouse;
    });
    this.setState({ branchOfficesByCs: formatedBranchOfficesByCs });
  }

  handleWarehouseCsAddressChange = (e, { value }) => {
    this.setState({ warehouseCs: value });
  }

  render() {
    const {
      openModal, branchOfficesInformation, arrayBranchOfficeAddress, priceUps, upsInsurance, pricePE, priceCS, loader, requesting,
      origins, currentParcel, insuredChecked, defaultAddressId, openModalWarehouse, warehouseCs, branchOfficesByCs, warehouseInformation,
    } = this.state;
    const { t, isQuotation } = this.props;
    return (
      <Grid container>
        <Grid.Row columns={2}>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
            {
              defaultAddressId === ''
                ? ''
                : (
                  <Grid.Row>
                    <Dropdown
                      fluid
                      placeholder={t('multi-shipping.selectBranchOficce')}
                      options={arrayBranchOfficeAddress}
                      onChange={this.handleBranchOfficesOnChange}
                      defaultValue={defaultAddressId}
                      search
                      selection
                    />
                    <Icon
                      name="info circle"
                      size="large"
                      onClick={() => { this.handleGetAddressInformation(); this.setState({ openModal: true }); }}
                    />
                  </Grid.Row>
                )
            }
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5} textAlign="left">
            {
              isQuotation === true
                ? ''
                : (
                  <Grid.Row>
                    {loader === true
                      ? (
                        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                          {requesting ? (
                            <Dimmer active inverted>
                              <Loader active size="medium" />
                            </Dimmer>
                          ) : ''}
                        </Grid.Column>
                      )
                      : (
                        <List selection verticalAlign="middle">
                          {
                            Number(priceCS) === 0
                              ? ''
                              : (
                                <List.Item>
                                  <List.Content>
                                    <List.Header>{`${t('multi-shipping.parcelCS')}${currencyFormat(priceCS)}`}</List.Header>
                                  </List.Content>
                                </List.Item>
                              )
                          }
                          {
                            Number(priceUps) === 0
                              ? ''
                              : (
                                <List.Item>
                                  <List.Content>
                                    <List.Header>{t('multi-shipping.parcelUps') + currencyFormat(priceUps)}</List.Header>
                                  </List.Content>
                                </List.Item>
                              )
                          }
                          {
                            Number(upsInsurance) === 0
                              ? ''
                              : (
                                <List.Item>
                                  <List.Content>
                                    <List.Header>{t('multi-shipping.upsInsurance') + currencyFormat(upsInsurance)}</List.Header>
                                  </List.Content>
                                </List.Item>
                              )
                          }
                          {
                            Number(pricePE) === 0
                              ? ''
                              : (
                                <List.Item>
                                  <List.Content>
                                    <List.Header>{t('multi-shipping.parcelExpress') + currencyFormat(pricePE)}</List.Header>
                                  </List.Content>
                                </List.Item>
                              )
                          }
                          {
                            Number(pricePE) === 0 && Number(upsInsurance) === 0 && Number(priceUps) === 0 && Number(priceCS) === 0
                              ? (
                                <List.Item>
                                  <List.Content>
                                    <List.Header>{t('multi-shipping.NoPricesAvailable')}</List.Header>
                                  </List.Content>
                                </List.Item>
                              )
                              : ''
                          }
                        </List>
                      )}
                  </Grid.Row>
                )
            }
            {isQuotation === true
              ? (
                <Grid.Row>
                  {loader === true
                    ? (
                      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                        {requesting ? (
                          <Dimmer active inverted>
                            <Loader active size="medium" />
                          </Dimmer>
                        ) : ''}
                      </Grid.Column>
                    )
                    : (
                      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                        <Form>
                          {
                            Number(priceCS) === 0
                              ? ''
                              : (
                                <Form.Field>
                                  <Checkbox
                                    radio
                                    label={t('multi-shipping.parcelCS') + currencyFormat(priceCS)}
                                    price={priceCS}
                                    value={1}
                                    checked={currentParcel === 1}
                                    onChange={this.handleQuotationChange}
                                  />
                                </Form.Field>
                              )
                          }
                          {
                            Number(priceUps) === 0
                              ? ''
                              : (
                                <Form.Field>
                                  <Checkbox
                                    radio
                                    label={t('multi-shipping.parcelUps') + currencyFormat(priceUps)}
                                    price={priceUps}
                                    value={2}
                                    checked={currentParcel === 2}
                                    onChange={this.handleQuotationChange}
                                  />
                                  <Checkbox
                                    label={t('multi-shipping.upsInsurance')}
                                    value={6}
                                    checked={insuredChecked}
                                    onChange={this.handleUpsInsured}
                                  />
                                </Form.Field>
                              )
                          }
                          {
                            Number(pricePE) === 0
                              ? ''
                              : (
                                <Form.Field>
                                  <Checkbox
                                    radio
                                    label={t('multi-shipping.parcelExpress') + currencyFormat(pricePE)}
                                    price={pricePE}
                                    value={3}
                                    checked={currentParcel === 3}
                                    onChange={this.handleQuotationChange}
                                  />
                                </Form.Field>
                              )
                          }
                          <Form.Field>
                            <Checkbox
                              radio
                              label={t('multi-shipping.freightQuotation')}
                              price={0}
                              value={5}
                              checked={currentParcel === 5}
                              onChange={this.handleQuotationChange}
                            />
                          </Form.Field>
                          {
                            origins.length > 1
                              ? ''
                              : (
                                <Form.Field>
                                  <Grid.Column width={7}>
                                    <Checkbox
                                      radio
                                      label={t('multi-shipping.customerPicksUp')}
                                      price={0}
                                      value={4}
                                      checked={currentParcel === 4}
                                      onChange={this.handleQuotationChange}
                                    />
                                    &nbsp;&nbsp;
                                    <Dropdown
                                      options={branchOfficesByCs}
                                      placeholder={t('multi-shipping.warehouse')}
                                      onChange={this.handleWarehouseCsAddressChange}
                                      defaultValue={warehouseCs}
                                      value={warehouseCs}
                                      compact
                                      selection
                                    />
                                    <Icon name="info circle" size="large" onClick={() => { this.handleGetWarehouseInformation(); this.setState({ openModalWarehouse: true }); }} />
                                  </Grid.Column>
                                </Form.Field>
                              )
                          }
                        </Form>
                      </Grid.Column>
                    )}
                </Grid.Row>
              )
              : ''}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal open={openModal} closeIcon size="small">
            <Icon name="window close" onClick={() => this.setState({ openModal: false })} />
            {branchOfficesInformation.map(branchOfficeName => (
              <Modal.Header>
                {t('multi-shipping.branchOffice')}
                {' '}
                {branchOfficeName.name}
              </Modal.Header>
            ))}
            <Modal.Content>
              {branchOfficesInformation.map(information => (
                <Modal.Description>
                  <Header as="h5">
                    {t('multi-shipping.address')}
                    {information.street}
                  </Header>
                  <Header as="h5">
                    {t('multi-shipping.needsAppointment')}
                    {information.appointment}
                  </Header>
                  <Header as="h5">
                    {t('Código postal: ')}
                    {information.postalCode}
                  </Header>
                  <Header as="h5">
                    {t('multi-shipping.parking')}
                    {information.parking}
                  </Header>
                  <Header as="h5">
                    {t('multi-shipping.contact')}
                    {information.mainContact}
                  </Header>
                  <Header as="h5">
                    {t('multi-shipping.phone')}
                    {information.phone}
                  </Header>
                  <br />
                </Modal.Description>
              ))}
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => this.setState({ openModal: false })}>Salir</Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Modal open={openModalWarehouse} closeIcon size="small">
              <Icon name="window close" onClick={() => this.setState({ openModalWarehouse: false })} />
              {warehouseInformation.map(branchOffice => (
                <Modal.Header>
                  {t('multi-shipping.branchOffice')}
                  {' '}
                  {branchOffice.branchOfficeName}
                </Modal.Header>
              ))}
              <Modal.Content>
                {warehouseInformation.map(branchOffice => (
                  <Grid.Row>
                    <Header as="h5">
                      {t('multi-shipping.branchOffice')}
                      {branchOffice.branchOfficeName}
                      {t('multi-shipping.ubication')}
                    </Header>
                    <p>
                      {t('multi-shipping.address')}
                      {' '}
                      {branchOffice.street}
                    </p>
                    <p>
                      {t('multi-shipping.neighborhood')}
                      {' '}
                      {branchOffice.neighborhood}
                    </p>
                    <p>
                      {t('multi-shipping.postalCode')}
                      {' '}
                      {branchOffice.postalCode}
                    </p>
                    <p>
                      {t('multi-shipping.city')}
                      {' '}
                      {branchOffice.city}
                    </p>
                    <p>
                      {t('multi-shipping.state')}
                      {' '}
                      {branchOffice.state}
                    </p>
                  </Grid.Row>
                ))}
              </Modal.Content>
              <Modal.Actions>
                <Button color="black" onClick={() => this.setState({ openModalWarehouse: false })}>{t('multi-shipping.exit')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

SingleDelivery.propTypes = {
  isQuotation: PropTypes.bool,
  orderIdFromQuotations: PropTypes.string,
  tr: PropTypes.func.isRequired,
};

SingleDelivery.defaultProps = {
  orderIdFromQuotations: '',
  isQuotation: false,
};

export default translate('common', { wait: true })(SingleDelivery);
