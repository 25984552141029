import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import cookie from 'js-cookie';
import { io } from 'socket.io-client';
import moment from 'moment';
import * as session from '../../common/sessions';
import { getUserImage } from '../../common/utils';
import Messages from './Messages';
import ChatInput from './ChatInput';
import Controllers from './Controllers';
import '../styles.css';

const DISABLE_CHAT = process.env.REACT_APP_DISABLE_CHAT;

class ChatArea extends Component {
  constructor(props) {
    super(props);
    const { order } = this.props;
    const token = cookie.get('token');
    const { userId, customerId } = session.get();
    this.state = {
      token,
      userId,
      customerId,
      ownImage: '',
      messages: [],
      automaticLoad: false,
      emailFlag: false,
      smsFlag: false,
      messagesToSend: [],
    };
    this.sendHandler = this.sendHandler.bind(this);
    this.socket = io(`${process.env.REACT_APP_API_ENDPOINT}:8994`, { query: `userId=${userId}&orderId=${order}&customerId=${customerId}`, autoConnect: false });
    if (!DISABLE_CHAT) {
      this.socket.connect();
      this.socket.emit('join_order_room', { token });
      this.socket.on('server:message', message => {
        this.addMessage(message);
      });
      this.socket.on('server:loadMessages', messages => {
        this.addMessage(messages);
      });
    }
  }

  componentDidMount() {
    const { token } = this.state;
    this.getAvatarImage();
    this.socket.emit('client:loadMessages', { token });
  }

  componentWillUnmount() {
    const {
      messagesToSend, token, smsFlag, emailFlag,
    } = this.state;
    const message = messagesToSend.join(' ');
    const messageData = {
      message, token, smsFlag, emailFlag,
    };
    if (message) this.socket.emit('client:notifyLostMessages', messageData);
    this.socket.close();
  }

  getAvatarImage = async () => {
    const { userId } = this.state;
    await getUserImage(null, 'user-profile/pictures', userId)
      .then(urlImg => this.setState({ ownImage: urlImg }));
  };

  handleChangeConfig = (e, data) => {
    switch (data.name) {
      case 'smsCheck': this.setState({ smsFlag: data.checked }); break;
      case 'emailCheck': this.setState({ emailFlag: data.checked }); break;
      default: break;
    }
  }

  addMessage(message) {
    const { messages, automaticLoad } = this.state;
    if (Array.isArray(message)) {
      if (!automaticLoad) {
        this.setState({ automaticLoad: true });
        message.forEach(currentMsg => {
          const { userName, customerOfUser } = currentMsg;
          const { userId, customerId } = this.state;
          const fromMe = (userName === userId && customerOfUser === customerId);
          messages.push({ ...currentMsg, fromMe });
        });
      }
    } else {
      messages.push(message);
    }
    this.setState({ messages });
  }

  sendHandler(message) {
    const { messagesToSend } = this.state;
    const messagesData = messagesToSend;
    messagesData.push(message);
    const { token } = this.state;
    this.setState({ automaticLoad: false, messagesToSend: messagesData });
    const messageObject = {
      message,
      token,
      date: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    this.socket.emit('client:message', messageObject);
    messageObject.fromMe = true;
    this.addMessage(messageObject);
  }

  render() {
    const {
      smsFlag, emailFlag, messages, ownImage, automaticLoad,
    } = this.state;
    return (
      <Grid container className="chat-container">
        <Grid.Row className="chat-controller">
          <Controllers
            smsFlag={smsFlag}
            emailFlag={emailFlag}
            handleChangeConfig={this.handleChangeConfig}
          />
        </Grid.Row>
        <Grid.Row>
          <Messages
            messages={messages}
            ownImage={ownImage}
            automaticLoad={automaticLoad}
          />
        </Grid.Row>
        <Grid.Row className="chat-input">
          <ChatInput onSend={this.sendHandler} />
        </Grid.Row>
      </Grid>
    );
  }
}

ChatArea.propTypes = {
  order: PropTypes.string.isRequired,
};

ChatArea.defaultProps = {
};

export default translate('common', { wait: true })(ChatArea);
