import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Modal, Grid, Button } from 'semantic-ui-react';
import UploadFile from './upload-modal-files';

const UploadCsv = ({
  t, openUploadModal, handleClickCancel, handleAddSpecificationsRefresh,
}) => (
  <Modal open={openUploadModal}>
    <Modal.Header left>{t('agents.uploadFile')}</Modal.Header>
    <Modal.Content>
      <Grid container>
        <Grid.Row>
          <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15}>
            <UploadFile handleCloseModal={handleClickCancel} handleAddSpecificationsRefresh={handleAddSpecificationsRefresh} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={handleClickCancel}>{t('adminConfiguration.cancel')}</Button>
    </Modal.Actions>
  </Modal>
);

UploadCsv.defaultProps = {
  openUploadModal: true,
  t: null,
  handleAddSpecificationsRefresh: null,
  handleClickCancel: null,
};

UploadCsv.propTypes = {
  handleAddSpecificationsRefresh: PropTypes.func,
  handleClickCancel: PropTypes.func,
  openUploadModal: PropTypes.bool,
  t: PropTypes.func,
};

export default translate('common', { wait: true })(UploadCsv);
