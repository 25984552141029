import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';
import { currencyFormat } from '../../common/utils';

const Payment = props => {
  const { t, currency: propsCurrency, checkoutData } = props;
  const currency = propsCurrency === 2 ? 'USD' : 'MXN';
  return (
    <Grid container centered>
      {checkoutData.map(data => (
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
              <Header as="h4" color="black">
                {t('checkout.subtotal')}
                <Header as="h5" style={{ display: 'inline' }}>
                  {' '}
                  {currencyFormat(data.subtotal, currency)}
                  {' '}
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
              <Header as="h4" color="black">
                {t('checkout.IVA')}
                <Header as="h5" style={{ display: 'inline' }}>
                  {' '}
                  {currencyFormat(data.iva, currency)}
                  {' '}
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
              <Header as="h4" color="black">
                {t('checkout.shipping')}
                <Header as="h5" style={{ display: 'inline' }}>
                  {' '}
                  {currencyFormat(data.shipping, currency)}
                  {' '}
                  *
                  {' '}
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
              <Header as="h4" color="black">
                {t('checkout.total')}
                <Header as="h4" color="green" style={{ display: 'inline' }}>
                  {' '}
                  {currencyFormat(data.total, currency)}
                  {' '}
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="no-padding">
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
              <p>
                *
                {t('shoppingCart.shippingAdvice')}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
    </Grid>
  );
};

Payment.propTypes = {
  currency: PropTypes.number,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
};

Payment.defaultProps = {
  currency: 1,
  checkoutData: [],
};

export default translate('common', { wait: true })(Payment);
