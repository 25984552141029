import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Button, Dropdown,
} from 'semantic-ui-react';
import services from '../../services';
import SiclikTable from '../../../common/components/table';
import { LinearLoader } from '../../../common/components/materials';
import Toast from '../../../common/components/toast';

const getHeaders = actions => {
  const { deleteItem } = actions;
  return ([
    { key: 'id', isKey: true },
    { key: 'sku', name: 'SKU' },
    {
      key: 'id',
      name: 'Acciones',
      format: cell => (
        <Button.Group basic size="small">
          <Button
            icon="trash"
            onClick={() => deleteItem(cell)}
          />
        </Button.Group>
      ),
    },
  ]);
};

const ARRAY_SIZE = 10;

class RelatedProducts extends Component {
  constructor(props) {
    super(props);
    this.skuRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.state = {
      loader: true,
      options: [],
      relatedProducts: [],
      skus: [],
    };
  }

  componentDidMount() {
    const { formatedProducts } = this.props;
    this.handleInitialOptions(formatedProducts);
    this.getRelatedList();
  }

  componentDidUpdate(prevProps) {
    const { formatedProducts, sku } = this.props;
    if (prevProps.formatedProducts !== formatedProducts) {
      this.handleInitialOptions(formatedProducts);
    }
    if (prevProps.sku !== sku) {
      this.getRelatedList();
    }
  }

  onKeyPress = e => {
    if (e.which === 13) {
      this.handleAddButtonClick();
    }
  };

  handleInitialOptions = options => this.setState({ options });

  getRelatedList = () => {
    const { sku } = this.props;
    this.setState({ loader: true });
    services.getInternRelated(sku)
      .then(({ relatedProducts }) => this.setState({ relatedProducts, loader: false }))
      .catch(() => this.setState({ relatedProducts: [], loader: false }));
  }

  handleAddButtonClick = () => {
    const { skus } = this.state;
    const { sku } = this.props;
    if (!skus.length) return;
    this.setState({ loader: true });
    services.addInternRelated(sku, { items: skus })
      .then(({ failures }) => {
        if (failures.length) Toast(`Productos no agregados: ${failures}`, 'warning');
        this.getRelatedList();
        this.setState({
          loader: false,
          skus: [],
        });
      })
      .catch(() => {
        Toast('Ocurrio un error al agregar los productos relacionados', 'error');
        this.setState({ loader: false });
      });
  };

  handleSearchStringChange = (options, query) => {
    const keywordResults = options.filter(filterInfo => (
      filterInfo.text.toUpperCase().includes(query.toUpperCase())
    )).slice(0, ARRAY_SIZE);
    return keywordResults;
  }

  handleDelete = async id => {
    if (!id) return;
    await services.deleteInternRelated(id);
    this.getRelatedList();
  }

  handleChange = (e, { value }) => {
    this.setState({ skus: value });
  }

  render() {
    const {
      relatedProducts, loader, skus, options,
    } = this.state;
    const { t } = this.props;

    return (
      <Grid centered padded>
        <Grid.Row verticalAlign="bottom">
          <Grid.Column computer="10" tablet="10" mobile="16">
            <Header className="no-margin-bottom" as="h5">Códigos</Header>
            <Dropdown
              fluid
              selection
              multiple
              noResultsMessage
              search={this.handleSearchStringChange}
              options={options}
              value={skus}
              placeholder="Seleccionar relacionados"
              onChange={this.handleChange}
            />
          </Grid.Column>
          <Grid.Column computer="4" tablet="5" mobile="16">
            <Button
              fluid
              color="blue"
              onClick={this.handleAddButtonClick}
              disabled={!skus.length}
              className="margin-top-buttons"
            >
              {t('add')}
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="bottom">
          <Grid.Column computer="14" tablet="15" mobile="15">
            {loader && <LinearLoader color="blue" />}
            <SiclikTable
              headers={getHeaders({
                deleteItem: this.handleDelete,
              })}
              data={relatedProducts}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

RelatedProducts.propTypes = {
  sku: PropTypes.string,
  formatedProducts: PropTypes.arrayOf(PropTypes.shape({})),
};

RelatedProducts.defaultProps = {
  sku: '',
  formatedProducts: [],
};

export default translate('common', { wait: true })(RelatedProducts);
