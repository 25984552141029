import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Loader,
} from 'semantic-ui-react';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';
import service from '../../services';
import ExportExcel from '../../../common/components/ExportExcel';
import { sheetHeaders, styleHeader, styleRow } from './carts-excel';
import { TextField } from '../../../common/components/materials';

class AbandonedCarts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: '',
      disabledTextField: false,
      report: [],
      loading: false,
      headers: [
        { key: 'id', isKey: true },
        { key: 'id', name: 'ID' },
        { key: 'userName', name: 'Nombre del comprador' },
        { key: 'customerId', name: 'No. Cliente' },
        { key: 'email', name: 'Correo' },
        { key: 'requestDate', name: 'Fecha del carrito' },
      ],
    };
  }

  componentDidMount() {
    this.handleGetAbandonedCartsReport();
  }

  handleGetAbandonedCartsReport = async () => {
    const { t } = this.props;
    this.setState({ report: [], loading: true });
    await service.getAbandonedCarts()
      .then(({ carts }) => {
        if (!carts) this.setState({ report: [], loading: false });
        this.setState({ report: carts, loading: false });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('errors.emptyData'), 'warning');
        } else {
          Toast(messageError, 'error');
        }
        this.setState({ report: [], loading: false });
      });
  };

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!filter) {
      this.setState({ filter, filteredResults: allReport });
    } else {
      const filteredResults = allReport.filter(filterInfo => ((filterInfo.id && filterInfo.id.toLowerCase().includes(baseFilter))
        || (filterInfo.userName && filterInfo.userName.toLowerCase().includes(baseFilter))
        || (filterInfo.customerId && filterInfo.customerId.toLowerCase().includes(baseFilter))
        || (filterInfo.email && filterInfo.email.toLowerCase().includes(baseFilter))
        || (filterInfo.requestDate && filterInfo.requestDate.toLowerCase().includes(baseFilter))));
      this.setState({ filter, filteredResults });
    }
  };

  search = async () => {
    const { report } = await this.handleGetAbandonedCartsReport();
    this.setState({ report, loading: false, filteredResults: report });
  };

  render() {
    const { t } = this.props;
    const {
      start, report, headers, loading, disabledTextField, filter, filteredResults,
    } = this.state;
    const fileName = `carritos_abandonados_${start}`;
    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('abandonedCarts.title')}
          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={7} tablet={6} mobile={16}>
            <TextField
              fullWidth
              maxLength={40}
              value={filter || ''}
              label={t('intelligenceDashboard.filter')}
              onChange={this.handleFilter}
              disabled={disabledTextField}
            />
          </Grid.Column>
          {(loading || !report || report.length === 0)
            ? null
            : (
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={8}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={filteredResults || report}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('abandonedCarts.download')}
                  sheetName={t('abandonedCartsTitle')}
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <SiclikTable
                data={filteredResults || report}
                headers={headers}
                pageSize={10}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AbandonedCarts);
