import {
  TOGGLE_SWITCH_BUY_MODAL,
  TOGGLE_SWITCH_ITEM_MODAL,
  TOGGLE_SWITCH_DELETE_LIST_MODAL,
} from './constants';

import { sendToCart } from './request';

function toggleSwitchAddItemModal(dispatch) {
  return function actionDispatch() {
    dispatch({
      type: TOGGLE_SWITCH_ITEM_MODAL,
    });
  };
}

function toggleSwitchBuyNowModal(dispatch) {
  return function actionDispatch() {
    dispatch({
      type: TOGGLE_SWITCH_BUY_MODAL,
    });
  };
}

function toggleSwitchDeleteListModal(dispatch) {
  return function actionDispatch() {
    dispatch({
      type: TOGGLE_SWITCH_DELETE_LIST_MODAL,
    });
  };
}

function sendItemsToCart() {
  return async function actionDispatch(items) {
    await sendToCart(items);
  };
}

export default {
  toggleSwitchAddItemModal,
  toggleSwitchBuyNowModal,
  toggleSwitchDeleteListModal,
  sendItemsToCart,
};
