import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Image, Icon, Grid, Label, Popup,
} from 'semantic-ui-react';

import MenuCategoriesComputer from './menuCategoriesComp';
import MenuCategoriesMobile from './menuCategoriesMob';
import BuyNow from '../../../buy-now';
import { Toast, Notification } from '../../../common/components';
import Search from './searchInput';
import BuyNowMenu from './BuyNowIcon';

import routeNames from '../../../route-names';
import PopMenu from '../../siclik/components/pop-menu';

import request from '../services';
import getRol from '../user-rol';

const urlAWS = process.env.REACT_APP_URL_AWS;
const assetUrlSiclik = `${urlAWS}/imagenes/assets/siclik/`;
const assetUrl = `${urlAWS}/imagenes/assets/comercio/`;
const commerceImage = `${assetUrlSiclik}siclikComercio.png`;
const agentsImage = `${assetUrl}Agentes.png`;
const isotipoCs = `${assetUrlSiclik}isotipoCs.png`;

class MenuApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: 'computer',
      openModal: false,
      wishListName: '',
      items: [],
      role: '',
      showMobile: false,
    };
  }

  componentDidMount() {
    this.getRolProfile();
  }

  getRolProfile = () => this.setState({ role: getRol() });

  getWishListItems = async wishListId => {
    const { t } = this.props;
    try {
      return await request.getItemsWishList(wishListId);
    } catch (error) {
      return Toast(t('wishList.items.emptyList'), 'warning');
    }
  };

  handleOpenBuyNow = async wishList => {
    const { t } = this.props;
    if (wishList.id === 'default') return;
    if (wishList.hasItems) {
      this.setState({ wishListName: wishList.name, openModal: true });
      const items = await this.getWishListItems(wishList.id);
      this.setState({ items });
    } else Toast(t('wishList.items.emptyList'), 'warning');
  }

  showMenuMobile = () => {
    const { showMobile } = this.state;
    this.setState({ showMobile: !showMobile });
  }

  handleOnCloseModal = () => this.setState({ openModal: false });

  redirectToComercio = props => props.history.push(routeNames.homeComercio.route);

  goShoppingCart = () => {
    const { history } = this.props;
    history.push(routeNames.shoppingCart.route);
  };

  goWishListManager = () => {
    const { history } = this.props;
    history.push(routeNames.wishList.route);
  }

  hanlderOnDimmer = dimmerStatus => {
    const { onActiveMenu } = this.props;
    onActiveMenu(dimmerStatus);
  };

  logoSiclik = () => (
    <Grid padded verticalAlign="middle" centered>
      <Grid.Row centered className="no-padding-bottom">
        <Grid.Column
          computer={16}
          only="computer"
        >
          <Image
            src={commerceImage}
            size="medium"
            as="a"
            style={{ cursor: 'pointer' }}
            onClick={() => this.redirectToComercio(this.props)}
          />
        </Grid.Column>
        <Grid.Column
          mobile={16}
          tablet={16}
          className="no-padding"
          only="tablet mobile"
        >
          <Image
            src={commerceImage}
            size="small"
            as="a"
            style={{ cursor: 'pointer' }}
            onClick={() => this.redirectToComercio(this.props)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )

  iconsMenu = () => {
    const { cartCount, history } = this.props;
    const {
      openModal, wishListName, items, role,
    } = this.state;

    return (
      <Grid padded verticalAlign="middle" centered>
        <Grid.Row>
          <Grid.Column
            mobile={4}
            tablet={3}
            computer={3}
            floated="right"
          >
            <Notification />
          </Grid.Column>
          <Grid.Column
            tablet={3}
            computer={2}
            className="no-padding"
            textAlign="center"
            only="tablet computer"
          >
            {role === 'Ventas'
              ? (
                <Image
                  src={isotipoCs}
                  size="mini"
                  as="a"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(routeNames.dashboard.route)}
                />
              ) : (
                <Popup
                  content="Directorio"
                  trigger={(
                    <Image
                      src={agentsImage}
                      size="mini"
                      as="a"
                      style={{ cursor: 'pointer' }}
                      onClick={() => history.push(routeNames.dashboard.route)}
                    />
                  )}
                />
              )}
          </Grid.Column>
          <Grid.Column
            mobile={4}
            tablet={3}
            computer={2}
            textAlign="center"
          >
            <Popup
              content="Lista de Favoritos"
              trigger={(
                <Icon
                  name="favorite"
                  size="big"
                  link
                  style={{ cursor: 'pointer' }}
                  onClick={() => this.goWishListManager()}
                />
              )}
            />
          </Grid.Column>
          <Grid.Column
            mobile={4}
            tablet={3}
            computer={2}
            textAlign="center"
          >
            <BuyNowMenu
              openBuyNow={this.handleOpenBuyNow}
            />
            <BuyNow
              open={openModal}
              onClose={this.handleOnCloseModal}
              wishListName={wishListName}
              items={items}
            />
          </Grid.Column>
          <Grid.Column
            mobile={4}
            tablet={3}
            computer={3}
          >
            <div className="notificationIconContainer">
              <Icon name="shop" size="big" link onClick={this.goShoppingCart} />
              <Label className="notificationNumber" circular color="blue">
                {cartCount}
              </Label>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  computerHeader = () => {
    const { history } = this.props;
    const {
      role,
    } = this.state;
    return (
      <Grid className="no-margin no-padding">
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding">
          <Grid.Column
            tablet={3}
            computer={4}
            className="no-padding-x"
          >
            {this.logoSiclik()}
          </Grid.Column>
          <Grid.Column
            tablet={9}
            computer={7}
            className="no-padding-x"
          >
            <Search
              history={history}
              onActiveMenu={this.hanlderOnDimmer}
            />
          </Grid.Column>
          <Grid.Column
            tablet={3}
            computer={4}
            className="no-padding-x"
          >
            {this.iconsMenu()}
          </Grid.Column>
          <Grid.Column
            tablet={1}
            computer={1}
          >
            <PopMenu role={role} history={history} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  responsiveHeader = () => {
    const { history, handleSearch } = this.props;
    const {
      device, role,
    } = this.state;
    return (
      <Grid>
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-bottom">
          <Grid.Column
            mobile={7}
            tablet={8}
          >
            {this.logoSiclik()}
          </Grid.Column>
          <Grid.Column
            mobile={7}
            tablet={6}
            floated="right"
            verticalAlign="middle"
            style={{ paddingTop: '1rem' }}
          >
            {this.iconsMenu()}
          </Grid.Column>
          <Grid.Column
            tablet={2}
            mobile={2}
            className="no-padding-left"
          >
            <PopMenu role={role} history={history} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-top padding-menu-mobile" textAlign="center" verticalAlign="middle" style={{ borderBottom: '3px solid #00549F' }}>
          <Grid.Column
            className="no-padding-x"
            mobile={14}
            tablet={16}
          >
            <Search device={device} handleSearch={search => handleSearch(search)} history={history} />
          </Grid.Column>
          <Grid.Column
            className="no-padding-x"
            mobile={2}
            only="mobile"
          >
            <Icon className="blueText" size="big" name="bars" onClick={() => this.showMenuMobile()} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    const { showMobile } = this.state;
    const { t, history, isAuthenticated } = this.props;
    return (
      <Grid padded style={{ backgroundColor: '#ffffff' }}>
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-margin no-padding">
          <Grid.Column
            mobile={16}
            tablet={16}
            only="mobile tablet"
          >
            {this.responsiveHeader()}
          </Grid.Column>
          <Grid.Column
            computer={16}
            only="computer"
          >
            {this.computerHeader()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="left" className="no-margin no-padding-bottom" stretched>
          <Grid.Column
            computer={16}
            tablet={16}
            only="computer tablet"
            className="blueBackground padding-menu-comp"
          >
            <MenuCategoriesComputer t={t} history={history} isAuthenticated={isAuthenticated} />
          </Grid.Column>
          <Grid.Column
            mobile={16}
            only="mobile"
          >
            {showMobile
              ? (
                <MenuCategoriesMobile
                  t={t}
                  history={history}
                  isAuthenticated={isAuthenticated}
                  showMobile={this.showMenuMobile}
                />
              )
              : ''}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

MenuApp.propTypes = {
  handleSearch: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  cartCount: PropTypes.number,
  routeNames: PropTypes.shape({}).isRequired,
  onActiveMenu: PropTypes.func,
};

MenuApp.defaultProps = {
  cartCount: 0,
  handleSearch: () => { },
  onActiveMenu: () => { },
};

export default translate('common', { wait: true })(MenuApp);
