import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '../../../common/components/materials';

export default function SearchBar({ search, filter, label }) {
  const handleSearch = event => {
    search(event.target.value);
  };

  return (
    <TextField
      fullWidth
      rightIcon="search"
      type="text"
      maxLength={30}
      value={filter || ''}
      label={label}
      onChange={handleSearch}
    />
  );
}

SearchBar.propTypes = {
  filter: PropTypes.string,
  label: PropTypes.string,
  search: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  filter: '',
  label: '',
};
