import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import {
  Grid, Header, Dimmer, Loader, Checkbox,
} from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const validateInput = (value, field, t) => {
  let error = '';
  if (field === 'email' && value.length > 0) {
    if (!validator.isEmail(value)) error = t('multibrand.errors.notEmail');
    if (value.length > 60) error = t('multibrand.errors.toLong');
  }
  if (field === 'contact' && value.length > 0) {
    if (!validator.isAlpha(value.replace(/ /g, ''), ['es-ES'])) error = t('multibrand.errors.notAlpha');
    if (value.length > 35) error = t('multibrand.errors.toLong');
  }
  if ((field === 'phone' || field === 'mobile') && value.length > 0) {
    if (!validator.isNumeric(value)) error = t('multibrand.errors.notNumeric');
    if (value.length > 10) error = t('branchOffice.errors.toLong');
  }
  if ((field === 'reseller' || field === 'agreement' || field === 'site') && value.length > 0) {
    if (!validator.isAlphanumeric(value)) error = t('multibrand.errors.notSymbol');
    if (value.length > 9) error = t('multibrand.errors.toLong');
  }
  if ((field === 'orderNumber') && value.length > 0) {
    if (value.length > 25) error = t('branchOffice.errors.toLong');
  }
  if ((field === 'dealReg') && value.length > 0) {
    if (!validator.isAlphanumeric(value.replace(/-/g, ''), ['es-ES'])) {
      error = t('finaluser.errors.notSymbol');
    }
    if (value.length > 28) error = t('multibrand.errors.toLong');
  }
  if ((field === 'veeamNSQ') && value.length > 0) {
    if (!validator.isAlphanumeric(value.replace(/-/g, ''), ['es-ES'])) {
      error = t('finaluser.errors.notSymbol');
    }
    if (value.length > 28) error = t('multibrand.errors.toLong');
  }
  if ((field === 'partnerId') && value.length > 0) {
    if (!validator.isNumeric(value)) {
      error = t('finaluser.errors.notAlpha');
    }
    if (value.length > 28) error = t('multibrand.errors.toLong');
  }
  if ((field === 'proPartnerID') && value.length > 0) {
    if (!validator.isAlphanumeric(value.replace(/-/g, ''), ['es-ES'])) {
      error = t('finaluser.errors.notSymbol');
    }
    if (value.length > 10) error = t('multibrand.errors.toLong');
  }
  return error;
};

const InputControlled = props => {
  const {
    id = '', initialData = '', maxLength = 35, label = '', initialError = '', onChange, hint = '', required = false, disabled, t,
  } = props;
  const [localData, setData] = useState('');
  const [localError, setError] = useState('');

  useEffect(() => {
    setData(initialData);
    setError(initialError);
  }, [initialData, initialError]);

  const handleBlur = () => {
    onChange(localData, localError);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column onBlur={handleBlur} largeScreen={16} computer={16} tablet={16} mobile={16}>
          <TextField
            fullWidth
            maxLength={maxLength}
            label={label}
            error={localError || ''}
            value={localData || ''}
            onChange={e => {
              setData(e.target.value);
              setError(validateInput(e.target.value, id, t));
            }}
            hint={hint}
            disabled={disabled}
            required={required}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

InputControlled.propTypes = {
  disabled: PropTypes.bool,
  initialError: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  initialData: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputControlled.defaultProps = {
  disabled: false,
  required: false,
  hint: '',
  id: '',
  initialError: '',
  initialData: '',
  label: '',
  maxLength: 35,
  onChange: () => { },
};

const Customer = props => {
  const {
    t, email, phone, resellerId, agreement, site, contact, loading, isQuotation, orderNumber, makers, orderNumberVeeam,
    dealReg, veeamNSQ, customerId, partnerLevel, partnerId, handleChangeContact, handleChangeEmail, handleChangePhone,
    handleChangeResellerId, handleChangeAgreement, handleChangeSite,
    handleChangeOrderNumber, handleChangeOrderNumberVeeam, handleChangeDealReg, handleChangeVeeamNSQ,
    onItemClickPartnerLevel, handleChangePartnerId, veeamProPartnerID, handleChangeveeamProPartnerID, handleLicensingVeeam,
    licensingVeeamSelected, veeamContractID, handleChangeveeamContractID, ShowLicensingVeeam,
  } = props;
  return (
    <Grid container>
      <Grid.Row columns={1}>
        <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={15}>
          <Header>{t('multibrand.customerData')}</Header>
        </Grid.Column>
      </Grid.Row>
      {loading
        ? (
          <Grid.Row>
            <Dimmer active inverted><Loader active size="big" /></Dimmer>
          </Grid.Row>
        )
        : (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={5} computer={7} tablet={7} mobile={15}>
              <InputControlled
                id="contact"
                maxLength={35}
                label={t('multibrand.contact')}
                initialError={contact.error}
                onChange={handleChangeContact}
                initialData={contact.value}
                hint={t('multibrand.decriptionContact')}
                required
                disabled={isQuotation}
                t={t}
              />
            </Grid.Column>
            <Grid.Column largeScreen={6} computer={6} tablet={8} mobile={15}>
              <InputControlled
                id="email"
                maxLength={60}
                label={t('multibrand.email')}
                initialError={email.error}
                initialData={email.value}
                onChange={handleChangeEmail}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      <Grid.Row columns={2}>
        <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
          <InputControlled
            id="phone"
            maxLength={10}
            label={t('multibrand.phone')}
            initialError={phone.error}
            initialData={phone.value}
            hint={t('multibrand.descriptionPhone')}
            onChange={handleChangePhone}
            disabled={isQuotation}
            required
            t={t}
          />
        </Grid.Column>
        {/* {!loading && makers.veeam
          ? (
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <Checkbox
                label="Solicitar precio por descuento Deal Reg o NSQ"
                onChange={handleSelectDealRegReview}
                checked={selectDealRegReview}
              />
            </Grid.Column>
          )
          : ''} */}
        {!loading && makers.IBM
          ? (
            <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={15}>
              <InputControlled
                id="reseller"
                maxLength={9}
                label={t('multibrand.reseller')}
                initialError={resellerId.error}
                initialData={resellerId.value}
                onChange={handleChangeResellerId}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
          )
          : ''}
      </Grid.Row>
      {!loading && makers.IBM
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <InputControlled
                id="agreement"
                maxLength={9}
                label={t('multibrand.agreement')}
                initialError={agreement.error}
                initialData={agreement.value}
                onChange={handleChangeAgreement}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
            <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={15}>
              <InputControlled
                id="site"
                maxLength={9}
                label={t('multibrand.site')}
                initialError={site.error}
                initialData={site.value}
                onChange={handleChangeSite}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : ''}
      {!loading && makers.redHat
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <InputControlled
                id="orderNumber"
                maxLength={25}
                label={t('multibrand.purchaseOrderNumber')}
                initialError={orderNumber.error}
                initialData={orderNumber.value}
                onChange={handleChangeOrderNumber}
                disabled={isQuotation}
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : ''}
      {!loading && makers.veeam
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <InputControlled
                id="proPartnerID"
                maxLength={10}
                label={t('ProPartner ID')}
                initialError={veeamProPartnerID.error}
                initialData={veeamProPartnerID.value}
                onChange={handleChangeveeamProPartnerID}
                disabled={isQuotation}
                required
                hint={t('ProPartner ID')}
                t={t}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <InputControlled
                id="orderNumber"
                maxLength={25}
                label={t('multibrand.purchaseOrderNumber')}
                initialError={orderNumberVeeam.error}
                initialData={orderNumberVeeam.value}
                onChange={handleChangeOrderNumberVeeam}
                disabled={isQuotation}
                hint={t('Número de OC del distribuidor a CompuSoluciones')}
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : ''}

      {!loading && makers.veeam && ShowLicensingVeeam
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={16} computer={16} tablet={8} mobile={15} style={{ paddingBottom: '1rem' }}>
              <Checkbox
                toggle
                label="¿Es licenciamiento de renovación?"
                onChange={handleLicensingVeeam}
                checked={licensingVeeamSelected}
              />
            </Grid.Column>
            {licensingVeeamSelected === true
              ? (
                <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
                  <InputControlled
                    id="Contract ID"
                    maxLength={10}
                    label={t('Contract ID')}
                    initialError={veeamContractID.error}
                    initialData={veeamContractID.value}
                    onChange={handleChangeveeamContractID}
                    disabled={isQuotation}
                    required
                    hint={t('ProPartner ID')}
                    t={t}
                  />
                </Grid.Column>
              )
              : ''}
          </Grid.Row>
        )
        : ''}

      {!loading && makers.veeam && customerId === 'G000000'
        ? (
          <Grid.Row>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <InputControlled
                id="dealReg"
                maxLength={28}
                label={t('DealReg')}
                initialError={dealReg.error}
                initialData={dealReg.value}
                onChange={handleChangeDealReg}
                disabled={isQuotation}
                hint={t('(Viene del propartner portal)')}
                t={t}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={15}>
              <InputControlled
                id="veeamNSQ"
                maxLength={28}
                label={t('NSQ ID')}
                initialError={veeamNSQ.error}
                initialData={veeamNSQ.value}
                onChange={handleChangeVeeamNSQ}
                disabled={isQuotation}
                hint={t('(ID de oferta no estándar (NSQ ID) )')}
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : ''}
      {!loading && makers.VMWARE
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={6} computer={6} tablet={8} mobile={16}>
              <TextField
                select
                fullWidth
                label={t('Nivel de partner')}
                disabled={isQuotation}
                value={partnerLevel.id || ''}
                error={partnerLevel.error || ''}
                onChange={onItemClickPartnerLevel}
                options={partnerLevel.array}
              />
            </Grid.Column>
            <Grid.Column largeScreen={6} computer={6} tablet={8} mobile={16}>
              <InputControlled
                id="partnerId"
                maxLength={28}
                label={t('ID Partner')}
                initialError={partnerId.error}
                initialData={partnerId.value}
                onChange={handleChangePartnerId}
                hint={t('')}
                t={t}
                disabled={isQuotation}
                required
              />
            </Grid.Column>
          </Grid.Row>
        )
        : null}
    </Grid>
  );
};

Customer.propTypes = {
  agreement: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  contact: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
  dealReg: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  email: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  handleChangeAgreement: PropTypes.func.isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  handleChangeDealReg: PropTypes.func.isRequired,
  handleChangeEmail: PropTypes.func.isRequired,
  handleChangeOrderNumber: PropTypes.func.isRequired,
  handleChangeOrderNumberVeeam: PropTypes.func.isRequired,
  handleChangePartnerId: PropTypes.func.isRequired,
  handleChangePhone: PropTypes.func.isRequired,
  handleChangeResellerId: PropTypes.func.isRequired,
  handleChangeSite: PropTypes.func.isRequired,
  handleChangeVeeamNSQ: PropTypes.func.isRequired,
  handleChangeveeamProPartnerID: PropTypes.func.isRequired,
  handleLicensingVeeam: PropTypes.func.isRequired,
  licensingVeeamSelected: PropTypes.bool.isRequired,
  handleChangeveeamContractID: PropTypes.func.isRequired,
  // handleSelectDealRegReview: PropTypes.func.isRequired,
  isQuotation: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  makers: PropTypes.shape({
    IBM: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    VMWARE: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    redHat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    veeam: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  onItemClickPartnerLevel: PropTypes.func.isRequired,
  orderNumber: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  orderNumberVeeam: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  partnerId: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  partnerLevel: PropTypes.shape({
    array: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  phone: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  resellerId: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  // selectDealRegReview: PropTypes.bool.isRequired,
  site: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  veeamNSQ: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  veeamProPartnerID: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  veeamContractID: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  ShowLicensingVeeam: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(Customer);
