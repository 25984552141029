import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import FlexGrid from './components/FlexGrid';

class GridConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { t } = this.props;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4" color="blue">{t('Configuración de página de inicio')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <FlexGrid />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

GridConfiguration.defaultProps = {
  t: () => {},
};

GridConfiguration.propTypes = {
  t: PropTypes.func,
};

export default translate('common', { wait: true })(GridConfiguration);
