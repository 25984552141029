import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid, Header, Divider } from 'semantic-ui-react';
import Article from './article';

function ArticleList(props) {
  const { t, articleArray, currency } = props;
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={2}>
          <Header textAlign="center" as="h5">{t('orderDetail.article')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={1} computer={1} tablet={2} mobile={3} textAlign="right">
          <Header as="h5" textAlign="right">{t('orderDetail.quantity')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} textAlign="right">
          <Header as="h5" textAlign="right">{t('orderDetail.price')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} textAlign="right">
          <Header as="h5" textAlign="right">{t('orderDetail.discount')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} textAlign="right">
          <Header as="h5" textAlign="right">{t('Precio final')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={5} textAlign="right">
          <Header textAlign="right" as="h5">{t('orderDetail.amount')}</Header>
        </Grid.Column>
      </Grid.Row>
      {articleArray.map(product => (
        <Article
          tr={t}
          image={product.image}
          name={product.name}
          article={product.article}
          sku={product.sku}
          sku2={product.sku2}
          quantity={product.quantity}
          price={product.price}
          coupon={product.coupon}
          originalPrice={product.originalPrice}
          discount={product.discount}
          amount={product.amount}
          currency={currency}
        />
      ))}
      <Divider />
    </Grid>
  );
}

ArticleList.propTypes = {
  articleArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currency: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(ArticleList);
