import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const service = {};

service.getFinalUser = () => (
  request({
    route: apiRoutes.getFinalUsers(),
    method: 'GET',
  })
);

service.quoteCheckout = body => (
  request({
    route: apiRoutes.quoteCheckout(),
    method: 'POST',
    body,
  })
);

service.getAttaches = quoteId => (
  request({
    route: apiRoutes.getAttachments(quoteId),
    method: 'GET',
  })
);

service.getActiveOrder = () => (
  request({
    route: apiRoutes.getActiveOrder(),
    method: 'GET',
  })
);

service.getExchangeRates = () => (
  request({
    route: apiRoutes.getExchangeRates(),
    method: 'GET',
  })
);

service.getCFDI = () => (
  request({
    route: apiRoutes.getCfdi(),
    method: 'GET',
  })
);

service.getDeliveryConfig = quoteId => (
  request({
    route: apiRoutes.getDeliveryConfig(quoteId),
    method: 'GET',
  })
);

service.updateProductQuantity = (sku, body) => (
  request({
    route: apiRoutes.updateProductQuantity(sku),
    method: 'PUT',
    body,
  })
);

service.updateCartComment = body => (
  request({
    route: apiRoutes.updateCartComment(),
    method: 'PUT',
    body,
  })
);

service.getCouponsByCustomerId = customerId => (
  request({
    route: apiRoutes.getCouponsByCustomerId(customerId),
    method: 'GET',
  })
);

service.getBranchOfficeAddress = () => (
  request({
    route: apiRoutes.getBranchOfficeAddress(),
    method: 'GET',
  })
);

service.updateShoppingCart = body => (
  request({
    route: apiRoutes.updateShoppingCart(),
    method: 'PUT',
    body,
  })
);

service.addDeliveryConfig = body => (
  request({
    route: apiRoutes.addDeliveryConfig(),
    method: 'POST',
    body,
  })
);

service.shoppingCartToQuote = body => (
  request({
    route: apiRoutes.shoppingCartToQuote(),
    method: 'PUT',
    body,
  })
);

service.deleteShoppingCartProduct = (sku, outlet) => (
  request({
    route: apiRoutes.deleteShoppingCartProduct(sku, outlet),
    method: 'DELETE',
  })
);

service.getReviews = orderId => (
  request({
    route: apiRoutes.getQuoteReviews(orderId),
    method: 'GET',
  })
);

service.updateReview = body => (
  request({
    route: apiRoutes.updateQuoteReview(),
    method: 'POST',
    body,
  })
);

service.quoteToShoppingCart = quoteId => (
  request({
    route: apiRoutes.quoteToShoppingCart(quoteId),
    method: 'PUT',
  })
);

service.getLeaseQuoteByOrder = quoteId => (
  request({
    route: apiRoutes.getLeaseQuoteByOrder(quoteId),
    method: 'GET',
  })
);

service.getPaymentMethods = () => (
  request({
    route: apiRoutes.getPaymentMethods(),
    methiod: 'GET',
  })
);

service.buy = body => (
  request({
    route: apiRoutes.buyQuotation(),
    method: 'POST',
    body,
  })
);

service.deleteLeaseQuote = quoteId => (
  request({
    route: apiRoutes.deleteLeaseQuote(quoteId),
    method: 'DELETE',
  })
);

service.pdfQuotation = (orderId, body) => (
  request({
    route: apiRoutes.pdfQuotation(orderId),
    method: 'POST',
    body,
  })
);
export default service;
