import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Header, Dropdown, Grid, Label,
} from 'semantic-ui-react';
import SiclikTable from '../../../common/components/table';

class TableWholesalepriceAsus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilityHeaders: [
        { key: 'id', isKey: true },
        { key: 'min', name: 'Desde' },
        { key: 'max', name: 'Hasta' },
        { key: 'price', name: 'Precio Unidad' },
      ],
    };
  }

  render() {
    const { t, steppedPriceHp } = this.props;
    const {
      availabilityHeaders,
    } = this.state;
    return (
      <Grid>
        <Grid.Row style={{ marginLeft: '2rem' }}>
          <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16}>
            <Label as="a" color="blue" ribbon>
              <Dropdown
                text={(
                  <Header color="white" as="h4">
                    {t('Precios por volumen')}
                    &nbsp;
                  </Header>
                )}
                floating
                labeled
                simple
              >
                <Dropdown.Menu>
                  <Dropdown.Header
                    content={(
                      <SiclikTable
                        singleSelection
                        data={steppedPriceHp}
                        headers={availabilityHeaders}
                      />
                  )}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
TableWholesalepriceAsus.defaultProps = {
  // eslint-disable-next-line no-console
  t: () => console.log('Missing function t'),
};

TableWholesalepriceAsus.propTypes = {
  steppedPriceHp: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func,
};
export default translate('common', { wait: true })(TableWholesalepriceAsus);
