/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import {
  Grid, Menu, Segment, Header, Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as session from '../../common/sessions';
import '../style.css';

function Dashboard(props) {
  const { t, changeItem, marketingAccess } = props;
  const [shippingAccess, setShippingAccess] = useState(false);

  useEffect(() => {
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.configurarEnvio,
      securityService.getAccess.administrativo,
    ], { oneAccess: true });
    setShippingAccess(hasAccess);
  }, []);

  const handleRenderMenuItem = (name, nameIcon, titleItem, descriptionItem) => (
    <Menu.Item
      name={name}
      onClick={changeItem}
      className="margin-titles"
    >
      <Header as="h4" color="black">
        <Icon name={nameIcon} className="icon-subtitle" />
        <Header.Content>{titleItem}</Header.Content>
      </Header>
      <p>{descriptionItem}</p>
    </Menu.Item>
  );

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column>
          <Header as="h2" color="blue">{t('adminConfiguration.configuration')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding">
        <Grid.Column computer={5} tablet={16} mobile={16} className="no-padding-right padding-products">
          <Segment>
            <Header as="h3" color="black" className="titles-segment">{t('adminConfiguration.products')}</Header>
            <Menu fluid text pointing vertical>
              {handleRenderMenuItem('ElegirContenido', 'external square alternate', t('adminConfiguration.chooseContentText.chooseContent'), t('adminConfiguration.chooseContentText.chooseContentDescription'))}
              {handleRenderMenuItem('EditarContenido', 'options', t('adminConfiguration.editContentText.editContent'), t('adminConfiguration.editContentText.editContentDescription'))}
              {handleRenderMenuItem('Imagenes', 'upload', t('updateImage.updateImage'), t('updateImage.updateImageDescription'))}
              {handleRenderMenuItem('CargaMasiva', 'cloud upload', t('uploadBulkImages.title'), t('uploadBulkImages.description'))}
              {handleRenderMenuItem('Sincronizador', 'sync', t('synchronization.title'), t('synchronization.synchronizeDescription'))}
            </Menu>
          </Segment>
          {
            shippingAccess
              ? (
                <Segment>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('Logistica', 'truck', t('standardShipping.title'), t('standardShipping.description'))}
                  </Menu>
                </Segment>
              ) : null
          }
        </Grid.Column>
        <Grid.Column computer={11} tablet={16} mobile={16} className="no-padding">
          <Grid padded>
            <Grid.Row>
              <Grid.Column computer={8} tablet={16} mobile={16} className="no-padding-right">
                <Segment>
                  <Header as="h3" color="black" className="titles-segment">{t('adminConfiguration.discounts')}</Header>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('Cupones', 'percent', t('discounts.coupons'), t('discounts.couponsDescription'))}
                    {handleRenderMenuItem('Grupos', 'buromobelexperte', t('groups.couponsGroup'), t('groups.couponsGroupDescription'))}
                    {handleRenderMenuItem('Autorizadores', 'users', t('authorizers.authorizers'), t('authorizers.authorizersDescription'))}
                    {handleRenderMenuItem('AdministradoresCupones', 'cogs', t('authorizers.admin'), t('authorizers.adminDescription'))}
                    {handleRenderMenuItem('BuscadorCupones', 'search', t('discounts.couponsSearch'), t('discounts.couponsSearchDescription'))}
                  </Menu>
                </Segment>
                <Segment>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('AltaAgentes', 'add user', t('user.title'), t('user.description'))}
                  </Menu>
                </Segment>
                <Segment>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('DirectorioAgentes', 'address book', t('agents.adminAgents'), t('agents.description'))}
                  </Menu>
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} tablet={16} mobile={16} className="no-padding-right padding-searcher">
                <Segment>
                  <Header as="h3" color="black" className="titles-segment">{t('adminConfiguration.searcher')}</Header>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('Atributos', 'tags', t('tags.searcherTag'), t('tags.searcherTagDescription'))}
                    {handleRenderMenuItem('Categorizacion', 'stack exchange', t('categorizations.categorization'), t('categorizations.categorizationDescription'))}
                  </Menu>
                </Segment>
                {
                  marketingAccess
                    ? (
                      <Segment>
                        <Menu fluid text pointing vertical>
                          {handleRenderMenuItem('Promocionales', 'images', t('marketing.header'), t('marketing.description'))}
                        </Menu>
                      </Segment>
                    ) : null
                }
                {
                  marketingAccess
                    ? (
                      <Segment>
                        <Menu fluid text pointing vertical>
                          {handleRenderMenuItem('Notificaciones', 'bell', t('notifications.title'), t('notifications.description'))}
                        </Menu>
                      </Segment>
                    ) : null
                }
                <Segment>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('UsuariosDistribuidor', 'users', t('agents.adminAgents'), t('agents.description'))}
                  </Menu>
                </Segment>
                <Segment>
                  <Menu fluid text pointing vertical>
                    {handleRenderMenuItem('AltaServicioCuenta', 'add circle', t('user.serviceAccount.title'), t('user.serviceAccount.description'))}
                  </Menu>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(Dashboard);

Dashboard.defaultProps = {
  t: null,
  changeItem: null,
  marketingAccess: false,
};

Dashboard.propTypes = {
  t: PropTypes.func,
  marketingAccess: PropTypes.bool,
  changeItem: PropTypes.func,
};
