import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Menu, Segment, Grid, Dropdown, Header, Dimmer, Loader, Responsive, Sticky, Popup, Icon,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import General from './components/generalInformation';
import EndUsers from './components/endUsers';
import DeliveryAddress from './components/deliveryAddress';
import Credit from './components/credit';
import Users from './components/users';
import VeeamMonitor from './components/veeam-monitor';
import service from './services';
import Toast from '../common/components/toast';
import * as session from '../common/sessions';

class CustomerDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'general',
      customerOptions: [],
      customerArray: [],
      customer: {},
      loader: true,
      requesting: false,
      componentRequestingView: false,
      searchLoader: false,
      errorCustomer: false,
    };
  }

  componentDidMount() {
    this.handleGetCustomers();
    this.handleAccessPermission();
    window.scrollTo(0, 0);
  }

  handleAccessPermission = () => {
    const { access } = session.get();
    const hasAccessValidation = securityService.validate(access, [securityService.getAccess.autorizarRevisiones]);
    this.setState({ hasAccess: hasAccessValidation });
  };

  handleCategorySelection = (e, { name }) => this.setState({ activeItem: name });

  handleGetCustomers = () => {
    this.setState({ searchLoader: true });
    service
      .getCustomers()
      .then(response => {
        this.setState({
          customerOptions: response.customers.map(customer => ({
            key: customer.id,
            text: `${customer.id} - ${customer.name}`,
            value: customer.id,
          })),
          searchLoader: false,
        });
      })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  handleGetCustomerChange = (e, { value }) => {
    this.handleGetCustomerInfoById(value);
  };

  handleGetCustomerInfoById = customerId => {
    this.setState({ loader: true, requesting: true });
    service
      .getCustomerData(customerId)
      .then(response => {
        this.setState({
          loader: false,
          requesting: false,
          componentRequestingView: true,
          customer: response,
          customerArray: response,
          errorCustomer: false,
        });
      })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t('customerDirectory.errors.inactiveCustomer');
        Toast(messageError, 'error');
        this.setState({
          loader: false,
          requesting: false,
          componentRequestingView: false,
          errorCustomer: true,
        });
        // this.setState({ finalUserError: messageError });
      });
  };

  handleOnUpdate = (e, { width }) => {
    this.setState({ width });
  };

  handleRenderMenu = () => {
    const { activeItem, hasAccess } = this.state;
    return (
      <Menu fluid vertical tabular color="blue">
        <Menu.Item
          name="general"
          active={activeItem === 'general'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="crédito"
          content="Crédito"
          active={activeItem === 'crédito'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="usuariosFinales"
          active={activeItem === 'usuariosFinales'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="direcciones"
          active={activeItem === 'direcciones'}
          onClick={this.handleCategorySelection}
        />
        {hasAccess
          ? (
            <Menu.Item
              name="usuarios"
              active={activeItem === 'usuarios'}
              onClick={this.handleCategorySelection}
            />
          ) : ''}
        <Menu.Item name="monitorVeeam" active={activeItem === 'monitorVeeam'} onClick={this.handleCategorySelection} />
      </Menu>
    );
  };

  render() {
    const { t } = this.props;
    const {
      activeItem, customerOptions, customerArray, loader, requesting, componentRequestingView, customer, searchLoader, width, context, errorCustomer,
    } = this.state;
    let componentView;
    if (activeItem === 'general') componentView = <General customerArray={customerArray} />;
    if (activeItem === 'crédito') componentView = <Credit customerId={customer.id} />;
    if (activeItem === 'usuariosFinales') componentView = <EndUsers customerId={customer.id} />;
    if (activeItem === 'direcciones') componentView = <DeliveryAddress customerId={customer.id} />;
    if (activeItem === 'usuarios') componentView = <Users customerId={customer.id} />;
    if (activeItem === 'monitorVeeam') componentView = <VeeamMonitor customerId={customer.id} />;
    return (
      <Grid style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate}>
          <Header as="h2" color="blue">
            {t('customerDirectory.customerDirectory')}
          </Header>
        </Responsive>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={3} textAlign="right">
            <Header as="h4" color="blue">
              {t('customerDirectory.searchCustomer')}
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={13} textAlign="left">
            {searchLoader === false ? (
              <Dropdown fluid placeholder={t('customerDirectory.selectCustomer')} options={customerOptions} onChange={this.handleGetCustomerChange} search selection />
            ) : (
              <Dimmer active inverted>
                <Loader active size="medium" />
              </Dimmer>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={2}>
            {width <= 425 ? (
              <Sticky offset={50} context={context}>
                <Popup trigger={<Icon link color="blue" size="big" name="bars" />} content={this.handleRenderMenu()} on="click" position="bottom rigth" />
              </Sticky>
            ) : (
              this.handleRenderMenu()
            )}
          </Grid.Column>
          <Grid.Column stretched largeScreen={12} computer={12} tablet={12} mobile={14}>
            <Segment>
              {loader === true ? (
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  {requesting ? (
                    <Dimmer active inverted>
                      <Loader active size="medium" />
                    </Dimmer>
                  ) : (
                    t('customerDirectory.selectCustomer')
                  )}
                </Grid.Column>
              ) : (
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  {errorCustomer ? 'Esté número de cliente está inactivo, para mayor información contacte a CxC' : ''}
                </Grid.Column>
              )}
              {componentRequestingView ? componentView : ''}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(CustomerDirectory);
