import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';

import { CartContext } from '../context/cart-context';

import Wrapper from './components/Wrapper';

import WishLists from './containers/WishLists';
import WishListItems from './containers/WishListItems';
import WishListOptions from './containers/WishListOptions';

import ListsProvider from './containers/WishLists/ListsProvider';
import ItemsProvider from './containers/WishListItems/ItemsProvider';
import OptionsProvider from './containers/WishListOptions/OptionsProvider';

import './style.css';

export default function WishList(props) {
  const { t } = props;
  const { updateCartCount } = useContext(CartContext);
  return (
    <ListsProvider>
      <Wrapper>
        <Grid.Row columns={2}>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
            <WishLists />
          </Grid.Column>
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={16}>
            <ItemsProvider>
              <OptionsProvider>
                <WishListOptions t={t} getShoppingCartCount={updateCartCount} />
              </OptionsProvider>
              <WishListItems t={t} getShoppingCartCount={updateCartCount} />
            </ItemsProvider>
          </Grid.Column>
        </Grid.Row>
      </Wrapper>
    </ListsProvider>
  );
}
