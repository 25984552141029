import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  Image,
  Label,
  Button,
} from 'semantic-ui-react';

import ImageSizes from './image-sizes';
import { getContentSource } from '../../commons';

const translatePath = 'productConfig.menus.editContent.images';

function Images(props) {
  const {
    t,
    changeMainImage,
    isImageType,
    ...item
  } = props;
  const [openImageSizes, setOpenImageSizes] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);

  const handleImageClick = image => {
    setOpenImageSizes(true);
    setSelectedImage(image);
  };

  const handleSelectNewMainImage = image => {
    changeMainImage(image.value.id);
    setSelectedImage({
      ...selectedImage,
      value: {
        ...selectedImage.value,
        isMain: true,
      },
    });
  };

  const ItemImages = () => (
    <Card.Group doubling>
      {item.images.map(image => (
        <Card key={image.value.id}>
          <Card.Content textAlign="center">
            <Image size={item.size} src={image.value.low} />
          </Card.Content>
          <Card.Content extra>
            <Label size="mini">{getContentSource(image.source, t)}</Label>
            {(image.value.isMain && isImageType) && (
              <Label color="green" size="mini">{t(`${translatePath}.mainImage`)}</Label>
            )}
            <Button
              floated="right"
              color="grey"
              icon="images"
              size="mini"
              onClick={() => handleImageClick(image)}
            />
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );

  const ImageSizeModal = () => (
    selectedImage && (
      <ImageSizes
        sku={item.sku}
        itemImageSizes={selectedImage}
        isImageType={isImageType}
        open={openImageSizes}
        setOpen={setOpenImageSizes}
        changeMainImage={handleSelectNewMainImage}
      />
    )
  );

  return (
    <Container fluid>
      {
        item.images.length > 0
          ? <ItemImages />
          : <p>{t(`${translatePath}.noImages`)}</p>
      }
      <ImageSizeModal />
    </Container>
  );
}

Images.propTypes = {
  changeMainImage: PropTypes.func.isRequired,
  sku: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  isImageType: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.string.isRequired,
    value: PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMain: PropTypes.bool.isRequired,
      thumbnail: PropTypes.string,
      low: PropTypes.string,
      medium: PropTypes.string,
      big: PropTypes.string,
    }),
  })).isRequired,
};

Images.defaultProps = {
  isImageType: false,
};

export default translate('common', { wait: true })(Images);
