import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

function getTotalItems() {
  return request({
    route: apiRoutes.getShoppingCartTotalItems(),
    method: 'GET',
  });
}

export default {
  getTotalItems,
};
