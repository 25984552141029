import React, { Component } from 'react';
import {
  Header, Grid, Dropdown, Loader, Button, Confirm, GridRow, GridColumn, Icon, Label, Modal, Rating, Divider, TransitionablePortal,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import { io } from 'socket.io-client';
import moment from 'moment';
import * as session from '../common/sessions';
import Months from '../common/components/months';
import SiclikTable from '../common/components/table';
import Toast from '../common/components/toast';
import Years from '../common/components/years';
import { TextField } from '../common/components/materials';
import quotesService from './services';
import routes from '../route-names';

const DISABLE_CHAT = process.env.REACT_APP_DISABLE_CHAT;

const currentDate = moment();

class QuotationMain extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const { userId, customerId } = session.get();
    this.state = {
      userIdToken: userId,
      year: currentDate.year(),
      monthApplicant: currentDate.month() + 1,
      search: '',
      searchError: '',
      messageTable: '',
      quotesArray: [],
      quotesArrayFiltered: [],
      review: 1,
      reviewArray: [
        { key: 1, text: 'Requiere revisión', value: 1 },
        { key: 2, text: 'Todas', value: 2 },
      ],
      quotesHeaders: [
        { key: 'orderId', isKey: true },
        {
          key: 'unreadMessages',
          name: t('quotes.chat'),
          format: (cell, row) => (
            <Grid>
              <GridRow columns={2}>
                <GridColumn largeScreen={5} computer={9} tablet={9} mobile={12}>
                  <Icon name="wechat" size="big" disabled={!row.isInvited} />
                </GridColumn>
                <GridColumn largeScreen={5} computer={9} tablet={9} mobile={12} floated="left">
                  <Label floating circular size="tiny" color="red">
                    {row.unreadMessages}
                  </Label>
                </GridColumn>
              </GridRow>
            </Grid>
          ),
        },
        { key: 'name', name: t('quotes.name') },
        { key: 'date', name: t('quotes.applicationDate') },
        { key: 'endUserName', name: t('quotes.endUser') },
        { key: 'seller', name: t('quotes.seller') },
        { key: 'userName', name: t('quotes.requester') },
        { key: 'status', name: t('quotes.status') },
        {
          key: 'open',
          name: 'Abrir',
          format: (cell, row) => (<Button color="blue" icon="file outline" onClick={() => props.history.push(`/cotizaciones/agente/detalle/${row.orderId}/${row.customerId}`)} />),
        },
      ],
      statusArray: [
        { key: 1, text: 'Cotización', value: 'Cotización' },
        { key: 2, text: 'Carrito', value: 'Carrito' },
      ],
      arrayUsers: [],
      status: '',
      loading: true,
      quoteId: '',
      quoteName: '',
      open: false,
      userId: '',
      userIdLoged: '',
      customerIdToSearch: '',
      arrayCustomers: [],
      filterOptions: [
        // { key: 2, text: 'Mi área', value: 2 },
        { key: 1, text: 'En las que estoy involucrado', value: 1 },
        { key: 2, text: 'Por distribuidor', value: 2 },
        { key: 4, text: 'Por colaborador', value: 4 },
        { key: 3, text: 'Todas', value: 3 },
      ],
      optionId: 1,
      ratingStars: undefined,
      platformId: 5,
      comments: '',
      openSurvey: false,
    };
    const { order } = this.state;
    this.socket = io(`${process.env.REACT_APP_API_ENDPOINT}:8994`, { query: `userId=${userId}&customerId=${customerId}&orderId=${(order || null)}`, autoConnect: false });
    if (!DISABLE_CHAT) {
      this.socket.connect();
      this.socket.on('server:newMessages', message => {
        this.reloadUnreadMessages(message);
      });
    }
  }

  componentDidMount = () => {
    this.handleAccessPermission();
    this.handleGetUsers();
    this.handleIsSurveyAnswered();
    this.handleGetCustomers();
  }

  reloadUnreadMessages = async message => {
    const { quotesArrayFiltered, userIdToken: userId } = this.state;
    const { orderId } = message;
    if (quotesArrayFiltered.length > 0) {
      const order = quotesArrayFiltered.find(quoute => quoute.orderId === orderId);
      if (order !== undefined) {
        const options = {
          userId,
          orders: [
            { orderId: order.orderId },
          ],
        };
        const { orders } = await quotesService.getUnreadMessages(options);
        const { unreadMessages } = orders.find(orderResult => orderResult.orderId === order.orderId);
        const orderPosition = quotesArrayFiltered.findIndex(quote => quote.orderId === order.orderId);
        if (orderPosition > -1) quotesArrayFiltered[orderPosition].unreadMessages = unreadMessages;
        this.setState({ quotesArrayFiltered });
      }
    }
  };

  handleAccessPermission = () => {
    const { userId } = session.get();
    this.setState({ userId, userIdLoged: userId });
    this.handleGetQuotations(userId, 0);
  }

  handleGetQuotationsByCustomer = customerId => {
    const { t } = this.props;
    const { year } = this.state;
    this.setState({ loading: true });
    quotesService.getQuotationByCustomerId(customerId)
      .then(quotes => {
        let quotation = [];
        if (quotes.quotation.length > 0) {
          quotation = quotes.quotation.map(quote => {
            const date = moment(quote.date).format('DD-MM-YYYY');
            return { ...quote, date };
          });
          this.setState({ quotesArray: quotation, quotesArrayFiltered: quotation, loading: false });
        } else {
          this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        }
        return quotation;
      })
      .then(quotes => this.filterGeneral(year, 2, quotes))
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        Toast(messageError, 'error');
      });
  }

  handleGetQuotations = (userId, all) => {
    const { t } = this.props;
    const { year } = this.state;
    this.setState({ loading: true });
    quotesService.getQuotationsByUser(userId, all)
      .then(quotes => {
        let quotation = [];
        if (quotes.quotation.length > 0) {
          quotation = quotes.quotation.map(quote => {
            const date = moment(quote.date).format('DD-MM-YYYY');
            return { ...quote, date };
          });
          this.setState({ quotesArray: quotation, quotesArrayFiltered: quotation, loading: false });
        } else {
          this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        }
        return quotation;
      })
      .then(quotes => this.filterGeneral(year, 2, quotes))
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        Toast(messageError, 'error');
      });
  }

  handleGetCustomers = async () => {
    let { arrayCustomers } = this.state;
    arrayCustomers = await quotesService.getCustomers()
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (arrayCustomers && arrayCustomers.customers) {
      arrayCustomers = arrayCustomers.customers.map(customer => (
        {
          key: customer.id,
          text: `${customer.id} - ${customer.name}`,
          value: customer.id,
        }
      ));
    }
    this.setState({ arrayCustomers });
  }

  /* handleGetStatus = statusItems => {
    if (statusItems && statusItems.length > 0) {
      const status = statusItems.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
      const statusArray = status.map(order => { const formatedStatus = { key: order, text: order, value: order }; return formatedStatus; });
      this.setState({ statusArray });
    }
  } */

  handleGetUsers = () => {
    const { t } = this.props;
    const arrayUsers = [];
    const { customerId } = session.get();
    quotesService.getUsers(customerId)
      .then(response => {
        response.users.map(user => {
          if (/* user.confirmedAccount === 1 && */ user.name && user.name.length > 1) {
            arrayUsers.push({ key: user.id, text: `${user.name} ${user.surname}`, value: user.id });
          }
          return user;
        });
        this.setState({ arrayUsers });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  validations = event => {
    const { t } = this.props;
    const { quotesArray } = this.state;
    const maxLength = 31;
    let mistakes = false;
    const { value: search } = event.target;
    if ((search.trim()).length > maxLength) {
      this.setState({ search, searchError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) {
      this.setState({ search, searchError: '' });
      this.filterGeneral(search, 1, quotesArray);
    }
  }

  handleReviewChange = (e, { value: review }) => {
    const { quotesArray } = this.state;
    this.setState({ review });
    this.filterGeneral(review, 6, quotesArray);
  }

  handleYearChange = year => {
    const { quotesArray } = this.state;
    this.setState({ year });
    this.filterGeneral(year, 2, quotesArray);
  }

  handleMonthApplicantChange = monthApplicant => {
    const { quotesArray } = this.state;
    this.setState({ monthApplicant });
    this.filterGeneral(monthApplicant, 5, quotesArray);
  }

  handleStatusChange = (e, { value }) => {
    const { quotesArray } = this.state;
    this.setState({ status: value });
    this.filterGeneral(value, 4, quotesArray);
  }

  filterGeneral = (value, typeFilter, quotesArray) => {
    let quotesArrayFiltered = JSON.parse(JSON.stringify(quotesArray));
    let {
      search, year, monthApplicant, status, review,
    } = this.state;
    if (typeFilter === 1) search = value;
    if (typeFilter === 2) year = value;
    if (typeFilter === 4) status = value;
    if (typeFilter === 5) monthApplicant = value;
    if (typeFilter === 6) review = value;

    if (search) quotesArrayFiltered = this.filterForSearch(search, quotesArrayFiltered);
    if (year) quotesArrayFiltered = this.filterForYear(year, quotesArrayFiltered);
    if (status) quotesArrayFiltered = this.filterForStatus(status, quotesArrayFiltered);
    if (monthApplicant) quotesArrayFiltered = this.filterForMonthApplicant(monthApplicant, quotesArrayFiltered);
    if (review) quotesArrayFiltered = this.filterForReviews(review, quotesArrayFiltered);

    this.setState({ quotesArrayFiltered });
  }

  filterForSearch = (filter, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => (filterInfo.name && filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.endUserName && filterInfo.endUserName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.customerId && filterInfo.customerId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.customerName && filterInfo.customerName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.seller && filterInfo.seller.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      || (filterInfo.userName && filterInfo.userName.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    return foundData;
  }

  filterForReviews = (option, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => Number(filterInfo.review) === Number(option));
    return foundData.length > 0 ? foundData : quotesToFilter;
  }

  filterForYear = (year, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => Number(filterInfo.date.substring(6, 10)) === Number(year));
    return foundData.length > 0 ? foundData : quotesToFilter;
  }

  filterForMonthApplicant = (month, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => Number(filterInfo.date.substring(3, 5)) === Number(month));
    return foundData.length > 0 ? foundData : quotesToFilter;
  }

  filterForStatus = (status, quotesToFilter) => {
    let foundData = [];
    foundData = quotesToFilter.filter(filterInfo => filterInfo.status === status);
    return foundData.length > 0 ? foundData : quotesToFilter;
  }

  handleClickQuoteToCart = quote => this.setState({ quoteId: quote.orderId, quoteName: quote.name, open: true });

  handleConfirmQuoteToCart = () => {
    const { t, history } = this.props;
    const { quoteId } = this.state;
    quotesService.quoteToShoppingCart(quoteId)
      .then(changeQuote => {
        if (changeQuote) history.push(routes.shoppingCart.route);
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ quotesArray: [], quotesArrayFiltered: [], loading: false });
        Toast(messageError, 'error');
      });
  }

  handleChangeUser = (e, { value }) => {
    this.setState({ userId: value });
    this.handleGetQuotations(value, 0);
  }

  handleChangeFilter = (e, { value }) => {
    const { userIdLoged, userId } = this.state;
    this.setState({ optionId: value });
    if (value === 1) this.handleGetQuotations(userIdLoged, 0);
    if (value === 4) this.handleGetQuotations(userId, 0);
    if (value === 3) this.handleGetQuotations(userIdLoged, 1);
  }

  handleChangeCustomer = (e, { value }) => {
    this.setState({ customerIdToSearch: value });
    this.handleGetQuotationsByCustomer(value);
  }

  handleInputComments= e => {
    this.setState({ comments: e.target.value });
  }

  handleRate = (e, { rating }) => {
    this.setState({ ratingStars: rating });
  }

  handleIsSurveyAnswered = async () => {
    const { userId } = session.get();
    const surveys = await quotesService.getSurveyResponse();
    const userExist = Object.values(surveys);
    const findUser = userExist.find(row => row.find(user => user.userId === userId && user.platformId === 5 && user.active === 1));
    return findUser ? this.setState({ openSurvey: false }) : this.setState({ openSurvey: true });
  }

  handleSaveSurvey = async () => {
    const { t } = this.props;
    const { customerId } = session.get();
    const {
      userId, ratingStars, comments, platformId,
    } = this.state;
    try {
      const body = {
        userId,
        customerId,
        platformId,
        rating: ratingStars || undefined,
        comments: comments || '',
      };
      if (comments.length < 250 && ratingStars !== undefined) {
        quotesService.addSurveyResponse(body);
        this.setState({ openSurvey: false });
        Toast(t('survey.thanksSurvey'), 'success');
      } else {
        return comments.length > 250
          ? Toast(t('survey.errorComments'), 'error')
          : Toast(t('survey.error'), 'info') && this.setState({ openSurvey: true, ratingStars: undefined });
      }
    } catch (error) {
      let messageError = '';
      if (!error.code) {
        messageError = t('error-codes.default');
      } else {
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
    return this.setState({ ratingStars: undefined });
  }

  render() {
    const { t } = this.props;
    const {
      search, searchError, messageTable, quotesArrayFiltered, quotesHeaders, statusArray, status, loading,
      arrayUsers, open, quoteName, userId, filterOptions, optionId, review, reviewArray, year, monthApplicant,
      customerIdToSearch, arrayCustomers, comments, openSurvey,
    } = this.state;
    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column className="no-padding-x" largeScreen={4} computer={5} tablet={5} mobile={15}>
            <Header color="blue" as="h1">{t('quotesMainAgent.reviews')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={5} tablet={5}>
            <Dropdown
              options={filterOptions}
              placeholder={t('quotesMainAgent.chooseCategory')}
              onChange={this.handleChangeFilter}
              value={optionId}
              selection
            />
          </Grid.Column>
          {optionId === 4 && arrayUsers && arrayUsers.length > 0
            ? (
              <Grid.Column largeScreen={4} computer={5} tablet={5}>
                <Dropdown
                  options={arrayUsers}
                  placeholder={t('quotesMainAgent.chooseQuotes')}
                  onChange={this.handleChangeUser}
                  value={userId}
                  search
                  selection
                />
              </Grid.Column>
            )
            : null}
          {optionId !== 2 && optionId !== 4
            ? <Grid.Column largeScreen={4} computer={5} tablet={5} /> : null}
          {optionId === 2 && arrayCustomers && arrayCustomers.length > 0
            ? (
              <Grid.Column largeScreen={4} computer={5} tablet={5}>
                <Dropdown
                  options={arrayCustomers}
                  placeholder={t('quotesMainAgent.chooseQuotes')}
                  onChange={this.handleChangeCustomer}
                  value={customerIdToSearch}
                  search
                  selection
                />
              </Grid.Column>
            )
            : null}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : (
            <Grid.Row centered>
              <Grid.Column className="no-padding-left" largeScreen={5} computer={5} tablet={9} mobile={12}>
                <TextField
                  fullWidth
                  maxLength={30}
                  value={search || ''}
                  error={searchError || ''}
                  label={t('quotesMainAgent.search')}
                  onChange={this.validations}
                />
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={2} tablet={2} mobile={2}>
                <Years min={2018} max={2030} year={year} change={this.handleYearChange} />
              </Grid.Column>
              <Grid.Column className="no-padding-x" largeScreen={2} computer={4} tablet={3} mobile={15}>
                <Months
                  title="quotesMainAgent.monthApplicant"
                  tr={t}
                  month={monthApplicant}
                  change={this.handleMonthApplicantChange}
                />
              </Grid.Column>
              <Grid.Column className="no-padding-x" largeScreen={2} computer={4} tablet={4} mobile={15}>
                <Dropdown
                  selection
                  value={status}
                  options={statusArray}
                  placeholder={t('quotesMainAgent.status')}
                  onChange={this.handleStatusChange}
                  clearable
                />
              </Grid.Column>
              <Grid.Column className="no-padding-x" largeScreen={2} computer={4} tablet={4} mobile={15}>
                <Dropdown
                  selection
                  value={review}
                  options={reviewArray}
                  placeholder={t('Filtrar')}
                  onChange={this.handleReviewChange}
                  clearable
                />
              </Grid.Column>
            </Grid.Row>
          )}
        {
          loading
            ? null
            : (
              <Grid.Row centered>
                <Grid.Column className="no-padding-x" largeScreen={12} computer={15} tablet={15} mobile={15} style={{ overflowX: 'auto' }}>
                  <SiclikTable
                    data={quotesArrayFiltered}
                    headers={quotesHeaders}
                    pageSize={10}
                    singleSelection
                    noDataMessage={messageTable}
                  />
                </Grid.Column>
              </Grid.Row>
            )
        }
        <Grid.Row>
          <Confirm
            open={open}
            header={`${t('quotesMainAgent.quoteToCartTitle')} ${quoteName} ${t('quotesMainAgent.quoteToCartTitleComplement')}`}
            content={t('quotesMainAgent.quoteToCartContent')}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ open: false })}
            onConfirm={this.handleConfirmQuoteToCart}
          />
        </Grid.Row>
        <TransitionablePortal open={openSurvey} animation="fade">
          <Modal size="small" open={openSurvey} centered>
            <Modal.Header>
              {t('survey.nameModal')}
              &nbsp;
              <Icon className="check" />
            </Modal.Header>
            <Modal.Content>
              <Grid.Row centered>
                {t('survey.descriptionSurvey')}
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <br />
                {t('survey.qualificationCollaborator')}
              </Grid.Row>
              <Grid.Row>
                <br />
                <Rating icon="star" size="massive" maxRating={5} onRate={this.handleRate} clearable />
              </Grid.Row>
              <br />
              <Grid.Row>
                {t('survey.comments')}
              </Grid.Row>
              <Grid.Row>
                <br />
                <TextField
                  fullWidth
                  outlined
                  maxLength={250}
                  multiline
                  rows={4}
                  value={comments || ''}
                  onChange={this.handleInputComments}
                />
              </Grid.Row>
            </Modal.Content>
            <Modal.Actions>
              <Button size="medium" color="blue" onClick={this.handleSaveSurvey}>{t('survey.saveSurvey')}</Button>
            </Modal.Actions>
          </Modal>
        </TransitionablePortal>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(QuotationMain);
