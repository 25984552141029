import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const banners = {};

banners.getHomeComercioAssets = () => (
  request({
    route: apiRoutes.getHomeComercioAssets(),
    method: 'GET',
  })
);

banners.getMostSelledProducts = (body = {}) => (
  request({
    route: apiRoutes.getMostSelledProducts(body),
  })
);

export default banners;
