import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const order = {};

order.getOrderDetail = orderId => (
  request({
    route: apiRoutes.getOrderDetails(orderId),
    method: 'GET',
  })
);

order.getStatusDeliveryTraking = orderId => (
  request({
    route: apiRoutes.getDeliveryTraking(orderId),
    method: 'GET',
  })
);

order.getAttachments = orderId => (
  request({
    route: apiRoutes.getAttachments(orderId),
    method: 'GET',
  })
);

order.getRequestFile = body => (
  request({
    route: apiRoutes.getCfdiAttachBuffer(),
    method: 'POST',
    body,
  })
);

order.getCuponsByOrder = orderId => (
  request({
    route: apiRoutes.getCuponsByOrder(orderId),
    method: 'GET',
  })
);

order.getAttachesByOrder = orderId => (
  request({
    route: apiRoutes.getAttachesByOrder(orderId),
    method: 'GET',
  })
);

export default order;
