/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid, Image, Header, Modal, Icon, Responsive, Popup, Label, Divider,
} from 'semantic-ui-react';
import { currencyFormat } from '../../common/utils';

const handleRenderCouponDetails = (coupons, tr) => (
  <Popup
    size="huge"
    trigger={<Label as="a" tag color="blue">{tr('checkout.seeCoupon')}</Label>}
    flowing
    on="click"
  >
    {coupons[0].couponsApplied.map(item => (
      <Grid centered columns={2}>
        <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={14}>
          <Header as="h4" color="blue">
            {tr('checkout.couponCode')}
            &nbsp;
            <Header as="h5" color="black">
              {' '}
              {item.couponCode}
            </Header>
          </Header>
        </Grid.Column>
        <Divider horizontal />
      </Grid>
    ))}
  </Popup>
);

function Article(props) {
  const {
    sku, sku2, image, name, tr, series, quantity, discount, originalPrice, price, currency, coupon,
  } = props;
  return (
    <Grid.Row columns={5}>
      <Responsive as={Header} minWidth={426}>
        <Grid.Column largeScreen={2} computer={2} tablet={2} textAlign="center">
          {sku !== 'ENVIOCS' && sku !== 'SERVICIO ELECTRONICO'
            ? <Image src={image} size="tiny" />
            : (
              <Image size="tiny">
                <Responsive as={Header} minWidth={426}>
                  <Icon color="blue" name={sku !== 'SERVICIO ELECTRONICO' ? 'truck' : 'file outline'} size="large" />
                </Responsive>
                <Responsive as={Header} maxWidth={425}>
                  <Icon color="blue" name={sku !== 'SERVICIO ELECTRONICO' ? 'truck' : 'file outline'} size="large" />
                </Responsive>
              </Image>
            )}
        </Grid.Column>
      </Responsive>
      <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={2}>
        <Header as="h5" color="blue">{name}</Header>
        <p>
          {tr('orderDetail.sku')}
          {': '}
          {sku}
          {' '}
          {' '}
        </p>
        <p hidden={!sku2 || sku2 === ''} style={{ fontSize: 11 }}>
          SKU2
          {': '}
          {sku2}
          {' '}
          {' '}
        </p>
        {series && series.length > 0
          ? (
            <Modal trigger={<a>{tr('orderDetail.series')}</a>}>
              <Modal.Header>{tr('orderDetail.series')}</Modal.Header>
              <Modal.Content image>
                <Modal.Description>
                  <p>Serie 1</p>
                  <p>Serie 2</p>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          )
          : ''}
      </Grid.Column>
      <Grid.Column largeScreen={2} computer={2} tablet={1} mobile={3} textAlign="right">{quantity}</Grid.Column>
      <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} textAlign="right">{currencyFormat((discount > 0 && originalPrice ? Number(originalPrice) : price), currency)}</Grid.Column>
      <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} textAlign="right">
        {currencyFormat(discount, currency)}
        {coupon && coupon.length > 0 ? handleRenderCouponDetails(coupon, tr) : null}
      </Grid.Column>
      <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} textAlign="right">{currencyFormat(price, currency)}</Grid.Column>
      <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={4} textAlign="right">{currencyFormat((price * quantity), currency)}</Grid.Column>
    </Grid.Row>
  );
}

Article.propTypes = {
  coupon: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currency: PropTypes.string.isRequired,
  discount: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  originalPrice: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sku: PropTypes.string.isRequired,
  sku2: PropTypes.string.isRequired,
  tr: PropTypes.func.isRequired,
};

export default Article;
