import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Button, Modal, Grid, Dropdown,
} from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';
import { TextField } from '../../common/components/materials';

function setCategories(industries = []) {
  return industries.map(industry => ({
    key: industry.key,
    text: industry.category,
    value: industry.value,
  }));
}

function setSectors(sectors = []) {
  return sectors.map((sector, index) => ({
    key: index + 1,
    text: sector,
    value: sector,
  }));
}

const ClientsOptions = props => {
  const {
    t, handleNewClient, data, headers, clean, updateClean, reason, rfc, shortname, industry, handleClickCancel,
    finalUserId, handleGetFinalUsersByRFC, getOfIntelisis, open, exist, handleGetSelectedOptions,
    validations, sectors, address, loader, addCustomer, showAdd, disableRfcCustomer,
  } = props;

  return (
    <Modal open={open}>
      <Modal.Header>{t('Cliente')}</Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
              <TextField
                fullWidth
                maxLength={13}
                label={t('finaluser.rfc')}
                disabled={Boolean(finalUserId || getOfIntelisis || disableRfcCustomer)}
                value={rfc.value || ''}
                error={rfc.error || ''}
                onChange={rfc.change}
              />
            </Grid.Column>
            {!exist
              ? (
                <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={16}>
                  <TextField
                    fullWidth
                    maxLength={50}
                    label={t('finaluser.reason')}
                    disabled={Boolean(finalUserId || getOfIntelisis)}
                    value={reason.value || ''}
                    error={reason.error || ''}
                    onChange={reason.change}
                    helperText={(finalUserId || getOfIntelisis) ? '' : t('finaluser.reasonHelperText')}
                    onBlur={reason.deleteCapitalRegime}
                  />
                </Grid.Column>
              )
              : null}
            {exist
              ? (
                <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={7}>
                  <Button onClick={handleGetFinalUsersByRFC} content="Buscar cliente" labelPosition="left" icon="search" />
                </Grid.Column>
              )
              : null}
            {exist
              ? <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={1} />
              : null}
            {exist && !getOfIntelisis && addCustomer
              ? (
                <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={7}>
                  <Button onClick={handleNewClient} content="Agregar nuevo" labelPosition="left" icon="add user" color="blue" />
                </Grid.Column>
              )
              : null}
          </Grid.Row>
          {exist && data.length > 0
            ? (
              <Grid.Row>
                <SiclikTable
                  selectable
                  singleSelection
                  data={data}
                  headers={headers}
                  pageSize={5}
                  getSelectedRows={rows => handleGetSelectedOptions({ rows })}
                  clean={clean}
                  updateClean={updateClean}
                />
              </Grid.Row>
            )
            : null}
          {!exist
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={16}>
                  <TextField
                    fullWidth
                    maxLength={100}
                    label={t('finaluser.shortname')}
                    value={shortname.value || ''}
                    error={shortname.error || ''}
                    onChange={shortname.change}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
                  <Dropdown
                    selection
                    fluid
                    name="sectorValue"
                    placeholder={t('finaluser.sector')}
                    options={setSectors(sectors.options)}
                    value={sectors.value}
                    error={sectors.error}
                    onChange={sectors.onChange}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                  <Dropdown
                    selection
                    fluid
                    name="industryValue"
                    placeholder={t('finaluser.industry')}
                    options={setCategories(industry.options)}
                    value={industry.value}
                    error={industry.error}
                    onChange={industry.change}
                  />
                </Grid.Column>
              </Grid.Row>
            )
            : null}
          {!exist
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={8}>
                  <TextField
                    fullWidth
                    maxLength={5}
                    label={t('branchOffice.pc')}
                    error={address.value.error}
                    onChange={address.onChange}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign="middle" largeScreen={3} computer={3} tablet={3} mobile={8}>
                  <Button
                    content={t('orders.search')}
                    color="blue"
                    size="small"
                    compact
                    floated="left"
                    loading={address.value.loader}
                    onClick={address.onClick}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
                  <b>{t('headers.address.estado')}</b>
                  <p>{address.value.state}</p>
                </Grid.Column>
                <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
                  <b>{t('headers.address.country')}</b>
                  <p>{address.value.country}</p>
                </Grid.Column>
              </Grid.Row>
            )
            : null}
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {!finalUserId && showAdd
          ? <Button color="blue" loading={loader} onClick={() => validations(1)}>{t('add')}</Button>
          : null}
        {finalUserId
          ? <Button color="blue" loading={loader} onClick={() => validations(2)}>{t('update')}</Button>
          : null}
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

ClientsOptions.propTypes = {
  clean: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  disableRfcCustomer: PropTypes.bool.isRequired,
  exist: PropTypes.bool.isRequired,
  finalUserId: PropTypes.string.isRequired,
  getOfIntelisis: PropTypes.bool.isRequired,
  addCustomer: PropTypes.bool.isRequired,
  showAdd: PropTypes.bool.isRequired,
  handleClickCancel: PropTypes.func.isRequired,
  handleGetFinalUsersByRFC: PropTypes.func.isRequired,
  handleGetSelectedOptions: PropTypes.func.isRequired,
  handleNewClient: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loader: PropTypes.bool.isRequired,
  industry: PropTypes.shape({
    change: PropTypes.func,
    error: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.string,
  }).isRequired,
  sectors: PropTypes.shape({
    change: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({})),
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  address: PropTypes.shape({
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  reason: PropTypes.shape({
    change: PropTypes.func,
    error: PropTypes.string,
    value: PropTypes.string,
    deleteCapitalRegime: PropTypes.func,
  }).isRequired,
  rfc: PropTypes.shape({
    change: PropTypes.func,
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  shortname: PropTypes.shape({
    change: PropTypes.func,
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  updateClean: PropTypes.bool.isRequired,
  validations: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ClientsOptions);
