import React from 'react';
import { translate } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';

function HPEViewer() {
  window.scrollTo(0, 0);
  const path = 'https://tecnologiaaldiacs.com';
  return (
    <div>
      <Helmet
        title="siclik - tecnología al día"
        meta={[
          {
            name: 'description',
            content: 'Conecta a tus clientes con la innovación de productos, capacitaciones, novedades, promociones y campañas de HPE.',
          },
        ]}
      />
      <Iframe
        url={path}
        position="absolute"
        overflow-x="hidden"
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </div>
  );
}

export default translate('common', { wait: true })(HPEViewer);
