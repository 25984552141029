/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { hasAccess } from '../common/permission';
import * as session from '../common/sessions';

export const PrivateRoute = ({
  component, accessRole, renderLayout, redirectTo, ...rest
}) => {
  const [userSession, setUserSession] = useState(false);

  async function validateUserSession() {
    const currentSession = session.get();
    if (currentSession.refreshToken) {
      await session.renewAccessToken();
    }
    if (session.get().isAuthenticated) {
      setUserSession(session.get());
    } else setUserSession({ isAuthenticated: false });
  }

  function hasValidSession() {
    return userSession && userSession.isAuthenticated;
  }

  function isFetchingSession() {
    return !userSession;
  }

  function loadScreen() {
    return '';
  }

  function goLoginScreen(location) {
    return <Redirect to={{ pathname: redirectTo, state: { from: location } }} />;
  }

  function goHome(location) {
    return <Redirect to={{ pathname: '/', state: { from: location } }} />;
  }

  useEffect(() => {
    validateUserSession();
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        switch (true) {
          case hasValidSession():
            if (hasAccess(accessRole)) {
              return renderLayout({ component, props, isAuthenticated: true });
            }
            return goHome(props.location);
          case isFetchingSession():
            return loadScreen();
          default:
            return goLoginScreen(props.location);
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  accessRole: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]),
  renderLayout: PropTypes.func.isRequired,
  location: PropTypes.shape({}),
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  accessRole: '',
  location: null,
  redirectTo: '/',
};

export const PublicRoute = ({ component, renderLayout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      renderLayout({ component, props })
    )}
  />
);

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
  ]).isRequired,
  renderLayout: PropTypes.func.isRequired,
};
