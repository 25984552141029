import PropTypes from 'prop-types';
import React from 'react';
import {
  Header, Grid, Dimmer, Loader, Segment,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import errorCodes from '../constants';
import FinalUser from './final-user';
import Payment from './payment';
import Orders from './orders';
import Attachments from './attachments';
import ItemDivider from './item-divider';
import Chat from '../../chat';
import LeaseOptions from './lease-options';

function UserOrder(props) {
  const {
    t, errorCode, currencyOptions, orderId, checkoutData,
    paymentMethods, odersData, ordersLoader, currentCurrency, currentPaymentMethod, attachments, loader,
    leaseOptionsHeaders, leaseQuote, leaseQuoteLoader, leaseItemsHeaders,
    leaseItemsData, leaseSubtotal, quoteAgainFlag, handleCurrencyChange, handlePaymentMethodChange,
  } = props;
  switch (errorCode) {
    case errorCodes.EMPTY_CART:
      return (
        <Header>
          {t('Cotización vacía')}
        </Header>
      );
    case errorCodes.EMPTY_ORDER:
      return (
        <Header>
          {t('Cotización vacía')}
        </Header>
      );
    default:
      return (
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={11} computer={11} tablet={8} mobile={16}>
              <FinalUser
                checkoutData={checkoutData}
                currencyOptions={currencyOptions}
                handleCurrencyChange={handleCurrencyChange}
                currentCurrency={currentCurrency}
                paymentMethods={paymentMethods}
                handlePaymentMethodChange={handlePaymentMethodChange}
                currentPaymentMethod={currentPaymentMethod}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={8} mobile={16}>
              <Payment checkoutData={checkoutData} currency={currentCurrency} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Header as="h3" color="blue">{t('checkout.generatedOrders')}</Header>
            <br />
            &nbsp;
            * El stock de los productos agregados en esta cotización puede variar, se recomienda proceder
            a la compra tan pronto como sea posible para conservar disponibilidad y precio.
          </Grid.Row>
          {
            ordersLoader ? (
              <Grid.Row>
                <Grid.Column computer={16} tablet={16} mobile={16} textAlign="justified">
                  <Dimmer active inverted>
                    <Loader active size="big" />
                  </Dimmer>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column style={{ overflowX: 'auto' }} largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Segment raised style={{ overflowX: 'auto' }}>
                    <Orders ordersData={odersData} currency={currentCurrency} />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )
          }
          {
            currentPaymentMethod !== 9 || loader ? '' : (
              <Grid.Row>
                <Grid.Column>
                  <Segment raised style={{ overflowX: 'auto' }}>
                    <LeaseOptions
                      leaseOptionsHeaders={leaseOptionsHeaders}
                      data={[leaseQuote]}
                      leaseQuoteLoader={leaseQuoteLoader}
                      ordersLoader={loader}
                      leaseItemsHeaders={leaseItemsHeaders}
                      leaseItemsData={leaseItemsData}
                      leaseSubtotal={leaseSubtotal}
                      quoteAgainFlag={quoteAgainFlag}
                    />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )
          }
          {
            ordersLoader && attachments.length > 0 ? null : (
              <ItemDivider text={t('quotation-detail.attachments')} icon="file" />
            )
          }
          {
            ordersLoader && attachments.length > 0 ? null : (
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Attachments files={attachments} />
                </Grid.Column>
              </Grid.Row>
            )
          }
          <ItemDivider text={t('quotation-detail.plusComments')} icon="comments outline" />
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Chat order={orderId} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      );
  }
}

UserOrder.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentCurrency: PropTypes.number.isRequired,
  currentPaymentMethod: PropTypes.number.isRequired,
  errorCode: PropTypes.number.isRequired,
  handleCurrencyChange: PropTypes.func.isRequired,
  handlePaymentMethodChange: PropTypes.func.isRequired,
  leaseOptionsHeaders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leaseQuote: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leaseQuoteLoader: PropTypes.bool.isRequired,
  loader: PropTypes.bool.isRequired,
  odersData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  orderId: PropTypes.string.isRequired,
  ordersLoader: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leaseItemsHeaders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leaseItemsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  leaseSubtotal: PropTypes.number.isRequired,
  quoteAgainFlag: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(UserOrder);
