import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid, Header, List } from 'semantic-ui-react';
import service from '../services';
import Toast from '../../common/components/toast';
import { currencyFormat } from '../../common/utils';

class Credit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableCredit: null,
      hasCredit: false,
      creditError: false,
      status: null,
      condition: null,
    };
  }

  componentDidMount() {
    this.handleGetCustomerCredit();
  }

  componentDidUpdate(prevProps) {
    const { customerId } = this.props;
    if (prevProps.customerId !== customerId) {
      this.handleGetCustomerCredit();
    }
  }

  handleGetCustomerCredit = () => {
    const { t, customerId } = this.props;
    service.getCustomerCredit(customerId)
      .then(response => {
        const deductions = response.balance + response.pendingSales;
        if (response.availableCredit === 1) {
          const realCredit = response.creditLimit - deductions;
          this.setState({
            hasCredit: true,
            status: response.status,
            condition: response.condition,
            availableCredit: realCredit,
            creditLimit: response.creditLimit,
            creditError: false,
          });
        } else this.setState({ hasCredit: false, creditError: false });
      })
      .catch(error => {
        this.setState({ creditError: true });
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  render() {
    const { t } = this.props;
    const {
      availableCredit, hasCredit, status, condition, creditLimit, creditError,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
            <Header as="h4" color="blue">{t('customerDirectory.creditLine')}</Header>
          </Grid.Column>
        </Grid.Row>
        {hasCredit === true && creditError === false
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15}>
                <List divided relaxed>
                  <List.Item>
                    <List.Content style={{ display: 'inline' }}>
                      <Header as="h5" color="green">{t('customerDirectory.status')}</Header>
                      <List.Description>
                        {status}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <Header as="h5" color="green">{t('customerDirectory.typeOfCredit')}</Header>
                      <List.Description>
                        {condition}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <Header as="h5" color="green">{t('customerDirectory.creditAvailable')}</Header>
                      <List.Description>
                        {currencyFormat(availableCredit)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <Header as="h5" color="green">{t('customerDirectory.creditLimit')}</Header>
                      <List.Description>
                        {currencyFormat(creditLimit)}
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          )
          : (
            <Grid.Row>
              <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15}>
                <List divided relaxed>
                  <List.Item>
                    <List.Content>
                      <Header as="h5" color="green">{t('customerDirectory.cashPaymentOnly')}</Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          )}
      </Grid>
    );
  }
}

Credit.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(Credit);
