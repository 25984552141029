import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Iframe from 'react-iframe';
import iquoteRoutes from './services';
import Toast from '../common/components/toast';

class iquote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iquoteUrl: '',
    };
  }

  componentDidMount() {
    this.handleGenerateTokenIquote();
  }

  onUpdate = () => {
    const iframe = document.getElementById('iframeTarget');
    const divContent = document.getElementById('contentFrame');

    if (window.frames.document.body) {
      iframe.height = ' 2300px';
      divContent.style.height = '2300px';
    }
  }

  handleGenerateTokenIquote = async () => {
    const { t, location: locationProps } = this.props;
    let iquoteResponse = '';
    try {
      const location = locationProps.state;
      if (location) {
        iquoteResponse = await iquoteRoutes.generateTokenIquoteQuote({ hpeIquoteId: location.hpeIquoteId });
      } else {
        iquoteResponse = await iquoteRoutes.generateTokenIquote();
      }
      const url = iquoteResponse.token;
      this.setState({ iquoteUrl: url });
    } catch (error) {
      let messageError = '';
      if (!error) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    }
  }

  render() {
    const { iquoteUrl } = this.state;
    return (
      <div id="contentFrame" style={{ width: '100%', height: '100%', marginTop: '20px' }}>
        <Iframe
          id="iframeTarget"
          onLoad={this.onUpdate}
          url={iquoteUrl}
          position="absolute"
          overflow-x="hidden"
          width="100%"
          height="100%"
          frameBorder="0"
        />
      </div>
    );
  }
}

export default translate('common', { wait: true })(iquote);
