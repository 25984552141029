import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import {
  Button, Modal, Grid, Image,
} from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { translate } from 'react-i18next';

import assets from '../../../home-siclik/images';
import Toast from '../../../common/components/toast';
import { TextField } from '../../../common/components/materials';
import * as validate from './validation';
import request from '../../services';

const emptyErrors = {
  email: false,
  accesstype: false,
};

function ModalDetails(props) {
  const {
    actions, user, customerId, roles, t,
  } = props;
  const { open, handleModalClose, handleModalSave } = actions;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(emptyErrors);
  const [selectedUser, setSelectedUser] = useState({
    id: null,
    email: '',
    roleId: 0,
    roleName: '',
  });

  function getRoleBy(value, type) {
    return roles.find(role => role[value] === type) || {};
  }

  const getRoleByForEffect = useCallback(
    (value, type) => (
      roles.find(role => role[value] === type) || {}
    ), [roles],
  );

  function defineErrorMessages(error) {
    if (error.code === callError.general.RESOURCE_NOT_FOUND) {
      Toast(t('user.permissionsNotFound'), 'error');
    } else Toast(t(`error-codes.${error.code}`), 'error');
  }

  async function updateUser() {
    const userUpdated = await request.updateUserAdminRoles(user.id, {
      email: selectedUser.email,
      roleId: selectedUser.roleId,
      customerId,
    }).catch(defineErrorMessages);
    if (userUpdated && userUpdated.changedRows) Toast(t('user.userUpdated'), 'success');
  }

  async function addUser() {
    const newUser = await request.addUser({
      email: selectedUser.email,
      roleId: selectedUser.roleId,
      customerId,
    }).catch(defineErrorMessages);
    if (newUser && newUser.id) {
      setSelectedUser({ ...selectedUser, id: newUser.id });
      Toast(t('user.userAdded'), 'success');
    }
  }

  function userAction(existUser) {
    return !existUser
      ? addUser()
      : updateUser();
  }

  const handleOnClickAccessType = ({ target }) => {
    const errorMessage = validate.isNumber(target.value);
    setErrorMessages({ ...errorMessages, accesstype: t(errorMessage) });
    setSelectedUser({ ...selectedUser, roleId: target.value });
  };

  const handleOnBlurEmail = event => {
    const { value } = event.target;
    const errorMessage = validate.isEmail(value);
    setErrorMessages({ ...errorMessages, email: t(errorMessage) });
  };

  function validateForm() {
    const { emailError, accessTypeIdError } = validate.form(selectedUser);
    setErrorMessages({
      email: t(emailError),
      accesstype: t(accessTypeIdError),
    });
    return !emailError && !accessTypeIdError;
  }

  const handleOnSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      await userAction(user.id);
      setIsLoading(false);
      const matchRol = getRoleBy('value', selectedUser.roleId);
      handleModalSave(user.id, {
        id: selectedUser.id || user.id,
        email: selectedUser.email,
        roleId: matchRol.value,
        role: matchRol.label,
      });
    }
  };

  const handleOnClose = () => {
    setErrorMessages(emptyErrors);
    setSelectedUser(validateForm() ? selectedUser : {
      email: user.email,
      roleId: getRoleBy('label', user.role).id,
    });
    handleModalClose();
  };

  useEffect(() => {
    const matchRol = getRoleByForEffect('label', user.role);
    setSelectedUser({ email: user.email, roleId: matchRol.value, roleName: matchRol.label });
  }, [getRoleByForEffect, user]);

  return (
    <Modal open={open} closeOnEscape={open}>
      <Modal.Header>
        {' '}
        {t('user.userInformation')}
        {' '}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column onBlur={handleOnBlurEmail} largeScreen={7} computer={7} tablet={6} mobile={15}>
              <TextField
                fullWidth
                maxLength={55}
                value={selectedUser.email || ''}
                error={errorMessages.email || ''}
                label={t('user.email')}
                onChange={({ target }) => setSelectedUser({ ...selectedUser, email: target.value })}
              />
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={10}>
              <Image src={assets.comercio} />
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <TextField
                select
                fullWidth
                label={t('user.accesstype')}
                value={selectedUser.roleId || ''}
                error={errorMessages.accesstype || ''}
                onChange={handleOnClickAccessType}
                options={roles}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleOnClose}>
          {' '}
          {t('cancel')}
          {' '}
        </Button>
        <Button onClick={handleOnSubmit} loading={isLoading} color="blue">
          {user.id ? t('update') : t('add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalDetails.propTypes = {
  actions: PropTypes.shape({
    handleModalClose: PropTypes.func,
    handleModalSave: PropTypes.func,
    open: PropTypes.bool,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
  roles: PropTypes.shape({
    find: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default translate('common', { wait: true })(ModalDetails);
