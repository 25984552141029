import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid, Image, Icon } from 'semantic-ui-react';
import routeNames from '../../../route-names';
import PopMenu from '../../siclik/components/pop-menu';
import ItemMenuOnboarding from './item-menu-onboarding';

const assetUrl = `${process.env.REACT_APP_URL_AWS}/imagenes/assets/siclik/`;
const logo = `${assetUrl}siclikComercio.png`;

function MenuMobile({ history, profileComplete, acceptedTerms }) {
  const [showMobile, setShowMobile] = useState(false);

  const showMenuMobile = () => {
    setShowMobile(!showMobile);
  };

  return (
    <Grid>
      <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-bottom menu-bar">
        <Grid.Column mobile={3}>
          {acceptedTerms && profileComplete
            ? <Icon size="big" name="bars" onClick={() => showMenuMobile()} />
            : ''}
        </Grid.Column>
        <Grid.Column
          mobile={9}
        >
          <Grid padded verticalAlign="middle" centered>
            <Grid.Row centered className="no-padding">
              <Grid.Column
                mobile={16}
              >
                <Image
                  src={logo}
                  size="medium"
                  as="a"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push(routeNames.homeComercio.route)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column
          mobile={4}
        >
          <PopMenu history={history} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-y">
        <Grid.Column
          mobile={16}
        >
          {showMobile
            ? <ItemMenuOnboarding history={history} />
            : ''}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

MenuMobile.propTypes = {
  history: PropTypes.func.isRequired,
  profileComplete: PropTypes.bool,
  acceptedTerms: PropTypes.bool,
};

MenuMobile.defaultProps = {
  profileComplete: false,
  acceptedTerms: false,
};

export default MenuMobile;
