import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Divider } from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import * as session from '../common/sessions';
import Header from './components/header';
import Address from './components/address';
import DetailManeuver from './components/detail-maneuver';
import DocsDelivery from './components/docs-delivery';
import Comments from './components/comments';

class AddBranchOffice extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
    ]) || securityService.validate(access, [
      securityService.getAccess.distribuidores,
    ]);

    if (!hasAccess) props.history.push('/perfil');

    this.state = {
      show: false,
      addressId: '',
    };
  }

  componentDidMount = () => {
    this.handleGetAddressId();
  }

  handleGetAddressId = () => {
    const { onboarding, match, addressId } = this.props;
    if (onboarding) this.setState({ addressId: match.params.branchOfficeId });
    if (!onboarding) this.setState({ addressId, show: false });
  }

  handleShowComponents = (show, addressId) => {
    const { onboarding, history } = this.props;
    if (addressId) {
      this.setState({ show, addressId });
      if (onboarding) history.push(`/sucursales/${addressId}`);
    }
  }

  render() {
    let { show } = this.state;
    const {
      onboarding, addressId: propsAddressId, steps, history, getNewAddress,
    } = this.props;
    const { addressId } = this.state;
    if (addressId) {
      if (addressId.length > 10 && !propsAddressId) { show = true; }
    }
    return (
      <Grid container>
        <Grid.Row>
          { onboarding ? <Header steps={steps} history={history} /> : '' }
          <Address
            history={history}
            showComponents={this.handleShowComponents}
            getNewAddress={getNewAddress}
            id={addressId}
            onboarding={onboarding}
          />
        </Grid.Row>
        { show
          ? (
            <div>
              <Grid.Row>
                {' '}
                <DetailManeuver id={addressId} />
                {' '}
                <Divider section />
                {' '}
              </Grid.Row>
              <Grid.Row>
                {' '}
                <DocsDelivery id={addressId} />
                {' '}
                <Divider section />
                {' '}
              </Grid.Row>
              <Grid.Row>
                {' '}
                <Comments id={addressId} />
                {' '}
                <Divider section />
                {' '}
              </Grid.Row>
            </div>
          )
          : ''}
      </Grid>
    );
  }
}

AddBranchOffice.defaultProps = {
  getNewAddress: null,
  onboarding: true,
  steps: [],
};

AddBranchOffice.propTypes = {
  addressId: PropTypes.string.isRequired,
  getNewAddress: PropTypes.func,
  onboarding: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
};

export default translate('common', { wait: true })(AddBranchOffice);
