import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Table } from 'semantic-ui-react';
import '../styles.css';

function Specifications(props) {
  const {
    firstProduct, secondProduct, productsData,
  } = props;
  const hashSpecifications = {};
  const dataFirstProduct = productsData[firstProduct];
  const dataSecondProduct = productsData[secondProduct];

  if (dataFirstProduct && dataFirstProduct.specifications) {
    dataFirstProduct.specifications.forEach(({ type, value }) => {
      const row = { type, [firstProduct]: value };
      hashSpecifications[type] = { ...row };
    });
  }

  if (dataSecondProduct && dataSecondProduct.specifications) {
    dataSecondProduct.specifications.forEach(({ type, value }) => {
      const existSpecification = hashSpecifications[type];
      if (existSpecification) hashSpecifications[type] = { ...existSpecification, [secondProduct]: value };
      else hashSpecifications[type] = { type, [secondProduct]: value };
    });
  }

  return (
    <Grid celled structured>
      <Grid.Row>
        <Table style={{ backgroundColor: 'transparent', border: 'none' }} padded unstackable>
          {
            Object.values(hashSpecifications).map(specification => (
              <Table.Body>
                <Table.Row style={{ backgroundColor: 'rgba(246, 246, 246, 0.8)' }}>
                  <Table.Cell width="4" rowSpan={16}>
                    {specification.type}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="6">{specification[firstProduct] || '-'}</Table.Cell>
                  <Table.Cell width="6">{specification[secondProduct] || '-'}</Table.Cell>
                </Table.Row>
              </Table.Body>
            ))
          }
        </Table>
      </Grid.Row>
    </Grid>
  );
}

Specifications.propTypes = {
  productsData: PropTypes.shape({}),
  firstProduct: PropTypes.string,
  secondProduct: PropTypes.string,
};

Specifications.defaultProps = {
  productsData: {},
  firstProduct: '',
  secondProduct: '',
};

export default translate('common', { wait: true })(Specifications);
