import React, { useState, useEffect, useRef } from 'react';
import { ReactSortable } from 'react-sortablejs';
import { translate } from 'react-i18next';
import {
  Grid, Header, Button, Card, Dropdown, Confirm, Divider,
} from 'semantic-ui-react';
import _ from 'lodash';
import services from './services';
import { LinearLoader } from '../common/components/materials';
import Toast from '../common/components/toast';

function indexData(items = []) {
  const indexedTags = items.reduce((indexed, current) => {
    const currentHash = { ...indexed };
    const {
      formatedBrands, brands,
    } = currentHash;
    const {
      id, name,
    } = current;
    brands[id] = current;
    return {
      formatedBrands: [...formatedBrands, { text: name, value: id }], brands,
    };
  }, {
    formatedBrands: [], brands: {},
  });
  return indexedTags;
}

function ProductCategorization(props) {
  const { t } = props;
  const [currentOrderList, setCurrentOrderList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [attributeValueOptions, setAttributeValueOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentBrand, setCurrentBrand] = useState('');
  const [currentAttributeType, setCurrentBrandAttributeType] = useState('');
  const [currentAttributeValue, setCurrentAttributeValue] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);

  const hashBrands = useRef({});
  const currentSelection = useRef(null);

  const attributeTypes = {
    sku: { value: 'sku', text: t('categorizations.sku') },
    family: { value: 'family', text: t('categorizations.family') },
    category: { value: 'category', text: t('categorizations.category') },
  };

  useEffect(() => {
    const getFilters = async () => {
      const { brands } = await services.getProductCategorizations();
      const indexedData = indexData(brands);
      setBrandList(indexedData.formatedBrands);
      hashBrands.current = { ...indexedData.brands };
      setLoading(false);
    };
    getFilters();
  }, []);

  const handleSave = async () => {
    const { newOrderList, categorizationsBody } = currentOrderList.reduce((previousObject, currentItem, index) => {
      const { newOrderList: orderList, categorizationsBody: currentCategorizations } = previousObject;
      const { id, attribute, value } = currentItem;
      const commonAttributes = { attribute, value, hierarchy: index + 1 };
      return {
        newOrderList: [...orderList, { id, ...commonAttributes }],
        categorizationsBody: [...currentCategorizations, { categorizationId: id, ...commonAttributes }],
      };
    }, { newOrderList: [], categorizationsBody: [] });
    setLoading(true);
    await services.updateProductCategorizations({ brandId: currentBrand, categorizations: categorizationsBody })
      .then(() => {
        Toast(t('toast.success.general'), 'success');
        hashBrands.current[currentBrand].categorizations = [...newOrderList];
      })
      .catch(() => Toast(t('toast.error.general'), 'error'));
    setLoading(false);
  };

  const handleDelete = async () => {
    const { categorizationId, brandId } = currentSelection.current;
    setLoading(true);
    setConfirmDelete(false);
    await services.deleteProductCategorization(categorizationId, brandId)
      .then(() => {
        const newOrderList = currentOrderList
          .filter(({ id }) => (id !== categorizationId))
          .map(({ id, attribute, value }, index) => ({
            id, attribute, value, hierarchy: index + 1,
          }));
        hashBrands.current[brandId].categorizations = [...newOrderList];
        setCurrentOrderList(newOrderList);
      })
      .catch(() => {
        Toast(t('toast.error.general'), 'error');
      });
    currentSelection.current = null;
    setLoading(false);
  };

  const handleGetNewAttributeOptions = async (brandName, type) => {
    setLoading(true);
    const attribute = {
      family: 'erpFamily',
      category: 'erpCategory',
      sku: 'sku',
    };
    const newValueOptions = await services.getAttributesPerBrand(brandName, attribute[type])
      .then(({ brand }) => (brand.values.map(currentValue => ({ text: currentValue, value: currentValue }))))
      .catch(() => ([]));
    setCurrentAttributeValue('');
    setAttributeValueOptions(_.uniq(newValueOptions));
    setLoading(false);
  };

  const handleCurrentBrand = (e, { value }) => {
    setCurrentBrand(value);
    const newList = hashBrands.current[value].categorizations;
    setCurrentOrderList(newList);
    if (currentAttributeType) handleGetNewAttributeOptions(hashBrands.current[value]?.name, currentAttributeType);
  };

  const handleCurrentAttributeValue = (e, { value }) => {
    setCurrentAttributeValue(value);
  };

  const handleCurrentAttributeType = async (e, { value }) => {
    setCurrentBrandAttributeType(value);
    handleGetNewAttributeOptions(hashBrands.current[currentBrand]?.name, value);
  };

  const handleReset = () => {
    const newList = hashBrands.current[currentBrand].categorizations;
    setCurrentOrderList(newList);
  };

  const handleDeleteButton = (categorizationId, brandId) => {
    currentSelection.current = { categorizationId, brandId };
    setConfirmDelete(true);
  };

  const handleCancelDelete = () => {
    currentSelection.current = null;
    setConfirmDelete(false);
  };

  const refreshData = async () => {
    const { brands } = await services.getProductCategorizations();
    const indexedData = indexData(brands);
    setBrandList(indexedData.formatedBrands);
    hashBrands.current = { ...indexedData.brands };
    const newList = hashBrands.current[currentBrand].categorizations;
    setCurrentOrderList(newList);
  };

  const addCategorization = async () => {
    if (currentOrderList.find(({ attribute, value }) => (attribute === currentAttributeType && value === currentAttributeValue))) {
      Toast(t('categorizations.categorizationExist'));
      return;
    }
    setLoading(true);
    await services.addProductCategorizations({
      brandId: currentBrand,
      categorizations: [{
        attribute: currentAttributeType,
        value: currentAttributeValue,
        hierarchy: currentOrderList.length + 1,
      }],
    })
      .then(async () => {
        await refreshData();
        Toast(t('toast.success.general'), 'success');
      })
      .catch(() => Toast(t('toast.error.general'), 'error'));
    setLoading(false);
  };

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('categorizations.title')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider className="margin-divider" />
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <p>{t('categorizations.description')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={15} tablet={15} mobile={16}>
          <Dropdown
            options={brandList}
            value={currentBrand}
            fluid
            onChange={handleCurrentBrand}
            selection
            placeholder={t('categorizations.brand')}
          />
        </Grid.Column>
      </Grid.Row>
      {
        currentBrand
        && (
          <Grid.Row>
            <Grid.Column computer={6} tablet={5} mobile={8}>
              <Dropdown
                options={Object.values(attributeTypes)}
                value={currentAttributeType}
                fluid
                onChange={handleCurrentAttributeType}
                selection
                placeholder={t('categorizations.attributeType')}
              />
            </Grid.Column>
            <Grid.Column computer={6} tablet={5} mobile={8}>
              <Dropdown
                options={attributeValueOptions}
                value={currentAttributeValue}
                fluid
                search
                disabled={loading}
                onChange={handleCurrentAttributeValue}
                selection
                placeholder={t('categorizations.value')}
              />
            </Grid.Column>
            <Grid.Column computer={3} tablet={5} mobile={16}>
              <Button className="margin-top-buttons" color="blue" onClick={addCategorization} fluid content={t('add')} disabled={loading} />
            </Grid.Column>
          </Grid.Row>
        )
      }
      <Grid.Row>
        <Grid.Column computer="15" tablet="15" mobile="16">
          {loading && <LinearLoader color="blue" />}
          {
            currentOrderList && (
              <ReactSortable list={currentOrderList} setList={setCurrentOrderList}>
                {currentOrderList.map(item => (
                  <Card
                    key={item.id}
                    fluid
                    color="blue"
                    style={{ cursor: 'pointer' }}
                  >
                    <Grid padded>
                      <Grid.Row verticalAlign="middle">
                        <Grid.Column computer={3} tablet={5} mobile={6}>
                          <Header
                            size="small"
                            content="Atributo: "
                            subheader={attributeTypes[item.attribute].text}
                          />
                        </Grid.Column>
                        <Grid.Column computer={3} tablet={5} mobile={6}>
                          <Header
                            size="small"
                            content="Valor: "
                            subheader={item.value}
                          />
                        </Grid.Column>
                        <Grid.Column only="computer tablet" computer={10} tablet={6} verticalAlign="middle" textAlign="right">
                          <Button
                            color="red"
                            size="small"
                            onClick={() => handleDeleteButton(item.id, currentBrand)}
                          >
                            {t('delete')}
                          </Button>
                        </Grid.Column>
                        <Grid.Column only="mobile" mobile={4} verticalAlign="middle">
                          <Button
                            color="red"
                            size="tiny"
                            fluid
                            icon="trash"
                            onClick={() => handleDeleteButton(item.id, currentBrand)}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Card>
                ))}
              </ReactSortable>
            )
          }
        </Grid.Column>
      </Grid.Row>
      {
        currentOrderList.length
          ? (
            <Grid.Row>
              <Grid.Column computer="15" tablet="15" mobile="16" textAlign="right">
                <Button
                  secondary
                  onClick={handleReset}
                  disabled={loading}
                >
                  {t('categorizations.reset')}
                </Button>
                <Button
                  primary
                  onClick={handleSave}
                  disabled={loading}
                >
                  {t('categorizations.saveChanges')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
      }
      <Confirm
        open={confirmDelete}
        header={t('categorizations.confirmDelete')}
        content={t('categorizations.confirmDeleteDescription')}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      />
    </Grid>
  );
}

export default translate('common', { wait: true })(ProductCategorization);
