import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Image,
  Modal,
  Icon,
  Responsive,
  Card,
  Placeholder,
  Header,
  Container,
  List,
} from 'semantic-ui-react';
import '../../styles.css';

class ImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      openModal: false,
      hasNotImages: false,
      arrayMedia: props.arrayImages || [],
    };
  }

  componentDidUpdate(prevProps) {
    const { arrayImages } = this.props;
    if (prevProps.arrayImages !== arrayImages) {
      this.handleGetImages();
    }
  }

  goToNextSlide = index => {
    this.setState({ currentIndex: index });
  }

  handleGetImages = () => {
    const { arrayImages, hasNotImages } = this.props;
    const currentIndex = arrayImages.findIndex(item => item.isMain);
    this.setState({ arrayMedia: arrayImages, currentIndex, hasNotImages });
  }

  handleOnUpdate = (e, { width }) => {
    this.setState({ width });
  }

  defineBrandLogoSize = brand => (
    brand === 'HP'
      ? 'mini'
      : 'tiny'
  );

  mobileView = () => {
    const {
      currentIndex, openModal, arrayMedia, width, hasNotImages,
    } = this.state;
    const {
      maker, logoMaker, makerReal,
    } = this.props;
    const currentImage = arrayMedia[currentIndex];
    return (
      <Grid centered>
        <Responsive as={Grid.Row} onUpdate={this.handleOnUpdate}>
          {!hasNotImages
            ? (
              <Grid.Column mobile={16}>
                <Placeholder>
                  <Placeholder.Image rectangular />
                </Placeholder>
              </Grid.Column>
            ) : (
              <Grid.Column mobile={16} floated="left">
                <Grid.Row verticalAlign="middle" style={{ paddingBottom: '.5rem' }}>
                  {
                    logoMaker ? (
                      <>
                        <Header as="h4" style={{ display: 'inline' }}>
                          Marca:
                        </Header>
                        <Image
                          src={logoMaker}
                          alt={logoMaker}
                          size={this.defineBrandLogoSize(makerReal || maker)}
                          style={this.defineBrandLogoSize(makerReal || maker) === 'mini'
                            ? { marginLeft: 32, paddingBottom: 12 }
                            : { marginLeft: 12, paddingBottom: 12 }}
                        />
                      </>
                    ) : (
                      null
                    )
                  }
                </Grid.Row>
                <Image
                  src={currentImage?.low}
                  className="myImg"
                  size="large"
                  centered
                  wrapped
                  bordered
                  style={{
                    objectFit: 'contain',
                    backgroundColor: '#ffffff',
                  }}
                  onClick={() => this.setState({ openModal: true })}
                />
              </Grid.Column>
            )}
          <Grid.Column mobile={15} />
        </Responsive>
        <Grid.Row stretched>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Card.Group itemsPerRow={width < 425 ? 3 : 4}>
              {arrayMedia.map((image, index) => (
                <Card key={image.thumbnail} fluid>
                  <Image
                    bordered
                    key={image.low}
                    src={image.low}
                    onMouseOver={() => this.goToNextSlide(index)}
                    size="tiny"
                    className="myImg"
                  />
                </Card>
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal open={openModal} closeOnDimmerClick size="large" className="modal">
            <Icon name="window close" onClick={() => { this.setState({ openModal: false }); this.goToNextSlide(0); }} />
            <Modal.Content className="modal-content">
              <Image
                src={currentImage?.low || ''}
                className="myImg"
                size="big"
                centered
                wrapped
                bordered
                onClick={() => this.setState({ openModal: true })}
              />
            </Modal.Content>
            <Modal.Actions>
              <Grid.Row stretched>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Card.Group itemsPerRow={width < 425 ? 3 : 4}>
                    {arrayMedia.map((image, index) => (
                      <Card key={image.thumbnail} fluid>
                        <Image
                          src={image.low}
                          key={image.low}
                          onMouseOver={() => this.goToNextSlide(index)}
                          size="tiny"
                          bordered
                          className="myImg"
                        />
                      </Card>
                    ))}
                  </Card.Group>
                </Grid.Column>
              </Grid.Row>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }

  loaderImageViewer = () => (
    <List>
      <List.Item>
        <Placeholder className="myImgTiny">
          <Placeholder.Image square />
        </Placeholder>
      </List.Item>
      <List.Item>
        <Placeholder className="myImgTiny">
          <Placeholder.Image square />
        </Placeholder>
      </List.Item>
      <List.Item>
        <Placeholder className="myImgTiny">
          <Placeholder.Image square />
        </Placeholder>
      </List.Item>
    </List>
  );

  webContentView = () => {
    const {
      hasNotImages,
      arrayMedia,
      currentIndex,
      openModal,
    } = this.state;
    const currentImage = arrayMedia[currentIndex];
    const { maker, logoMaker, makerReal } = this.props;
    return (
      <Container>
        <Grid centered>
          <Grid.Row>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={13}>
              <Image
                src={logoMaker}
                alt={logoMaker}
                size={this.defineBrandLogoSize(makerReal || maker)}
                style={this.defineBrandLogoSize(makerReal || maker) === 'mini'
                  ? { marginLeft: 32, paddingBottom: 12 }
                  : { marginLeft: 12, paddingBottom: 12 }}
              />
              <Container style={{ overflowY: 'scroll', maxHeight: '368px' }}>
                {!hasNotImages
                  ? this.loaderImageViewer() : (
                    arrayMedia.map((image, index) => (
                      <Image
                        fluid
                        key={image.thumbnail}
                        src={image.thumbnail}
                        onMouseOver={() => this.goToNextSlide(index)}
                        size="tiny"
                        bordered
                        className="myImgTiny"
                      />
                    ))
                  )}
              </Container>
            </Grid.Column>
            <Grid.Column largeScreen={13} computer={13} tablet={13} mobile={13} verticalAlign="middle">
              {!hasNotImages
                ? (
                  <Placeholder>
                    <Placeholder.Image square />
                  </Placeholder>
                ) : (
                  <Image
                    src={currentImage?.medium}
                    className="myImg"
                    size="large"
                    centered
                    wrapped
                    style={{
                      objectFit: 'contain',
                      backgroundColor: '#ffffff',
                      height: '100%',
                    }}
                    onClick={() => this.setState({ openModal: true })}
                  />
                )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Modal
          closeIcon
          open={openModal}
          closeOnDimmerClick
          size="large"
          className="modal"
          onClose={() => { this.setState({ openModal: false }); this.goToNextSlide(0); }}
        >
          <Modal.Content className="modal-content">
            <Image
              src={currentImage?.big || currentImage?.medium || ''}
              className="myImg"
              size="big"
              centered
              wrapped
              bordered
              onClick={() => this.setState({ openModal: true })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Card.Group itemsPerRow={arrayMedia.length ? arrayMedia.length + 1 : 1} fluid>
              {arrayMedia && arrayMedia.length && arrayMedia.map((image, index) => (
                <Card key={image.low}>
                  <Image
                    key={image.low}
                    src={image.low}
                    onMouseOver={() => this.goToNextSlide(index)}
                    size="medium"
                    bordered
                    className="myImg"
                    style={{
                      maxHeight: '100%',
                      maxWidth: '100%',
                    }}
                  />

                </Card>
              ))}
            </Card.Group>
          </Modal.Actions>
        </Modal>
      </Container>
    );
  };

  render() {
    return (
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
          <Responsive minWidth={Responsive.onlyTablet.minWidth}>
            {
              this.webContentView()
            }
          </Responsive>
          <Responsive minWidth={Responsive.onlyMobile.minWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
            {
              this.mobileView()
            }
          </Responsive>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

ImageComponent.propTypes = {
  arrayImages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasNotImages: PropTypes.bool.isRequired,
  maker: PropTypes.string.isRequired,
  makerReal: PropTypes.string.isRequired,
  logoMaker: PropTypes.string.isRequired,
};

export default ImageComponent;
