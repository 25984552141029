import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Container,
  Menu,
  Dropdown,
  Button,
  Pagination,
  Segment,
  Grid,
  Checkbox,
} from 'semantic-ui-react';

const translatePath = 'productConfig.menus.chooseContent.itemListActions';

const fieldsOption = [
  { key: 'title', text: 'Nombre', value: 'title' },
  { key: 'family', text: 'Familia', value: 'family' },
  { key: 'category', text: 'Categoría', value: 'category' },
  { key: 'images', text: 'Imágenes', value: 'images' },
];

function MenuSync(props) {
  const {
    t,
    selectedContentProvider,
    onChangeContentProvider,
    onClickContentProvider,
    disabledApplyButton,
    onChangePagination,
    onChangeFields,
    totalPages,
    activePage,
    itemsCount,
    options,
    loading,
    fields,
  } = props;

  const handleClick = e => {
    e.stopPropagation();
  };

  return (
    <Container fluid>
      <Grid>
        <Grid.Row only="computer tablet" style={{ marginBottom: '-12px' }}>
          <Grid.Column>
            <Menu text size="tiny">
              <Menu.Item>
                <Dropdown
                  button
                  className="icon"
                  labeled
                  icon="settings"
                  text="Elementos a sincronizar"
                  selectOnBlur={false}
                >
                  <Dropdown.Menu>
                    <Dropdown.Header icon="tags" content="Fuente de contenido" />
                    <Dropdown.Divider />
                    {options.map(option => (
                      <Dropdown.Item key={option.value} onClick={handleClick}>
                        <Checkbox
                          radio
                          label={option.text}
                          value={option.value}
                          checked={selectedContentProvider === option.value}
                          onChange={onChangeContentProvider}

                        />
                      </Dropdown.Item>
                    ))}
                    <Dropdown.Header icon="tags" content="Campos a sincronizar" />
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleClick}>
                      <Checkbox
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        label={<label style={{ fontStyle: 'italic' }}>Todos</label>}
                        type="checkbox"
                        value="all"
                        checked={fields.all}
                        onChange={onChangeFields}
                      />
                    </Dropdown.Item>
                    {fieldsOption.map(field => (
                      <Dropdown.Item key={field.key} onClick={handleClick}>
                        <Checkbox
                          label={field.text}
                          value={field.value}
                          checked={fields[field.value]}
                          onChange={onChangeFields}
                        />
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Item>
              <Menu.Item>
                <Button
                  primary
                  content={t(`${translatePath}.applyButton`)}
                  disabled={disabledApplyButton}
                  onClick={onClickContentProvider}
                />
              </Menu.Item>
              <Menu.Item position="right">
                <span>
                  {itemsCount}
                  {' '}
                  {t(`${translatePath}.itemsLenght`)}
                </span>
                <Pagination
                  pointing
                  secondary
                  totalPages={totalPages}
                  activePage={activePage}
                  onPageChange={onChangePagination}
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment textAlign="left" size="tiny">
              <b>
                {t(`${translatePath}.contentProviders`)}
                :
              </b>
              <Dropdown
                fluid
                selection
                placeholder={t(`${translatePath}.syncSource`)}
                value={selectedContentProvider}
                options={options}
                loading={loading}
                onChange={onChangeContentProvider}
              />
              <Button
                fluid
                primary
                content={t(`${translatePath}.applyButton`)}
                disabled={disabledApplyButton}
                onClick={onClickContentProvider}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

MenuSync.propTypes = {
  selectedContentProvider: PropTypes.string.isRequired,
  onChangeContentProvider: PropTypes.func.isRequired,
  onClickContentProvider: PropTypes.func.isRequired,
  disabledApplyButton: PropTypes.bool.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  activePage: PropTypes.number.isRequired,
  itemsCount: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  fields: PropTypes.shape({
    title: PropTypes.bool.isRequired,
    description: PropTypes.bool.isRequired,
    category: PropTypes.bool.isRequired,
    images: PropTypes.bool.isRequired,
    all: PropTypes.bool.isRequired,
  }).isRequired,
  onChangeFields: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(MenuSync);
