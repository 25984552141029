/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';
import {
  Grid, Icon, Button, Header,
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import { fixRotation } from '../../common/utils';
import * as session from '../../common/sessions';
import '../styles.css';
import uploader from '../services';
import Toast from '../../common/components/toast';
import routes from '../../route-names';
// import user from '../../profile/services';

const { login } = routes;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

// const img = {
//   display: 'block',
//   width: 'auto',
//   height: '90%',
// };

class UploadPanel extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    const { userId, customerId } = session.get();
    this.state = {
      userId,
      company: customerId,
      accepted: [],
      rejected: [],
      files: [],
      crop: {
        unit: '%', // default, can be 'px' or '%'
        aspect: 16 / 16,
        width: 100,
        height: 100,
      },

    };
  }

  componentDidMount() {
    const { userId, company } = this.state;
    if (!userId || !company) {
      window.location.assign(login.route);
    }
  }

  componentWillUnmount() {
    const { files } = this.state;
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  onDrop = async files => {
    const blobOfArray = await fixRotation(files);
    this.setState({
      files: blobOfArray.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file),
      })),
    });
    return blobOfArray;
  };

  getFileExtension = file => {
    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(file.name)[1];
  }

  setNameFile = file => {
    const { userId } = this.state;
    const fileName = `${userId}.${this.getFileExtension(file)}`;
    return fileName;
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        // eslint-disable-next-line no-param-reassign
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve([blob]);
      }, 'image/jpeg');
    });
  }

  deletefiles = filesToDelete => {
    if (filesToDelete.files) {
      filesToDelete.files.map(urlDelete => {
        const objectKey = urlDelete.Key;
        return uploader.deleteImageS3({ objectKey });
      });
    }
  }

  setDomainUrl = url => {
    const imageUrl = url.toString().substring(0, url.toString().indexOf('?'));
    const hasDomain = process.env.REACT_APP_ENV === 'production';
    if (hasDomain) {
      const domain = 'https://bucket.siclik.mx';
      const s3Url = process.env.REACT_APP_URL_AWS;
      return imageUrl.replace(s3Url, domain);
    }
    return imageUrl;
  }

  saveFile = async (file, name, bucketPath) => {
    const { t, panelState } = this.props;
    try {
      const urlSigned = await uploader.getSignedUrl({ name, bucketPath, hasValidName: true });

      const newUrl = await uploader.uploadImgS3(urlSigned, file);
      if (newUrl) {
        const imageUrl = this.setDomainUrl(newUrl.url);
        await uploader.addProfileImage({ image: imageUrl });
        panelState(false);
        Toast(t('uploadFiles.fileUploaded'), 'success');
        return imageUrl;
      }
    } catch (error) {
      this.setState(Toast(`${t('error-codes.default')}`, 'error'));
    }
    return true;
  };

  loadfiles = async () => {
    const { t, getAvatarImage } = this.props;
    const { croppedImage, accepted } = this.state;
    const bucketPath = 'user-profile/pictures';
    if (accepted.length > 1) {
      Toast(t('uploadFiles.justOneProfileImg'), 'error');
    } else {
      await croppedImage.map(file => {
        const name = this.setNameFile(file);
        return this.saveFile(file, name, bucketPath)
          .then(newUrl => getAvatarImage(newUrl));
      });
    }
  }

  handleOnCropChange = crop => {
    this.setState({ crop });
  }

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  }

  onImageLoaded = image => {
    this.imageRef = image;
  }

  makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg',
      ).then(croppedImage => this.setState({ croppedImage }));
    }
  }

  render() {
    const {
      files, crop, accepted, rejected,
    } = this.state;

    const thumbs = files.map(file => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner} />
        <ReactCrop
          src={file.preview}
          crop={crop}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          onChange={this.handleOnCropChange}
        // imageStyle={img}
        />
      </div>
    ));
    const { t } = this.props;
    return (
      <Grid container>
        <Grid.Row>
          <section style={{ width: '100%' }}>
            <div className="dropzone">
              <Dropzone
                className="dropzone"
                accept="image/jpeg, image/png"
                onDropAccepted={acceptedProp => { this.setState({ accepted: acceptedProp }); }}
                onDropRejected={rejectedProp => { this.setState({ rejected: rejectedProp }); }}
                onDrop={file => this.onDrop(file)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={thumbsContainer}>
                    <input {...getInputProps()} />
                    <Icon name="add" circular />
                    {(accepted.length <= 0)
                      ? (
                        <Grid.Row>
                          <Header as="h5">
                            {t('profile.dropImage')}
                            {' '}
                          </Header>
                          <Header as="h5">{t('profile.correctImageFormat')}</Header>
                        </Grid.Row>
                      )
                      : ''}
                  </div>
                )}
              </Dropzone>
              {thumbs}
            </div>
          </section>
        </Grid.Row>
        {(accepted.length >= 1)
          ? (
            <Grid.Row>
              <aside>
                <Header as="h4">{t('profile.accepted')}</Header>
                <ul>
                  {accepted.map(f => (
                    <li key={f.name}>
                      <Icon color="green" name="check" />
                      {f.name}
                      {' '}
                      -
                      {f.size}
                      {' '}
                      {t('profile.bytes')}
                      {' '}
                    </li>
                  ))}
                </ul>
              </aside>
            </Grid.Row>
          )
          : ''}
        {(rejected.length >= 1)
          ? (
            <Grid.Row>
              <aside>
                <Header as="h4">{t('profile.allowedFiles')}</Header>
                <ul>
                  {rejected.map(f => (
                    <li key={f.name}>
                      {f.name}
                      {' '}
                      -
                      {' '}
                      {f.size}
                      {' '}
                      {t('profile.bytes')}
                    </li>
                  ))}
                </ul>
              </aside>
            </Grid.Row>
          )
          : ''}
        <Grid.Row centered>
          <Grid.Column>
            <Button color="blue" disabled={files.length <= 0 || rejected.length >= 1} onClick={this.loadfiles}>{t('profile.save')}</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

UploadPanel.propTypes = {
  getAvatarImage: PropTypes.func,
  panelState: PropTypes.func,
  t: PropTypes.func,
};

UploadPanel.defaultProps = {
  getAvatarImage: () => {},
  panelState: () => {},
  t: () => {},
};

export default translate('common', { wait: true })(UploadPanel);
