import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const HeaderCell = ({ header }) => (<Table.HeaderCell key={header.key}>{header.name}</Table.HeaderCell>);

HeaderCell.propTypes = {
  header: PropTypes.shape({
    key: PropTypes.string.isRequired,
    isKey: PropTypes.bool,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

HeaderCell.defaultProps = {
};

export default HeaderCell;
