import React, { Component } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import validator from 'validator';
import userInformation from '../services';
import Toast from '../../common/components/toast';
import { TextField } from '../../common/components/materials';
import routes from '../../route-names';

const { login } = routes;
class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: '',
    };
  }

  validateEmailChange = async () => {
    const { t } = this.props;
    const maxLength = 100;
    const minLength = 10;
    let emailError = '';
    const { email } = this.state;
    if (!validator.isEmail(email)) {
      emailError = t('profile.errors.notEmail');
      Toast(t('profile.errors.notEmail'), 'error');
      return;
    }
    if (email.length > maxLength) emailError = t('profile.errors.toLong');
    if (email.length < minLength) emailError = t('profile.errors.toShort');

    if (emailError === '') {
      await userInformation.requestNewPassword({ email })
        .then(msg => {
          if (msg.message) {
            Toast(t('toast.info.accountSent'), 'info');
          }
        })
        .catch(error => {
          if (error.code) {
            this.setState({ emailError: t('toast.error.restore') + error.message });
          } else {
            this.setState({ emailError: t('toast.error.restore') });
          }
        });
    } else {
      this.setState({ emailError });
    }
  }

  handleEmailChange = event => {
    const { value: email } = event.target;
    const maxLength = 100;
    let emailError = '';
    const { t } = this.props;
    if (email.length > maxLength) emailError = t('profile.errors.toLong');
    if (email.length === 0) emailError = t('login.error.empty');
    this.setState({ email, emailError });
  };

  goTologin = () => {
    window.location.assign(login.route);
  }

  render() {
    const { t } = this.props;
    const { email, emailError } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header textAlign="center" as="h4">
              {t('login.resetInfo')}
              {' '}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '2rem' }}>
          <Grid.Column>
            <TextField
              fullWidth
              maxLength={100}
              label={t('profile.email')}
              error={emailError || ''}
              value={email || ''}
              onChange={this.handleEmailChange}
              type="text"
              required
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={12}>
            <Button color="blue" onClick={this.validateEmailChange}>
              {t('login.resetPassword')}
            </Button>
          </Grid.Column>
          <Grid.Column width={4} style={{ textAlign: 'right' }}>
            <Button
              color="lightBlack"
              onClick={this.goTologin}
            >
              {' '}
              {t('back')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    );
  }
}

export default translate('common', { wait: true })(Forgot);
