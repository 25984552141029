import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import Footer from '../../common/components/public-footer';
import { scrollToRef } from '../../common/utils';
import routes from '../../route-names';
import tokenSuscribe from '../siclik/services';
import PublicHeader from './components/header';
import './styles.css';

function LoginLayout(props) {
  const {
    history, children: propsChildren, t, isAuthenticated,
  } = props;
  const refs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
  };

  const redirects = {
    0: () => {},
    1: () => history.push(routes.homeComercio.route),
    2: async () => {
      const token = await tokenSuscribe().then(response => response.token).catch(() => '');
      window.location.replace(`https://clicksuscribe.compusoluciones.com/#/siclick/${token}`);
    },
    3: () => history.push(routes.home.route),
  };

  const children = React.Children.map(propsChildren, child => (
    React.cloneElement(child, { refs })
  ));

  const onButtonClick = (refId = 0) => {
    const selectedRef = refs[refId];
    if (selectedRef.current) scrollToRef(refs[refId]);
    else redirects[refId]();
  };

  return (
    <Grid className="no-margin">
      <Grid.Row className="no-padding">
        <Grid.Column className="no-padding">
          <PublicHeader onButtonClick={onButtonClick} t={t} history={history} isAuthenticated={isAuthenticated} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="padding-title">
        <Grid.Column style={{ minHeight: '69vh' }} className="no-padding-x">
          {children}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding-y">
        <Grid.Column className="no-padding">
          <Footer history={history} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

LoginLayout.propTypes = {
  children: PropTypes.shape({}).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default LoginLayout;
