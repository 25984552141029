import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Button } from 'semantic-ui-react';
import { TextField, LinearLoader } from '../../../common/components/materials';
import Toast from '../../../common/components/toast';
import service from '../../services';
import SectorTable from './sector-table';

const PERCENT_CODE = 1406;

function Sector(props) {
  const {
    handleAdd, handleDelete: handleFatherDelete, t, sectors,
    sectorItems, customerId, year, loadDone, activeLoader,
  } = props;

  const [state, setState] = useState({
    industryId: null,
    percent: null,
    industryIdError: '',
    percentError: '',
  });

  function cleanErrors() {
    setState({
      ...state,
      industryIdError: '',
      percentError: '',
    });
  }

  function isValidBody() {
    const { percent, industryId } = state;
    const errors = {};
    if (!industryId) {
      Toast(t('clientPlan.errors.industryId'), 'error');
      errors.industryIdError = t('clientPlan.errors.empty');
    }
    if (!percent) {
      Toast(t('clientPlan.errors.percent'), 'error');
      errors.percentError = t('clientPlan.errors.empty');
    } else if (percent < 0 || percent > 100) {
      Toast(t('clientPlan.errors.percent'), 'error');
      errors.percentError = t('clientPlan.errors.percentValue');
    }
    if (Object.keys(errors).length) {
      setState({ ...state, ...errors });
      return false;
    }
    return true;
  }

  function addIndustry() {
    const { industryId, percent } = state;
    const body = {
      customerId, year, industryId, percent,
    };
    if (!isValidBody()) return;
    activeLoader();
    service.addIndustriesPercentItem(body)
      .then(response => {
        handleAdd({ ...body, id: response.id });
        cleanErrors();
      })
      .catch(({ code }) => {
        if (code === PERCENT_CODE) {
          setState({
            ...state,
            percentError: t('clientPlan.errors.percent'),
          });
        } else {
          Toast('Error al agregar', 'error');
        }
        handleAdd();
      });
  }

  const handleSave = () => {
    addIndustry();
  };

  const handleChange = ({ target }) => {
    const { value, id, name } = target;
    const nameOrId = id || name;
    setState({ ...state, [nameOrId]: value, [`${nameOrId}Error`]: '' });
  };

  const handleUpdate = element => {
    const { industryId, percent } = element;
    setState({ ...state, industryId, percent });
  };

  const handleDelete = ({ id }) => {
    if (!id) return;
    activeLoader();
    service.deleteIndustriesPercentItem(id)
      .then(({ status }) => {
        if (!status) return;
        handleFatherDelete(id);
      })
      .catch(() => {
        Toast(t('clientPlan.errors.delete'), 'error');
        handleFatherDelete();
      });
  };

  const onKeyPress = e => {
    if (e.which === 13) {
      handleSave();
    }
  };

  return (
    <Grid>
      <Grid.Row className="no-padding-bottom">
        <Grid.Column
          largeScreen={6}
          computer={6}
          tablet={6}
          mobile={14}
        >
          <TextField
            id="industryId"
            name="industryId"
            select
            fullWidth
            error={state.industryIdError || ''}
            label={t('clientPlan.label.sector')}
            value={state.industryId || ''}
            onChange={handleChange}
            options={sectors}
          />
        </Grid.Column>
        <Grid.Column
          onKeyPress={onKeyPress}
          largeScreen={6}
          computer={6}
          tablet={6}
          mobile={14}
        >
          <TextField
            id="percent"
            fullWidth
            type="number"
            inputProps={{ min: 0, max: 100, step: 1 }}
            label={t('clientPlan.label.percent')}
            error={state.percentError || ''}
            value={state.percent || ''}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column
          largeScreen={2}
          computer={2}
          tablet={2}
          mobile={2}
          verticalAlign="middle"
        >
          <Button onClick={handleSave}>{t('add')}</Button>
        </Grid.Column>
      </Grid.Row>
      {
        loadDone
          ? (
            <Grid.Row className="no-padding-y">
              <Grid.Column>
                <LinearLoader color="yellow" />
              </Grid.Column>
            </Grid.Row>
          )
          : null
      }
      <Grid.Row className="no-padding-top">
        <Grid.Column
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
          verticalAlign="middle"
        >
          <SectorTable t={t} sectorItems={sectorItems} handleUpdate={handleUpdate} handleDelete={handleDelete} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Sector.propTypes = {
  t: PropTypes.func,
  sectors: PropTypes.arrayOf(PropTypes.shape({})),
  sectorItems: PropTypes.arrayOf(PropTypes.shape({})),
  customerId: PropTypes.string,
  year: PropTypes.number,
  handleAdd: PropTypes.func,
  activeLoader: PropTypes.func,
  handleDelete: PropTypes.func,
  loadDone: PropTypes.bool,
};

Sector.defaultProps = {
  sectors: [],
  sectorItems: [],
  customerId: '',
  year: 2021,
  t: null,
  loadDone: false,
  handleAdd: () => { },
  activeLoader: () => { },
  handleDelete: () => { },
};

export default translate('common', { wait: true })(Sector);
