export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};

export const sheetHeaders = {
  searchType: { header: 'Tipo de búsqueda', width: 40 },
  value: { header: 'Valor', width: 40 },
  totalEvents: { header: 'Total', width: 20 },
};

export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
