import { Responsive } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

export function getSize(width, modifier = '') {
  let mobileLimit;
  let tabletLimit;
  switch (modifier) {
    case 'dualBanner':
      mobileLimit = Responsive.onlyTablet.minWidth - 150;
      tabletLimit = Responsive.onlyComputer.minWidth + 300;
      break;
    default:
      mobileLimit = Responsive.onlyTablet.minWidth;
      tabletLimit = Responsive.onlyComputer.minWidth;
      break;
  }
  if (width < mobileLimit) return 'mobile';
  if (width < tabletLimit) return 'tablet';
  return 'computer';
}

export function promotionClickGA(promotion) {
  const { id, name, position } = promotion;
  ReactGA.plugin.execute('ec', 'addPromo', {
    id,
    name,
    position,
  });
  ReactGA.plugin.execute('ec', 'setAction', 'promo_click');
  ReactGA.event({
    category: 'Promocionales',
    action: 'click',
    label: name,
  });
  ReactGA4.event({
    category: 'Promocionales',
    action: 'click',
    label: name,
  });
  ReactGA.plugin.execute('ec', 'clear');
}
