import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Divider, Button, Container, Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import service from '../../services';
import UploadModal from './add-csv';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';
import EspecificationsTable from './specificationsTable';
import UpdateAttributesModal from './update-attributes-modal';
import RelatedProducts from './related-products';

class AdditionalAttributes extends Component {
  constructor(props) {
    super(props);
    const { sku } = props;
    this.state = {
      openUploadModal: false,
      sku,
      headers: [
        { key: 'id', isKey: true },
        { key: 'sku', name: 'Código' },
        { key: 'type', name: 'Atributo' },
        { key: 'value', name: 'Valor' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button color="primary" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button color="primary" icon="trash alternate" onClick={() => this.handleDeleteProductAttributes(row)} />
            </Grid.Row>
          ),
        },
      ],
      specificationsData: [],
      attribute: '',
      openUpdate: false,
      attributeError: '',
      attributeValue: '',
      attributeValueError: '',
      openCustomerView: false,
    };
  }

  componentDidMount() {
    const { sku } = this.props;
    this.handleGetSpecifications(sku);
  }

  componentDidUpdate(prevProps) {
    const { sku } = this.props;
    if (prevProps.sku !== sku) {
      this.handleGetSpecifications(sku);
    }
  }

  downloadFile = () => {
    const awsUrl = `${process.env.REACT_APP_URL_AWS}/templates/especificaciones.xlsx`;
    const a = document.createElement('a'); // eslint-disable-line
    a.href = awsUrl;
    a.download = `${awsUrl}`;
    a.click();
  };

  handleGetProductsChange = (e, { value }) => {
    if (value !== null) this.handleGetSpecifications(value);
    this.setState({ sku: value });
  }

  handleGetSpecifications = sku => {
    const { t } = this.props;
    service.getProductsSpecificationsBySku(encodeURIComponent(sku))
      .then(response => {
        if (response.specifications.length > 0) {
          this.handleFormatSpecifications(response.specifications);
        } else this.setState({ specificationsData: [] });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleClickUpdate = row => this.setState({
    attribute: row.attribute,
    attributeValue: row.value,
    openUpdate: true,
  });

  handleClickCancel = () => this.setState({
    attribute: '',
    attributeValue: '',
    openUpdate: false,
  });

  handleClickClose = () => this.setState({
    openCustomerView: false,
  });

  handleAddSpecificationsRefresh = () => {
    const { sku } = this.props;
    this.handleGetSpecifications(sku);
  }

  handleAttributesChange = (e, { value, id, name }) => {
    const { t } = this.props;
    const { specificationsData } = this.state;
    const maxLength = 500;
    const attribute = value;
    const index = name;
    let updatedData = specificationsData;
    if (attribute && attribute.length > 0) {
      if (attribute.length > maxLength) { this.setState({ attribute, attributeError: t('productsAttributes.errors.toLong') }); return; }
    }
    updatedData = specificationsData.map(item => {
      if (item.id === id) {
        const elementToUpdate = specificationsData.findIndex(element => element.id === item.id);
        updatedData[elementToUpdate].type[index] = attribute;
      }
      return item;
    });
    this.setState({ specificationsData: updatedData, attribute, attributeError: '' });
  }

  handleAttributeValueChange = (e, { value, id, name }) => {
    const { t } = this.props;
    const { specificationsData } = this.state;
    const maxLength = 500;
    const attributeValue = value;
    const index = name;
    let updatedData = specificationsData;
    if (attributeValue && attributeValue.length > 0) {
      if (attributeValue.length > maxLength) { this.setState({ attributeValue, attributeError: t('productsAttributes.errors.toLong') }); return; }
    }
    updatedData = specificationsData.map(item => {
      if (item.id === id) {
        const elementToUpdate = specificationsData.findIndex(element => element.id === item.id);
        updatedData[elementToUpdate].value[index] = attributeValue;
      }
      return item;
    });
    this.setState({ specificationsData: updatedData, attributeValue, attributeValueError: '' });
  }

  handleUpdateSpecificationsById = () => {
    const { specificationsData } = this.state;
    const formattedSpecifications = specificationsData.map(element => {
      const newelement = {
        itemId: element.id,
        type: element.type.filter(el => el !== '').join('\\'),
        value: element.value.filter(el => el !== '').join('\\'),
      };
      return newelement;
    });
    this.handleSaveUpdatedSpecifications(formattedSpecifications);
  }

  handleSaveUpdatedSpecifications = async updatedSpecifications => {
    const { t } = this.props;
    const { sku } = this.state;
    service.updateProductsSpecificationsById({ specifications: updatedSpecifications })
      .then(response => {
        if (response.changedRows === '1') {
          const message = t('Se actualizo correctamente'); Toast(message, 'success');
          this.setState({
            attribute: '',
            attributeValue: '',
            openUpdate: false,
          });
          this.handleGetSpecifications(sku);
        } else {
          const message = t('No se realizaron cambios');
          Toast(message, 'warning');
        }
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleFormatSpecifications = specificationsData => {
    if (specificationsData && specificationsData.length > 0) {
      const formattedSpecifications = specificationsData.map(element => {
        const newelement = {
          id: element.id,
          sku: element.sku,
          type: element.type.trim().split('\\'),
          value: element.value.trim().split('\\'),
        };
        return newelement;
      });
      this.setState({ specificationsData: formattedSpecifications });
    }
  }

  handleDeleteProductAttributes = row => {
    const { t } = this.props;
    service.disableProductsSpecificationsById({ itemId: row.id })
      .then(response => {
        if (response.changedRows === '1') {
          const message = t('Se actualizo correctamente'); Toast(message, 'success');
          this.setState({
            attribute: '',
            attributeValue: '',
          });
          this.handleGetSpecifications(row.sku);
        } else {
          const message = t('No se realizaron cambios'); Toast(message, 'warning');
        }
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  render() {
    const { t, sku, formatedProducts } = this.props;
    const {
      openUploadModal, headers, specificationsData, openUpdate,
      attribute, attributeError, attributeValue, attributeValueError, openCustomerView,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header as="h4" color="black" className="titles-news-gothic">{t('productSpecifications.specificationsText')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider section />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header as="h4" color="black" className="titles-news-gothic">{t('productSpecifications.updateSpecifications')}</Header>
            <p>{t('productSpecifications.attributesDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <Button
              icon="download"
              fluid
              content={t('productSpecifications.downloadTemplate')}
              labelPosition="left"
              onClick={this.downloadFile}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Button
              icon="upload"
              color="green"
              fluid
              content={t('productSpecifications.uploadFile')}
              labelPosition="left"
              onClick={() => this.setState({ openUploadModal: true })}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <UploadModal
            openUploadModal={openUploadModal}
            handleClickCancel={() => this.setState({ openUploadModal: false })}
            handleAddSpecificationsRefresh={this.handleAddSpecificationsRefresh}
          />
        </Grid.Row>
        <Divider section />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header as="h4" color="black" className="titles-news-gothic">{t('productSpecifications.adminSpecifications')}</Header>
            <p>{t('productSpecifications.adminSpecificationsText')}</p>
          </Grid.Column>
        </Grid.Row>
        {specificationsData.length > 0 ? (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <Button color="primary" icon="eye" onClick={() => this.setState({ openCustomerView: true })} />
            </Grid.Column>
          </Grid.Row>
        )
          : ''}
        <Grid.Row>
          <Grid.Column width={16}>
            <Container style={{ overflowX: 'scroll' }}>
              <SiclikTable
                unstackable
                headers={headers}
                data={specificationsData}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Modal open={openCustomerView} closeIcon onClose={this.handleClickClose}>
              <Modal.Header>{t('productSpecifications.specifications')}</Modal.Header>
              <Modal.Content>
                <Grid celled structured>
                  <Grid.Row largeScreen={16} computer={16} tablet={16} mobile={16}>
                    <EspecificationsTable
                      unstackable
                      headers={headers}
                      specificationsData={specificationsData}
                    />
                  </Grid.Row>
                </Grid>
              </Modal.Content>
            </Modal>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <UpdateAttributesModal
              open={openUpdate}
              handleClickCancel={this.handleClickCancel}
              attribute={{ value: attribute, error: attributeError, change: this.handleAttributesChange }}
              attributeValue={{ value: attributeValue, error: attributeValueError, change: this.handleAttributeValueChange }}
              validations={this.handleUpdateSpecificationsById}
              specificationsData={specificationsData}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider section />
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
            <Header as="h4" color="black" className="titles-news-gothic">{t('productSpecifications.relatedProducts')}</Header>
            <p>{t('productSpecifications.relatedProductsText')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <RelatedProducts sku={sku} formatedProducts={formatedProducts} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AdditionalAttributes);

AdditionalAttributes.defaultProps = {
  sku: '',
  formatedProducts: [],
};

AdditionalAttributes.propTypes = {
  sku: PropTypes.string,
  formatedProducts: PropTypes.arrayOf(PropTypes.shape({})),
};
