import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as pbi from 'powerbi-client';

// powerbi object is global

const validateConfig = config => {
  switch (config.type) {
    case 'report':
      return pbi.models.validateReportLoad(config);
    case 'dashboard':
      return pbi.models.validateDashboardLoad(config);
    default:
      return 'Unknown config type';
  }
};

class Embed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.component = null;
    this.reportRef = React.createRef();
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    const { config } = this.props;
    this.updateState(config);
    this.setState({ flag: true });
  }

  static getDerivedStateFromProps(props) {
    return ({ ...props.config });
  }

  componentDidUpdate() {
    const errors = validateConfig(this.state);
    if (!errors) {
      return this.embed(this.state);
    } if (this.component !== null) {
      this.reset();
    }
    return null;
  }

  embed(config) {
    const { performOnEmbed } = this.props;
    // eslint-disable-next-line no-undef
    this.component = powerbi.embed(this.reportRef.current, config);
    if (performOnEmbed) {
      performOnEmbed(this.component);
    }
    return this.component;
  }

  updateState(config) {
    const nextState = { ...this.state, ...config };
    this.setState(nextState);
  }

  render() {
    const { style } = this.props;
    return (
      <div
        className="report"
        style={style}
        ref={this.reportRef}
      />
    );
  }
}

Embed.propTypes = {
  config: PropTypes.shape({}).isRequired,
  performOnEmbed: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

Embed.defaultProps = {
  style: {},
};

export default Embed;
