import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const wellcomeServices = {};

wellcomeServices.updateStep = body => (
  request({
    route: apiRoutes.updateStep(),
    method: 'PUT',
    body,
  })
);

export default wellcomeServices;
