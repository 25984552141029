import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Menu, Segment, Grid, Header, Sticky, Popup, Icon,
} from 'semantic-ui-react';
import BestBuyers from './components/best-buyers';
import TopSearchs from './components/searchs';
import MostSelled from './components/most-selled';
import CouponsByOrder from './components/coupons-by-order';
import LogSessions from './components/log-sessions';
import CesSurvey from './components/ces-survey';
import LogUser from './components/log-user';
import NoImageProducts from './components/no-image-products';
import UnmeasuredProducts from './components/unmeasured-products';
import AbandonedCarts from './components/abandoned-carts';

class IntelligenceDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCategorySelection = (e, { name }) => this.setState({ activeItem: name });

  handleRenderMenu = () => {
    const { activeItem } = this.state;
    return (
      <Menu fluid vertical tabular color="blue">
        <Menu.Item
          name="logSessions"
          content="Actividad de inicios de sesión"
          active={activeItem === 'logSessions'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="topSearchs"
          content="Búsquedas frecuentes"
          active={activeItem === 'topSearchs'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="abandonedCarts"
          content="Carritos abandonados"
          active={activeItem === 'abandonedCarts'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="couponsByOrder"
          content="Cupones canjeados"
          active={activeItem === 'couponsByOrder'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="bestBuyers"
          content="Mejores compradores"
          active={activeItem === 'bestBuyers'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="mostSelled"
          content="Productos más vendidos"
          active={activeItem === 'mostSelled'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="noImageProducts"
          content="Productos sin imagen"
          active={activeItem === 'noImageProducts'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="unmeasuredProducts"
          content="Productos sin medidas"
          active={activeItem === 'unmeasuredProducts'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="cesSurvey"
          content="Resultados encuesta CES"
          active={activeItem === 'cesSurvey'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="logUser"
          content="Usuarios - Altas/Bajas"
          active={activeItem === 'logUser'}
          onClick={this.handleCategorySelection}
        />
      </Menu>
    );
  };

  handleSelectedComponent = activeItem => {
    switch (activeItem) {
      case 'bestBuyers':
        return <BestBuyers />;
      case 'topSearchs':
        return <TopSearchs />;
      case 'mostSelled':
        return <MostSelled />;
      case 'couponsByOrder':
        return <CouponsByOrder />;
      case 'logSessions':
        return <LogSessions />;
      case 'cesSurvey':
        return <CesSurvey />;
      case 'logUser':
        return <LogUser />;
      case 'noImageProducts':
        return <NoImageProducts />;
      case 'unmeasuredProducts':
        return <UnmeasuredProducts />;
      case 'abandonedCarts':
        return <AbandonedCarts />;
      default:
        return null;
    }
  };

  render() {
    const { t } = this.props;
    const {
      activeItem, context,
    } = this.state;

    return (
      <Grid style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h2" color="blue">
              {t('intelligenceDashboard.title')}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column only="computer tablet" computer={4} tablet={4}>
            {this.handleRenderMenu()}
          </Grid.Column>
          <Grid.Column only="mobile" mobile={1}>
            <Sticky offset={50} context={context}>
              <Popup
                trigger={<Icon style={{ cursor: 'pointer' }} link color="blue" size="big" name="bars" />}
                content={this.handleRenderMenu()}
                on="click"
                position="bottom rigth"
              />
            </Sticky>
          </Grid.Column>
          <Grid.Column stretched computer={12} tablet={12} mobile={15}>
            <Segment>
              {this.handleSelectedComponent(activeItem)}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(IntelligenceDashboard);
