import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { translate } from 'react-i18next';

function DetailsToBuy(props) {
  const { t } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={6} tablet={6} computer={6} largeScreen={6} widescreen={6}>
          <Header color="blue" style={{ display: 'inline' }} size="tiny" className="titles-news-gothic">
            {t('orderDetail.endUser')}
            :
            {' '}
          </Header>
          <p style={{ display: 'inline' }}>Stock</p>
        </Grid.Column>
        <Grid.Column mobile={10} tablet={10} computer={10} largeScreen={10} widescreen={10}>
          <Header color="blue" style={{ display: 'inline' }} size="tiny" className="titles-news-gothic">
            {t('checkout.paymentMethod')}
            :
            {' '}
          </Header>
          <p style={{ display: 'inline' }}>Crédito CompuSoluciones</p>
        </Grid.Column>
        <Grid.Column mobile={6} tablet={6} computer={6} largeScreen={6} widescreen={6}>
          <Header color="blue" style={{ display: 'inline' }} size="tiny" className="titles-news-gothic">
            {t('orderDetail.exchangeRate')}
            :
            {' '}
          </Header>
          <p style={{ display: 'inline' }}>MXN</p>
        </Grid.Column>
        <Grid.Column mobile={10} tablet={10} computer={10} largeScreen={10} widescreen={10}>
          <Header color="blue" style={{ display: 'inline' }} size="tiny" className="titles-news-gothic">
            CFDI
            :
            {' '}
          </Header>
          <p style={{ display: 'inline' }}>G01 / Adquisición de mercancías</p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(DetailsToBuy);
