import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const checkoutRoutes = {};
checkoutRoutes.agentReviewNotification = body => (
  request({
    route: apiRoutes.agentReviewNotification(),
    method: 'POST',
    body,
  })
);
checkoutRoutes.createCustomer = () => (
  request({
    route: apiRoutes.createCustomer(),
    method: 'POST',
  })
);
checkoutRoutes.createReference = body => (
  request({
    route: apiRoutes.createReference(),
    method: 'POST',
    body,
  })
);
checkoutRoutes.getItems = () => (
  request({
    route: apiRoutes.getItems(),
    method: 'GET',
  })
);
checkoutRoutes.shoppingCartToQuote = body => (
  request({
    route: apiRoutes.shoppingCartToQuote(),
    method: 'PUT',
    body,
  })
);
checkoutRoutes.createSpeiReference = body => (
  request({
    route: apiRoutes.createSpeiReference(),
    method: 'POST',
    body,
  })
);
checkoutRoutes.checkout = body => (
  request({
    route: apiRoutes.checkout(),
    method: 'POST',
    body,
  })
);
checkoutRoutes.addCoupons = body => (
  request({
    route: apiRoutes.addCoupons(),
    method: 'POST',
    body,
  })
);

checkoutRoutes.getCouponsByCustomerId = customerId => (
  request({
    route: apiRoutes.getCouponsByCustomerId(customerId),
    method: 'GET',
  })
);

checkoutRoutes.buy = body => (
  request({
    route: apiRoutes.buy(),
    method: 'POST',
    body,
  })
);
checkoutRoutes.getReviews = orderId => (
  request({
    route: apiRoutes.getQuoteReviews(orderId),
    method: 'GET',
  })
);
checkoutRoutes.getCustomerCredit = () => (
  request({
    route: apiRoutes.getCustomerCredit(),
    method: 'GET',
  })
);
checkoutRoutes.getPaymentMethods = () => (
  request({
    route: apiRoutes.getPaymentMethods(),
    methiod: 'GET',
  })
);

checkoutRoutes.getAccessKeys = () => (
  request({
    route: apiRoutes.getAccessKeys(),
    method: 'GET',
  })
);

checkoutRoutes.addReview = (orderId, payload) => (
  request({
    route: apiRoutes.addReview(orderId),
    method: 'POST',
    body: payload,
  })
);

checkoutRoutes.getExchangeRates = () => (
  request({
    route: apiRoutes.getExchangeRates(),
    method: 'GET',
  })
);

checkoutRoutes.getLeaseQuote = options => (
  request({
    route: apiRoutes.getLeaseQuote(),
    method: 'POST',
    body: options,
  })
);

checkoutRoutes.saveLeaseQuote = options => (
  request({
    route: apiRoutes.saveLeaseQuote(),
    method: 'POST',
    body: options,
  })
);

checkoutRoutes.satisfactionSurvey = options => (
  request({
    route: apiRoutes.satisfactionSurvey(),
    method: 'POST',
    body: options,
  })
);

checkoutRoutes.getReviews = orderId => (
  request({
    route: apiRoutes.getQuoteReviews(orderId),
    method: 'GET',
  })
);

checkoutRoutes.validateOrder = body => (
  request({
    route: apiRoutes.validateOrder(),
    method: 'POST',
    body,
  })
);

checkoutRoutes.deleteCoupon = (orderId, couponCode) => (
  request({
    route: apiRoutes.deleteCouponByCode(orderId, couponCode),
    method: 'DELETE',
  })
);

export default checkoutRoutes;
