import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Container, Header, Loader, Dimmer, Button, Modal,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import VeeamOrderDetail from './veeam-order-detail';
import service from '../services';
import Toast from '../../common/components/toast';
import { TextField } from '../../common/components/materials';

class VeeamMonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      headers: [
        { key: 'requestId', isKey: true },
        { key: 'customerId', name: 'Cliente' },
        { key: 'intelisisId', name: 'Intelisis ID' },
        { key: 'endUserName', name: 'Usuario Final' },
        { key: 'date', name: 'Fecha' },
        { key: 'stage', name: 'Estatus' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleGetVeeamDetail(row)} color="green">
                Ver detalle
              </Button>
            </Grid.Row>
          ), // eslint-dsable-line
        },
      ],
      detailsHeaders: [
        { key: 'sku', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'quantity', name: 'Cantidad' },
        { key: 'lineItemCost', name: 'Costo' },
      ],
      orders: [],
      ordersArrayFiltered: [],
      openDetail: false,
      orderDetail: [],
    };
  }

  componentDidMount() {
    this.handleGetVeeamOrders();
  }

  componentDidUpdate(prevProps) {
    const { customerId } = this.props;
    if (prevProps.customerId !== customerId) {
      this.handleGetVeeamOrders();
    }
  }

  handleGetVeeamOrders = () => {
    const { t, customerId } = this.props;
    this.setState({ loader: true });
    service
      .getVeeamOrders()
      .then(response => {
        const filterByCustomer = response.orders.filter(filter => filter.customerId === customerId);
        this.setState({ orders: filterByCustomer, ordersArrayFiltered: filterByCustomer, loader: false });
      })
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005) {
          this.setState({ loader: false });
        }
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  handleFilterOrders = event => {
    const { orders } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, orders);
  };

  handleFilter = (filter, orders) => {
    const arrayOrdersCopy = JSON.parse(JSON.stringify(orders));
    let foundData = [];
    this.setState({ filter });
    if (!filter) {
      foundData = orders;
    } else {
      foundData = arrayOrdersCopy.filter(
        filterInfo => (filterInfo.customerId && filterInfo.customerId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
          || (filterInfo.intelisisId && filterInfo.intelisisId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
          || (filterInfo.endUserName && filterInfo.endUserName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
          || (filterInfo.date && filterInfo.date.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
          || (filterInfo.stage && filterInfo.stage.toLowerCase().indexOf(filter.toLowerCase()) >= 0),
      );
    }
    this.setState({ filter, ordersArrayFiltered: foundData });
  };

  handleGetVeeamDetail = row => {
    const { t } = this.props;
    this.setState({ loader: true, openDetail: true });
    service
      .getVeeamOrderById(row.requestId)
      .then(response => {
        this.setState({ loader: false, orderDetail: response });
      })
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005) {
          // this.setState({ messageTable: t('finaluser.emptyTable') });
        }
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  render() {
    const { t } = this.props;
    const {
      headers, filter, ordersArrayFiltered, filteError, openDetail, loader, orderDetail, detailsHeaders,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4" color="blue">
              {t('Monitor de pedidos Veeam')}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={1} />
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('Filtrar pedido')}
              onChange={this.handleFilterOrders}
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={14} />
        </Grid.Row>
        <Grid.Row>
          {loader ? (
            <Grid.Row>
              <Dimmer active inverted>
                <Loader active size="big" />
              </Dimmer>
            </Grid.Row>
          ) : (
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable unstackable headers={headers} data={ordersArrayFiltered} />
              </Container>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Modal open={openDetail} closeIcon onClose={() => this.setState({ openDetail: false })}>
            {loader ? (
              <Grid.Row>
                <Dimmer active inverted>
                  <Loader active size="big" />
                </Dimmer>
              </Grid.Row>
            ) : (
              <Modal.Content>
                <VeeamOrderDetail orderDetail={orderDetail} detailsHeaders={detailsHeaders} />
              </Modal.Content>
            )}
            <Modal.Actions>
              <Button
                onClick={() => this.setState({
                  openDetail: false,
                })}
              >
                {t('close')}
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }
}
VeeamMonitor.propTypes = {
  customerId: PropTypes.string,
};
VeeamMonitor.defaultProps = {
  customerId: '',
};
export default translate('common', { wait: true })(VeeamMonitor);
