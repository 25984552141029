import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Button, Embed } from 'semantic-ui-react';

function EmbedModal(props) {
  const {
    t,
    open,
    setOpen,
    video,
  } = props;

  return (
    <Modal
      size="large"
      dimmer="blurring"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>{video.description}</Modal.Header>
      <Modal.Content>
        <Embed
          icon="play circle outline"
          placeholder={video.preview}
          url={video.url}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t('close')}
          onClick={() => setOpen(false)}
        />
      </Modal.Actions>
    </Modal>
  );
}

EmbedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  video: PropTypes.shape({
    description: PropTypes.string.isRequired,
    preview: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }).isRequired,
};

export default translate('common', { wait: true })(EmbedModal);
