import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Popup, Label, Loader, Placeholder, Button,
} from 'semantic-ui-react';
import moment from 'moment';
import { currencyFormat } from '../../../common/utils';
import '../../styles.css';

const newsGothicFont = {
  fontFamily: 'News Gothic MT',
  fontWeight: '500',
};

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  loadOrNull = isValidPrice => {
    const { t } = this.props;
    return (
      !isValidPrice
        ? null
        : (
          <Grid.Row>
            <Loader active inline="centered">{t('productDetails.loading')}</Loader>
          </Grid.Row>
        )
    );
  }

  render() {
    const {
      t, currencyPrice, sku, price, isValidPrice, arrayObjectStock, promotionPrice,
      arrayObjectStockOc, hasNotStock, promotionDeadline, deliverDate, maker,
    } = this.props;

    let currency = '';
    if (currencyPrice === 'Dolares' || currencyPrice === 'Dólares' || currencyPrice === 'Dolar MS') currency = 'USD';
    else currency = 'MXN';
    return (
      <Grid padded centered>
        <Grid.Row>
          <Grid.Column verticalAlign="middle" textAlign="left" widescreen={16} computer={16} tablet={16} mobile={16} className="no-padding-x">
            <Header as="h4" style={newsGothicFont} color="fullBlack">
              {t('productDetails.SKU')}
              :
              {' '}
              {!sku ? '' : sku}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {
            arrayObjectStock && arrayObjectStock.totalStock === null && !hasNotStock
              ? (
                <Grid.Column>
                  <Placeholder fluid>
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                  </Placeholder>
                </Grid.Column>
              )
              : (
                <Grid.Column
                  className="no-padding-x"
                  largeScreen={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                  computer={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                  tablet={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                  mobile={(deliverDate === false || maker !== 'Apple') ? 10 : 7}
                  floated="left"
                >
                  <Popup
                    size="huge"
                    trigger={(
                      <Label as="a">
                        <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">
                          {t('productDetails.available')}
                          :
                          &nbsp;
                          <Header as="h5" className="available" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">
                            <u>
                              {
                                !arrayObjectStock || !arrayObjectStock.totalStock || Number(arrayObjectStock.totalStock) <= 0
                                  ? t('products.notAvailable')
                                  : arrayObjectStock.totalStock
                              }
                            </u>
                          </Header>
                        </Header>
                      </Label>
                    )}
                    flowing
                    hoverable
                  >
                    {
                      arrayObjectStock && arrayObjectStock.totalStock == null
                        ? <Header className="header_price" color="fullBlack">{t('products.notAvailable')}</Header>
                        : (
                          <Grid centered divided columns={4}>
                            <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                              <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.guadalajara')}</Header>
                              <p className="fullBlackText">
                                <b>{(!arrayObjectStock || arrayObjectStock.totalStockGdl == null) || Number(arrayObjectStock.totalStockGdl) <= 0 ? 0 : arrayObjectStock.totalStockGdl}</b>
                                {' '}
                                {t('productDetails.pieces')}
                              </p>
                            </Grid.Column>
                            <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                              <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.monterrey')}</Header>
                              <p className="fullBlackText">
                                <b>{!arrayObjectStock || arrayObjectStock.totalStockMty == null || Number(arrayObjectStock.totalStockMty) <= 0 ? 0 : arrayObjectStock.totalStockMty}</b>
                                {' '}
                                {t('productDetails.pieces')}
                              </p>
                            </Grid.Column>
                            <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                              <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.mexico')}</Header>
                              <p className="fullBlackText">
                                <b>{!arrayObjectStock || arrayObjectStock.totalStockMx == null || Number(arrayObjectStock.totalStockMx) <= 0 ? 0 : arrayObjectStock.totalStockMx}</b>
                                {' '}
                                {t('productDetails.pieces')}
                              </p>
                            </Grid.Column>
                            <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                              <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h4">{t('productDetails.edomexico')}</Header>
                              <p className="fullBlackText">
                                <b>{!arrayObjectStock || arrayObjectStock.totalStockEm == null || Number(arrayObjectStock.totalStockEm) <= 0 ? 0 : arrayObjectStock.totalStockEm}</b>
                                {' '}
                                {t('productDetails.pieces')}
                              </p>
                            </Grid.Column>
                          </Grid>
                        )
                    }
                  </Popup>
                </Grid.Column>
              )
          }
          {
            !arrayObjectStockOc || !arrayObjectStockOc.totalStockOc
              ? ''
              : (
                <Grid.Column
                  className="no-padding-x"
                  largeScreen={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                  computer={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                  tablet={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                  mobile={(deliverDate === false || maker !== 'Apple') ? 6 : 3}
                >
                  <Popup
                    size="huge"
                    trigger={(
                      <Label as="a">
                        <Header as="h5" color="fullBlack" style={{ fontFamily: 'News Gothic MT' }}>
                          {t('productDetails.toArrive')}
                          :
                          &nbsp;
                          <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} className="available" color="fullBlack">
                            <u>{arrayObjectStockOc.totalStockOc}</u>
                          </Header>
                        </Header>
                      </Label>
                    )}
                    flowing
                    hoverable
                  >
                    <Grid centered divided columns={4}>
                      <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.guadalajara')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockGdlOc == null ? 0 : arrayObjectStockOc.totalStockGdlOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.monterrey')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockMtyOc == null ? 0 : arrayObjectStockOc.totalStockMtyOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.mexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockMxOc == null ? 0 : arrayObjectStockOc.totalStockMxOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="center" widescreen={4} computer={4} tablet={4} mobile={4}>
                        <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">{t('productDetails.edomexico')}</Header>
                        <p className="fullBlackText">
                          <b>{arrayObjectStockOc.totalStockEmOc == null ? 0 : arrayObjectStockOc.totalStockEmOc}</b>
                          {' '}
                          {t('productDetails.pieces')}
                        </p>
                      </Grid.Column>
                    </Grid>
                  </Popup>
                </Grid.Column>
              )
          }
          {
            !arrayObjectStockOc || !arrayObjectStockOc.totalStockOc || deliverDate === false || maker !== 'Apple'
              ? ''
              : (
                <Grid.Column className="no-padding-x" largeScreen={6} computer={6} tablet={6} mobile={6}>
                  <Label basic>
                    <Header as="h5" color="fullBlack" style={{ fontFamily: 'News Gothic MT' }}>
                      {t('productDetails.estimatedDate')}
                      :
                      &nbsp;
                      <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} className="available" color="fullBlack">
                        {deliverDate}
                      </Header>
                    </Header>
                  </Label>
                </Grid.Column>
              )
          }
        </Grid.Row>
        {
          ((!promotionPrice) || (Number(promotionPrice) === 0) || (promotionPrice === ''))
            ? null
            : (
              <Grid.Row>
                <Grid.Column className="no-padding-x">
                  {
                    !isValidPrice
                      ? null
                      : (
                        <Header as="h4" style={newsGothicFont} className="header_title_previousprice" color="fullBlack">
                          {t('productDetails.previousprice')}
                          :
                          <Header as="h4" style={{ fontFamily: 'News Gothic MT' }} className="header_previousprice" color="fullBlack">
                            <i>
                              <strike>
                                {!promotionPrice || Number(price) === 0 ? '' : `${currencyFormat(price, currency)}`}
                              </strike>
                            </i>
                          </Header>
                        </Header>
                      )
                  }
                </Grid.Column>
              </Grid.Row>
            )
        }
        {
          (price == null || !isValidPrice)
            ? this.loadOrNull(isValidPrice)
            : (
              <Grid.Row>
                <Grid.Column className="no-padding-x">
                  <Header style={newsGothicFont} className="header_title_price" color="green">
                    {t('productDetails.price')}
                    :
                  </Header>
                  &nbsp;
                  {
                    Number(price) === 0
                      ? ''
                      : (
                        <Header className="header_price" color="green">
                          {
                            (!promotionPrice || promotionPrice === '0' || promotionPrice === '')
                              ? `${currencyFormat(price, currency)}` : `${currencyFormat(promotionPrice, currency)}`
                          }
                        </Header>
                      )
                  }
                </Grid.Column>
              </Grid.Row>
            )
        }
        {(!promotionDeadline || promotionDeadline == null) ? ''
          : (
            <Grid.Row>
              <Grid.Column className="no-padding-x">
                <Button size="tiny" color="red">
                  (
                  {t('productDetails.deadline')}
                  {' '}
                  {moment(promotionDeadline).format('DD/MM/YYYY')}
                  )
                </Button>
              </Grid.Column>
            </Grid.Row>
          )}
        {
          isValidPrice
            ? null
            : (
              <Grid.Row>
                <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16} className="no-padding-x">
                  <Header className="header_price" style={newsGothicFont} color="red">
                    {t('products.invalidPrice')}
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )
        }
      </Grid>
    );
  }
}

ProductDetails.propTypes = {
  arrayObjectStock: PropTypes.shape({
    totalStock: PropTypes.number,
    totalStockEm: PropTypes.number,
    totalStockGdl: PropTypes.number,
    totalStockMty: PropTypes.number,
    totalStockMx: PropTypes.number,
  }).isRequired,
  arrayObjectStockOc: PropTypes.shape({
    totalStockEmOc: PropTypes.number,
    totalStockGdlOc: PropTypes.number,
    totalStockMtyOc: PropTypes.number,
    totalStockMxOc: PropTypes.number,
    totalStockOc: PropTypes.number,
  }).isRequired,
  currencyPrice: PropTypes.string.isRequired,
  hasNotStock: PropTypes.bool.isRequired,
  isValidPrice: PropTypes.bool.isRequired,
  price: PropTypes.number.isRequired,
  promotionDeadline: PropTypes.string.isRequired,
  promotionPrice: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  deliverDate: PropTypes.func.isRequired,
  maker: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(ProductDetails);
