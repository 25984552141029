import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Container,
  Menu,
  Dropdown,
  Button,
  Input,
  Grid,
  Segment,
} from 'semantic-ui-react';

const translatePath = 'productConfig.menus.chooseContent.filterMenu';

function MenuBar(props) {
  const {
    t,
    onClickSelectedBrand,
    onChangeFilter,
    onSelectBrand,
    brandList,
    disabled,
    loader,
    filter,
  } = props;

  return (
    <Container fluid style={{ marginBottom: '-20px' }}>
      <Grid>
        <Grid.Row only="computer tablet">
          <Grid.Column>
            <Menu borderless size="tiny">
              <Menu.Item>
                <Dropdown
                  selection
                  placeholder={t(`${translatePath}.brandList`)}
                  options={brandList}
                  loading={loader}
                  onChange={(e, { value }) => onSelectBrand(value)}
                />
              </Menu.Item>
              <Menu.Item>
                <Button
                  primary
                  content={t(`${translatePath}.filter`)}
                  onClick={() => onClickSelectedBrand()}
                  disabled={disabled}
                />
              </Menu.Item>
              <Menu.Item position="right">
                <Input
                  placeholder={t(`${translatePath}.search`)}
                  onChange={onChangeFilter}
                  value={filter}
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment textAlign="left" size="tiny">
              <b>
                {t(`${translatePath}.brand`)}
                :
              </b>
              <Dropdown
                fluid
                selection
                placeholder={t(`${translatePath}.brandList`)}
                options={brandList}
                loading={loader}
                onChange={(e, { value }) => {
                  onSelectBrand(value);
                  onClickSelectedBrand(value);
                }}
              />
              <br />
              <b>
                {t(`${translatePath}.filter`)}
                :
              </b>
              <Input
                fluid
                placeholder={t(`${translatePath}.search`)}
                onChange={onChangeFilter}
                value={filter}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

MenuBar.propTypes = {
  filter: PropTypes.string,
  brandList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
  })),
  onClickSelectedBrand: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onSelectBrand: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loader: PropTypes.bool,
};

MenuBar.defaultProps = {
  filter: '',
  brandList: [],
  disabled: false,
  loader: false,
};

export default translate('common', { wait: true })(MenuBar);
