import { request } from '../../fetch-utils';
import apiRoutes from '../../../apiRoutes';

const services = {};

services.deleteReviewById = reviewId => (
  request({
    route: apiRoutes.deleteReviewById(),
    method: 'POST',
    body: reviewId,
  })
);
services.getReviews = orderId => (
  request({
    route: apiRoutes.getQuoteReviews(orderId),
    method: 'GET',
  })
);

services.getSegmentByCustomer = customerId => (
  request({
    route: apiRoutes.getSegmentByCustomer(customerId),
    method: 'GET',
  })
);

services.getParcelPrice = body => (
  request({
    route: apiRoutes.getStandardShippingPrice(body),
    method: 'POST',
    body,
  })
);

export default services;
