import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const customerUsers = {};

customerUsers.getUsers = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
    method: 'GET',
  })
);

customerUsers.addUser = body => (
  request({
    route: apiRoutes.addUser(),
    method: 'POST',
    body,
  })
);

customerUsers.getUserRolesGeneral = () => (
  request({
    route: apiRoutes.getRoles('?general=true'),
    method: 'GET',
  })
);
customerUsers.updateCustomerUsers = (userId, body) => (
  request({
    route: apiRoutes.updateCustomerUsers(userId),
    method: 'PUT',
    body,
  })
);
customerUsers.deleteUser = userId => (
  request({
    route: apiRoutes.deleteUserById(userId),
    method: 'PUT',
  })
);

export default customerUsers;
