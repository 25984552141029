import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Menu, Grid } from 'semantic-ui-react';
import menuItems from './index';

const menuStyle = {
  minHeight: '2em',
};

function ItemMenuOnboarding({ history }) {
  return (
    <Grid.Row textAlign="center" verticalAlign="middle">
      <Menu
        stackable
        text
        className="menu-side"
        style={menuStyle}
      >
        { menuItems.map(step => (
          <Grid.Column
            className="no-padding-x menu-item-border"
            mobile={16}
            tablet={3}
            computer={2}
            largeScreen={2}
            widescreen={2}
          >
            <Menu.Item
              name="customers"
              className="menu-item-style lightGreyText hov-blue"
              onClick={() => {
                history.push(`${step.href}`);
              }}
            >
              {step.title}
            </Menu.Item>
          </Grid.Column>
        ))}
      </Menu>
    </Grid.Row>
  );
}

ItemMenuOnboarding.propTypes = {
  history: PropTypes.func.isRequired,
};

ItemMenuOnboarding.defaultProps = {
};

export default translate('common', { wait: true })(ItemMenuOnboarding);
