import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Modal, Dropdown,
} from 'semantic-ui-react';
import propTypes from 'prop-types';
import { TextField } from '../../common/components/materials';

const AddAttribute = props => {
  const {
    t, attribute, attributeError, arrayFamiliesFormat, handleGetFamilyValue, handleAttributeChange, handleClickCancel,
    handleGetHierarchyValue, arrayHierarchy, family, hierarchy, save, update, open, handleClickSave, handleUpdateKeywords,
  } = props;

  return (
    <Modal open={open}>
      <Modal.Header left>Agregar Atributos</Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column largeScreen={8} computer={8} tablet={5} mobile={15}>
              <TextField
                fullWidth
                value={attribute || ''}
                error={attributeError || ''}
                label={t('attributes.addAttribute')}
                onChange={handleAttributeChange}
                defaultValue={attribute}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('attributes.selectFamily')}
                fluid
                search
                selection
                options={arrayFamiliesFormat}
                onChange={handleGetFamilyValue}
                value={family}
                defaultValue={family}
              />
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('attributes.selectHierarchy')}
                fluid
                search
                selection
                options={arrayHierarchy}
                onChange={handleGetHierarchyValue}
                value={hierarchy}
                defaultValue={hierarchy}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {save ? <Button color="blue" onClick={handleClickSave}>{t('save')}</Button> : null}
        {update ? <Button color="blue" onClick={handleUpdateKeywords}>{t('update')}</Button> : null}
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

AddAttribute.defaultProps = {
  open: true,
  t: null,
  handleClickCancel: null,
  handleClickSave: null,
  handleUpdateKeywords: null,
  handleGetFamilyValue: null,
  attributeError: '',
  attribute: '',
  handleGetHierarchyValue: null,
  family: '',
  hierarchy: '',
  save: '',
  update: '',
  handleAttributeChange: null,
};

AddAttribute.propTypes = {
  t: propTypes.func,
  open: propTypes.bool,
  handleClickCancel: propTypes.func,
  handleClickSave: propTypes.func,
  handleUpdateKeywords: propTypes.func,
  handleGetFamilyValue: propTypes.func,
  arrayFamiliesFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  attributeError: propTypes.string,
  attribute: propTypes.string,
  handleGetHierarchyValue: propTypes.func,
  arrayHierarchy: propTypes.arrayOf(propTypes.shape({})).isRequired,
  family: propTypes.string,
  hierarchy: propTypes.string,
  save: propTypes.string,
  update: propTypes.string,
  handleAttributeChange: propTypes.func,
};

export default translate('common', { wait: true })(AddAttribute);
