import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Table } from 'semantic-ui-react';
import '../styles.css';

class Specifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { specifications } = this.props;
    return (
      <div>
        {
          specifications.map(specification => (
            <Table style={{ backgroundColor: 'transparent', border: 'none' }} padded unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">{specification.type}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              {specification.details.map(details => (
                <Table.Body>
                  <Table.Row style={{ backgroundColor: 'rgba(246, 246, 246, 0.8)' }}>
                    <Table.Cell width="5" rowSpan={details.value.length + 1}>
                      {details.type}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width="11">{details.value}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              ))}
            </Table>
          ))
        }
      </div>
    );
  }
}

Specifications.propTypes = {
  specifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default translate('common', { wait: true })(Specifications);
