import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Segment, Grid, Dropdown, Header, Dimmer, Loader, Responsive,
} from 'semantic-ui-react';
import Reports from './components/reports';
import service from './services';
import Toast from '../common/components/toast';
import routes from '../route-names';

class CustomerPowerBi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'BI de Clientes',
      customerOptions: [],
      customer: {},
      loader: true,
      requesting: false,
      componentRequestingView: false,
      searchLoader: false,
      errorCustomer: false,
      idCustomer: props.match.params.idCustomer,
    };
  }

  componentDidMount() {
    const { idCustomer } = this.state;
    this.handleGetCustomers();
    if (idCustomer) {
      this.handleGetCustomerInfoById(idCustomer);
    }
    window.scrollTo(0, 0);
  }

  handleCategorySelection = (e, { name }) => this.setState({ activeItem: name });

  handleGetCustomers = () => {
    this.setState({ searchLoader: true });
    service
      .getCustomers()
      .then(response => {
        this.setState({
          customerOptions: response.customers.map(customer => ({
            key: customer.id,
            text: `${customer.id} - ${customer.name}`,
            value: customer.id,
          })),
          searchLoader: false,
        });
      })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  handleGetCustomerChange = (e, { value }) => {
    const { history } = this.props;
    this.setState({ componentRequestingView: false });
    history.push(`${routes.powerBiDirectory.route.replace(':idCustomer?', '')}${value}`);
    window.location.reload(true);
  };

  handleGetCustomerInfoById = customerId => {
    this.setState({ loader: true, requesting: true });
    service
      .getCustomerData(customerId)
      .then(response => {
        this.setState({
          loader: false,
          requesting: false,
          componentRequestingView: true,
          customer: response,
          errorCustomer: false,
        });
      })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t('customerPowerBi.errors.inactiveCustomer');
        Toast(messageError, 'error');
        this.setState({
          loader: false,
          requesting: false,
          componentRequestingView: false,
          errorCustomer: true,
        });
        // this.setState({ finalUserError: messageError });
      });
  };

  render() {
    const { t } = this.props;
    const {
      activeItem, customerOptions, loader, requesting, componentRequestingView, customer, searchLoader, errorCustomer, idCustomer,
    } = this.state;
    let componentView;
    if (activeItem === 'BI de Clientes') componentView = <Reports customerId={customer.id} />;
    return (
      <Grid style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate}>
          <Header as="h2" color="blue">
            {t('customerPowerBi.title')}
          </Header>
        </Responsive>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={3} textAlign="right">
            <Header as="h4" color="blue">
              {t('customerPowerBi.searchCustomer')}
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={13} textAlign="left">
            {searchLoader === false ? (
              <Dropdown fluid placeholder={t('customerPowerBi.selectCustomer')} options={customerOptions} onChange={this.handleGetCustomerChange} search selection defaultValue={idCustomer} />
            ) : (
              <Dimmer active inverted>
                <Loader active size="medium" />
              </Dimmer>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column stretched largeScreen={16} computer={16} tablet={16} mobile={14}>
            <Segment>
              {loader === true ? (
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  {requesting ? (
                    <Dimmer active inverted>
                      <Loader active size="medium" />
                    </Dimmer>
                  ) : (
                    t('customerPowerBi.selectCustomer')
                  )}
                </Grid.Column>
              ) : (
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  {errorCustomer ? 'Esté número de cliente está inactivo, para mayor información contacte a CxC' : ''}
                </Grid.Column>
              )}
              {componentRequestingView ? componentView : ''}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(CustomerPowerBi);
