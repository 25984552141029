import React from 'react';
import { translate } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';

const LeaseOptions = props => {
  const { leaseOptionsHeaders, data } = props;
  return (
    <div>
      <Grid>
        <Grid.Row centered style={{ overflowX: 'auto' }}>
          <Grid.Column className="members-dimmer" largeScreen={16} computer={16} tablet={16} mobile={16}>
            <SiclikTable headers={leaseOptionsHeaders} data={data} striped={false} basic={null} pageSize={1000} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

LeaseOptions.propTypes = {
  leaseOptionsHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

LeaseOptions.defaultProps = {
  leaseOptionsHeaders: [],
  data: [],
};

export default translate('common', { wait: true })(LeaseOptions);
