import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { TextField } from '../../common/components/materials';

function RenameList(props) {
  const {
    t, open, renameList, onClose, currentListName,
  } = props;
  const [name, setName] = useState('');
  const [loader, setloader] = useState(false);

  const handleRenameWishList = async () => {
    try {
      setloader(true);
      await renameList(name);
      setloader(false);
      onClose();
    } catch (error) {
      setloader(false);
    }
  };

  const handleOnChange = event => {
    setName(event.target.value);
  };

  const handleOnMount = () => {
    setName(currentListName);
  };

  return (
    <Modal onMount={handleOnMount} size="tiny" open={open} onClose={onClose}>
      <Modal.Header>
        {' '}
        {t('wishList.rename')}
      </Modal.Header>
      <Modal.Content>
        <TextField
          fullWidth
          type="text"
          maxLength={30}
          label={t('wishList.label')}
          value={name || ''}
          onChange={handleOnChange}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          {' '}
          {t('close')}
          {' '}
        </Button>
        <Button color="blue" onClick={handleRenameWishList} loading={loader} disabled={name === ''}>
          {t('save')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

RenameList.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renameList: PropTypes.func.isRequired,
  currentListName: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(RenameList);
