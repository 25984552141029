/* eslint-disable no-restricted-syntax, no-prototype-builtins */

export const getPaginationArrayAndPages = (originalArray, pageSize) => {
  const formatedArray = [];
  let totalPages = originalArray.length / pageSize;
  let start = 0;
  let end = pageSize;
  totalPages = (totalPages % 1 > 0) ? Math.trunc(totalPages) + 1 : Math.trunc(totalPages);
  for (let index = 0; index < totalPages; index++) {
    const page = originalArray.slice(start, end);
    start = end;
    end += pageSize;
    formatedArray.push(page);
  }
  return ({ formatedArray, totalPages: totalPages > 0 ? totalPages : 1 });
};

export const cloneArrayNoRef = originalArray => JSON.parse(JSON.stringify(originalArray));

export const compareObjectsWithJSON = (object1, object2) => JSON.stringify(object1) === JSON.stringify(object2);

export const compareArray = (array1, array2) => {
  if (!array1 || !array2) return false;
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if ((array1[i] instanceof Array && array2[i] instanceof Array)) {
      if (!compareArray(array1[i], array2[i])) return false;
    } else if ((array1[i] instanceof Object && array2[i] instanceof Object)) {
      if (!compareObjectsWithJSON(array1[i], array2[i])) return false;
    } else if (array1[i] !== array2[i]) return false;
  }
  return true;
};
