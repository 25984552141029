import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Header, Icon } from 'semantic-ui-react';

function ClientTable(props) {
  const {
    t, clientNumbers, handleUpdate, handleDelete,
  } = props;

  return (
    <Grid className="no-margin-top" celled>
      <Grid.Row textAlign="center" color="grey">
        <Grid.Column
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
          verticalAlign="middle"
        >
          <Header size="small" inverted>{t('clientPlan.title.clientCount')}</Header>
        </Grid.Column>
      </Grid.Row>
      {
        clientNumbers.map(({
          id, category, description, categoryId,
        }) => (
          <Grid.Row key={id}>
            <Grid.Column
              largeScreen={11}
              computer={11}
              tablet={11}
              mobile={11}
              verticalAlign="middle"
            >
              {category}
            </Grid.Column>
            <Grid.Column
              largeScreen={3}
              computer={3}
              tablet={3}
              mobile={3}
              textAlign="center"
              verticalAlign="middle"
            >
              {description}
            </Grid.Column>
            <Grid.Column
              largeScreen={2}
              computer={2}
              tablet={2}
              mobile={2}
              textAlign="center"
              verticalAlign="middle"
            >
              <Grid columns="equal">
                <Grid.Row>
                  <Grid.Column className="no-padding-x" verticalAlign="middle" textAlign="center">
                    <Icon
                      color="blue"
                      link
                      name="pencil alternate"
                      onClick={() => handleUpdate({
                        id, category, categoryId, description,
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column className="no-padding-x" verticalAlign="middle" textAlign="center">
                    <Icon color="blue" link name="trash alternate" onClick={() => handleDelete({ id })} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        ))
      }
    </Grid>
  );
}

ClientTable.defaultProps = {
  handleUpdate: () => {},
  handleDelete: () => {},
  clientNumbers: [],
};

ClientTable.propTypes = {
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  clientNumbers: PropTypes.arrayOf(PropTypes.shape({})),
};

export default translate('common', { wait: true })(ClientTable);
