import React from 'react';

import ModalBuyNow from '../../../buy-now';
import OptionBar from '../../components/OptionsBar';
import ModalAddItem from '../../components/ModalAddItem';
import ModalRenameList from '../../components/ModalRenameList';
import ModalDeletelist from '../../components/ModalDeleteList';

import { useWishListOptions } from './OptionsProvider';
import { useWishLists } from '../WishLists/ListsProvider';
import { useItems } from '../WishListItems/ItemsProvider';

export default function WishList(props) {
  const { t, getShoppingCartCount } = props;
  const {
    setFilteredItems, items, getWishListItems, addItemToWishList,
  } = useItems();
  const {
    selectedList,
    createDefaultWishList,
    modalRenameListToggle,
    toggleSwitchRenameListModal,
    renameWishList,
    deleteWishList,
  } = useWishLists();
  const {
    toggleSwitchAddItemModal,
    toggleSwitchBuyNowModal,
    modalToggleItem,
    modalToggleBuy,
    toggleSwitchDeleteListModal,
    modalToggleDeleteList,
    sendItemsToCart,
  } = useWishListOptions();

  function hasWishListItems() {
    return items.length > 0;
  }

  const handleAddItem = async item => {
    if (selectedList.default) {
      await createDefaultWishList(selectedList.name, item);
    } else {
      await addItemToWishList(selectedList.id, item);
      await getWishListItems(selectedList);
    }
    toggleSwitchAddItemModal();
  };

  const handleOpenBuyQuickCart = () => {
    if (hasWishListItems()) {
      toggleSwitchBuyNowModal();
    }
  };

  const handleOpenRenameList = () => {
    if (!selectedList.default) {
      toggleSwitchRenameListModal();
    }
  };

  const handleSendWishListToCart = async () => {
    if (hasWishListItems()) {
      await sendItemsToCart(items);
      getShoppingCartCount();
    }
  };

  const handleRenameList = name => renameWishList(selectedList.id, name);

  const handleDeleteList = async () => {
    if (selectedList && !selectedList.default) {
      await deleteWishList(selectedList.id);
    }
  };

  const handleOpenDeleteList = () => {
    if (selectedList && !selectedList.default) {
      toggleSwitchDeleteListModal();
    }
  };

  return (
    <div>
      <ModalAddItem
        open={modalToggleItem}
        onClose={toggleSwitchAddItemModal}
        addNewItem={handleAddItem}
      />
      <ModalBuyNow
        wishListName={selectedList.name}
        open={modalToggleBuy}
        onClose={toggleSwitchBuyNowModal}
        items={items}
        t={t}
        getShoppingCartCount={getShoppingCartCount}
      />
      <ModalRenameList
        open={modalRenameListToggle}
        onClose={toggleSwitchRenameListModal}
        renameList={handleRenameList}
        currentListName={selectedList.name}
      />
      <ModalDeletelist
        open={modalToggleDeleteList}
        onClose={toggleSwitchDeleteListModal}
        deleteWishList={handleDeleteList}
      />
      <OptionBar
        wishListName={selectedList.name}
        onClickOpenModal={toggleSwitchAddItemModal}
        filteredItems={setFilteredItems}
        items={items}
        sendItemsToCart={handleSendWishListToCart}
        onOpenBuyNowModal={handleOpenBuyQuickCart}
        onOpenRenameListModal={handleOpenRenameList}
        onOpenDeleteListModal={handleOpenDeleteList}
      />
    </div>
  );
}
