import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Grid, Header, Image, Button, Modal, Icon, Rating, Divider, TransitionablePortal, Input, Card,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import Toast from '../common/components/toast';
import { TextField } from '../common/components/materials';
import routeNames from '../route-names';
import * as session from '../common/sessions';
import quotesService from '../quotations-main-agent/services';

const bucket = `${process.env.REACT_APP_URL_AWS}`;
const csat = `${bucket}/imagenes/assets/comercio/encuesta.png`;

const Payment = props => {
  const {
    t, match, history, location,
  } = props;
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState();
  const [title, setTitle] = useState();
  const [subtitle, setSubTitle] = useState();
  const [message, setMessage] = useState();
  const [openSurvey, setOpenSurvey] = useState(false);
  const [ratingStars, setRatingStars] = useState();
  const [comments, setComments] = useState('');
  const [isAnswered, setIsAnswered] = useState();
  const [platformId, setPlatformId] = useState();
  const [banamexAccount, setBanamex] = useState('870/0084346');
  const [bancomerAccount, setBancomer] = useState('0137516460');
  const [copyBanamex, setCopyBanamex] = useState(false);
  const [copyBancomer, setCopyBancomer] = useState(false);

  useEffect(() => {
    setCurrentPaymentMethod(match.params.type || 0);
    const typePay = match.params.type || 0;
    const displayMessage = () => {
      if (typePay === '1') {
        setTitle(t('checkout.paymentByCredit'));
        setMessage(t('checkout.paymentCompleted'));
      } else if (typePay === '2') {
        setTitle(t('openpay.cards'));
        setMessage(t('checkout.paymentCompleted'));
      } else if (typePay === '3') {
        const urlFile = location.state ? location.state.detail : 'n';
        setTitle(t('checkout.paymentByReference'));
        setSubTitle(urlFile);
        setMessage(t('checkout.paymentCompletedByReference'));
      } else if (typePay === '4') {
        const urlFile = location.state ? location.state.detail : 'n';
        setTitle(t('checkout.paymentBySpei'));
        setSubTitle(urlFile);
        setMessage(t('checkout.paymentCompletedByReference'));
        window.history.replaceState({ detail: '' }, document.title);
      } else if (typePay === '6') {
        setTitle(t('checkout.paymentByCash'));
        setMessage(t('checkout.paymentCompleted'));
      } else {
        setTitle('No existe aún ningún recibo de pago');
        setMessage('Puedes consultar en el módulo de pedidos para revisar algún recibo anterior');
      }
    };
    displayMessage();
  }, [location, match, t]);

  const handleRate = (event, { rating }) => {
    setRatingStars(rating);
  };

  const handleInputComments = e => {
    setComments(e.target.value || '');
  };

  const closeModalSurvey = () => {
    setOpenSurvey(false);
  };

  const handleIsSurveyAnswered = async () => {
    const { userId } = session.get();
    const surveys = await quotesService.getSurveyResponse();
    const userExist = Object.values(surveys);
    if (userExist.find(row => row.find(user => user.userId === userId && user.platformId === 1 && user.active === 1))) {
      setOpenSurvey(false);
      setIsAnswered(true);
      Toast(t('survey.answered'), 'success');
    } else {
      return setOpenSurvey(true);
    }
    return userExist && platformId;
  };

  const handleSaveSurvey = () => {
    const { customerId, userId } = session.get();
    try {
      const body = {
        userId,
        customerId,
        platformId: 1,
        rating: ratingStars || undefined,
        comments: comments || '',
      };
      if (comments.length < 250 && ratingStars !== undefined) {
        quotesService.addSurveyResponse(body);
        setOpenSurvey(false);
        setRatingStars(undefined);
        Toast(t('survey.thanksSurvey'), 'success');
      } else {
        return comments.length > 250
          ? Toast(t('survey.errorComments'), 'error')
          : Toast(t('survey.error'), 'info') && setOpenSurvey(true) && setRatingStars(undefined);
      }
    } catch (error) {
      let messageError = '';
      if (!error.code) {
        messageError = t('error-codes.default');
      } else {
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
    return setRatingStars(undefined) && setPlatformId(1);
  };

  const handleValidateAnswered = async () => {
    const { userId } = session.get();
    const surveys = await quotesService.getSurveyResponse();
    const userExist = Object.values(surveys);
    if (userExist.find(row => row.find(user => user.userId === userId && user.platformId === 1 && user.active === 1))) {
      setOpenSurvey(false);
      setIsAnswered(true);
    } else {
      return setOpenSurvey(true);
    }
    return userExist && platformId;
  };

  useEffect(() => {
    setTimeout(() => {
      handleValidateAnswered();
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className="padding-children">
      <Helmet
        title="siclik - gracias"
        meta={[
          {
            name: 'description',
            content: 'Confirmación de recibo de pago',
          },
        ]}
      />
      <Grid.Row centered>
        <Header as="h3" color="blue">
          {t('checkout.paymentMethod')}
          : &nbsp;
          {title}
        </Header>
      </Grid.Row>
      <Grid.Row centered>
        {subtitle && currentPaymentMethod === '3'
        && (
          <Header as="h4" className="titles-news-gothic">
            {t('checkout.paymentByRefrenceConfirmation')}
            <a style={{ color: 'blue' }} href={subtitle} target="_blank" rel="noopener noreferrer">
              <b>{t('checkout.here')}</b>
            </a>
          </Header>
        )}
        {subtitle && currentPaymentMethod === '4'
        && (
          <Header as="h4" className="titles-news-gothic">
            {t('checkout.speiConfirmation')}
            <a style={{ color: 'blue' }} href={subtitle} target="_blank" rel="noopener noreferrer">
              <b>{t('checkout.here')}</b>
            </a>
          </Header>
        )}
        <Header as="h4" className="titles-news-gothic">
          {message}
        </Header>
      </Grid.Row>
      <Grid.Row centered>
        <Button color="blue" onClick={() => { history.push(routeNames.orders.route); }}>{t('checkout.goToOrders')}</Button>
      </Grid.Row>
      {currentPaymentMethod === '6'
        && (
          <>
            <Divider />
            <Grid.Row centered>
              <Header color="blue" textAlign="center" as="h3">
                {t('checkout.bankReferences')}
                :
              </Header>
            </Grid.Row>
            <Grid.Row centered>
              <Card.Group itemsPerRow={2}>
                <Card
                  style={{
                    width: '250px',
                    height: '150px',
                    boxShadow: '1px',
                  }}
                  centered
                >
                  <Card.Header textAlign="center">
                    <Header color="blue" textAlign="center" as="h4">BBVA</Header>
                  </Card.Header>
                  <Card.Content>
                    <Card.Description textAlign="center">
                      <Header textAlign="center" as="h5"><p>{t('checkout.accountBankBancomer')}</p></Header>
                      <Input value={bancomerAccount} onChange={({ target: { value } }) => setBancomer(value)} readOnly />
                    </Card.Description>
                  </Card.Content>
                  <CopyToClipboard
                    text={bancomerAccount}
                    onCopy={() => setCopyBancomer(!copyBancomer)}
                  >
                    <Button
                      className="bottom attached button"
                      color={copyBancomer === true ? 'green' : 'initial'}
                    >
                      <Icon className="copy outline" />
                      {copyBancomer === true ? 'Copiado' : 'Copiar'}
                    </Button>
                  </CopyToClipboard>
                </Card>
                <Card
                  style={{
                    width: '250px',
                    height: '150px',
                    boxShadow: '1px',
                  }}
                  centered
                >
                  <Card.Header textAlign="center">
                    <Header color="blue" textAlign="center" as="h4">Banamex</Header>
                  </Card.Header>
                  <Card.Content>
                    <Card.Description textAlign="center">
                      <Header textAlign="center" as="h5"><p>{t('checkout.accountBankBanamex')}</p></Header>
                      <Input value={banamexAccount} onChange={({ target: { value } }) => setBanamex(value)} readOnly />
                    </Card.Description>
                  </Card.Content>
                  <CopyToClipboard
                    text={banamexAccount}
                    onCopy={() => setCopyBanamex(!copyBanamex)}
                  >
                    <Button className="bottom attached button" color={copyBanamex === true ? 'green' : 'initial'}>
                      <Icon className="copy outline" />
                      {copyBanamex === true ? 'Copiado' : 'Copiar'}
                    </Button>
                  </CopyToClipboard>
                </Card>
              </Card.Group>
            </Grid.Row>
            <Divider />
          </>
        )}
      <Grid.Row centered>
        <Grid.Column mobile={16} largeScreen={16} computer={16} tablet={16}>
          <Image
            alt={csat}
            fluid
            src={csat}
            target="_blank"
            onClick={() => handleIsSurveyAnswered()}
          />
        </Grid.Column>
      </Grid.Row>
      {isAnswered
        ? (<Grid.Column mobile={16} largeScreen={16} computer={16} tablet={16} />
        )
        : (
          <TransitionablePortal open={openSurvey} animation="fade">
            <Modal size="small" open={openSurvey} centered>
              <Modal.Header>
                {t('survey.nameModal')}
                &nbsp;
                <Icon className="check" />
              </Modal.Header>
              <Modal.Content>
                <Grid.Row centered>
                  {t('survey.descriptionSurvey')}
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <br />
                  {t('survey.qualificationCustomers')}
                </Grid.Row>
                <Grid.Row>
                  <br />
                  <Rating icon="star" size="massive" maxRating={5} onRate={handleRate} clearable />
                </Grid.Row>
                <br />
                <Grid.Row>
                  {t('survey.comments')}
                </Grid.Row>
                <Grid.Row>
                  <br />
                  <TextField
                    fullWidth
                    outlined
                    maxLength={250}
                    multiline
                    rows={4}
                    value={comments || ''}
                    onChange={handleInputComments}
                  />
                </Grid.Row>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={closeModalSurvey}>
                  {' '}
                  {t('survey.cancel')}
                  {' '}
                </Button>
                <Button size="medium" color="blue" onClick={handleSaveSurvey}>{t('survey.saveSurvey')}</Button>
              </Modal.Actions>
            </Modal>
          </TransitionablePortal>
        )}
    </Grid>
  );
};

export default translate('common', { wait: true })(Payment);
