import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Dimmer } from 'semantic-ui-react';

import Footer from '../../common/components/footer';
import MenuApp from './components/menu';

import { CartContext } from '../../context/cart-context';

import * as session from '../../common/sessions';
import constantValues from '../constants';
import './styles.css';

function Layout(props) {
  const {
    history,
    routes,
    isAuthenticated,
    children,
  } = props;

  const { cartCount, updateCartCount } = useContext(CartContext);

  const [dimmerActive, setDimmerActive] = useState(false);

  const { profileComplete, customerId } = session.get();
  const accessDenied = (customerId !== 'G000000');
  if (!accessDenied) history.push('/cotizaciones/agente');
  if (!profileComplete) history.push('/perfil');

  const onTidioChatApiReady = async () => {
    const tidioScript = document.createElement('script');
    tidioScript.src = constantValues.TIDIO_URL;
    document.body.appendChild(tidioScript);
  };

  const handleDimmer = dimmerStatus => {
    setDimmerActive(dimmerStatus);
  };

  useEffect(() => {
    updateCartCount();
  }, [updateCartCount]);

  return (
    <Grid>
      <Grid.Row stretched className="no-padding-y" onLoad={onTidioChatApiReady}>
        <Grid.Column>
          <MenuApp
            history={history}
            isAuthenticated={isAuthenticated}
            routes={routes}
            cartCount={cartCount}
            onActiveMenu={handleDimmer}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding-top">
        <Grid.Column style={{ minHeight: '69vh' }}>
          {children}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched className="general-top-padding">
        <Grid.Column>
          <Footer />
        </Grid.Column>
      </Grid.Row>
      <Dimmer
        active={dimmerActive}
        style={{
          opacity: '0.5',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          display: 'flex',
          position: 'fixed',
        }}
      />
    </Grid>
  );
}

Layout.propTypes = {
  children: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool,
  routes: PropTypes.shape({}).isRequired,
};

Layout.defaultProps = {
  isAuthenticated: false,
};

export default Layout;
