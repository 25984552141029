import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Grid, Header, Button, Input,
} from 'semantic-ui-react';
import services from '../services';
import Toast from '../../../../common/components/toast';

function AddExtendedArea(props) {
  const { t, finishAdd } = props;

  const [state, setState] = useState({
    postalCode: '',
    branch: '',
    plaza: '',
    delegationMunicipality: '',
    city: '',
    colonySettlement: '',
    extendedZone: '',
  });

  const handleInput = (e, { name, value }) => {
    setState(currentState => ({
      ...currentState,
      [name]: value,
    }));
  };

  const handleAddExtendedArea = () => {
    services.addExtendedArea(state)
      .then(() => {
        setState({
          postalCode: '',
          branch: '',
          plaza: '',
          delegationMunicipality: '',
          city: '',
          colonySettlement: '',
          extendedZone: '',
        });
        Toast(t('toast.success.general'), 'success');
        finishAdd();
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  return (
    <Grid>
      <Grid.Row verticalAlign="bottom">
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.postalCode')}</Header>
          <Input
            name="postalCode"
            value={state.postalCode}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.branch')}</Header>
          <Input
            name="branch"
            value={state.branch}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.plaza')}</Header>
          <Input
            name="plaza"
            value={state.plaza}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.delegationMunicipality')}</Header>
          <Input
            name="delegationMunicipality"
            value={state.delegationMunicipality}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.city')}</Header>
          <Input
            name="city"
            value={state.city}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.colonySettlement')}</Header>
          <Input
            name="colonySettlement"
            value={state.colonySettlement}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Header className="no-margin-bottom" as="h5">{t('standardShipping.label.extendedZone')}</Header>
          <Input
            name="extendedZone"
            value={state.extendedZone}
            onChange={handleInput}
            fluid
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={7} mobile={15}>
          <Button
            primary
            onClick={handleAddExtendedArea}
          >
            {t('standardShipping.addExtendedArea')}
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

AddExtendedArea.propTypes = {
  finishAdd: PropTypes.func,
};

AddExtendedArea.defaultProps = {
  finishAdd: () => {},
};

export default translate('common', { wait: true })(AddExtendedArea);
