import React from 'react';
import propTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Modal, Grid, Dropdown, Button, Divider,
} from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const AddAgents = props => {
  const {
    t, openReplaceAgentModal, handleClickCancel, rolesFormat, handleGetRolValue, rol, rolId, cellPhone,
    personalFormat, handleGetPreviousEmail, agentsFormat, agentEmail, handleReplaceAgent,
    previousEmail, agent, handleGetNameValue,
  } = props;
  return (
    <Modal open={openReplaceAgentModal}>
      <Modal.Header>
        {t('agents.replaceAgent')}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row>
            <Grid.Column largeScreen={7} computer={10} tablet={8} mobile={14} textAlign="left">
              <p>
                {t('agents.selectReplaceAgent')}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.agent')}
                fluid
                options={agentsFormat}
                onChange={handleGetPreviousEmail}
                value={previousEmail}
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={15}>
              <p>
                {t('agents.email')}
                &nbsp;&nbsp;&nbsp;
                {previousEmail}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Divider light />
          <Grid.Row>
            <Grid.Column largeScreen={4} computer={10} tablet={8} mobile={14} textAlign="left">
              <p>
                {t('agents.newAgentData')}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.newAgent')}
                fluid
                options={personalFormat}
                onChange={handleGetNameValue}
                value={agent}
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={15}>
              <p>
                {t('agents.email')}
                &nbsp;&nbsp;&nbsp;
                {agentEmail}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.role')}
                options={rolesFormat}
                onChange={handleGetRolValue}
                value={rolId}
                defaultValue={rol}
                fluid
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={7} mobile={15}>
              <TextField
                fullWidth
                maxLength={15}
                label={t('agents.cell')}
                value={cellPhone.value || ''}
                error={cellPhone.error || ''}
                onChange={cellPhone.change}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
        <Button color="blue" onClick={handleReplaceAgent}>{t('update')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
AddAgents.defaultProps = {
  openReplaceAgentModal: true,
  t: null,
  handleClickCancel: null,
  handleGetRolValue: null,
  rol: '',
  handleGetNameValue: null,
  agentEmail: '',
  agentsFormat: '',
  cellPhone: '',
  handleReplaceAgent: null,
  rolId: 0,
  handleGetPreviousEmail: '',
  previousEmail: '',
  agent: '',
};

AddAgents.propTypes = {
  t: propTypes.func,
  openReplaceAgentModal: propTypes.bool,
  handleClickCancel: propTypes.func,
  rolesFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  personalFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  handleGetRolValue: propTypes.func,
  rol: propTypes.string,
  handleGetNameValue: propTypes.func,
  agentsFormat: propTypes.string,
  cellPhone: propTypes.string,
  handleReplaceAgent: propTypes.func,
  agentEmail: propTypes.string,
  rolId: propTypes.number,
  handleGetPreviousEmail: propTypes.func,
  previousEmail: propTypes.string,
  agent: propTypes.string,
};

export default translate('common', { wait: true })(AddAgents);
