import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Menu, Grid } from 'semantic-ui-react';
import routes from '../../../route-names';

const menuStyle = {
  minHeight: '2em',
};

function ItemMenu({
  history, isAuthenticated, t,
}) {
  return (
    <Grid.Row textAlign="center" verticalAlign="middle">
      <Menu
        stackable
        text
        className="menu-side"
        style={menuStyle}
      >
        {
        isAuthenticated && (
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={16}
          tablet={3}
          computer={2}
          largeScreen={2}
          widescreen={2}
        >
          <Menu.Item
            name="productos"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => {
              history.push(routes.intelligenceDashboard.route);
            }}
          >
            {t('intelligenceDashboard.title')}
          </Menu.Item>
        </Grid.Column>
        )
				}
        {
        isAuthenticated && (
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={16}
          tablet={3}
          computer={2}
          largeScreen={2}
          widescreen={2}
        >
          <Menu.Item
            name="customers"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => {
              history.push(routes.customerDirectory.route);
            }}
          >
            {t('adminCS.customers')}
          </Menu.Item>
        </Grid.Column>
        )
				}
        {
        isAuthenticated && (
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={16}
          tablet={3}
          computer={2}
          largeScreen={2}
          widescreen={2}
        >
          <Menu.Item
            name="powerBi"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => {
              history.push(routes.powerBiDirectory.route.replace(':idCustomer?', ''));
            }}
          >
            {t('adminCS.powerBi')}
          </Menu.Item>
        </Grid.Column>
        )
				}
        {
        isAuthenticated && (
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={16}
          tablet={3}
          computer={2}
          largeScreen={2}
          widescreen={2}
        >
          <Menu.Item
            name="reviews"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => {
              history.push(routes.quotationsAgent.route);
            }}
          >
            {t('adminCS.reviews')}
          </Menu.Item>
        </Grid.Column>
        )
				}
        {
        isAuthenticated && (
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={16}
          tablet={3}
          computer={2}
          largeScreen={2}
          widescreen={2}
        >
          <Menu.Item
            name="Pedidos"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => {
              history.push(routes.ordersAgent.route);
            }}
          >
            {t('adminCS.order')}
          </Menu.Item>
        </Grid.Column>
        )
				}
        {
        isAuthenticated && (
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={16}
          tablet={6}
          computer={2}
          largeScreen={2}
          widescreen={2}
        >
          <Menu.Item
            name="Plan de negocios"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => {
              history.push(routes.agentBusinessPlan.route);
            }}
          >
            {t('Plan de negocios')}
          </Menu.Item>
        </Grid.Column>
        )
				}
      </Menu>
    </Grid.Row>
  );
}

ItemMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ItemMenu);
