import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import validator from 'validator';
import moment from 'moment';
import {
  Grid, Button, Header, Confirm, Modal, Dimmer, Loader,
} from 'semantic-ui-react';
import SiclikTable from '../common/components/table';
import Toast from '../common/components/toast';
import service from './services';
import AddUpdate from './components/add-and-update';

const currentDate = moment();

class CrossSelling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      makers: [],
      products: [],
      lines: [],
      families: [],
      headers: [
        { key: 'id', isKey: true },
        { key: 'maker', name: 'Marca' },
        { key: 'action', name: 'Acción' },
        { key: 'lines', name: 'Líneas' },
        { key: 'families', name: 'Familias' },
        { key: 'products', name: 'Productos' },
        { key: 'other', name: 'Otros' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ), // eslint-dsable-line
        },
      ],
      maker: '',
      line: [],
      actionError: '',
      action: '',
      family: [],
      sku: [],
      other: '',
      otherError: '',
      crossSellingData: [],
      update: false,
      openDelete: false,
      makerCrossSellingId: '',
      makerToDelete: '',
      productsCopy: [],
      linesCopy: [],
      familiesCopy: [],
      productsSimpleCopy: [],
      linesSimpleCopy: [],
      familiesSimpleCopy: [],
      makerName: '',
      loadingLists: false,
    };
  }

  componentDidMount() {
    this.handleGetMakers();
    this.handleGetCrossSelling();
  }

  componentDidUpdate(prevProps) {
    const { customerId, year } = this.props;
    if ((customerId !== prevProps.customerId) || (year !== prevProps.year)) {
      this.handleGetCrossSelling();
    }
  }

  onKeyPress = e => {
    if (e.which === 13) {
      this.handleValidateData();
    }
  }

  handleGetMakers = async () => {
    const { t } = this.props;
    const makersObject = await service.getAllMakers().catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
    if (makersObject && makersObject.makers) {
      const makers = makersObject.makers.map(maker => {
        const formatedMaker = { key: maker.id, text: maker.name, value: maker.id };
        return formatedMaker;
      });
      this.setState({ makers });
    }
  }

  handleGetCrossSelling = async () => {
    const { t, customerId, year } = this.props;
    let crossSellingFormated = {};
    this.setState({ loading: true });
    let crossSellingObject = await service.getCrossSelling(customerId, year).catch(error => {
      let messageError = '';
      if (!error || !error.code) {
        messageError = t('error-codes.default');
      } else if (Number(error.code) === 1005 || Number(error.code) === 1000) {
        // this.setState({ messageTable: t('foda.emptyTable') });
      } else { messageError = t(`error-codes.${error.code}`); }
      if (messageError) Toast(messageError, 'error');
      this.setState({ loading: false });
    });
    if (crossSellingObject && crossSellingObject.length > 0) {
      crossSellingObject = crossSellingObject.map(crossSelling => {
        const arrayLines = [];
        const arrayFamilies = [];
        const arrayProducts = [];

        if (crossSelling.details) {
          const lines = crossSelling.details.filter(objectLine => Number(objectLine.categoryId) === 13);
          const families = crossSelling.details.filter(objectLine => Number(objectLine.categoryId) === 14);
          const products = crossSelling.details.filter(objectLine => Number(objectLine.categoryId) === 15);
          lines.map(objectLine => arrayLines.push(objectLine.item));
          families.map(objectLine => arrayFamilies.push(objectLine.item));
          products.map(objectLine => arrayProducts.push(objectLine.item));
          crossSellingFormated = {
            ...crossSelling,
            lines: arrayLines.join(', '),
            families: arrayFamilies.join(', '),
            products: arrayProducts.join(', '),
            linesCopy: lines,
            familiesCopy: families,
            productsCopy: products,
          };
        }
        return crossSellingFormated;
      });
    }
    this.setState({
      crossSellingData: !crossSellingObject ? [] : crossSellingObject,
      update: false,
      openDelete: false,
      maker: '',
      action: '',
      line: '',
      family: '',
      sku: '',
      other: '',
      makerCrossSellingId: '',
      linesCopy: [],
      familiesCopy: [],
      productsCopy: [],
      linesSimpleCopy: '',
      familiesSimpleCopy: '',
      productsSimpleCopy: '',
      loading: false,
    });
  }

  handleChangeMaker = (e, { value }) => {
    const { makers } = this.state;
    const makerArray = makers.filter(maker => maker.value === value);
    const makerName = makerArray && makerArray[0] ? makerArray[0].text : '';
    this.setState({ maker: value, makerName, loadingLists: true });
    this.handleGetAndFilterList(value);
  }

  handleGetAndFilterList = async maker => {
    const { makers } = this.state;
    const { t } = this.props;
    const allLists = await service.getAllProducts().catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
    let products = allLists.product.map(product => {
      const makerObj = makers.filter(makerItem => makerItem.text === product.maker);
      const formatedProduct = {
        key: product.sku,
        text: `${product.sku}-${product.name}`,
        value: product.sku,
        maker: product.maker,
        makerId: makerObj && makerObj[0] ? makerObj[0].key : '',
      };
      return formatedProduct;
    });
    products = products.filter(product => product.makerId === maker);
    const lines = allLists.line.map(line => {
      const formatedLine = { key: line.name, text: line.name, value: line.name };
      return formatedLine;
    });
    let families = allLists.family.map(family => {
      const formatedFamily = {
        key: `${family.makerId}-${family.name}`, text: family.name, value: family.name, makerId: family.makerId,
      };
      return formatedFamily;
    });
    families = families.filter(family => family.makerId === maker && family.value);
    this.setState({
      products, lines, families, loadingLists: false,
    });
  }

  handleChangeLine = (e, { value }) => this.setState({ line: value });

  handleChangeFamily = (e, { value }) => this.setState({ family: value });

  handleChangeSku = (e, { value }) => this.setState({ sku: value });

  handleChangeAction = event => {
    const { t } = this.props;
    const maxLength = 65;
    const { value: action } = event.target;
    if (action.length > maxLength) {
      this.setState({ action, actionError: t('cross-selling.errors.toLong') });
    } else if (action.length > 0 && !validator.isAlphanumeric(action, ['es-ES'], { ignore: ' .,:;%$+-*/=' })) {
      this.setState({ action, actionError: t('cross-selling.errors.notSymbol') });
    } else {
      this.setState({ action, actionError: '' });
    }
  };

  handleChangeOther = event => {
    const { t } = this.props;
    const maxLength = 280;
    const { value: other } = event.target;
    if (other.length > maxLength) {
      this.setState({ other, otherError: t('cross-selling.errors.toLong') });
    } else if (other.length > 0 && !validator.isAlphanumeric(other, ['es-ES'], { ignore: ' .,:;%$+-*/=' })) {
      this.setState({ other, otherError: t('cross-selling.errors.notSymbol') });
    } else {
      this.setState({ other, otherError: '' });
    }
  };

  handleCrossSellingAdded = (id, itemsAdded) => {
    const {
      maker, makerName, action, other, crossSellingData,
    } = this.state;
    const { t } = this.props;
    const arrayLines = [];
    const arrayFamilies = [];
    const arrayProducts = [];
    let lines = [];
    let families = [];
    let products = [];
    if (itemsAdded.crossSellingDetails && itemsAdded.crossSellingDetails.length > 0) {
      lines = itemsAdded.crossSellingDetails.filter(objectLine => Number(objectLine.categoryId) === 13);
      families = itemsAdded.crossSellingDetails.filter(objectLine => Number(objectLine.categoryId) === 14);
      products = itemsAdded.crossSellingDetails.filter(objectLine => Number(objectLine.categoryId) === 15);
      lines.map(objectLine => arrayLines.push(objectLine.item));
      families.map(objectLine => arrayFamilies.push(objectLine.item));
      products.map(objectLine => arrayProducts.push(objectLine.item));
    }
    crossSellingData.push({
      id,
      makerId: maker,
      maker: makerName,
      action,
      lines: arrayLines.length > 0 ? arrayLines.join(', ') : '',
      families: arrayFamilies.length > 0 ? arrayFamilies.join(', ') : '',
      products: arrayProducts.length > 0 ? arrayProducts.join(', ') : '',
      other,
      linesCopy: lines,
      familiesCopy: families,
      productsCopy: products,
    });
    Toast(t('cross-selling.addedMaker'), 'success');
    this.setState({
      crossSellingData, maker: '', action: '', line: '', family: '', sku: '', other: '',
    });
  }

  handleUpdate = row => {
    this.setState({
      update: true,
      makerCrossSellingId: row.id,
      maker: row.makerId,
      makerName: row.marker,
      action: row.action,
      line: row.lines ? row.lines.split(', ') : '',
      family: row.families ? row.families.split(', ') : '',
      sku: row.products ? row.products.split(', ') : '',
      other: row.other,
      linesCopy: row.linesCopy,
      familiesCopy: row.familiesCopy,
      productsCopy: row.productsCopy,
      linesSimpleCopy: row.lines ? row.lines.split(', ') : '',
      familiesSimpleCopy: row.families ? row.families.split(', ') : '',
      productsSimpleCopy: row.products ? row.products.split(', ') : '',
      loadingLists: true,
    });
    this.handleGetAndFilterList(row.makerId);
  }

  handleDelete = row => {
    this.setState({
      makerCrossSellingId: row.id,
      makerToDelete: row.maker,
      openDelete: true,
    });
  }

  handleAddHeader = async () => {
    const { customerId, year } = this.props;
    const {
      maker, action, other, line, family, sku,
    } = this.state;
    const headerData = {
      customerId, makerId: maker, action, year,
    };
    if (other) headerData.other = other;
    const headerObject = await service.addCrossSellingHeader(headerData);
    if (headerObject && headerObject.id && (line.length > 0 || family.length > 0 || sku.length > 0)) {
      this.handleAddItems(headerObject.id);
    }
    if (headerObject && headerObject.id && headerObject.id && line.length < 1 && family < 1 && sku < 1) {
      this.handleCrossSellingAdded(headerObject.id, []);
    }
  }

  handleAddItemsRequest = async items => {
    const { t } = this.props;
    const added = await service.addCrossSellingItems({ items })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    return added;
  }

  handleAddItems = async crossSellingId => {
    const { line, family, sku } = this.state;
    if (line.length > 0 || family.length > 0 || sku.length > 0) {
      let linesToAdd = [];
      let familiesToAdd = [];
      let skusToAdd = [];
      if (line.length > 0) linesToAdd = line.map(item => ({ crossSellingId, categoryId: 13, item }));
      if (family.length > 0) familiesToAdd = family.map(item => ({ crossSellingId, categoryId: 14, item }));
      if (sku.length > 0) skusToAdd = sku.map(item => ({ crossSellingId, categoryId: 15, item }));
      const itemsToAdd = [...linesToAdd, ...familiesToAdd, ...skusToAdd];
      const itemsAdded = await this.handleAddItemsRequest(itemsToAdd);
      if (itemsAdded.crossSellingDetails && itemsAdded.crossSellingDetails.length > 0) this.handleCrossSellingAdded(crossSellingId, itemsAdded);
    }
  }

  handleUpdateHeader = async () => {
    const { makerCrossSellingId, action, other } = this.state;
    const headerData = { crossSellingId: makerCrossSellingId, action, other };
    const headerObject = await service.updateCrossSellingHeader(headerData);
    if (headerObject && headerObject.changedRows) this.handleCompareArrays();
  }

  findDeteledItems = (arrayObjectOld, arrayCurrentItems) => arrayObjectOld.filter(objectOld => arrayCurrentItems.indexOf(objectOld.item) === -1)

  findAddedItems = (arrayCurrentItems, arrayOldItems) => arrayCurrentItems.filter(item => arrayOldItems.indexOf(item) === -1)

  handleCompareArrays = async () => {
    const {
      linesCopy, line, makerCrossSellingId, productsCopy, sku, family, familiesCopy,
      linesSimpleCopy, productsSimpleCopy, familiesSimpleCopy,
    } = this.state;
    let deletedLines = [];
    const addedLines = [];
    let deletedProducts = [];
    const addedProducts = [];
    let deletedFamilies = [];
    const addedFamilies = [];
    if (line && line.length > 0) {
      deletedLines = await this.findDeteledItems(linesCopy, line);
      const linesAddedArray = await this.findAddedItems(line, linesSimpleCopy);
      linesAddedArray.map(item => {
        addedLines.push({ crossSellingId: makerCrossSellingId, categoryId: 13, item });
        return item;
      });
    }
    if (sku && sku.length > 0) {
      deletedProducts = await this.findDeteledItems(productsCopy, sku);
      const productsAddedArray = await this.findAddedItems(sku, productsSimpleCopy);
      productsAddedArray.map(item => {
        addedProducts.push({ crossSellingId: makerCrossSellingId, categoryId: 15, item });
        return item;
      });
    }
    if (family && family.length > 0) {
      deletedFamilies = await this.findDeteledItems(familiesCopy, family);
      const familiesAddedArray = await this.findAddedItems(family, familiesSimpleCopy);
      familiesAddedArray.map(item => {
        addedFamilies.push({ crossSellingId: makerCrossSellingId, categoryId: 14, item });
        return item;
      });
    }
    const addedItems = [...addedLines, ...addedFamilies, ...addedProducts];
    const deletedItems = [...deletedLines, ...deletedFamilies, ...deletedProducts];
    this.handleUpdateItems(addedItems, deletedItems);
  }

  handleUpdateItems = async (addedItems, deletedItems) => {
    const { productsCopy, linesCopy, familiesCopy } = this.state;
    let currentItems = [...productsCopy, ...linesCopy, ...familiesCopy];
    if (addedItems && addedItems.length > 0) {
      const allItems = await this.handleAddItemsRequest(addedItems);
      currentItems = allItems.crossSellingDetails;
    }
    if (deletedItems && deletedItems.length > 0) {
      const idsDeleted = await Promise.all(deletedItems.map(item => {
        const id = service.deleteCrossSellingItems(item.id).then(response => (response.status ? item.id : ''));
        return id;
      }));
      idsDeleted.map(id => {
        const elementToDelete = currentItems.findIndex(itemToDelete => itemToDelete.id === id);
        currentItems.splice(elementToDelete, 1);
        return id;
      });
    }
    this.handleCrossSellingItemUpdated(currentItems);
  }

  handleCrossSellingItemUpdated = currentItems => {
    const { t } = this.props;
    const {
      crossSellingData, action, other, makerCrossSellingId,
    } = this.state;
    const arrayLines = [];
    const arrayFamilies = [];
    const arrayProducts = [];
    const elementToUpdate = crossSellingData.findIndex(element => element.id === makerCrossSellingId);
    if (currentItems && currentItems.length > 0) {
      const lines = currentItems.filter(objectLine => Number(objectLine.categoryId) === 13);
      const families = currentItems.filter(objectLine => Number(objectLine.categoryId) === 14);
      const products = currentItems.filter(objectLine => Number(objectLine.categoryId) === 15);
      lines.map(objectLine => arrayLines.push(objectLine.item));
      families.map(objectLine => arrayFamilies.push(objectLine.item));
      products.map(objectLine => arrayProducts.push(objectLine.item));
      crossSellingData[elementToUpdate].lines = arrayLines.join(', ');
      crossSellingData[elementToUpdate].action = action;
      crossSellingData[elementToUpdate].families = arrayFamilies.join(', ');
      crossSellingData[elementToUpdate].products = arrayProducts.join(', ');
      crossSellingData[elementToUpdate].other = other;
      crossSellingData[elementToUpdate].linesCopy = lines;
      crossSellingData[elementToUpdate].familiesCopy = families;
      crossSellingData[elementToUpdate].productsCopy = products;
    } else {
      crossSellingData[elementToUpdate].action = action;
      crossSellingData[elementToUpdate].other = other;
    }
    Toast(t('cross-selling.updatedMaker'), 'success');
    this.setState({
      update: false,
      crossSellingData,
      maker: '',
      action: '',
      line: '',
      family: '',
      sku: '',
      other: '',
      makerCrossSellingId: '',
      linesCopy: [],
      familiesCopy: [],
      productsCopy: [],
      linesSimpleCopy: '',
      familiesSimpleCopy: '',
      productsSimpleCopy: '',
    });
  }

  handleDeleteItems = async itemId => {
    const { t } = this.props;
    const deleted = await service.deleteCrossSellingItem(itemId)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    return deleted;
  }

  handleDeleteHeader = async () => {
    const { t } = this.props;
    const { makerCrossSellingId } = this.state;
    const deleted = await service.deleteCrossSellingHeader(makerCrossSellingId)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (deleted && deleted.status) this.handleCrossSellingItemDeleted(makerCrossSellingId);
  }

  handleValidateData = () => {
    const { t } = this.props;
    const {
      action, maker, makerCrossSellingId, crossSellingData,
    } = this.state;
    let makerExist = '';
    if (!makerCrossSellingId && action && maker) {
      if (crossSellingData && crossSellingData.length > 0) makerExist = crossSellingData.filter(data => data.makerId === maker);
      if (makerExist && makerExist.length > 0) {
        Toast(t('cross-selling.errors.exist'), 'error');
      } else {
        this.handleAddHeader();
      }
    } else if (makerCrossSellingId && action && maker) {
      this.handleUpdateHeader();
    } else {
      Toast(t('cross-selling.errors.notComplete'), 'error');
    }
  }

  handleCrossSellingItemDeleted = makerCrossSellingId => {
    const { t } = this.props;
    const { crossSellingData } = this.state;
    const elementToDelete = crossSellingData.findIndex(element => element.id === makerCrossSellingId);
    crossSellingData.splice(elementToDelete, 1);
    Toast(t('cross-selling.deletedMaker'), 'success');
    this.setState({
      crossSellingData, openDelete: false, makerCrossSellingId: '', makerToDelete: '',
    });
  }

  render() {
    const { t } = this.props;
    const {
      headers, makers, products, action, actionError, other, otherError, lines, families, crossSellingData,
      update, line, maker, family, sku, openDelete, makerToDelete, loading, loadingLists,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row />
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16}>
            <Header as="h3" color="blue">{t('cross-selling.crossSellingMakers')}</Header>
          </Grid.Column>
        </Grid.Row>
        {loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          )
          : null}
        {!loading && !update
          ? (
            <AddUpdate
              makers={makers}
              maker={maker}
              products={products}
              sku={sku}
              action={action}
              actionError={actionError}
              other={other}
              otherError={otherError}
              lines={lines}
              line={line}
              families={families}
              family={family}
              handleChangeMaker={this.handleChangeMaker}
              handleChangeAction={this.handleChangeAction}
              handleChangeLine={this.handleChangeLine}
              handleChangeFamily={this.handleChangeFamily}
              handleChangeSku={this.handleChangeSku}
              handleChangeOther={this.handleChangeOther}
              loading={loadingLists}
              onKeyPress={this.onKeyPress}
            />
          ) : null}
        {!loading && !update && !loadingLists
          ? (
            <Grid.Row centered columns={4}>
              <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={16}>
                <Button fluid onClick={this.handleValidateData}>
                  {' '}
                  {t('save')}
                  {' '}
                </Button>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {!loading
          ? (
            <Grid.Row style={{ overflowX: 'scroll' }}>
              <SiclikTable headers={headers} data={crossSellingData} />
            </Grid.Row>
          ) : null}
        <Grid.Row>
          <Confirm
            open={openDelete}
            header={t('cross-selling.deleteMaker')}
            content={makerToDelete}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({
              openDelete: false,
            })}
            onConfirm={this.handleDeleteHeader}
          />
        </Grid.Row>
        <Grid.Row>
          <Modal open={update}>
            <Modal.Content>
              <AddUpdate
                disabled
                makers={makers}
                maker={maker}
                products={products}
                sku={sku}
                action={action}
                actionError={actionError}
                other={other}
                otherError={otherError}
                lines={lines}
                line={line}
                families={families}
                family={family}
                handleChangeMaker={this.handleChangeMaker}
                handleChangeAction={this.handleChangeAction}
                handleChangeLine={this.handleChangeLine}
                handleChangeFamily={this.handleChangeFamily}
                handleChangeSku={this.handleChangeSku}
                handleChangeOther={this.handleChangeOther}
                loading={loadingLists}
                onKeyPress={this.onKeyPress}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleValidateData} color="blue">{t('save')}</Button>
              <Button
                onClick={() => this.setState({
                  update: false,
                  maker: '',
                  action: '',
                  line: '',
                  family: '',
                  sku: '',
                  other: '',
                  makerCrossSellingId: '',
                  linesCopy: [],
                  familiesCopy: [],
                  productsCopy: [],
                  linesSimpleCopy: '',
                  familiesSimpleCopy: '',
                  productsSimpleCopy: '',
                })}
              >
                {t('back')}
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(CrossSelling);

CrossSelling.defaultProps = {
  year: currentDate.year(),
  customerId: '',
  t: null,
};

CrossSelling.propTypes = {
  year: PropTypes.number,
  customerId: PropTypes.string,
  t: PropTypes.func,
};
