import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Icon, Header, Loader, Divider,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { TextField } from '../../common/components/materials';
import * as session from '../../common/sessions';
import SiclikTable from '../../common/components/table';
import Toast from '../../common/components/toast';
import branchOfficeAddress from '../services';

class DeliveryAddress extends Component {
  constructor(props) {
    const { t } = props;
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
    ]);

    if (!hasAccess) props.history.push('/perfil');
    this.state = {
      filter: '',
      filterError: '',
      messageTable: 'No se encontraron direcciones registradas',
      customerAddress: [],
      customerHeaders: [
        { key: 'id', isKey: true },
        { key: 'name', name: t('headers.address.name') },
        { key: 'street', name: t('headers.address.address') },
        { key: 'postalCode', name: t('headers.address.postalCode') },
        { key: 'city', name: t('headers.address.town') },
        { key: 'state', name: t('headers.address.estado') },
        {
          key: 'isValidated',
          name: t('headers.address.status'),
          format: (cell, row) => (cell == true ? <Icon color="green" name="checkmark" size="large" /> : ''), // eslint-disable-line
        },
      ],
      loading: true,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.handleGetAddress();
  }

  componentDidUpdate(prevProps) {
    const { customerId } = this.props;
    if (prevProps.customerId !== customerId) {
      this.handleGetAddress();
    }
  }

  handleGetAddress = () => {
    const { t, customerId } = this.props;
    branchOfficeAddress.getBranchOfficeAddress(customerId)
      .then(response => {
        const customerAddress = response.branchOffices;
        const filteredInfoAddress = response.branchOffices;
        this.setState({ customerAddress, filteredInfoAddress, loading: false });
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ customerAddress: [], filteredInfoAddress: [], loading: false });
        if (error.code !== 1000) Toast(messageError, 'error');
      });
  }

  validations = event => {
    const { t } = this.props;
    const maxLength = 30;
    let mistakes = false;
    const { value: filter } = event.target;
    if ((filter.trim()).length > maxLength) {
      this.setState({ filterError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) {
      this.setState({ filter, filterError: '' });
      this.handleFilterChange(filter, mistakes);
    }
  }

  handleFilterChange = (filter, mistakes) => {
    const { customerAddress } = this.state;
    const adressesForFilter = [...customerAddress];
    let foundData = [];
    if (mistakes !== true) {
      foundData = adressesForFilter.filter(filterInfo => (filterInfo.intelisisId && filterInfo.intelisisId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.street.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.postalCode.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.city.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.state.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
      this.setState({ filteredInfoAddress: foundData });
    }
  }

  render() {
    const { t } = this.props;
    const {
      filter, filterError, customerHeaders, filteredInfoAddress, messageTable, loading,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4" color="blue">{t('customerDirectory.addresses')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider section />
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={10} mobile={15}>
            <TextField
              fullWidth
              maxLength={30}
              value={filter || ''}
              error={filterError || ''}
              label={t('branchOffice.filter')}
              onChange={this.validations}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} />
        </Grid.Row>
        <Grid.Row centered>
          {loading
            ? <Loader active inline="centered" />
            : (
              <Grid.Column largeScreen={14} computer={14} tablet={12} mobile={14} style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  selectable
                  singleSelection
                  data={filteredInfoAddress}
                  headers={customerHeaders}
                  pageSize={10}
                  noDataMessage={messageTable}
                />
              </Grid.Column>
            )}
        </Grid.Row>
      </Grid>
    );
  }
}
DeliveryAddress.propTypes = {
  customerId: PropTypes.string,
};

DeliveryAddress.defaultProps = {
  customerId: '',
};
export default translate('common', { wait: true })(DeliveryAddress);
