import React, { Component } from 'react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import moment from 'moment';
import {
  Menu, Segment, Grid, Header, Popup, Sticky, Icon, Responsive, Dropdown, Loader,
} from 'semantic-ui-react';
import * as session from '../common/sessions';
import service from './services';
import Toast from '../common/components/toast';
import ClientPlan from '../client-plan';
import FodaForm from '../foda-form';
import CrossSelling from '../cross-selling';
import StrategicPriorities from '../strategic-priorities';
import PlanArea from '../plan-area';
import Years from '../common/components/years';
import roles from '../role-permissions';

class BusinessPlan extends Component {
  constructor(props) {
    super(props);
    const { access, customerId } = session.get();
    const isCSUser = securityService.validate(access, roles.adminCS);
    this.state = {
      activeItem: 'FODA',
      loader: isCSUser,
      isCSUser,
      customers: [],
      customerId: !isCSUser ? customerId : '',
      year: moment().year(),
    };
  }

  componentDidMount() {
    const { isCSUser } = this.state;
    if (isCSUser) {
      this.handleGetCustomers();
    }
  }

  handleGetCustomers = async () => {
    const customers = await service.getCustomers()
      .then(response => {
        const formatedCustomers = response.customers.map(customer => (
          {
            key: customer.id,
            text: `${customer.id} - ${customer.name}`,
            value: customer.id,
          }
        ));
        return formatedCustomers;
      })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
        return [];
      });
    const [customer] = customers;
    this.setState({ customers, customerId: customer ? customer.value : '', loader: false });
  }

  handleCategorySelection = (e, { name }) => this.setState({ activeItem: name });

  handleOnUpdate = (e, { width }) => {
    this.setState({ width });
  }

  handleSelectedComponent = (activeItem, customerId, year) => {
    switch (activeItem) {
      case 'FODA':
        return <FodaForm year={year} customerId={customerId} />;
      case 'Clientes':
        return <ClientPlan year={year} customerId={customerId} />;
      case 'PrioridadesEstratégicas':
        return <StrategicPriorities year={year} customerId={customerId} />;
      case 'CrossSelling':
        return <CrossSelling year={year} customerId={customerId} />;
      case 'PlanArea':
        return <PlanArea year={year} customerId={customerId} />;
      default:
        return null;
    }
  };

  handleRenderMenu = () => {
    const { activeItem } = this.state;

    return (
      <Menu fluid vertical tabular color="blue">
        <Menu.Item
          name="FODA"
          active={activeItem === 'FODA'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="Clientes"
          active={activeItem === 'Clientes'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="PrioridadesEstratégicas"
          content="Prioridades estratégicas"
          active={activeItem === 'PrioridadesEstratégicas'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="CrossSelling"
          content="Marcas en cross selling"
          active={activeItem === 'CrossSelling'}
          onClick={this.handleCategorySelection}
        />
        <Menu.Item
          name="PlanArea"
          content="Plan del área"
          active={activeItem === 'PlanArea'}
          onClick={this.handleCategorySelection}
        />
      </Menu>
    );
  }

  handleYearChange = year => {
    this.setState({ year });
  }

  handleChangeCustomer = (e, { value }) => {
    this.setState({ customerId: value });
  }

  render() {
    const { t } = this.props;
    const {
      activeItem, width, year, customerId, customers, loader, isCSUser, context,
    } = this.state;
    return (
      <Grid padded>
        <Responsive as={Grid.Row} centered fireOnMount onUpdate={this.handleOnUpdate}>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={3}>
            <Header as="h2" color="blue">{t('businessPlan.title')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={13}>
            <Grid>
              <Grid.Row>
                <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2}>
                  <Years
                    min={2019}
                    max={moment().year() + 2}
                    year={year}
                    change={this.handleYearChange}
                    tr={t}
                    clearable={false}
                  />
                </Grid.Column>
                {
                  isCSUser
                    ? (
                      <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8}>
                        {
                        !loader
                          ? (
                            <Dropdown
                              placeholder={t('businessPlan.customerPlaceholder')}
                              onChange={this.handleChangeCustomer}
                              options={customers}
                              value={customerId}
                              error={!customerId}
                              selection
                              search
                              fluid
                            />
                          )
                          : <Loader active inline />
                      }
                      </Grid.Column>
                    )
                    : null
                }
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Responsive>
        {
          !customerId
            ? null
            : (
              <Grid.Row centered>
                <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={2}>
                  {
                  width <= 425
                    ? (
                      <Sticky offset={50} context={context}>
                        <Popup
                          trigger={
                            <Icon link color="blue" size="big" name="bars" />
                        }
                          content={this.handleRenderMenu()}
                          on="click"
                          position="bottom rigth"
                        />
                      </Sticky>
                    )
                    : this.handleRenderMenu()
                }
                </Grid.Column>
                <Grid.Column stretched largeScreen={12} computer={12} tablet={12} mobile={14}>
                  <Segment>
                    {this.handleSelectedComponent(activeItem, customerId, year)}
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )
        }
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(BusinessPlan);
