import React from 'react';
import { translate } from 'react-i18next';
import {
  Image, Grid,
} from 'semantic-ui-react';
import routes from '../../../route-names';
import Multiline from './multiline';

const assetUrl = `${process.env.REACT_APP_URL_AWS}/imagenes/assets/siclik/`;
const logo = `${assetUrl}siclik_compusoluciones.png`;

const PublicHeader = props => {
  const { history } = props;

  return (
    <Grid padded>
      <Grid.Row className="general-padding" verticalAlign="middle">
        <Grid.Column textAlign="start" mobile={9} tablet={4} computer={4} className="no-padding-x">
          <Image style={{ cursor: 'pointer', zIndex: '1000', maxWidth: '200px' }} src={logo} size="medium" wrapped onClick={() => history.push(routes.home.route)} />
        </Grid.Column>
      </Grid.Row>
      <Multiline />
    </Grid>
  );
};

export default translate('common', { wait: true })(PublicHeader);
