import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';

import {
  Header,
  Segment,
  List,
  Checkbox,
  Icon,
  Container,
} from 'semantic-ui-react';
import Slider from './slider';

import {
  MAX_FILTERS_VISIBLE,
  FILTER_VISIBILITY_HIDE,
  FILTER_VISIBILITY_SHOW,
  PRICE_FILTER,
  BUNDLE_FILTER,
  STOCK_FILTER,
  OUTLET_FILTER,
  AVAILABILITY_FILTER,
} from '../const';

const containerFilterVisibilityStyle = {
  color: '#0054A3',
  marginTop: '0.8rem',
  marginLeft: '0.5rem',
  fontWeight: 'bold',
  cursor: 'pointer',
};

function setVisibilityFilters({ values, ...restProps }) {
  const visibleValues = values.slice(0, MAX_FILTERS_VISIBLE);
  const hiddenValues = values.slice(MAX_FILTERS_VISIBLE);
  return {
    ...restProps,
    visibleValues,
    hiddenValues,
  };
}

function getFilterArrayValues(filters) {
  return filters
    .map(setVisibilityFilters);
}

function validatePriceFilter(filter) {
  const isValidFilter = filter.type === PRICE_FILTER && filter.visibleValues.length > 0;
  if (isValidFilter) {
    const minPrice = filter.visibleValues.find(({ name }) => name === 'minPrice')?.count || 0;
    const maxPrice = filter.visibleValues.find(({ name }) => name === 'maxPrice')?.count || 0;
    return minPrice !== maxPrice;
  }
  return false;
}
function Filters(props) {
  const {
    filters,
    onFiltersChange,
    clearFilters,
    isMainFilter,
  } = props;
  const [generalFilters, setGeneralFilters] = useState([]);
  const [checkedFilters, setCheckedFilters] = useState({});

  const handleFilterClick = (filter, action) => {
    const visibilitySHowAction = () => {
      const allFiltersByType = filters.find(({ type }) => type === filter.type);
      const newGeneralFilters = generalFilters.map(generalFilter => {
        if (generalFilter.type === filter.type) {
          return {
            ...generalFilter,
            showAll: true,
            visibleValues: allFiltersByType.values,
            hiddenValues: [],
          };
        }
        return generalFilter;
      });
      setGeneralFilters(newGeneralFilters);
    };

    const visibilityHideAction = () => {
      setGeneralFilters(getFilterArrayValues(filters));
    };

    const actions = {
      [FILTER_VISIBILITY_SHOW]: visibilitySHowAction,
      [FILTER_VISIBILITY_HIDE]: visibilityHideAction,
    };

    actions[action]();
  };

  const handleFilterChange = (filterType, value, checked) => {
    const newCheckedFilters = {
      ...checkedFilters,
    };
    if (filterType === PRICE_FILTER) {
      Object.keys(value).forEach(key => {
        newCheckedFilters[key] = value[key];
      });
    } else {
      newCheckedFilters[filterType] = uniq([
        ...(checked
          ? [...(checkedFilters[filterType] || []), value]
          : (checkedFilters[filterType] || []).filter(item => item !== value)),
      ]);
    }
    setCheckedFilters(newCheckedFilters);
    onFiltersChange(newCheckedFilters);
  };

  const handleStockChange = (filterType, checked) => {
    const newCheckedFilters = {
      ...checkedFilters,
      availability: checked ? filterType : 'all',
    };
    setCheckedFilters(newCheckedFilters);
    onFiltersChange(newCheckedFilters);
  };

  const isCheckboxChecked = filterType => {
    const matchType = generalFilters.find(({ type }) => type === AVAILABILITY_FILTER);
    if (matchType) {
      return matchType.visibleValues.some(({ name }) => name === filterType);
    }
    return false;
  };

  useEffect(() => {
    if (clearFilters) {
      setGeneralFilters([]);
      setCheckedFilters({});
    } else {
      const newGeneralFilters = getFilterArrayValues(filters);
      setGeneralFilters(newGeneralFilters);
    }
  }, [filters, clearFilters]);

  return (
    <Segment>
      <Header as="h4" color="blue">Filtros</Header>
      <List>
        <List.Item style={{ padding: '0.8rem 0' }}>
          <List.Header style={{ color: 'black' }}>Disponibilidad</List.Header>
          <List.List>
            <List.Item>
              <List.Content>
                <Checkbox
                  toggle
                  label="Solo con stock"
                  disabled={isMainFilter}
                  checked={isCheckboxChecked(STOCK_FILTER)}
                  onChange={(e, { checked }) => handleStockChange(STOCK_FILTER, checked)}
                />
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Checkbox
                  toggle
                  label="Solo stock outlet"
                  disabled={isMainFilter}
                  checked={isCheckboxChecked(OUTLET_FILTER)}
                  onChange={(e, { checked }) => handleStockChange(OUTLET_FILTER, checked)}
                />
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Content>
                <Checkbox
                  toggle
                  label="Mostrar paquetes"
                  disabled={isMainFilter}
                  checked={isCheckboxChecked(BUNDLE_FILTER)}
                  onChange={(e, { checked }) => handleStockChange(BUNDLE_FILTER, checked)}
                />
              </List.Content>
            </List.Item>
          </List.List>
        </List.Item>

        {generalFilters.filter(validatePriceFilter).map(filter => (
          <List.Item>
            <List.Header style={{ color: 'black' }}>{filter.title}</List.Header>
            <List.List>
              <List.Item>
                <List.Content>
                  <Slider
                    range={[
                      filter.visibleValues.find(({ name }) => name === 'minPrice')?.count || 0,
                      filter.visibleValues.find(({ name }) => name === 'maxPrice')?.count || 1,
                    ]}
                    initValues={[
                      filter.visibleValues.find(({ name }) => name === 'minPrice')?.checked || 0,
                      filter.visibleValues.find(({ name }) => name === 'maxPrice')?.checked || 1,
                    ]}
                    onChange={(values => handleFilterChange(filter.type, values, true))}
                  />
                </List.Content>
              </List.Item>
            </List.List>
          </List.Item>
        ))}

        {generalFilters.filter(filter => filter.type !== AVAILABILITY_FILTER && filter.type !== PRICE_FILTER).map(filter => (
          <List.Item key={filter.title} style={{ padding: '0.8rem 0' }}>
            <List.Header style={{ color: 'black' }}>{filter.title}</List.Header>
            <List.List>
              {filter.visibleValues.map(value => (
                <List.Item key={value.name}>
                  <List.Content>
                    <Checkbox
                      label={`${value.name} (${value.count})`}
                      onChange={(event, data) => handleFilterChange(filter.type, value.name, data.checked)}
                      checked={value?.checked}
                      disabled={!value.count}
                      style={{ fontWeight: value.count ? 'normal' : 'lighter' }}
                    />
                  </List.Content>
                </List.Item>
              ))}

              {filter.hiddenValues.length > 0 && (
                <Container
                  style={containerFilterVisibilityStyle}
                  onClick={() => handleFilterClick(filter, FILTER_VISIBILITY_SHOW)}
                >
                  <span>Ver todas las opciones</span>
                  <Icon name="angle down" style={{ marginLeft: '0.5rem' }} />
                </Container>
              )}
              {(filter?.showAll) && (
                <Container
                  style={containerFilterVisibilityStyle}
                  onClick={() => handleFilterClick(filter, FILTER_VISIBILITY_HIDE)}
                >
                  <span>Ver menos opciones</span>
                  <Icon name="angle up" style={{ marginLeft: '0.5rem' }} />
                </Container>
              )}
            </List.List>
          </List.Item>
        ))}
      </List>
    </Segment>
  );
}

Filters.defaultProps = {
  filters: [],
};

Filters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        checked: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ])).isRequired,
  })),
  clearFilters: PropTypes.bool.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  isMainFilter: PropTypes.bool.isRequired,
};

export default Filters;
