import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

export default () => (
  request({
    route: apiRoutes.externalSession(),
    method: 'POST',
    body: {},
  })
);
