import PropTypes from 'prop-types';
import React from 'react';
import XLSX from 'xlsx-js-style';
import { Button } from 'semantic-ui-react';

function ExportToExcel(props) {
  const {
    data, fileName, headers, label, styleRow, styleHeader, sheetName,
  } = props;
  const dataValues = data.map(item => Object.keys(headers).map(key => {
    const v = item[key] || '';
    const numFmt = headers[key]?.numFmt || 0;
    return ({
      v, s: styleRow, z: numFmt, t: numFmt ? 'n' : 's',
    });
  }));
  const formatHeaders = Object.values(headers).map(({ header }) => ({ v: header, s: styleHeader }));
  const formatedData = [formatHeaders, ...dataValues];
  const fileExtension = '.xlsx';

  const exportToFile = () => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(formatedData);
    workSheet['!cols'] = Object.values(headers).map(({ width }) => ({ width }));
    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
    XLSX.writeFile(workBook, `${fileName}${fileExtension}`);
  };

  return (
    <Button fluid onClick={exportToFile}>{label || 'Exportar'}</Button>
  );
}

ExportToExcel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.shape({}),
  fileName: PropTypes.string,
  label: PropTypes.string,
  sheetName: PropTypes.string,
  styleRow: PropTypes.shape({}),
  styleHeader: PropTypes.shape({}),
};

ExportToExcel.defaultProps = {
  data: [],
  headers: {},
  styleRow: {
    font: { sz: 12, name: 'News Gothic MT' },
    alignment: { wrapText: true },
  },
  styleHeader: {
    font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
    alignment: { wrapText: true },
    fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
  },
  fileName: 'Datos_exportados',
  label: '',
  sheetName: 'Hoja 1',
};

export default ExportToExcel;
