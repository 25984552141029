import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Button, Grid, GridRow, Header, Icon, Confirm,
} from 'semantic-ui-react';
import validator from 'validator';
import deliveryAddress from '../services';
import { TextField } from '../../common/components/materials';
import TableComments from '../../common/components/table';
import Toast from '../../common/components/toast';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: '',
      addComments: [],
      commentsError: '',
      commentId: '',
      headers: [
        { key: 'id', isKey: true },
        { key: 'type', name: 'Tipo' },
        { key: 'comment', name: 'Comentario' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <GridRow>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
            </GridRow>
          ),
        },
      ],
      commentType: [
        { value: 1, label: 'Restricción' },
        { value: 2, label: 'Seguridad' },
        { value: 3, label: 'General' },
      ],
      messageTable: '',
      commentsSelected: [],
      disabledDelete: true,
      clean: false,
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    if (id.length > 1) this.handleGetComments();
  }

  handleGetComments = async () => {
    const { t, id } = this.props;
    const arrayComments = await deliveryAddress.getComments(id)
      .catch(error => {
        if (!error.code) {
          Toast(t('error-codes.default'), 'error');
        } else if (Number(error.code) === 1005 || Number(error.code) === 1000) {
          this.setState({ messageTable: t('branchOffice.message.table') });
        } else {
          Toast(t(`error-codes.${error.code}`), 'error');
        }
      });
    if (arrayComments && arrayComments.comments) this.setState({ addComments: arrayComments.comments });
  }

  handleCommentsChange = event => {
    const { t } = this.props;
    const maxLength = 30;
    const minLength = 7;
    const { value: comments } = event.target;
    if (comments && comments > 0) {
      if (!validator.isAlphanumeric(comments.replace(/ /g, ''), ['es-ES'])) { this.setState({ comments, commentsError: t('user.errors.notSymbol') }); return; }
      if ((comments.trim()).length > maxLength) { this.setState({ comments, commentsError: t('user.errors.toLong') }); return; }
      if ((comments.trim()).length < minLength) { this.setState({ comments, commentsError: t('user.errors.toShort') }); return; }
    }
    this.setState({ comments, commentsError: '' });
  }

  validations = action => {
    let mistakes = false;
    let { commentsError, commentTypeByError } = this.state;
    const { comments, commentTypeBy } = this.state;
    const { t } = this.props;
    if (comments.trim().length === 0 || (!comments)) {
      commentsError = t('branchOffice.errors.empty');
      mistakes = true;
    }
    if (!commentTypeBy) {
      commentTypeByError = t('branchOffice.errors.empty');
      mistakes = true;
    }
    if (!validator.isEmpty(commentsError)) mistakes = true;
    if (!validator.isEmpty(commentTypeByError)) mistakes = true;
    if (mistakes === false) {
      if (action === 1) this.handleAddComment();
      if (action === 2) this.handleUpdateComment();
    } else {
      this.setState({ commentsError, commentTypeByError });
    }
  }

  handleAddComment = () => {
    const { t, id } = this.props;
    const {
      comments, commentType, addComments, commentTypeBy,
    } = this.state;
    const body = {
      commentTypeId: commentTypeBy,
      comment: comments,
    };
    const { label } = commentType.find(item => item.value === commentTypeBy);
    deliveryAddress.addComment(id, body).then(response => {
      if (response) {
        addComments.push({ id: response.id, type: label, comment: comments });
        this.setState({
          addComments, comments: '', commentTypeBy: '',
        });
        Toast(t('branchOffice.message.confirmCommentAdd'), 'success');
      }
    })
      .catch(error => {
        let messageError = '';
        if (!error) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  };

  handleClickUpdate = comments => {
    const commentTypeBy = Number((comments.type).replace('Restricción', 1).replace('Seguridad', 2).replace('General', 3));
    this.setState({
      disabled: true,
      commentTypeBy,
      comments: comments.comment,
      commentTypeByError: '',
      commentsError: '',
      commentId: comments.id,
    });
  }

  handleSelectComment = comments => {
    if (comments.length === 1) {
      const commentTypeBy = Number((comments[0].type).replace('Restricción', 1).replace('Seguridad', 2).replace('General', 3));
      this.setState({
        disabled: true,
        disabledDelete: false,
        commentTypeByError: '',
        commentsError: '',
        comments: comments[0].comment,
        commentTypeBy,
        commentId: comments[0].id,
        commentsSelected: comments,
      });
    }
    if (comments.length > 1) {
      this.setState({
        disabled: false,
        disabledDelete: false,
        commentTypeBy: '',
        comments: '',
        commentsSelected: comments,
      });
    } else if (comments.length < 1) {
      this.setState({
        disabled: false,
        disabledDelete: true,
        commentTypeBy: '',
        comments: '',
        commentTypeByError: '',
        commentsError: '',
      });
    }
  }

  handleUpdateComment = () => {
    const { t, id } = this.props;
    const {
      comments, commentTypeBy, commentId, addComments: stateAddComments,
    } = this.state;
    const body = {
      commentTypeId: commentTypeBy,
      comment: comments,
    };
    deliveryAddress.updateComment(id, body).then(() => {
      const addComments = stateAddComments.map(currentItem => {
        const item = { ...currentItem };
        if (Number(item.id) === Number(commentId)) {
          item.comment = comments;
        }
        return item;
      });
      Toast(t('branchOffice.message.confirmCommentUpdate'), 'success');
      this.setState({
        disabled: false, addComments, comments: '', commentTypeBy: '', clean: true,
      });
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleShowModalDelete = () => {
    const { commentsSelected } = this.state;
    if (commentsSelected.length >= 1) this.setState({ open: true });
  }

  handleConfirm = () => {
    const { commentsSelected } = this.state;
    const { id } = this.props;

    commentsSelected.map(currentComment => {
      const comment = { ...currentComment };
      deliveryAddress.deleteComment(id, comment.id).then(() => {
        this.handleUpdateCommentsArray(comment.id);
      }).catch(error => {
        comment.error = error;
        Toast(`Ocurrio un error al eliminar el tipo de comentario: ${comment.type}, favor de intentarlo de nuevo`, 'error');
      });
      return comment;
    });

    this.setState({
      commentTypeBy: '',
      commentsError: '',
      commentTypeByError: '',
      clean: true,
      comments: '',
      disabled: false,
      disabledDelete: true,
      open: false,
      commentsSelected: [],
    });
  }

  handleUpdateCommentsArray = id => {
    const { addComments } = this.state;
    const { t } = this.props;
    const maneuverIndex = addComments.findIndex(maneuver => maneuver.id === id);
    addComments.splice(maneuverIndex, 1);
    this.setState(addComments);
    Toast(t('branchOffice.message.confirmCommentDelete'), 'success');
  }

  handleCleanedArray = () => this.setState({ clean: false });

  handleCancel = () => this.setState({ open: false, commentsSelected: [], clean: true });

  handleClickCancelUpdate = () => this.setState({
    commentTypeBy: '',
    commentsError: '',
    commentTypeByError: '',
    clean: true,
    comments: '',
    disabled: false,
    disabledDelete: true,
    open: false,
    commentsSelected: [],
  });

  render() {
    const { t } = this.props;
    const {
      headers, comments, commentType, commentsError, commentsSelected, commentTypeBy, commentTypeByError,
      addComments, messageTable, disabled, clean, disabledDelete, open,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h3">{t('branchOffice.title5')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={16}>
            <TextField
              select
              fullWidth
              label={t('branchOffice.commentType')}
              disabled={disabled}
              value={commentTypeBy || ''}
              error={commentTypeByError || ''}
              onChange={({ target }) => this.setState({ commentTypeBy: target.value, commentTypeByError: '' })}
              options={commentType}
              required
            />
          </Grid.Column>
          <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={16}>
            <TextField
              fullWidth
              maxLength={30}
              value={comments || ''}
              error={commentsError || ''}
              label={t('branchOffice.comments')}
              onChange={this.handleCommentsChange}
              required
            />
          </Grid.Column>
          {!disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
                <Button color="blue" onClick={() => this.validations(1)} disabled={disabled}>{t('add')}</Button>
              </Grid.Column>
            )
            : null}
          {disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={7}>
                <Button color="blue" content={t('update')} onClick={() => this.validations(2)} />
              </Grid.Column>
            )
            : null}
          {disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
                <Button color="blue" content={t('cancel')} onClick={this.handleClickCancelUpdate} />
              </Grid.Column>
            )
            : null}
          <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={2}>
            <Button color="blue" disabled={disabledDelete} icon onClick={this.handleShowModalDelete}>
              <Icon name="trash alternate" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <GridRow>
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16}>
            <TableComments
              headers={headers}
              data={addComments}
              noDataMessage={messageTable}
              clean={clean}
              updateClean={() => this.setState({ clean: !clean })}
              getSelectedRows={this.handleSelectComment}
            />
          </Grid.Column>
        </GridRow>
        <Grid.Row>
          <Confirm
            open={open}
            header={t('branchOffice.deleteComment')}
            content={commentsSelected.map(item => <p>{item.type}</p>)}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

Comments.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default translate('common', { wait: true })(Comments);
