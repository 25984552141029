/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Grid, List, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

function Lists(props) {
  const {
    t, selectedList, modalOpen, lists,
  } = props;
  function handleSelectedList(selectedWishList) {
    selectedList(selectedWishList);
  }

  const handleNewWishlist = () => {
    modalOpen();
  };

  function isDefaultList(wishLists) {
    return wishLists.find(wishList => !wishList.default);
  }

  function generateLists(wishList) {
    return (
      <List.Item key={wishList.id} onClick={() => handleSelectedList(wishList)} active={wishList.selected}>
        <List.Content>
          <List.Header>{wishList.name}</List.Header>
          <List.Description as="a">{wishList.created}</List.Description>
        </List.Content>
      </List.Item>
    );
  }

  function newWishlist() {
    return (
      <List size="small">
        <List.Item onClick={handleNewWishlist}>
          <List.Content>
            <Button basic color="blue" fluid>
              <b>{t('wishList.add')}</b>
            </Button>
          </List.Content>
        </List.Item>
      </List>
    );
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <List selection>
            {lists.map(generateLists)}
          </List>
          {isDefaultList(lists) && newWishlist()}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Lists.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
  })).isRequired,
  selectedList: PropTypes.func.isRequired,
  modalOpen: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(Lists);
