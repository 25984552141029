export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};
export const sheetHeaders = {
  executor: { header: 'Usuario Ejecutor', width: 50 },
  modifiedUser: { header: 'Usuario Modificado', width: 50 },
  movement: { header: ' Movimiento', width: 12 },
  dateMovement: { header: 'Fecha de Movimiento', width: 50 },
};
export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
