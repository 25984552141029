import React, { Component } from 'react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Confirm, Label, Modal, Divider, Dropdown,
} from 'semantic-ui-react';
import { TextField, LinearLoader } from '../common/components/materials';
import ExportExcel from '../common/components/ExportExcel';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import SiclikTable from '../common/components/table';
import services from './services';
import Toast from '../common/components/toast';
import AddTags from './components/add';
import * as session from '../common/sessions';
import routeNames from '../route-names';

function groupTags(items = []) {
  const indexedTags = items.reduce((indexed, current) => {
    const currentHash = { ...indexed };
    const {
      skus, brands, families, categories,
    } = currentHash;
    const {
      sku, brand, family, category, tags,
    } = current;
    if (!brands[brand]) brands[brand] = {};
    if (!families[family]) families[family] = {};
    if (!categories[category]) categories[category] = {};
    skus[sku] = tags;
    brands[brand][sku] = true;
    families[family][sku] = true;
    categories[category][sku] = true;
    return {
      skus, brands, families, categories,
    };
  }, {
    skus: {}, brands: {}, families: {}, categories: {},
  });
  return indexedTags;
}

class ProductKeywords extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);

    if (!hasAccess) props.history.push(routeNames.profile.route);

    this.state = {
      messageTable: '',
      filter: '',
      selectedTag: null,
      tags: [],
      headersFamilyArray: [
        { key: 'sku', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'description', name: 'Descripcion' },
        { key: 'brand', name: 'Marca' },
        { key: 'family', name: 'Familia' },
        { key: 'category', name: 'Categoria' },
        {
          key: 'tags',
          name: 'Palabras clave',
          format: (cell, row) => (
            <>
              {cell.map(tag => {
                const tagColor = row.generatedTagsHash[tag] ? 'blue' : '';
                return (<Label color={tagColor} key={`${row.sku}${tag}`}>{tag}</Label>);
              })}
            </>
          ),
        },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleDeleteAttribute(row)} />
            </Grid.Row>
          ),
        },
      ],
      shouldClean: false,
      confirmDelete: false,
      loader: true,
      open: false,
      filteredTags: [],
      groupedTags: {
        skus: {}, brands: {}, families: {}, categories: {},
      },
      formatedSkus: [],
      formatedBrands: [],
      formatedFamilies: [],
      formatedCategories: [],
      selectedBrand: null,
    };
  }

  componentDidMount() {
    this.handleGetAllTags();
  }

  cleanState = () => {
    this.setState({
      confirmDelete: false,
      filter: '',
      filteredTags: [],
      formatedBrands: [],
      formatedCategories: [],
      formatedFamilies: [],
      formatedSkus: [],
      groupedTags: {
        skus: {}, brands: {}, families: {}, categories: {},
      },
      loader: true,
      messageTable: '',
      open: false,
      selectedTag: null,
      shouldClean: false,
      tags: [],
    }, () => {
      this.handleGetAllTags();
    });
  }

  handleCancel = () => this.setState({ confirmDelete: false });

  handleDeleteAttribute = row => {
    this.setState({ confirmDelete: true, selectedTag: row });
  }

  handleClickCancel = () => this.setState({
    open: false,
    selectedTag: null,
  });

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase();
    const { tags, selectedBrand } = this.state;
    let allTagsCopy = [...tags];
    if (selectedBrand) {
      allTagsCopy = tags.filter(tag => tag.brand === selectedBrand);
    }
    if (!filter) {
      this.setState({ filter, filteredTags: allTagsCopy });
    } else {
      const filteredTags = allTagsCopy.filter(filterInfo => ((filterInfo.family && filterInfo.family.toLowerCase().includes(baseFilter))
        || (filterInfo.brand && filterInfo.brand.toLowerCase().includes(baseFilter))
        || (filterInfo.category && filterInfo.category.toLowerCase().includes(baseFilter))
        || (filterInfo.sku && filterInfo.sku.toLowerCase().includes(baseFilter))));
      this.setState({ filter, filteredTags });
    }
  }

  handleGetAllTags = async () => {
    const items = await services.getAllProductTags()
      .then(response => (response.items || []));
    const groupedTags = groupTags(items);
    const formatedSkus = Object.keys(groupedTags.skus).map(sku => ({ key: sku, text: sku, value: sku }));
    const formatedBrands = Object.keys(groupedTags.brands).map(brand => ({ key: brand, text: brand, value: brand }));
    const formatedFamilies = Object.keys(groupedTags.families).map(family => ({ key: family, text: family, value: family }));
    const formatedCategories = Object.keys(groupedTags.categories).map(category => ({ key: category, text: category, value: category }));
    this.setState({
      tags: items,
      filteredTags: items,
      groupedTags,
      formatedSkus,
      formatedBrands,
      formatedFamilies,
      formatedCategories,
      loader: false,
      shouldClean: true,
    });
  }

  handleDeleteConfirmed = async () => {
    const { t } = this.props;
    const { selectedTag } = this.state;
    await services.deleteTags({ skus: [selectedTag.sku] })
      .then(async () => {
        Toast(t('tags.eliminationConfirmation'), 'success');
        this.cleanState();
      })
      .catch(error => {
        Toast(t(`error-codes.${error.code}`, 'error'));
      });
  }

  handleClickUpdate = row => this.setState({
    selectedTag: row,
    open: true,
  });

  handleUpdateComplete = async () => {
    this.cleanState();
  }

  handleFilterByBrand = (event, data) => {
    const { value: brand } = data;
    const { tags, filter } = this.state;
    const allTagsCopy = [...tags];
    this.setState({ selectedBrand: brand });
    if (!brand) return;
    if (filter) {
      const filteredTags = allTagsCopy.filter(filterInfo => ((filterInfo.family && filterInfo.family.toLowerCase().includes(filter))
        || (filterInfo.brand && filterInfo.brand === brand)
        || (filterInfo.category && filterInfo.category.toLowerCase().includes(filter))
        || (filterInfo.sku && filterInfo.sku.toLowerCase().includes(filter))));
      this.setState({ filteredTags });
    }
    const filteredTags = allTagsCopy.filter(filterInfo => filterInfo.brand === brand);
    this.setState({ filteredTags });
  }

  render() {
    const { t } = this.props;
    const {
      headersFamilyArray, messageTable, shouldClean, confirmDelete, filter, filteError,
      open, filteredTags, loader, selectedBrand,
      groupedTags, selectedTag, formatedSkus, formatedBrands, formatedFamilies, formatedCategories,
    } = this.state;
    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header color="blue" className="titles-menu">{t('tags.searcherTag')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('tags.welcome')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center">
          <Grid.Column computer={8} tablet={8} mobile={10}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('tags.filter')}
              onChange={this.handleFilter}
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={2} mobile={6}>
            <Dropdown
              button
              floating
              fluid
              labeled
              className="icon pointer"
              options={formatedBrands}
              onChange={this.handleFilterByBrand}
              search
              text={selectedBrand || t('tags.filterBrand')}
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={16}>
            <Button
              onClick={() => this.setState({ open: true })}
              content={t('add')}
              labelPosition="left"
              icon="add user"
              color="blue"
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={2} tablet={2} mobile={6} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={12} tablet={6} mobile={6}>
            <Label color="blue" horizontal />
            <span>{t('tags.generatedByIA')}</span>
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={6}>
            <ExportExcel
              headers={sheetHeaders}
              data={filteredTags.map(tag => {
                const {
                  sku, description, brand, family, category, tags, generatedTagsHash,
                } = tag;
                const { manualTags: manual, aiTags: ai } = tags.reduce((tagsHash, current) => {
                  const { manualTags, aiTags } = tagsHash;
                  if (generatedTagsHash[current]) {
                    aiTags.push(current);
                  } else {
                    manualTags.push(current);
                  }
                  return { manualTags, aiTags };
                }, { manualTags: [], aiTags: [] });
                return {
                  sku,
                  description,
                  brand,
                  family,
                  category,
                  manualTags: manual,
                  aiTags: ai,
                  total: tags.length,
                };
              }) || []}
              fileName="Tags de busqueda"
              styleRow={styleRow}
              styleHeader={styleHeader}
              sheetName="Tags de busqueda"
            />
          </Grid.Column>
        </Grid.Row>
        {
          loader
            ? (
              <Grid.Row className="no-padding-y">
                <Grid.Column>
                  <LinearLoader color="blue" />
                </Grid.Column>
              </Grid.Row>
            )
            : null
        }
        <Grid.Row>
          <Grid.Column style={{ overflowX: 'scroll' }}>
            <SiclikTable
              selectable
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              data={filteredTags}
              headers={headersFamilyArray}
              pageSize={15}
              noDataMessage={messageTable}
              singleSelection
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={confirmDelete}
            header={t('tags.confirmDelete')}
            content={t('tags.confirmDeleteDescription')}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleDeleteConfirmed}
          />
        </Grid.Row>
        <Grid.Row>
          <Modal open={open}>
            <Modal.Header left>{t('tags.addKeywords')}</Modal.Header>
            <Modal.Content>
              <AddTags
                handleUpdateComplete={this.handleUpdateComplete}
                handleClickCancel={this.handleClickCancel}
                skus={groupedTags.skus}
                brands={groupedTags.brands}
                families={groupedTags.families}
                categories={groupedTags.categories}
                formatedSkus={formatedSkus}
                formatedBrands={formatedBrands}
                formatedFamilies={formatedFamilies}
                formatedCategories={formatedCategories}
                selectedTag={selectedTag}
              />
            </Modal.Content>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(ProductKeywords);
