import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

class AddIndicator extends Component {
  static getDerivedStateFromProps(props, state) {
    if ((props.objectiveId !== state.objectiveId) || (props.clean !== state.clean)) {
      return {
        objective: props.objective,
        objectiveId: props.objectiveId,
        clean: props.clean,
        progress: props.progress,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      objective: props.objective,
      objectiveId: props.objectiveId,
      objectiveError: '',
      clean: false,
      progress: props.progress,
    };
  }

  onKeyPress = e => {
    if (e.which === 13) this.handleClickSave();
  }

  handleChangeObjective = event => {
    const { tr } = this.props;
    const { value } = event.target;
    if (value.length > 500) {
      this.setState({ objective: value, objectiveError: `Error: ${tr('business-plan.errors.maxCharLimitExceeded')}` });
      return;
    }
    this.setState({ objective: value, objectiveError: '' });
  }

  handleChangeProgress = event => {
    const { tr } = this.props;
    const { value } = event.target;
    if (value.length > 500) {
      this.setState({ progress: value, progressError: `Error: ${tr('business-plan.errors.maxCharLimitExceeded')}` });
      return;
    }
    this.setState({ progress: value, progressError: '' });
  }

  handleClickSave = () => {
    const {
      handleGetObjectiveToAdd, handleUpdateObjective, tr, clean, changeClean,
    } = this.props;
    const {
      objective, objectiveId, progress, progressError, objectiveError,
    } = this.state;
    if (clean) changeClean();
    if (objective && objective.length > 0) {
      if (objectiveId && !objectiveError && !progressError) handleUpdateObjective(objective, progress);
      if (!objectiveId && !objectiveError && !progressError) handleGetObjectiveToAdd(objective, progress);
    } else {
      this.setState({ objectiveError: tr('business-plan.errors.empty') });
    }
  }

  render() {
    const { tr, handleClickCancelObjective } = this.props;
    const {
      objective, objectiveError, objectiveId, progress, progressError,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column onKeyPress={this.onKeyPress} largeScreen={10} computer={10} tablet={10} mobile={16}>
            <TextField
              id="objective"
              label={`${tr('business-plan.objective')}...`}
              value={objective}
              error={objectiveError}
              maxLength={500}
              color="green"
              onChange={this.handleChangeObjective}
              fullWidth
              multiline
            />
          </Grid.Column>
          <Grid.Column onKeyPress={this.onKeyPress} largeScreen={3} computer={3} tablet={3} mobile={6} style={{ textAlign: 'center', marginLeft: '2rem' }}>
            <TextField
              id="progress"
              type="number"
              maxLength={5}
              value={progress}
              label={tr('business-plan.progress')}
              color="green"
              error={progressError}
              InputProps={{
                startAdornment: <InputAdornment>%</InputAdornment>,
              }}
              onChange={this.handleChangeProgress}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
            <Button onClick={this.handleClickSave} color="blue">{tr('save')}</Button>
          </Grid.Column>
          { objectiveId
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
                <Button onClick={handleClickCancelObjective}>{tr('back')}</Button>
              </Grid.Column>
            )
            : null }
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AddIndicator);

AddIndicator.defaultProps = {
  tr: () => {},
  changeClean: () => {},
  clean: false,
  handleGetObjectiveToAdd: null,
  objective: '',
  objectiveId: '',
  progress: '',
  handleUpdateObjective: null,
  handleClickCancelObjective: null,
};

AddIndicator.propTypes = {
  changeClean: PropTypes.func,
  clean: PropTypes.bool,
  handleClickCancelObjective: PropTypes.func,
  handleGetObjectiveToAdd: PropTypes.func,
  handleUpdateObjective: PropTypes.func,
  objective: PropTypes.string,
  objectiveId: PropTypes.number,
  progress: PropTypes.string,
  tr: PropTypes.func,
};
