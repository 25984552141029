export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};

export const sheetHeaders = {
  orderId: { header: 'Id orden', width: 80 },
  intelisisId: { header: 'Id Intelisis', width: 40 },
  customerId: { header: 'Id distribuidor', width: 20 },
  purchaseDate: { header: 'Fecha registro', width: 20 },
  email: { header: 'Correo', width: 40 },
  coupon: { header: 'Cupón', width: 30 },
  subtotal: { header: 'Subtotal', width: 30 },
  sku: { header: 'SKU', width: 20 },
  maker: { header: 'Fabricante', width: 20 },
};

export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
