import React from 'react';
import { Button } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import Help from './Help';

const showButtonStyle = {
  visibility: 'hidden',
  display: 'none',
};

const hiddenButtonStyle = {
  visibility: 'visible',
};

function ActionButtons(props) {
  const {
    activeStep, hasItems, loadFirstStep, t, hasShipping, loadSecondStep,
    buyCartError, hasCart, buyCartLoader, buyQuickCart, sendCartLoader, sendListToCart,
  } = props;
  return (
    <div>
      <Help />
      <Button.Group>
        <Button
          disabled={activeStep.shipping || !hasItems}
          onClick={loadFirstStep}
          content={t('wishList.buyNow.actions.backward')}
        />
        <Button
          disabled={hasShipping}
          style={activeStep.confirmOrder ? showButtonStyle : hiddenButtonStyle}
          onClick={loadSecondStep}
          content={t('wishList.buyNow.actions.forward')}
          color="blue"
        />
        <Button
          style={activeStep.confirmOrder && !buyCartError
            ? hiddenButtonStyle
            : showButtonStyle}
          disabled={!hasCart}
          loading={buyCartLoader}
          color="green"
          onClick={buyQuickCart}
          content={t('wishList.buyNow.actions.placeOrder')}
        />
        <Button
          style={activeStep.confirmOrder && buyCartError
            ? hiddenButtonStyle
            : showButtonStyle}
          loading={sendCartLoader}
          color="blue"
          onClick={sendListToCart}
          content={t('wishList.items.sendToCart')}
        />
      </Button.Group>
    </div>
  );
}

ActionButtons.propTypes = {
  buyCartLoader: PropTypes.bool.isRequired,
  sendCartLoader: PropTypes.bool.isRequired,
  buyCartError: PropTypes.bool.isRequired,
  hasCart: PropTypes.bool.isRequired,
  hasShipping: PropTypes.bool.isRequired,
  hasItems: PropTypes.bool.isRequired,
  activeStep: PropTypes.shape({
    shipping: PropTypes.bool.isRequired,
    confirmOrder: PropTypes.bool.isRequired,
  }).isRequired,
  loadFirstStep: PropTypes.func.isRequired,
  loadSecondStep: PropTypes.func.isRequired,
  sendListToCart: PropTypes.func.isRequired,
  buyQuickCart: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ActionButtons);
