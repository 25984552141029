import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '../../common/components/materials';

class ChatInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chatInput: '' };
    this.submitHandler = this.submitHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
  }

  submitHandler(event) {
    const { chatInput } = this.state;
    const { onSend } = this.props;
    event.preventDefault();
    this.setState({ chatInput: '' });
    onSend(chatInput);
  }

  textChangeHandler(event) {
    this.setState({ chatInput: event.target.value });
  }

  render() {
    const { chatInput } = this.state;
    return (
      <form className="chat-form" onSubmit={this.submitHandler}>
        <TextField
          fullWidth
          value={chatInput || ''}
          type="text"
          label="Escribe un mensaje aquí"
          onChange={this.textChangeHandler}
        />
      </form>
    );
  }
}

ChatInput.propTypes = {
  onSend: PropTypes.func,
};

ChatInput.defaultProps = {
  onSend: () => { },
};

export default ChatInput;
