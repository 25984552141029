import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid, List, Dropdown, Header, Checkbox, Input,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import validator from 'validator';
import * as session from '../../common/sessions';
import { currencyFormat } from '../../common/utils';

function OrderDetails(props) {
  const {
    t, endUserId, userName, creditAvailable, endUsers, endUserChange, buyToMe, handleBoolField, exchangeRate, hasAppleCare, purchaseOrder, purchaseOrderChange,
  } = props;
  const { customerId } = session.get();
  const customersToAddInput = [{ idCustomer: 'G103063' }];
  const foundCustomerToAddInput = customersToAddInput.filter(customer => customer.idCustomer === customerId);

  const onJustNumbers = event => {
    if (!validator.isNumeric(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
        <List>
          <List.Item>
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column mobile={16} tablet={3} computer={2} widescreen={1}>
                  <Header size="tiny">
                    {t('orderDetail.endUser')}
                    :
                    {' '}
                  </Header>
                </Grid.Column>
                <Grid.Column className="no-padding-right" mobile={8} tablet={8} computer={6} widescreen={4}>
                  <Dropdown
                    options={endUsers}
                    onChange={endUserChange}
                    disabled={buyToMe}
                    placeholder="Usuario final"
                    value={endUserId}
                    selection
                    search
                    fluid
                  />
                </Grid.Column>
                <Grid.Column className="no-padding-x" mobile={8} tablet={4} computer={4}>
                  {!hasAppleCare
                    ? (
                      <Checkbox
                        label={t('orderDetail.buyToMyself')}
                        onChange={() => handleBoolField('buyToMe', buyToMe)}
                        checked={buyToMe}
                        style={{ paddingLeft: '1rem' }}
                      />
                    )
                    : ''}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </List.Item>
          <List.Item>
            <Header style={{ display: 'inline' }} size="tiny">
              {`${t('orderDetail.applicant')}: `}
            </Header>
            <p style={{ display: 'inline' }}>{userName}</p>
          </List.Item>
          <List.Item>
            <Header style={{ display: 'inline' }} size="tiny">
              {`${t('orderDetail.creditAvailable')}: `}
            </Header>
            <p style={{ display: 'inline' }}>{creditAvailable ? t('orderDetail.hasCredit') : t('orderDetail.noCredit')}</p>
          </List.Item>
          <List.Item>
            <Header style={{ display: 'inline' }} size="tiny">
              {`${t('orderDetail.exchangeRate')}: `}
            </Header>
            <p style={{ display: 'inline' }}>{currencyFormat(exchangeRate.cash, 'MXN')}</p>
          </List.Item>
          <List.Item>
            <p style={{ display: 'inline' }}>
              {`${t('orderDetail.exchangeRateLegend')} `}
            </p>
          </List.Item>
          {
            foundCustomerToAddInput && foundCustomerToAddInput.length > 0 ? (
              <List.Item>
                <p style={{
                  display: 'inline',
                  fontWeight: 'bold',
                  fontFamily: 'Exo, sans-serif',
                  color: '#FFA100',
                }}
                >
                  {`${t('orderDetail.purchaseOrder')} `}
                </p>
                <Input
                  type="text"
                  required
                  maxLength={20}
                  value={purchaseOrder || ''}
                  onChange={purchaseOrderChange}
                  onKeyPress={onJustNumbers}
                />
              </List.Item>
            ) : ''
          }
        </List>
      </Grid.Column>
    </Grid.Row>
  );
}

OrderDetails.propTypes = {
  buyToMe: PropTypes.bool,
  creditAvailable: PropTypes.bool,
  endUserChange: PropTypes.func,
  endUserId: PropTypes.string,
  endUsers: PropTypes.arrayOf(PropTypes.shape({})),
  exchangeRate: PropTypes.shape({
    cash: PropTypes.number,
  }),
  handleBoolField: PropTypes.func,
  t: PropTypes.func,
  userName: PropTypes.string,
  hasAppleCare: PropTypes.bool,
  purchaseOrder: PropTypes.string,
  purchaseOrderChange: PropTypes.func,
};

OrderDetails.defaultProps = {
  endUserChange: () => {},
  exchangeRate: {
    cash: 0,
  },
  buyToMe: false,
  creditAvailable: false,
  endUserId: '',
  endUsers: [],
  handleBoolField: () => {},
  t: () => {},
  userName: '',
  hasAppleCare: false,
  purchaseOrder: '',
  purchaseOrderChange: () => {},
};

export default translate('common', { wait: true })(OrderDetails);
