import React from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';

import { Breadcrumb as BreadcrumbSemantic } from 'semantic-ui-react';

function isLastSection(index, sections) {
  return index === sections.length - 1;
}

function Breadcrumb({ sections, history }) {
  return (
    <BreadcrumbSemantic>
      <BreadcrumbSemantic.Section
        link={() => {
          history.push('/comercio');
        }}
      >
        Inicio
      </BreadcrumbSemantic.Section>
      {sections.length > 0 && (
        <BreadcrumbSemantic.Divider />
      )}
      {sections.length > 0 && sections.map(({ content, link, extra }, index) => (
        <span key={nanoid(10)}>
          <BreadcrumbSemantic.Section
            link={link}
            active={isLastSection(index, sections)}
          >
            {content}
            {extra && ` :${extra}`}
          </BreadcrumbSemantic.Section>
          {!isLastSection(index, sections) && (
            <BreadcrumbSemantic.Divider icon="right angle" />
          )}
        </span>
      ))}
    </BreadcrumbSemantic>
  );
}

Breadcrumb.defaultProps = {
  sections: [],
};

Breadcrumb.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    extra: PropTypes.string,
    link: PropTypes.bool,
  })),
};

export default Breadcrumb;
