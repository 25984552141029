import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Player, BigPlayButton } from 'video-react';
import {
  Grid, Image, Modal, Icon, Responsive, Card, Placeholder,
} from 'semantic-ui-react';
import '../../styles.css';
import '../../../../node_modules/video-react/dist/video-react.css';

class ImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      openModal: false,
      arrayMedia: props.arrayImages,
    };
    this.pause = this.pause.bind(this);
    this.play = this.play.bind(this);
    this.load = this.load.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { arrayImages } = this.props;
    if (prevProps.arrayImages !== arrayImages) {
      this.handleGetImages();
    }
  }

  goToNextSlide = index => {
    this.setState({ currentIndex: index });
  }

  handleGetImages = () => {
    const { arrayImages } = this.props;
    this.setState({ arrayMedia: arrayImages });
  }

  handleOnUpdate = (e, { width }) => {
    this.setState({ width });
  }

  mobileView = () => {
    const {
      currentIndex, openModal, arrayMedia, width,
    } = this.state;
    const { arrayImages, hasNotImages } = this.props;
    const currentImage = arrayMedia[currentIndex] || '';
    return (
      <Grid centered>
        <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate}>
          {arrayImages.length === 0 && !hasNotImages
            ? (
              <Grid.Column mobile={16} floated="left">
                <Placeholder>
                  <Placeholder.Image square />
                </Placeholder>
              </Grid.Column>
            ) : (
              <Grid.Column mobile={16} floated="left">
                {currentImage !== undefined && currentImage.medium.split('.') !== 'mp4'
                  ? (
                    <Image
                      src={currentImage.medium.replace('http://', 'https://')}
                      className="myImg"
                      size="big"
                      centered
                      wrapped
                      bordered
                      onClick={() => this.setState({ openModal: true })}
                    />
                  )
                  : (
                    <Player ref={player => { this.player = player; }}>
                      <source src={arrayMedia[currentIndex]} />
                      <BigPlayButton position="center" />
                    </Player>
                  )}
              </Grid.Column>
            )}
          <Grid.Column mobile={15} />
        </Responsive>
        <Grid.Row stretched>
          <Grid.Column widescreen={16} computer={16} tablet={16} mobile={16}>
            <Card.Group itemsPerRow={width < 425 ? 3 : 7}>
              {arrayMedia.map((image, index) => (
                <Card key={image.thumbnail} fluid>
                  {image.low !== 'mp4' ? (
                    <Image src={image.low} onMouseOver={() => this.goToNextSlide(index)} size="tiny" bordered className="myImg" fluid />
                  ) : null}
                  {image.low === 'mp4' ? (
                    <div
                      role="presentation"
                      onClick={() => {
                        this.load();
                        this.setState({ openModal: true });
                        this.goToNextSlide(index);
                      }}
                    >
                      <Player ref={player => { this.player = player; }}>
                        <source src={image} />
                        <BigPlayButton position="center" />
                      </Player>
                    </div>
                  ) : null}
                </Card>
              ))}
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal open={openModal} closeOnDimmerClick size="large" className="modal">
            <Icon name="window close" onClick={() => { this.setState({ openModal: false }); this.goToNextSlide(0); }} />
            <Modal.Content className="modal-content">
              {currentImage !== undefined && currentImage.medium.split('.') !== 'mp4'
                ? (
                  <Image
                    src={currentImage.medium.replace('http://', 'https://')}
                    className="myImg"
                    size="big"
                    centered
                    wrapped
                    bordered
                    onClick={() => this.setState({ openModal: true })}
                  />
                )
                : (
                  <Player ref={player => { this.player = player; }}>
                    <source src={arrayMedia[currentIndex]} />
                    <BigPlayButton position="center" />
                  </Player>
                )}
            </Modal.Content>
            <Modal.Actions>
              <Grid.Row stretched>
                <Grid.Column widescreen={16} computer={16} tablet={16} mobile={16}>
                  <Card.Group itemsPerRow={width < 425 ? 3 : 4}>
                    {arrayMedia.map((image, index) => (
                      <Card key={image} fluid>
                        {image.low.split('.') !== 'mp4' ? (
                          <Image src={image.low} onMouseOver={() => this.goToNextSlide(index)} size="tiny" bordered className="myImg" fluid />
                        ) : null}
                        {image.low.split('.') === 'mp4' ? (
                          <div
                            role="presentation"
                            onClick={() => {
                              this.load();
                              this.setState({ openModal: true });
                              this.goToNextSlide(index);
                            }}
                          >
                            <Player ref={player => { this.player = player; }} muted paused>
                              <source src={image} />
                              <BigPlayButton position="center" />
                            </Player>
                          </div>
                        ) : null}
                      </Card>
                    ))}
                  </Card.Group>
                </Grid.Column>
              </Grid.Row>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  load() {
    this.player.load();
  }

  render() {
    return (
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
          {
            this.mobileView()
          }
        </Grid.Column>
      </Grid.Row>
    );
  }
}
ImageComponent.propTypes = {
  arrayImages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  hasNotImages: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(ImageComponent);
