import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Header, Grid, Button, Dropdown, Confirm, Checkbox,
} from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';
import { TextField } from '../../common/components/materials';

const Conditions = ({
  t, optionsMaker, maker, handleChangeMaker, minimumAmount, handleChangeMinimumAmount, handleChangeCondition, arrayTypes,
  condition, ruleId, arrayValues, conditionValue, type, optionCurrency, currency, handleChangeValue,
  fieldError, valueError, loadingField, hanldeAddField, hanldeUpdateField, handleCancelUpdateField, handleChecked, limit,
  handleChangeLimit, arrayConditions, nameHeaders, handleCancelDelete, handleConfirmDelete, open,
}) => (
  <Grid container>
    {type !== 4
      ? (
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4">{t('discounts.applyOn')}</Header>
          </Grid.Column>
        </Grid.Row>
      ) : null}
    {type !== 4
      ? (
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
            {t('discounts.maker')}
            <Dropdown
              placeholder={t('discounts.maker')}
              options={optionsMaker}
              value={maker.value}
              error={maker.error}
              onChange={handleChangeMaker}
              fluid
              selection
              search
              clearable
            />
          </Grid.Column>
        </Grid.Row>
      )
      : null}
    {type === 4 || type === 5
      ? (
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={10}>
            <TextField
              fullWidth
              maxLength={15}
              value={minimumAmount.value || ''}
              error={minimumAmount.error || ''}
              label={t('Monto mínimo de compra')}
              onChange={handleChangeMinimumAmount}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={3} mobile={5}>
            <Dropdown
              placeholder={t('discounts.currency')}
              options={optionCurrency}
              value={currency}
              disabled
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      )
      : null}
    {type !== 3 && type !== 4 && type !== 5
      ? (
        <Grid.Row colums={3}>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
            <Dropdown
              placeholder={t('discounts.type')}
              onChange={handleChangeCondition}
              options={arrayTypes}
              value={condition.value}
              error={condition.error}
              additionPosition="bottom"
              fluid
              selection
              searchallowAdd
              disabled={ruleId}
            />
            <p style={{ color: 'red' }}>{fieldError}</p>
          </Grid.Column>
          {(arrayValues && arrayValues.length > 0 && type !== 5)
            ? (
              <Grid.Column largeScreen={4} computer={4} tablet={6} mobile={16}>
                <Dropdown
                  options={arrayValues}
                  placeholder={t('discounts.value')}
                  value={conditionValue.value}
                  error={conditionValue.error}
                  onChange={handleChangeValue}
                  search
                  selection
                  fluid
                />
                <p style={{ color: 'red' }}>{valueError}</p>
              </Grid.Column>
            )
            : null}
          {!ruleId
            ? (
              <Grid.Column largeScreen={2} computer={3} tablet={3} mobile={9}>
                <Button size="large" loading={loadingField} disabled={loadingField} onClick={hanldeAddField}>{t('add')}</Button>
              </Grid.Column>
            )
            : null}
          {ruleId
            ? (
              <Grid.Column largeScreen={2} computer={3} tablet={3} mobile={9}>
                <Button size="large" loading={loadingField} disabled={loadingField} onClick={hanldeUpdateField}>{t('update')}</Button>
              </Grid.Column>
            )
            : null}
          {ruleId
            ? (
              <Grid.Column largeScreen={2} computer={3} tablet={3} mobile={8}>
                <Button size="large" loading={loadingField} disabled={loadingField} onClick={handleCancelUpdateField}>{t('cancel')}</Button>
              </Grid.Column>
            )
            : null}
        </Grid.Row>
      )
      : null}
    {type !== 3
      ? (
        <Grid.Row>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={16}>
            <Checkbox onClick={handleChecked} checked={limit.checked} label={t('discounts.limit')} styleType="info" />
          </Grid.Column>
          {limit.checked
            ? (
              <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={4}
                  value={limit.value || ''}
                  error={limit.error || ''}
                  label={t('discounts.avaible')}
                  onChange={handleChangeLimit}
                  disabled={!limit.checked}
                  required
                />
              </Grid.Column>
            )
            : null}
        </Grid.Row>
      )
      : null}
    {type !== 3 && type !== 4 && type !== 5
      ? (
        <Grid.Row style={{ overflowX: 'auto' }}>
          <SiclikTable
            selectable
            data={arrayConditions}
            headers={nameHeaders}
            pageSize={5}
            singleSelection
          />
        </Grid.Row>
      )
      : null}
    <Grid.Row>
      <Confirm
        open={open}
        header={t('discounts.confirmFieldDelete')}
        content={condition.text}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </Grid.Row>
  </Grid>
);

Conditions.defaultProps = {
  limit: { value: '', error: '' },
  condition: {
    value: '', error: '', text: '', allowAdd: false,
  },
  conditionValue: { value: '', error: '' },
  arrayTypes: [],
  arrayValues: [],
  arrayConditions: [],
  nameHeaders: [],
  checked: false,
  open: false,
  handleChangeCondition: null,
  handleChangeValue: null,
  hanldeAddField: null,
  handleChecked: null,
  handleChangeLimit: null,
  handleCancelDelete: null,
  handleConfirmDelete: null,
  hanldeUpdateField: null,
};

Conditions.propTypes = {
  arrayConditions: PropTypes.arrayOf(),
  arrayTypes: PropTypes.arrayOf(),
  arrayValues: PropTypes.arrayOf(),
  checked: PropTypes.bool,
  condition: PropTypes.shape({
    allowAdd: PropTypes.bool,
    error: PropTypes.string,
    text: PropTypes.string,
    value: PropTypes.string,
  }),
  conditionValue: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  currency: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  fieldError: PropTypes.string.isRequired,
  handleCancelDelete: PropTypes.func,
  handleCancelUpdateField: PropTypes.func.isRequired,
  handleChangeCondition: PropTypes.func,
  handleChangeLimit: PropTypes.func,
  handleChangeMaker: PropTypes.func.isRequired,
  handleChangeMinimumAmount: PropTypes.func.isRequired,
  handleChangeValue: PropTypes.func,
  handleChecked: PropTypes.func,
  handleConfirmDelete: PropTypes.func,
  hanldeAddField: PropTypes.func,
  hanldeUpdateField: PropTypes.func,
  limit: PropTypes.shape({
    checked: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  loadingField: PropTypes.bool.isRequired,
  maker: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  minimumAmount: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  nameHeaders: PropTypes.arrayOf(),
  open: PropTypes.bool,
  optionCurrency: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  optionsMaker: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ruleId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  valueError: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(Conditions);
