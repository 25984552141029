import React from 'react';
import { Confirm } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

function DeleteList(props) {
  const {
    t, open, onClose, deleteWishList,
  } = props;

  const handleConfirm = () => {
    onClose();
    deleteWishList();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Confirm
      open={open}
      cancelButton={t('cancel')}
      confirmButton={t('continue')}
      content={t('wishList.warningDelete')}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );
}

DeleteList.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteWishList: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(DeleteList);
