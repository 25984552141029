import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const discount = {};

discount.getAllListApply = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

discount.getAllMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

discount.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

discount.getGroups = () => (
  request({
    route: apiRoutes.getCustomersGroups(),
    method: 'GET',
  })
);

discount.getDiscountById = discountId => (
  request({
    route: apiRoutes.getDiscountById(discountId),
    method: 'GET',
  })
);

discount.updateDiscount = body => (
  request({
    route: apiRoutes.updateDiscount(),
    method: 'PUT',
    body,
  })
);

discount.addDiscount = body => (
  request({
    route: apiRoutes.addDiscount(),
    method: 'POST',
    body,
  })
);

discount.getDiscountDetail = id => (
  request({
    route: apiRoutes.getDiscountDetail(id),
    method: 'GET',
  })
);

discount.updateDiscountDetail = body => (
  request({
    route: apiRoutes.updateDiscountDetail(),
    method: 'PUT',
    body,
  })
);

discount.addDiscountDetail = body => (
  request({
    route: apiRoutes.addDiscountDetail(),
    method: 'POST',
    body,
  })
);

discount.getBehaviorTypes = () => (
  request({
    route: apiRoutes.getBehaviorTypesDiscounts(),
    method: 'GET',
  })
);

discount.getDiscountTypes = () => (
  request({
    route: apiRoutes.getTypesDiscounts(),
    method: 'GET',
  })
);

discount.getDiscountDays = () => (
  request({
    route: apiRoutes.getDaysDiscounts(),
    method: 'GET',
  })
);

discount.getCustomersWithCredit = () => (
  request({
    route: apiRoutes.getCustomersWithCredit(),
    method: 'GET',
  })
);

discount.getGroupsItems = type => (
  request({
    route: apiRoutes.getGroups(type),
    method: 'GET',
  })
);

discount.getUsersCoupons = () => (
  request({
    route: apiRoutes.getUsersCoupons(),
    method: 'GET',
  })
);

export default discount;
