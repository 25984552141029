import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Header } from 'semantic-ui-react';

function MainHeader({ title, colorText, idName }) {
  const borderHeader = {
    borderBottom: '5px solid',
  };
  return (
    <Grid.Row id={idName} centered stretched className="general-top-padding general-padding-children">
      <Grid.Column
        largeScreen={5}
        computer={6}
        tablet={0}
        mobile={0}
        textAlign="center"
        style={borderHeader}
        className={colorText}
      />
      <Grid.Column
        largeScreen={6}
        computer={4}
        tablet={15}
        mobile={15}
        textAlign="center"
        className="padding-main-header"
      >
        <Header
          as="h3"
          color="black"
          content={title}
        />
      </Grid.Column>
      <Grid.Column
        largeScreen={5}
        computer={6}
        tablet={0}
        mobile={0}
        textAlign="center"
        style={borderHeader}
        className={colorText}
      />
    </Grid.Row>
  );
}

MainHeader.propTypes = {
  title: PropTypes.string,
  colorText: PropTypes.string,
  idName: PropTypes.string.isRequired,
};

MainHeader.defaultProps = {
  title: '',
  colorText: 'blueText',
};

export default MainHeader;
