import React, { Component } from 'react';
import {
  Grid, Responsive, Dimmer, Loader, Header, Segment, Checkbox, Button, Confirm,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FinalUser from './components/final-user';
import Payment from './components/payment';
import LeaseOptions from './components/lease-options';
import Orders from './components/orders';
import quoteServices from './services';
import { currencyFormat } from '../common/utils';
import Toast from '../common/components/toast';
import './styless.css';

const CURRENCY_OPTIONS = [
  { key: 1, text: 'MXN', value: 1 },
  { key: 2, text: 'USD', value: 2 },
];

class LeaseQuotation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: '',
      width: 0,
      centeredProperty: '',
      quoteData: {},
      checkoutData: [],
      paymentMethods: [],
      paymentMethodSelected: '',
      checkoutLoader: true,
      quoteDataLoader: true,
      currentCurrency: 1,
      currencyOptions: [],
      ordersLoader: true,
      confirmLeaseType: false,
      leaseOptionSelected: '',
      leaseOptionsHeaders: [
        { key: 'id', isKey: true },
        { key: 'LeaseTerm', name: 'Duración', format: '' },
        { key: 'PaymentFrequency', name: 'Frecuencia de pago', format: (cell, row) => (row.PaymentFrequency === 'MON' ? 'Mensual' : 'Cuatrimestral') },
        {
          key: 'Amount',
          name: 'Cantidad a pagar',
          format: (cell, row) => {
            const { currentCurrency } = this.state;
            return (currentCurrency === 1 ? `$ ${row.Amount} MXN` : `$ ${row.Amount} USD`);
          },
        },
        {
          name: 'Seleccionar',
          format: (cell, row) => {
            const { leaseOptionSelected } = this.state;
            return (
              <Checkbox
                radio
                name="leaseSelection"
                value={row.id}
                checked={leaseOptionSelected === row.id}
                onChange={this.handleRadioSelection}
              />
            );
          },
        },
      ],
      ordersData: [],
      ordersHeaders: [
        { key: 'id', isKey: true },
        { key: 'sku', name: 'SKU' },
        { key: 'maker', name: 'Fabricante' },
        { key: 'name', name: 'Nombre' },
        { key: 'quantity', name: 'Cantidad' },
        {
          key: 'price',
          name: 'Precio',
          format: cell => {
            const { currency } = this.state;
            return (currencyFormat(cell, currency));
          },
        },
        {
          key: 'finalPrice',
          name: 'Precio final unidad',
          format: cell => {
            const { currency } = this.state;
            return (currencyFormat(cell, currency));
          },
        },
        { key: 'coupons', name: 'Cupones', format: cell => (cell.length === 0 ? '' : this.handleRenderCouponDetails(cell)) },
      ],
    };
  }

  componentDidMount() {
    this.getCurrencyOptions();
    this.getLeaseQuote();
  }

  getQuoteData = () => (
    quoteServices.getLeaseQuote()
  )

  getCheckout = body => (
    quoteServices.checkout(body)
  )

  getPaymentMethods = () => (
    quoteServices.getPaymentMethods()
      .then(res => this.givePaymentMethodsFormat(res))
  )

  getCurrencyOptions = () => {
    this.setState({ currencyOptions: CURRENCY_OPTIONS });
  }

  getLeaseQuote = async () => {
    const { history } = this.props;
    try {
      const checkoutData = await this.getCheckout();
      const quoteData = await this.getQuoteData();
      const paymentMethods = await this.getPaymentMethods();
      const paymentMethodSelected = checkoutData.paymentId;
      const ordersData = checkoutData.orders;
      const orderId = checkoutData.id;
      this.setState({
        orderId,
        quoteData,
        checkoutData,
        paymentMethods,
        paymentMethodSelected,
        ordersData,
        checkoutLoader: false,
        quoteDataLoader: false,
        ordersLoader: false,
      });
    } catch (error) {
      this.showError(error);
      history.push('/carrito');
    }
  }

  showError = error => {
    const { t } = this.props;
    let messageError = '';
    if (!error.code) messageError = t('error-codes.default');
    else messageError = error.message;
    Toast(messageError, 'error');
  }

  saveLeaseQuote = body => (
    quoteServices.saveQuote(body)
  )

  handlePriceReload = async (currency, paymentMethod) => {
    this.setState({ ordersLoader: true, quoteDataLoader: true });
    const paymentConfig = {
      currencyId: currency,
      paymentId: paymentMethod,
      cardBrand: '',
    };
    try {
      const checkoutData = await this.getCheckout(paymentConfig);
      this.setState({
        checkoutData,
        ordersData: checkoutData.orders,
        currentCurrency: currency,
        currency: currency === 2 ? 'USD' : 'MXN',
        ordersLoader: false,
        quoteDataLoader: false,
      });
    } catch (error) {
      this.showError(error);
    }
  }

  handleRadioSelection = (e, selection) => {
    this.setState({ leaseOptionSelected: selection.value });
  }

  givePaymentMethodsFormat = ({ paymentMethods }) => {
    const paymentMethodOptions = paymentMethods.map(options => ({
      key: options.id,
      text: options.name,
      value: options.id,
    }));
    return paymentMethodOptions;
  }

  handleOnUpdate = (e, { width }) => {
    if (width > 768) this.setState({ width, centeredProperty: 'centered' });
    else this.setState({ width, centeredProperty: '' });
  }

  handleCurrencyChange = (e, { value }) => {
    const { currentPaymentMethod } = this.state;
    this.setState({ currentCurrency: value });
    this.handlePriceReload(value, currentPaymentMethod);
  }

  handleApplyLease = async () => {
    const { t, history, updateCartCount } = this.props;
    this.setState({ checkoutLoader: true });
    const { leaseOptionSelected, quoteData, orderId } = this.state;
    if (!leaseOptionSelected) {
      this.setState({ checkoutLoader: false });
      Toast(t('lease-quote.lease-no-selected'), 'error');
    } else {
      const {
        hardwareAmount, printerAmount, servicesAmount, softwareAmount, LeasePaymentOptions, requestId,
      } = quoteData;
      const values = LeasePaymentOptions.filter(option => option.id === leaseOptionSelected)[0];
      const {
        LeaseTerm: leaseTerm, LeaseType: leaseType, PaymentFrequency: paymentFrecuency, Amount: paymentAmount,
      } = values;
      const options = {
        leaseTerm,
        leaseType,
        paymentFrecuency,
        hardwareAmount,
        printerAmount,
        softwareAmount,
        servicesAmount,
        paymentAmount,
        requestId,
        orderId,
      };
      try {
        await this.saveLeaseQuote(options)
          .then(response => {
            if (response) history.push('/cotizaciones');
          }).then(() => updateCartCount());
      } catch (error) {
        this.showError(error);
        this.setState({ checkoutLoader: false });
      }
    }
  }

  handleConfirmLeaseType = () => {
    const { t } = this.props;
    const { leaseOptionSelected } = this.state;
    if (!leaseOptionSelected) {
      Toast(t('lease-quote.lease-no-selected'), 'error');
    } else {
      this.setState({ confirmLeaseType: true });
    }
  }

  handleCancelLeaseType = () => {
    this.setState({ confirmLeaseType: false });
  }

  render() {
    let values = {};
    const { t } = this.props;
    const {
      quoteData, quoteDataLoader, checkoutData, paymentMethods, paymentMethodSelected,
      currentCurrency, checkoutLoader, currencyOptions, leaseOptionsHeaders, ordersData, ordersHeaders,
      ordersLoader, width, centeredProperty, confirmLeaseType, leaseOptionSelected,
    } = this.state;
    if (leaseOptionSelected) {
      [values] = quoteData.LeasePaymentOptions.filter(option => option.id === leaseOptionSelected);
    }
    return (
      <Grid container className="lease-quote-container">
        <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate} />
        <Grid.Row>
          <Header as="h2" color="blue">{t('lease-quote.lease-title')}</Header>
        </Grid.Row>
        {checkoutLoader === true
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
                <Dimmer active inverted>
                  <Loader active size="big" />
                </Dimmer>
              </Grid.Column>
            </Grid.Row>
          )
          : (
            <Grid className="lease-quote-container">
              <Grid.Row centered>
                <Grid.Column largeScreen={11} computer={11} tablet={8} mobile={16}>
                  <FinalUser
                    checkoutData={[checkoutData]}
                    paymentMethods={paymentMethods}
                    currencyOptions={currencyOptions}
                    handleCurrencyChange={this.handleCurrencyChange}
                    currentCurrency={currentCurrency}
                    currentPaymentMethod={paymentMethodSelected}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={5} computer={5} tablet={8} mobile={16}>
                  <Payment checkoutData={[checkoutData]} currency={currentCurrency} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={8} mobile={8}>
                  <Responsive maxWidth={425}>
                    <br />
                  </Responsive>
                  <Header as="h3" color="blue">{t('lease-quote.lease-orders-title')}</Header>
                </Grid.Column>
              </Grid.Row>
              {ordersLoader
                ? (
                  <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16} textAlign="justified">
                      <Dimmer active inverted>
                        <Loader active size="big" />
                      </Dimmer>
                    </Grid.Column>
                  </Grid.Row>
                )
                : (
                  <Grid.Row>
                    <Grid.Column largeScreen={16} computer={16} tablet={15} mobile={8}>
                      <Segment placeholder style={{ overflowX: 'auto' }}>
                        <Orders ordersHeaders={ordersHeaders} ordersData={ordersData} currency={currentCurrency} />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                )}
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={8} mobile={8}>
                  <Responsive maxWidth={425}>
                    <br />
                  </Responsive>
                  <Header as="h3" color="blue">{t('lease-quote.lease-options')}</Header>
                </Grid.Column>
              </Grid.Row>
              {quoteDataLoader
                ? (
                  <Grid.Row>
                    <Grid.Column computer={16} tablet={16} mobile={16} textAlign="justified">
                      <Dimmer active inverted>
                        <Loader active size="big" />
                      </Dimmer>
                    </Grid.Column>
                  </Grid.Row>
                )
                : (
                  <Grid.Row>
                    <Grid.Column largeScreen={16} computer={16} tablet={15} mobile={8}>
                      <Segment placeholder style={{ overflowX: 'auto' }}>
                        <LeaseOptions leaseOptionsHeaders={leaseOptionsHeaders} data={quoteData.LeasePaymentOptions} />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                )}
              <Grid.Row centered={centeredProperty} columns={2} className="lease-quote-margin-bottom">
                <Grid.Column largeScreen={6} computer={6} tablet={15} mobile={width < 768 && width > 425 ? 5 : 8}>
                  <Button size="medium" fluid color="gray" onClick={this.handleBack}>
                    <span>{t('lease-quote.back')}</span>
                  </Button>
                  <Responsive maxWidth={768}>
                    <br />
                  </Responsive>
                </Grid.Column>
                <Responsive as={Grid.Column} minWidth={Responsive.onlyMobile.minWidth} mobile={8} maxWidth={425} />
                <Responsive as={Grid.Column} minWidth={Responsive.onlyTablet.minWidth} tablet={8} maxWidth={768} />
                <Grid.Column largeScreen={6} computer={6} tablet={15} mobile={width < 768 && width > 425 ? 5 : 8}>
                  <Button size="medium" fluid color="green" id="idPayment" onClick={this.handleConfirmLeaseType}>
                    <span>{t('lease-quote.apply')}</span>
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        <Grid.Row>
          <Confirm
            open={confirmLeaseType}
            header={t('checkout.confirmPayment')}
            content={`${t('lease-quote.confirm')} Duración: ${values.LeaseTerm}, Frecuencia de pago ${values.PaymentFrequency === 'MON' ? 'Mensual' : 'Cuatrimestral'}, Monto: ${values.Amount}?`}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancelLeaseType}
            onConfirm={this.handleApplyLease}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

LeaseQuotation.defaultProps = {
  updateCartCount: () => {},
  history: [],
  t: () => {},
};

LeaseQuotation.propTypes = {
  updateCartCount: PropTypes.func,
  history: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
};

export default LeaseQuotation;
