import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Icon, Label, Popup, List,
} from 'semantic-ui-react';
import services from './services';
import './styles.css';

function Notification(props) {
  const { iconName } = props;
  const { location: { pathname } } = window;
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setCount] = useState(0);

  useEffect(() => {
    services.getNotificationsCount()
      .then(({ count }) => {
        setCount(count);
      });
  }, [pathname]);

  const handleOpen = () => {
    services.getNotifications()
      .then(response => {
        setNotifications(response.notifications);
        setOpen(true);
        services.readNotifications();
      });
  };

  const handleClose = () => {
    services.getNotificationsCount()
      .then(({ count }) => {
        setCount(count);
      });
    setOpen(false);
  };

  return (
    <Popup
      content={(
        <List selection divided verticalAlign="middle">
          {notifications.map(notification => (
            <List.Item key={notification.id} as="a" className={notification.read ? '' : 'unreadNotification'} style={{ padding: '1rem' }} href={notification.link}>
              <List.Content>
                <List.Header>{notification.title}</List.Header>
                {notification.message}
              </List.Content>
            </List.Item>
          ))}
        </List>
      )}
      // position="bottom right"
      on="click"
      onClose={handleClose}
      onOpen={handleOpen}
      openOnTriggerClick
      style={{ padding: 0 }}
      open={open}
      trigger={(
        <div className="notificationIconContainer">
          <Icon name={iconName} size="big" link />
          <Label className="notificationNumber" circular color="blue">
            {notificationsCount}
          </Label>
        </div>
      )}
    />
  );
}

Notification.propTypes = {
  iconName: PropTypes.string,
};

Notification.defaultProps = {
  iconName: 'bell',
};

export default Notification;
