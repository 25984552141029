/* eslint-disable import/prefer-default-export */
import securityService from '@compusoluciones-pdigitales/siclik-security';
import * as session from './sessions';

export const hasAccess = roles => {
  const { access } = session.get();
  if (!roles || roles.length === 0) return true;
  const accessGranted = roles.some(role => (securityService.validate(access, role)));
  return accessGranted;
};
