import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import SearchBySku from '../../common/components/searchBySku';

function AddItem(props) {
  const {
    t, addNewItem, onClose, open,
  } = props;
  const [item, setItem] = useState('');
  const [loader, setloader] = useState(false);

  const handleAddItem = async () => {
    setloader(true);
    try {
      await addNewItem(item);
      setItem('');
      setloader(false);
    } catch (error) {
      setloader(false);
    }
  };

  const handleSearch = sku => {
    setItem(sku.value);
  };

  const handleOnClose = () => {
    setItem('');
    onClose();
  };

  return (
    <Modal size="tiny" open={open}>
      <Modal.Header>
        {' '}
        {t('wishList.items.modal.header')}
        {' '}
      </Modal.Header>
      <Modal.Content>
        {t('wishList.items.modal.description')}
        <SearchBySku
          search={handleSearch}
          label={t('wishList.items.modal.label')}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleOnClose}>
          {' '}
          {t('close')}
          {' '}
        </Button>
        <Button color="blue" onClick={handleAddItem} loading={loader} disabled={item === ''}>
          {t('add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

AddItem.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addNewItem: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(AddItem);
