import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Header, Grid, Icon, Button, Confirm, Divider, Label, Dimmer, Loader,
} from 'semantic-ui-react';
import SiclikTable from '../common/components/table';
import MembersOptions from './components/members-options';
import groupsService from './services';
import Toast from '../common/components/toast';
import { TextField } from '../common/components/materials';

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        { key: 'id', isKey: true },
        { key: 'name', name: 'Nombre' },
        { key: 'description', name: 'Descripción' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (<Grid.Row><Button icon="trash alternate" onClick={() => this.handleClickDelete(row)} /></Grid.Row>),
        },
      ],
      headersOptions: [],
      arrayOptions: [],
      arrayOptionsFiltered: [],
      openAdd: false,
      arrayOptionsSelected: [],
      filter: '',
      filterError: '',
      arrayMembers: [],
      arrayMembersFiltered: [],
      filterMembers: '',
      filterMembersError: '',
      member: {},
      loading: false,
    };
  }

  componentDidMount = () => {
    this.handleValidateGroupType();
  }

  handleValidateGroupType = () => {
    const { type, id } = this.props;

    if (Number(type) === 2) {
      this.handleGetArticles();
      if (id && id > 0) this.handleGetMembers();
    }
    if (Number(type) === 1) {
      this.handleGetClients();
      if (id && id > 0) this.handleGetMembers();
    }
  }

  handleGetMembers = () => {
    const { t, id, type } = this.props;
    this.setState({ loading: true });
    groupsService.getMembersGroupsById(id, type)
      .then(response => {
        this.setState({ arrayMembers: response.elementByGroup, arrayMembersFiltered: response.elementByGroup, loading: false });
        this.handleValidateExistMembers(response.elementByGroup);
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code !== 1000 || error.code !== 1005) Toast(messageError, 'error');
        this.setState({ loading: false });
      });
  }

  handleGetClients = () => {
    const { t } = this.props;

    const customersHeaders = [
      { key: 'id', isKey: true },
      { key: 'id', name: 'Número de cliente' },
      { key: 'name', name: 'Cliente' },
    ];

    groupsService.getCustomers().then(response => {
      response.customers.map(currentCustomer => {
        const customer = { ...currentCustomer };
        customer.description = `${customer.tradingName} - ${customer.id}`;
        return customer;
      });
      this.setState({
        arrayOptions: response.customers,
        arrayOptionsFiltered: response.customers,
        headersOptions: customersHeaders,
        dividerGroup: t('groups.groupMembers'),
      });
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
        this.setState({
          dividerGroup: t('groups.groupMembers'),
        });
      });
  }

  handleGetArticles = () => {
    const { t } = this.props;

    const articlesHeaders = [
      { key: 'sku', isKey: true },
      { key: 'sku', name: 'SKU' },
      { key: 'name', name: 'Nombre' },
      { key: 'maker', name: 'Marca' },
      { key: 'branch', name: 'Rama' },
    ];

    groupsService.getAllListApply().then(response => {
      response.product.map(currentArticle => {
        const article = { ...currentArticle };
        article.description = `${article.name}`;
        article.name = article.sku;
        article.id = article.sku;
        return article;
      });
      this.setState({
        arrayOptions: response.product,
        arrayOptionsFiltered: response.product,
        headersOptions: articlesHeaders,
        dividerGroup: t('groups.groupArt'),
      });
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleFilterMembersChange = event => {
    const { t, type } = this.props;
    const { arrayOptions } = this.state;
    const maxLength = 30;
    let mistakes;
    const { value: filterMembers } = event.target;
    if ((filterMembers.trim()).length > maxLength) {
      this.setState({ filterMembers, filterError: t('groups.errors.toLong') });
      mistakes = true;
      return;
    }

    if (mistakes !== true) {
      this.setState({ filterMembers, filterMembersError: '', arrayOptions });
      if (type === 1) {
        if (filterMembers && filterMembers.length > 0) this.handleFilterByClient(filterMembers, mistakes, arrayOptions);
      } else if (filterMembers && filterMembers.length > 0) this.handleFilterByArticle(filterMembers, mistakes, arrayOptions);
    }
  }

  handleFilterOptionsChange = event => {
    const { t } = this.props;
    const { arrayMembers } = this.state;
    const maxLength = 30;
    let mistakes;
    const { value: filter } = event.target;
    if ((filter.trim()).length > maxLength) {
      this.setState({ filterError: t('groups.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) {
      this.setState({ filter, filterError: '', arrayMembers });
      this.handleFilterByMember(filter, mistakes, arrayMembers);
    }
  }

  handleFilterByMember = (filter, mistakes, arrayMembers) => {
    let { arrayMembersFiltered } = this.state;
    if (mistakes !== true) {
      arrayMembersFiltered = arrayMembers.filter(filterInfo => filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || filterInfo.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
      this.setState({ arrayMembersFiltered });
    }
  }

  handleFilterByArticle = (filter, mistakes, arrayOptions) => {
    let { arrayOptionsFiltered } = this.state;
    if (mistakes !== true && filter && filter.length > 0) {
      arrayOptionsFiltered = arrayOptions.filter(filterInfo => filterInfo.sku.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || filterInfo.branch.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.maker.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
      this.setState({ arrayOptionsFiltered });
    }
  }

  handleFilterByClient = (filter, mistakes, arrayOptions) => {
    let { arrayOptionsFiltered } = this.state;
    if (mistakes !== true) {
      arrayOptionsFiltered = arrayOptions.filter(filterInfo => filterInfo.id.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      || filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
      this.setState({ arrayOptionsFiltered });
    }
  }

  handleGetSelectedOptions = arrayOptions => this.setState({ arrayOptionsSelected: arrayOptions.rows });

  handleAddMembers = () => {
    const { id, type, t } = this.props;
    const { arrayMembers } = this.state;
    const { arrayOptions, arrayOptionsSelected } = this.state;
    const elementsToFail = [];
    if (arrayOptionsSelected.length > 0) {
      arrayOptionsSelected.map(element => {
        const dataElement = { type, idGroup: `${id}`, idElement: type === 1 ? element.id : element.sku };
        groupsService.addElementsToGroup(dataElement)
          .then(response => {
            arrayMembers.push({ id: response.id, name: type === 1 ? element.id : element.sku, description: element.name });
            arrayOptions.map((member, index) => {
              if (element.id === member.id) arrayOptions.splice(index, 1);
              return member;
            });
            this.setState({
              arrayOptions, arrayMembers, clean: true, arrayOptionsSelected: [],
            });
            this.handleFilterByMember('', false, arrayMembers);
            this.handleFilterByArticle('', false, arrayOptions);
          }).catch(() => {
            elementsToFail.push(element.name);
          });
        return element;
      });
      if (!elementsToFail || elementsToFail.length <= 0) {
        this.setState({ openAdd: false });
        Toast(t('groups.successMembers'), 'success');
      } else {
        Toast(`${t('groups.errorAddMembers')} ${elementsToFail} ,${t('groups.errorAddMembersComplement')}`, 'error');
      }
    } else {
      Toast(t('groups.chooseElements'), 'warning');
    }
  }

  handleClickDelete = member => this.setState({ member, open: true });

  handleConfirmDelete = () => {
    const { t, type } = this.props;
    const { member, arrayMembers, arrayOptions } = this.state;

    groupsService.deleteElementOfGroup(type, member.id).then(response => {
      if (response) {
        arrayMembers.map((memberElement, index) => {
          if (memberElement.id === member.id) arrayMembers.splice(index, 1);
          arrayOptions.push(memberElement);
          return memberElement;
        });
        this.handleFilterByMember('', false, arrayMembers);
        this.handleFilterByArticle('', false, arrayOptions);
        this.setState({ arrayMembers, arrayOptions, open: false });
        Toast(t('groups.successDeleteMember'), 'success');
      }
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleValidateExistMembers = arrayMembers => {
    const { arrayOptions } = this.state;
    if (arrayMembers && arrayMembers.length > 0) {
      arrayMembers.map(data => {
        arrayOptions.map((optionMember, index) => {
          if (optionMember.id === data.name) arrayOptions.splice(index, 1);
          return optionMember;
        });
        return arrayMembers;
      });
      this.setState({ arrayOptions });
    }
  }

  render() {
    const {
      t, id, groupName, error, handleChangeName, description, descriptionError,
      handleChangeDescription, onCloseClick, onSaveClick,
    } = this.props;
    const {
      filter, filterError, arrayMembersFiltered, headers, headersOptions, arrayOptionsFiltered, openAdd, arrayOptions,
      filterMembers, filterMembersError, open, member, dividerGroup, arrayMembers, clean, loading,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row centered>
          <Grid.Column>
            <Header as="h4" color="black" className="titles-news-gothic">{t('groups.group')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={5} computer={5} tablet={7} mobile={16}>
            <TextField
              fullWidth
              label={t('groups.groupName')}
              value={groupName || ''}
              error={error || ''}
              onChange={handleChangeName}
              required
            />
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={8} tablet={7} mobile={16}>
            <TextField
              fullWidth
              label={t('groups.groupDescription')}
              value={description || ''}
              error={descriptionError || ''}
              onChange={handleChangeDescription}
              maxLength={30}
              required
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={7} mobile={13}>
            <Button color="blue" onClick={onSaveClick}>{t('save')}</Button>
          </Grid.Column>
        </Grid.Row>
        {id && !loading
          ? (
            <Divider horizontal>
              <Header as="h4">
                {!dividerGroup ? '' : dividerGroup}
                <Label circular>{arrayMembers.length}</Label>
              </Header>
            </Divider>
          )
          : null}
        {id && loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          ) : null}
        {id && !loading
          ? (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={14}>
                { arrayMembersFiltered && arrayMembersFiltered.length > 5
                  ? (
                    <TextField
                      fullWidth
                      maxLength={30}
                      value={filter || ''}
                      error={filterError || ''}
                      label={t('discounts.filter')}
                      onChange={this.handleFilterOptionsChange}
                    />
                  ) : null}
              </Grid.Column>
              <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={13} />
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={1}>
                <Button icon onClick={() => this.setState({ openAdd: true })}>
                  <Icon name="add user" size="large" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {id && !loading
          ? (
            <Grid.Row style={{ overflowX: 'auto' }}>
              <SiclikTable
                selectable
                pageSize={10}
                singleSelection
                headers={headers}
                data={arrayMembersFiltered}
              />
            </Grid.Row>
          )
          : null}
        {id
          ? (
            <Grid.Row>
              <MembersOptions
                data={arrayOptionsFiltered}
                headers={headersOptions}
                open={openAdd}
                filter={filterMembers}
                filterError={filterMembersError}
                handleFilterChange={this.handleFilterMembersChange}
                handleGetSelectedOptions={this.handleGetSelectedOptions}
                onCloseClick={() => this.setState({ openAdd: false, filterMembers: '', arrayOptionsFiltered: arrayOptions })}
                onAddClick={this.handleAddMembers}
                clean={clean}
                updateClean={() => this.setState({ clean: !clean })}
              />
            </Grid.Row>
          )
          : null}
        <Grid.Row>
          <Confirm
            open={open}
            header={t('groups.confirmDeleteClientOfGroup')}
            content={member.name}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ open: false })}
            onConfirm={this.handleConfirmDelete}
          />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={13} computer={13} tablet={7} mobile={13} />
          <Grid.Column largeScreen={2} computer={2} tablet={7} mobile={13}>
            <Button onClick={() => onCloseClick(false)}>{t('back')}</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Group.defaultProps = {
};

Group.propTypes = {
  description: PropTypes.string.isRequired,
  descriptionError: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  handleChangeDescription: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};

export default translate('common', { wait: true })(Group);
