/* eslint-disable import/prefer-default-export */
import { callError } from '@compusoluciones-pdigitales/status-codes';

import { request } from '../../../common/fetch-utils';
import url from '../../../apiRoutes';

import { toast } from '../../utils';

function itemBodyScheme(items) {
  const setItemsFormat = items.map(({ sku, quantity = 1 }) => ({
    sku,
    quantity,
  }));
  return { items: setItemsFormat };
}

function getErrorType(error) {
  switch (error.code) {
    case callError.products.INVALID_SKU: {
      toast.warning('No se encontro ningun articulo con ese SKU');
      throw error;
    }
    case callError.orders.NO_ORDERS_FOUND:
      return error;
    default: {
      toast.error('El sistema parece no estar respondiendo correctamente. Intenta mas tarde.');
      throw error;
    }
  }
}

export async function sendToCart(items) {
  try {
    await request({
      route: url.addItems(),
      method: 'POST',
      body: itemBodyScheme(items),
    });
    toast.success('Los articulos fueron enviados a tu carrito');
    return { result: true };
  } catch (error) {
    return getErrorType(error);
  }
}
