import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import Iframe from 'react-iframe';
import getToken from '../services';
import Toast from '../../common/components/toast';

class CXC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    getToken()
      .then(({ token }) => this.setState({
        tokenizeUrl: `https://appsd.compusoluciones.com/siClik_csCxc/Home.aspx?token=${token}`,
        loading: false,
      }))
      .catch(() => {
        Toast('No fue posible solicitar token del sitio.', 'error');
      });
  }

  onUpdate = () => {
    const iframe = document.getElementById('iframeTarget');
    const divContent = document.getElementById('contentFrame');

    if (window.frames.document.body) {
      iframe.height = ' 2300px';
      divContent.style.height = '2300px';
    }
  }

  render() {
    const { tokenizeUrl, loading } = this.state;
    return loading
      ? (
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )
      : (
        <div id="contentFrame" style={{ width: '100%', height: '100%', marginTop: '20px' }}>
          <Iframe
            id="iframeTarget"
            onLoad={this.onUpdate}
            url={tokenizeUrl}
            position="absolute"
            overflow-x="hidden"
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      );
  }
}

export default translate('common', { wait: true })(CXC);
