import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Dropdown, Responsive,
} from 'semantic-ui-react';

function Payment(props) {
  const {
    t, CFDIOptions, idCFDI, handleCFDIChange,
  } = props;

  // function PaymentType() {
  //   return (
  //     <Grid>
  //       <Grid.Row>
  //         <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
  //           <Header as="h4">{t('shoppingCart.wayToPay')}</Header>
  //         </Grid.Column>
  //       </Grid.Row>
  //       {
  //         creditAvailable
  //           ? (
  //             <Grid.Row>
  //               <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
  //                 <Checkbox
  //                   radio
  //                   label={t('shoppingCart.CSCredit')}
  //                   name="paymentGroup"
  //                   value={1}
  //                   checked={payment === 1}
  //                   onChange={handlePaymentChange}
  //                 />
  //               </Grid.Column>
  //             </Grid.Row>
  //           ) : null
  //       }
  //       <Grid.Row>
  //         <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
  //           <Checkbox
  //             radio
  //             label={t('shoppingCart.cash')}
  //             name="paymentGroup"
  //             value={3}
  //             checked={payment === 3}
  //             onChange={handlePaymentChange}
  //           />
  //         </Grid.Column>
  //       </Grid.Row>
  //     </Grid>
  //   );
  // }

  // function CurrencySelection() {
  //   return (
  //     <Grid>
  //       <Grid.Row>
  //         <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
  //           <Header as="h4">{t('shoppingCart.currency')}</Header>
  //         </Grid.Column>
  //       </Grid.Row>
  //       <Grid.Row>
  //         <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
  //           <Checkbox
  //             radio
  //             label={t('shoppingCart.mxn')}
  //             name="currencyGroup"
  //             value="MXN"
  //             checked={currency === 'MXN'}
  //             onChange={handleCurrencyChange}
  //           />
  //         </Grid.Column>
  //       </Grid.Row>
  //       <Grid.Row>
  //         <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
  //           <Checkbox
  //             radio
  //             label={t('shoppingCart.usd')}
  //             name="currencyGroup"
  //             value="USD"
  //             checked={currency === 'USD'}
  //             onChange={handleCurrencyChange}
  //           />
  //         </Grid.Column>
  //       </Grid.Row>
  //     </Grid>
  //   );
  // }

  function CFDI() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4">{t('shoppingCart.CFDI')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Dropdown
              placeholder="CFDI"
              selection
              fluid
              className="only-bottom-border  no-padding-left"
              onChange={handleCFDIChange}
              options={CFDIOptions}
              value={idCFDI}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  return (
    <Grid.Row>
      {/* <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
        <PaymentType props={props} />
      </Grid.Column> */}
      <Responsive
        style={{ paddingTop: '2rem' }}
        as={Grid.Column}
        mobile={16}
        maxWidth={Responsive.onlyMobile.maxWidth}
      />
      {/* <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
        <CurrencySelection props={props} />
      </Grid.Column> */}
      <Responsive
        style={{ paddingTop: '2rem' }}
        as={Grid.Column}
        mobile={16}
        maxWidth={Responsive.onlyMobile.maxWidth}
      />
      <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
        <CFDI props={props} />
      </Grid.Column>
      {/* <Grid.Column largeScreen={16} computer={15} tablet={16} mobile={16}>
        <Grid.Row centered>
          <Grid.Column style={{ paddingTop: '2rem' }} largeScreen={14} computer={14} tablet={14} mobile={14}>
            <p>{t('shoppingCart.paymentAlert')}</p>
          </Grid.Column>
        </Grid.Row>
      </Grid.Column> */}
    </Grid.Row>
  );
}

Payment.propTypes = {
  CFDIOptions: PropTypes.arrayOf(PropTypes.shape({})),
  handleCFDIChange: PropTypes.func,
  idCFDI: PropTypes.string,
  t: PropTypes.func,
};

Payment.defaultProps = {
  CFDIOptions: [],
  handleCFDIChange: () => {},
  idCFDI: '',
  t: () => {},
};

export default translate('common', { wait: true })(Payment);
