import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import Iframe from 'react-iframe';

const urlAWS = process.env.REACT_APP_URL_AWS;

class PDFVisualizer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleType = type => {
    switch (type) {
      case 'terms':
        return '/TERMINOS.pdf';
      case 'fqa':
        return '/FQA.pdf';
      case 'mpoCxC':
        return '/2021_MPO_Credito_Cobranza.pdf';
      case 'devoluciones':
        return '/Politica_devoluciones_distribuidores.pdf';
      case 'formasdepago':
        return '/Formas_Pago.pdf';
      case 'metodosenvio':
        return '/Metodos_envio_CS.pdf';
      default:
        return '';
    }
  };

  render() {
    const { type } = this.props;
    const file = this.handleType(type);
    return (
      <Iframe
        id="iframeTarget"
        onLoad={this.onUpdate}
        url={`${urlAWS}${file}`}
        position="absolute"
        overflow-x="hidden"
        width="97%"
        height="85%"
        frameBorder="0"
      />
    );
  }
}

PDFVisualizer.defaultProps = {
  type: '',
};

PDFVisualizer.propTypes = {
  type: PropTypes.string,
};

export default translate('common', { wait: true })(PDFVisualizer);
