import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Grid, Menu, Dropdown, Image,
} from 'semantic-ui-react';
import routeNames from '../../../route-names';
import assets from '.';

const outletPath = `${routeNames.products.route}/outlet`;
const bundlePath = `${routeNames.products.route}/bundle`;

const {
  brands,
  accesories,
  computer,
  printers,
  networks,
  servers,
  software,
  gaming,
} = assets;

class MenuCategoriesComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      hoveredItem: null,
    };
  }

  handleSearchButtonClick = () => {
    const { searchString } = this.state;
    const { history } = this.props;
    const encodedSearchString = encodeURIComponent(searchString);
    history.push(`/productos${encodedSearchString}`);
  };

  handleMouseEnter = value => {
    this.setState({ hoveredItem: value });
  }

  handleMouseLeave = () => {
    this.setState({ hoveredItem: null });
  }

  render() {
    const {
      isAuthenticated, t, history,
    } = this.props;
    const { hoveredItem } = this.state;
    return (
      <Grid padded textAlign="left" verticalAlign="middle">
        <Grid.Row className="no-padding">
          <Menu stackable text pointing className="no-margin-top no-margin-bottom" style={{ paddingLeft: '1rem', minHeight: '2em' }}>
            {
              isAuthenticated && (
                <Grid.Column
                  className="no-padding-x"
                  textAlign="center"
                  tablet={5}
                  computer={5}
                  largeScreen={2}
                  widescreen={2}
                >
                  <Dropdown text="Marcas" className="whiteText menu-item-style">
                    <Dropdown.Menu style={{
                      top: '140%',
                      paddingRight: '30px',
                      paddingBottom: '3px',
                      width: '100vw',
                    }}
                    >
                      <Grid columns={8} style={{ width: '100vw' }}>
                        <Grid.Row style={{ paddingLeft: '45px', paddingRight: '45px' }}>
                          {
                            brands.map(brand => (
                              <Grid.Column
                                className="no-padding-x"
                                tablet={6}
                                largeScreen={2}
                                widescreen={2}
                                key={brand.text}
                                onMouseEnter={() => this.handleMouseEnter(brand.text)}
                                onMouseLeave={this.handleMouseLeave}
                                onClick={() => { history.push(`/productos${brand.redirect}`); }}
                                style={{
                                  cursor: 'pointer',
                                  backgroundColor: hoveredItem === brand.text ? '#f7f7f7' : 'white',
                                  justifyContent: 'center',
                                  display: 'flex',
                                }}
                              >
                                <Dropdown.Item
                                  className="no-padding-x"
                                  text={brand.text}
                                  style={{
                                    padding: '15px 15px 15px 15px',
                                  }}
                                >
                                  <Image
                                    size={brand.text === 'HP Inc' ? 'mini' : 'tiny'}
                                    src={brand.logo}
                                  />
                                </Dropdown.Item>
                              </Grid.Column>
                            ))
                          }
                        </Grid.Row>
                      </Grid>
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              )
            }
            {
              isAuthenticated && (
                <Grid.Column
                  className="no-padding-x"
                  textAlign="center"
                  tablet={5}
                  computer={4}
                  largeScreen={2}
                  widescreen={2}
                >
                  <Dropdown text="Hardware" className="whiteText menu-item-style">
                    <Dropdown.Menu style={{ top: '150%' }}>
                      <Dropdown.Item className="no-padding">
                        <Dropdown text="Accesorios" className="no-padding menu-subitem-style fullBlackText" fluid pointing="left">
                          <Dropdown.Menu style={{ left: '127%' }}>
                            {accesories.map(accesorie => (
                              <Dropdown.Item text={accesorie.text} onClick={() => { history.push(`/productos${accesorie.redirect}`); }} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item className="no-padding">
                        <Dropdown text="Cómputo" className="menu-subitem-style fullBlackText" fluid pointing="left">
                          <Dropdown.Menu style={{ left: '127%' }}>
                            {computer.map(compute => (
                              <Dropdown.Item text={compute.text} onClick={() => { history.push(`/productos${compute.redirect}`); }} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item className="no-padding">
                        <Dropdown text="Impresoras" className="menu-subitem-style fullBlackText" fluid pointing="left">
                          <Dropdown.Menu style={{ left: '127%' }}>
                            {printers.map(printer => (
                              <Dropdown.Item text={printer.text} onClick={() => { history.push(`/productos${printer.redirect}`); }} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item className="no-padding">
                        <Dropdown text="Redes" className="menu-subitem-style fullBlackText" fluid pointing="left">
                          <Dropdown.Menu style={{ left: '127%' }}>
                            {networks.map(network => (
                              <Dropdown.Item text={network.text} onClick={() => { history.push(`/productos${network.redirect}`); }} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item className="no-padding">
                        <Dropdown text="Servidores" className="menu-subitem-style fullBlackText" fluid pointing="left">
                          <Dropdown.Menu style={{ left: '127%' }}>
                            {servers.map(server => (
                              <Dropdown.Item text={server.text} onClick={() => { history.push(`/productos${server.redirect}`); }} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Item>
                      <Dropdown.Item className="menu-item fullBlackText" text="Gaming" onClick={() => { history.push(`/productos${gaming[0].redirect}`); }} />
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              )
            }
            {
              isAuthenticated && (
                <Grid.Column
                  className="no-padding-x"
                  textAlign="center"
                  tablet={5}
                  computer={4}
                  largeScreen={2}
                  widescreen={2}
                >
                  <Dropdown fluid text="Software" className="whiteText menu-item-style">
                    <Dropdown.Menu style={{ top: '150%' }}>
                      {software.map(soft => (
                        <Dropdown.Item className="menu-item" text={soft.text} onClick={() => { history.push(`/productos${soft.redirect}`); }} />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Grid.Column>
              )
            }
            {
              isAuthenticated && (
                <Grid.Column
                  className="no-padding-x"
                  textAlign="center"
                  tablet={5}
                  computer={3}
                  largeScreen={2}
                  widescreen={2}
                >
                  <Menu.Item
                    onClick={() => history.push(outletPath)}
                    className="whiteText menu-item-style"
                  >
                    Outlet
                  </Menu.Item>
                </Grid.Column>
              )
            }
            {
              isAuthenticated && (
                <Grid.Column
                  className="no-padding-x"
                  textAlign="center"
                  tablet={5}
                  computer={3}
                  largeScreen={2}
                  widescreen={2}
                >
                  <Menu.Item
                    onClick={() => history.push(bundlePath)}
                    className="whiteText menu-item-style"
                  >
                    Paquetes
                  </Menu.Item>
                </Grid.Column>
              )
            }
            {
              isAuthenticated && (
                <Grid.Column
                  className="no-padding-x"
                  textAlign="center"
                  tablet={5}
                  computer={3}
                  largeScreen={2}
                  widescreen={2}
                >
                  <Menu.Item
                    onClick={() => { history.push(routeNames.iquote.route); }}
                    className="whiteText menu-item-style"
                  >
                    {t('menu.iquote')}
                  </Menu.Item>
                </Grid.Column>
              )
            }
          </Menu>
        </Grid.Row>
      </Grid>
    );
  }
}

MenuCategoriesComp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(MenuCategoriesComp);
