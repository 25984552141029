import React, { useState } from 'react';
import { translate } from 'react-i18next';
import Resizer from 'react-image-file-resizer';
import PropTypes from 'prop-types';
import {
  Modal,
  Tab,
  Button,
  Container,
} from 'semantic-ui-react';

import Toast from '../../../common/components/toast';
import ImageSizes from './image-sizes';
import LoadImages from './load-image';

import { IMAGE_SIZES, LOGO_SIZES } from '../../constants';

const translatePath = 'productConfig.menus.uploadImages.loadImages';

const resizeImage = (file, maxWidth, maxHeight) => (
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'WEBP',
      100,
      0,
      uri => {
        resolve(uri);
      },
      'base64',
    );
  })
);

const setAvailableResizedSizes = (acceptedImages, isImageType) => {
  const sizes = isImageType ? IMAGE_SIZES : LOGO_SIZES;
  return acceptedImages.map(image => {
    const isHorizontal = image.width > image.height;
    const isSquare = image.width === image.height;
    const availableSizes = sizes
      .filter(sizeObject => sizeObject.width <= image.width && sizeObject.height <= image.height)
      .filter(sizeObject => {
        if (isSquare) {
          return sizeObject.position === 'square';
        }
        if (isHorizontal) {
          return sizeObject.position === 'horizontal';
        }
        return sizeObject.position === 'vertical';
      });
    return { ...image, availableSizes };
  });
};

function ModalUploadImage(props) {
  const {
    t,
    open,
    onClose,
    uploadImages,
    isImageType,
  } = props;

  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);

  const resizeAllImages = acceptedImages => {
    const availableResizedSizes = setAvailableResizedSizes(acceptedImages, isImageType);
    return Promise.all(availableResizedSizes.map(async image => {
      const { file, availableSizes } = image;
      const sizes = await Promise.all(availableSizes.map(async size => {
        try {
          const { width, height } = size;
          const newSize = await resizeImage(file, width, height);
          return { size: size.size, preview: newSize };
        } catch (error) {
          Toast(t('toast.error.general'), 'error');
          return { size: size.size, preview: null };
        }
      }));
      return { ...image, sizes };
    }));
  };

  const handleAcceptedImages = async images => {
    const resizedImages = await resizeAllImages(images);
    setAcceptedFiles(resizedImages);
  };

  const handleOnClose = () => {
    setRejectedFiles([]);
    setAcceptedFiles([]);
    onClose(false);
  };

  const handleOnUploadImages = () => {
    uploadImages(acceptedFiles);
    handleOnClose();
  };

  const panes = [
    {
      menuItem: t(`${translatePath}.tabLoadImages`),
      render: () => (
        <Container style={{ marginBottom: '20px' }}>
          <LoadImages
            isImageType={isImageType}
            accepted={{
              images: acceptedFiles,
              setImages: images => handleAcceptedImages(images),
            }}
            rejected={{
              images: rejectedFiles,
              setImages: images => setRejectedFiles(images),
            }}
          />
        </Container>
      ),
    },
    {
      menuItem: t(`${translatePath}.tabImageSizes`),
      render: () => (
        <Container style={{ marginBottom: '20px' }}>
          <ImageSizes
            images={acceptedFiles}
            isImageType={isImageType}
          />
        </Container>
      ),
    },
  ];
  return (
    <Modal
      centered
      closeIcon
      size="large"
      open={open}
      onClose={handleOnClose}
    >
      <Modal.Header>{t(`${translatePath}.header`)}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Tab
            menu={{ pointing: true }}
            panes={panes}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t(`${translatePath}.close`)}
          onClick={handleOnClose}
        />
        <Button
          primary
          content={t(`${translatePath}.save`)}
          onClick={handleOnUploadImages}
        />
      </Modal.Actions>
    </Modal>
  );
}

ModalUploadImage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  uploadImages: PropTypes.func.isRequired,
  isImageType: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(ModalUploadImage);
