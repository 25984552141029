/* eslint-disable no-undef */
import React from 'react';
import { Loader } from 'semantic-ui-react';
import billingService from './services';
import Toast from '../common/components/toast';
import * as session from '../common/sessions';
import './styles.css';

class DownloadBilling extends React.Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      customerId,
      loading: true,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { movId } = match.params;
    const { customerId } = this.state;
    this.getBillingByMovId(customerId, movId);
  }

  getBillingByMovId = (customerId, billName) => {
    const componentsBill = billName.split('.');
    const movId = componentsBill[componentsBill.length - 2];
    const extensionBill = componentsBill[componentsBill.length - 1];
    billingService.getBillingData(customerId, movId)
      .then(result => {
        const { attaches } = result;
        if (attaches.length > 0) {
          const file = attaches.find(attach => {
            const nameDestructured = attach.name.split('.');
            const type = nameDestructured[nameDestructured.length - 1];
            return type === extensionBill;
          });
          file.fileType = extensionBill;
          return this.downloadFile(file);
        }
        return null;
      }).catch(error => {
        this.setState({ loading: false });
        let messageError = '';
        if (!error) messageError = ('error-codes.default');
        else messageError = error.message;
        Toast(messageError, 'error');
        setTimeout(() => {
          window.open('about:blank', '_self');
          window.close();
        }, 3000);
      });
    return null;
  }

  downloadFile = attachment => {
    const { url: urlFile, name } = attachment;
    const { t } = this.props;

    billingService.getRequestFile({ fileName: name, url: urlFile })
      .then(response => {
        const data = new Blob([Buffer.from(response.remoteFile)], { type: 'text/plain' });
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${name}`;
        a.click();
        this.setState({ loading: false });
        window.open('about:blank', '_self');
        window.close();
      }).catch(() => {
        setTimeout(() => {
          billingService.getRequestFile({ fileName: name, url: urlFile })
            .then(response => {
              const data = new Blob([Buffer.from(response.remoteFile)], { type: 'text/plain' });
              const url = window.URL.createObjectURL(data);
              const a = document.createElement('a');
              a.href = url;
              a.download = `${name}`;
              a.click();
              this.setState({ loading: false });
              window.open('about:blank', '_self');
              window.close();
            }).catch(error => {
              this.setState({ loading: false });
              let messageError = '';
              if (!error) messageError = t('error-codes.default');
              else messageError = error.message;
              Toast(messageError, 'error');
              setTimeout(() => {
                window.open('about:blank', '_self');
                window.close();
              }, 3000);
            });
        }, 6000);
      });
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="Download-container">
        {
          loading
            ? <Loader active inline="centered" size="massive">Downloading</Loader>
            : <div />
        }
      </div>
    );
  }
}

export default DownloadBilling;
