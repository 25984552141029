/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import UserWishList from '../../components/Lists';
import ModalNewList from '../../components/ModalNewList';

import { useWishLists } from './ListsProvider';

export default function WishList() {
  const {
    wishLists,
    selectWishList,
    getWishLists,
    createWishList,
    toggleSwitchNewListModal,
    modalNewListToggle,
  } = useWishLists();

  useEffect(() => {
    getWishLists();
  }, []);

  return (
    <div>
      <ModalNewList
        open={modalNewListToggle}
        onClose={toggleSwitchNewListModal}
        createList={createWishList}
      />
      <UserWishList
        lists={wishLists}
        selectedList={selectWishList}
        modalOpen={toggleSwitchNewListModal}
      />
    </div>
  );
}
