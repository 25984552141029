import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Image, Header } from 'semantic-ui-react';
import responsiveStyle from './styles';

import './style.css';

const ElementsCategories = props => {
  const { device, elements, elementType } = props;
  const { rowWidths, columnWidths } = responsiveStyle[device];
  return (
    <Grid.Row
      centered
      className="padding-btw-text general-padding-children"
      columns={rowWidths.columns}
    >
      {
        elements.map(serviceResource => {
          const {
            web, promotionName, id, action,
          } = serviceResource;
          return (
            <Grid.Column
              key={id}
              className=""
              textAlign="center"
              tablet={columnWidths.tablet}
              mobile={columnWidths.mobile}
            >
              <Link
                to={`/productos?type=${elementType}&value=${action.value}`}
              >
                <Image
                  alt={web}
                  fluid
                  src={web}
                />
                <Header
                  size="small"
                  className="margin-btw-text-images titles-news-gothic blackText hov-blue header-elements"
                  content={promotionName}
                />
              </Link>
            </Grid.Column>
          );
        })
      }
    </Grid.Row>
  );
};

ElementsCategories.propTypes = {
  device: PropTypes.string,
  elementType: PropTypes.string,
  elements: PropTypes.arrayOf(PropTypes.shape({
    imageWeb: PropTypes.string,
    text: PropTypes.string,
    redirect: PropTypes.string,
  })),
};

ElementsCategories.defaultProps = {
  device: 'computer',
  elementType: 'search',
  elements: [],
};

export default translate('common', { wait: true })(ElementsCategories);
