import cookie from 'js-cookie';
import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const chatService = {};

chatService.getUsersList = orderId => (
  request({
    route: apiRoutes.getUsersList(orderId),
    method: 'GET',
  })
);

chatService.addUserToOrder = (body, orderId) => (
  request({
    route: apiRoutes.addUserToOrder(orderId),
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookie.get('token')}`,
    },
  })
);

export default chatService;
