import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Dropdown, Dimmer, Loader,
} from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const AddUpdate = props => {
  const {
    t, makers, maker, action, actionError, lines, line, families, family, products, sku, other, otherError,
    handleChangeMaker, handleChangeAction, handleChangeLine, handleChangeFamily, handleChangeSku, handleChangeOther, loading,
    disabled, onKeyPress,
  } = props;
  return (
    <Grid container>
      <Grid.Row columns={4}>
        <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
          {t('cross-selling.maker')}
          <Dropdown
            placeholder={t('cross-selling.chooseMaker')}
            fluid
            search
            selection
            options={makers}
            onChange={!disabled ? handleChangeMaker : null}
            value={maker}
            disabled={disabled}
          />
        </Grid.Column>
        <Grid.Column onKeyPress={onKeyPress} largeScreen={8} computer={8} tablet={8} mobile={16}>
          <TextField
            maxLength={280}
            fullWidth
            label={t('cross-selling.action')}
            value={action || ''}
            error={actionError || ''}
            onChange={handleChangeAction}
          />
        </Grid.Column>
      </Grid.Row>
      {loading
        ? (
          <Grid.Row>
            <Dimmer active inverted><Loader active size="big" /></Dimmer>
          </Grid.Row>
        )
        : null }
      {!loading
        ? (
          <Grid.Row>
            <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
              {t('cross-selling.lines')}
              <Dropdown
                placeholder={t('cross-selling.chooseLines')}
                fluid
                multiple
                search
                selection
                onChange={handleChangeLine}
                options={lines}
                value={line}
              />
            </Grid.Column>
            <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
              {t('cross-selling.families')}
              <Dropdown
                placeholder={t('cross-selling.chooseFamilies')}
                fluid
                multiple
                search
                selection
                onChange={handleChangeFamily}
                options={families}
                value={family}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      {!loading
        ? (
          <Grid.Row columns={4}>
            <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
              {t('cross-selling.products')}
              <Dropdown
                placeholder={t('cross-selling.chooseProducts')}
                fluid
                search
                selection
                multiple
                onChange={handleChangeSku}
                options={products}
                value={sku}
              />
            </Grid.Column>
            <Grid.Column onKeyPress={onKeyPress} largeScreen={8} computer={8} tablet={8} mobile={16}>
              <TextField
                maxLength={280}
                fullWidth
                label={t('cross-selling.other')}
                value={other || ''}
                error={otherError || ''}
                onChange={handleChangeOther}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
    </Grid>
  );
};

export default translate('common', { wait: true })(AddUpdate);

AddUpdate.defaultProps = {
  t: null,
  maker: '',
  action: '',
  actionError: '',
  line: '',
  family: '',
  sku: '',
  other: '',
  otherError: '',
  disabled: false,
  handleChangeMaker: null,
  handleChangeAction: null,
  handleChangeLine: null,
  handleChangeFamily: null,
  handleChangeSku: null,
  handleChangeOther: null,
  loading: false,
  onKeyPress: null,
};

AddUpdate.propTypes = {
  t: PropTypes.func,
  disabled: PropTypes.bool,
  makers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  maker: PropTypes.string,
  action: PropTypes.string,
  actionError: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  line: PropTypes.string,
  families: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  family: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sku: PropTypes.string,
  other: PropTypes.string,
  otherError: PropTypes.string,
  handleChangeMaker: PropTypes.func,
  handleChangeAction: PropTypes.func,
  handleChangeLine: PropTypes.func,
  handleChangeFamily: PropTypes.func,
  handleChangeSku: PropTypes.func,
  handleChangeOther: PropTypes.func,
  loading: PropTypes.bool,
  onKeyPress: PropTypes.func,
};
