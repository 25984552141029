import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import validator from 'validator';
import moment from 'moment';
import {
  Grid, Header, Form, TextArea, Button, Modal, Confirm,
} from 'semantic-ui-react';
import SiclikTable from '../common/components/table';
import AddAndUpdate from './components/add-update';
import Toast from '../common/components/toast';
import PrioritiesService from './services';

const currentDate = moment();

class StrategicPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerslines: [
        { key: 'id', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      headersCases: [
        { key: 'id', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      headersPlan: [
        { key: 'id', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      items: [],
      selectedItemId: '',
      errorCategory: '',
      value: '',
      error: '',
      openDelete: false,
      update: false,
      vision: '',
      visionError: '',
      dataLines: [],
      dataCases: [],
      dataPlan: [],
      dataPriorities: [],
      visionId: '',
    };
  }

  componentDidMount() {
    this.handleGetPrioritiesData();
    this.handleGetCategories();
  }

  componentDidUpdate(prevProps) {
    const { customerId, year } = this.props;
    if ((customerId !== prevProps.customerId) || (year !== prevProps.year)) {
      this.handleGetPrioritiesData();
    }
  }

  onItemClick = ({ target }) => this.setState({ selectedItemId: target.value, errorCategory: '' });

  onKeyPressAdd = e => {
    if (e.which === 13) this.handleClickAdd();
  }

  onKeyPressUpdate = e => {
    if (e.which === 13) this.handleClickUpdate();
  }

  handleGetCategories = async () => {
    const { t } = this.props;
    let categoriesFormated = [];
    const typeForm = 'PRIORIDADES ESTRATEGICAS';
    const arrayCategories = await PrioritiesService.getCategories(typeForm).catch(error => {
      let messageError = '';
      if (Number(error.code) === 1005 || Number(error.code) === 1000) {
        // this.setState({ messageTable: t('strategic-priorities.emptyTable') });
      }
      if (!error) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
      // this.setState({ loading: false });
    });
    if (arrayCategories && arrayCategories.categories) {
      categoriesFormated = arrayCategories.categories.filter(category => category.id !== 9);
      categoriesFormated = categoriesFormated.map(item => {
        const formatedItem = { ...item, label: item.category, value: item.id };
        return formatedItem;
      });
    }
    this.setState({ items: categoriesFormated });
    return categoriesFormated;
  }

  handleGetPrioritiesData = async () => {
    const { t, customerId, year } = this.props;
    // this.setState({ loading: true });
    const priorities = await PrioritiesService.getFodaByCustomerId(customerId, year, 2).catch(error => {
      let messageError = '';
      if (!error) {
        messageError = t('error-codes.default');
      } else if (Number(error.code) === 1005 || Number(error.code) === 1000) {
        // this.setState({ messageTable: t('foda.emptyTable') });
      } else { messageError = t(`error-codes.${error.code}`); }
      if (messageError) Toast(messageError, 'error');
    });
    if (priorities && priorities.businessPlanList) {
      this.handleFilterByCategory(priorities.businessPlanList);
      this.setState({ dataPriorities: priorities.businessPlanList });
    } else {
      this.setState({
        // loading: false,
        visionId: '',
        vision: '',
        dataLines: [],
        dataCases: [],
        dataPlan: [],
        selectedItemId: '',
        elementId: '',
        value: '',
        error: '',
        update: false,
        openDelete: false,
        dataPriorities: [],
        errorCategory: '',
      });
    }
  }

  handleFilterByCategory = async arrayFoda => {
    const vision = arrayFoda.filter(data => data.categoryId === 9);
    const dataLines = arrayFoda.filter(data => data.categoryId === 10);
    const dataCases = arrayFoda.filter(data => data.categoryId === 11);
    const dataPlan = arrayFoda.filter(data => data.categoryId === 12);
    this.setState({
      visionId: vision && vision[0] ? vision[0].id : '',
      vision: vision && vision[0] ? vision[0].description : '',
      dataLines,
      dataCases,
      dataPlan,
      selectedItemId: '',
      elementId: '',
      value: '',
      error: '',
      errorCategory: '',
      update: false,
      openDelete: false,
      // loading: false,
    });
  }

  handleChangeVision = (e, { value: vision }) => this.setState({ vision });

  handleChange = event => {
    const { t } = this.props;
    const maxLength = 280;
    const { value } = event.target;
    if (value.length > maxLength) {
      this.setState({ value, error: t('strategic-priorities.error.toLong') });
    } else if (value.length > 0 && !validator.isAlphanumeric(value, ['es-ES'], { ignore: ' .,:;%$+-*/=' })) {
      this.setState({ value, error: t('strategic-priorities.error.notSymbol') });
    } else {
      this.setState({ value, error: '' });
    }
  };

  handleUpdate = row => {
    this.setState({
      update: true,
      value: row.description,
      selectedItemId: row.categoryId,
      elementId: row.id,
      error: '',
      errorCategory: '',
    });
  }

  handleDelete = row => {
    this.setState({
      openDelete: true,
      value: row.description,
      elementId: row.id,
      selectedItemId: row.categoryId,
      error: '',
      errorCategory: '',
    });
  }

  handleClickAdd = async () => {
    const { t, year, customerId } = this.props;
    const { selectedItemId, value } = this.state;
    let { error, errorCategory } = this.state;
    const priorityItem = {
      customerId,
      categoryId: selectedItemId,
      description: value,
      year,
    };
    if (!value || !selectedItemId || !year || error || errorCategory) {
      if (!value) error = t('strategic-priorities.error.empty');
      if (!selectedItemId) errorCategory = t('strategic-priorities.error.empty');
      if (error || errorCategory) Toast(t('strategic-priorities.error.dataError'), 'warning');
      this.setState({ error, errorCategory });
    } else {
      const added = await PrioritiesService.addBusinessPlanItem(priorityItem)
        .catch(e => {
          let messageError = '';
          if (!e.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${e.code}`);
          Toast(messageError, 'error');
        });
      if (added) this.handlePrioritiesFieldAdded({ id: added.id, ...priorityItem });
    }
  }

  handleClickUpdate = async () => {
    const { t } = this.props;
    const {
      value, elementId, error, errorCategory,
    } = this.state;
    if (value && !error && !errorCategory) {
      const fodaItem = {
        itemId: elementId,
        description: value,
      };
      const updated = await PrioritiesService.updateBusinessPlanItem(fodaItem)
        .catch(e => {
          let messageError = '';
          if (!e.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${e.code}`);
          Toast(messageError, 'error');
        });
      if (updated && updated.changedRows) this.handlePrioritiesFieldUpdated(fodaItem);
    } else {
      Toast(t('strategic-priorities.error.dataError'), 'warning');
    }
  }

  handleClickDelete = async () => {
    const { t } = this.props;
    const { elementId } = this.state;
    const deleted = await PrioritiesService.deleteBusinessPlanItem(elementId)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (deleted && deleted.status) this.handlePrioritiesFieldDeleted(elementId);
  }

  handlePrioritiesFieldAdded = async priority => {
    const { dataPriorities } = this.state;
    dataPriorities.push({ id: priority.id, categoryId: priority.categoryId, description: priority.description });
    this.setState({ dataPriorities });
    this.handleFilterByCategory(dataPriorities);
  }

  handlePrioritiesFieldUpdated = priority => {
    const { dataPriorities } = this.state;
    const elementToUpdate = dataPriorities.findIndex(element => element.id === priority.itemId);
    dataPriorities[elementToUpdate].description = priority.description;
    this.setState(dataPriorities);
    this.handleFilterByCategory(dataPriorities);
  }

  handlePrioritiesFieldDeleted = elementId => {
    const { dataPriorities } = this.state;
    const elementToDelete = dataPriorities.findIndex(element => element.id === elementId);
    dataPriorities.splice(elementToDelete, 1);
    this.setState(dataPriorities);
    this.handleFilterByCategory(dataPriorities);
  }

  handleClickSaveVision = async () => {
    const { t, year, customerId } = this.props;
    const { vision, visionId } = this.state;
    let { visionError } = this.state;
    const priorityItemAdd = {
      customerId,
      categoryId: 9,
      description: vision,
      year,
    };
    const priorityItemUpdate = { itemId: visionId, description: vision };
    if (!year || !vision || visionError) {
      if (!vision) visionError = t('strategic-priorities.error.empty');
      if (visionError) Toast(t('strategic-priorities.error.dataError'), 'warning');
      this.setState({ visionError });
    } else if (!visionId) {
      this.handleAddVision(priorityItemAdd);
    } else {
      this.handleUpdateVision(priorityItemUpdate);
    }
  }

  handleAddVision = async priorityItem => {
    const { t } = this.props;
    const added = await PrioritiesService.addBusinessPlanItem(priorityItem)
      .catch(e => {
        let messageError = '';
        if (!e.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${e.code}`);
        Toast(messageError, 'error');
      });
    if (added) {
      this.handlePrioritiesFieldAdded({ id: added.id, ...priorityItem });
      this.setState({ visionId: added.id });
      Toast(t('strategic-priorities.visionSet'), 'success');
    }
  }

  handleUpdateVision = async priorityItem => {
    const { t } = this.props;
    const updated = await PrioritiesService.updateBusinessPlanItem(priorityItem)
      .catch(e => {
        let messageError = '';
        if (!e.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${e.code}`);
        Toast(messageError, 'error');
      });
    if (updated && updated.changedRows) {
      this.handlePrioritiesFieldUpdated(priorityItem);
      Toast(t('strategic-priorities.visionUpdated'), 'success');
    }
  }

  render() {
    const { t } = this.props;
    const {
      headersCases, headersPlan, headerslines, items, value, error, errorCategory, selectedItemId,
      update, openDelete, vision, visionError, dataLines, dataCases, dataPlan,
    } = this.state;
    let category = '';
    switch (selectedItemId) {
      case 10: category = 'Lineas estratégicas'; break;
      case 11: category = 'Casos de Uso/workloads/soluciones/servicios profesionales'; break;
      case 12: category = 'Plan de acción por zona geográfica'; break;
      default: category = ''; break;
    }
    return (
      <Grid container>
        <Grid.Row />
        <Grid.Row>
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={12}>
            <Header color="blue" as="h3">{t('strategic-priorities.strategicPriorities')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
            <Form>
              <Header color="blue" as="h4">{t('strategic-priorities.vision')}</Header>
              <TextArea
                placeholder="Ingresa la visión de la empresa"
                value={vision}
                error={visionError}
                onChange={this.handleChangeVision}
              />
            </Form>
            <Button fluid onClick={this.handleClickSaveVision}>{t('strategic-priorities.visionSave')}</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={14} computer={14} tablet={13} mobile={15}>
            <AddAndUpdate
              items={items}
              value={value}
              error={error}
              errorCategory={errorCategory}
              selectedItemId={selectedItemId}
              onItemClick={this.onItemClick}
              handleChange={this.handleChange}
              onKeyPress={this.onKeyPressAdd}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={3} mobile={15}>
            <Button fluid onClick={this.handleClickAdd}>{t('add')}</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
            <Header color="blue" as="h4">{t('strategic-priorities.strategicLines')}</Header>
            <SiclikTable headers={headerslines} data={dataLines} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
            <Header color="blue" as="h4">{t('strategic-priorities.useCases')}</Header>
            <SiclikTable headers={headersCases} data={dataCases} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
            <Header color="blue" as="h4">{t('strategic-priorities.actionPlan')}</Header>
            <SiclikTable headers={headersPlan} data={dataPlan} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal open={update}>
            <Modal.Content>
              <AddAndUpdate
                items={items}
                value={value}
                error={error}
                errorCategory={errorCategory}
                selectedItemId={selectedItemId}
                onItemClick={this.onItemClick}
                handleChange={this.handleChange}
                onKeyPress={this.onKeyPressUpdate}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleClickUpdate} color="blue">{t('save')}</Button>
              <Button
                onClick={() => this.setState({
                  update: false,
                  value: '',
                  selectedItemId: '',
                  error: '',
                  errorCategory: '',
                })}
              >
                {t('back')}
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openDelete}
            header={`${t('strategic-priorities.deleteOf')} ${category}`}
            content={value}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({
              openDelete: false, value: '', selectedItemId: '', elementId: '',
            })}
            onConfirm={this.handleClickDelete}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(StrategicPriorities);

StrategicPriorities.defaultProps = {
  year: currentDate.year(),
  customerId: '',
};

StrategicPriorities.propTypes = {
  year: PropTypes.number,
  customerId: PropTypes.string,
};
