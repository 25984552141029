export default {
  cash: {
    discount: 'cash discount',
    type: 'cash',
  },
  volume: {
    discount: 'volume prices',
    type: 'volume',
  },
  itemPricing: {
    discount: 'items discounts',
    type: 'coupon',
  },
  creditDays: {
    discount: 'credit days',
    type: 'coupon',
  },
  subtotal: {
    discount: 'subtotal',
    type: 'coupon',
  },
  shipping: {
    discount: 'shipping',
    type: 'coupon',
  },
};
