import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Button, Header, Grid, Responsive, Checkbox,
} from 'semantic-ui-react';
import TextField from '@material-ui/core/TextField';
import deliveryAddress from '../services';
import Toast from '../../common/components/toast';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localSchedule: [
        {
          weekday: 1, name: 'Lunes', startTime1: '', endTime1: '', checked: false, disabledTime: true,
        },
        {
          weekday: 2, name: 'Martes', startTime1: '', endTime1: '', checked: false, disabledTime: true,
        },
        {
          weekday: 3, name: 'Miércoles', startTime1: '', endTime1: '', checked: false, disabledTime: true,
        },
        {
          weekday: 4, name: 'Jueves', startTime1: '', endTime1: '', checked: false, disabledTime: true,
        },
        {
          weekday: 5, name: 'Viernes', startTime1: '', endTime1: '', checked: false, disabledTime: true,
        },
      ],
      disabledButton: true,
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    if (id.length > 1) this.handleGetSchedule();
  }

  handleGetSchedule = () => {
    const { t, id } = this.props;
    const { localSchedule } = this.state;
    let { disabledButton } = this.state;
    deliveryAddress.getSchedule(id)
      .then(response => {
        if (response.schedules.length === 1) disabledButton = false;
        response.schedules.map(day => {
          if (day.weekday === 1) {
            localSchedule[0].startTime1 = day.startTime1;
            localSchedule[0].endTime1 = day.endTime1;
            if (day.startTime1 && day.endTime1) {
              localSchedule[0].checked = true;
              localSchedule[0].disabledTime = false;
            }
          }
          if (day.weekday === 2) {
            localSchedule[1].startTime1 = day.startTime1;
            localSchedule[1].endTime1 = day.endTime1;
            if (day.startTime1 && day.endTime1) {
              localSchedule[1].checked = true;
              localSchedule[1].disabledTime = false;
            }
          }
          if (day.weekday === 3) {
            localSchedule[2].startTime1 = day.startTime1;
            localSchedule[2].endTime1 = day.endTime1;
            if (day.startTime1 && day.endTime1) {
              localSchedule[2].checked = true;
              localSchedule[2].disabledTime = false;
            }
          }
          if (day.weekday === 4) {
            localSchedule[3].startTime1 = day.startTime1;
            localSchedule[3].endTime1 = day.endTime1;
            if (day.startTime1 && day.endTime1) {
              localSchedule[3].checked = true;
              localSchedule[3].disabledTime = false;
            }
          }
          if (day.weekday === 5) {
            localSchedule[4].startTime1 = day.startTime1;
            localSchedule[4].endTime1 = day.endTime1;
            if (day.startTime1 && day.endTime1) {
              localSchedule[4].checked = true;
              localSchedule[4].disabledTime = false;
            }
          }
          return day;
        });
        this.setState({ localSchedule, disabledButton });
      })
      .catch(error => {
        if (!error.code) { Toast(t('error-codes.default'), 'error'); } else if (Number(error.code) !== 1005) {
          Toast(t(`error-codes.${error.code}`), 'error');
        }
      });
  }

  handleAddSchedule = action => {
    const { t, returnSchedule, onboarding } = this.props;
    const { localSchedule } = this.state;
    const daysEmpty = [];
    const daysChecked = [];
    const scheduleToSave = JSON.parse(JSON.stringify(localSchedule));
    if (!onboarding) {
      daysChecked.push({
        weekday: 1,
        startTime1: '10:00',
        endTime1: '15:00',
      });
      returnSchedule(action, daysChecked);
    } else {
      scheduleToSave.map(day => {
        // const elementToUpdate = localSchedule.findIndex(element => element.weekday === day.weekday);
        if (day.checked === true) {
          daysChecked.push({
            weekday: day.weekday,
            startTime1: day.startTime1,
            endTime1: day.endTime1,
          });
        }
        if (day.startTime1.length > 0 && day.endTime1.length > 0) daysEmpty.push(day.weekday);
        // delete localSchedule[elementToUpdate].name;
        // delete localSchedule[elementToUpdate].disabledTime;
        return day;
      });
      const body = daysChecked;
      if (daysEmpty.length > 0) {
        if (daysChecked.length > 0) {
          returnSchedule(action, body);
        } else {
          Toast(t('schedule.errors.notChecked'));
        }
      } else {
        Toast(t('schedule.errors.scheduleEmpty'));
      }
    }
  };

  handleChecked = (e, { value, checked }) => {
    let { localSchedule } = this.state;
    localSchedule = localSchedule.map(currentDay => {
      const elementToUpdate = localSchedule.findIndex(element => element.weekday === Number(value));
      if (Number(currentDay.weekday) === Number(value)) {
        localSchedule[elementToUpdate].checked = checked;
        localSchedule[elementToUpdate].disabledTime = !currentDay.disabledTime;
      }
      return currentDay;
    });
    this.setState({ localSchedule });
    this.enableButton();
  }

  handleStartTime1 = event => {
    const { t } = this.props;
    const { id, value } = event.target;
    let { localSchedule } = this.state;
    localSchedule = localSchedule.map(currentDay => {
      const elementToUpdate = localSchedule.findIndex(element => element.weekday === currentDay.weekday);
      if (Number(currentDay.weekday) === Number(id)) {
        localSchedule[elementToUpdate].startTime1 = value;
        const start = value.split(':');
        const end = localSchedule[elementToUpdate].endTime1 ? localSchedule[elementToUpdate].endTime1.split(':') : [0, 0];
        const startTime1 = (Number(start[0]) * 60) + Number(start[1]);
        const endTime1 = (Number(end[0]) * 60) + Number(end[1]);
        if (localSchedule[elementToUpdate].startTime1.length !== 0 && localSchedule[elementToUpdate].endTime1.length !== 0) {
          if (startTime1 > endTime1) {
            localSchedule[elementToUpdate].descriptionError = t('La hora de inicio no debe ser mayor a la hora de fin');
            localSchedule[elementToUpdate].errorStartTime1 = true;
          } else {
            delete localSchedule[elementToUpdate].errorStartTime1;
            delete localSchedule[elementToUpdate].errorEndTime1;
            delete localSchedule[elementToUpdate].descriptionError;
          }
        }
      }
      return currentDay;
    });
    this.setState({ localSchedule });
  }

  handleEndTime1 = event => {
    const { t } = this.props;
    const { id, value } = event.target;
    let { localSchedule } = this.state;
    localSchedule = localSchedule.map(currentDay => {
      const elementToUpdate = localSchedule.findIndex(element => element.weekday === currentDay.weekday);
      if (Number(currentDay.weekday) === Number(id)) {
        localSchedule[elementToUpdate].endTime1 = value;
        const end = value.split(':');
        const start = localSchedule[elementToUpdate].startTime1 ? localSchedule[elementToUpdate].startTime1.split(':') : [0, 0];
        const startTime1 = (Number(start[0]) * 60) + Number(start[1]);
        const endTime1 = (Number(end[0]) * 60) + Number(end[1]);
        if (localSchedule[elementToUpdate].startTime1.length !== 0 && localSchedule[elementToUpdate].endTime1.length !== 0) {
          if (startTime1 > endTime1) {
            localSchedule[elementToUpdate].descriptionError = t('La hora de fin no debe ser menor a la hora de inicio');
            localSchedule[elementToUpdate].errorEndTime1 = true;
          } else {
            delete localSchedule[elementToUpdate].errorStartTime1;
            delete localSchedule[elementToUpdate].errorEndTime1;
            delete localSchedule[elementToUpdate].descriptionError;
          }
        }
      }
      return currentDay;
    });
    this.setState({ localSchedule });
  }

  handleClickAllEquals = () => {
    const { localSchedule } = this.state;
    let errors = false;
    let dayChecked = '';

    localSchedule.map(day => {
      if (day.descriptionError) errors = true;
      return day;
    });
    if (errors === false) {
      dayChecked = localSchedule.find(day => (day.checked === true));
      localSchedule.map(currentDay => {
        const elementToUpdate = localSchedule.findIndex(element => element.weekday === currentDay.weekday);
        if (currentDay.weekday !== dayChecked.weekday) {
          if (dayChecked.startTime1 || dayChecked.endTime1) {
            if (dayChecked.startTime1) localSchedule[elementToUpdate].startTime1 = dayChecked.startTime1;
            if (dayChecked.endTime1) localSchedule[elementToUpdate].endTime1 = dayChecked.endTime1;
            localSchedule[elementToUpdate].checked = true;
            localSchedule[elementToUpdate].disabledTime = false;
          } else {
            errors = true;
          }
        }
        return currentDay;
      });
      if (errors === false) this.setState({ localSchedule, disabledButton: true });
      else Toast('No se permite repetir valores vacios', 'warning');
    }
  }

  enableButton = () => {
    const { localSchedule, validateErrors } = this.state;
    const daysChecked = [];
    localSchedule.map(day => {
      if (day.checked === true) daysChecked.push(day.weekday);
      return day;
    });
    if (daysChecked.length === 1 && validateErrors !== true) { this.setState({ disabledButton: false }); } else { this.setState({ disabledButton: true }); }
  }

  handleClickSave = action => {
    const { localSchedule } = this.state;
    const error = localSchedule.find(day => (day.descriptionError));
    if (!error) this.handleAddSchedule(action);
  }

  render() {
    const { t, id, onboarding } = this.props;
    const { localSchedule, disabledButton } = this.state;
    return (
      <Grid container>
        {onboarding
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Header as="h3">{t('branchOffice.title2')}</Header>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {onboarding
          ? (
            <Grid.Row columns={2}>
              <Grid.Column largeScreen={3} computer={3} tablet={3}>
                <Responsive as={Header} minWidth={768}>
                  <Header as="h4" textAlign="right">{t('branchOffice.subtitle1')}</Header>
                </Responsive>
              </Grid.Column>
              <Grid.Column largeScreen={9} computer={9} tablet={9}>
                <Responsive as={Header} minWidth={768}>
                  <Header textAlign="center" as="h4">{t('branchOffice.subtitle2')}</Header>
                </Responsive>
              </Grid.Column>
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
                <Button color="blue" disabled={disabledButton} onClick={this.handleClickAllEquals}>{t('schedule.sameSchedule')}</Button>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {onboarding
          ? (
            <Grid container>
              {localSchedule.map(day => (
                <Grid.Row columns={6} key={day.weekday}>
                  <Responsive as={Header} minWidth={768}>
                    <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={1} />
                  </Responsive>
                  <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
                    <Checkbox
                      label={day.name}
                      value={day.weekday}
                      onClick={this.handleChecked}
                      checked={day.checked}
                    />
                  </Grid.Column>
                  <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
                    <TextField
                      id={day.weekday}
                      label={t('schedule.since')}
                      type="time"
                      error={day.errorStartTime1}
                      value={day.startTime1}
                      onChange={this.handleStartTime1}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 1800 }}
                      disabled={day.disabledTime}
                    />
                  </Grid.Column>
                  <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
                    <TextField
                      id={day.weekday}
                      label={t('schedule.until')}
                      type="time"
                      error={day.errorEndTime1}
                      value={day.endTime1}
                      onChange={this.handleEndTime1}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 1800 }}
                      disabled={day.disabledTime}
                    />
                  </Grid.Column>
                  <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={16}>
                    <p style={{ color: 'red' }}>{day.descriptionError}</p>
                  </Grid.Column>
                </Grid.Row>
              ))}
            </Grid>
          )
          : null}
        <Grid.Row centered>
          {!id
            ? (
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
                <Button fluid color="blue" onClick={() => this.handleClickSave(1)}>{t('save')}</Button>
              </Grid.Column>
            )
            : null}
          {id
            ? (
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
                <Button fluid color="blue" onClick={() => this.handleClickSave(2)}>{t('update')}</Button>
              </Grid.Column>
            )
            : null}
        </Grid.Row>
      </Grid>
    );
  }
}

Schedule.propTypes = {
  id: PropTypes.string,
  returnSchedule: PropTypes.func,
  onboarding: PropTypes.bool,
};

Schedule.defaultProps = {
  id: '',
  onboarding: true,
  returnSchedule: () => {},
};

export default translate('common', { wait: true })(Schedule);
