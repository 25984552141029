import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Header, Icon, Button, Container, Confirm,
} from 'semantic-ui-react';
import validator from 'validator';
import { TextField } from '../common/components/materials';
import finalUserService from './services';
import routes from '../route-names';
import Toast from '../common/components/toast';
import wellcomeServices from '../welcome/services';
import SiclikTable from '../common/components/table';
import ClientOptions from './components/add';
import { escapeReasonAccents } from '../common/utils';

class FinalUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersArray: [],
      shortnameError: '',
      reason: '',
      reasonError: '',
      getOfIntelisis: false,
      rfc: '',
      disableRfc: false,
      rfcError: '',
      shortname: '',
      filter: '',
      toAddCustomer: false,
      filteError: '',
      confirmDelete: false,
      userToDelete: '',
      crmid: '',
      finalUserId: '',
      usersArrayFiltered: [],
      clean: false,
      openAdd: false,
      loader: false,
      headers: [
        { key: 'id', isKey: true },
        { key: 'crmid', isKey: true },
        { key: 'tradingName', name: 'Razón social' },
        { key: 'rfc', name: 'RFC' },
        { key: 'name', name: 'Nombre' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleDeleteFinalUser(row)} />
            </Grid.Row>
          ), // eslint-dsable-line
        },
      ],
      industryValue: '',
      industryError: '',
      sectorError: '',
      sectorValue: '',
      sectors: ['Privado', 'Público / Gobierno'],
      headersOptions: [
        { key: 'tradingName', isKey: true },
        { key: 'tradingName', name: 'Razón social' },
        { key: 'rfc', name: 'RFC' },
        { key: 'industry', name: 'Vertical' },
      ],
      industries: [],
      industriesNew: [],
      clients: [],
      messageTable: '',
      exist: true,
      address: {
        loader: false,
        error: '',
        postalCode: '',
        state: '',
        country: '',
      },
    };
  }

  componentDidMount() {
    this.handleGetFinalUsers();
    this.getIndustries();
  }

  setCategories = industries => {
    const { t } = this.props;
    return industries.map(industry => {
      switch (industry.category) {
        case 'Agro Industria':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.agroindustry'),
          };
        case 'Construcción':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.building'),
          };
        case 'Educación':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.education'),
          };
        case 'Energía y medio ambiente':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.energyEnvironment'),
          };
        case 'Financiero':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.financial'),
          };
        case 'Manufactura':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.manufacture'),
          };
        case 'Medios y entretenimiento':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.media'),
          };
        case 'Minería':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.mining'),
          };
        case 'Química / Farmacéutica':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.chemistry'),
          };
        case 'Retail y Comercializadoras':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.retail'),
          };
        case 'Salud':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.health'),
          };
        case 'Servicios':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.services'),
          };
        case 'TIC':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.IT'),
          };
        case 'Transporte':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.transport'),
          };
        case 'Autónomo':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.autonomus'),
          };
        case 'Estatal':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.state'),
          };
        case 'Federal':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.federal'),
          };
        case 'Municipal':
          return {
            ...industry,
            value: industry.category,
            category: t('finaluser.municipal'),
          };
        case 'Sector Público':
          return t('finaluser.public');
        default:
          return null;
      }
    }).filter(Boolean);
  }

  getLegacyIndustryData = industry => {
    switch (industry) {
      case 'AGROINDUSTRIA':
        return 'Agro Industria';
      case 'CONSTRUCCIÓN':
        return 'Construcción';
      case 'EDUCACIÓN':
        return 'Educación';
      case 'ENERGÍA Y MEDIO AMBIENTE':
        return 'Energía y medio ambiente';
      case 'FINANCIERO':
        return 'Financiero';
      case 'MANUFACTURA':
        return 'Manufactura';
      case 'MEDIOS Y ENTRETENIMIENTO':
        return 'Medios y entretenimiento';
      case 'MINERÍA':
        return 'Minería';
      case 'QUIMICA/FARMACEUTICA':
        return 'Química / Farmacéutica';
      case 'RETAIL Y COMERCIALIZADORAS':
        return 'Retail y Comercializadoras';
      case 'SALUD':
        return 'Salud';
      case 'SERVICIOS':
        return 'Servicios';
      case 'TIC':
        return 'TIC';
      case 'TRANSPORTE':
        return 'Transporte';
      case 'SECTOR PÚBLICO':
        return 'Sector Público';
      case 'AUTONOMO':
        return 'Autónomo';
      case 'ESTATAL':
        return 'Estatal';
      case 'FEDERAL':
        return 'Federal';
      case 'MUNICIPAL':
        return 'Municipal';
      default:
        return '';
    }
  };

  getIndustries = async () => {
    const { t } = this.props;
    try {
      const result = await finalUserService.getIndustries();
      const industries = this.setCategories(result.industries);
      this.setState({ industries });
    } catch (error) {
      Toast(t('finaluser.errors.notIndustriesFound'), 'error');
    }
  }

  handleGetFinalUsersByRFC = async () => {
    const { t } = this.props;
    const { rfc } = this.state;
    const rfcError = this.handleRFCValidations(rfc);
    if (!rfcError) {
      const arrayEndUsers = await finalUserService.getFinalUserByRFC(rfc).catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005 || Number(error.code) === 1000) this.setState({ messageTable: t('finaluser.emptyTable') });
        if (Number(error.code) === 1404) {
          Toast(t('finaluser.errors.rfcNotFound'), 'warning');
          this.setState({ clients: [], toAddCustomer: true });
        } else {
          if (!error) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
      if (arrayEndUsers && arrayEndUsers.endUsers) {
        this.setState({ clients: arrayEndUsers.endUsers });
        if (arrayEndUsers.endUsers.length < 1) Toast(t('finaluser.errors.rfcNotFound'), 'warning');
        this.setState({ toAddCustomer: false });
      }
    } else {
      this.setState({ rfcError });
    }
  }

  handleGetFinalUsers = async () => {
    const { t } = this.props;
    const arrayEndUsers = await finalUserService.getFinalUser()
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005 || Number(error.code) === 1000) {
          this.setState({ messageTable: t('finaluser.emptyTable') });
        }
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`${error.message}`);
        Toast(messageError, 'error');
      });
    if (arrayEndUsers && arrayEndUsers.endUsers) {
      arrayEndUsers.endUsers = arrayEndUsers.endUsers.filter(endUser => endUser.rfc && endUser.rfc.length > 0);
      this.setState({ usersArray: arrayEndUsers.endUsers, usersArrayFiltered: arrayEndUsers.endUsers });
      if (arrayEndUsers.endUsers.length > 0) this.handleUpdateStep();
    }
  }

  handleDeleteCapitalRegime = ({ target }) => {
    const { value } = target;
    const reason = escapeReasonAccents(value).toUpperCase().concat(' ')
      .replaceAll('.', '')
      .replace(' AC ', '')
      .replace(' SA DE CV ', '')
      .replace(' S DE RL DE CV ', '')
      .replace(' SC DE RL DE CV ', '')
      .replace(' S DE RL ', '')
      .replace(' SAPI DE CV ', '')
      .replace(' SPR DE RL ', '')
      .replace(' SAS DE CV ', '')
      .replace(' S EN NC ', '')
      .replace(' S EN CS ', '')
      .replace(' S COOP ', '')
      .replace(' SAPI ', '')
      .replace(' S COM ', '')
      .replace(' SAC ', '')
      .replace(' SA ', '')
      .replace(' SAS ', '')
      .replace(' SC ', '')
      .replace(' SCL ', '')
      .replace(' SCS ', '')
      .replace(' SLNE ', '')
      .replace(' SLL ', '')
      .replace(' SL ', '');
    this.setState({ reason: reason.replace(/\s*$/, ''), reasonError: '' });
  }

  handleReasonChange = ({ target }) => {
    const { value } = target;
    const reason = escapeReasonAccents(value).toUpperCase();
    const { t } = this.props;
    const maxLength = 50;
    if (reason && reason.length > 0) {
      if (!validator.isAlphanumeric(reason.replace(/ /g, ''), ['es-ES'])) {
        this.setState({ reason, reasonError: t('finaluser.errors.notSymbol') });
        return;
      }
      if (reason.length > maxLength) { this.setState({ reason, reasonError: t('finaluser.errors.toLong') }); return; }
    }
    this.setState({ reason, reasonError: '' });
  }

  handleRFCChange = event => {
    const rfcRegexNotSymbol = /^[A-Z\d&]*$/;
    const rfcRegexLowerCase = /(?=.*?[a-z])/;
    const { t } = this.props;
    const maxLength = 13;
    const { value: rfc } = event.target;
    this.setState({ toAddCustomer: false, disableRfc: false });
    if (rfc && rfc.length > 0 && rfc.length > maxLength) {
      this.setState({ rfc, rfcError: t('finaluser.errors.toLong') });
    } else if (rfc.length > 0 && rfcRegexLowerCase.test(rfc)) {
      this.setState({ rfc, rfcError: t('finaluser.errors.notLowerCase') });
    } else if (rfc.length > 0 && !rfcRegexNotSymbol.test(rfc)) {
      this.setState({ rfc, rfcError: t('finaluser.errors.notSymbol') });
    } else {
      this.setState({ rfc, rfcError: '' });
    }
  }

  handleShortNameChange = event => {
    const { t } = this.props;
    const maxLength = 100;
    let shortnameError = '';
    const { value: shortname } = event.target;
    if (shortname && shortname.length > 0) {
      if (!validator.isAlpha(shortname.replace(/ /g, ''), ['es-ES'])) shortnameError = t('finaluser.errors.notAlpha');
      if (shortname.length > maxLength) shortnameError = t('finaluser.errors.toLong');
      this.setState({ shortname, shortnameError });
    } else {
      this.setState({ shortname, shortnameError: '' });
    }
  }

  handleIndustryChange = (e, { value }) => {
    this.setState({
      industryValue: value,
      industryError: '',
    });
  }

  handleGetIndustriesBySector = sector => {
    const { industries } = this.state;
    const industriesNew = industries.filter(industry => industry.sectors.includes(sector));

    this.setState({ industriesNew });
  }

  handleSector = (e, { value }) => {
    this.handleGetIndustriesBySector(value);
    this.setState({ sectorValue: value, sectorError: '', industryValue: '' });
  }

  validations = type => {
    const {
      reason, rfc, shortname, getOfIntelisis, industryValue, exist, sectorValue, address, finalUserId,
    } = this.state;
    let {
      rfcError, reasonError, shortnameError, industryError, sectorError,
    } = this.state;
    let addressError;
    const { t } = this.props;
    let mistakes = false;
    rfcError = this.handleRFCValidations(rfc);
    // eslint-disable-next-line no-useless-escape
    if (!getOfIntelisis && !finalUserId && reason.length !== 0 && !validator.isAlphanumeric(reason.replace(/ /g, ''), ['es-ES'])) {
      reasonError = t('finaluser.errors.notSymbol');
      mistakes = true;
    }
    if (!getOfIntelisis && reason.length > 0 && reason.length < 5) {
      reasonError = t('finaluser.errors.toShort');
      mistakes = true;
    }
    if (reason.length === 0) {
      reasonError = t('finaluser.errors.empty');
      mistakes = true;
    }
    if ((shortname.trim()).length === 0) {
      shortnameError = t('finaluser.errors.empty');
      mistakes = true;
    }
    if (!getOfIntelisis && (reasonError).length !== 0) mistakes = true;
    if (rfcError.length !== 0) mistakes = true;
    if ((shortnameError).length !== 0) mistakes = true;
    if (!exist && !industryValue) {
      mistakes = true;
      industryError = t('finaluser.errors.empty');
    }
    if (!exist && !sectorValue) {
      mistakes = true;
      sectorError = t('finaluser.errors.empty');
    }
    if (!exist && (!address.country || !address.state)) {
      mistakes = true;
      addressError = t('finaluser.errors.empty');
    }
    if (mistakes === true) {
      if (exist && (!rfc || !shortname || !reason)) Toast(t('finaluser.errors.chooseClient'), 'error');
      if (!exist) {
        this.setState({
          reasonError, rfcError, shortnameError, industryError, sectorError, address: { ...address, error: addressError },
        });
      }
    } else {
      if (type === 1) this.handleClickSave();
      if (type === 2) this.handleUpdateFinalUser();
    }
  }

  handleUpdateStep = () => {
    const { t, updateStep, onboarding } = this.props;
    const body = { stepId: 4, status: 1 };
    const stepToUpdate = { key: 4, completed: true };
    if (onboarding) {
      wellcomeServices.updateStep(body)
        .then(() => updateStep(stepToUpdate))
        .catch(error => {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`${error.message}`);
          Toast(messageError, 'error');
        });
    }
  }

  handleClickSave = () => {
    const {
      t, getNewUser, steps, addFinalUser,
    } = this.props;
    const {
      reason, rfc, shortname, industryValue, usersArray, filter, sectorValue, address,
    } = this.state;
    const body = {
      tradingName: reason,
      name: shortname,
      rfc: rfc.toUpperCase(),
      industry: industryValue,
      sector: sectorValue,
      state: address.state,
      country: address.country,
    };
    this.setState({ loader: true });
    const exist = usersArray.findIndex(user => user.rfc === rfc);
    if (exist < 0) {
      finalUserService.addFinalUser(body)
        .then(response => {
          usersArray.push({ crmid: response.crmid, id: response.id, ...body });
          if (getNewUser) {
            const userData = {
              key: response.id,
              value: response.id,
              text: reason,
            };
            getNewUser(userData);
            if (addFinalUser) {
              addFinalUser(userData);
            }
          }
          Toast(t('finaluser.successAdd'), 'success');
          this.setState({
            reason: '',
            rfc: '',
            crmid: '',
            shortname: '',
            industryValue: '',
            sectorValue: '',
            industriesNew: [],
            loader: false,
            address: {
              loader: false,
              error: '',
              state: '',
              country: '',
            },
            usersArray,
            openAdd: false,
            exist: false,
            clean: true,
            getOfIntelisis: false,
          });
          if (!steps[3].completed) this.handleUpdateStep();
          this.handleFilter(filter, usersArray);
        }).catch(error => {
          this.setState({ loader: false });
          let messageError = '';
          if (error.message === 'No existe el proyecto' || error.message === 'El rfc ya fue dado de alta') {
            messageError = 'El RFC ingresado ya fue dado de alta anteriormente.';
            Toast(messageError, 'warning');
            this.handleClickCancel();
          } else if (!error.code) {
            messageError = t('error-codes.default');
          } else {
            messageError = t(`${error.message}`);
            Toast(messageError, 'error');
          }
        });
    } else {
      this.setState({ loader: false });
      Toast(t('finaluser.errors.existClient'), 'warning');
    }
  }

  handleNextClick = nextStep => {
    const { history } = this.props;
    history.push(nextStep);
  }

  goBack = backStep => {
    const { history } = this.props;
    history.push(backStep);
  }

  handleDeleteFinalUser = row => {
    this.setState({
      userToDelete: row.name, confirmDelete: true, finalUserId: row.id,
    });
  }

  handleDeleteConfirmed = () => {
    const { finalUserId, filter, usersArray } = this.state;
    const { t } = this.props;
    finalUserService.disableEndUser(finalUserId).then(() => {
      Toast(t('finaluser.eliminationConfirmation'), 'success');
      const userToDeleted = usersArray.findIndex(user => user.id === finalUserId);
      usersArray.splice(userToDeleted, 1);
      this.handleFilter(filter, usersArray);
      this.setState({ usersArray, confirmDelete: false });
    }).catch(error => Toast(t(`error-codes.${error.message}`, 'error')));
  }

  handleCancel = () => this.setState({ confirmDelete: false });

  handleClickUpdate = row => {
    const { industries } = this.state;
    let industryValue;
    const newCategories = industries.find(industry => industry.value === row.industry);
    if (!newCategories) {
      const legacyCategory = this.getLegacyIndustryData(row.industry);
      industryValue = legacyCategory;
    } else {
      industryValue = row.industry;
    }
    this.handleGetIndustriesBySector(row.sector);
    this.setState({
      finalUserId: row.id,
      reason: row.tradingName,
      rfc: row.rfc,
      crmid: row.crmid,
      shortname: row.name,
      industryValue,
      sectorValue: row.sector,
      loader: false,
      address: {
        loader: false,
        error: '',
        state: row.state,
        country: row.country,
      },
      reasonError: '',
      rfcError: '',
      shortnameError: '',
      openAdd: true,
      exist: false,
    });
  };

  handleUpdateFinalUser = () => {
    const { t } = this.props;
    const {
      reason, rfc, shortname, finalUserId, usersArray, filter, industryValue, sectorValue, address, crmid,
    } = this.state;
    const finalUserData = {
      id: finalUserId,
      crmid,
      tradingName: reason,
      name: shortname,
      rfc: rfc.toUpperCase(),
      industry: industryValue,
      sector: sectorValue,
      state: address.state,
      country: address.country,
    };
    this.setState({ loader: true });
    finalUserService.updateEndUser(finalUserData).then(() => {
      Toast(t('finaluser.successUpdate'), 'success');
      const userToUpdate = usersArray.findIndex(user => user.id === finalUserId);
      usersArray[userToUpdate].tradingName = reason;
      usersArray[userToUpdate].name = shortname;
      usersArray[userToUpdate].rfc = rfc;
      usersArray[userToUpdate].industry = industryValue;
      usersArray[userToUpdate].sector = sectorValue;
      usersArray[userToUpdate].state = address.state;
      usersArray[userToUpdate].country = address.country;
      this.setState({
        usersArray,
        crmid: '',
        rfc: '',
        shortname: '',
        reason: '',
        industryValue: '',
        industriesNew: [],
        sectorValue: '',
        loader: false,
        address: {
          loader: false,
          error: '',
          state: '',
          country: '',
        },
        finalUserId: '',
        openAdd: false,
        exist: false,
      });
      this.handleFilter(filter, usersArray);
    }).catch(error => {
      this.setState({ loader: false });
      Toast(t(`error-codes.${error.message}`, 'error'));
    });
  }

  handleClickCancel = () => {
    this.handleGetFinalUsers();
    this.setState({
      finalUserId: '',
      reason: '',
      rfc: '',
      disableRfc: false,
      shortname: '',
      industryValue: '',
      sectorValue: '',
      industriesNew: [],
      industryError: '',
      sectorError: '',
      reasonError: '',
      rfcError: '',
      shortnameError: '',
      address: {
        loader: false,
        postalCode: '',
        state: '',
        country: '',
      },
      clients: [],
      getOfIntelisis: false,
      exist: true,
      clean: true,
      openAdd: false,
      toAddCustomer: false,
    });
  }

  handleRFCValidations = rfc => {
    const rfcRegex = /^(?!.*&.*&)[A-Z&]{3}[A-Z]?\d{6}[A-Z\d]{3}$/;
    const { t } = this.props;
    let { rfcError } = this.state;
    if (rfc.length === 0) {
      rfcError = t('finaluser.errors.empty');
    } else {
      if (rfc.length < 12 || rfc.length > 13) rfcError = t('finaluser.errors.notRFC');
      if (rfc.length === 13) {
        if (!rfcRegex.test(rfc)) {
          rfcError = t('finaluser.errors.notRFC');
        } else {
          const year = Number(rfc.substring(4, 6));
          const month = Number(rfc.substring(6, 8));
          const day = Number(rfc.substring(8, 10));
          const yearToValidate = year / 4;
          const yearbis = validator.isInt(`${yearToValidate}`);
          if (month > 0 && month < 14) {
            switch (month) {
              case 1: case 3: case 5: case 7: case 8: case 10: case 12:
                if (day < 1 || day > 31) rfcError = t('finaluser.errors.notRFC');
                break;
              case 4: case 6: case 9: case 11:
                if (day < 1 || day > 30) rfcError = t('finaluser.errors.notRFC');
                break;
              case 2:
                if ((yearbis && day < 1) || (yearbis && day > 29)) rfcError = t('finaluser.errors.notRFC');
                if ((!yearbis && day < 1) || (!yearbis && day > 28)) rfcError = t('finaluser.errors.notRFC');
                break;
              default: rfcError = ''; break;
            }
          } else {
            rfcError = t('finaluser.errors.notRFC');
          }
        }
      } else if (rfc.length === 12) {
        if (!rfcRegex.test(rfc)) {
          rfcError = t('finaluser.errors.notRFC');
        } else {
          const year = Number(rfc.substring(3, 5));
          const month = Number(rfc.substring(5, 7));
          const day = Number(rfc.substring(7, 9));
          const yearToValidate = year / 4;
          const yearbis = validator.isInt(`${yearToValidate}`);
          if (month > 0 && month < 13) {
            switch (month) {
              case 1: case 3: case 5: case 7: case 8: case 10: case 12:
                if (day < 1 || day > 31) rfcError = t('finaluser.errors.notRFC');
                break;
              case 4: case 6: case 9: case 11:
                if (day < 1 || day > 30) rfcError = t('finaluser.errors.notRFC');
                break;
              case 2:
                if ((yearbis && day < 1) || (yearbis && day > 29)) rfcError = t('finaluser.errors.notRFC');
                if ((!yearbis && day < 1) || (!yearbis && day > 28)) rfcError = t('finaluser.errors.notRFC');
                break;
              default: rfcError = ''; break;
            }
          } else {
            rfcError = t('finaluser.errors.notRFC');
          }
        }
      }
    }
    return rfcError;
  }

  handleGetClientsSelected = clientsData => {
    if (clientsData.rows[0]) {
      this.handleGetIndustriesBySector(clientsData.rows[0].sector);
      this.setState({
        rfc: clientsData.rows[0].rfc,
        crmid: clientsData.rows[0].crmid,
        shortname: clientsData.rows[0].tradingName,
        reason: clientsData.rows[0].tradingName,
        industryValue: clientsData.rows[0].industry,
        sectorValue: clientsData.rows[0].sector,
        showAdd: true,
        reasonError: '',
        rfcError: '',
        shortnameError: '',
        getOfIntelisis: true,
        address: {
          loader: false,
          error: '',
          postalCode: '',
          state: clientsData.rows[0].state,
          country: clientsData.rows[0].country,
        },
        exist: false,
      });
    }
    if (clientsData.rows.length === 0) {
      this.setState({
        getOfIntelisis: false,
        rfc: '',
        crmid: '',
        shortname: '',
        reason: '',
        reasonError: '',
        rfcError: '',
        industryValue: '',
        shortnameError: '',
        clean: true,
      });
    }
  }

  handleFilterUsers = event => {
    const { usersArray } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, usersArray);
  }

  handleFilter = (filter, usersArray) => {
    const arrayUsersCopy = JSON.parse(JSON.stringify(usersArray));
    let foundData = [];
    this.setState({ filter });
    if (!filter) {
      foundData = usersArray;
    } else {
      foundData = arrayUsersCopy.filter(filterInfo => (filterInfo.name && filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.tradingName && filterInfo.tradingName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.rfc && filterInfo.rfc.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    }
    this.setState({ filter, usersArrayFiltered: foundData });
  }

  handleOnClikAddress = async () => {
    const { address } = this.state;
    try {
      this.setState({ address: { ...address, loader: true } });
      const { addresses } = await finalUserService.getAddressByPostalCode(address.postalCode);
      const foundAddress = addresses[0];
      this.setState({ address: { state: foundAddress.state, country: foundAddress.country, loader: false } });
    } catch (error) {
      const { t } = this.props;
      this.setState({ address: { ...address, loader: false } });
      Toast(t('error-codes.1401', 'error'));
    }
  }

  handleOnChangeAddress = ({ target }) => {
    const postalCode = target.value;
    const { address } = this.state;
    this.setState({ address: { ...address, postalCode } });
  };

  render() {
    const { t, steps, onboarding } = this.props;
    const {
      reason, reasonError, rfc, rfcError, shortname, shortnameError, headers, usersArrayFiltered, messageTable, industryValue, industriesNew,
      confirmDelete, userToDelete, finalUserId, clean, openAdd, headersOptions, clients, exist, filter, filteError, industryError, getOfIntelisis,
      sectorValue, sectors, sectorError, address, loader, toAddCustomer, showAdd, disableRfc,
    } = this.state;
    let nextStep = '';
    let backStep = '';
    if (onboarding) {
      if (steps.length === 4) {
        backStep = steps[2].href;
        nextStep = routes.dashboard;
      }
      if (steps.length > 4) {
        backStep = steps[2].href;
        nextStep = (steps.filter(step => step.key > 4))[0].href;
      }
    }
    let currentKey = 0;
    if (steps && steps.length > 0) currentKey = steps.find(step => step.key === 4);
    return (
      <Grid container>
        {onboarding
          ? (
            <Grid.Row columns={3} centered>
              {currentKey && currentKey.key === 4 && currentKey.completed === false
                && (
                  <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={3}>
                    <Header textAlign="center"><Icon onClick={() => this.goBack(backStep)} class="arrow" name="arrow circle left" size="huge" /></Header>
                    <Header textAlign="center" as="h4">{t('back')}</Header>
                  </Grid.Column>
                )}
              <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={7}>
                <Header color="blue" textAlign="center" as="h2">{t('finaluser.title')}</Header>
              </Grid.Column>
              {currentKey && currentKey.key === 4 && currentKey.completed === false
                && (
                  <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={3}>
                    <Header textAlign="center"><Icon onClick={() => this.handleNextClick(nextStep)} class="arrow" name="arrow circle right" size="huge" /></Header>
                    <Header as="h4" textAlign="center">{t('next')}</Header>
                  </Grid.Column>
                )}
            </Grid.Row>
          )
          : null}
        {onboarding
          ? (
            <Grid.Row centered>
              <Grid.Column width={11}>
                <Header textAlign="center" as="h4">{t('finaluser.description')}</Header>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        <Grid.Row>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={14} />
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={4} />
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={6}>
            <Button
              onClick={async () => {
                this.setState({
                  openAdd: true, finalUserId: '', exist: true, getOfIntelisis: false, showAdd: false, clients: [],
                });
              }}
              content="Agregar nuevo"
              labelPosition="left"
              icon="add user"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={1} />
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('finaluser.filter')}
              onChange={this.handleFilterUsers}
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={14} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Container style={{ overflowX: 'scroll' }}>
              <SiclikTable
                unstackable
                headers={headers}
                data={usersArrayFiltered}
                noDataMessage={messageTable}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={confirmDelete}
            header={t('finaluser.deleteFinalUser')}
            content={userToDelete}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleDeleteConfirmed}
          />
        </Grid.Row>
        <Grid.Row>
          <ClientOptions
            finalUserId={finalUserId}
            handleGetFinalUsersByRFC={this.handleGetFinalUsersByRFC}
            validations={this.validations}
            addCustomer={toAddCustomer}
            handleClickCancel={this.handleClickCancel}
            disableRfcCustomer={disableRfc}
            reason={{
              value: reason, error: reasonError, change: this.handleReasonChange, deleteCapitalRegime: this.handleDeleteCapitalRegime,
            }}
            rfc={{ value: rfc, error: rfcError, change: this.handleRFCChange }}
            shortname={{ value: shortname, error: shortnameError, change: this.handleShortNameChange }}
            industry={{
              value: industryValue, error: industryError, options: industriesNew, change: this.handleIndustryChange,
            }}
            address={{
              value: address,
              onClick: this.handleOnClikAddress,
              onChange: this.handleOnChangeAddress,
            }}
            sectors={{
              value: sectorValue,
              options: sectors,
              error: sectorError,
              onChange: this.handleSector,
            }}
            data={clients}
            getOfIntelisis={getOfIntelisis}
            showAdd={showAdd}
            headers={headersOptions}
            loader={loader}
            exist={exist}
            handleNewClient={() => this.setState({ exist: false, showAdd: true, disableRfc: true })}
            open={openAdd}
            handleGetSelectedOptions={this.handleGetClientsSelected}
            onCloseClick={() => this.setState({ openAdd: false })}
            clean={clean}
            updateClean={() => this.setState({ clean: !clean })}
          />
        </Grid.Row>
      </Grid>

    );
  }
}
FinalUser.defaultProps = {
  getNewUser: null,
  onboarding: true,
  addFinalUser: () => {},
};

FinalUser.propTypes = {
  getNewUser: PropTypes.func,
  addFinalUser: PropTypes.func,
  onboarding: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.shape({
    completed: PropTypes.bool,
    href: PropTypes.string,
  })).isRequired,
  updateStep: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(FinalUser);
