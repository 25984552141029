import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Table } from 'semantic-ui-react';

class SpecificationTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { specificationsData } = this.props;
    return (
      <Grid celled structured>
        <Grid.Row textAlign="left" computer={16} tablet={5} mobile={6} className="container_specifications">
          <Table padded>
            {specificationsData.map(specification => (
              <Table.Body>
                {specification.type.map(elemenAttribute => (
                  <Table.Row>
                    <Table.Cell style={{ backgroundColor: '#f6f6f6' }} width="5" rowSpan={specification.value.length + 1}>{elemenAttribute}</Table.Cell>
                  </Table.Row>
                ))}
                {specification.value.map(elementValue => (
                  <Table.Row>
                    <Table.Cell width="11">{elementValue}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            ))}
          </Table>
        </Grid.Row>
      </Grid>
    );
  }
}
SpecificationTable.propTypes = {
  specificationsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default translate('common', { wait: true })(SpecificationTable);
