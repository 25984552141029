import React, { Component } from 'react';
// import _ from 'lodash';
// import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
// import { Responsive, WidthProvider } from 'react-grid-layout';
import '../../common/react-grid-layout.css';
import '../../common/react-resizable.css';

// const ResponsiveReactGridLayout = WidthProvider(Responsive);

// function generateLayout() {
//   return _.map(_.range(0, 25), (item, i) => {
//     const y = Math.ceil(Math.random() * 4) + 1;
//     return {
//       x: Math.round(Math.random() * 5) * 2,
//       y: Math.floor(i / 6) * y,
//       w: 2,
//       h: y,
//       i: i.toString(),
//       static: Math.random() < 0.05,
//     };
//   });
// }

class ShowcaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // currentBreakpoint: 'lg',
      // compactType: 'vertical',
      // layouts: { lg: generateLayout() },
    };
  }

  componentDidMount() {
  }

  // onDrop = elemParams => {
  //   // alert(`Element parameters: ${JSON.stringify(elemParams)}`);
  // };

  // onBreakpointChange = breakpoint => {
  //   this.setState({ currentBreakpoint: breakpoint });
  // };

  onCompactTypeChange = () => {
    // const { compactType: oldCompactType } = this.state;
    // const compactType =
    //   oldCompactType === 'horizontal'
    //     ? 'vertical'
    //     : oldCompactType === 'vertical'
    //       ? null
    //       : 'horizontal';
    // this.setState({ compactType });
  };

  // onLayoutChange = (layout, layouts) => {
  //   this.props.onLayoutChange(layout, layouts);
  // };

  // onNewLayout = () => {
  //   this.setState({
  //     layouts: { lg: generateLayout() },
  //   });
  // };

  // generateDOM() {
  //   return _.map(this.state.layouts.lg, (l, i) => (
  //     <Grid key={i} className="blueBackground" padded>
  //       <Grid.Row className="blueBackground">
  //         <Grid.Column className="whiteText" largeScreen={16} computer={16} tablet={16} mobile={16}>
  //           { l.static ? `Static - ${i}` : i }
  //         </Grid.Column>
  //       </Grid.Row>
  //     </Grid>
  //   ));
  // }

  render() {
    return (
      <Grid padded>
        {/* <Grid.Row>
          Current Breakpoint:
          {' '}
          {this.state.currentBreakpoint}
          {' '}
          (
          {this.props.cols[this.state.currentBreakpoint]}
          {' '}
          columns)
        </Grid.Row>
        <Grid.Row>
          Compaction type:
          {' '}
          {_.capitalize(this.state.compactType) || 'No Compaction'}
        </Grid.Row> */}
        <Grid.Row>
          {/* <button onClick={this.onNewLayout}>Generate New Layout</button>
          <button onClick={this.onCompactTypeChange}>
            Change Compaction Type
          </button> */}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {/* <ResponsiveReactGridLayout
              {...this.props}
              layouts={this.state.layouts}
              onBreakpointChange={this.onBreakpointChange}
              onLayoutChange={this.onLayoutChange}
              onDrop={this.onDrop}
              // WidthProvider option
              measureBeforeMount
              // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
              // and set `measureBeforeMount={true}`.
              compactType={this.state.compactType}
              preventCollision={!this.state.compactType}
            >
              {this.generateDOM()}
            </ResponsiveReactGridLayout> */}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

// ShowcaseLayout.defaultProps = {
//   className: 'layout',
//   rowHeight: 30,
//   onLayoutChange: () => { },
//   cols: {
//     lg: 12, md: 10, sm: 6, xs: 4, xxs: 2,
//   },
// };

// ShowcaseLayout.propTypes = {
//   className: PropTypes.string,
//   rowHeight: PropTypes.number,
//   cols: PropTypes.shape({}),
//   onLayoutChange: PropTypes.func,
// };

export default ShowcaseLayout;
