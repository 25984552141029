import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Header, Grid, Divider, Label,
} from 'semantic-ui-react';
import { currencyFormat } from '../utils';

const styleTitle = {
  padding: '.5rem 1rem',
  backgroundColor: '#F2F2F2',
};

const stylePadding = {
  padding: '.5rem 1rem',
};

const SiclikTableMobile = props => {
  const { currency, ordersData } = props;
  const currencyTable = currency === 2 ? 'USD' : 'MXN';

  return (
    <Grid style={{ overflowY: 'auto', maxHeight: '100vh' }}>
      {ordersData.map(dataOrder => (
        <Grid.Row>
          <Grid.Column mobile={16} verticalAlign="middle" style={styleTitle}>
            <Header as="h4" className="no-margin" color="blue">
              {dataOrder.branchOffice}
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} verticalAlign="middle" style={styleTitle}>
            <Header as="h5" color="black">
              IVA:&nbsp;
              <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(dataOrder.iva, currencyTable)}</Header>
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} verticalAlign="middle" style={styleTitle}>
            <Header as="h5" color="black">
              Subtotal:&nbsp;
              <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(dataOrder.subtotal, currencyTable)}</Header>
            </Header>
          </Grid.Column>
          <Grid.Column mobile={16} verticalAlign="middle">
            <Grid>
              {dataOrder.items.map(dataitem => (
                <Grid.Row>
                  <Grid.Column mobile={16} verticalAlign="middle" style={stylePadding}>
                    <Header as="h5" color="blue">
                      Producto:&nbsp;
                      {dataitem.name}
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={6} verticalAlign="middle" style={stylePadding}>
                    <Header as="h5">
                      SKU:&nbsp;
                      <p style={{ display: 'inline' }}>{dataitem.sku}</p>
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={10} verticalAlign="middle" style={stylePadding}>
                    <Header as="h5">
                      Fabricante:&nbsp;
                      <p style={{ display: 'inline' }}>{dataitem.brand}</p>
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={4} verticalAlign="middle" style={stylePadding}>
                    <Header as="h5">
                      Cantidad
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={6} verticalAlign="middle" style={stylePadding}>
                    <Header as="h5">
                      Precio
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={6} verticalAlign="middle" style={stylePadding}>
                    <Header as="h5">
                      Precio Final
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={4} verticalAlign="middle">
                    <p>{dataitem.finalPrice}</p>
                  </Grid.Column>
                  <Grid.Column mobile={6} verticalAlign="middle">
                    <p>{currencyFormat(dataitem.price, currencyTable)}</p>
                  </Grid.Column>
                  <Grid.Column mobile={6} verticalAlign="middle">
                    <p>{currencyFormat(dataitem.finalPrice, currencyTable)}</p>
                  </Grid.Column>
                  <Grid.Column mobile={16} verticalAlign="middle">
                    <Divider style={{ margin: '.5rem 0' }} />
                  </Grid.Column>
                  {dataitem.coupons.length > 0
                    && (
                    <Grid.Column mobile={16} verticalAlign="middle">
                      {dataitem.coupons.map(coupon => (
                        <Grid>
                          <Grid.Row>
                            <Grid.Column mobile={6} verticalAlign="middle">
                              <Label as="a" color="blue" tag size="large">
                                {coupon.code}
                              </Label>
                            </Grid.Column>
                            <Grid.Column mobile={10} verticalAlign="middle">
                              <Header as="h5">
                                Descuento:&nbsp;
                                <p style={{ display: 'inline' }}>{currencyFormat(coupon.discount, currencyTable)}</p>
                              </Header>
                            </Grid.Column>
                            <Grid.Column mobile={16} verticalAlign="middle">
                              <Divider style={{ margin: '.5rem 0' }} />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      ))}
                    </Grid.Column>
                    )}
                </Grid.Row>
              ))}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};

SiclikTableMobile.defaultProps = {
  currency: 1,
  ordersData: [],
};

SiclikTableMobile.propTypes = {
  currency: PropTypes.number,
  ordersData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default translate('common', { wait: true })(SiclikTableMobile);
