import React, { useState } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Dropdown, List, Label, Icon, Segment, Message, Confirm, Form, Checkbox,
} from 'semantic-ui-react';
import propTypes from 'prop-types';
import _ from 'lodash';
import services from '../services';

const addingOptions = [
  { key: 1, text: 'Agregar por sku', value: 'skus' },
  { key: 2, text: 'Agregar por marca', value: 'brands' },
  { key: 3, text: 'Agregar por familia', value: 'families' },
  { key: 4, text: 'Agregar por categoria', value: 'categories' },
];

function getInitials(selectedTag) {
  const tags = selectedTag?.tags || [];
  const sku = selectedTag?.sku || '';
  return ({ tags, skus: sku ? [sku] : [] });
}

function AddTags(props) {
  const {
    t, handleClickCancel, skus,
    selectedTag, handleUpdateComplete,
    formatedSkus, formatedBrands, formatedFamilies, formatedCategories,
  } = props;

  const initial = getInitials(selectedTag);

  const [currentTags, setCurrentTags] = useState(initial.tags);
  const [fetching, setFetching] = useState(false);
  const [currentSkus, setCurrentSkus] = useState(initial.skus);
  const [addingType, setAddingType] = useState('skus');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [merge, setMerge] = useState(true);

  const deleteTag = tag => {
    setCurrentTags(_.without(currentTags, tag));
  };

  const deleteSku = sku => {
    setCurrentSkus(_.without(currentSkus, sku));
  };

  const cleanSkus = () => {
    setCurrentSkus([]);
  };

  const generateTags = async () => {
    setFetching(true);
    if (currentSkus > 1) return;
    const response = await services.generateTags({ skus: currentSkus })
      .catch(() => ({ tags: [] }));
    setFetching(false);
    setCurrentTags(response.tags);
  };

  const addTag = e => {
    e.preventDefault();
    const concatTags = [...currentTags, e.target.keyword.value];
    setCurrentTags(_.uniq(concatTags));
    e.target.reset();
  };

  const handleImport = (e, { value }) => {
    const toAdd = skus[value];
    if (!toAdd) return;
    const concatTags = _.concat(currentTags, toAdd);
    setCurrentTags(_.uniq(concatTags));
  };

  const handleAddBySku = (e, { value }) => {
    if (!value) return;
    setCurrentSkus(_.uniq([...currentSkus, value]));
  };

  const handleAddByGroup = (e, { value }) => {
    // eslint-disable-next-line react/destructuring-assignment
    const dataOrigin = props[addingType];
    const toAdd = dataOrigin[value];
    if (!toAdd) return;
    const concatSkus = _.concat(currentSkus, Object.keys(toAdd));
    setCurrentSkus(_.uniq(concatSkus));
  };

  const handleAddingType = (e, { value }) => {
    setAddingType(value);
  };

  const handleUpdateOrDelete = async (isDelete = false) => {
    if (isDelete) {
      await services.deleteTags({ skus: currentSkus });
      setConfirmDelete(false);
    } else {
      await services.addTags({ productTags: [{ skus: currentSkus, tags: currentTags }] });
      setConfirmUpdate(false);
    }
    handleUpdateComplete();
  };

  const handleClickSave = async () => {
    if (!currentSkus?.length) return;
    if (!currentTags?.length) {
      setConfirmDelete(true);
      return;
    }
    if (!merge) setConfirmUpdate(true);
    else {
      await services.mergeTags({ productTags: [{ skus: currentSkus, tags: currentTags }] });
      handleUpdateComplete();
    }
  };

  return (
    <Grid padded>
      <Grid.Row centered>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <Segment color="blue">
            <Grid padded>
              <Grid.Row centered>
                <Grid.Column computer={4} tablet={5} mobile={16}>
                  <Dropdown
                    options={addingOptions}
                    value={addingType}
                    fluid
                    onChange={handleAddingType}
                    selection
                    placeholder={t('tags.addTo')}
                  />
                </Grid.Column>
                <Grid.Column only="mobile" mobile={16} style={{ padding: '.5em' }} />
                {
                  addingType === 'skus' && (
                    <Grid.Column computer={6} tablet={7} mobile={8}>
                      <Dropdown
                        button
                        floating
                        fluid
                        labeled
                        className="icon pointer"
                        icon="add"
                        options={formatedSkus}
                        onChange={handleAddBySku}
                        search
                        text={t('tags.findSku')}
                      />
                    </Grid.Column>
                  )
                }
                {
                  addingType === 'brands' && (
                    <Grid.Column computer={6} tablet={7} mobile={8}>
                      <Dropdown
                        button
                        floating
                        fluid
                        labeled
                        className="icon pointer"
                        icon="add"
                        options={formatedBrands}
                        onChange={handleAddByGroup}
                        search
                        text={t('tags.findBrand')}
                      />
                    </Grid.Column>
                  )
                }
                {
                  addingType === 'families' && (
                    <Grid.Column computer={6} tablet={7} mobile={8}>
                      <Dropdown
                        button
                        floating
                        fluid
                        labeled
                        className="icon pointer"
                        icon="add"
                        options={formatedFamilies}
                        onChange={handleAddByGroup}
                        search
                        text={t('tags.findFamily')}
                      />
                    </Grid.Column>
                  )
                }
                {
                  addingType === 'categories' && (
                    <Grid.Column computer={6} tablet={7} mobile={8}>
                      <Dropdown
                        button
                        floating
                        fluid
                        labeled
                        className="icon pointer"
                        icon="add"
                        options={formatedCategories}
                        onChange={handleAddByGroup}
                        search
                        text={t('tags.findCategory')}
                      />
                    </Grid.Column>
                  )
                }
                <Grid.Column fluid computer={2} tablet={2} mobile={4}>
                  <Button color="primary" loading={fetching} disabled={(currentSkus.length !== 1 || fetching)} onClick={generateTags}>{t('tags.generateFromIA')}</Button>
                </Grid.Column>
                <Grid.Column fluid computer={4} tablet={2} mobile={4}>
                  <Button color="red" onClick={cleanSkus}>{t('tags.cleanSkus')}</Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column computer={16} tablet={16} mobile={8}>
                  <List horizontal>
                    {currentSkus.map(sku => (
                      <List.Item>
                        <Label key={sku}>
                          {sku}
                          <Icon name="delete" style={{ cursor: 'pointer' }} onClick={() => deleteSku(sku)} />
                        </Label>
                      </List.Item>
                    ))}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column computer={16} tablet={16} mobile={16}>
          <Segment color="blue">
            <Grid padded>
              <Grid.Row centered>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <Form onSubmit={addTag}>
                    <Form.Group>
                      <Form.Input
                        type="text"
                        placeholder={t('tags.newKeyword')}
                        name="keyword"
                      />
                      <Form.Button content={t('add')} />
                    </Form.Group>
                  </Form>
                </Grid.Column>
                <Grid.Column computer={8} tablet={8} mobile={8}>
                  <Dropdown
                    button
                    floating
                    labeled
                    className="icon pointer"
                    icon="upload"
                    options={formatedSkus}
                    onChange={handleImport}
                    search
                    text={t('tags.importFrom')}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column computer={16} tablet={16} mobile={16}>
                  <List horizontal>
                    {currentTags && currentTags.map(tag => {
                      const tagColor = selectedTag.generatedTagsHash[tag] ? 'blue' : '';
                      return (
                        <List.Item>
                          <Label color={tagColor} key={tag}>
                            {tag}
                            <Icon name="delete" style={{ cursor: 'pointer' }} onClick={() => deleteTag(tag)} />
                          </Label>
                        </List.Item>
                      );
                    })}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      {
        !currentSkus?.length && (
          <Grid.Row>
            <Grid.Column width={16}>
              <Message negative>
                <p>{t('tags.emptySkus')}</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )
      }
      <Confirm
        open={confirmDelete}
        header={t('tags.confirmDelete')}
        content={t('tags.confirmDeleteDescription')}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
        onCancel={() => setConfirmDelete(false)}
        onConfirm={() => handleUpdateOrDelete(true)}
      />
      <Confirm
        open={confirmUpdate}
        header={t('tags.confirmUpdate')}
        content={t('tags.confirmUpdateDescription')}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
        onCancel={() => setConfirmUpdate(false)}
        onConfirm={() => handleUpdateOrDelete()}
      />
      <Grid.Row>
        <Grid.Column width={12}>
          <Checkbox
            toggle
            onChange={(e, data) => setMerge(data.checked)}
            checked={merge}
            label={t('tags.mergeLabel')}
          />
        </Grid.Column>
        <Grid.Column width={4}>
          <Label color="blue" horizontal />
          <span>{t('tags.generatedByIA')}</span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="right">
          <Button color="blue" onClick={handleClickSave}>{t('save')}</Button>
          <Button onClick={handleClickCancel}>{t('cancel')}</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

AddTags.defaultProps = {
  t: null,
  handleClickCancel: null,
  handleUpdateComplete: null,
  selectedTag: {
    tags: [],
    sku: '',
    brand: '',
    family: '',
    category: '',
    generatedTagsHash: {},
  },
  skus: {},
  formatedSkus: [],
  formatedBrands: [],
  formatedFamilies: [],
  formatedCategories: [],
};

AddTags.propTypes = {
  t: propTypes.func,
  handleClickCancel: propTypes.func,
  handleUpdateComplete: propTypes.func,
  selectedTag: propTypes.shape({
    tags: propTypes.arrayOf(propTypes.string),
    sku: propTypes.string,
    brand: propTypes.string,
    family: propTypes.string,
    category: propTypes.string,
    generatedTagsHash: propTypes.shape({}),
  }),
  skus: propTypes.shape({}),
  formatedSkus: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string,
    text: propTypes.string,
    value: propTypes.string,
  })),
  formatedBrands: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string,
    text: propTypes.string,
    value: propTypes.string,
  })),
  formatedFamilies: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string,
    text: propTypes.string,
    value: propTypes.string,
  })),
  formatedCategories: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string,
    text: propTypes.string,
    value: propTypes.string,
  })),
};

export default translate('common', { wait: true })(AddTags);
