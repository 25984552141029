import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Item,
  Segment,
  Checkbox,
  Loader,
  Label,
  Button,
} from 'semantic-ui-react';

import { generateSearchPath } from '../../../common/utils';
import { getContentSource } from '../../commons';
import { DEFAULT_IMAGE, CONTENT_SOURCE } from '../../constants';

const translatePath = 'productConfig.menus.chooseContent.items';

function ItemList(props) {
  const {
    onChangeMenu,
    items: incomingItems,
    changeItemsChecked,
    isLoading,
    t,
  } = props;

  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(incomingItems || []);
  }, [incomingItems]);

  const getMainImage = images => {
    const mainImage = images.find(image => image.value.isMain);
    if (!mainImage) {
      return {
        src: DEFAULT_IMAGE,
        source: CONTENT_SOURCE.ERP,
      };
    }
    return {
      src: mainImage.value.low,
      source: getContentSource(mainImage.source, t),
    };
  };

  const handleOnSelectItem = (checked, sku) => {
    const newItemsChecked = items.map(item => (item.sku === sku ? { ...item, selected: checked } : item));
    setItems(newItemsChecked);
    changeItemsChecked(newItemsChecked);
  };

  const handleOnEditItem = item => {
    const currentPath = window.location.pathname;
    const queryParams = queryString.parseUrl(currentPath);
    queryParams.query.sku = item.sku;
    queryParams.query.brand = item.brand;
    const url = generateSearchPath({ type: 'url', value: queryParams.query }, { onlyParams: true });
    window.history.pushState(null, null, url);
    onChangeMenu('EditarContenido');
  };

  const ItemsCard = () => (
    items.map(item => (
      <Item key={item.sku}>
        <div style={{ marginRight: '5px' }}>
          <Checkbox
            checked={item.selected}
            onClick={(e, { checked }) => handleOnSelectItem(checked, item.sku)}
          />
        </div>
        <Item.Image size="small" src={getMainImage(item.images).src} />
        <Item.Content verticalAlign="middle">
          <Item.Header as="a">{item.sku}</Item.Header>
          <Item.Description>
            <span style={{ fontWeight: 'bold', color: 'green' }}>
              {t(`${translatePath}.name`)}
              {': '}
            </span>
            <Label size="mini">{getContentSource(item.title.source, t)}</Label>
            {' '}
            {item.title.value}
          </Item.Description>
          <Item.Description>
            <span style={{ fontWeight: 'bold', color: 'green' }}>
              {t(`${translatePath}.category`)}
              {': '}
            </span>
            <Label size="mini">{getContentSource(item.category.source, t)}</Label>
            {' '}
            {item.category.value}
          </Item.Description>
          <Item.Description>
            <span style={{ fontWeight: 'bold', color: 'green' }}>
              {t(`${translatePath}.family`)}
              {': '}
            </span>
            <Label size="mini">{getContentSource(item.family.source, t)}</Label>
            {' '}
            {item.family.value}
          </Item.Description>
          <Item.Description>
            <span style={{ fontWeight: 'bold', color: 'green' }}>
              {t(`${translatePath}.images`)}
              {': '}
            </span>
            <Label size="mini">{getMainImage(item.images).source}</Label>
            <Button
              primary
              size="small"
              floated="right"
              content="Edicion manual"
              onClick={() => handleOnEditItem(item)}
            />
          </Item.Description>
        </Item.Content>
      </Item>
    ))
  );

  const ItemLoader = () => (
    <Segment>
      <Loader active inline="centered">
        {t(`${translatePath}.loading`)}
      </Loader>
    </Segment>
  );

  const EmptyItems = () => (
    <Item>
      <Item.Content
        style={{ textAlign: 'center' }}
        content={t(`${translatePath}.noItems`)}
      />
    </Item>
  );

  const ItemComponent = () => {
    const hasItems = items.length > 0;
    if (isLoading) {
      return <ItemLoader />;
    }
    return hasItems
      ? <ItemsCard />
      : <EmptyItems />;
  };

  return (
    <Segment>
      <Item.Group divided>
        <ItemComponent />
      </Item.Group>
    </Segment>
  );
}

ItemList.propTypes = {
  onChangeMenu: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  changeItemsChecked: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    title: PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

export default translate('common', { wait: true })(ItemList);
