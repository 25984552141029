export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};

export const sheetHeaders = {
  id: { header: 'ID', width: 24 },
  userName: { header: 'Nombre del comprador', width: 24 },
  customerId: { header: 'No. cliente', width: 17 },
  email: { header: 'Correo', width: 50 },
  requestDate: { header: 'Fecha de creación', width: 12 },
};

export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
