/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Input, Button, Dropdown, Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

function CustomDropdown(props) {
  const {
    loadItems, t, loader, items, addItem, selectedItem, maker, isAvailableRedhat,
  } = props;
  const [value, setValue] = useState('');
  const [addItemLoader, setAddItemLoader] = useState(false);

  const handleOnChange = (e, target) => {
    setValue(target.value);
  };

  const handleOnAdd = () => {
    addItem(value);
  };

  const handleOnClose = () => {
    setValue('');
  };

  async function handleSelectedItem(item) {
    setAddItemLoader(true);
    await selectedItem(item.key);
    setAddItemLoader(false);
  }

  const preventClosing = e => {
    e.stopPropagation();
  };

  return (
    <Button.Group basic color="blue">
      <Dropdown
        disabled={!isAvailableRedhat && maker === 'Red Hat'}
        button
        className="button_addtofavorites icon"
        text={t('wishList.itemDetails.title')}
        icon="favorite"
        labeled
        floating
        onClick={loadItems}
        loading={addItemLoader}
        onClose={handleOnClose}
      >
        <Dropdown.Menu fluid onClick={preventClosing}>
          <Dropdown.Header content={t('wishList.itemDetails.create')} />
          <Dropdown.Header
            key="add"
            content={(
              <Input type="text" action size="medium" onChange={handleOnChange}>
                <input value={value} />
                <Button icon="save" onClick={handleOnAdd} />
              </Input>
          )}
          />
          <Dropdown.Header content={t('wishList.itemDetails.myLists')} />
          <Dropdown.Menu scrolling>
            {loader
              ? <Segment loading vertical padded="very" size="mini" />
              : items.map(item => <Dropdown.Item {...item} onClick={() => handleSelectedItem(item)} />)}
          </Dropdown.Menu>
        </Dropdown.Menu>
      </Dropdown>
    </Button.Group>
  );
}

CustomDropdown.propTypes = {
  loader: PropTypes.bool.isRequired,
  loadItems: PropTypes.func.isRequired,
  selectedItem: PropTypes.func.isRequired,
  addItem: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
  maker: PropTypes.string.isRequired,
  isAvailableRedhat: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(CustomDropdown);
