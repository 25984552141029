import {
  fetchUserWishLists,
  createUserWishList,
  renameUserWishList,
  deleteList,
} from './request';

import {
  SELECT_WISH_LIST,
  GET_WISH_LISTS,
  CREATE_WISH_LIST,
  CREATE_DEFAULT_WISH_LIST,
  RENAME_WISH_LIST,
  TOGGLE_SWITCH_NEW_LIST_MODAL,
  TOGGLE_SWITCH_RENAME_LIST_MODAL,
  DELETE_WISH_LIST,
} from './constants';

function selectWishList(dispatch) {
  return function actionDispatch(selectedList) {
    dispatch({
      type: SELECT_WISH_LIST,
      payload: { selectedList },
    });
  };
}

function getWishLists(dispatch) {
  return async function actionDispatch() {
    const wishLists = await fetchUserWishLists();
    dispatch({
      type: GET_WISH_LISTS,
      payload: { wishLists },
    });
  };
}

function createWishList(dispatch) {
  return async function actionDispatch(wishListName) {
    const wishListId = await createUserWishList(wishListName);
    dispatch({
      type: CREATE_WISH_LIST,
      payload: {
        wishList: {
          id: wishListId,
          name: wishListName,
        },
      },
    });
  };
}

function createDefaultWishList(dispatch) {
  return async function actionDispatch(wishListName, items) {
    const wishListId = await createUserWishList(wishListName, items);
    dispatch({
      type: CREATE_DEFAULT_WISH_LIST,
      payload: {
        wishList: {
          id: wishListId,
          name: wishListName,
        },
      },
    });
  };
}

function toggleSwitchRenameListModal(dispatch) {
  return function actionDispatch() {
    dispatch({
      type: TOGGLE_SWITCH_RENAME_LIST_MODAL,
    });
  };
}

function toggleSwitchNewListModal(dispatch) {
  return function actionDispatch() {
    dispatch({
      type: TOGGLE_SWITCH_NEW_LIST_MODAL,
    });
  };
}

function renameWishList(dispatch) {
  return async function actionDispatch(wishListId, wishListName) {
    await renameUserWishList(wishListId, wishListName);
    dispatch({
      type: RENAME_WISH_LIST,
      payload: {
        wishList: {
          id: wishListId,
          name: wishListName,
        },
      },
    });
  };
}

function deleteWishList(dispatch) {
  return async function actionDispatch(wishListId) {
    await deleteList(wishListId);
    dispatch({
      type: DELETE_WISH_LIST,
      payload: { wishListId },
    });
  };
}

export default {
  getWishLists,
  selectWishList,
  createWishList,
  createDefaultWishList,
  toggleSwitchRenameListModal,
  toggleSwitchNewListModal,
  renameWishList,
  deleteWishList,
};
