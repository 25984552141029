import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import {
  Segment,
  Grid,
  Container,
  Header,
  Button,
  Divider,
} from 'semantic-ui-react';

import ModalUploadImage from './modal-upload-image';
import ImageLoader from './image-loader';
import CardImages from './card-images';
import MenuBar from '../menu-bar';
import Toast from '../../../common/components/toast';

import service from '../../services';
import { CONTENT_SOURCE } from '../../constants';

const translatePath = 'productConfig.menus.uploadImages';

function getSizeUrl(sizeToSearch, sizes) {
  const foundSize = sizes.find(size => size.size === sizeToSearch);
  return foundSize ? foundSize.preview : null;
}

function UploadImages({ t }) {
  const [imageTypeSelected, setImageTypeSelected] = useState(false);
  const [findItemLoader, setFindItemLoader] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [itemContent, setItemContent] = useState(null);
  const [item, setItem] = useState(null);

  const getItemContent = async (sku, brand) => {
    try {
      setFindItemLoader(true);
      const foundItem = await service.getItemContentBySource(
        sku,
        brand,
        CONTENT_SOURCE.ERP,
      );
      setItemContent(foundItem);
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
    } finally {
      setFindItemLoader(false);
    }
  };

  const addImageUrls = (image, type) => (
    service.addImages({
      sku: item.sku,
      type: type === 'images' ? 'image' : type,
      thumbnail: getSizeUrl('thumbnail', image.sizes),
      low: getSizeUrl('low', image.sizes),
      medium: getSizeUrl('medium', image.sizes),
      big: getSizeUrl('big', image.sizes),
    })
  );

  const getImageFormat = (imagesToUpload, type) => (
    imagesToUpload.map((image, index) => {
      const generatedSizes = image.sizes.reduce((accu, size) => (
        { ...accu, [size.size]: size.preview }
      ), {});
      return {
        uploading: true,
        id: index + 1,
        type,
        isMain: false,
        ...generatedSizes,
      };
    })
  );

  const addSignedUrls = async (image, imageIndex) => {
    const outputExtension = 'jpeg';
    const bucketPath = `imagenes/siclik/${item.brand}/${item.sku}`;
    const timestamp = new Date().getTime();
    const files = image.sizes.map(({ size }, index) => ({
      size,
      bucketPath,
      name: `${timestamp}-${index + 1}-${imageIndex}-${size}.${outputExtension}`,
    }));
    const { signedUrls } = await service.generateSignedUrl(files);
    const addedSignedUrls = image.sizes.map(size => ({
      ...size,
      signedUrl: signedUrls.find(signedUrl => signedUrl.size === size.size)?.signedUrl,
    }));
    return { ...image, sizes: addedSignedUrls };
  };

  const uploadImages = async signedUrls => {
    const { sizes } = signedUrls;
    const uploadedImagesResult = await Promise.all(sizes.map(async size => {
      const { signedUrl, preview } = size;
      await service.uploadImage(signedUrl, preview);
      const [url] = signedUrl.split('?');
      return { ...size, preview: url };
    }));
    return { ...signedUrls, sizes: uploadedImagesResult };
  };

  const handleOnUpload = async imagesToUpload => {
    const newImages = [...itemContent[imageTypeSelected], ...getImageFormat(imagesToUpload, imageTypeSelected)];
    setItemContent({ ...itemContent, [imageTypeSelected]: newImages });
    await Promise.all(imagesToUpload.map(async (image, index) => {
      try {
        const signedUrls = await addSignedUrls(image, index + 1);
        const uploadedImages = await uploadImages(signedUrls);
        const addedImages = await addImageUrls(uploadedImages, imageTypeSelected);
        const updatedItem = newImages.findIndex(currentImage => currentImage.id === index + 1);
        newImages[updatedItem] = {
          ...newImages[updatedItem],
          id: addedImages.id,
          preview: uploadedImages.sizes.find(size => size.size === 'low')?.preview,
          uploading: false,
        };
        setItemContent({ ...itemContent, [imageTypeSelected]: newImages });
      } catch (error) {
        Toast(t('toast.error.general'), 'error');
      }
    }));
  };

  const handleOnDelete = async ({ id: imageId }, imageType) => {
    try {
      const setItemOnDelete = itemContent[imageType].map(image => ({
        ...image,
        deleting: image.id === imageId,
      }));
      setItemContent({ ...itemContent, [imageType]: setItemOnDelete });
      await service.deleteImage(imageId);
      const newImages = itemContent[imageType].filter(image => image.id !== imageId);
      setItemContent({ ...itemContent, [imageType]: newImages });
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
    }
  };

  const EmptyImages = () => (
    <Container
      style={{ marginTop: '12px', marginBottom: '12px' }}
      textAlign="center"
    >
      {t(`${translatePath}.noImages`)}
    </Container>
  );

  const handleItemLoader = (imageType, title, loader) => {
    if (itemContent && itemContent[imageType].length) {
      return (
        <CardImages
          title={title}
          imageType={imageType}
          images={itemContent[imageType]}
          setOpenImageModal={setOpenImageModal}
          handleOnDelete={handleOnDelete}
        />
      );
    }
    return loader
      ? <ImageLoader />
      : <EmptyImages />;
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sku = params.get('sku');
    const brand = params.get('brand');
    if (sku && brand) {
      setItem({ sku, brand });
      getItemContent(sku, brand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnGetItemContent = async (sku, brand) => {
    try {
      setFindItemLoader(true);
      const foundItem = await service.getItemContentBySource(
        sku,
        brand,
        CONTENT_SOURCE.ERP,
      );
      setItemContent(foundItem);
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
    } finally {
      setFindItemLoader(false);
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t(`${translatePath}.title`)}</Header>
          <Divider />
          <p>{t(`${translatePath}.description`)}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Segment style={{ backgroundColor: '#F5F5F5' }}>
            <Grid
              style={{
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 'auto',
              }}
            >
              <Grid.Row>
                <MenuBar
                  item={item}
                  setItemContent={handleOnGetItemContent}
                  setItem={setItem}
                  loading={findItemLoader}
                />
              </Grid.Row>
              <Grid.Row>
                <Container fluid>
                  <Segment>
                    <Header floated="left" as="h4">{t(`${translatePath}.images`)}</Header>
                    <Button
                      primary
                      floated="right"
                      content={t(`${translatePath}.uploadButton`)}
                      size="mini"
                      disabled={!itemContent}
                      onClick={() => {
                        setOpenImageModal(true);
                        setImageTypeSelected('images');
                      }}
                    />
                    <Divider clearing />
                    {handleItemLoader('images', t(`${translatePath}.images`), findItemLoader)}
                  </Segment>
                </Container>
              </Grid.Row>
              <Grid.Row>
                <Container fluid>
                  <Segment>
                    <Header floated="left" as="h4">{t(`${translatePath}.logos`)}</Header>
                    <Button
                      primary
                      floated="right"
                      content={t(`${translatePath}.uploadButton`)}
                      size="mini"
                      disabled={!itemContent}
                      onClick={() => {
                        setOpenImageModal(true);
                        setImageTypeSelected('featuredLogos');
                      }}
                    />
                    <Divider clearing />
                    {handleItemLoader('featuredLogos', t(`${translatePath}.logos`), findItemLoader)}
                  </Segment>
                </Container>
              </Grid.Row>
            </Grid>
            <ModalUploadImage
              open={openImageModal}
              isImageType={imageTypeSelected === 'images'}
              onClose={() => setOpenImageModal(false)}
              uploadImages={images => handleOnUpload(images)}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(UploadImages);
