import React, { useState } from 'react';
import { translate } from 'react-i18next';
import { Grid, Header, Button } from 'semantic-ui-react';
import SiclikTable from '../../../../common/components/table';
import services from '../services';
import { TextField } from '../../../../common/components/materials';
import AddExtendedArea from './AddExtendedArea';

function getTableHeader() {
  return ([
    { key: '_id', isKey: true },
    { key: 'postalCode', name: 'Código postal' },
    { key: 'branch', name: 'Sucursal' },
    { key: 'plaza', name: 'Plaza' },
    { key: 'delegationMunicipality', name: 'Delegación/Municipio' },
    { key: 'city', name: 'Ciudad' },
    { key: 'colonySettlement', name: 'Colonia/Asentamiento' },
    { key: 'extendedZone', name: 'Zona extendida' },
  ]);
}

function ExtendedAreaList(props) {
  const { t } = props;

  const headers = getTableHeader();

  const [postalCode, setPostalCode] = useState('');
  const [extendedAreas, setExtendedAreas] = useState([]);
  const [clean, setClean] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState('');
  const [openAddExtendedArea, setOpenAddExtendedArea] = useState(false);
  const updateClean = () => setClean(!clean);

  const getExtendedAreas = () => services.getExtendedAreas(postalCode);

  const handleChange = ({ target }) => {
    const { value } = target;
    setPostalCode(value);
  };

  const handleSearch = () => {
    if (!postalCode) {
      setPostalCodeError('El código postal es requerido');
    } else {
      setPostalCodeError('');
      getExtendedAreas()
        .then(response => {
          setExtendedAreas(response.extendedAreas);
        });
    }
  };

  const handleAdd = () => {
    setOpenAddExtendedArea(false);
    getExtendedAreas();
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('standardShipping.extendedArea')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle">
        <Grid.Column computer={5} tablet={6} mobile={8} verticalAlign="bottom">
          <TextField
            id="postalCode"
            fullWidth
            label={t('Código postal')}
            value={`${postalCode}` || ''}
            error={postalCodeError || ''}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column computer={2} tablet={6} mobile={8} verticalAlign="bottom">
          <Button
            secondary
            fluid
            size="tiny"
            onClick={handleSearch}
          >
            {t('Buscar')}
          </Button>
        </Grid.Column>
        <Grid.Column computer={2} tablet={6} mobile={8} verticalAlign="bottom" floated="right">
          {
            !openAddExtendedArea && (
              <Button
                primary
                fluid
                size="tiny"
                onClick={() => setOpenAddExtendedArea(true)}
              >
                {t('Agregar')}
              </Button>
            )
          }
        </Grid.Column>
        <Grid.Column only="mobile" mobile={16} style={{ paddingBottom: '2em' }} />
      </Grid.Row>
      {
        openAddExtendedArea && (
          <Grid.Row>
            <Grid.Column>
              <AddExtendedArea
                finishAdd={handleAdd}
              />
            </Grid.Column>
          </Grid.Row>
        )
      }
      <Grid.Row>
        <Grid.Column>
          <SiclikTable
            selectable
            clean={clean}
            updateClean={updateClean}
            data={extendedAreas}
            headers={headers}
            pageSize={10}
            singleSelection
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ExtendedAreaList.propTypes = {
};

ExtendedAreaList.defaultProps = {
};

export default translate('common', { wait: true })(ExtendedAreaList);
