import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import actions from './actions';
import optionsReducer, { initialState } from './reducers';

const OptionsContext = createContext({});

export function useWishListOptions() {
  const contextValue = useContext(OptionsContext);
  if (!contextValue) {
    throw Error('El contexto de WishListOptions no fue encontrado');
  }
  return contextValue;
}

export default function OptionsProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(optionsReducer, initialState);
  const value = {
    ...state.toJS(),
    toggleSwitchAddItemModal: actions.toggleSwitchAddItemModal(dispatch),
    toggleSwitchBuyNowModal: actions.toggleSwitchBuyNowModal(dispatch),
    toggleSwitchDeleteListModal: actions.toggleSwitchDeleteListModal(dispatch),
    sendItemsToCart: actions.sendItemsToCart(dispatch),
  };

  return (
    <OptionsContext.Provider value={value}>
      {children}
    </OptionsContext.Provider>
  );
}

OptionsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
