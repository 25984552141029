import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Header,
  Image,
  Container,
  Icon,
} from 'semantic-ui-react';

import ScrollableBar from '../../../common/components/scrollable-bar';
import EmbedModal from './embed-modal';

function Multimedia({ featureLogos, videos, t }) {
  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);

  function handleOnClickVideo(item) {
    if (item.isVideo) {
      setVideo(item);
      setOpen(true);
    }
  }

  function setCarrusel() {
    const allItems = [
      ...featureLogos,
      ...videos.map(item => ({
        ...item,
        low: item.preview,
      })),
    ];
    return allItems.map(item => ({
      render: () => (
        <div>
          <Image
            key={item.id}
            size="tiny"
            style={{
              marginRight: '1em',
              marginLeft: '1em',
              cursor: item.isVideo ? 'pointer' : 'default',
              padding: '0',
            }}
            src={item.low}
          />
          {item.isVideo && (
            <Icon
              style={{
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontSize: '3em',
                cursor: item.isVideo ? 'pointer' : 'default',
              }}
              onClick={() => handleOnClickVideo(item)}
              name="play circle outline"
            />
          )}
        </div>
      ),
    }));
  }

  return (
    <Container style={{ maxWidth: '70%', marginLeft: 30 }}>
      <Header as="h4" color="green">{t('productDetails.moreInfo')}</Header>
      <ScrollableBar
        showNextPrev
        elements={setCarrusel()}
        duration={3000}
        iconSize="large"
      />
      {video && (
        <EmbedModal
          open={open}
          setOpen={setOpen}
          video={video}
        />
      )}
    </Container>
  );
}

Multimedia.propTypes = {
  featureLogos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    low: PropTypes.string,
  })),
  videos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    preview: PropTypes.string,
    description: PropTypes.string,
  })),
};

Multimedia.defaultProps = {
  featureLogos: [],
  videos: [],
};

export default translate('common', { wait: true })(Multimedia);
