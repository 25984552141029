import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import Table from '../../common/components/table';

const CarePacks = props => {
  const {
    t, headers, maker, products,
  } = props;
  return (
    <Grid container>
      <Grid.Row columns={1}>
        <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={15}>
          <Header>{`${maker} ${t('multibrand.carePacks')}`}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column style={{ overflowX: 'auto' }}>
          <Table headers={headers} data={products} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

CarePacks.defaultProps = {
  headers: [],
  products: [],
  maker: '',
  t: null,
};

CarePacks.propTypes = {
  headers: PropTypes.arrayOf(),
  products: PropTypes.arrayOf(),
  maker: PropTypes.string,
  t: PropTypes.func,
};

export default translate('common', { wait: true })(CarePacks);
