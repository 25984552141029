import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid, Header, Divider, Icon,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';

const ItemDivider = ({ text = '', icon = '' }) => (
  <Grid.Row>
    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
      {
        text
          ? (
            <Divider horizontal>
              <Header as="h4">
                {icon ? <Icon name={icon} /> : ''}
                {text}
              </Header>
            </Divider>
          )
          : <Divider />
      }
    </Grid.Column>
  </Grid.Row>
);

ItemDivider.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
};

ItemDivider.defaultProps = {
  icon: '',
  text: '',
};

export default translate('common', { wait: true })(ItemDivider);
