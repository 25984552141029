import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import {
  Button, Grid, Header, Divider, Confirm,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { fuse } from '../../../common/utils';
import Table from '../../../common/components/table';
import toast from '../../../common/components/toast';

import ModalDetails from './ModalDetails';
import SearchBar from './SearchBar';
import GuestUserConfirm from './ModalGuestUserConfirm';
import request from '../../services';

const SERVICE_ACCOUNT_USER = 'd9c2b0e7-ff59-45e5-98ea-beb09f8f290d';

function Users(props) {
  const { t, customerId } = props;
  const [activeModal, setModalActive] = useState(false);
  const [activeGuestUserModal, setActiveGuestUserModal] = useState(false);
  const [activeDeleteUserModal, setActiveDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [filter, setFilter] = useState('');

  function editAction(cell, user) {
    return (
      <Grid>
        <Button
          color="blue"
          icon="pencil alternate"
          disabled={user.id === SERVICE_ACCOUNT_USER}
          onClick={() => {
            setModalActive(true);
            setSelectedUser(user);
          }}
        />
        <Button
          color="blue"
          icon="trash alternate"
          disabled={user.id === SERVICE_ACCOUNT_USER}
          onClick={() => {
            setActiveDeleteUserModal(true);
            setSelectedUser(user);
          }}
        />
      </Grid>
    );
  }

  function guestAccessAction(cell, user) {
    return (
      <Button
        color="blue"
        icon="user md"
        disabled={user.id === SERVICE_ACCOUNT_USER}
        onClick={() => {
          setSelectedUser(user);
          setActiveGuestUserModal(true);
        }}
      />
    );
  }

  const headers = [
    { key: 'id', isKey: true },
    { key: 'actions', format: editAction },
    { key: 'name', name: t('profile.name') },
    { key: 'email', name: t('profile.email') },
    { key: 'role', name: t('profile.role') },
    { key: 'guest', name: t('profile.accountAccess'), format: guestAccessAction },
  ];

  function getUpdatedArrayUsers(updatedUser) {
    return users.map(user => {
      if (user.id === updatedUser.id) {
        return ({
          ...user, email: updatedUser.email, role: updatedUser.role, roleId: updatedUser.roleId,
        });
      }
      return user;
    });
  }

  function handleUpdate(actionUpdate, user) {
    let newUsersData = [];
    if (!actionUpdate) newUsersData.push(...users, user);
    else newUsersData = getUpdatedArrayUsers(user);
    setFilteredUsers(newUsersData);
    setUsers(newUsersData);
    setModalActive(false);
  }

  function handleDeleteConfirmed(userData) {
    request.deleteUser(userData.id).then(() => {
      users.map((user, index) => {
        if (user.id === userData.id) {
          users.splice(index, 1);
          toast(t('authorizers.userDeleted'), 'success');
        }
        return user;
      });
      toast(t('user.userDelted'), 'success');
      setActiveDeleteUserModal(false);
    }).catch(error => toast(t(`error-codes.${error.code}`, 'error')));
  }

  const handleUserSearch = keyword => {
    setFilter(keyword);
    const items = fuse(users, ['name', 'email', 'role']);
    const result = items.search(keyword).map(user => user.item);
    setFilteredUsers(result);
  };

  const handleNewUser = () => {
    setSelectedUser({
      id: '',
      email: '',
      roleId: '',
      role: '',
    });
    setModalActive(true);
  };

  useEffect(() => {
    async function fetchUsers() {
      try {
        const usersData = await request.getUsersByCustomer(customerId);
        setUsers(usersData.users);
        setFilteredUsers(usersData.users);
      } catch (error) {
        setUsers([]);
        setFilteredUsers([]);
      }
    }
    fetchUsers();
  }, [customerId]);

  useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await request.getRoles();
        if (customerId === 'G000000') {
          const filteredRoles = response.roles.filter(filterRole => filterRole.id !== 1 && filterRole.id !== 2 && filterRole.id !== 3);
          setRoles(filteredRoles.map(role => ({ value: role.id, label: role.name })));
        } else {
          const filteredRoles = response.roles.filter(filterRole => filterRole.id === 1 || filterRole.id === 2 || filterRole.id === 3);
          setRoles(filteredRoles.map(role => ({ value: role.id, label: role.name })));
        }
      } catch (error) {
        toast(t(`error-codes.${error.code}`), 'error');
        setRoles([]);
      }
    }
    fetchRoles();
  }, [customerId, t]);

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('user.title')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider className="margin-divider" />
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <p>{t('user.description')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
          <SearchBar
            search={handleUserSearch}
            filter={filter}
            label={t('user.filter')}
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={4} mobile={16} textAlign="center">
          <Button color="blue" fluid icon="add user" content={t('add')} onClick={handleNewUser} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ overflowX: 'auto' }}>
        <Table
          selectable
          data={filteredUsers}
          headers={headers}
          pageSize={5}
        />
      </Grid.Row>
      <ModalDetails
        actions={{
          open: activeModal,
          handleModalClose: () => setModalActive(false),
          handleModalSave: handleUpdate,
        }}
        customerId={customerId}
        user={selectedUser}
        roles={roles}
      />
      <GuestUserConfirm
        open={activeGuestUserModal}
        onClose={() => setActiveGuestUserModal(false)}
        selectedUser={selectedUser}
        customerId={customerId}
      />
      <Confirm
        open={activeDeleteUserModal}
        header={t('authorizers.confirmDeleteUser')}
        content={selectedUser.email}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
        onCancel={() => setActiveDeleteUserModal(false)}
        onConfirm={() => handleDeleteConfirmed(selectedUser)}
      />
    </Grid>
  );
}

Users.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(Users);
