import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const annualPlan = {};

annualPlan.getMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

annualPlan.getAnnualPlanByCustomerId = (customerId, year, makerId) => (
  request({
    route: apiRoutes.getAnnualPlanByCustomer(customerId, year, makerId),
    method: 'GET',
  })
);

annualPlan.addCorporateGoals = body => (
  request({
    route: apiRoutes.addCorporateGoals(),
    method: 'POST',
    body,
  })
);

annualPlan.updateCorporateGoals = body => (
  request({
    route: apiRoutes.updateCorporateGoals(),
    method: 'PUT',
    body,
  })
);

annualPlan.deleteCorporateGoals = objectiveId => (
  request({
    route: apiRoutes.deleteCorporateGoals(objectiveId),
    method: 'DELETE',
  })
);

annualPlan.addGoalIndicators = body => (
  request({
    route: apiRoutes.addGoalIndicators(),
    method: 'POST',
    body,
  })
);

annualPlan.updateIndicators = body => (
  request({
    route: apiRoutes.updateGoalIndicators(),
    method: 'PUT',
    body,
  })
);

annualPlan.deleteIndicator = indicatorId => (
  request({
    route: apiRoutes.deleteIndicator(indicatorId),
    method: 'DELETE',
  })
);

annualPlan.addIndicatorStrategy = body => (
  request({
    route: apiRoutes.addIndicatorStrategy(),
    method: 'POST',
    body,
  })
);

annualPlan.updateStrategy = body => (
  request({
    route: apiRoutes.updateStrategy(),
    method: 'PUT',
    body,
  })
);

annualPlan.deleteStrategy = strategyId => (
  request({
    route: apiRoutes.deleteStrategy(strategyId),
    method: 'DELETE',
  })
);

export default annualPlan;
