import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Accordion, Icon, Grid, Header, Label,
} from 'semantic-ui-react';
import ObjectiveItem from './objective-items';

const Objective = props => {
  const {
    activeIndex, handleClick, goals, handleClickIndicators, activeIndicator, handleClickAddIndicator,
    handleClickAddStrategy, handleOpenDeleteObjective, handleOpenDeleteIndicator, handleOpenDeleteStrategy,
    handleClickUpdateObjective, handleClickUpdateIndicator, handleClickUpdateStrategy,
  } = props;

  return (
    goals.map(goal => (
      <Accordion exclusive={false}>
        <Accordion.Title index={goal.goalId} active={activeIndex === goal.goalId} onClick={handleClick}>
          <Grid container>
            <Grid.Row>
              <Grid.Column><Icon name="dropdown" /></Grid.Column>
              <Grid.Column>
                <Label basic circular size="large">
                  {Number(goal.progress)}
                  %
                </Label>
              </Grid.Column>
              <Grid.Column largeScreen={11} computer={11} tablet={11}><Header as="h5">{goal.objective}</Header></Grid.Column>
              <Grid.Column><Icon onClick={() => handleClickAddIndicator(goal.goalId)} color="blue" name="plus" /></Grid.Column>
              <Grid.Column><Icon onClick={() => handleOpenDeleteObjective(goal.objective, goal.goalId)} color="blue" name="trash" /></Grid.Column>
              <Grid.Column><Icon onClick={() => handleClickUpdateObjective(goal.goalId, goal.objective, goal.progress)} color="blue" name="pencil" /></Grid.Column>
            </Grid.Row>
          </Grid>
        </Accordion.Title>
        <Accordion.Content active={activeIndex === goal.goalId}>
          <Grid container>
            <Grid.Row columns={1}>
              <Grid.Column largeScreen={16} computer={16} tablet={16}>
                {goal.indicators && goal.indicators.length > 0
                  ? (
                    <ObjectiveItem
                      objectiveId={goal.goalId}
                      indicators={goal.indicators}
                      handleClick={handleClickIndicators}
                      activeIndicator={activeIndicator}
                      handleClickAddStrategy={handleClickAddStrategy}
                      handleOpenDeleteIndicator={handleOpenDeleteIndicator}
                      handleOpenDeleteStrategy={handleOpenDeleteStrategy}
                      handleClickUpdateIndicator={handleClickUpdateIndicator}
                      handleClickUpdateStrategy={handleClickUpdateStrategy}
                    />
                  ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Accordion.Content>
      </Accordion>
    ))
  );
};
export default translate('common', { wait: true })(Objective);

Objective.defaultProps = {
  activeIndex: 0,
  handleClick: null,
  goals: [],
  handleClickIndicators: null,
  activeIndicator: 0,
  handleClickAddIndicator: null,
  handleClickAddStrategy: null,
  handleOpenDeleteObjective: null,
  handleOpenDeleteStrategy: null,
  handleClickUpdateObjective: null,
  handleClickUpdateIndicator: null,
  handleClickUpdateStrategy: null,
};

Objective.propTypes = {
  activeIndex: PropTypes.number,
  handleClick: PropTypes.func,
  goals: PropTypes.arrayOf(),
  handleClickIndicators: PropTypes.func,
  activeIndicator: PropTypes.number,
  handleClickAddIndicator: PropTypes.func,
  handleOpenDeleteObjective: PropTypes.func,
  handleOpenDeleteStrategy: PropTypes.func,
  handleClickUpdateObjective: PropTypes.func,
  handleClickUpdateIndicator: PropTypes.func,
  handleClickUpdateStrategy: PropTypes.func,
};
