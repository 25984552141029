import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import {
  Grid,
  Button,
  Label,
  Icon,
  Confirm,
} from 'semantic-ui-react';
import propTypes from 'prop-types';
import moment from 'moment';

import { fuse } from '../../../common/utils';
import Table from '../../../common/components/table';
import toast from '../../../common/components/toast';

import ModalDetails from './ModalDetails';
import ModalAccess from './ModalAccess';
import SearchBar from './SearchBar';
import request from '../../services';

function setFormattedDate(serviceAccounts) {
  return serviceAccounts.map(account => ({
    ...account,
    createdAt: moment(account.createdAt).format('ll hh:mm a'),
  }));
}

function ServiceAcconst({ serviceAccounts, t, env }) {
  const [activeModal, setModalActive] = useState(false);
  const [activeModalAccess, setModalAccessActive] = useState(false);
  const [activeDeleteUserModal, setActiveDeleteUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('');

  function editAction(cell, user) {
    return (
      <Grid>
        <Button
          color="blue"
          icon="universal access"
          onClick={() => {
            setModalAccessActive(true);
            setSelectedUser(user);
          }}
        />
        <Button
          color="blue"
          icon="trash alternate"
          onClick={() => {
            setActiveDeleteUserModal(true);
            setSelectedUser(user);
          }}
        />
      </Grid>
    );
  }

  const headers = [
    { key: 'id', isKey: true },
    { key: 'customerId', name: t('user.serviceAccount.idCustomer') },
    { key: 'customerName', name: t('profile.name') },
    { key: 'createdAt', name: t('user.serviceAccount.dateCreated') },
    { key: 'actions', format: editAction },
  ];

  async function handleSave() {
    try {
      const usersData = await request.getServiceAccounts();
      const rows = setFormattedDate(usersData.serviceAccounts);
      setUsers(rows);
      setFilteredUsers(rows);
      setModalActive(false);
    } catch (error) {
      setUsers([]);
      setFilteredUsers([]);
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      toast(messageError, 'error');
    }
  }

  function handleDeleteConfirmed(userData) {
    request
      .deleteAccount(userData.customerId, env)
      .then(() => {
        users.map((user, index) => {
          if (user.id === userData.id) {
            users.splice(index, 1);
          }
          return user;
        });
        toast(t('user.userDelted'), 'success');
        setActiveDeleteUserModal(false);
      })
      .catch(error => toast(t(`error-codes.${error.code}`, 'error')));
  }

  const handleUserSearch = keyword => {
    setFilter(keyword);
    if (keyword === '') {
      setFilteredUsers(users);
    } else {
      const items = fuse(users, ['customerId', 'customerName', 'createdAt']);
      const result = items.search(keyword).map(user => user.item);
      setFilteredUsers(result);
    }
  };

  const handleNewUser = () => {
    setSelectedUser({
      id: '',
      customerId: '',
      customerName: '',
      createdAt: '',
    });
    setModalActive(true);
  };

  useEffect(() => {
    const rows = setFormattedDate(serviceAccounts);
    setUsers(rows);
    setFilteredUsers(rows);
  }, [serviceAccounts]);

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
          <SearchBar search={handleUserSearch} filter={filter} label={t('user.filter')} />
        </Grid.Column>
        {filteredUsers.length > 0 ? (
          <Grid.Column computer={4} tablet={4} mobile={16} textAlign="center">
            <Button color="blue" fluid icon="add user" content={t('add')} onClick={handleNewUser} />
          </Grid.Column>
        ) : (
          ''
        )}
      </Grid.Row>

      <Grid.Row style={{ overflowX: 'auto' }}>
        <Label className="basic">
          {`${t('user.serviceAccount.totalCustomers')}: ${users.length} `}
          <Icon name="user" color="blue" />
        </Label>
      </Grid.Row>
      <Grid.Row style={{ overflowX: 'auto' }}>
        <Table selectable data={filteredUsers} headers={headers} pageSize={15} />
      </Grid.Row>

      {activeModal ? (
        <ModalDetails
          actions={{
            open: activeModal,
            handleModalClose: () => setModalActive(false),
            handleModalSave: handleSave,
          }}
          user={selectedUser}
          env={env}
        />
      ) : (
        ''
      )}
      {activeModalAccess ? (
        <ModalAccess
          actions={{
            open: activeModalAccess,
            handleModalClose: () => setModalAccessActive(false),
          }}
          user={selectedUser}
          env={env}
        />
      ) : (
        ''
      )}
      <Confirm
        open={activeDeleteUserModal}
        header={t('authorizers.confirmDeleteUser')}
        content={selectedUser.customerId}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
        onCancel={() => setActiveDeleteUserModal(false)}
        onConfirm={() => handleDeleteConfirmed(selectedUser)}
      />
    </Grid>
  );
}

ServiceAcconst.propTypes = {
  serviceAccounts: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      customerId: propTypes.string,
      customerName: propTypes.string,
      createdAt: propTypes.string,
    }),
  ),
  env: propTypes.string,
  t: propTypes.func.isRequired,
};

ServiceAcconst.defaultProps = {
  serviceAccounts: [],
  env: '',
};

export default translate('common', { wait: true })(ServiceAcconst);
