import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const BodyCell = ({
  cellKey, format, row, isActive,
}) => (<Table.Cell width={cellKey === 'actions' ? 2 : null} active={isActive}>{format(row[cellKey], row)}</Table.Cell>);

BodyCell.propTypes = {
  cellKey: PropTypes.string.isRequired,
  format: PropTypes.func,
  isActive: PropTypes.bool,
  row: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number])).isRequired,
};

BodyCell.defaultProps = {
  format: cell => cell,
  isActive: false,
};

export default BodyCell;
