import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import SiclikTable from '../../../../common/components/table';

function filterData(value = '', baseArray = []) {
  return !value.length
    ? [...baseArray]
    : baseArray.filter(({ title, message }) => (
      title.toUpperCase().includes(value.toUpperCase())
      || message.toUpperCase().includes(value.toUpperCase())
    ));
}

function BoxList(props) {
  const {
    clean, updateClean, boxes, headers, messageTable, t,
  } = props;

  const [state, setState] = useState({
    filter: '',
    filteredData: [],
    dataCopy: [],
    filterError: '',
  });

  useEffect(() => {
    const filteredData = filterData('', boxes);
    setState(currentState => ({
      ...currentState,
      filteredData,
      dataCopy: [...boxes],
      filter: '',
      filterError: '',
    }));
  }, [boxes, clean]);

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('standardShipping.listTitle')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SiclikTable
            selectable
            clean={clean}
            updateClean={updateClean}
            data={state.filteredData}
            headers={headers}
            pageSize={10}
            noDataMessage={messageTable}
            singleSelection
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

BoxList.propTypes = {
  clean: PropTypes.bool,
  messageTable: PropTypes.string,
  updateClean: PropTypes.func,
  boxes: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})),
};

BoxList.defaultProps = {
  updateClean: () => null,
  boxes: [],
  headers: [],
  messageTable: '',
  clean: false,
};

export default translate('common', { wait: true })(BoxList);
