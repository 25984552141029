import React, { Component } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import PropTypes from 'prop-types';
import {
  Header, Grid, Image, Message, Table, Checkbox, Form, Popup,
} from 'semantic-ui-react';
import DatePicker from '@material-ui/core/TextField';
import { TextField } from '../../common/components/materials';
import routeNames from '../../route-names';

const urlAWS = process.env.REACT_APP_URL_AWS;
const assetUrl = `${urlAWS}/imagenes/assets/comercio/`;
const agentsImage = `${assetUrl}Agentes.png`;

class AddAppleCareSerials extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.appleCareResponse !== state.appleCareResponse) {
      return {
        appleCareResponse: props.appleCareResponse,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      appleCareResponse: [],
      productsAppleCare: props.productsAppleCare,
      itemPurchasedInCS: false,
      enableButton: false,
    };
  }

  componentDidMount() {
    this.setAppleCareProducts();
  }

  componentDidUpdate(prevProps) {
    const { appleCareResponse } = this.props;
    if (appleCareResponse !== prevProps.appleCareResponse) {
      this.setAppleCareProducts();
    }
  }

  setAppleCareProducts = () => {
    const { productsAppleCare, appleCareResponse } = this.state;
    const { handleGetAppleCareConfig } = this.props;
    let formattedArray = productsAppleCare;
    let enableButton = false;
    formattedArray = formattedArray.map(items => {
      let cont = 1;
      let serialsBySku = appleCareResponse.filter(filter => filter.sku === items.sku);
      const quantity = items.quantity - serialsBySku.length;
      const emptyObject = {
        sku: items.sku,
        serial: '',
        orderNumber: '',
        purchaseDate: '',
        id: items.sku + cont++,
      };
      for (let i = 0; i < quantity; i++) {
        serialsBySku.push(emptyObject);
      }
      serialsBySku = serialsBySku.map(data => {
        const newFormat = {
          ...data,
          id: items.sku + cont++,
        };
        return newFormat;
      });
      const arrayConfig = {
        ...items,
        appleCare: serialsBySku,
        itemPurchasedInCS: serialsBySku.length > 0,
      };
      if (appleCareResponse.length > 0) {
        this.setState({ itemPurchasedInCS: true });
        enableButton = true;
      }
      return arrayConfig;
    });
    handleGetAppleCareConfig(formattedArray, enableButton);
    this.setState({ productsAppleCare: formattedArray, enableButton });
  }

  handleAddSerialChange = ({ target }) => {
    const { productsAppleCare, enableButton } = this.state;
    const { tr: t, handleGetAppleCareConfig } = this.props;
    const { value, name, id } = target;
    let productsFormatted = productsAppleCare;
    let serialError = '';
    const skuToUpdate = productsFormatted.findIndex(findElement => findElement.sku === name);
    productsFormatted = productsFormatted.map(arraydata => {
      if (arraydata.sku === name) {
        arraydata.appleCare.map(item => {
          const appleCareToUpdate = arraydata.appleCare.findIndex(findElement => findElement.sku === name && findElement.id === id);
          if (id === item.id && value.length > 0) {
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].serial = value;
            if (!validator.isAlphanumeric(value.replace(/ /g, ''), ['es-ES'])) serialError = t('multibrand.errors.notSymbol');
            if (value.length > 15) serialError = t('branchOffice.errors.toLong');
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].serialError = serialError;
          }
          return item;
        });
      }
      return arraydata;
    });
    handleGetAppleCareConfig(productsFormatted, enableButton);
    this.setState({ productsAppleCare: productsFormatted });
  }

  handleAddOrderNumberChange = ({ target }) => {
    const { productsAppleCare, enableButton } = this.state;
    const { tr: t, handleGetAppleCareConfig } = this.props;
    const { value, name, id } = target;
    let productsFormatted = productsAppleCare;
    let orderNumberError = '';
    const skuToUpdate = productsFormatted.findIndex(findElement => findElement.sku === name);
    productsFormatted = productsFormatted.map(arraydata => {
      if (arraydata.sku === name) {
        arraydata.appleCare.map(item => {
          const appleCareToUpdate = arraydata.appleCare.findIndex(findElement => findElement.sku === name && findElement.id === id);
          if (id === item.id && value.length > 0) {
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].orderNumber = value;
            if (value.length > 15) orderNumberError = t('branchOffice.errors.toLong');
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].orderNumberError = orderNumberError;
          }
          return item;
        });
      }
      return arraydata;
    });
    handleGetAppleCareConfig(productsFormatted, enableButton);
    this.setState({ productsAppleCare: productsFormatted });
  }

  handleAddPurchaseOrderDateChange = ({ target }) => {
    const { productsAppleCare, enableButton } = this.state;
    const { handleGetAppleCareConfig } = this.props;
    const { value, name, id } = target;
    let dateError = '';
    const today = new Date();
    const currentDay = new Date();
    const subtractDate = new Date(today.setDate(today.getDate() - 10));
    const minDate = new Date(subtractDate);
    const dateValue = new Date(value);
    let productsFormatted = productsAppleCare;
    const skuToUpdate = productsFormatted.findIndex(findElement => findElement.sku === name);
    productsFormatted = productsFormatted.map(arraydata => {
      if (arraydata.sku === name) {
        arraydata.appleCare.map(item => {
          const appleCareToUpdate = arraydata.appleCare.findIndex(findElement => findElement.sku === name && findElement.id === id);
          if (id === item.id && value.length > 0) {
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].purchaseDate = value;
            if (dateValue < minDate) dateError = 'La fecha de la factura no puede tener más de 7 días hábiles';
            if (dateValue > currentDay) dateError = 'La fecha no puede ser mayor a la de hoy';
            productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].purchaseDateError = dateError;
          }
          return item;
        });
      }
      return arraydata;
    });
    handleGetAppleCareConfig(productsFormatted, enableButton);
    this.setState({ productsAppleCare: productsFormatted });
  }

  handleItemPurchasedInCS = (e, { checked }) => {
    const { productsAppleCare } = this.state;
    const { handleGetAppleCareConfig } = this.props;
    let formattedArray = productsAppleCare;
    formattedArray = formattedArray.map(items => {
      const arrayConfig = {
        ...items,
        itemPurchasedInCS: checked,
      };
      return arrayConfig;
    });
    handleGetAppleCareConfig(formattedArray, checked);
    this.handleCleanErrors(checked);

    this.setState({ itemPurchasedInCS: checked, productsAppleCare: formattedArray, enableButton: checked });
  }

  handleCleanErrors = itemPurchasedInCS => {
    const { productsAppleCare } = this.state;
    let productsFormatted = productsAppleCare;
    const { handleGetAppleCareConfig } = this.props;
    if (!itemPurchasedInCS) {
      productsFormatted = productsFormatted.map(arraydata => {
        const skuToUpdate = productsFormatted.findIndex(findElement => findElement.sku === arraydata.sku);
        arraydata.appleCare.map(item => {
          const appleCareToUpdate = arraydata.appleCare.findIndex(findElement => findElement.sku === item.sku && findElement.id === item.id);
          productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].serialError = '';
          productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].purchaseDateError = '';
          productsFormatted[skuToUpdate].appleCare[appleCareToUpdate].orderNumberError = '';
          return item;
        });
        return arraydata;
      });
      this.setState({ productsAppleCare: productsFormatted });
      handleGetAppleCareConfig(productsFormatted);
    }
  }

  render() {
    const {
      tr: t, applecareError, visible, handleDismiss, history,
    } = this.props;
    const { productsAppleCare, itemPurchasedInCS } = this.state;
    return (
      <Grid container>
        <Grid.Row columns={1}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
            <Header>{t('Asignar series para garantías Apple Care')}</Header>
          </Grid.Column>
        </Grid.Row>
        {visible
          ? (
            <Grid.Row>
              <Message warning onDismiss={handleDismiss}>
                <Message.Header>{t('Falta completar información')}</Message.Header>
                {applecareError}
              </Message>
            </Grid.Row>
          )
          : ''}
        {!itemPurchasedInCS
          ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
                <Message compact>
                  <Message.Header>
                    Si no compraste el equipo en CompuSoluciones, por favor contacta a tu consultor de ventas asignado para revisar las opciones que tenemos para la compra de este AppleCare+,
                    si desconoces quien es, contacta a Miriam Navarro mnavarro@compusoluciones.com.
                    {' '}
                    <Popup
                      content="Directorio de Agentes"
                      trigger={(
                        <Image
                          src={agentsImage}
                          size="mini"
                          as="a"
                          style={{ cursor: 'pointer' }}
                          onClick={() => history.push(routeNames.dashboard.route)}
                        />
                      )}
                    />

                  </Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )
          : ''}
        <Grid.Row columns={1}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
            <Form>
              <Form.Group grouped>
                <Form.Field>
                  <Checkbox
                    label="¿Compraste el dispositivo con nosotros?"
                    onChange={this.handleItemPurchasedInCS}
                    checked={itemPurchasedInCS}
                    style={{ fontSize: '1.5em' }}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            {itemPurchasedInCS
              ? (
                <Table celled compact definition>
                  <Table.Header fullWidth>
                    <Table.Row>
                      <Table.HeaderCell>Articulo</Table.HeaderCell>
                      <Table.HeaderCell>SKU</Table.HeaderCell>
                      <Table.HeaderCell>Cantidad</Table.HeaderCell>
                      <Table.HeaderCell>Serie</Table.HeaderCell>
                      <Table.HeaderCell>Fecha de compra</Table.HeaderCell>
                      <Table.HeaderCell>Orden de compra</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  {productsAppleCare.map(item => (
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width="2"><Image avatar size="small" src={item.image} /></Table.Cell>
                        <Table.Cell width="1"><Header className="no-margin-bottom" as="h5">{item.sku}</Header></Table.Cell>
                        <Table.Cell width="1"><Header className="no-margin-bottom" as="h5">{item.quantity}</Header></Table.Cell>
                        <Table.Cell width="4">
                          {item.appleCare && item.appleCare.length > 0
                            ? item.appleCare.map(appleCareItems => (
                              <TextField
                                name={item.sku}
                                id={appleCareItems.id}
                                fullWidth
                                maxLength={15}
                                label="Ingresar serie"
                                error={appleCareItems.serialError || ''}
                                value={appleCareItems.serial}
                                onChange={this.handleAddSerialChange}
                              />
                            ))
                            : ''}
                        </Table.Cell>
                        <Table.Cell width="4">
                          {item.appleCare && item.appleCare.length > 0
                            ? item.appleCare.map(appleCareItems => (
                              <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={4}>
                                <DatePicker
                                  name={item.sku}
                                  id={appleCareItems.id}
                                  type="date"
                                  format="dd/mm/yyyy"
                                  error={appleCareItems.purchaseDateError || ''}
                                  value={appleCareItems.purchaseDate}
                                  defaultValue={appleCareItems.purchaseDate}
                                  onChange={this.handleAddPurchaseOrderDateChange}
                                />
                                <Grid.Row>
                                  <p style={{ color: 'red' }}>{appleCareItems.purchaseDateError || ''}</p>
                                </Grid.Row>
                                {item.appleCare.length > 1 ? <br /> : ''}
                              </Grid.Column>
                            ))
                            : ''}
                        </Table.Cell>
                        <Table.Cell width="4">
                          {item.appleCare && item.appleCare.length > 0
                            ? item.appleCare.map(appleCareItems => (
                              <TextField
                                name={item.sku}
                                id={appleCareItems.id}
                                fullWidth
                                maxLength={15}
                                label="Numero orden de compra"
                                error={appleCareItems.orderNumberError || ''}
                                value={appleCareItems.orderNumber}
                                onChange={this.handleAddOrderNumberChange}
                              />
                            ))
                            : ''}

                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  ))}
                </Table>
              )
              : ''}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

AddAppleCareSerials.defaultProps = {
  productsAppleCare: [],
  appleCareResponse: [],
  applecareError: '',
  handleGetAppleCareConfig: null,
  visible: false,
  handleDismiss: null,
  tr: null,
};

AddAppleCareSerials.propTypes = {
  handleGetAppleCareConfig: PropTypes.func,
  handleDismiss: PropTypes.func,
  productsAppleCare: PropTypes.arrayOf(PropTypes.shape({})),
  tr: PropTypes.func,
  appleCareResponse: PropTypes.arrayOf(PropTypes.shape({})),
  applecareError: PropTypes.string,
  visible: PropTypes.bool,
};

export default translate('common', { wait: true })(AddAppleCareSerials);
