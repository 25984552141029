/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import {
  Dropdown, Form, Header, Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import DeliveryOptions from '../../common/components/quotation';
import DetailsToBuy from './DetailsToBuy';

function formatBranchOffices(branchOffices) {
  return branchOffices.map(branchOffice => {
    const address = `${branchOffice.street} #${branchOffice.number}`;
    return {
      key: branchOffice.id,
      value: branchOffice.id,
      text: branchOffice.name,
      content: (
        <Header
          size="tiny"
          content={branchOffice.name}
          subheader={address}
        />
      ),
    };
  });
}

function ShippingStep(props) {
  const {
    active, selectedBranchOffice, setSelectedBranchOffice, branchOffices, items, selectedShipping, t,
  } = props;
  const [loader, setLoader] = useState(false);

  const handleSelectedBranchOffice = (e, { value }) => {
    setSelectedBranchOffice(value);
  };

  const handleSelectedShipping = selectedShippingParam => {
    selectedShipping(selectedShippingParam);
  };

  useEffect(() => {
    setLoader(!branchOffices.length);
  }, [branchOffices, selectedBranchOffice]);

  return (
    <div hidden={!active}>
      <Segment>
        <DetailsToBuy />
      </Segment>
      <Form className="padding-y">
        <Form.Field>
          <label>{t('wishList.shippingStep.sendTo')}</label>
          <Dropdown
            fluid
            search
            selection
            loading={loader}
            placeholder={t('wishList.shippingStep.findAddress')}
            options={formatBranchOffices(branchOffices)}
            value={selectedBranchOffice}
            onChange={handleSelectedBranchOffice}
          />
        </Form.Field>
        <Form.Field>
          <label>{t('wishList.shippingStep.shippingMethod')}</label>
          <DeliveryOptions
            isQuickCart
            parcelValue=""
            skus={items}
            addressId={selectedBranchOffice}
            tr={t}
            response={handleSelectedShipping}
            isSingle
          />
        </Form.Field>
      </Form>
    </div>
  );
}

ShippingStep.propTypes = {
  active: PropTypes.bool.isRequired,
  selectedBranchOffice: PropTypes.string.isRequired,
  setSelectedBranchOffice: PropTypes.func.isRequired,
  branchOffices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  selectedShipping: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ShippingStep);
