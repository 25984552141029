import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Responsive, Divider, Loader,
} from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { currencyFormat } from '../../common/utils';

import { CartContext } from '../../context/cart-context';

import Toast from '../../common/components/toast';
import services from '../services';

import ItemContent from './item-content';

function OrderItems(props) {
  const {
    items, orderId, currency, exchangeRate, t, deleteShoppingCartProduct, payment, hasAgentPrice, hpeIquoteId,
    dispatch, handleBoolField, subtotal, actionLoader, steppedPrices,
    chosenDelivery, warehouseSelected,
  } = props;

  const [loaderTotal, setLoaderTotal] = useState(false);
  const { updateCartCount } = useContext(CartContext);

  const validateQuantityLimit = cartItems => {
    const hasQuantityError = cartItems.some(item => (
      !item.outlet
        && ((item.minQuantity > 0 && item.quantity < item.minQuantity)
          || (item.maxQuantity > 0 && item.quantity > item.maxQuantity))
    ));
    dispatch({
      type: 'fieldChange',
      payload: { field: 'cartError', value: hasQuantityError },
    });
  };

  const handleOnChange = async (selectedItem, isOnBlur = false) => {
    const {
      sku, quantity, outlet, mcAfeeQty,
    } = selectedItem;
    const itemsSkus = items.map(item => {
      if (item.sku === sku && item.outlet === outlet) {
        return { ...item, quantity: Number(quantity), mcAfeeQty: Number(mcAfeeQty) };
      }
      return item;
    });
    if (isOnBlur) {
      dispatch({
        type: 'fieldChange',
        payload: { field: 'actionLoader', value: true },
      });
      await services.updateProductQuantity(encodeURIComponent(sku), { quantity: Number(quantity), outlet, mcAfeeQty: Number(mcAfeeQty) })
        .then(async () => {
          setLoaderTotal(false);
          dispatch({
            type: 'orderItems',
            payload: { itemsSkus },
          });
          dispatch({
            type: 'fieldChange',
            payload: { field: 'actionLoader', value: false },
          });
          validateQuantityLimit(itemsSkus);
          Toast(t('shoppingCart.quantityUpdated'), 'success');
          await updateCartCount();
          if (chosenDelivery === 'warehousePickup' && warehouseSelected) {
            dispatch({
              type: 'fieldChange',
              payload: { field: 'updateQuantityWarehouse', value: true },
            });
          }
        })
        .catch(error => {
          setLoaderTotal(false);
          dispatch({
            type: 'fieldChange',
            payload: { field: 'actionLoader', value: false },
          });
          dispatch({
            type: 'fieldChange',
            payload: { field: 'cartError', value: true },
          });
          if (error.code === callError.products.INSUFFICIENT_OUTLET_STOCK) {
            Toast(t('shoppingCart.errors.noStock'), 'error');
          } else if (error.code === callError.products.QUANTITY_LIMIT) {
            Toast('Hay productos en el carrito que no cumplen con los mínimos y/o máximos de compra', 'error');
          } else Toast(t('shoppingCart.errors.quantity'), 'error');
        });
    }
  };

  return (
    <Grid style={{ overflowY: 'auto', maxHeight: '150vh' }}>
      <Responsive as={Grid.Row} minWidth={Responsive.onlyTablet.minWidth}>
        <Grid.Column verticalAlign="top" tablet={3} computer={3} largeScreen={3} widescreen={3} />
        <Grid.Column verticalAlign="top" tablet={4} computer={4} largeScreen={4} widescreen={4}>
          <Header as="h4">{t('shoppingCart.itemHeaders.name')}</Header>
        </Grid.Column>
        <Grid.Column verticalAlign="top" textAlign="left" tablet={2} computer={2} largeScreen={2} widescreen={2}>
          <Header as="h4">{t('shoppingCart.itemHeaders.quantity')}</Header>
        </Grid.Column>
        <Grid.Column verticalAlign="top" textAlign="right" tablet={3} computer={3} largeScreen={3} widescreen={3}>
          <Header as="h4">{t('shoppingCart.itemHeaders.unitPrice')}</Header>
        </Grid.Column>
        <Grid.Column verticalAlign="top" textAlign="right" tablet={3} computer={3} largeScreen={3} widescreen={3}>
          <Header as="h4">{t('shoppingCart.itemHeaders.total')}</Header>
        </Grid.Column>
        <Grid.Column verticalAlign="top" tablet={1} computer={1} largeScreen={1} widescreen={1} />
      </Responsive>
      {
        items.map(item => {
          const {
            promotionPrice, quantity, sku, hpMapId,
          } = item;
          let formatedItem = { ...item };
          if (hpMapId && hpMapId !== '') {
            const volumeDiscount = steppedPrices[sku]
              ? steppedPrices[sku].find(stepPrice => quantity >= stepPrice.min && quantity <= stepPrice.max)
              : null;
            const promotionToUse = volumeDiscount ? volumeDiscount.price : promotionPrice;
            formatedItem = { ...item, promotionPrice: promotionToUse };
          }
          return (
            <Grid.Row key={[item.sku, orderId].join('_')}>
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
                <ItemContent
                  key={[item.sku, item.outlet, orderId].join('_')}
                  item={formatedItem}
                  onChange={handleOnChange}
                  currency={currency}
                  paymentType={payment}
                  exchangeRate={exchangeRate}
                  deleteShoppingCartProduct={deleteShoppingCartProduct}
                  hasAgentPrice={hasAgentPrice}
                  handleBoolField={handleBoolField}
                  hpeIquoteId={hpeIquoteId}
                  tr={t}
                  actionLoader={actionLoader}
                />
              </Grid.Column>
            </Grid.Row>
          );
        })
      }
      <Responsive as={Grid.Row} minWidth={Responsive.onlyTablet.minWidth} style={{ paddingTop: 0 }}>
        <Grid.Column tablet={8} computer={8} largeScreen={8} widescreen={8} />
        <Grid.Column verticalAlign="middle" textAlign="right" tablet={3} computer={3} largeScreen={3} widescreen={3}>
          <Header as="h4">{t('shoppingCart.subtotal')}</Header>
        </Grid.Column>
        <Grid.Column textAlign="right" verticalAlign="middle" tablet={4} computer={4} largeScreen={4} widescreen={4}>
          { loaderTotal ? <Loader active inline />
            : <Header as="h4" color="green">{currencyFormat(subtotal, currency)}</Header>}
        </Grid.Column>
        <Grid.Column verticalAlign="top" tablet={1} computer={1} largeScreen={1} widescreen={1} />
        <Grid.Column tablet={16} computer={16} largeScreen={16} widescreen={16}>
          <Divider />
        </Grid.Column>
      </Responsive>
    </Grid>
  );
}

OrderItems.propTypes = {
  currency: PropTypes.string,
  deleteShoppingCartProduct: PropTypes.func,
  dispatch: PropTypes.func,
  exchangeRate: PropTypes.shape({}),
  handleBoolField: PropTypes.func,
  hasAgentPrice: PropTypes.bool,
  hpeIquoteId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  orderId: PropTypes.string,
  payment: PropTypes.number,
  subtotal: PropTypes.number,
  t: PropTypes.func,
  actionLoader: PropTypes.bool,
  steppedPrices: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({}))),
  chosenDelivery: PropTypes.string,
  warehouseSelected: PropTypes.string,
};

OrderItems.defaultProps = {
  currency: '',
  deleteShoppingCartProduct: () => {},
  dispatch: () => {},
  exchangeRate: {},
  handleBoolField: () => {},
  hasAgentPrice: false,
  hpeIquoteId: '',
  items: [],
  orderId: '',
  payment: 0,
  subtotal: 0,
  t: () => {},
  actionLoader: false,
  steppedPrices: [],
  chosenDelivery: 'singleDelivery',
  warehouseSelected: '',
};

export default translate('common', { wait: true })(OrderItems);
