/* eslint-disable react/jsx-filename-extension */
import 'react-app-polyfill/stable';
import 'fast-text-encoding';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import TagManager from 'react-gtm-module';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import i18n from './translator';
import './semantic/rating.min.css';
import './semantic/semantic.min.css';
import { CartProvider } from './context/cart-context';
import App from './App';

const gtmId = process.env.REACT_APP_GTM_TRACKINGID;
const basename = process.env.REACT_APP_BASENAME;

const tagManagerArgs = {
  gtmId,
  events: {
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render((
  <Router basename={basename}>
    <I18nextProvider i18n={i18n}>
      <CartProvider>
        <App />
      </CartProvider>
    </I18nextProvider>
  </Router>
), document.getElementById('root'));  // eslint-disable-line 
