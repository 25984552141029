import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Image, Grid, Icon } from 'semantic-ui-react';
import routeNames from '../../../route-names';
import PopMenu from './pop-menu';
import ItemMenu from './item-menu';

const urlAWS = process.env.REACT_APP_URL_AWS;
const assetUrl = `${urlAWS}/imagenes/assets/siclik/`;
const commerceImage = `${assetUrl}siclikComercio.png`;

const dividerStyle = {
  borderBottom: '3px solid #00549F',
  backgroundColor: '#ffffff',
  // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
};

class MenuApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobile: false,
    };
  }

  showMenuMobile = () => {
    const { showMobile } = this.state;
    this.setState({ showMobile: !showMobile });
  }

  redirectToComercio = ({ history }) => history.push(routeNames.homeComercio.route);

  computerHeader = () => {
    const {
      isAuthenticated, t, history,
    } = this.props;
    return (
      <Grid>
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-bottom">
          <Grid.Column
            tablet={3}
            computer={3}
            largeScreen={3}
            widescreen={3}
          >
            <Grid padded verticalAlign="middle" centered>
              <Grid.Row centered className="no-padding">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                  widescreen={16}
                >
                  <Image
                    src={commerceImage}
                    size="medium"
                    as="a"
                    style={{ cursor: 'pointer' }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column
            tablet={11}
            computer={11}
            largeScreen={12}
            widescreen={12}
          >
            <ItemMenu t={t} isAuthenticated={isAuthenticated} history={history} />
          </Grid.Column>
          <Grid.Column
            tablet={2}
            computer={2}
            largeScreen={1}
            widescreen={1}
            floated="right"
            textAlign="right"
          >
            <PopMenu history={history} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  responsiveHeader = () => {
    const {
      isAuthenticated, t, history,
    } = this.props;
    const { showMobile } = this.state;
    return (
      <Grid>
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-bottom">
          <Grid.Column textAlign="left" mobile={3} floated="left">
            <Icon size="big" name="bars" onClick={() => this.showMenuMobile()} />
          </Grid.Column>
          <Grid.Column
            mobile={9}
          >
            <Grid padded verticalAlign="middle" centered>
              <Grid.Row centered className="no-padding">
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={16}
                  largeScreen={16}
                  widescreen={16}
                >
                  <Image
                    src={commerceImage}
                    size="medium"
                    as="a"
                    style={{ cursor: 'pointer' }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column
            mobile={4}
            floated="rigth"
            textAlign="right"
          >
            <PopMenu history={history} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-y">
          <Grid.Column
            mobile={16}
          >
            {showMobile
              ? <ItemMenu t={t} isAuthenticated={isAuthenticated} history={history} />
              : ''}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  render() {
    return (
      <Grid padded style={dividerStyle}>
        <Grid.Row textAlign="center" verticalAlign="middle" className="no-padding-bottom">
          <Grid.Column
            mobile={16}
            only="mobile"
          >
            {this.responsiveHeader()}
          </Grid.Column>
          <Grid.Column
            tablet={16}
            computer={16}
            largeScreen={16}
            widescreen={16}
            only="largeScreen computer tablet"
          >
            {this.computerHeader()}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

MenuApp.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(MenuApp);
