import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

class AddIndicator extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.indicatorId !== state.indicatorId) {
      return {
        indicator: props.indicator,
        indicatorId: props.indicatorId,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      indicator: props.indicator,
      indicatorId: props.indicatorId,
      indicatorError: '',
    };
  }

  onKeyPress = e => {
    if (e.which === 13) this.handleClickSave();
  }

  handleChangeIndicator = event => {
    const { tr } = this.props;
    const { value } = event.target;
    if (value.length > 50) {
      this.setState({ indicator: value, indicatorError: `Error: ${tr('business-plan.errors.maxCharLimitExceeded')}` });
      return;
    }
    this.setState({ indicator: value, indicatorError: '' });
  }

  handleClickSave = () => {
    const { handleGetIndicatorToAdd, handleUpdateIndicator, tr } = this.props;
    const { indicator, indicatorId, indicatorError } = this.state;
    if (indicator) {
      if (indicatorId && !indicatorError) handleUpdateIndicator(indicator);
      if (!indicatorId && !indicatorError) handleGetIndicatorToAdd(indicator);
    } else {
      this.setState({ indicatorError: tr('business-plan.errors.empty') });
    }
  }

  render() {
    const { tr, handleClickCancelIndicator } = this.props;
    const { indicator, indicatorError } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column onKeyPress={this.onKeyPress} largeScreen={12} computer={12} tablet={12} mobile={16}>
            <TextField
              id="indicator"
              label={`${tr('business-plan.indicator')}...`}
              value={indicator}
              error={indicatorError}
              maxLength={50}
              color="green"
              onChange={this.handleChangeIndicator}
              fullWidth
              multiline
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
            <Button onClick={this.handleClickSave} color="blue">{tr('save')}</Button>
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
            <Button onClick={handleClickCancelIndicator}>{tr('back')}</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AddIndicator);

AddIndicator.defaultProps = {
  tr: () => {},
  handleGetIndicatorToAdd: null,
  indicator: '',
  indicatorId: '',
  handleUpdateIndicator: null,
  handleClickCancelIndicator: null,
};

AddIndicator.propTypes = {
  tr: PropTypes.func,
  handleGetIndicatorToAdd: PropTypes.func,
  indicator: PropTypes.string,
  handleUpdateIndicator: PropTypes.func,
  indicatorId: PropTypes.number,
  handleClickCancelIndicator: PropTypes.func,
};
