import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import SectorRow from './sector-row';

function SectorTable(props) {
  const {
    t, sectorItems, handleUpdate, handleDelete,
  } = props;
  return (
    <Grid className="no-margin-top" celled>
      <Grid.Row color="grey">
        <Grid.Column
          largeScreen={6}
          computer={6}
          tablet={6}
          mobile={6}
          textAlign="center"
          verticalAlign="middle"
        >
          <Header size="small" inverted>{t('clientPlan.label.sector')}</Header>
        </Grid.Column>
        <Grid.Column
          className="no-padding"
          largeScreen={10}
          computer={10}
          tablet={10}
          mobile={10}
          verticalAlign="middle"
        >
          <Grid celled className="no-margin">
            {
              sectorItems.map(({
                id, industry, industryId, percent,
              }) => (
                <SectorRow
                  key={id}
                  industryId={industryId}
                  industry={industry}
                  percent={percent}
                  handleUpdate={handleUpdate}
                  handleDelete={handleDelete}
                />
              ))
            }
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

SectorTable.propTypes = {
  t: PropTypes.func.isRequired,
  sectorItems: PropTypes.arrayOf(PropTypes.shape({})),
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
};

SectorTable.defaultProps = {
  sectorItems: [],
  handleUpdate: () => { },
  handleDelete: () => { },
};

export default translate('common', { wait: true })(SectorTable);
