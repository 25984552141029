import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

class Months extends Component {
  constructor(props) {
    super(props);
    const { tr, month } = props;
    this.state = {
      monthOptions: [
        { key: 1, value: 1, text: tr('orders.months.january') },
        { key: 2, value: 2, text: tr('orders.months.february') },
        { key: 3, value: 3, text: tr('orders.months.march') },
        { key: 4, value: 4, text: tr('orders.months.april') },
        { key: 5, value: 5, text: tr('orders.months.may') },
        { key: 6, value: 6, text: tr('orders.months.june') },
        { key: 7, value: 7, text: tr('orders.months.july') },
        { key: 8, value: 8, text: tr('orders.months.august') },
        { key: 9, value: 9, text: tr('orders.months.september') },
        { key: 10, value: 10, text: tr('orders.months.october') },
        { key: 11, value: 11, text: tr('orders.months.november') },
        { key: 12, value: 12, text: tr('orders.months.december') },
      ],
      value: month,
    };
  }

  handleChange = (e, { value }) => {
    const { change } = this.props;
    this.setState({ value });
    change(value);
  }

  render() {
    const { value, monthOptions } = this.state;
    const { tr, title, noResultsMessage } = this.props;
    return (
      <Dropdown
        clearable
        placeholder={tr(title)}
        value={value}
        search
        selection
        options={monthOptions}
        onChange={this.handleChange}
        noResultsMessage={noResultsMessage}
      />
    );
  }
}
Months.defaultProps = {
  title: 'Mes',
  noResultsMessage: 'Intenta con otro valor',
  month: '',
  tr: () => {},
  change: () => {},
};

Months.propTypes = {
  change: PropTypes.func,
  month: PropTypes.string,
  noResultsMessage: PropTypes.string,
  title: PropTypes.string,
  tr: PropTypes.func,
};

export default Months;
