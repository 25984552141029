import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Dimmer, Image } from 'semantic-ui-react';
import { promotionClickGA } from '../../utils';
import routes from '../../../route-names';

const BannerElement = props => {
  const [activeDimmer, setActiveDimmer] = useState(false);
  const {
    mobile, tablet, web, action, promotionName: name, id, position, history,
  } = props;

  const handleBannerAction = () => {
    const { type, value } = action;
    promotionClickGA({ id, name, position });
    switch (type) {
      case 'image':
        setActiveDimmer(true);
        break;
      case 'detail':
        history.push(`${(routes.productDetails.route).replace(':sku', encodeURIComponent(value))}`);
        break;
      case 'search':
        history.push(`${(routes.products.route).replace(':category?', '00').replace(':search?', encodeURIComponent(value))}`);
        break;
      case 'redirect':
        window.location.replace(value);
        break;
      case 'route':
        history.push(value);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    setActiveDimmer(false);
  };

  return (
    <Grid style={{ cursor: 'pointer' }} padded>
      <Grid.Row className="no-padding-y" centered>
        <Grid.Column className="no-padding-x" largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
          <Image
            alt="No image"
            fluid
            onClick={handleBannerAction}
            src={mobile}
            srcSet={`${mobile} 360w,${tablet} 768w,${web} 1920w`}
          />
          {
            action.type === 'image'
              ? (
                <Dimmer active={activeDimmer} onClickOutside={handleClose} page>
                  <Image
                    alt="No image"
                    size="massive"
                    src={action.value}
                  />
                </Dimmer>
              )
              : null
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

BannerElement.propTypes = {
  id: PropTypes.number,
  position: PropTypes.number.isRequired,
  promotionName: PropTypes.string,
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  web: PropTypes.string,
  action: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }),
};

BannerElement.defaultProps = {
  id: 0,
  mobile: '',
  tablet: '',
  web: '',
  action: { type: '', value: '' },
  promotionName: '',
};

export default translate('common', { wait: true })(BannerElement);
