import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Image, Card, Button, Icon, Header, Responsive, Loader,
} from 'semantic-ui-react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { currencyFormat } from '../../common/utils';
import Toast from '../../common/components/toast';
import products from '../services';
import '../styles.css';

const headerStyle = {
  fontWeight: '500',
  fontSize: '1.2em',
  color: '#00549F',
  height: '1.2em',
  cursor: 'pointer',
};

const textStyle = {
  fontSize: '16px',
  textAlign: 'center',
};

class RelatedProducts extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.relatedProductsArray !== state.relatedProductsArray) {
      return {
        relatedProductsArray: props.relatedProductsArray,
        product: props.relatedProductsArray[0],
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      relatedProductsArray: [],
      product: [],
      transformValue: null,
      steppedPriceHpMapId: '',
      quantity: 1,
      adding: false,
    };
  }

  componentDidMount() {
    this.transformValue();
  }

  nextCard = () => {
    const { product, relatedProductsArray } = this.state;
    const newIndex = product.index + 1;
    this.setState({
      product: relatedProductsArray[newIndex],
    });
  }

  prevCard = () => {
    const { product, relatedProductsArray } = this.state;
    const newIndex = product.index - 1;
    this.setState({
      product: relatedProductsArray[newIndex],
    });
  }

  transformValue = () => {
    const { relatedProductsArray } = this.state;
    const arraylength = relatedProductsArray.length;
    let transform = arraylength * arraylength;
    if ((arraylength >= 5) && (arraylength <= 10)) { transform *= 7; }
    if ((arraylength >= 10) && (arraylength <= 15)) { transform *= 6; }
    if ((arraylength >= 15) && (arraylength <= 19)) { transform *= 4; }
    if ((arraylength >= 20) && (arraylength <= 24)) { transform *= 3.5; }
    if ((arraylength >= 25) && (arraylength <= 29)) { transform *= 3.5; }
    if ((arraylength >= 30) && (arraylength <= 45)) { transform *= 3.3; }
    this.setState({ transformValue: transform });
  }

  addItems = async skuProduct => {
    const { tr, getShoppingCartCount } = this.props;
    const productId = skuProduct ? encodeURIComponent(skuProduct) : skuProduct;
    const {
      steppedPriceHpMapId, quantity, product,
    } = this.state;
    const {
      name, sku, price, currency, maker, category,
    } = product;
    const body = {
      items: [
        {
          sku: decodeURIComponent(productId),
          quantity,
          hpMapId: steppedPriceHpMapId,
        },
      ],
    };
    this.setState({ adding: true });
    await products.addItems(body)
      .then(response => {
        const id = response.orderId;
        if (id.length > 0) {
          Toast(tr('productDetails.productInCart'), 'success');
          const currencyPrice = currency.toLowerCase() === 'pesos' ? 'MXN' : 'USD';
          ReactGA.set('currencyCode', currencyPrice);
          ReactGA.plugin.execute('ec', 'addProduct', {
            id: sku,
            name,
            brand: maker,
            category,
            price,
            quantity,
          });
          ReactGA.plugin.execute('ec', 'setAction', 'add');
          ReactGA.event({
            category: 'Comercio',
            action: 'Agregar al carrito',
            label: sku,
          });
          ReactGA4.event({
            category: 'Comercio',
            action: 'Agregar al carrito',
            label: sku,
          });
          ReactGA.plugin.execute('ec', 'clear');
          this.setState({ adding: false });
        }
      })
      .then(() => {
        getShoppingCartCount();
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = tr('error-codes.default');
        else messageError = tr(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  render() {
    const {
      product, relatedProductsArray, transformValue, adding,
    } = this.state;
    const {
      tr, goToProduct, isAvailableRedhat, maker,
    } = this.props;
    const currency = relatedProductsArray.currency === 'Dolares' || relatedProductsArray.currency === 'Dólares' ? 'USD' : 'MXN';
    return (
      <Grid padded centered>
        {
          (product == null)
            ? <Loader active inline="centered">{tr('productDetails.loading')}</Loader>
            : (
              <Grid.Row>
                <Grid.Column largeScreen={1} computer={1} tablet={1} verticalAlign="middle" textAlign="left">
                  {
                    (relatedProductsArray.length <= 5)
                      ? null : (
                        <Responsive as={Header} minWidth={1024}>
                          <Icon
                            style={{ cursor: 'pointer', marginRight: 0 }}
                            Link
                            size="big"
                            name="angle left"
                            className="lightGreyText"
                            fitted
                            onClick={() => this.prevCard()}
                            disabled={product.index === 0}
                          />
                        </Responsive>
                      )
                  }
                </Grid.Column>
                <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16} style={{ overflowX: 'auto', minHeight: '300px' }}>
                  <Grid.Row className={`cards-slider-relatedProducts active-slide-${product.index}`}>
                    <Grid.Column
                      className="cards-slider-wrapper-relatedProducts"
                    >
                      {
                        relatedProductsArray.map(prod => {
                          const image = prod.image || '';
                          return (
                            <Grid.Column
                              key={prod.sku}
                              className="cards-relatedProducts-slider-scroll"
                              style={{
                                transform: `translateX(-${product.index * (transformValue / relatedProductsArray.length)}%)`,
                                margin: '.5rem',
                              }}
                            >
                              <Card
                                as="a"
                                style={{ cursor: 'auto' }}
                                fluid
                                centered
                                className="card-relatedproducts card-product"
                                key={relatedProductsArray.relatedId}
                                // onClick={() => this.props.goToProduct(prod.sku)}
                              >
                                <Image as="img" src={image.replace('http://', 'https://')} centered wrapped size="small" onClick={() => goToProduct(prod.sku)} style={{ cursor: 'pointer' }} />
                                <Card.Content>
                                  <Header onClick={() => goToProduct(prod.sku)} style={headerStyle} className="header_productName_relatedProducts" textAlign="center">{prod.name}</Header>
                                  <Card.Meta textAlign="center">
                                    SKU :
                                    {prod.sku}
                                  </Card.Meta>
                                  {
                                    (Number(prod.promotionPrice) === 0 || prod.promotionPrice === null || prod.promotionPrice === '')
                                      ? null
                                      : (
                                        <Card.Description>
                                          <p className="fullBlackText" style={textStyle}><b><strike>{currencyFormat(prod.price, currency)}</strike></b></p>
                                        </Card.Description>
                                      )
                                  }
                                  <Card.Description>
                                    {
                                      Number(prod.price) === 0
                                        ? null
                                        : (
                                          <p className="greenText" style={textStyle}>
                                            <b>
                                              {(Number(prod.promotionPrice) === 0 || prod.promotionPrice === null || prod.promotionPrice === '')
                                                ? `${currencyFormat(prod.price, currency)}`
                                                : `${currencyFormat(prod.promotionPrice, currency)}`}
                                            </b>
                                          </p>
                                        )
                                      }
                                  </Card.Description>
                                  <Card.Description style={{ margin: '1em 0' }}>
                                    {(prod.maker === 'VMware' || prod.maker === 'VMWARE') ? (
                                      <Button
                                        primary
                                        size="medium"
                                        fluid
                                        onClick={() => goToProduct(prod.sku)}
                                      >
                                        Ver más
                                      </Button>
                                    ) : (
                                      <Button
                                        primary
                                        size="small"
                                        fluid
                                        loading={adding}
                                        disabled={adding || (!isAvailableRedhat && maker === 'Red Hat')}
                                        onClick={() => this.addItems(prod.sku)}
                                      >
                                        Agregar al carrito
                                      </Button>
                                    )}
                                  </Card.Description>
                                </Card.Content>
                              </Card>
                            </Grid.Column>
                          );
                        })
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
                <Grid.Column largeScreen={1} computer={1} tablet={1} verticalAlign="middle" textAlign="right">
                  {
                    (relatedProductsArray.length <= 5)
                      ? null
                      : (
                        <Responsive as={Header} minWidth={1024}>
                          <Icon
                            style={{ cursor: 'pointer', marginRight: 0 }}
                            Link
                            size="big"
                            name="angle right"
                            className="lightGreyText"
                            fitted
                            onClick={() => this.nextCard()}
                            disabled={product.index === relatedProductsArray.length - 1}
                          />
                        </Responsive>
                      )
                  }
                </Grid.Column>
              </Grid.Row>
            )
        }
      </Grid>
    );
  }
}

RelatedProducts.propTypes = {
  getShoppingCartCount: PropTypes.func.isRequired,
  goToProduct: PropTypes.func.isRequired,
  relatedProductsArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tr: PropTypes.func.isRequired,
  isAvailableRedhat: PropTypes.bool.isRequired,
  maker: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(RelatedProducts);
