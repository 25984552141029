const data = {};

data.headerGroups = [
  { title: 'Seleccionar' },
  { title: 'Grupo' },
  { title: 'Miembros' },
];

data.headerClientsOfGroups = [
  { title: '' },
  { title: 'Cliente' },
  { title: 'Grupos Actuales' },
];

data.headerDiscountsClients = [
  { title: 'Seleccionar' },
  { title: 'Cliente' },
];

data.discountsGroupsArray = [
  { id: 1, name: 'VIP', members: 5 },
  { id: 2, name: 'Plata', members: 2 },
  { id: 3, name: 'Oro', members: 6 },
];

data.clientsOfGroups = [
  {
    id: 1, name: 'Client 1', group: 'Plata', checked: false,
  },
  {
    id: 2, name: 'Client 2', group: 'Oro', checked: false,
  },
  {
    id: 3, name: 'Client 3', group: 'VIP', checked: false,
  },
  {
    id: 4, name: 'Client 4', group: 'Plata', checked: false,
  },
  {
    id: 5, name: 'Client 5', group: 'Oro', checked: false,
  },
  {
    id: 6, name: 'Client 6', group: 'VIP', checked: false,
  },
  {
    id: 7, name: 'Client 7', group: 'Plata', checked: false,
  },
  {
    id: 8, name: 'Client 8', group: 'Oro', checked: false,
  },
  {
    id: 9, name: 'Client 9', group: 'VIP', checked: false,
  },
  {
    id: 10, name: 'Client 10', group: 'Plata', checked: false,
  },
  {
    id: 11, name: 'Client 11', group: 'Oro', checked: false,
  },
  {
    id: 12, name: 'Client 12', group: 'VIP', checked: false,
  },
];

data.quickQuoteResponse = {
  requestId: 784635,
  hardwareAmount: 2500,
  softwareAmount: 2500,
  printerAmount: 2500,
  servicesAmount: 2500,
  LeasePaymentOptions: [
    {
      id: 1,
      LeaseType: 'FL',
      LeaseTerm: '12',
      PaymentFrequency: 'MON',
      Amount: 928.55,
      AmountIncUplift: 928.55,
    },
    {
      id: 2,
      LeaseType: 'FL',
      LeaseTerm: '24',
      PaymentFrequency: 'MON',
      Amount: 489.29,
      AmountIncUplift: 489.29,
    },
    {
      id: 3,
      LeaseType: 'FL',
      LeaseTerm: '36',
      PaymentFrequency: 'MON',
      Amount: 341.83,
      AmountIncUplift: 341.83,
    },
    {
      id: 4,
      LeaseType: 'FL',
      LeaseTerm: '48',
      PaymentFrequency: 'MON',
      Amount: 271.67,
      AmountIncUplift: 271.67,
    },
    {
      id: 5,
      LeaseType: 'FL',
      LeaseTerm: '60',
      PaymentFrequency: 'MON',
      Amount: 227.81,
      AmountIncUplift: 227.81,
    },
  ],
};

export default data;
