import React, { Component } from 'react';
import {
  Grid, Header, Sticky, Dimmer, Loader, Responsive,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Summary from './summary';

const Loading = ({ disable, message }) => (
  <Dimmer active={!disable} inverted>
    <Loader active>
      {' '}
      {message}
      {' '}
    </Loader>
  </Dimmer>
);

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: null,
    };
  }

  handleContextRef = ref => {
    this.setState({ context: ref });
  };

  render() {
    const {
      t, children: childrenComponents, currency, initialLoader,
      subtotal, shippingCost, iva, total, discount, items, loader, ActionButtons, actionLoader,
    } = this.props;
    const { context } = this.state;
    return (
      <div ref={this.handleContextRef}>
        {
          loader || initialLoader
            ? <Loading disable={!loader && !initialLoader} message={t('loading')} />
            : (
              <Grid padded centered>
                <Grid.Row>
                  <Grid.Column mobile={13} tablet={13} computer={10} largeScreen={10} widescreen={10}>
                    <Header color="blue">
                      {' '}
                      {t('shoppingCart.title')}
                      {' '}
                    </Header>
                  </Grid.Column>
                  <Grid.Column mobile={1} tablet={1} computer={5} largeScreen={4} widescreen={4}>
                    {
                    actionLoader
                      ? <Loader active inline />
                      : null
                  }
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile={14} tablet={14} computer={11} largeScreen={11} widescreen={11}>
                    {childrenComponents}
                  </Grid.Column>
                  <Responsive as={Grid.Column} minWidth={Responsive.onlyComputer.minWidth} computer={4} largeScreen={3} widescreen={3} style={{ margin: '0 2rem' }}>
                    <Sticky offset={50} context={context}>
                      <Grid>
                        <Grid.Row>
                          {
                            items.length === 0
                              ? null
                              : (
                                <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} className="styleSummary">
                                  <Summary
                                    subtotal={subtotal}
                                    shippingCost={shippingCost}
                                    iva={iva}
                                    currency={currency}
                                    discount={discount}
                                    total={total}
                                    items={items}
                                  />
                                </Grid.Column>
                              )
                          }
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                            {
                            items.length === 0
                              ? null
                              : ActionButtons()
                          }
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Sticky>
                  </Responsive>
                </Grid.Row>
              </Grid>
            )
        }
      </div>
    );
  }
}

OrderDetails.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  ActionButtons: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  loader: PropTypes.bool.isRequired,
  actionLoader: PropTypes.bool.isRequired,
  subtotal: PropTypes.number.isRequired,
  shippingCost: PropTypes.number.isRequired,
  iva: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  initialLoader: PropTypes.bool.isRequired,
};

Loading.propTypes = {
  message: PropTypes.string.isRequired,
  disable: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(OrderDetails);
