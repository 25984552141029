import { CONTENT_SOURCE } from './constants';

export const getMainImage = images => {
  const mainImage = images.find(image => image.value.isMain);
  return mainImage ? mainImage.value.low : 'https://react.semantic-ui.com/images/wireframe/image.png';
};

export const getContentSource = (source, translate) => {
  const optionsTranslatePath = 'productConfig.menus.chooseContent.itemListActions.options';
  const contentSource = {
    [CONTENT_SOURCE.ERP]: translate(`${optionsTranslatePath}.erp`),
    [CONTENT_SOURCE.MANUAL]: translate(`${optionsTranslatePath}.manual`),
    [CONTENT_SOURCE.CONTENT_PROVIDER]: translate(`${optionsTranslatePath}.contentProvider`),
  };
  return contentSource[source].toUpperCase();
};
