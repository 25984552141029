const categoriesProduct = [
  {
    name: 'ElegirContenido',
    icon: 'external square alternate',
    content: 'Elegir fuente de contenido',
  },
  {
    name: 'EditarContenido',
    icon: 'options',
    content: 'Editar contenido',
  },
  {
    name: 'Imagenes',
    icon: 'upload',
    content: 'Subir imágenes',
  },
  {
    name: 'CargaMasiva',
    icon: 'cloud upload',
    content: 'Carga masiva de imágenes',
  },
  {
    name: 'Sincronizador',
    icon: 'sync',
    content: 'Sincronizador',
  },
];

const categoriesSearcher = [
  {
    name: 'Atributos',
    icon: 'tags',
    content: 'Tags de búsqueda',
  },
  {
    name: 'Categorizacion',
    icon: 'stack exchange',
    content: 'Categorización',
  },
];

const categoriesDiscounts = [
  {
    name: 'Cupones',
    icon: 'percent',
    content: 'Cupones',
  },
  {
    name: 'Grupos',
    icon: 'buromobelexperte',
    content: 'Cupones por grupo',
  },
  {
    name: 'Autorizadores',
    icon: 'users',
    content: 'Autorizadores',
  },
  {
    name: 'Administradores',
    icon: 'cogs',
    content: 'Administradores',
  },
  {
    name: 'BuscadorCupones',
    icon: 'search',
    content: 'Buscador de cupones',
  },
];

const categories = [
  {
    name: 'AltaAgentes',
    icon: 'add user',
    content: 'Alta Agentes',
  },
  {
    name: 'DirectorioAgentes',
    icon: 'address book',
    content: 'Directorio Agentes',
  },
  {
    name: 'UsuariosDistribuidor',
    icon: 'users',
    content: 'Distribuidores por usuario',
  },
  {
    name: 'AltaServicioCuenta',
    icon: 'add circle',
    content: 'API´s siclik Comercio',
  },
];

const categoriesmkt = [
  {
    name: 'Promocionales',
    icon: 'images',
    content: 'Recursos estáticos',
  },
  {
    name: 'Notificaciones',
    icon: 'bell',
    content: 'Notificaciones',
  },
  {
    name: 'Logistica',
    icon: 'truck',
    content: 'Logística',
  },
];

const allCategories = {
  categoriesProduct,
  categoriesSearcher,
  categoriesDiscounts,
  categories,
  categoriesmkt,
};

export default allCategories;
