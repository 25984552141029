import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import '../login/styles.css';
import PublicHeaderComercio from './components/headerComercio';

class LoginLayoutComercio extends Component {
  constructor(props) {
    super(props);
    this.loginDiv = React.createRef();
    this.state = {
    };
  }

  render() {
    const { children } = this.props;
    return (
      <div className="login-container">
        <div className="login-header"><PublicHeaderComercio /></div>
        <div className="login-children">
          <Grid verticalAlign="middle" centered>
            <Grid.Row centered>
              <Grid.Column mobile={12} tablet={9} computer={7} widescreen={9} largeScreen={6}>
                {children}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

LoginLayoutComercio.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default LoginLayoutComercio;
