import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Button, Grid, Icon, Header, Responsive, Loader, Confirm,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { TextField } from '../common/components/materials';
import * as session from '../common/sessions';
import SiclikTable from '../common/components/table';
import Toast from '../common/components/toast';
import routes from '../route-names';
import branchOfficeAddress from './services';

class DeliveryAddress extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
    ]) || securityService.validate(access, [
      securityService.getAccess.distribuidores,
    ]);

    if (!hasAccess) props.history.push('/perfil');
    this.state = {
      filter: '',
      filterError: '',
      messageTable: 'No se encontraron direcciones registradas',
      customerAddress: [],
      confirmDelete: false,
      addressToDelete: '',
      addressId: '',
      customerHeaders: [
        { key: 'id', isKey: true },
        { key: 'name', name: t('headers.address.name') },
        { key: 'street', name: t('headers.address.address') },
        { key: 'postalCode', name: t('headers.address.postalCode') },
        { key: 'city', name: t('headers.address.town') },
        { key: 'state', name: t('headers.address.estado') },
        {
          key: 'isValidated',
          name: t('headers.address.status'),
          format: (cell, row) => (cell == true ? <Icon color="green" name="checkmark" size="large" /> : ''), // eslint-disable-line
        },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleUpdateBranchOffice(row.id)} />
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleDeleteBranchOffice(row)} />
            </Grid.Row>
          ), // eslint-dsable-line
        },
      ],
      loading: true,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentDidMount() {
    this.handleGetAddress();
  }

  handleGetAddress = async () => {
    const { t, steps } = this.props;
    const arrayBranchOfficeAddress = await branchOfficeAddress.getBranchOfficeAddress()
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ customerAddress: [], filteredInfoAddress: [], loading: false });
        if (error.code !== 1000) Toast(messageError, 'error');
      });

    if (arrayBranchOfficeAddress && arrayBranchOfficeAddress.branchOffices) {
      const customerAddress = arrayBranchOfficeAddress.branchOffices;
      const filteredInfoAddress = arrayBranchOfficeAddress.branchOffices;
      if (steps[4] && steps[4].completed === false) this.handleUpdateStep();
      this.setState({ customerAddress, filteredInfoAddress, loading: false });
      this.handleUpdateStep();
    }
  }

  handleUpdateStep = () => {
    const { t, updateStep } = this.props;
    const body = { stepId: 5, status: 1 };
    const stepToUpdate = { key: 5, completed: true };
    branchOfficeAddress.updateStep(body)
      .then(() => updateStep(stepToUpdate))
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  validations = event => {
    const { t } = this.props;
    const maxLength = 30;
    let mistakes = false;
    const { value: filter } = event.target;
    if ((filter.trim()).length > maxLength) {
      this.setState({ filterError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) {
      this.setState({ filter, filterError: '' });
      this.handleFilterChange(filter, mistakes);
    }
  }

  handleFilterChange = (filter, mistakes) => {
    const { customerAddress } = this.state;
    const adressesForFilter = [...customerAddress];
    let foundData = [];
    if (mistakes !== true) {
      foundData = adressesForFilter.filter(filterInfo => (filterInfo.intelisisId && filterInfo.intelisisId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.street.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.postalCode.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.city.toLowerCase().indexOf(filter.toLowerCase()) >= 0
        || filterInfo.state.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
      this.setState({ filteredInfoAddress: foundData });
    }
  }

  handleAddClick = () => {
    const { history } = this.props;
    history.push('/sucursales');
  }

  goNext = () => {
    const { history } = this.props;
    history.push(routes.dashboard.route);
  }

  goBack = backStep => {
    const { history } = this.props;
    history.push(backStep);
  }

  handleCancel = () => this.setState({ confirmDelete: false });

  handleDeleteBranchOffice = row => {
    this.setState({
      addressToDelete: row.name, confirmDelete: true, addressId: row.id,
    });
  }

  handleDeleteConfirmed = () => {
    const { addressId, customerAddress, filter } = this.state;
    const { t } = this.props;
    branchOfficeAddress.disableBranchOffice(addressId)
      .then(() => {
        Toast(t('branchOffice.eliminationConfirmation'), 'success');
        const addressToDeleted = customerAddress.findIndex(address => address.id === addressId);
        customerAddress.splice(addressToDeleted, 1);
        this.handleFilterChange(filter, false);
        this.setState({ customerAddress, confirmDelete: false });
      })
      .catch(error => (error));
  }

  handleUpdateBranchOffice = row => {
    const { history } = this.props;
    history.push(`sucursales/${row}`);
  }

  render() {
    const { t, steps } = this.props;
    const {
      filter, filterError, customerHeaders, filteredInfoAddress, messageTable, loading, confirmDelete, addressToDelete,
    } = this.state;
    let backStep = '';
    if (steps.length > 1) {
      const back = (steps.filter(step => step.key < 5));
      backStep = back[back.length - 1].href;
    }
    const currentKey = steps.find(step => step.key === 5);
    return (
      <Grid container>
        <Grid.Row columns={3} centered>
          { currentKey && (currentKey.key === 5 && currentKey.completed === false)
            && (
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={16}>
              <Header textAlign="center"><Icon onClick={() => this.goBack(backStep)} name="arrow circle left" size="huge" /></Header>
              <Responsive as={Header} minWidth={768}>
                <Header textAlign="center" as="h4">{t('back')}</Header>
              </Responsive>
            </Grid.Column>
            )}
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15}>
            <Header color="blue" textAlign="center" as="h2">{t('branchOffice.title')}</Header>
          </Grid.Column>
          { currentKey && (currentKey.key === 5 && currentKey.completed === false)
            && (
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={16}>
              <Header textAlign="center"><Icon onClick={() => this.goNext()} name="arrow circle right" size="huge" /></Header>
              <Responsive as={Header} minWidth={768}>
                <Header as="h4" textAlign="center">{t('next')}</Header>
              </Responsive>
            </Grid.Column>
            )}
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={14} computer={14} tablet={12} mobile={13}>
            <Header as="h4">
              {t('branchOffice.info')}
              <Icon color="green" name="check" />
              {t('branchOffice.info2')}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={10} mobile={2} />
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={13}>
            <Button content={t('add')} labelPosition="left" icon="plus" onClick={this.handleAddClick} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={10} mobile={15}>
            <TextField
              maxLength={30}
              fullWidth
              value={filter || ''}
              error={filterError || ''}
              label={t('branchOffice.filter')}
              onChange={this.validations}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={2} />
        </Grid.Row>
        <Grid.Row centered>
          {loading
            ? <Loader active inline="centered" />
            : (
              <Grid.Column largeScreen={14} computer={14} tablet={12} mobile={14} style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  selectable
                  singleSelection
                  data={filteredInfoAddress}
                  headers={customerHeaders}
                  pageSize={10}
                  noDataMessage={messageTable}
                />
              </Grid.Column>
            )}
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={confirmDelete}
            header={t('branchOffice.deleteBranchOffices')}
            content={addressToDelete}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleDeleteConfirmed}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

DeliveryAddress.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    completed: PropTypes.bool,
  })),
  updateStep: PropTypes.func,
};

DeliveryAddress.defaultProps = {
  steps: [],
  updateStep: () => {},
};

export default translate('common', { wait: true })(DeliveryAddress);
