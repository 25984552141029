import React, { useState, useEffect } from 'react';
import {
  Grid, Divider, Header, Loader, Input, Responsive, Button, Image, Icon, Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { currencyFormat } from '../../common/utils';
import ItemDivider from '../../shopping-cart/components/item-divider';

const baseUrl = process.env.REACT_APP_ENDPOINT;

function ItemsWishList(props) {
  const {
    t, updateQuantity, wishListId, sendItemToCart, removeItem, items: propItems, itemsLoader,
  } = props;
  const [updatedQuantity, setUpdatedQuantity] = useState(0);
  const [items, setItems] = useState(false);

  function setItemsLoader(selectedItem, isActive) {
    const setLoaderItems = items.map(item => (
      item.sku === selectedItem.sku
        ? { ...item, optionsLoader: isActive }
        : { ...item, optionsLoader: false }
    ));
    setItems(setLoaderItems);
  }

  function handleSendCartLoader(selectedItem, isActive) {
    const setLoaderItems = items.map(item => (
      item.sku === selectedItem.sku
        ? { ...item, sendCartLoader: isActive }
        : { ...item, sendCartLoader: false }
    ));
    setItems(setLoaderItems);
  }

  function handleOnSelectedItem(sku, isActive) {
    const setCheckItems = items.map(item => (
      item.sku === sku
        ? { ...item, selected: isActive }
        : { ...item, selected: false }
    ));
    setItems(setCheckItems);
  }

  function validateQuantity(value) {
    if (value > 0) return value;
    if (value === 0 || value < 0) return 1;
    return value;
  }

  const handleUpdateQuantity = ({ target }) => {
    const roundedQuantity = (typeof target.value === 'string') ? target.value : Math.round(target.value);
    const validNumber = validateQuantity(Number(roundedQuantity));
    setUpdatedQuantity(validNumber);
  };

  async function handleOnSaveQuantity({ sku, quantity }) {
    if (updatedQuantity > 0 && updatedQuantity !== quantity) {
      handleOnSelectedItem(sku, false);
      setItemsLoader({ sku }, true);
      await updateQuantity(wishListId, {
        quantity: updatedQuantity,
        sku,
      });
      setItemsLoader({ sku }, false);
      setUpdatedQuantity(0);
    } else {
      handleOnSelectedItem(sku, false);
    }
  }

  async function handleOnSendToCart(item) {
    try {
      handleSendCartLoader(item, true);
      await sendItemToCart(item);
      handleSendCartLoader(item, false);
    } catch (error) {
      handleSendCartLoader(item, false);
    }
  }

  async function handleOnRemoveItem(item) {
    try {
      setItemsLoader(item, true);
      await removeItem(wishListId, item.sku);
      setItemsLoader(item, false);
    } catch (error) {
      setItemsLoader(item, false);
    }
  }

  function inputUpdateQuanity(item) {
    return (
      <Input
        action={{
          content: 'Guardar',
          onClick: () => handleOnSaveQuantity(item),
          size: 'tiny',
        }}
        onChange={handleUpdateQuantity}
        defaultValue={item.quantity}
        style={{ width: '60px' }}
        disabled={item.optionsLoader}
      />
    );
  }

  function generateItem(item) {
    const validatedImage = item.image || '';
    const encodedSku = encodeURIComponent(item.sku);
    const detailPath = `${baseUrl}/producto/detalle/${encodedSku}`;
    return (
      <Grid key={item.sku}>
        <Grid.Row only="tablet computer largeScreen">
          <Grid.Column verticalAlign="middle" tablet={3} computer={3} largeScreen={3} widescreen={3}>
            <Image
              src={validatedImage.replace('http://', 'https://')}
              size="small"
              as="a"
              href={item.name}
              alt=""
              bordered
              centered
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(detailPath, '_blank')}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" tablet={4} computer={4} largeScreen={4} widescreen={4}>
            <Header as="h5" className="no-margin" color="blue" textAlign="justified" style={{ cursor: 'pointer' }} onClick={() => window.open(detailPath, '_blank')}>{item.name}</Header>
            <p>
              SKU:
              {item.sku}
            </p>
            <p hidden={!item.sku2 || item.sku2 === ''} style={{ fontSize: 11 }}>
              SKU2:
              {item.sku2}
            </p>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" tablet={2} computer={2} largeScreen={2} widescreen={2}>
            {/* <Input
              action={{
                content: 'Cambiar',
                onClick: () => handleOnSaveQuantity(item),
                size: 'tiny',
              }}
              onChange={handleUpdateQuantity}
              defaultValue={item.quantity}
              style={{ width: '60px' }}
            /> */}
            {item.selected
              ? inputUpdateQuanity(item)
              : (
                <Dropdown
                  text={item.quantity}
                  icon="ellipsis vertical"
                  labeled
                  button
                  className="icon"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text={t('wishList.items.updateQuantity')}
                      icon="edit"
                      onClick={() => handleOnSelectedItem(item.sku, true)}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              )}
          </Grid.Column>
          <Grid.Column
            verticalAlign="middle"
            textAlign="right"
            tablet={3}
            computer={3}
            largeScreen={3}
            widescreen={3}
          >
            <Header as="h5" color="green">
              {' '}
              {currencyFormat(item.price, item.currency)}
            </Header>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" tablet={3} computer={3} largeScreen={3} widescreen={3}>
            <Button
              floated="right"
              size="small"
              content={t('wishList.items.sendToCart')}
              icon="in cart"
              color="blue"
              onClick={() => handleOnSendToCart(item)}
              loading={item.sendCartLoader}
              disabled={item.optionsLoader}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" tablet={1} computer={1} largeScreen={1} widescreen={1}>
            { (!item.optionsLoader)
              ? (
                <Icon
                  name="trash alternate"
                  size="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOnRemoveItem(item)}
                />
              )
              : <Loader active inline />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile" className="no-padding">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding">
            <Image
              src={validatedImage.replace('http://', 'https://')}
              size="small"
              as="a"
              href={item.name}
              alt=""
              bordered
              centered
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(detailPath, '_blank')}
            />
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Header as="h5" className="no-margin" color="blue" style={{ cursor: 'pointer' }} onClick={() => window.open(detailPath, '_blank')}>{item.name}</Header>
            <p>
              SKU:
              {item.sku}
            </p>
            <p hidden={!item.sku2 || item.sku2 === ''} style={{ fontSize: 11 }}>
              SKU2:
              {item.sku2}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding" />
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Grid>
              <Grid.Row className="no-padding">
                <Grid.Column verticalAlign="middle" textAlign="left" mobile={8}>
                  <Header as="h5" className="no-margin">{t('shoppingCart.itemHeaders.unitPrice')}</Header>
                  <Header as="h5" className="no-margin" color="green">
                    {' '}
                    {currencyFormat(item.price, item.currency)}
                  </Header>
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="right" mobile={8}>
                  {item.selected
                    ? inputUpdateQuanity(item)
                    : (
                      <Dropdown
                        text={item.quantity}
                        icon="ellipsis vertical"
                        labeled
                        button
                        className="icon"
                      >
                        <Dropdown.Menu>
                          <Dropdown.Item
                            text={t('wishList.items.updateQuantity')}
                            icon="edit"
                            onClick={() => handleOnSelectedItem(item.sku, true)}
                          />
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile" className="no-padding-bottom">
          <Grid.Column verticalAlign="middle" mobile={5} className="no-padding" />
          <Grid.Column verticalAlign="middle" mobile={11}>
            <Grid>
              <Grid.Row>
                <Grid.Column verticalAlign="middle" textAlign="center" mobile={13}>
                  <Button
                    fluid
                    size="small"
                    content={t('wishList.items.sendToCart')}
                    icon="in cart"
                    color="blue"
                    onClick={() => handleOnSendToCart(item)}
                    loading={item.sendCartLoader}
                    disabled={item.optionsLoader}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign="middle" textAlign="center" mobile={3}>
                  { (!item.optionsLoader)
                    ? (
                      <Icon
                        name="trash alternate"
                        size="large"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleOnRemoveItem(item)}
                      />
                    )
                    : <Loader active inline />}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Divider />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  function emptyItem(message) {
    return <Header as="h5" textAlign="center">{message}</Header>;
  }

  function getItems() {
    switch (true) {
      case Array.isArray(items) && items.length === 0:
        return emptyItem(t('wishList.items.emptyList'));
      case Array.isArray(items) && items.length > 0:
        return items.map(generateItem);
      case items === false:
        return emptyItem(t('wishList.items.noResultsFound'));
      default:
        return null;
    }
  }

  useEffect(() => {
    setItems(propItems);
  }, [propItems]);

  return (
    <>
      { !itemsLoader ? (
        <Grid>
          <ItemDivider text={t('shoppingCart.items')} icon="tag" />
          <Responsive as={Grid.Row} minWidth={Responsive.onlyTablet.minWidth}>
            <Grid.Column verticalAlign="top" tablet={3} computer={3} largeScreen={3} widescreen={3} />
            <Grid.Column verticalAlign="top" tablet={4} computer={4} largeScreen={4} widescreen={4}>
              <Header as="h4">{t('shoppingCart.itemHeaders.name')}</Header>
            </Grid.Column>
            <Grid.Column verticalAlign="top" tablet={2} computer={2} largeScreen={2} widescreen={2}>
              <Header as="h4">{t('shoppingCart.itemHeaders.quantity')}</Header>
            </Grid.Column>
            <Grid.Column verticalAlign="top" textAlign="right" tablet={3} computer={3} largeScreen={3} widescreen={3}>
              <Header as="h4">{t('shoppingCart.itemHeaders.unitPrice')}</Header>
            </Grid.Column>
            <Grid.Column verticalAlign="top" textAlign="center" tablet={3} computer={3} largeScreen={3} widescreen={3} />
            <Grid.Column verticalAlign="top" tablet={1} computer={1} largeScreen={1} widescreen={1} />
          </Responsive>
          <Grid.Row>
            <Grid.Column verticalAlign="middle" tablet={16} computer={16} largeScreen={16} widescreen={16}>
              {getItems()}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column verticalAlign="middle" textAlign="center" tablet={16} computer={16} largeScreen={16} widescreen={16}>
              <Loader inline="centered" active={itemsLoader} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </>
  );
}

ItemsWishList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
  })).isRequired,
  itemsLoader: PropTypes.bool.isRequired,
  removeItem: PropTypes.func.isRequired,
  sendItemToCart: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  wishListId: PropTypes.number.isRequired,
};

export default translate('common', { wait: true })(ItemsWishList);
