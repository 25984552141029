import securityService from '@compusoluciones-pdigitales/siclik-security';
import roles from '../../role-permissions';
import * as session from '../../common/sessions';

const {
  customerAdmin, sales, buyer, adminCs, logisticAgent,
} = roles;

const getRol = () => {
  const { access } = session.get();
  const adminCustomer = securityService.validate(access, customerAdmin);
  const shopperCustomer = securityService.validate(access, buyer);
  const sellerCustomer = securityService.validate(access, sales);
  const adminAgent = securityService.validate(access, adminCs);
  const logistic = securityService.validate(access, logisticAgent);

  if (adminCustomer) return 'Administrador';
  if (shopperCustomer) return 'Compras';
  if (sellerCustomer) return 'Ventas';
  if (adminAgent) return 'Administrador CS';
  if (logistic) return 'Agente de logistica';
  return '';
};

export default getRol;
