import React, { Component } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Button } from 'semantic-ui-react';
import { InputAdornment } from '@material-ui/core';
import { TextField } from '../../common/components/materials';

class AddStrategy extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.strategyId !== state.strategyId) {
      return {
        strategy: props.strategy,
        attendant: props.attendant,
        date: props.date,
        progress: props.progress,
        strategyId: props.strategyId,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      strategyId: props.strategyId,
      strategy: props.strategy,
      attendant: props.attendant,
      date: props.date,
      progress: props.progress,
      strategyError: '',
      attendantError: '',
      dateError: '',
      progressError: '',
    };
  }

  onKeyPress = e => {
    if (e.which === 13) this.handleClickSave();
  }

  handleChange = event => {
    const { tr: t } = this.props;
    const { value, id } = event.target;
    switch (id) {
      case 'strategy':
        if (value.length > 100) {
          this.setState(currentState => ({ [id]: value, [`${id}Error`]: `Error: ${t('business-plan.errors.maxCharLimitExceeded')} ${currentState[[`max${id}`]]} ${t('businessPlan.errors.chars')}` }));
          return;
        }
        this.setState({ [id]: value, [`${id}Error`]: '' });
        break;
      case 'attendant':
        if (value.length > 50) {
          this.setState(currentState => ({ [id]: value, [`${id}Error`]: `Error: ${t('business-plan.errors.maxCharLimitExceeded')} ${currentState[[`max${id}`]]} ${t('businessPlan.errors.chars')}` }));
          return;
        }
        if (value.length > 0 && !validator.isAlpha(value.replace(/ /g, ''), ['es-ES'])) {
          this.setState({ [id]: value, [`${id}Error`]: `Error: ${t('business-plan.errors.notAlpha')}` });
          return;
        }
        this.setState({ [id]: value, [`${id}Error`]: '' });
        break;
      case 'date':
        if (!moment(value).isValid()) {
          this.setState({ [id]: value, [`${id}Error`]: t('business-plan.errors.notDate') });
          return;
        }
        this.setState({ [id]: value, [`${id}Error`]: '' });
        break;
      case 'progress':
        if (value && value.length > 0) {
          if (!validator.isDecimal(value)) {
            this.setState({ [id]: value, [`${id}Error`]: t('business-plan.errors.notNumeric') });
            return;
          }
          if (value.length > 5) {
            this.setState(currentState => ({ [id]: value, [`${id}Error`]: `Error: ${t('business-plan.errors.maxCharLimitExceeded')} ${currentState[[`max${id}`]]} ${t('businessPlan.errors.chars')}` }));
            return;
          }
        }
        this.setState({ [id]: value, [`${id}Error`]: '' });
        break;
      default:
        this.setState({ [id]: value, [`${id}Error`]: '' });
        break;
    }
  }

  handleClickSave = () => {
    const { handleGetStrategyToAdd, handleUpdateStrategy, tr: t } = this.props;
    const {
      strategy, attendant, date, progress, strategyId,
    } = this.state;
    const payload = {
      strategy, responsable: attendant, date, progress,
    };
    let {
      strategyError, attendantError, dateError, progressError,
    } = this.state;
    if (strategy && attendant && date && progress) {
      if (!strategyId) {
        handleGetStrategyToAdd(payload);
      } else {
        handleUpdateStrategy({ ...payload, strategyId });
      }
    } else {
      if (!strategy) strategyError = t('business-plan.errors.empty');
      if (!attendant) attendantError = t('business-plan.errors.empty');
      if (!date) dateError = t('business-plan.errors.empty');
      if (!progress) progressError = t('business-plan.errors.empty');
      this.setState({
        strategyError, attendantError, dateError, progressError,
      });
    }
  }

  render() {
    const { tr, handleClickCancelStrategy } = this.props;
    const {
      strategy, attendant, date, progress, strategyError, attendantError, dateError, progressError,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column onKeyPress={this.onKeyPress} largeScreen={3} computer={4} tablet={3} mobile={7} style={{ marginRight: '1rem' }}>
            <TextField
              id="strategy"
              maxLength={100}
              value={strategy}
              label={tr('business-plan.strategy')}
              color="green"
              multiline
              error={strategyError}
              onChange={this.handleChange}
            />
          </Grid.Column>
          <Grid.Column onKeyPress={this.onKeyPress} largeScreen={3} computer={4} tablet={3} mobile={7} style={{ marginRight: '1rem' }}>
            <TextField
              id="attendant"
              type="text"
              value={attendant}
              maxLength={50}
              label={tr('business-plan.attendant')}
              color="green"
              multiline
              error={attendantError}
              onChange={this.handleChange}
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={8} style={{ textAlign: 'center', marginTop: '16px' }}>
            <TextField
              id="date"
              type="date"
              value={date}
              format="dd/mm/yyyy"
              color="green"
              error={dateError}
              onChange={this.handleChange}
            />
          </Grid.Column>
          <Grid.Column onKeyPress={this.onKeyPress} largeScreen={3} computer={3} tablet={3} mobile={6} style={{ textAlign: 'center', marginLeft: '2rem' }}>
            <TextField
              id="progress"
              type="number"
              maxLength={5}
              value={progress}
              label={tr('business-plan.progress')}
              color="green"
              error={progressError}
              InputProps={{
                startAdornment: <InputAdornment>%</InputAdornment>,
              }}
              onChange={this.handleChange}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={1} />
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={7}>
            <Button onClick={this.handleClickSave} color="blue">{tr('save')}</Button>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={7}>
            <Button onClick={handleClickCancelStrategy}>{tr('back')}</Button>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={1} />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AddStrategy);

AddStrategy.defaultProps = {
  tr: () => {},
  handleGetStrategyToAdd: null,
  handleUpdateStrategy: null,
  handleClickCancelStrategy: null,
  strategy: '',
  attendant: '',
  date: '',
  progress: '',
  strategyId: '',
};

AddStrategy.propTypes = {
  tr: PropTypes.func,
  handleGetStrategyToAdd: PropTypes.func,
  handleUpdateStrategy: PropTypes.func,
  handleClickCancelStrategy: PropTypes.func,
  strategy: PropTypes.string,
  attendant: PropTypes.string,
  date: PropTypes.string,
  progress: PropTypes.number,
  strategyId: PropTypes.number,
};
