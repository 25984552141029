import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const reset = {};

reset.requestNewPassword = body => (
  request({
    route: apiRoutes.requestNewPassword(),
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

reset.resetPassword = (token, password) => (
  request({
    route: apiRoutes.resetPassword(),
    method: 'PUT',
    body: {
      token,
      password,
    },
  })
);

reset.validateToken = token => (
  request({
    route: apiRoutes.validateTokenSession(token),
  })
);

export default reset;
