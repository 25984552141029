const USER_ERROR = 'user.errors';

const EMPTY_FIELD_MESSAGE = `${USER_ERROR}.empty`;
const INVALID_CUSTOMER_ID_MESSAGE = `${USER_ERROR}.notCustomerId`;
const INVALID_ACCESS_DESCRIPTION_MESSAGE = `${USER_ERROR}.notDescription`;

export function isCustomerId(input = '') {
  if (input === '') {
    return EMPTY_FIELD_MESSAGE;
  }
  const regex = /^[A-Za-z]{1}[A-Za-z\d-]+$/;
  return regex.test(input) ? null : INVALID_CUSTOMER_ID_MESSAGE;
}

export function form({ customerId }) {
  const customerIdError = isCustomerId(customerId);
  return { customerIdError };
}

export function isDescription(input = '') {
  if (input === '') {
    return EMPTY_FIELD_MESSAGE;
  }
  const regex = /^[A-Za-z\s]+$/;
  return regex.test(input) ? null : INVALID_ACCESS_DESCRIPTION_MESSAGE;
}

export function formAccess({ description }) {
  const descriptionError = isDescription(description);
  return { descriptionError };
}
