import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { List, Image, Card } from 'semantic-ui-react';

const translatePath = 'productConfig.menus.editContent';
function ContentBySource(props) {
  const { t, item } = props;

  const getImages = (images, size) => (
    <Card.Group doubling>
      {images.map(image => (
        <Card key={image.id}>
          <Card.Content textAlign="center">
            <Image size={size} src={image.low} />
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  );

  return (
    <List>
      <List.Item>
        <List.Content>
          <List.Header as="a">
            {t(`${translatePath}.item.title`)}
            {' '}
          </List.Header>
          <List.Description as="a">
            {item.title}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">
            {t(`${translatePath}.item.category`)}
            {' '}
          </List.Header>
          <List.Description as="a">
            {item.category}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">
            {t(`${translatePath}.item.family`)}
            {' '}
          </List.Header>
          <List.Description as="a">
            {item.family}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">{t(`${translatePath}.item.images`)}</List.Header>
          <List.Description as="a">
            {
              item.images.length > 0
                ? getImages(item.images, 'medium')
                : <p>{t(`${translatePath}.images.noImages`)}</p>
            }
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">{t(`${translatePath}.item.logos`)}</List.Header>
          <List.Description as="a">
            {
              item.featuredLogos.length > 0
                ? getImages(item.featuredLogos, 'small')
                : <p>{t(`${translatePath}.images.noImages`)}</p>
            }
          </List.Description>
        </List.Content>
      </List.Item>
    </List>
  );
}

ContentBySource.propTypes = {
  item: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    family: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMain: PropTypes.bool.isRequired,
      thumbnail: PropTypes.string,
      low: PropTypes.string,
      medium: PropTypes.string,
      big: PropTypes.string,
    })),
    featuredLogos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      isMain: PropTypes.bool.isRequired,
      thumbnail: PropTypes.string,
      low: PropTypes.string,
      medium: PropTypes.string,
      big: PropTypes.string,
    })),
  }).isRequired,
};

export default translate('common', { wait: true })(ContentBySource);
