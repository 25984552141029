import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Icon } from 'semantic-ui-react';
import SiclikTable from '../../../common/components/table';

const HEADER = (handleUpdate, handleDelete) => ([
  { key: 'id', isKey: true },
  { key: 'categoryId', isKey: true },
  { key: 'client', name: 'Clientes 80/20' },
  { key: 'couldSell', name: '¿Qué le puedo vender?' },
  { key: 'sell', name: '¿Qué le vendo?' },
  {
    key: 'actions',
    name: '',
    format: (cell, row) => (
      <Grid columns="equal">
        <Grid.Row>
          <Grid.Column className="no-padding-x" verticalAlign="middle" textAlign="center">
            <Icon color="blue" link name="pencil alternate" onClick={() => handleUpdate(row)} />
          </Grid.Column>
          <Grid.Column className="no-padding-x" verticalAlign="middle" textAlign="center">
            <Icon color="blue" link name="trash alternate" onClick={() => handleDelete(row)} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ),
  },
]);

function Pareto(props) {
  const { data, handleUpdate, handleDelete } = props;
  const headers = HEADER(handleUpdate, handleDelete);
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
          textAlign="center"
          verticalAlign="middle"
        >
          <SiclikTable headers={headers} data={data} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Pareto.propTypes = {
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
};

Pareto.defaultProps = {
  handleUpdate: () => null,
  handleDelete: () => null,
  data: [],
};

export default translate('common', { wait: true })(Pareto);
