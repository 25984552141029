import React, { Component } from 'react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Confirm, GridRow,
} from 'semantic-ui-react';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import '../style.css';
import familyAttributes from './services';
import Toast from '../common/components/toast';
import FamilyData from './components/add';
import * as session from '../common/sessions';

class FamilyAttributes extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);

    if (!hasAccess) props.history.push('/perfil');

    this.state = {
      family: '',
      hierarchy: '',
      messageTable: '',
      filter: '',
      arrayFamiliesFormat: [],
      attribute: '',
      attributeId: '',
      attributeError: '',
      arrayFamilies: [],
      arrayHierarchy: [
        { key: 1, text: '1', value: 1 },
        { key: 2, text: '2', value: 2 },
        { key: 3, text: '3', value: 3 },
        { key: 4, text: '4', value: 4 },
      ],
      headersFamilyArray: [
        { key: 'id', isKey: true },
        { key: 'family', name: 'Familia' },
        { key: 'attribute', name: 'Atributo' },
        { key: 'hierarchy', name: 'Jerarquía' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <GridRow>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleDeleteAttribute(row)} />
            </GridRow>
          ),
        },
      ],
      shouldClean: false,
      update: false,
      save: true,
      confirmDelete: false,
      open: false,
      arrayFamilyFiltered: [],
    };
  }

  componentDidMount() {
    this.handleGetFamilies();
    this.handleGetFamilyAttributes();
  }

  handleAddition = (e, { value }) => {
    this.setState(prevState => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };

  handleCancel = () => this.setState({ confirmDelete: false });

  handleDeleteAttribute = row => {
    this.setState({ confirmDelete: true, attributeId: row.id });
  }

  handleClickCancel = () => this.setState({
    update: false,
    save: true,
  });

  handleFilterFamily = event => {
    const { arrayFamilies } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, arrayFamilies);
  }

  handleFilter = (filter, arrayFamily) => {
    const arrayFamilyCopy = JSON.parse(JSON.stringify(arrayFamily));
    let foundData = [];
    if (!filter) {
      foundData = arrayFamily;
    } else {
      foundData = arrayFamilyCopy.filter(filterInfo => (filterInfo.family && filterInfo.family.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.attribute && filterInfo.attribute.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.hierarchy && filterInfo.hierarchy.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    }
    this.setState({ filter, arrayFamilyFiltered: foundData });
  }

  handleGetFamilyAttributes = async () => {
    const familiesArray = await familyAttributes.getAttributes();
    this.setState({ arrayFamilies: familiesArray.familiesAttributes, arrayFamilyFiltered: familiesArray.familiesAttributes });
  }

  handleDeleteConfirmed = () => {
    const { t } = this.props;
    const { attributeId, filter, arrayFamilies } = this.state;
    familyAttributes.deleteFamilyAttribute(attributeId).then(() => {
      Toast(t('attributes.eliminationConfirmation'), 'success');
      const familyToDeleted = arrayFamilies.findIndex(attribute => attribute.id === attributeId);
      arrayFamilies.splice(familyToDeleted, 1);
      this.handleFilter(filter, arrayFamilies);
      this.setState({ arrayFamilies, confirmDelete: false });
    }).catch(error => {
      Toast(t(`error-codes.${error.code}`, 'error'));
    });
  }

  handleClickUpdate = row => this.setState({
    attributeId: row.id,
    family: row.family,
    attribute: row.attribute,
    hierarchy: Number(row.hierarchy),
    open: true,
    save: false,
    update: true,
  });

  handleUpdateKeywords = () => {
    const { t } = this.props;
    const {
      attributeId, family, attribute, hierarchy, arrayFamilies, filter,
    } = this.state;
    const familyKeywordsData = {
      id: attributeId, family, attribute, hierarchy,
    };
    familyAttributes.updateFamilyKeywords(familyKeywordsData)
      .then(() => {
        Toast(t('finaluser.successAdd'), 'success');
        const attributeToUpdate = arrayFamilies.findIndex(keyword => keyword.id === attributeId);
        arrayFamilies[attributeToUpdate].family = familyKeywordsData.family;
        arrayFamilies[attributeToUpdate].attribute = familyKeywordsData.attribute;
        arrayFamilies[attributeToUpdate].hierarchy = familyKeywordsData.hierarchy;
        this.setState({
          family: '',
          attribute: '',
          hierarchy: '',
          attributeId: '',
          open: false,
        });
        this.handleFilter(filter, arrayFamilies);
      }).catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
  }

  handleGetFamilies = () => {
    const { t } = this.props;
    familyAttributes.getFamilies()
      .then(response => {
        const familiesFormat = response.families.map(opc => {
          const options = {
            key: opc.family,
            text: opc.family,
            value: opc.family,
          };
          return options;
        });
        this.setState({ arrayFamilies: response.families, arrayFamiliesFormat: familiesFormat });
      })
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005) this.setState({ messageTable: t('tableEmpty') });
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleClickSave = async () => {
    const { t } = this.props;
    const {
      attribute, family, hierarchy,
    } = this.state;
    const body = { attribute, family, hierarchy };
    try {
      await familyAttributes.addFamilyAttributes(body);
      this.setState({
        arrayFamilies: '',
        family: '',
        hierarchy: '',
        open: false,
      });
      const familiesArray = await familyAttributes.getAttributes();
      this.setState({ arrayFamilies: familiesArray.familiesAttributes, arrayFamilyFiltered: familiesArray.familiesAttributes });
      Toast(t('attributes.confirmAttributeAdd'), 'success');
    } catch (error) {
      let messageError = '';
      if (!error.code) {
        messageError = t('error-codes.default');
      } else {
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
  }

  handleGetFamilyValue = (e, { value }) => this.setState({ family: value })

  handleGetHierarchyValue = (e, { value }) => this.setState({ hierarchy: value })

  handleAttributeChange = event => {
    const { t } = this.props;
    const maxLength = 500;
    const { value: attribute } = event.target;
    if (attribute && attribute.length > 0) {
      if (attribute.length > maxLength) {
        this.setState({ attribute, attributeError: t('attributes.attributeLimit') });
        return;
      }
    }
    this.setState({ attribute, attributeError: '' });
  }

  render() {
    const { t } = this.props;
    const {
      headersFamilyArray, messageTable, shouldClean, confirmDelete, filter, filteError,
      open, arrayFamiliesFormat, arrayFamilyFiltered, arrayHierarchy, attribute, attributeError, save, update,
      family, hierarchy,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Grid.Row columns={3}>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14} />
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={8}>
            <Header color="blue" textAlign="left" as="h2">{t('attributes.addAttributes')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={14}>
            <Header className="userMessage" as="h4">{t('attributes.welcome')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('attributes.searchFamily')}
              onChange={this.handleFilterFamily}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
            <Button onClick={() => this.setState({ open: true, save: true, update: false })} content={t('add')} labelPosition="left" icon="add user" />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ overflowX: 'scroll' }}>
            <SiclikTable
              selectable
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              data={arrayFamilyFiltered}
              headers={headersFamilyArray}
              pageSize={5}
              noDataMessage={messageTable}
              singleSelection
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={confirmDelete}
            header={t('attributes.confirmDeleteAttribute')}
            content={attribute}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleDeleteConfirmed}
          />
        </Grid.Row>
        <Grid.Row>
          <FamilyData
            open={open}
            arrayFamiliesFormat={arrayFamiliesFormat}
            arrayHierarchy={arrayHierarchy}
            handleGetFamilyValue={this.handleGetFamilyValue}
            handleGetHierarchyValue={this.handleGetHierarchyValue}
            handleAddition={this.handleAddition}
            handleClickSave={this.handleClickSave}
            handleUpdateKeywords={this.handleUpdateKeywords}
            handleAttributeChange={this.handleAttributeChange}
            attribute={attribute}
            attributeError={attributeError}
            handleClickCancel={() => this.setState({ open: false })}
            save={save}
            update={update}
            family={family}
            hierarchy={hierarchy}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(FamilyAttributes);
