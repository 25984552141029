import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Segment,
  Label,
  Container,
  Image,
  Responsive,
} from 'semantic-ui-react';

function isPrimeNumber(index) {
  let isPrime = true;
  const number = index + 1;
  if (number === 1) {
    isPrime = false;
  } else {
    for (let i = 2; i < number; i++) {
      if (number % i === 0) {
        isPrime = false;
        break;
      }
    }
  }
  return isPrime;
}

function ReasonsToBuy({ reasonsToBuy, t }) {
  function WebContentView() {
    return (
      <Grid.Row style={{ paddingTop: '2rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Segment padded="very">
            <Label attached="top">
              <Header as="h3" textAlign="center" color="blue">
                {t('productDetails.reasonsToBuy')}
              </Header>
            </Label>
            {reasonsToBuy.map(reason => (
              <Container key={reason.id} textAlign="center" style={{ marginBottom: 40 }}>
                <Header as="h3" content={reason.title} color="black" />
                <p>{reason.description}</p>
                <Image src={reason.image} size="huge" centered />
              </Container>
            ))}
          </Segment>
        </Grid.Column>
      </Grid.Row>
    );
  }

  function MobileView() {
    return (
      <Grid.Row style={{ paddingTop: '2rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
        <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
          {reasonsToBuy.map((reason, index) => (
            <Container key={reason.id} textAlign="center" style={{ marginBottom: 40 }}>
              <Image src={reason.image} size="small" floated={isPrimeNumber(index) ? 'left' : 'right'} />
              <Header as="h5" content={reason.title} color="black" />
              <p>{reason.description}</p>
            </Container>
          ))}
        </Grid.Column>
      </Grid.Row>
    );
  }

  return (
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <WebContentView />
        </Responsive>
        <Responsive minWidth={Responsive.onlyMobile.minWidth} maxWidth={Responsive.onlyMobile.maxWidth}>
          <MobileView />
        </Responsive>
      </Grid.Column>
    </Grid.Row>
  );
}

ReasonsToBuy.propTypes = {
  reasonsToBuy: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  })).isRequired,
};

export default translate('common', { wait: true })(ReasonsToBuy);
