import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const services = {};

services.getAccesTokenPowerBI = body => (
  request({
    route: apiRoutes.getAccesTokenPowerBI(),
    method: 'POST',
    body,
  })
);

services.getAgents = customerId => (
  request({
    route: apiRoutes.getPublicAgents(customerId),
    method: 'GET',
  })
);

services.getSegmentByCustomer = customerId => (
  request({
    route: apiRoutes.getSegmentByCustomer(customerId),
    method: 'GET',
  })
);

export default services;
