const bucket = `${process.env.REACT_APP_URL_AWS}`;
const assetUrl = `${bucket}/imagenes/assets/comercio/`;

const brands = [
  {
    image: `${assetUrl}apple.png`,
    imageGray: `${assetUrl}apple-gray.png`,
    redirect: 'apple',
  },
  {
    image: `${assetUrl}aruba.png`,
    imageGray: `${assetUrl}aruba-gray.png`,
    redirect: 'aruba',
  },
  {
    image: `${assetUrl}asus.png`,
    imageGray: `${assetUrl}asus-gray.png`,
    redirect: 'asus',
  },
  {
    image: `${assetUrl}blancco.png`,
    imageGray: `${assetUrl}blancco-gray.png`,
    redirect: 'blancco',
  },
  {
    image: `${assetUrl}hpe.png`,
    imageGray: `${assetUrl}hpe-gray.png`,
    redirect: 'hpe',
  },
  {
    image: `${assetUrl}hp.png`,
    imageGray: `${assetUrl}hp-gray.png`,
    redirect: 'hp',
  },
  {
    image: `${assetUrl}lenovo.png`,
    imageGray: `${assetUrl}lenovo-gray.png`,
    redirect: 'lenovo',
  },
  {
    image: `${assetUrl}logitech.png`,
    imageGray: `${assetUrl}logitech-gray.png`,
    redirect: 'logitech',
  },
  {
    image: `${assetUrl}perfectChoice.png`,
    imageGray: `${assetUrl}perfectChoice-gray.png`,
    redirect: 'perfectchoice',
  },
  {
    image: `${assetUrl}redHat.png`,
    imageGray: `${assetUrl}redHat-gray.png`,
    redirect: 'redHat',
  },
  {
    image: `${assetUrl}safetica.png`,
    imageGray: `${assetUrl}safetica-gray.png`,
    redirect: 'safetica',
  },
  {
    image: `${assetUrl}screenbeam.png`,
    imageGray: `${assetUrl}screenbeam-gray.png`,
    redirect: 'screenbeam',
  },
  {
    image: `${assetUrl}techZone.png`,
    imageGray: `${assetUrl}techZone-gray.png`,
    redirect: 'techzone',
  },
  {
    image: `${assetUrl}trellix.png`,
    imageGray: `${assetUrl}trellix-gray.png`,
    redirect: 'trellix',
  },
  {
    image: `${assetUrl}veeam.png`,
    imageGray: `${assetUrl}veeam-gray.png`,
    redirect: 'veeam',
  },
  {
    image: `${assetUrl}vmware.png`,
    imageGray: `${assetUrl}vmware-gray.png`,
    redirect: 'vmware',
  },

];

export default brands;
