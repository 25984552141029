import React from 'react';
import { Grid } from 'semantic-ui-react';

const borderMultiLine = {
  borderBottom: '3px solid',
  height: 'auto',
};

export default function Multiline() {
  return (
    <Grid.Row className="no-padding-y" verticalAlign="middle">
      <Grid.Column mobile={4} tablet={4} computer={4} largeScreen={4} widescreen={4} className="no-padding-x yellowText" style={borderMultiLine} />
      <Grid.Column mobile={4} tablet={4} computer={4} largeScreen={4} widescreen={4} className="no-padding-x blueText" style={borderMultiLine} />
      <Grid.Column mobile={4} tablet={4} computer={4} largeScreen={4} widescreen={4} className="no-padding-x greenText" style={borderMultiLine} />
      <Grid.Column mobile={4} tablet={4} computer={4} largeScreen={4} widescreen={4} className="no-padding-x redText" style={borderMultiLine} />
    </Grid.Row>
  );
}
