import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Grid, Button, Header, Icon, Segment,
} from 'semantic-ui-react';
import * as session from '../../common/sessions';
import services from '../services';
import Toast from '../../common/components/toast';
import { currencyFormat } from '../../common/utils';
import '../styless.css';

class Coupons extends Component {
  static getDerivedStateFromProps(props, state) {
    const { currentPaymentMethod } = props;
    if (currentPaymentMethod !== state.currentPaymentMethod) {
      return {
        currentPaymentMethod,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      couponsList: [],
      selectedCoupons: [],
      currentPaymentMethod: props.currentPaymentMethod,
    };
  }

  componentDidMount() {
    this.getCoupons();
  }

  getCoupons = () => {
    const { customerId } = session.get();
    const { t } = this.props;
    services.getCouponsByCustomerId(customerId).then(response => {
      this.setState({
        couponsList: response.coupons.map(currentCoupon => {
          const coupon = { ...currentCoupon };
          let meta = '';
          switch (coupon.idType) {
            case 1: meta = `- ${currencyFormat(coupon.quantity, coupon.currency === 1 ? 'MXN' : 'USD')}`; break;
            case 2: meta = `${coupon.quantity}%OFF`; break;
            case 3: meta = coupon.creditDays; break;
            case 4: meta = `- ${currencyFormat(coupon.quantity, coupon.currency === 1 ? 'MXN' : 'USD')}`; break;
            default: meta = ''; break;
          }
          return ({
            ...coupon,
            key: coupon.id,
            text: coupon.coupon,
            idType: coupon.idType,
            value: { couponId: coupon.coupon, couponType: coupon.idType },
            meta,
          });
        }),
      });
    })
      .catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
  }

  handleChangeCoupons = (e, { value }) => {
    this.setState({ selectedCoupons: value });
  }

  handleApplyCoupons = () => {
    const { selectedCoupons } = this.state;
    const { t, getCoupons } = this.props;
    let message = '';
    if (selectedCoupons.length === 0) {
      message = t('shoppingCart.selectCouponWarning');
      Toast(message, 'warning');
    } else {
      getCoupons(selectedCoupons);
    }
  }

  handleClickCoupon = coupon => {
    const { t, getCoupons } = this.props;
    let message = '';
    if (coupon.length === 0) {
      message = t('shoppingCart.selectCouponWarning');
      Toast(message, 'warning');
    } else {
      getCoupons([{ couponId: coupon.coupon, couponType: coupon.idType }]);
    }
  }

  render() {
    const {
      currentPaymentMethod,
    } = this.state;
    let { couponsList } = this.state;
    if (currentPaymentMethod !== 1) couponsList = couponsList.filter(coupon => coupon.idType !== 3);

    const couponsCardsWeb = couponsList.map(coupon => (
      <Segment>
        <Grid padded>
          <Grid.Row className="no-padding">
            <Grid.Column textAlign="left" className="no-padding-left" largeScreen={4} computer={4}>
              <Icon name="tag" className="display-inline" />
              <Header as="h5" className="no-margin display-inline" color="gray">{coupon.coupon}</Header>
            </Grid.Column>
            <Grid.Column textAlign="left" largeScreen={3} computer={3}>
              <Header as="h5" color="green">{coupon.meta}</Header>
            </Grid.Column>
            <Grid.Column className="no-padding" textAlign="left" largeScreen={6} computer={6}>
              <Header as="h5" color="blue">{coupon.description}</Header>
            </Grid.Column>
            <Grid.Column className="no-padding-right" largeScreen={3} computer={3}>
              <Button color="blue" fluid onClick={() => this.handleClickCoupon(coupon)}>Aplicar</Button>
            </Grid.Column>
            <Grid.Column className="no-padding" textAlign="left" width={16}>
              <p>
                {`Vigencia del
                ${moment(coupon.startValidity).format('DD/MM/YYYY')} al
                ${moment(coupon.endValidity).format('DD/MM/YYYY')}`}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    ));

    const couponsCardsMobile = couponsList.map(coupon => (
      <Segment>
        <Grid padded>
          <Grid.Row className="no-padding">
            <Grid.Column textAlign="left" className="no-padding-left" tablet={8} mobile={16}>
              <Icon name="tag" className="display-inline" />
              <Header as="h5" className="no-margin-top display-inline margin-text-coupons" color="gray">{coupon.coupon}</Header>
              <Header as="h5" className="no-margin-top margin-text-coupons" color="green">{coupon.meta}</Header>
              <Header as="h5" className="no-margin-top margin-text-coupons" color="blue">{coupon.description}</Header>
            </Grid.Column>
            <Grid.Column className="no-padding" tablet={8} mobile={16}>
              <Button color="blue" fluid onClick={() => this.handleClickCoupon(coupon)}>Aplicar</Button>
            </Grid.Column>
            <Grid.Column className="no-padding" textAlign="left" width={16}>
              <p>
                {`Vigencia del
                ${moment(coupon.startValidity).format('DD/MM/YYYY')} al
                ${moment(coupon.endValidity).format('DD/MM/YYYY')}`}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    ));

    return (
      <Grid padded>
        <Grid.Row centered className="no-padding">
          <Grid.Column width={16} className="no-padding" only="computer">
            {couponsCardsWeb}
          </Grid.Column>
          <Grid.Column width={16} className="no-padding" only="tablet mobile">
            {couponsCardsMobile}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Coupons.defaultProps = {
  getCoupons: () => {},
};

Coupons.propTypes = {
  currentPaymentMethod: PropTypes.number.isRequired,
  getCoupons: PropTypes.func,
};

export default translate('common', { wait: true })(Coupons);
