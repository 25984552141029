export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};

export const sheetHeaders = {
  customerId: { header: 'Cliente', width: 12 },
  name: { header: 'Nombre corto', width: 50 },
  tradingName: { header: 'Nombre comercial', width: 50 },
  totalAmount: { header: 'Total en pesos mexicanos', width: 50, numFmt: '$#,##0.0000' },
};

export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
