import cookie from 'js-cookie';
import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const billingService = {};

billingService.getBillingData = (customer, movId) => (
  request({
    route: apiRoutes.getBillingByMovId(customer, movId),
    method: 'GET',
  })
);

billingService.getRequestFile = body => (
  request({
    route: apiRoutes.getCfdiAttachBuffer(),
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookie.get('token')}`,
    },
  })
);

export default billingService;
