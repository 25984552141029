import React, { Component } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import {
  Grid, Button, Header, Modal, Confirm, Dimmer, Loader, Responsive,
} from 'semantic-ui-react';
import SiclikTable from '../common/components/table';
import Toast from '../common/components/toast';
import FodaService from './services';
import AddUpdate from './componentes/add-update';
import UploadModal from './componentes/add-upload';

const currentDate = moment();

class FodaForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      selectedItemId: '',
      value: '',
      error: '',
      headers1: [
        { key: 'id', isKey: true },
        { key: 'categoryId', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      headers2: [
        { key: 'id', isKey: true },
        { key: 'categoryId', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      headers3: [
        { key: 'id', isKey: true },
        { key: 'categoryId', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      headers4: [
        { key: 'id', isKey: true },
        { key: 'categoryId', isKey: true },
        { key: 'description', name: '' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid.Row>
              <Button onClick={() => this.handleUpdate(row)} color="blue" icon="pencil alternate" />
              <Button onClick={() => this.handleDelete(row)} color="blue" icon="trash alternate" />
            </Grid.Row>
          ),
        },
      ],
      update: false,
      dataF: [],
      dataO: [],
      dataD: [],
      dataA: [],
      dataFoda: [],
      elementId: '',
      openDelete: false,
      errorCategory: '',
      year: props.year,
      loading: false,
      openUploadModal: false,
      fodaArray: [],
    };
  }

  componentDidMount() {
    const { year } = this.state;
    this.handleGetDataFoda(year);
    this.handleGetCategories();
  }

  componentDidUpdate(prevProps) {
    const { customerId, year } = this.props;
    if ((customerId !== prevProps.customerId) || (year !== prevProps.year)) {
      this.handleGetDataFoda();
    }
  }

  onKeyPressAdd = e => {
    if (e.which === 13) this.handleClickAdd();
  }

  onKeyPressUpdate = e => {
    if (e.which === 13) this.handleClickUpdate();
  }

  handleGetDataFoda = async () => {
    const { t, customerId, year } = this.props;
    this.setState({ loading: true });
    const foda = await FodaService.getFodaByCustomerId(customerId, year, 1).catch(error => {
      let messageError = '';
      if (!error) {
        messageError = t('error-codes.default');
      } else if (Number(error.code) === 1005 || Number(error.code) === 1000) {
        // this.setState({ messageTable: t('foda.emptyTable') });
      } else { messageError = t(`error-codes.${error.code}`); }
      if (messageError) Toast(messageError, 'error');
      this.setState({ loading: false });
    });
    if (foda && foda.businessPlanList) {
      this.setState({ dataFoda: foda.businessPlanList });
      this.handleFilterByCategory(foda.businessPlanList);
    } else {
      this.setState({
        dataFoda: [],
        dataF: [],
        dataO: [],
        dataD: [],
        dataA: [],
        value: '',
        error: '',
        errorCategory: '',
        selectedItemId: '',
      });
    }
  }

  handleFilterByCategory = arrayFoda => {
    const dataF = arrayFoda.filter(data => data.categoryId === 1);
    const dataO = arrayFoda.filter(data => data.categoryId === 2);
    const dataD = arrayFoda.filter(data => data.categoryId === 3);
    const dataA = arrayFoda.filter(data => data.categoryId === 4);
    this.setState({
      dataF,
      dataO,
      dataD,
      dataA,
      selectedItemId: '',
      elementId: '',
      value: '',
      error: '',
      errorCategory: '',
      update: false,
      openDelete: false,
      loading: false,
    });
  }

  handleChange = event => {
    const { t } = this.props;
    const maxLength = 280;
    const { value } = event.target;
    if (value.length > maxLength) {
      this.setState({ value, error: t('foda.error.toLong') });
    } else if (value.length > 0 && !validator.isAlphanumeric(value, ['es-ES'], { ignore: ' .,:;%$+-*/=' })) {
      this.setState({ value, error: t('foda.error.notSymbol') });
    } else {
      this.setState({ value, error: '' });
    }
  };

  onItemClick = ({ target }) => this.setState({ selectedItemId: target.value, errorCategory: '' });

  handleUpdate = row => {
    this.setState({
      update: true,
      value: row.description,
      selectedItemId: row.categoryId,
      elementId: row.id,
      error: '',
      errorCategory: '',
    });
  }

  handleDelete = row => {
    this.setState({
      openDelete: true,
      value: row.description,
      elementId: row.id,
      selectedItemId: row.categoryId,
      error: '',
      errorCategory: '',
    });
  }

  handleClickAdd = async () => {
    const { t, year, customerId } = this.props;
    const { selectedItemId, value } = this.state;
    let { error, errorCategory } = this.state;
    const fodaItem = {
      customerId,
      categoryId: selectedItemId,
      description: value,
      year,
    };
    if (!value || !selectedItemId || !year || error || errorCategory) {
      if (!value) error = t('foda.error.empty');
      if (!selectedItemId) errorCategory = t('foda.error.empty');
      if (error || errorCategory) Toast(t('foda.error.dataError'), 'warning');
      this.setState({ error, errorCategory });
    } else {
      const added = await FodaService.addFodaField(fodaItem)
        .catch(e => {
          let messageError = '';
          if (!e.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${e.code}`);
          Toast(messageError, 'error');
        });
      if (added) this.handleFodaFieldAdded({ id: added.id, ...fodaItem });
    }
  }

  handleClickUpdate = async () => {
    const { t } = this.props;
    const {
      value, elementId, error, errorCategory,
    } = this.state;
    if (value && !error && !errorCategory) {
      const fodaItem = {
        itemId: elementId,
        description: value,
      };
      const updated = await FodaService.updateFodaField(fodaItem)
        .catch(e => {
          let messageError = '';
          if (!e.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${e.code}`);
          Toast(messageError, 'error');
        });
      if (updated && updated.changedRows) this.handleFodaFieldUpdated(fodaItem);
    } else {
      Toast(t('foda.error.dataError'), 'warning');
    }
  }

  handleClickDelete = async () => {
    const { t } = this.props;
    const { elementId } = this.state;
    const deleted = await FodaService.disableFodaField(elementId)
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
    if (deleted && deleted.status) this.handleFodaFieldDeleted(elementId);
  }

  handleFodaFieldAdded = async foda => {
    const { dataFoda } = this.state;
    dataFoda.push({ id: foda.id, categoryId: foda.categoryId, description: foda.description });
    this.setState({ dataFoda });
    this.handleFilterByCategory(dataFoda);
  }

  handleFodaFieldUpdated = fodaField => {
    const { dataFoda } = this.state;
    const elementToUpdate = dataFoda.findIndex(element => element.id === fodaField.itemId);
    dataFoda[elementToUpdate].description = fodaField.description;
    this.setState(dataFoda);
    this.handleFilterByCategory(dataFoda);
  }

  handleFodaFieldDeleted = elementId => {
    const { dataFoda } = this.state;
    const elementToDelete = dataFoda.findIndex(element => element.id === elementId);
    dataFoda.splice(elementToDelete, 1);
    this.setState(dataFoda);
    this.handleFilterByCategory(dataFoda);
  }

  handleGetCategories = async () => {
    let categoriesFormated = [];
    const typeForm = 'FODA';
    const arrayCategories = await FodaService.getCategories(typeForm);
    if (arrayCategories && arrayCategories.categories) {
      categoriesFormated = arrayCategories.categories.filter(category => category.id !== 9)
        .map(item => ({ ...item, label: item.category, value: item.id }));
    }
    this.setState({ items: categoriesFormated });
    return categoriesFormated;
  }

  handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value });
    const sheets = [];
    if (name === 'file') {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        workbook.SheetNames.forEach(sheetName => {
          if (sheetName === 'foda') {
            const xlRowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            sheets.push({ data: xlRowObject });
          }
        });
        this.setState({ fodaArray: sheets[0].data });
      };
    }
  }

  handleAddFileData = async arrayFodaData => {
    const { t, year, customerId } = this.props;
    const dataToAdd = arrayFodaData.map(item => {
      const fodaItem = {
        customerId,
        categoryId: item.idCategoria,
        description: item.Descripcion,
        year,
      };
      return fodaItem;
    });
    await Promise.all(dataToAdd.map(item => FodaService.addFodaField(item).catch(e => {
      let messageError = '';
      if (!e.code) {
        messageError = t('error-codes.default');
      } else {
        messageError = t(`error-codes.${e.code}`);
      }
      Toast(messageError, 'error');
    })));
    this.handleGetDataFoda(year);
    this.setState({ openUploadModal: false });
  }

  render() {
    const { t } = this.props;
    const {
      headers1, headers2, headers3, headers4, update, dataF, dataO, dataD, dataA, items, value, error,
      selectedItemId, openDelete, errorCategory, loading, openUploadModal, disabledButton, fodaArray,
    } = this.state;
    let category = '';
    switch (selectedItemId) {
      case 1: category = 'fortaleza'; break;
      case 2: category = 'oportunidad'; break;
      case 3: category = 'debilidad'; break;
      case 4: category = 'amenaza'; break;
      default: category = ''; break;
    }
    return (
      <Grid container>
        <Grid.Row />
        <Grid.Row columns={4}>
          <Grid.Column largeScreen={13} computer={13} tablet={13} mobile={10}>
            <Header as="h3" color="blue">{t('foda.foda')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={6}>
            <Button
              onClick={() => this.setState({ openUploadModal: true })}
              content={t('agents.uploadFile')}
              labelPosition="center"
              icon="upload"
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        {loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          )
          : (
            <Grid.Row columns={2}>
              <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={12} />
            </Grid.Row>
          )}
        { !loading && !update
          ? (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={14} computer={14} tablet={13} mobile={16}>
                <AddUpdate
                  items={items}
                  value={value}
                  error={error}
                  errorCategory={errorCategory}
                  selectedItemId={selectedItemId}
                  onItemClick={this.onItemClick}
                  handleChange={this.handleChange}
                  onKeyPress={this.onKeyPressAdd}
                />
              </Grid.Column>
              <Grid.Column largeScreen={2} computer={2} tablet={3} mobile={8}>
                <Button fluid onClick={this.handleClickAdd}>{t('save')}</Button>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {!loading
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16}>
                <Header size="small" color="blue">{t('foda.strengths')}</Header>
                <SiclikTable headers={headers1} data={dataF} />
              </Grid.Column>
              <Responsive as={Grid.Column} maxWidth={768}>
                <Grid.Column mobile={16} />
              </Responsive>
              <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16}>
                <Header size="small" color="blue">{t('foda.weaknesses')}</Header>
                <SiclikTable headers={headers3} data={dataD} />
              </Grid.Column>
            </Grid.Row>
          ) : null}
        {!loading
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16}>
                <Header size="small" color="blue">{t('foda.oportunities')}</Header>
                <SiclikTable headers={headers2} data={dataO} />
              </Grid.Column>
              <Responsive as={Grid.Column} maxWidth={768}>
                <Grid.Column mobile={16} />
              </Responsive>
              <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16}>
                <Header size="small" color="blue">{t('foda.threats')}</Header>
                <SiclikTable headers={headers4} data={dataA} />
              </Grid.Column>
            </Grid.Row>
          ) : null}
        <Grid.Row>
          <Modal open={update}>
            <Modal.Content>
              <AddUpdate
                items={items}
                value={value}
                error={error}
                errorCategory={errorCategory}
                selectedItemId={selectedItemId}
                onItemClick={this.onItemClick}
                handleChange={this.handleChange}
                onKeyPress={this.onKeyPressUpdate}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleClickUpdate} color="blue">{t('save')}</Button>
              <Button
                onClick={() => this.setState({
                  update: false,
                  value: '',
                  selectedItemId: '',
                  error: '',
                  errorCategory: '',
                })}
              >
                {t('back')}
              </Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openDelete}
            header={`${t('foda.delete')} ${category}`}
            content={value}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({
              openDelete: false, value: '', selectedItemId: '', elementId: '',
            })}
            onConfirm={this.handleClickDelete}
          />
        </Grid.Row>
        <Grid.Row>
          <UploadModal
            openUploadModal={openUploadModal}
            handleClickCancel={() => this.setState({ openUploadModal: false })}
            handleInputChange={this.handleInputChange}
            handleClickSave={() => this.handleAddFileData(fodaArray)}
            disabledButton={disabledButton}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(FodaForm);

FodaForm.defaultProps = {
  year: currentDate.year(),
  customerId: 'G000000',
  t: null,
};

FodaForm.propTypes = {
  year: PropTypes.number,
  customerId: PropTypes.string,
  t: PropTypes.func,
};
