import React from 'react';
import PropTypes from 'prop-types';
import {
  ThemeProvider,
  makeStyles,
  createTheme,
} from '@material-ui/core/styles';
import MaterialLinearProgress from '@material-ui/core/LinearProgress';
import getColors from '../constants';

const COLORS = getColors();

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  linearProgress: props => {
    const style = {
      margin: theme.spacing(0),
      ...props,
    };
    Object.keys(style).forEach(key => (
      (typeof style[key] === 'undefined' || style[key] === null) && delete style[key]
    ));
    return style;
  },
}));

// const useStyles = makeStyles(theme => ({
//   root: {
//     flexGrow: 1,
//   },
//   margin: {
//     margin: theme.spacing(1),
//   },
// }));

const theme = color => createTheme({
  typography: {
    fontFamily: [
      'News Gothic MT',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
  palette: {
    primary: COLORS[color],
  },
});

function LinearProgress(props) {
  const {
    style = {},
    className = '',
    color = 'gray',
  } = props;

  const classes = useStyles(style);

  return (
    <ThemeProvider theme={theme(color)}>
      <MaterialLinearProgress className={`${classes.linearProgress} ${className}`} />
    </ThemeProvider>
  );
}

LinearProgress.propTypes = {
  style: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  color: PropTypes.string,
};

LinearProgress.defaultProps = {
  style: {},
  className: '',
  color: 'gray',
};

export default LinearProgress;
