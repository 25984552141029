import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import routeNames from '../../../route-names';
import PopMenu from '../../siclik/components/pop-menu';
import ItemMenuOnboarding from './item-menu-onboarding';

const assetUrl = `${process.env.REACT_APP_URL_AWS}/imagenes/assets/siclik/`;
const logo = `${assetUrl}siclikComercio.png`;

const MenuWeb = ({ history, profileComplete, acceptedTerms }) => (
  <Grid verticalAlign="middle" padded>
    <Grid.Row className="menu-bar">
      <Grid.Column tablet={3} computer={3} largeScreen={3} widescreen={3}>
        <Grid padded verticalAlign="middle" centered>
          <Grid.Row centered className="no-padding">
            <Grid.Column
              computer={16}
              largeScreen={16}
              widescreen={16}
              tablet={16}
            >
              <Image
                src={logo}
                size="medium"
                as="a"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push(routeNames.homeComercio.route)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column
        tablet={11}
        computer={12}
        largeScreen={12}
        widescreen={12}
        floated="right"
        verticalAlign="middle"
      >
        {acceptedTerms && profileComplete
          ? (
            <ItemMenuOnboarding history={history} />
          )
          : ''}
      </Grid.Column>
      <Grid.Column
        tablet={2}
        computer={1}
        largeScreen={1}
        widescreen={1}
        floated="right"
        verticalAlign="middle"
      >
        <PopMenu history={history} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

MenuWeb.propTypes = {
  history: PropTypes.func.isRequired,
  profileComplete: PropTypes.bool,
  acceptedTerms: PropTypes.bool,
};

MenuWeb.defaultProps = {
  profileComplete: false,
  acceptedTerms: false,
};

export default MenuWeb;
