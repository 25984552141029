import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Icon, Grid, Menu, Accordion, Divider,
} from 'semantic-ui-react';
import routeNames from '../../../route-names';
import assets from '.';

const outletPath = `${routeNames.products.route}?filters=outlet`;

const {
  brands,
  accesories,
  computer,
  printers,
  networks,
  servers,
  software,
} = assets;

const menuItemStyleMob = {
  fontSize: '18px',
  fontWeight: 'bold',
  // padding: '.5rem 0',
};

const accordionItemStyleMob = {
  padding: '.5rem 2rem',
  borderBottom: '1px solid rgba(255,255,255,.1)',
};

class MenuCategoriesComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      activeIndex: 0,
      hardware: [
        {
          key: 'accesories',
          title: { content: this.handleTitle('Accesorios'), icon: false },
          content: { content: this.handleSubcategories(accesories) },
        },
        {
          key: 'computer',
          title: { content: this.handleTitle('Cómputo'), icon: false },
          content: { content: this.handleSubcategories(computer) },
        },
        // {
        //   key: 'gaming',
        //   title: 'Gaming',
        //   //title: { content: this.handleTitle('Gaming'), icon: false },
        //   content: { content: this.handleGaming() },
        // },
        {
          key: 'printers',
          title: { content: this.handleTitle('Impresoras'), icon: false },
          content: { content: this.handleSubcategories(printers) },
        },
        {
          key: 'networks',
          title: { content: this.handleTitle('Redes'), icon: false },
          content: { content: this.handleSubcategories(networks) },
        },
        {
          key: 'servers',
          title: { content: this.handleTitle('Servidores'), icon: false },
          content: { content: this.handleSubcategories(servers) },
        },
      ],
    };
  }

  handleSearchButtonClick = () => {
    const { searchString } = this.state;
    const { history } = this.props;
    const encodedSearchString = encodeURIComponent(searchString);
    history.push(`/productos/00/${encodedSearchString}`);
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  }

  handleBrand = brand => {
    const { history, showMobile } = this.props;
    history.push(`/productos${brand}`);
    showMobile();
  }

  handleTitle = title => (
    <Accordion.Title
      className="blackText titles-news-gothic no-padding"
      style={menuItemStyleMob}
    >
      <Icon name="dropdown" />
      {title}
      <Divider style={{ margin: '0.3em 0' }} />
    </Accordion.Title>
  );

  handleSubcategories = subcategories => (
    <>
      {subcategories.map(subcategorie => (
        <Accordion.Content
          style={accordionItemStyleMob}
          className="lightBlackText"
          onClick={() => this.handleBrand(subcategorie.redirect)}
        >
          {subcategorie.text}
          <Divider style={{ margin: '0.3em 0' }} />
        </Accordion.Content>
      ))}
    </>
  )

  // handleGaming = () => {
  //   <Menu.Item
  //     onClick={() => this.handleBrand('gaming')}
  //     className="blueText"
  //     style={menuItemStyleMob}
  //   >
  //   Gaming
  // </Menu.Item>
  // };

  render() {
    const {
      isAuthenticated, t, history,
    } = this.props;
    const { activeIndex, hardware } = this.state;
    const bundlePath = `${routeNames.products.route}?filters=bundle`;
    return (
      <Grid padded textAlign="left" verticalAlign="middle">
        <Grid.Row className="no-padding">
          <Menu stackable text pointing fluid className="no-margin-top no-margin-bottom" style={{ minHeight: '2em' }}>
            {/* {
            isAuthenticated && (
            <Grid.Column
              className="no-padding-x"
              textAlign="center"
              mobile={16}
              style={{ paddingTop: '.5rem' }}
            >
              <Menu.Item
                onClick={() => this.handleSearchButtonClick()}
                className="blueText"
                style={menuItemStyleMob}
              >
                {t('Catálogo General')}
              </Menu.Item>
              <Divider style={{ margin: '0.3em 0' }} />
            </Grid.Column>
            )
          } */}
            {
          isAuthenticated && (
          <Grid.Column
            className="no-padding-x"
            textAlign="center"
            mobile={16}
            stretched
          >
            <Accordion fluid style={menuItemStyleMob}>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
                className="blueText titles-news-gothic no-padding-bottom"
                style={menuItemStyleMob}
              >
                <Icon name="dropdown" />
                Marcas
                <Divider style={{ margin: '0.3em 0' }} />
              </Accordion.Title>
              {brands.map(brand => (
                <Accordion.Content
                  style={accordionItemStyleMob}
                  className="blackText"
                  active={activeIndex === 1}
                  // onClick={() => { history.push(`/productos/00/${brand.redirect}`); }}
                  onClick={() => this.handleBrand(brand.redirect)}
                >
                  {brand.text}
                  <Divider style={{ margin: '0.3em 0' }} />
                </Accordion.Content>
              ))}
            </Accordion>
          </Grid.Column>
          )
          }
            {
          isAuthenticated && (
          <Grid.Column
            className="no-padding-x"
            textAlign="center"
            mobile={16}
            stretched
          >
            <Accordion fluid style={menuItemStyleMob} className="no-margin">
              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
                className="blueText titles-news-gothic no-padding-bottom"
                style={menuItemStyleMob}
              >
                <Icon name="dropdown" />
                Hardware
                <Divider style={{ margin: '0.3em 0' }} />
              </Accordion.Title>
              <Accordion.Content
                style={menuItemStyleMob}
                className="blackText titles-news-gothic no-padding-top"
                active={activeIndex === 2}
              >
                <Accordion.Accordion panels={hardware} fluid className="no-margin" />
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
          )
          }
            {
            isAuthenticated && (
            <Grid.Column
              className="no-padding-x"
              textAlign="center"
              mobile={16}
              stretched
            >
              <Accordion fluid style={menuItemStyleMob}>
                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={this.handleClick}
                  className="blueText titles-news-gothic no-padding-bottom"
                  style={menuItemStyleMob}
                >
                  <Icon name="dropdown" />
                  Software
                  <Divider style={{ margin: '0.3em 0' }} />
                </Accordion.Title>
                {software.map(soft => (
                  <Accordion.Content
                    style={accordionItemStyleMob}
                    className="blackText"
                    active={activeIndex === 3}
                    onClick={() => this.handleBrand(soft.redirect)}
                  >
                    {soft.text}
                    <Divider style={{ margin: '0.3em 0' }} />
                  </Accordion.Content>
                ))}
              </Accordion>
            </Grid.Column>
            )
            }
            {
            isAuthenticated && (
            <Grid.Column
              className="no-padding-x"
              textAlign="center"
              mobile={16}
            >
              <Menu.Item
                onClick={() => history.push(outletPath)}
                className="blueText"
                style={menuItemStyleMob}
              >
                Outlet
              </Menu.Item>
              <Divider style={{ margin: '0.3em 0' }} />
            </Grid.Column>
            )
          }
            {
            isAuthenticated && (
              <Grid.Column
                className="no-padding-x"
                textAlign="center"
                mobile={16}
              >
                <Menu.Item
                  onClick={() => history.push(bundlePath)}
                  className="blueText"
                  style={menuItemStyleMob}
                >
                  Paquetes
                </Menu.Item>
                <Divider style={{ margin: '0.3em 0' }} />
              </Grid.Column>
            )
          }
            {
            isAuthenticated && (
              <Grid.Column
                className="no-padding-x"
                textAlign="center"
                mobile={16}
              >
                <Menu.Item
                  onClick={() => { history.push(routeNames.iquote.route); }}
                  className="blueText"
                  style={menuItemStyleMob}
                >
                  {t('menu.iquote')}
                </Menu.Item>
                <Divider style={{ margin: '0.3em 0' }} />
              </Grid.Column>
            )
          }
            {
            isAuthenticated && (
              <Grid.Column
                className="no-padding-x"
                textAlign="center"
                mobile={16}
              >
                <Menu.Item
                  onClick={() => this.handleBrand('gaming')}
                  className="blueText"
                  style={menuItemStyleMob}
                >
                  Gaming
                </Menu.Item>
                <Divider style={{ margin: '0.3em 0' }} />
              </Grid.Column>
            )
          }
          </Menu>
        </Grid.Row>
      </Grid>
    );
  }
}

MenuCategoriesComp.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  isAuthenticated: PropTypes.string.isRequired,
  showMobile: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(MenuCategoriesComp);
