import React from 'react';
import { Message } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

function OrderErrorMessage(props) {
  const { t, hidden } = props;
  return (
    <Message warning hidden={hidden}>
      <Message.Header>{t('wishList.buyNow.message.header')}</Message.Header>
      <p>{t('wishList.buyNow.message.description')}</p>
      <p>{t('wishList.buyNow.message.extra')}</p>
    </Message>
  );
}

OrderErrorMessage.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(OrderErrorMessage);
