import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, List, Divider, Container,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import { currencyFormat } from '../../common/utils';

const VeeamOrderDetail = props => {
  const { t, orderDetail, detailsHeaders } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
          <Header as="h4" color="blue">
            {t('Detalle del pedido')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={8}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Fecha de orden')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderGeneralInfo.purchaseOrderDate}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={8}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('PO Name')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderGeneralInfo.purchaseOrderName}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={9}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Orden de compra')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderInfo.resellerPoNumber}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={8}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Estatus')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderStatusInfo.stage}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={8}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Motivo de rechazo')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderStatusInfo.declineReason}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={8}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Detalles de rechazo')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderStatusInfo.declineReasonDetails}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={8}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Total de la orden')}
                </Header>
                <List.Description>{currencyFormat(orderDetail.purchaseOrderStatusInfo.orderTotal, 'USD')}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={8} textAlign="justified">
          <Header as="h4" color="blue">
            {t('Datos usuario final')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Dirección')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserAddress}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Ciudad')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserCity}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  País
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserCountry}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  Razón social
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserName}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('customerDirectory.phone')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserPhone}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Estado')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserState}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Email')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.primaryLaEmail}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Código postal')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.endUserZip}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Nombre')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderEndUserInfo.primaryLaName}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={8} textAlign="justified">
          <Header as="h4" color="blue">
            {t('Datos del Distribuidor')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Dirección')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerAddress}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Ciudad')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerCity}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  País
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerCountry}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  Razón social
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerName}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Estado')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerState}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Email')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerEmail}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Código postal')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerZip}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
          <br />
          <List relaxed="very" size="large">
            <List.Item>
              <List.Content>
                <Header as="h5" color="green">
                  {t('Contacto')}
                </Header>
                <List.Description>{orderDetail.purchaseOrderResellerInfo.resellerContact}</List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={8} textAlign="justified">
          <Header as="h4" color="blue">
            {t('Articulos')}
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Divider section />
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Container style={{ overflowX: 'scroll' }}>
            <SiclikTable unstackable headers={detailsHeaders} data={orderDetail.purchaseOrderLineItems} />
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
VeeamOrderDetail.propTypes = {
  detailsHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  orderDetail: PropTypes.shape({
    purchaseOrderResellerInfo: PropTypes.shape({
      resellerContact: PropTypes.string,
      resellerZip: PropTypes.string,
      resellerEmail: PropTypes.string,
      resellerState: PropTypes.string,
      resellerName: PropTypes.string,
      resellerCountry: PropTypes.string,
      resellerCity: PropTypes.string,
      resellerAddress: PropTypes.string,
    }),
    purchaseOrderEndUserInfo: PropTypes.shape({
      endUserAddress: PropTypes.string,
      endUserCity: PropTypes.string,
      endUserCountry: PropTypes.string,
      endUserName: PropTypes.string,
      endUserPhone: PropTypes.string,
      endUserState: PropTypes.string,
      primaryLaEmail: PropTypes.string,
      endUserZip: PropTypes.string,
      primaryLaName: PropTypes.string,
    }),
    purchaseOrderStatusInfo: PropTypes.shape({
      stage: PropTypes.string,
      declineReason: PropTypes.string,
      declineReasonDetails: PropTypes.string,
      orderTotal: PropTypes.string,
    }),
    purchaseOrderGeneralInfo: PropTypes.shape({
      purchaseOrderDate: PropTypes.string,
      purchaseOrderName: PropTypes.string,
    }),
    purchaseOrderInfo: PropTypes.shape({
      resellerPoNumber: PropTypes.string,
    }),
    purchaseOrderLineItems: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

VeeamOrderDetail.defaultProps = {
  orderDetail: [],
  detailsHeaders: [],
};
export default translate('common', { wait: true })(VeeamOrderDetail);
