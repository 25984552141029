import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Button, Divider, Loader, Icon, Label, Responsive,
} from 'semantic-ui-react';
import ShippingDetail from './components/shippingDetail';
import SinglerDelivery from './components/singleDelivery';
import shippingRoutes from './services';
import { currencyFormat } from '../common/utils';
import Toast from '../common/components/toast';
import './styles.css';

class multiShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayProductsData: [],
      arrayBranchOfficeAddress: [],
      totalCost: null,
      arrayShippingDetail: [],
      loader: false,
      orderIdFromQuotations: props.match.params.orderId,
      singleDelivery: false,
      simpleDeliveryView: false,
      isQuotation: false,
      defaultBranch: '',
      arrayParcels: [],
      orderInCart: false,
    };
  }

  componentDidMount() {
    this.handleGetParcels();
    this.handleGetDeliveryConfig();
  }

  handleAsyncFunctions = async () => {
    await this.handleGetDeliveryConfig();
    this.validateCourierService();
  }

  handleGetDeliveryConfig = async () => {
    const { t } = this.props;
    const { orderIdFromQuotations } = this.state;
    let order = {};
    let isSingleDelivery = true;
    if (orderIdFromQuotations) order = orderIdFromQuotations;
    else order = '';
    this.setState({ loader: true });
    shippingRoutes.getDeliveryConfig(order).then(response => {
      const validateSingleDelivery = response.items;
      const allConfigurations = validateSingleDelivery.map(item => (item.deliveryConfig.map(branchOffice => ({ branchOfficeId: branchOffice.branchOfficeId })))).flat();
      for (let i = 0; i < allConfigurations.length; i++) {
        if (allConfigurations[i].branchOfficeId !== allConfigurations[0].branchOfficeId) isSingleDelivery = false;
      }
      this.setState({ singleDelivery: isSingleDelivery, customerFromOrder: response.customerId, orderId: response.orderId });
      this.handleGetAddress(response.customerId);
      const deliveryConfig = response.items;
      let cont = 100;
      if (deliveryConfig.length > 0) {
        const arrayProductsData = deliveryConfig.map(config => ({
          productName: config.name,
          sku: config.sku,
          quantity: config.quantity,
          image: config.image,
          addAddressValidation: false,
          amountToSendError: '',
          mistakes: false,
          restAmountToSend: 0,
          cont: 0,
          quotation: config.deliveryConfig.map(shippingConfig => {
            const copyShippingConfig = {
              UPSinsure: shippingConfig.UPSinsure,
              addressId: shippingConfig.branchOfficeId,
              index: config.sku + cont,
              parcel: shippingConfig.courierServiceId,
              parcelCheked: false,
              quantity: shippingConfig.quantity,
              sku: config.sku,
              shippingId: shippingConfig.shippingId,
              shippingPriceByAgent: shippingConfig.ShippingPriceByAgent,
              agentPrice: shippingConfig.AgentPrice,
              updatedPrice: false,
              parcelSeletected: false,
              parcelByLogistics: shippingConfig.courierServiceId,
            };
            cont++;
            return copyShippingConfig;
          }),
        }));
        this.setState({
          arrayProductsData,
          loader: false,
          customerFromOrder: response.customerId,
        });
      }
    })
      .catch(error => {
        if (error.code === 1200) {
          const messageError = 'La orden se encuentra en carrito';
          Toast(messageError, 'warning');
          this.setState({ loader: false, orderInCart: true });
        } else {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          this.setState({ loader: false });
          Toast(messageError, 'error');
        }
      });
  }

  handleGetAddress = customerFromOrder => {
    const { t } = this.props;
    shippingRoutes.getBranchOfficeAddress(customerFromOrder)
      .then(response => {
        const defaultBranchId = response.branchOffices.map(branchOffice => branchOffice.id);
        this.setState({
          arrayBranchOfficeAddress: response.branchOffices.map(branchOffice => (
            {
              key: branchOffice.id,
              text: branchOffice.name,
              value: branchOffice.id,
            }
          )),
          defaultBranch: defaultBranchId[0],
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetNewAddress = newAddress => {
    const { arrayBranchOfficeAddress } = this.state;
    arrayBranchOfficeAddress.push(newAddress);
  };

  handleSaveChanges = () => {
    const { orderId, customerFromOrder, arrayShippingDetail } = this.state;
    const { history } = this.props;
    const arrayShipping = arrayShippingDetail;
    const arrayQuotation = [];
    const { t } = this.props;
    arrayShipping.map(data => {
      if (data.quotation.length > 0) {
        for (let q = 0; q < data.quotation.length; q++) {
          if (data.quotation[q].length !== 0) {
            arrayQuotation.push({
              UPSinsure: data.quotation[q].UPSinsure,
              courierServiceId: data.quotation[q].parcelByLogistics,
              updatedPrice: data.quotation[q].updatedPrice,
              shippingId: data.quotation[q].shippingId,
              ShippingPriceByAgent: data.quotation[q].shippingPriceByAgent,
              pickWarehouse: data.quotation[q].warehouseCs,
            });
          }
        }
      }
      return data;
    });
    shippingRoutes.updateShippingPriceByAgent({ logisticsPrice: arrayQuotation })
      .then(response => {
        if (response.changedRows >= 1) {
          Toast(t('multi-shipping.successfulConfiguration'), 'success');
          history.push(`/cotizaciones/agente/detalle/${orderId}/${customerFromOrder}`);
        } else {
          Toast(t('multi-shipping.NotSavedChanges'), 'warning');
          history.push(`/cotizaciones/agente/detalle/${orderId}/${customerFromOrder}`);
        }
      })
      .catch(error => {
        let messageToast = '';
        if (!error.code) messageToast = t('error-codes.default');
        else messageToast = t(`error-codes.${error.code}`);
        Toast(messageToast, 'error');
      });
  }

  goToQuotation = () => {
    const { orderId, customerFromOrder } = this.state;
    const { history } = this.props;
    history.push(`/cotizaciones/agente/detalle/${orderId}/${customerFromOrder}`);
  }

  handleGetParcels = () => {
    const { t } = this.props;
    shippingRoutes.getParcels()
      .then(response => {
        const parcelFiltered = response.allParcels.filter(
          parcel => parcel.id !== 5 && parcel.id !== 0,
        );
        const parcelOptions = parcelFiltered.map(parcel => {
          const parcelConfig = {
            key: parcel.id,
            text: parcel.name,
            value: parcel.id,
          };
          return parcelConfig;
        });
        this.setState({ arrayParcels: parcelOptions });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  parcelValidations = () => {
    const { t } = this.props;
    const { arrayShippingDetail } = this.state;
    const arrayShipping = arrayShippingDetail;
    let parcelChekedError = false;
    let messageError = '';
    arrayShipping.map(data => {
      data.quotation.map(qt => {
        for (let i = 0; i < data.quotation.length; i++) {
          if (data.quotation[i].parcelSeletected === false) {
            parcelChekedError = true;
          }
        }
        return qt;
      });
      return data;
    });
    if (parcelChekedError) {
      messageError = t('multi-shipping.selectParcel');
      Toast(messageError, 'warning');
    } else this.handleSaveChanges();
  }

  render() {
    const {
      arrayProductsData, arrayBranchOfficeAddress, totalCost, loader, orderIdFromQuotations, singleDelivery,
      simpleDeliveryView, isQuotation, customerFromOrder, defaultBranch, arrayParcels, orderInCart,
    } = this.state;
    const { t } = this.props;
    return (
      <Grid container className="logisticsAgent-container">
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h3" color="blue">{t('multi-shipping.setUpShipping')}</Header>
          </Grid.Column>
        </Grid.Row>
        {orderInCart ? ''
          : (
            <Grid.Row>
              <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8}>
                <Divider horizontal>
                  <Header as="h4" style={{ display: 'inline' }}>
                    <Icon name="calculator" />
                    <Label basic onClick={() => { this.setState({ simpleDeliveryView: true }); }}>Calcular envío simple </Label>
                  </Header>
                </Divider>
              </Grid.Column>
            </Grid.Row>
          )}
        {simpleDeliveryView === true
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <p>
                  En esta sección puedes elegir una dirección de envío a la cual se calculará el precio de envío para los productos que estan en la orden.
                </p>
                <SinglerDelivery orderIdFromQuotations={orderIdFromQuotations} isSingleDelivery={singleDelivery} isQuotation={isQuotation} />
              </Grid.Column>
            </Grid.Row>
          )
          : ''}
        <Divider />
        {loader
          ? <Loader active size="big" />
          : (
            <Grid.Row>
              {orderInCart ? (
                <Label>
                  {' '}
                  <Header as="h3">La orden ya se encuentra en carrito.</Header>
                </Label>
              ) : (
                <ShippingDetail
                  arrayProductsData={arrayProductsData}
                  tr={t}
                  arrayBranchOfficeAddress={arrayBranchOfficeAddress}
                  getTotalCost={cost => this.setState({ totalCost: cost })}
                  getArrayShipping={data => this.setState({ arrayShippingDetail: data })}
                  customerFromOrder={customerFromOrder}
                  defaultBranch={defaultBranch}
                  arrayParcels={arrayParcels}
                />
              )}
            </Grid.Row>
          )}
        <Divider />
        <Grid.Row>
          <Grid.Column largeScreen={9} computer={9} tablet={7} mobile={16} />
          <Grid.Column largeScreen={7} computer={7} tablet={9} mobile={13} textAlign="right">
            <Header as="h4" color="black">
              {t('multi-shipping.totalShippingCost')}
              <Header as="h3" color="green" style={{ display: 'inline' }}>{ Number.isNaN(totalCost) ? '' : currencyFormat(totalCost)}</Header>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
            <Button size="medium" color="gray" fluid onClick={this.goToQuotation}>
              <span>{t('Regresar')}</span>
            </Button>
            <Responsive maxWidth={425}>
              <br />
            </Responsive>
          </Grid.Column>
          <Responsive as={Grid.Column} mobile={16} only="mobile" maxWidth={425} />
          <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
            <Button size="medium" color="green" fluid onClick={this.parcelValidations}>
              <span>{t('multi-shipping.saveChanges')}</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(multiShipping);
