import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Header, Loader, Dimmer,
} from 'semantic-ui-react';
import Toast from '../toast';
import * as session from '../../sessions';
import services from './services';
import { currencyFormat, compareArray } from '../../utils';

import './styles.css';

const CUSTOMER_PICKS_UP = 4;
const STANDARD_SHIPPING = 25;

class BoxQuotation extends Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      discountPercent: 0,
      subtotal: 0,
      total: 0,
      loader: true,
      requesting: false,
      customerId,
      requestError: false,
    };
  }

  componentDidMount() {
    this.handleGetParcelPrice();
  }

  componentDidUpdate(prevProps) {
    const { skus, addressId } = this.props;
    if (!compareArray(prevProps.skus, skus) || prevProps.addressId !== addressId) {
      this.handleGetParcelPrice();
    }
  }

  getAvailableParcels = () => ([STANDARD_SHIPPING, CUSTOMER_PICKS_UP]);

  handleGetParcelPrice = async () => {
    const {
      skus, currencyPrice, exchangeRate, customerFromOrder, addressId,
    } = this.props;
    if (skus.length > 0 && skus[0].quantity > 0) {
      const { tr } = this.props;
      let { customerId } = this.state;
      if (customerFromOrder !== undefined && customerFromOrder !== '') customerId = customerFromOrder;
      const body = { customerId, skus, addressId };
      this.setState({ loader: true, requesting: true });
      services.getParcelPrice(body)
        .then(response => {
          const {
            subtotal, discountPercent, total, origin,
          } = response;
          if (currencyPrice && currencyPrice === 'USD' && exchangeRate) {
            const { cash } = exchangeRate;
            this.setState({
              subtotal: subtotal / cash,
              discountPercent,
              total: total / cash,
            });
          } else {
            this.setState({
              subtotal,
              discountPercent,
              total,
            });
          }
          this.setState({
            loader: false,
            requesting: false,
            requestError: false,
          });
          this.handleSendDeliveryConfigBody(origin);
        })
        .catch(error => {
          if (error.code !== 1005) {
            this.setState({ requesting: false, requestError: true });
            let messageError = '';
            if (!error.code) messageError = tr('error-codes.default');
            else messageError = tr(`error-codes.${error.code}`);
            Toast(messageError, 'error');
          }
        });
    }
  }

  handleSendDeliveryConfigBody = originsArray => {
    const {
      warehouseValue, addressId, skus, response,
    } = this.props;
    const { total } = this.state;
    const availableParcels = this.getAvailableParcels();
    const body = {
      parcel: STANDARD_SHIPPING,
      parcelPrice: total,
      addressId,
      warehouseCs: warehouseValue,
      UPSinsure: false,
      skus,
      origins: originsArray,
    };
    response(body, availableParcels);
  }

  render() {
    const {
      loader, requesting, total, discountPercent, subtotal, requestError,
    } = this.state;
    const { currencyPrice } = this.props;
    const currency = currencyPrice === 'Dolares' || currencyPrice === 'Dólares' || currencyPrice === 'USD' ? 'USD' : 'MXN';
    const { tr } = this.props;
    return (
      <Grid>
        <Grid.Row>
          {loader
            ? (
              <Grid.Column width={16}>
                {
                  requesting
                    ? (
                      <Dimmer active inverted>
                        <Loader active size="medium">Cotizando envío ...</Loader>
                      </Dimmer>
                    ) : 'No se ha elegido la ubicación'
                }
                {requestError === true
                  ? <Header as="h5" color="black"><p>{tr('No se logró obtener los costos de envío debido a los datos proporcionados para esta sucursal, favor de contactar agente.')}</p></Header>
                  : ''}
              </Grid.Column>
            )
            : ([
              <Grid.Column width={16}>
                <div>
                  <span style={{ fontSize: 'large', fontWeight: 'bold' }}>Envío: </span>
                </div>
                {
                  discountPercent > 0
                    ? (
                      <div style={{ marginTop: '1em' }}>
                        <span style={{ color: 'green', fontSize: 'x-small' }}>{`(-${discountPercent}%) `}</span>
                        <span style={{ textDecoration: 'line-through', fontSize: 'x-small' }}>{currencyFormat(subtotal, currency)}</span>
                      </div>
                    )
                    : null
                }
                <div>
                  <span style={{ fontSize: 'medium', fontWeight: 'bold' }}>{currencyFormat(total, currency)}</span>
                </div>
              </Grid.Column>,
              <Grid.Column width={8}>
                <div>
                  <span style={{ fontSize: 'x-small' }}>* Tu pedido será entregado bajo las mejores condiciones en tiempo y costo para ti, te estaremos notificando en 48hrs tu número de rastreo.</span>
                </div>
              </Grid.Column>,
            ])}
        </Grid.Row>
      </Grid>
    );
  }
}
BoxQuotation.defaultProps = {
  // orderId: '',
  // defaultBranch: '',
  currencyPrice: '',
  customerFromOrder: '',
  warehouseValue: '',
  // needInsuranceUps: false,
  // subtotal: 0,
};
BoxQuotation.propTypes = {
  addressId: PropTypes.string.isRequired,
  currencyPrice: PropTypes.string,
  customerFromOrder: PropTypes.string,
  exchangeRate: PropTypes.shape({
    cash: PropTypes.number,
  }).isRequired,
  // getOrigins: PropTypes.bool.isRequired,
  // isQuickCart: PropTypes.bool.isRequired,
  // isSingle: PropTypes.bool.isRequired,
  // needInsuranceUps: PropTypes.bool,
  // orderId: PropTypes.string,
  // parcelValue: PropTypes.number.isRequired,
  response: PropTypes.func.isRequired,
  // defaultBranch: PropTypes.string,
  skus: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  tr: PropTypes.func.isRequired,
  warehouseValue: PropTypes.string,
  // subtotal: PropTypes.number,
};

export default translate('common', { wait: true })(BoxQuotation);
