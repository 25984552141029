import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Responsive } from 'semantic-ui-react';
import './style.css';

class ScrollableBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollLength: 0,
      width: 0,
    };
  }

  componentDidMount() {
    const { duration } = this.props;
    if (duration > 0) {
      this.interval = setInterval(this.nextSlide, duration);
    }
  }

  componentWillUnmount() {
    const { duration } = this.props;
    if (duration) clearInterval(this.interval);
  }

  nextSlide = () => {
    const { scrollLength } = this.state;
    const maxScrollLeft = this.containerRef.scrollWidth - this.containerRef.clientWidth;
    const scrollValue = this.containerRef.scrollLeft + scrollLength;
    const newScrollLeft = (
      ((scrollValue > maxScrollLeft) && (scrollValue < (maxScrollLeft + scrollLength)))
      || (scrollValue <= maxScrollLeft)
    ) ? scrollValue : 0;
    this.containerRef.scrollLeft = newScrollLeft;
  }

  prevSlide = () => {
    const { scrollLength } = this.state;
    const maxScrollLeft = this.containerRef.scrollWidth - this.containerRef.clientWidth;
    const scrollValue = this.containerRef.scrollLeft - scrollLength;
    const newScrollLeft = (
      ((scrollValue < 0) && (scrollValue > (0 - scrollLength)))
      || (scrollValue >= 0)
    ) ? scrollValue : maxScrollLeft;
    this.containerRef.scrollLeft = newScrollLeft;
  }

  nextClicked = e => {
    const { duration, continueOnClick } = this.props;
    if (duration > 0 && !continueOnClick) clearInterval(this.interval);
    e.target.blur();
    this.nextSlide();
  }

  prevClicked = e => {
    const { duration, continueOnClick } = this.props;
    if (duration > 0 && !continueOnClick) clearInterval(this.interval);
    e.target.blur();
    this.prevSlide();
  }

  handleOnUpdate = (e, { width }) => {
    document.documentElement.style.setProperty('--scrollablebar-container-width', width);
    this.setState({ scrollLength: width > 0 ? (width / 10) : 0, width });
  }

  getIconSize = (iconSize, width) => {
    if (iconSize === '') {
      return width < Responsive.onlyComputer.minWidth ? 'small' : 'huge';
    }
    return iconSize;
  }

  render() {
    const {
      elements,
      showBar,
      showNextPrev,
      iconSize,
    } = this.props;
    const { width } = this.state;
    const scrollableWidth = showNextPrev ? 14 : 16;
    return (
      <Grid padded>
        <Grid.Row centered>
          {
            showNextPrev
              ? (
                <Grid.Column width={1} verticalAlign="middle" textAlign="center" className="no-padding">
                  <Icon
                    style={{ cursor: 'pointer', marginRight: 0 }}
                    Link
                    size={this.getIconSize(iconSize, width)}
                    name="angle left"
                    className="lightGreyText"
                    fitted
                    onClick={this.prevClicked}
                  />
                </Grid.Column>
              ) : null
          }
          <Responsive
            as={Grid.Column}
            fireOnMount
            onUpdate={this.handleOnUpdate}
            width={scrollableWidth}
            textAlign="center"
            className="no-padding"
          >
            <div
              style={{ overflowX: showBar ? 'auto' : 'hidden' }}
              className="scrollable-container"
              ref={ref => { this.containerRef = ref; return ref; }}
            >
              {
                elements.map(element => (
                  element.render('element-scrollable')
                ))
              }
            </div>
          </Responsive>
          {
            showNextPrev
              ? (
                <Grid.Column width={1} verticalAlign="middle" textAlign="center" className="no-padding">
                  <Icon
                    style={{ cursor: 'pointer', marginRight: 0 }}
                    Link
                    size={this.getIconSize(iconSize, width)}
                    name="angle right"
                    className="lightGreyText"
                    fitted
                    onClick={this.nextClicked}
                  />
                </Grid.Column>
              ) : null
          }
        </Grid.Row>
      </Grid>
    );
  }
}

ScrollableBar.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      render: PropTypes.func,
    }),
  ).isRequired,
  duration: PropTypes.number,
  showNextPrev: PropTypes.bool,
  showBar: PropTypes.bool,
  continueOnClick: PropTypes.bool,
  iconSize: PropTypes.string,
};

ScrollableBar.defaultProps = {
  duration: 0,
  showNextPrev: false,
  showBar: false,
  continueOnClick: false,
  iconSize: '',
};

export default ScrollableBar;
