import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { models } from 'powerbi-client';
import Embed from './Embed';

const createConfig = props => {
  if (props) {
    const {
      accessToken,
      embedId,
      embedType,
      embedUrl,
      extraSettings,
      permissions,
      tokenType,
    } = props;
    return {
      accessToken,
      embedUrl,
      id: embedId,
      permissions: models.Permissions[permissions],
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        ...extraSettings,
      },
      tokenType: models.TokenType[tokenType],
      type: embedType,
    };
  }
  return null;
};

class Report extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentConfig: null,
    };
    this.performOnEmbed = this.performOnEmbed.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this.updateState(this.props);
  }

  static getDerivedStateFromProps(props) {
    return ({ currentConfig: createConfig(props) });
  }

  performOnEmbed(report) {
    const {
      embedType,
      onLoad,
    } = this.props;
    if (embedType === 'report') {
      report.on('loaded', () => {
        if (onLoad) {
          try {
            onLoad(report);
          } catch (e) {
            return e;
          }
        }
        return {};
      });
    }
  }

  updateState(props) {
    this.setState({ currentConfig: createConfig(props) });
  }

  render() {
    const { currentConfig } = this.state;
    const { style } = this.props;

    if (!currentConfig) { return <div> Error </div>; }
    return (
      <Embed
        config={currentConfig}
        performOnEmbed={this.performOnEmbed}
        style={style}
      />
    );
  }
}

Report.propTypes = {
  embedType: PropTypes.string.isRequired,
  onLoad: PropTypes.func.isRequired,
  style: PropTypes.shape({}).isRequired,
};

export default Report;
