import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { List, Label } from 'semantic-ui-react';

import Images from './Images';

import { getContentSource } from '../../commons';

const translatePath = 'productConfig.menus.editContent.item';

function ItemOptions({ t, item, ...events }) {
  return (
    <List>
      <List.Item>
        <List.Content>
          <List.Header as="a">
            {t(`${translatePath}.title`)}
            {' '}
            <Label size="mini">
              {getContentSource(item.title.source, t)}
            </Label>
          </List.Header>
          <List.Description as="a">
            {item.title.value}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">
            {t(`${translatePath}.category`)}
            {' '}
            <Label size="mini">
              {getContentSource(item.category.source, t)}
            </Label>
          </List.Header>
          <List.Description as="a">
            {item.category.value}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">
            {t(`${translatePath}.family`)}
            {' '}
            <Label size="mini">
              {getContentSource(item.family.source, t)}
            </Label>
          </List.Header>
          <List.Description as="a">
            {item.family.value}
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">{t(`${translatePath}.images`)}</List.Header>
          <List.Description as="a">
            <Images
              sku={item.sku}
              images={item.images}
              size="medium"
              isImageType
              changeMainImage={events.changeMainImage}
            />
          </List.Description>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Content>
          <List.Header as="a">{t(`${translatePath}.logos`)}</List.Header>
          <List.Description as="a">
            <Images
              sku={item.sku}
              images={item.featuredLogos}
              size="small"
              changeMainImage={events.changeMainImage}
            />
          </List.Description>
        </List.Content>
      </List.Item>
    </List>
  );
}

ItemOptions.propTypes = {
  changeMainImage: PropTypes.func.isRequired,
  item: PropTypes.shape({
    sku: PropTypes.string.isRequired,
    title: PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    category: PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    family: PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isMain: PropTypes.bool.isRequired,
        thumbnail: PropTypes.string,
        low: PropTypes.string,
        medium: PropTypes.string,
        big: PropTypes.string,
      }),
    })).isRequired,
    featuredLogos: PropTypes.arrayOf(PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        isMain: PropTypes.bool.isRequired,
        thumbnail: PropTypes.string,
        low: PropTypes.string,
        medium: PropTypes.string,
        big: PropTypes.string,
      }),
    })).isRequired,
  }).isRequired,
};

export default translate('common', { wait: true })(ItemOptions);
