import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const groups = {};

groups.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

groups.getGroups = type => (
  request({
    route: apiRoutes.getGroups(type),
    method: 'GET',
  })
);

groups.addGroup = body => (
  request({
    route: apiRoutes.addGroup(),
    method: 'POST',
    body,
  })
);

groups.updateGroup = body => (
  request({
    route: apiRoutes.updateGroup(),
    method: 'PUT',
    body,
  })
);

groups.getMembersGroupsById = (groupId, type) => (
  request({
    route: apiRoutes.getMembersGroupsById(groupId, type),
    method: 'GET',
  })
);

groups.deleteGroup = (type, groupId) => (
  request({
    route: apiRoutes.deleteGroup(type, groupId),
    method: 'DELETE',
  })
);

groups.addElementsToGroup = body => (
  request({
    route: apiRoutes.addElementToGroup(),
    method: 'POST',
    body,
  })
);

groups.deleteElementOfGroup = (type, groupId) => (
  request({
    route: apiRoutes.deleteElementOfGroup(type, groupId),
    method: 'DELETE',
  })
);

groups.getAllListApply = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

export default groups;
