import { callError } from '@compusoluciones-pdigitales/status-codes';

import { request } from '../../../common/fetch-utils';
import url from '../../../apiRoutes';

import { toast } from '../../utils';

function itemBodyScheme(sku, quantity = 1) {
  return [{
    sku,
    quantity,
  }];
}

function getErrorType(error) {
  switch (error.code) {
    case callError.products.NO_WISH_LIST_FOUND: {
      return [];
    }
    case callError.products.INVALID_SKU: {
      toast.warning('No se encontro ningun articulo con ese SKU');
      throw error;
    }
    default: {
      toast.error('El sistema parece no estar respondiendo correctamente. Intenta mas tarde.');
      throw error;
    }
  }
}

export async function fetchUserWishLists() {
  try {
    const user = await request({
      route: url.getWishLists(),
      method: 'GET',
    });
    return user.wishList;
  } catch (error) {
    return getErrorType(error);
  }
}

export async function createUserWishList(wishListName, item) {
  try {
    const user = await request({
      route: url.getWishLists(),
      method: 'POST',
      body: {
        name: wishListName,
        items: item && itemBodyScheme(item),
      },
    });
    return user.id;
  } catch (error) {
    return getErrorType(error);
  }
}

export async function renameUserWishList(wishListNameId, wishListName) {
  try {
    const user = await request({
      route: url.renameWishList(wishListNameId, wishListName),
      method: 'PUT',
    });
    return user.id;
  } catch (error) {
    return getErrorType(error);
  }
}

export async function deleteList(wishListId) {
  try {
    return await request({
      route: url.deleteWishList(wishListId),
      method: 'DELETE',
    });
  } catch (error) {
    return getErrorType(error);
  }
}
