import React, { Component } from 'react';
import * as XLSX from 'xlsx';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { translate } from 'react-i18next';
import {
  Grid, Header, Button, GridRow, Confirm, Dropdown, Divider, Loader,
} from 'semantic-ui-react';
import ExportExcel from '../common/components/ExportExcel';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import '../style.css';
import agents from './services';
import Toast from '../common/components/toast';
import * as session from '../common/sessions';
import roles from '../role-permissions';
import AgentsModal from './components/add';
import UploadModal from './components/add-upload';
import UpdateAgentModal from './components/update';
import ReplaceAgentModal from './components/replace';
import DeleteAgentModal from './components/delete';
import { sheetHeaders, styleHeader, styleRow } from './components/agents-excel';

class Agents extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const hasAccess = securityService.validate(access, roles.customerAdmin);

    if (!hasAccess)props.history.push('/perfil');

    this.state = {
      arrayAgentDirectory: [],
      arrayAgentDirectoryFiltered: [],
      arrayRolesFormat: [],
      arrayMakersFormat: [],
      arrayListAgentsFormat: [],
      arrayListCustomersFormat: [],
      arrayCustomersFormat: [],
      arrayPersonalFormat: [],
      emailError: '',
      cellPhoneError: '',
      cellPhone: '',
      agent: '',
      agentId: '',
      agentsArray: [],
      rol: '',
      rolId: 0,
      maker: '',
      customerId: '',
      email: '',
      makerId: '',
      save: true,
      update: false,
      headersAgent: [
        { key: 'id', isKey: true },
        { key: 'customerId', name: 'id Distribuidor' },
        { key: 'level', name: 'Nivel' },
        { key: 'customerName', name: 'Distribuidor' },
        { key: 'agent', name: 'Agente' },
        { key: 'maker', name: 'Marca' },
        { key: 'email', name: 'Correo' },
        { key: 'cellPhone', name: 'Teléfono' },
        {
          key: 'actions',
          format: (cell, row) => (
            <GridRow>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
              <Button color="blue" icon="trash alternate" onClick={() => this.handleDeleteAgent(row)} />
            </GridRow>
          ),
        },
      ],
      open: false,
      openUploadModal: false,
      openUpdateAgentModal: false,
      openDeleteAgentModal: false,
      openReplaceAgentModal: false,
      disabledButton: false,
      dirMakerId: '',
      agentEmail: '',
      previousEmail: '',
      dirCustomerId: '',
      agentCounter: 0,
      loader: false,
      loaderUpload: false,
    };
  }

  componentDidMount() {
    this.handleGetRoles();
    this.handleGetPersonal();
    this.handleGetMakers();
    this.handleGetCustomer();
    this.handleGetAgentList();
    this.handleGetCustomerList();
  }

  handleGetPersonal = () => {
    const { t } = this.props;
    agents.getAllAgents()
      .then(response => {
        const personalFormat = response.agents.map((agent, index) => {
          const options = {
            key: index,
            text: agent.name,
            value: agent.name,
            mail: agent.email,
          };
          return options;
        });
        this.setState({ arrayPersonalFormat: personalFormat });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleClickCancel = () => this.setState({
    update: false,
    save: true,
    open: false,
    openDeleteAgentModal: false,
    openUpdateAgentModal: false,
    openReplaceAgentModal: false,
    openUploadModal: false,
    agentId: '',
    maker: '',
    makerId: '',
    rol: '',
    rolId: '',
    customerId: '',
    email: '',
    cellPhone: '',
    emailError: '',
    cellPhoneError: '',
    customerName: '',
    previousEmail: '',
  });

  handleClearVariables = () => {
    this.setState({
      loaderUpload: false,
      disabledButton: false,
      arrayAgentDirectory: [],
      arrayAgentDirectoryFiltered: [],
      agentCounter: 0,
      agent: '',
      agentEmail: '',
      dirMakerId: '',
      dirCustomerId: '',
    });
  }

  handleGetNameValue = (e, { value }) => {
    const { arrayPersonalFormat } = this.state;
    const agentData = arrayPersonalFormat.find(element => element.text === value);
    this.setState({ agent: value, agentEmail: agentData.mail });
  }

  handleGetRolValue = (e, { value }) => this.setState({ rolId: value })

  handleGetMakerValue = (e, { value, id }) => this.setState({ makerId: value, maker: id })

  handleGetEmailValue = (e, { value }) => this.setState({ email: value })

  handleGetCustomerValue = (e, { value }) => this.setState({ customerId: value })

  handleGetPreviousEmail = async (e, { value }) => this.setState({ previousEmail: value })

  handleGetSelectedEmail = async (e, { value }) => {
    const { arrayListAgentsFormat } = this.state;
    const agentData = arrayListAgentsFormat.find(agent => agent.key === value);
    this.setState({ agentEmail: value, agent: agentData.text });
  }

  handleSaveAgent = async () => {
    const { t } = this.props;
    const {
      agent, rolId, makerId, customerId, agentEmail, cellPhone,
    } = this.state;
    this.setState({ loaderUpload: true });
    const body = {
      agent, rolId, makerId, customerId, email: agentEmail, cellPhone,
    };
    agents.addAgents({ agents: [body] }).then(() => {
      Toast(t('agents.confirmAgentAdd'), 'success');
      this.setState({ arrayAgentDirectoryFiltered: [], loaderUpload: false });
      this.handleClickCancel();
      this.handleClearVariables();
      this.handleGetAgentList();
    }).catch(() => {
      Toast(t('Favor de revisar que el correo corresponda al agente', 'error'));
    });
  }

  handleClickUpdate = row => (
    this.setState({
      agentId: row.id,
      agent: row.agent,
      rol: row.rol,
      rolId: row.rolId,
      maker: row.maker,
      makerId: row.makerId,
      customerId: row.customerId,
      cellPhone: row.cellPhone,
      email: row.email,
      open: true,
      save: false,
      update: true,
    })
  )

handleUpdateAgents = () => {
  const {
    agentId, customerId, agent, rolId, email, cellPhone, makerId, arrayAgentDirectory, filter,
    maker, rol,
  } = this.state;
  let agentsData = {
    customerId, agent, makerId, rolId, email, cellPhone,
  };
  const { t } = this.props;
  agents.addAgents({ agents: [agentsData] })
    .then(() => {
      Toast(t('agents.confirmUpdateAgents'), 'success');
      const agentToUpdate = arrayAgentDirectory.findIndex(keyword => keyword.id === agentId);
      agentsData = { ...agentsData, maker, rol };
      arrayAgentDirectory[agentToUpdate].agentId = agentsData.id;
      arrayAgentDirectory[agentToUpdate].customerId = agentsData.customerId;
      arrayAgentDirectory[agentToUpdate].agent = agentsData.agent;
      arrayAgentDirectory[agentToUpdate].maker = agentsData.maker;
      arrayAgentDirectory[agentToUpdate].rol = agentsData.rol;
      arrayAgentDirectory[agentToUpdate].email = agentsData.email;
      arrayAgentDirectory[agentToUpdate].cellPhone = agentsData.cellPhone;
      this.setState({
        agent: '',
        agentId: '',
        customerId: '',
        makerId: '',
        rolId: '',
        email: '',
        cellPhone: '',
        open: false,
      });
      this.handleFilter(filter, arrayAgentDirectory);
    }).catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
}

handleUpdateAllAgents = () => {
  const {
    agent, rolId, agentEmail, cellPhone,
  } = this.state;
  const { t } = this.props;
  const agentsData = {
    agent, rolId, email: agentEmail, cell: cellPhone,
  };
  agents.updateDirectoryAgentByEmail(agentsData)
    .then(() => {
      Toast(t('agents.confirmUpdateAgents'), 'success');
      this.handleClickCancel();
      this.handleClearVariables();
      this.handleGetAgentList();
    }).catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
}

handleReplaceAgent = () => {
  const {
    agent, rolId, agentEmail, cellPhone, previousEmail,
  } = this.state;
  const { t } = this.props;
  const agentsData = {
    name: agent, rolId, email: agentEmail, cellPhone, previousEmail,
  };
  agents.replaceAgentDirectory(agentsData)
    .then(() => {
      Toast(t('agents.confirmUpdateAgents'), 'success');
      this.handleClickCancel();
      this.handleGetAgentList();
      this.handleClearVariables();
    }).catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
}

  handleGetCustomer = () => {
    const { t } = this.props;
    agents.getCustomers()
      .then(response => {
        const cutomersFormat = response.customers.map((customer, index) => {
          const options = {
            key: index,
            text: customer.id,
            value: customer.id,
          };
          return options;
        });
        this.setState({ arrayCustomersFormat: cutomersFormat });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetMakers = () => {
    const { t } = this.props;
    agents.getAgentMakers()
      .then(response => {
        const makersFormat = response.makers.map(maker => {
          const options = {
            key: maker.id,
            text: maker.name,
            value: maker.id,
          };
          return options;
        });
        this.setState({ arrayMakersFormat: makersFormat });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetAgentList = () => {
    const { t } = this.props;
    agents.getAgentList()
      .then(response => {
        const agentsFormat = response.agents.map(agent => {
          const options = {
            key: agent.email,
            text: agent.agent,
            value: agent.email,
          };
          return options;
        });
        this.setState({ arrayListAgentsFormat: agentsFormat });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetCustomerList = () => {
    const { t } = this.props;
    agents.getCustomerList()
      .then(response => {
        const customersFormat = response.customers.map(customer => {
          const options = {
            key: customer.customerId,
            text: customer.name,
            value: customer.customerId,
          };
          return options;
        });
        this.setState({ arrayListCustomersFormat: customersFormat });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetRoles = () => {
    const { t } = this.props;
    agents.getAgentsRoles()
      .then(response => {
        const rolesFormat = response.roles.map(rol => {
          const options = {
            key: rol.id,
            text: rol.rol,
            value: rol.id,
          };
          return options;
        });
        this.setState({ arrayRolesFormat: rolesFormat });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleCancel = () => this.setState({ confirmDelete: false });

  handleDeleteAgent = row => {
    this.setState({ confirmDelete: true, agentId: row.id });
  }

  handleDeleteConfirmed = () => {
    const { t } = this.props;
    const { agentId, filter, arrayAgentDirectory } = this.state;
    agents.deleteAgentById(agentId).then(() => {
      Toast(t('agents.eliminationConfirmation'), 'success');
      const agentToDelete = arrayAgentDirectory.findIndex(agent => agent.id === agentId);
      arrayAgentDirectory.splice(agentToDelete, 1);
      this.handleFilter(filter, arrayAgentDirectory);
      this.setState({ arrayAgentDirectory, confirmDelete: false });
      this.handleGetAgentList();
    }).catch(error => {
      Toast(t(`error-codes.${error.code}`, 'error'));
    });
  }

  handleDeleteAgentDirectory = () => {
    const { agentEmail } = this.state;
    const { t } = this.props;
    agents.deleteDirectoryAgentByEmail(agentEmail).then(() => {
      Toast(t('agents.eliminationConfirmation'), 'success');
      this.setState({ arrayAgentDirectoryFiltered: [], agentCounter: 0 });
      this.handleClickCancel();
      this.handleGetAgentList();
    }).catch(error => {
      this.handleGetAgentList();
      Toast(t(`error-codes.${error.code}`, 'error'));
    });
  }

  handleFilterAgent = event => {
    const { arrayAgentDirectory } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, arrayAgentDirectory);
  }

  handleEmailChange = event => {
    const { t } = this.props;
    const maxLength = 100;
    const { value: email } = event.target;
    if (email && email.length > 0 && email.length > maxLength) {
      this.setState({ email, emailError: t('attributes.100Limit') });
    } else {
      this.setState({ email, emailError: '' });
    }
  }

  handleCellPhoneChange = event => {
    const { t } = this.props;
    const maxLength = 100;
    const { value: cellPhone } = event.target;
    if (cellPhone && cellPhone.length > 0 && cellPhone.length > maxLength) {
      this.setState({ cellPhone, cellPhoneError: t('attributes.100Limit') });
    } else {
      this.setState({ cellPhone, cellPhoneError: '' });
    }
  }

  handleFilter = (filter, arrayAgents) => {
    const arrayAgentsCopy = JSON.parse(JSON.stringify(arrayAgents));
    let foundData = [];
    if (!filter) {
      foundData = arrayAgents;
    } else {
      foundData = arrayAgentsCopy.filter(filterInfo => (filterInfo.agent && filterInfo.agent.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.rol && filterInfo.rol.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.maker && filterInfo.maker.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.customerId && filterInfo.customerId.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.customerName && filterInfo.customerName.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    }
    this.setState({ filter, arrayAgentDirectoryFiltered: foundData, agentCounter: foundData.length });
  }

  handleGetAgentDirectoryByMaker = async (e, { value }) => {
    this.setState({ dirMakerId: value, loader: true });
    agents.getAgentDirectoryByMaker(value)
      .then(agentDirectoryArray => {
        this.setState({
          dirMakerId: value,
          arrayAgentDirectory: agentDirectoryArray.agents,
          arrayAgentDirectoryFiltered: agentDirectoryArray.agents,
          agentEmail: '',
          previousEmail: '',
          dirCustomerId: '',
          agentCounter: agentDirectoryArray.agents.length,
          loader: false,
        });
      })
      .catch(() => {
        const messageError = 'No se encontraron agentes para la marca seleccionada';
        Toast(messageError, 'error');
        this.setState({
          dirMakerId: value, arrayAgentDirectory: [], arrayAgentDirectoryFiltered: [], agentEmail: '', dirCustomerId: '', agentCounter: 0, loader: false,
        });
      });
  }

  handleGetAgentDirectoryByAgent = async (e, { value }) => {
    this.setState({ agentEmail: value, loader: true });
    agents.getDirectoryByAgent(value)
      .then(agentDirectoryArray => {
        const agentData = agentDirectoryArray.agents.find(element => element.email === value);
        this.setState({
          agentEmail: value,
          agent: agentData.agent,
          arrayAgentDirectory: agentDirectoryArray.agents,
          arrayAgentDirectoryFiltered: agentDirectoryArray.agents,
          dirMakerId: '',
          dirCustomerId: '',
          agentCounter: agentDirectoryArray.agents.length,
          loader: false,
        });
      })
      .catch(() => {
        const messageError = 'No se encontraron agentes';
        Toast(messageError, 'error');
        this.setState({
          agentEmail: value, arrayAgentDirectory: [], arrayAgentDirectoryFiltered: [], dirCustomerId: '', agentCounter: 0, loader: false,
        });
      });
  }

  handleGetAgentDirectoryByCustomer = async (e, { value }) => {
    this.setState({ dirCustomerId: value, loader: true });
    agents.getAgentsByCustomerId(value)
      .then(agentDirectoryArray => {
        this.setState({
          dirCustomerId: value,
          arrayAgentDirectory: agentDirectoryArray.agents,
          arrayAgentDirectoryFiltered: agentDirectoryArray.agents,
          dirMakerId: '',
          agentEmail: '',
          previousEmail: '',
          agentCounter: agentDirectoryArray.agents.length,
          loader: false,
        });
      })
      .catch(() => {
        const messageError = 'No se encontraron agentes para el distribuidor seleccionado';
        Toast(messageError, 'error');
        this.setState({
          agentEmail: value, arrayAgentDirectory: [], arrayAgentDirectoryFiltered: [], dirCustomerId: '', agentCounter: 0, loader: false,
        });
      });
  }

  handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value });
    const sheets = [];
    if (name === 'file') {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        workbook.SheetNames.forEach(sheetName => {
          if (sheetName === 'Directorio') {
            const xlRowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
            sheets.push({ data: xlRowObject });
          }
        });
        this.setState({ agentsArray: sheets[0].data });
      };
    }
  }

  generateSaveBody = agentsArray => (
    Promise.all(agentsArray.map(agent => {
      let cellPhone = '';
      if (!agent.Celular) cellPhone = '';
      else cellPhone = agent.Celular.toString();
      return {
        agent: agent.Agente,
        customerId: agent.Cliente,
        makerId: agent.IdMarca,
        rolId: agent.IdPuesto,
        email: agent.Correo,
        cellPhone,
      };
    }))
  )

  handleClickSave = async () => {
    this.setState({ disabledButton: true, loaderUpload: true });
    const { t } = this.props;
    const { agentsArray } = this.state;
    const body = await this.generateSaveBody(agentsArray);
    if (body.length <= 500) {
      agents.addAgents({ agents: body }).then(() => {
        Toast(t('agents.confirmAgentAdd'), 'success');
        this.handleClickCancel();
        this.handleClearVariables();
        this.handleGetAgentList();
      }).catch(() => {
        Toast('Favor de revisar que los datos en el archivo (correo, marca, puesto) existan', 'error');
        this.setState({ openUploadModal: false, loaderUpload: false, disabledButton: false });
      });
    } else {
      Toast(t('agents.limitAgentsError'), 'error');
      this.setState({ disabledButton: false, loaderUpload: false });
    }
  }

  render() {
    const { t } = this.props;
    const {
      headersAgent, open, openUploadModal, openUpdateAgentModal, openDeleteAgentModal, arrayAgentDirectoryFiltered, filter, agent, confirmDelete,
      arrayRolesFormat, rol, rolId, arrayPersonalFormat, email, maker, arrayMakersFormat, arrayCustomersFormat, cellPhone, cellPhoneError, emailError,
      save, update, customerId, makerId, disabledButton, dirMakerId, arrayListAgentsFormat, agentEmail, dirCustomerId, customerName,
      arrayListCustomersFormat, agentCounter, loader, loaderUpload, openReplaceAgentModal, previousEmail,
    } = this.state;
    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('agents.adminAgents')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('agents.welcome')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={3} computer={3} tablet={8} mobile={8}>
            <Button
              onClick={() => this.setState({ open: true, save: true, update: false })}
              content={t('agents.add')}
              color="blue"
              size="small"
              icon="add user"
              labelPosition="center"
              fluid
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={8} mobile={8}>
            <Button
              onClick={() => this.setState({ openUpdateAgentModal: true })}
              content={t('agents.modify')}
              color="blue"
              size="small"
              icon="edit"
              labelPosition="center"
              fluid
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={8} mobile={8}>
            <Button
              onClick={() => this.setState({ openReplaceAgentModal: true })}
              content="Reemplazar"
              size="small"
              color="blue"
              icon="clone"
              labelPosition="center"
              fluid
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={8} mobile={8}>
            <Button
              onClick={() => this.setState({ openDeleteAgentModal: true })}
              content={t('agents.delete')}
              color="red"
              size="small"
              icon="delete"
              labelPosition="center"
              fluid
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={8} mobile={8}>
            <Button
              onClick={() => this.setState({ openUploadModal: true })}
              content={t('agents.uploadFile')}
              color="green"
              size="small"
              icon="upload"
              labelPosition="center"
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
            <Dropdown
              placeholder={t('cross-selling.chooseMaker')}
              options={arrayMakersFormat}
              onChange={this.handleGetAgentDirectoryByMaker}
              value={dirMakerId}
              id={maker}
              defaultValue={maker}
              fluid
              search
              selection
            />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
            <Dropdown
              placeholder={t('cross-selling.chooseAgent')}
              options={arrayListAgentsFormat}
              onChange={this.handleGetAgentDirectoryByAgent}
              value={agentEmail}
              id={email}
              defaultValue={email}
              fluid
              search
              selection
            />
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={8} mobile={16}>
            <Dropdown
              placeholder={t('cross-selling.chooseCustomer')}
              options={arrayListCustomersFormat}
              onChange={this.handleGetAgentDirectoryByCustomer}
              value={dirCustomerId}
              id={customerName}
              defaultValue={customerName}
              fluid
              search
              selection
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
            <TextField
              maxLength={55}
              value={filter || ''}
              fullWidth
              label={t('agents.searchAgent')}
              onChange={this.handleFilterAgent}
            />
          </Grid.Column>
          <Grid.Column largeScreen={10} computer={10} tablet={6} mobile={14} textAlign="right">
            <p>
              {t('agents.registersCounter')}
              &nbsp;&nbsp;&nbsp;
              {agentCounter}
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {agentCounter > 0
            ? (
              <Grid.Column largeScreen={3} computer={3} tablet={5} mobile={5}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={arrayAgentDirectoryFiltered}
                  fileName={t('agents.exportFileName')}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('agents.export')}
                  sheetName="Agentes"
                />
              </Grid.Column>
            ) : null}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ overflowX: 'scroll' }}>
            {loader
              ? <Loader active inline="centered" />
              : (
                <SiclikTable
                  selectable
                  headers={headersAgent}
                  data={arrayAgentDirectoryFiltered}
                  pageSize={50}
                  singleSelection
                />
              )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={confirmDelete}
            header={t('agents.confirmDeleteAgents')}
            content={agent}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleDeleteConfirmed}
          />
        </Grid.Row>
        <Grid.Row>
          <AgentsModal
            open={open}
            rolesFormat={arrayRolesFormat}
            makersFormat={arrayMakersFormat}
            customersFormat={arrayCustomersFormat}
            handleGetRolValue={this.handleGetRolValue}
            handleGetMakerValue={this.handleGetMakerValue}
            handleGetCustomerValue={this.handleGetCustomerValue}
            rol={rol}
            rolId={rolId}
            makerId={makerId}
            maker={maker}
            customerId={customerId}
            handleClickCancel={this.handleClickCancel}
            handleGetNameValue={this.handleGetNameValue}
            handleGetEmailValue={this.handleGetEmailValue}
            handleUpdateAgents={this.handleUpdateAgents}
            handleSaveAgent={this.handleSaveAgent}
            personalFormat={arrayPersonalFormat}
            email={{ value: email, error: emailError, change: this.handleEmailChange }}
            cellPhone={{ value: cellPhone, error: cellPhoneError, change: this.handleCellPhoneChange }}
            save={save}
            update={update}
            agent={agent}
            agentEmail={agentEmail}
            loaderUpload={loaderUpload}
          />
        </Grid.Row>
        <Grid.Row>
          <UploadModal
            openUploadModal={openUploadModal}
            handleClickCancel={() => this.setState({ openUploadModal: false })}
            handleGetNameValue={this.handleGetNameValue}
            handleInputChange={this.handleInputChange}
            handleClickSave={this.handleClickSave}
            disabledButton={disabledButton}
            rolesFormat={arrayRolesFormat}
            makersFormat={arrayMakersFormat}
            customersFormat={arrayCustomersFormat}
            handleGetRolValue={this.handleGetRolValue}
            handleGetMakerValue={this.handleGetMakerValue}
            handleGetCustomerValue={this.handleGetCustomerValue}
            rol={rol}
            rolId={rolId}
            makerId={makerId}
            maker={maker}
            customerId={customerId}
            handleGetEmailValue={this.handleGetEmailValue}
            handleUpdateAgents={this.handleUpdateAgents}
            handleSaveAgent={this.handleSaveAgent}
            personalFormat={arrayPersonalFormat}
            email={{ value: email, error: emailError, change: this.handleEmailChange }}
            cellPhone={{ value: cellPhone, error: cellPhoneError, change: this.handleCellPhoneChange }}
            save={save}
            update={update}
            agent={agent}
            loaderUpload={loaderUpload}
          />
        </Grid.Row>
        <Grid.Row>
          <UpdateAgentModal
            openUpdateAgentModal={openUpdateAgentModal}
            handleClickCancel={this.handleClickCancel}
            handleGetSelectedEmail={this.handleGetSelectedEmail}
            personalFormat={arrayListAgentsFormat}
            agentEmail={agentEmail}
            handleUpdateAllAgents={this.handleUpdateAllAgents}
            rolesFormat={arrayRolesFormat}
            handleGetRolValue={this.handleGetRolValue}
            rol={rol}
            rolId={rolId}
            cellPhone={{ value: cellPhone, error: cellPhoneError, change: this.handleCellPhoneChange }}
          />
        </Grid.Row>
        <Grid.Row>
          <DeleteAgentModal
            openDeleteAgentModal={openDeleteAgentModal}
            handleClickCancel={this.handleClickCancel}
            handleGetSelectedEmail={this.handleGetSelectedEmail}
            personalFormat={arrayListAgentsFormat}
            agentEmail={agentEmail}
            handleDeleteAgentDirectory={this.handleDeleteAgentDirectory}
          />
        </Grid.Row>
        <Grid.Row>
          <ReplaceAgentModal
            openReplaceAgentModal={openReplaceAgentModal}
            handleClickCancel={this.handleClickCancel}
            handleGetSelectedEmail={this.handleGetSelectedEmail}
            handleGetPreviousEmail={this.handleGetPreviousEmail}
            handleGetNameValue={this.handleGetNameValue}
            agentsFormat={arrayListAgentsFormat}
            personalFormat={arrayPersonalFormat}
            agentEmail={agentEmail}
            handleReplaceAgent={this.handleReplaceAgent}
            rolesFormat={arrayRolesFormat}
            handleGetRolValue={this.handleGetRolValue}
            rol={rol}
            rolId={rolId}
            previousEmail={previousEmail}
            agent={agent}
            cellPhone={{ value: cellPhone, error: cellPhoneError, change: this.handleCellPhoneChange }}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(Agents);
