import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Icon, Image, Divider,
} from 'semantic-ui-react';
import routes from '../../route-names';

const urlAWS = process.env.REACT_APP_URL_AWS;
const paymentsImage = `${urlAWS}/imagenes/assets/brands/formas_pago.png`;
const trustImage = `${urlAWS}/imagenes/assets/comercio/sello_confianza.png`;
const trustImageProfeco = `${urlAWS}/imagenes/assets/comercio/sello_confianza.svg`;
const pdfPath = pdfName => routes.pdfViewer.route.replace(':name?', pdfName);

const SITES = {
  facebook: 'https://www.facebook.com/CompuSolucionesyAsociados',
  twitter: 'https://twitter.com/CompuSoluciones',
  linkedin: 'https://www.linkedin.com/company/compusoluciones/',
  youtube: 'https://www.youtube.com/user/CanalCompuSoluciones',
  privacy: 'https://www.compusoluciones.com/aviso-de-privacidad/',
  contact: 'mailto:queremosescucharte@compusoluciones.com',
  corruption: 'https://mkt.compusoluciones.com/e/ConstantContact/politica-anticorrupcion-2020.pdf',
  report: 'https://appsd.compusoluciones.com/SistemaDenuncias/LandingDenuncias.aspx',
  returnsAndCancellations: pdfPath('devoluciones'),
  faqs: pdfPath('fqa'),
  terms: pdfPath('terms'),
  payments: pdfPath('formasdepago'),
  shipping: pdfPath('metodosenvio'),
  profeco: 'https://www.gob.mx/profeco',
  condusef: 'https://www.gob.mx/condusef',
  tutoriales: 'https://bit.ly/TutorialesYoutube',
};

function IconLink(props) {
  const {
    label, url, color, style, size, iconName, background,
  } = props;
  return (
    <a style={{ style }} href={url} className={`${background}Text`} target="_blank" rel="noreferrer">
      <Icon
        size={size}
        circular
        color={color}
        className={`${background}Background`}
        name={iconName}
      />
      <span style={{ paddingRight: '.5em', paddingLeft: '.5em' }}>{label}</span>
    </a>
  );
}

IconLink.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  label: PropTypes.string,
  iconName: PropTypes.string,
  style: PropTypes.shape({}),
  url: PropTypes.string,
  size: PropTypes.string,
};

IconLink.defaultProps = {
  color: 'blue',
  background: 'white',
  size: 'large',
  label: '',
  iconName: '',
  style: {},
  url: '',
};
function StackableLink(props) {
  const {
    label, url, color, style, size, hideDivider,
  } = props;
  return (
    <Grid.Row style={{ paddingTop: '.2em' }}>
      <Header size={size}>
        <a style={style} href={url} className={`${color}Text`} target="_blank" rel="noreferrer">
          {label}
        </a>
        {
          hideDivider || <Divider style={{ margin: '0.3em 0' }} />
        }
      </Header>
    </Grid.Row>
  );
}

StackableLink.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  style: PropTypes.shape({}),
  url: PropTypes.string,
  size: PropTypes.string,
  hideDivider: PropTypes.bool,
};

StackableLink.defaultProps = {
  color: 'white',
  size: 'small',
  hideDivider: false,
  label: '',
  style: {},
  url: '',
};

function Footer(props) {
  const { t } = props;
  const colorTextFooter = {
    color: '#64a2d9',
  };
  return (
    <Grid>
      <Grid.Row color="blueBackground" className="no-padding-y">
        <Grid.Column
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
          style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
        />
      </Grid.Row>
      <Grid.Row textAlign="left" color="blueBackground" centered className="no-padding-y">
        <Grid.Column
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
          largeScreen={3}
          computer={3}
          tablet={7}
          mobile={10}
          verticalAlign="top"
        >
          <StackableLink label={t('footer.comercio.terms')} url={SITES.terms} />
          <StackableLink label={t('footer.comercio.payments')} url={SITES.payments} />
          <StackableLink label={t('footer.comercio.shipping')} url={SITES.shipping} />
          <StackableLink label={t('footer.comercio.returnsAndCancellations')} url={SITES.returnsAndCancellations} />
          <StackableLink label="PROFECO" url={SITES.profeco} />
          <StackableLink label="CONDUSEF" url={SITES.condusef} />
        </Grid.Column>
        <Grid.Column
          largeScreen={3}
          computer={3}
          tablet={7}
          mobile={10}
          verticalAlign="top"
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <StackableLink label={t('footer.comercio.contactUs')} url={SITES.contact} />
          <StackableLink label={t('footer.comercio.privacy')} url={SITES.privacy} />
          <StackableLink label={t('footer.comercio.faqs')} url={SITES.faqs} />
          <StackableLink label={t('footer.comercio.tutorials')} url={SITES.tutoriales} />
          <Image style={{ marginTop: '1rem' }} size="small" src={trustImage} href="https://sellosdeconfianza.org.mx/MuestraCertificado.php?NUMERO_SERIE=SC_b40" target="_blank" />
          <Image size="medium" src={trustImageProfeco} href="https://distintivodigital.profeco.gob.mx/consulta.php?serie=DA23STY1031019" target="_blank" />
        </Grid.Column>
        <Grid.Column
          largeScreen={4}
          computer={4}
          tablet={7}
          mobile={10}
          verticalAlign="top"
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <Header size="small" color="white">{t('footer.public.gdl.name')}</Header>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.gdl.address')}</p>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.gdl.phone')}</p>
          <Header size="small" color="white">{t('footer.public.cdmx.name')}</Header>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.cdmx.address')}</p>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.cdmx.phone')}</p>
          <Header size="small" color="white">{t('footer.public.mty.name')}</Header>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.mty.address')}</p>
        </Grid.Column>
        <Grid.Column
          largeScreen={4}
          computer={4}
          tablet={7}
          mobile={10}
          verticalAlign="top"
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <Header size="small" color="white">{t('footer.public.officeHours')}</Header>
          <p className="no-margin-bottom no-padding-bottom" style={colorTextFooter}>{t('footer.public.schedule')}</p>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.email')}</p>
          <Grid style={{ paddingTop: '1rem' }}>
            <Grid.Row style={{ paddingTop: '1rem' }} columns="equal">
              <Grid.Column>
                <IconLink iconName="facebook f" label="Facebook" url={SITES.facebook} />
              </Grid.Column>
              <Grid.Column>
                <IconLink iconName="twitter" label="Twitter" url={SITES.twitter} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: '1rem' }} columns="equal">
              <Grid.Column>
                <IconLink iconName="linkedin" label="LinkedIn" url={SITES.linkedin} />
              </Grid.Column>
              <Grid.Column>
                <IconLink iconName="youtube" label="YouTube" url={SITES.youtube} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered className="no-padding-y fullBlackBackground">
        <Grid.Column
          widescreen={8}
          computer={8}
          tablet={16}
          mobile={16}
          textAlign="center"
          verticalAlign="top"
        >
          <Image
            fluid
            src={paymentsImage}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered className="no-padding-y midWhiteBackground">
        <Grid.Column
          largeScreen={14}
          computer={14}
          tablet={16}
          mobile={16}
          style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
          textAlign="center"
          verticalAlign="top"
        >
          <Header size="tiny" color="grey">
            {t('footer.public.allRightsReserved')}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(Footer);
