import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Menu, Segment } from 'semantic-ui-react';
import cookie from 'js-cookie';
import Discount from '../discount';
import AdminGroups from '../groups';
import testData from '../test-data';
import * as session from '../common/sessions';

class AddDiscount extends Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      activeItem: cookie.get('element'),
      headersGroups: [],
      informationGroups: [],
      headersClients: [],
      informationClients: [],
      accessDenied: (customerId === 'G000000'),
    };
  }

  componentDidMount() {
    this.getInformation();
    this.getHeaders();
  }

  getHeaders = () => this.setState({
    headersGroups: testData.headerGroups,
    headersModalClients: testData.headerClientsOfGroups,
    headersClients: testData.headerDiscountsClients,
  });

  getInformation = () => {
    this.setState({
      informationGroups: testData.discountsGroupsArray,
      informationClients: testData.clientsOfGroups,
    });
  }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  render() {
    const {
      accessDenied, activeItem, headersModalClients, headersClients, headersGroups, informationClients, informationGroups,
    } = this.state;
    const { history, changeView, id } = this.props;
    let componentView;
    if (!accessDenied) history.push('/');

    if (activeItem === 'Descuento') {
      componentView = <Discount changeView={changeView} id={id} />;
    } else {
      componentView = (
        <AdminGroups
          headersGroups={headersGroups}
          headersClients={headersClients}
          headersModalClients={headersModalClients}
          informationGroups={informationGroups}
          informationClients={informationClients}
          newGroup={this.newGroup}
        />
      );
    }

    return (
      <div>
        {accessDenied
          ? (
            <Menu size="massive" attached="top" tabular>
              <Menu.Item name="Descuento" active={activeItem === 'Descuento'} onClick={this.handleItemClick} />
              <Menu.Item name="Grupos" active={activeItem === 'Grupos'} onClick={this.handleItemClick} />
            </Menu>
          )
          : null }
        { accessDenied
          ? (
            <Segment attached="bottom">
              {componentView}
            </Segment>
          )
          : null }
      </div>
    );
  }
}

AddDiscount.propTypes = {
  changeView: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default translate('common', { wait: true })(AddDiscount);
