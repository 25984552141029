import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import cookie from 'js-cookie';
import validator from 'validator';
import {
  Grid, Header, Icon, Responsive, Button, Dimmer, Loader, Checkbox, Divider, Modal, Segment,
} from 'semantic-ui-react';
import DatePicker from '@material-ui/core/TextField';
import * as XLSX from 'xlsx';
import { cloneArrayNoRef } from '../common/utils';
import service from './services';
import Toast from '../common/components/toast';
import SerialsProducts from './components/serialsProducts';
import routeNames from '../route-names';
import { decode } from '../common/sessions';
import { TextField } from '../common/components/materials';

class AppleDep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      billing: '',
      billingError: '',
      billingData: [],
      filteredSerials: [],
      loader: false,
      requesting: true,
      PONumber: '',
      DEPResellerId: '',
      DEPCustomerId: '',
      orderDate: '',
      PONumberError: '',
      DEPResellerIdError: '',
      DEPCustomerIdError: '',
      mistakes: false,
      currentActions: 'Enrolar',
      orderDateError: false,
      buttonLoader: false,
      openUploadModal: false,
      seriesFromCsv: [],
      customerIdLoged: decode(cookie.get('token')).customer.id,
      emailCustomer: '',

    };
  }

  componentDidMount() {
    this.handleGetUserInformation();
  }

  handleBillingNumberChange = event => {
    const { t } = this.props;
    const maxLength = 10;
    let { errors } = false;
    const { value: billing } = event.target;
    if ((billing.trim()).length > maxLength) {
      this.setState({ billing, billingError: t('appleDEP.errors.toLong') });
      errors = true;
      return;
    }
    if (errors !== true) {
      this.setState({ billing, billingError: '', mistakes: errors });
    }
  }

  handlePONumberChange = event => {
    const { t } = this.props;
    const maxLength = 15;
    let errors = false;
    const { value: PONumber } = event.target;
    if ((PONumber.trim()).length > maxLength) {
      errors = true;
      this.setState({ PONumber, PONumberError: t('appleDEP.errors.toLong'), mistakes: errors });
      return;
    }
    if (!validator.matches(PONumber, '^[a-zA-Z0-9_-]*$')) {
      errors = true;
      this.setState({ PONumber, PONumberError: t('appleDEP.errors.notSymbol'), mistakes: errors });
      return;
    }
    if (errors !== true) {
      this.setState({ PONumber, PONumberError: '', mistakes: errors });
    }
  }

  handleDEPResellerIdChange = event => {
    const { t } = this.props;
    const maxLength = 15;
    let errors = false;
    const { value: DEPResellerId } = event.target;
    if ((DEPResellerId.trim()).length > maxLength) {
      errors = true;
      this.setState({ DEPResellerId, DEPResellerIdError: t('appleDEP.errors.toLong'), mistakes: errors });
      return;
    }
    if (!validator.matches(DEPResellerId, '^[a-zA-Z0-9_-]*$')) {
      errors = true;
      this.setState({ DEPResellerId, DEPResellerIdError: t('appleDEP.errors.notSymbol'), mistakes: errors });
      return;
    }
    if (errors !== true) {
      this.setState({ DEPResellerId, DEPResellerIdError: '', mistakes: errors });
    }
  }

  handleDEPCustomerIdChange = event => {
    const { t } = this.props;
    const maxLength = 15;
    let errors = false;
    const { value: DEPCustomerId } = event.target;
    if ((DEPCustomerId.trim()).length > maxLength) {
      errors = true;
      this.setState({ DEPCustomerId, DEPCustomerIdError: t('appleDEP.errors.toLong'), mistakes: errors });
      return;
    }
    if (!validator.matches(DEPCustomerId, '^[a-zA-Z0-9_-]*$')) {
      errors = true;
      this.setState({ DEPCustomerId, DEPCustomerIdError: t('appleDEP.errors.notSymbol'), mistakes: errors });
      return;
    }
    if (errors !== true) {
      this.setState({ DEPCustomerId, DEPCustomerIdError: '', mistakes: errors });
    }
  }

  handleOrderDateChange = ({ target }) => {
    const { value } = target;
    this.setState({ orderDate: value, orderDateError: '' });
  };

  handleValidateBillingNumberField = () => {
    const { t } = this.props;
    let completed = true;
    const { billing } = this.state;
    let { billingError } = this.state;
    if (!billing || billing.length === 0) {
      billingError = t('appleDEP.errors.empty');
      completed = false;
    }
    if (!validator.isAlphanumeric(billing.replace(/ /g, ''), ['es-ES'])) {
      this.setState({ billing, billingError: t('appleDEP.errors.notSymbol') });
      return;
    }
    if (completed !== true) {
      this.setState({ billingError: t('appleDEP.errors.incompleteData') });
      Toast(billingError, 'error');
    } else {
      this.handleValidateBillingNumber();
    }
  }

  handleValidateBillingNumber = () => {
    const { t } = this.props;
    let mistakes = false;
    const { billing } = this.state;
    let { billingError } = this.state;
    if (!validator.isAlphanumeric(billing)) {
      billingError = t('appleDEP.errors.notSymbol');
      mistakes = true;
    }
    if (mistakes === false) {
      this.handleGetBilling(billing);
    } else {
      this.setState({ billingError });
      billingError = t('appleDEP.errors.dataErrors');
      Toast(billingError, 'error');
    }
  }

  handleGetBilling = async billing => {
    const { t, isMultibrand, maker } = this.props;
    const seriesStatus = await service.getSeriesStatus(billing);
    this.setState({ loader: true, requesting: true });
    let billingArray = [];
    service.getBillingDetailsByMovId(billing)
      .then(response => {
        if (response) {
          if (isMultibrand) {
            billingArray = response.billingDetails.filter(filter => filter.maker === maker);
          } else {
            billingArray = response.billingDetails.filter(filter => filter.maker === 'Apple');
            if (billingArray && billingArray.length > 0) {
              const ipad = 'IPAD';
              const imac = 'MAC';
              const ipod = 'Ipod';
              const mbp = 'mbp';
              const appleTv = 'APPLE TV';
              const filterProductsArray = billingArray.filter(filterInfo => (filterInfo.productName.toLowerCase().indexOf(ipad.toLowerCase()) >= 0
                  || filterInfo.productName.toLowerCase().indexOf(imac.toLowerCase()) >= 0
                  || filterInfo.productName.toLowerCase().indexOf(ipod.toLowerCase()) >= 0
                  || filterInfo.productName.toLowerCase().indexOf(mbp.toLowerCase()) >= 0
                  || filterInfo.productName.toLowerCase().indexOf(appleTv.toLowerCase()) >= 0));
              billingArray = filterProductsArray;
            }
          } if (billingArray.length > 0) {
            billingArray = billingArray.map(config => {
              let cont = 1;
              const newConfig = {
                serials: this.handleAsignStatus(config, seriesStatus, cont),
                productName: config.productName,
                sku: config.sku,
                maker: config.maker,
                family: config.family,
                line: config.line,
                billingDate: config.billingDate,
                billingMovId: config.billingMovId,
                selectionCounter: 0,
                index: config.sku + cont++,
                filter: '',
              };
              return newConfig;
            });
            const message = t('appleDEP.rightBill'); Toast(message, 'success');
            this.setState({
              billingData: billingArray,
              filteredSerials: billingArray,
              loader: false,
              requesting: false,
            });
          } else {
            this.setState({
              billingData: billingArray,
              filteredSerials: billingArray,
              loader: false,
              requesting: false,
            });
          }
        }
      })
      .catch(error => {
        let messageError = '';
        if (error.code === 1005) {
          messageError = t('appleDEP.invalidBill');
        } else messageError = t(`error-codes.${error.code}`);
        this.setState({
          billingData: [],
          filteredSerials: [],
          loader: false,
          requesting: false,
        });
        Toast(messageError, 'error');
      });
  }

  handleGetStatus = (seriesStatus, serial) => {
    const statusFound = seriesStatus.status.find(element => element.serial === serial);
    if (statusFound) return statusFound.status;
    return 'Sin enrolar';
  }

  handleAsignStatus = (config, seriesStatus, count) => {
    let counter = count;
    const serials = config.serials.map(serial => ({
      serial,
      checked: false,
      index: config.sku + counter++,
      status: seriesStatus && seriesStatus.status.length > 0
        ? this.handleGetStatus(seriesStatus, serial)
        : 'Sin enrolar',
    })).flat();
    return serials;
  }

  handleSelectSerial = (e, { checked, id, name }) => {
    const { billingData, filteredSerials } = this.state;
    const billingDataNoRef = cloneArrayNoRef(billingData);
    const filteredSerialsNoRef = cloneArrayNoRef(filteredSerials);
    const serialsProducts = billingDataNoRef.map(updateBillingData => {
      if (updateBillingData.sku === name) {
        let { selectionCounter } = updateBillingData;
        const modifiedSerials = updateBillingData.serials.map(item => {
          let checkedValue = item.checked;
          if (checked && id === item.index) {
            selectionCounter++;
            checkedValue = checked;
            return { ...item, checked: checkedValue };
          } if (!checked && id === item.index) {
            selectionCounter--;
            checkedValue = checked;
            return { ...item, checked: checkedValue };
          }
          return { ...item };
        });
        return { ...updateBillingData, serials: modifiedSerials, selectionCounter };
      }
      return updateBillingData;
    });
    const serialsProductsForFilter = filteredSerialsNoRef.map(updateBillingData => {
      if (updateBillingData.sku === name) {
        let { selectionCounter } = updateBillingData;
        const modifiedSerials = updateBillingData.serials.map(item => {
          let checkedValue = item.checked;
          if (checked && id === item.index) {
            selectionCounter++;
            checkedValue = checked;
            return { ...item, checked: checkedValue };
          } if (!checked && id === item.index) {
            selectionCounter--;
            checkedValue = checked;
            return { ...item, checked: checkedValue };
          }
          return { ...item };
        });
        return { ...updateBillingData, serials: modifiedSerials, selectionCounter };
      }
      return updateBillingData;
    });
    this.setState({ billingData: serialsProducts, filteredSerials: serialsProductsForFilter });
  }

  handleValidateComponent = () => {
    const { isMultibrand } = this.props;
    if (isMultibrand) {
      this.handleValidateToSave();
    } else {
      this.handleValidateEmptyData();
    }
  }

  handleValidateEmptyData = () => {
    let {
      PONumberError, DEPResellerIdError, DEPCustomerIdError, mistakes, orderDateError,
    } = this.state;
    const {
      PONumber, DEPResellerId, DEPCustomerId, orderDate,
    } = this.state;
    const { t } = this.props;
    if ((PONumber.trim()).length === 0) {
      PONumberError = t('appleDEP.errors.empty');
      mistakes = true;
    }
    if ((DEPResellerId.trim()).length === 0) {
      DEPResellerIdError = t('appleDEP.errors.empty');
      mistakes = true;
    }
    if ((DEPCustomerId.trim()).length === 0) {
      DEPCustomerIdError = t('user.errors.empty');
      mistakes = true;
    }
    if ((orderDate.trim()).length === 0) {
      orderDateError = t('user.errors.empty');
      mistakes = true;
    }
    if (validator.isEmpty(PONumber)) mistakes = true;
    if (validator.isEmpty(DEPResellerId)) mistakes = true;
    if (validator.isEmpty(DEPCustomerId)) mistakes = true;
    if (validator.isEmpty(orderDate)) mistakes = true;
    if (mistakes === true) {
      Toast(t('appleDEP.errors.incompleteData'), 'error');
      this.setState({
        PONumberError, DEPResellerIdError, DEPCustomerIdError, orderDateError,
      });
    } else {
      this.handleValidateToSave();
    }
  }

  handleValidateToSave = () => {
    const { billingData, currentActions, mistakes } = this.state;
    const { t, isMultibrand, limitGuarantees } = this.props;
    let serialChecked = false;
    let selectionCounterError = false;
    let messageError = '';
    let orderTypeError = false;
    let sumSelectionCounter = 0;
    if (isMultibrand) {
      billingData.map(data => {
        sumSelectionCounter = billingData.reduce((acc, counter) => acc + counter.selectionCounter, 0);
        return data;
      });
      if (sumSelectionCounter !== limitGuarantees) {
        selectionCounterError = true;
      }
    }
    if (billingData.length > 0) {
      billingData.map(data => {
        if (data.serials.length > 0) {
          data.serials.map(serialData => {
            if (serialData.checked === true) serialChecked = true;
            return serialData;
          });
        }
        return data;
      });
    }
    if (currentActions === false && isMultibrand === false) orderTypeError = true;
    if (orderTypeError) {
      messageError = t('appleDEP.selectOrderType');
      Toast(messageError, 'warning');
    }
    if (serialChecked !== true) {
      messageError = t('appleDEP.selectAtLessOneSerial');
      Toast(messageError, 'warning');
    }
    if (selectionCounterError) {
      messageError = t('appleDEP.errors.limitGuarantees').concat(limitGuarantees);
      Toast(messageError, 'warning');
    }
    if (mistakes) {
      messageError = t('appleDEP.errors.incompleteData');
      Toast(messageError, 'error');
    }
    if (serialChecked && !selectionCounterError && !mistakes) {
      if (isMultibrand) this.handleSendDataToMultibrand();
      else if (!isMultibrand && !orderTypeError) {
        this.handleDepRequestBody();
      }
    }
  }

  handleSelectAllSerials = (e, { checked, name }) => {
    const { billingData, filteredSerials } = this.state;
    const billingDataNoRef = cloneArrayNoRef(billingData);
    const filteredSerialsNoRef = cloneArrayNoRef(filteredSerials);
    const serialsProducts = billingDataNoRef.map(updateBillingData => {
      if (updateBillingData.sku === name) {
        let selectionCounter = 0;
        const modifiedSerials = updateBillingData.serials.map(item => {
          if (checked) selectionCounter++;
          else selectionCounter = 0;
          return { ...item, checked };
        });
        return { ...updateBillingData, serials: modifiedSerials, selectionCounter };
      }
      return updateBillingData;
    });
    const serialsProductsForFilter = filteredSerialsNoRef.map(updateBillingData => {
      if (updateBillingData.sku === name) {
        let selectionCounter = 0;
        const modifiedSerials = updateBillingData.serials.map(item => {
          if (checked) selectionCounter++;
          else selectionCounter = 0;
          return { ...item, checked };
        });
        return { ...updateBillingData, serials: modifiedSerials, selectionCounter };
      }
      return updateBillingData;
    });
    this.setState({ billingData: serialsProducts, filteredSerials: serialsProductsForFilter });
  }

  handleSelectOrderTypeChange = (e, { value }) => {
    const { billingData } = this.state;
    let updatebillingArray = billingData;
    if (billingData.length > 0 && value === 'Enrolar') {
      updatebillingArray = updatebillingArray.map(updateBillingData => {
        let modifiedSerials = updateBillingData;
        modifiedSerials = updateBillingData.serials.map(item => {
          if (item.status === 'Enrolado') {
            const elementToUpdate = updateBillingData.serials.findIndex(findElement => findElement.status === 'Enrolado');
            modifiedSerials.serials[elementToUpdate].checked = false;
          }
          return item;
        });
        return updateBillingData;
      });
    }
    this.setState({ currentActions: value, billingData: updatebillingArray, filteredSerials: updatebillingArray });
  }

  handleSendDataToMultibrand = () => {
    const { billingData } = this.state;
    const { skuCarePack, handleGetSerials } = this.props;
    let billingDataToSave = [];
    billingData.map(config => {
      if (config.serials.length > 0) {
        billingDataToSave.push({
          serials: config.serials.filter(
            item => item.checked === true,
          ).map(serialProduct => ({ serial: serialProduct.serial })).flat(),
          productName: config.productName,
          sku: config.sku,
          maker: config.maker,
          family: config.family,
          line: config.line,
          billingDate: moment(config.billingDate).format('DD/MM/YYYY'),
          billingMovId: config.billingMovId,
          skuCarePack,
        });
      }
      return config;
    });
    billingDataToSave = billingDataToSave.filter(filter => filter.serials.length > 0);
    handleGetSerials(billingDataToSave);
  }

  handleDepRequestBody = () => {
    const {
      billingData, billing, DEPResellerId, DEPCustomerId, orderDate, currentActions, PONumber, emailCustomer, customerIdLoged,
    } = this.state;
    const serials = [];
    billingData.map(config => {
      if (config.serials.length > 0) {
        serials.push(
          config.serials.filter(
            item => item.checked === true,
          ).map(serialProduct => (serialProduct.serial)),
        );
      }
      return config;
    });
    const joinAllSerials = serials.reduce((accumulatedArray, allSerials) => accumulatedArray.concat(allSerials));
    const depRequestBody = {
      invoiceNumber: billing,
      depResellerId: DEPResellerId,
      depCustomerId: DEPCustomerId,
      orderDate,
      action: currentActions,
      poNumber: PONumber,
      serials: joinAllSerials,
      email: emailCustomer,
      customerId: customerIdLoged,
    };
    this.handleAddDepRequest(depRequestBody);
  }

  handleAddDepRequest = depRequestBody => {
    const { t, history } = this.props;
    this.setState({ buttonLoader: true });
    service.addDepRequest(depRequestBody).then(response => {
      if (response) {
        Toast(t('appleDEP.requestSent'), 'success');
        this.setState({ buttonLoader: false });
        history.push(routeNames.homeComercio.route);
      }
    }).catch(error => {
      this.setState({ buttonLoader: false });
      let messageError = '';
      messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  handleGetFilterValue = (sku, filter) => {
    const { billingData, filteredSerials } = this.state;
    let updatedData = cloneArrayNoRef(filteredSerials);
    let foundData = [];
    const billinDataNoRef = cloneArrayNoRef(billingData);
    const filterValue = filter;
    const elementToUpdate = billingData.findIndex(findElement => findElement.sku === sku);
    const originSerials = billinDataNoRef[elementToUpdate].serials;
    updatedData = updatedData.map(item => {
      if (item.sku === sku) {
        foundData = originSerials.filter(filterInfo => (filterInfo.serial.toLowerCase().indexOf(filter.toLowerCase()) >= 0
            || filterInfo.status.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
        updatedData[elementToUpdate].serials = foundData;
        updatedData[elementToUpdate].filter = filterValue;
      }
      return item;
    });
    this.setState({ filteredSerials: updatedData });
  }

  handleUpdateFilterValue = (sku, filter) => {
    const { filteredSerials } = this.state;
    let updatedData = filteredSerials;
    const filterValue = filter;
    const elementToUpdate = filteredSerials.findIndex(findElement => findElement.sku === sku);
    updatedData = updatedData.map(item => {
      if (item.sku === sku) {
        updatedData[elementToUpdate].filter = filterValue;
      }
      return item;
    });
    this.setState({ billingData: updatedData, filteredSerials: updatedData });
  }

  downloadFile = () => {
    const awsUrl = 'https://s3.amazonaws.com/aws.cs-siclick/templates/series.xlsx';
    const a = document.createElement('a'); // eslint-disable-line
    a.href = awsUrl;
    a.download = `${awsUrl}`;
    a.click();
  };

  handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value });
    const sheets = [];
    if (name === 'file') {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        workbook.SheetNames.forEach(sheetName => {
          const xlRowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          sheets.push({ data: xlRowObject, sheetName });
        });
        this.setState({ seriesFromCsv: sheets[0].data });
      };
    }
  }

  handleClickSaveCsv = async () => {
    const { t } = this.props;
    const { seriesFromCsv, currentActions } = this.state;
    const { billingData } = this.state;
    let updatebillingArray = billingData;
    const serialsData = await Promise.all(seriesFromCsv.map(serial => (
      {
        serial: serial.Series,
        checked: true,
      }
    )));
    if (serialsData.length > 0) {
      updatebillingArray = updatebillingArray.map(updateBillingData => {
        let modifiedSerials = updateBillingData;
        modifiedSerials = updateBillingData.serials.map(item => {
          const seriesCsv = serialsData.find(element => element.serial === item.serial);
          if (currentActions === 'Enrolar') {
            if (seriesCsv && item.serial === seriesCsv.serial && item.status !== 'Enrolado') {
              const elementToUpdate = updateBillingData.serials.findIndex(findElement => findElement.serial === seriesCsv.serial);
              modifiedSerials.serials[elementToUpdate].checked = seriesCsv.checked;
            }
          } else if (currentActions !== 'Enrolar') {
            if (seriesCsv && item.serial === seriesCsv.serial) {
              const elementToUpdate = updateBillingData.serials.findIndex(findElement => findElement.serial === seriesCsv.serial);
              modifiedSerials.serials[elementToUpdate].checked = seriesCsv.checked;
            }
          }
          return item;
        });
        return updateBillingData;
      });
      this.setState({ billingData: updatebillingArray, filteredSerials: updatebillingArray, openUploadModal: false });
      const message = t('Carga exitosa'); Toast(message, 'success');
    } else {
      const message = t('El archivo esta vacio o no es valido');
      Toast(message, 'warning');
    }
  }

  handleGetUserInformation = () => {
    const token = cookie.get('token');
    const userId = decode(token).id;
    const { t } = this.props;
    service.getUser(userId)
      .then(response => {
        this.setState({
          emailCustomer: response.email,
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  render() {
    const { t, isMultibrand, handleCancel } = this.props;
    const {
      billing, billingError, billingData, loader, requesting, PONumber, DEPResellerId,
      DEPCustomerId, PONumberError, DEPResellerIdError, DEPCustomerIdError, currentActions,
      orderDate, orderDateError, buttonLoader, filteredSerials, openUploadModal,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1.5rem', paddingBottom: '1.5rem' }}>
        <Responsive as={Grid.Row} fireOnMount>
          {isMultibrand ? '' : <Header as="h2" color="blue">{t('appleDEP.depRequest')}</Header>}
        </Responsive>
        {isMultibrand ? '' : (
          <Grid.Row style={{ paddingTop: '3rem', paddingBottom: '1.5rem' }}>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
              <Header as="h3" color="gray">{t('appleDEP.orderType')}</Header>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={5}>
              <Segment compact>
                <Checkbox
                  label="Enrolar"
                  value="Enrolar"
                  checked={currentActions === 'Enrolar'}
                  onChange={this.handleSelectOrderTypeChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={5}>
              <Segment compact>
                <Checkbox
                  label="Devolver"
                  value="Devolver"
                  checked={currentActions === 'Devolver'}
                  onChange={this.handleSelectOrderTypeChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={5}>
              <Segment compact>
                <Checkbox
                  label="Anular"
                  value="Anular"
                  checked={currentActions === 'Anular'}
                  onChange={this.handleSelectOrderTypeChange}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={6}>
              <Segment fluid>
                <Checkbox
                  label="Modificar"
                  value="Modificar"
                  checked={currentActions === 'Modificar'}
                  onChange={this.handleSelectOrderTypeChange}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        )}
        <Divider />
        <Grid.Row>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={10}>
            <TextField
              id="billingNumber"
              fullWidth
              maxLength={15}
              value={billing || ''}
              error={billingError || ''}
              label={t('appleDEP.billingNumber')}
              onChange={this.handleBillingNumberChange}
              hit="appleDEP.invoiceNumber"
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
            <Button icon color="lightBlack" size="medium" onClick={this.handleValidateBillingNumberField}>
              <Icon name="search" size="large" color="midWhite" />
            </Button>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16} />
        </Grid.Row>
        {isMultibrand ? '' : (
          <Grid.Row>
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
              <TextField
                id="PONumber"
                fullWidth
                maxLength={15}
                label="PO Number"
                hint={t('appleDEP.purchaseOrder')}
                required
                error={PONumberError || ''}
                value={PONumber || ''}
                onChange={this.handlePONumberChange}
              />
            </Grid.Column>
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={16} />
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
              <TextField
                id="DEPResellerId"
                fullWidth
                maxLength={15}
                label="DEP Reseller id"
                hint={t('appleDEP.DepResellerId')}
                required
                error={DEPResellerIdError || ''}
                value={DEPResellerId || ''}
                onChange={this.handleDEPResellerIdChange}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {isMultibrand ? '' : (
          <Grid.Row>
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
              <TextField
                id="DEPCustomerId"
                fullWidth
                maxLength={10}
                label="Organization id"
                hint={t('Organization id')}
                required
                error={DEPCustomerIdError || ''}
                value={DEPCustomerId || ''}
                onChange={this.handleDEPCustomerIdChange}
              />
            </Grid.Column>
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={16} />
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
              <Header className="no-margin-bottom" as="h5">{t('appleDEP.orderDate')}</Header>
              <DatePicker
                type="date"
                format="dd/mm/yyyy"
                value={orderDate}
                onChange={this.handleOrderDateChange}
                InputLabelProps={{ shrink: true }}
                error={orderDateError}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Divider />
        {isMultibrand || billingData.length === 0 ? '' : (
          <Grid.Row>
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
              <Header as="h4" color="gray">{t('Cargar series desde archivo Excel')}</Header>
            </Grid.Column>
          </Grid.Row>
        )}
        {isMultibrand || billingData.length === 0 ? '' : (
          <Grid.Row>
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
              <p>En este apartado puede subir un archivo con las series que desea solicitar, o bien seleccionarlas manualmente en la parte de abajo.</p>
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={3} mobile={16}>
              <Button
                icon="download"
                fluid
                content={t('Descargar plantilla')}
                labelPosition="left"
                onClick={this.downloadFile}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={3} mobile={16}>
              <Button
                icon="upload"
                color="green"
                fluid
                content={t('Subir archivo')}
                labelPosition="left"
                onClick={() => this.setState({ openUploadModal: true })}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Divider />
        <Grid.Row>
          <Modal open={openUploadModal}>
            <Modal.Header left>{t('agents.uploadFile')}</Modal.Header>
            <Modal.Content>
              <Grid.Row>
                <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
                  <Grid.Row>
                    <input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={this.handleInputChange}
                      placeholder="Archivo de excel"
                      accept=".xlsx"
                    />
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Modal.Content>
            <Modal.Actions>
              <Button color="blue" loading={loader} onClick={this.handleClickSaveCsv}>Guardar</Button>
              <Button onClick={() => this.setState({ openUploadModal: false })}>{t('adminConfiguration.cancel')}</Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            {!requesting
              ? <Header as="h3" color="gray">{t('appleDEP.selectSeriesYouWant')}</Header> : ''}
            {requesting
              ? (
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  {loader ? (
                    <Dimmer active inverted>
                      <Loader active size="medium" />
                    </Dimmer>
                  ) : t('appleDEP.enterBillingNumber')}
                </Grid.Column>
              )
              : ''}
            <SerialsProducts
              billingData={filteredSerials}
              handleSelectSerial={this.handleSelectSerial}
              handleSelectAllSerials={this.handleSelectAllSerials}
              tr={t}
              handleGetFilterValue={this.handleGetFilterValue}
              currentActions={currentActions}
              isMultibrand={isMultibrand}
            />
          </Grid.Column>
        </Grid.Row>
        {billingData.length > 0 || isMultibrand
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={13} computer={13} tablet={13} mobile={16} textAlign="right">
                {isMultibrand
                  ? (
                    <Button
                      color="gray"
                      onClick={() => handleCancel(false)}
                    >
                      {t('appleDEP.cancel')}
                    </Button>
                  )
                  : ''}
              </Grid.Column>
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16} textAlign="right">
                <Button
                  color="blue"
                  onClick={() => this.handleValidateComponent()}
                  loading={buttonLoader}
                  size="medium"
                >
                  {t('Enviar solicitud')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          )
          : ''}
      </Grid>
    );
  }
}
AppleDep.defaultProps = {
  t: null,
  handleGetSerials: () => { },
  isMultibrand: false,
  limitGuarantees: null,
  maker: '',
  skuCarePack: null,
  handleCancel: () => { },
};
AppleDep.propTypes = {
  t: PropTypes.func,
  handleGetSerials: PropTypes.func,
  isMultibrand: PropTypes.bool,
  limitGuarantees: PropTypes.number,
  maker: PropTypes.string,
  skuCarePack: PropTypes.string,
  handleCancel: PropTypes.func,
};
export default translate('common', { wait: true })(AppleDep);
