import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Button, Modal, Grid } from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';
import { TextField } from '../../common/components/materials';

const MembersOptions = ({
  open, t, filter, filterError, handleFilterChange, data, headers, handleGetSelectedOptions, clean, updateClean, onAddClick, onCloseClick,
}) => (
  <Modal open={open}>
    <Modal.Header>{t('groups.options')}</Modal.Header>
    <Modal.Content>
      <Grid.Row>
        <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={6}>
          <TextField
            fullWidth
            maxLength={30}
            value={filter || ''}
            error={filterError || ''}
            label={t('groups.filter')}
            onChange={handleFilterChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row style={{ overflowX: 'auto' }}>
        <SiclikTable
          selectable
          data={data}
          headers={headers}
          pageSize={5}
          getSelectedRows={rows => handleGetSelectedOptions({ rows })}
          clean={clean}
          updateClean={updateClean}
        />
      </Grid.Row>
    </Modal.Content>
    <Modal.Actions>
      <Button color="blue" onClick={() => onAddClick()}>{t('add')}</Button>
      <Button onClick={(() => onCloseClick(false))}>{t('cancel')}</Button>
    </Modal.Actions>
  </Modal>
);

MembersOptions.propTypes = {
  clean: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filter: PropTypes.string.isRequired,
  filterError: PropTypes.string.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  handleGetSelectedOptions: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onAddClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updateClean: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(MembersOptions);
