import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

export function syncProductCatalog(body) {
  return request({
    route: apiRoutes.syncProductCatalog(),
    method: 'POST',
    body,
  });
}

export function syncIcecatProducts(body) {
  return request({
    route: apiRoutes.syncIcecatProducts(),
    method: 'POST',
    body,
  });
}

export function requestAllBrands() {
  return request({
    route: apiRoutes.getBrands(),
    method: 'GET',
  });
}

export function findProducts(keyword) {
  return request({
    route: apiRoutes.getAttributesByKeywords(keyword),
    method: 'GET',
  });
}
