import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import PropTypes from 'prop-types';
import {
  Grid, Header, Message, Table as TableSemantic, Input,
} from 'semantic-ui-react';
import DatePicker from '@material-ui/core/TextField';
import { TextField } from '../../common/components/materials';
import Table from '../../common/components/table';

const validateInput = (value, field, t) => {
  let error = '';
  if (field === 'discount' && value.length > 0) {
    if (value.length > 6) error = t('multibrand.errors.toLong');
    if (!validator.isFloat(value)) error = t('multibrand.errors.notNumeric');
    if (validator.isFloat(value) && Number(value) > 99.99) error = t('multibrand.errors.limitExceeded');
  }
  if (field === 'emailOwner' && value.length > 0) {
    if (!validator.isEmail(value)) error = t('multibrand.errors.notEmail');
    if (value.length > 40) error = t('multibrand.errors.toLong');
  }
  return error;
};

const InputControlled = props => {
  const {
    id = '', initialData = '', maxLength = 35, label = '',
    t, disabled, initialError = '', onChange, hint = '',
  } = props;
  const [localData, setData] = useState('');
  const [localError, setError] = useState('');

  useEffect(() => {
    setData(initialData);
    setError(initialError);
  }, [initialData, initialError]);

  const handleBlur = () => {
    onChange(localData, localError);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          onBlur={handleBlur}
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
        >
          <TextField
            fullWidth
            maxLength={maxLength}
            label={label}
            error={localError || ''}
            value={localData || ''}
            onChange={e => {
              setData(e.target.value);
              setError(validateInput(e.target.value, id, t));
            }}
            hint={hint}
            disabled={disabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

InputControlled.propTypes = {
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  id: PropTypes.string,
  initialData: PropTypes.string,
  initialError: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
};

InputControlled.defaultProps = {
  disabled: false,
  hint: '',
  id: '',
  initialData: '',
  initialError: '',
  label: '',
  maxLength: 35,
  onChange: () => {},
};

const ProductsRestrictions = props => {
  const {
    t, headers, discount, products, isQuotation, maker, emailOwner, showMessage, handleChangeDiscount, handleChangeEmailOwner,
    handleChangeItemProps, redhatError,
  } = props;

  return (
    <Grid container>
      <Grid.Row columns={1}>
        <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={15}>
          <Header>{`${maker} ${t('multibrand.productsAndRestrictions')}`}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        { maker === 'Microsoft'
          ? (
            <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15}>
              <InputControlled
                id="discount"
                maxLength={6}
                label={t('multibrand.discount')}
                initialError={discount.error}
                initialData={discount.value}
                onChange={handleChangeDiscount}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
          )
          : null }
        { maker !== 'Microsoft'
          ? (
            <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15}>
              <InputControlled
                id="emailOwner"
                maxLength={40}
                label={t('multibrand.emailOwner')}
                initialError={emailOwner.error}
                initialData={emailOwner.value}
                onChange={handleChangeEmailOwner}
                disabled={isQuotation}
                t={t}
              />
            </Grid.Column>
          )
          : null }
      </Grid.Row>
      {maker !== 'Microsoft' && showMessage
        ? (
          <Grid.Row>
            <Message color="yellow">
              <Message.Header>{t('multibrand.redHatMessage')}</Message.Header>
            </Message>
            {redhatError && (
              <Message color="red">
                En productos con renovación, es necesario indicar la fecha de inicio, fecha de fin de la suscripción e incluir el número de contrato.
              </Message>
            )}
          </Grid.Row>
        )
        : null}
      <Grid.Row columns={1}>
        <Grid.Column style={{ overflowX: 'auto' }}>
          {maker === 'Red Hat' ? (
            <TableSemantic>
              <TableSemantic.Header>
                <TableSemantic.Row>
                  {headers.filter(header => header?.name).map(header => (
                    <TableSemantic.HeaderCell key={header.key}>{header.name}</TableSemantic.HeaderCell>
                  ))}
                </TableSemantic.Row>
              </TableSemantic.Header>

              <TableSemantic.Body>
                {products.map(product => (
                  <TableSemantic.Row key={product.sku}>
                    <TableSemantic.Cell>{product.sku}</TableSemantic.Cell>
                    <TableSemantic.Cell>{product.name}</TableSemantic.Cell>
                    <TableSemantic.Cell>
                      $
                      {product.price}
                      {' '}
                      {product.currency}
                    </TableSemantic.Cell>
                    <TableSemantic.Cell>{product.quantity}</TableSemantic.Cell>
                    <TableSemantic.Cell>
                      <div
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ marginRight: '0.5em' }}>Fecha inicio:</span>
                        <DatePicker
                          type="date"
                          name="start"
                          format="dd/mm/yyyy"
                          defaultValue={product?.startDate}
                          value={product?.startDate}
                          onChange={(({ target }) => handleChangeItemProps(product.sku, 'startDate', target.value))}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ marginRight: '0.5em' }}>Fecha fin:</span>
                        <DatePicker
                          type="date"
                          name="start"
                          format="dd/mm/yyyy"
                          defaultValue={product?.endDate}
                          value={product?.endDate}
                          onChange={(({ target }) => handleChangeItemProps(product.sku, 'endDate', target.value))}
                          InputLabelProps={{ shrink: true }}
                        />
                      </div>
                    </TableSemantic.Cell>
                    <TableSemantic.Cell>
                      <Input
                        type="text"
                        name="Contrato"
                        defaultValue={product?.courseType}
                        onChange={(({ target }) => handleChangeItemProps(product.sku, 'courseType', target.value))}
                      />
                    </TableSemantic.Cell>
                  </TableSemantic.Row>
                ))}
              </TableSemantic.Body>
            </TableSemantic>
          ) : <Table headers={headers} data={products} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProductsRestrictions.defaultProps = {
  t: null,
  headers: [],
  discount: '',
  products: [],
  isQuotation: false,
  maker: '',
  emailOwner: '',
  showMessage: false,
  handleChangeDiscount: null,
  handleChangeEmailOwner: null,
  handleChangeItemProps: null,
  redhatError: false,
};

ProductsRestrictions.propTypes = {
  t: PropTypes.func,
  headers: PropTypes.arrayOf(),
  discount: PropTypes.number,
  products: PropTypes.arrayOf(),
  isQuotation: PropTypes.bool,
  redhatError: PropTypes.bool,
  maker: PropTypes.string,
  emailOwner: PropTypes.string,
  showMessage: PropTypes.bool,
  handleChangeDiscount: PropTypes.func,
  handleChangeEmailOwner: PropTypes.func,
  handleChangeItemProps: PropTypes.func,
};

export default translate('common', { wait: true })(ProductsRestrictions);
