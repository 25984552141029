import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import actions from './actions';
import listsReducer, { initialState } from './reducers';

const ItemsContext = createContext({});

export function useItems() {
  const contextValue = useContext(ItemsContext);
  if (!contextValue) {
    throw Error('El contexto de Items no fue encontrado');
  }
  return contextValue;
}

export default function ItemsProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(listsReducer, initialState);

  const value = {
    ...state.toJS(),
    updateQuantity: actions.updateQuantity(dispatch),
    removeItem: actions.removeItem(dispatch),
    sendItemToCart: actions.sendItemToCart(dispatch),
    getWishListItems: actions.getWishListItems(dispatch),
    setFilteredItems: actions.setFilteredItems(dispatch),
    addItemToWishList: actions.addItemToWishList(dispatch),
  };

  return (
    <ItemsContext.Provider value={value}>
      {children}
    </ItemsContext.Provider>
  );
}

ItemsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
