import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { translate } from 'react-i18next';
import zxcvbn from 'zxcvbn';
import validator from 'validator';

import Toast from '../../common/components/toast';
import { TextField } from '../../common/components/materials';
import routes from '../../route-names';
import userInformation from '../services';
import PasswordSecurity from '../../password-security/PasswordSecurity';

const { login } = routes;

class SetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPasswordError: '',
      showNewPassword: '',
      confirmPasswordError: '',
      showConfirmPassword: '',
      passwordScore: 0,
    };
  }

  validations = () => {
    let mistakes = false;
    const { t } = this.props;
    const {
      newPassword, confirmPassword, emailError, passwordScore,
    } = this.state;
    let { newPasswordError, confirmPasswordError } = this.state;

    if (!newPassword) {
      newPasswordError = t('profile.errors.empty');
      mistakes = true;
    }
    if (!confirmPassword) {
      confirmPasswordError = t('profile.errors.empty');
      mistakes = true;
    }
    if (newPassword !== confirmPassword) {
      mistakes = true;
      confirmPasswordError = t('profile.errors.passwordsDontMatch');
    }
    if (passwordScore < 3) {
      newPasswordError = t('profile.errors.securePass');
      mistakes = true;
    }
    if (!validator.isEmpty(newPasswordError)) { mistakes = true; }
    if (!validator.isEmpty(confirmPasswordError)) { mistakes = true; }
    if (mistakes === false) {
      this.handleUpdatePassword();
    } else {
      this.setState({ emailError, newPasswordError, confirmPasswordError });
    }
  }

  handleUpdatePassword = async () => {
    const { t, resetToken } = this.props;
    const { newPassword } = this.state;
    const password = newPassword;
    try {
      await userInformation.resetPassword(resetToken, password);
      Toast(t('login.passwordChanged'), 'success');
      setTimeout(() => this.goTologin(), 2000);
    } catch (error) {
      if (error.code === callError.users.INVALID_TOKEN) {
        Toast(t('login.tokenExpired'), 'error');
      } else Toast(t(`error-codes.${error.code}`), 'error');
    }
  };

  handleNewPasswordChange = event => {
    const { t } = this.props;
    const minLength = 8;
    let newPasswordError = '';
    const { value: newPassword } = event.target;
    this.securityScore(newPassword);
    if ((newPassword.trim()).length === 0) newPasswordError = t('profile.errors.empty');
    if (newPassword.length < minLength) newPasswordError = t('profile.errors.toShort');
    this.setState({ newPassword, newPasswordError });
  }

  securityScore = newPassword => {
    const securityResult = zxcvbn(newPassword);
    this.setState({ passwordScore: securityResult.score });
  }

  handleConfirmPasswordChange = event => {
    const { t } = this.props;
    const { value: confirmPassword } = event.target;
    let confirmPasswordError = '';
    const { newPassword } = this.state;
    if (newPassword !== confirmPassword) confirmPasswordError = t('profile.errors.passwordsDontMatch');
    if ((confirmPassword.trim()).length === 0) confirmPasswordError = t('profile.errors.empty');
    this.setState({ confirmPassword, confirmPasswordError });
  }

  validateEmailChange = () => {
    const { t } = this.props;
    const maxLength = 100;
    const minLength = 10;
    const { email } = this.state;
    if (!validator.isEmail(email)) {
      this.setState({
        email, emailError: t('profile.errors.notEmail'),
      }); return;
    }
    if (email.length > maxLength) {
      this.setState({ email, emailError: t('profile.errors.toLong') });
      return;
    }
    if (email.length < minLength) {
      this.setState({ email, emailError: t('profile.errors.toShort') });
      return;
    }
    this.setState({ email, emailError: '' });

    if (this.emailError === '') {
      userInformation.requestNewPassoword(email)
        .catch(error => this.setState({
          emailError: t(`user.errors.emailNotFound.${error.code}`),
        }));
    }
  }

  handleEmailChange = event => {
    const { value: email } = event.target;
    const maxLength = 100;
    const { t } = this.props;
    if (email.length > maxLength) { this.setState({ email, emailError: t('profile.errors.toLong') }); return; }
    if (email.length === 0) { this.setState({ email, emailError: t('login.error.empty') }); return; }
    this.setState({ email, emailError: '' });
  };

  goTologin = () => {
    window.location.assign(login.route);
  }

  render() {
    const { t } = this.props;
    const {
      newPassword, confirmPassword, newPasswordError, showNewPassword, confirmPasswordError, showConfirmPassword, passwordScore,
    } = this.state;
    return (

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header textAlign="center" as="h4">
              {t('login.setNewPassword')}
              {' '}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15}>
            <TextField
              fullWidth
              value={newPassword || ''}
              error={newPasswordError || ''}
              type="password"
              InputProps={{ autoComplete: 'new-password' }}
              showPassword={showNewPassword}
              label={t('profile.newPassword')}
              onChange={this.handleNewPasswordChange}
              required
            />
            <PasswordSecurity securityResult={passwordScore} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15}>
            <TextField
              fullWidth
              value={confirmPassword || ''}
              error={confirmPasswordError || ''}
              type="password"
              InputProps={{ autoComplete: 'new-password' }}
              showPassword={showConfirmPassword}
              label={t('profile.confirmPassword')}
              onChange={this.handleConfirmPasswordChange}
              required
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15}>
            <Button color="blue" fluid size="large" onClick={this.validations}>
              {t('login.changePassword')}
            </Button>
          </Grid.Column>
          <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={15} style={{ paddingTop: '3rem' }}>
            <Button
              color="lightBlack"
              fluid
              size="large"
              onClick={this.goTologin}
            >
              {' '}
              {t('login.backLogin')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    );
  }
}

SetPassword.propTypes = {
  resetToken: PropTypes.string,
};

SetPassword.defaultProps = {
  resetToken: '',
};

export default translate('common', { wait: true })(SetPassword);
