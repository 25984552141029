import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Dropdown, Icon, Form, Checkbox, Modal, Button, Header, Loader, Dimmer, Label,
} from 'semantic-ui-react';
import Toast from '../toast';
import * as session from '../../sessions';
import branchOfficeAddress from '../../../multi-shipping/services';
import services from './services';
import { currencyFormat, compareArray } from '../../utils';
import testData from '../../../multi-shipping/testDataMultiShipping';

import './styles.css';

const FREE_SHIPPING_SUBTOTAL = 10000;
const CS_MESSENGER_SERVICE = 1;
const UPS_SAVER = 2;
const PAQUETE_EXPRESS = 3;
const CUSTOMER_PICKS_UP = 4;
const FREIGHT_QUOTATION = 5;
const PLATINO_FREE_SHIPPING = 24;

class Quotation extends Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      warehouseCs: '',
      branchOfficesByCs: [],
      openModalQuotation: false,
      warehouseInformation: [],
      priceUps: '',
      upsInsurance: '',
      pricePE: '',
      priceCS: '',
      loader: true,
      insuredChecked: false,
      disabledInsuredChecked: false,
      currentParcel: false,
      requesting: false,
      origins: '',
      customerId,
      arrayWarehouse: [],
      classNameByTypeShipping: '',
      originsByLogisticsAgent: [],
      sameWarehouse: true,
      requestError: false,
      defaultBranch: '',
      upsSum: false,
      orderId: props.orderId,
      reviewsArray: [],
      segmentCustomer: '',
    };
  }

  componentDidMount() {
    this.handleGetParcelPrice();
    this.handleGetParcelValue();
    this.handleGetReviews();
    this.getSegment();
  }

  componentDidUpdate(prevProps) {
    const { skus, addressId, parcelValue } = this.props;
    const { defaultBranch } = this.state;
    if (!compareArray(prevProps.skus, skus) || addressId !== prevProps.addressId) {
      this.handleGetParcelPrice();
    }
    if (parcelValue !== prevProps.parcelValue) {
      this.handleUpdateParcelValue(parcelValue);
    }
    if (defaultBranch !== prevProps.defaultBranch) this.handleCustomerRequest();
  }

  handleUpdateParcelValue = parcelValue => {
    this.setState({ currentParcel: parcelValue });
  }

  getWarehouseByCs = origins => {
    let formatedArray = origins.filter(origin => origin.warehouse !== 'MI55');
    formatedArray = formatedArray.map(warehouse => {
      let text = '';
      if (warehouse.warehouse === 'MI01' || warehouse.warehouse === 'MI03') text = 'CS CDMX';
      if (warehouse.warehouse === 'GI01' || warehouse.warehouse === 'GI03') text = 'CS GDL';
      if (warehouse.warehouse === 'YI01' || warehouse.warehouse === 'YI03') text = 'CS Mty';
      return { key: warehouse.warehouse, value: warehouse.warehouse, text };
    });
    this.setState({ branchOfficesByCs: testData.branchOfficesByCs, arrayWarehouse: formatedArray });
  }

  getAvailableParcels = () => {
    const {
      arrayWarehouse, priceUps, pricePE, priceCS, sameWarehouse, segmentCustomer,
    } = this.state;
    const validations = [
      { courierId: CS_MESSENGER_SERVICE, visible: Number(priceCS) > 0 },
      { courierId: UPS_SAVER, visible: Number(priceUps) > 0 },
      { courierId: PAQUETE_EXPRESS, visible: Number(pricePE) > 0 },
      { courierId: CUSTOMER_PICKS_UP, visible: !(!sameWarehouse || arrayWarehouse.length === 0) },
      { courierId: FREIGHT_QUOTATION, visible: true },
      { courierId: PLATINO_FREE_SHIPPING, visible: segmentCustomer === 'Platino' },
    ];
    return validations
      .filter(parcel => parcel.visible)
      .map(parcel => parcel.courierId);
  }

  handleGetWarehouseForLogistics = origins => {
    const origin = origins.map(warehouse => {
      let text = '';
      if (warehouse.warehouse === 'MI01' || warehouse.warehouse === 'MI03') text = 'CS CDMX';
      if (warehouse.warehouse === 'GI01' || warehouse.warehouse === 'GI03') text = 'CS GDL';
      if (warehouse.warehouse === 'YI01' || warehouse.warehouse === 'YI03') text = 'CS Mty';
      if (warehouse.warehouse === 'MI55') text = 'CS Edo. Méx.';
      return { key: warehouse.warehouse, value: warehouse.warehouse, text };
    });
    this.setState({ branchOfficesByCs: testData.branchOfficesByCs, originsByLogisticsAgent: origin });
  }

  handleGetParcelPrice = async () => {
    const {
      skus, currencyPrice, exchangeRate, addressId, customerFromOrder, subtotal,
    } = this.props;
    if (skus.length > 0 && addressId !== '' && skus[0].quantity > 0) {
      const { tr } = this.props;
      let { customerId } = this.state;
      if (customerFromOrder !== undefined && customerFromOrder !== '') customerId = customerFromOrder;
      const body = {
        customerId,
        addressId,
        upsSafe: '1',
        skus,
      };
      this.setState({ loader: true, requesting: true });
      branchOfficeAddress.getParcelPrice(body)
        .then(response => {
          if (currencyPrice && currencyPrice === 'USD' && exchangeRate) {
            const { cash } = exchangeRate;
            this.setState({
              priceUps: response.priceUps / cash,
              upsInsurance: response.upsInsured / cash,
              pricePE: response.pricePE / cash,
              priceCS: (response.priceCS > 0 && (subtotal >= (FREE_SHIPPING_SUBTOTAL / cash))) ? 0.1 : response.priceCS / cash,
            });
          } else {
            this.setState({
              priceUps: response.priceUps,
              upsInsurance: response.upsInsured,
              pricePE: response.pricePE,
              priceCS: (response.priceCS > 0 && subtotal >= FREE_SHIPPING_SUBTOTAL) ? 0.1 : response.priceCS,
            });
          }
          this.setState({
            loader: false,
            requesting: false,
            origins: response.origin,
            requestError: false,
          });
          this.getWarehouseByCs(response.origin);
          this.handleGetWarehouseForLogistics(response.origin);
          this.handleSendDeliveryConfigBody(response.origin);
          this.handleFilterCustomerPicksUp(response.origin);
          this.handleValidateUpsInsurance();
        })
        .catch(error => {
          if (error.code !== 1005) {
            this.setState({ requesting: false, requestError: true });
            let messageError = '';
            if (!error.code) messageError = tr('error-codes.default');
            else messageError = tr(`error-codes.${error.code}`);
            Toast(messageError, 'error');
          }
        });
    }
  }

  handleCustomerRequest = () => {
    const { parcelValue, customerFromOrder, skus } = this.props;
    const { defaultBranch } = this.state;
    if (defaultBranch) {
      if (parcelValue === CUSTOMER_PICKS_UP) {
        const { tr } = this.props;
        let { customerId } = this.state;
        if (customerFromOrder !== undefined && customerFromOrder !== '') customerId = customerFromOrder;
        const body = {
          customerId,
          addressId: defaultBranch,
          upsSafe: '1',
          skus,
        };
        this.setState({ loader: true, requesting: true });
        branchOfficeAddress.getParcelPrice(body).then(response => {
          this.setState({
            priceUps: response.priceUps,
            upsInsurance: response.upsInsured,
            pricePE: response.pricePE,
            priceCS: response.priceCS,
            loader: false,
            requesting: false,
            origins: response.origin,
            requestError: false,
          });
          this.getWarehouseByCs(response.origin);
          this.handleGetWarehouseForLogistics(response.origin);
          this.handleSendDeliveryConfigBody(response.origin);
          this.handleFilterCustomerPicksUp(response.origin);
        })
          .catch(error => {
            this.setState({ requesting: false, requestError: true });
            let messageError = '';
            if (!error.code) messageError = tr('error-codes.default');
            else messageError = tr(`error-codes.${error.code}`);
            Toast(messageError, 'error');
          });
      }
    }
  }

  handleWarehouseCsAddressChange = (e, { value }) => {
    const { addressId, skus, response } = this.props;
    const { insuredChecked, currentParcel, origins } = this.state;
    this.setState({ warehouseCs: value });
    const body = {
      parcel: currentParcel,
      parcelPrice: 0,
      addressId,
      warehouseCs: value,
      UPSinsure: insuredChecked,
      skus,
      origins,
    };
    const availableParcels = this.getAvailableParcels();
    response(body, availableParcels);
  }

  handleGetBranchOfficesByCs = () => {
    const { branchOfficesByCs, warehouseCs } = this.state;
    const arrayInformation = branchOfficesByCs.filter(addressInfo => addressInfo.id === warehouseCs);
    this.setState({ warehouseInformation: arrayInformation });
  }

  handleQuotationChange = (e, { value, price }) => {
    const { warehouseCs, arrayWarehouse } = this.state;
    const { needInsuranceUps } = this.props;
    let defaultValue = '';
    if (value === CUSTOMER_PICKS_UP && warehouseCs === '') {
      const wharehouseValue = arrayWarehouse[0];
      defaultValue = wharehouseValue;
    }
    if (value === UPS_SAVER && needInsuranceUps === true) {
      this.setState({ insuredChecked: true, upsSum: true, disabledInsuredChecked: true }, () => {
        this.handleSendBodyFromQuotationChange(value, price, '');
      });
    } else if (value !== UPS_SAVER && needInsuranceUps === true) {
      this.setState({ insuredChecked: false }, () => {
        this.handleSendBodyFromQuotationChange(value, price, '');
      });
    }
    switch (value) {
      case CS_MESSENGER_SERVICE:
        this.setState({ currentParcel: value, warehouseCs: '' }, () => {
          this.handleSendBodyFromQuotationChange(value, price, '');
          this.handleValidateReview();
        });
        break;
      case UPS_SAVER:
        this.setState({ currentParcel: value, warehouseCs: '' }, () => {
          this.handleSendBodyFromQuotationChange(value, price, '');
          this.handleValidateReview();
        });
        break;
      case PAQUETE_EXPRESS:
        this.setState({ currentParcel: value, warehouseCs: '' }, () => {
          this.handleSendBodyFromQuotationChange(value, price, '');
          this.handleValidateReview();
        });
        break;
      case CUSTOMER_PICKS_UP:
        this.setState({ currentParcel: value }, () => {
          this.handleSendBodyFromQuotationChange(value, price, defaultValue.value);
          this.handleValidateReview();
        });
        break;
      case FREIGHT_QUOTATION:
        this.setState({ currentParcel: value, warehouseCs: '' }, () => {
          this.handleSendBodyFromQuotationChange(value, price, '');
        });
        break;
      case PLATINO_FREE_SHIPPING:
        this.setState({ currentParcel: value, warehouseCs: '' }, () => {
          this.handleSendBodyFromQuotationChange(value, price, '');
        });
        break;
      default: this.handleSendBodyFromQuotationChange(value, price, defaultValue.value);
    }
  }

  handleSendBodyFromQuotationChange = (parcel, price, warehouse) => {
    const {
      priceUps, upsInsurance, insuredChecked, currentParcel, origins, priceCS, pricePE, upsSum,
    } = this.state;
    const {
      needInsuranceUps, addressId, skus, response,
    } = this.props;
    let total = 0;
    if (currentParcel === UPS_SAVER && needInsuranceUps === true && insuredChecked === true) {
      total += Number(upsInsurance) + Number(priceUps);
      this.setState({ priceUps: total });
      const body = {
        parcel,
        parcelPrice: Number(upsInsurance) + Number(priceUps),
        addressId,
        warehouseCs: warehouse,
        UPSinsure: insuredChecked,
        skus,
        origins,
      };
      const availableParcels = this.getAvailableParcels();
      response(body, availableParcels);
    } else if (currentParcel !== UPS_SAVER && needInsuranceUps === true && insuredChecked === false) {
      let parcelPrice = price;
      switch (currentParcel) {
        case CS_MESSENGER_SERVICE:
          parcelPrice = priceCS;
          break;
        case PAQUETE_EXPRESS:
          parcelPrice = pricePE;
          break;
        case CUSTOMER_PICKS_UP:
          parcelPrice = 0;
          break;
        case FREIGHT_QUOTATION:
          parcelPrice = 0;
          break;
        case PLATINO_FREE_SHIPPING:
          parcelPrice = 0.1;
          break;
        default: parcelPrice = price;
      }
      if (upsSum === true) {
        total -= Number(upsInsurance) - Number(priceUps);
        this.setState({ priceUps: total, upsSum: false });
      }
      const body = {
        parcel,
        parcelPrice: Number(parcelPrice),
        addressId,
        warehouseCs: warehouse,
        UPSinsure: insuredChecked,
        skus,
        origins,
      };
      const availableParcels = this.getAvailableParcels();
      response(body, availableParcels);
    }
    if (needInsuranceUps === false) {
      const body = {
        parcel,
        parcelPrice: Number(price),
        addressId,
        warehouseCs: warehouse,
        UPSinsure: insuredChecked,
        skus,
        origins,
      };
      const availableParcels = this.getAvailableParcels();
      response(body, availableParcels);
    }
  }

  handleUpsInsured = (e, { checked, value }) => {
    const { priceUps, upsInsurance } = this.state;
    let total = 0;
    if (checked === true) {
      total += Number(upsInsurance) + Number(priceUps);
      this.setState({ priceUps: total, insuredChecked: true });
      this.handleSendUpsInsuredBody(total, checked);
    }
    if (checked === false || value !== 6) {
      total -= Number(upsInsurance) - Number(priceUps);
      this.setState({
        priceUps: total, insuredChecked: false, upsSum: false,
      });
      this.handleSendUpsInsuredBody(total, checked);
    }
  }

  handleGetParcelValue = () => {
    const { parcelValue, isSingle, warehouseValue } = this.props;
    let classname = '';
    if (parcelValue !== null) this.setState({ currentParcel: parcelValue });
    if (warehouseValue !== null) this.setState({ warehouseCs: warehouseValue });
    if (isSingle === true) {
      classname = '';
      this.setState({ classNameByTypeShipping: classname });
    } else {
      classname = 'block-form-parcel';
      this.setState({ classNameByTypeShipping: classname });
    }
  }

  handleSendDeliveryConfigBody = originsArray => {
    const {
      parcelValue, warehouseValue, needInsuranceUps, addressId, skus, response,
    } = this.props;
    const {
      priceUps, pricePE, priceCS, currentParcel, insuredChecked,
    } = this.state;
    const availableParcels = this.getAvailableParcels();
    if ((parcelValue !== null && needInsuranceUps === false) || (parcelValue === CUSTOMER_PICKS_UP && needInsuranceUps === true)) {
      let price = null;
      if (currentParcel === CS_MESSENGER_SERVICE) price = priceCS;
      if (currentParcel === UPS_SAVER) price = priceUps;
      if (currentParcel === PAQUETE_EXPRESS) price = pricePE;
      if (currentParcel === CUSTOMER_PICKS_UP || currentParcel === PLATINO_FREE_SHIPPING) price = 0;

      const body = {
        parcel: currentParcel,
        parcelPrice: Number(price),
        addressId,
        warehouseCs: warehouseValue,
        UPSinsure: insuredChecked,
        skus,
        origins: originsArray,
      };
      response(body, availableParcels);
    }
  }

  handleSendUpsInsuredBody = (price, checked) => {
    const {
      addressId, skus, response,
    } = this.props;
    const {
      origins, currentParcel,
      pricePE,
      priceCS,
    } = this.state;
    let parcelPrice = price;
    switch (currentParcel) {
      case CS_MESSENGER_SERVICE:
        parcelPrice = priceCS;
        break;
      case UPS_SAVER:
        parcelPrice = price;
        break;
      case PAQUETE_EXPRESS:
        parcelPrice = pricePE;
        break;
      case CUSTOMER_PICKS_UP:
        parcelPrice = 0;
        break;
      case FREIGHT_QUOTATION:
        parcelPrice = 0;
        break;
      case PLATINO_FREE_SHIPPING:
        parcelPrice = 0;
        break;
      default: parcelPrice = price;
    }
    const body = {
      parcel: currentParcel,
      parcelPrice: Number(parcelPrice),
      addressId,
      warehouseCs: '',
      UPSinsure: checked,
      skus,
      origins,
    };
    const availableParcels = this.getAvailableParcels();
    response(body, availableParcels);
  }

  handleFilterCustomerPicksUp = origin => {
    let isSameWarehouse = true;
    const allWarehouseOptions = origin.map(item => ({ warehouse: item.warehouse })).flat();
    for (let i = 0; i < allWarehouseOptions.length; i++) {
      if (allWarehouseOptions[i].warehouse !== allWarehouseOptions[0].warehouse) isSameWarehouse = false;
    }
    this.setState({ sameWarehouse: isSameWarehouse });
  }

  handleGetReviews = () => {
    const { orderId } = this.state;
    if (orderId !== '') {
      services.getReviews(orderId).then(response => {
        this.setState({ reviewsArray: response.orderReviews });
      })
        .catch(() => { });
    }
  }

  handleValidateReview = async () => {
    const { reviewsArray } = this.state;
    if (reviewsArray.length > 0) {
      const logisticReview = reviewsArray.filter(uen => uen.uen === 46);
      if (logisticReview.length > 0) {
        const id = Number(logisticReview.map(idReview => idReview.id));
        try {
          const deleteReview = await services.deleteReviewById({ reviewId: id });
          if (deleteReview.affectedRows === 1) this.handleGetReviews();
        } catch (error) {
          return error;
        }
      }
    }
    return null;
  }

  handleGetWarehouseValue = warehouseValue => {
    this.setState({ warehouseCs: warehouseValue });
  }

  handleValidateUpsInsurance = () => {
    const { needInsuranceUps, parcelValue } = this.props;
    const { priceUps } = this.state;
    if (needInsuranceUps === true && parcelValue === UPS_SAVER) {
      this.setState({ insuredChecked: true, upsSum: true, disabledInsuredChecked: true }, () => {
        this.handleSendBodyFromQuotationChange(UPS_SAVER, priceUps, '');
      });
    }
  }

  getSegment = async () => {
    const { customerId, segmentCustomer } = this.state;
    const { segment } = await services.getSegmentByCustomer(customerId);
    this.setState({ segmentCustomer: segment });
    return segmentCustomer;
  }

  render() {
    const {
      openModalQuotation, warehouseInformation, priceUps, pricePE, priceCS,
      insuredChecked, loader, currentParcel, requesting, classNameByTypeShipping, originsByLogisticsAgent,
      upsInsurance, requestError, disabledInsuredChecked, segmentCustomer,
    } = this.state;
    const { currencyPrice, getOrigins } = this.props;
    const currency = currencyPrice === 'Dolares' || currencyPrice === 'Dólares' || currencyPrice === 'USD' ? 'USD' : 'MXN';
    const { tr, isQuickCart } = this.props;
    return (
      <Grid>
        <Grid.Row>
          {loader
            ? (
              <Grid.Column width={16}>
                {
                  requesting
                    ? (
                      <Dimmer active inverted>
                        <Loader active size="medium">Cotizando envío ...</Loader>
                      </Dimmer>
                    ) : 'No se ha elegido la ubicación'
                }
                {requestError === true
                  ? <Header as="h5" color="black"><p>{tr('No se logró obtener los costos de envío debido a los datos proporcionados para esta sucursal, favor de contactar agente.')}</p></Header>
                  : ''}
              </Grid.Column>
            )
            : (
              <Grid.Column width={16}>
                <Form className={classNameByTypeShipping}>
                  {
                    Number(priceCS) > 0 ? (
                      <Form.Field>
                        <Checkbox
                          radio
                          label={tr('multi-shipping.parcelCS') + (priceCS === 0.1 ? 'Gratis' : currencyFormat(priceCS, currency))}
                          price={priceCS}
                          value={CS_MESSENGER_SERVICE}
                          checked={currentParcel === CS_MESSENGER_SERVICE}
                          onChange={this.handleQuotationChange}
                          disabled={segmentCustomer === 'Platino' ? 'disabled' : ''}
                        />
                      </Form.Field>
                    ) : ''
                  }
                  {
                    Number(priceUps) === 0 ? '' : (
                      <Form.Field>
                        <Checkbox
                          radio
                          label={tr('multi-shipping.parcelUps') + currencyFormat(priceUps, currency)}
                          price={priceUps}
                          value={UPS_SAVER}
                          checked={currentParcel === UPS_SAVER}
                          onChange={this.handleQuotationChange}
                          disabled={segmentCustomer === 'Platino' ? 'disabled' : ''}
                        />
                        <Checkbox
                          label={`${tr('multi-shipping.upsInsurance')} ${currencyFormat(upsInsurance, currency)}`}
                          value={6}
                          checked={insuredChecked}
                          onChange={this.handleUpsInsured}
                          disabled={segmentCustomer === 'Platino' ? 'disabled' : disabledInsuredChecked}
                        />
                      </Form.Field>
                    )
                  }
                  {
                    Number(pricePE) === 0 ? '' : (
                      <Form.Field>
                        <Checkbox
                          radio
                          label={tr('multi-shipping.parcelExpress') + currencyFormat(pricePE, currency)}
                          price={pricePE}
                          value={PAQUETE_EXPRESS}
                          checked={currentParcel === PAQUETE_EXPRESS}
                          onChange={this.handleQuotationChange}
                          disabled={segmentCustomer === 'Platino' ? 'disabled' : ''}
                        />
                      </Form.Field>
                    )
                  }
                  {isQuickCart ? '' : (
                    <Form.Field>
                      <Checkbox
                        radio
                        label={tr('multi-shipping.freightQuotation')}
                        price={0}
                        value={FREIGHT_QUOTATION}
                        checked={currentParcel === FREIGHT_QUOTATION}
                        onChange={this.handleQuotationChange}
                        disabled={segmentCustomer === 'Platino' ? 'disabled' : ''}
                      />
                    </Form.Field>
                  )}
                  {isQuickCart && Number(priceCS) === 0 && Number(priceUps) === 0 && Number(pricePE) === 0
                    ? 'No fue posible cotizar en alguna paqueteria'
                    : null}
                  {
                    getOrigins === true
                      ? (
                        <Form.Field>
                          <Grid.Column width={7}>
                            <Label pointing="right">Consultar origenes</Label>
                            &nbsp;&nbsp;
                            <Dropdown
                              options={originsByLogisticsAgent}
                              placeholder={tr('Origenes')}
                              compact
                              selection
                              defaultOpen
                            />
                          </Grid.Column>
                        </Form.Field>
                      )
                      : ''
                  }
                  {segmentCustomer === 'Platino'
                    ? (
                      <Form.Field>
                        <Checkbox
                          price={0.1}
                          value={PLATINO_FREE_SHIPPING}
                          checked={currentParcel === PLATINO_FREE_SHIPPING}
                          onChange={this.handleQuotationChange}
                        />
                        <p style={{ display: 'inline' }}>
                            &nbsp; Envío &nbsp;
                          <p style={{ display: 'inline', color: '#21ba45', fontWeight: 'bold' }}>
                            {tr('multi-shipping.free')}
                          </p>
                            &nbsp;(Nivel Platino)
                        </p>
                      </Form.Field>
                    )
                    : ''}
                  <Form.Field />
                </Form>
              </Grid.Column>
            )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Modal open={openModalQuotation} closeIcon size="small">
              <Icon name="window close" onClick={() => this.setState({ openModalQuotation: false })} />
              {warehouseInformation.map(branchOffice => (
                <Modal.Header>
                  {tr('multi-shipping.branchOffice')}
                  {' '}
                  {branchOffice.branchOfficeName}
                </Modal.Header>
              ))}
              <Modal.Content>
                {warehouseInformation.map(branchOffice => (
                  <Grid.Row>
                    <Header as="h5">
                      {tr('multi-shipping.branchOffice')}
                      {branchOffice.branchOfficeName}
                      {tr('multi-shipping.ubication')}
                    </Header>
                    <p>
                      {tr('multi-shipping.address')}
                      {' '}
                      {branchOffice.street}
                    </p>
                    <p>
                      {tr('multi-shipping.neighborhood')}
                      {' '}
                      {branchOffice.neighborhood}
                    </p>
                    <p>
                      {tr('multi-shipping.postalCode')}
                      {' '}
                      {branchOffice.postalCode}
                    </p>
                    <p>
                      {tr('multi-shipping.city')}
                      {' '}
                      {branchOffice.city}
                    </p>
                    <p>
                      {tr('multi-shipping.state')}
                      {' '}
                      {branchOffice.state}
                    </p>
                  </Grid.Row>
                ))}
              </Modal.Content>
              <Modal.Actions>
                <Button color="black" onClick={() => this.setState({ openModalQuotation: false })}>{tr('multi-shipping.exit')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
Quotation.defaultProps = {
  orderId: '',
  defaultBranch: '',
  currencyPrice: '',
  customerFromOrder: '',
  warehouseValue: '',
  needInsuranceUps: false,
  subtotal: 0,
};
Quotation.propTypes = {
  addressId: PropTypes.string.isRequired,
  currencyPrice: PropTypes.string,
  customerFromOrder: PropTypes.string,
  exchangeRate: PropTypes.shape({
    cash: PropTypes.number,
  }).isRequired,
  getOrigins: PropTypes.bool.isRequired,
  isQuickCart: PropTypes.bool.isRequired,
  isSingle: PropTypes.bool.isRequired,
  needInsuranceUps: PropTypes.bool,
  orderId: PropTypes.string,
  parcelValue: PropTypes.number.isRequired,
  response: PropTypes.func.isRequired,
  defaultBranch: PropTypes.string,
  skus: PropTypes.arrayOf(PropTypes.shape({
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  tr: PropTypes.func.isRequired,
  warehouseValue: PropTypes.string,
  subtotal: PropTypes.number,
};

export default translate('common', { wait: true })(Quotation);
