import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Message, Placeholder } from 'semantic-ui-react';

function setDescriptionFormat(description = '') {
  const paragraphs = description.split('\n')
    .join('~')
    .split('<br />')
    .join('~')
    .split('~');
  return paragraphs.map(paragraph => {
    if (paragraph.includes('<b>') || paragraph.includes('</b>')) {
      let cleanedParagraph = paragraph.replace('<b>', '');
      cleanedParagraph = cleanedParagraph.replace('</b>', '');
      return { type: 'title', paragraph: cleanedParagraph };
    }
    if (paragraph === '') {
      return { type: 'lineBreak', paragraph: null };
    }
    let cleanedParagraph = paragraph.replace('&nbsp;', '');
    cleanedParagraph = cleanedParagraph.replace('&bull;', '');
    return { type: 'text', paragraph: cleanedParagraph };
  });
}

function generateDescription(description) {
  return setDescriptionFormat(description).map(descriptionFormated => {
    if (descriptionFormated.type === 'title') {
      return (
        <Message.Header>
          {descriptionFormated.paragraph}
        </Message.Header>
      );
    }
    if (descriptionFormated.type === 'lineBreak') {
      return <br />;
    }
    return <Message.Content>{descriptionFormated.paragraph}</Message.Content>;
  });
}
function Description(props) {
  const { firstProduct, secondProduct } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          widescreen={8}
          computer={8}
          tablet={8}
          mobile={8}
        >
          <Message fluid>
            {firstProduct.hasDescription
              ? generateDescription(firstProduct.description)
              : (
                <Placeholder fluid>
                  <Placeholder.Paragraph>
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
          </Message>
        </Grid.Column>
        <Grid.Column
          widescreen={8}
          computer={8}
          tablet={8}
          mobile={8}
        >
          <Message fluid>
            {secondProduct.hasDescription
              ? generateDescription(secondProduct.description)
              : (
                <Placeholder fluid>
                  <Placeholder.Paragraph>
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                    <Placeholder.Line full />
                  </Placeholder.Paragraph>
                </Placeholder>
              )}
          </Message>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Description.propTypes = {
  firstProduct: PropTypes.shape({
    description: PropTypes.string,
    productName: PropTypes.string,
    hasDescription: PropTypes.string,
    mktDescription: PropTypes.string,
  }),
  secondProduct: PropTypes.shape({
    description: PropTypes.string,
    productName: PropTypes.string,
    hasDescription: PropTypes.string,
    mktDescription: PropTypes.string,
  }),
};

Description.defaultProps = {
  firstProduct: {},
  secondProduct: {},
};

export default translate('common', { wait: true })(Description);
