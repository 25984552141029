import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const branchOfficeAddress = {};
branchOfficeAddress.getBranchOfficeAddress = () => (
  request({
    route: apiRoutes.getBranchOfficeAddress(),
    method: 'GET',
  })
);
branchOfficeAddress.updateStep = body => (
  request({
    route: apiRoutes.updateStep(),
    method: 'PUT',
    body,
  })
);
branchOfficeAddress.disableBranchOffice = uuid => (
  request({
    route: apiRoutes.disableBranchOffice(uuid),
    method: 'DELETE',
  })
);
export default branchOfficeAddress;
