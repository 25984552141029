import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const InputControlled = props => {
  const {
    initialData = '', maxLength = 35, label = '', error = '', onChange, hint = '', required = false, disabled,
  } = props;
  const [localData, setData] = useState('');

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  const handleBlur = () => {
    onChange(localData);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          onBlur={handleBlur}
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
        >
          <TextField
            fullWidth
            maxLength={maxLength}
            label={label}
            error={error || ''}
            value={localData || ''}
            onChange={e => setData(e.target.value)}
            hint={hint}
            required={required}
            disabled={disabled}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

InputControlled.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  hint: PropTypes.string,
  initialData: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputControlled.defaultProps = {
  disabled: false,
  required: false,
  hint: '',
  error: '',
  initialData: '',
  label: '',
  maxLength: 35,
  onChange: () => { },
};

const Note = props => {
  const {
    t, note, handleChangeNote, isQuotation,
  } = props;
  return (
    <Grid container>
      <Grid.Row columns={1}>
        <Grid.Column largeScreen={11} computer={11} tablet={14} mobile={16}>
          <Header>{t('multibrand.note')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column largeScreen={11} computer={16} tablet={16} mobile={16}>
          <InputControlled
            maxLength={40}
            label={t('multibrand.note')}
            error={note.error}
            initialData={note.value}
            onChange={handleChangeNote}
            disabled={isQuotation}
            requiered
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Note.propTypes = {
  handleChangeNote: PropTypes.func,
  isQuotation: PropTypes.bool,
  note: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
};

Note.defaultProps = {
  handleChangeNote: () => { },
  isQuotation: false,
  note: {
    error: '',
    value: '',
  },
};

export default translate('common', { wait: true })(Note);
