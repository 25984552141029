import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Icon } from 'semantic-ui-react';

function SectorRow(props) {
  const {
    id, industry, industryId, percent, handleUpdate, handleDelete,
  } = props;
  return (
    <Grid.Row className="whiteBackground blackText">
      <Grid.Column
        largeScreen={10}
        computer={10}
        tablet={10}
        mobile={10}
        verticalAlign="middle"
        textAlign="right"
      >
        {industry}
      </Grid.Column>
      <Grid.Column
        largeScreen={3}
        computer={3}
        tablet={3}
        mobile={3}
        textAlign="center"
        verticalAlign="middle"
      >
        {`${percent}%`}
      </Grid.Column>
      <Grid.Column
        largeScreen={3}
        computer={3}
        tablet={3}
        mobile={3}
        textAlign="center"
        verticalAlign="middle"
      >
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column className="no-padding-x" verticalAlign="middle" textAlign="center">
              <Icon
                color="blue"
                link
                name="pencil alternate"
                onClick={() => handleUpdate({
                  id, industry, industryId, percent,
                })}
              />
            </Grid.Column>
            <Grid.Column className="no-padding-x" verticalAlign="middle" textAlign="center">
              <Icon color="blue" link name="trash alternate" onClick={() => handleDelete({ id })} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid.Row>
  );
}

SectorRow.propTypes = {
  industry: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  industryId: PropTypes.number,
  id: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func,
  handleDelete: PropTypes.func,
};

SectorRow.defaultProps = {
  handleUpdate: () => {},
  handleDelete: () => {},
  industryId: -1,
};

export default translate('common', { wait: true })(SectorRow);
