import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const services = {};

services.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

services.getAllListProducts = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

services.getCouponsByCustomerId = customerId => (
  request({
    route: apiRoutes.getCouponsByCustomerId(customerId),
    method: 'GET',
  })
);

services.getCouponsBySku = sku => (
  request({
    route: apiRoutes.getCouponsBySku(sku),
    method: 'GET',
  })
);

export default services;
