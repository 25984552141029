/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Loader, Container, Dropdown, Label, Icon,
} from 'semantic-ui-react';
import { TextField } from '../../../common/components/materials';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

class unmeasuredProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledTextField: false,
      report: [],
      loading: false,
      brandList: [],
      brandId: 0,
      selectedBrand: '',
      headers: [
        { key: 'sku', isKey: true },
        { key: 'brand', name: 'Marca' },
        { key: 'sku', name: 'Sku' },
        { key: 'description', name: 'Descripción' },
      ],
      quantityItems: 0,
      brandName: '',
    };
  }

  componentDidMount = () => {
    this.search();
    this.getBrands();
  }

  handleGetUnmeasuredProducts = async () => {
    const { t } = this.props;
    this.setState({ report: [], loading: true });
    try {
      const { products } = await service.getUnmeasuredProductReport();
      return (products.length > 0 ? { report: products, quantityItems: products.length } : { report: [] });
    } catch (error) {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      if (error.code === 1005) {
        Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
      } else {
        Toast(messageError, 'error');
      }
      return ({ report: [] });
    }
  };

  getBrands = async () => {
    const { brands } = await service.getBrands();
    brands.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      if (textA < textB) {
        return -1;
      }
      if (textA > textB) {
        return 1;
      }
      return 0;
    });
    const all = { id: 0, name: 'Todos', logo: 'Todos' };
    brands.unshift(all);
    const setBrandsFormat = brands.map(brand => ({
      key: brand.id,
      value: brand.name === 'Todos' ? '' : brand.name,
      text: brand.name,
    }));
    this.setState({ brandList: setBrandsFormat });
    this.setState({ quantityItems: brands.length });
  }

  search = async () => {
    const { report } = await this.handleGetUnmeasuredProducts();
    this.setState({
      report,
      loading: false,
      filteredResults: report,
      quantityItems: report.length,
    });
  };

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!filter) {
      this.setState({ filter, filteredResults: allReport, quantityItems: report.length });
    } else {
      const filteredResults = allReport.filter(filterInfo => ((filterInfo.sku && filterInfo.sku.toLowerCase().includes(baseFilter))
        || (filterInfo.brand && filterInfo.brand.toLowerCase().includes(baseFilter))
        || (filterInfo.description && filterInfo.description.toLowerCase().includes(baseFilter))));
      this.setState({
        filter,
        filteredResults,
        makerId: 0,
        quantityItems: filteredResults.length,
        brandName: '',
      });
    }
  };

  handleChangeBrand = (e, { value }) => {
    this.setState({ brandId: value });
    const baseFilter = value.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!value) {
      this.setState({
        value,
        filteredResults: allReport,
        quantityItems: allReport.length,
        brandName: '',
      });
    } else {
      const filteredResults = allReport.filter(filterInfo => (filterInfo.brand && filterInfo.brand.toLowerCase().includes(baseFilter)));
      this.setState({
        value,
        filteredResults,
        quantityItems: filteredResults.length,
        brandName: value,
      });
    }
  }

  render() {
    const { t } = this.props;
    const {
      report, headers, loading, filteredResults, filter, disabledTextField, brandList, brandId, quantityItems, brandName,
    } = this.state;
    const fileName = 'Reporte_productos_sin_medidas';

    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('intelligenceDashboard.unmeasuredProducts')}
          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={7} tablet={6} mobile={16}>
            <TextField
              fullWidth
              maxLength={40}
              value={filter || ''}
              label={t('intelligenceDashboard.filter')}
              onChange={this.handleFilter}
              disabled={disabledTextField}
            />
            <Label className="basic">
              <Icon name="circle notch" color="blue" />
              {`Total de productos ${brandName}: ${quantityItems}`}
            </Label>
          </Grid.Column>
          <Grid.Column computer={4} tablet={6} mobile={16}>
            <Dropdown
              placeholder={t('synchronization.brandList.placeholder')}
              options={brandList}
              value={brandId}
              onChange={this.handleChangeBrand}
              fluid
              selection
            />
          </Grid.Column>
          {(loading || !report || report.length === 0)
            ? null
            : (
              <Grid.Column computer={3} tablet={6} mobile={16} style={{ marginLeft: '7rem' }}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={filteredResults || report}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('intelligenceDashboard.download')}
                  sheetName="ProductosSinMedidas"
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  data={filteredResults || report}
                  headers={headers}
                  pageSize={15}
                />
              </Container>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(unmeasuredProducts);
