import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid, Header, Divider } from 'semantic-ui-react';
import Report from '../../dashboard/components/Report';
import services from '../../dashboard/services';
import Toast from '../../common/components/toast';

class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      embedType: 'report',
      tokenType: 'Embed',
      accessToken: '',
      embedUrl: 'https://app.powerbi.com/reportEmbed',
      embedId: process.env.REACT_APP_POWERBI_REPORT_ID,
      permissions: 'All',
    };
  }

  componentDidMount() {
    this.loadReport();
  }

  componentDidUpdate(prevProps) {
    const { customerId } = this.props;
    if (prevProps.customerId !== customerId) {
      this.loadReport();
    }
  }

  onLoadFilters = report => {
    const { customerId } = this.props;
    const filter = [{
      $schema: 'https://powerbi.com/product/schema#basic',
      target: {
        table: 'Cte',
        column: 'Cliente',
      },
      operator: 'In',
      values: [customerId.trim()],
    },
    ];
    report.setFilters(filter)
      .catch(errors => {
        this.setState({ loadFilter: false });
        return errors;
      });
    this.report = report;
  }

  setFullscreen = () => {
    if (this.report) this.report.fullscreen();
  }

  getPowerBIToken = () => {
    const { t } = this.props;
    services.getAccesTokenPowerBI()
      .then(response => {
        this.setState({ accessToken: response.token });
      }).catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  loadReport = () => {
    this.getPowerBIToken();
    this.setState({ loadFilter: true });
  }

  render() {
    const {
      embedType, tokenType, embedUrl, embedId, permissions, accessToken, loadFilter, filterPaneEnabled, navContentPaneEnabled,
    } = this.state;
    const extraSettings = {
      filterPaneEnabled: filterPaneEnabled === 'filter-true',
      navContentPaneEnabled: navContentPaneEnabled === 'nav-true',
    };
    const style = {
      report: {},
    };
    const { t } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4" color="blue">{t('Tablero')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider section />
        {!accessToken || !loadFilter
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
                <Header as="h4">{t('dashboard.loadingReport')}</Header>
              </Grid.Column>
            </Grid.Row>
          )
          : (
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
                <div className="PWBZone">
                  <div className="containerPWBI">
                    <Report
                      embedType={embedType}
                      tokenType={tokenType}
                      accessToken={accessToken}
                      embedUrl={embedUrl}
                      embedId={embedId}
                      extraSettings={extraSettings}
                      permissions={permissions}
                      style={style.report}
                      onLoad={this.onLoadFilters}
                    />
                    <div className="config">
                      <span className="interactions">
                        <button
                          type="button"
                          id="fullscreenButton"
                          className="interaction-btn"
                          onClick={this.setFullscreen}
                          aria-label="Set fullscreen"
                        >
                          <img src="images/full.png" alt="Activar pantalla completa" width="100%" />
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          )}
      </Grid>
    );
  }
}

Reports.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(Reports);
