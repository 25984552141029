import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Modal, Header, Grid, Button,
} from 'semantic-ui-react';
import DatePicker from '@material-ui/core/TextField';
import { TextField } from '../../common/components/materials';
import Toast from '../../common/components/toast';

class AddSerials extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.row.serials !== state.arraySerials) {
      return {
        arraySerials: props.row.serials,
        open: props.open,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      arraySerials: [],
      open: false,
      date: '',
      number: '',
    };
  }

  handleChangeSerial = (serial, id) => {
    const { arraySerials } = this.state;
    const indexSerialChanged = arraySerials.findIndex(s => s.id === id);
    arraySerials[indexSerialChanged].serial = serial;
    this.setState({ arraySerials });
  };

  handleInvoiceNumberChange = event => {
    const { value: number } = event.target;
    this.setState({ number, numberError: '' });
  }

  handleSaveSerials = () => {
    const { row, handleGetSerials, tr } = this.props;
    const { arraySerials, date, number } = this.state;
    const serials = [];
    const haveSerials = arraySerials.some(item => item.serial && item.serial.length > 0);
    if (!date || !haveSerials) {
      Toast(tr('multibrand.error.incompleteDataSerials'), 'warning');
    } else {
      arraySerials.map(serial => serials.push(serial.serial));
      const allSerials = {
        sku: row.sku, invoiceDate: date, invoiceNumber: number, serials,
      };
      handleGetSerials(allSerials);
      this.setState({ open: false });
    }
  }

  handleClose = () => {
    const { row, onClose } = this.props;
    onClose(row.sku);
    this.setState({ open: false });
  }

  handleDateChange = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  render() {
    const { tr } = this.props;
    const {
      arraySerials, open, date, dateError, number, numberError,
    } = this.state;
    return (
      <Modal open={open} size="tiny">
        <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{tr('multibrand.addSerials')}</Header>} />
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={20}
                  label={tr('multibrand.invoiceNumber')}
                  error={numberError || ''}
                  value={number || ''}
                  onChange={this.handleInvoiceNumberChange}
                />
              </Grid.Column>
              <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                <Header className="no-margin-bottom" as="h5">{tr('multibrand.invoiceDate')}</Header>
                <DatePicker
                  type="date"
                  name="date"
                  format="dd/mm/yyyy"
                  value={date}
                  onChange={this.handleDateChange}
                  InputLabelProps={{ shrink: true }}
                  error={dateError}
                />
              </Grid.Column>
            </Grid.Row>
            {arraySerials ? arraySerials.map(serial => (
              <Grid.Row>
                <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16}>
                  <TextField
                    fullWidth
                    maxLength={20}
                    label={`${tr('multibrand.serialNumber')} ${serial.id}`}
                    error={serial.error || ''}
                    value={serial.serial || ''}
                    onChange={e => this.handleChangeSerial(e.target.value, serial.id)}
                  />
                </Grid.Column>
              </Grid.Row>
            )) : null}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleSaveSerials} color="blue">{tr('save')}</Button>
          <Button
            onClick={this.handleClose}
          >
            {tr('cancel')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AddSerials.defaultProps = {
  row: [],
  productsByMaker: { microsoft: [], redHat: [] },
  handleGetSerials: null,
  tr: null,
  onClose: null,
  open: null,
};

AddSerials.propTypes = {
  handleGetSerials: PropTypes.func,
  onClose: PropTypes.func,
  productsByMaker: PropTypes.shape({
    microsoft: PropTypes.arrayOf(PropTypes.shape({})),
    redHat: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  row: PropTypes.arrayOf(PropTypes.shape({})),
  tr: PropTypes.func,
  open: PropTypes.bool,
};

export default translate('common', { wait: true })(AddSerials);
