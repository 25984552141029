/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ThemeProvider,
  makeStyles,
  createTheme,
} from '@material-ui/core/styles';
import { Icon } from 'semantic-ui-react';
import MenuItem from '@material-ui/core/MenuItem';
import MaterialTextField from '@material-ui/core/TextField';
import { Grid, InputAdornment } from '@material-ui/core';
import getColors from '../constants';
import { EMPTY_HELPER_CODE } from './constants';

const COLORS = getColors();

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: props => {
    const style = {
      margin: theme.spacing(0),
      ...props,
    };
    Object.keys(style).forEach(key => (
      (typeof style[key] === 'undefined' || style[key] === null) && delete style[key]
    ));
    return style;
  },
}));

const theme = color => createTheme({
  typography: {
    fontFamily: [
      'News Gothic MT',
      'sans-serif',
    ].join(','),
    fontSize: 14,
  },
  palette: {
    primary: COLORS[color],
  },
});

function HelperText(helperProps, maxLength, length) {
  const { children: helperText, ...restProps } = helperProps;
  const sanitizedText = helperText.replace(EMPTY_HELPER_CODE, '');
  const maxLengthError = maxLength && (length > maxLength)
    ? 'La longitud del texto es mayor al límite permitido.'
    : '';
  const textToShow = maxLengthError || sanitizedText || '';
  return (!maxLength
    ? <p {...restProps}>{textToShow}</p>
    : (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="baseline"
      >
        <Grid item xs={10}>
          <p {...restProps}>{textToShow}</p>
        </Grid>
        <Grid item xs={2}>
          <p {...restProps} style={{ textAlign: 'right' }}>{`${length}/${maxLength}`}</p>
        </Grid>
      </Grid>
    )
  );
}

function getLength(value) {
  const type = typeof value;
  if (type === 'number' || type === 'string') return String(value).length;
  return 0;
}

function TextField(props) {
  const {
    style = {},
    className = '',
    color = 'gray',
    select,
    options = [],
    error,
    maxLength,
    value,
    helperText,
    onChange,
    InputProps,
    rightIcon,
    iconChange,
    disabled = false,
    ...textProps
  } = props;

  const [localValue, setValue] = useState('');

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = event => {
    if (select) onChange(event);
    else {
      setValue(event.target.value);
      onChange(event);
    }
  };

  const classes = useStyles(style);
  const valueLength = getLength(localValue);
  const hasError = (maxLength && (valueLength > maxLength)) || error;
  const needCount = maxLength ? EMPTY_HELPER_CODE : '';
  return (
    <ThemeProvider theme={theme(color)}>
      <MaterialTextField
        FormHelperTextProps={{
          component: helperProps => HelperText(helperProps, maxLength, valueLength),
        }}
        {...textProps}
        select={select}
        InputProps={{
          ...InputProps,
          endAdornment: rightIcon
            ? (
              <InputAdornment position="end">
                <Icon name={rightIcon} link onClick={iconChange} />
              </InputAdornment>
            )
            : undefined,
        }}
        disabled={disabled}
        error={!!hasError}
        helperText={error || helperText || needCount}
        onChange={handleChange}
        value={select ? value : localValue}
        className={`${classes.textField} ${className}`}
      >
        {
          select
            ? options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))
            : null
        }
      </MaterialTextField>
    </ThemeProvider>
  );
}

TextField.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  style: PropTypes.objectOf(PropTypes.string),
  InputProps: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
  color: PropTypes.string,
  select: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  rightIcon: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  iconChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
};

TextField.defaultProps = {
  style: {},
  className: '',
  color: '',
  select: false,
  options: [],
  error: '',
  helperText: '',
  rightIcon: '',
  maxLength: 0,
  InputProps: {},
  disabled: false,
  iconChange: () => {},
};

export default TextField;
