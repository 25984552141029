import React, { useState } from 'react';
import {
  Modal, Message, Icon, Button, Item, Form,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { callError } from '@compusoluciones-pdigitales/status-codes';

import * as sessions from '../../../common/sessions';
import toast from '../../../common/components/toast';

function GuestUserConfirm(props) {
  const [loadSession, setLoadSession] = useState(false);
  const [forbiddenAccess, setForbiddenAccess] = useState(false);

  const {
    t, open, selectedUser, customerId, onClose,
  } = props;

  const handleConfirm = async () => {
    setLoadSession(true);
    try {
      await sessions.getGuestSession(selectedUser.id, customerId);
      const { location } = window;
      location.replace(`${location.protocol}//${location.hostname}:${location.port}/comercio`);
    } catch (error) {
      setLoadSession(false);
      if (error.code === callError.general.FORBIDDEN_ACCESS) {
        setForbiddenAccess(true);
      } else {
        setForbiddenAccess(false);
        toast(t(`shoppingCart.errors.${error.code}`), 'error');
      }
    }
  };

  const handleCancel = () => {
    setLoadSession(false);
    setForbiddenAccess(false);
    onClose();
  };

  function LoadingSession({ active }) {
    return (
      <Message icon hidden={active}>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>{t('customerDirectory.guestUser.loading.header')}</Message.Header>
          {t('customerDirectory.guestUser.loading.description')}
        </Message.Content>
      </Message>
    );
  }

  LoadingSession.propTypes = {
    active: PropTypes.bool.isRequired,
  };

  function ForbiddenMessage({ active }) {
    return (
      <Message icon hidden={!active} floating error>
        <Icon name="ban" />
        <Message.Content>
          <Message.Header>{t('customerDirectory.guestUser.forbidden.header')}</Message.Header>
          {t('customerDirectory.guestUser.forbidden.description')}
        </Message.Content>
      </Message>
    );
  }

  ForbiddenMessage.propTypes = {
    active: PropTypes.bool.isRequired,
  };

  function ContentMessage({ user, customerId: propsCustomerId, active }) {
    const defaultImage = `${process.env.REACT_APP_URL_AWS}/imagenes/defaultAvatar.png`;
    const userImage = user.image === '' ? defaultImage : user.image;
    return (
      <div hidden={!active}>
        <Form className="attached segment">
          <Item.Group>
            <Item>
              <Item.Image src={userImage} size="tiny" />
              <Item.Content>
                <Item.Header>
                  {user.name}
                  {' '}
                  {user.surname}
                </Item.Header>
                <Item.Meta>
                  <span className="price">{user.email}</span>
                </Item.Meta>
                <Item.Description>{propsCustomerId}</Item.Description>
              </Item.Content>
            </Item>
          </Item.Group>
        </Form>
        <Message attached="bottom">
          <p>{t('customerDirectory.guestUser.card.messageOne')}</p>
          <p>{t('customerDirectory.guestUser.card.messageTwo')}</p>
        </Message>
      </div>
    );
  }

  ContentMessage.propTypes = {
    active: PropTypes.bool.isRequired,
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }).isRequired,
    customerId: PropTypes.string.isRequired,
  };

  return (
    <Modal open={open} closeOnEscape={open} size="tiny">
      <Modal.Header>{t('customerDirectory.guestUser.header')}</Modal.Header>
      <Modal.Content>
        <LoadingSession active={!loadSession} />
        <ContentMessage
          active={!loadSession && !forbiddenAccess}
          user={selectedUser}
          customerId={customerId}
        />
        <ForbiddenMessage active={forbiddenAccess} />
      </Modal.Content>
      <Modal.Actions>
        <Button.Group>
          <Button
            content={t('cancel')}
            onClick={handleCancel}
          />
          <Button
            content={t('continue')}
            onClick={handleConfirm}
            color="blue"
            disabled={forbiddenAccess}
          />
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
}

GuestUserConfirm.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedUser: PropTypes.shape({
    id: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(GuestUserConfirm);
