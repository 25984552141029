import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const orders = {};

orders.getOrdersByUser = userId => (
  request({
    route: apiRoutes.getOrdersByUser(userId),
    method: 'GET',
  })
);

orders.getOrdersStatus = () => (
  request({
    route: apiRoutes.getOrdersStatus(),
    method: 'GET',
  })
);

orders.getUsers = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
    method: 'GET',
  })
);

export default orders;
