import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid, Message } from 'semantic-ui-react';
import '../styles.css';

const Features = ({ features }) => (
  <Grid padded>
    <Grid.Row computer={16} tablet={5} mobile={6}>
      <Grid.Column>
        <Message fluid>
          {features.map(feature => (
            <Grid.Column key={feature.title}>
              <Message.Header>
                {feature.title}
              </Message.Header>
              <p>
                {feature.values}
              </p>
              <br />
            </Grid.Column>
          ))}
        </Message>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

Features.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default translate('common', { wait: true })(Features);
