const bucket = `${process.env.REACT_APP_URL_AWS}`;
const assetUrl = `${bucket}/imagenes/assets/siclik/`;
const brandsUrl = `${bucket}/imagenes/assets/brands/`;

const comercio = `${assetUrl}siclikComercio.png`;
const xaas = `${assetUrl}siclikXaas.png`;
const suscribe = `${assetUrl}siclikSuscribe.png`;
const cesa = `${assetUrl}siclik-CESA.png`;

const bannerData = [
  {
    title: '',
    background: {
      web: `${assetUrl}siclik_campanadirectores_web.png`,
      tablet: `${assetUrl}siclik_campanadirectores_tablet.png`,
      mobile: `${assetUrl}siclik_campanadirectores_mobile.png`,
    },
    points: [],
  },
  {
    title: '',
    background: {
      web: `${assetUrl}siclik_portafolioSoluciones_web.png`,
      tablet: `${assetUrl}siclik_portafolioSoluciones_tablet.png`,
      mobile: `${assetUrl}siclik_portafolioSoluciones_movil.png`,
    },
    points: [],
  },
  {
    title: 'Todo tu negocio en línea',
    background: {
      web: `${assetUrl}siclik_header1_web.png`,
      tablet: `${assetUrl}siclik_header1_tablet.png`,
      mobile: `${assetUrl}siclik_header1_mobile.png`,
    },
    points: ['Consulta el crecimiento de tu empresa, movimientos, inventario y más desde una sola plataforma.'],
  },
  {
    title: '',
    background: {
      web: `${assetUrl}siclik_header2_web.png`,
      tablet: `${assetUrl}siclik_header2_tablet.png`,
      mobile: `${assetUrl}siclik_header2_mobile.png`,
    },
    points: [],
  },
  {
    title: 'Vive la experiencia digital',
    background: {
      web: `${assetUrl}siclik_header3_web.png`,
      tablet: `${assetUrl}siclik_header3_tablet.png`,
      mobile: `${assetUrl}siclik_header3_mobile.png`,
    },
    points: ['Únete a la cadena de distribuidores que han confiado en siclik CompuSoluciones.'],
  },
  {
    title: 'Una plataforma para cada negocio',
    background: {
      web: `${assetUrl}siclik_header4_web.png`,
      tablet: `${assetUrl}siclik_header4_tablet.png`,
      mobile: `${assetUrl}siclik_header4_mobile.png`,
    },
    points: ['Diferentes comercios electrónicos según la necesidad de tu cliente; hardware, software, suscripciones, arrendamiento de equipos o cualquier servicio de tecnología.'],
  },
];

const appData = [
  {
    id: 1,
    image: comercio,
    sideImage: `${assetUrl}comercio.png`,
    description: 'Comercio electrónico donde puedes comprar equipos de cómputo, impresión, redes, accesorios, complementos y licencias de software perpetuo de todas las marcas que representamos en México.',
    redirect: '/login/comercio',
    redirectWeb: '',
    buttonLabel: 'Visitar sitio',
    color: 'blue',
  },
  {
    id: 2,
    image: suscribe,
    sideImage: `${assetUrl}suscribe.png`,
    description: 'Compra y administra las suscripciones de software, puedes comprar tus licencias como servicio. También encontrarás suscripciones especializado por vertical.',
    redirect: '',
    redirectWeb: 'https://clicksuscribe.compusoluciones.com/#/Login',
    buttonLabel: 'Visitar sitio',
    color: 'green',
  },
  {
    id: 3,
    image: xaas,
    sideImage: `${assetUrl}xass.png`,
    description: 'Habilita el ecosistema de tecnología como servicio, incluye arrendamiento de equipo de cómputo e impresión y todo lo que puedas imaginar en una modalidad de servicios administrados.',
    redirect: '',
    redirectWeb: 'https://www.siclik.mx/directorio-agentes',
    buttonLabel: 'Consultar agente',
    color: 'red',
  },
  {
    id: 4,
    image: cesa,
    sideImage: `${assetUrl}cesa.png`,
    description: 'Es la plataforma de gestión de solicitudes para los servicios administrados. Proporciona un punto único de contacto entre '
    + 'distribuidores, usuarios finales e ingenieros de soporte con la finalidad de gestionar y solucionar todas las posibles incidencias de manera integral.',
    redirectWeb: 'http://cesa.siclik.mx/',
    buttonLabel: 'Visitar sitio',
    color: 'red',
  },
];

const strategic = [
  {
    id: 1,
    image: `${assetUrl}productividad.png`,
    title: 'PRODUCTIVIDAD',
    description: 'Trabaja en una sóla plataforma, facilitando procesos y acceso a la información.',
    color: 'yellow',
  },
  {
    id: 4,
    image: `${assetUrl}negocio.png`,
    title: 'NEGOCIO',
    description: 'Alcanza nuevos canales de venta y optimiza estrategias comerciales, mejorando la experiencia del cliente',
    color: 'blue',
  },
  {
    id: 3,
    image: `${assetUrl}ahorro.png`,
    title: 'AHORRO',
    description: 'Reduce costes para la empresa en tiempo y dinero, haciendo más eficientes los procesos e infraestructura.',
    color: 'green',
  },
  {
    id: 2,
    image: `${assetUrl}conectividad.png`,
    title: 'CONECTIVIDAD',
    description: 'Conexión con varios usuarios logrando una interacción más fluida y funcional entre equipos de trabajo.',
    color: 'red',
  },
];

const solutions = [
  {
    id: 1,
    image: `${assetUrl}siclik_solucionesdenicho_640x627px_rhynomina.png`,
    title: 'RH Y NÓMINA',
    description: 'Fortalece la gestión del manejo de tus colaboradores con programas que ayudan a transformar los procesos a efectivos e innovadores.',
    redirect: 'https://www.compusoluciones.com/soluciones/soluciones-de-nicho/',
    buttonLabel: 'CONOCE MÁS',
    color: 'blue',
  },
  {
    id: 2,
    image: `${assetUrl}siclik_solucionesdenicho_640x627px_productividad.png`,
    title: 'PRODUCTIVIDAD',
    description: 'Con la ayuda de la nube consigue innovación empresarial en tus operaciones, reduce costes y haz crecer tu negocio.',
    redirect: 'https://www.compusoluciones.com/soluciones/soluciones-de-nicho/',
    buttonLabel: 'CONOCE MÁS',
    color: 'red',
  },
  {
    id: 3,
    image: `${assetUrl}siclik_solucionesdenicho_640x627px_educacion.png`,
    title: 'EDUCACIÓN',
    description: 'Tecnología que impulsa el aprendizaje según los desafíos de las instituciones educativas.',
    redirect: 'https://www.compusoluciones.com/soluciones/soluciones-de-nicho/',
    buttonLabel: 'CONOCE MÁS',
    color: 'green',
  },
];

const assets = {
  hpeImage: `${bucket}/imagenes/banners/tecnologiaaldia.png`,
  comercio,
  xaas,
  cesa,
  suscribe,
  appData,
  strategic,
  solutions,
  bannerData,
  backgrounds: {
    first: {
      web: `${assetUrl}siclik_header1_web.png`,
      tablet: `${assetUrl}siclik_header1_tablet.png`,
      mobile: `${assetUrl}siclik_header1_mobile.png`,
    },
    second: {
      web: `${assetUrl}siclik_header2_web.png`,
      tablet: `${assetUrl}siclik_header2_tablet.png`,
      mobile: `${assetUrl}siclik_header2_mobile.png`,
    },
    third: {
      web: `${assetUrl}siclik_header3_web.png`,
      tablet: `${assetUrl}siclik_header3_tablet.png`,
      mobile: `${assetUrl}siclik_header3_mobile.png`,
    },
  },
  brands: [
    {
      image: `${brandsUrl}microsoft.png`,
      text: 'microsoft',
      redirect: { url: '/productos?type=brand&value=microsoft', local: true },
    },
    {
      image: `${brandsUrl}hp.png`,
      text: 'hp',
      size: 'tiny',
      redirect: { url: '/productos?type=brand&value=hp', local: true },
    },
    {
      image: `${brandsUrl}hpe.svg`,
      text: 'hpe',
      redirect: { url: '/productos?type=brand&value=hpe', local: true },
    },
    {
      image: `${brandsUrl}aruba.png`,
      text: 'aruba',
      redirect: { url: '/productos?type=brand&value=aruba', local: true },
    },
    {
      image: `${brandsUrl}lenovo.png`,
      text: 'lenovo',
      redirect: { url: '/productos?type=brand&value=lenovo', local: true },
    },
    {
      image: `${brandsUrl}logitech.png`,
      text: 'logitech',
      redirect: { url: '/productos?type=brand&value=logitech', local: true },
    },
    {
      image: `${brandsUrl}veeam.png`,
      text: 'veeam',
      redirect: { url: '/productos?type=brand&value=veeam', local: true },
    },
    {
      image: `${brandsUrl}logos/trellix-logo.png`,
      text: 'Trellix',
      redirect: { url: '/productos?type=brand&value=trellix', local: true },
    },
    {
      image: `${brandsUrl}apple.png`,
      text: 'apple',
      redirect: { url: '/productos?type=brand&value=apple', local: true },
    },
    {
      image: `${brandsUrl}perfectchoice.png`,
      text: 'perfectchoice',
      redirect: { url: '/productos?type=brand&value=perfectchoice', local: true },
    },
    {
      image: `${brandsUrl}techzone.png`,
      text: 'techzone',
      redirect: { url: '/productos?type=brand&value=techzone', local: true },
    },
    {
      image: `${brandsUrl}tripplite.png`,
      text: 'tripplite',
      redirect: { url: '/productos?type=brand&value=tripplite', local: true },
    },
    {
      image: `${brandsUrl}actiontec.png`,
      text: 'actiontec',
      redirect: { url: '/productos?type=brand&value=actiontec', local: true },
    },
    {
      image: `${brandsUrl}redhat.png`,
      text: 'redhat',
      redirect: { url: '/productos?type=brand&value=redhat', local: true },
    },
    {
      image: `${brandsUrl}logos/vmware.png`,
      text: 'vmware',
      redirect: { url: '/productos?type=brand&value=vmware', local: true },
    },
  ],
  banner1M: `${assetUrl}banner1-mobile.jpg`,
  banner1T: `${assetUrl}banner1-tablet.jpg`,
  banner1W: `${assetUrl}banner1-web.jpg`,
  banner2M: `${assetUrl}banner2-mobile.jpg`,
  banner2T: `${assetUrl}banner2-tablet.jpg`,
  banner2W: `${assetUrl}banner2-web.jpg`,
  banner3M: `${assetUrl}banner3-mobile.jpg`,
  banner3T: `${assetUrl}banner3-tablet.jpg`,
  banner3W: `${assetUrl}banner3-web.jpg`,
  services: [
    {
      imageMobile: `${assetUrl}img1-mobile.jpg`,
      imageTablet: `${assetUrl}img1-tablet.jpg`,
      imageWeb: `${assetUrl}img1-web.jpg`,
      text: 'TENDENCIAS',
      buttonText: 'VER BLOG',
      redirect: { url: 'https://www.compusoluciones.com/blog/', local: false },
    },
    {
      imageMobile: `${assetUrl}img2-mobile.jpg`,
      imageTablet: `${assetUrl}img2-tablet.jpg`,
      imageWeb: `${assetUrl}img2-web.jpg`,
      text: 'SOLUCIONES DE NICHO',
      buttonText: 'VER PORTAFOLIO',
      redirect: { url: 'https://www.compusoluciones.com/portafolio-de-soluciones/', local: false },
    },
    {
      imageMobile: `${assetUrl}img4-mobile.jpg`,
      imageTablet: `${assetUrl}img4-tablet.jpg`,
      imageWeb: `${assetUrl}img4-web.jpg`,
      text: 'CONECTA TU NEGOCIO EN LÍNEA',
      buttonText: 'SABER MÁS',
      redirect: { url: 'https://mkt.compusoluciones.com/e/ConstantContact/2020/PlataformasDigitales/API.pdf', local: false },
    },
    {
      imageMobile: `${assetUrl}img3-mobile.jpg`,
      imageTablet: `${assetUrl}img3-tablet.jpg`,
      imageWeb: `${assetUrl}img3-web.jpg`,
      text: 'CESA',
      buttonText: 'INGRESA AQUÍ',
      redirect: { url: 'https://cesa.siclik.mx/', local: false },
    },
  ],
  extraBanner: {
    // imageMobile: `${assetUrl}siclik_2doAniversario_300x79px.png`,
    // imageTablet: `${assetUrl}siclik_2doAniversario_768x203px.png`,
    // imageWeb: `${assetUrl}siclik_2doAniversario_1920x508px.png`,
  },
  img1M: `${assetUrl}img1-mobile.jpg`,
  img1T: `${assetUrl}img1-tablet.jpg`,
  img1W: `${assetUrl}img1-web.jpg`,
  img2M: `${assetUrl}img2-mobile.jpg`,
  img2T: `${assetUrl}img2-tablet.jpg`,
  img2W: `${assetUrl}img2-web.jpg`,
  img3M: `${assetUrl}img3-mobile.jpg`,
  img3T: `${assetUrl}img3-tablet.jpg`,
  img3W: `${assetUrl}img3-web.jpg`,
  img4M: `${assetUrl}img4-mobile.jpg`,
  img4T: `${assetUrl}img4-tablet.jpg`,
  img4W: `${assetUrl}img4-web.jpg`,
};

export default assets;
