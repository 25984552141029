export const styleHeader = {
  font: { sz: 12, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: '00549F' } },
};

export const sheetHeaders = {
  customerId: { header: 'id Distribuidor', width: 16 },
  customerName: { header: ' Distribuidor', width: 30 },
  level: { header: 'Nivel', width: 16 },
  agent: { header: 'Agente', width: 20 },
  maker: { header: 'Marca', width: 17 },
  email: { header: 'Correo', width: 30 },
  cellPhone: { header: 'Teléfono', width: 20 },
};

export const styleRow = {
  font: { sz: 9, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
