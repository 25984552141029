export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};

export const sheetHeaders = {
  sku: { header: 'SKU', width: 25 },
  description: { header: 'Descripcion', width: 50 },
  brand: { header: 'Marca', width: 20 },
  family: { header: 'Familia', width: 25 },
  category: { header: 'Categoria', width: 25 },
  manualTags: { header: 'Tags manuales', width: 70 },
  aiTags: { header: 'Tags generados', width: 70 },
  total: { header: 'Total', width: 12 },
};

export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
