import { request } from '../../fetch-utils';
import apiRoutes from '../../../apiRoutes';

const service = {};

service.getNotifications = () => (
  request({
    route: apiRoutes.getUserNotifications(),
  })
);

service.getNotificationsCount = () => (
  request({
    route: apiRoutes.getNotificationsUnreadCount(),
  })
);

service.readNotifications = () => (
  request({
    route: apiRoutes.readNotifications(),
    method: 'PUT',
  })
);

export default service;
