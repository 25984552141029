import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Responsive, Header, Dimmer, Loader, Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import { currencyFormat } from '../../common/utils';
import '../../checkout/styless.css';

const LeaseOptions = props => {
  const {
    leaseOptionsHeaders, data, leaseQuoteLoader, t, handleTableSelected, leaseItemsHeaders,
    leaseItemsData, leaseSubtotal, reloadQuickQuote, quoteAgainFlag,
  } = props;
  return (
    <Grid>
      <Grid.Row className="checkout-lease-title">
        <Grid.Column>
          <Responsive maxWidth={425}>
            <br />
          </Responsive>
          <Header as="h3" color="blue">{t('lease-quote.lease-options')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="checkout-lease-margin">
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Header as="h5" style={{ display: 'inline' }}><p>{t('lease-quote.price-adjustment')}</p></Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered style={{ overflowX: 'auto' }}>
        <Grid.Column floated="right" largeScreen={6} computer={6} tablet={6} mobile={16} textAlign="left">
          <Header as="h5" color="black">
            Monto de cotización:&nbsp;
            <Header as="h5" color="green" style={{ display: 'inline' }}>
              {currencyFormat(leaseSubtotal, 'MXN')}
            </Header>
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered style={{ overflowX: 'auto' }}>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <SiclikTable
            headers={leaseItemsHeaders}
            data={leaseItemsData}
            pageSize={1000}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column largeScreen={6} computer={6} tablet={15} mobile={16}>
          <Button size="small" fluid color="blue" id="idPayment" disabled={quoteAgainFlag} onClick={reloadQuickQuote}>
            <span>{t('lease-quote.quote-again')}</span>
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="checkout-lease-margin">
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Header as="h5" style={{ display: 'inline' }}><p>{t('lease-quote.lease-options-info')}</p></Header>
        </Grid.Column>
      </Grid.Row>
      { (leaseQuoteLoader) ? (
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="justified">
            <Dimmer active inverted>
              <Loader active size="big" />
            </Dimmer>
          </Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row centered style={{ overflowX: 'auto' }}>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <SiclikTable
              selectable
              headers={leaseOptionsHeaders}
              data={data}
              pageSize={1000}
              getSelectedRows={rows => handleTableSelected(rows)}
              singleSelection
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
};

LeaseOptions.propTypes = {
  leaseOptionsHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  leaseItemsHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  leaseItemsData: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  reloadQuickQuote: PropTypes.func,
  handleTableSelected: PropTypes.func,
  leaseQuoteLoader: PropTypes.bool,
  quoteAgainFlag: PropTypes.bool,
  leaseSubtotal: PropTypes.number,
};

LeaseOptions.defaultProps = {
  leaseOptionsHeaders: [],
  data: [],
  leaseItemsHeaders: [],
  leaseItemsData: [],
  t: () => {},
  handleTableSelected: () => {},
  reloadQuickQuote: () => {},
  leaseQuoteLoader: false,
  quoteAgainFlag: false,
  leaseSubtotal: 0,
};

export default translate('common', { wait: true })(LeaseOptions);
