import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Header, Grid, Input, Label, List, Image, Message,
} from 'semantic-ui-react';

class AddVeeamDiscountPrice extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.veeamDiscountPricesResponse !== state.veeamDiscountPricesResponse) {
      return {
        veeamDiscountPricesResponse: props.veeamDiscountPricesResponse,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      veeamDiscountPricesResponse: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { veeamDiscountPricesResponse } = this.props;
    if (veeamDiscountPricesResponse !== prevProps.veeamDiscountPricesResponse) {
      this.handleGetFormResponse();
    }
  }

  handleGetFormResponse = () => {
    const { productsVeeam, getArrayVeeamSkuPrices } = this.props;
    const { veeamDiscountPricesResponse } = this.state;
    let productsVeeamFormatted = productsVeeam;
    const productsVeeamResponse = veeamDiscountPricesResponse;
    if (veeamDiscountPricesResponse && veeamDiscountPricesResponse.length > 0) {
      productsVeeamFormatted = productsVeeamFormatted.map(arraydata => {
        const skuToUpdateResponse = productsVeeamResponse.findIndex(findElement => findElement.sku === arraydata.sku);
        if (arraydata.sku === productsVeeamResponse[skuToUpdateResponse].sku) {
          const skuToUpdate = productsVeeamFormatted.findIndex(findElement => findElement.sku === productsVeeamResponse[skuToUpdateResponse].sku);
          productsVeeamFormatted[skuToUpdate].discountPrice = Number(productsVeeamResponse[skuToUpdateResponse].discountPrice);
          if (!productsVeeamResponse[skuToUpdateResponse].discountPrice) productsVeeamFormatted[skuToUpdate].hasDiscountPrice = false;
          else productsVeeamFormatted[skuToUpdate].hasDiscountPrice = true;
        }
        return arraydata;
      });
    }
    getArrayVeeamSkuPrices(productsVeeamFormatted);
  }

  handleSavePrice = (e, { value, name }) => {
    const { productsVeeam, getArrayVeeamSkuPrices } = this.props;
    let productsVeeamFormatted = productsVeeam;
    const skuToUpdate = productsVeeamFormatted.findIndex(findElement => findElement.sku === name);
    productsVeeamFormatted = productsVeeamFormatted.map(arraydata => {
      if (arraydata.sku === name) {
        productsVeeamFormatted[skuToUpdate].discountPrice = Number(value);
        if (value === '') productsVeeamFormatted[skuToUpdate].hasDiscountPrice = false;
        else productsVeeamFormatted[skuToUpdate].hasDiscountPrice = true;
      }
      return arraydata;
    });
    getArrayVeeamSkuPrices(productsVeeamFormatted);
  }

  render() {
    const {
      tr: t, productsVeeam, veeamIdError, visible, handleDismiss,
    } = this.props;
    return (
      <Grid container>
        <Grid.Row columns={1}>
          <Grid.Column largeScreen={12} computer={12} tablet={14} mobile={15}>
            <Header>{t('Asignar precios por (DealReg o NSQ)')}</Header>
          </Grid.Column>
        </Grid.Row>
        {visible
          ? (
            <Grid.Row>
              <Message warning onDismiss={handleDismiss}>
                <Message.Header>{t('Falta completar información')}</Message.Header>
                {veeamIdError}
              </Message>
            </Grid.Row>
          )
          : ''}
        <Grid.Row columns={2}>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
            {productsVeeam.map(item => (
              <Grid.Row>
                <List relaxed="very" size="medium">
                  <List.Item key={item.sku}>
                    <Image avatar size="small" src={item.image} />
                    <List.Content>
                      <List.Description>
                        <Header as="h5" color="black">
                          {t('multi-shipping.sku')}
                          {' '}
                          {item.sku}
                        </Header>
                      </List.Description>
                      <List.Description>
                        <Input
                          labelPosition="center"
                          key={item.index}
                          name={item.sku}
                          type="number"
                          compact
                          onChange={this.handleSavePrice}
                          fluid
                          placeholder="Precio"
                          defaultValue={item.discountPrice}
                        >
                          <Label basic>$</Label>
                          <input />
                        </Input>
                      </List.Description>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Row>
            ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>

    );
  }
}

AddVeeamDiscountPrice.defaultProps = {
  productsVeeam: [],
  veeamDiscountPricesResponse: [],
  veeamIdError: '',
  getArrayVeeamSkuPrices: null,
  visible: false,
  handleDismiss: null,
  tr: null,
};

AddVeeamDiscountPrice.propTypes = {
  getArrayVeeamSkuPrices: PropTypes.func,
  handleDismiss: PropTypes.func,
  productsVeeam: PropTypes.arrayOf(PropTypes.shape({})),
  tr: PropTypes.func,
  veeamDiscountPricesResponse: PropTypes.arrayOf(PropTypes.shape({})),
  veeamIdError: PropTypes.string,
  visible: PropTypes.bool,
};

export default translate('common', { wait: true })(AddVeeamDiscountPrice);
