import React from 'react';
import propTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Modal, Grid, Dropdown, Button,
} from 'semantic-ui-react';

const AddAgents = props => {
  const {
    t, openDeleteAgentModal, handleClickCancel, personalFormat,
    handleGetSelectedEmail, agentEmail, handleDeleteAgentDirectory,
  } = props;
  return (
    <Modal open={openDeleteAgentModal}>
      <Modal.Header>
        {t('agents.deleteAllAgentRegisters')}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.selectAgent')}
                fluid
                options={personalFormat}
                onChange={handleGetSelectedEmail}
                value={agentEmail}
                valueEmail=""
                defaultValue={agentEmail}
                search
                selection
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <p>
                {agentEmail}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
        <Button color="red" onClick={handleDeleteAgentDirectory}>{t('delete')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
AddAgents.defaultProps = {
  openDeleteAgentModal: true,
  t: null,
  handleClickCancel: null,
  personalFormat: '',
  handleDeleteAgentDirectory: null,
  handleGetSelectedEmail: null,
  agentEmail: '',
};

AddAgents.propTypes = {
  t: propTypes.func,
  openDeleteAgentModal: propTypes.bool,
  handleClickCancel: propTypes.func,
  personalFormat: propTypes.string,
  handleDeleteAgentDirectory: propTypes.func,
  handleGetSelectedEmail: propTypes.func,
  agentEmail: propTypes.string,
};

export default translate('common', { wait: true })(AddAgents);
