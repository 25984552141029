const BRAND_PARAM = 'brand';
const KEYWORD_PARAM = 'search';

const BRAND_PATH = `?type=${BRAND_PARAM}&value=`;
const KEYWORD_PATH = `?type=${KEYWORD_PARAM}&value=`;

const bucket = `${process.env.REACT_APP_URL_AWS}`;
const brandsUrl = `${bucket}/imagenes/assets/brands/`;
const logosUrl = `${bucket}/imagenes/assets/brands/logos/`;

const brands = [
  {
    text: 'Apple',
    redirect: `${BRAND_PATH}Apple`,
    variants: ['apple'],
    logo: `${brandsUrl}apple.png`,
  },
  {
    text: 'Aruba',
    redirect: `${BRAND_PATH}Aruba`,
    variants: ['aruba'],
    logo: `${brandsUrl}aruba.png`,
  },
  {
    text: 'Autodesk',
    redirect: `${BRAND_PATH}autodesk`,
    variants: ['asus'],
    logo: `${logosUrl}autodesk.png`,
  },
  {
    text: 'Asus',
    redirect: `${BRAND_PATH}Asus`,
    variants: ['asus'],
    logo: `${logosUrl}asus.png`,
  },
  {
    text: 'Blancco',
    redirect: `${BRAND_PATH}Blancco`,
    variants: ['blancco'],
    logo: `${brandsUrl}blancco.png`,
  },
  {
    text: 'HPE',
    redirect: `${BRAND_PATH}HPE`,
    variants: ['hpe', 'hewlett packard enterprise', 'hp enterprise'],
    logo: `${logosUrl}hpe.png`,
  },
  {
    text: 'HP Inc',
    redirect: `${BRAND_PATH}HP`,
    variants: ['hp inc', 'hp'],
    logo: `${brandsUrl}hp.png`,
  },
  {
    text: 'Lenovo',
    redirect: `${BRAND_PATH}Lenovo`,
    variants: ['lenovo'],
    logo: `${logosUrl}lenovo.png`,
  },
  {
    text: 'Logitech',
    redirect: `${BRAND_PATH}logitech`,
    variants: ['logitech'],
    logo: `${logosUrl}logitech.png`,
  },
  {
    text: 'Matterport',
    redirect: `${BRAND_PATH}Matterport`,
    variants: ['matterport'],
    logo: `${logosUrl}matterport.png`,
  },
  {
    text: 'Perfect Choice',
    redirect: `${BRAND_PATH}PerfectChoice`,
    variants: ['perfect choice', 'perfectchoice'],
    logo: `${logosUrl}perfectchoice.png`,
  },
  {
    text: 'Red Hat',
    redirect: `${BRAND_PATH}RedHat`,
    variants: ['red hat', 'redhat'],
    logo: `${brandsUrl}redhat.png`,
  },
  {
    text: 'Safetica',
    redirect: `${BRAND_PATH}Safetica`,
    variants: ['Safetica'],
    logo: `${brandsUrl}safetica.png`,
  },
  {
    text: 'Screenbeam',
    redirect: `${BRAND_PATH}Screenbeam`,
    variants: ['Screenbeam', 'screenbeam', 'Actiontec', 'actiontec'],
    logo: `${logosUrl}actiontec.png`,
  },
  {
    text: 'TechZone',
    redirect: `${BRAND_PATH}TechZone`,
    variants: ['techzone'],
    logo: `${logosUrl}techzone.png`,
  },
  {
    text: 'Trellix',
    redirect: `${BRAND_PATH}trellix`,
    variants: ['trellix'],
    logo: `${logosUrl}trellix-logo.png`,
  },
  {
    text: 'Tripp Lite',
    redirect: `${BRAND_PATH}tripp lite`,
    variants: ['Tripplite'],
    logo: `${logosUrl}tripp-lite.png`,
  },
  {
    text: 'Veeam',
    redirect: `${BRAND_PATH}Veeam`,
    variants: ['veeam'],
    logo: `${brandsUrl}veeam.png`,
  },
  // {
  //   text: 'VMware',
  //   redirect: `${BRAND_PATH}VMware`,
  //   variants: [
  //     'vmware',
  //   ],
  //   logo: `${logosUrl}vmware.png`,
  // },
];

const accesories = [
  {
    text: 'Bocinas',
    redirect: `${KEYWORD_PATH}bocinas`,
  },
  {
    text: 'Cámaras Digitales',
    redirect: `${KEYWORD_PATH}camara`,
  },
  {
    text: 'Cómputo',
    redirect: `${KEYWORD_PATH}accesorios de computo`,
  },
  {
    text: 'Monitores',
    redirect: `${KEYWORD_PATH}monitor`,
  },
];

const computer = [
  {
    text: 'Escritorio',
    redirect: `${KEYWORD_PATH}escritorio`,
  },
  {
    text: 'Laptop',
    redirect: `${KEYWORD_PATH}laptop`,
  },
  {
    text: 'Tableta',
    redirect: `${KEYWORD_PATH}tableta`,
  },
];

const printers = [
  {
    text: 'Consumibles',
    redirect: `${KEYWORD_PATH}consumibles`,
  },
  {
    text: 'Escaner',
    redirect: `${KEYWORD_PATH}scanner`,
  },
  {
    text: 'Láser',
    redirect: `${KEYWORD_PATH}laser`,
  },
  {
    text: 'Miniprinters',
    redirect: `${KEYWORD_PATH}printer`,
  },
  {
    text: 'Plotter',
    redirect: `${KEYWORD_PATH}impresora`,
  },
];

const networks = [
  {
    text: 'Wireless LAN',
    redirect: 'wireless',
  },
  {
    text: 'Switch',
    redirect: `${KEYWORD_PATH}switch`,
  },
];

const servers = [
  {
    text: 'Accesorios',
    redirect: `${KEYWORD_PATH}servidores`,
  },
  {
    text: 'Almacenamiento',
    redirect: `${KEYWORD_PATH}almacenamiento`,
  },
  {
    text: 'Reguladores de energía',
    redirect: `${KEYWORD_PATH}energia`,
  },
];

const software = [
  {
    text: 'Colaboración',
    redirect: `${KEYWORD_PATH}colaboracion`,
  },
  {
    text: 'Nube',
    redirect: `${KEYWORD_PATH}nube`,
  },
  {
    text: 'Seguridad',
    redirect: `${KEYWORD_PATH}seguridad`,
  },
  {
    text: 'Sistemas operativos',
    redirect: `${KEYWORD_PATH}sistemas operativos`,
  },
  {
    text: 'Virtualización',
    redirect: `${KEYWORD_PATH}virtualizacion`,
  },
];

const gaming = [
  {
    text: 'gaming',
    redirect: `${KEYWORD_PATH}gaming`,
  },
];

const assets = {
  brands,
  accesories,
  computer,
  printers,
  networks,
  servers,
  gaming,
  software,
};

export default assets;
