import PropTypes from 'prop-types';
import React from 'react';
import {
  Grid, Dimmer, Loader, Header, Dropdown,
} from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';

const Members = props => {
  const {
    membersHeaders, data, membersLoader, customers, statusFilter, statusSelected, customerSelected,
    handleChangeCustomer, handleChangeStatus, membersCount, pendingCount,
  } = props;
  return (
    <div>
      { membersLoader
        ? (
          <Grid>
            <Grid.Row>
              <Grid.Column className="members-dimmer" largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Dimmer active={membersHeaders} inverted><Loader active>Cargando...</Loader></Dimmer>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )
        : (
          <Grid>
            <Grid.Row>
              <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
                <Header as="h4" color="blue">Filtrar por Empresa</Header>
                <Dropdown
                  selection
                  value={customerSelected}
                  options={customers}
                  placeholder="Elegir empresa"
                  noResultsMessage="Intenta con otra empresa"
                  onChange={handleChangeCustomer}
                />
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
                <Header as="h4" color="blue">Filtrar por estatus</Header>
                <Dropdown
                  selection
                  value={statusSelected}
                  options={statusFilter}
                  placeholder="Elegir status"
                  noResultsMessage="Intenta con otro status"
                  onChange={handleChangeStatus}
                />
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
                <Header as="h4" color="blue">
                  Invitados:
                  <Header as="h2" color="green" style={{ display: 'inline' }}>
                    {' '}
                    {membersCount}
                    {' '}
                  </Header>
                </Header>
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={4} tablet={8} mobile={16}>
                <Header as="h4" color="blue">
                  Disponibles:
                  <Header as="h2" color="green" style={{ display: 'inline' }}>
                    {' '}
                    {pendingCount}
                    {' '}
                  </Header>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered style={{ overflowX: 'auto' }}>
              <Grid.Column className="members-dimmer" largeScreen={16} computer={16} tablet={16} mobile={16}>
                <SiclikTable headers={membersHeaders} data={data} striped={false} basic={null} pageSize={1000} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
    </div>
  );
};

Members.propTypes = {
  customerSelected: PropTypes.string,
  customers: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  handleChangeCustomer: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  membersCount: PropTypes.string,
  membersHeaders: PropTypes.arrayOf(PropTypes.shape({})),
  membersLoader: PropTypes.bool.isRequired,
  pendingCount: PropTypes.string,
  statusFilter: PropTypes.arrayOf(PropTypes.shape({})),
  statusSelected: PropTypes.string,
};

Members.defaultProps = {
  customerSelected: '',
  customers: [],
  data: [],
  handleChangeCustomer: () => {},
  handleChangeStatus: () => {},
  membersCount: '',
  membersHeaders: [],
  pendingCount: '',
  statusFilter: [],
  statusSelected: '',
};

export default Members;
