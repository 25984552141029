import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import routes from '../../../route-names';

function loginClickGA(label) {
  ReactGA.event({
    category: 'Login',
    action: 'Clic login',
    label,
  });
  ReactGA4.event({
    category: 'Login',
    action: 'Clic login',
    label,
  });
}

const MenuHeader = props => {
  const {
    t, history, showMobile, isAuthenticated,
  } = props;

  const redirectComerio = () => {
    if (!isAuthenticated) {
      history.push(routes.home.route);
    } else {
      history.push(routes.homeComercio.route);
    }
  };

  return (
    <Grid.Row textAlign="center" verticalAlign="middle">
      <Menu
        stackable={!!showMobile}
        text
        className="menu-side"
      >
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={8}
          tablet={5}
          computer={2}
          largeScreen={2}
          widescreen={2}
          onClick={() => {
            loginClickGA('Comercio');
          }}
        >
          <Menu.Item
            name="siclik Comercio"
            className="menu-item-style lightGreyText hov-blue"
            onClick={() => redirectComerio()}
          >
            {t('siclik Comercio')}
          </Menu.Item>
        </Grid.Column>
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={8}
          tablet={5}
          computer={2}
          largeScreen={2}
          widescreen={2}
          onClick={() => {
            loginClickGA('Suscribe');
          }}
        >
          <Menu.Item
            name="siclik Suscribe"
            className="menu-item-style lightGreyText hov-green"
            onClick={() => window.open('https://clicksuscribe.compusoluciones.com/#/Login', '_blank')}
          >
            {t('siclik Suscribe')}
          </Menu.Item>
        </Grid.Column>
        <Grid.Column
          className="no-padding-x menu-item-border"
          mobile={5}
          tablet={5}
          computer={2}
          largeScreen={2}
          widescreen={2}
          onClick={() => {
            loginClickGA('Cesa');
          }}
        >
          <Menu.Item
            name="siclik CESA"
            className="menu-item-style lightGreyText hov-red"
            onClick={() => window.open('https://cesa.siclik.mx/', '_blank')}
          >
            {t('siclik CESA')}
          </Menu.Item>
        </Grid.Column>
        {/* <Grid.Column
          className="button-login-style menu-item-border"
          mobile={5}
          tablet={5}
          computer={3}
          largeScreen={3}
          widescreen={3}
          textAlign="left"
          verticalAlign="middle"
        >
          {
            !isAuthenticated
              ? (
                <Button
                  primary
                  size="medium"
                  onClick={() => {
                    history.push(routes.loginCS.route);
                  }}
                >
                  {t('login.login')}
                </Button>
              ) : (
                <Button
                  primary
                  size="medium"
                  onClick={() => {
                    history.push(routes.homeComercio.route);
                  }}
                >
                  {t('Entrar')}
                </Button>
              )
          }
        </Grid.Column> */}
      </Menu>
    </Grid.Row>
  );
};

MenuHeader.propTypes = {
  showMobile: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

MenuHeader.defaultProps = {
};

export default translate('common', { wait: true })(MenuHeader);
