import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const customerDirectory = {};

customerDirectory.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);
customerDirectory.getCustomerData = (customerFromParams = undefined) => (
  request({
    route: apiRoutes.getCustomerById(customerFromParams),
    method: 'GET',
  })
);
customerDirectory.getFinalUser = (customerFromParams = undefined) => (
  request({
    route: apiRoutes.getFinalUsers(customerFromParams),
    method: 'GET',
  })
);
customerDirectory.getBranchOfficeAddress = (customerFromOrder = undefined) => (
  request({
    route: apiRoutes.getBranchOfficeAddress(customerFromOrder),
    method: 'GET',
  })
);

customerDirectory.getCustomerCredit = customerId => {
  const queryParams = customerId ? `?customerId=${customerId}` : '';
  return request({
    route: apiRoutes.getCustomerCredit(queryParams),
    method: 'GET',
  });
};

customerDirectory.getUsersByCustomer = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
  })
);

customerDirectory.updateUserAdminRoles = (userId, userData) => (
  request({
    route: apiRoutes.updateUserAdminRoles(userId),
    method: 'PUT',
    body: userData,
  })
);

customerDirectory.getRoles = () => (
  request({
    route: apiRoutes.getRoles(),
  })
);

customerDirectory.addUser = user => (
  request({
    route: apiRoutes.addAdminUser(),
    method: 'POST',
    body: user,
  })
);

export default customerDirectory;
