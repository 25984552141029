import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import validator from 'validator';
import { translate } from 'react-i18next';
import {
  Grid, Header, Dimmer, Loader, Image, Dropdown, Form,
} from 'semantic-ui-react';
import AddDeliveryAddress from './add-delivery-adress';
import AddFinalUser from './add-final-user';
import { TextField } from '../../common/components/materials';

const validateInput = (value, field, t) => {
  let error = '';
  if (field === 'email' && value.length > 0) {
    if (!validator.isEmail(value)) error = t('multibrand.errors.notEmail');
    if (value.length > 60) error = t('multibrand.errors.toLong');
  }
  if (field === 'endUserRelatedProyect' && value.length > 0) {
    if (!validator.isAlphanumeric(value.replace(/ /g, ''), ['es-ES'])) error = t('multibrand.errors.notAlpha');
    if (value.length > 60) error = t('multibrand.errors.toLong');
  }
  if (field === 'contact' && value.length > 0) {
    if (!validator.isAlpha(value.replace(/ /g, ''), ['es-ES'])) error = t('multibrand.errors.notAlpha');
    if (value.length > 35) error = t('multibrand.errors.toLong');
  }
  if ((field === 'phone' || field === 'mobile') && value.length > 0) {
    if (!validator.isNumeric(value)) error = t('multibrand.errors.notNumeric');
    if (value.length > 10) error = t('branchOffice.errors.toLong');
  }
  if (field === 'loggin' && value.length > 0) {
    if (!validator.isAlphanumeric(validator.blacklist(value, '.@-_'), ['es-ES'])) error = t('multibrand.errors.notLoggin');
    if (value.length > 50) error = t('multibrand.errors.toLong');
  }
  if ((field === 'icn') && value.length > 0) {
    if (!validator.isNumeric(value)) error = t('multibrand.errors.notNumeric');
    if (value.length > 7) error = t('multibrand.errors.toLong');
  }
  return error;
};

const InputControlled = props => {
  const {
    id = '', initialData = '', maxLength = 35, label = '', initialError = '', onChange, hint = '', required = false, t, disabled,
  } = props;
  const [localData, setData] = useState('');
  const [localError, setError] = useState('');

  useEffect(() => {
    setData(initialData);
    setError(initialError);
  }, [initialData, initialError]);

  const handleBlur = () => {
    onChange(localData, localError);
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column onBlur={handleBlur} largeScreen={16} computer={16} tablet={16} mobile={16}>
          <TextField
            fullWidth
            maxLength={maxLength}
            label={label}
            error={localError || ''}
            value={localData || ''}
            onChange={e => {
              setData(e.target.value);
              setError(validateInput(e.target.value, id, t));
            }}
            hint={hint}
            disabled={disabled}
            required={required}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

InputControlled.propTypes = {
  disabled: PropTypes.bool,
  initialError: PropTypes.string,
  hint: PropTypes.string,
  id: PropTypes.string,
  initialData: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

InputControlled.defaultProps = {
  disabled: false,
  required: false,
  hint: '',
  id: '',
  initialError: '',
  initialData: '',
  label: '',
  maxLength: 35,
  onChange: () => { },
};

const FinalUser = props => {
  const {
    t, loading, makers, email, phone, mobile, contact, finalUser, address, isQuotation, loggin, icn, handleChangeLoggin,
    relatedEndUser, onItemClickFinalUser,
    handleGetNewUser, handleChangeContact, handleChangeEmail, handleChangePhone, handleChangeMobile, handleChangeIcn,
    onItemClickAddress, getNewAddress, handleChangeRelatedProyect, imageForm, addFinalUser,
  } = props;

  let finalUsers = finalUser.array;

  if (makers.apple) finalUsers = finalUsers.filter(filter => filter.text.toLowerCase() !== 'stock');
  return (
    <Grid container>
      <Grid.Row columns={2}>
        <Grid.Column largeScreen={13} computer={13} tablet={13} mobile={10}>
          <Header>{t('multibrand.finalUserData')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={6}>
          {imageForm ? (
            <Image
              src={imageForm}
              alt=""
              size="small"
            />
          ) : ''}
        </Grid.Column>
      </Grid.Row>
      {loading
        ? (
          <Grid.Row>
            <Dimmer active inverted><Loader active size="big" /></Dimmer>
          </Grid.Row>
        )
        : (
          <Grid.Row columns={1}>
            <Grid.Column largeScreen={7} computer={7} tablet={10} mobile={16}>
              <Form>
                <Form.Dropdown
                  options={finalUsers}
                  onChange={onItemClickFinalUser}
                  disabled={isQuotation}
                  label={t('multibrand.name')}
                  hint={t('multibrand.nameDescription')}
                  value={finalUser.id || ''}
                  error={finalUser.error ? { content: finalUser.error } : false}
                  selection
                  search
                  fluid
                />
              </Form>
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
              <AddFinalUser t={t} getNewUser={handleGetNewUser} addFinalUser={addFinalUser} />
            </Grid.Column>
          </Grid.Row>
        )}
      {!loading
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={6} computer={7} tablet={10} mobile={16}>
              <InputControlled
                id="contact"
                maxLength={35}
                label={t('multibrand.contact')}
                initialError={contact.error}
                onChange={handleChangeContact}
                initialData={contact.value}
                hint={t('multibrand.finalUserContact')}
                required
                disabled={isQuotation}
                t={t}
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={6} tablet={6} mobile={16}>
              <InputControlled
                id="email"
                maxLength={60}
                label={t('multibrand.email')}
                initialError={email.error}
                onChange={handleChangeEmail}
                initialData={email.value}
                disabled={isQuotation}
                required
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : null}
      {!loading
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={16}>
              <InputControlled
                id="phone"
                maxLength={10}
                label={t('multibrand.phone')}
                initialError={phone.error}
                initialData={phone.value}
                hint={t('multibrand.descriptionPhone')}
                t={t}
                onChange={handleChangePhone}
                disabled={isQuotation}
                required
              />
            </Grid.Column>
            {makers.IBM || makers.TRELLIX
              ? (
                <Grid.Column largeScreen={6} computer={6} tablet={8} mobile={16}>
                  {!makers.apple
                    ? (
                      <InputControlled
                        id="mobile"
                        maxLength={10}
                        label={t('multibrand.mobile')}
                        initialError={mobile.error}
                        initialData={mobile.value}
                        hint={t('multibrand.descriptionPhone')}
                        onChange={handleChangeMobile}
                        t={t}
                        disabled={isQuotation}
                        required={null}
                      />
                    )
                    : ''}
                </Grid.Column>
              )
              : null}
          </Grid.Row>
        )
        : null}

      {!loading
        ? (
          <Grid.Row columns={2}>
            {makers.IBM
              ? (
                <Grid.Column largeScreen={5} computer={7} tablet={8} mobile={16}>
                  <InputControlled
                    id="icn"
                    maxLength={7}
                    label={t('multibrand.icn')}
                    initialError={icn.erros}
                    initialData={icn.value}
                    hint={t('multibrand.icnDescription')}
                    t={t}
                    onChange={handleChangeIcn}
                    disabled={isQuotation}
                    required
                  />
                </Grid.Column>
              )
              : null}
            {makers.redHat
              ? (
                <Grid.Column largeScreen={6} computer={6} tablet={8} mobile={16}>
                  <InputControlled
                    id="loggin"
                    maxLength={50}
                    label={t('multibrand.loggin')}
                    initialError={loggin.error}
                    initialData={loggin.value}
                    onChange={handleChangeLoggin}
                    hint={t('multibrand.logginDescription')}
                    t={t}
                    disabled={isQuotation}
                    required
                  />
                </Grid.Column>
              ) : null}
          </Grid.Row>
        )
        : null}

      {!loading
        ? (
          <Grid.Row>
            <Grid.Column largeScreen={7} computer={7} tablet={10} mobile={16}>
              <Dropdown
                options={address.array}
                onChange={onItemClickAddress}
                disabled={isQuotation}
                label={t('multibrand.registeredAddress')}
                hint={t('multibrand.nameDescription')}
                value={address.id || ''}
                error={address.error || ''}
                selection
                search
                fluid
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
              <AddDeliveryAddress t={t} getNewAddress={getNewAddress} />
            </Grid.Column>
          </Grid.Row>
        )
        : null}
      {!loading && makers.veeam
        ? (
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={6} computer={7} tablet={10} mobile={16}>
              <InputControlled
                id="endUserRelatedProyect"
                maxLength={60}
                label={t('Nombre de la sede relacionada a la razón social')}
                initialError={relatedEndUser.error}
                onChange={handleChangeRelatedProyect}
                initialData={relatedEndUser.value}
                hint={t('Solo en caso de haber una sede relacionada a la razón social')}
                disabled={isQuotation}
                required={null}
                t={t}
              />
            </Grid.Column>
          </Grid.Row>
        )
        : null}
    </Grid>
  );
};

FinalUser.defaultProps = {
  addFinalUser: () => {},
};

FinalUser.propTypes = {
  address: PropTypes.shape({
    array: PropTypes.shape({
      length: PropTypes.number,
    }),
    error: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  contact: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  email: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  endDate: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  finalUser: PropTypes.shape({
    array: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  getNewAddress: PropTypes.func.isRequired,
  handleChangeContact: PropTypes.func.isRequired,
  handleChangeEmail: PropTypes.func.isRequired,
  handleChangeIcn: PropTypes.func.isRequired,
  handleChangeLoggin: PropTypes.func.isRequired,
  handleChangeMobile: PropTypes.func.isRequired,
  handleChangePhone: PropTypes.func.isRequired,
  handleChangeRelatedProyect: PropTypes.func.isRequired,
  handleGetNewUser: PropTypes.func.isRequired,
  addFinalUser: PropTypes.func,
  icn: PropTypes.shape({
    erros: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  isQuotation: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loggin: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  makers: PropTypes.shape({
    IBM: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    TRELLIX: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    redHat: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    veeam: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    apple: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }).isRequired,
  mobile: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  onItemClickAddress: PropTypes.func.isRequired,
  onItemClickFinalUser: PropTypes.func.isRequired,
  phone: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  relatedEndUser: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  startDate: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  imageForm: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(FinalUser);
