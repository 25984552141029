import React from 'react';
import propTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Modal, Grid, Dropdown, Button, Loader,
} from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const AddAgents = props => {
  const {
    t, open, handleClickCancel, customersFormat, customerId, handleGetCustomerValue,
    rolesFormat, makersFormat, handleGetRolValue, handleGetMakerValue, rol, rolId, makerId, handleGetNameValue,
    personalFormat, cellPhone, handleSaveAgent, save, update, agent, maker, agentEmail, loaderUpload,
  } = props;
  return (
    <Modal open={open}>
      { save
        ? <Modal.Header>{t('agents.addAgent')}</Modal.Header>
        : <Modal.Header>{t('agents.updateAgent')}</Modal.Header>}
      <Modal.Content>
        <Grid container>
          {loaderUpload
            ? <Loader active inline="centered" />
            : null}
          <Grid.Row centered>
            <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={15}>
              <p>
                {t('agents.email')}
                &nbsp;&nbsp;&nbsp;
                {agentEmail}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={15}>
              <Dropdown
                placeholder={t('agents.selectAgent')}
                fluid
                options={personalFormat}
                onChange={handleGetNameValue}
                value={agent}
                valueEmail=""
                defaultValue={agent}
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.role')}
                options={rolesFormat}
                onChange={handleGetRolValue}
                value={rolId}
                defaultValue={rol}
                fluid
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.maker')}
                options={makersFormat}
                onChange={handleGetMakerValue}
                value={makerId}
                id={maker}
                defaultValue={maker}
                fluid
                search
                selection
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column largeScreen={5} computer={5} tablet={7} mobile={15}>
              <Dropdown
                placeholder={t('agents.customer')}
                options={customersFormat}
                onChange={handleGetCustomerValue}
                value={customerId}
                defaultValue={customerId}
                fluid
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={7} mobile={15}>
              <TextField
                fullWidth
                maxLength={15}
                label={t('agents.cell')}
                value={cellPhone.value || ''}
                error={cellPhone.error || ''}
                onChange={cellPhone.change}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
        {save ? <Button onClick={handleSaveAgent} color="blue">{t('save')}</Button> : null}
        {update ? <Button color="blue" onClick={handleSaveAgent}>{t('update')}</Button> : null}
      </Modal.Actions>
    </Modal>
  );
};
AddAgents.defaultProps = {
  open: true,
  loaderUpload: false,
  t: null,
  handleClickCancel: null,
  handleGetRolValue: null,
  handleGetMakerValue: null,
  rol: '',
  handleGetNameValue: null,
  handleSaveAgent: null,
  agent: '',
  maker: '',
  personalFormat: '',
  customerId: '',
  handleGetCustomerValue: null,
  cellPhone: '',
  save: true,
  update: false,
  rolId: 0,
  makerId: '',
  agentEmail: '',
};

AddAgents.propTypes = {
  t: propTypes.func,
  open: propTypes.bool,
  loaderUpload: propTypes.bool,
  handleClickCancel: propTypes.func,
  rolesFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  handleGetRolValue: propTypes.func,
  rol: propTypes.string,
  maker: propTypes.string,
  handleGetNameValue: propTypes.func,
  personalFormat: propTypes.string,
  makersFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  handleGetMakerValue: propTypes.func,
  handleSaveAgent: propTypes.func,
  customersFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  customerId: propTypes.string,
  handleGetCustomerValue: propTypes.func,
  cellPhone: propTypes.string,
  save: propTypes.string,
  update: propTypes.string,
  agent: propTypes.string,
  rolId: propTypes.number,
  makerId: propTypes.string,
  agentEmail: propTypes.string,
};

export default translate('common', { wait: true })(AddAgents);
