import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const agents = {};

agents.getAllAgents = () => (
  request({
    route: apiRoutes.getAllAgents(),
    method: 'GET',
  })
);

agents.getAgentsRoles = () => (
  request({
    route: apiRoutes.getAgentsRoles(),
    method: 'GET',
  })
);

agents.getAgentMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

agents.getAgentDirectory = () => (
  request({
    route: apiRoutes.getAgentDirectory(),
    method: 'GET',
  })
);

agents.getAgentsByCustomer = () => (
  request({
    route: apiRoutes.getAgentsByCustomer(),
    method: 'GET',
  })
);

agents.addAgents = body => (
  request({
    route: apiRoutes.addAgents(),
    method: 'POST',
    body,
  })
);

agents.deleteAgentById = id => (
  request({
    route: apiRoutes.deleteAgentById(id),
    method: 'DELETE',
  })
);

agents.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

agents.updateAgentDirectory = body => (
  request({
    route: apiRoutes.updateAgentDirectory(),
    method: 'PUT',
    body,
  })
);

agents.getAgentDirectoryByMaker = makerId => (
  request({
    route: apiRoutes.getAgentDirectoryByMaker(makerId),
    method: 'GET',
  })
);

agents.getDirectoryByAgent = customerId => (
  request({
    route: apiRoutes.getDirectoryByAgent(customerId),
    method: 'GET',
  })
);

agents.getAgentList = () => (
  request({
    route: apiRoutes.getAgentList(),
    method: 'GET',
  })
);

agents.getCustomerList = () => (
  request({
    route: apiRoutes.getCustomerList(),
    method: 'GET',
  })
);

agents.getAgentsByCustomerId = customerId => (
  request({
    route: apiRoutes.getAgentsByCustomerId(customerId),
    method: 'GET',
  })
);

agents.updateDirectoryAgentByEmail = body => (
  request({
    route: apiRoutes.updateDirectoryAgentByEmail(),
    method: 'PUT',
    body,
  })
);

agents.deleteDirectoryAgentByEmail = email => (
  request({
    route: apiRoutes.deleteDirectoryAgentByEmail(email),
    method: 'DELETE',
  })
);

agents.replaceAgentDirectory = body => (
  request({
    route: apiRoutes.replaceAgentDirectory(),
    method: 'PUT',
    body,
  })
);

export default agents;
