import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Header, List } from 'semantic-ui-react';

function TypeDetail({ type }) {
  return !type
    ? <Header>Sin datos</Header>
    : (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            {type.description}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom">
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4">Medidas</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <List divided relaxed>
              {
              type.sizes.map(size => (
                <List.Item key={size.id}>
                  <List.Content>
                    <List.Header>{size.name}</List.Header>
                    <List.Description>{`${size.width || '(ancho libre)'} x ${size.height || '(alto libre)'}`}</List.Description>
                  </List.Content>
                </List.Item>
              ))
            }
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
}

TypeDetail.propTypes = {
  type: PropTypes.shape({
    description: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default TypeDetail;
