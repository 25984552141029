import React from 'react';
import { Grid, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

function WishList(props) {
  const { t, children } = props;
  return (
    <Grid padded centered>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={16} widescreen={16}>
          <Grid divided>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3" textAlign="left" color="blue">
                  <Header.Content>
                    {' '}
                    {t('wishList.title')}
                    {' '}
                  </Header.Content>
                </Header>
              </Grid.Column>
            </Grid.Row>
            {children}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

WishList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default translate('common', { wait: true })(WishList);
