import React, { useState } from 'react';
import { translate } from 'react-i18next';

import {
  Grid,
  Header,
  Divider,
  Segment,
  Container,
  Button,
  Form,
  Radio,
} from 'semantic-ui-react';

import toast from '../../../common/components/toast';
import ItemGroups from './components/item-groups';
import AddItem from './components/add-items';

import service from '../../services';

const REWRITE_ACTION = 'rewrite';
const ADD_ACTION = 'add';
const translatePath = 'productConfig.menus.uploadBulkImages';

async function addSignedUrls(image, imageIndex) {
  const outputExtension = 'jpeg';
  const bucketPath = 'imagenes/siclik/productos';
  const timestamp = new Date().getTime();
  const files = image.sizes.map(({ size }, index) => ({
    size,
    bucketPath,
    name: `${timestamp}-${index + 1}-${imageIndex}-${size}.${outputExtension}`,
  }));
  const { signedUrls } = await service.generateSignedUrl(files);
  const addedSignedUrls = image.sizes.map(size => ({
    ...size,
    signedUrl: signedUrls.find(signedUrl => signedUrl.size === size.size)?.signedUrl,
  }));
  return { ...image, sizes: addedSignedUrls };
}

async function uploadImagesToS3(signedUrls) {
  const { sizes } = signedUrls;
  const uploadedImagesResult = await Promise.all(sizes.map(async size => {
    const { signedUrl, preview } = size;
    await service.uploadImage(signedUrl, preview);
    const [url] = signedUrl.split('?');
    return { ...size, preview: url };
  }));
  return { ...signedUrls, sizes: uploadedImagesResult };
}

function hasEmptyImages(itemGroup) {
  return itemGroup.length
    ? !itemGroup.every(item => item?.images?.length > 0)
    : true;
}

function UploadBulkImages({ t }) {
  const [itemGroup, setItemGroup] = useState([]);
  const [action, setAction] = useState(REWRITE_ACTION);
  const [open, setOpen] = useState(false);
  const [selectedItemGroup, setSelectedItemGroup] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e, { value }) => {
    setAction(value);
  };

  const handleAddProduct = newItemGroup => {
    const exists = itemGroup.findIndex(item => item.id === newItemGroup.id);
    if (exists !== -1) {
      const newItems = [...itemGroup];
      newItems[exists] = newItemGroup;
      setItemGroup(newItems);
    } else {
      setItemGroup([...itemGroup, newItemGroup]);
    }
    setSelectedItemGroup(null);
  };

  const handleDeleteItem = itemId => {
    setItemGroup(currItems => currItems.filter(currItem => currItem.id !== itemId));
  };

  const handleEditItem = itemId => {
    const item = itemGroup.find(currItem => currItem.id === itemId);
    setSelectedItemGroup(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItemGroup(null);
  };

  const handleLoadImages = (itemId, images) => {
    const item = itemGroup.findIndex(currItem => currItem.id === itemId);
    if (item !== -1) {
      const newItems = [...itemGroup];
      newItems[item].images = images;
      setItemGroup(newItems);
    }
  };

  const updateItemsImages = async items => {
    try {
      await service.updateImages({ items, action });
      toast(t(`${translatePath}.toast.success`), 'success');
    } catch (error) {
      toast(t(`${translatePath}.toast.error`), 'error');
    }
  };

  const uploadImages = async () => {
    setLoading(true);
    const addedImages = await Promise.allSettled(itemGroup.flatMap(async item => {
      const images = await Promise.all(item.images.map(async (image, imageIndex) => {
        const signedUrls = await addSignedUrls(image, imageIndex + 1);
        const uploadedImages = await uploadImagesToS3(signedUrls);
        const result = { isMain: uploadedImages.isMain };
        uploadedImages.sizes.forEach(size => {
          result[size.size] = size.preview;
        });
        return result;
      }));
      return { skus: item.selectedProducts, images };
    }));
    const hasError = addedImages.some(image => image.status === 'rejected');
    if (hasError) {
      toast(t(`${translatePath}.toast.error`), 'error');
    } else {
      const newItemsImages = addedImages.map(image => image.value);
      await updateItemsImages(newItemsImages);
    }
    setLoading(false);
    setItemGroup([]);
  };

  const handleUploadImages = async () => {
    const groupWithoutMainImage = itemGroup.findIndex(item => item?.images.every(image => !image.isMain));
    if (action === REWRITE_ACTION && groupWithoutMainImage !== -1) {
      toast(t(`${translatePath}.toast.mainImageError`, { itemGroupNumber: groupWithoutMainImage + 1 }), 'error');
    } else {
      await uploadImages();
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t(`${translatePath}.title`)}</Header>
          <Divider />
          <p>{t(`${translatePath}.description`)}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Segment style={{ backgroundColor: '#F5F5F5' }}>
            <Segment>
              <Grid columns={3} stackable textAlign="center">
                <Grid.Row verticalAlign="middle">
                  <Grid.Column>
                    <Button
                      primary
                      content={t(`${translatePath}.menu.addItemGroupButton`)}
                      icon="plus"
                      labelPosition="right"
                      disabled={loading}
                      onClick={() => setOpen(true)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form>
                      <Form.Field disabled={!itemGroup.length || loading}>
                        <Radio
                          label={t(`${translatePath}.menu.rewriteImagesOption`)}
                          name="radioGroup"
                          value={REWRITE_ACTION}
                          checked={action === REWRITE_ACTION}
                          onChange={handleChange}
                        />
                      </Form.Field>
                      <Form.Field disabled={!itemGroup.length}>
                        <Radio
                          label={t(`${translatePath}.menu.addImagesOption`)}
                          name="radioGroup"
                          value={ADD_ACTION}
                          checked={action === ADD_ACTION}
                          onChange={handleChange}
                        />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      content={t(`${translatePath}.menu.uploadImagesButton`)}
                      color="green"
                      icon="cloud upload"
                      labelPosition="right"
                      disabled={hasEmptyImages(itemGroup)}
                      loading={loading}
                      onClick={handleUploadImages}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
            {itemGroup.length > 0 && itemGroup.map((group, index) => (
              <Container key={group.id} fluid style={{ paddingBottom: '1em' }}>
                <ItemGroups
                  groupNumber={index + 1}
                  itemGroup={group}
                  onDismiss={handleDeleteItem}
                  onEdit={handleEditItem}
                  onLoadImages={handleLoadImages}
                  loading={loading}
                />
              </Container>
            ))}
            <AddItem
              open={open}
              onClose={handleClose}
              onConfirm={handleAddProduct}
              itemGroup={selectedItemGroup}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(UploadBulkImages);
