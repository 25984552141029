import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Header, Icon } from 'semantic-ui-react';
import Toast from '../../common/components/toast';
import orderService from '../services';

function getIcon(type) {
  let iconName = '';
  switch (type) {
    case 'jpg': case 'png': case 'jpeg':
      iconName = 'file image outline';
      break;
    case 'pdf':
      iconName = 'file pdf outline';
      break;
    case 'docx': case 'doc':
      iconName = 'file word outline';
      break;
    case 'xlsx':
      iconName = 'file excel outline';
      break;
    default:
      iconName = 'file outline';
      break;
  }
  return iconName;
}

const downloadIntelisisFile = (urlFile, fileName) => {
  orderService.getRequestFile({ fileName, url: urlFile })
    .then(response => {
      const data = new Blob([Buffer.from(response.remoteFile)], { type: 'text/plain' }); // eslint-disable-line
      const url = window.URL.createObjectURL(data);
      const a = document.createElement('a'); // eslint-disable-line
      a.href = url;
      a.download = `${fileName}`;
      a.click();
    }).catch(() => {
      setTimeout(() => {
        orderService.getRequestFile({ fileName, url: urlFile })
          .then(response => {
            const data = new Blob([Buffer.from(response.remoteFile)], { type: 'text/plain' }); // eslint-disable-line
            const url = window.URL.createObjectURL(data);
            const a = document.createElement('a'); // eslint-disable-line
            a.href = url;
            a.download = `${fileName}`;
            a.click();
          }).catch(error => {
            Toast(error, 'error');
          });
      }, 6000);
    });
};

const downloadFile = (fileName, type, urlFile, t) => {
  if (urlFile.substring(0, 1) === '\\') {
    const data = urlFile.split('\\');
    const fullName = data[data.length - 1];
    downloadIntelisisFile(urlFile, fullName);
  } else {
    fetch(urlFile) // eslint-disable-line
      .then(response => {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${fileName}.${type}`;
          a.click();
        });
      }).catch(() => {
        Toast(t('failedDownload'), 'error');
      });
  }
};

function FileElement(fileName, type, key, url, t) {
  return (
    <Grid.Column
      as="a"
      key={key}
      largeScreen={5}
      computer={5}
      tablet={5}
      mobile={5}
      verticalAlign="top"
      textAlign="center"
    >
      <Icon link size="big" name={getIcon(type)} onClick={() => downloadFile(fileName, type, url, t)} />
      <Header as="h5" color="primary">{fileName}</Header>
    </Grid.Column>
  );
}

function Attachments(props) {
  const { t, files } = props;
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Header as="h4">{t('shoppingCart.attachments')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {
          (!files || files.length === 0)
            ? (
              <Grid.Column
                largeScreen={16}
                computer={16}
                tablet={16}
                mobile={16}
              >
                <Header as="h5">{t('shoppingCart.noAttachments')}</Header>
              </Grid.Column>
            )
            : files.map(file => (FileElement(file.name, file.type, `${file.name}-${file.type}`, file.url, t)))
        }
      </Grid.Row>
    </Grid>

  );
}

Attachments.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

Attachments.defaultProps = {
  files: [],
};

export default translate('common', { wait: true })(Attachments);
