import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid, Container, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import { TextField } from '../../common/components/materials';
import service from '../services';
import Toast from '../../common/components/toast';

class EndUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        { key: 'id', isKey: true },
        { key: 'tradingName', name: 'Razón social' },
        { key: 'rfc', name: 'RFC' },
        { key: 'name', name: 'Nombre' },
      ],
    };
  }

  componentDidMount() {
    this.handleGetFinalUsers();
  }

  componentDidUpdate(prevProps) {
    const { customerId } = this.props;
    if (prevProps.customerId !== customerId) {
      this.handleGetFinalUsers();
    }
  }

  handleGetFinalUsers = () => {
    const { t, customerId } = this.props;
    service.getFinalUser(customerId)
      .then(response => {
        this.setState({ endUsers: response.endUsers, usersArrayFiltered: response.endUsers });
      })
      .catch(error => {
        let messageError = '';
        if (Number(error.code) === 1005) {
          // this.setState({ messageTable: t('finaluser.emptyTable') });
        }
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleFilterUsers = event => {
    const { endUsers } = this.state;
    const { value: filter } = event.target;
    this.setState({ filter });
    this.handleFilter(filter, endUsers);
  }

  handleFilter = (filter, endUsers) => {
    const arrayUsersCopy = JSON.parse(JSON.stringify(endUsers));
    let foundData = [];
    this.setState({ filter });
    if (!filter) {
      foundData = endUsers;
    } else {
      foundData = arrayUsersCopy.filter(filterInfo => (filterInfo.name && filterInfo.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.tradingName && filterInfo.tradingName.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
        || (filterInfo.rfc && filterInfo.rfc.toLowerCase().indexOf(filter.toLowerCase()) >= 0));
    }
    this.setState({ filter, usersArrayFiltered: foundData });
  }

  render() {
    const { t } = this.props;
    const {
      headers, filter, usersArrayFiltered, filteError,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4" color="blue">{t('customerDirectory.endUsers')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={1} />
          <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={14}>
            <TextField
              fullWidth
              maxLength={55}
              value={filter || ''}
              error={filteError || ''}
              label={t('finaluser.filter')}
              onChange={this.handleFilterUsers}
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={14} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Container style={{ overflowX: 'scroll' }}>
              <SiclikTable
                unstackable
                headers={headers}
                data={usersArrayFiltered}
              />
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
EndUsers.propTypes = {
  customerId: PropTypes.string,
};

EndUsers.defaultProps = {
  customerId: '',
};
export default translate('common', { wait: true })(EndUsers);
