/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import {
  Grid, Label, Segment, Header, Transition, Message, Button, Image, Placeholder, Menu, Divider, Icon,
} from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';
import { groupBy } from 'lodash';
import { currencyFormat } from '../common/utils';
import RelatedProducts from './components/related-products';
import Specifications from './components/specifications';
import Features from './components/features';
import Toast from '../common/components/toast';
import SiclikTable from '../common/components/table';
import products from './services';
import './styles.css';
import BundleOptions from './components/bundle-options';

import DescriptionComponent from './components/product-descriptions/description-component';
import ImageComponent from './components/product-descriptions/imageComponent';
import ReasonsToBuy from './components/product-descriptions/reasons-to-buy';
import Multimedia from './components/product-descriptions/multimedia';
import * as session from '../common/sessions';

function detailGA(product) {
  const {
    sku, title, brand, maker, category, price, currency,
  } = product;
  ReactGA.set('currencyCode', currency);
  ReactGA.plugin.execute('ec', 'addProduct', {
    id: sku,
    name: title,
    brand: brand || maker,
    category,
    price,
  });

  ReactGA.plugin.execute('ec', 'setAction', 'detail');
  ReactGA.event({
    category: 'Comercio',
    action: 'Detalle producto',
    label: sku,
  });
  ReactGA4.event({
    category: 'Comercio',
    action: 'Detalle producto',
    label: sku,
  });
  ReactGA.plugin.execute('ec', 'clear');
}

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    const {
      t, sku, sku2, isVirtual, match,
    } = this.props;
    this.state = {
      outletLoader: false,
      visible: false,
      errorMessage: '',
      typeMessage: '',
      sku,
      sku2,
      variants: [],
      isVariant: false,
      appearHome: true,
      productId: sku || decodeURIComponent(match.params.sku),
      isVirtual,
      arrayImages: [],
      productName: props.name,
      maker: props.maker || '',
      price: props.price || 0,
      promotionPrice: props.promotionPrice || 0,
      currencyPrice: props.currencyPrice || '',
      description: [],
      features: [],
      specifications: [],
      reasonsToBuy: [],
      videos: [],
      inBox: [],
      promotionDeadline: null,
      arrayObjectStock:
      {
        totalStock: null,
        totalStockGdl: null,
        totalStockMty: null,
        totalStockMx: null,
        totalStockEm: null,
        totalStockMxElec: null,
      },
      arrayObjectStockOc:
      {
        totalStockOc: null,
        totalStockGdlOc: null,
        totalStockMtyOc: null,
        totalStockMxOc: null,
        totalStockEmOc: null,
      },
      arrayBundleOptions: [],
      discountSerialsHeaders: [
        { key: 'sku', isKey: true },
        { key: 'warehouse', name: t('productDetails.warehouse') },
        { key: 'stock', name: t('productDetails.stock') },
        { key: 'reason', name: t('discounts.type') },
        {
          key: 'price',
          name: t('productDetails.price'),
          format: cell => {
            const { currency } = this.state;
            return (currencyFormat(cell, currency));
          },
        },
        { key: 'addToCart', name: 'Agregar', format: this.addItemOutlettAction },
      ],
      updatedarrayRelatedProduct: [],
      makersPercents: {},
      product: {},
      arrayRequiredProducts: [],
      hasRequiredProduct: false,
      requiredId: '',
      promotionalMessage: '',
      loadingVmware: false,
      arraySubstituteProducts: [],
      hasNotImages: false,
      hasDescription: false,
      hasNotStock: false,
      skus: [],
      configurations: [],
      activeItem: 'description',
      promotion: {},
      outletStock: [],
      deliverDate: false,
      isAvailableRedhat: false,
      minQuantity: 0,
      maxQuantity: 0,
    };
  }

  componentDidMount() {
    const { isVirtual } = this.props;
    const { productId } = this.state;
    this.getArrayProducts(productId);
    this.getProductDataSheet();
    this.getAvailableRedhat();
    if (!isVirtual) {
      this.getArraySubstituteProducts();
      this.getArrayRelatedProducts();
    }
    if (isVirtual) this.getArrayConfig();
    window.scrollTo(0, 0);
  }

  getAvailableRedhat = () => {
    const { customerId } = session.get();
    products.getAvailableRedhatByCustomer(customerId).then(response => {
      this.setState({
        isAvailableRedhat: response,
      });
    }).catch(() => {
      this.setState({
        isAvailableRedhat: false,
      });
    });
  };

  getProductDataSheet = async () => {
    const { t } = this.props;
    try {
      const { productId } = this.state;
      const response = await products.getProductDataSheet(productId);
      const description = response.info.description?.long || response.info.description?.short || '';
      const hasDescription = Boolean(description);
      const descriptionFormated = this.setDescriptionFormat(hasDescription ? description : '');
      const videos = response?.multimedia ? response.multimedia.filter(({ isVideo }) => isVideo) : [];
      this.setState({
        specifications: response?.specifications || [],
        description: descriptionFormated,
        reasonsToBuy: response?.reasonsToBuy || [],
        videos,
        hasDescription,
      });
    } catch (error) {
      Toast(t(`error-codes.${error.code}`, 'error'));
    }
  };

  getMakersTable = async () => {
    const { makers } = await products.getAllMakers();
    const makersPercents = makers.reduce((hashMakers, { minPercent, name }) => ({
      ...hashMakers,
      [name]: minPercent,
    }), {});
    this.setState({ makersPercents });
  }

  getImages = async arrayImagesRequest => {
    const { productId } = this.state;
    const { t } = this.props;
    const sku = productId;
    let cnetImages = arrayImagesRequest;
    if (cnetImages && cnetImages.length > 1) {
      const arrayCSImages = await products.getAllImages(sku);
      const filterVideos = arrayCSImages.images.filter(data => data.url.slice(data.url.lastIndexOf('.')) === '.mp4');
      const urlVideos = filterVideos.map(video => video.url);
      cnetImages = arrayImagesRequest.slice(0, 7).map(data => data.big);
      const combinedFiles = cnetImages.concat(urlVideos);
      this.setState({ arrayImages: combinedFiles });
    } else {
      try {
        const arrayCatalogImages = await products.getAllImages(sku);
        const catalogImages = arrayCatalogImages.images.slice(0, 7).map(image => image.url);
        this.setState({ arrayImages: catalogImages, hasNotImages: catalogImages.length === 0 });
      } catch (error) {
        Toast(t(`error-codes.${error.code}`, 'error'));
      }
    }
  }

  setDescriptionFormat = description => {
    const paragraphs = description.split('\n')
      .join('~')
      .split('<br />')
      .join('~')
      .split('<br>')
      .join('~')
      .split('~');
    return paragraphs.map(paragraph => {
      if (paragraph.includes('<b>') || paragraph.includes('</b>')) {
        let cleanedParagraph = paragraph.replace('<b>', '');
        cleanedParagraph = cleanedParagraph.replace('</b>', '');
        return { type: 'title', paragraph: cleanedParagraph };
      }
      if (paragraph === '') {
        return { type: 'lineBreak', paragraph: null };
      }
      let cleanedParagraph = paragraph.replace('&nbsp;', '');
      cleanedParagraph = cleanedParagraph.replace('&bull;', '');
      return { type: 'text', paragraph: cleanedParagraph };
    });
  }

  getArrayProducts = async (productId, allowVariants) => {
    const { t } = this.props;
    try {
      const item = await products.getProductInformation(productId, allowVariants);
      const {
        title: productName, brand, sku, price, inventory, promotionPrice,
        expPromotionPrice: promotionDeadline, currency, tieredPricing,
        promotionalMessage, images, featureLogos, deliverDate, variants,
        description, minQuantity, maxQuantity,
      } = item;
      detailGA(item);
      this.setState({
        product: item,
        productName,
        productId: sku,
        maker: brand,
        sku,
        price,
        outletStock: inventory.outletStock,
        deliverDate,
        promotionPrice,
        promotionDeadline,
        currency,
        promotionalMessage,
        arrayImages: images,
        featureLogos,
        hasNotImages: Boolean(images.length),
        hasDescription: true,
        hasNotStock: Boolean(item.inventory.stock.length),
        variants,
        descriptionBySku: description,
        minQuantity,
        maxQuantity,
      });
      this.getStock(inventory.stock, brand.toLowerCase());
      this.getStockOc(inventory.requestedStock);
      if (brand.toLowerCase() === 'aruba') this.getArrayRequiredProducts();
      if (tieredPricing.length) {
        this.handleFormatSteppedPrice(tieredPricing, currency);
      }
      if (item.type === 'Juego' || item.type === 'juego') this.getOptionBundle();
    } catch (error) {
      Toast(t('error-codes.default'), 'error');
    }
  };

  getArrayConfig = async () => {
    const { t } = this.props;
    const { productId, isVirtual } = this.state;
    let { configurations } = this.state;
    if (Number(isVirtual) === 1) {
      const item = await products.getConfigBySku(productId, isVirtual)
        .catch(error => {
          let messageError = '';
          if (error.code === 14) {
            messageError = t(`error-codes.${error.code}`);
            Toast(messageError, 'error');
          }
        });
      if (item && item.skusConfig && item.skusConfig.length > 0) configurations = await this.handleGetConfigurations(item.skusConfig);
      this.setState({
        skus: item && item.skusConfig ? item.skusConfig : [],
        configurations,
      });
    }
  };

  getOptionBundle = async () => {
    const { productId, arrayBundleOptions } = this.state;
    const components = await products.getBundleOptionsBySku(productId);
    let counter = 0;
    if (components.options && components.options.length > 0) {
      // eslint-disable-next-line array-callback-return
      Promise.all(components.options.map(component => {
        arrayBundleOptions.push({
          id: counter,
          component: component.sku,
          componentName: component.name,
          componentImage: component.image,
        });
        counter++;
      }));
    }
    this.setState({ arrayBundleOptions });
  }

  getStock = (arrayStock, brand) => {
    const stockGdl = arrayStock.filter(data => data.warehouse === 'GI01');
    const stockMty = arrayStock.filter(data => data.warehouse === 'YI01');
    const stockMx = arrayStock.filter(data => data.warehouse === 'MI01');
    const stockEm = arrayStock.filter(data => data.warehouse === 'MI55');
    const stockMxElec = arrayStock.filter(data => data.warehouse === 'MI09');
    const stockGdlElec = arrayStock.filter(data => data.warehouse === 'GI09');
    const stockMtyElec = arrayStock.filter(data => data.warehouse === 'YI09');
    this.getTotalStock(stockGdl, stockMty, stockMx, stockEm, stockMxElec, stockGdlElec, stockMtyElec, brand);
  };

  getTotalStock = (stockGdl, stockMty, stockMx, stockEm, stockMxElec, stockMtyElec, stockGdlElec) => {
    let total = 0;
    let totalElec = 0;
    let totalgdl = 0;
    let totalmty = 0;
    let totalmx = 0;
    let totalstockGdl = stockGdl.map(data => data.quantity);
    let totalstockMty = stockMty.map(data => data.quantity);
    let totalstockMx = stockMx.map(data => data.quantity);
    let totalstockEm = stockEm.map(data => data.quantity);
    let totalstockMxElec = stockMxElec.map(data => data.quantity);
    let totalstockGdlElec = stockGdlElec.map(data => data.quantity);
    let totalstockMtyElec = stockMtyElec.map(data => data.quantity);
    if (totalstockGdl.length === 0) totalstockGdl = 0;
    if (totalstockMty.length === 0) totalstockMty = 0;
    if (totalstockMx.length === 0) totalstockMx = 0;
    if (totalstockEm.length === 0) totalstockEm = 0;
    if (totalstockMxElec.length === 0) totalstockMxElec = 0;
    if (totalstockGdlElec.length === 0) totalstockGdlElec = 0;
    if (totalstockMtyElec.length === 0) totalstockMtyElec = 0;
    total += Number(totalstockGdl) + Number(totalstockMty) + Number(totalstockMx) + Number(totalstockEm);
    totalgdl = Number(totalstockGdl);
    totalmty = Number(totalstockMty);
    totalmx = Number(totalstockMx);
    totalElec += Number(totalstockGdlElec) + Number(totalstockMtyElec) + Number(totalstockMxElec);
    const totalem = Number(totalstockEm);
    const totalmxElec = Number(totalstockMxElec);
    const totalgdlElec = Number(totalstockGdlElec);
    const totalmtyElec = Number(totalstockMtyElec);
    if (totalgdlElec || totalmtyElec || totalmxElec) {
      this.setState({
        arrayObjectStock: {
          totalStock: totalElec,
          totalStockGdlElec: totalgdlElec,
          totalStockMtyElec: totalmtyElec,
          totalStockMxElec: totalmxElec,
          hasElectronic: true,
        },
      });
    } else {
      this.setState({
        arrayObjectStock: {
          totalStock: total,
          totalStockGdl: totalgdl,
          totalStockMty: totalmty,
          totalStockMx: totalmx,
          totalStockEm: totalem,
          hasElectronic: false,
        },
      });
    }
  }

  getStockOc = arrayStockOc => {
    const stockOcGdl = arrayStockOc.filter(data => data.warehouse === 'GI01');
    const stockOcMty = arrayStockOc.filter(data => data.warehouse === 'YI01');
    const stockOcMx = arrayStockOc.filter(data => data.warehouse === 'MI01');
    const stockOcEm = arrayStockOc.filter(data => data.warehouse === 'MI55');
    this.getTotalStockOc(stockOcGdl, stockOcMty, stockOcMx, stockOcEm);
  };

  getTotalStockOc = (stockGdl, stockMty, stockMx, stockEm) => {
    let total = 0;
    let totalstockGdl = stockGdl.map(data => data.quantity);
    let totalstockMty = stockMty.map(data => data.quantity);
    let totalstockMx = stockMx.map(data => data.quantity);
    let totalstockEm = stockEm.map(data => data.quantity);
    if (totalstockGdl.length === 0) totalstockGdl = 0;
    if (totalstockMty.length === 0) totalstockMty = 0;
    if (totalstockMx.length === 0) totalstockMx = 0;
    if (totalstockEm.length === 0) totalstockEm = 0;

    total += Number(totalstockGdl) + Number(totalstockMty) + Number(totalstockMx) + Number(totalstockEm);
    const totalgdl = Number(totalstockGdl);
    const totalmty = Number(totalstockMty);
    const totalmx = Number(totalstockMx);
    const totalem = Number(totalstockEm);

    this.setState({
      arrayObjectStockOc: {
        totalStockOc: total, totalStockGdlOc: totalgdl, totalStockMtyOc: totalmty, totalStockMxOc: totalmx, totalStockEmOc: totalem,
      },
    });
  }

  getArrayRelatedProducts = async () => {
    const { productId } = this.state;
    const response = await products.getRelatedProducts(productId)
      .catch(() => ({ relatedProducts: [] }));
    if (response && response.relatedProducts) {
      this.addIndexArrayRelatedProduct(response.relatedProducts);
    }
  };

  getArrayRequiredProducts = async () => {
    const { productId, currencyPrice } = this.state;
    this.setState({ loadingVmware: true });
    const wmwareProducts = await products.getRequiredProductsBySku(productId)
      .catch(() => this.setState({ loadingVmware: false }));
    if (wmwareProducts) {
      const arrayRequiredProducts = wmwareProducts.requiredProducts.map(item => {
        const price = currencyFormat(Number(item.promotionPrice) ? Number(item.promotionPrice) : Number(item.price), currencyPrice);
        const formatedItem = {
          key: item.sku,
          text: `${item.name}, ${!price ? '' : price}`,
          value: item.sku,
          content: (
            <Header as="h4">
              <Image src={item.image} />
              {item.name}
              {price || price === 0 ? '' : price}
            </Header>
          ),
        };
        return formatedItem;
      });
      this.setState({
        arrayRequiredProducts,
        hasRequiredProduct: true,
        requiredId: arrayRequiredProducts[0].key,
        loadingVmware: false,
      });
    }
  };

  addItemOutlettAction = () => {
    const { t, updateCartCount } = this.props;
    const { sku, outletLoader } = this.state;
    const skuItem = sku;
    return (
      <Button
        color="blue"
        icon="shopping cart"
        size="medium"
        loading={outletLoader}
        onClick={async () => {
          this.setState({ outletLoader: true });
          try {
            await products.addItems({
              items: [{
                sku: skuItem,
                quantity: 1,
                outlet: true,
              }],
            });
            this.setState({ outletLoader: false });
            updateCartCount();
            Toast(t('productDetails.productInCart'), 'success');
          } catch (error) {
            this.setState({ outletLoader: false });
            Toast(t(`error-codes.${error.code}`, 'error'));
          }
        }}
      />
    );
  };

  addIndexArrayRelatedProduct = arrayRelatedProduct => {
    let cont = 0;
    arrayRelatedProduct.map(currentItem => {
      const item = { ...currentItem };
      item.index = cont;
      cont++;
      return item;
    });
    this.setState({ updatedarrayRelatedProduct: arrayRelatedProduct });
  }

  goToProduct = sku => {
    window.location.assign(`/producto/detalle/${encodeURIComponent(sku)}`);
  }

  handleFormatSteppedPrice = (tieredPricing, currencyPrice) => {
    if (tieredPricing && tieredPricing.length > 0) {
      let currency = '';
      if (currencyPrice === 'Dolar MS') currency = 'USD';
      else if (currencyPrice === 'Dolares' || currencyPrice === 'Dólares') currency = 'USD';
      else currency = 'MXN';
      const formatedSteppedPrice = tieredPricing.map((item, index) => {
        const newConfig = {
          id: index,
          min: `${item.min} PZAS`,
          max: `${item.max} PZAS`,
          price: currencyFormat(item.price, currency),
        };
        return newConfig;
      });
      this.setState({ steppedPriceHp: formatedSteppedPrice });
    } else this.setState({ steppedPriceHp: [] });
  }

  getArraySubstituteProducts = async () => {
    const { sku } = this.state;
    const response = products.getSubstituteProducts(sku).catch(() => { });
    if (response && response.substituteProducts) this.setState({ arraySubstituteProducts: response.substituteProducts });
  };

  filterForColor = (color, skusToFilter) => {
    const byColor = skusToFilter.map(sku => {
      const filtered = sku.config.filter(skuConfig => skuConfig.idType === 1 && skuConfig.value === color);
      const exist = filtered.length > 0;
      if (exist) return sku;
      return '';
    });
    return byColor ? byColor.filter(sku => sku) : [];
  }

  filterForUrl = (url, skusToFilter) => {
    const byUrl = skusToFilter.map(sku => {
      const filtered = sku.config.filter(skuConfig => skuConfig.idType === 2 && skuConfig.value === url);
      const exist = filtered.length > 0;
      if (exist) return sku;
      return '';
    });
    return byUrl ? byUrl.filter(sku => sku) : [];
  }

  filterForText = (text, skusToFilter) => {
    const byText = skusToFilter.map(sku => {
      const filtered = sku.config.filter(skuConfig => skuConfig.idType === 3 && skuConfig.value === text);
      const exist = filtered.length > 0;
      if (exist) return sku;
      return '';
    });
    return byText ? byText.filter(sku => sku) : [];
  }

  filterGeneral = (value, typeFilter) => {
    const { skus } = this.state;
    let {
      selectedColor, selectedText, selectedUrl, configurations,
    } = this.state;
    let skusArrayFiltered = JSON.parse(JSON.stringify(skus));
    if (typeFilter === 1) {
      selectedColor = value;
      selectedText = '';
      skusArrayFiltered = JSON.parse(JSON.stringify(skus));
    }
    if (typeFilter === 2) selectedUrl = value;
    if (typeFilter === 3) selectedText = value;
    if (selectedColor) skusArrayFiltered = this.filterForColor(selectedColor, skusArrayFiltered);
    if (selectedUrl) skusArrayFiltered = this.filterForUrl(selectedUrl, skusArrayFiltered);
    if (selectedText) skusArrayFiltered = this.filterForText(selectedText, skusArrayFiltered);
    if (skusArrayFiltered && skusArrayFiltered.length > 0) {
      this.handleChangeSelected(skusArrayFiltered[0]);
      if (typeFilter === 1) {
        const newConfig = this.handleGetConfigurations(skusArrayFiltered).Texto;
        if (newConfig) newConfig[0].checked = 'check';
        configurations = { Color: configurations.Color, Texto: newConfig };
      }
    }
    this.setState({
      selectedColor, selectedText, selectedUrl, configurations,
    });
  }

  handleChangeConfig = item => {
    const { configurations, selectedText } = this.state;
    let Color = !configurations || !configurations.Color ? [] : configurations.Color;
    let Texto = !configurations || !configurations.Texto ? [] : configurations.Texto;
    let Imagen = !configurations || !configurations.Imagen ? [] : configurations.Imagen;
    switch (item.id) {
      case 1:
        Color = configurations.Color.map(color => ({ ...color, checked: color.value === item.value }));
        if (configurations && configurations.Texto && selectedText) configurations.Texto = configurations.Texto.map(text => ({ ...text, checked: false }));
        this.filterGeneral(item.value, 1);
        break;
      case 2:
        Imagen = configurations.Imagen.map(image => ({ ...image, checked: image.value === item.value }));
        this.filterGeneral(item.value, 2);
        break;
      case 3:
        this.filterGeneral(item.value, 3);
        Texto = configurations.Texto.map(text => ({ ...text, checked: text.value === item.value }));
        break;
      default:
        break;
    }
    const newConfigurations = {
      ...configurations, Color, Texto, Imagen,
    };
    this.setState({ configurations: newConfigurations });
  }

  handleGetConfigurations = skus => {
    const allConfig = [];
    skus.map(sku => {
      sku.config.map(item => {
        const itemFound = allConfig.filter(itemConfig => (itemConfig.id === item.idType) && (itemConfig.value === item.value));
        const exist = itemFound && itemFound.length > 0;
        if (!exist) allConfig.push({ id: item.idType, ...item, checked: false });
        return item;
      });
      return sku;
    });
    const configurations = groupBy(allConfig, 'name');
    if (configurations && configurations.Color) configurations.Color[0].checked = true;
    if (configurations && !configurations.Color && configurations.Texto) configurations.Texto[0].checked = true;
    if (configurations && !configurations.Color && !configurations.Texto && configurations.Imagen) configurations.Imagen[0].checked = true;
    return configurations;
  }

  handleChangeSelected = item => {
    const { skus, isVirtual } = this.state;
    let hasImagesValidation = false;
    let hasStockValidation = false;
    const {
      name: productName, maker, sku, price, outlet, promotionPrice, description,
      features, specifications, box: inBox, expirePromo: promotionDeadline,
      currencyPrice,
    } = item;
    if (item.images && item.images.length === 0) hasImagesValidation = true;
    if (!item.stock || item.stock.length === 0) hasStockValidation = true;
    const arrayImages = item.images ? item.images.slice(0, 7).map(image => image.url) : [];
    detailGA(item);
    const hasDescription = Boolean(item.description);
    this.setState({
      product: item,
      productName,
      maker,
      productId: encodeURIComponent(sku),
      sku,
      price,
      outletStock: outlet.stock,
      promotionPrice,
      description: this.setDescriptionFormat(hasDescription ? description : productName),
      features,
      specifications,
      inBox,
      promotionDeadline,
      currencyPrice,
      hasNotImages: hasImagesValidation,
      hasDescription: true,
      hasNotStock: hasStockValidation,
      skus,
      isVirtual,
      arrayImages,
    });
    if (item.specifications && item.specifications.length === 0) this.handleGetCsSpecifications(sku);
    if (item.description === '') this.handleGetDescriptionBySku(sku);
    if (item.stock) this.getStock(item.stock, maker.toLowerCase());
    if (item.stockOC) this.getStockOc(item.stockOC);
    if (item.tieredPricing && item.currencyPrice) {
      this.handleFormatSteppedPrice(item.tieredPricing, item.currencyPrice);
    }
    this.getArrayRelatedProducts(item.sku);
  }

  handleSelection = (e, { name }) => this.setState({ activeItem: name });

  handleChangeItem = async ({ sku, allowVariants }) => {
    this.setState({
      productName: '',
      hasNotImages: false,
      arrayImages: [],
      isVariant: allowVariants,
    });
    await this.getArrayProducts(sku, allowVariants);
  }

  generateDescription = description => (
    description.map(descriptionFormated => {
      if (descriptionFormated.type === 'title') {
        return (
          <Message.Header>
            {descriptionFormated.paragraph}
          </Message.Header>
        );
      }
      if (descriptionFormated.type === 'lineBreak') {
        return <br />;
      }
      return <Message.Content>{descriptionFormated.paragraph}</Message.Content>;
    })
  );

  getQuantityLimitText = () => {
    const { t } = this.props;
    const { minQuantity, maxQuantity } = this.state;
    if (minQuantity > 0 && maxQuantity > 0) {
      return t('products.minAndMaxQuantity', { max: maxQuantity, min: minQuantity });
    }
    if (minQuantity > 0) {
      return t('products.minQuantity', { min: minQuantity });
    }
    return t('products.maxQuantity', { max: maxQuantity });
  };

  render() {
    const {
      discountSerialsHeaders, description, appearHome, productId, outletStock, maker, product, hasRequiredProduct, promotion,
      arrayImages, productName, sku, sku2, price, promotionPrice, features, specifications, inBox, promotionDeadline, reasonsToBuy,
      arrayObjectStock, arrayObjectStockOc, deliverDate, updatedarrayRelatedProduct, currencyPrice, featureLogos, videos,
      visible, errorMessage, typeMessage, steppedPriceHp, makersPercents, arrayBundleOptions, promotionalMessage, arrayRequiredProducts,
      requiredId, loadingVmware, arraySubstituteProducts, hasNotImages, hasDescription, hasNotStock, isVirtual, configurations, activeItem,
      descriptionBySku, minQuantity, maxQuantity,
      isAvailableRedhat, variants, isVariant,
    } = this.state;
    const { t, updateCartCount } = this.props;
    let componentView;
    if (activeItem === 'description') {
      componentView = (
        <Grid.Row style={{ paddingTop: '2rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
          <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment>
              <Label attached="top">
                <Header as="h3" textAlign="center" color="blue">
                  {t('productDetails.description')}
                </Header>
              </Label>
              <Grid.Row style={{ paddingTop: '2rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
                <Message fluid>
                  {hasDescription
                    ? this.generateDescription(description)
                    : (
                      <Placeholder fluid>
                        <Placeholder.Paragraph>
                          <Placeholder.Line full />
                          <Placeholder.Line full />
                          <Placeholder.Line full />
                          <Placeholder.Line full />
                        </Placeholder.Paragraph>
                      </Placeholder>
                    )}
                </Message>
              </Grid.Row>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    }
    if (activeItem === 'features') {
      componentView = (
        <Grid.Row style={{ paddingTop: '2rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
          <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment>
              <Label attached="top">
                <Header as="h3" textAlign="center" color="blue">
                  {t('productDetails.features')}
                </Header>
              </Label>
              <Grid.Row style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
                <Features features={features} />
              </Grid.Row>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    }
    if (activeItem === 'specifications') {
      componentView = (
        <Grid.Row style={{ paddingTop: '2rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
          <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment padded="very">
              <Label attached="top">
                <Header as="h3" textAlign="center" color="blue">
                  {t('productDetails.specifications')}
                </Header>
              </Label>
              <Specifications specifications={specifications} inBox={inBox} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      );
    }
    if (activeItem === 'reasonsToBuy') {
      if (reasonsToBuy && reasonsToBuy.length > 0) {
        componentView = <ReasonsToBuy reasonsToBuy={reasonsToBuy} />;
      }
    }

    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={8} tablet={15} mobile={16}>
            <ImageComponent arrayImages={arrayImages} hasNotImages={hasNotImages} maker={product.brand} logoMaker={product.brandLogo} />
            <Divider hidden />
            {((featureLogos && featureLogos.length > 0) || (videos && videos.length > 0)) && (
              <Multimedia
                featureLogos={featureLogos}
                videos={videos}
              />
            )}
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16} className="padding-header">
            <Segment>
              <DescriptionComponent
                configurations={configurations}
                isVirtual={isVirtual}
                arrayObjectStock={arrayObjectStock}
                arrayObjectStockOc={arrayObjectStockOc}
                deliverDate={deliverDate}
                arrayRequiredProducts={arrayRequiredProducts}
                arraySubstituteProducts={arraySubstituteProducts}
                currencyPrice={currencyPrice}
                updateCartCount={updateCartCount}
                handleChangeConfig={this.handleChangeConfig}
                hasNotStock={hasNotStock}
                hasRequiredProduct={hasRequiredProduct}
                loadingVmware={loadingVmware}
                maker={maker}
                makersPercents={makersPercents}
                price={price}
                product={product}
                productId={productId}
                productName={productName}
                promotionDeadline={promotionDeadline}
                promotionPrice={promotionPrice}
                promotionalMessage={promotionalMessage}
                requiredId={requiredId}
                sku={sku}
                sku2={sku2}
                steppedPriceHp={steppedPriceHp}
                t={t}
                variants={variants}
                promotion={promotion}
                isAvailableRedhat={isAvailableRedhat}
                onChangeItem={this.handleChangeItem}
                descriptionBySku={descriptionBySku}
              />
              {(minQuantity > 0 || maxQuantity > 0) && (
                <Grid padded>
                  <Grid.Column>
                    <Message warning>
                      <Icon name="warning" />
                      {this.getQuantityLimitText()}
                    </Message>
                  </Grid.Column>
                </Grid>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Transition.Group animation="fly down" duration={{ hide: 0, show: 5000 }}>
              {
                visible
                  ? (
                    <Grid.Row>
                      <Message negative size="small" color={typeMessage} onDismiss={this.handleDismiss} content={errorMessage} />
                    </Grid.Row>
                  ) : null
              }
            </Transition.Group>
            {
              !outletStock.length
                ? null
                : (
                  <Grid.Row>
                    <Grid.Column centered style={{ overflowX: 'auto' }} largeScreen={16} computer={16} tablet={16} mobile={16}>
                      <Segment>
                        <Label attached="top">
                          <Header as="h3" textAlign="center" color="blue">
                            {t('productDetails.discountSerials')}
                          </Header>
                        </Label>
                        <SiclikTable
                          selectable
                          data={outletStock.map(stock => ({
                            sku,
                            warehouse: t(`availability.warehouses.${stock.warehouse}`),
                            stock: stock.quantity,
                            reason: stock.comments,
                            price: stock.price,
                          }))}
                          headers={discountSerialsHeaders}
                          pageSize={5}
                        />
                      </Segment>
                    </Grid.Column>
                  </Grid.Row>
                )
            }
            {
              (arrayBundleOptions.length === 0)
                ? null
                : (
                  <Grid.Row>
                    <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
                      <Label>
                        <Header as="h4" textAlign="center" color="blue">
                          Componentes
                        </Header>
                      </Label>
                      <BundleOptions arrayBundleOptions={arrayBundleOptions} />
                    </Grid.Column>
                  </Grid.Row>
                )
            }
            {
              (updatedarrayRelatedProduct.length === 0)
                ? null
                : (
                  <CSSTransition
                    in={appearHome}
                    appear
                    timeout={2000}
                    classNames="fade"
                  >
                    <Grid.Row centered>
                      <Grid.Column centered largeScreen={16} computer={16} tablet={16} mobile={16}>
                        <Segment style={{ padding: '1rem 0' }}>
                          <Label attached="top">
                            <Header as="h3" textAlign="center" color="blue">
                              {t('productDetails.related')}
                            </Header>
                          </Label>
                          <Grid.Row>
                            <RelatedProducts
                              relatedProductsArray={updatedarrayRelatedProduct}
                              tr={t}
                              goToProduct={this.goToProduct}
                              currencyPrice={currencyPrice}
                              getShoppingCartCount={updateCartCount}
                              isAvailableRedhat={isAvailableRedhat}
                              maker={maker}
                            />
                          </Grid.Row>
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </CSSTransition>
                )
            }
            <Menu fluid attached="top" tabular stackable color="blue" style={{ paddingTop: '1.5rem', paddingRight: '2rem', paddingLeft: '2rem' }}>
              <Menu.Item
                name="description"
                active={activeItem === 'description'}
                onClick={this.handleSelection}
                content={t('productDetails.description')}
              />
              {
                (features == null || features.length === 0)
                  ? null
                  : (
                    <Menu.Item
                      name="features"
                      active={activeItem === 'features'}
                      onClick={this.handleSelection}
                      content={t('productDetails.features')}
                    />
                  )
              }
              {
                (!specifications.length)
                  ? null
                  : (
                    <Menu.Item
                      name="specifications"
                      active={activeItem === 'specifications'}
                      onClick={this.handleSelection}
                      content={t('productDetails.specifications')}
                    />
                  )
              }
              {
                (!reasonsToBuy.length)
                  ? null
                  : (
                    <Menu.Item
                      name="reasonsToBuy"
                      active={activeItem === 'reasonsToBuy'}
                      onClick={this.handleSelection}
                      content={t('productDetails.reasonsToBuy')}
                    />
                  )
              }
            </Menu>
            {componentView}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

ProductDetails.defaultProps = {
  configurations: {
    Color: [],
    Imagen: [],
    Texto: [],
    length: 0,
  },
  sku2: '',
  t: () => { },
};

ProductDetails.propTypes = {
  configurations: PropTypes.shape({
    Color: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Imagen: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    Texto: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    length: PropTypes.number,
  }),
  currencyPrice: PropTypes.string.isRequired,
  updateCartCount: PropTypes.func.isRequired,
  isVirtual: PropTypes.bool.isRequired,
  maker: PropTypes.string.isRequired,
  makersPercents: PropTypes.shape({}).isRequired,
  price: PropTypes.number.isRequired,
  promotionPrice: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  sku2: PropTypes.string,
  name: PropTypes.string.isRequired,
  t: PropTypes.func,
};

export default ProductDetails;
