import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Image,
  Button,
  Segment,
  Header,
  Card,
  Label,
  Container,
  Modal,
} from 'semantic-ui-react';

import { getContentSource } from '../../commons';
import { CONTENT_SOURCE } from '../../constants';

const translatePath = 'productConfig.menus.editContent';

const setImagesValues = (allImages, images, source) => (
  images.map(image => ({
    ...image,
    selected: allImages.some(img => img.source === source
      && img.value.id === image.id),
  }))
);

function EditContentImages(props) {
  const {
    t,
    currentImages,
    icecatImages,
    erpImages,
    isImageType,
    onChangeContent,
  } = props;
  const [icecatArray, setIcecatArray] = useState([]);
  const [erpArray, setErpArray] = useState([]);
  const [modalMessage, setModalMessage] = useState(false);

  const handleOnRemoveImage = removedImage => {
    if ((isImageType && !removedImage.value.isMain) || !isImageType) {
      onChangeContent(
        currentImages.filter(image => image.value.id !== removedImage.value.id),
        isImageType ? 'images' : 'featuredLogos',
      );
      if (removedImage.source === CONTENT_SOURCE.CONTENT_PROVIDER) {
        setIcecatArray(icecatArray.map(image => ({
          ...image,
          selected: image.id === removedImage.value.id ? false : image.selected,
        })));
      }
      if (removedImage.source === CONTENT_SOURCE.ERP) {
        setErpArray(erpArray.map(image => ({
          ...image,
          selected: image.id === removedImage.value.id ? false : image.selected,
        })));
      }
    } else {
      setModalMessage(true);
    }
  };

  const handleOnSelectImage = (image, source) => {
    const hasItemImages = currentImages.length;
    const newImageValues = [
      ...currentImages, {
        source,
        value: {
          ...image,
          isMain: !hasItemImages,
        },
      },
    ];
    onChangeContent(newImageValues, isImageType ? 'images' : 'featuredLogos');
    if (source === CONTENT_SOURCE.CONTENT_PROVIDER) {
      setIcecatArray(setImagesValues(
        newImageValues,
        icecatArray,
        source,
      ));
    }
    if (source === CONTENT_SOURCE.ERP) {
      setErpArray(setImagesValues(
        newImageValues,
        erpArray,
        source,
      ));
    }
  };

  useEffect(() => {
    setIcecatArray(setImagesValues(
      currentImages,
      icecatImages,
      CONTENT_SOURCE.CONTENT_PROVIDER,
    ));
    setErpArray(setImagesValues(
      currentImages,
      erpImages,
      CONTENT_SOURCE.ERP,
    ));
  }, [icecatImages, currentImages, erpImages]);

  return (
    <div>
      <Container>
        <Header as="h5" color="green">{t(`${translatePath}.contentOptions.current`)}</Header>
        <Segment>
          {currentImages.length
            ? (
              <Card.Group doubling itemsPerRow={5}>
                {currentImages.map(image => (
                  <Card key={image.value.id}>
                    <Card.Content textAlign="center">
                      <Image size="small" src={image.value.low} />
                    </Card.Content>
                    <Card.Content extra>
                      <Label size="mini">{getContentSource(image.source, t)}</Label>
                      {(image.value.isMain && isImageType) && (
                        <Label color="green" size="mini">{t(`${translatePath}.images.mainImage`)}</Label>
                      )}
                      <Button
                        primary
                        floated="right"
                        icon="trash"
                        size="mini"
                        onClick={() => handleOnRemoveImage(image)}
                      />
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            ) : <p>{t(`${translatePath}.images.noImagesSelected`)}</p>}
        </Segment>
        <Header as="h5" color="green">{t(`${translatePath}.contentOptions.contentProvider`)}</Header>
        <Segment>
          {icecatArray.length
            ? (
              <Card.Group doubling itemsPerRow={5}>
                {icecatArray.map(image => (
                  <Card key={image.id}>
                    <Card.Content textAlign="center">
                      <Image size="small" src={image.low} />
                    </Card.Content>
                    <Card.Content extra>
                      <Button
                        primary
                        fluid
                        floated="right"
                        size="mini"
                        disabled={image.selected}
                        content={image.selected
                          ? t(`${translatePath}.images.selectedImage`)
                          : t(`${translatePath}.images.selectImage`)}
                        onClick={() => handleOnSelectImage(image, CONTENT_SOURCE.CONTENT_PROVIDER)}
                      />
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            ) : <p>{t(`${translatePath}.images.noImages`)}</p>}
        </Segment>
        <Header as="h5" color="green">{t(`${translatePath}.contentOptions.erp`)}</Header>
        <Segment>
          {erpArray.length
            ? (
              <Card.Group doubling itemsPerRow={5}>
                {erpArray.map(image => (
                  <Card key={image.id}>
                    <Card.Content textAlign="center">
                      <Image size="small" src={image.low} />
                    </Card.Content>
                    <Card.Content extra>
                      <Button
                        primary
                        fluid
                        floated="right"
                        size="mini"
                        disabled={image.selected}
                        content={image.selected
                          ? t(`${translatePath}.images.selectedImage`)
                          : t(`${translatePath}.images.selectImage`)}
                        onClick={() => handleOnSelectImage(image, CONTENT_SOURCE.ERP)}
                      />
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
            ) : <p>{t(`${translatePath}.images.noImages`)}</p>}
        </Segment>
      </Container>
      <Modal
        centered
        onClose={() => setModalMessage(false)}
        open={modalMessage}
        size="small"
      >
        <Modal.Header>{t(`${translatePath}.images.modalWarning.title`)}</Modal.Header>
        <Modal.Content>
          <p>{t(`${translatePath}.images.modalWarning.message`)}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t(`${translatePath}.images.modalWarning.close`)}
            onClick={() => setModalMessage(false)}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
}

EditContentImages.propTypes = {
  isImageType: PropTypes.bool,
  onChangeContent: PropTypes.func.isRequired,
  currentImages: PropTypes.arrayOf(PropTypes.shape({
    source: PropTypes.string,
    value: PropTypes.shape({
      id: PropTypes.string,
      low: PropTypes.string,
    }),
  })),
  icecatImages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    low: PropTypes.string,
  })),
  erpImages: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    low: PropTypes.string,
  })),
};

EditContentImages.defaultProps = {
  isImageType: false,
  currentImages: [],
  icecatImages: [],
  erpImages: [],
};

export default translate('common', { wait: true })(EditContentImages);
