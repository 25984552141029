import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { Dropdown, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Toast from '../../common/components/toast';
import { requestAllBrands } from '../services';

function BrandList(props) {
  const { t, handleSelectedBrand, error } = props;
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoader(true);
        const { brands } = await requestAllBrands();
        const setBrandsFormat = brands.map(brand => ({
          key: brand.id,
          value: brand.name,
          text: brand.name,
        }));
        setBrandList(setBrandsFormat);
      } catch (err) {
        Toast(t('toast.error.general'), 'error');
      } finally {
        setLoader(false);
      }
    };
    getBrands();
  }, [t]);

  const handleBrand = (e, { value }) => {
    setSelectedBrand(value);
    handleSelectedBrand(value);
  };

  return (
    <Form.Group widths="equal">
      <Form.Field error={error}>
        <label htmlFor="a">{t('synchronization.brandList.title')}</label>
        <Dropdown
          placeholder={t('synchronization.brandList.placeholder')}
          options={brandList}
          value={selectedBrand}
          onChange={handleBrand}
          loading={loader}
          fluid
          selection
        />
      </Form.Field>
    </Form.Group>
  );
}

BrandList.propTypes = {
  handleSelectedBrand: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(BrandList);
