import React, { Component } from 'react';
import {
  Input, Grid, Accordion, Icon, Checkbox, Table,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

class SerialsProducts extends Component {
  static getDerivedStateFromProps(props, state) {
    const { billingData } = props;
    if (billingData !== state.filteredSerials) {
      return {
        billingData,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      filteredSerials: [],
      billingData: [],
      filterError: '',
    };
  }

  handleClickChange = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  handleInputFilterChange = (e, { value: filter, id: sku }) => {
    const { handleGetFilterValue } = this.props;
    handleGetFilterValue(sku, filter);
  }

  render() {
    const {
      tr: t, currentActions, isMultibrand, handleSelectAllSerials, handleSelectSerial,
    } = this.props;
    const { activeIndex, filterError, billingData } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Accordion fluid styled className="accordion-container">
            {billingData.map((detail, index) => (
              <Grid.Row>
                <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClickChange} color="blue">
                  <Icon name="dropdown" />
                  {`${detail.sku} - ${detail.productName}`}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  <Grid.Row>
                    <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                      <Input
                        style={{ maxWidth: '50%' }}
                        id={detail.sku}
                        fluid
                        value={detail.filter}
                        error={filterError}
                        label={t('Buscar por serie')}
                        onChange={this.handleInputFilterChange}
                      />
                      <Grid.Row>
                        <p style={{ color: 'red' }}>{filterError}</p>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid.Row>
                </Accordion.Content>
                <Accordion.Content active={activeIndex === index}>
                  <Checkbox
                    toggle
                    label="Seleccionar todas"
                    name={detail.sku}
                    // checked={item.checked}
                    onChange={handleSelectAllSerials}
                  />
                </Accordion.Content>
                <Accordion.Content active={activeIndex === index}>
                  <Table celled selectable compact definition>
                    <Table.Header fullWidth active>
                      <Table.Row>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Series</Table.HeaderCell>
                        <Table.HeaderCell>Estatus</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    {detail.serials.map(item => (
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell collapsing>
                            <Checkbox
                              slider
                              id={item.index}
                              name={detail.sku}
                              checked={item.checked}
                              onChange={handleSelectSerial}
                              disabled={item.status === 'Enrolado' && currentActions === 'Enrolar' && !isMultibrand}
                            />
                          </Table.Cell>
                          <Table.Cell>{item.serial}</Table.Cell>
                          {
                            !isMultibrand
                              ? <Table.Cell>{item.status ? item.status : 'Sin enrolar'}</Table.Cell>
                              : ''
                          }

                        </Table.Row>
                      </Table.Body>
                    ))}
                  </Table>
                </Accordion.Content>
              </Grid.Row>
            ))}
          </Accordion>
        </Grid.Row>
      </Grid>
    );
  }
}
SerialsProducts.defaultProps = {
  handleGetFilterValue: () => {},
  billingData: [],
};
SerialsProducts.propTypes = {
  currentActions: PropTypes.string.isRequired,
  handleGetFilterValue: PropTypes.func,
  handleSelectAllSerials: PropTypes.func.isRequired,
  handleSelectSerial: PropTypes.func.isRequired,
  isMultibrand: PropTypes.bool.isRequired,
  tr: PropTypes.func.isRequired,
  billingData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default translate('common', { wait: true })(SerialsProducts);
