import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';
import testData from '../test-data';

const quoteService = {};

quoteService.getLeaseQuote = () => testData.quickQuoteResponse;

quoteService.checkout = body => (
  request({
    route: apiRoutes.checkout(),
    method: 'POST',
    body,
  })
);

quoteService.getPaymentMethods = () => (
  request({
    route: apiRoutes.getPaymentMethods(),
    methiod: 'GET',
  })
);

quoteService.saveQuote = body => (
  request({
    route: apiRoutes.saveLeaseQuote(),
    method: 'POST',
    body,
  })
);

export default quoteService;
