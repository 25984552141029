import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Icon, Image, Divider,
} from 'semantic-ui-react';
import routes from '../../route-names';

const urlAWS = process.env.REACT_APP_URL_AWS;
const paymentsImage = `${urlAWS}/imagenes/assets/brands/formas_pago.png`;
const pdfPath = pdfName => routes.pdfViewer.route.replace(':name?', pdfName);

function IconLink(props) {
  const {
    label, url, color, style, size, iconName, background,
  } = props;
  return (
    <a style={{ style }} href={url} className={`${background}Text`} rel="noreferrer">
      <Icon
        size={size}
        circular
        color={color}
        className={`${background}Background`}
        name={iconName}
      />
      <span style={{ paddingRight: '.5em', paddingLeft: '.5em' }}>{label}</span>
    </a>
  );
}

IconLink.propTypes = {
  color: PropTypes.string,
  background: PropTypes.string,
  label: PropTypes.string,
  iconName: PropTypes.string,
  style: PropTypes.shape({}),
  url: PropTypes.string,
  size: PropTypes.string,
};

IconLink.defaultProps = {
  color: 'blue',
  background: 'white',
  size: 'large',
  label: '',
  iconName: '',
  style: {},
  url: '',
};
function StackableLink(props) {
  const {
    label, url, color, style, size, hideDivider, targetProp,
  } = props;
  return (
    <Grid.Row style={{ paddingTop: '.2em' }}>
      <Header size={size}>
        <a style={style} href={url} className={`${color}Text`} target={targetProp} rel="noreferrer">
          {label}
        </a>
        {
          hideDivider || <Divider style={{ margin: '0.3em 0' }} />
        }
      </Header>
    </Grid.Row>
  );
}

StackableLink.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  targetProp: PropTypes.string,
  style: PropTypes.shape({}),
  url: PropTypes.string,
  size: PropTypes.string,
  hideDivider: PropTypes.bool,
};

StackableLink.defaultProps = {
  color: 'white',
  size: 'small',
  hideDivider: false,
  label: '',
  targetProp: '_blank',
  style: {},
  url: '',
};

function Footer(props) {
  const { t } = props;
  const colorTextFooter = {
    color: '#64a2d9',
  };
  return (
    <Grid padded>
      <Grid.Row color="blue" className="no-padding-y">
        <Grid.Column
          largeScreen={16}
          computer={16}
          tablet={16}
          mobile={16}
          style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
        />
      </Grid.Row>
      <Grid.Row textAlign="left" color="blue" centered className="no-padding-y">
        <Grid.Column
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
          largeScreen={3}
          computer={3}
          tablet={7}
          mobile={10}
          verticalAlign="top"
        >
          <StackableLink label={t('footer.public.us')} url="https://www.compusoluciones.com/" targetProp="_blank" />
          <StackableLink label={t('footer.public.solutions')} url="https://www.compusoluciones.com/soluciones/" targetProp="_blank" />
          <StackableLink label={t('footer.public.alliance')} url="https://www.compusoluciones.com/alianzas/" targetProp="_blank" />
          <StackableLink label={t('footer.public.blog')} url="https://www.compusoluciones.com/tendencias/" targetProp="_blank" />
          <StackableLink label={t('footer.public.beClient')} url="https://appsd.compusoluciones.com/csAltaClientesVer2/Cliente/SolicitudAlta.aspx" targetProp="_blank" />
          <StackableLink label={t('footer.public.privacy')} url="https://www.compusoluciones.com/aviso-de-privacidad" targetProp="_blank" />
        </Grid.Column>
        <Grid.Column
          largeScreen={3}
          computer={3}
          tablet={7}
          mobile={10}
          verticalAlign="top"
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <StackableLink label={t('footer.public.terms')} url={pdfPath('terms')} targetProp="_blank" />
          <StackableLink label={t('footer.public.payments')} url={pdfPath('formasdepago')} targetProp="_blank" />
          <StackableLink label={t('footer.public.shipping')} url={pdfPath('metodosenvio')} targetProp="_blank" />
          <StackableLink label={t('footer.public.returnsAndCancellations')} url={pdfPath('devoluciones')} targetProp="_blank" />
          <StackableLink label="PROFECO" url="https://www.gob.mx/profeco" targetProp="_blank" />
          <StackableLink label="CONDUSEF" url="https://www.gob.mx/condusef" targetProp="_blank" />
        </Grid.Column>
        <Grid.Column
          largeScreen={3}
          computer={3}
          tablet={7}
          mobile={10}
          verticalAlign="top"
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <Header size="small" color="white">{t('footer.public.gdl.name')}</Header>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.gdl.address')}</p>
          <p style={colorTextFooter}>{t('footer.public.gdl.phone')}</p>
          <Header size="small" color="white">{t('footer.public.cdmx.name')}</Header>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.cdmx.address')}</p>
          <p style={colorTextFooter}>{t('footer.public.cdmx.phone')}</p>
          <Header size="small" color="white">{t('footer.public.mty.name')}</Header>
          <p className="no-margin-bottom" style={colorTextFooter}>{t('footer.public.mty.address')}</p>
        </Grid.Column>
        <Grid.Column
          largeScreen={4}
          computer={4}
          tablet={7}
          mobile={10}
          verticalAlign="top"
          style={{ paddingBottom: '2rem', paddingTop: '2rem' }}
        >
          <Header color="white" size="small">
            {t('footer.public.officeHours')}
          </Header>
          <p className="no-padding-bottom no-margin-bottom" style={colorTextFooter}>{t('footer.public.schedule')}</p>
          <p style={colorTextFooter}>{t('footer.public.email')}</p>
          <Grid style={{ paddingTop: '1rem' }}>
            <Grid.Row style={{ paddingTop: '1rem' }} columns="equal">
              <Grid.Column>
                <IconLink iconName="facebook f" label="Facebook" url="https://www.facebook.com/CompuSolucionesyAsociados" />
              </Grid.Column>
              <Grid.Column>
                <IconLink iconName="twitter" label="Twitter" url="https://twitter.com/CompuSoluciones" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ paddingTop: '1rem' }} columns="equal">
              <Grid.Column>
                <IconLink iconName="linkedin" label="LinkedIn" url="https://www.linkedin.com/company/compusoluciones" />
              </Grid.Column>
              <Grid.Column>
                <IconLink iconName="youtube" label="YouTube" url="https://www.youtube.com/user/CanalCompuSoluciones" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered className="no-padding-y fullBlackBackground">
        <Grid.Column
          widescreen={8}
          computer={8}
          tablet={16}
          mobile={16}
          textAlign="center"
          verticalAlign="top"
        >
          <Image
            fluid
            src={paymentsImage}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered className="no-padding-y midWhiteBackground">
        <Grid.Column
          largeScreen={14}
          computer={14}
          tablet={16}
          mobile={16}
          style={{ paddingBottom: '1rem', paddingTop: '1rem' }}
          textAlign="center"
          verticalAlign="top"
        >
          <Header size="tiny" color="grey">
            {t('footer.public.allRightsReserved')}
          </Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(Footer);
