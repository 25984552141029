import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';

import {
  Tab,
  TabPane,
  Grid,
  Divider,
  Header,
  Button,
} from 'semantic-ui-react';

import TabEnv from './TabEnv';

import toast from '../../../common/components/toast';
import request from '../../services';
import routeNames from '../../../route-names';

function ServiceAccounts({ t }) {
  const [serviceAccounts, setServiceAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [env, setEnv] = useState('');

  useEffect(() => {
    async function fetchUsers() {
      try {
        const usersData = await request.getServiceAccounts(env);
        setServiceAccounts(usersData.serviceAccounts);
        setLoading(true);
      } catch (error) {
        setServiceAccounts([]);
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        toast(messageError, 'error');
        setLoading(true);
      }
    }
    fetchUsers();
  }, [t, env]);

  const handleTabChange = (e, { activeIndex }) => {
    try {
      const selectedEnv = activeIndex === 0 ? '' : 'staging';
      setEnv(selectedEnv);
      setLoading(true);
      const response = request.getServiceAccounts(env);
      setServiceAccounts(response.serviceAccounts);
    } catch (error) {
      setServiceAccounts([]);
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      toast(messageError, 'error');
    } finally {
      setLoading(false);
    }
  };

  const goToTestApi = () => {
    window.open(routeNames.testApisSiclik.route);
  };

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('user.serviceAccount.title')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider className="margin-divider" />
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <p>{t('user.serviceAccount.description')}</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Tab
            menu={{ secondary: true, pointing: true }}
            onTabChange={handleTabChange}
            renderActiveOnly
            panes={[
              {
                menuItem: 'Producción',
                render: () => (
                  <TabPane loading={!loading} attached={false}>
                    <TabEnv
                      env={env}
                      serviceAccounts={serviceAccounts}
                    />
                  </TabPane>
                ),
              },
              {
                menuItem: 'SandBox',
                render: () => (
                  <TabPane loading={!loading} attached={false}>
                    <Button
                      style={{
                        marginTop: '1rem', marginLeft: '1rem', marginBottom: '1rem', width: '20%',
                      }}
                      color="blue"
                      primary
                      icon="external alternate"
                      content={t('user.serviceAccount.testApi')}
                      onClick={goToTestApi}
                    />
                    <TabEnv
                      env={env}
                      serviceAccounts={serviceAccounts}
                    />
                  </TabPane>
                ),
              },
            ]}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

export default translate('common', { wait: true })(ServiceAccounts);
