/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import find from 'lodash/find';
import {
  Segment,
  Grid,
  Header,
  Menu,
  Container,
  Checkbox,
  Divider,
  Message,
} from 'semantic-ui-react';

import MenuSync from './menu-sync';
import MenuBar from './menu-bar';

import Toast from '../../../common/components/toast';
import ItemsList from './item-list';
import { CONTENT_SOURCE } from '../../constants';

import service from '../../services';

const translatePath = 'productConfig.menus.chooseContent';
const pageSize = 10;

function ChooseContent(props) {
  const { t, onChangeMenu } = props;
  const [brandList, setBrandList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isLoadingitems, setIsLoadingitems] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [selectedContentProvider, setSelectedContentProvider] = useState('');
  const [brandItems, setBrandItems] = useState([]);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [allItemsSelected, setallItemsSelected] = useState(false);
  const [indeterminateCheck, setIndeterminateCheck] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState('');
  const [selectedItemCount, setSelectedItemCount] = useState(0);
  const [fields, setFields] = useState({
    all: true,
    title: false,
    description: false,
    category: false,
    images: false,
  });

  const options = [
    { key: 1, text: t(`${translatePath}.itemListActions.options.erp`), value: CONTENT_SOURCE.ERP },
    { key: 2, text: t(`${translatePath}.itemListActions.options.contentProvider`), value: CONTENT_SOURCE.CONTENT_PROVIDER },
  ];

  useEffect(() => {
    const getBrands = async () => {
      try {
        setLoader(true);
        const { brands } = await service.requestAllBrands();
        const setBrandsFormat = brands.map(brand => ({
          key: brand.id,
          value: brand.name,
          text: brand.name,
        }));
        setBrandList(setBrandsFormat);
        setFilter('');
      } catch (err) {
        Toast(t('toast.error.general'), 'error');
      } finally {
        setLoader(false);
      }
    };
    getBrands();
  }, [t]);

  const setItemsPage = (items, pageNumber = 1) => {
    const numberItems = items.length;
    const newCurrentPage = pageNumber + 1;
    setTotalPages(numberItems > pageSize ? Math.ceil(numberItems / pageSize) : 1);
    setPaginatedItems(chunk(items, pageSize)[pageNumber]);
    setActivePage(newCurrentPage);
  };

  const handleOnFilterBrand = async brand => {
    try {
      setallItemsSelected(false);
      setIndeterminateCheck(false);
      setIsLoadingitems(true);
      setSelectedItemCount(0);
      const { items } = await service.getItemsByBrand(brand || selectedBrand);
      const itemsFormat = items.map(item => ({
        ...item,
        selected: false,
      }));
      setBrandItems(itemsFormat);
      setItemsPage(itemsFormat, 0);
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
      setBrandItems([]);
      setItemsPage([], 0);
    } finally {
      setIsLoadingitems(false);
    }
  };

  const syncContent = async (syncBy, syncValue) => {
    try {
      const fieldsNames = fields.all ? [] : Object.keys(fields).filter(key => fields[key]);
      setIsLoadingitems(true);
      await service.syncContentItems(selectedContentProvider, {
        syncBy,
        syncValue,
        fields: fieldsNames,
      });
      await handleOnFilterBrand();
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
    } finally {
      setIsLoadingitems(false);
    }
  };

  const handleOnSelectAllItems = (e, { checked }) => {
    const newItems = brandItems.map(item => ({
      ...item,
      selected: checked,
    }));
    setallItemsSelected(checked);
    setBrandItems(newItems);
    setItemsPage(newItems, page);
    setIndeterminateCheck(false);
    if (checked) {
      setSelectedItemCount(newItems.length);
    } else {
      setSelectedItemCount(0);
    }
  };

  const handlePaginationChange = (e, { activePage: newPage }) => {
    const currentPage = newPage - 1;
    setPage(currentPage);
    setItemsPage(brandItems, currentPage);
  };

  const handleOnSelectPaginatedItems = checkedItems => {
    const newItems = brandItems.map(item => {
      const itemChecked = find(checkedItems, { sku: item.sku });
      return itemChecked || item;
    });
    const selectedItems = newItems
      .filter(item => item.selected)
      .length;
    setSelectedItemCount(selectedItems);
    setBrandItems(newItems);
    setIndeterminateCheck(Boolean(selectedItems));
  };

  const handleFilter = ({ target }) => {
    const { value: keyword } = target;
    const baseFilter = keyword.toLowerCase();
    if (baseFilter) {
      const filteredItems = brandItems.filter(item => ((item.family.value && item.family.value.toLowerCase().includes(baseFilter))
        || (item.title.value && item.title.value.toLowerCase().includes(baseFilter))
        || (item.category.value && item.category.value.toLowerCase().includes(baseFilter))
        || (item.sku && item.sku.toLowerCase().includes(baseFilter))));
      setFilter(keyword);
      setItemsPage(filteredItems, 0);
    } else {
      setFilter(keyword);
      setItemsPage(brandItems);
    }
  };

  const handleSetItemsContent = () => {
    if (selectedContentProvider) {
      const isUpdateByBrand = allItemsSelected && !indeterminateCheck;
      if (isUpdateByBrand) {
        return syncContent('brand', selectedBrand);
      }
      const selectedItems = brandItems
        .filter(item => item.selected)
        .map(({ sku }) => sku);
      if (selectedItems.length) {
        return syncContent('skus', selectedItems);
      }
    }
    return null;
  };

  const handleOnSelectContentProvider = (e, { value }) => {
    setSelectedContentProvider(value);
  };

  const handleFieldsChange = (e, data) => {
    if (data.value === 'all') {
      setFields({
        all: true,
        title: false,
        description: false,
        category: false,
        images: false,
      });
    } else {
      setFields({
        ...fields,
        all: false,
        [data.value]: !fields[data.value],
      });
    }
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t(`${translatePath}.title`)}</Header>
          <Divider />
          <p>{t(`${translatePath}.description`)}</p>
          <Message size="small" style={{ textAlign: 'left' }}>
            <Message.Header>{t(`${translatePath}.notes.title`)}</Message.Header>
            <Message.List>
              <Message.Item>{t(`${translatePath}.notes.note1`)}</Message.Item>
              <Message.Item>{t(`${translatePath}.notes.note2`)}</Message.Item>
            </Message.List>
          </Message>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16} textAlign="center">
          <Segment style={{ backgroundColor: '#F5F5F5' }}>
            <Grid
              style={{
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: 'auto',
              }}
            >
              <Grid.Row>
                <MenuBar
                  disabled={selectedBrand === '' || isLoadingitems}
                  loader={loader}
                  brandList={brandList}
                  filter={filter}
                  onClickSelectedBrand={handleOnFilterBrand}
                  onChangeFilter={handleFilter}
                  onSelectBrand={setSelectedBrand}
                />
              </Grid.Row>
              <Grid.Row>
                <MenuSync
                  selectedContentProvider={selectedContentProvider}
                  onChangeContentProvider={handleOnSelectContentProvider}
                  onClickContentProvider={handleSetItemsContent}
                  disabledApplyButton={selectedItemCount === 0 || selectedBrand === '' || selectedContentProvider === ''}
                  onChangePagination={handlePaginationChange}
                  totalPages={totalPages}
                  activePage={activePage}
                  itemsCount={brandItems.length}
                  options={options}
                  loading={loader}
                  fields={fields}
                  onChangeFields={handleFieldsChange}
                />
                <Container fluid>
                  <Menu borderless size="tiny">
                    <Menu.Item>
                      <Checkbox
                        label={t(`${translatePath}.itemListActions.items.checkbox`)}
                        checked={allItemsSelected}
                        onClick={handleOnSelectAllItems}
                        indeterminate={indeterminateCheck}
                      />
                    </Menu.Item>
                    <Menu.Item position="right">
                      {selectedItemCount}
                      {' '}
                      {t(`${translatePath}.itemListActions.items.checkedItems`)}
                    </Menu.Item>
                  </Menu>
                </Container>
                <Container fluid textAlign="left">
                  <ItemsList
                    items={paginatedItems}
                    changeItemsChecked={handleOnSelectPaginatedItems}
                    isLoading={isLoadingitems}
                    onChangeMenu={onChangeMenu}
                  />
                </Container>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

ChooseContent.propTypes = {
  onChangeMenu: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ChooseContent);
