export const UPDATE_ITEM_QUANTITY = 'UPDATE_ITEM_QUANTITY';
export const ADD_ITEM_TO_WISH_LIST = 'ADD_ITEM_TO_WISH_LIST';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const FETCH_WISH_LIST_ITEMS = 'FETCH_WISH_LIST_ITEMS';
export const FILTER_ITEMS = 'FILTER_ITEMS';
export const ADD_ITEM = 'ADD_ITEM';

export const ITEMS_LOADER = 'ITEMS_LOADER';

export const FETCH_ITEM_FAILURE = 'FETCH_ITEM_FAILURE';
export const UNKNOW_ERROR = 'UNKNOW_ERROR';
