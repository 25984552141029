import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Step } from 'semantic-ui-react';

class Steps extends Component {
  static getDerivedStateFromProps(props, state) {
    if (props.steps !== state.steps) {
      return {
        steps: props.steps.map(currentStep => {
          const step = { ...currentStep };
          if (step.key === props.currentStep) step.active = true;
          else step.active = false;
          return step;
        }),
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    const { steps } = props;
    this.state = {
      steps,
    };
  }

  render() {
    const { steps } = this.state;
    return (
      <Step.Group stackable="tablet" items={steps} />
    );
  }
}

Steps.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
  })),
  currentStep: PropTypes.string,
};

Steps.defaultProps = {
  steps: [],
  currentStep: '',
};

export default Steps;
