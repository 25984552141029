import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Footer from '../../common/components/footer';
// import DashboardLinks from './components/dashboard-links';
import DashboardHeader from './components/dashboard-header';
import constantValues from '../constants';
import './styles.css';

const onTidioChatApiReady = () => {
  const tidioScript = document.createElement('script');
  tidioScript.src = constantValues.TIDIO_URL;
  document.body.appendChild(tidioScript);
};

const DashboardLayout = ({
  history, t, children, isAuthenticated,
}) => {
  useEffect(() => {
    onTidioChatApiReady();
  }, []);

  return (
    <div className="layout-container">
      <DashboardHeader history={history} t={t} isAuthenticated={isAuthenticated} />
      {/* {showApps ? <DashboardLinks history={history} /> : null} */}
      {children}
      <div className="layout-children" />
      <Footer />
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.shape({}).isRequired,
  history: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

DashboardLayout.defaultProps = {
};

export default DashboardLayout;
