import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Button, Modal, Grid, Table, Input,
} from 'semantic-ui-react';

const UpdateAttributesModal = props => {
  const {
    t, attribute, attributeValue, specificationsData, open, handleClickCancel, validations,
  } = props;
  return (
    <Modal open={open} closeIcon onClose={handleClickCancel}>
      <Modal.Header>{t('Editar especificaciones')}</Modal.Header>
      <Modal.Content>
        <Grid celled structured>
          <Grid.Row textAlign="left" computer={16} tablet={5} mobile={6} className="container_specifications">
            <Table padded>
              {specificationsData.map(specification => (
                <Table.Body>
                  {specification.type.map((elementAttribute, index) => (
                    <Table.Row>
                      <Table.Cell style={{ backgroundColor: '#f6f6f6' }} width="5" rowSpan={specification.value.length + 1}>
                        <Input
                          id={specification.id}
                          name={index}
                          maxLength={500}
                          fluid
                          value={elementAttribute}
                          error={attribute.error}
                          onChange={attribute.change}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  {specification.value.map((elementValue, index) => (
                    <Table.Row>
                      <Table.Cell width="11">
                        <Input
                          id={specification.id}
                          name={index}
                          maxLength={500}
                          value={elementValue}
                          error={attributeValue.error}
                          fluid
                          onChange={attributeValue.change}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              ))}
            </Table>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color="primary" onClick={() => validations()}>{t('update')}</Button>
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
UpdateAttributesModal.defaultProps = {
  open: true,
  t: null,
  attribute: [],
  handleClickCancel: null,
  attributeValue: [],
  specificationsData: [],
  validations: null,
};

UpdateAttributesModal.propTypes = {
  t: PropTypes.func,
  open: PropTypes.bool,
  attribute: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      change: PropTypes.func.isRequired,
    }),
  ),
  specificationsData: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  attributeValue: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      error: PropTypes.string.isRequired,
      change: PropTypes.func.isRequired,
    }),
  ),
  handleClickCancel: PropTypes.func,
  validations: PropTypes.func,
};
export default translate('common', { wait: true })(UpdateAttributesModal);
