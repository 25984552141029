import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Container,
  Dropdown,
  Item,
  Label,
  Header,
  Divider,
  Button,
  Responsive,
  Icon,
} from 'semantic-ui-react';

import services from '../services';

import { generateSearchPath } from '../../../common/utils';

const DEFAULT_MESSAGE = 'Escribe lo que estás buscando.';
const NO_RESULTS_MESSAGE = 'Lo sentimos, no encontramos resultados para tu búsqueda.';
const KEY_ENTER = 13;
const KEY_ESC = 27;

function SuggestionSearch(props) {
  const { history, onActiveMenu } = props;
  const [items, setItems] = useState([]);
  const [bundles, setBundles] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const [searches, setSearches] = useState([]);
  const dropdownRef = useRef(null);

  const storeSearch = search => {
    const storedSearches = JSON.parse(window.localStorage.getItem('searches')) || [];
    if (search.value !== '') {
      storedSearches.push(search);
      window.localStorage.setItem('searches', JSON.stringify(storedSearches));
    }
  };

  const getLocalStorage = () => {
    const stored = JSON.parse(window.localStorage.getItem('searches')) || [];
    if (stored.length >= 6) {
      const flipStored = stored.reverse();
      const cleanStoredRepeats = [...new Set(flipStored)];
      const cleanEmptyStored = cleanStoredRepeats.filter(cleanStored => cleanStored !== '');
      const firstSixStored = cleanEmptyStored.slice(0, 6);
      setSearches(firstSixStored);
    } else {
      const flipStored = stored.reverse();
      const storedSearches = [...new Set(flipStored)];
      const cleanEmptyStored = storedSearches.filter(cleanStored => cleanStored !== '');
      setSearches(cleanEmptyStored);
    }
  };

  const handleGetKeywords = async (e, { searchQuery }) => {
    setSearchValue(searchQuery);
    setHasFocus(true);
    if (searchQuery.length > 1) {
      try {
        const { suggestions } = await services.getAttributesByKeywords(searchQuery);
        const bundleItems = suggestions.filter(suggestion => suggestion.type === 'Juego');
        const products = suggestions.filter(suggestion => suggestion.type !== 'Juego');
        setItems(products);
        setBundles(bundleItems);
      } catch (error) {
        setItems([]);
        setBundles([]);
      }
    } else {
      setItems([]);
      setBundles([]);
      getLocalStorage();
    }
  };

  const handlerOnBlur = () => {
    onActiveMenu(false);
    setOpen(false);
    setHasFocus(false);
  };

  const handleOnSelectItem = sku => {
    const url = generateSearchPath({ type: 'sku', value: sku });
    setSearchValue(sku);
    storeSearch(sku);
    handlerOnBlur();
    history.push(url);
  };

  const handleClickResult = () => {
    const keyword = searchValue.toLowerCase().trim();
    if (keyword !== '') {
      const url = generateSearchPath({ type: 'search', value: keyword });
      setOpen(false);
      history.push(url);
      storeSearch(keyword);
      return dropdownRef.current.ref.current.querySelector('input').blur();
    }
    return dropdownRef.current.ref.current.querySelector('input').focus();
  };

  const handleOnSelectItemStorage = search => {
    const url = generateSearchPath({ type: 'search', value: search });
    setSearchValue(search);
    handlerOnBlur();
    history.push(url);
    storeSearch(search);
    getLocalStorage();
  };

  const handlerOnFocus = () => {
    onActiveMenu(true);
    getLocalStorage();
    setHasFocus(true);
    setOpen(true);
  };

  return (
    <div style={{ display: 'flex' }}>
      <Dropdown
        fluid
        placeholder={hasFocus ? '' : DEFAULT_MESSAGE}
        search
        selection
        icon={null}
        onSearchChange={handleGetKeywords}
        onBlur={handlerOnBlur}
        onOpen={() => {
          setOpen(true);
          setHasFocus(true);
        }}
        searchQuery={searchValue}
        open={open}
        onKeyDown={e => {
          if (e.keyCode === KEY_ENTER) {
            handleClickResult();
          }
          if (e.keyCode === KEY_ESC) {
            dropdownRef.current.ref.current.querySelector('input').blur();
          }
        }}
        onFocus={handlerOnFocus}
        ref={dropdownRef}
        style={hasFocus ? { zIndex: 2000 } : {}}
      >
        <Dropdown.Menu style={{ maxHeight: 1200 }}>
          {searchValue === '' && (
            <>
              <Dropdown.Header style={{ paddingLeft: 20, paddingBottom: '0' }} content="BÚSQUEDAS RECIENTES" />
              <Dropdown.Divider />
              <Item.Group link style={{ paddingLeft: 20 }}>
                {searches.map(search => (
                  <Dropdown.Item key={search}>
                    <Item.Content
                      style={{
                        fontFamily: 'Exo, sans-serif',
                        fontSize: 14,
                        whiteSpace: 'normal',
                      }}
                      verticalAlign="middle"
                    >
                      <Item.Meta className="hov-blue" onClick={() => handleOnSelectItemStorage(search)}>
                        <Icon className="clock outline" />
                        &nbsp; &nbsp;
                        {search}
                      </Item.Meta>
                    </Item.Content>
                  </Dropdown.Item>
                ))}
              </Item.Group>
            </>
          )}
          {(items.length > 0 || bundles.length > 0) && (
            <>
              {bundles.length > 0 && (
                <>
                  <Dropdown.Header content="Paquetes" />
                  <Dropdown.Divider />
                  <Item.Group link style={{ paddingLeft: 20 }}>
                    {bundles.map(bundle => (
                      <Dropdown.Item key={bundle.sku} onClick={() => handleOnSelectItem(bundle.sku)}>
                        <Item.Image size="tiny" src={bundle.image} />

                        <Item.Content verticalAlign="middle">
                          <Item.Header style={{ fontSize: 14, whiteSpace: 'normal' }}>{bundle.title}</Item.Header>
                          <Item.Meta>
                            SKU:
                            {' '}
                            {bundle.sku}
                          </Item.Meta>
                          <Item.Extra>
                            Este paquete incluye:
                            {' '}
                            {bundle.bundleItems.map(sku => (
                              <Label key={sku} color="blue" size="tiny">
                                {sku}
                              </Label>
                            ))}
                          </Item.Extra>
                        </Item.Content>
                      </Dropdown.Item>
                    ))}
                  </Item.Group>
                </>
              )}
              {items.length > 0 && (
                <>
                  <Dropdown.Header content="Articulos" />
                  <Dropdown.Divider />
                  <Item.Group link style={{ paddingLeft: 20 }}>
                    {items.map(item => (
                      <Dropdown.Item key={item.sku} onClick={() => handleOnSelectItem(item.sku)}>
                        <Item.Image
                          size="tiny"
                          src={item.image}
                          style={{
                            objectFit: 'contain',
                            backgroundColor: '#ffffff',
                          }}
                        />
                        <Item.Content verticalAlign="middle">
                          <Item.Header style={{ fontSize: 14, whiteSpace: 'normal' }}>{item.title}</Item.Header>
                          <Item.Meta>
                            SKU:
                            {' '}
                            {item.sku}
                          </Item.Meta>
                          <Item.Extra>{item.brand}</Item.Extra>
                        </Item.Content>
                      </Dropdown.Item>
                    ))}
                  </Item.Group>
                </>
              )}
              <Container style={{ padding: '0 10px 10px' }}>
                <Divider />
                <Header
                  as="h5"
                  color="blue"
                  style={{ cursor: 'pointer', marginTop: 0 }}
                  onClick={handleClickResult}
                >
                  Ver todos los resultados
                </Header>
              </Container>
            </>
          )}
          {(items.length === 0 && bundles.length === 0 && searchValue !== '') && (
            <Dropdown.Item text={NO_RESULTS_MESSAGE} />
          )}
        </Dropdown.Menu>
      </Dropdown>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}>
        <Button
          style={{ marginLeft: '4px' }}
          icon="search"
          type="submit"
          primary
          onClick={handleClickResult}
        />
      </Responsive>
    </div>
  );
}

SuggestionSearch.defaultProps = {
  onActiveMenu: () => {},
};

SuggestionSearch.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  onActiveMenu: PropTypes.func,
};

export default translate('common', { wait: true })(SuggestionSearch);
