import React from 'react';
import { translate } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Iframe from 'react-iframe';
import { Header } from 'semantic-ui-react';

const urlAWS = process.env.REACT_APP_URL_AWS;

const HELMET_DATA = {
  terms: {
    title: 'siclik - términos y condiciones',
    description: 'Consulta los términos y condiciones de uso de los comercios electrónicos para regular la relación entre CompuSoluciones y el cliente.',
  },
  fqa: {
    title: 'siclik - preguntas frecuentes',
    description: 'Consulta las preguntas frecuentes en relación a nuestro comercio electrónico.',
  },
  mpoCxC: {
    title: 'siclik - política de crédito y cobranza',
    description: 'Consulta la política de crédito y cobranza de CompuSoluciones para ser más eficiente con tus operaciones de pago y tener un buen manejo de tu crédito.',
  },
  devoluciones: {
    title: 'siclik - política de devolución',
    description: 'Consulta la política de devolución de siclik Comercio, el proceso a seguir tanto por CompuSoluciones como por el cliente.',
  },
  formasdepago: {
    title: 'siclik - formas de pago',
    description: 'Conoce las diferentes formas de pago disponibles para los comercios electrónicos; elige el que más te convenga para adquirir tus productos.',
  },
  metodosenvio: {
    title: 'siclik - métodos de envío',
    description: 'Nuestro objetivo es asegurar la eficiencia en cada una de nuestras entregas: recoger en almacén, mensajería CompuSoluciones, paqueterías y fleteras.',
  },
  search: {
    title: 'siclik - Archivo adjunto',
    description: 'Visualización de un archivo adjunto',
  },
};

function PDFViewer({ match }) {
  window.scrollTo(0, 0);
  const getPath = (name, url = undefined) => {
    switch (name) {
      case 'terms':
        return '/TERMINOS.pdf';
      case 'fqa':
        return '/FQA.pdf';
      case 'mpoCxC':
        return '/2021_MPO_Credito_Cobranza.pdf';
      case 'devoluciones':
        return '/Politica_devoluciones_distribuidores.pdf';
      case 'formasdepago':
        return '/Formas_Pago.pdf';
      case 'metodosenvio':
        return '/Metodos_envio_CS.pdf';
      case 'search':
        return url;
      default:
        return '';
    }
  };
  const params = location.search; // eslint-disable-line
  let url = '';
  if (params !== '') url = params.split('=')[1]; // eslint-disable-line
  const file = match.params.name || '';
  const path = getPath(file, url);
  const { title, description } = HELMET_DATA[file];
  return (
    <div>
      <Helmet
        title={title}
        meta={[
          {
            name: 'description',
            content: description,
          },
        ]}
      />
      {
        file
          ? (
            <Iframe
              url={`${urlAWS}${path}`}
              position="absolute"
              overflow-x="hidden"
              width="100%"
              height="100%"
              frameBorder="0"
            />
          )
          : <Header textAlign="center" style={{ padding: '4em' }}>Archivo no encontrado</Header>
      }
    </div>
  );
}

export default translate('common', { wait: true })(PDFViewer);
