import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Dropdown, Loader, Message,
} from 'semantic-ui-react';
import BoxQuotation from '../../common/components/BoxQuotation';
import AddDeliveryAddress from '../../multi-shipping/components/addDeliveryAddress';

function SingleDelivery(props) {
  const {
    t, handleShippingCostChange, branchOffices, currentBranchOfficeIndex, addressId, items, courierServiceId, allBranchOffices, currency, exchangeRate, handleBranchOfficeChange, handleAddressChange, handleGetNewAddress, orderId, subtotal,
  } = props;
  const handleChange = (e, { value }) => handleBranchOfficeChange(value);
  const handleChangeAddress = (e, { value }) => handleAddressChange(value);
  const onlyDeliveryItems = items.filter(item => item.hasDelivery);
  const selectedBranch = branchOffices.find(branch => branch.id === currentBranchOfficeIndex);
  const branchOfficesDropdown = branchOffices && branchOffices.length > 0 ? branchOffices.map(branchOffice => (
    { key: branchOffice.id, text: branchOffice.name, value: branchOffice.id }
  )) : [];
  const branchOfficesNotValid = allBranchOffices && allBranchOffices.length > 0 ? allBranchOffices.map(branchOffice => (
    { key: branchOffice.id, text: branchOffice.name, value: branchOffice.id }
  )) : [];
  const value = !selectedBranch ? '' : selectedBranch.id;
  const formatedItems = onlyDeliveryItems.map(({ sku, quantity, outlet }) => ({ sku, quantity, outlet }));
  const nameItems = onlyDeliveryItems.map(({ name }) => ({ name }));
  const getAddress = address => {
    const addressArray = [...branchOffices, ...[{
      id: address.key,
      name: address.text,
      value: address.value,
      city: address.city,
      colony: address.colony,
      state: address.state,
      street: address.street,
      number: address.number,
    }]];
    handleGetNewAddress(addressArray);
  };
  return (
    <Grid>
      {
        (branchOffices.length <= 0)
          ? (
            <Grid.Row>
              <Grid.Column computer={16} tablet={16} mobile={16}>
                <Loader active />
              </Grid.Column>
            </Grid.Row>
          )
          : (
            <Grid.Row>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <Grid.Row style={{ paddingBottom: '1rem' }}>
                  <Dropdown
                    placeholder={t('shoppingCart.branchOffices')}
                    onChange={handleChange}
                    value={value}
                    selection
                    search
                    className=""
                    options={branchOfficesDropdown}
                  />
                </Grid.Row>
                <Grid.Row style={{ paddingBottom: '1rem' }}>
                  <p className="informativeLegendText">{t('shoppingCart.informationDelivery')}</p>
                </Grid.Row>
                <Grid.Row>
                  <Header as="h4" style={{ display: 'inline' }}>
                    {`${t('shoppingCart.adress')}: `}
                  </Header>
                  <p style={{ display: 'inline' }}>{`${value ? selectedBranch.street : ''} ${value ? selectedBranch.number : ''}, ${value ? selectedBranch.city : ''},${value ? selectedBranch.state : ''}.`}</p>
                </Grid.Row>
                <Grid.Row>
                  <Header as="h4" style={{ display: 'inline' }}>
                    {`${t('shoppingCart.maneuver')}: `}
                  </Header>
                  <p style={{ display: 'inline' }}>{value ? selectedBranch.maneuver : ''}</p>
                </Grid.Row>
                <Grid.Row>
                  <Header as="h4" style={{ display: 'inline' }}>
                    {`${t('shoppingCart.appointment')}: `}
                  </Header>
                  <p style={{ display: 'inline' }}>{value && selectedBranch.appointment ? t('yes') : t('no')}</p>
                </Grid.Row>
                <Grid.Row>
                  <Header as="h4" style={{ display: 'inline' }}>
                    {`${t('shoppingCart.parking')}: `}
                  </Header>
                  <p style={{ display: 'inline' }}>{value && selectedBranch.parking ? t('yes') : t('no')}</p>
                </Grid.Row>
                <Grid.Row>
                  <Header as="h4" style={{ display: 'inline' }}>
                    {`${t('shoppingCart.contact')}: `}
                  </Header>
                  <p style={{ display: 'inline' }}>{value ? selectedBranch.mainContact : ''}</p>
                </Grid.Row>
                <Grid.Row>
                  <Header as="h4" style={{ display: 'inline' }}>
                    {`${t('shoppingCart.phone')}: `}
                  </Header>
                  <p style={{ display: 'inline' }}>{value ? selectedBranch.phone : ''}</p>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column style={{ paddingTop: '1rem' }}>
                    <AddDeliveryAddress t={t} getNewAddress={getAddress} />
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column computer={8} tablet={8} mobile={16}>
                <br />
                <Grid.Row>
                  {
                    !value
                      ? (
                        <p>
                          {t('shoppingCart.needBranchOffice')}
                        </p>
                      )
                      : (
                        <BoxQuotation
                          parcelValue={courierServiceId}
                          skus={formatedItems}
                          addressId={currentBranchOfficeIndex}
                          tr={t}
                          response={body => handleShippingCostChange(body)}
                          isSingle
                          orderId={orderId}
                          nameItems={nameItems}
                          currencyPrice={currency}
                          exchangeRate={exchangeRate}
                          subtotal={subtotal}
                        />
                      )
                  }
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          )
      }
      {(branchOffices.length < 1)
        ? (
          <Grid.Row>
            <Message>
              <Message.Header>No se encontraron direcciones validas</Message.Header>
              <br />
              <Grid>
                <p>
                  Para seguir con tu compra es necesario completar la información de la direccion a la que
                  deseas enviar el pedido o bien agregar una nueva.
                </p>
                <Grid.Row style={{ paddingBottom: '1rem' }}>
                  <Grid.Column computer={6}>
                    <Dropdown
                      placeholder={t('shoppingCart.branchOffices')}
                      onChange={handleChangeAddress}
                      value={addressId}
                      selection
                      search
                      // className="only-bottom-border  no-padding-left"
                      options={branchOfficesNotValid}
                    />
                  </Grid.Column>
                  {addressId
                    ? (
                      <Grid.Column computer={3}>
                        <AddDeliveryAddress addressId={addressId} t={t} getNewAddress={getAddress} />
                      </Grid.Column>
                    ) : null}
                  <Grid.Column computer={6}>
                    <AddDeliveryAddress addressId="" t={t} getNewAddress={getAddress} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Message>
          </Grid.Row>
        ) : null}
    </Grid>

  );
}

SingleDelivery.propTypes = {
  addressId: PropTypes.string,
  allBranchOffices: PropTypes.arrayOf(PropTypes.shape({})),
  branchOffices: PropTypes.arrayOf(PropTypes.shape({})),
  courierServiceId: PropTypes.number,
  currency: PropTypes.string,
  currentBranchOfficeIndex: PropTypes.string,
  exchangeRate: PropTypes.shape({}),
  handleAddressChange: PropTypes.func,
  handleBranchOfficeChange: PropTypes.func,
  handleGetNewAddress: PropTypes.func,
  handleShippingCostChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({})),
  orderId: PropTypes.string,
  t: PropTypes.func,
  subtotal: PropTypes.number,
};

SingleDelivery.defaultProps = {
  addressId: '',
  allBranchOffices: [],
  branchOffices: [],
  handleShippingCostChange: () => { },
  courierServiceId: 0,
  currency: '',
  currentBranchOfficeIndex: '',
  exchangeRate: {},
  handleAddressChange: () => { },
  handleBranchOfficeChange: () => { },
  handleGetNewAddress: () => { },
  items: [],
  orderId: '',
  t: () => { },
  subtotal: 0,
};

export default translate('common', { wait: true })(SingleDelivery);
