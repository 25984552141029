/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Icon, Button, Header, Image, List, Message, Loader,
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { fixRotationWithFile, compressArrayOfFiles } from '../../common/utils';
import '../style.css';
import uploader from '../../upload-s3/services';
import Toast from '../../common/components/toast';

const RejectedList = ({ rejected, t }) => (
  (rejected.length >= 1)
    ? (
      <Grid.Row centered>
        <Grid.Column width={15}>
          <Header as="h3">{t('marketing.allowedFiles')}</Header>
          <List divided relaxed>
            {
              rejected.map(file => (
                <List.Item key={file.name}>
                  <List.Content>
                    <List.Header>{file.name}</List.Header>
                    <List.Description>{`${file.size} - ${t('marketing.bytes')}`}</List.Description>
                  </List.Content>
                </List.Item>
              ))
            }
          </List>
        </Grid.Column>
      </Grid.Row>
    ) : null
);

const AcceptedList = ({
  accepted, t, deleteFile, saved,
}) => (
  (accepted.length >= 1)
    ? (
      <Grid.Row centered>
        <Grid.Column width={15}>
          <Header as="h3">{t('marketing.accepted')}</Header>
          <List divided relaxed>
            {
              accepted.map(currentFile => (
                <List.Item key={currentFile.name}>
                  <List.Content floated="right">
                    <Button
                      color="red"
                      onClick={() => deleteFile(currentFile)}
                      disabled={saved}
                    >
                      {t('marketing.delete')}
                    </Button>
                  </List.Content>
                  <List.Content>
                    <List.Header>{currentFile.name}</List.Header>
                    <List.Description>{`${currentFile.size} - ${t('marketing.bytes')}`}</List.Description>
                  </List.Content>
                </List.Item>
              ))
            }
          </List>
        </Grid.Column>
      </Grid.Row>
    ) : null
);

function validateSizes(sizes = [], fileDetails = {}, actionId = 0) {
  const files = Object.values(fileDetails);
  const existingSizes = [];
  let extraImages = 0;
  files.forEach(file => {
    const alreadyExist = existingSizes.find(sizeId => sizeId === file.sizeId);
    if (!file.sizeId || alreadyExist) extraImages++;
    else existingSizes.push(file.sizeId);
  });
  const hasError = (
    (actionId !== 1 && extraImages > 0)
    || (actionId === 1 && (extraImages > 1 || extraImages === 0))
    || (existingSizes.length !== sizes.length)
  );
  return {
    validSizes: [...existingSizes],
    extraImages,
    hasError,
  };
}

const SizeCheckList = ({
  t, type, fileDetails, action,
}) => {
  const { id: actionId } = action;
  const { sizes } = type;
  const {
    validSizes: existingSizes,
    extraImages,
    hasError,
  } = validateSizes(sizes, fileDetails, actionId);
  return (
    (type)
      ? (
        <Grid.Row centered>
          <Grid.Column width={15}>
            <Header as="h3">{t('marketing.allowedSizes')}</Header>
            <List divided relaxed>
              {
                sizes.map(size => {
                  const imageExist = existingSizes.find(sizeId => sizeId === size.id);
                  return (
                    <List.Item key={`${size.id}${size.name}`}>
                      <List.Icon
                        size="big"
                        color={imageExist ? 'green' : 'red'}
                        name={imageExist ? 'check' : 'close'}
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>{size.name.toUpperCase()}</List.Header>
                        <List.Description>{`${size.width || '(ancho libre)'} x ${size.height || '(alto libre)'}`}</List.Description>
                      </List.Content>
                    </List.Item>
                  );
                })
              }
              {
                actionId === 1
                  ? (
                    <List.Item key="extra-image">
                      <List.Icon
                        size="big"
                        color={extraImages === 1 ? 'green' : 'red'}
                        name={extraImages === 1 ? 'check' : 'close'}
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>{t('marketing.extraImage')}</List.Header>
                        <List.Description>{t('marketing.anySize')}</List.Description>
                      </List.Content>
                    </List.Item>
                  ) : null
              }
            </List>
            {
              (hasError && extraImages > 0)
                ? (
                  <Message negative>
                    <Message.Header>{t('marketing.error.extraImages')}</Message.Header>
                    <p>
                      {
                        actionId === 1
                          ? `${t('marketing.actionImageMessage.before')} ${extraImages - 1} ${t('marketing.actionImageMessage.after')}`
                          : `${t('marketing.extraImagesMessage.before')} ${extraImages} ${t('marketing.extraImagesMessage.after')}`
                      }
                    </p>
                  </Message>
                ) : null
            }
          </Grid.Column>
        </Grid.Row>
      ) : null
  );
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const mimeImages = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

const mimeTypes = [
  ...mimeImages,
];

class UploadPanel extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      accepted: [],
      rejected: [],
      fileDetails: {},
      files: [],
      saved: false,
      compressionLoader: false,
    };
  }

  componentWillUnmount() {
    this.state.files.forEach(file => URL.revokeObjectURL(file.preview)); // eslint-disable-line
  }

  onDrop = async files => {
    const { files: stateFiles } = this.state;
    const blobOfArray = await fixRotationWithFile(files);
    const formatedBlob = blobOfArray.map(fixedFile => {
      const { file, fixed } = fixedFile;
      return { ...fixed, preview: URL.createObjectURL(fixed), name: file.name };
    });
    const combinedFiles = formatedBlob.concat(stateFiles);
    this.setState({ files: combinedFiles });
    return combinedFiles;
  };

  deletefiles = filesToDelete => {
    if (filesToDelete.files) {
      filesToDelete.files.map(urlDelete => {
        const objectKey = urlDelete.Key;
        return uploader.deleteImageS3({ objectKey });
      });
    }
  }

  saveFile = async (file, name, bucketPath) => {
    const urlSigned = await uploader.getSignedUrl({ name, bucketPath });
    const fullUrl = await uploader.uploadImgS3(urlSigned, file);
    const urlSplited = fullUrl.url.split('?');
    if (!urlSplited[0]) {
      return null;
    }
    return urlSplited[0];
  };

  loadFiles = async () => {
    const {
      t, action, type, name: bannerName,
    } = this.props;
    const { accepted, fileDetails } = this.state;
    this.setState({ compressionLoader: true });
    const compressAccepted = await compressArrayOfFiles(accepted);
    this.setState({ compressionLoader: false });
    const { hasError } = validateSizes(type.sizes, fileDetails, action.id);
    if (hasError) {
      Toast(t('marketing.error.image'), 'error');
      return null;
    }
    const uploadedObject = compressAccepted.map(async (file, index) => {
      const { sizeId } = fileDetails[file.name];
      const sizeOrExtra = index < type.sizes.length ? sizeId : 'extra';
      const nameImage = file.type.split('/');
      const splitedFileName = file.name.split('.');
      if (splitedFileName.length > 1) splitedFileName.pop();
      const fileName = splitedFileName.join('.');
      const extension = nameImage[1];
      const bucketPath = 'imagenes/banners';
      const name = `${type.id}-${sizeOrExtra}-${bannerName}_${fileName}.${extension}`;
      const uploadedUrl = await this.saveFile(file, name, bucketPath);
      return { sizeId: sizeOrExtra, url: uploadedUrl };
    });
    return Promise.all(uploadedObject);
  }

  handleLoad = async () => {
    const { uploadedImages, handleModalState } = this.props;
    const loadedImages = await this.loadFiles();
    this.setState({ saved: true });
    uploadedImages(loadedImages);
    handleModalState(false);
  }

  deleteFile = file => {
    const { files, fileDetails, accepted } = this.state;
    delete fileDetails[file.name];
    const filteredFiles = files.filter(currentFile => currentFile.name !== file.name);
    const filteredAccepted = accepted.filter(currentFile => currentFile.name !== file.name);
    this.setState({
      files: filteredFiles,
      accepted: filteredAccepted,
    });
  }

  loadPreview = ({ target }) => {
    const { name, naturalWidth, naturalHeight } = target;
    const { fileDetails: stateDetails } = this.state;
    const { type } = this.props;
    const sizeType = type.sizes.find(size => (
      (size.width === naturalWidth || size.width === 0) && (size.height === naturalHeight || size.height === 0)
    ));
    const formatedDetail = {
      [name]: {
        width: naturalWidth,
        height: naturalHeight,
        sizeId: !sizeType ? null : sizeType.id,
      },
    };
    const fileDetails = { ...stateDetails, ...formatedDetail };
    this.setState({
      fileDetails,
    });
  }

  handleAcceptedFiles = acceptedFiles => {
    const { accepted } = this.state;
    this.setState({ accepted: accepted.concat(acceptedFiles) });
  }

  render() {
    const {
      files, accepted, rejected, fileDetails, saved, compressionLoader,
    } = this.state;
    const { t, action, type } = this.props;
    const { hasError } = validateSizes(type.sizes, fileDetails, action.id);
    const thumbs = files.map(file => {
      const identifier = file.preview.substring(0, 4);
      return (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            {(identifier === 'blob')
              ? (
                <Image
                  src={file.preview}
                  name={file.name}
                  style={img}
                  onLoad={this.loadPreview}
                />
              )
              : (
                <Icon.Group size="huge">
                  <Icon name={file.preview} />
                </Icon.Group>
              )}
          </div>
        </div>
      );
    });
    return (
      <Grid>
        <Grid.Row>
          <section style={{ width: '100%' }}>
            <div className="dropzone">
              <Dropzone
                className="dropzone"
                accept={mimeTypes}
                multiple
                onDropAccepted={this.handleAcceptedFiles}
                onDropRejected={rejectedFile => { this.setState({ rejected: rejected.concat(rejectedFile) }); }}
                onDrop={file => this.onDrop(file)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={thumbsContainer}>
                    <input {...getInputProps()} />
                    <Icon name="add" circular />
                    {thumbs}
                    {(accepted.length <= 0)
                      ? (
                        <Grid.Row>
                          <Header as="h5">
                            {t('marketing.dropImage')}
                            {' '}
                          </Header>
                          <Header as="h5">{t('marketing.correctImageFormat')}</Header>
                        </Grid.Row>
                      )
                      : ''}
                  </div>
                )}
              </Dropzone>
            </div>
          </section>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <p>
              {t('*Las imágenes de tamaño mayor a 1 MB serán comprimidas de manera automática.')}
            </p>
          </Grid.Column>
        </Grid.Row>
        <SizeCheckList
          t={t}
          type={type}
          fileDetails={fileDetails}
          action={action}
        />
        <AcceptedList t={t} accepted={accepted} deleteFile={this.deleteFile} />
        <RejectedList t={t} rejected={rejected} />
        <Grid.Row centered>
          <Grid.Column>
            <Button color="blue" disabled={compressionLoader || hasError || saved} onClick={this.handleLoad}>
              {t('save')}
            </Button>
            <Loader active={compressionLoader} inline="centered">Comprimiendo imagenes, esto puede tardar un momento</Loader>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

AcceptedList.propTypes = {
  accepted: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  saved: PropTypes.bool,
};

AcceptedList.defaultProps = {
  saved: false,
};

RejectedList.propTypes = {
  rejected: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  t: PropTypes.func.isRequired,
};

SizeCheckList.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.shape({
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        required: PropTypes.bool,
      }),
    ),
  }).isRequired,
  action: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  fileDetails: PropTypes.shape({}).isRequired,
};

UploadPanel.propTypes = {
  type: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        required: PropTypes.bool.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  action: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string.isRequired,
  uploadedImages: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  handleModalState: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(UploadPanel);
