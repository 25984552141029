import React from 'react';
import { Step, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

function StepsBuyNow(props) {
  const { steps, t } = props;
  return (
    <Step.Group size="tiny" fluid>
      <Step active={steps.shipping} completed={!steps.shipping}>
        <Icon name="truck" />
        <Step.Content>
          <Step.Title>{t('wishList.buyNowSteps.shipping')}</Step.Title>
          <Step.Description>{t('wishList.buyNowSteps.shippingOptions')}</Step.Description>
        </Step.Content>
      </Step>
      <Step active={steps.confirmOrder} disabled={!steps.confirmOrder}>
        <Icon name="info" />
        <Step.Content>
          <Step.Title>{t('wishList.buyNowSteps.orderConfirmation')}</Step.Title>
          <Step.Description>{t('wishList.buyNowSteps.orderDetails')}</Step.Description>
        </Step.Content>
      </Step>
    </Step.Group>
  );
}

StepsBuyNow.propTypes = {
  steps: PropTypes.shape({
    shipping: PropTypes.bool.isRequired,
    confirmOrder: PropTypes.bool.isRequired,
  }).isRequired,
};

export default translate('common', { wait: true })(StepsBuyNow);
