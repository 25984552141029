import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const familyAttributes = {};

familyAttributes.getFamilies = () => (
  request({
    route: apiRoutes.getAllFamilies(),
    method: 'GET',
  })
);

familyAttributes.getAttributes = () => (
  request({
    route: apiRoutes.getAttributesFamilies(),
    method: 'GET',
  })
);

familyAttributes.addFamilyAttributes = body => (
  request({
    route: apiRoutes.addFamilyKeywords(),
    method: 'POST',
    body,
  })
);

familyAttributes.deleteFamilyAttribute = attributeId => (
  request({
    route: apiRoutes.deleteFamilyKeyword(attributeId),
    method: 'DELETE',
  })
);

familyAttributes.updateFamilyKeywords = body => (
  request({
    route: apiRoutes.updateFamilyKeywords(),
    method: 'PUT',
    body,
  })
);

export default familyAttributes;
