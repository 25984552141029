import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Divider, Dropdown,
} from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';

const AgentDirectory = props => {
  const {
    t, headers, data, maker, makersArray, handleChangeMaker, agentsArray, handleChangeAgent, agent,
  } = props;
  return (
    <Grid>
      <Grid.Row centered>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16} textAlign="justified">
          <Header as="h4" color="blue">{t('agents.filterByMaker')}</Header>
          <Dropdown
            selection
            value={maker}
            options={makersArray}
            placeholder={t('agents.chooseMaker')}
            search
            noResultsMessage={t('agents.tryAnotherBrand')}
            onChange={handleChangeMaker}
          />
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16} textAlign="justified">
          <Header as="h4" color="blue">{t('agents.filterByAgent')}</Header>
          <Dropdown
            selection
            value={agent}
            search
            options={agentsArray}
            placeholder={t('agents.chooseAgent')}
            noResultsMessage={t('agents.tryAnotherName')}
            onChange={handleChangeAgent}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered style={{ overflowX: 'auto' }}>
        <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={16}>
          <SiclikTable headers={headers} data={data} striped={false} basic={null} />
        </Grid.Column>
      </Grid.Row>
      <Divider section />
    </Grid>
  );
};

AgentDirectory.propTypes = {
  agent: PropTypes.string,
  agentsArray: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  handleChangeAgent: PropTypes.func,
  handleChangeMaker: PropTypes.func,
  headers: PropTypes.arrayOf(PropTypes.shape({})),
  maker: PropTypes.string,
  makersArray: PropTypes.arrayOf(PropTypes.shape({})),
};

AgentDirectory.defaultProps = {
  agent: '',
  agentsArray: [],
  data: [],
  handleChangeAgent: () => {},
  handleChangeMaker: () => {},
  headers: [],
  maker: '',
  makersArray: [],
};

export default translate('common', { wait: true })(AgentDirectory);
