import React from 'react';
import propTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Modal, Grid, Dropdown, Button,
} from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const AddAgents = props => {
  const {
    t, openUpdateAgentModal, handleClickCancel, rolesFormat, handleGetRolValue, rol, rolId,
    handleGetSelectedEmail, personalFormat, cellPhone, agentEmail, handleUpdateAllAgents,
  } = props;
  return (
    <Modal open={openUpdateAgentModal}>
      <Modal.Header>
        {t('agents.updateAgentLabel')}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column largeScreen={6} computer={6} tablet={7} mobile={15}>
              <p>
                {t('agents.email')}
                &nbsp;&nbsp;&nbsp;
                {agentEmail}
              </p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.selectAgent')}
                fluid
                options={personalFormat}
                onChange={handleGetSelectedEmail}
                value={agentEmail}
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={5} mobile={15}>
              <Dropdown
                placeholder={t('agents.role')}
                options={rolesFormat}
                onChange={handleGetRolValue}
                value={rolId}
                defaultValue={rol}
                fluid
                search
                selection
              />
            </Grid.Column>
            <Grid.Column largeScreen={5} computer={5} tablet={7} mobile={15}>
              <TextField
                fullWidth
                maxLength={15}
                label={t('agents.cell')}
                value={cellPhone.value || ''}
                error={cellPhone.error || ''}
                onChange={cellPhone.change}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
        <Button color="blue" onClick={handleUpdateAllAgents}>{t('update')}</Button>
      </Modal.Actions>
    </Modal>
  );
};
AddAgents.defaultProps = {
  openUpdateAgentModal: true,
  t: null,
  handleClickCancel: null,
  handleGetRolValue: null,
  rol: '',
  handleGetSelectedEmail: null,
  agentEmail: '',
  personalFormat: '',
  cellPhone: '',
  handleUpdateAllAgents: null,
  rolId: 0,
};

AddAgents.propTypes = {
  t: propTypes.func,
  openUpdateAgentModal: propTypes.bool,
  handleClickCancel: propTypes.func,
  rolesFormat: propTypes.arrayOf(propTypes.shape({})).isRequired,
  handleGetRolValue: propTypes.func,
  rol: propTypes.string,
  handleGetSelectedEmail: propTypes.func,
  personalFormat: propTypes.string,
  cellPhone: propTypes.string,
  handleUpdateAllAgents: propTypes.func,
  agentEmail: propTypes.string,
  rolId: propTypes.number,
};

export default translate('common', { wait: true })(AddAgents);
