import React from 'react';
import PropTypes from 'prop-types';
import { Header, Icon } from 'semantic-ui-react';

function NotFound({ keyword }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '90%',
        border: 'none',
        flexDirection: 'column',
      }}
    >
      <Header as="h4" icon>
        <Icon name="search" />
        {keyword ? (
          // eslint-disable-next-line react/jsx-one-expression-per-line
          <span>No hemos encontrado resultados para &ldquo;{keyword}&rdquo;</span>
        ) : (
          <span>No se encontró ningún resultado</span>
        )}
      </Header>
      <div>
        <p>Intenta una búsqueda diferente o con una combinación de filtros distinta.</p>
      </div>
    </div>
  );
}

NotFound.defaultProps = {
  keyword: '',
};

NotFound.propTypes = {
  keyword: PropTypes.string,
};

export default NotFound;
