import React, { Component } from 'react';
import {
  Grid, Header, Sticky, Button, Confirm, Modal, Responsive, Icon, Popup, Dimmer, Loader,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import services from '../services';
import routes from '../../route-names';
import Toast from '../../common/components/toast';
import MultiBrand from '../../multi-brand-data';
import Authorization from './authorization';
import * as session from '../../common/sessions';

const CUSTOMERID = 'G000000';

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: null,
      open: false,
      openReviews: false,
      reviewsArray: [],
      width: '',
      loadingButtonPdf: false,
      loaderAuthorization: false,
    };
  }

  componentDidMount() {
    this.handleGetReviews();
    this.handleAccessPermission();
  }

  handleContextRef = ref => {
    this.setState({ context: ref });
  };

  deleteLeaseQuote = quoteId => (
    services.deleteLeaseQuote(quoteId)
  )

  handleConfirmQuoteToCart = () => {
    const {
      t, quoteId, history, getShoppingCartCount, isLeaseQuote,
    } = this.props;
    services.quoteToShoppingCart(quoteId)
      .then(changeQuote => {
        if (changeQuote.changedRows && changeQuote.changedRows === 1) {
          if (isLeaseQuote) {
            try {
              this.deleteLeaseQuote(quoteId);
            } catch (error) {
              Toast(t('quotes.cantDeleteLeaseQuote'), 'warning');
            }
          }
          getShoppingCartCount();
          history.push(routes.shoppingCart.route);
        } else {
          Toast('Ocurrio un error al abrir la cotización, favor de intentarlo nuevamente', 'error');
        }
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetReviews = () => {
    const { quoteId, t } = this.props;
    services.getReviews(quoteId).then(response => {
      this.setState({ reviewsArray: response.orderReviews });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      if (error.code && error.code !== 1005) Toast(messageError, 'error');
    });
  }

  handleAccessPermission = () => {
    const { access } = session.get();
    const hasAccessValidation = securityService.validate(access, [
      securityService.getAccess.autorizarRevisiones,
    ]);
    this.setState({ hasAccess: hasAccessValidation });
  }

  handlepostPdfQuotation = () => {
    const { quoteId, checkoutData, t } = this.props;
    this.setState({ loadingButtonPdf: true });
    const name = `${quoteId}-pdfQuotation.pdf`;
    const body = {
      id: checkoutData,
      url_cotizacion: name,
      orderId: quoteId,
    };
    services.pdfQuotation(quoteId, body)
      .then(url => {
        const a = document.createElement('a'); // eslint-disable-line
        a.href = url.quotationUrl;
        a.download = `${name}`;
        a.click();
        this.setState({ loadingButtonPdf: false });
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = error.message;
        Toast(messageError, 'error');
        this.setState({ loadingButtonPdf: false });
      });
  }

  ActionButtons = () => {
    const {
      tr, handleGoToShipping, customerIdByToken, handleGoToShippingAgent,
      handleGoToBack, handleGoToBackAgent, show, odersData, handleModalState,
    } = this.props;
    const { reviewsArray, hasAccess, loadingButtonPdf } = this.state;
    const orders = odersData;
    let allBrands = [];
    const allMakers = [];
    if (orders) allBrands = orders.map(order => order.items);
    if (allBrands) allBrands = allBrands.map(items => items.map(item => allMakers.push({ name: item.maker })));
    const brandsArray = allMakers.filter((currentValue, currentIndex, array) => array.findIndex(valueOfArray => JSON.stringify(valueOfArray) === JSON.stringify(currentValue)) === currentIndex);
    const visibleBrandsForm = (brandsArray.filter(brand => brand.name === 'VEEAM' || brand.name === 'Microsoft' || brand.name === 'IBM' || brand.name === 'Oracle' || brand.name === 'Trellix')).length > 0;
    return (
      <Grid centered>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            {visibleBrandsForm ? (
              <Button fluid color="blue" onClick={() => handleModalState('openMultiBrand')}>
                {tr('shoppingCart.multiBrandButton')}
              </Button>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        {reviewsArray.length > 0 ? (
          <Grid.Row centered>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Button onClick={() => this.setState({ openReviews: true })} fluid color="blue">
                {tr('quotation-detail.authorizations')}
              </Button>
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {
          customerIdByToken !== CUSTOMERID ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button loading={loadingButtonPdf} fluid color="blue" onClick={this.handlepostPdfQuotation} disabled={customerIdByToken === CUSTOMERID}>
                  {tr('quotation-detail.pdfExport')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
        }
        {
          customerIdByToken !== CUSTOMERID ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button fluid color="blue" onClick={handleGoToShipping} disabled={customerIdByToken === CUSTOMERID}>
                  {tr('quotation-detail.shippingReview')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
        }
        {
          customerIdByToken === CUSTOMERID && hasAccess ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button fluid color="blue" onClick={handleGoToShippingAgent}>
                  {tr('quotation-detail.shippingConfiguration')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
        }
        {
          !show && customerIdByToken !== CUSTOMERID ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button fluid color="blue" onClick={() => this.setState({ open: true })} disabled={customerIdByToken === CUSTOMERID}>
                  {tr('quotation-detail.update')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
        }
        {
          customerIdByToken !== CUSTOMERID ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button fluid color="blue" onClick={handleGoToBack} disabled={customerIdByToken === CUSTOMERID}>
                  {tr('back')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
        }
        {
          customerIdByToken === CUSTOMERID ? (
            <Grid.Row centered>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Button fluid color="blue" onClick={handleGoToBackAgent}>
                  {tr('back')}
                </Button>
              </Grid.Column>
            </Grid.Row>
          ) : null
        }
      </Grid>
    );
  }

  handleChangeLoader = (value, reviewsArray) => {
    this.setState({ loaderAuthorization: value, reviewsArray });
  }

  handleOnUpdate = (e, { width }) => this.setState({ width });

  render() {
    const {
      tr, children: childrenComponents, userId, userNameLoged, customerIdByToken, openMultiBrand, quoteId,
      customerIdOfQuote, userOrderId, customerIdFromOrder, history, handleBoolField, items, odersData, handleModalState,
    } = this.props;
    const {
      reviewsArray, openReviews, width, open, loaderAuthorization, context,
    } = this.state;
    const orders = odersData;
    const itemsMicrosoft = items.filter(item => item.maker === 'Microsoft');
    const itemsVeeam = items.filter(item => item.maker === 'VEEAM');
    const itemsRedHat = items.filter(item => item.maker === 'Red Hat');
    let allBrands = [];
    const allMakers = [];
    if (orders) allBrands = orders.map(order => order.items);
    if (allBrands) allBrands = allBrands.map(itemsBrands => itemsBrands.map(item => allMakers.push({ name: item.maker })));
    const brandsArray = allMakers.filter((currentValue, currentIndex, array) => array.findIndex(valueOfArray => JSON.stringify(valueOfArray) === JSON.stringify(currentValue)) === currentIndex);
    const visibleBrandsForm = (brandsArray.filter(brand => brand.name === 'VEEAM' || brand.name === 'Microsoft' || brand.name === 'IBM' || brand.name === 'Oracle' || brand.name === 'Trellix')).length > 0;
    return (
      <div ref={this.handleContextRef}>
        <Grid padded>
          <Responsive fireOnMount onUpdate={this.handleOnUpdate} />
          <Grid.Row>
            <Grid.Column mobile={1} tablet={1} computer={1} largeScreen={1} widescreen={1} />
            <Grid.Column mobile={14} tablet={14} computer={12} largeScreen={12} widescreen={12}>
              <Header>
                {tr('quotation-detail.quotation')}
              </Header>
            </Grid.Column>
            <Grid.Column mobile={1} tablet={1} computer={1} largeScreen={1} widescreen={1} />
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={1} tablet={1} computer={1} largeScreen={1} widescreen={1} />
            <Grid.Column mobile={12} tablet={11} computer={11} largeScreen={11} widescreen={11}>
              {childrenComponents}
            </Grid.Column>
            {width <= 660 ? (
              <Grid.Column mobile={2}>
                <Sticky offset={50} context={context}>
                  <Popup
                    trigger={
                      <Icon link color="blue" size="big" name="bars" />
                    }
                    content={this.ActionButtons()}
                    on="click"
                    position="bottom rigth"
                  />
                </Sticky>
              </Grid.Column>
            ) : null}
            {width > 660 ? (
              <Grid.Column mobile={14} tablet={4} computer={3} largeScreen={3} widescreen={3}>
                <Sticky offset={50} context={context}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                        {this.ActionButtons()}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Sticky>
              </Grid.Column>
            ) : null}
            <Grid.Column mobile={1} tablet={1} computer={1} largeScreen={1} widescreen={1} />
          </Grid.Row>
          <Grid.Row>
            <Modal open={openReviews} onClose={() => this.setState({ open: false })}>
              <Modal.Header>{tr('quotation-detail.authorizations')}</Modal.Header>
              <Modal.Content>
                {loaderAuthorization ? (
                  <Dimmer active inverted>
                    <Loader active size="big" />
                  </Dimmer>
                ) : (
                  <Authorization
                    reviewsArray={reviewsArray}
                    userId={userId}
                    userName={userNameLoged}
                    customerId={customerIdByToken}
                    customerIdFromOrder={customerIdOfQuote}
                    quoteId={quoteId}
                    customerOrder={customerIdFromOrder}
                    userOrder={userOrderId}
                    customerIdByToken={customerIdByToken}
                    handleBoolField={handleBoolField}
                    history={history}
                    loaderAuthorization={loaderAuthorization}
                    changeLoader={this.handleChangeLoader}
                  />
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ openReviews: false })}>{tr('back')}</Button>
              </Modal.Actions>
            </Modal>
          </Grid.Row>
          <Grid.Row>
            <Confirm
              open={open}
              content={<Header as="h5">{tr('quotation-detail.messageUpdateQuote')}</Header>}
              cancelButton={tr('cancel')}
              confirmButton={tr('yes')}
              onCancel={() => this.setState({ open: false })}
              onConfirm={this.handleConfirmQuoteToCart}
              closeOnDimmerClick={false}
            />
          </Grid.Row>
          <Grid.Row>
            {visibleBrandsForm ? (
              <Modal size="fullscreen" closeIcon open={openMultiBrand} onClose={() => handleModalState('openMultiBrand', false)}>
                <Modal.Content>
                  <MultiBrand
                    orderId={quoteId}
                    customerId={customerIdOfQuote}
                    arrayBrand={brandsArray}
                    onClose={() => handleModalState('openMultiBrand', false)}
                    closeOnDimmerClick={false}
                    productsByMaker={{
                      microsoft: itemsMicrosoft,
                      redHat: itemsRedHat,
                      veeam: itemsVeeam,
                    }}
                  />
                </Modal.Content>
              </Modal>
            ) : null}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

OrderDetails.propTypes = {
  checkoutData: PropTypes.string,
  children: PropTypes.node.isRequired,
  customerIdByToken: PropTypes.string,
  customerIdFromOrder: PropTypes.string,
  customerIdOfQuote: PropTypes.string,
  handleBoolField: PropTypes.func,
  handleGoToBack: PropTypes.func,
  handleGoToBackAgent: PropTypes.func,
  handleGoToShipping: PropTypes.func,
  handleGoToShippingAgent: PropTypes.func,
  handleModalState: PropTypes.func,
  isLeaseQuote: PropTypes.bool.isRequired,
  odersData: PropTypes.arrayOf(PropTypes.shape({})),
  openMultiBrand: PropTypes.bool.isRequired,
  quoteId: PropTypes.string,
  show: PropTypes.bool.isRequired,
  tr: PropTypes.func,
  userId: PropTypes.string,
  userNameLoged: PropTypes.string,
  userOrderId: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

OrderDetails.defaultProps = {
  customerIdByToken: '',
  checkoutData: '',
  quoteId: '',
  customerIdFromOrder: '',
  customerIdOfQuote: '',
  userId: '',
  userNameLoged: '',
  userOrderId: '',
  odersData: [],
  items: [],
  tr: () => { },
  handleGoToShipping: () => { },
  handleGoToShippingAgent: () => { },
  handleGoToBack: () => { },
  handleGoToBackAgent: () => { },
  handleModalState: () => { },
  handleBoolField: () => { },
};

export default translate('quoteToCartcommon', { wait: true })(OrderDetails);
