import React, { Component } from 'react';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import {
  Grid, Header, Button, Divider, Modal, Form, TextArea, Loader, Input, Responsive,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import ShippingDetail from './components/shippingDetail';
import shippingRoutes from './services';
import { currencyFormat } from '../common/utils';
import routeNames from '../route-names';
import Toast from '../common/components/toast';
import './styles.css';

function multishippingGA() {
  ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
    step: 2,
    option: 'Multiples direcciones',
  });
  ReactGA.event({
    category: 'Compra',
    action: 'Multienvio',
    label: 'Multiples direcciones',
  });
  ReactGA4.event({
    category: 'Compra',
    action: 'Multienvio',
    label: 'Multiples direcciones',
  });
  ReactGA.plugin.execute('ec', 'clear');
}

class multiShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayProductsData: [],
      arrayBranchOfficeAddress: [],
      totalCost: null,
      arrayShippingDetail: [],
      openModal: false,
      conactAgentMessage: [],
      loader: false,
      orderId: '',
      openQuoteModal: false,
      quotationName: '',
      orderIdFromQuotations: props.match.params.orderId,
      hideButton: false,
      needUsdCurrency: '',
    };
  }

  componentDidMount() {
    this.handleGetDeliveryConfig();
    this.handleGetAddress();
    this.handleGetOrder();
  }

  getQuotationName = (e, { value }) => {
    const message = value;
    this.setState({ quotationName: message });
  }

  handleGetDeliveryConfig = () => {
    const { orderIdFromQuotations } = this.state;
    const { history } = this.props;
    let order = {};
    if (orderIdFromQuotations) {
      order = orderIdFromQuotations;
      this.setState({ hideButton: true });
    } else {
      order = '';
      this.setState({ hideButton: false });
    }
    const { t } = this.props;
    this.setState({ loader: true });
    let parcelCheck = false;
    shippingRoutes.getDeliveryConfig(order).then(response => {
      const deliveryConfig = response.items;
      if (deliveryConfig.length === 0) {
        multishippingGA();
        history.push(routeNames.checkout.route);
      }
      let cont = 100;
      const allParcelConfigurations = deliveryConfig.map(item => (item.deliveryConfig.map(courierServiceId => ({ courierServiceId: courierServiceId.courierServiceId })))).flat();
      const parcelValue = allParcelConfigurations[0].courierServiceId;
      if (parcelValue === 4) parcelCheck = true;
      if (deliveryConfig.length > 0) {
        this.setState({
          arrayProductsData: deliveryConfig.map(currentConfig => {
            let config = { ...currentConfig };
            config = {
              productName: config.name,
              sku: config.sku,
              quantity: config.quantity,
              image: config.image,
              outlet: config.outlet,
              addAddressValidation: false,
              amountToSendError: '',
              mistakes: false,
              restAmountToSend: 0,
              cont: 1,
              maker: config.maker,
              quotation: config.deliveryConfig.map(shippingConfig => ({
                UPSinsure: shippingConfig.UPSinsure,
                addressId: shippingConfig.branchOfficeId,
                index: config.sku + cont++,
                parcel: shippingConfig.courierServiceId,
                parcelCheked: parcelCheck,
                quantity: shippingConfig.quantity,
                sku: config.sku,
                outlet: config.outlet,
                agentPrice: shippingConfig.AgentPrice,
                parcelprice: shippingConfig.ShippingPriceByAgent,
                warehouseCs: shippingConfig.pickWarehouse,
                origins: [{}],
              })),
            };
            return config;
          }),
          loader: false,
        });
      }
    }).catch(error => {
      if (error.code === 1000) this.handlegetArrayProductsInCart();
      if (error.code !== 1216) {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
      this.setState({ loader: false });
    });
  }

  getFieldNameHashObject = (fieldName, catalog = []) => {
    const hashTable = {};
    catalog.map(currentElement => {
      const fieldValue = currentElement[fieldName];
      if (!hashTable[fieldValue]) hashTable[fieldValue] = 1;
      else hashTable[fieldValue] += 1;
      return null;
    });
    return hashTable;
  };

   getFieldNameKeys = (fieldName, catalog = []) => {
     const hashTable = this.getFieldNameHashObject(fieldName, catalog);
     return Object.keys(hashTable);
   };

  getBrands = (catalog = []) => (
    this.getFieldNameKeys('maker', catalog).map(current => ({ name: current }))
  );

  handleGetOrder = () => {
    shippingRoutes.getItems().then(orderResponse => {
      const existingBrandsArray = this.getBrands(orderResponse.items);
      const needUsdCurrencyResponse = existingBrandsArray.some(brand => (['Microsoft', 'VEEAM', 'VMware', 'VMWARE', 'Red Hat'].indexOf(brand.name) !== -1));
      this.setState({ orderId: orderResponse.id, needUsdCurrency: needUsdCurrencyResponse });
    });
  }

  handlegetArrayProductsInCart = () => {
    const { t } = this.props;
    this.setState({ loader: true });
    shippingRoutes.getItems().then(responseProducts => {
      const arrayProducts = [responseProducts];
      const filteredItems = responseProducts.items.filter(product => product.hasDelivery);
      arrayProducts.map(currentItem => {
        const item = { ...currentItem };
        item.items = filteredItems;
        return item;
      });
      this.setState({
        loader: false,
        arrayProductsData: responseProducts.items.map(currentArray => {
          const newArray = { ...currentArray };
          const newIndex = newArray.sku + 0;
          newArray.quotation = [{
            quantity: null,
            sku: '',
            parcelCheked: false,
            addressId: '',
            UPSinsure: false,
            index: newIndex,
            parcelprice: 0,
            agentPrice: false,
          }];
          newArray.cont = 1;
          newArray.amountToSendError = '';
          newArray.mistakes = false;
          newArray.addAddressValidation = false;
          return newArray;
        }),
      });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  handleGetAddress = () => {
    const { t } = this.props;
    shippingRoutes.getBranchOfficeAddress()
      .then(response => {
        const customerAddress = response.branchOffices;
        const filteredAddress = customerAddress.filter(branchOffice => branchOffice.isValidated);
        const validatedCustomerAddress = filteredAddress.map(currentRequiredField => {
          const requiredField = { ...currentRequiredField };
          requiredField.key = requiredField.id;
          requiredField.text = requiredField.name;
          requiredField.value = requiredField.id;
          return requiredField;
        });
        this.setState({ arrayBranchOfficeAddress: validatedCustomerAddress });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetNewAddress = newAddress => {
    const { arrayBranchOfficeAddress } = this.state;
    arrayBranchOfficeAddress.push(newAddress);
  };

  validations = contactAgent => {
    const { arrayShippingDetail, needUsdCurrency } = this.state;
    const { history } = this.props;
    const arrayShipping = arrayShippingDetail;
    let mistakes = false;
    let quantityError = false;
    let sumRealQuantity = 0;
    let messageError = '';
    const arrayQuotation = [];
    let sumQuotationQuantity = 0;
    let totalQuantity = 0;
    let errorsShippingArray = false;
    let logisticsAgent = false;
    let freightage = false;
    let parcelChekedError = false;
    const errorsarrayShipping = arrayShipping.filter(er => er.errors === true);
    const { t } = this.props;
    if (arrayShipping.length <= 0) {
      mistakes = true;
    } else mistakes = false;
    if (arrayShipping.length > 0) {
      arrayShipping.map(data => {
        for (let i = 0; i < arrayShipping.length; i++) {
          if (arrayShipping[i].length !== 0) {
            sumRealQuantity = arrayShipping.reduce((acc, cantidad) => acc + cantidad.quantity, 0);
          }
        }
        if (data.quotation.length > 0) {
          data.quotation.map(qt => {
            for (let q = 0; q < data.quotation.length; q++) {
              if (data.quotation[q].length !== 0) {
                sumQuotationQuantity = data.quotation.reduce((acc, quantity) => acc + quantity.quantity, 0);
              }
            }
            if (qt.agentPrice === true) logisticsAgent = true;
            if (qt.parcel === 5 && logisticsAgent === false) freightage = true;
            return qt;
          });
          totalQuantity += sumQuotationQuantity;
        }
        return data;
      });
      if (totalQuantity !== sumRealQuantity) {
        quantityError = true;
      } else {
        quantityError = false;
      }
      if (errorsarrayShipping.length > 0) {
        errorsShippingArray = true;
      } else {
        errorsShippingArray = false;
      }
    }
    if (quantityError === false) {
      arrayShipping.map(data => {
        data.quotation.map(qt => {
          for (let i = 0; i < data.quotation.length; i++) {
            if (data.quotation[i].parcelCheked === false && qt.agentPrice === false) {
              parcelChekedError = true;
            }
          }
          return qt;
        });
        return data;
      });
    }
    if (errorsShippingArray === true) {
      messageError = t('multi-shipping.errors.validateErrorsInQuantities');
      Toast(messageError, 'error');
    }
    if (parcelChekedError === true) {
      messageError = t('multi-shipping.errors.completeAllParcelOptions');
      Toast(messageError, 'warning');
    }
    if (mistakes === true) {
      messageError = t('multi-shipping.errors.completeAllFields');
      Toast(messageError, 'warning');
    }
    if (totalQuantity !== sumRealQuantity) {
      messageError = t('multi-shipping.errors.quantityError');
      Toast(messageError, 'warning');
    }
    if (freightage === true && contactAgent === false) {
      messageError = 'Al seleccionar cotizar fletera, porfavor de contactar agente.';
      Toast(messageError, 'warning');
    }
    if (contactAgent === true) freightage = false;
    if (parcelChekedError === false && mistakes === false && quantityError === false && errorsShippingArray === false && freightage === false) {
      if (contactAgent === true) this.panelState(true);
      if (logisticsAgent === true && contactAgent === false) {
        multishippingGA();
        history.push(routeNames.checkout.route);
      } else if (logisticsAgent === false) {
        arrayShipping.map(data => {
          if (data.quotation.length > 0) {
            for (let q = 0; q < data.quotation.length; q++) {
              if (data.quotation[q].length !== 0 && data.quotation[q].parcel !== 4) {
                arrayQuotation.push({
                  sku: data.quotation[q].sku,
                  branchOfficeId: data.quotation[q].addressId,
                  UPSinsure: data.quotation[q].UPSinsure,
                  courierServiceId: data.quotation[q].parcel,
                  pickWarehouse: data.quotation[q].warehouseCs,
                  source: data.quotation[q].origins.map(source => ({
                    quantity: source.quantity,
                    warehouse: source.warehouse,
                  })),
                });
              } else {
                arrayQuotation.push({
                  sku: data.quotation[q].sku,
                  branchOfficeId: '',
                  UPSinsure: data.quotation[q].UPSinsure,
                  courierServiceId: data.quotation[q].parcel,
                  pickWarehouse: data.quotation[q].warehouseCs,
                  source: data.quotation[q].origins.map(source => ({
                    quantity: source.quantity,
                    warehouse: source.warehouse,
                  })),
                });
              }
            }
          }
          return data;
        });
        shippingRoutes.addDeliveryConfig({
          deliveryConfig: arrayQuotation,
        })
          .then(() => {
            Toast(t('multi-shipping.successfulConfiguration'), 'success');
            if (contactAgent === false) {
              multishippingGA();
              history.push((routeNames.checkout.route).replace(':currency', Number(needUsdCurrency)));
            }
          })
          .catch(error => {
            this.panelState(false);
            let messageToast = '';
            if (!error.code) messageToast = t('error-codes.default');
            else messageToast = t(`error-codes.${error.code}`);
            Toast(messageToast, 'error');
          });
      }
    }
  }

panelState = visible => {
  this.setState({ openModal: visible });
}

contactAgentOnChange = (e, { value }) => {
  const message = value;
  this.setState({ conactAgentMessage: message });
}

handleContactAgent = () => {
  const { t } = this.props;
  const { conactAgentMessage, orderId } = this.state;
  let messageError = ''; // eslint-disable-line
  const body = {
    comment: conactAgentMessage,
    orderId,
  };
  shippingRoutes.contactAgent(body).then(() => {
    const messageSuccess = t('multi-shipping.sentSuccessfully');
    Toast(messageSuccess, 'success');
  }).then(() => this.handleQuoteModal())
    .catch(error => {
      this.handleQuoteModal();
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
    });
  Toast(messageError, 'warning');
}

handleCancelConfiguration = () => {
  const { history } = this.props;
  history.goBack();
}

handleShoppingCartToQuote = async name => {
  const { t, history, updateCartCount } = this.props;
  this.setState({ openQuoteModal: false });
  shippingRoutes.shoppingCartToQuote({ name }).then(responseQuote => {
    if (responseQuote) history.push(routeNames.quotations.route);
  })
    .then(() => updateCartCount())
    .catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
}

handleQuoteModal = () => {
  this.setState({ openQuoteModal: true });
}

render() {
  const {
    arrayProductsData, arrayBranchOfficeAddress, totalCost, openModal, loader,
    openQuoteModal, quotationName, hideButton, orderIdFromQuotations,
  } = this.state;
  const { t, updateCartCount } = this.props;
  return (
    <Responsive as={Grid} padded fireOnMount>
      <Grid.Row centered>
        <Grid.Column largeScreen={14} computer={14} tablet={15} mobile={16}>
          <Grid centered>
            <Grid.Row>
              <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                <Header as="h3" color="blue">{t('multi-shipping.titleMultishipping')}</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={8} textAlign="left" />
              <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={3} textAlign="right">
                {
                  hideButton === true
                    ? ''
                    : (
                      <Button size="medium" color="blue" onClick={() => this.validations(true)}>
                        <span>{t('multi-shipping.contactAgent')}</span>
                      </Button>
                    )
                }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
                <p>
                  <strong>{t('multi-shipping.note')}</strong>
                  {t('multi-shipping.AverageDeliveryTime')}
                </p>
                <p>{t('multi-shipping.priceConditions')}</p>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            {loader
              ? <Loader active size="big" />
              : (
                <Grid.Row>
                  <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                    <ShippingDetail
                      arrayProductsData={arrayProductsData}
                      tr={t}
                      arrayBranchOfficeAddress={arrayBranchOfficeAddress}
                      getTotalCost={cost => this.setState({ totalCost: cost })}
                      getArrayShipping={data => this.setState({ arrayShippingDetail: data })}
                      getShoppingCartCount={updateCartCount}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            <Divider />
            <Grid.Row />
            <Modal open={openModal}>
              <Modal.Header as="header" color="blue">{t('multi-shipping.contactAgent')}</Modal.Header>
              <Modal.Content>
                <p>
                  {t('multi-shipping.contentMessageAgent')}
                </p>
                <Form>
                  <TextArea placeholder={t('multi-shipping.writeMessage')} onInput={this.contactAgentOnChange} />
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color="blue" onClick={() => { this.setState({ openModal: false }); this.handleContactAgent(); }}>{t('multi-shipping.send')}</Button>
                <Button color="black" onClick={() => this.setState({ openModal: false })}>{t('multi-shipping.cancel')}</Button>
              </Modal.Actions>
            </Modal>
            <Grid.Row>
              <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
                <p>
                  <strong>{t('multi-shipping.note')}</strong>
                  {t('multi-shipping.insuranceApplePrice')}
                </p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Row>
                <Modal size="mini" open={openQuoteModal} onClose={() => this.setState({ openQuoteModal: false })}>
                  <Modal.Header>{t('shoppingCart.quotation')}</Modal.Header>
                  <Modal.Content>
                    <p>{t('multi-shipping.quotationMessage')}</p>
                    <Input
                      placeholder={t('shoppingCart.name')}
                      fluid
                      value={quotationName}
                      type="text"
                      onChange={this.getQuotationName}
                    />
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="lightBlack" onClick={() => this.handleShoppingCartToQuote(quotationName)}>{t('save')}</Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Row>
              <Grid.Column largeScreen={9} computer={9} tablet={7} mobile={16} />
              <Grid.Column largeScreen={7} computer={7} tablet={9} mobile={16} textAlign="right">
                <Header as="h4" color="black">
                  {t('multi-shipping.totalShippingCost')}
                  <Header as="h3" color="green" style={{ display: 'inline' }}>{Number.isNaN(totalCost) || totalCost === null ? '' : currencyFormat(totalCost)}</Header>
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={16}>
                <Button size="medium" color="blue" onClick={this.handleCancelConfiguration} fluid>
                  <span>{t('multi-shipping.back')}</span>
                </Button>
                <Responsive maxWidth={425}>
                  <br />
                </Responsive>
              </Grid.Column>
              <Responsive as={Grid.Column} minWidth={Responsive.onlyMobile.minWidth} mobile={16} maxWidth={425} />
              {
                orderIdFromQuotations
                  ? null
                  : (
                    <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={16}>
                      <Button size="medium" color="green" onClick={() => this.validations(false)} fluid>
                        <span>{t('multi-shipping.proceedPayment')}</span>
                      </Button>
                    </Grid.Column>
                  )
              }
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Responsive>
  );
}
}

multiShipping.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  updateCartCount: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
};

export default multiShipping;
