import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Icon, List, Button,
} from 'semantic-ui-react';
import { TextField } from '../common/components/materials';
import Toast from '../common/components/toast';
import serviceDashboard from './services';
import AgentDirectory from './components/AgentDirectory';

class AgentsDirectory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: '',
      customerIdError: '',
      maker: 'All',
      makersArray: [],
      agentsArray: [],
      allAgents: [],
      agent: '',
      headers: [
        { key: 'id', isKey: true },
        { key: 'maker', name: 'Marca' },
        {
          key: 'agent',
          name: 'Agente',
          format: (cell, row) => (
            <Grid>
              <Grid.Row>
                <List>
                  <List.Item>{cell}</List.Item>
                  <List.Item>{`${row.rol} ${row.maker}`}</List.Item>
                  <List.Item>{row.email}</List.Item>
                  <List.Item>{row.cellPhone}</List.Item>
                </List>
              </Grid.Row>
            </Grid>
          ),
        },
        {
          key: 'mail',
          format: (cell, row) => (<Icon as="a" href={`mailto:${row.email}`} link color="blue" name="mail outline" size="big" />),
        },
      ],
    };
  }

  componentDidMount() {

  }

  setFullscreen = () => {
    if (this.report) this.report.fullscreen();
  }

  handleGetAgents = () => {
    const { t } = this.props;
    let { data, makersArray } = this.state;
    const { customerId, allAgents } = this.state;
    makersArray = [];
    serviceDashboard.getAgents(customerId).then(agentsResponse => {
      agentsResponse.agents.map(agent => {
        makersArray.push(agent.maker);
        allAgents.push({ agent: agent.agent, maker: agent.maker });
        return agent;
      });
      data = agentsResponse.agents;
      this.handleGetMakers(makersArray);
      this.setState({ data, dataFiltered: data });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
    });
  }

  handleGetMakers = makersItems => {
    const { maker: stateMaker } = this.state;
    const makerDistinct = makersItems.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
    const makersArray = makerDistinct.map(maker => {
      const formatedMakers = { key: maker, text: maker, value: maker };
      return formatedMakers;
    });
    makersArray.push({ key: 'All', text: 'Todas las marcas', value: 'All' });
    this.setState({ makersArray });
    this.handleGetAgentsByMaker(stateMaker);
  }

  handleChangeMaker = (e, { value }) => {
    this.setState({ maker: value });
    this.handleGetAgentsByMaker(value);
  }

  handleGetAgentsByMaker = value => {
    const { allAgents } = this.state;
    const agentsArrayByMaker = [];
    let agentArrayFilter = [];
    if (value && value !== 'All') {
      allAgents.map(agent => {
        if (agent.maker === value) agentsArrayByMaker.push(agent.agent);
        return agent;
      });
      const agentMaker = agentsArrayByMaker.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
      agentArrayFilter = agentMaker.map(name => {
        const formatedAgents = { key: name, text: name, value: name };
        return formatedAgents;
      });
    } else {
      allAgents.map(agent => {
        agentsArrayByMaker.push(agent.agent);
        return agent;
      });
      const agentMaker = agentsArrayByMaker.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
      agentArrayFilter = agentMaker.map(name => {
        const formatedAgents = { key: name, text: name, value: name };
        return formatedAgents;
      });
    }
    this.setState({ agentsArray: agentArrayFilter, agent: '' });
    this.filterForMaker(value);
  }

  handleChangeAgent = (e, { value }) => {
    this.setState({ agent: value });
    this.filterForAgent(value);
  }

  filterForMaker = maker => {
    const { data: agentsToFilter } = this.state;
    let foundData = [];
    if (!maker || maker === 'All') {
      foundData = agentsToFilter;
    } else {
      foundData = agentsToFilter.filter(filterInfo => filterInfo.maker === maker);
    }
    this.setState({ dataFiltered: foundData });
  }

  filterForAgent = agent => {
    const { data: agentsToFilter } = this.state;
    let foundData = [];
    if (!agent) {
      foundData = agentsToFilter;
    } else {
      foundData = agentsToFilter.filter(filterInfo => filterInfo.agent === agent);
    }
    this.setState({ dataFiltered: foundData });
  }

  handleItemClick = () => { };

  handleCustomerIdChange = event => {
    const { t } = this.props;
    const maxLength = 8;
    const { value: customerId } = event.target;
    if (customerId && customerId.length > 0) {
      if (customerId.length > maxLength) {
        this.setState({ customerId, customerIdError: t('attributes.attributeLimit') });
        return;
      }
    }
    this.setState({ customerId, customerIdError: '' });
  }

  render() {
    const {
      headers,
      dataFiltered,
      maker,
      makersArray,
      agentsArray,
      agent,
      customerId,
      customerIdError,
    } = this.state;
    const { t } = this.props;
    return (
      <Grid container centered>
        <Grid.Row>
          <Grid.Column largeScreen={5} computer={7} tablet={10} mobile={10} />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={3} computer={5} tablet={10} mobile={10}>
            <TextField
              fullWidth
              value={customerId || ''}
              label={t('agents.writeCustomerId')}
              onChange={this.handleCustomerIdChange}
              error={customerIdError || ''}
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={3} tablet={8} mobile={8}>
            <Button
              onClick={this.handleGetAgents}
              content={t('orders.search')}
              labelPosition="center"
              icon="search"
              fluid
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
          <Grid.Column largeScreen={13} computer={13} tablet={13} mobile={13}>
            <AgentDirectory
              headers={headers}
              data={dataFiltered}
              makersArray={makersArray}
              maker={maker}
              handleChangeMaker={this.handleChangeMaker}
              agentsArray={agentsArray}
              agent={agent}
              handleChangeAgent={this.handleChangeAgent}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AgentsDirectory);
