import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const clientPlan = {};

clientPlan.getCategories = type => (
  request({
    route: apiRoutes.getBusinessPlanCategories(type),
    method: 'GET',
  })
);

clientPlan.getBusinessPlanClients = (customerId, year) => (
  request({
    route: apiRoutes.getBusinessPlanClients(customerId, year),
    method: 'GET',
  })
);

clientPlan.addBusinessPlanClientItem = body => (
  request({
    route: apiRoutes.addBusinessPlanClientItem(),
    method: 'POST',
    body,
  })
);

clientPlan.addBusinessPlanItem = body => (
  request({
    route: apiRoutes.addBusinessPlanItem(),
    method: 'POST',
    body,
  })
);

clientPlan.updateBusinessPlanClientItem = body => (
  request({
    route: apiRoutes.updateBusinessPlanClientItem(),
    method: 'PUT',
    body,
  })
);

clientPlan.deleteBusinessPlanClientItem = itemId => (
  request({
    route: apiRoutes.deleteBusinessPlanClientItem(itemId),
    method: 'DELETE',
  })
);

clientPlan.deleteBusinessPlanItem = itemId => (
  request({
    route: apiRoutes.deleteBusinessPlanItem(itemId),
    method: 'DELETE',
  })
);

clientPlan.getIndustriesPercentByCustomerId = (customerId, year) => (
  request({
    route: apiRoutes.getIndustriesPercentByCustomerId(customerId, year),
    method: 'GET',
  })
);

clientPlan.addIndustriesPercentItem = body => (
  request({
    route: apiRoutes.addIndustriesPercentItem(),
    method: 'POST',
    body,
  })
);

clientPlan.updateIndustriesPercentItem = body => (
  request({
    route: apiRoutes.updateIndustriesPercentItem(),
    method: 'PUT',
    body,
  })
);

clientPlan.updateBusinessPlanItem = body => (
  request({
    route: apiRoutes.updateBusinessPlanItem(),
    method: 'PUT',
    body,
  })
);

clientPlan.deleteIndustriesPercentItem = itemId => (
  request({
    route: apiRoutes.deleteIndustriesPercentItem(itemId),
    method: 'DELETE',
  })
);

clientPlan.getBusinessPlanElementsByCategoryDescription = (customerId, year, categoryDescription) => (
  request({
    route: apiRoutes.getBusinessPlanElementsByCategoryDescription(customerId, year, categoryDescription),
    method: 'GET',
  })
);

clientPlan.getIndustries = () => (
  request({
    route: apiRoutes.getIndustries(),
    method: 'GET',
  })
);

export default clientPlan;
