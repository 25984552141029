import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Message from './Message';

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    const objDiv = document.getElementById('messageList');
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  render() {
    const {
      messages: propMessages, userId, customerId, userEmitImage, ownImage,
    } = this.props;
    const messages = propMessages.map(message => {
      let userName = '';
      let user = userId;
      let company = customerId;
      let userImage = userEmitImage;
      if (message.userEmit) {
        userName = `${message.userEmit.name} ${message.userEmit.surname}`;
        user = message.userEmit.id;
        company = message.userEmit.customerId;
        userImage = message.userEmit.image;
      }
      return (
        <Grid.Row>
          <Grid.Column>
            <Message
              key={message.message}
              username={(userName || message.username)}
              message={message.message}
              date={message.date}
              fromMe={message.fromMe}
              user={user}
              company={company}
              userEmitImage={userImage}
              ownImage={ownImage}
            />
          </Grid.Column>
        </Grid.Row>
      );
    });

    return (
      <Grid container className="chat-messages" id="messageList">
        { messages || null }
      </Grid>
    );
  }
}

Messages.propTypes = {
  customerId: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  ownImage: PropTypes.string,
  userEmitImage: PropTypes.string,
  userId: PropTypes.string,
};

Messages.defaultProps = {
  messages: [],
  customerId: '',
  ownImage: '',
  userEmitImage: '',
  userId: '',
};

export default Messages;
