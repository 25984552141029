/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from '@material-ui/core/TextField';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Loader, Container,
} from 'semantic-ui-react';
import { TextField } from '../../../common/components/materials';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

class LogSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      startError: '',
      end: moment().format('YYYY-MM-DD'),
      endError: '',
      report: [],
      loading: false,
      sortAsc: false,
      headers: [
        { key: 'user', isKey: true },
        { key: 'customerId', name: 'No. Cliente' },
        { key: 'user', name: 'Usuario' },
        { key: 'email', name: 'Correo' },
        {
          key: 'lastLogin',
          name: 'Último inicio',
          format: cell => (`${moment(cell).format('DD-MM-YYYY HH:mm')}`),
        },
        {
          key: 'totalLogin',
          name: (
            <Button style={{ fontFamily: 'Exo, sans-serif' }} labelPosition="left" className="ui button" onClick={() => this.sortTotalLogins()}>
              Total de entradas &nbsp;
              <i id="up" style={{ display: 'none' }} className="angle up icon" />
              <i id="down" style={{ display: 'block' }} className="angle down icon" />
            </Button>
          ),
        },
      ],
    };
  }

  sortTotalLogins = () => {
    const { filteredResults, sortAsc } = this.state;
    const copyReport = [...filteredResults];
    if (sortAsc) {
      copyReport.sort((x, y) => x.totalLogin - y.totalLogin);
      document.getElementById('down').style.display = 'block';
      document.getElementById('up').style.display = 'none';
      this.setState({ sortAsc: false });
    } else {
      copyReport.sort((x, y) => y.totalLogin - x.totalLogin);
      document.getElementById('up').style.display = 'block';
      document.getElementById('down').style.display = 'none';
      this.setState({ sortAsc: true });
    }
    this.setState({ filteredResults: copyReport });
  }

  handleGetLogSessionsReport = async ({ start, end }) => {
    const { t } = this.props;
    this.setState({ report: [], loading: true });
    const logs = await service.getLogSessionsReport({ startDate: start, endDate: end })
      .then(({ logSessions: report }) => {
        if (!report) return { report: [] };
        return ({ report });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
        } else {
          Toast(messageError, 'error');
        }
        return ({ report: [] });
      });
    return logs;
  };

  handleDate = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  isValidData = () => {
    const { t } = this.props;
    const { start, end } = this.state;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    if (!start || !end) {
      Toast(t('errors.empty'), 'error');
      return false;
    }
    if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
      Toast(t('intelligenceDashboard.errors.biggerThanEnd'), 'error');
      return false;
    }
    return true;
  };

  search = async () => {
    const { start, end } = this.state;
    if (this.isValidData()) {
      const formatedData = {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      };
      const { report } = await this.handleGetLogSessionsReport(formatedData);
      this.setState({ report, loading: false, filteredResults: report });
    }
  };

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!filter) {
      this.setState({ filter, filteredResults: allReport });
    } else {
      const filteredResults = allReport.filter(filterInfo => ((filterInfo.customerId && filterInfo.customerId.toLowerCase().includes(baseFilter))
        || (filterInfo.user && filterInfo.user.toLowerCase().includes(baseFilter))
        || (filterInfo.email && filterInfo.email.toLowerCase().includes(baseFilter))
        || (filterInfo.lastLogin && filterInfo.lastLogin.toLowerCase().includes(baseFilter))
        || (filterInfo.totalLogin && filterInfo.totalLogin.toLowerCase().includes(baseFilter))));
      this.setState({ filter, filteredResults });
    }
  };

  render() {
    const { t } = this.props;
    const {
      start, startError, end, endError, report, headers, loading, filteredResults, filter,
    } = this.state;
    const fileName = `Reporte_sesiones_${moment(start).format('DD-MM-YYYY')}_${moment(end).format('DD-MM-YYYY')}`;

    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('intelligenceDashboard.logSessions')}

          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={5} tablet={4} mobile={14}>
            <TextField
              fullWidth
              maxLength={40}
              value={filter || ''}
              label={t('intelligenceDashboard.filter')}
              onChange={this.handleFilter}
            />
          </Grid.Column>
          <Grid.Column className="paddingBottomIntelligenceDashboard" computer={3} tablet={4} mobile={8}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.fromDate')}</Header>
            <DatePicker
              type="date"
              name="start"
              format="dd/mm/yyyy"
              value={start}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={startError}
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} mobile={8}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.toDate')}</Header>
            <DatePicker
              type="date"
              name="end"
              format="dd/mm/yyyy"
              value={end}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={endError}
            />
          </Grid.Column>
          <Grid.Column computer={2} tablet={4} mobile={7}>
            <Button loading={loading} onClick={this.search}>{t('intelligenceDashboard.search')}</Button>
          </Grid.Column>
          {(loading || !report || report.length === 0)
            ? null
            : (
              <Grid.Column computer={3} tablet={3} mobile={8}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={filteredResults || report}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('intelligenceDashboard.download')}
                  sheetName="Sesiones"
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  data={filteredResults || report}
                  headers={headers}
                  pageSize={15}
                />
              </Container>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(LogSessions);
