import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

import services from './services';

const CartContext = createContext();

function CartProvider({ children }) {
  const [cartCount, setCartCount] = useState(0);

  const updateCartCount = async () => {
    try {
      const response = await services.getTotalItems();
      setCartCount(response?.quantity || 0);
    } catch (error) {
      setCartCount(0);
    }
  };

  return (
    <CartContext.Provider value={{ cartCount, updateCartCount }}>
      {children}
    </CartContext.Provider>
  );
}

CartProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export { CartContext, CartProvider };
