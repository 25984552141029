import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Segment,
  Button,
  Card,
  Image,
} from 'semantic-ui-react';

const translatePath = 'productConfig.menus.uploadImages.cardImages';

function CardImages(props) {
  const {
    t,
    images,
    imageType,
    handleOnDelete,
  } = props;

  return (
    <Card.Group doubling>
      {images.map(image => (
        <Segment
          key={image.id}
          loading={image.uploading}
          style={{ border: 'none', boxShadow: 'none', marginTop: 0 }}
        >
          <Card key={image.id}>
            <Card.Content textAlign="center">
              <Image size="small" src={image.low} />
            </Card.Content>
            <Card.Content extra>
              <Button
                fluid
                primary
                content={t(`${translatePath}.deleteButton`)}
                size="mini"
                loading={image.deleting}
                onClick={() => handleOnDelete(image, imageType)}
              />
            </Card.Content>
          </Card>
        </Segment>
      ))}
    </Card.Group>
  );
}

CardImages.propTypes = {
  imageType: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    uploading: PropTypes.bool,
    deleting: PropTypes.bool,
  })),
  handleOnDelete: PropTypes.func.isRequired,
};

CardImages.defaultProps = {
  images: [{
    uploading: false,
    deleting: false,
  }],
};

export default translate('common', { wait: true })(CardImages);
