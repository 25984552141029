import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Header, Checkbox } from 'semantic-ui-react';

const Controllers = props => {
  const { t } = props;
  const { smsFlag, emailFlag, handleChangeConfig } = props;
  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h4" color="blue">{t('chat.notifyBy')}</Header>
            <Checkbox
              name="smsCheck"
              label={t('chat.bySms')}
              checked={smsFlag === true}
              onChange={handleChangeConfig}
            />
            <Checkbox
              name="emailCheck"
              label={t('chat.byEmail')}
              checked={emailFlag === true}
              onChange={handleChangeConfig}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

Controllers.propTypes = {
  smsFlag: PropTypes.bool.isRequired,
  emailFlag: PropTypes.bool.isRequired,
  handleChangeConfig: PropTypes.func.isRequired,
};

Controllers.defaultProps = {
};

export default translate('common', { wait: true })(Controllers);
