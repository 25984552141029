import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import { Grid, Button } from 'semantic-ui-react';
import { TextField, LinearLoader } from '../../../common/components/materials';
import Toast from '../../../common/components/toast';
import service from '../../services';
import Pareto from './pareto';
import Target from './target';

function Clients(props) {
  const {
    handleAddOrUpdate, handleDelete: handleFatherDelete, t, categories,
    targetClients, paretoClients, customerId, year, loadDone, activeLoader,
  } = props;

  const [state, setState] = useState({
    categoryId: '',
    itemId: '',
    sell: '',
    couldSell: '',
    client: '',
  });

  function cleanErrors() {
    setState({
      ...state,
      categoryIdError: '',
      sellError: '',
      clientError: '',
    });
  }

  function cleanState() {
    setState({
      categoryId: '',
      itemId: '',
      sell: '',
      couldSell: '',
      client: '',
    });
  }

  function isValidBody() {
    const {
      couldSell, client, categoryId, itemId,
    } = state;
    const errors = {};
    if (!itemId && !categoryId) errors.categoryIdError = t('clientPlan.errors.empty');
    if (!couldSell) errors.couldSellError = t('clientPlan.errors.empty');
    if (!client) errors.clientError = t('clientPlan.errors.empty');
    if (Object.keys(errors).length > 0) {
      setState({ ...state, ...errors });
      return false;
    }
    cleanErrors();
    return true;
  }

  function addClient() {
    const {
      sell, client, categoryId, couldSell,
    } = state;
    const body = {
      customerId, year, categoryId, client, sell, couldSell,
    };
    if (!isValidBody()) return;
    activeLoader();
    service.addBusinessPlanClientItem(body)
      .then(response => {
        handleAddOrUpdate({ ...body, id: response.id });
        cleanState();
      })
      .catch(() => {
        Toast(t('clientPlan.errors.add'), 'error');
        handleAddOrUpdate();
      });
  }

  function updateClient() {
    const {
      itemId, sell, client, couldSell,
    } = state;
    const body = {
      itemId, client, sell, couldSell,
    };
    if (!isValidBody()) return;
    activeLoader();
    service.updateBusinessPlanClientItem(body)
      .then(() => {
        handleAddOrUpdate({ ...body, id: itemId });
        cleanState();
      })
      .catch(() => {
        Toast(t('clientPlan.errors.update'), 'error');
        handleAddOrUpdate();
      });
  }

  const handleSave = () => {
    const { itemId } = state;
    if (itemId) updateClient();
    else addClient();
  };

  const handleChange = ({ target }) => {
    const { value, id, name } = target;
    const nameOrId = id || name;
    setState({ ...state, [nameOrId]: value, [`${nameOrId}Error`]: '' });
  };

  const handleUpdate = element => {
    const {
      sell, client, id: itemId, couldSell, categoryId,
    } = element;
    setState({
      sell, client, itemId, couldSell, categoryId,
    });
  };

  const handleDelete = element => {
    const { id: itemId, categoryId } = element;
    if (!itemId) return;
    activeLoader();
    service.deleteBusinessPlanClientItem(itemId, categoryId)
      .then(({ status }) => {
        if (!status) return;
        handleFatherDelete(itemId, categoryId);
      })
      .catch(() => {
        Toast(t('clientPlan.errors.delete'), 'error');
        handleFatherDelete();
      });
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column
          largeScreen={2}
          computer={2}
          tablet={7}
          mobile={8}
        >
          <TextField
            id="categoryId"
            name="categoryId"
            disabled={state.itemId}
            select
            fullWidth
            label="Categoría"
            value={state.categoryId}
            error={state.categoryIdError || ''}
            onChange={handleChange}
            options={categories}
          />
        </Grid.Column>
        <Grid.Column
          largeScreen={3}
          computer={3}
          tablet={7}
          mobile={8}
        >
          <TextField
            id="client"
            fullWidth
            multiline
            label="Cliente"
            error={state.clientError || ''}
            value={state.client}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column
          largeScreen={5}
          computer={5}
          tablet={7}
          mobile={6}
        >
          <TextField
            id="couldSell"
            fullWidth
            multiline
            error={state.couldSellError || ''}
            label="¿Qué le puedo vender?"
            value={state.couldSell}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column
          largeScreen={5}
          computer={5}
          tablet={7}
          mobile={6}
        >
          <TextField
            id="sell"
            disabled={state.categoryId !== 13}
            fullWidth
            multiline
            error={state.sellError || ''}
            label="¿Qué le vendo?"
            value={state.sell}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column
          largeScreen={1}
          computer={1}
          tablet={2}
          mobile={3}
          verticalAlign="middle"
        >
          <Button onClick={handleSave}>{state.itemId ? t('edit') : t('add')}</Button>
        </Grid.Column>
      </Grid.Row>
      {
        loadDone
          ? (
            <Grid.Row className="no-padding-y">
              <Grid.Column>
                <LinearLoader color="yellow" />
              </Grid.Column>
            </Grid.Row>
          )
          : null
      }
      <Grid.Row className="no-padding-top">
        <Grid.Column largeScreen={16} computer={16} tablet={14} mobile={12}>
          <Pareto
            data={paretoClients}
            loadDone={loadDone}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={14} mobile={12}>
          <Target data={targetClients} handleUpdate={handleUpdate} handleDelete={handleDelete} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Clients.propTypes = {
  t: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  targetClients: PropTypes.arrayOf(PropTypes.shape({})),
  paretoClients: PropTypes.arrayOf(PropTypes.shape({})),
  customerId: PropTypes.string,
  year: PropTypes.number,
  handleAddOrUpdate: PropTypes.func,
  activeLoader: PropTypes.func,
  handleDelete: PropTypes.func,
  loadDone: PropTypes.bool,
};

Clients.defaultProps = {
  categories: [],
  targetClients: [],
  paretoClients: [],
  customerId: '',
  year: 2021,
  t: null,
  loadDone: false,
  handleAddOrUpdate: () => { },
  activeLoader: () => { },
  handleDelete: () => { },
};

export default translate('common', { wait: true })(Clients);
