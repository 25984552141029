const bucket = `${process.env.REACT_APP_URL_AWS}`;
const assetUrl = `${bucket}/imagenes/assets/siclik/`;

const comercio = `${assetUrl}siclikComercio.png`;
const suscribe = `${assetUrl}siclikSuscribe.png`;
const cesa = `${assetUrl}siclik-CESA.png`;

const imagesSiclikCS = [
  {
    id: 1,
    image: comercio,
    name: 'comercio',
    redirect: '/login/comercio',
    redirectWeb: '',
    text: 'Comercio electrónico',
  },
  {
    id: 2,
    image: suscribe,
    name: 'suscribe',
    redirect: '',
    redirectWeb: 'https://clicksuscribe.compusoluciones.com/#/Login',
    text: 'Suscripción de licencias',
  },
  {
    id: 3,
    image: cesa,
    name: 'cesa',
    redirect: '',
    redirectWeb: 'https://cesa.siclik.mx/',
    text: 'Soporte y Servicios profesionales',
  },
];

export default imagesSiclikCS;
