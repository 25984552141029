/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Container,
  Placeholder,
  Card,
  Grid,
} from 'semantic-ui-react';

export default function ItemLoader() {
  const CardLoader = key => (
    <Card key={key} style={{ height: 200, width: 200 }}>
      <Card.Content>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
      </Card.Content>
    </Card>
  );

  return (
    <Container fluid>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Card.Group>
              {
                Array(6)
                  .fill(CardLoader)
                  .map((Item, index) => <Item key={index} />)
              }
            </Card.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}
