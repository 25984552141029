import {
  UPDATE_ITEM_QUANTITY,
  REMOVE_ITEM,
  FETCH_WISH_LIST_ITEMS,
  ITEMS_LOADER,
  FILTER_ITEMS,
} from './constants';

import request, {
  fetchItemsWishList,
  updateItemQuantity,
  sendToCart,
  removeItemFromWishList,
  addItem,
} from './request';

function activateLoader(loadertype, dispatch) {
  dispatch({
    type: loadertype,
    payload: { loader: true },
  });
}

function onLoadingFinish(loadertype, dispatch) {
  return function actionDispatch(hasError) {
    dispatch({
      type: loadertype,
      payload: {
        loader: false,
        error: hasError,
      },
    });
  };
}

function updateQuantity(dispatch) {
  return async function actionDispatch(wishListId, item) {
    await updateItemQuantity(wishListId, item);
    dispatch({
      type: UPDATE_ITEM_QUANTITY,
      payload: { item },
    });
  };
}

function removeItem(dispatch) {
  return async function actionDispatch(wishListId, sku) {
    await removeItemFromWishList(wishListId, sku);
    dispatch({
      type: REMOVE_ITEM,
      payload: { sku },
    });
  };
}

function sendItemToCart() {
  return async function actionDispatch(item) {
    await sendToCart(item);
  };
}

function getWishListItems(dispatch) {
  return async function actionDispatch(wishList) {
    activateLoader(ITEMS_LOADER, dispatch);
    const fetchData = () => fetchItemsWishList(wishList.id);
    const loadingFinish = onLoadingFinish(ITEMS_LOADER, dispatch);
    let items = [];
    if (!wishList.default) items = await request(fetchData, loadingFinish);
    dispatch({
      type: FETCH_WISH_LIST_ITEMS,
      payload: { items },
    });
  };
}

function setFilteredItems(dispatch) {
  return function actionDispatch(itemsFound) {
    dispatch({
      type: FILTER_ITEMS,
      payload: { itemsFound },
    });
  };
}

function addItemToWishList() {
  return async function actionDispatch(wishListId, sku) {
    await addItem(wishListId, sku);
  };
}

export default {
  updateQuantity,
  removeItem,
  sendItemToCart,
  getWishListItems,
  setFilteredItems,
  addItemToWishList,
};
