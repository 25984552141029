const menuItems = [
  {
    key: 1,
    title: 'Perfil',
    href: '/perfil',
  },
  {
    key: 2,
    title: 'Usuarios',
    href: '/usuarios',
  },
  {
    key: 3,
    title: 'Direcciones de entrega',
    href: '/direcciones',
  },
  {
    key: 4,
    title: 'Clientes',
    href: '/clientes',
  },
];

export default menuItems;
