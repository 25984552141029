import React from 'react';
import { translate } from 'react-i18next';
import { Grid, Button, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';

const AddCompleteFoda = props => {
  const {
    t, openUploadModal, handleClickCancel, handleInputChange, handleClickSave, disabledButton,
  } = props;

  return (
    <Modal open={openUploadModal}>
      <Modal.Header left>{t('agents.uploadFile')}</Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row>
            <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={15}>
              <input
                required
                type="file"
                name="file"
                id="file"
                onChange={handleInputChange}
                placeholder="Archivo de excel"
                accept=".xlsx"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <a href="https://s3.amazonaws.com/aws.cs-siclick/templates/foda.xlsx">Descarga el archivo base</a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button color="blue" onClick={handleClickSave} disabled={disabledButton}>Guardar</Button>
        <Button onClick={handleClickCancel} disabled={disabledButton}>{t('cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

AddCompleteFoda.defaultProps = {
  openUploadModal: true,
  t: null,
  handleClickCancel: null,
  handleInputChange: null,
  handleClickSave: null,
  disabledButton: false,
};

AddCompleteFoda.propTypes = {
  t: propTypes.func,
  openUploadModal: propTypes.bool,
  handleClickCancel: propTypes.func,
  handleInputChange: propTypes.func,
  handleClickSave: propTypes.func,
  disabledButton: propTypes.bool,
};

export default translate('common', { wait: true })(AddCompleteFoda);
