import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid, Message } from 'semantic-ui-react';
import '../styles.css';

const Features = ({ firstProduct, secondProduct }) => (
  <Grid padded>
    <Grid.Row>
      <Grid.Column computer={8} tablet={8} mobile={8}>
        <Message fluid>
          {firstProduct.features.map(feature => (
            <Grid.Column key={feature.title}>
              <Message.Header>
                {feature.title}
              </Message.Header>
              <p>
                {feature.values}
              </p>
              <br />
            </Grid.Column>
          ))}
        </Message>
      </Grid.Column>
      <Grid.Column computer={8} tablet={8} mobile={8}>
        <Message fluid>
          {secondProduct.features.map(feature => (
            <Grid.Column key={feature.title}>
              <Message.Header>
                {feature.title}
              </Message.Header>
              <p>
                {feature.values}
              </p>
              <br />
            </Grid.Column>
          ))}
        </Message>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

Features.propTypes = {
  firstProduct: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  secondProduct: PropTypes.shape({
    features: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

Features.defaultProps = {
  firstProduct: {},
  secondProduct: {},
};

export default translate('common', { wait: true })(Features);
