import apiRoutes from '../apiRoutes';
import { request } from '../common/fetch-utils';

const products = {};

products.getProductBySku = (productId, isVirtual = 0) => (
  request({
    route: apiRoutes.getProductBySku(productId, isVirtual),
    method: 'GET',
  })
);

products.getAllImages = sku => (
  request({
    route: apiRoutes.getAllImages(sku),
    method: 'GET',
  })
);

products.getAllMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

products.getAllProducts = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

products.getProductInformation = sku => (
  request({
    route: apiRoutes.getProductInformation(sku),
    method: 'GET',
  })
);

export default products;
