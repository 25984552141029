import cookie from 'js-cookie';
import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const quotes = {};

quotes.getUsers = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
    method: 'GET',
  })
);

quotes.getQuotationsByUser = (userId, getAll) => (
  request({
    route: apiRoutes.getQuotationsAgentByUser(userId, getAll),
    method: 'GET',
  })
);

quotes.quoteToShoppingCart = quoteId => (
  request({
    route: apiRoutes.quoteToShoppingCart(quoteId),
    method: 'PUT',
  })
);

quotes.getUnreadMessages = orderData => (
  request({
    route: apiRoutes.getUnreadMessages(),
    method: 'POST',
    body: orderData,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
      Authorization: `Bearer ${cookie.get('token')}`,
    },
  })
);

quotes.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

quotes.getQuotationByCustomerId = customerId => (
  request({
    route: apiRoutes.getQuotationByCustomerId(customerId),
    method: 'GET',
  })
);

quotes.addSurveyResponse = body => (
  request({
    route: apiRoutes.addSurveyResponse(),
    method: 'POST',
    body,
  })
);

quotes.getSurveyResponse = body => (
  request({
    route: apiRoutes.getSurveyResponse(),
    method: 'GET',
    body,
  })
);

export default quotes;
