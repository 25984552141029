import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Container,
  Image,
  Grid,
  Segment,
  Menu,
  Divider,
  Label,
} from 'semantic-ui-react';

import { DEFAULT_IMAGE } from '../../constants';

const translatePath = 'productConfig.menus.uploadImages';
function ImageSizes({ t, images, isImageType }) {
  const [activeItem, setActiveItem] = useState(images.length ? images[0] : null);

  const getImageBySize = (image, size) => {
    if (image) {
      const itemSize = image.sizes.find(resizedImage => resizedImage.size === size);
      return itemSize ? itemSize.preview : DEFAULT_IMAGE;
    }
    return DEFAULT_IMAGE;
  };

  const handleItemClick = (e, { name }) => {
    const index = Number(name.split('-')[1]);
    const selectedItem = images[index - 1];
    setActiveItem(selectedItem);
  };

  return (
    <Container>
      <Segment>
        {images.length ? (
          <Grid>
            <Grid.Column width={2}>
              <Menu icon="labeled" vertical>
                {images.map((image, index) => (
                  <Menu.Item
                    key={image.path}
                    name={`image-${index + 1}`}
                    active={activeItem.name === image.name}
                    color={activeItem.name === image.name ? 'green' : 'grey'}
                    onClick={handleItemClick}
                  >
                    <Image size="tiny" src={image.preview} />
                    {t(`${translatePath}.imageSizes.image`)}
                    {' '}
                    {index + 1}
                  </Menu.Item>
                ))}
              </Menu>
            </Grid.Column>
            <Grid.Column stretched width={14}>
              <Segment>
                <div>
                  <Segment raised>
                    <Label color="blue" attached="top left">{t(`${translatePath}.imageSizes.thumbnail`)}</Label>
                    <Image
                      centered
                      bordered
                      src={getImageBySize(activeItem, 'thumbnail')}
                      size="tiny"
                    />
                  </Segment>
                </div>
                <Divider hidden />
                <div>
                  <Segment raised>
                    <Label color="blue" attached="top left">{t(`${translatePath}.imageSizes.small`)}</Label>
                    <Image
                      centered
                      bordered
                      src={getImageBySize(activeItem, 'low')}
                      size="medium"
                    />
                  </Segment>
                </div>
                <Divider hidden />
                <div hidden={!isImageType}>
                  <Segment raised>
                    <Label color="blue" attached="top left">{t(`${translatePath}.imageSizes.medium`)}</Label>
                    <Image
                      centered
                      bordered
                      src={getImageBySize(activeItem, 'medium')}
                      size="large"
                    />
                  </Segment>
                </div>
                <Divider hidden />
                <div hidden={!isImageType}>
                  <Segment raised>
                    <Label color="blue" attached="top left">{t(`${translatePath}.imageSizes.large`)}</Label>
                    <Image
                      centered
                      bordered
                      src={getImageBySize(activeItem, 'big')}
                      size="massive"
                    />
                  </Segment>
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        ) : <Container textAlign="center">{t(`${translatePath}.noImages`)}</Container>}
      </Segment>
    </Container>
  );
}

ImageSizes.propTypes = {
  isImageType: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    preview: PropTypes.string,
  })),
};

ImageSizes.defaultProps = {
  images: [],
};

export default translate('common', { wait: true })(ImageSizes);
