import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const service = {};

service.login = body => (
  request({
    route: apiRoutes.login(),
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  })
);

service.tokenSuscribe = () => (
  request({
    route: apiRoutes.tokenSuscribe(),
    method: 'POST',
    body: {},
  })
);

service.validateCode = (secretKey, userId) => (
  request({
    route: apiRoutes.validateCode(secretKey, userId),
    method: 'GET',
  })
);

export default service;
