import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

class Years extends Component {
  constructor(props) {
    super(props);
    const { year } = this.props;
    this.state = {
      value: year,
    };
  }

  handleChange = (e, { value }) => {
    const { change } = this.props;
    this.setState({ value });
    change(value);
  }

  render() {
    const { value } = this.state;
    const {
      min, max, clearable, title, noResultsMessage,
    } = this.props;
    const allYears = [];
    for (let x = min; x < max; x++) {
      allYears.push({ key: x, text: x, value: x });
    }
    return (
      <Dropdown
        clearable={clearable}
        compact
        placeholder={title}
        value={value}
        search
        selection
        options={allYears}
        onChange={this.handleChange}
        noResultsMessage={noResultsMessage}
      />
    );
  }
}

Years.defaultProps = {
  title: 'Año',
  min: 2017,
  max: 2030,
  year: '',
  noResultsMessage: 'Intenta con otro valor',
  clearable: true,
  change: () => {},
};

Years.propTypes = {
  change: PropTypes.func,
  clearable: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  noResultsMessage: PropTypes.string,
  title: PropTypes.string,
  year: PropTypes.string,
};

export default Years;
