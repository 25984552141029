import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, List, Image, Input, Dropdown, Button, Icon, Modal, Divider, Responsive, Label,
} from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';
import Quotation from '../../common/components/quotation';
import shippingRoutes from '../services';
import Toast from '../../common/components/toast';
import '../styles.css';

class ShippingDetail extends Component {
  static getDerivedStateFromProps(props) {
    if (props.arrayProductsData.length !== 0) {
      return {
        arrayProductsResponse: props.arrayProductsData,

      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openModalQuotation: false,
      branchOfficesInformation: [],
      typeManeuver: '',
      maneuverDescription: '',
      comment: '',
      typeComment: '',
      arrayProductsResponse: [],
      branchOfficesByCs: [],
      warehouseInformation: [],
      warehouseCs: '',
      appearHome: true,
    };
  }

  componentDidMount() {
    this.handleSendPropsToIndex();
    this.validateCourierService();
  }

  getParcelPrice = (body, index) => {
    const { arrayProductsResponse } = this.state;
    const { getArrayShipping } = this.props;
    const arrayData = arrayProductsResponse;
    arrayData.map(data => {
      const dataCopy = { ...data };
      const sku = body.skus.map(skus => skus.sku).toString();
      const { addressId } = body;
      const { parcel } = body;
      const { UPSinsure } = body;
      const { warehouseCs } = body;
      const { origins } = body;
      if (data.sku === sku) {
        dataCopy.quotation = data.quotation.map(qt => {
          if (qt.sku === sku && qt.addressId === addressId && qt.index === index && qt.agentPrice === false) {
            dataCopy.addAddressValidation = false;
            return ({
              ...qt,
              parcel,
              parcelprice: qt.shippingPriceByAgent,
              UPSinsure,
              warehouseCs,
              parcelCheked: true,
              origins,
            });
          } if (qt.sku === sku && qt.addressId === addressId && qt.index === index && qt.agentPrice === true) {
            dataCopy.addAddressValidation = false;
            return ({
              ...qt,
              parcel,
              UPSinsure,
              parcelprice: qt.shippingPriceByAgent,
              warehouseCs,
              parcelCheked: true,
              origins,
              updatedPrice: true,
            });
          }
          return qt;
        });
      }
      return dataCopy;
    });
    this.handleCalculateTotalCost(arrayData);
    getArrayShipping(arrayProductsResponse);
    this.setState({ arrayProductsResponse: arrayData });
  }

  handleCalculateTotalCost = arrayData => {
    const { getTotalCost } = this.props;
    const total = [];
    arrayData.map(data => {
      for (let i = 0; i < data.quotation.length; i++) {
        if (data.quotation[i].length !== 0) {
          total.push({ price: data.quotation[i].parcelprice });
          const sumQuotation = total.reduce((sum, price) => sum + price.price, 0);
          getTotalCost(sumQuotation);
        }
      }
      return data;
    });
  }

  handleBranchOfficesOnChange = (e, { value, id }) => {
    const { arrayProductsResponse } = this.state;
    const arrayData = arrayProductsResponse;
    const idReduced = id.substring(0, id.length - 1);
    arrayData.map(arraydata => {
      if (arraydata.sku === idReduced) {
        arraydata.quotation.map(qt => {
          if (id === qt.index) {
            return ({ ...qt, addressId: value });
          }
          return qt;
        });
      }
      return arraydata;
    });
  }

  handleWarehouseCsAddressChange = ({ value }) => {
    this.setState({ warehouseCs: value });
  }

  handleGetBranchOfficesByCs = () => {
    const { branchOfficesByCs, warehouseCs } = this.state;
    const arrayInformation = branchOfficesByCs.filter(addressInfo => addressInfo.id === warehouseCs);
    this.setState({ warehouseInformation: arrayInformation });
  }

  handleGetAddress = value => {
    const { customerFromOrder, tr: t } = this.props;
    shippingRoutes.getBranchOfficeAddress(customerFromOrder)
      .then(response => {
        const arraybranchOffices = response.branchOffices;
        arraybranchOffices.map(alter => {
          const alterCopy = { ...alter };
          if (alter.appointment === false) {
            alterCopy.appointment = 'No';
          } else {
            alterCopy.appointment = 'Si';
          }
          if (alter.parking === false) {
            alterCopy.parking = 'No';
          } else {
            alterCopy.parking = 'Si';
          }
          return alter;
        });
        const arrayAddressInformation = arraybranchOffices.filter(addressInfo => addressInfo.id === value);
        this.handleGetManeuvers(value);
        this.handleGetComments(value);
        this.setState({ branchOfficesInformation: arrayAddressInformation });
      })
      .catch(error => {
        if (error.code !== 1005) {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetManeuvers = value => {
    const { tr: t } = this.props;
    shippingRoutes.getManeuver(value)
      .then(response => {
        const arrayManeuver = response.maneuvers;
        const type = arrayManeuver.map(typeManeuver => typeManeuver.type);
        const description = arrayManeuver.map(info => info.maneuver);
        this.setState({ typeManeuver: type, maneuverDescription: description });
      })
      .catch(error => {
        if (error.code !== 1005) {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetComments = value => {
    const { tr: t } = this.props;
    shippingRoutes.getComments(value)
      .then(response => {
        const arrayComment = response.comments;
        const type = arrayComment.map(typeComment => typeComment.type);
        const commentInfo = arrayComment.map(comment => comment.comment);
        this.setState({ typeComment: type, comment: commentInfo });
      })
      .catch(error => {
        if (error.code !== 1005) {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetAgentPrice = (e, { value, id, name }) => {
    const { arrayProductsResponse } = this.state;
    const { getArrayShipping } = this.props;
    let shippingConfig = arrayProductsResponse;
    const skuToUpdate = shippingConfig.findIndex(findElement => findElement.sku === name);
    shippingConfig = shippingConfig.map(arraydata => {
      if (arraydata.sku === name) {
        arraydata.quotation.map(qt => {
          if (name === arraydata.sku && id === qt.index) {
            const elementToUpdate = arraydata.quotation.findIndex(findElement => findElement.index === id);
            shippingConfig[skuToUpdate].quotation[elementToUpdate].parcelprice = Number(value);
            shippingConfig[skuToUpdate].quotation[elementToUpdate].shippingPriceByAgent = Number(value);
            if (value === '') shippingConfig[skuToUpdate].quotation[elementToUpdate].updatedPrice = false;
            else shippingConfig[skuToUpdate].quotation[elementToUpdate].updatedPrice = true;
          }
          return qt;
        });
      }
      return arraydata;
    });
    this.setState({ arrayProductsResponse: shippingConfig });
    this.handleCalculateTotalCost(shippingConfig);
    getArrayShipping(shippingConfig);
  }

  handleSendPropsToIndex = () => {
    const { arrayProductsResponse } = this.state;
    const { getArrayShipping } = this.props;
    getArrayShipping(arrayProductsResponse);
    this.handleCalculateTotalCost(arrayProductsResponse);
  }

  handleOnUpdate = (e, { width }) => {
    if (width > 768) this.setState({ needClassName: true });
    else this.setState({ needClassName: false });
  }

  handleParcelOnChange = (e, { name, value, id }) => {
    const { arrayProductsResponse } = this.state;
    let shippingConfig = arrayProductsResponse;
    const skuToUpdate = shippingConfig.findIndex(findElement => findElement.sku === name);
    shippingConfig = shippingConfig.map(arraydata => {
      if (arraydata.sku === name) {
        arraydata.quotation.map(qt => {
          if (id === qt.index) {
            const elementToUpdate = arraydata.quotation.findIndex(findElement => findElement.index === id);
            shippingConfig[skuToUpdate].quotation[elementToUpdate].parcelSeletected = true;
            shippingConfig[skuToUpdate].quotation[elementToUpdate].parcelByLogistics = value;
          }
          return qt;
        });
      }
      return arraydata;
    });
    this.setState({ arrayProductsResponse: shippingConfig });
  }

  validateCourierService = () => {
    const { arrayProductsResponse } = this.state;
    const arrayShipping = arrayProductsResponse;
    arrayShipping.map(data => {
      const copyData = { ...data };
      data.quotation.map(qt => {
        for (let i = 0; i < data.quotation.length; i++) {
          if (data.quotation[i].parcelByLogistics !== 0 && data.quotation[i].parcelByLogistics !== 4
            && data.quotation[i].parcelByLogistics !== 5) {
            copyData.quotation[i].parcelSeletected = true;
          }
        }
        return qt;
      });
      return copyData;
    });
  }

  render() {
    const {
      openModal, branchOfficesInformation, typeManeuver, typeComment, comment, warehouseInformation,
      openModalQuotation, maneuverDescription, arrayProductsResponse, appearHome, needClassName,
    } = this.state;
    const {
      tr: t, arrayBranchOfficeAddress, arrayParcels, customerFromOrder, defaultBranch,
    } = this.props;
    return (
      <Grid container>
        <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate} />
        <Grid.Row>
          <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={4} textAlign="left">
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('multi-shipping.products')}</Header>
            </Responsive>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={3} mobile={4} textAlign="left">
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('Dirección de envío')}</Header>
            </Responsive>
            <Grid.Row />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={3} textAlign="left">
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('Ajustar precio')}</Header>
            </Responsive>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={4} textAlign="left">
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('multi-shipping.shippingMethod')}</Header>
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        {arrayProductsResponse.map(item => (
          <Grid>
            <Grid.Row>
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
                <CSSTransition
                  in={appearHome}
                  appear
                  timeout={2000}
                  classNames="fade"
                >
                  <Grid.Row>
                    <List relaxed="very" size="medium">
                      <List.Item key={item.sku}>
                        <Image avatar size="small" src={item.image} />
                        <List.Content>
                          <List.Header className="header_productName">
                            <Header as="h5" color="blue">{item.name}</Header>
                          </List.Header>
                          <List.Description>
                            <Header as="h5" color="black">
                              {t('multi-shipping.sku')}
                              {' '}
                              {item.sku}
                            </Header>
                          </List.Description>
                          <List.Description>
                            <Header as="h4" color="black">
                              {t('multi-shipping.quantity')}
                              &nbsp;
                              <Input
                                className="input-quantity"
                                type="number"
                                defaultValue={item.quantity}
                                size="large"
                                transparent
                                name={item.sku}
                                id={item.id}
                                onChange={this.handleAddProducts}
                                error={item.quantityError}
                                disabled
                              />
                              <Grid.Row>
                                <p style={{ color: 'red' }}>{item.quantityError}</p>
                              </Grid.Row>
                            </Header>
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    </List>
                  </Grid.Row>
                </CSSTransition>
              </Grid.Column>
              <Divider />
              <Grid.Column largeScreen={4} computer={4} tablet={3} mobile={16} floated="left">
                {item.quotation.map(itemArray => (
                  <Grid>
                    <Grid.Row className="drop-add-branch-office-logistics">
                      <Dropdown
                        fluid
                        id={itemArray.index}
                        name={item.sku}
                        placeholder={t('multi-shipping.selectBranchOficce')}
                        defaultValue={itemArray.addressId}
                        options={arrayBranchOfficeAddress}
                        onChange={this.handleBranchOfficesOnChange}
                        search
                        selection
                      />
                      <Icon name="info circle" size="large" onClick={() => { this.handleGetAddress(itemArray.addressId); this.setState({ openModal: true }); }} />
                    </Grid.Row>
                    <Grid.Row>
                      <Input
                        className={needClassName === true ? 'input-amount-to-send' : ''}
                        transparent
                        id={itemArray.index}
                        defaultValue={itemArray.quantity}
                        name={item.sku}
                        placeholder={t('multi-shipping.amountToSend')}
                        size="large"
                        error={item.amountToSendError}
                        type="number"
                        disabled
                      />
                      <Grid.Row>
                        <p style={{ color: 'red' }}>{item.amountToSendError}</p>
                      </Grid.Row>
                    </Grid.Row>
                  </Grid>
                ))}
                <Grid.Row>
                  <Modal open={openModal} closeIcon size="small">
                    <Icon name="window close" onClick={() => this.setState({ openModal: false })} />
                    {branchOfficesInformation.map(branchOfficeName => (
                      <Modal.Header>
                        {t('multi-shipping.branchOffice')}
                        {' '}
                        {branchOfficeName.name}
                      </Modal.Header>
                    ))}
                    <Modal.Content>
                      {branchOfficesInformation.map(information => (
                        <Modal.Description>
                          <Header as="h5">
                            {t('multi-shipping.address')}
                            {information.street}
                          </Header>
                          <Header as="h5">
                            {t('multi-shipping.needsAppointment')}
                            {information.appointment}
                          </Header>
                          <Header as="h5">
                            Código: Postal:
                            {information.postalCode}
                          </Header>
                          <Header as="h5">
                            {t('multi-shipping.parking')}
                            {information.parking}
                          </Header>
                          <Header as="h5">
                            {t('multi-shipping.contact')}
                            {information.mainContact}
                          </Header>
                          <Header as="h5">
                            {t('multi-shipping.phone')}
                            {information.phone}
                          </Header>
                          <br />
                        </Modal.Description>
                      ))}
                      <Modal.Description>
                        <Header as="h5">
                          {t('multi-shipping.typeManeuver')}
                          {typeManeuver}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.maeuverDescription')}
                          {maneuverDescription}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.comments')}
                          {typeComment}
                          ,
                          {comment}
                        </Header>
                      </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="black" onClick={() => this.setState({ openModal: false })}>Salir</Button>
                    </Modal.Actions>
                  </Modal>
                </Grid.Row>
              </Grid.Column>
              <Divider />
              <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={9}>
                <Grid.Row>
                  {item.quotation.map(i => (
                    <div>
                      <Responsive maxWidth={425}>
                        <br />
                      </Responsive>
                      <Input
                        labelPosition="right"
                        key={i.index}
                        id={i.index}
                        name={item.sku}
                        type="number"
                        className={needClassName === true ? 'input-price-agent' : ''}
                        compact
                        onChange={this.handleGetAgentPrice}
                        fluid
                        placeholder="Precio nuevo"
                        defaultValue={i.shippingPriceByAgent}
                      >
                        <Label basic>$</Label>
                        <input />
                        <Label basic>.00</Label>
                      </Input>
                      <Responsive maxWidth={425}>
                        <br />
                      </Responsive>
                      <Responsive as={Header} minWidth={768}>
                        <Header as="h4" color="blue">{t('Selección de paqueteria')}</Header>
                      </Responsive>
                      <Dropdown
                        fluid
                        className={needClassName === true ? 'drop-parcel' : ''}
                        id={i.index}
                        name={i.sku}
                        placeholder={t('selecciona fletera')}
                        defaultValue={i.parcelByLogistics}
                        options={arrayParcels}
                        onChange={this.handleParcelOnChange}
                        search
                        selection
                      />
                    </div>
                  ))}
                  <Responsive maxWidth={425}>
                    <br />
                  </Responsive>
                </Grid.Row>
              </Grid.Column>
              <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16} floated="right">
                {item.quotation.map(i => (
                  <Grid.Row className="quotation-Agent">
                    <Quotation
                      key={item.index}
                      skus={[{ sku: i.sku, quantity: i.quantity }]}
                      addressId={i.addressId}
                      tr={t}
                      response={body => this.getParcelPrice(body, i.index)}
                      parcelValue={i.parcel}
                      isSingle={false}
                      customerFromOrder={customerFromOrder}
                      defaultBranch={!i.addressId ? defaultBranch : ''}
                      getOrigins
                    />
                  </Grid.Row>
                ))}
                <Grid.Row>
                  <Modal open={openModalQuotation} closeIcon size="small">
                    <Icon name="window close" onClick={() => this.setState({ openModalQuotation: false })} />
                    {warehouseInformation.map(branchOffice => (
                      <Modal.Header>
                        {t('multi-shipping.branchOffice')}
                        {' '}
                        {branchOffice.branchOfficeName}
                      </Modal.Header>
                    ))}
                    <Modal.Content>
                      {warehouseInformation.map(branchOffice => (
                        <Grid.Row>
                          <Header as="h5">
                            La sucursal
                            {branchOffice.branchOfficeName}
                            {' '}
                            esta ubicada en:
                          </Header>
                          <p>
                            {t('multi-shipping.address')}
                            {' '}
                            {branchOffice.street}
                          </p>
                          <p>
                            {t('multi-shipping.neighborhood')}
                            {' '}
                            {branchOffice.neighborhood}
                          </p>
                          <p>
                            {t('multi-shipping.postalCode')}
                            {' '}
                            {branchOffice.postalCode}
                          </p>
                          <p>
                            {t('multi-shipping.city')}
                            {' '}
                            {branchOffice.city}
                          </p>
                          <p>
                            {t('multi-shipping.state')}
                            {' '}
                            {branchOffice.state}
                          </p>
                        </Grid.Row>
                      ))}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color="black" onClick={() => this.setState({ openModalQuotation: false })}>Salir</Button>
                    </Modal.Actions>
                  </Modal>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
            <Divider section />
          </Grid>
        ))}
      </Grid>
    );
  }
}

ShippingDetail.propTypes = {
  arrayBranchOfficeAddress: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arrayParcels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arrayProductsData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customerFromOrder: PropTypes.string.isRequired,
  defaultBranch: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  getArrayShipping: PropTypes.func.isRequired,
  getTotalCost: PropTypes.func.isRequired,
  tr: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(ShippingDetail);
