import { toast } from 'react-semantic-toasts';

const titles = {
  warning: 'Aviso', info: 'Notificación', error: 'Error', success: 'Éxito',
};
const icons = {
  warning: 'exclamation', info: 'info', error: 'times', success: 'check',
};

function Toast(
  description = 'Contenido de la notificación',
  type = 'warning',
  icon = '',
  title = '',
  time = 4000,
  animation = '',
  onClick = null,
  onClose = null,
) {
  return toast({
    type,
    icon: !icon ? icons[type] : icon,
    title: !title ? titles[type] : title,
    description,
    animation,
    time,
    onClick,
    onClose,
  });
}

export default Toast;
