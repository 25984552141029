const data = {};

data.productsInCart = [
  {
    id: '1',
    productName: 'IMAC 21.5\"/2.3DC/8GB/1TB-SPA', // eslint-disable-line
    sku: 'MMQA2E/A',
    quantity: 3,
    image: 'https://cdn.cnetcontent.com/58/88/5888b838-8a6a-48ee-8caf-1df511d2b152.jpg',
  },
  {
    id: '2',
    productName: 'IPAD PRO 10.5-IN WI-FI 256GB GRAY-CLA',
    sku: 'MPDY2CL/A',
    quantity: 5,
    image: 'https://cdn.cnetcontent.com/97/7f/977f79ca-3af2-4fa2-b010-49c71b45dff5.jpg',
  },
  {
    id: '3',
    productName: 'IMAC 27\"/3.8QC/8GB/2TB FD/RP580-SPA IMAC 27\"/3.8QC/8GB/2TB FD/RP580-SPA', // eslint-disable-line
    sku: 'MNED2E/A',
    quantity: 8,
    image: 'https://cdn.cnetcontent.com/7f/e6/7fe66b61-099f-4964-ae7a-298aaf8c4d8f.jpg',
  },
];
data.deliveryConfig = [
  {
    productName: 'IMAC 21.5\"/2.3DC/8GB/1TB-SPA', // eslint-disable-line
    sku: 'MMQA2E/A',
    quantity: 3,
    image: 'https://cdn.cnetcontent.com/58/88/5888b838-8a6a-48ee-8caf-1df511d2b152.jpg',
    deliveryConfig: [
      {
        addressId: '55BDDC0B-3054-4407-AE10-0B254F6F955F',
        quantity: 2,
        parcel: 1,
        UPSinsure: false,
      },
      {
        addressId: '55BDDC0B-3054-4407-AE10-0B254F6F955F',
        quantity: 1,
        parcel: 1,
        UPSinsure: false,
      },
    ],
  },

  {
    productName: 'IPAD PRO 10.5-IN WI-FI 256GB GRAY-CLA',
    sku: 'MPDY2CL/A',
    quantity: 5,
    image: 'https://cdn.cnetcontent.com/97/7f/977f79ca-3af2-4fa2-b010-49c71b45dff5.jpg',
    deliveryConfig: [
      {
        addressId: '55BDDC0B-3054-4407-AE10-0B254F6F955F',
        quantity: 3,
        parcel: 1,
        UPSinsure: false,
      },
      {
        addressId: '55BDDC0B-3054-4407-AE10-0B254F6F955F',
        quantity: 2,
        parcel: 1,
        UPSinsure: false,
      },
    ],
  },
  {
    productName: 'IMAC 27\"/3.8QC/8GB/2TB FD/RP580-SPA IMAC 27\"/3.8QC/8GB/2TB FD/RP580-SPA', // eslint-disable-line
    sku: 'MNED2E/A',
    quantity: 8,
    image: 'https://cdn.cnetcontent.com/7f/e6/7fe66b61-099f-4964-ae7a-298aaf8c4d8f.jpg',
    deliveryConfig: [
      {
        addressId: '55BDDC0B-3054-4407-AE10-0B254F6F955F',
        quantity: 4,
        parcel: 1,
        UPSinsure: false,
      },
      {
        addressId: '55BDDC0B-3054-4407-AE10-0B254F6F955F',
        quantity: 4,
        parcel: 1,
        UPSinsure: false,
      },
    ],
  },
];
data.nameBranchOffices = [
  { key: 1, text: 'Almacén de prueba 1', value: 'Almacén de prueba 1' },
  { id: 2, text: 'Almacén de prueba 2', value: 'Almacén de prueba 2' },
  { id: 3, text: 'Almacén de prueba 3', value: 'Almacén de prueba 3' },
];

data.branchOffices = [
  {
    id: '31173822-1df7-4045-8fe5-5855f93658ec',
    name: 'Almacén de prueba 2',
    street: 'San pedrito',
    number: '1234',
    unit: '32',
    postalCode: '20170',
    city: 'Aguascalientes',
    state: 'AGS',
    country: 'México',
    phone: '2334442444',
    parking: false,
    appointment: true,
    mainContact: 'Juan de la puerta',
    auxiliaryContact: 'Pedro el novio de juan',
    isValidated: false,
    endUser: {
      id: '0ed44701-008a-4025-b230-94ae393dc178',
      tradingName: 'FRAGUA S.A de C.v',
      name: 'Farmacias Guadalajara',
      rfc: 'FGTSFH273DM9E3D',
    },
  },
  {
    id: '31173822-1df7-4045-8fe5-5555f93658ec',
    name: 'Almacén de prueba 3',
    street: 'San pedrito',
    number: '1234',
    unit: '32',
    postalCode: '20170',
    city: 'Aguascalientes',
    state: 'AGS',
    country: 'México',
    phone: '2334442444',
    parking: false,
    appointment: true,
    mainContact: 'Juan de la puerta',
    auxiliaryContact: '',
    isValidated: false,
    endUser: {
      id: '0ed44701-008a-4025-b230-94ae393dc178',
      tradingName: 'FRAGUA S.A de C.v',
      name: 'Farmacias Guadalajara',
      rfc: 'FGTSFH273DM9E3D',
    },
  },
  {
    id: '31173822-1df7-4045-8Ge5-5855f93658ec',
    name: 'Almacén de prueba 4',
    street: '',
    number: '1234',
    unit: '32',
    postalCode: '20170',
    city: 'Aguascalientes',
    state: 'AGS',
    country: 'México',
    phone: '2334442444',
    parking: false,
    appointment: true,
    mainContact: 'Juan de la puerta',
    auxiliaryContact: 'Pedro el novio de juan',
    isValidated: false,
    endUser: {
      id: '0ed44701-008a-4025-b230-94ae393dc178',
      tradingName: 'FRAGUA S.A de C.v',
      name: 'Farmacias Guadalajara',
      rfc: 'FGTSFH273DM9E3D',
    },
  },
];

data.shippingPriceParcelCS = [
  {
    parcelid: '3343',
    parcelName: 'Mensajeria CS',
    price: '134',

  },
];
data.shippingPriceParcelUPS = [
  {
    parcelid: '3345',
    parcelName: 'UPS',
    price: '150',

  },
];
data.shippingPriceParcelUPSurgent = [
  {
    parcelid: '3347',
    parcelName: 'UPS urgent',
    price: '375',

  },
];
data.shippingPriceParcelExpress = [
  {
    parcelid: '3350',
    parcelName: 'Express',
    price: '150',

  },
];
data.branchOfficesByCs = [
  {
    id: 'GI01',
    branchOfficeName: 'CS GDL CompuSoluciones Guadalajara',
    stock: '30',
    street: 'Avenia Mariano Otero',
    number: '1105',
    neighborhood: 'Rinconada del Bosque',
    postalCode: ' 44530',
    city: 'Guadalajara',
    state: 'Jalisco',
  },
  {
    id: 'YI01',
    branchOfficeName: 'CS Mty CompuSoluciones Monterrey',
    stock: '30',
    street: 'Marklog Escobedo Petroquimica',
    number: '403',
    neighborhood: 'cruz cerámica Parque industrial Escobedo',
    postalCode: '66062',
    city: 'Escobedo',
    state: 'Nuevo León',
  },
  {
    id: 'MI01',
    branchOfficeName: 'CS CDMX CompuSoluciones CDMX',
    stock: '30',
    street: 'Pennsylvania',
    number: '4',
    neighborhood: 'Nápoles',
    postalCode: '03810',
    city: 'CDMX',
    state: 'CDMX',
  },
];

data.warehouseCs = [
  { key: 'GI01', text: 'CS GDL', value: 'GI01' },
  { key: 'YI01', text: 'CS Mty', value: 'YI01' },
  { key: 'MI01', text: 'CS CDMX', value: 'MI01' },
];

data.response = [
  {
    Origen: 'GI01',
    Cliente: 'G103063',
    DireccionID: '2',
    SeguroUPS: '1',
    Articulo: [
      {
        sku: 'Y2048AL',
        Cantidad: '3',
      },
      {
        sku: 'Y2048AL',
        Cantidad: '2',
      },
    ],
  },
];
export default data;
