import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import Toast from '../../common/components/toast';
import service from '../services';
import { TextField } from '../../common/components/materials';

function Segments(props) {
  const { t, privateInitiative: propPrivateIniciative, government: propGovernment } = props;

  const [state, setState] = useState({
    government: null,
    privateInitiative: null,
    categoryIdError: '',
    descriptionError: '',
  });
  const [details, setDetails] = useState({});

  useEffect(() => {
    const governmentValue = !propGovernment ? '' : propGovernment.description;
    const privateInitiativeValue = !propPrivateIniciative ? '' : propPrivateIniciative.description;
    setState(currentState => ({ ...currentState, privateInitiative: Number(privateInitiativeValue), government: Number(governmentValue) }));
    setDetails({ privateInitiative: propPrivateIniciative, government: propGovernment });
  }, [propPrivateIniciative, propGovernment]);

  function cleanErrors() {
    setState({
      ...state,
      governmentError: '',
      privateInitiativeError: '',
    });
  }

  function isValidBody(name, value) {
    const government = Number(state.government);
    const privateInitiative = Number(state.privateInitiative);
    const errors = {};
    switch (name) {
      case 'government':
        if (Number(government) < 0) errors.governmentError = t('clientPlan.errors.integer');
        else if ((value + privateInitiative) > 100) errors.governmentError = t('clientPlan.errors.percent');
        break;
      case 'privateInitiative':
        if (Number(privateInitiative) < 0) errors.privateInitiativeError = t('clientPlan.errors.integer');
        else if ((value + government) > 100) errors.privateInitiativeError = t('clientPlan.errors.percent');
        break;
      default:
        break;
    }
    if (Object.keys(errors).length > 0) {
      setState({ ...state, ...errors });
      return false;
    }
    return true;
  }

  const handleChange = ({ target }) => {
    const { value, id, name } = target;
    const nameOrId = id || name;
    setState({ ...state, [nameOrId]: `${Number(value)}`, [`${nameOrId}Error`]: '' });
  };

  const handleBlur = ({ target }) => {
    const { id, name } = target;
    const nameOrId = id || name;
    const value = Number(target.value);
    const body = {
      description: `${value}`,
      itemId: details[nameOrId].id,
    };
    if (!isValidBody(nameOrId, value)) return;
    service.updateBusinessPlanItem(body)
      .then(() => {
        cleanErrors();
        Toast(t('clientPlan.updated'), 'success');
      })
      .catch(() => {
        Toast(t('clientPlan.errors.update'), 'error');
      });
  };

  return (
    <Grid celled>
      <Grid.Row color="grey" className="no-padding-y">
        <Grid.Column
          largeScreen={6}
          computer={6}
          tablet={6}
          mobile={6}
          textAlign="center"
          verticalAlign="middle"
        >
          <Header size="small" inverted>{t('clientPlan.title.segment')}</Header>
        </Grid.Column>
        <Grid.Column
          className="no-padding"
          largeScreen={10}
          computer={10}
          tablet={10}
          mobile={10}
          verticalAlign="middle"
        >
          <Grid celled className="no-margin whiteBackground blackText">
            <Grid.Row className="no-padding-y">
              <Grid.Column
                largeScreen={10}
                computer={10}
                tablet={10}
                mobile={10}
                verticalAlign="middle"
                textAlign="right"
              >
                {t('clientPlan.goverment')}
              </Grid.Column>
              <Grid.Column
                largeScreen={6}
                computer={6}
                tablet={6}
                mobile={6}
                verticalAlign="middle"
              >
                <TextField
                  id="government"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  error={state.governmentError || ''}
                  value={`${state.government}` || '0'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="no-padding-y">
              <Grid.Column
                largeScreen={10}
                computer={10}
                tablet={10}
                mobile={10}
                verticalAlign="middle"
                textAlign="right"
              >
                {t('clientPlan.private')}
              </Grid.Column>
              <Grid.Column
                largeScreen={6}
                computer={6}
                tablet={6}
                mobile={6}
                verticalAlign="middle"
              >
                <TextField
                  id="privateInitiative"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                  onBlur={handleBlur}
                  error={state.privateInitiativeError || ''}
                  value={`${state.privateInitiative}` || '0'}
                  onChange={handleChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Segments.propTypes = {
  t: PropTypes.func.isRequired,
  government: PropTypes.shape({
    description: PropTypes.string,
  }),
  privateInitiative: PropTypes.shape({
    description: PropTypes.string,
  }),
};

Segments.defaultProps = {
  government: {
    description: '',
  },
  privateInitiative: {
    description: '',
  },
};

export default translate('common', { wait: true })(Segments);
