import { request } from '../../../common/fetch-utils';
import apiRoutes from '../../../apiRoutes';

const service = {};

service.getBoxesConfiguration = () => (
  request({
    route: apiRoutes.getBoxConfigurations(),
  })
);

service.getExtendedAreas = postalCode => (
  request({
    route: apiRoutes.getExtendedAreas(postalCode),
  })
);

service.addExtendedArea = body => (
  request({
    method: 'POST',
    route: apiRoutes.addExtendedArea(),
    body,
  })
);

service.updateBoxConfiguration = (id, body) => (
  request({
    method: 'PUT',
    route: apiRoutes.updateBoxConfigurations(id),
    body,
  })
);

export default service;
