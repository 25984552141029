import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Progress } from 'semantic-ui-react';

const DeliveryTracking = props => {
  const { statusDelivery, tr } = props;
  let percent = 0;
  if (statusDelivery === 'Recibido') percent = 100;
  if (statusDelivery === 'En camino') percent = 50;
  if (statusDelivery === 'Por enviar') percent = 0;
  return (
    <Grid.Row>
      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
        <Progress percent={percent} color="blue" />
      </Grid.Column>
      <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">{tr('orderDetail.toSend')}</Grid.Column>
      <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5} textAlign="center">{tr('orderDetail.onWay')}</Grid.Column>
      <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5} textAlign="right">{tr('orderDetail.received')}</Grid.Column>
    </Grid.Row>
  );
};

DeliveryTracking.propTypes = {
  statusDelivery: PropTypes.string.isRequired,
  tr: PropTypes.func.isRequired,
};

export default DeliveryTracking;
