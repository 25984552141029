import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Header } from 'semantic-ui-react';

function ActionDetail({ action }) {
  return !action
    ? <Header>Sin datos</Header>
    : (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            {action.description}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
}

ActionDetail.propTypes = {
  action: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
};

export default ActionDetail;
