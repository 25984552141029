import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Header, Icon } from 'semantic-ui-react';

function getIcon(type) {
  let iconName = '';
  switch (type) {
    case 'jpg': case 'png': case 'jpeg':
      iconName = 'file image outline';
      break;
    case 'pdf':
      iconName = 'file pdf outline';
      break;
    case 'docx': case 'doc':
      iconName = 'file word outline';
      break;
    default:
      iconName = 'file outline';
      break;
  }
  return iconName;
}

const downloadFile = (fileName, type, urlFile) => {
  // eslint-disable-next-line no-undef
  fetch(urlFile)
    .then(response => {
      response.blob().then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.${type}`;
        a.click();
      });
    });
};

function FileElement(fileName, type, key, url) {
  return (
    <Grid.Column
      as="a"
      key={key}
      largeScreen={5}
      computer={5}
      tablet={5}
      mobile={5}
      verticalAlign="top"
      textAlign="center"
    >
      <Icon size="huge" name={getIcon(type)} onClick={() => downloadFile(fileName, type, url)} />
      <Header as="h5">{fileName}</Header>
    </Grid.Column>
  );
}

function Attachments(props) {
  const { t, files } = props;
  return (
    <Grid>
      <Grid.Row>
        {
          (!files || files.length === 0)
            ? (
              <Grid.Column
                largeScreen={16}
                computer={16}
                tablet={16}
                mobile={16}
              >
                <Header as="h5">{t('shoppingCart.noAttachments')}</Header>
              </Grid.Column>
            )
            : files.map(file => (FileElement(file.name, file.type, `${file.name}-${file.type}`, file.url)))
        }
      </Grid.Row>
    </Grid>

  );
}

Attachments.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

Attachments.defaultProps = {
  files: [],
};

export default translate('common', { wait: true })(Attachments);
