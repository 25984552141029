import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const crossSelling = {};

crossSelling.getAllMakers = () => (
  request({
    route: apiRoutes.getAllMakers(),
    method: 'GET',
  })
);

crossSelling.getAllProducts = () => (
  request({
    route: apiRoutes.getAllListProducts(),
    method: 'GET',
  })
);

crossSelling.getCrossSelling = (customerId, year) => (
  request({
    route: apiRoutes.getCrossSellingByCustomerId(customerId, year),
    method: 'GET',
  })
);

crossSelling.addCrossSellingHeader = body => (
  request({
    route: apiRoutes.addCrossSellingHeader(),
    method: 'POST',
    body,
  })
);

crossSelling.addCrossSellingItems = body => (
  request({
    route: apiRoutes.addCrossSellingItem(),
    method: 'POST',
    body,
  })
);

crossSelling.updateCrossSellingHeader = body => (
  request({
    route: apiRoutes.updateCrossSellingHeader(),
    method: 'PUT',
    body,
  })
);

crossSelling.deleteCrossSellingHeader = headerId => (
  request({
    route: apiRoutes.deleteCrossSellingHeader(headerId),
    method: 'DELETE',
  })
);

crossSelling.deleteCrossSellingItems = itemId => (
  request({
    route: apiRoutes.deleteCrossSellingItem(itemId),
    method: 'DELETE',
  })
);

export default crossSelling;
