import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const iquoteRoutes = {};
iquoteRoutes.generateTokenIquote = () => (
  request({
    route: apiRoutes.generateTokenIquote(),
    method: 'POST',
  })
);

iquoteRoutes.generateTestIquote = () => (
  request({
    route: 'https://live.hpiquote.net/aspx/gatekeeper.aspx?token=INRLFPYQXAKBFYKBHLFO',
    method: 'POST',
    headers: { 'Content-type': 'multipart/form-data' },
    formData:
    {
      host: 'DCOMX44530',
      token: '57f837edd31a796d71622db3a50d684f',
      mfr: 'HPE',
      uEmail: 'yvelazqu@compusoluciones.com',
      uName: 'Kimberly Velazquez',
      uTel: '7000 7777',
      CName: 'CompuSoluciones',
      cPCode: '44110',
      cAccountNum: 'G000000',
      ref: 'https://www.siclik.mx/',
      basketUrl: 'http://localhost:8997/iquote/shopping-cart/items',
      SessionID: '9ec8b1ac-9313-4b61-9eca-5d9098c1e210',
    },
    json: true,
  })
);

iquoteRoutes.generateTokenIquoteQuote = body => (
  request({
    route: apiRoutes.generateTokenIquote(),
    method: 'POST',
    body,
  })
);

export default iquoteRoutes;
