import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Icon, Header, Responsive,
} from 'semantic-ui-react';
import routes from '../../route-names';

class HeaderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  goNext = () => {
    const { history } = this.props;
    history.push(routes.dashboard.route);
  }

  render() {
    const { t, steps, history } = this.props;
    let backStep = '';
    if (steps.length > 1) {
      const back = (steps.filter(step => step.key < 5));
      backStep = back[back.length - 1].href;
    }
    const currentKey = steps.find(step => step.key === 5);
    return (
      <Grid container>
        <Grid.Row columns={3} centered>
          { currentKey && currentKey.key === 5 && currentKey.completed === false
            && (
            <Grid.Column largeScreen={1} computer={1} tablet={2} mobile={16}>
              <Header textAlign="center"><Icon onClick={() => history.push(backStep)} name="arrow circle left" size="huge" /></Header>
              <Responsive as={Header} minWidth={768}>
                <Header textAlign="center" as="h4">{t('back')}</Header>
              </Responsive>
            </Grid.Column>
            )}
          <Grid.Column largeScreen={14} computer={14} tablet={12} mobile={15}>
            <Header color="blue" textAlign="center" as="h2">{t('branchOffice.title')}</Header>
          </Grid.Column>
          { currentKey && currentKey.key === 5 && currentKey.completed === false
            && (
            <Grid.Column largeScreen={1} computer={1} tablet={2} mobile={16}>
              <Header textAlign="center"><Icon onClick={this.goNext} name="arrow circle right" size="huge" /></Header>
              <Responsive as={Header} minWidth={768}>
                <Header as="h4" textAlign="center">{t('next')}</Header>
              </Responsive>
            </Grid.Column>
            )}
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={14} computer={14} tablet={12} mobile={16}>
            <Header as="h4">
              {t('branchOffice.info')}
              <Icon color="green" name="check" />
              {t('branchOffice.info2')}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

HeaderComponent.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({})),
};

HeaderComponent.defaultProps = {
  steps: [],
};

export default translate('common', { wait: true })(HeaderComponent);
