import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const appleDepService = {};

appleDepService.getBillingDetailsByMovId = movId => (
  request({
    route: apiRoutes.getBillingDetailsByMovId(movId),
    method: 'GET',
  })
);
appleDepService.getFinalUser = (customerFromParams = undefined) => (
  request({
    route: apiRoutes.getFinalUsers(customerFromParams),
    method: 'GET',
  })
);
appleDepService.addDepRequest = body => (
  request({
    route: apiRoutes.addDepRequest(),
    method: 'POST',
    body,
  })
);
appleDepService.getSeriesStatus = billing => (
  request({
    route: apiRoutes.getSeriesStatus(billing),
    method: 'GET',
  })
);
appleDepService.getUser = userId => (
  request({
    route: apiRoutes.getUserInformation(userId),
    method: 'GET',
  })
);
export default appleDepService;
