/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import {
  Header, Grid, Dropdown, Dimmer, Loader, Button,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import { TextField } from '../common/components/materials';
import SiclikTable from '../common/components/table';
import * as session from '../common/sessions';
import Months from '../common/components/months';
import Years from '../common/components/years';
import routes from '../route-names';
import Toast from '../common/components/toast';
import orderService from './services';
import { currencyFormat, formatDecimalNumber } from '../common/utils';

class OrdersMain extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      search: '',
      year: '',
      month: '',
      searchError: '',
      messageTable: '',
      ordersArray: [],
      ordersArrayFiltered: [],
      ordersHeaders: [
        { key: 'uuid', isKey: true },
        { key: 'id', name: t('orderDetail.orderNumber') },
        { key: 'intelisisId', name: t('orderDetail.mov') },
        { key: 'humaneDate', name: t('orders.appointment') },
        { key: 'amount', name: props.t('orders.amount') },
        { key: 'endUser', name: t('orders.endUser') },
        { key: 'agent', name: t('orders.agent') },
        { key: 'status', name: t('orders.status') },
        {
          key: 'open',
          name: 'Abrir',
          format: (cell, row) => (row.id ? <Button color="blue" icon="file outline" onClick={() => props.history.push(`${routes.orderDetails.route.replace(':orderId?', '')}${row.uuid}`)} /> : null), // eslint-dsable-line
        },
      ],
      sortOptionsArray: [
        { key: 1, text: 'Id Pedido (ascendente)', value: 1 },
        { key: 2, text: 'Id Pedido (descendente)', value: 2 },
        { key: 3, text: 'Fecha de pedido (ascendente)', value: 3 },
        { key: 4, text: 'Fecha de pedido (descendente)', value: 4 },
        { key: 5, text: 'Importe (ascendente)', value: 5 },
        { key: 6, text: 'Importe (descendente)', value: 6 },
        { key: 7, text: 'Usuario final A-Z', value: 7 },
        { key: 8, text: 'Usuario final Z-A', value: 8 },
        { key: 9, text: 'Agente A-Z', value: 9 },
        { key: 10, text: 'Agente Z-A', value: 10 },
        { key: 11, text: 'Estatus A-Z', value: 11 },
        { key: 12, text: 'Estatus Z-A', value: 12 },
      ],
      statusArray: [],
      arrayUsers: [],
      column: 4,
      status: '',
      loading: true,
      visibleUsers: false,
      userId: '',
    };
  }

  componentDidMount = () => {
    this.handleAccessPermission();
  }

  handleAccessPermission = () => {
    const { access, userId } = session.get();
    const hasAccess = securityService.validate(access, [
      securityService.getAccess.general,
      securityService.getAccess.compra,
      securityService.getAccess.cotizacion,
      securityService.getAccess.finanzas,
      securityService.getAccess.envios,
      securityService.getAccess.pedidos,
      securityService.getAccess.usuarios,
    ]);
    if (hasAccess) {
      this.handleGetUsers();
      this.setState({ visibleUsers: true });
    }
    this.setState({ userId });
    this.handleGetOrders(userId);
  }

  handleGetOrders = userId => {
    const { t } = this.props;
    const statusArray = [];
    this.setState({ loading: true });
    orderService.getOrdersByUser(userId)
      .then(response => {
        const formatedOrder = response.orders.map(currentOrder => {
          const order = { ...currentOrder };
          const { parcelData } = currentOrder;
          if (parcelData !== null) {
            let parcelGuide = 'Dentro del detalle';
            if (parcelData.guides.length === 1) parcelGuide = parcelData.guides[0].guide;
            order.parcelGuide = parcelGuide;
          } else order.parcelGuide = 'Sin guía';
          order.humaneDate = order.date
            ? moment(order.date).locale('es').format('D [de] MMMM [de] YYYY')
            : 'Sin fecha';
          order.dateFormated = order.date ? moment(order.date).format('DD/MM/YYYY HH:mm:ss') : null;
          order.amountNumber = order.amount;
          if (order.currency && order.currency === 'Dolar MS') order.currencyFormat = 'USD';
          else if (order.currency && (order.currency === 'Dolares' || order.currency === 'Dólares')) order.currencyFormat = 'USD';
          else order.currencyFormat = 'MXN';
          order.total = order.amount;
          if (order.amount && order.currency) {
            order.amount = `${currencyFormat(order.amount, order.currencyFormat)}`;
          } else if (order.amount) {
            order.amount = `${formatDecimalNumber(order.amount)}`;
          } else { order.amount = ''; }
          if (order.status && order.status.length > 0) statusArray.push(order.status);
          return order;
        });
        this.setState({ ordersArray: formatedOrder, ordersArrayFiltered: formatedOrder, loading: false });
        this.handleGetStatus(statusArray);
        this.handleSortedByDate('asc');
      }).catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code !== 1000) Toast(messageError, 'error');
        this.setState({ loading: false, ordersArray: [], ordersArrayFiltered: [] });
      });
  }

  handleGetStatus = statusItems => {
    const status = statusItems.filter((valor, indiceActual, arreglo) => arreglo.indexOf(valor) === indiceActual);
    const statusArray = status.map(order => { const formatedStatus = { key: order, text: order, value: order }; return formatedStatus; });
    this.setState({ statusArray });
  }

  handleGetUsers = () => {
    const { t } = this.props;
    const arrayUsers = [];
    const { customerId } = session.get();
    orderService.getUsers(customerId)
      .then(response => {
        response.users.map(user => {
          if (/* user.confirmedAccount === 1 && */ user.name && user.name.length > 1) {
            arrayUsers.push({ key: user.id, text: `${user.name} ${user.surname}`, value: user.id });
          }
          return user;
        });
        this.setState({ arrayUsers });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  validations = event => {
    const { t } = this.props;
    const maxLength = 40;
    let mistakes = false;
    const { value: search } = event.target;
    if ((search.trim()).length > maxLength) {
      this.setState({ search, searchError: t('branchOffice.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) this.setState({ search, searchError: '' }); this.filterGeneral(search, 1);
  }

  handleYearChange = year => {
    this.setState({ year });
    this.filterGeneral(year, 2);
  }

  handleMonthChange = month => {
    this.setState({ month });
    this.filterGeneral(month, 3);
  }

  handleStatusChange = (e, { value }) => {
    this.setState({ status: value });
    this.filterGeneral(value, 4);
  }

  filterGeneral = (value = '', typeFilter) => {
    const { ordersArray } = this.state;
    let ordersArrayFiltered = JSON.parse(JSON.stringify(ordersArray));
    let {
      search, year, month, status,
    } = this.state;
    const filter = String(value).toLowerCase();
    if (typeFilter === 1) search = filter;
    if (typeFilter === 2) year = filter;
    if (typeFilter === 3) month = filter;
    if (typeFilter === 4) status = filter;
    if (search) ordersArrayFiltered = this.filterForSearch(search, ordersArrayFiltered);
    if (year) ordersArrayFiltered = this.filterForYear(year, ordersArrayFiltered);
    if (month) ordersArrayFiltered = this.filterForMonth(month, ordersArrayFiltered);
    if (status) ordersArrayFiltered = this.filterForStatus(status, ordersArrayFiltered);
    this.setState({ ordersArrayFiltered });
  }

  filterForSearch = (filter, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => (filterInfo.total && String(filterInfo.total).indexOf(filter) >= 0)
    || (filterInfo.quotation && filterInfo.quotation.toLowerCase().indexOf(filter) >= 0)
    || (filterInfo.uuid && filterInfo.uuid.toLowerCase().indexOf(filter) >= 0)
    || (filterInfo.intelisisId && filterInfo.intelisisId.indexOf(filter) >= 0)
    || (filterInfo.id && filterInfo.id.indexOf(filter) >= 0)
    || (filterInfo.endUser && filterInfo.endUser.toLowerCase().indexOf(filter) >= 0)
    || (filterInfo.agent && filterInfo.agent.toLowerCase().indexOf(filter) >= 0));
    return foundData;
  }

  filterForYear = (year, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => {
      if (filterInfo.dateFormated && filterInfo.dateFormated.length >= 10
        && Number(filterInfo.dateFormated.substring(6, 10))
        === Number(year)) {
        return true;
      }
      return false;
    });
    return foundData;
  }

  filterForMonth = (month, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => filterInfo.dateFormated && filterInfo.dateFormated.length > 9 && Number(filterInfo.dateFormated.substring(3, 5)) === Number(month));
    return foundData;
  }

  filterForStatus = (status, ordersToFilter) => {
    let foundData = [];
    foundData = ordersToFilter.filter(filterInfo => filterInfo.status === status);
    return foundData;
  }

  handleChangeUser = (e, { value }) => {
    this.setState({ userId: value });
    this.handleGetOrders(value);
  }

  handleSortChange = (e, { value }) => {
    this.setState({ column: value });
    switch (value) {
      case 1: this.handleSortedByIntelisisId('desc'); break;
      case 2: this.handleSortedByIntelisisId('asc'); break;
      case 3: this.handleSortedByDate('desc'); break;
      case 4: this.handleSortedByDate('asc'); break;
      case 5: this.handleSortedByAmount('desc'); break;
      case 6: this.handleSortedByAmount('asc'); break;
      case 7: this.handleSortedByEndUser('desc'); break;
      case 8: this.handleSortedByEndUser('asc'); break;
      case 9: this.handleSortedByAgent('desc'); break;
      case 10: this.handleSortedByAgent('asc'); break;
      case 11: this.handleSortedByStatus('desc'); break;
      case 12: this.handleSortedByStatus('asc'); break;
      default: this.handleSortedByIntelisisId('asc'); break;
    }
  }

  handleSortedByIntelisisId = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.intelisisId.toLowerCase();
        const y = b.intelisisId.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );

    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByDate = direction => {
    const { ordersArrayFiltered } = this.state;
    let array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = moment(b.dateFormated, 'DD-MM-YYYY');
        const y = moment(a.dateFormated, 'DD-MM-YYYY');
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      },
    );
    if (direction === 'desc') {
      array = array.reverse();
    }
    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByAmount = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = Number(a.amountNumber);
        const y = Number(b.amountNumber);
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );

    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByEndUser = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.endUser.toLowerCase();
        const y = b.endUser.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );
    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByAgent = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.agent.toLowerCase();
        const y = b.agent.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );
    this.setState({ ordersArrayFiltered: array });
  }

  handleSortedByStatus = direction => {
    const { ordersArrayFiltered } = this.state;
    const array = ordersArrayFiltered.sort(
      (a, b) => {
        const x = a.status.toLowerCase();
        const y = b.status.toLowerCase();
        let sorted = [];
        if (direction === 'desc') {
          sorted = ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          sorted = ((x > y) ? -1 : ((x < y) ? 1 : 0));
        }
        return sorted;
      },
    );
    this.setState({ ordersArrayFiltered: array });
  }

  render() {
    const { t } = this.props;
    const {
      userId, visibleUsers, search, loading, loadingStatus, searchError, messageTable, ordersArrayFiltered, ordersHeaders,
      statusArray, status, arrayUsers, column, sortOptionsArray,
    } = this.state;
    return (
      <Grid container style={{ paddingTop: '1rem' }}>
        <Grid.Row>
          <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={15}>
            <Header color="blue" as="h1">{t('orders.orders')}</Header>
          </Grid.Column>
          {visibleUsers && arrayUsers.length > 0 ? (
            <Grid.Column>
              <Dropdown
                options={arrayUsers}
                placeholder={t('orders.selectOrders')}
                onChange={this.handleChangeUser}
                value={userId}
                selection
                search
              />
            </Grid.Column>
          ) : ''}
        </Grid.Row>
        {loading ? (
          <Grid.Row>
            <Dimmer active inverted><Loader active size="big" /></Dimmer>
          </Grid.Row>
        ) : (
          <Grid.Row columns={4}>
            <Grid.Column largeScreen={6} computer={6} tablet={4} mobile={15}>
              <TextField
                fullWidth
                maxLength={40}
                value={search || ''}
                error={searchError || ''}
                label={t('orders.search')}
                onChange={this.validations}
              />
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={11}>
              <Months change={this.handleMonthChange} tr={t} />
            </Grid.Column>
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={5}>
              <Years min={2017} max={2030} change={this.handleYearChange} tr={t} />
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={2} mobile={15}>
              <Dropdown
                clearable
                selection
                value={status}
                options={statusArray}
                placeholder={t('orders.status')}
                onChange={this.handleStatusChange}
                loading={loadingStatus}
              />
            </Grid.Column>
            <Grid.Column largeScreen={12} computer={12} tablet={11} mobile={15} />
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              Ordenar por:
              <Dropdown
                selection
                value={column}
                options={sortOptionsArray}
                placeholder={t('Ordenar por')}
                onChange={this.handleSortChange}
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column style={{ overflowX: 'auto' }}>
              <SiclikTable
                data={ordersArrayFiltered}
                headers={ordersHeaders}
                pageSize={10}
                noDataMessage={messageTable}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(OrdersMain);
