import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Button, Modal, Grid, Dimmer, Loader, Confirm,
} from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { translate } from 'react-i18next';

import Toast from '../../../common/components/toast';
import request from '../../services';
import SearchBar from './SearchBar';
import Table from '../../../common/components/table';
import { fuse } from '../../../common/utils';
import ModalAddAccess from './ModalAddAccess';

function ModalAccess(props) {
  const {
    actions, user, t, env,
  } = props;
  const { open, handleModalClose } = actions;
  const [filteredAccess, setFilteredAccess] = useState([]);
  const [access, setAccess] = useState([]);
  const [filter, setFilter] = useState('');
  const [loaderTable, setLoaderTable] = useState(false);
  const [activeModalAccess, setModalAccessActive] = useState(false);
  const [activeDeleteAccessModal, setActiveDeleteAccessModal] = useState(false);
  const [selectedAccess, setSelectedAccess] = useState(false);

  function messageCopy() {
    Toast(t('user.accessAccount.messageCopy'), 'info');
  }

  function editAction(cell, accessData) {
    return (
      <Grid>
        <CopyToClipboard
          text={accessData.secretKey}
          onCopy={() => messageCopy()}
        >
          <Button
            color="blue"
            icon="key"
          />
        </CopyToClipboard>
        <Button
          color="blue"
          icon="trash alternate"
          onClick={() => {
            setSelectedAccess(accessData);
            setActiveDeleteAccessModal(true);
          }}
        />
      </Grid>
    );
  }

  const headers = [
    { key: 'secretKey', isKey: true },
    { key: 'customerKey', name: t('user.accessAccount.curtomerKeyHeader') },
    { key: 'description', name: t('user.accessAccount.descriptionHeader') },
    { key: 'createdAt', name: t('user.accessAccount.dateCreated') },
    { key: 'actions', format: editAction },
  ];

  const handleOnClose = () => {
    handleModalClose();
  };

  const handleNewAccess = () => {
    setModalAccessActive(true);
  };

  const handleAccessSearch = keyword => {
    setFilter(keyword);
    if (keyword === '') {
      setFilteredAccess(access);
    } else {
      const items = fuse(access, ['customerKey', 'description', 'createdAt']);
      const result = items.search(keyword).map(item => item.item);
      setFilteredAccess(result);
    }
  };

  async function handleSave() {
    if (user) {
      try {
        const accessData = await request.getAccessByAccount(user.customerId, env);
        setAccess(accessData.serviceAccountAccess);
        setFilteredAccess(accessData.serviceAccountAccess);
        setLoaderTable(true);
      } catch (error) {
        setAccess([]);
        setFilteredAccess([]);
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(error.message);
        Toast(messageError, 'error');
        setLoaderTable(true);
      }
    }
  }

  function handleDeleteConfirmed(accessData) {
    request.deleteAccess(
      accessData.customerKey,
      user.customerId,
      env,
    )
      .then(() => {
        access.map((accessInfo, index) => {
          if (accessInfo.customerKey === accessData.customerKey) {
            access.splice(index, 1);
          }
          return user;
        });
        Toast(t('user.accessAccount.accessDeleted'), 'success');
        setActiveDeleteAccessModal(false);
      })
      .catch(error => Toast(t(`error-codes.${error.code}`, 'error')));
  }

  useEffect(() => {
    async function fetchAccess() {
      if (user) {
        try {
          const accessData = await request.getAccessByAccount(user.customerId, env);
          setAccess(accessData.serviceAccountAccess);
          setFilteredAccess(accessData.serviceAccountAccess);
          setLoaderTable(true);
        } catch (error) {
          setAccess([]);
          setFilteredAccess([]);
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(error.message);
          Toast(messageError, 'info');
          setLoaderTable(true);
        }
      }
    }
    fetchAccess();
  }, [t, user, env]);

  return (
    <Modal open={open} closeOnEscape={open}>
      <Modal.Header>
        {' '}
        {t('user.accessAccount.title')}
        {' '}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column largeScreen={12} computer={12} tablet={12} mobile={16}>
              <SearchBar
                search={handleAccessSearch}
                filter={filter}
                label={t('user.accessAccount.filter')}
              />
            </Grid.Column>
            <Grid.Column computer={4} tablet={4} mobile={16} textAlign="center">
              <Button color="blue" fluid icon="plus" content={t('add')} onClick={handleNewAccess} />
            </Grid.Column>
          </Grid.Row>
          {loaderTable ? (
            <Grid.Row style={{ overflowX: 'auto' }}>
              <Table
                selectable
                data={filteredAccess}
                headers={headers}
                pageSize={5}
              />
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="medium" /></Dimmer>
            </Grid.Row>
          )}
        </Grid>
        {activeModalAccess ? (
          <ModalAddAccess
            actions={{
              open: activeModalAccess,
              handleModalClose: () => setModalAccessActive(false),
              handleModalSave: handleSave,
            }}
            customerId={user.customerId}
            env={env}
          />
        ) : (
          ''
        )}
        <Confirm
          open={activeDeleteAccessModal}
          header={t('user.accessAccount.descriptionDeleted')}
          content={selectedAccess.description}
          cancelButton={t('cancel')}
          confirmButton={t('yes')}
          onCancel={() => setActiveDeleteAccessModal(false)}
          onConfirm={() => handleDeleteConfirmed(selectedAccess)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleOnClose}>
          {' '}
          {t('cancel')}
          {' '}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalAccess.propTypes = {
  actions: PropTypes.shape({
    handleModalClose: PropTypes.func,
    handleModalSave: PropTypes.func,
    open: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    customerId: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  env: PropTypes.string,
};

ModalAccess.defaultProps = {
  env: '',
};

export default translate('common', { wait: true })(ModalAccess);
