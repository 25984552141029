import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Header, Grid, Button, Checkbox, Modal, Form, TextArea, Confirm, Responsive, Radio, Input,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import validator from 'validator';
import errorCodes from '../constants';
import services from '../services';
import Toast from '../../common/components/toast';
import { escapeAccents } from '../../common/utils';

import Payment from './payment';
import SingleDelivery from './single-delivery';
import Attachments from './attachments';
import OrderItems from './order-items';
import OrderDetails from './order-details';
import ItemDivider from './item-divider';
import MultiBrand from '../../multi-brand-data';
import UploadPanel from '../../upload-s3/components/upload-modal-files';
import Summary from './summary';

const TextAreaControlled = props => {
  const { t, comment, handleInputFieldChange } = props;

  const [localComment, setComment] = useState('');

  useEffect(() => {
    setComment(comment);
  }, [comment]);

  const handleBlur = () => {
    const validationString = escapeAccents(validator.blacklist(localComment, ' .,'));
    if (
      comment.trim().length > 0 && !validator.isAlphanumeric(validationString, 'es-ES')
    ) {
      Toast(t('shoppingCart.errors.comment'), 'error');
    } else handleInputFieldChange(localComment, 'comment', true);
  };

  return (
    <Grid.Row>
      <Grid.Column onBlur={handleBlur} largeScreen={6} computer={6} tablet={7} mobile={16}>
        <Form>
          <Header size="small">{t('shoppingCart.comment')}</Header>
          <TextArea
            onChange={(e, { value }) => setComment(value)}
            value={localComment}
            placeholder={t('shoppingCart.comment')}
            rows={3}
          />
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

TextAreaControlled.propTypes = {
  comment: PropTypes.string,
  handleInputFieldChange: PropTypes.func,
  t: PropTypes.func,
};

TextAreaControlled.defaultProps = {
  comment: '',
  handleInputFieldChange: () => { },
  t: () => { },
};

const multishippingMessage = (noDeliveryProducts, hasAgentPrice) => {
  if (noDeliveryProducts) return 'shoppingCart.noConfigNeeded';
  if (hasAgentPrice) return 'shoppingCart.hasAgentPrice';
  return 'shoppingCart.configureShipping';
};

const TextAreaControlledCollector = props => {
  const { t, collectorName, handleInputFieldChange } = props;

  const [collectorNameOrder, setCollectorNameOrder] = useState('');

  useEffect(() => {
    setCollectorNameOrder(collectorName);
  }, [collectorName]);

  const handleBlurCollector = () => handleInputFieldChange(collectorNameOrder, 'collectorName');

  const onkeyPressInput = event => {
    const keyEvent = event.key;
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s-]+$/;
    if (!regex.test(keyEvent)) {
      event.preventDefault();
    }
  };

  return (
    <Grid.Row>
      <Grid.Column onBlur={handleBlurCollector} largeScreen={16} computer={18} tablet={16} mobile={16}>
        <Form>
          <p
            style={{
              display: 'inline',
              fontWeight: 'bold',
              fontFamily: 'Exo, sans-serif',
            }}
          >
            {t('shoppingCart.collectorName')}
          </p>
          <Input
            className="input-collector"
            type="text"
            required
            maxLength={40}
            onChange={(e, { value }) => setCollectorNameOrder(value)}
            value={collectorNameOrder}
            onKeyPress={onkeyPressInput}
          />
        </Form>
      </Grid.Column>
    </Grid.Row>
  );
};

TextAreaControlledCollector.propTypes = {
  collectorName: PropTypes.string,
  handleInputFieldChange: PropTypes.func,
  t: PropTypes.func,
};

TextAreaControlledCollector.defaultProps = {
  collectorName: '',
  handleInputFieldChange: () => {},
  t: () => {},
};

function UserOrder(props) {
  const {
    t, endUserId, errorCode, userName, credit, endUsers, attachments, openMultiBrand, existingBrands, openAttach,
    handleGetNewAddress, orderId, chosenDelivery, handleDeliveryChange, currentBranchOfficeIndex, handleBranchOfficeChange,
    handleAddressChange, handlePaymentChange, deleteShoppingCartProduct, payment, currency, cfdi, CFDIArray, items,
    exchangeRate, customerId, buyToMe, creditAvailable, courierServiceId, hasAgentPrice, openConfirm, fileSelected,
    noDeliveryProducts, handleFormId, handleModalState, total, handleShippingChange, subtotal, shippingCost, iva,
    discount, ActionButtons, needForm, handleGoToPayment, saveAsQuote, isGoToPayment, allBranchOffices, addressId,
    hpeIquoteId, handleInputFieldChange, handleBoolField, dispatch, handleAttachedDelete,
    handleAttachedAdd, handleCurrencyChange, comment, actionLoader, steppedPrices, history, handleGetSavedForm,
    warehouseSelected, handleWarehouseSelectedChange, doNotShowWarning, handleGetEndUsers, purchaseOrder, collectorName,
    // pickWarehouse,
  } = props;
  let { branchOffices } = props;
  let endUserBranchOffices = [];
  let generalBranchOfficesArray = [];
  let otherEndUserBranchOfficesArray = [];
  let endUsersData = endUsers;
  let hasAppleCare = false;

  if (endUserId && branchOffices) {
    generalBranchOfficesArray = branchOffices.filter(branchOffice => !branchOffice.endUser);
    const branchOfficesArray = branchOffices.filter(branchOffice => branchOffice.endUser);
    otherEndUserBranchOfficesArray = branchOfficesArray.filter(branchOffice => branchOffice.endUser.id !== endUserId);
    endUserBranchOffices = branchOfficesArray.filter(branchOffice => branchOffice.endUser.id === endUserId);
    branchOffices = [...endUserBranchOffices, ...otherEndUserBranchOfficesArray, ...generalBranchOfficesArray];
  }
  const itemsMicrosoft = items.filter(item => item.maker === 'Microsoft');
  const itemsVeeam = items.filter(item => item.maker === 'VEEAM');
  const itemsTrellix = items.filter(item => item.maker === 'Trellix');
  const itemsRedHat = items.filter(item => item.maker === 'Red Hat');
  const itemsVmware = items.filter(item => item.maker === 'VMware' || item.maker === 'VMWARE');

  const itemsCarepacks = items.filter(item => (item.branch.includes('CAREPACK') || item.branch === 'HPE-CAREPACK'
    || item.branch === 'ARUBA-SERVICES' || item.branch === 'ARUBA-CLOUD'));

  const itemsAppleCare = items.filter(item => item.branch === 'APPLE-APPLECARE');
  if (itemsAppleCare && itemsAppleCare.length > 0) hasAppleCare = true;
  if (itemsAppleCare && itemsAppleCare.length > 0) endUsersData = endUsersData.filter(filter => filter.text.toLowerCase() !== 'stock');
  // autodesk sintaxis de maker debe coincidir
  const itemsAutodesk = items.filter(item => item.branch === 'AUTODESK' || item.branch === 'MFG-RENOVACION'
    || item.branch === 'MFG-LICENCIA' || item.branch === 'SP-SERVICIOS');

  const handleCloseConfirm = () => {
    handleInputFieldChange(false, 'openConfirm');
  };

  // const handleContactAgent = () => {
  //   if (!currentBranchOfficeIndex) {
  //     Toast(t('shoppingCart.errors.noBranchOffice'), 'error');
  //     handleModalState('openAgentModal', false);
  //     return;
  //   } if (!courierServiceId && !noDeliveryProducts) {
  //     Toast(t('shoppingCart.errors.noCourier'), 'error');
  //     handleModalState('openAgentModal', false);
  //     return;
  //   } if (courierServiceId === 4 && !pickWarehouse) {
  //     Toast(t('shoppingCart.errors.noPickWarehouse'), 'error');
  //     handleModalState('openAgentModal', false);
  //     return;
  //   }
  //   handleModalState('openAgentModal');
  // };

  const handleDoNotShowWarning = dontShow => dispatch({
    type: 'fieldChange',
    payload: { field: 'doNotShowWarning', value: dontShow },
  });

  const addFinalUser = finalUser => {
    endUsersData.push(finalUser);
    handleGetEndUsers(endUsersData);
  };

  switch (errorCode) {
    case errorCodes.EMPTY_CART:
    case errorCodes.EMPTY_ITEMS:
      return (
        <Header>
          {' '}
          {t('shoppingCart.empty')}
          {' '}
        </Header>
      );
    default:
      if (items.length === 0 && typeof errorCode !== 'undefined') {
        return (
          <Header>
            {' '}
            {t('shoppingCart.empty')}
            {' '}
          </Header>
        );
      }
      return (
        <Grid>
          <OrderDetails
            endUserId={endUserId}
            userName={userName}
            credit={credit}
            handleBoolField={handleBoolField}
            buyToMe={buyToMe}
            endUsers={endUsersData}
            creditAvailable={creditAvailable}
            exchangeRate={exchangeRate}
            endUserChange={(e, { value }) => {
              handleInputFieldChange(value, 'endUserId');
              services.updateShoppingCart({ endUserId: value });
            }}
            hasAppleCare={hasAppleCare}
            purchaseOrder={purchaseOrder}
            purchaseOrderChange={(e, { value }) => {
              handleInputFieldChange(value, 'purchaseOrder');
              services.updateShoppingCart({ purchaseOrder: value });
            }}
          />
          <ItemDivider text={t('shoppingCart.items')} icon="tag" />
          <Grid.Row>
            <Grid.Column computer={16} tablet={16} mobile={16}>
              <OrderItems
                dispatch={dispatch}
                items={items}
                orderId={orderId}
                currency={currency}
                exchangeRate={exchangeRate}
                payment={payment}
                steppedPrices={steppedPrices}
                deleteShoppingCartProduct={deleteShoppingCartProduct}
                handleBoolField={handleBoolField}
                hasAgentPrice={hasAgentPrice}
                hpeIquoteId={hpeIquoteId}
                subtotal={subtotal}
                actionLoader={actionLoader}
                warehouseSelected={warehouseSelected}
                chosenDelivery={chosenDelivery}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={8} computer={8} tablet={16} mobile={16}>
              <Attachments files={attachments} attachSelectedChange={handleInputFieldChange} />
              <Confirm
                open={openConfirm}
                header={t('shoppingCart.deleteAttachments')}
                content={fileSelected.map(file => (
                  <p key={file.id}>
                    {file.name}
                    .
                    {file.type}
                  </p>
                ))}
                cancelButton={t('cancel')}
                confirmButton={t('yes')}
                onCancel={handleCloseConfirm}
                onConfirm={() => handleAttachedDelete(fileSelected[0])}
              />
            </Grid.Column>
            <Responsive as={Grid.Column} minWidth={Responsive.onlyComputer.minWidth} textAlign="center" verticalAlign="middle" largeScreen={8} computer={8}>
              <Grid centered>
                {
                  needForm
                    ? (
                      <Grid.Row>
                        <Grid.Column largeScreen={9} computer={11}>
                          <Button
                            fluid
                            color="blue"
                            onClick={() => {
                              handleBoolField('saveAsQuote', false);
                              handleBoolField('isGoToPayment', false);
                              handleModalState('openMultiBrand');
                              handleDoNotShowWarning(true);
                            }}
                          >
                            {t('shoppingCart.multiBrandButton')}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )
                    : null
                }
                <Grid.Row>
                  <Grid.Column largeScreen={9} computer={11}>
                    <Button fluid color="blue" onClick={() => handleModalState('openAttach')}>{t('shoppingCart.attach')}</Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Responsive>
            <Modal closeIcon open={openAttach} onClose={() => handleModalState('openAttach', false)}>
              <Modal.Content>
                <UploadPanel
                  handleAttachedAdd={handleAttachedAdd}
                  panelState={() => handleModalState('openAttach', false)}
                  order={orderId}
                  name="prueba"
                  style={{ width: '100%' }}
                />
              </Modal.Content>
            </Modal>
            <Modal
              closeIcon
              size="fullscreen"
              open={openMultiBrand}
              onClose={() => {
                handleModalState('openMultiBrand', false);
                handleDoNotShowWarning(false);
              }}
            >
              <Modal.Content>
                <MultiBrand
                  orderId={orderId}
                  finalUser={endUserId}
                  customerId={customerId}
                  productsByMaker={{
                    microsoft: itemsMicrosoft,
                    redHat: itemsRedHat,
                    carePacks: itemsCarepacks,
                    autodesk: itemsAutodesk,
                    veeam: itemsVeeam,
                    vmware: itemsVmware,
                    appleCare: itemsAppleCare,
                    trellix: itemsTrellix,
                  }}
                  arrayBrand={existingBrands}
                  formId={handleFormId}
                  onClose={() => {
                    handleModalState('openMultiBrand', false);
                    handleDoNotShowWarning(false);
                  }}
                  handleGoToPayment={handleGoToPayment}
                  handleSaveQuotation={() => handleModalState('openNameModal', true)}
                  isGoToPayment={isGoToPayment}
                  saveAsQuote={saveAsQuote}
                  addFinalUser={addFinalUser}
                  savedForm={(saved, dataForm) => handleGetSavedForm(saved, dataForm)}
                  history={history}
                  doNotShowWarning={doNotShowWarning}
                />
              </Modal.Content>
            </Modal>
          </Grid.Row>
          <Responsive as={Grid.Row} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Grid.Column textAlign="center" verticalAlign="middle" tablet={16} mobile={16}>
              <Grid centered>
                {
                  needForm
                    ? (
                      <Grid.Row>
                        <Grid.Column largeScreen={9} computer={11}>
                          <Button
                            fluid
                            color="blue"
                            onClick={() => {
                              handleBoolField('saveAsQuote', false);
                              handleBoolField('isGoToPayment', false);
                              handleModalState('openMultiBrand');
                              handleDoNotShowWarning(true);
                            }}
                          >
                            {t('shoppingCart.multiBrandButton')}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    )
                    : null
                }
                <Grid.Row>
                  <Grid.Column tablet={10} mobile={12}>
                    <Button fluid color="blue" onClick={() => handleModalState('openAttach')}>{t('shoppingCart.attach')}</Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Responsive>
          <ItemDivider />
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Header>
                {' '}
                {t('shoppingCart.deliveryType')}
                {' '}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={4} tablet={4} computer={4} largeScreen={4} widescreen={4}>
              {
                (hpeIquoteId <= 0 && !hasAgentPrice)
                  ? (
                    <Checkbox
                      radio
                      label={noDeliveryProducts ? t('shoppingCart.noDelivery') : t('shoppingCart.singleDelivery')}
                      name="deliveryType"
                      value="singleDelivery"
                      checked={chosenDelivery === 'singleDelivery'}
                      onChange={() => handleDeliveryChange('singleDelivery')}
                    />
                  )
                  : null
              }
            </Grid.Column>
            <Grid.Column mobile={5} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              {
                (Number(hpeIquoteId) <= 0 && hasAgentPrice)
                  ? (
                    <Checkbox
                      radio
                      label={noDeliveryProducts ? t('shoppingCart.noDelivery') : t('shoppingCart.quoteWithAgent')}
                      name="deliveryType"
                      value="multiDelivery"
                      checked={chosenDelivery === 'multiDelivery'}
                      onChange={() => handleDeliveryChange('multiDelivery')}
                    />
                  )
                  : null
              }
            </Grid.Column>
            <Grid.Column mobile={5} tablet={5} computer={4} largeScreen={4} widescreen={4}>
              {
                (Number(hpeIquoteId) <= 0 && !hasAgentPrice && !noDeliveryProducts)
                  ? (
                    <Checkbox
                      radio
                      label={noDeliveryProducts ? t('shoppingCart.noDelivery') : t('shoppingCart.warehousePickup')}
                      name="deliveryType"
                      value="warehousePickup"
                      checked={chosenDelivery === 'warehousePickup'}
                      onChange={() => handleDeliveryChange('warehousePickup')}
                    />
                  )
                  : null
              }
            </Grid.Column>
          </Grid.Row>
          {
            chosenDelivery === 'warehousePickup'
              ? (
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                          <Grid.Row centered>
                            <Grid.Column computer={16} tablet={16} mobile={16}>
                              <p><h4>Selecciona el almacén donde se recogerán los productos:</h4></p>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ paddingBottom: '2rem' }}>
                        <Grid.Column>
                          <Grid.Row>
                            <Radio
                              label="Guadalajara"
                              name="radioWarehouse"
                              value="GI01"
                              checked={warehouseSelected === 'GI01'}
                              onChange={() => handleWarehouseSelectedChange('GI01')}
                            />
                          </Grid.Row>
                          <Grid.Row>
                            <Radio
                              label="Mexico"
                              name="radioWarehouse"
                              value="MI01"
                              checked={warehouseSelected === 'MI01'}
                              onChange={() => handleWarehouseSelectedChange('MI01')}
                            />
                          </Grid.Row>
                          <Grid.Row>
                            <Radio
                              label="Monterrey"
                              name="radioWarehouse"
                              value="YI01"
                              checked={warehouseSelected === 'YI01'}
                              onChange={() => handleWarehouseSelectedChange('YI01')}
                            />
                          </Grid.Row>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row centered>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <p>{t('shoppingCart.shippingWarehousePickupAlert')}</p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <TextAreaControlledCollector collectorName={collectorName} handleInputFieldChange={handleInputFieldChange} t={t} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              )
              : null
          }
          {
            chosenDelivery === 'warehousePickup'
              ? <ItemDivider />
              : null
          }
          {
            chosenDelivery === 'singleDelivery' && !noDeliveryProducts
              ? (
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column mobile={16} tablet={16} computer={16}>
                          <SingleDelivery
                            handleShippingCostChange={shippingConfig => handleShippingChange(shippingConfig)}
                            branchOffices={branchOffices}
                            allBranchOffices={allBranchOffices}
                            courierServiceId={courierServiceId}
                            currentBranchOfficeIndex={currentBranchOfficeIndex}
                            addressId={addressId}
                            handleBranchOfficeChange={handleBranchOfficeChange}
                            handleAddressChange={handleAddressChange}
                            items={items}
                            orderId={orderId}
                            handleGetNewAddress={handleGetNewAddress}
                            currency={currency}
                            exchangeRate={exchangeRate}
                            subtotal={subtotal}
                          />
                        </Grid.Column>
                      </Grid.Row>
                      {/* <Grid.Row centered>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <Button color="yellow" onClick={handleContactAgent}>
                            {t('multi-shipping.contactAgent')}
                          </Button>
                        </Grid.Column>
                      </Grid.Row> */}
                      <Grid.Row centered>
                        <Grid.Column computer={16} tablet={16} mobile={16}>
                          <p>{t('shoppingCart.shippingAlert')}</p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              )
              : null
          }
          {
            chosenDelivery === 'singleDelivery'
              ? <ItemDivider />
              : null
          }
          {
            chosenDelivery === 'multiDelivery'
              ? (
                <Grid.Row>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    <Header size="small">
                      {t(multishippingMessage(noDeliveryProducts, hasAgentPrice))}
                    </Header>
                  </Grid.Column>
                </Grid.Row>
              )
              : null
          }
          {chosenDelivery === 'multiDelivery' ? <ItemDivider /> : null}
          <Grid.Row>
            <Grid.Column mobile={15} tablet={15} computer={8}>
              <Payment
                handlePaymentChange={handlePaymentChange}
                handleCurrencyChange={(e, { value }) => handleCurrencyChange(value)}
                handleCFDIChange={(e, { value }) => handleInputFieldChange(value, 'cfdi')}
                payment={payment}
                currency={currency}
                idCFDI={cfdi}
                CFDIOptions={CFDIArray}
                creditAvailable={creditAvailable}
              />
            </Grid.Column>
            <Responsive
              style={{ paddingTop: '2rem' }}
              as={Grid.Column}
              mobile={16}
              maxWidth={Responsive.onlyMobile.maxWidth}
            />
            <Grid.Column mobile={15} tablet={15} computer={8}>
              <TextAreaControlled comment={comment} handleInputFieldChange={handleInputFieldChange} t={t} />
            </Grid.Column>
          </Grid.Row>
          <ItemDivider />
          <Responsive as={Grid.Row} maxWidth={Responsive.onlyTablet.maxWidth}>
            <Grid.Column computer={16}>
              <Grid>
                <Grid.Row>
                  {
                    items.length === 0
                      ? null
                      : (
                        <Grid.Column mobile={16} tablet={16} className="styleSummary">
                          <Summary
                            subtotal={subtotal}
                            shippingCost={shippingCost}
                            iva={iva}
                            currency={currency}
                            discount={discount}
                            total={total}
                          />
                        </Grid.Column>
                      )
                  }
                </Grid.Row>
                <Grid.Row centered>
                  <Grid.Column tablet={16} mobile={16}>
                    {
                      items.length === 0
                        ? null
                        : ActionButtons()
                    }
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Responsive>
        </Grid>
      );
  }
}

UserOrder.propTypes = {
  ActionButtons: PropTypes.func,
  CFDIArray: PropTypes.arrayOf(PropTypes.shape({})),
  addressId: PropTypes.string,
  allBranchOffices: PropTypes.arrayOf(PropTypes.shape({})),
  attachments: PropTypes.arrayOf(PropTypes.shape({})),
  branchOffices: PropTypes.shape({
    filter: PropTypes.func,
  }),
  buyToMe: PropTypes.bool,
  cfdi: PropTypes.string,
  comment: PropTypes.string,
  purchaseOrder: PropTypes.string,
  courierServiceId: PropTypes.number,
  credit: PropTypes.bool,
  creditAvailable: PropTypes.bool,
  currency: PropTypes.string,
  currentBranchOfficeIndex: PropTypes.string,
  customerId: PropTypes.string,
  deleteShoppingCartProduct: PropTypes.func,
  discount: PropTypes.number,
  dispatch: PropTypes.func,
  endUserId: PropTypes.string,
  endUsers: PropTypes.arrayOf(PropTypes.shape({})),
  handleGetEndUsers: PropTypes.func,
  errorCode: PropTypes.number,
  exchangeRate: PropTypes.shape({}),
  existingBrands: PropTypes.arrayOf(PropTypes.shape({})),
  fileSelected: PropTypes.shape({
    map: PropTypes.func,
  }),
  handleAddressChange: PropTypes.func,
  handleAttachedAdd: PropTypes.func,
  handleAttachedDelete: PropTypes.func,
  handleBoolField: PropTypes.func,
  handleBranchOfficeChange: PropTypes.func,
  handleCurrencyChange: PropTypes.func,
  handleFormId: PropTypes.func,
  handleGetNewAddress: PropTypes.func,
  handleGoToPayment: PropTypes.func,
  handleInputFieldChange: PropTypes.func,
  handleModalState: PropTypes.func,
  handlePaymentChange: PropTypes.func,
  handleShippingChange: PropTypes.func,
  hasAgentPrice: PropTypes.bool,
  hpeIquoteId: PropTypes.number,
  isGoToPayment: PropTypes.bool,
  items: PropTypes.shape({
    filter: PropTypes.func,
    length: PropTypes.number,
  }),
  iva: PropTypes.number,
  needForm: PropTypes.bool,
  noDeliveryProducts: PropTypes.bool,
  openAttach: PropTypes.bool,
  openConfirm: PropTypes.bool,
  openMultiBrand: PropTypes.bool,
  orderId: PropTypes.string,
  payment: PropTypes.number,
  // pickWarehouse: PropTypes.string,
  saveAsQuote: PropTypes.bool,
  shippingCost: PropTypes.number,
  subtotal: PropTypes.number,
  t: PropTypes.func,
  total: PropTypes.number,
  userName: PropTypes.string,
  actionLoader: PropTypes.bool,
  steppedPrices: PropTypes.arrayOf(PropTypes.shape({})),
  handleGetSavedForm: PropTypes.func.isRequired,
  chosenDelivery: PropTypes.string,
  handleDeliveryChange: PropTypes.func,
  handleWarehouseSelectedChange: PropTypes.func,
  warehouseSelected: PropTypes.string,
  doNotShowWarning: PropTypes.bool,
  collectorName: PropTypes.string,
};

UserOrder.defaultProps = {
  ActionButtons: () => { },
  CFDIArray: [],
  addressId: '',
  allBranchOffices: [],
  attachments: [],
  branchOffices: [],
  buyToMe: false,
  cfdi: '',
  comment: '',
  purchaseOrder: '',
  courierServiceId: 0,
  credit: false,
  creditAvailable: false,
  currency: '',
  currentBranchOfficeIndex: '',
  customerId: '',
  deleteShoppingCartProduct: () => { },
  discount: 0,
  dispatch: () => { },
  endUserId: '',
  handleGetEndUsers: () => { },
  endUsers: [],
  errorCode: 1,
  exchangeRate: {},
  existingBrands: [],
  fileSelected: [],
  handleAddressChange: () => { },
  handleAttachedAdd: () => { },
  handleAttachedDelete: () => { },
  handleBoolField: () => { },
  handleBranchOfficeChange: () => { },
  handleCurrencyChange: () => { },
  handleFormId: () => { },
  handleGetNewAddress: () => { },
  handleGoToPayment: () => { },
  handleInputFieldChange: () => { },
  handleModalState: () => { },
  handlePaymentChange: () => { },
  handleShippingChange: () => { },
  hasAgentPrice: false,
  hpeIquoteId: '',
  isGoToPayment: false,
  items: [],
  iva: 0,
  needForm: false,
  noDeliveryProducts: false,
  openAttach: false,
  openConfirm: false,
  openMultiBrand: false,
  orderId: '',
  payment: 0,
  // pickWarehouse: '',
  saveAsQuote: false,
  shippingCost: 0,
  subtotal: 0,
  t: () => { },
  total: 0,
  userName: '',
  actionLoader: false,
  steppedPrices: [],
  chosenDelivery: 'singleDelivery',
  handleDeliveryChange: () => { },
  handleWarehouseSelectedChange: () => { },
  warehouseSelected: '',
  doNotShowWarning: false,
  collectorName: '',
};

export default translate('common', { wait: true })(UserOrder);
