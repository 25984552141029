import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from 'react-i18next';
import { Grid, Header, Checkbox } from 'semantic-ui-react';
import SiclikTable from '../../common/components/table';
import { TextField } from '../../common/components/materials';

function filterPromotional(value = '', baseArray = []) {
  return !value.length
    ? [...baseArray]
    : baseArray.filter(promotional => (
      promotional.name.toUpperCase().includes(value.toUpperCase())
    ));
}

function filterActiveStatus(value = '', baseArray = [], filter = '') {
  let promotionalsCopy = [];
  let filteredPromotionals = [];
  switch (value) {
    case 'active':
      promotionalsCopy = baseArray.filter(promotional => {
        const startDate = moment(promotional.start, 'YYYY-MM-DD');
        const endDate = moment(promotional.end, 'YYYY-MM-DD');
        return (startDate.isValid() && endDate.isValid() && moment().isBetween(startDate, endDate));
      });
      filteredPromotionals = filterPromotional(filter, promotionalsCopy);
      return ({ promotionalsCopy, filteredPromotionals });
    case 'inactive':
      promotionalsCopy = baseArray.filter(promotional => {
        const startDate = moment(promotional.start, 'YYYY-MM-DD');
        const endDate = moment(promotional.end, 'YYYY-MM-DD');
        return (startDate.isValid() && endDate.isValid() && !moment().isBetween(startDate, endDate));
      });
      filteredPromotionals = filterPromotional(filter, promotionalsCopy);
      return ({ promotionalsCopy, filteredPromotionals });
    default:
      promotionalsCopy = [...baseArray];
      filteredPromotionals = filterPromotional(filter, promotionalsCopy);
      return ({ promotionalsCopy, filteredPromotionals });
  }
}

function PromotionalList(props) {
  const {
    clean, updateClean, promotionals, headers, messageTable, t,
  } = props;

  const [state, setState] = useState({
    filter: '',
    status: 'active',
    filteredPromotionals: [],
    promotionalsCopy: [],
    filterError: '',
  });

  useEffect(() => {
    const { filteredPromotionals } = filterActiveStatus('active', promotionals);
    setState(currentState => ({
      ...currentState,
      filteredPromotionals,
      promotionalsCopy: promotionals,
      filter: '',
      filterError: '',
      status: 'active',
    }));
  }, [promotionals, clean]);

  const handleChange = ({ target }) => {
    const { value, id, name } = target;
    const nameOrId = id || name;
    const filteredPromotionals = filterPromotional(value, state.promotionalsCopy);
    setState({
      ...state,
      [nameOrId]: value,
      [`${nameOrId}Error`]: filteredPromotionals.length ? '' : 'No se encontraron coincidencias',
      filteredPromotionals,
    });
  };

  const handleStatusChange = (e, { value }) => {
    const { promotionalsCopy, filteredPromotionals } = filterActiveStatus(value, promotionals, state.filter);
    setState({
      ...state,
      promotionalsCopy,
      filteredPromotionals,
      status: value,
      filterError: filteredPromotionals.length ? '' : 'No se encontraron coincidencias',
    });
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('marketing.header')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle">
        <Grid.Column widescreen={4} computer={5} tablet={6} mobile={16}>
          <TextField
            id="filter"
            fullWidth
            label={t('Filtrar')}
            value={`${state.filter}` || ''}
            error={state.filterError || ''}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column only="mobile" mobile={16} style={{ paddingBottom: '2em' }} />
        <Grid.Column widescreen={1} computer={2} tablet={3} mobile={5}>
          <Checkbox
            radio
            label="Visibles"
            name="statusGroup"
            value="active"
            checked={state.status === 'active'}
            onChange={handleStatusChange}
          />
        </Grid.Column>
        <Grid.Column widescreen={1} computer={3} tablet={4} mobile={5}>
          <Checkbox
            radio
            label="No visibles"
            name="statusGroup"
            value="inactive"
            checked={state.status === 'inactive'}
            onChange={handleStatusChange}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SiclikTable
            selectable
            clean={clean}
            updateClean={updateClean}
            data={state.filteredPromotionals}
            headers={headers}
            pageSize={5}
            noDataMessage={messageTable}
            singleSelection
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

PromotionalList.propTypes = {
  clean: PropTypes.bool,
  messageTable: PropTypes.string,
  t: PropTypes.func,
  updateClean: PropTypes.func,
  promotionals: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})),
};

PromotionalList.defaultProps = {
  t: () => null,
  updateClean: () => null,
  promotionals: [],
  headers: [],
  messageTable: '',
  clean: false,
};

export default translate('common', { wait: true })(PromotionalList);
