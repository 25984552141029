import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Divider, Dropdown, Radio, Header, Grid,
} from 'semantic-ui-react';

const Scope = ({
  t, type, behavior, scope, handleChangeScope, client, placeholderScope, handleChangeClients, scopeOptions,
}) => (
  <Grid container>
    <Grid.Row>
      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
        <Divider horizontal>
          <Header as="h4">{t('discounts.discountRules')}</Header>
        </Divider>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
        <Header as="h4">{t('discounts.scope')}</Header>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row colums={3}>
      {type !== 3 && behavior !== 1
        ? (
          <Grid.Column largeScreen={2} computer={2} tablet={3} mobile={16}>
            <Radio
              label={t('discounts.general')}
              name="radioGroup"
              value={3}
              checked={Number(scope) === 3}
              onChange={handleChangeScope}
            />
          </Grid.Column>
        )
        : null}
      <Grid.Column largeScreen={2} computer={3} tablet={3} mobile={16}>
        <Radio
          label={t('discounts.byClient')}
          name="radioGroup"
          value={1}
          checked={Number(scope) === 1}
          onChange={handleChangeScope}
        />
      </Grid.Column>
      <Grid.Column largeScreen={2} computer={3} tablet={3} mobile={16}>
        <Radio
          label={t('discounts.byGroup')}
          name="radioGroup"
          value={2}
          checked={Number(scope) === 2}
          onChange={handleChangeScope}
        />
      </Grid.Column>
    </Grid.Row>
    <p style={{ color: 'red' }}>{client.error}</p>
    {scope && Number(scope) > 0 && Number(scope) < 3
      ? (
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={8} tablet={9} mobile={16}>
            <Dropdown
              placeholder={placeholderScope}
              onChange={handleChangeClients}
              options={scopeOptions}
              value={client.value}
              error={client.error}
              selection
              search
              fluid
            />
          </Grid.Column>
        </Grid.Row>
      )
      : ''}
  </Grid>
);

Scope.defaultProps = {
  handleChangeScope: null,
  scopeOptions: [],
  handleChangeClients: null,
  behavior: null,
  placeholderScope: 'Elije una opción',
  value: '',
  scope: '',
  error: '',
  client: { value: '', error: '' },
};

Scope.propTypes = {
  behavior: PropTypes.number,
  client: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  error: PropTypes.string,
  handleChangeClients: PropTypes.func,
  handleChangeScope: PropTypes.func,
  placeholderScope: PropTypes.string,
  scope: PropTypes.string,
  scopeOptions: PropTypes.arrayOf(),
  type: PropTypes.number.isRequired,
  value: PropTypes.string,
};

export default translate('common', { wait: true })(Scope);
