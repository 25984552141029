import React, { Component } from 'react';
import {
  Tab, Grid, List, Image, Icon, Header, Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ChatComponent from './components/Chat';
import MembersComponent from './components/Members';
import Toast from '../common/components/toast';
import chatService from './services';
import './styles.css';

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: props.order,
      customers: [],
      membersCount: 0,
      pendingCount: 0,
      statusFilter: [
        { key: '0', text: 'Por invitar', value: 0 },
        { key: '1', text: 'Invitado', value: 1 },
      ],
      customerSelected: 'All',
      statusSelected: '',
      membersHeaders: [
        { key: 'userId', isKey: true },
        {
          key: 'image',
          format: cell => (cell !== '' ? <Image src={cell} size="tiny" /> : (
            <Image spaced="rigth" size="small">
              {' '}
              <Icon color="blue" name="user" size="huge" />
              {' '}
            </Image>
          )),
        },
        {
          name: 'Nombre',
          format: (cell, row) => (
            <Grid>
              <Grid.Row>
                <List>
                  <List.Item>{`${row.name} ${row.surname}`}</List.Item>
                  {row.role ? <List.Item>{row.role}</List.Item> : ''}
                  <List.Item>{row.customerName}</List.Item>
                  <List.Item>{row.email}</List.Item>
                </List>
              </Grid.Row>
            </Grid>
          ),
        },
        {
          key: 'isInvited',
          name: 'Estatus',
          format: (cell, row) => (
            row.isInvited ? (
              <Header as="h4" color="green" style={{ display: 'inline' }}>Invitado</Header>)
              : <Button color="blue" icon="add user" onClick={() => this.handleAddUserToOrder(row)}>Invitar</Button>
          ),
        },
      ],
      membersData: [],
      membersLoader: true,
      dataFiltered: [],
      panes: [
        {
          menuItem: { key: 'chat', icon: 'wechat', content: 'Chat' },
          render: () => <Tab.Pane><ChatComponent order={props.order} /></Tab.Pane>,
        },
        {
          menuItem: { key: 'users', icon: 'users', content: 'Integrantes' },
          render: () => {
            const {
              membersHeaders, dataFiltered, membersLoader, customers, statusFilter, customerSelected, statusSelected, membersCount, pendingCount,
            } = this.state;
            return (
              <Tab.Pane>
                <MembersComponent
                  membersHeaders={membersHeaders}
                  data={dataFiltered}
                  membersLoader={membersLoader}
                  customers={customers}
                  statusFilter={statusFilter}
                  customerSelected={customerSelected}
                  statusSelected={statusSelected}
                  handleChangeCustomer={this.handleChangeCustomer}
                  handleChangeStatus={this.handleChangeStatus}
                  membersCount={membersCount}
                  pendingCount={pendingCount}
                />
              </Tab.Pane>
            );
          },
        },
      ],
    };
  }

  componentDidMount() {
    const { order } = this.props;
    this.getUsersList(order);
  }

  getUsersList = async orderId => {
    try {
      const { customers } = this.state;
      chatService.getUsersList(orderId)
        .then(async users => {
          const totalUsers = users.usersInvited.concat(users.usersToInvite);
          const usersList = await this.distinctUsers(totalUsers, users.usersInvited, customers);
          this.setState({
            membersData: usersList,
            dataFiltered: usersList,
            membersLoader: false,
            membersCount: users.usersInvited.length,
            pendingCount: users.usersToInvite.length,
          });
        });
    } catch (error) {
      let messageError = '';
      if (!error) messageError = ('error-codes.default');
      else messageError = error.message;
      Toast(messageError, 'error');
    }
  }

  setValues = (usersList, customers) => {
    customers.push({ key: 'All', text: 'Todas las empresas', value: 'All' });
    this.setState({ customers });
    return usersList;
  }

  distinctUsers = async (usersList, usersInvited, customers) => (
    Promise.all(usersList.map(user => {
      const isInvited = usersInvited.some(invited => (user.userId === invited.userId && user.customerId === invited.customerId));
      if (!customers.some(customer => customer.key === user.customerId)) customers.push({ key: user.customerId, text: user.customerName, value: user.customerId });
      return { ...user, isInvited };
    }))
  ).then(arrayUsers => this.setValues(arrayUsers, customers))

  handleChangeCustomer = (e, { value }) => {
    this.setState({ customerSelected: value, statusSelected: '' });
    this.filterForCustomer(value);
  }

  handleChangeStatus = (e, { value }) => {
    this.setState({ statusSelected: value });
    this.filterForStatus(value);
  }

  filterForCustomer = customer => {
    const { membersData: customerToFilter } = this.state;
    let foundData = [];
    if (!customer || customer === 'All') {
      foundData = customerToFilter;
    } else {
      foundData = customerToFilter.filter(filterInfo => filterInfo.customerId === customer);
    }
    this.setState({ dataFiltered: foundData });
  }

  filterForStatus = status => {
    const { membersData, customerSelected } = this.state;
    const flag = status === 1;
    let foundData = [];
    if (customerSelected === 'All') {
      foundData = membersData.filter(filterInfo => filterInfo.isInvited === flag);
    } else {
      foundData = membersData.filter(filterInfo => filterInfo.isInvited === flag && filterInfo.customerId === customerSelected);
    }
    this.setState({ dataFiltered: foundData });
  }

  handleTabChange = (e, { activeIndex }) => {
    if (activeIndex === 0) {
      this.handleChangeCustomer('', { value: 'All' });
    }
  }

  handleAddUserToOrder = user => {
    const {
      orderId, membersData, membersCount, pendingCount,
    } = this.state;
    let membersCounter = membersCount;
    let pendingCounter = pendingCount;
    const { userId, customerId } = user;
    const users = [{ userId, customerId }];
    const index = membersData.findIndex(element => element.userId === userId);
    chatService.addUserToOrder({ users }, orderId)
      .then(() => {
        membersCounter++;
        pendingCounter--;
        membersData[index].isInvited = true;
        this.setState({
          dataFiltered: membersData,
          membersData,
          membersCount: membersCounter,
          pendingCount: pendingCounter,
        });
        Toast('El usuario ha sido invitado', 'success');
      }).catch(error => {
        let messageError = '';
        if (!error) messageError = ('error-codes.default');
        else messageError = error.message;
        Toast(messageError, 'error');
      });
  }

  render() {
    const { panes } = this.state;
    return (
      <Tab panes={panes} onTabChange={this.handleTabChange} />
    );
  }
}

Chat.propTypes = {
  order: PropTypes.string.isRequired,
};

Chat.defaultProps = {
};

export default Chat;
