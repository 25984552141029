import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Modal, Image,
} from 'semantic-ui-react';
import assets from '../../home-siclik/images';
import { TextField } from '../../common/components/materials';

const {
  comercio,
} = assets;

const AddUser = props => {
  const {
    t, userEmail, accessType, emailError, accessBy, accessByError, update, handleClickUpdate,
    save, open, handleEmailChange, onItemClick, validations, isCompleted, handleClickCancel,
  } = props;
  return (
    <Modal open={open}>
      <Modal.Header>{t('user.userInformation')}</Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column largeScreen={7} computer={7} tablet={6} mobile={15}>
              <TextField
                fullWidth
                maxLength={55}
                value={userEmail || ''}
                error={emailError || ''}
                label={t('user.email')}
                onChange={handleEmailChange}
              />
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={10}>
              <Image src={comercio} />
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={15}>
              <TextField
                select
                fullWidth
                label={t('user.accesstype')}
                value={accessBy || ''}
                error={accessByError || ''}
                onChange={onItemClick}
                options={accessType}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        {save ? <Button color="blue" onClick={() => validations(isCompleted)}>{t('save')}</Button> : null}
        {update ? <Button color="blue" onClick={handleClickUpdate}>{t('update')}</Button> : null}
        <Button onClick={handleClickCancel}>{t('cancel')}</Button>
      </Modal.Actions>
    </Modal>
  );
};

AddUser.propTypes = {
  accessBy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accessByError: PropTypes.string,
  accessType: PropTypes.arrayOf(PropTypes.shape({})),
  emailError: PropTypes.string,
  handleClickCancel: PropTypes.func,
  handleClickUpdate: PropTypes.func,
  handleEmailChange: PropTypes.func,
  isCompleted: PropTypes.bool,
  onItemClick: PropTypes.func,
  open: PropTypes.bool,
  save: PropTypes.bool,
  t: PropTypes.func,
  update: PropTypes.bool,
  userEmail: PropTypes.string,
  validations: PropTypes.func,
};

AddUser.defaultProps = {
  accessBy: '',
  accessByError: '',
  accessType: [],
  emailError: '',
  handleClickCancel: () => { },
  handleClickUpdate: () => { },
  handleEmailChange: () => { },
  isCompleted: false,
  onItemClick: () => { },
  open: false,
  save: false,
  t: () => { },
  update: false,
  userEmail: '',
  validations: () => { },
};

export default translate('common', { wait: true })(AddUser);
