/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
  Header, Message, Icon, Grid, Segment, Divider,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import ListItems from './ListItems';
import DetailsToBuy from './DetailsToBuy';

import { currencyFormat } from '../../common/utils';

function setFormatShippingData(items, shipping) {
  const shippingItems = items.filter(item => item.hasDelivery);
  return shippingItems.map(item => ({
    sku: item.sku,
    courierServiceId: shipping.parcel,
    branchOfficeId: shipping.addressId,
    pickWarehouse: shipping.warehouseCs,
    UPSinsure: shipping.UPSinsure,
    source: shipping.origins
      .filter(origin => origin.sku === item.sku)
      .map(({ quantity, warehouse }) => ({ quantity, warehouse })),
  }));
}

function ConfirmOrderStep(props) {
  const {
    active, items, generateQuickCart, wishListName, cartGenerated, selectedShipping, t,
  } = props;
  const [checkoutLoader, setCheckoutLoader] = useState(false);
  const [checkout, setCheckout] = useState({});

  async function getCheckout() {
    try {
      const deliveryConfig = setFormatShippingData(items, selectedShipping);
      const itemsFormated = items.map(({ sku, quantity }) => ({ sku, quantity }));
      setCheckoutLoader(true);
      const userCart = await generateQuickCart(itemsFormated, deliveryConfig, wishListName);
      setCheckout(userCart);
      setCheckoutLoader(false);
      cartGenerated(userCart.id);
    } catch (error) {
      setCheckoutLoader(false);
      cartGenerated(false);
    }
  }

  useEffect(() => {
    if (active) {
      getCheckout();
    }
  }, [active]);

  function Checkout() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
            <Segment>
              <DetailsToBuy />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column verticalAlign="top" textAlign="center" mobile={16} tablet={8} computer={8} largeScreen={8} widescreen={8}>
            <Grid.Row className="">
              <Header as="h5" color="blue">{t('checkout.generatedOrders')}</Header>
            </Grid.Row>
            <Segment raised style={{ overflowX: 'auto', height: '160px' }}>
              {items.map(item => (
                <ListItems item={item} t={t} />
              ))}
            </Segment>
          </Grid.Column>
          <Grid.Column verticalAlign="top" textAlign="center" className="styleSummary margin-summary" mobile={16} tablet={8} computer={8} largeScreen={8} widescreen={8}>
            <Grid centered>
              <Grid.Row>
                <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={14}>
                  <Header as="h4">{t('shoppingCart.summary')}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="padding-x-5">
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h5">{t('wishList.confirmOrder.subtotal')}</Header>
                </Grid.Column>
                <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
                  <Header as="h5">{currencyFormat(checkout.subtotal)}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="padding-x-5">
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h5">{t('wishList.confirmOrder.shipping')}</Header>
                </Grid.Column>
                <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
                  <Header as="h5">{currencyFormat(checkout.shippingCost)}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="padding-x-5">
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h5">{t('wishList.confirmOrder.iva')}</Header>
                </Grid.Column>
                <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
                  <Header as="h5">{currencyFormat(checkout.iva)}</Header>
                </Grid.Column>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Divider style={{ margin: '0.5rem' }} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="padding-x-5">
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
                  <Header as="h4" color="green">
                    {t('wishList.confirmOrder.total')}
                    {' '}
                  </Header>
                </Grid.Column>
                <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
                  <Header as="h5" color="green">{currencyFormat(checkout.total)}</Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  function LoadingCheckout() {
    return (
      <Message icon>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>{t('wishList.confirmOrder.loading.header')}</Message.Header>
          {t('wishList.confirmOrder.loading.description')}
        </Message.Content>
      </Message>
    );
  }

  return (
    <div hidden={!active}>
      {checkoutLoader
        ? <LoadingCheckout />
        : <Checkout />}
    </div>
  );
}

ConfirmOrderStep.defaultProps = {
  wishListName: '',
};

ConfirmOrderStep.propTypes = {
  active: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  generateQuickCart: PropTypes.func.isRequired,
  wishListName: PropTypes.string,
  cartGenerated: PropTypes.func.isRequired,
  selectedShipping: PropTypes.shape({
    parcel: PropTypes.string,
    addressId: PropTypes.string,
    warehouseCs: PropTypes.string,
    UPSinsure: PropTypes.bool,
    origins: PropTypes.arrayOf(PropTypes.shape({
      quantity: PropTypes.number,
      warehouse: PropTypes.string,
    })),
  }).isRequired,
};

export default translate('common', { wait: true })(ConfirmOrderStep);
