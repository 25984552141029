const USER_ERROR = 'user.errors';

const EMPTY_FIELD_MESSAGE = `${USER_ERROR}.empty`;
const INVALID_EMAIL_MESSAGE = `${USER_ERROR}.notEmail`;
const INVALID_ACCESS_TYPE_MESSAGE = `${USER_ERROR}.emptyAccessType`;

export function isEmail(input = '') {
  if (input === '') {
    return EMPTY_FIELD_MESSAGE;
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(input) ? null : INVALID_EMAIL_MESSAGE;
}

export function isNumber(input) {
  const regex = /^[0-9]+$/;
  return regex.test(input) ? null : INVALID_ACCESS_TYPE_MESSAGE;
}

export function form({ roleId, email }) {
  const emailError = isEmail(email);
  const accessTypeIdError = isNumber(roleId);
  return { emailError, accessTypeIdError };
}
