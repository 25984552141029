/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Header, Table, Pagination, Icon, Dropdown,
} from 'semantic-ui-react';
import {
  getPaginationArrayAndPages, compareArray, cloneArrayNoRef, compareObjectsWithJSON,
} from './utils';
import HeaderCell from './components/header-cell';
import TableBody from './components/body';
/* eslint react/no-array-index-key: 0 */

function generatePageOptions(pageSize) {
  const totalOptions = Math.floor(pageSize / 5);
  const options = [];
  for (let i = 1; i < totalOptions; i += 1) {
    const value = i * 5;
    options.push({ key: value, text: value, value });
  }
  options.push({ key: pageSize, text: pageSize, value: pageSize });
  return options;
}

class SiclikTable extends Component {
  static getDerivedStateFromProps(props, state) {
    const dataChanged = {};
    if (!compareArray(props.data, state.data)) {
      const tablePagination = getPaginationArrayAndPages(props.data, state.pageSize);
      const activePage = tablePagination.totalPages >= state.activePage ? state.activePage : tablePagination.totalPages;
      dataChanged.data = cloneArrayNoRef(props.data);
      dataChanged.paginatedData = tablePagination.formatedArray;
      dataChanged.totalPages = tablePagination.totalPages;
      dataChanged.activePage = activePage;
    }
    if (!compareArray(props.headers, state.headers)) {
      const visibleHeaders = props.headers.filter(header => !header.isKey);
      const headerKeys = visibleHeaders.map(header => {
        const formatedHeader = {};
        formatedHeader.key = header.key;
        if (header.format) formatedHeader.format = header.format;
        return formatedHeader;
      });
      const { key } = props.headers.filter(header => header.isKey)[0];
      dataChanged.headers = props.headers;
      dataChanged.visibleHeaders = visibleHeaders;
      dataChanged.headerKeys = headerKeys;
      dataChanged.key = key;
    }
    return (!Object.keys(dataChanged).length) ? null : dataChanged;
  }

  constructor(props) {
    super(props);
    const { pageSize } = this.props;
    this.state = {
      headers: [],
      data: [],
      paginatedData: [],
      key: null,
      headerKeys: [],
      visibleHeaders: [],
      selectedRows: [],
      activePage: 1,
      totalPages: 1,
      pageSize,
      pageSizeOptions: generatePageOptions(pageSize),
    };
  }

  handlePaginationChange = (e, { activePage }) => this.setState({ activePage });

  handleGetPagination = pageSize => {
    const { data } = this.props;
    let { paginatedData, totalPages, activePage } = this.state;
    const tablePagination = getPaginationArrayAndPages(data, pageSize);
    activePage = 1;
    paginatedData = tablePagination.formatedArray;
    totalPages = tablePagination.totalPages;
    this.setState({ activePage, paginatedData, totalPages });
  }

  handleRowSelection = row => {
    const { getSelectedRows, singleSelection } = this.props;
    const { selectedRows: stateSelectedRows } = this.state;
    if (!getSelectedRows) return;
    let selectedRows = cloneArrayNoRef(stateSelectedRows);
    const rowIndexExist = selectedRows.findIndex(currentRow => compareObjectsWithJSON(currentRow, row));
    if (singleSelection) {
      if (rowIndexExist >= 0) selectedRows = [];
      else selectedRows = [row];
    } else if (rowIndexExist >= 0) {
      selectedRows.splice(rowIndexExist, 1);
    } else {
      selectedRows.push(row);
    }
    getSelectedRows(selectedRows);
    this.setState({ selectedRows });
  }

  handleChangeSize = (e, { value: pageSize }) => {
    this.setState({ pageSize });
    this.handleGetPagination(pageSize);
  }

  renderTableBody = (pageArray, headerKeys, key) => (
    <Table.Body>
      {pageArray.map((rowObject, index) => (
        <Table.Row key={`${rowObject[key]}.${index}`}>
          {headerKeys.map(headerKey => (<Table.Cell>{rowObject[headerKey]}</Table.Cell>))}
        </Table.Row>
      ))}
    </Table.Body>
  )

  renderEmptyTable = () => {
    const { headers, noDataMessage } = this.props;
    const columnCount = headers.filter(header => (!header.isKey)).length;
    return (
      <Table.Body>
        <Table.Row textAlign="center">
          <Table.Cell colSpan={columnCount}>
            <Header size="small">{noDataMessage}</Header>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    );
  }

  render() {
    const {
      data, headers, noDataMessage, clean, striped, basic, updateClean, getSelectedRows, ...restProps
    } = this.props;
    const {
      visibleHeaders, paginatedData, activePage, headerKeys, key, totalPages, selectedRows, pageSizeOptions, pageSize,
    } = this.state;
    if (!!updateClean && clean) {
      this.setState({ selectedRows: [] });
      if (getSelectedRows) getSelectedRows([]);
      updateClean();
    }
    return (
      <Table striped={striped} basic={basic} {...restProps} unstackable>
        <Table.Header>
          <Table.Row>
            {visibleHeaders.map((header, index) => (
              <HeaderCell key={`${header.key}.${index}`} header={header} />
            ))}
          </Table.Row>
        </Table.Header>
        {
          data.length === 0
            ? this.renderEmptyTable()
            : (
              <TableBody
                pageArray={paginatedData[activePage - 1]}
                handleSelectedRow={this.handleRowSelection}
                headerKeys={headerKeys}
                mainKey={key}
                selectedRows={selectedRows}
                isSelectable={restProps.selectable}
              />
            )
        }
        {(totalPages > 1)
          ? (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="1" textAlign="left">
                  <label>Tamaño </label>
                  <Dropdown
                    options={pageSizeOptions}
                    onChange={this.handleChangeSize}
                    selection
                    compact
                    value={pageSize}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" colSpan={visibleHeaders.length - 1}>
                  <Pagination
                    activePage={activePage}
                    onPageChange={this.handlePaginationChange}
                    totalPages={totalPages}
                    pointing
                    secondary
                    ellipsisItem={{ content: <Icon name="ellipsis horizontal" />, icon: true }}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          ) : null}
      </Table>
    );
  }
}

SiclikTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.PropTypes.string,
      isKey: PropTypes.bool,
      name: PropTypes.string,
      format: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  noDataMessage: PropTypes.string,
  pageSize: PropTypes.number,
  getSelectedRows: PropTypes.func,
  updateClean: PropTypes.func,
  clean: PropTypes.bool,
  singleSelection: PropTypes.bool,
  striped: PropTypes.bool,
  basic: PropTypes.string,
};

SiclikTable.defaultProps = {
  data: [],
  noDataMessage: 'No hay datos para mostrar',
  pageSize: 10,
  getSelectedRows: null,
  updateClean: null,
  clean: false,
  striped: true,
  singleSelection: false,
  basic: 'very',
};

export default SiclikTable;
