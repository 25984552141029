import { fromJS } from 'immutable';
import moment from 'moment';
import * as session from '../../../common/sessions';

import {
  SELECT_WISH_LIST,
  GET_WISH_LISTS,
  CREATE_WISH_LIST,
  TOGGLE_SWITCH_NEW_LIST_MODAL,
  TOGGLE_SWITCH_RENAME_LIST_MODAL,
  CREATE_DEFAULT_WISH_LIST,
  RENAME_WISH_LIST,
  DELETE_WISH_LIST,
} from './constants';

const wishLists = 'wishLists';
const selectedList = 'selectedList';
const modalRenameListToggle = 'modalRenameListToggle';
const modalNewListToggle = 'modalNewListToggle';

export const initialState = fromJS({
  [wishLists]: [],
  [selectedList]: {},
  [modalRenameListToggle]: false,
  [modalNewListToggle]: false,
});

function generateNewList(userWishLists, currentWishList) {
  return userWishLists.map(list => (
    list.id === currentWishList.id
      ? { ...currentWishList, selected: true }
      : { ...list, selected: false }
  ));
}

function getFirstElement(lists) {
  return Array.isArray(lists) && lists.length ? lists[0] : {};
}

function hasElements(array) {
  return array.length > 0;
}

function defaultWishList() {
  const { userName } = session.get();
  return {
    default: true,
    id: 'default',
    name: `Lista de ${userName}`,
    created: '',
  };
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_WISH_LISTS: {
      const userWishLists = hasElements(payload.wishLists) ? payload.wishLists : [defaultWishList()];
      const firstList = getFirstElement(userWishLists);
      const newSelectedList = generateNewList(userWishLists, firstList);
      return state
        .set(selectedList, firstList)
        .set(wishLists, newSelectedList);
    }
    case SELECT_WISH_LIST: {
      const { wishLists: userWishLists } = state.toJS();
      const newSelectedList = generateNewList(userWishLists, payload.selectedList);
      return state
        .set(selectedList, payload.selectedList)
        .set(wishLists, newSelectedList);
    }
    case CREATE_DEFAULT_WISH_LIST: {
      const { wishLists: userWishLists } = state.toJS();
      const created = moment().format('YYYY-MM-DD');
      const newWishList = { ...payload.wishList, created };
      const newSelectedList = userWishLists.map(wishList => (
        wishList.default
          ? { ...newWishList, selected: true }
          : wishList
      ));
      return state
        .set(selectedList, newWishList)
        .set(wishLists, newSelectedList);
    }
    case CREATE_WISH_LIST: {
      const { wishLists: userWishLists } = state.toJS();
      const created = moment().format('YYYY-MM-DD');
      const newWishList = { ...payload.wishList, created, selected: true };
      const newArrayList = userWishLists.map(list => ({ ...list, selected: false }));
      newArrayList.push(newWishList);
      return state
        .set(selectedList, newWishList)
        .set(wishLists, newArrayList);
    }
    case RENAME_WISH_LIST: {
      const { wishLists: userWishLists } = state.toJS();
      const newArrayList = userWishLists.map(list => (
        list.id === payload.wishList.id
          ? { ...list, name: payload.wishList.name }
          : list
      ));
      return state
        .set(selectedList, payload.wishList)
        .set(wishLists, newArrayList);
    }
    case TOGGLE_SWITCH_NEW_LIST_MODAL:
      return state
        .set(modalNewListToggle, !state.toJS().modalNewListToggle);
    case TOGGLE_SWITCH_RENAME_LIST_MODAL:
      return state
        .set(modalRenameListToggle, !state.toJS().modalRenameListToggle);
    case DELETE_WISH_LIST: {
      const filterValidLists = state.toJS().wishLists
        .filter(list => list.id !== payload.wishListId);
      const userWishLists = hasElements(filterValidLists) ? filterValidLists : [defaultWishList()];
      const firstList = getFirstElement(userWishLists);
      const newArrayList = generateNewList(userWishLists, firstList);
      return state
        .set(selectedList, firstList)
        .set(wishLists, newArrayList);
    }
    default:
      return state;
  }
}
