import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { callError } from '@compusoluciones-pdigitales/status-codes';

import apiRoutes from '../../apiRoutes';
import routes from '../../route-names';

import {
  ACCESS_TOKEN, GUEST_SESSION, USER_SESSION, OWNER_TOKEN_SESSION,
} from './constants';

const { login } = routes;

function getHeaders() {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookie.get(ACCESS_TOKEN)}`,
  };
}

export function decode(token) {
  try {
    return jwtDecode(token);
  } catch (error) {
    return { id: false };
  }
}

export function isInvalid(errorCode) {
  return errorCode === callError.general.INVALID_TOKEN
    || errorCode === callError.users.INVALID_TOKEN;
}

export function hasExpired(errorCode) {
  return errorCode === callError.general.TOKEN_EXPIRED;
}

export function isValidGuestSession(session) {
  return session && session !== '';
}

export function setCookies(accessToken, refreshToken, isGuest = false) {
  const isProductionMode = process.env.REACT_APP_ENV === 'production';
  const sessionType = isGuest ? GUEST_SESSION : USER_SESSION;
  cookie.set(ACCESS_TOKEN, accessToken, { secure: isProductionMode });
  cookie.set(sessionType, refreshToken, { secure: isProductionMode });
}

function setOwnerSessionCookie() {
  const accessToken = cookie.get(ACCESS_TOKEN);
  const isProductionMode = process.env.REACT_APP_ENV === 'production';
  cookie.set(OWNER_TOKEN_SESSION, accessToken, { secure: isProductionMode });
}

export function logout(saveLocation = true) {
  const isProductionMode = process.env.REACT_APP_ENV === 'production';
  cookie.remove(ACCESS_TOKEN, { secure: isProductionMode });
  cookie.remove(USER_SESSION, { secure: isProductionMode });
  cookie.remove(GUEST_SESSION, { secure: isProductionMode });
  const { location } = window;
  const loginRedirect = `from=${location.pathname}`;
  location.replace(`${location.protocol}//${location.hostname}:${location.port}${login.route}${saveLocation ? loginRedirect : ''}`);
}

export function get() {
  const token = cookie.get(ACCESS_TOKEN);
  const userSession = cookie.get(USER_SESSION);
  const guestSession = cookie.get(GUEST_SESSION);
  const decodedToken = decode(token);
  const isAuthenticated = Boolean(decodedToken.id);
  const completeName = `${decodedToken.name} ${decodedToken.surname}`;
  return {
    refreshToken: isValidGuestSession(guestSession) ? guestSession : userSession,
    isGuest: isValidGuestSession(guestSession),
    userId: decodedToken.id,
    userName: decodedToken.name,
    completeName,
    customerId: decodedToken.id ? decodedToken.customer.id : false,
    customerName: decodedToken.id ? decodedToken.customer.name : false,
    access: decodedToken.access,
    acceptedTerms: decodedToken.terms,
    profileComplete: decodedToken.profile,
    isAuthenticated,
    token,
  };
}

export async function renewAccessToken() {
  const userSession = get();
  try {
    const { accessToken } = await axios.request({
      url: apiRoutes.refreshToken(),
      method: 'POST',
      data: {
        userId: userSession.userId,
        customerId: userSession.customerId,
        refreshToken: userSession.refreshToken,
      },
    });
    setCookies(accessToken, userSession.refreshToken, userSession.isGuest);
    return accessToken;
  } catch (error) {
    logout();
    return false;
  }
}

export async function getGuestSession(userId, customerId) {
  const response = await axios.request({
    url: apiRoutes.guestSession(),
    method: 'POST',
    headers: getHeaders(),
    data: {
      userId,
      customerId,
    },
  });
  setOwnerSessionCookie();
  setCookies(response.accessToken, response.refreshToken, true);
}

export async function guestlogout() {
  const isProductionMode = process.env.REACT_APP_ENV === 'production';
  const accessToken = cookie.get(OWNER_TOKEN_SESSION, { secure: isProductionMode });
  cookie.set(ACCESS_TOKEN, accessToken, { secure: isProductionMode });
  cookie.remove(GUEST_SESSION, { secure: isProductionMode });
  cookie.remove(OWNER_TOKEN_SESSION, { secure: isProductionMode });
  await renewAccessToken();
  const { location } = window;
  location.replace(`${location.protocol}//${location.hostname}:${location.port}/directorio/clientes`);
}
