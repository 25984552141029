import React, { useState } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Header, Dropdown, Icon, Responsive, Checkbox, Label, Popup, Card,
} from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const FinalUser = props => {
  const {
    t, checkoutData, leaseTypesArray, currentPaymentMethod, paymentMethods, handleLeaseTypeChange,
    handlePaymentMethodChange, handleOpenModalByReferenceNumbers, cardBrand, handleCardTypeChange,
    currentLeaseType, hpeIquoteId, currencyOptions, handleCurrencyChange, currentCurrency, messageConfirmCurrency,
  } = props;

  const [copy, setCopy] = useState('Copiar');

  const handleCopy = () => {
    setCopy('Copiado');
    setTimeout(() => {
      setCopy('Copiar');
    }, 2000);
  };

  return (
    <div className="padding-y">
      {checkoutData.map(data => (
        <Grid padded>
          <Grid.Row className="no-padding-bottom">
            <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5} textAlign="left">
              <Header as="h5" color="black">
                {t('checkout.finalUser')}
              </Header>
              <Responsive maxWidth={425}>
                <br />
              </Responsive>
            </Grid.Column>
            <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={11} textAlign="right">
              <Header as="h5" color="blue">
                {data.endUserName}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="no-padding-bottom">
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="left">
              <Header as="h5" color="black">
                {t('checkout.paymentMethod')}
              </Header>
            </Grid.Column>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="right">
              <Grid padded>
                <Grid.Row className="no-padding">
                  <Grid.Column
                    largeScreen={currentPaymentMethod === 6 ? 14 : 16}
                    computer={currentPaymentMethod === 6 ? 14 : 16}
                    tablet={currentPaymentMethod === 6 ? 14 : 16}
                    mobile={currentPaymentMethod === 6 ? 14 : 16}
                    className="no-padding"
                  >
                    <Dropdown
                      floating
                      defaultValue={currentPaymentMethod}
                      placeholder={t('checkout.selectPaymentMethod')}
                      options={paymentMethods}
                      onChange={handlePaymentMethodChange}
                      search
                      selection
                      fluid
                    />
                  </Grid.Column>
                  {currentPaymentMethod === 6 ? (
                    <Grid.Column
                      largeScreen={currentPaymentMethod === 6 ? 2 : 0}
                      computer={currentPaymentMethod === 6 ? 2 : 0}
                      tablet={currentPaymentMethod === 6 ? 2 : 0}
                      mobile={currentPaymentMethod === 6 ? 2 : 0}
                      className="no-padding"
                    >
                      <Icon name="info circle" size="large" onClick={handleOpenModalByReferenceNumbers} />
                    </Grid.Column>
                  ) : ''}
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          {currentPaymentMethod === 2 ? (
            <Grid.Row>
              <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={7} textAlign="left">
                <Header as="h5" color="black">{t('checkout.cardType')}</Header>
              </Grid.Column>
              <Grid.Column largeSreen={9} computer={9} tablet={9} mobile={9} textAlign="right">
                <Checkbox
                  radio
                  label="Visa / MasterCard"
                  name="cardBrandGroup"
                  value="VisaMasterCard"
                  checked={cardBrand === 'Visa'}
                  onChange={handleCardTypeChange}
                />
                <Responsive maxWidth={768}>
                  <br />
                </Responsive>
                <Checkbox
                  radio
                  label="American Express"
                  name="cardBrandGroup"
                  value="American Express"
                  checked={cardBrand === 'American Express'}
                  onChange={handleCardTypeChange}
                />
              </Grid.Column>
            </Grid.Row>
          ) : ''}
          {currentPaymentMethod === 6 ? (
            <>
              <Grid.Row className="no-padding-bottom">
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <p style={{ display: 'inline' }}>
                    {t('checkout.reservationPolicy')}
                  </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="left">
                  <Header color="black" as="h5">Referencias Bancarias:</Header>
                </Grid.Column>
                <Card
                  style={{
                    width: '100%',
                    height: 'auto',
                    boxShadow: '1px',
                    maxWidth: '310px',
                    marginBottom: '5px',
                    marginTop: '10px',
                  }}
                  centered
                >
                  <Card.Content>
                    <Grid>
                      <Grid.Column mobile={16} tablet={13} computer={13}>
                        <p style={{
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          textAlign: 'left',
                        }}
                        >
                          {t('checkout.accountBanamex')}
                          &nbsp;
                          {(t('checkout.banamexNumber'))}
                        </p>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={3} computer={3} style={{ backgroundColor: '#f0f0f0' }}>
                        <Popup
                          className="inverted tiny"
                          content={copy}
                          trigger={(
                            <CopyToClipboard
                              text={(t('checkout.banamexNumber'))}
                              onCopy={handleCopy}
                            >
                              <Icon
                                className="copy link icon"
                                onMouseOver={() => setCopy('Copiar')}
                              />
                            </CopyToClipboard>
                          )}
                        />
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </Card>
                <Card
                  style={{
                    width: '100%',
                    height: 'auto',
                    boxShadow: '1px',
                    maxWidth: '310px',
                    marginTop: '10px',
                  }}
                  centered
                >
                  <Card.Content>
                    <Grid>
                      <Grid.Column mobile={16} tablet={13} computer={13}>
                        <p style={{
                          wordWrap: 'break-word',
                          overflowWrap: 'break-word',
                          textAlign: 'left',
                        }}
                        >
                          {t('checkout.accountBancomer')}
                          &nbsp;
                          {(t('checkout.bancomerNumber'))}
                        </p>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={3} computer={3} style={{ backgroundColor: '#f0f0f0' }}>
                        <Popup
                          className="inverted tiny"
                          content={copy}
                          trigger={(
                            <CopyToClipboard
                              text={(t('checkout.bancomerNumber'))}
                              onCopy={handleCopy}
                            >
                              <Icon
                                className="copy link icon"
                                onMouseOver={() => setCopy('Copiar')}
                              />
                            </CopyToClipboard>
                          )}
                        />
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                </Card>
              </Grid.Row>
            </>
          ) : ''}
          {currentPaymentMethod === 9 ? (
            <Grid.Row className="no-padding-bottom">
              <Grid.Column largeScreen={6} computer={6} tablet={16} mobile={16} textAlign="left">
                <Header as="h5" color="black">
                  Tipo de Financiamiento:
                </Header>
              </Grid.Column>
              <Grid.Column largeScreen={10} computer={10} tablet={16} mobile={16} textAlign="right">
                <Dropdown
                  compact
                  floating
                  placeholder="Financiamiento"
                  options={leaseTypesArray}
                  onChange={handleLeaseTypeChange}
                  value={currentLeaseType}
                  search
                  selection
                />
              </Grid.Column>
            </Grid.Row>
          ) : ''}
          {
            (hpeIquoteId <= 0) ? (
              <Grid.Row className="no-padding-bottom">
                <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={8} textAlign="left">
                  <Header as="h5" color="black">
                    {t('checkout.currency')}
                  </Header>
                </Grid.Column>
                <Grid.Column largeScreen={6} computer={6} tablet={10} mobile={8} textAlign="right">
                  <Dropdown
                    compact
                    floating
                    placeholder="Moneda"
                    defaultValue={data.currency}
                    options={currencyOptions}
                    onChange={handleCurrencyChange}
                    value={currentCurrency}
                    search
                    selection
                  />
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row className="no-padding-bottom">
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Header as="h4" color="black">
                    {t('checkout.iquotePaymentMethod')}
                    <Header as="h5" style={{ display: 'inline' }} />
                  </Header>
                </Grid.Column>
              </Grid.Row>
            )
          }
          {
            (messageConfirmCurrency) ? (
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="right">
                  <Label basic color="blue" className="pointing-currency">
                    {t('checkout.confirmCurrency')}
                  </Label>
                </Grid.Column>
              </Grid.Row>
            ) : ''
          }
          <Grid.Row>
            <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
              <Header as="h5" color="black">
                {t('checkout.comments')}
              </Header>
              <Responsive maxWidth={425}>
                <br />
              </Responsive>
            </Grid.Column>
            <Grid.Column largeScreen={10} computer={10} tablet={10} mobile={10} textAlign="right">
              {data.comments
                ? <p style={{ display: 'inline' }}>{data.comments}</p>
                : 's/c'}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
    </div>
  );
};

FinalUser.propTypes = {
  currentPaymentMethod: PropTypes.number,
  currentCurrency: PropTypes.number,
  currentLeaseType: PropTypes.string,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  leaseTypesArray: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  handlePaymentMethodChange: PropTypes.func,
  handleCurrencyChange: PropTypes.func,
  handleLeaseTypeChange: PropTypes.func,
  handleOpenModalByReferenceNumbers: PropTypes.func,
  handleCardTypeChange: PropTypes.func,
  cardBrand: PropTypes.string,
  hpeIquoteId: PropTypes.number,
  messageConfirmCurrency: PropTypes.bool,
};

FinalUser.defaultProps = {
  currentPaymentMethod: 1,
  currentCurrency: 1,
  currentLeaseType: 'FL',
  checkoutData: [],
  paymentMethods: [],
  currencyOptions: [],
  leaseTypesArray: [],
  t: () => {},
  handlePaymentMethodChange: () => {},
  handleCurrencyChange: () => {},
  handleLeaseTypeChange: () => {},
  hpeIquoteId: 0,
  handleOpenModalByReferenceNumbers: () => {},
  handleCardTypeChange: () => {},
  cardBrand: PropTypes.string,
  messageConfirmCurrency: false,
};

export default translate('common', { wait: true })(FinalUser);
