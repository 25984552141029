import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Button, Tab } from 'semantic-ui-react';

import EditContentField from './edit-content-field';
import EditContentImages from './edit-content-images';

const translatePath = 'productConfig.menus.editContent.tabs';

function ModalEditContentSource(props) {
  const {
    t,
    open,
    onClose,
    currentContent,
    icecatContent,
    erpContent,
    onSaveItemContent,
    loading,
  } = props;

  const [newContent, setNewContent] = useState({});

  useEffect(() => {
    setNewContent(currentContent);
  }, [currentContent]);

  const handleOnChangeContent = (contentName, content) => {
    setNewContent({
      ...newContent,
      [contentName]: content,
    });
  };

  const handleOnChangeImages = (images, imagetype) => {
    setNewContent({
      ...newContent,
      [imagetype]: images,
    });
  };

  const handleOnCancel = () => {
    onClose(false);
    setNewContent(currentContent);
  };

  const panes = [
    {
      menuItem: t(`${translatePath}.info.name`),
      render: () => (
        <Tab.Pane>
          <EditContentField
            contentName="title"
            currentContent={newContent}
            icecatValue={icecatContent.title || ''}
            erpValue={erpContent.title || ''}
            onChangeContent={handleOnChangeContent}
          />
          <EditContentField
            contentName="family"
            currentContent={newContent}
            icecatValue={icecatContent.family || ''}
            erpValue={erpContent.family || ''}
            onChangeContent={handleOnChangeContent}
          />
          <EditContentField
            contentName="category"
            currentContent={newContent}
            icecatValue={icecatContent.category || ''}
            erpValue={erpContent.category || ''}
            onChangeContent={handleOnChangeContent}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t(`${translatePath}.images.name`),
      render: () => (
        <Tab.Pane>
          <EditContentImages
            isImageType
            currentImages={newContent.images}
            icecatImages={icecatContent.images}
            erpImages={erpContent.images}
            onChangeContent={handleOnChangeImages}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: t(`${translatePath}.logos.name`),
      render: () => (
        <Tab.Pane>
          <EditContentImages
            currentImages={newContent.featuredLogos}
            icecatImages={icecatContent.featuredLogos}
            erpImages={erpContent.featuredLogos}
            onChangeContent={handleOnChangeImages}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <Modal
      centered
      closeIcon
      size="large"
      open={open}
      onClose={handleOnCancel}
    >
      <Modal.Header>{t(`${translatePath}.title`)}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Tab
            menu={{ pointing: true }}
            panes={panes}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={t(`${translatePath}.close`)}
          onClick={handleOnCancel}
        />
        <Button
          primary
          loading={loading}
          content={t(`${translatePath}.save`)}
          onClick={() => onSaveItemContent(newContent)}
        />
      </Modal.Actions>
    </Modal>
  );
}

const ItemContentSchema = PropTypes.shape({
  title: PropTypes.string,
  category: PropTypes.string,
  family: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    low: PropTypes.string.isRequired,
  })),
  featuredLogos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    low: PropTypes.string.isRequired,
  })),
});

ModalEditContentSource.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveItemContent: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  currentContent: PropTypes.shape({
    title: PropTypes.shape({
      source: PropTypes.string,
      value: PropTypes.string,
    }),
    category: PropTypes.shape({
      source: PropTypes.string,
      value: PropTypes.string,
    }),
    family: PropTypes.shape({
      source: PropTypes.string,
      value: PropTypes.string,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      source: PropTypes.string.isRequired,
      value: PropTypes.shape({
        id: PropTypes.string.isRequired,
        low: PropTypes.string.isRequired,
      }).isRequired,
    })),
  }),
  icecatContent: ItemContentSchema,
  erpContent: ItemContentSchema,
};

ModalEditContentSource.defaultProps = {
  currentContent: {
    title: {
      source: '',
      value: '',
    },
    category: {
      source: '',
      value: '',
    },
    family: {
      source: '',
      value: '',
    },
  },
  icecatContent: {
    title: '',
    category: '',
    family: '',
    images: [],
  },
  erpContent: {
    title: '',
    category: '',
    family: '',
    images: [],
  },
};

export default translate('common', { wait: true })(ModalEditContentSource);
