/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

function getRoundedPrice(price) {
  return Math.ceil(price);
}

function setMinPrice(values, range) {
  const [minRange, maxRange] = range;
  const [minValue] = values;
  let minPrice;
  if (minValue >= minRange && minValue < maxRange) {
    minPrice = minValue;
  }
  if (minValue < minRange) {
    minPrice = minRange;
  }
  if (minValue >= maxRange) {
    minPrice = maxRange - 1;
  }
  return minPrice;
}

function setMaxPrice(values, range) {
  const [minRange, maxRange] = range;
  const [, maxValue] = values;
  let maxPrice;
  if (maxValue <= maxRange && maxValue > minRange) {
    maxPrice = maxValue;
  }
  if (maxValue > maxRange) {
    maxPrice = maxRange;
  }
  if (maxValue <= minRange) {
    maxPrice = minRange + 1;
  }
  return maxPrice;
}

function fixLimits(values, range) {
  const minPrice = setMinPrice(values, range);
  const maxPrice = setMaxPrice(values, range);
  return [getRoundedPrice(minPrice), getRoundedPrice(maxPrice)];
}

function setValueFormat(value) {
  const numberFormat = new Intl.NumberFormat('es-MX', {
    currency: 'MXN',
  });

  return numberFormat.format(Number(value));
}

function clearValueFormat(value) {
  return String(value).replace(/,/g, '');
}

function getInitialValues(initValues, range) {
  const [minPrice, maxPrice] = fixLimits(initValues, range);
  const newMinValue = getRoundedPrice(minPrice);
  const newMaxValue = getRoundedPrice(maxPrice);
  return [newMinValue, newMaxValue];
}

function getInitialFormattedValues(initValues, range) {
  const [minPrice, maxPrice] = getInitialValues(initValues, range);
  const newMinValue = setValueFormat(minPrice);
  const newMaxValue = setValueFormat(maxPrice);
  return [newMinValue, newMaxValue];
}
function Slider({ onChange, range, initValues }) {
  const [min, max] = range;
  const [[minValue, maxValue], setValues] = useState(getInitialValues(initValues, range));
  const [[minFormatted, maxFormatted], setFormattedValues] = useState(getInitialFormattedValues(initValues, range));

  const handleChange = rangeValue => {
    setValues(rangeValue);
    setFormattedValues([
      setValueFormat(rangeValue[0]),
      setValueFormat(rangeValue[1]),
    ]);
  };

  const handleFinalChange = rangeValue => {
    const [minPrice, maxPrice] = fixLimits(rangeValue, range);
    onChange({ minPrice, maxPrice });
  };

  const handleMinValueChange = ({ target }) => {
    const newMinValue = Number(clearValueFormat(target.value));
    if (!Number.isNaN(newMinValue)) {
      setFormattedValues([
        setValueFormat(newMinValue),
        setValueFormat(maxValue),
      ]);
      if (newMinValue >= min && newMinValue < maxValue) {
        setValues([newMinValue, maxValue]);
      }
    }
  };

  const handleMaxValueChange = ({ target }) => {
    const newMaxValue = Number(clearValueFormat(target.value));
    if (!Number.isNaN(newMaxValue)) {
      setFormattedValues([
        setValueFormat(minValue),
        setValueFormat(newMaxValue),
      ]);
      if (newMaxValue <= max && newMaxValue > minValue) {
        setValues([minValue, newMaxValue]);
      }
    }
  };

  const handleOnFocus = () => {
    setFormattedValues([minValue, maxValue]);
  };

  const handleOnBlur = () => {
    setFormattedValues([
      setValueFormat(minValue),
      setValueFormat(maxValue),
    ]);
  };

  const handleEnterPress = ({ keyCode }) => {
    if (keyCode === 13) {
      handleOnBlur();
      handleFinalChange([minValue, maxValue]);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: '1rem',
      }}
    >
      <Range
        step={getRoundedPrice(max / 100)}
        min={getRoundedPrice(min)}
        max={getRoundedPrice(max)}
        values={[minValue, maxValue]}
        onChange={handleChange}
        onFinalChange={handleFinalChange}
        renderTrack={({ props: rtProps, children }) => (
          <div
            {...rtProps}
            style={{
              ...rtProps.style,
              height: '6px',
              width: '100%',
              background: getTrackBackground({
                values: [minValue, maxValue],
                colors: ['#ccc', '#00549F', '#ccc'],
                min,
                max,
              }),
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props: rProps, isDragged }) => (
          <div
            {...rProps}
            style={{
              ...rProps.style,
              height: '30px',
              width: '30px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              style={{
                height: '10px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC',
              }}
            />
          </div>
        )}
      />

      <Grid style={{ marginTop: '1rem' }}>
        <Grid.Column width={8}>
          <TextField
            fullWidth
            label="Mínimo"
            type="text"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={minFormatted}
            onKeyDown={handleEnterPress}
            onChange={handleMinValueChange}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <TextField
            fullWidth
            label="Máximo"
            type="text"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={maxFormatted}
            onChange={handleMaxValueChange}
            onKeyDown={handleEnterPress}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
}

Slider.defaultProps = {
  initValues: [0, 0],
};

Slider.propTypes = {
  range: PropTypes.arrayOf(PropTypes.number).isRequired,
  initValues: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
};

export default Slider;
