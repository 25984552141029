export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};

export const sheetHeaders = {
  sku: { header: 'SKU', width: 40 },
  name: { header: 'Nombre', width: 60 },
  maker: { header: 'UEN', width: 30 },
  totalSold: { header: 'Cantidad vendido', width: 20 },
};

export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
