import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import moment from 'moment';
import cookie from 'js-cookie';
import {
  Grid, Header, Button, Confirm, TextArea, Modal, Form, Radio, Icon, Dropdown, Dimmer, Loader, Divider,
} from 'semantic-ui-react';
import Toast from '../common/components/toast';
import discountsService from './services';
import SiclikTable from '../common/components/table';
import * as session from '../common/sessions';
import { TextField } from '../common/components/materials';

const AuthorizationComponent = props => {
  const {
    comment, commentError, t, handleChangeComment, value, handleChange, reviewerName, resolutionDate, disabled,
  } = props;
  return (
    <Grid>
      <Grid.Row>
        {reviewerName
          ? (
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
              {`${t('discounts.reviewer')} ${reviewerName}`}
            </Grid.Column>
          )
          : null}
        {resolutionDate
          ? (
            <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
              {`${t('discounts.reviewDate')} ${moment(resolutionDate).format('DD-MM-YYYY')}`}
            </Grid.Column>
          )
          : null}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
          <Radio
            label={t('discounts.authorize')}
            name="radioGroup"
            value={1}
            checked={value === 1}
            onChange={handleChange}
            disabled={disabled}
          />
        </Grid.Column>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={8}>
          <Radio
            label={t('discounts.reject')}
            name="radioGroup"
            value={0}
            checked={value === 0}
            onChange={handleChange}
            disabled={disabled}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {!disabled
          ? (
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Form>
                <TextArea
                  value={comment}
                  error={commentError}
                  onChange={handleChangeComment}
                  placeholder={t('discounts.comments')}
                />
              </Form>
            </Grid.Column>
          )
          : null}
        {disabled
          ? (
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              {`${t('discounts.comment')} ${comment}`}
            </Grid.Column>
          )
          : null}
      </Grid.Row>
    </Grid>
  );
};

AuthorizationComponent.propTypes = {
  comment: PropTypes.string.isRequired,
  commentError: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleChangeComment: PropTypes.func.isRequired,
  resolutionDate: PropTypes.string.isRequired,
  reviewerName: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

class ViewDiscounts extends Component {
  constructor(props) {
    super(props);
    const { customerId } = session.get();
    this.state = {
      value: '',
      filter: '',
      filterError: '',
      statusArray: [
        { key: 1, text: 'Pendiente', value: 3 },
        { key: 2, text: 'Autorizado', value: 1 },
        { key: 3, text: 'Rechazado', value: 2 },
      ],
      open: false,
      openAuthorization: false,
      discountId: '',
      reviewerName: '',
      resolutionDate: '',
      idResolution: '',
      hasPermission: false,
      loading: false,
      accessDenied: (customerId === 'G000000'),
      headers: [
        { key: 'id', isKey: true },
        { key: 'coupon', name: 'Código' },
        { key: 'description', name: 'Descripción' },
        { key: 'startDate', name: 'Fecha Inicio' },
        { key: 'endDate', name: 'Fecha Expiración' },
        { key: 'quantity', name: 'Cantidad' },
        { key: 'cumulative', name: 'Acumulable' },
        {
          key: 'authorized',
          name: 'Autorizado',
          format: (cell, row) => {
            let cellFormat = 'Pendiente';
            if (row.idResolution && cell === 1) cellFormat = <Icon color="green" name="checkmark" size="large" />;
            if (row.idResolution && cell === 0) cellFormat = <Icon name="times" size="large" />;
            return cellFormat;
          },
        },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <Grid>
              <Grid.Row>
                <Button color="blue" onClick={() => this.handleAuthorization(row)}>Revisar</Button>
              </Grid.Row>
              <Grid.Row>
                <Button color="blue" icon="pencil alternate" onClick={() => this.handleEdit(row)} />
                <Button color="blue" icon="trash alternate" onClick={() => this.selectTodelete(row)} />
              </Grid.Row>
            </Grid>
          ),
        },
      ],
      optionsMaker: [],
      optionsUen: [],
      visibleUen: false,
    };
  }

  componentDidMount() {
    this.handleGetMakers();
    this.handleGetInitialData();
  }

  handleGetInitialData = () => {
    const { userId } = session.get();
    this.handleGetUsers();
    this.checkAccess();
    this.setState({ userId });
    this.handleGetInformation(userId, 0);
  }

  checkAccess = async () => {
    const { t } = this.props;
    const { userId: id } = session.get();
    let { hasPermission } = this.state;
    const agentsList = await discountsService.getUsersHasPermission()
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        if (messageError) Toast(messageError, 'error');
      });
    if (agentsList && agentsList.users) hasPermission = agentsList.users.some(agent => id === agent.id);
    this.setState({ hasPermission });
  };

  handleGetInformation = (userId, getAll) => {
    cookie.set('element', 'Descuento', { expires: 1 });
    this.setState({ loading: true });
    discountsService.getDiscountsList(userId, getAll).then(response => {
      this.setState({
        informationDiscounts: response.couponList,
        filteredInfoDiscounts: response.couponList,
        loading: false,
      });
    })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        if (error.code !== 1005 && !error.code !== 1000) Toast(messageError, 'error');
        this.setState({ loading: false });
      });
  }

  handleGetByUen = uenId => {
    cookie.set('element', 'Descuento', { expires: 1 });
    discountsService.getCouponsByUen(uenId).then(response => {
      this.setState({
        informationDiscounts: response.couponList,
        filteredInfoDiscounts: response.couponList,
      });
    })
      .catch(error => {
        const { t } = this.props;
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        if (error.code !== 1005 && !error.code !== 1000) Toast(messageError, 'error');

        this.setState({
          informationDiscounts: [],
          filteredInfoDiscounts: [],
          userId: '',
        });
      });
  }

  hanldeDeleteDiscountById = () => {
    const { t } = this.props;
    const { discountId } = this.state;
    discountsService.deleteDiscount(discountId).then(response => {
      if (response) this.discountDeleted();
    }).catch(error => {
      let messageError = '';
      if (!error.code) {
        messageError = t('error-codes.default');
      } else {
        messageError = t(`error-codes.${error.code}`);
      }
      Toast(messageError, 'error');
    });
  }

  handleAuthorization = discount => this.setState({
    openAuthorization: true,
    value: discount.authorized,
    reviewerName: discount.reviewerName,
    resolutionDate: discount.resolutionDate,
    comment: discount.comment,
    idResolution: discount.idResolution,
    discountId: discount.id,
  });

  validations = event => {
    const { t } = this.props;
    const { informationDiscounts } = this.state;
    const maxLength = 20;
    let mistakes;
    const { value: filter } = event.target;
    if ((filter.trim()).length > maxLength) {
      this.setState({ filterError: t('discounts.errors.toLong') });
      mistakes = true;
      return;
    }
    if (mistakes !== true) {
      this.setState({ filter, filterError: '' });
      this.handleFilterChange(filter, mistakes, informationDiscounts);
    }
  }

  handleFilterChange = (filter, mistakes, informationDiscounts) => {
    let foundData = [];
    if (mistakes !== true) {
      if (filter && informationDiscounts && informationDiscounts.length > 0) {
        foundData = informationDiscounts.filter(filterInfo => (filterInfo.coupon && filterInfo.coupon.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
          || (filterInfo.description && (filterInfo.description.toLowerCase().indexOf(filter.toLowerCase()) >= 0))
          || (filterInfo.dateStart && (filterInfo.dateStart.indexOf(filter) >= 0))
          || (filterInfo.cumulative && (filterInfo.cumulative.toLowerCase().indexOf(filter.toLowerCase()) >= 0))
          || (filterInfo.quantity.length > 0 && (filterInfo.quantity.indexOf(filter) >= 0))
          || (filterInfo.dateEnd && (filterInfo.dateEnd.indexOf(filter) >= 0)));
      } else {
        foundData = informationDiscounts;
      }
      this.setState({ filteredInfoDiscounts: foundData });
    }
  }

  cancel = () => this.setState({ open: false })

  discountDeleted = () => {
    const { t } = this.props;
    const { filteredInfoDiscounts, discountId } = this.state;
    filteredInfoDiscounts.map((discount, index) => {
      if (discount.id === discountId) filteredInfoDiscounts.splice(index, 1);
      return discount;
    });
    Toast(t('discounts.successDelete'), 'success');
    this.setState({ filteredInfoDiscounts, open: false });
  }

  selectTodelete = discountData => {
    this.setState({
      discountToDelete: discountData.coupon,
      discountId: discountData.id,
      open: true,
    });
  }

  handleAdd = () => {
    const { changeView, discountId } = this.props;
    changeView(true);
    discountId('');
  }

  handleEdit = discount => {
    const { changeView, discountId } = this.props;
    changeView(true);
    discountId(discount.id);
  }

  handleChangeComment = (e, { value }) => this.setState({ comment: value });

  handleChange = (e, { value }) => this.setState({ value })

  handleResolveAutorization = () => {
    const { t } = this.props;
    const { comment, value } = this.state;
    if (comment && value !== '') {
      this.handleAddReview();
    } else {
      Toast(t('discounts.error.incompleteReview'), 'error');
    }
  }

  handleAddReview = () => {
    const { comment, value, discountId } = this.state;
    const { t } = this.props;
    const reviewData = { couponId: discountId, approve: value, comment };
    discountsService.addReview(reviewData)
      .then(resolution => {
        if (resolution.id) this.handleSuccessReview(resolution.id);
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  handleSuccessReview = resolutionId => {
    const {
      value, discountId, filter, comment,
    } = this.state;
    let { informationDiscounts } = this.state;
    const { t } = this.props;
    informationDiscounts = informationDiscounts.map(discount => {
      let formatedDiscount = {};
      if (discount.id === discountId) {
        formatedDiscount = {
          ...discount,
          authorized: value,
          idResolution: resolutionId,
          comment,
        };
      } else {
        formatedDiscount = discount;
      }
      return formatedDiscount;
    });
    this.setState({
      informationDiscounts, openAuthorization: false, comment: '', value: '', commentError: '', discountId: '',
    });
    this.handleFilterChange(filter, false, informationDiscounts);
    Toast(t('discounts.successReview'), 'success');
  }

  handleChangeStatus = (e, { value }) => {
    this.setState({ status: value });
    this.filterGeneral(value, 2);
  }

  handleFilterByStatus = (status, informationDiscounts) => {
    let foundData = [];
    if (status === 1 && informationDiscounts && informationDiscounts.length > 0) {
      foundData = informationDiscounts.filter(filterInfo => (filterInfo.authorized && filterInfo.authorized === 1));
    } else if (status === 2 && informationDiscounts && informationDiscounts.length > 0) {
      foundData = informationDiscounts.filter(filterInfo => (filterInfo.idResolution && filterInfo.authorized === 0));
    } else if (status === 3 && informationDiscounts && informationDiscounts.length > 0) {
      foundData = informationDiscounts.filter(filterInfo => (!filterInfo.idResolution && filterInfo.authorized === 0));
    } else {
      foundData = informationDiscounts;
    }
    return foundData;
  }

  filterGeneral = (value, typeFilter) => {
    const { informationDiscounts } = this.state;
    let filteredInfoDiscounts = JSON.parse(JSON.stringify(informationDiscounts));
    let { maker, status } = this.state;
    if (typeFilter === 1) maker = value;
    if (typeFilter === 2) status = value;
    if (maker) filteredInfoDiscounts = this.handleFilterByMaker(maker, filteredInfoDiscounts);
    if (status) filteredInfoDiscounts = this.handleFilterByStatus(status, filteredInfoDiscounts);
    this.setState({ filteredInfoDiscounts });
  }

  handleGetUnique = array => {
    const hash = {};
    const filteredArray = array.filter(current => {
      const exists = !hash[current.key];
      hash[current.key] = true;
      return exists;
    });
    return filteredArray;
  }

  handleGetMakers = async () => {
    const { t } = this.props;
    let messageError = '';
    let optionsMaker = [];
    let optionsUen = [];
    const objectMaker = await discountsService.getAllMakers()
      .catch(error => {
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
    if (objectMaker && objectMaker.makers) {
      optionsMaker = objectMaker.makers.map(maker => {
        const makerFormated = { key: maker.id, text: maker.name, value: maker.id };
        return makerFormated;
      });
      optionsUen = objectMaker.makers.map(maker => {
        const uenFormated = { key: maker.uenId, text: maker.uen, value: maker.uenId };
        return uenFormated;
      });
      const filteredUen = this.handleGetUnique(optionsUen);
      this.setState({ optionsMaker, optionsUen: filteredUen });
    }
  }

  handleChangeMaker = (e, { value }) => {
    this.setState({ maker: value });
    this.filterGeneral(value, 1);
  }

  handleChangeUen = (e, { value }) => {
    this.setState({ uenId: value });
    this.handleGetByUen(value);
  }

  handleFilterByMaker = (maker, informationDiscounts) => {
    let foundData = [];
    if (maker && informationDiscounts && informationDiscounts.length > 0) {
      foundData = informationDiscounts.filter(filterInfo => (filterInfo.makerId === maker));
    }
    return foundData;
  }

  handleChangeUser = (e, { value }) => {
    this.setState({ userId: value, visibleUen: value === 'uen' });
    if (value !== 'uen') {
      if (value) {
        this.handleGetInformation(value, 0);
      } else {
        this.handleGetInformation(value, 1);
      }
    }
  }

  handleGetUsers = () => {
    const { t } = this.props;
    const arrayUsers = [];
    const { customerId } = session.get();
    discountsService.getUsers(customerId)
      .then(response => {
        response.users.map(user => {
          if (/* user.confirmedAccount === 1 && */ user.name && user.name.length > 1) {
            arrayUsers.push({ key: user.id, text: `${user.name} ${user.surname}`, value: user.id });
          }
          return user;
        });
        arrayUsers.push(
          { key: 'uen', text: t('discounts.uen'), value: 'uen' },
          { key: 0, text: t('discounts.allDiscounts'), value: 0 },
        );
        this.setState({ arrayUsers });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) {
          messageError = t('error-codes.default');
        } else {
          messageError = t(`error-codes.${error.code}`);
        }
        Toast(messageError, 'error');
      });
  }

  render() {
    const { t } = this.props;
    const {
      filter, filterError, headers, filteredInfoDiscounts, discountToDelete, open, accessDenied, openAuthorization,
      comment, commentError, value, reviewerName, resolutionDate, idResolution, statusArray, optionsMaker, maker,
      makerError, arrayUsers, userId, hasPermission, uenId, optionsUen, loading, visibleUen,
    } = this.state;
    return (
      accessDenied
        ? (
          <Grid container>
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <Header className="titles-menu" color="blue">{t('discounts.coupons')}</Header>
              </Grid.Column>
            </Grid.Row>
            <Divider className="margin-divider" />
            <Grid.Row>
              <Grid.Column width={16} textAlign="center">
                <p>{t('discounts.couponsDescription')}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {arrayUsers && arrayUsers.length > 0
                ? (
                  <Grid.Column largeScreen={4} computer={5} tablet={4} mobile={8}>
                    <Dropdown
                      options={arrayUsers}
                      placeholder={t('discounts.selectUser')}
                      onChange={this.handleChangeUser}
                      value={userId}
                      search
                      selection
                    />
                  </Grid.Column>
                )
                : null}
              {visibleUen
                ? (
                  <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={8}>
                    <Dropdown
                      options={optionsUen}
                      placeholder={t('UEN')}
                      onChange={this.handleChangeUen}
                      value={uenId}
                      search
                      selection
                    />
                  </Grid.Column>
                )
                : false}
            </Grid.Row>
            {loading
              ? (
                <Grid.Row>
                  <Dimmer active inverted><Loader active size="big" /></Dimmer>
                </Grid.Row>
              )
              : null}
            { !loading && filteredInfoDiscounts && filteredInfoDiscounts.length > 5
              ? (
                <Grid.Row>
                  <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                    <TextField
                      fullWidth
                      maxLength={30}
                      value={filter || ''}
                      error={filterError || ''}
                      label={t('discounts.filter')}
                      onChange={this.validations}
                    />
                  </Grid.Column>
                  <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                    <Dropdown
                      placeholder={t('discounts.maker')}
                      options={optionsMaker}
                      value={maker}
                      error={makerError}
                      onChange={this.handleChangeMaker}
                      fluid
                      selection
                      search
                      clearable
                    />
                  </Grid.Column>
                  <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                    <Dropdown
                      placeholder={t('discounts.status')}
                      selection
                      options={statusArray}
                      onChange={this.handleChangeStatus}
                      search
                      clearable
                    />
                  </Grid.Column>
                </Grid.Row>
              )
              : null}
            {!loading
              ? (
                <Grid.Row>
                  <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
                    <Button color="blue" onClick={this.handleAdd}>{t('add')}</Button>
                  </Grid.Column>
                </Grid.Row>
              )
              : null}
            {!loading
              ? (
                <Grid.Row style={{ overflowX: 'auto' }}>
                  <SiclikTable
                    selectable
                    data={filteredInfoDiscounts}
                    headers={headers}
                    pageSize={10}
                    singleSelection
                  />
                </Grid.Row>
              )
              : null}
            <Grid.Row>
              <Confirm
                open={open}
                header={t('discounts.confirmDelete')}
                content={discountToDelete}
                cancelButton={t('cancel')}
                confirmButton={t('yes')}
                onCancel={this.cancel}
                onConfirm={this.hanldeDeleteDiscountById}
              />
            </Grid.Row>
            <Grid.Row>
              <Modal open={openAuthorization} onClose={() => this.setState({ openAuthorization: false })}>
                <Modal.Header>{t('discounts.discountsResolution')}</Modal.Header>
                <Modal.Content>
                  <AuthorizationComponent
                    comment={comment}
                    commentError={commentError}
                    handleChangeComment={this.handleChangeComment}
                    value={value}
                    handleChange={this.handleChange}
                    reviewerName={reviewerName}
                    resolutionDate={resolutionDate}
                    idResolution={idResolution}
                    disabled={!hasPermission}
                    t={t}
                  />
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={() => this.setState({
                    openAuthorization: false, comment: '', value: '', discountId: '',
                  })}
                  >
                    {t('back')}
                  </Button>
                  {hasPermission ? <Button color="blue" content={t('save')} onClick={this.handleResolveAutorization} /> : null}
                </Modal.Actions>
              </Modal>
            </Grid.Row>
          </Grid>
        ) : null
    );
  }
}

ViewDiscounts.propTypes = {
  changeView: PropTypes.func.isRequired,
  discountId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(ViewDiscounts);
