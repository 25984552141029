import React, { useReducer, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import actions from './actions';
import listsReducer, { initialState } from './reducers';

const WishListsContext = createContext({});

export function useWishLists() {
  const contextValue = useContext(WishListsContext);
  if (!contextValue) {
    throw Error('El contexto de WishList no fue encontrado');
  }
  return contextValue;
}

export default function WishlistProvider(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(listsReducer, initialState);
  const value = {
    ...state.toJS(),
    getWishLists: actions.getWishLists(dispatch),
    selectWishList: actions.selectWishList(dispatch),
    createWishList: actions.createWishList(dispatch),
    createDefaultWishList: actions.createDefaultWishList(dispatch),
    toggleSwitchRenameListModal: actions.toggleSwitchRenameListModal(dispatch),
    toggleSwitchNewListModal: actions.toggleSwitchNewListModal(dispatch),
    renameWishList: actions.renameWishList(dispatch),
    deleteWishList: actions.deleteWishList(dispatch),
  };

  return (
    <WishListsContext.Provider value={value}>
      {children}
    </WishListsContext.Provider>
  );
}

WishlistProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
