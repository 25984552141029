import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Grid, Header, Image, Input, Button, Responsive, Dimmer, Loader,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import validator from 'validator';
import credit from './components/images/cards1.png';
import debit from './components/images/cards2.png';
import cvv from './components/images/cvv.png';
import openpay from './components/images/openpay.png';
import Toast from '../common/components/toast';
import security from './components/images/security.png';
import services from './services';
import './styless.css';

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nameOwner: '',
      cardNumber: '',
      expirationMonth: '',
      expirationYear: '',
      securityCode: '',
      nameError: '',
      cardError: '',
      monthError: '',
      yearError: '',
      codeError: '',
      cardType: '',
      expiryError: '',
      orderId: '',
      paymentMethod: 'card',
      currency: 'MXN',
      deviceSessionId: '',
    };
  }

  componentDidMount() {
    this.handleGetOrder();
    this.handleGetAccessKeys();
  }

  handleGetAccessKeys = () => {
    const { t } = this.props;
    services.getAccessKeys().then(response => {
      const sandboxFlag = !(process.env.REACT_APP_OPENPAY_SANDBOX === 'false');
      const responseOpenpay = response.openpay;
      window.OpenPay.setId(responseOpenpay.id);
      window.OpenPay.setApiKey(responseOpenpay.apiKey);
      window.OpenPay.setSandboxMode(sandboxFlag);
      this.setState({ deviceSessionId: window.OpenPay.deviceData.setup() });
    }).catch(error => {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      Toast(messageError, 'error');
    });
  }

  handleGetOrder = () => {
    services.getItems().then(orderResponse => {
      this.setState({ orderId: orderResponse.id });
    });
  }

  handleValidateEmptyData = () => {
    const { handleLoaderStatusChange } = this.props;
    handleLoaderStatusChange(true);
    const { t } = this.props;
    let completed = true;
    const {
      nameOwner, cardNumber, expirationMonth, expirationYear, securityCode,
    } = this.state;
    let {
      nameError, cardError, monthError, yearError, codeError,
    } = this.state;
    if (!nameOwner || nameOwner.length === 0) {
      nameError = t('El campo no puede ir vacio.');
      completed = false;
    }
    if (!cardNumber || cardNumber.length === 0) {
      cardError = t('El campo no puede ir vacio.');
      completed = false;
    }
    if (!expirationMonth || expirationMonth.length === 0) {
      monthError = t('El campo no puede ir vacio.');
      completed = false;
    }
    if (!expirationYear) {
      yearError = t('El campo no puede ir vacio.');
      completed = false;
    }
    if (!securityCode) {
      codeError = t('El campo no puede ir vacio.');
      completed = false;
    }
    if (completed !== true) {
      handleLoaderStatusChange(false);
      this.setState({
        nameError, cardError, monthError, yearError, codeError,
      });
    } else this.handleOpenpayValidations();
  }

  handleChangeField = (e, { value, name }) => {
    const { t } = this.props;
    if (name === 'nameOwner') {
      if (!validator.isAlpha(value.replace(/ /g, ''), ['es-ES']) && value.length > 0) {
        this.setState({ nameOwner: value, nameError: t('finaluser.errors.notAlpha') });
      } else this.setState({ nameOwner: value, nameError: '' });
      if (value.length > 35) {
        this.setState({ nameOwner: value, nameError: t('finaluser.errors.toLong') });
        return;
      }
      this.setState({ nameOwner: value });
    }
    if (name === 'cardNumber') {
      if (!validator.isNumeric(value) && value.length > 0) {
        this.setState({ cardNumber: value, cardError: t('finaluser.errors.notNumeric') });
      } else this.setState({ cardNumber: value, cardError: '' });
      if (window.OpenPay.card.cardType(value) !== '' && value.length >= 4) {
        this.setState({ cardType: window.OpenPay.card.cardType(value) });
        return;
      }
      if (value.length < 4) {
        this.setState({ cardType: '' });
        return;
      }
      this.setState({ cardNumber: value });
    }
    if (name === 'expirationMonth') {
      if (!validator.isNumeric(value) && value.length > 0) {
        this.setState({ expirationMonth: value, monthError: t('finaluser.errors.notNumeric') });
        return;
      } this.setState({ expirationMonth: value, monthError: '' });
      if (value > 12) {
        this.setState({ expirationMonth: value, monthError: t('finaluser.errors.toLong') });
        return;
      } this.setState({ expirationMonth: value, monthError: '' });
      this.setState({ expirationMonth: value, expiryError: '' });
    }
    if (name === 'expirationYear') {
      if (!validator.isNumeric(value) && value.length > 0) {
        this.setState({ expirationYear: value, yearError: t('finaluser.errors.notNumeric') }); return;
      } this.setState({ yearError: '' });
      if (value.length > 2) {
        this.setState({ expirationYear: value, yearError: t('finaluser.errors.toLong') });
      } else { this.setState({ expirationYear: value, yearError: '' }); }
      this.setState({ expirationYear: value, expiryError: '' });
    }
    if (name === 'securityCode') {
      if (!validator.isNumeric(value) && value.length > 0) {
        this.setState({ securityCode: value, codeError: t('finaluser.errors.notNumeric') }); return;
      }
      this.setState({ securityCode: value, codeError: '' });
      if (value.length > 4) {
        this.setState({ securityCode: value, codeError: t('finaluser.errors.toLong') }); return;
      } this.setState({ securityCode: value, codeError: '' });
      this.setState({ securityCode: value });
    }
  }

  handleOpenpayValidations = () => {
    const {
      cardNumber, expirationMonth, expirationYear, securityCode,
    } = this.state;
    const { t, handleLoaderStatusChange } = this.props;
    let mistakes = false;
    if (!validator.isNumeric(cardNumber)) {
      this.setState({ cardError: t('openpay.errors.notNumeric') });
      mistakes = true;
      handleLoaderStatusChange(false);
      return;
    }
    if (window.OpenPay.card.validateCardNumber(cardNumber) === false) {
      this.setState({ cardError: t('openpay.errors.invalidCard') }); mistakes = true;
      handleLoaderStatusChange(false);
      return;
    }
    if (window.OpenPay.card.validateExpiry(String(expirationMonth), String(expirationYear)) === false) {
      this.setState({ expiryError: t('openpay.errors.invalidExpirationDate') }); mistakes = true;
      handleLoaderStatusChange(false);
      return;
    }
    if (window.OpenPay.card.validateCVC(securityCode, cardNumber) === false) {
      this.setState({ codeError: t('openpay.errors.invalidCvv2') }); mistakes = true;
      handleLoaderStatusChange(false);
      return;
    }
    if (mistakes !== true) { this.handleValidateErrors(); }
  }

  handleValidateErrors = () => {
    const { t, handleLoaderStatusChange } = this.props;
    const {
      nameError, cardError, monthError, yearError, codeError, expiryError,
    } = this.state;
    let mistakes = false;
    if (nameError) { mistakes = true; }
    if (cardError) { mistakes = true; }
    if (monthError) { mistakes = true; }
    if (yearError) { mistakes = true; }
    if (codeError) { mistakes = true; }
    if (expiryError) { mistakes = true; }

    if (mistakes === false) {
      this.handleGetCardData(mistakes);
    } else {
      handleLoaderStatusChange(false);
      Toast(t('openpay.validateErrors'), 'error');
    }
  }

  handleGetCardData = mistakes => {
    const { validateOrderError } = this.props;
    if (validateOrderError !== '') {
      Toast(validateOrderError, 'error');
    } else {
      const {
        cardNumber, expirationMonth, expirationYear, securityCode, nameOwner,
      } = this.state;
      if (mistakes === false) {
        const cardData = {
          nameOwner,
          cardNumber,
          expirationYear,
          expirationMonth,
          cvv2: securityCode,
        };
        this.handleCreateToken(cardData);
      }
    }
  }

  handleCreateTokenError = () => {
    const { handleLoaderStatusChange } = this.props;
    handleLoaderStatusChange(false);
    const { t } = this.props;
    const messageError = t('openpay.errors.createTokenError');
    Toast(messageError, 'error');
  }

  handleCreateToken = cardData => {
    const options = {
      card_number: cardData.cardNumber,
      holder_name: cardData.nameOwner,
      expiration_year: cardData.expirationYear,
      expiration_month: cardData.expirationMonth,
      cvv2: cardData.cvv2,
    };
    return window.OpenPay.token.create(options, this.handleCreateCustomer, this.handleCreateTokenError);
  }

  handleCreateCustomer = tokenData => {
    const { id: sourceId } = tokenData.data;
    const { t, handleLoaderStatusChange } = this.props;
    services.createCustomer().then(() => {
      this.handlePaymentProcess(sourceId);
    }).catch(error => {
      if (error.code === 1008 || error.code === 1622) {
        this.handlePaymentProcess(sourceId);
      } else {
        handleLoaderStatusChange(false);
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    });
  }

  handlePaymentProcess = sourceId => {
    const {
      orderId, paymentMethod, currency, deviceSessionId,
    } = this.state;
    const {
      t, amount, handleLoaderStatusChange, response: propResponse,
    } = this.props;
    const roundedQuantity = amount.toFixed(2);
    const paymentData = {
      sourceId,
      method: paymentMethod,
      amount: roundedQuantity,
      currency,
      description: 'Pago con tarjeta',
      orderId,
      deviceSessionId,
    };
    handleLoaderStatusChange(true);
    services.paymentProcess(paymentData).then(response => {
      propResponse(response);
    }).catch(error => {
      this.setState({
        nameOwner: '',
        cardNumber: '',
        expirationYear: '',
        expirationMonth: '',
        securityCode: '',
        cardType: '',
      });
      handleLoaderStatusChange(false);
      let messageError = '';
      switch (error.message) {
        case 'The card was declined': messageError = t('openpay.errors.cardDeclined'); break;
        case 'The card has expired': messageError = t('openpay.errors.cardHasExpired'); break;
        case "The card doesn't have sufficient funds": messageError = t('openpay.errors.cardWithoutSufficientFunds'); break;
        case 'The card was reported as stolen': messageError = t('openpay.errors.cardDeclined'); break;
        case 'The card was declined (k)': messageError = t('openpay.errors.cardDeclined'); break;
        case 'The expiration date has already passed': messageError = t('openpay.errors.expirationDatePassed');
          this.setState({ yearError: true }); break;
        default: messageError = t('error-codes.default');
      }
      Toast(messageError, 'error');
    });
  }

  render() {
    const { t } = this.props;
    const {
      cardNumber, nameError, cardError, monthError, yearError,
      codeError, cardType, expiryError, nameOwner, securityCode,
      expirationMonth, expirationYear,
    } = this.state;
    const { paymentLoader, readyToPay } = this.props;
    return (
      <Grid className="container-openpay">
        {paymentLoader === true
          ? (
            <Dimmer active inverted>
              <Loader active size="big">Procesando pago...</Loader>
            </Dimmer>
          ) : ''}
        <Grid.Row centered>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="centered">
            <Header>{t('openpay.cards')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={2} computer={3} tablet={5} mobile={13} textAlign="left">
            <Header as="h5" color="black">{t('openpay.creditCard')}</Header>
            <Image src={credit} />
          </Grid.Column>
          <Grid.Column largeScreen={6} computer={7} tablet={5} mobile={13} textAlign="left">
            <Header as="h5" color="black">{t('openpay.debitCard')}</Header>
            <Image src={debit} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={4} computer={5} tablet={5} mobile={13} textAlign="left">
            <Header as="h5" color="black">{t('openpay.ownerName')}</Header>
            <Input name="nameOwner" value={nameOwner} fluid onChange={this.handleChangeField} error={nameError} placeholder={t('openpay.nameDescription')} />
            <p style={{ color: 'red' }}>{nameError}</p>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={5} tablet={5} mobile={13} textAlign="left">
            <Header as="h5" color="black">{t('openpay.cardNumber')}</Header>
            <Input fluid name="cardNumber" value={cardNumber} error={cardError} onChange={this.handleChangeField} placeholder={t('Numero de tarjeta')} />
            <p style={{ color: 'red' }}>{cardError}</p>
            <p style={{ color: 'blue' }}>{cardType}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={4} computer={5} tablet={5} mobile={13} textAlign="left">
            <Header as="h5" color="black">{t('openpay.expirationDate')}</Header>
            <div>
              <Input name="expirationMonth" value={expirationMonth} error={monthError} placeholder={t('openpay.expirationMonth')} onChange={this.handleChangeField} />
              <Input name="expirationYear" value={expirationYear} error={yearError} placeholder={t('openpay.expirationYear')} onChange={this.handleChangeField} />
            </div>
            <p style={{ color: 'red' }}>
              {monthError}
              {' '}
              {yearError}
            </p>
            <p style={{ color: 'red' }}>{expiryError}</p>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={5} tablet={5} mobile={13} textAlign="left">
            <Header as="h5" color="black">{t('openpay.securityCode')}</Header>
            <Input placeholder="CVC" value={securityCode} name="securityCode" type="password" error={codeError} onChange={this.handleChangeField}>
              <input />
              <Responsive as={Image} minWidth={1440}>
                <Image src={cvv} />
              </Responsive>
            </Input>
            <Responsive as={Image} maxWidth={425} minWidth={425}>
              <Image src={cvv} />
            </Responsive>
            <Responsive as={Image} maxWidth={375}>
              <br />
              <Image src={cvv} />
            </Responsive>
            <p style={{ color: 'red' }}>{codeError}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column largeScreen={8} computer={10} tablet={10} mobile={13}>
            <Button fluid color="blue" disabled={!readyToPay} onClick={this.handleValidateEmptyData}>{t('openpay.pay')}</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered divided>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={6} textAlign="right">
            <Image src={openpay} label={<Header as="h5" color="black">{t('openpay.openpayParagraph')}</Header>} />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={5} tablet={5} mobile={6}>
            <Header as="h5">
              <Image src={security} />
              {t('openpay.openPayDescription')}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Cards.propTypes = {
  amount: PropTypes.number.isRequired,
  handleLoaderStatusChange: PropTypes.func,
  paymentLoader: PropTypes.bool.isRequired,
  readyToPay: PropTypes.bool.isRequired,
  response: PropTypes.func,
  validateOrderError: PropTypes.string,
};

Cards.defaultProps = {
  handleLoaderStatusChange: PropTypes.func,
  response: () => {},
  validateOrderError: '',
};

export default translate('common', { wait: true })(Cards);
