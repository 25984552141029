import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import TableCell from './body-cell';
/* eslint react/no-array-index-key: 0 */

const isRowSelected = (selectedKeyValue, key, selectedRows) => {
  const selected = selectedRows.find(row => row[key] === selectedKeyValue);
  return !!selected;
};

const getPointerStyle = isSelectable => (isSelectable ? { cursor: 'pointer' } : { });

const TableBody = ({
  pageArray, headerKeys, mainKey, handleSelectedRow, selectedRows, isSelectable,
}) => (
  <Table.Body>
    {pageArray.map((rowObject, index) => (
      <Table.Row className="lightBlackTable" key={`${rowObject[mainKey]}.${index}`} onClick={() => handleSelectedRow(rowObject)} style={getPointerStyle(isSelectable)}>
        {headerKeys.map(header => (<TableCell row={rowObject} key={header.key} cellKey={header.key} format={header.format} isActive={isRowSelected(rowObject[mainKey], mainKey, selectedRows)} />))}
      </Table.Row>
    ))}
  </Table.Body>
);

TableBody.propTypes = {
  pageArray: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headerKeys: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.PropTypes.string,
      format: PropTypes.func,
    }),
  ).isRequired,
  mainKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleSelectedRow: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSelectable: PropTypes.bool,
};

TableBody.defaultProps = {
  isSelectable: false,
};

export default TableBody;
