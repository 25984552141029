import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const priorities = {};

priorities.getFodaByCustomerId = (customerId, year, type) => (
  request({
    route: apiRoutes.getFodaByCustomerId(customerId, year, type),
    method: 'GET',
  })
);

priorities.addBusinessPlanItem = body => (
  request({
    route: apiRoutes.addBusinessPlanItem(),
    method: 'POST',
    body,
  })
);

priorities.deleteBusinessPlanItem = itemId => (
  request({
    route: apiRoutes.deleteBusinessPlanItem(itemId),
    method: 'DELETE',
  })
);

priorities.updateBusinessPlanItem = body => (
  request({
    route: apiRoutes.updateBusinessPlanItem(),
    method: 'PUT',
    body,
  })
);

priorities.getCategories = type => (
  request({
    route: apiRoutes.getBusinessPlanCategories(type),
    method: 'GET',
  })
);

export default priorities;
