import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Footer from '../../common/components/footer';
import OnBoardingHeader from './components/on-boarding-header';
import constantValues from '../constants';
import './styles.css';
import * as session from '../../common/sessions';

const onTidioChatApiReady = () => {
  const tidioScript = document.createElement('script');
  tidioScript.src = constantValues.TIDIO_URL;
  document.body.appendChild(tidioScript);
};

function OnBoardingLayout({ children, history, isAuthenticated }) {
  const [acceptedTermsUser, setAcceptedTerms] = useState(false);
  const [profileCompleteUser, setProfileComplete] = useState(false);

  useEffect(() => {
    const { profileComplete, acceptedTerms } = session.get();
    setProfileComplete(profileComplete);
    setAcceptedTerms(acceptedTerms);
  }, [isAuthenticated]);

  useEffect(() => {
    onTidioChatApiReady();
  }, []);

  return (
    <div className="onboarding-container">
      <OnBoardingHeader
        steps={children.props.steps}
        currentStep={children.props.current}
        history={history}
        isAuthenticated={isAuthenticated}
        acceptedTerms={acceptedTermsUser}
        profileComplete={profileCompleteUser}
      />
      <div className="onboarding-children">
        {children}
      </div>
      <Footer />
    </div>
  );
}

OnBoardingLayout.propTypes = {
  children: PropTypes.shape({
    props: PropTypes.shape({
      current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      steps: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  history: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

OnBoardingLayout.defaultProps = {
  isAuthenticated: false,
};

export default OnBoardingLayout;
