import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button, Grid, Message, GridColumn, GridRow,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import cookie from 'js-cookie';
import validator from 'validator';
import * as session from '../../common/sessions';
import Toast from '../../common/components/toast';
import { TextField } from '../../common/components/materials';
import userInformation from '../services';

class Authentication extends Component {
  constructor(props) {
    super(props);
    const { userId } = session.get();
    this.state = {
      authCode: '',
      authCodeError: '',
      userId,
    };
  }

  handleUpdatePassword = () => {
    const { t, newPassword } = this.props;
    const token = cookie.get('token');
    const body = {
      password: newPassword,
    };
    userInformation.updatePassword(body, token)
      .then(() => {
        Toast(t('Contraseña actualizada'), 'success');
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  validateCode = () => {
    const minLength = 6;
    const validCode = true;
    const { t, history, nextAction } = this.props;
    const { userId, authCode, authCodeError } = this.state;
    if (authCode.length < minLength) { this.setState({ authCodeError: t('profile.errors.toShort') }); return; }
    if (validCode && !authCodeError) {
      userInformation.validateCode(authCode.replace(/ /g, ''), userId)
        .then(response => {
          if (response.authenticated === 'true') {
            this.handleUpdatePassword();
            if (nextAction === 'next') {
              history.push('/usuarios');
              // window.location.assign('/usuarios');
            } else {
              window.location.assign('/tablero');
            }
          } else {
            this.setState({ authCodeError: t('profile.errors.authCode') });
          }
        })
        .catch(error => this.setState({ authCodeError: t('profile.errors.authCode') + error.message }));
    }
  }

  handleCodeChange = event => {
    const { t } = this.props;
    const maxLength = 6;
    const { value: authCode } = event.target;
    if (!validator.isDecimal(authCode.replace(/ /g, ''), ['es-ES'])) {
      this.setState({ authCode, authCodeError: t('profile.errors.notNumeric') });
      return;
    }
    if (authCode.length > maxLength) {
      this.setState({ authCodeError: t('profile.errors.toLong') });
      return;
    }
    this.setState({ authCode, authCodeError: '' });
  }

  render() {
    const { t, sendAuthCode } = this.props;
    const { authCode, authCodeError } = this.state;
    return (
      <div>
        <Message
          attached
          header={t('profile.validateAccount')}
          content={t('profile.infoValidate')}
          style={{ marginLeft: '0', marginRight: '0' }}
        />
        <Grid>
          <Grid.Row style={{ paddingBottom: '0rem' }}>
            <GridColumn style={{ width: '52%', margin: '2%' }}>
              <TextField
                fullWidth
                maxLength={6}
                value={authCode || ''}
                error={authCodeError || ''}
                label={t('profile.authCode')}
                onChange={this.handleCodeChange}
                type="text"
                required
              />
            </GridColumn>
            <Grid.Column style={{ width: '10.5rem', marginTop: '1rem', paddingLeft: '0%' }}>
              <Button color="blue" style={{ align: 'right', width: '130px' }} onClick={this.validateCode}>{t('profile.validate')}</Button>
            </Grid.Column>
          </Grid.Row>
          <GridRow style={{ paddingTop: '0rem' }}>
            <Grid.Column style={{
              align: 'right', textAlign: 'right', marginBottom: '1rem', paddingRight: '2rem',
            }}
            >
              <Button color="yellow" style={{ align: 'left', width: '130px' }} onClick={sendAuthCode}>{t('profile.sendNewCode')}</Button>
            </Grid.Column>
          </GridRow>
        </Grid>
      </div>
    );
  }
}

Authentication.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  newPassword: PropTypes.string.isRequired,
  nextAction: PropTypes.string.isRequired,
  sendAuthCode: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(Authentication);
