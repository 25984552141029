import { callError } from '@compusoluciones-pdigitales/status-codes';

import { request } from '../../../common/fetch-utils';
import url from '../../../apiRoutes';

import { toast } from '../../utils';

function itemBodyScheme({ sku, quantity = 1 }) {
  const items = [{
    sku,
    quantity,
  }];
  return { items };
}

function getErrorType(error) {
  switch (error.code) {
    case callError.products.INVALID_SKU: {
      toast.warning('No se encontro ningun articulo con ese SKU');
      throw error;
    }
    case callError.products.EMPTY_WISH_LIST:
      return { result: [] };
    case callError.products.ITEM_ALREADY_EXISTS_IN_WISH_LIST: {
      toast.warning('Este articulo ya esta en la lista de deseo');
      throw error;
    }
    default: {
      toast.error('El sistema parece no estar respondiendo correctamente. Intenta mas tarde.');
      throw error;
    }
  }
}

export async function fetchItemsWishList(wishListId) {
  try {
    const { wishList } = await request({
      route: url.itemsWishList(wishListId),
      method: 'GET',
    });
    return { result: wishList };
  } catch (error) {
    return getErrorType(error);
  }
}

export async function updateItemQuantity(wishListId, item) {
  try {
    await request({
      route: url.itemsWishList(wishListId),
      method: 'PUT',
      body: item,
    });
    toast.success('Datos actualizados correctamente');
    return { result: item };
  } catch (error) {
    return getErrorType(error);
  }
}

export async function sendToCart(item) {
  try {
    await request({
      route: url.addItems(),
      method: 'POST',
      body: itemBodyScheme(item),
    });
    toast.success('Los articulos fueron enviados a tu carrito');
    return { result: true };
  } catch (error) {
    return getErrorType(error);
  }
}

export async function removeItemFromWishList(wishListId, sku) {
  try {
    await request({
      route: url.removeItemFromWishList(wishListId, encodeURIComponent(sku)),
      method: 'DELETE',
    });
    toast.success('El articulo fue eliminado de la lista');
    return { result: true };
  } catch (error) {
    return getErrorType(error);
  }
}

export async function addItem(wishListId, sku) {
  try {
    return await request({
      route: url.itemsWishList(wishListId),
      method: 'POST',
      body: itemBodyScheme({ sku }),
    });
  } catch (error) {
    return getErrorType(error);
  }
}

export default async function doRequest(fetchFunction, handleLoadingFinish) {
  const { error, result } = await fetchFunction();
  if (error) {
    handleLoadingFinish(true);
    throw error;
  }
  handleLoadingFinish(false);
  return result;
}
