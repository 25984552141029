import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import MenuApp from './components/menu';
import Footer from '../../common/components/footer';
import * as session from '../../common/sessions';

import './styles.css';

function AgentsLayout(props) {
  const {
    history, children: childrenProp, routes, isAuthenticated, location,
  } = props;
  const { customerId } = session.get();
  const accessDenied = (customerId === 'G000000');
  if (!accessDenied) history.push('/');
  const children = React.Children.map(childrenProp, child => (
    React.cloneElement(child)
  ));

  return (
    <Grid>
      <Grid.Row stretched className="no-padding-y">
        <Grid.Column>
          <MenuApp
            history={history}
            location={location.pathname}
            isAuthenticated={isAuthenticated}
            routes={routes}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding">
        <Grid.Column style={{ minHeight: '69vh' }}>
          {children}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched>
        <Grid.Column>
          <Footer />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

AgentsLayout.propTypes = {
  children: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  isAuthenticated: PropTypes.bool,
  routes: PropTypes.shape({}).isRequired,
};

AgentsLayout.defaultProps = {
  isAuthenticated: false,
};

export default AgentsLayout;
