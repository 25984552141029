import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import Steps from './steps';
import MenuWeb from './menuWeb';
import MenuMobile from './menuMobile';

const OnBoardingHeader = ({
  history, steps, currentStep, profileComplete, acceptedTerms,
}) => (
  <Grid>
    <Grid.Row>
      <Grid.Column only="tablet computer largeScreen" tablet={16} computer={16} largeScreen={16} widescreen={16}>
        <MenuWeb history={history} profileComplete={profileComplete} acceptedTerms={acceptedTerms} />
      </Grid.Column>
      <Grid.Column only="mobile" mobile={16}>
        <MenuMobile history={history} profileComplete={profileComplete} acceptedTerms={acceptedTerms} />
      </Grid.Column>
    </Grid.Row>
    <Grid.Row centered>
      {acceptedTerms && profileComplete
        ? ''
        : <Steps steps={steps} currentStep={currentStep} />}
    </Grid.Row>
  </Grid>
);

OnBoardingHeader.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({
    active: PropTypes.bool,
  })),
  currentStep: PropTypes.string,
  profileComplete: PropTypes.bool,
  acceptedTerms: PropTypes.bool,
};

OnBoardingHeader.defaultProps = {
  steps: [],
  currentStep: '',
  profileComplete: false,
  acceptedTerms: false,
};

export default OnBoardingHeader;
