/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Loader, Container, Checkbox,
} from 'semantic-ui-react';
import moment from 'moment';
import { TextField } from '../../../common/components/materials';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

class LogUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabledHigh: false,
      enabledLow: false,
      disabledTextField: false,
      report: [],
      loading: false,
      sortAsc: false,
      headers: [
        { key: 'executor', isKey: true },
        { key: 'executor', name: 'Usuario Ejecutor' },
        { key: 'modifiedUser', name: 'Usuario Modificado' },
        { key: 'movement', name: 'Movimiento' },
        {
          key: 'dateMovement',
          name: (
            <Button style={{ fontFamily: 'Exo, sans-serif' }} labelPosition="left" className="ui button" onClick={() => this.sortDate()}>
              Fecha de Movimiento
              <i id="upDate" style={{ display: 'block' }} className="angle up icon" />
              <i id="downDate" style={{ display: 'none' }} className="angle down icon" />
            </Button>),
          format: cell => (`${moment(cell).utc().add(12, 'hours').format('DD-MM-YYYY HH:mm')}`),
        },
      ],
    };
  }

  componentDidMount = () => {
    this.search();
  }

  sortDate = () => {
    const { filteredResults, sortAscDate } = this.state;
    const copyReport = [...filteredResults];
    if (sortAscDate) {
      copyReport.sort((a, b) => Date.parse(a.dateMovement) - Date.parse(b.dateMovement));
      document.getElementById('downDate').style.display = 'block';
      document.getElementById('upDate').style.display = 'none';
      this.setState({ sortAscDate: false });
    } else {
      copyReport.sort((a, b) => Date.parse(b.dateMovement) - Date.parse(a.dateMovement));
      document.getElementById('upDate').style.display = 'block';
      document.getElementById('downDate').style.display = 'none';
      this.setState({ sortAscDate: true });
    }
    this.setState({ filteredResults: copyReport });
  }

  handleGetLogUsersReport = async () => {
    const { t } = this.props;
    this.setState({ report: [], loading: true });
    const logs = await service.getLogUserReport()
      .then(({ logs: report }) => {
        if (!report) return { report: [] };
        return ({ report });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
        } else {
          Toast(messageError, 'error');
        }
        return ({ report: [] });
      });
    return logs;
  };

  search = async () => {
    const { report } = await this.handleGetLogUsersReport();
    this.setState({ report, loading: false, filteredResults: report });
  };

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!filter) {
      this.setState({ filter, filteredResults: allReport });
    } else {
      const filteredResults = allReport.filter(filterInfo => ((filterInfo.executor && filterInfo.executor.toLowerCase().includes(baseFilter))
        || (filterInfo.modifiedUser && filterInfo.modifiedUser.toLowerCase().includes(baseFilter))
        || (filterInfo.movement && filterInfo.movement.toLowerCase().includes(baseFilter))
        || (filterInfo.dateMovement && filterInfo.dateMovement.toLowerCase().includes(baseFilter))));
      this.setState({ filter, filteredResults });
    }
  };

  handleFilterMovementHigh = async event => {
    const { report, enabledHigh } = this.state;
    this.setState({ enabledHigh: !enabledHigh });
    if (!enabledHigh) {
      const copyReport = [...report];
      const filtered = copyReport.filter(filterInfo => filterInfo.movement === event);
      this.setState({ filteredResults: filtered, enabledLow: false, disabledTextField: true });
    } else {
      this.setState({ filteredResults: report, disabledTextField: false });
    }
  };

  handleFilterMovementLow = async event => {
    const { report, enabledLow } = this.state;
    this.setState({ enabledLow: !enabledLow });
    if (!enabledLow) {
      const copyReport = [...report];
      const filtered = copyReport.filter(filterInfo => filterInfo.movement === event);
      this.setState({ filteredResults: filtered, enabledHigh: false, disabledTextField: true });
    } else {
      this.setState({ filteredResults: report, disabledTextField: false });
    }
  };

  render() {
    const { t } = this.props;
    const {
      report, headers, loading, filteredResults, filter, enabledHigh, enabledLow, disabledTextField,
    } = this.state;
    const fileName = 'Reporte_log_usuarios_modificaciones';
    const low = 'Baja';
    const high = 'Alta';

    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('intelligenceDashboard.logUser')}

          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={7} tablet={6} mobile={16}>
            <TextField
              fullWidth
              maxLength={40}
              value={filter || ''}
              label={t('intelligenceDashboard.filter')}
              onChange={this.handleFilter}
              disabled={disabledTextField}
            />
          </Grid.Column>
          <Grid.Column style={{ marginLeft: '20px', marginRight: '20px' }} computer={4} tablet={4} mobile={8}>
            <Header as="h5">{t('intelligenceDashboard.movement')}</Header>
            <Checkbox toggle style={{ marginRight: '11px' }} label="Alta" onChange={() => this.handleFilterMovementHigh(high)} checked={enabledHigh} />
            <Checkbox toggle label="Baja" onChange={() => this.handleFilterMovementLow(low)} checked={enabledLow} />
          </Grid.Column>
          {(loading || !report || report.length === 0)
            ? null
            : (
              <Grid.Column computer={3} tablet={3} mobile={8}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={filteredResults || report}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('intelligenceDashboard.download')}
                  sheetName="Logs"
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  data={filteredResults || report}
                  headers={headers}
                  pageSize={15}
                />
              </Container>
              <br />
              <Header
                as="h5"
                style={{ cursor: 'pointer', display: 'inline' }}
                color="blue"
              >
                *(UTC-06:00) Guadalajara, Ciudad de México, Monterrey
              </Header>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(LogUser);
