import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Confirm, Header, Dropdown, Dimmer, Loader, Divider, Button,
} from 'semantic-ui-react';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import * as session from '../common/sessions';
import Toast from '../common/components/toast';
import SiclikTable from '../common/components/table';
import service from './services';
import roles from '../role-permissions';

class AutorizersUsers extends Component {
  constructor(props) {
    const { access } = session.get();
    const hasAccess = securityService.validate(access, roles.customerAdmin);
    if (!hasAccess) props.history.push('/cotizaciones/agente');
    super(props);
    this.state = {
      headers: [
        { key: 'id', isKey: true },
        { key: 'name', name: 'Nombre' },
        { key: 'role', name: 'Rol' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (<Button icon="trash alternate" onClick={() => this.handleClickDelete(row)} />),
        },
      ],
      usersArray: [],
      usersOptions: [],
      userId: '',
      customerId: '',
      open: false,
      loading: false,
    };
  }

  componentDidMount = () => {
    this.getUsersHasPermission();
  }

  getError = error => {
    const { t } = this.props;
    let messageError = '';
    if (!error.code) {
      messageError = t('error-codes.default');
    } else {
      messageError = t(`error-codes.${error.code}`);
    }
    if (messageError) Toast(messageError, 'error');
    this.setState({ loading: false });
  }

  getUsersHasPermission = async () => {
    this.setState({ loading: true });
    let usersList = await service.getAllUsers().catch(this.getError);
    if (usersList.users) {
      usersList = usersList.users.map(user => {
        const userFormated = { key: user.id, text: user.email, value: user.id };
        return userFormated;
      });
      usersList = usersList.filter(userItem => userItem.text && userItem.text.trim().length > 0);
      this.setState({ loading: false });
    }
    this.setState({ usersOptions: usersList });
  };

  handleClickDelete = user => (
    this.setState({ customerId: user.key, open: true })
  );

  handleConfirmDelete = () => {
    const { t } = this.props;
    const { userId, customerId, usersArray } = this.state;
    service.deleteUser({ userId, customerId }).then(response => {
      if (response) {
        usersArray.map((user, index) => {
          if (user.key === customerId) {
            usersArray.splice(index, 1);
            Toast(t('authorizers.userDeleted'), 'success');
          }
          return user;
        });
        this.setState({ usersArray, open: false });
      }
    })
      .catch(this.getError);
  }

  handleChangeUsers = (e, { value }) => {
    service.getCustomersByUser({ userId: value, platformId: 1 })
      .then(response => {
        if (response.customers) {
          const userCustomers = response.customers.map(user => {
            const customerFormated = {
              key: user.id, name: user.name, role: user.role, value: user.id,
            };
            return customerFormated;
          });
          this.setState({ userId: value, usersArray: userCustomers });
        }
      });
  }

  render() {
    const { t } = this.props;
    const {
      headers, usersArray, usersOptions, userId, customerId, open, loading,
    } = this.state;
    return (
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('customerDirectory.customersUsers')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('customerDirectory.customersUserDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        { loading
          ? (
            <Grid.Row>
              <Dimmer active inverted><Loader active size="big" /></Dimmer>
            </Grid.Row>
          ) : null}
        { !loading
          ? (
            <Grid.Row columns={4}>
              <Grid.Column largeScreen={14} computer={14} tablet={10} mobile={10}>
                <Dropdown
                  options={usersOptions}
                  selection
                  fluid
                  value={userId}
                  onChange={this.handleChangeUsers}
                  placeholder={t('authorizers.chooseUser')}
                  search
                />
              </Grid.Column>
            </Grid.Row>
          ) : null }
        { !loading
          ? (
            <Grid.Row style={{ overflowX: 'auto' }} largeScreen={15} computer={15} tablet={15} mobile={15}>
              <SiclikTable
                selectable
                data={usersArray}
                headers={headers}
                pageSize={10}
              />
            </Grid.Row>
          ) : null }
        <Grid.Row>
          <Confirm
            open={open}
            header={t('authorizers.confirmDeleteUser')}
            content={customerId}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={() => this.setState({ open: false })}
            onConfirm={this.handleConfirmDelete}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(AutorizersUsers);
