import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Menu, Confirm, Divider, Label, Icon, Container,
} from 'semantic-ui-react';
import moment from 'moment';
import * as session from '../../../common/sessions';
import { TextField } from '../../../common/components/materials';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

class CesSurvey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: [],
      loading: false,
      sortAsc: false,
      sortAscDate: false,
      activeItem: 'agent',
      authorizedUser: [
        { id: 'f017ac52-670c-42ad-a921-b7a1605926ab' },
        { id: '778580d8-def6-4b55-bc0c-c032311fe7e7' },
        { id: '73021e95-c277-4fb3-b8bf-a755270a2747' },
        { id: '600cda12-a164-4232-846e-3af73be7f305' },
        { id: 'e384f2c6-8eb8-4b9e-b010-28b72efb89b8' },
        { id: '0e0f5208-ddec-4ee5-b464-91eeff02d401' },
        { id: '5227c48e-6583-4750-88d0-3cc88330ad90' },
        { id: 'f73f7c6f-3c54-4d4f-ba66-dae7b795bcce' },
        { id: '153ad879-a9d8-46c3-9f7b-a31db25d82a4' },
        { id: '43a18ffd-907e-4246-81fd-451f6945eda5' },
        { id: '6ae07310-2bcc-453d-ad6a-55e47112abc6' },
      ],
      headers: [
        { key: 'userId', isKey: true },
        { key: 'user', name: 'Usuario' },
        { key: 'email', name: 'Correo' },
        { key: 'customerId', name: 'Cliente' },
        { key: 'comments', name: 'Comentarios' },
        {
          key: 'rating',
          name: (
            <Button style={{ fontFamily: 'Exo, sans-serif' }} labelPosition="left" className="ui button" onClick={() => this.sortRating()}>
              Calificación
              <i id="upRating" style={{ display: 'none' }} className="angle up icon" />
              <i id="downRating" style={{ display: 'block' }} className="angle down icon" />
            </Button>
          ),
        },
        {
          key: 'date',
          name: (
            <Button style={{ fontFamily: 'Exo, sans-serif' }} labelPosition="left" className="ui button" onClick={() => this.sortDate()}>
              Fecha de registro
              <i id="upDate" style={{ display: 'none' }} className="angle up icon" />
              <i id="downDate" style={{ display: 'block' }} className="angle down icon" />
            </Button>),
        },
      ],
      openConfirmAgent: false,
      openConfirmCustomer: false,
      totalAgentAnswers: 0,
      totalCustomerAnswers: 0,
      dateLogRestartCustomer: '',
      dateLogRestartAgent: '',
      ratings: [0, 0, 0, 0, 0],
      averageRating: 0,
    };
  }

  componentDidMount = () => {
    this.handleGetReportAgents();
    this.getQuantityAnsweredAgents();
    this.getQuantityAnsweredCustomers();
    this.getLastDateRestartCustomer();
    this.getLastDateRestartAgent();
  }

  showAgents = () => this.setState({ openConfirmAgent: true });

  showCustomer = () => this.setState({ openConfirmCustomer: true });

  handleCancel = () => this.setState({ openConfirmAgent: false, openConfirmCustomer: false });

  handleCategorySelection = (e, { name }) => this.setState({ activeItem: name });

  addLogRestartSurveyAgent = () => {
    const { userId } = session.get();
    try {
      const body = {
        userId,
        platformId: 5,
      };
      service.addLogRestartSurvey(body);
    } catch (error) {
      let messageError = '';
      if (!error.code) {
        messageError = ('error-codes.default');
      } else {
        messageError = (`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
  }

  addLogRestartSurveyCustomer = () => {
    const { userId } = session.get();
    try {
      const body = {
        userId,
        platformId: 1,
      };
      service.addLogRestartSurvey(body);
    } catch (error) {
      let messageError = '';
      if (!error.code) {
        messageError = ('error-codes.default');
      } else {
        messageError = (`error-codes.${error.code}`);
        Toast(messageError, 'error');
      }
    }
  }

  getLastDateRestartAgent = async () => {
    const { dateLogRestartAgent } = this.state;
    const { t } = this.props;
    try {
      const logsService = await service.getLogRestartSurvey();
      const logsAgents = logsService.logs.filter(element => element.platformId === 5);
      const sortDate = logsAgents.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
      const maxDate = sortDate[0].date;
      const maxDateFormat = moment(maxDate).utc('America/Mexico_City').format('DD-MM-YYYY hh:mm a');
      this.setState({ dateLogRestartAgent: maxDateFormat });
    } catch (error) {
      Toast(t('intelligenceDashboard.errors.lastDate'), 'error');
    }
    return dateLogRestartAgent;
  }

  getLastDateRestartCustomer = async () => {
    const { dateLogRestartCustomer } = this.state;
    const { t } = this.props;
    try {
      const logsService = await service.getLogRestartSurvey();
      const logsCustomers = logsService.logs.filter(element => element.platformId === 1);
      const sortDate = logsCustomers.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
      const maxDate = sortDate[0].date;
      const maxDateFormat = moment(maxDate).utc('America/Mexico_City').format('DD-MM-YYYY hh:mm a');
      this.setState({ dateLogRestartCustomer: maxDateFormat });
    } catch (error) {
      Toast(t('intelligenceDashboard.errors.lastDate'), 'error');
    }
    return dateLogRestartCustomer;
  }

  handleConfirmAgent = async () => {
    const { t } = this.props;
    const platformId = 5;
    try {
      const disableAgentSurvey = await service.disableSurvey(platformId);
      if (disableAgentSurvey.changedRows !== 0) {
        this.setState({ openConfirmAgent: false });
        this.addLogRestartSurveyAgent();
        this.getLastDateRestartAgent();
        this.getQuantityAnsweredAgents();
        Toast(t('survey.confirmUpdateAgents'), 'success');
      } else {
        this.setState({ openConfirmAgent: false });
        Toast(t('survey.noChanges'), 'success');
      }
    } catch (error) {
      this.setState({ openConfirmAgent: false });
      Toast(t('survey.errorDisableSurvey'), 'error');
    }
  }

  handleConfirmCustomer = async () => {
    const { t } = this.props;
    const platformId = 1;
    try {
      const disableAgentSurvey = await service.disableSurvey(platformId);
      if (disableAgentSurvey.changedRows !== 0) {
        this.setState({ openConfirmCustomer: false });
        this.addLogRestartSurveyCustomer();
        this.getLastDateRestartCustomer();
        this.getQuantityAnsweredCustomers();
        Toast(t('survey.confirmUpdateCustomers'), 'success');
      } else {
        this.setState({ openConfirmCustomer: false });
        Toast(t('survey.noChanges'), 'success');
      }
    } catch (error) {
      this.setState({ openConfirmCustomer: false });
      Toast(t('survey.errorDisableSurvey'), 'error');
    }
  }

  sortDate = () => {
    const { filteredResults, sortAscDate } = this.state;
    const copyReport = [...filteredResults];
    if (sortAscDate) {
      copyReport.sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
      document.getElementById('downDate').style.display = 'block';
      document.getElementById('upDate').style.display = 'none';
      this.setState({ sortAscDate: false });
    } else {
      copyReport.sort((a, b) => Date.parse(b.date) - Date.parse(a.date));
      document.getElementById('upDate').style.display = 'block';
      document.getElementById('downDate').style.display = 'none';
      this.setState({ sortAscDate: true });
    }
    this.setState({ filteredResults: copyReport });
  }

  sortRating = () => {
    const { filteredResults, sortAsc } = this.state;
    const copyReport = [...filteredResults];
    if (sortAsc) {
      copyReport.sort((x, y) => x.rating - y.rating);
      document.getElementById('downRating').style.display = 'block';
      document.getElementById('upRating').style.display = 'none';
      this.setState({ sortAsc: false });
    } else {
      copyReport.sort((x, y) => y.rating - x.rating);
      document.getElementById('upRating').style.display = 'block';
      document.getElementById('downRating').style.display = 'none';
      this.setState({ sortAsc: true });
    }
    this.setState({ filteredResults: copyReport });
  }

  handleFilter = event => {
    const { value: filter } = event.target;
    const baseFilter = filter.toLowerCase().trim();
    const { report } = this.state;
    const allReport = [...report];
    if (!filter) {
      this.setState({ filter, filteredResults: allReport });
    } else {
      const filteredResults = allReport.filter(filterInfo => ((filterInfo.user && filterInfo.user.toLowerCase().includes(baseFilter))
        || (filterInfo.email && filterInfo.email.toLowerCase().includes(baseFilter))
        || (filterInfo.customerId && filterInfo.customerId.toLowerCase().includes(baseFilter))
        || (filterInfo.comments && filterInfo.comments.toLowerCase().includes(baseFilter))
        || (filterInfo.date && filterInfo.date.includes(baseFilter))));
      this.setState({ filter, filteredResults });
    }
  };

  handleGetSurveyAgents = async () => {
    const { t } = this.props;
    this.setState({
      report: [], loading: true, activeItem: 'agent',
    });
    const responseSurveys = await service.getSurveyReport()
      .then(({ surveys: report }) => {
        const filterPlatform = report.filter(filterInfo => filterInfo.platformId === 5);
        if (filterPlatform) return { report: filterPlatform };
        return ({ report: [] });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
        } else {
          Toast(messageError, 'error');
        }
        return ({ report: [] });
      });
    return responseSurveys;
  };

  handleGetReportAgents = async () => {
    const { t } = this.props;
    let { ratings } = this.state;
    let { averageRating } = this.state;
    ratings = [0, 0, 0, 0, 0];
    let totalRating = 0;
    const { report } = await this.handleGetSurveyAgents();
    const { userId } = session.get();
    report.forEach(element => {
      ratings[element.rating - 1] += 1;
      totalRating += element.rating;
    });
    averageRating = totalRating / report.length;
    const { authorizedUser } = this.state;
    const havePermission = authorizedUser.filter(user => user.id === userId);
    if (havePermission && havePermission.length > 0) {
      this.getQuantityAnsweredAgents();
      this.getLastDateRestartAgent();
      this.setState({
        report, loading: false, filteredResults: report, averageRating, ratings,
      });
    } else {
      Toast(t('error-codes.7'), 'warning');
      return this.setState({
        report: [],
        filteredResults: [],
        loading: false,
        totalAgentAnswers: 0,
      });
    }
    return report;
  };

  handleGetSurveyCustomer = async () => {
    const { t } = this.props;
    this.setState({ report: [], loading: true, activeItem: 'customer' });
    const responseSurveys = await service.getSurveyReport()
      .then(({ surveys: report }) => {
        const filterPlatform = report.filter(filterInfo => filterInfo.platformId === 1);
        if (filterPlatform) return { report: filterPlatform };
        return ({ report: [] });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
        } else {
          Toast(messageError, 'error');
        }
        return ({ report: [] });
      });
    return responseSurveys;
  };

  handleGetReportCustomer = async () => {
    const { t } = this.props;
    const { report } = await this.handleGetSurveyCustomer();
    const { userId } = session.get();
    const { authorizedUser } = this.state;
    let { averageRating, ratings } = this.state;
    ratings = [0, 0, 0, 0, 0];
    let totalRating = 0;
    report.forEach(element => {
      ratings[element.rating - 1] += 1;
      totalRating += element.rating;
    });
    averageRating = totalRating / report.length;
    const havePermission = authorizedUser.filter(user => user.id === userId);
    if (havePermission && havePermission.length > 0) {
      this.getQuantityAnsweredCustomers();
      this.getLastDateRestartCustomer();
      this.setState({
        report, loading: false, filteredResults: report, averageRating, ratings,
      });
    } else {
      Toast(t('error-codes.7'), 'warning');
      return this.setState({
        report: [],
        filteredResults: [],
        loading: false,
        totalCustomerAnswers: 0,
      });
    }
    return report;
  };

  getQuantityAnsweredAgents = async () => {
    const { surveys } = await service.getSurveyResponse();
    const totalAnswers = surveys.filter(survey => survey.active === 1 && survey.platformId === 5);
    return this.setState({ totalAgentAnswers: totalAnswers.length });
  }

  getQuantityAnsweredCustomers = async () => {
    const { surveys } = await service.getSurveyResponse();
    const totalAnswers = surveys.filter(survey => survey.active === 1 && survey.platformId === 1);
    return this.setState({ totalCustomerAnswers: totalAnswers.length });
  }

  starsRatings = numberStars => {
    const stars = [];
    for (let index = 0; index < numberStars; index++) {
      stars.push(<Icon name="star" color="yellow" />);
    }
    return stars;
  }

  render() {
    const { t } = this.props;
    const {
      report, loading, headers, filteredResults, filter, activeItem, openConfirmAgent, openConfirmCustomer,
      totalAgentAnswers, totalCustomerAnswers, dateLogRestartAgent, dateLogRestartCustomer, ratings,
      averageRating,
    } = this.state;
    const fileName = 'Reporte_encuestaCES';
    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Menu className="ui secondary pointing menu" color="blue">
          <Menu.Item
            name="agent"
            content={t('survey.CesSurveyAgent')}
            active={activeItem === 'agent'}
            onClick={this.handleCategorySelection && this.handleGetReportAgents}
          />
          <Menu.Item
            name="customer"
            content={t('survey.CesSurveyCustomer')}
            active={activeItem === 'customer'}
            onClick={this.handleCategorySelection && this.handleGetReportCustomer}
          />
        </Menu>
        {(activeItem === 'agent')
          ? (
            <>
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Label className="basic">
                    <Icon name="circle notch" color="blue" />
                    {`Respuestas del período: ${totalAgentAnswers}`}
                  </Label>
                  <br />
                  <br />
                  <Label className="detail">
                    <Icon name="calendar check" color="blue" />
                    {`Último reinicio de la encuesta: ${dateLogRestartAgent || 0}`}
                  </Label>
                  <br />
                  <br />
                  {ratings.map((data, i) => (
                    <Label className="basic">
                      {this.starsRatings(i + 1)}
                      {`: ${data} `}
                      <Icon name="user" color="blue" />
                    </Label>
                  ))}
                  <br />
                  <br />
                  <Label className="basic">
                    <Icon name="users" color="blue" />
                    {`Promedio: ${averageRating.toFixed(2)}`}
                  </Label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column largeScreen={7} computer={7} tablet={5} mobile={15}>
                  <TextField
                    fullWidth
                    maxLength={40}
                    value={filter || ''}
                    label={t('intelligenceDashboard.filter')}
                    onChange={this.handleFilter}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={3} computer={2} tablet={2} mobile={7}>
                  <Button color="blue" onClick={this.handleGetReportAgents}>{t('intelligenceDashboard.update')}</Button>
                </Grid.Column>
                {(loading || !report || report.length === 0)
                  ? <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={7} />
                  : (
                    <>
                      <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={7}>
                        <ExportExcel
                          headers={sheetHeaders}
                          data={filteredResults || report}
                          fileName={fileName}
                          styleRow={styleRow}
                          styleHeader={styleHeader}
                          label={t('intelligenceDashboard.download')}
                          sheetName="Encuesta"
                        />
                      </Grid.Column>
                      <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={7}>
                        <Button color="grey" onClick={this.showAgents}>{t('survey.restartSurvey')}</Button>
                      </Grid.Column>
                      <Divider />
                      <Grid.Row style={{ overflowX: 'scroll' }}>
                        <Grid.Column>
                          <Container style={{ overflowX: 'scroll' }}>
                            <SiclikTable
                              data={filteredResults || report}
                              headers={headers}
                              pageSize={15}
                            />
                          </Container>
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
              </Grid.Row>
            </>
          )
          : (
            <>
              <Grid.Row>
                <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
                  <Label className="basic">
                    <Icon name="circle notch" color="blue" />
                    {`Respuestas del período: ${totalCustomerAnswers}`}
                  </Label>
                  <br />
                  <br />
                  <Label className="detail">
                    <Icon name="calendar check" color="blue" />
                    {`Último reinicio de la encuesta: ${dateLogRestartCustomer || 0}`}
                  </Label>
                  <br />
                  <br />
                  {ratings.map((data, i) => (
                    <Label className="basic">
                      {this.starsRatings(i + 1)}
                      {`: ${data} `}
                      <Icon name="user" color="blue" />
                    </Label>
                  ))}
                  <br />
                  <br />
                  <Label className="basic">
                    <Icon name="users" color="blue" />
                    {`Promedio: ${averageRating.toFixed(2)}`}
                  </Label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column largeScreen={7} computer={7} tablet={5} mobile={15}>
                  <TextField
                    fullWidth
                    maxLength={40}
                    value={filter || ''}
                    label={t('intelligenceDashboard.filter')}
                    onChange={this.handleFilter}
                  />
                </Grid.Column>
                <Grid.Column largeScreen={3} computer={2} tablet={2} mobile={7}>
                  <Button color="blue" onClick={this.handleGetReportCustomer}>{t('intelligenceDashboard.update')}</Button>
                </Grid.Column>
                {(loading || !report || report.length === 0)
                  ? <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={7} />
                  : (
                    <>
                      <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={7}>
                        <ExportExcel
                          headers={sheetHeaders}
                          data={filteredResults || report}
                          fileName={fileName}
                          styleRow={styleRow}
                          styleHeader={styleHeader}
                          label={t('intelligenceDashboard.download')}
                          sheetName="Encuesta"
                        />
                      </Grid.Column>
                      <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={7}>
                        <Button color="grey" onClick={this.showCustomer}>{t('survey.restartSurvey')}</Button>
                      </Grid.Column>
                      <Grid.Row style={{ overflowX: 'scroll' }}>
                        <Grid.Column>
                          <Container style={{ overflowX: 'scroll' }}>
                            <SiclikTable
                              data={filteredResults || report}
                              headers={headers}
                              pageSize={15}
                            />
                          </Container>
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
              </Grid.Row>
            </>
          )}
        <Grid.Row>
          <Confirm
            open={openConfirmAgent}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirmAgent}
            header={t('survey.confirmHeaderAgent')}
            content={t('survey.confirmContentAgent')}
            cancelButton={t('Cancelar')}
            confirmButton={t('Aceptar')}
          />
        </Grid.Row>
        <Grid.Row>
          <Confirm
            open={openConfirmCustomer}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirmCustomer}
            header={t('survey.confirmHeaderCustomer')}
            content={t('survey.confirmContentCustomer')}
            cancelButton={t('Cancelar')}
            confirmButton={t('Aceptar')}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(CesSurvey);
