import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Header, Dropdown, Responsive,
} from 'semantic-ui-react';

const FinalUser = props => {
  const {
    t, checkoutData, currencyOptions, handleCurrencyChange, currentCurrency,
  } = props;
  return (
    <Grid container>
      {checkoutData.map(data => (
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={7} mobile={11}>
              <Header as="h4" color="black">
                {t('checkout.finalUser')}
                <Responsive maxWidth={425}>
                  <br />
                </Responsive>
                <Header as="h5" style={{ display: 'inline' }}>
                  {' '}
                  {data.endUserName}
                  {' '}
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={9} mobile={16}>
              <Header as="h4" color="black">
                {t('checkout.paymentMethod')}
                <Header as="h5" style={{ display: 'inline' }}>
                  <Responsive maxWidth={768}>
                    <br />
                  </Responsive>
                  <Header as="h5" style={{ display: 'inline' }}>
                    {' '}
                    {t('lease-quote.paymentMethod')}
                    {' '}
                  </Header>
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={8} mobile={16}>
              <Header as="h4" color="black">
                {t('checkout.currency')}
                <Header as="h5" style={{ display: 'inline' }}>
                  <Dropdown
                    compact
                    floating
                    placeholder="Moneda"
                    defaultValue={data.currencyId}
                    options={currencyOptions}
                    onChange={handleCurrencyChange}
                    value={currentCurrency}
                    search
                    selection
                  />
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
    </Grid>
  );
};

FinalUser.propTypes = {
  currentCurrency: PropTypes.number,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  handleCurrencyChange: PropTypes.func,
};

FinalUser.defaultProps = {
  currentCurrency: 1,
  checkoutData: [],
  currencyOptions: [],
  handleCurrencyChange: () => {},
};

export default translate('common', { wait: true })(FinalUser);
