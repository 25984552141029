import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const upload = {};

upload.getSignedUrl = body => (
  request({
    route: apiRoutes.getSignedUrl(),
    method: 'POST',
    body,
  })
);

upload.uploadImgS3 = ({ signedUrl }, file) => (
  // eslint-disable-next-line no-undef
  fetch(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      Accept: '*/*',
      'content-type': file.type,
    },
  })
);

upload.getImageS3 = body => (
  request({
    route: apiRoutes.getFilesS3(),
    method: 'POST',
    body,
  })
);

upload.deleteImageS3 = body => (
  request({
    route: apiRoutes.deleteImageS3(),
    method: 'POST',
    body,
  })
);

upload.uploadFile = ({ signedUrl }, file) => (
  request(signedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      Accept: '*/*',
      'content-type': file.type,
    },
  })
);

upload.getFileS3 = userId => (
  request({
    route: apiRoutes.getFilesS3(userId),
    method: 'GET',
  })
);

upload.deleteFileS3 = url => (
  request({
    route: apiRoutes.deleteFileS3(url),
    method: 'DELETE',
  })
);

upload.getDocumentsTypes = url => (
  request({
    route: apiRoutes.getDocumentsTypes(url),
    method: 'GET',
  })
);

upload.addAttachment = body => (
  request({
    route: apiRoutes.addAttaches(),
    method: 'POST',
    body,
  })
);

upload.addProfileImage = body => (
  request({
    route: apiRoutes.addProfileImage(),
    method: 'PUT',
    body,
  })
);

export default upload;
