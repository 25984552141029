export const SORT_OPTIONS = [
  {
    key: 1,
    text: 'Coincidencia',
    value: 'coin',
  },
  {
    key: 2,
    text: 'Disponibilidad',
    value: 'avail',
  },
  {
    key: 3,
    text: 'Nombre A - Z',
    value: 'nameasc',
  },
  {
    key: 4,
    text: 'Nombre Z - A',
    value: 'namedesc',
  },
  {
    key: 5,
    text: 'Precio menor a mayor',
    value: 'priceasc',
  },
  {
    key: 6,
    text: 'Precio mayor a menor',
    value: 'pricedesc',
  },
];

export const DEFAULT_SORT = 'coin';

export const breadcrumbTypes = {
  brand: {
    content: 'Marcas',
    link: true,
  },
  sku: {
    content: 'Productos',
    link: false,
  },
  category: {
    content: 'Categorías',
    link: false,
  },
  search: {
    content: 'Búsqueda',
    link: false,
  },
  allByFilters: {
    content: 'Catálogo de productos',
    link: false,
  },
};

export const VIEW_GRID = 'grid';
export const VIEW_LIST = 'list';

export const PAGE_SIZE = 12;
export const DEFAULT_PAGE = 1;

export const MAX_FILTERS_VISIBLE = 15;
export const FILTER_VISIBILITY_SHOW = 'show';
export const FILTER_VISIBILITY_HIDE = 'hide';

export const AVAILABILITY_FILTER = 'availability';

export const PRICE_FILTER = 'price';
export const STOCK_FILTER = 'stock';
export const OUTLET_FILTER = 'outletStock';
export const BUNDLE_FILTER = 'bundle';

export const DEFAULT_SEARCH_TYPE = 'allByFilters';
export const OUTLET_PARAM = 'outlet';
export const BUNDLE_PARAM = 'bundle';
