import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Container, Divider, Responsive,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import { currencyFormat } from '../../common/utils';

const Orders = props => {
  const {
    t, currency: propsCurrency, ordersData, ordersHeaders,
  } = props;
  const currency = propsCurrency === 2 ? 'USD' : 'MXN';
  return (
    <Grid>
      {ordersData.map(order => (
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16} textAlign="left">
              <Header as="h4" color="blue">
                {t('checkout.addressee')}
                <Header as="h5" color="blue" style={{ display: 'inline' }}>{order.branchOffice}</Header>
              </Header>
              <Responsive maxWidth={768}>
                <br />
              </Responsive>
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left">
              <Header as="h5" color="black">
                IVA:&nbsp;
                <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(order.iva, currency)}</Header>
              </Header>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16} textAlign="left">
              <Header as="h5" color="black">
                Subtotal:&nbsp;
                <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(order.subtotal, currency)}</Header>
              </Header>
            </Grid.Column>
            {order.creditDays ? <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} /> : null}
            {order.creditDays
              ? (
                <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={4} textAlign="right">
                  <Header as="h5" color="black">
                    Dias totales de crédito:&nbsp;
                    <Header as="h5" color="green" style={{ display: 'inline' }}>{order.creditDays}</Header>
                  </Header>
                </Grid.Column>
              )
              : null}
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Container>
                <SiclikTable
                  selectable
                  data={order.items}
                  headers={ordersHeaders}
                  pageSize={5}
                />
                <Divider section />
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
    </Grid>
  );
};
Orders.defaultProps = {
  currency: 1,
  ordersData: [],
  ordersHeaders: [],
};

Orders.propTypes = {
  currency: PropTypes.number,
  ordersData: PropTypes.arrayOf(PropTypes.shape({})),
  ordersHeaders: PropTypes.arrayOf(PropTypes.shape({})),
};
export default translate('common', { wait: true })(Orders);
