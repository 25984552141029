import PropTypes from 'prop-types';
import React from 'react';
import { Progress } from 'semantic-ui-react';
import { translate } from 'react-i18next';

const Color = score => {
  switch (score) {
    case 0:
      return 'gray';
    case 1:
      return 'red';
    case 2:
      return 'yellow';
    case 3:
      return 'blue';
    case 4:
      return 'green';
    default:
      return 'gray';
  }
};

const ProgressExampleProgressRatio = props => {
  const { t, securityResult } = props;
  return (
    <Progress value={securityResult} total="4" color={Color(securityResult)} Active>
      {t('profile.securityPassword')}
      {' '}
      {t(`profile.passworStrength.${securityResult}`)}
    </Progress>
  );
};

ProgressExampleProgressRatio.propTypes = {
  securityResult: PropTypes.number.isRequired,
};

export default translate('common', { wait: true })(ProgressExampleProgressRatio);
