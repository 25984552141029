import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import {
  Grid, Header, Loader, Dimmer,
} from 'semantic-ui-react';
import ClientCount from './components/client-count';
import Sector from './components/sector';
import Clients from './components/clients';
import Segments from './components/segments';
import service from './services';
import Toast from '../common/components/toast';

const TYPE_CLIENT_NUMBER = 'Número de Clientes';
const TYPE_INITIATIVE = 'Iniciativa';
const TYPE_CLIENT = 'CLIENTES';
const CLIENT8020_ID = 13;
const TARGETCLIENT_ID = 14;
const GOVERNMENT_ID = 18;
const PRIVATE_ID = 19;

function buildInitiativeBody(categoryId, customerId, year, description = '0') {
  return ({
    categoryId, customerId, description, year,
  });
}

const catalogsHash = {
  [CLIENT8020_ID]: 'clients8020',
  [TARGETCLIENT_ID]: 'targetClients',
  [GOVERNMENT_ID]: 'government',
  [PRIVATE_ID]: 'privateInitiative',
};

class ClientForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sectorLoadDone: true,
      countLoadDone: true,
      clientLoadDone: true,
      clientNumbers: [],
      numberCategories: [],
      sectors: [],
      privateInitiative: null,
      government: null,
      sectorItems: [],
      clients8020: [],
      targetClients: [],
      clientCategories: [],
    };
  }

  componentDidMount() {
    this.handleGetCatalogs();
    this.getData();
  }

  componentDidUpdate(prevProps) {
    const { year, customerId } = this.props;
    const { year: prevYear, customerId: prevCustomerId } = prevProps;
    if (prevYear !== year || prevCustomerId !== customerId) {
      this.getData();
    }
  }

  getData = async () => {
    this.handleGetClients();
    this.handleGetSectorItems();
    this.handleGetClientNumbers();
    this.handleGetInitiative();
  }

  handleGetCatalogs = async () => {
    const numberCategories = await service.getCategories(TYPE_CLIENT_NUMBER)
      .then(response => {
        if (!response.categories) return ([]);
        const categories = response.categories.map(element => ({
          value: element.id,
          label: element.category,
        }));
        return categories;
      })
      .catch(() => ([]));
    const clientCategories = await service.getCategories(TYPE_CLIENT)
      .then(response => {
        if (!response.categories) return ([]);
        const categories = response.categories.map(element => ({
          value: element.id,
          label: element.category,
        }));
        return categories;
      })
      .catch(() => ([]));
    const sectors = await service.getIndustries()
      .then(response => {
        if (!response.industries) return ([]);
        const industries = response.industries.map(element => ({
          value: element.id,
          label: element.industry,
        }));
        return industries;
      })
      .catch(() => ([]));
    this.setState({
      numberCategories, clientCategories, sectors, loading: false,
    });
  }

  handleGetClients = async () => {
    const { year, customerId } = this.props;
    const clients = await service.getBusinessPlanClients(customerId, year)
      .then(response => {
        if (!response.clients) return ([]);
        return response.clients;
      })
      .catch(() => ([]));
    const groups = clients.reduce((clientGroups, client) => {
      if (client.categoryId === CLIENT8020_ID) clientGroups.clients8020.push(client);
      if (client.categoryId === TARGETCLIENT_ID) clientGroups.targetClients.push(client);
      return clientGroups;
    }, { clients8020: [], targetClients: [] });
    this.setState({ ...groups, clientLoadDone: false });
  }

  handleGetSectorItems = async () => {
    const { year, customerId } = this.props;
    const sectorItems = await service.getIndustriesPercentByCustomerId(customerId, year)
      .then(response => {
        if (!response.industries) return ([]);
        return response.industries;
      })
      .catch(() => ([]));
    this.setState({ sectorItems, sectorLoadDone: false });
  }

  handleGetClientNumbers = async () => {
    const { year, customerId } = this.props;
    const clientNumbers = await service.getBusinessPlanElementsByCategoryDescription(customerId, year, TYPE_CLIENT_NUMBER)
      .then(({ businessPlanList }) => {
        if (!businessPlanList) return ([]);
        return businessPlanList;
      })
      .catch(() => ([]));
    this.setState({ clientNumbers, countLoadDone: false });
  }

  handleGetInitiative = async () => {
    const { year, customerId } = this.props;
    try {
      const initiatives = await service.getBusinessPlanElementsByCategoryDescription(customerId, year, TYPE_INITIATIVE)
        .then(({ businessPlanList }) => {
          if (!businessPlanList) return ([]);
          return businessPlanList;
        })
        .catch(error => {
          if (error.code === 1009) throw error;
          return ([]);
        });

      const containInitiatives = initiatives.length
        && initiatives.every(({ categoryId }) => [GOVERNMENT_ID, PRIVATE_ID].includes(categoryId));

      if (containInitiatives) {
        const reducedResponse = initiatives.reduce((hashObject, initiative) => {
          const propertyName = catalogsHash[initiative.categoryId];
          return ({
            ...hashObject,
            [propertyName]: initiative,
          });
        }, {});
        this.setState({ ...reducedResponse });
      } else {
        const initiativesHash = {};
        const governmentBody = buildInitiativeBody(GOVERNMENT_ID, customerId, year);
        const privateBody = buildInitiativeBody(PRIVATE_ID, customerId, year);
        initiativesHash.government = await service.addBusinessPlanItem(governmentBody)
          .then(response => ({ ...governmentBody, id: response.id }))
          .catch(() => { });
        initiativesHash.privateInitiative = await service.addBusinessPlanItem(privateBody)
          .then(response => ({ ...privateBody, id: response.id }))
          .catch(() => { });
        this.setState({ ...initiativesHash });
      }
    } catch ({ code }) {
      if (code === 1009) Toast('No cuenta con permisos para esta información', 'error');
    }
  }

  handleAddIndustry = async industryItem => {
    if (!industryItem) {
      this.setState({ sectorLoadDone: false });
      return;
    }
    await this.handleGetSectorItems();
  }

  handleAddCount = async countItem => {
    if (!countItem) {
      this.setState({ countLoadDone: false });
      return;
    }
    await this.handleGetClientNumbers();
  }

  handleAddOrUpdateClient = async clientItem => {
    if (!clientItem) {
      this.setState({ clientLoadDone: false });
      return;
    }
    await this.handleGetClients();
  }

  handleDeleteIndustry = async itemId => {
    const { sectorItems } = this.state;
    if (!itemId) {
      this.setState({ countLoadDone: false });
      return;
    }
    const filteredItems = sectorItems.filter(item => item.id !== itemId);
    this.setState({ sectorItems: filteredItems, sectorLoadDone: false });
  }

  handleDeleteCountItem = async (itemId = false) => {
    const { clientNumbers } = this.state;
    if (!itemId) {
      this.setState({ countLoadDone: false });
      return;
    }
    const filteredItems = clientNumbers.filter(item => item.id !== itemId);
    this.setState({ clientNumbers: filteredItems, countLoadDone: false });
  }

  handleDeleteClient = async (itemId = false, categoryId) => {
    const catalog = catalogsHash[categoryId];
    if (!itemId) {
      this.setState({ clientLoadDone: false });
    } else {
      const { [catalog]: catalogSelected } = this.state;
      const filteredItems = catalogSelected.filter(item => item.id !== itemId);
      this.setState({ [catalog]: filteredItems, clientLoadDone: false });
    }
  }

  render() {
    const { t, customerId, year } = this.props;
    const {
      numberCategories, clientCategories, clients8020, targetClients, sectors, sectorItems,
      clientNumbers, loading, sectorLoadDone, countLoadDone, clientLoadDone, government, privateInitiative,
    } = this.state;

    return (
      <Grid padded centered>
        <Grid.Row>
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={12}>
            <Header color="blue">{t('Clientes')}</Header>
          </Grid.Column>
        </Grid.Row>
        {
          loading
            ? <Dimmer active inverted><Loader active size="big" /></Dimmer>
            : (
              <Grid centered>
                <Grid.Row>
                  <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={7}>
                    <Segments privateInitiative={privateInitiative} government={government} />
                  </Grid.Column>
                  <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={5} />
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={6}>
                    <Sector
                      t={t}
                      customerId={customerId}
                      year={year}
                      sectors={sectors}
                      sectorItems={sectorItems}
                      loadDone={sectorLoadDone}
                      activeLoader={() => this.setState({ sectorLoadDone: true })}
                      handleDelete={this.handleDeleteIndustry}
                      handleAdd={this.handleAddIndustry}
                    />
                  </Grid.Column>
                  <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={6}>
                    <ClientCount
                      t={t}
                      customerId={customerId}
                      year={year}
                      categories={numberCategories}
                      clientNumbers={clientNumbers}
                      loadDone={countLoadDone}
                      activeLoader={() => this.setState({ countLoadDone: true })}
                      handleDelete={this.handleDeleteCountItem}
                      handleAdd={this.handleAddCount}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={12}>
                    <Clients
                      t={t}
                      customerId={customerId}
                      year={year}
                      categories={clientCategories}
                      targetClients={targetClients}
                      paretoClients={clients8020}
                      loadDone={clientLoadDone}
                      activeLoader={() => this.setState({ clientLoadDone: true })}
                      handleAddOrUpdate={this.handleAddOrUpdateClient}
                      handleDelete={this.handleDeleteClient}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )
        }
      </Grid>
    );
  }
}

ClientForm.defaultProps = {
  t: () => { },
};

ClientForm.propTypes = {
  year: PropTypes.number.isRequired,
  t: PropTypes.func,
  customerId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(ClientForm);
