import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Header, Button, Image,
} from 'semantic-ui-react';
import { currencyFormat } from '../../utils';
import './style.css';

function CardProduct({
  price, currencyPrice, image, name, promotionPrice, customClass, history, sku,
}) {
  const encodedSkuString = encodeURIComponent(sku);
  let currency = '';
  if (currencyPrice === 'Dolar MS') currency = 'USD';
  else if (currencyPrice === 'Dolares' || currencyPrice === 'Dólares') currency = 'USD';
  else currency = 'MXN';
  const isValidPrice = (parseFloat(price).toFixed(2) > 0);
  const isValidPromotion = (parseFloat(promotionPrice).toFixed(2) < parseFloat(price).toFixed(2)) && parseFloat(promotionPrice) > 0;
  return (
    <Grid className={`card-grid card-product ${customClass}`} centered>
      <Grid.Row centered className="card-image no-padding" verticalAlign="middle">
        <Image size="medium" style={{ padding: '1rem' }} src={image} centered />
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center" className="card-title-cont">
          <Header size="small" color="blue" content={name} className="no-margin card-title" />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16} textAlign="center" style={{ paddingTop: '1rem' }}>
          <p className="fullBlackText fontSizeText">
            SKU:
            {' '}
            {sku}
          </p>
        </Grid.Column>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
          {
            isValidPrice ? (
              <p className="fullBlackText card-padding-price"><b>{currencyFormat(price, currency)}</b></p>
            ) : <p className="fullBlackText card-padding-price"><b>----</b></p>
          }
        </Grid.Column>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
          {
            isValidPromotion ? (
              <p className="greenText card-padding-promotion"><b>{currencyFormat(promotionPrice, currency)}</b></p>
            ) : ''
          }
        </Grid.Column>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
          <Button
            primary
            size="medium"
            fluid
            onClick={() => {
              history.push(`/producto/detalle/${encodedSkuString}`);
            }}
          >
            Ver más
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

CardProduct.propTypes = {
  sku: PropTypes.string,
  currencyPrice: PropTypes.string,
  customClass: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  image: PropTypes.string,
  name: PropTypes.string,
  price: PropTypes.number,
  promotionPrice: PropTypes.number,
};

CardProduct.defaultProps = {
  sku: '',
  currencyPrice: 'MXN',
  customClass: '',
  image: '',
  name: '',
  price: 0,
  promotionPrice: 0,
};

export default CardProduct;
