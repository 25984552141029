import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from '@material-ui/core/TextField';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Loader, Container,
} from 'semantic-ui-react';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

const SEARCH_TYPES = {
  Brand: 'Marca',
  Null: 'Catálogo general',
  Search: 'Búsqueda general',
  Sku: 'SKU',
};

class TopSearchs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment().subtract(6, 'months').format('YYYY-MM-DD'),
      startError: '',
      end: moment().format('YYYY-MM-DD'),
      endError: '',
      commonSearchs: [],
      loading: false,
      headers: [
        { key: 'value', isKey: true },
        { key: 'searchType', name: 'Tipo de búsqueda' },
        { key: 'value', name: 'Valor' },
        { key: 'totalEvents', name: 'Total' },
      ],
    };
  }

  handleGetCommonSearchsReport = async ({ start, end }) => {
    const { t } = this.props;
    this.setState({ commonSearchs: [], loading: true });
    const searchs = await service.getCommonSearchs({ startDate: start, endDate: end })
      .then(({ report }) => {
        if (!report) return { commonSearchs: [] };
        return ({
          commonSearchs: report.map(({ searchType, value, totalEvents }) => ({
            searchType: SEARCH_TYPES[searchType] || searchType,
            value: value === 'Null' ? '' : value,
            totalEvents,
          })),
        });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
        } else if (error.code === 5) {
          Toast(t('intelligenceDashboard.errors.missingGoogleKey'), 'error');
        } else {
          Toast(messageError, 'error');
        }
        return ({ commonSearchs: [] });
      });
    return searchs;
  };

  handleDate = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  isValidData = () => {
    const { t } = this.props;
    const { start, end } = this.state;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    if (!start || !end) {
      Toast(t('errors.empty'), 'error');
      return false;
    }
    if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
      Toast(t('intelligenceDashboard.errors.biggerThanEnd'), 'error');
      return false;
    }
    return true;
  };

  search = async () => {
    const { start, end } = this.state;
    if (this.isValidData()) {
      const formatedData = {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      };
      const { commonSearchs } = await this.handleGetCommonSearchsReport(formatedData);
      this.setState({ commonSearchs, loading: false });
    }
  };

  render() {
    const { t } = this.props;
    const {
      start, startError, end, endError, commonSearchs, headers, loading,
    } = this.state;
    const fileName = `busquedas_${moment(start).format('DD-MM-YYYY')}_${moment(end).format('DD-MM-YYYY')}`;

    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('intelligenceDashboard.commonSearchs')}

          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className="paddingBottomCommonSearchs" computer={3} tablet={4} mobile={6}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.fromDate')}</Header>
            <DatePicker
              type="date"
              name="start"
              format="dd/mm/yyyy"
              value={start}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={startError}
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} mobile={6}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.toDate')}</Header>
            <DatePicker
              type="date"
              name="end"
              format="dd/mm/yyyy"
              value={end}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={endError}
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} mobile={4}>
            <Button loading={loading} onClick={this.search}>{t('intelligenceDashboard.search')}</Button>
          </Grid.Column>
          <Grid.Column only="computer mobile" style={{ padding: '1rem' }} computer={4} mobile={12} />
          {(loading || !commonSearchs || commonSearchs.length === 0)
            ? null
            : (
              <Grid.Column computer={3} tablet={4} mobile={12}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={commonSearchs}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('intelligenceDashboard.download')}
                  sheetName="Busquedas"
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  data={commonSearchs}
                  headers={headers}
                  pageSize={30}
                />
              </Container>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(TopSearchs);
