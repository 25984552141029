import { callError } from '@compusoluciones-pdigitales/status-codes';
import moment from 'moment';

import toast from '../common/components/toast';
import { request } from '../common/fetch-utils';
import url from '../apiRoutes';

function filterValidated(branchOffices) {
  return branchOffices.filter(branchOffice => branchOffice.isValidated);
}

function getErrorType(error) {
  switch (error.code) {
    case callError.products.INVALID_SKU: {
      toast('No se encontro ningun articulo con ese SKU', 'error');
      throw error;
    }
    case callError.orders.NO_ORDERS_FOUND:
      return error;
    default: {
      toast('El sistema parece no estar respondiendo correctamente. Intenta mas tarde.', 'error');
      throw error;
    }
  }
}

function isOrderError(errorCode) {
  return Object.keys(callError.orders)
    .find(code => callError.orders[code] === errorCode);
}

function itemBodyScheme(items) {
  const setItemsFormat = items.map(({ sku, quantity = 1 }) => ({
    sku,
    quantity,
  }));
  return { items: setItemsFormat };
}

export async function getBranchOffices() {
  try {
    const { branchOffices } = await request({
      route: url.getBranchOfficeAddress(),
      method: 'GET',
    });
    return filterValidated(branchOffices);
  } catch (error) {
    return getErrorType(error);
  }
}

export async function createCart(items, deliveryConfig, wishListName) {
  try {
    return await request({
      route: url.generateQuickCart(),
      method: 'POST',
      body: {
        description: `WishList: ${wishListName}`,
        items,
        deliveryConfig,
      },
    });
  } catch (error) {
    return getErrorType(error);
  }
}

export async function buyQuickCart(cartId) {
  try {
    const userCart = await request({
      route: url.buyQuickCart(),
      method: 'POST',
      body: {
        cartId,
      },
    });
    toast('Tu compra fue realizada', 'success');
    return userCart;
  } catch (error) {
    throw isOrderError(error.code)
      ? error
      : getErrorType(error);
  }
}

export async function shoppingCartToQuote() {
  try {
    const date = moment().format('YYYY-MM-DD');
    return await request({
      route: url.shoppingCartToQuote(),
      method: 'PUT',
      body: {
        name: `Carrito suspendido: ${date}`,
      },
    });
  } catch (error) {
    return getErrorType(error);
  }
}

export async function sendToCart(items) {
  try {
    await request({
      route: url.addItems(),
      method: 'POST',
      body: itemBodyScheme(items),
    });
    toast('Los articulos fueron enviados a tu carrito', 'success');
    return { result: true };
  } catch (error) {
    return getErrorType(error);
  }
}
