import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from '@material-ui/core/TextField';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Loader, Dropdown, Container,
} from 'semantic-ui-react';
import ExportExcel from '../../../common/components/ExportExcel';
import service from '../../services';
import { sheetHeaders, styleHeader, styleRow } from './excelFormat';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';

const FILE_NAME = 'Reporte_cupones';
const SHEET_NAME = 'Cupones canjeados';

class CouponsByOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      startError: '',
      end: moment().format('YYYY-MM-DD'),
      endError: '',
      makers: [{ key: 0, value: 0, text: 'Todos' }],
      makerId: 0,
      makersHash: {},
      report: [],
      loading: false,
      headers: [
        { key: 'orderId', isKey: true },
        { key: 'orderId', name: 'Id orden' },
        { key: 'intelisisId', name: 'Id Intelisis' },
        {
          key: 'purchaseDate',
          name: 'Fecha registro',
          format: cell => (`${moment(cell).format('DD-MM-YYYY HH:mm')}`),
        },
        { key: 'customerId', name: 'Id distribuidor' },
        { key: 'email', name: 'Correo' },
        { key: 'coupon', name: 'Cupón' },
        { key: 'subtotal', name: 'Subtotal' },
        { key: 'sku', name: 'SKU' },
        { key: 'maker', name: 'Fabricante' },
      ],
    };
  }

  componentDidMount() {
    this.handleGetDataMakers();
  }

  handleGetDataMakers = async () => {
    const { makers, makersHash } = await service.getMakers()
      .then(({ makers: responseMakers }) => (
        responseMakers.reduce((currentResponse, maker) => {
          const { name, id } = maker;
          return ({ makers: [...currentResponse.makers, { key: id, value: id, text: name }], makersHash: { ...currentResponse.makersHash, [id]: maker } });
        }, { makers: [{ key: 0, value: 0, text: 'Todos' }], makersHash: { 0: { key: 0, value: 0, text: 'Todos' } } })
      ))
      .catch(() => ({ makers: [{ key: 0, value: 0, text: 'Todos' }], makersHash: { 0: { key: 0, value: 0, text: 'Todos' } } }));

    this.setState({ makers, makersHash });
  }

  handleGetReport = async ({ start, end }) => {
    const { t } = this.props;
    const { makerId, makersHash } = this.state;
    this.setState({ report: [], loading: true });
    const orders = await service.getCouponsByOrderReport({ startDate: start, endDate: end, uenId: makersHash[makerId].uenId })
      .then(({ coupons: report }) => {
        if (!report) return { report: [] };
        return ({ report });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        if (error.code === 1005) {
          Toast(t('intelligenceDashboard.errors.emptyData'), 'warning');
        } else {
          Toast(messageError, 'error');
        }
        return ({ report: [] });
      });
    return orders;
  };

  handleDate = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  isValidData = () => {
    const { t } = this.props;
    const { start, end } = this.state;
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    if (!start || !end) {
      Toast(t('errors.empty'), 'error');
      return false;
    }
    if (startDate.isValid() && endDate.isValid() && startDate.isAfter(endDate)) {
      Toast(t('intelligenceDashboard.errors.biggerThanEnd'), 'error');
      return false;
    }
    return true;
  };

  search = async () => {
    const { start, end } = this.state;
    if (this.isValidData()) {
      const formatedData = {
        start: moment(start).format('YYYY-MM-DD'),
        end: moment(end).format('YYYY-MM-DD'),
      };
      const { report } = await this.handleGetReport(formatedData);
      this.setState({ report, loading: false });
    }
  };

  handleChangeMaker = (e, { value }) => this.setState({ makerId: value });

  render() {
    const { t } = this.props;
    const {
      start, startError, end, endError, report, headers, loading, makers, makerId,
    } = this.state;
    const fileName = `${FILE_NAME}_${moment(start).format('DD-MM-YYYY')}_${moment(end).format('DD-MM-YYYY')}`;

    return (
      <Grid container className="paddingTopIntelligenceDashboard">
        <Grid.Row>
          <Header
            as="h2"
            color="primary"
          >
            {t('intelligenceDashboard.couponsByOrder')}

          </Header>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={3} tablet={4} mobile={12}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.makers')}</Header>
            <Dropdown
              options={makers}
              placeholder={t('intelligenceDashboard.makers')}
              value={makerId}
              fluid
              selection
              search
              onChange={this.handleChangeMaker}
            />
          </Grid.Column>
          <Grid.Column style={{ padding: '1rem' }} only="mobile" mobile={12} />
          <Grid.Column className="paddingBottomCommonSearchs" computer={3} tablet={4} mobile={8}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.fromDate')}</Header>
            <DatePicker
              type="date"
              name="start"
              format="dd/mm/yyyy"
              value={start}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={startError}
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={4} mobile={8}>
            <Header className="no-margin-bottom" as="h5">{t('intelligenceDashboard.toDate')}</Header>
            <DatePicker
              type="date"
              name="end"
              format="dd/mm/yyyy"
              value={end}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              error={endError}
            />
          </Grid.Column>
          <Grid.Column style={{ padding: '1rem' }} only="mobile" mobile={12} />
          <Grid.Column computer={3} tablet={4} mobile={8}>
            <Button loading={loading} onClick={this.search}>{t('intelligenceDashboard.search')}</Button>
          </Grid.Column>
          <Grid.Column style={{ padding: '1rem' }} only="computer tablet" computer={1} tablet={12} />
          {(loading || !report || report.length === 0)
            ? null
            : (
              <Grid.Column computer={3} tablet={8} mobile={8}>
                <ExportExcel
                  headers={sheetHeaders}
                  data={report}
                  fileName={fileName}
                  styleRow={styleRow}
                  styleHeader={styleHeader}
                  label={t('intelligenceDashboard.download')}
                  sheetName={SHEET_NAME}
                />
              </Grid.Column>
            )}
        </Grid.Row>
        {loading
          ? <Loader active inline="centered" />
          : null}
        {loading ? null : (
          <Grid.Row centered>
            <Grid.Column>
              <Container style={{ overflowX: 'scroll' }}>
                <SiclikTable
                  data={report}
                  headers={headers}
                  pageSize={30}
                />
              </Container>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(CouponsByOrder);
