import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment';
import { translate } from 'react-i18next';
import { Grid, Header } from 'semantic-ui-react';
import SiclikTable from '../../../../common/components/table';
import { TextField } from '../../../../common/components/materials';

function filterData(value = '', baseArray = []) {
  return !value.length
    ? [...baseArray]
    : baseArray.filter(({ title, message }) => (
      title.toUpperCase().includes(value.toUpperCase())
      || message.toUpperCase().includes(value.toUpperCase())
    ));
}

function NotificationList(props) {
  const {
    clean, updateClean, notifications, headers, messageTable, t,
  } = props;

  const [state, setState] = useState({
    filter: '',
    filteredData: [],
    dataCopy: [],
    filterError: '',
  });

  useEffect(() => {
    const filteredData = filterData('', notifications);
    setState(currentState => ({
      ...currentState,
      filteredData,
      dataCopy: [...notifications],
      filter: '',
      filterError: '',
    }));
  }, [notifications, clean]);

  const handleChange = ({ target }) => {
    const { value, id, name } = target;
    const nameOrId = id || name;
    const filteredData = filterData(value, state.dataCopy);
    setState({
      ...state,
      [nameOrId]: value,
      [`${nameOrId}Error`]: filteredData.length ? '' : 'No se encontraron coincidencias',
      filteredData,
    });
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center">
          <Header className="titles-menu" color="blue">{t('notifications.listTitle')}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row verticalAlign="middle">
        <Grid.Column computer={5} tablet={6} mobile={16}>
          <TextField
            id="filter"
            fullWidth
            label={t('Filtrar')}
            value={`${state.filter}` || ''}
            error={state.filterError || ''}
            onChange={handleChange}
          />
        </Grid.Column>
        <Grid.Column only="mobile" mobile={16} style={{ paddingBottom: '2em' }} />
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <SiclikTable
            selectable
            clean={clean}
            updateClean={updateClean}
            data={state.filteredData}
            headers={headers}
            pageSize={10}
            noDataMessage={messageTable}
            singleSelection
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

NotificationList.propTypes = {
  clean: PropTypes.bool,
  messageTable: PropTypes.string,
  updateClean: PropTypes.func,
  notifications: PropTypes.arrayOf(PropTypes.shape({})),
  headers: PropTypes.arrayOf(PropTypes.shape({})),
};

NotificationList.defaultProps = {
  updateClean: () => null,
  notifications: [],
  headers: [],
  messageTable: '',
  clean: false,
};

export default translate('common', { wait: true })(NotificationList);
