/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import cookie from 'js-cookie';
import { callError } from '@compusoluciones-pdigitales/status-codes';

import * as session from './sessions';

function unavailableError() {
  return new Error(callError.system.UNAVAILABLE);
}

axios.interceptors.request.use(config => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (cookie.get('token')) headers.Authorization = `Bearer ${cookie.get('token')}`;
  return { ...config, headers };
});

axios.interceptors.response.use(response => response.data, async error => {
  const { config: originalRequest, response } = error;
  const bodyResponse = response && response.data;
  if (!bodyResponse) throw unavailableError();
  if (!originalRequest.retry && session.hasExpired(bodyResponse.code)) {
    originalRequest.retry = true;
    await session.renewAccessToken();
    return axios.request(originalRequest);
  }
  throw bodyResponse;
});

export function request({
  route, method = 'GET', headers, body,
}) {
  return axios.request({
    url: route,
    method,
    headers,
    data: body && JSON.stringify(body),
  });
}
