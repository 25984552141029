import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { translate } from 'react-i18next';
import DatePicker from '@material-ui/core/TextField';
import _ from 'lodash';
import {
  Grid, Header, Dropdown, Divider, Button,
  Input, Checkbox, List, Label, Icon,
} from 'semantic-ui-react';
import NotificationList from './components/NotificationList';
import service from './services';
import Toast from '../../../common/components/toast';
import { scrollToRef } from '../../../common/utils';

const DESTINATION = [
  { key: 1, text: 'General', value: 'general' },
  { key: 2, text: 'Usuario', value: 'user' },
  { key: 3, text: 'Cliente', value: 'customer' },
];

function validateData(data) {
  const {
    target, title, message, user, customer, enableDate, expiresAt,
  } = data;
  return (
    !title
    || !message
    || (target === 'user' && !user)
    || (target === 'customer' && !customer)
    || (enableDate && !expiresAt)
  );
}

function getTargetValue({ target, targetUsers, customer }) {
  if (target === 'general') return [];
  if (target === 'customer') return [customer];
  return targetUsers.map(({ id }) => id);
}

function getTableHeader(handleDelete) {
  return ([
    { key: '_id', isKey: true },
    { key: 'title', name: 'Titulo' },
    { key: 'message', name: 'Contenido' },
    { key: 'target', name: 'Para', format: cell => cell.type },
    { key: 'link', name: 'URL' },
    {
      key: 'createdAt',
      name: 'Fecha de creación',
      format: cell => (`${moment(cell).format('DD/MM/YYYY')}`),
    },
    {
      key: 'expiresAt',
      name: 'Fecha de expiración',
      format: cell => (cell ? `${moment(cell).format('DD/MM/YYYY')}` : 'Nunca'),
    },
    {
      key: 'actions',
      name: '',
      format: (cell, row) => (
        <Grid.Row>
          <Button color="blue" icon="trash alternate" onClick={() => handleDelete(row)} />
        </Grid.Row>
      ),
    },
  ]);
}

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.usersHash = {};
    this.headerRef = React.createRef();
    this.state = {
      notifications: [],
      notificationHeaders: getTableHeader(this.handleDelete),
      targetUsers: [],
      target: 'general',
      id: null,
      title: '',
      message: '',
      customers: [],
      customer: '',
      users: [],
      user: '',
      link: '',
      shouldClean: false,
      messageTable: '',
      expiresAt: '',
      enableDate: false,
      userLoader: false,
      endError: '',
    };
  }

  componentDidMount() {
    scrollToRef(this.headerRef);
    this.getCatalogs();
    this.getNotificationList();
  }

  getCatalogs = async () => {
    const { customers } = await service.getCustomers();
    const customerFormatted = customers.map(customer => ({
      key: customer.id,
      text: `${customer.id} - ${customer.tradingName}`,
      value: customer.id,
    }));
    this.setState({ customers: customerFormatted });
  }

  getNotificationList = async () => {
    const { notifications } = await service.getNotifications();
    this.setState({ notifications });
  }

  handleDelete = row => {
    const { t } = this.props;
    service.deleteNotification(row._id)
      .then(() => {
        this.getNotificationList();
      })
      .catch(() => Toast(t('notifications.error.deleteFail'), 'warning'));
  }

  handleTargetChange = (e, { value }) => {
    const { customer } = this.state;
    if (value === 'user' && customer) this.getUsersByCustomer(customer);
    this.setState({ target: value });
  }

  handleUserChange = (e, { value }) => {
    if (!value) return;
    this.setState(prevState => ({ targetUsers: _.uniq([...prevState.targetUsers, this.usersHash[value]]) }));
    this.setState({ user: value });
  }

  handleCustomerChange = (e, { value }) => {
    const { target } = this.state;
    this.setState({ customer: value });
    if (target === 'user') {
      this.setState({ userLoader: true });
      this.getUsersByCustomer(value);
    }
  }

  getUsersByCustomer = async customerId => {
    const { users } = await service.getUsersByCustomer(customerId);
    const usersFormatted = users.map(user => {
      if (!this.usersHash[user.id]) this.usersHash[user.id] = user;
      return ({
        key: user.id,
        text: `${user.email} - ${user.name} ${user.surname}`,
        value: user.id,
      });
    });
    this.setState({ users: usersFormatted, userLoader: false });
  }

  handleInput = (e, { name, value }) => {
    this.setState({ [name]: value });
  }

  handleDate = ({ target }) => {
    const { value, name } = target;
    this.setState({ [name]: value });
  };

  isValidData = () => {
    const { t } = this.props;
    const { expiresAt } = this.state;
    const endDate = moment(expiresAt, 'YYYY-MM-DD');
    if (validateData(this.state)) {
      Toast(t('notifications.error.empty'), 'error');
      return false;
    }
    if (endDate.isValid() && moment().isAfter(endDate)) {
      Toast(t('notifications.error.biggerThanEnd'), 'error');
      return false;
    }
    return true;
  }

  handleAdd = notificationData => {
    const { t } = this.props;
    service.addNotification(notificationData)
      .then(() => {
        Toast(t('notifications.added'), 'success');
        this.getNotificationList();
        this.cleanState();
      })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  };

  cleanState = () => {
    this.setState({
      target: 'general',
      id: null,
      title: '',
      message: '',
      link: '',
      customer: '',
      targetUsers: [],
      expiresAt: '',
      endError: '',
    });
  }

  handleSave = () => {
    const {
      target, title, message, link, expiresAt,
    } = this.state;
    const value = getTargetValue(this.state);
    if (this.isValidData()) {
      const formatedData = {
        target: { type: target, value },
        title,
        message,
        link,
        expiresAt: !expiresAt ? null : moment(expiresAt).format('DD/MM/YYYY'),
      };
      this.handleAdd(formatedData);
    }
  }

  handleCheck = (e, data) => {
    this.setState({ enableDate: data.checked });
    if (!data.checked) this.setState({ expiresAt: '' });
  }

  deleteUser = userId => {
    this.setState(currentState => ({ targetUsers: _.filter(currentState.targetUsers, ({ id }) => id !== userId) }));
  };

  render() {
    const { t } = this.props;
    const {
      title, message, expiresAt, endError, users, user, userLoader,
      target, customers, link, customer, enableDate, targetUsers,
      shouldClean, messageTable, notifications, notificationHeaders, id,
    } = this.state;
    const disableSave = validateData(this.state);
    return (
      <Grid>
        <Grid.Row>
          <div ref={this.headerRef} />
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('notifications.title')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('notifications.moduleDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('notifications.label.title')}</Header>
            <Input
              name="title"
              value={title}
              onChange={this.handleInput}
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('notifications.label.message')}</Header>
            <Input
              name="message"
              value={message}
              onChange={this.handleInput}
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={7} mobile={15}>
            <Header className="no-margin-bottom" as="h5">{t('notifications.label.link')}</Header>
            <Input
              name="link"
              value={link}
              onChange={this.handleInput}
              fluid
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={7} mobile={15}>
            <div style={{ display: 'flex' }}>
              <Header className="no-margin-top no-margin-bottom" style={{ marginRight: '1em' }} as="h5">
                {t('notifications.label.expiresAt')}
              </Header>
              <Checkbox
                checked={enableDate}
                onChange={this.handleCheck}
              />
            </div>
            <DatePicker
              type="date"
              name="expiresAt"
              format="dd/mm/yyyy"
              value={expiresAt}
              onChange={this.handleDate}
              InputLabelProps={{ shrink: true }}
              disabled={!enableDate}
              error={endError}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={3} tablet={7} mobile={15} textAlign="left">
            <Header className="no-margin-bottom" as="h5">{t('notifications.label.audience')}</Header>
            <Dropdown
              placeholder={t('notifications.label.audience')}
              options={DESTINATION}
              value={target}
              onChange={this.handleTargetChange}
              fluid
              selection
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={7} mobile={15} textAlign="left">
            <Header className="no-margin-bottom" as="h5">{t('notifications.label.customers')}</Header>
            <Dropdown
              placeholder={t('notifications.label.customers')}
              options={customers}
              value={customer}
              fluid
              disabled={target === 'general'}
              onChange={this.handleCustomerChange}
              selection
            />
          </Grid.Column>
          <Grid.Column computer={3} tablet={7} mobile={15} textAlign="left">
            <Header className="no-margin-bottom" as="h5">{t('notifications.label.users')}</Header>
            <Dropdown
              options={users}
              value={user}
              disabled={target !== 'user' || !customer}
              onChange={this.handleUserChange}
              selection
              fluid
              labeled
              className="icon pointer"
              search
              text={t('notifications.label.users')}
              loading={userLoader}
            />
          </Grid.Column>
          <Grid.Column computer={7} tablet={7} mobile={15} textAlign="left">
            <List horizontal>
              {targetUsers.map(({ email, id: userId }) => (
                <List.Item>
                  <Label key={userId}>
                    {email}
                    <Icon name="delete" style={{ cursor: 'pointer' }} onClick={() => this.deleteUser(userId)} />
                  </Label>
                </List.Item>
              ))}
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16} tablet={16} mobile={16} textAlign="center">
            <Button
              size="medium"
              onClick={this.cleanState}
            >
              <span>{t('cancel')}</span>
            </Button>
            <Button
              size="medium"
              color="blue"
              onClick={this.handleSave}
              disabled={disableSave}
            >
              <span>{!id ? t('save') : t('edit')}</span>
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider className="no-margin-top no-margin-bottom" section />
        <Grid.Row>
          <Grid.Column>
            <NotificationList
              clean={shouldClean}
              updateClean={() => this.setState({ shouldClean: !shouldClean })}
              notifications={notifications}
              headers={notificationHeaders}
              messageTable={messageTable}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

Notifications.defaultProps = {
  t: () => { },
};

Notifications.propTypes = {
  t: PropTypes.func,
};

export default translate('common', { wait: true })(Notifications);
