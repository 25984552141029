import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Header, Dropdown, Grid, Label, Image, Button,
} from 'semantic-ui-react';
// import validator from 'validator';
import SiclikTable from '../../../common/components/table';
import Toast from '../../../common/components/toast';
import products from '../../services';

class SubstituteProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilityHeaders: [
        { key: 'id', isKey: true },
        { key: 'sku', name: 'Sku' },
        { key: 'name', name: 'Nombre' },
        {
          key: 'image',
          format: cell => (cell ? <Image src={cell} size="small" /> : <Image spaced="rigth" size="small"> </Image>),
          name: 'imagen',
        },
        { key: 'price', name: 'Precio' },
        { key: 'promotionPrice', name: 'Precio Promo' },
        { key: 'stock', name: 'Disponibilidad' },
        { key: 'addToCart', name: 'Agregar', format: this.addItemAction },
      ],
      // hpMapIdSelected: false,
      // mapIdHpInputView: false,
      // hpMapIdError: '',
      // hpMapId: '',
    };
  }

  addItemAction = (cell, row) => {
    const { t, getShoppingCartCount } = this.props;
    const { btnLoader } = this.state;
    return (
      <Button
        color="blue"
        icon="shopping cart"
        size="medium"
        loading={btnLoader}
        onClick={async () => {
          this.setState({ btnLoader: true });
          try {
            await products.addItems({
              items: [{
                sku: row.sku,
                quantity: 1,
                outlet: false,
              }],
            });
            this.setState({ btnLoader: false });
            getShoppingCartCount();
            Toast(t('productDetails.productInCart'), 'success');
          } catch (error) {
            this.setState({ btnLoader: false });
            Toast(t(`error-codes.${error.code}`, 'error'));
          }
        }}
      />
    );
  };

  // handleSelectHpId = (e, { checked }) => {
  //   this.setState({ hpIdSelected: checked, mapIdHpInputView: checked });
  //   this.props.handleGetsteppedPriceSelection(checked);
  // }

  // handleHpMapIdChange = event => {
  //   const { t } = this.props;
  //   const maxLength = 20;
  //   let errors = false;
  //   const { value: hpMapId } = event.target;
  //   if (hpMapId.length > 0) {
  //     if ((hpMapId.trim()).length > maxLength) {
  //       errors = true;
  //       this.setState({ hpMapId, hpMapIdError: t('productDetails.errors.toLong') });
  //     }
  //     if (!validator.isNumeric(hpMapId)) {
  //       errors = true;
  //       this.setState({ hpMapId, hpMapIdError: t('multibrand.errors.notNumeric') });
  //     }
  //   }
  //   if (errors !== true) {
  //     this.setState({ hpMapId, hpMapIdError: '', mistakes: errors });
  //   }
  //   this.props.handleGetHpMapId(hpMapId, errors);
  // }

  render() {
    const { t, arraySubstituteProducts } = this.props;
    const { availabilityHeaders } = this.state;
    return (
      <Grid>
        {
          arraySubstituteProducts.length > 0
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={18} computer={18} tablet={18} mobile={18}>
                  <Label as="a" color="white">
                    <Dropdown
                      button
                      text={<Header color="blue" as="h4">{t('Productos con disponibilidad')}</Header>}
                      floating
                      labeled
                    >
                      <Dropdown.Menu>
                        <Dropdown.Header
                          content={(
                            <SiclikTable
                              selectable
                              singleSelection
                              data={arraySubstituteProducts}
                              headers={availabilityHeaders}
                              pageSize={5}
                            />
                          )}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Label>
                </Grid.Column>
              </Grid.Row>
            )
            : null
        }
      </Grid>
    );
  }
}
SubstituteProducts.defaultProps = {
  t: () => {},
  // handleGetHpMapId: () => { },
  // handleGetsteppedPriceSelection: () => { },
};

SubstituteProducts.propTypes = {
  arraySubstituteProducts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // handleGetsteppedPriceSelection: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  // handleGetHpMapId: PropTypes.func,
};
export default translate('common', { wait: true })(SubstituteProducts);
