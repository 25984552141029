import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Divider, Button, Dimmer, Loader, TextArea, Form,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import validator from 'validator';
import service from '../services';
import { escapeAccents } from '../../common/utils';
import Toast from '../../common/components/toast';
import SearchBySku from '../../common/components/searchBySku';
import Specifications from './products-specifications';

class manageProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrayProducts: [],
      formatedProducts: [],
      sku: '',
      loader: false,
      productLoader: false,
      loaderConfig: false,
      productDescription: '',
      promotionalMessage: '',
    };
  }

  componentDidMount() {
    this.handleGetProductsList();
  }

  handleGetProductsList = () => {
    service.getAllListProducts()
      .then(productList => {
        const formatedProducts = productList.product.map(product => {
          const productConfig = {
            key: product.sku,
            text: product.sku,
            value: product.sku,
          };
          return productConfig;
        });
        this.setState({ arrayProducts: [...productList.product], formatedProducts, productLoader: false });
      })
      .catch(() => {
        Toast('Error al cargar los productos', 'error');
        this.setState({ productLoader: false });
      });
  }

  handleGetProductsChange = data => {
    if (data.value !== null) {
      this.handleGetDescriptionBySku(data.value);
      this.handleGetPromotionalMessage(data.value);
    }
    this.setState({ sku: data.value, loaderConfig: true });
  }

  handleDescriptionChange = (e, { value }) => {
    this.setState({ productDescription: value });
  }

  handlePromotionalMessageChange = (e, { value }) => {
    this.setState({ promotionalMessage: value });
  }

  handleValidateDescription = () => {
    const { productDescription } = this.state;
    const maxLength = 3024;
    let messageError = '';
    let mistakes = false;
    if (productDescription && productDescription.length > 0) {
      const validateDescription = escapeAccents(validator.blacklist(productDescription, ' "":¿?!¡.,-/"'));
      if (!validator.isAlphanumeric(validateDescription.replace(/ /g, ''), ['es-ES'])) {
        messageError = 'El campo no debe de contener caracteres especiales"';
        Toast(messageError, 'error');
        mistakes = true; return;
      }
      if (productDescription.length > maxLength) {
        messageError = 'La longitud del texto es   mayor al límite permitido';
        Toast(messageError, 'error');
        mistakes = true; return;
      }
    }
    if (!mistakes) this.handleSaveDescription();
  }

  handleValidatePromotionalMessage = () => {
    const { promotionalMessage } = this.state;
    const maxLength = 500;
    let messageError = '';
    let mistakes = false;
    if (promotionalMessage && promotionalMessage.length > 0) {
      const validateDescription = escapeAccents(validator.blacklist(promotionalMessage, ' "":¿?!¡.,-/"'));
      if (!validator.isAlphanumeric(validateDescription.replace(/ /g, ''), ['es-ES'])) {
        messageError = 'El campo no debe de contener caracteres especiales';
        Toast(messageError, 'error');
        mistakes = true; return;
      }
      if (promotionalMessage.length > maxLength) {
        messageError = 'La longitud del texto es   mayor al límite permitido';
        Toast(messageError, 'error');
        mistakes = true; return;
      }
    }
    if (!mistakes) this.handleSavePromotionalMessage();
  }

  handleGetDescriptionBySku = sku => {
    const { t } = this.props;
    service.getProductDescription(encodeURIComponent(sku))
      .then(response => {
        this.setState({ productDescription: response.description, loaderConfig: false });
      })
      .catch(error => {
        let messageError = '';
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleSaveDescription = () => {
    const { sku, productDescription } = this.state;
    const { t } = this.props;
    const body = {
      sku: encodeURIComponent(sku),
      description: productDescription,
    };
    service.addProductDescription(body)
      .then(response => {
        this.setState({ productDescription: response.description, loaderConfig: false });
        Toast('Se actualizo la descripción', 'success');
      })
      .catch(error => {
        let messageError = '';
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleSavePromotionalMessage = () => {
    const { sku, promotionalMessage } = this.state;
    const { t } = this.props;
    const body = {
      sku,
      promotionalMessage,
    };
    service.addPromotionalMessage(body)
      .then(response => {
        this.setState({ promotionalMessage: response.promotionalMessage, loaderConfig: false });
        Toast('Se actualizo el mensaje promocional', 'success');
      })
      .catch(error => {
        let messageError = '';
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleGetPromotionalMessage = sku => {
    const { t } = this.props;
    service.getPromotionalMessage(encodeURIComponent(sku))
      .then(response => {
        this.setState({ promotionalMessage: response.promotionalMessage, loaderConfig: false });
      })
      .catch(error => {
        let messageError = '';
        messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  render() {
    const { t } = this.props;
    const {
      sku, arrayProducts, loader, loaderConfig, productLoader, productDescription,
      promotionalMessage, formatedProducts,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <Header className="titles-menu" color="blue">{t('updateProduct.updateProduct')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider className="margin-divider" />
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p>{t('updateProduct.updateProductDescription')}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle" textAlign="center">
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5} textAlign="right">
            <Header as="h4" color="black" className="titles-news-gothic">{t('adminConfiguration.searchProduct')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={11} textAlign="left">
            {
              (!loader && !productLoader)
                ? (
                  <SearchBySku
                    search={this.handleGetProductsChange}
                    products={arrayProducts}
                    label={t('adminConfiguration.selectSku')}
                  />
                )
                : (
                  <Dimmer active inverted>
                    <Loader active size="medium" />
                  </Dimmer>
                )
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
        {
          sku !== ''
            ? (
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Header as="h4" color="black" className="titles-news-gothic">{t('updateProduct.productDescription')}</Header>
                  <p>{t('updateProduct.productDescriptionText')}</p>
                </Grid.Column>
              </Grid.Row>
            )
            : ''
        }
        <Grid.Row>
          {
            loaderConfig === false
              ? (
                <Grid.Column width={16} textAlign="center">
                  {
                    sku !== ''
                      ? (
                        <Form>
                          <Form.Field>
                            <TextArea
                              placeholder={t('updateProduct.productDescription')}
                              onChange={this.handleDescriptionChange}
                              value={productDescription}
                            />
                          </Form.Field>
                          <Button
                            color="gray"
                            onClick={() => this.handleValidateDescription()}
                          >
                            {t('adminConfiguration.update')}
                          </Button>
                        </Form>
                      )
                      : ''
                  }
                </Grid.Column>
              )
              : (
                <Dimmer active inverted>
                  <Loader active size="small" />
                </Dimmer>
              )
}
        </Grid.Row>
        {sku !== '' ? <Divider section /> : ''}
        {
          sku !== ''
            ? (
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Header as="h4" color="black" className="titles-news-gothic">{t('updateProduct.promotionalMsg')}</Header>
                  <p>{t('updateProduct.promotionalMsgText')}</p>
                </Grid.Column>
              </Grid.Row>
            )
            : ''
        }
        <Grid.Row>
          {
            loaderConfig === false
              ? (
                <Grid.Column width={16} textAlign="center">
                  {sku !== ''
                    ? (
                      <Form>
                        <Form.Field>
                          <TextArea
                            placeholder={t('updateProduct.promotionalMsg')}
                            onChange={this.handlePromotionalMessageChange}
                            value={promotionalMessage}
                          />
                        </Form.Field>
                        <Button
                          color="gray"
                          onClick={() => this.handleValidatePromotionalMessage()}
                        >
                          {t('adminConfiguration.update')}
                        </Button>
                      </Form>
                    )
                    : ''}
                </Grid.Column>
              )
              : (
                <Dimmer active inverted>
                  <Loader active size="small" />
                </Dimmer>
              )
          }
        </Grid.Row>
        {sku !== '' ? <Divider section /> : ''}
        {
          sku !== ''
            ? (
              <Grid.Row>
                <Grid.Column width={16}>
                  <Specifications sku={sku} formatedProducts={formatedProducts} />
                </Grid.Column>
              </Grid.Row>
            )
            : ''
        }
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(manageProductDetail);
manageProductDetail.defaultProps = {
  t: null,
};

manageProductDetail.propTypes = {
  t: PropTypes.func,
};
