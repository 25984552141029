import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
import FinalUser from '../../final-user';

const AddFinalUser = ({ t, getNewUser, addFinalUser }) => (
  <Modal size="fullscreen" trigger={<Button>{t('multibrand.newFinalUser')}</Button>} closeIcon>
    <Modal.Header content={<Header color="blue" textAlign="center" as="h3">{t('multibrand.newFinalUser')}</Header>} />
    <Modal.Content>
      <br />
      <FinalUser getNewUser={getNewUser} onboarding={false} addFinalUser={addFinalUser} />
    </Modal.Content>
  </Modal>
);

AddFinalUser.propTypes = {
  getNewUser: PropTypes.func,
  addFinalUser: PropTypes.func,
};

AddFinalUser.defaultProps = {
  getNewUser: () => {},
  addFinalUser: () => {},
};

export default AddFinalUser;
