import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import validator from 'validator';
import {
  Button, Grid, GridRow, Header, Icon, Confirm,
} from 'semantic-ui-react';
import deliveryAddress from '../services';
import { TextField } from '../../common/components/materials';
import TableDocuments from '../../common/components/table';
import Toast from '../../common/components/toast';

class DocsDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copy: '',
      copyError: '',
      addDocuments: [],
      headers: [
        { key: 'id', isKey: true },
        { key: 'type', name: 'Documento' },
        { key: 'copies', name: 'Número de copias' },
        {
          key: 'actions',
          name: '',
          format: (cell, row) => (
            <GridRow>
              <Button color="blue" icon="pencil alternate" onClick={() => this.handleClickUpdate(row)} />
            </GridRow>
          ),
        },
      ],
      docType: [
        { value: 1, label: 'Factura' },
        { value: 2, label: 'Orden de Compra' },
        { value: 3, label: 'Remisión' },
        { value: 4, label: 'Orden de compra usuario final' },
      ],
      messageTable: '',
      disabled: false,
      disabledDelete: true,
      documentsSelected: [],
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    if (id.length > 10) this.handleGetDocuments();
  }

  handleGetDocuments = async () => {
    const { t, id } = this.props;
    const arrayDocuments = await deliveryAddress.getDocuments(id).catch(error => {
      if (!error.code) {
        Toast(t('error-codes.default'), 'error');
      } else if (Number(error.code) === 1005) {
        this.setState({ messageTable: t('branchOffice.message.table') });
      } else {
        Toast(t(`error-codes.${error.code}`), 'error');
      }
    });
    if (arrayDocuments && arrayDocuments.documents) this.setState({ addDocuments: arrayDocuments.documents });
  }

  handleCopyChange = event => {
    const { t } = this.props;
    const maxLength = 10;
    const { value: copy } = event.target;
    if (copy && copy.length > 0) {
      if (Number(copy) > maxLength) { this.setState({ copy, copyError: t('El número máximo de copias por documento son 10') }); return; }
      if (copy <= 0) { this.setState({ copy: '', copyError: '' }); return; }
      if (!validator.isNumeric(copy)) { this.setState({ copy, copyError: t('branchOffice.errors.notNumeric') }); return; }
    }
    this.setState({ copy, copyError: '' });
  }

  handleAddDocument = () => {
    const { t, id } = this.props;
    const {
      copy, docTypeBy, docType, addDocuments,
    } = this.state;
    const body = { documentTypeId: docTypeBy, copies: copy };
    const { label } = docType.find(item => item.value === docTypeBy);
    deliveryAddress.addDocuments(id, body).then(response => {
      addDocuments.push({
        id: docTypeBy, type: label, copies: copy, commentId: response.id,
      });
      Toast('branchOffice.message.confirmDocumentAdd', 'success');
      this.setState({ addDocuments, copy: '', docTypeBy: '' });
    }).catch(error => {
      if (!error.code) {
        Toast(t('error-codes.default'), 'error');
      } else if (Number(error.code) === 1005 || Number(error.code) === 1000) {
        this.setState({ messageTable: t('branchOffice.message.table') });
      } else {
        Toast(t(`error-codes.${error.code}`), 'error');
      }
    });
  }

  validations = action => {
    let mistakes = false;
    const { copy, docTypeBy } = this.state;
    let { copyError, docTypeError } = this.state;
    const { t } = this.props;
    if (copy.trim().length === 0) {
      copyError = t('branchOffice.errors.empty');
      mistakes = true;
    }
    if (!docTypeBy) {
      docTypeError = t('branchOffice.errors.empty');
      mistakes = true;
    }
    if (copyError.length !== 0) mistakes = true;
    if (mistakes === false) {
      if (action === 1) this.handleAddDocument();
      if (action === 2) this.handleUpdateDocument();
    } else {
      this.setState({ copyError, docTypeError });
    }
  }

  handleClickUpdate = documents => {
    const docTypeBy = Number((documents.type).replace('Orden de Compra', 2).replace('Factura', 1).replace('Remisión', 3).replace('Orden de compra usuario final', 4));
    this.setState({
      copyError: '',
      docTypeError: '',
      docTypeBy,
      copy: documents.copies,
    });
  }

  handleSelectDocument = documents => {
    if (documents.length === 1) {
      const docTypeBy = Number((documents[0].type)
        .replace('Orden de Compra', 2)
        .replace('Factura', 1)
        .replace('Remisión', 3)
        .replace('Orden de compra usuario final', 4));
      this.setState({
        disabled: true,
        copyError: '',
        docTypeError: '',
        disabledDelete: false,
        docTypeBy,
        documentsSelected: documents,
        copy: documents[0].copies,
      });
    } else if (documents.length > 1) {
      this.setState({
        disabled: false,
        copyError: '',
        docTypeError: '',
        docTypeBy: '',
        copy: '',
        disabledDelete: false,
        documentsSelected: documents,
      });
    } else {
      this.setState({
        disabledDelete: true, disabled: false, copyError: '', docTypeError: '', docTypeBy: '', copy: '',
      });
    }
  }

  handleUpdateDocument = () => {
    const { t, id } = this.props;
    const {
      copy, docTypeBy, docType, addDocuments: stateAddDocuments,
    } = this.state;
    const body = {
      documentTypeId: docTypeBy,
      copies: copy,
    };
    const { label } = docType.find(item => item.value === docTypeBy);
    deliveryAddress.addDocuments(id, body).then(() => {
      const addDocuments = stateAddDocuments.map(currentItem => {
        const item = { ...currentItem };
        if (item.type === label) item.copies = copy;
        return item;
      });
      Toast(t('branchOffice.message.confirmDocumentUpdate'), 'success');
      this.setState({
        clean: true, disabled: false, addDocuments, copy: '', docTypeBy: '',
      });
    }).catch(error => {
      let messageError = '';
      if (!error.code) {
        messageError = t('error-codes.default');
      } else {
        messageError = t(`error-codes.${error.code}`);
      }
      Toast(messageError, 'error');
    });
  }

  handleDeleteDocuments = () => {
    const { documentsSelected } = this.state;
    if (documentsSelected.length >= 1) this.setState({ open: true });
  }

  handleConfirm = () => {
    const { documentsSelected } = this.state;
    const { id } = this.props;

    documentsSelected.map(currentDocument => {
      const document = { ...currentDocument };
      deliveryAddress.deleteDocument(id, document.id).then(() => {
        this.handleUpdateDocumentsArray(document.id);
      }).catch(error => {
        document.error = error;
        Toast(`Ocurrio un error al eliminar el tipo de documento: ${document.type}, favor de intentarlo de nuevo`, 'error');
      });
      return document;
    });

    this.setState({
      docTypeBy: '',
      docTypeError: '',
      copyError: '',
      clean: true,
      copy: '',
      disabled: false,
      disabledDelete: true,
      open: false,
      documentsSelected: [],
    });
  }

  handleUpdateDocumentsArray = id => {
    const { addDocuments } = this.state;
    const { t } = this.props;
    const documentIndex = addDocuments.findIndex(document => document.id === id);
    addDocuments.splice(documentIndex, 1);
    this.setState(addDocuments);
    Toast(t('branchOffice.message.confirmDocumentDelete'), 'success');
  }

  handleCancel = () => this.setState({ open: false, documentsSelected: [], clean: true });

  handleClickCancelUpdate = () => this.setState({
    docTypeBy: '',
    docTypeError: '',
    copyError: '',
    clean: true,
    copy: '',
    disabled: false,
    disabledDelete: true,
    open: false,
    documentsSelected: [],
  });

  render() {
    const { t } = this.props;
    const {
      headers, docType, docTypeBy, copy, copyError, docTypeError, addDocuments,
      messageTable, disabled, documentsSelected, clean, disabledDelete, open,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Header as="h3">{t('branchOffice.title4')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
            <TextField
              select
              fullWidth
              label={t('branchOffice.documentType')}
              disabled={disabled}
              value={docTypeBy || ''}
              error={docTypeError || ''}
              onChange={({ target }) => this.setState({ docTypeBy: target.value, docTypeError: '' })}
              options={docType}
              required
            />
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={16}>
            <TextField
              fullWidth
              maxLength={2}
              value={copy || ''}
              error={copyError || ''}
              label={t('branchOffice.copy')}
              onChange={this.handleCopyChange}
              required
            />
          </Grid.Column>
          {!disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
                <Button color="blue" onClick={() => this.validations(1)} disabled={disabled}>{t('add')}</Button>
              </Grid.Column>
            )
            : null}
          {disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={7}>
                <Button color="blue" content={t('update')} onClick={() => this.validations(2)} />
              </Grid.Column>
            ) : null}
          {disabled
            ? (
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={6}>
                <Button color="blue" content={t('cancel')} onClick={this.handleClickCancelUpdate} />
              </Grid.Column>
            ) : null}
          <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={2}>
            <Button disabled={disabledDelete} color="blue" icon onClick={this.handleDeleteDocuments}>
              <Icon name="trash alternate" />
            </Button>
          </Grid.Column>
        </Grid.Row>
        <GridRow>
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16}>
            <TableDocuments
              headers={headers}
              data={addDocuments}
              noDataMessage={messageTable}
              getSelectedRows={this.handleSelectDocument}
              clean={clean}
              updateClean={() => this.setState({ clean: !clean })}
            />
          </Grid.Column>
        </GridRow>
        <Grid.Row>
          <Confirm
            open={open}
            header={t('branchOffice.deleteDocument')}
            content={documentsSelected.map(item => <p>{item.type}</p>)}
            cancelButton={t('cancel')}
            confirmButton={t('yes')}
            onCancel={this.handleCancel}
            onConfirm={this.handleConfirm}
          />
        </Grid.Row>
      </Grid>
    );
  }
}

DocsDelivery.propTypes = {
  id: PropTypes.string,
};

DocsDelivery.defaultProps = {
  id: '',
};

export default translate('common', { wait: true })(DocsDelivery);
