import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Container, Divider, Responsive,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import { currencyFormat } from '../../common/utils';

const Orders = props => {
  const {
    t, currency: propCurrency, ordersData, ordersHeaders,
  } = props;
  const currency = propCurrency === 2 ? 'USD' : 'MXN';
  return (
    <Grid padded>
      {ordersData.map(order => (
        <Grid.Row className="no-padding">
          <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={16} textAlign="left" verticalAlign="middle" className="padding-orders-title">
            <Header as="h5" color="blue">
              {t('checkout.addressee')}
              <Header as="h5" color="blue" style={{ display: 'inline' }}>{order.branchOffice}</Header>
            </Header>
            <Responsive maxWidth={768}>
              <br />
            </Responsive>
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16} textAlign="left" verticalAlign="middle" className="padding-orders-title">
            <Header as="h5" color="black">
              IVA:&nbsp;
              <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(order.iva, currency)}</Header>
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16} textAlign="left" verticalAlign="middle" className="padding-orders-title">
            <Header as="h5" color="black">
              Subtotal:&nbsp;
              <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(order.subtotal, currency)}</Header>
            </Header>
          </Grid.Column>
          {order.creditDays ? <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} /> : null}
          {order.creditDays
            ? (
              <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={4} textAlign="right">
                <Header as="h5" color="black">
                  Dias totales de crédito:&nbsp;
                  <Header as="h5" color="green" style={{ display: 'inline' }}>{order.creditDays}</Header>
                </Header>
              </Grid.Column>
            )
            : null}
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={15}>
            <Container>
              <SiclikTable
                selectable
                data={order.items}
                headers={ordersHeaders}
                pageSize={5}
              />
              <Divider section />
            </Container>
          </Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};
Orders.defaultProps = {
  currency: 1,
  ordersData: [],
  ordersHeaders: [],
};

Orders.propTypes = {
  currency: PropTypes.number,
  ordersData: PropTypes.arrayOf(PropTypes.shape({})),
  ordersHeaders: PropTypes.arrayOf(PropTypes.shape({})),
};
export default translate('common', { wait: true })(Orders);
