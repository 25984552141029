import imageCompression from 'browser-image-compression';
import loadImage from 'blueimp-load-image';
import queryString from 'query-string';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import Fuse from 'fuse.js';

import assets from '../layouts/comercio/components';
import Amazon from '../upload-s3/services';
import routes from '../route-names';

const urlAWS = process.env.REACT_APP_URL_AWS;

export const formatDecimalNumber = number => number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const pureCurrencyFormat = (myNumber, currency = 'MXN') => (new Intl.NumberFormat('es-MX', { style: 'currency', currency, currencyDisplay: 'symbol' }).format(myNumber));

export const currencyFormat = (myNumber, currency = 'MXN') => (`$${formatDecimalNumber(Number(myNumber).toFixed(2))} ${currency}`);

export const decimalFormat = myNumber => (new Intl.NumberFormat('es-MX', { style: 'decimal' }).format(myNumber));

export const cloneArrayNoRef = originalArray => JSON.parse(JSON.stringify(originalArray));

export const getPaginationArrayAndPages = (originalArray = [], pageSize) => {
  const formatedArray = [];
  let totalPages = originalArray.length / pageSize;
  let start = 0;
  let end = pageSize;
  totalPages = (totalPages % 1 > 0) ? Math.trunc(totalPages) + 1 : Math.trunc(totalPages);
  for (let index = 0; index < totalPages; index++) {
    const page = originalArray.slice(start, end);
    start = end;
    end += pageSize;
    formatedArray.push(page);
  }
  return ({ formatedArray, totalPages: totalPages > 0 ? totalPages : 1 });
};

export const getUserImage = async (
  newUrl = 'url de la imagen de Amazon',
  bucketPath = 'ruta despues del nombre del bucket hasta la carpeta donde esta',
  identifier = 'UserId del usuario que se quiere mostrar la foto',
) => {
  if (newUrl && newUrl !== 'url de la imagen de Amazon') {
    return newUrl;
  }
  const images = await Amazon.getImageS3({
    identifier,
    bucketPath,
  }).catch(() => ({}));
  if (images.files && images.files.length > 0) {
    return `${urlAWS}/${images.files[images.files.length - 1].Key}`;
  }
  return `${urlAWS}/imagenes/defaultAvatar.png`;
};
export const compareObjectsWithJSON = (object1, object2) => JSON.stringify(object1) === JSON.stringify(object2);

export const compareArray = (array1, array2) => {
  if (!array1 || !array2) return false;
  if (array1.length !== array2.length) return false;
  for (let i = 0; i < array1.length; i++) {
    if ((array1[i] instanceof Array && array2[i] instanceof Array)) {
      if (!compareArray(array1[i], array2[i])) return false;
    } else if ((array1[i] instanceof Object && array2[i] instanceof Object)) {
      if (!compareObjectsWithJSON(array1[i], array2[i])) return false;
    } else if (array1[i] !== array2[i]) return false;
  }
  return true;
};

export const escapeAccents = string => {
  let replacedCharacters = string.toLowerCase();
  replacedCharacters = replacedCharacters.replace(new RegExp(/\s/g), ' ');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[àáâãäå]/g), 'a');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[èéêë]/g), 'e');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[ìíîï]/g), 'i');
  replacedCharacters = replacedCharacters.replace(new RegExp(/ñ/g), 'n');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[òóôõö]/g), 'o');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[ùúûü]/g), 'u');
  return replacedCharacters;
};

export const escapeReasonAccents = string => {
  let replacedCharacters = string.toLowerCase();
  replacedCharacters = replacedCharacters.replace(new RegExp(/[àáâãäå]/g), 'a');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[èéêë]/g), 'e');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[ìíîï]/g), 'i');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[òóôõö]/g), 'o');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[ùúûü]/g), 'u');
  replacedCharacters = replacedCharacters.replace(new RegExp(/[ýÿ]/g), 'y');
  return replacedCharacters;
};

export const fixRotationOfFile = file => new Promise(resolve => {
  loadImage(file, img => {
    img.toBlob(blob => {
      resolve(blob);
    }, 'image/jpeg');
  }, {
    orientation: true,
  });
});

export const fixRotation = async function fixRotation(arrayOfFiles) {
  const fixedFiles = arrayOfFiles.map(file => fixRotationOfFile(file));
  return Promise.all(fixedFiles);
};

export const fixRotationWithFile = async function fixRotationWithFile(arrayOfFiles) {
  const fixedFiles = arrayOfFiles.map(async file => {
    const formatedFiles = { file };
    formatedFiles.fixed = await fixRotationOfFile(file);
    return formatedFiles;
  });
  return Promise.all(fixedFiles);
};

export const compressArrayOfFiles = async arrayOfFiles => {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };
  const compressFiles = arrayOfFiles.map(async file => imageCompression(file, options).catch(() => file));
  return Promise.all(compressFiles);
};

export function fuse(list = [{}], keys = ['']) {
  const options = {
    threshold: 0.4,
    minMatchCharLength: 1,
    keys,
  };
  return new Fuse(list, options);
}

export function controlStringLength(description = '', limit = 42) {
  const sentenceArray = description.split(' ');
  const longestObject = sentenceArray.reduce((longest, currentWord, index) => (
    currentWord.length > longest.word.length
      ? { word: currentWord, index }
      : longest
  ), { word: '', index: -1 });
  const { word, index } = longestObject;
  if (word.length > limit) {
    const firstString = sentenceArray.slice(0, index).join(' ');
    const cuttedString = `${sentenceArray[index].slice(0, limit)}...`;
    return firstString ? `${firstString} ${cuttedString}` : cuttedString;
  }
  return description;
}

export const scrollToRef = ref => {
  const { current } = ref;
  if (current) {
    current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

/* eslint-disable */
export function lightenDarkenColor(color = '#000', quantity = 0) {
  const usePound = color[0] === '#';
  const colorString = usePound ? color.slice(1) : color;
  const numberColor = parseInt(colorString, 16);

  let red = (numberColor >> 16) + quantity;
  if (red > 255) red = 255;
  else if (red < 0) red = 0;

  let blue = ((numberColor >> 8) & 0x00FF) + quantity;
  if (blue > 255) blue = 255;
  else if (blue < 0) blue = 0;

  let green = (numberColor & 0x0000FF) + quantity;
  if (green > 255) green = 255;
  else if (green < 0) green = 0;

  return `${usePound ? '#' : ''}${(green | (blue << 8) | (red << 16)).toString(16)}`;
}
/* eslint-enable */

export const getFieldNameHashObject = (fieldName, catalog = []) => {
  const hashTable = {};
  catalog.map(currentElement => {
    const fieldValue = currentElement[fieldName];
    if (!hashTable[fieldValue]) hashTable[fieldValue] = 1;
    else hashTable[fieldValue] += 1;
    return null;
  });
  return hashTable;
};

export const getFieldNameKeys = (fieldName, catalog = []) => {
  const hashTable = getFieldNameHashObject(fieldName, catalog);
  return Object.keys(hashTable);
};

export const getBrands = (catalog = []) => (
  getFieldNameKeys('maker', catalog).map(current => ({ name: current }))
);

export function promotionClickGA(promotion) {
  const { id, promotionName, position } = promotion;
  ReactGA.plugin.execute('ec', 'addPromo', {
    id,
    name: promotionName,
    position,
  });
  ReactGA.plugin.execute('ec', 'setAction', 'promo_click');
  ReactGA.event({
    category: 'Promocionales',
    action: 'click',
    label: promotionName,
  });
  ReactGA4.event({
    category: 'Promocionales',
    action: 'click',
    label: promotionName,
  });
  ReactGA.plugin.execute('ec', 'clear');
}

export function handleBannerAction(
  bannerData = {
    action: { type: '', value: '' }, promotionName: '', id: 0, position: 0,
  },
  history = () => { },
  setActiveDimmer = () => { },
) {
  const {
    action, promotionName, id, position,
  } = bannerData;
  const { type, value } = action;
  promotionClickGA({ id, promotionName, position });
  switch (type) {
    case 'image':
      setActiveDimmer(true);
      break;
    case 'detail':
      history.push(`${(routes.productDetails.route).replace(':sku', encodeURIComponent(value))}`);
      break;
    case 'search':
      history.push(`${(routes.products.route).replace(':category?', '00').replace(':search?', encodeURIComponent(value))}`);
      break;
    case 'redirect':
      window.open(value, '_blank');
      break;
    case 'route':
      history.push(value);
      break;
    default:
      break;
  }
}

export function generateSearchPath(params = { type: '', value: '' || {} }, options = { filters: [], onlyParams: false }) {
  const order = ['type', 'value', 'filters'];
  const queryParams = {
    value: undefined,
    type: undefined,
    filters: options.filters,
    url: undefined,
  };
  switch (params.type) {
    case 'brand':
      queryParams.type = params.type;
      queryParams.value = params.value;
      break;
    case 'search': {
      const isBrand = assets.brands.find(({ variants }) => variants.some(brand => params.value === brand.toLowerCase()));
      if (isBrand) {
        queryParams.type = 'brand';
        queryParams.value = isBrand.text;
        break;
      }
      queryParams.type = 'search';
      queryParams.value = params.value;
      break;
    }
    case 'pn':
      queryParams.type = 'pn';
      queryParams.value = params.value;
      break;
    case 'url':
      queryParams.url = { ...params.value, filters: options.filters };
      break;
    case 'sku':
      queryParams.type = 'sku';
      queryParams.value = params.value;
      break;
    default:
      break;
  }
  return queryString.stringifyUrl({
    url: options.onlyParams ? '' : '/productos',
    query: params.type === 'url' ? queryParams.url : queryParams,
  }, {
    encode: false,
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    sort: (a, b) => order.indexOf(a) - order.indexOf(b),
  });
}
