import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const getProductCategorizations = () => (
  request({
    route: apiRoutes.baseProductCategorizations(),
    method: 'GET',
  })
);

const getAttributesPerBrand = (brand, attribute) => (
  request({
    route: apiRoutes.getAttributesPerBrand(brand, attribute),
    method: 'GET',
  })
);

const deleteProductCategorization = (categorizationId, brandId) => (
  request({
    route: apiRoutes.deleteProductCategorization(categorizationId, brandId),
    method: 'DELETE',
  })
);

const updateProductCategorizations = body => (
  request({
    route: apiRoutes.baseProductCategorizations(),
    method: 'PUT',
    body,
  })
);

const addProductCategorizations = body => (
  request({
    route: apiRoutes.baseProductCategorizations(),
    method: 'POST',
    body,
  })
);

const service = {
  updateProductCategorizations,
  getProductCategorizations,
  deleteProductCategorization,
  getAttributesPerBrand,
  addProductCategorizations,
};

export default service;
