import { request } from '../../common/fetch-utils';
import apiRoutes from '../../apiRoutes';

const service = {};

service.tokenSuscribe = () => (
  request({
    route: apiRoutes.tokenSuscribe(),
    method: 'POST',
    body: {},
  })
);

service.getSegmentByCustomer = customerId => (
  request({
    route: apiRoutes.getSegmentByCustomer(customerId),
    method: 'GET',
  })
);

export default service;
