/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Placeholder, Card } from 'semantic-ui-react';

export default function ImageLoader() {
  const Items = key => (
    <Card key={key} style={{ height: 200, width: 200 }}>
      <Card.Content>
        <Placeholder>
          <Placeholder.Image square />
        </Placeholder>
      </Card.Content>
    </Card>
  );
  return (
    <Card.Group>
      {Array(6)
        .fill(Items)
        .map((Item, index) => <Item key={index} />)}
    </Card.Group>
  );
}
