import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import { TextField } from '../../common/components/materials';

const AddUpdate = props => {
  const {
    items, selectedItemId, error, value, handleChange, onItemClick, errorCategory, onKeyPress,
  } = props;
  return (
    <Grid container>
      <Grid.Row columns={4}>
        <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
          <TextField
            select
            fullWidth
            label="Categoría"
            value={selectedItemId || ''}
            error={errorCategory || ''}
            onChange={onItemClick}
            options={items}
          />
        </Grid.Column>
        <Grid.Column onKeyPress={onKeyPress} largeScreen={11} computer={11} tablet={11} mobile={16}>
          <TextField
            fullWidth
            maxLength={280}
            label="Descripción"
            error={error || ''}
            value={value || ''}
            onChange={handleChange}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

AddUpdate.propTypes = {
  error: PropTypes.string,
  errorCategory: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onItemClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  selectedItemId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string.isRequired,
};

AddUpdate.defaultProps = {
  error: '',
  errorCategory: '',
};

export default translate('common', { wait: true })(AddUpdate);
