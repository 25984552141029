import { request } from '../../../common/fetch-utils';
import apiRoutes from '../../../apiRoutes';

const service = {};

service.getNotifications = () => (
  request({
    route: apiRoutes.getAllNotifications(),
  })
);

service.addNotification = body => (
  request({
    route: apiRoutes.createNotification(),
    method: 'POST',
    body,
  })
);

service.getUsersByCustomer = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
  })
);

service.getCustomers = () => (
  request({
    route: apiRoutes.getCustomers(),
    method: 'GET',
  })
);

service.deleteNotification = notificationId => (
  request({
    route: apiRoutes.deleteNotification(notificationId),
    method: 'DELETE',
  })
);

export default service;
