/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Icon, Button, Header, Image, Dropdown,
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as session from '../../common/sessions';
import '../styles.css';
import uploader from '../services';
import Toast from '../../common/components/toast';
import routes from '../../route-names';
// import user from '../../profile/services';

const { login } = routes;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const mimeExcel = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel',
];

const mimeWord = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
];

const mimeImages = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

const mimeTypes = [
  'application/pdf',
  'text/plain',
  ...mimeExcel,
  ...mimeWord,
  ...mimeImages,
];

class UploadFiles extends Component {
  constructor(props) {
    super(props);
    const { userId, customerId } = session.get();
    this.state = {
      userId,
      company: customerId,
      accepted: [],
      rejected: [],
      files: [],
      acceptedPaths: [],
    };
  }

  componentDidMount() {
    const { userId, company } = this.state;
    if (!userId || !company) {
      window.location.assign(login.route);
    } else {
      this.getDocumentsTypes();
    }
  }

  componentWillUnmount() {
    const { files } = this.state;
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  onDrop = files => {
    this.setState({
      files: files.map(file => Object.assign(file, {
        preview: this.selectPreview(file),
      })),
    });
  }

  getDocumentsTypes = () => {
    const { t } = this.props;
    uploader.getDocumentsTypes()
      .then(response => {
        const documentsTypes = response.documentsTypes.map(type => {
          if (type.id === 7) return undefined;
          return {
            key: type.id, text: type.documentType, value: type.id, content: type.documentType,
          };
        });
        const dataFilter = documentsTypes.filter(Boolean);
        this.setState({ documentsTypes: dataFilter });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  getFileExtension = file => {
    const regex = /(?:\.([^.]+))?$/;
    return regex.exec(file)[1];
  }

  setNameFile = file => {
    const { order } = this.props;
    const fileName = `${order}_${file.value}_${Math.floor(Date.now())}.${this.getFileExtension(file.name)}`;
    return fileName;
  }

  saveFile = async (file, name, bucketPath, prefix, documentId, textContent) => {
    const appEndpoint = process.env.REACT_APP_ENDPOINT;
    const { t, handleAttachedAdd, panelState } = this.props;
    try {
      const urlSigned = await uploader.getSignedUrl({ name, bucketPath });
      const newUrl = await uploader.uploadImgS3(urlSigned, file);
      const urlEstructure = urlSigned.signedUrl.split('?');
      if (newUrl) {
        const serverUrl = `${appEndpoint}/pdf/search?url=/${bucketPath}/${name}`;
        const body = {
          name: textContent, type: this.getFileExtension(file.name), prefix, url: urlEstructure[0], serverUrl, documentId,
        };
        await uploader.addAttachment(body)
          .then(response => {
            body.id = response.id;
            handleAttachedAdd(body);
            return Promise.resolve(response);
          });
        panelState(false);
        Toast(t('uploadFiles.fileUploaded'), 'success');
      }
    } catch (error) {
      this.setState(Toast(`${t('error-codes.default')}`, 'error'));
    }
  };

  loadfiles = async () => {
    const { t } = this.props;
    const { company, acceptedPaths, accepted } = this.state;
    const date = moment().format('YYYY-MM-DD');
    const dateSplit = date.split('-');
    const prefix = `${dateSplit[0]}/${dateSplit[1]}`;
    const bucketPath = `${company}/ordenes/${prefix}`;
    if (accepted.length !== acceptedPaths.length) {
      Toast(t('uploadFiles.filesType'), 'error');
    } else {
      await accepted.map(file => {
        const match = acceptedPaths.find(element => element.singleName === file.name);
        this.saveFile(file, match.nameComplete, bucketPath, prefix, match.documentId, match.textContent);
        return match;
      });
    }
  }

  preparePaths = async (e, result) => {
    const { textContent } = e.nativeEvent.target;
    const { acceptedPaths } = this.state;
    const fileName = this.setNameFile(result);
    if (acceptedPaths.length > 0) {
      const index = acceptedPaths.findIndex(element => element.singleName === result.name);
      if (index !== -1) {
        acceptedPaths.splice(index, 1, {
          singleName: result.name,
          nameComplete: fileName,
          documentId: result.value,
          textContent,
        });
      } else {
        acceptedPaths.push({
          singleName: result.name,
          nameComplete: fileName,
          documentId: result.value,
          textContent,
        });
      }
    } else {
      acceptedPaths.push({
        singleName: result.name,
        nameComplete: fileName,
        documentId: result.value,
        textContent,
      });
    }
    this.setState({ acceptedPaths });
  }

  selectPreview = file => {
    if (mimeExcel.find(type => type === file.type) !== undefined) {
      return 'file excel';
    }
    if (mimeWord.find(type => type === file.type) !== undefined) {
      return 'file word';
    }
    if (file.type === 'text/plain') {
      return 'file';
    }
    if (file.type === 'application/pdf') {
      return 'file pdf';
    }
    return URL.createObjectURL(file);
  }

  render() {
    const {
      files, documentsTypes, accepted, rejected,
    } = this.state;
    const thumbs = files.map(file => {
      const identifier = file.preview.substring(0, 4);
      return (
        <div style={thumb} key={file.name}>
          <div style={thumbInner}>
            {(identifier === 'blob') ? (
              <Image
                src={file.preview}
                style={img}
              />
            ) : (
              <Icon.Group size="huge">
                <Icon name={file.preview} />
              </Icon.Group>
            )}
          </div>
        </div>
      );
    });
    const { t } = this.props;
    return (
      <Grid>
        <Grid.Row>
          <section style={{ width: '100%' }}>
            <div className="dropzone">
              <Dropzone
                className="dropzone"
                accept={mimeTypes}
                onDropAccepted={acceptedParam => { this.setState({ accepted: acceptedParam }); }}
                onDropRejected={rejectedParam => { this.setState({ rejected: rejectedParam }); }}
                onDrop={file => this.onDrop(file)}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} style={thumbsContainer}>
                    <input {...getInputProps()} />
                    <Icon name="add" circular />
                    {thumbs}
                    {(accepted.length <= 0) ? (
                      <Grid.Row>
                        <Header as="h5">
                          {t('profile.dropImage')}
                        </Header>
                        <Header as="h5">
                          {t('profile.correctImageFormat')}
                        </Header>
                      </Grid.Row>
                    ) : ''}
                  </div>
                )}
              </Dropzone>
            </div>
          </section>
        </Grid.Row>
        {(accepted.length >= 1) ? (
          <Grid.Row>
            <aside style={{ width: '100%' }}>
              <Header as="h4">{t('profile.accepted')}</Header>
              <ul>
                {accepted.map(currentFile => (
                  <li key={currentFile.name} style={{ paddingBottom: '5px' }}>
                    <Icon color="green" name="check" />
                    {currentFile.name}
                    -
                    {currentFile.size}
                    {t('profile.bytes')}
                    <Dropdown
                      selection
                      placeholder="Tipo de archivo"
                      style={{ align: 'right', marginLeft: '5px' }}
                      name={currentFile.name}
                      onChange={this.preparePaths}
                      options={documentsTypes}
                    />
                  </li>
                ))}
              </ul>
            </aside>
          </Grid.Row>
        ) : ''}
        {(rejected.length >= 1) ? (
          <Grid.Row>
            <aside>
              <Header as="h4">{t('profile.allowedFiles')}</Header>
              <ul>
                {rejected.map(f => (
                  <li key={f.name}>
                    {f.name}
                    -
                    {f.size}
                    {t('profile.bytes')}
                  </li>
                ))}
              </ul>
            </aside>
          </Grid.Row>
        ) : ''}
        <Grid.Row centered>
          <Grid.Column>
            <Button color="blue" disabled={files.length <= 0 || rejected.length >= 1} onClick={this.loadfiles}>{t('profile.save')}</Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

UploadFiles.propTypes = {
  bucketPath: PropTypes.string,
  handleAttachedAdd: PropTypes.func,
  name: PropTypes.string,
  order: PropTypes.string,
  panelState: PropTypes.func.isRequired,
  t: PropTypes.func,
};

UploadFiles.defaultProps = {
  order: '0',
  bucketPath: 'default/ordenes',
  t: () => {},
  handleAttachedAdd: () => {},
  name: '',
};

export default translate('common', { wait: true })(UploadFiles);
