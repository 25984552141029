import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const openpayRoutes = {};
openpayRoutes.createCustomer = () => (
  request({
    route: apiRoutes.createCustomer(),
    method: 'POST',
  })
);
openpayRoutes.saveCard = body => (
  request({
    route: apiRoutes.saveCard(),
    method: 'POST',
    body,
  })
);
openpayRoutes.getItems = () => (
  request({
    route: apiRoutes.getItems(),
    method: 'GET',
  })
);
openpayRoutes.getCustomerDetails = userId => (
  request({
    route: apiRoutes.getCustomerDetails(userId),
    method: 'GET',
  })
);
openpayRoutes.paymentProcess = body => (
  request({
    route: apiRoutes.paymentProcess(),
    method: 'POST',
    body,
  })
);
openpayRoutes.getAccessKeys = () => (
  request({
    route: apiRoutes.getAccessKeys(),
    method: 'GET',
  })
);
export default openpayRoutes;
