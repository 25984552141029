/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { translate } from 'react-i18next';
import {
  Form,
  Search,
  Label,
  Icon,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { without } from 'lodash';

import Toast from '../../common/components/toast';
import { findProducts } from '../services';

function ProductList(props) {
  const { t, handleSelectedItems, error } = props;
  const [productList, setProductList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [loader, setLoader] = useState(false);

  const getItems = async value => {
    try {
      setLoader(true);
      const { attributesByKeyWord } = await findProducts(value);
      const items = attributesByKeyWord.map(item => ({
        key: item.attribute,
        title: item.attribute,
        description: item.description,
      }));
      setProductList(items);
    } catch (err) {
      if (err.code !== 1300) {
        Toast(t('toast.error.general'), 'error');
      }
    } finally {
      setLoader(false);
    }
  };

  const handleChange = async (e, { value }) => {
    setKeyword(value);
    if (value !== '' && value.length > 2) {
      await getItems(value);
    }
  };

  const handleResultSelect = (e, { result }) => {
    setKeyword('');
    const alreadyExists = selectedItems.some(sku => sku === result.key);
    if (!alreadyExists) {
      const newSelectedItems = [...selectedItems, result.key];
      setSelectedItems(newSelectedItems);
      handleSelectedItems(newSelectedItems);
    }
  };

  const handleRemoveItem = sku => {
    const newSelectedItems = without(selectedItems, sku);
    setSelectedItems(newSelectedItems);
    handleSelectedItems(newSelectedItems);
  };

  return (
    <Form.Group grouped>
      <Form.Field error={error}>
        <label htmlFor="a">{t('synchronization.productList.title')}</label>
        <Search
          fluid
          loading={loader}
          value={keyword}
          results={productList}
          onSearchChange={handleChange}
          onResultSelect={handleResultSelect}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor="a">{t('synchronization.productList.selectedItems')}</label>
        {selectedItems.map(sku => (
          <Label>
            {sku}
            <Icon name="delete" onClick={() => handleRemoveItem(sku)} />
          </Label>
        ))}
      </Form.Field>
    </Form.Group>
  );
}

ProductList.propTypes = {
  handleSelectedItems: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(ProductList);
