import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid, Image, Icon } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PopMenu from './pop-menu';
// import routeNames from '../../../route-names';
import MenuHeader from '../../public/components/menu';

const assetUrl = `${process.env.REACT_APP_URL_AWS}/imagenes/assets/siclik/`;
const logo = `${assetUrl}siclik_compusoluciones.png`;

const DashboardHeader = props => {
  const [showMobile, setShowMobile] = useState(false);

  const {
    t, history, isAuthenticated,
  } = props;

  const menuMobile = () => {
    setShowMobile(!showMobile);
  };

  return (
    <Grid>
      <Grid.Row verticalAlign="middle" className="menu-bar" style={{ paddingLeft: '3%', paddingTop: '2em' }}>
        <Grid.Column
          mobile={10}
          tablet={4}
          computer={3}
          largeScreen={3}
          widescreen={3}
          textAlign="start"
          className="no-padding-x"
        >
          <Image
            src={logo}
            size="medium"
            as="a"
          />
        </Grid.Column>
        <Grid.Column
          only="largeScreen computer tablet"
          textAlign="center"
          verticalAlign="middle"
          tablet={10}
          computer={11}
          largeScreen={12}
          widescreen={12}
          className="no-padding-x"
        >
          <MenuHeader t={t} history={history} showMobile={showMobile} isAuthenticated={isAuthenticated} />
        </Grid.Column>
        <Grid.Column
          mobile={3}
          tablet={2}
          computer={2}
          largeScreen={1}
          widescreen={1}
          floated="right"
          verticalAlign="middle"
        >
          <PopMenu history={history} />
        </Grid.Column>
        <Grid.Column
          only="mobile"
          textAlign="right"
          mobile={3}
        >
          <Icon size="big" name="bars" onClick={() => menuMobile()} />
        </Grid.Column>
        <Grid.Column only="mobile" textAlign="center" verticalAlign="middle" mobile={16} tablet={16} className="no-padding-x">
          {showMobile
            ? <MenuHeader t={t} history={history} showMobile={showMobile} isAuthenticated={isAuthenticated} />
            : ''}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DashboardHeader.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(DashboardHeader);
