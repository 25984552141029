import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import {
  Button, Grid, Confirm, Modal, Header, Dropdown, Dimmer, Loader,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as ramdaSolutions from 'ramda';

import { fuse } from '../../common/utils';
import Table from '../../common/components/table';
import Toast from '../../common/components/toast';
import { TextField } from '../../common/components/materials';

import request from '../services';

function CustomersB2b2c(props) {
  const { t, customerId } = props;
  const [addLoading, setAddLoading] = useState(false);
  const [searchLoader, setSearchLoader] = useState(false);
  const [activeModal, setModalActive] = useState(false);
  const [activeNewCustomerModal, setNewCustomerModalActive] = useState(false);
  const [customerSelected, setSelectedCustomer] = useState(false);
  const [customerToAdd, setCustomerToAdd] = useState(false);
  const [filteredServiceAccounts, setFilteredServiceAccounts] = useState([]);
  const [customerToDelete, setCustomerToDelete] = useState(false);
  const [serviceAccounts, setServiceAccounts] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customersList, setCustomerList] = useState([]);
  const [filter, setFilter] = useState('');

  function handleClic(customer) {
    setSelectedCustomer({});
    setCustomerToDelete('');
    const userContext = `¿Estás seguro de eliminar al cliente "${customer.customerId} - ${customer.customerName}"?`;
    setCustomerToDelete(userContext);
    setSelectedCustomer(customer);
    setModalActive(true);
  }

  const headers = [
    { key: 'id', isKey: true },
    { key: 'customerId', name: 'ID del Cliente' },
    { key: 'customerName', name: 'Nombre del Cliente' },
    { key: 'createdAt', name: 'Fecha de Creación' },
    {
      key: 'actions',
      name: 'Acciones',
      format: (cell, row) => (
        <Button color="blue" icon="user delete" disabled={row.status === 0} onClick={() => handleClic(row)} />
      ),
    },
  ];

  const handleDelete = async () => {
    try {
      const customerDelete = customerSelected;
      const { changedRows } = await request.disableServiceAccount(customerDelete.customerId);
      if (changedRows && changedRows > 0) {
        Toast(t('b2b2c.customerDeleted'), 'success');
        // Quitar opción de serviceAccounts
        const indexOptions = serviceAccounts.findIndex(ramdaSolutions.propEq('customerId', customerDelete.customerId));
        if (indexOptions > -1) {
          const serviceAccountsCopy = serviceAccounts;
          serviceAccountsCopy.splice(indexOptions, 1);
          setServiceAccounts(serviceAccountsCopy);
          setFilteredServiceAccounts(serviceAccountsCopy);
        }
        // Agregar al listado de customerOptions si no existe en el
        if (!customerOptions.find(option => option.key === customerDelete.customerId)) {
          const newOption = [{
            key: customerDelete.customerId,
            text: `${customerDelete.customerId} - ${customerDelete.customerName}`,
            value: customerDelete.customerId,
          }];
          setCustomerOptions(customerOptions.concat(newOption));
        }
      }
      setModalActive(false);
      setSelectedCustomer({});
      setCustomerToDelete('');
    } catch (error) {
      Toast(t('b2b2c.cantDelete'), 'error');
    }
  };

  const handleCustomerSearch = keyword => {
    setFilter(keyword);
    const items = fuse(serviceAccounts, ['customerId', 'customerName']);
    const result = items.search(keyword);
    setFilteredServiceAccounts(result.length === 0 ? serviceAccounts : result);
  };

  const handleSearch = event => {
    handleCustomerSearch(event.target.value);
  };

  const handleNewCustomer = () => {
    setSearchLoader(true);
    setNewCustomerModalActive(true);
    const comparation = (option, account) => option.key === account.customerId;
    const listWithOutExistAccounts = ramdaSolutions.differenceWith(comparation, customerOptions, serviceAccounts);
    setSelectedCustomer({});
    setCustomerOptions(listWithOutExistAccounts);
    setSearchLoader(false);
  };

  useEffect(() => {
    async function fetchServiceAccounts() {
      try {
        const { serviceAccounts: accounts } = await request.getServicesAccounts();
        setServiceAccounts(accounts);
        setFilteredServiceAccounts(accounts);
      } catch (error) {
        setServiceAccounts([]);
        setFilteredServiceAccounts([]);
      }
    }
    fetchServiceAccounts();
  }, [customerId]);

  useEffect(() => {
    async function fetchCustomersList() {
      try {
        setSearchLoader(true);
        const { customers } = await request.getCustomers();
        const customerFormatted = customers.map(customer => ({
          key: customer.id,
          text: `${customer.id} - ${customer.tradingName}`,
          value: customer.id,
        }));
        setCustomerList(customers);
        setCustomerOptions(customerFormatted);
        setSearchLoader(false);
      } catch (error) {
        setCustomerOptions([]);
        setSearchLoader(false);
      }
    }
    fetchCustomersList();
  }, [customerId]);

  const handleGetCustomerChange = (e, { value }) => {
    const customerData = customersList.find(account => account.id === value);
    setCustomerToAdd(customerData);
  };

  const handleAddNewCustomer = async () => {
    try {
      const { id: clientId, tradingName: customerName } = customerToAdd;
      setAddLoading(true);
      const { id } = await request.addServiceAccount(clientId);
      const newCustomer = [{
        id,
        customerId: clientId,
        customerName,
        createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
      }];
      setFilteredServiceAccounts(serviceAccounts.concat(newCustomer));
      setServiceAccounts(serviceAccounts.concat(newCustomer));
      const indexOptions = ramdaSolutions.findIndex(ramdaSolutions.propEq('key', clientId))(customerOptions);
      if (indexOptions > -1) {
        const customerOptionsCopy = customerOptions;
        customerOptionsCopy.splice(indexOptions, 1);
        setCustomerOptions(customerOptionsCopy);
      }
      setAddLoading(false);
      Toast(t('b2b2c.customerAdded'), 'success');
      setNewCustomerModalActive(false);
      setCustomerToAdd({});
    } catch (error) {
      setAddLoading(false);
      Toast(t('b2b2c.cantAdd'), 'error');
    }
  };

  const onCloseModal = () => {
    setAddLoading(false);
    setNewCustomerModalActive(false);
    setCustomerToAdd({});
  };

  const onCloseConfirm = () => {
    setModalActive(false);
    setCustomerToDelete({});
  };

  return (
    <div>
      <div>
        <Button icon="add user" labelPosition="right" content={t('add')} floated="right" onClick={handleNewCustomer} />
        <TextField
          fullWidth
          rightIcon="search"
          type="text"
          maxLength={30}
          value={filter || ''}
          label={t('b2b2c.searchCustomer')}
          onChange={handleSearch}
        />
      </div>
      <Grid.Row style={{ overflowX: 'auto' }}>
        <Table
          // selectable
          data={filteredServiceAccounts}
          headers={headers}
          pageSize={5}
          singleSelection
        />
      </Grid.Row>
      <Confirm
        open={activeModal}
        onCancel={onCloseConfirm}
        onConfirm={handleDelete}
        header={t('b2b2c.deleteAccount')}
        content={customerToDelete}
        cancelButton={t('cancel')}
        confirmButton={t('yes')}
      />
      <Modal open={activeNewCustomerModal} closeOnEscape={false} closeOnDimmerClick={false}>
        <Modal.Header>
          {' '}
          {t('b2b2c.addServiceAccountTitle')}
          {' '}
        </Modal.Header>
        <Modal.Content>
          <Grid.Row>
            <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={3} textAlign="right">
              <Header as="h4" color="blue">
                {t('b2b2c.selectCustomer')}
              </Header>
            </Grid.Column>
            <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={13} textAlign="left">
              {searchLoader === false ? (
                <Dropdown fluid placeholder={t('b2b2c.availableCustomers')} options={customerOptions} onChange={handleGetCustomerChange} search selection />
              ) : (
                <Dimmer active inverted>
                  <Loader active size="medium" />
                </Dimmer>
              )}
            </Grid.Column>
          </Grid.Row>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCloseModal}>
            {' '}
            {t('cancel')}
            {' '}
          </Button>
          <Button onClick={handleAddNewCustomer} loading={addLoading}>
            {t('add')}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}

CustomersB2b2c.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(CustomersB2b2c);
