import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Header, Container, Dropdown,
} from 'semantic-ui-react';

const FinalUser = props => {
  const {
    t, checkoutData, currentPaymentMethod, paymentMethods, handlePaymentMethodChange, currencyOptions, handleCurrencyChange, currentCurrency,
  } = props;
  return (
    <Grid container>
      {checkoutData.map(data => (
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={6} mobile={16}>
              <Header as="h4" color="black">
                {t('checkout.finalUser')}
                <Header as="h5" style={{ display: 'inline' }}>
                  {' '}
                  {data.endUserName}
                  {' '}
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={8} mobile={16}>
              <Header as="h4" color="black">
                {t('checkout.paymentMethod')}
                <Header as="h5" style={{ display: 'inline' }}>
                  <Dropdown
                    floating
                    value={currentPaymentMethod}
                    placeholder="Selecciona método de pago."
                    options={paymentMethods}
                    onChange={handlePaymentMethodChange}
                    search
                    selection
                    disabled={currentPaymentMethod === 9}
                  />
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={8} mobile={16}>
              <Header as="h4" color="black">
                {t('checkout.currency')}
                <Header as="h5" style={{ display: 'inline' }}>
                  <Dropdown
                    compact
                    floating
                    placeholder="Moneda"
                    defaultValue={data.currency}
                    options={currencyOptions}
                    onChange={handleCurrencyChange}
                    value={currentCurrency}
                    search
                    selection
                    disabled={currentPaymentMethod === 9}
                  />
                </Header>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
              <Header as="h4" color="black">{t('checkout.comments')}</Header>
              <Container>
                <Header as="h5">{data.comments}</Header>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ))}
    </Grid>
  );
};

FinalUser.propTypes = {
  currentPaymentMethod: PropTypes.number,
  currentCurrency: PropTypes.number,
  checkoutData: PropTypes.arrayOf(PropTypes.shape({})),
  paymentMethods: PropTypes.arrayOf(PropTypes.shape({})),
  currencyOptions: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  handlePaymentMethodChange: PropTypes.func,
  handleCurrencyChange: PropTypes.func,
};

FinalUser.defaultProps = {
  currentPaymentMethod: 1,
  currentCurrency: 1,
  checkoutData: [],
  paymentMethods: [],
  currencyOptions: [],
  t: () => {},
  handlePaymentMethodChange: () => {},
  handleCurrencyChange: () => {},
};

export default translate('common', { wait: true })(FinalUser);
