const bucket = `${process.env.REACT_APP_URL_AWS}`;
const assetUrl = `${bucket}/imagenes/assets/comercio/`;
const assetUrlLogos = `${bucket}/imagenes/assets/brands/logos/`;

const banners = [
  {
    name: 'accesorios',
    imageWeb: `${assetUrl}banner-acc-web.png`,
    imageMobile: `${assetUrl}banner-acc-mobile.png`,
  },
  {
    name: 'computo',
    imageWeb: `${assetUrl}banner-comp-web.png`,
    imageMobile: `${assetUrl}banner-comp-mobile.png`,
  },
  {
    name: 'gaming',
    imageWeb: `${assetUrl}banner-gam-web.png`,
    imageMobile: `${assetUrl}banner-gam-web-mobile.png`,
  },
  {
    name: 'impresora',
    imageWeb: `${assetUrl}banner-imp-web.png`,
    imageMobile: `${assetUrl}banner-imp-web.png`,
  },
  {
    name: 'redes',
    imageWeb: `${assetUrl}banner-redes-web.png`,
    imageMobile: `${assetUrl}banner-redes-web.png`,
  },
  {
    name: 'servidores',
    imageWeb: `${assetUrl}banner-serv-web.png`,
    imageMobile: `${assetUrl}banner-serv-web.png`,
  },
  {
    name: 'apple',
    imageWeb: `${assetUrl}apple-web.png`,
    imageMobile: `${assetUrl}apple-mobile.png`,
  },
  {
    name: 'aruba',
    imageWeb: `${assetUrl}aruba-web.png`,
    imageMobile: `${assetUrl}aruba-mobile.png`,
  },
  {
    name: 'asus',
    imageWeb: `${assetUrl}asus-web.png`,
    imageMobile: `${assetUrl}asus-mobile.png`,
  },
  {
    name: 'blancco',
    imageWeb: `${assetUrl}blancco-web.png`,
    imageMobile: `${assetUrl}blancco-mobile.png`,
  },
  {
    name: 'matterport',
    imageWeb: `${assetUrl}matterport-web.png`,
    imageMobile: `${assetUrl}matterport-mobile.png`,
  },
  {
    name: 'hp',
    imageWeb: `${assetUrl}hp-web.png`,
    imageMobile: `${assetUrl}hp-mobile.png`,
  },
  {
    name: 'hpe',
    imageWeb: `${assetUrl}hpe-web.png`,
    imageMobile: `${assetUrl}hpe-mobile.png`,
  },
  {
    name: 'lenovo',
    imageWeb: `${assetUrl}lenovo-web.png`,
    imageMobile: `${assetUrl}lenovo-mobile.png`,
  },
  {
    name: 'logitech',
    imageWeb: `${assetUrl}logitech-web.png`,
    imageMobile: `${assetUrl}logitech-mobile.png`,
  },
  {
    name: 'perfectchoice',
    imageWeb: `${assetUrl}perfectchoice-web.png`,
    imageMobile: `${assetUrl}perfectchoice-mobile.png`,
  },
  {
    name: 'safetica',
    imageWeb: `${assetUrl}safetica-web.png`,
    imageMobile: `${assetUrl}safetica-mobile.png`,
  },
  {
    name: 'redhat',
    imageWeb: `${assetUrl}red-hat-web.png`,
    imageMobile: `${assetUrl}red-hat-mobile.png`,
  },
  {
    name: 'screenbeam',
    imageWeb: `${assetUrl}screenbeam-web.png`,
    imageMobile: `${assetUrl}screenbeam-mobile.png`,
  },
  {
    name: 'techzone',
    imageWeb: `${assetUrl}techzone-web.png`,
    imageMobile: `${assetUrl}techzone-mobile.png`,
  },
  {
    name: 'trellix',
    imageWeb: `${assetUrl}trellix-web.png`,
    imageMobile: `${assetUrl}trellix-mobile.png`,
  },
  {
    name: 'tripp lite',
    imageWeb: `${assetUrl}tripplite-web.png`,
    imageMobile: `${assetUrl}tripplite-mobile.png`,
  },
  {
    name: 'veeam',
    imageWeb: `${assetUrl}veeam-web.png`,
    imageMobile: `${assetUrl}veeam-mobile.png`,
  },
  {
    name: 'vmware',
    imageWeb: `${assetUrl}vmware-web.png`,
    imageMobile: `${assetUrl}vmware-mobile.png`,
  },

];

const logos = [
  {
    logoMaker: `${assetUrlLogos}apple.png`,
    maker: 'Apple',
  },
  {
    logoMaker: `${assetUrlLogos}aruba.png`,
    maker: 'Aruba',
  },
  {
    logoMaker: `${assetUrlLogos}checkpoint.png`,
    maker: 'Check Point',
  },
  {
    logoMaker: `${assetUrlLogos}hpe.png`,
    maker: 'HP',
  },
  {
    logoMaker: `${assetUrlLogos}hp.png`,
    maker: 'HP Inc',
  },
  {
    logoMaker: `${assetUrlLogos}lenovo.png`,
    maker: 'Lenovo',
  },
  {
    logoMaker: `${assetUrlLogos}isotipologitech.png`,
    maker: 'Logitech',
  },
  {
    logoMaker: `${assetUrlLogos}microsoft.png`,
    maker: 'Microsoft',
  },
  {
    logoMaker: `${assetUrlLogos}perfectchoice.png`,
    maker: 'Perfect Choice',
  },
  {
    logoMaker: `${assetUrlLogos}redhat.png`,
    maker: 'CS-REDHAT-SP',
  },
  {
    logoMaker: `${assetUrlLogos}screenbeam.png`,
    maker: 'Actiontec',
  },
  {
    logoMaker: `${assetUrlLogos}techzone.png`,
    maker: 'Tech Zone',
  },
  {
    logoMaker: `${assetUrlLogos}trellix-logo.png`,
    maker: 'trellix',
  },
  {
    logoMaker: `${assetUrlLogos}veeam.png`,
    maker: 'VEEAM',
  },
  {
    logoMaker: `${assetUrlLogos}vmware.png`,
    maker: 'VMWARE',
  },

];

const accesories = [
  {
    text: 'Bocinas',
    redirect: 'bocinas',
  },
  {
    text: 'Cámaras Digitales',
    redirect: 'camara',
  },
  {
    text: 'Cómputo',
    redirect: 'accesorios',
  },
  {
    text: 'Monitores',
    redirect: 'monitores',
  },
];

const computer = [
  {
    text: 'Escritorio',
    redirect: 'computo%20Computadora',
  },
  {
    text: 'Laptop',
    redirect: 'laptop',
  },
  {
    text: 'Tableta',
    redirect: 'tableta',
  },
];

const printers = [
  {
    text: 'Consumibles',
    redirect: 'consumibles',
  },
  {
    text: 'Escaner',
    redirect: 'scanner',
  },
  {
    text: 'Láser',
    redirect: 'laser',
  },
  {
    text: 'Miniprinters',
    redirect: 'receipt%20printer',
  },
  {
    text: 'Plotter',
    redirect: 'ploter%20HP-BIG%20IMPRESION',
  },
];

const networks = [
  {
    text: 'Conmutadores',
    redirect: 'Conmutador',
  },
  {
    text: 'Router',
    redirect: 'router',
  },
  {
    text: 'Switch',
    redirect: 'switch',
  },
];

const servers = [
  {
    text: 'Accesorios',
    redirect: 'servidores',
  },
  {
    text: 'Almacenamiento',
    redirect: 'almacenamiento',
  },
  {
    text: 'Reguladores de energía',
    redirect: 'energia',
  },
];

const software = [
  {
    text: 'Colaboración',
    redirect: 'colaboracion',
  },
  {
    text: 'Nube',
    redirect: 'nube',
  },
  {
    text: 'Office 365',
    redirect: 'office',
  },
  {
    text: 'Seguridad',
    redirect: 'seguridad',
  },
  {
    text: 'Sistemas operativos',
    redirect: 'sistemas operativos',
  },
  {
    text: 'Virtualización',
    redirect: 'virtualizacion',
  },
];

const assets = {
  banners,
  logos,
  accesories,
  computer,
  printers,
  networks,
  servers,
  software,
};

export default assets;
