import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Button, Header, Checkbox, Divider, Popup, Icon,
} from 'semantic-ui-react';
import validator from 'validator';
import { TextField } from '../../common/components/materials';
import deliveryAddress from '../services';
import finalUserService from '../../final-user/services';
import { escapeAccents } from '../../common/utils';
import Toast from '../../common/components/toast';
import Schedule from './schedule';
import '../styles.css';

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postalCode: '',
      pcError: '',
      street: '',
      streetError: '',
      dirnumber: '',
      dirnumberError: '',
      inError: '',
      nickname: '',
      nicknameError: '',
      phoneBO: '',
      phoneBOError: '',
      colony: [],
      contact: '',
      assistant: '',
      contactError: '',
      assistantError: '',
      validateDate: '',
      validateParking: '',
      parcel: [
        { value: 1, label: 'UPS' },
        { value: 2, label: 'Paquete express' },
      ],
      mistakes: false,
      addressData: {
        codigoPostal: '',
        colonia: '',
        calle: '',
        numeroExterno: '',
        numeroInterior: '',
        alias: '',
        contacto: '',
        asistente: '',
        telefonoSucursal: '',
        paqueteria: '',
        cliente: '',
      },
      finalUser: '',
      parkingCheckedY: false,
      parkingCheckedN: true,
      dateCheckedY: true,
      dateCheckedN: false,
      show: false,
      betweenStreets: '',
      betweenStreetsError: '',
      country: 'México',
    };
  }

  componentDidMount = () => {
    const { id } = this.props;
    this.handleGetFinalUsers();
    if (id) this.handleGetAddressDetail();
  }

  handleGetAddressDetail = async () => {
    let {
      dateCheckedN, dateCheckedY, parkingCheckedN, parkingCheckedY, nickname, street, dirnumber, inside, colonyBy, postalCode,
      phoneBO, contact, assistant, parcelBy, finalUser, betweenStreets,
    } = this.state;
    const { id } = this.props;
    const addressDetail = await deliveryAddress.getAddressDetail(id)
      .catch(() => {
        // this.setState({ error });
      });
    if (addressDetail) {
      if (addressDetail.postalCode) postalCode = addressDetail.postalCode;
      if (addressDetail.street) street = addressDetail.street;
      if (addressDetail.name) nickname = addressDetail.name;
      if (addressDetail.number) dirnumber = addressDetail.number;
      if (addressDetail.unit) inside = addressDetail.unit;
      if (addressDetail.colony) colonyBy = addressDetail.colony;
      if (addressDetail.phone) phoneBO = addressDetail.phone;
      if (addressDetail.mainContact) contact = addressDetail.mainContact;
      if (addressDetail.auxiliaryContact) assistant = addressDetail.auxiliaryContact;
      if (addressDetail.courierServiceId) parcelBy = addressDetail.courierServiceId;
      if (addressDetail.betweenStreets) betweenStreets = addressDetail.betweenStreets;
      if (addressDetail.endUser) finalUser = addressDetail.endUser.id;
      if (addressDetail.parking === true) {
        parkingCheckedN = false;
        parkingCheckedY = true;
      } else {
        parkingCheckedN = true;
        parkingCheckedY = false;
      }
      if (addressDetail.appointment === true) {
        dateCheckedN = false;
        dateCheckedY = true;
      } else {
        dateCheckedN = true;
        dateCheckedY = false;
      }
      this.setState({
        show: true,
        nickname,
        street,
        dirnumber,
        inside,
        colonyBy,
        postalCode,
        phoneBO,
        contact,
        assistant,
        parcelBy,
        finalUser,
        parkingCheckedN,
        parkingCheckedY,
        dateCheckedN,
        dateCheckedY,
        betweenStreets,
      });
      if (postalCode.length > 0) {
        this.handleValidatePC();
        this.setState({ state: addressDetail.state, city: addressDetail.city, postalCode: addressDetail.postalCode });
      }
    }
  }

  handleGetFinalUsers = async () => {
    const { t } = this.props;
    const arrayEndUsers = await finalUserService.getFinalUser()
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        this.setState({ finalUserError: messageError });
      });
    this.setState({
      finalUserArray: arrayEndUsers.endUsers.map(currentUser => {
        const user = { value: currentUser.id, label: currentUser.name };
        return user;
      }),
    });
  }

  handlePCChange = event => {
    const { t } = this.props;
    const maxLength = 5;
    const minLength = 5;
    const { value: postalCode } = event.target;
    if (postalCode && postalCode.length > 0) {
      if (!validator.isNumeric(postalCode)) {
        this.setState({ postalCode, pcError: t('branchOffice.errors.notPC') });
        return;
      }
      if (postalCode.length > maxLength) {
        this.setState({ postalCode, pcError: t('branchOffice.errors.toLong') });
        return;
      }
      if (postalCode.length < minLength) {
        this.setState({ postalCode, pcError: t('branchOffice.errors.toShort') });
        return;
      }
    }
    this.setState({ postalCode, pcError: '' });
  }

  handleValidatePC = () => {
    this.setState({ city: '', state: '' });
    const { postalCode, pcError } = this.state;
    const { colonyBy } = this.state;
    const { t } = this.props;
    if (pcError.length === 0 && postalCode.length === 5) {
      deliveryAddress.getColony(postalCode)
        .then(response => {
          const colony = response.addresses.map(address => {
            const addressModified = {
              id: address.colony, value: address.colony, label: address.colony, delegation: address.delegation, state: address.state, city: address.city,
            };
            return addressModified;
          });
          this.setState({
            colony, colonyBy, colonyError: '', show: true,
          });
        })
        .catch(() => {
          Toast(t('branchOffice.errors.nodatafound'), 'error');
          this.setState({ colonyError: t('branchOffice.errors.notFoundNeighborhood'), colony: [] });
        });
    } else {
      this.setState({ postalCode, pcError: t('branchOffice.errors.notPC') });
    }
  }

  handleStreetChange = event => {
    const { t } = this.props;
    const maxLength = 55;
    const minLength = 2;
    const { value: street } = event.target;
    if (street && street.length > 0) {
      const validateStreet = escapeAccents(validator.blacklist(street, ' .,'));
      if (!validator.isAlphanumeric(validateStreet.replace(/ /g, ''), ['es-ES'])) { this.setState({ street, streetError: t('branchOffice.errors.notStreet') }); return; }
      if (street.length > maxLength) { this.setState({ street, streetError: t('branchOffice.errors.toLong') }); return; }
      if (street.length < minLength) { this.setState({ street, streetError: t('branchOffice.errors.toShort') }); return; }
    }
    this.setState({ street, streetError: '' });
  }

  handleBetweenStreetsChange = event => {
    const { t } = this.props;
    const maxLength = 75;
    const minLength = 2;
    const { value: betweenStreets } = event.target;
    if (betweenStreets && betweenStreets.length > 0) {
      if (!validator.isAlphanumeric(betweenStreets.replace(/ /g, '').replace(/\./g, '').replace(/,/g, ''), ['es-ES'])) { this.setState({ betweenStreets, betweenStreetsError: t('branchOffice.errors.notStreet') }); return; }
      if (betweenStreets.length > maxLength) { this.setState({ betweenStreets, betweenStreetsError: t('branchOffice.errors.toLong') }); return; }
      if (betweenStreets.length < minLength) { this.setState({ betweenStreets, betweenStreetsError: t('branchOffice.errors.toShort') }); return; }
    }
    this.setState({ betweenStreets, betweenStreetsError: '' });
  }

  handleNumberChange = event => {
    const { t } = this.props;
    const maxLength = 5;
    const { value: dirnumber } = event.target;
    if (dirnumber && dirnumber.length > 0) {
      if (!validator.isInt(dirnumber)) { this.setState({ dirnumber, dirnumberError: t('branchOffice.errors.notNumeric') }); return; }
      if (dirnumber.length > maxLength) { this.setState({ dirnumber, dirnumberError: t('branchOffice.errors.toLong') }); return; }
    }
    this.setState({ dirnumber, dirnumberError: '' });
  }

  handleIntChange = event => {
    const { t } = this.props;
    const maxLength = 5;
    const { value: inside } = event.target;
    if (inside && inside.length > 0) {
      if (!validator.isAlphanumeric(inside.replace(/ /g, ''))) { this.setState({ inside, inError: t('branchOffice.errors.notSymbol') }); return; }
      if (inside.length > maxLength) { this.setState({ inside, inError: t('branchOffice.errors.toLong') }); return; }
    }
    this.setState({ inside, inError: '' });
  }

  handleNicknameChange = event => {
    const { t } = this.props;
    const maxLength = 30;
    const { value: nickname } = event.target;
    if (nickname && nickname.length > 0) {
      if (!validator.isAlphanumeric(nickname.replace(/ /g, ''), ['es-ES'])) { this.setState({ nickname, nicknameError: t('branchOffice.errors.notAs') }); return; }
      if (nickname.length > maxLength) { this.setState({ nickname, nicknameError: t('branchOffice.errors.toLong') }); return; }
    }
    this.setState({ nickname, nicknameError: '' });
  }

  handlePhoneBOChange = event => {
    const { t } = this.props;
    const maxLength = 10;
    const { value: phoneBO } = event.target;
    if (phoneBO && phoneBO.length > 0) {
      if (!validator.isNumeric(phoneBO.replace(/ /g, ''))) { this.setState({ phoneBO, phoneBOError: t('branchOffice.errors.notNumeric') }); return; }
      if (phoneBO.length > maxLength) { this.setState({ phoneBO, phoneBOError: t('branchOffice.errors.toLong') }); return; }
      if (phoneBO.length < maxLength) { this.setState({ phoneBO, phoneBOError: t('branchOffice.errors.toShort') }); return; }
    }
    this.setState({ phoneBO, phoneBOError: '' });
  }

  handleContactChange = event => {
    const { t } = this.props;
    const maxLength = 35;
    const minLength = 8;
    const { value: contact } = event.target;
    if (contact && contact.length > 0) {
      if (!validator.isAlpha(contact.replace(/ /g, ''), ['es-ES'])) { this.setState({ contact, contactError: t('finaluser.errors.notAlpha') }); return; }
      if (contact.length > maxLength) { this.setState({ contact, contactError: t('finaluser.errors.toLong') }); return; }
      if (contact.length < minLength) { this.setState({ contact, contactError: t('finaluser.errors.toShort') }); return; }
    }
    this.setState({ contact, contactError: '' });
  }

  handleAssistantChange = event => {
    const { t } = this.props;
    const maxLength = 35;
    const minLength = 8;
    const { value: assistant } = event.target;
    if (assistant && assistant.length > 0) {
      if (!validator.isAlpha(assistant.replace(/ /g, ''), ['es-ES'])) { this.setState({ assistant, assistantError: t('finaluser.errors.notAlpha') }); return; }
      if (assistant.length > maxLength) { this.setState({ assistant, assistantError: t('finaluser.errors.toLong') }); return; }
      if (assistant.length < minLength) { this.setState({ assistant, assistantError: t('finaluser.errors.toShort') }); return; }
    }
    this.setState({ assistant, assistantError: '' });
  }

  handleClickParking = (e, { value }) => {
    if (value === 1) {
      this.setState({ parkingCheckedY: true, parkingCheckedN: false });
    } else {
      this.setState({ parkingCheckedY: false, parkingCheckedN: true });
    }
  }

  handleClickDate = (e, { value }) => {
    if (value === 1) this.setState({ dateCheckedY: true, dateCheckedN: false });
    else this.setState({ dateCheckedY: false, dateCheckedN: true });
  }

  handleClickCancel = () => {
    const { history } = this.props;
    history.goBack();
  }

  validations = (action, schedule) => {
    let {
      mistakes, pcError, streetError, dirnumberError, phoneBOError, colonyError, assistantError, nicknameError,
      contactError, betweenStreetsError, assistant,
    } = this.state;
    const {
      postalCode, inError, street, colonyBy, contact, dirnumber, nickname, phoneBO, betweenStreets,
    } = this.state;
    const { t, onboarding } = this.props;
    assistant = (onboarding) ? assistant : contact;
    if ((postalCode.trim()).length === 0) {
      pcError = t('user.errors.empty');
      mistakes = true;
    }
    if ((street.trim()).length === 0) {
      streetError = t('user.errors.empty');
      mistakes = true;
    }
    if (betweenStreets.length === 0) {
      betweenStreetsError = t('user.errors.empty');
      mistakes = true;
    }
    if (!colonyBy) {
      colonyError = t('user.errors.empty');
      mistakes = true;
    }
    if ((nickname.trim()).length === 0) {
      nicknameError = t('user.errors.empty');
      mistakes = true;
    }
    if ((assistant.trim()).length === 0) {
      assistantError = t('user.errors.empty');
      mistakes = true;
    }
    if ((contact.trim()).length === 0) {
      contactError = t('user.errors.empty');
      mistakes = true;
    }
    if ((dirnumber.trim()).length === 0) {
      dirnumberError = t('user.errors.empty');
      mistakes = true;
    }
    if ((phoneBO.trim()).length === 0) {
      phoneBOError = t('user.errors.empty');
      mistakes = true;
    }
    if (phoneBO && phoneBO.length < 10) {
      phoneBOError = t('El telefono debe contener 10 dígitos');
      mistakes = true;
    }
    if (dirnumber.length < 1) {
      dirnumberError = t('branchOffice.errors.toShort');
      mistakes = true;
    }
    if (nickname.length < 5) {
      nicknameError = t('branchOffice.errors.toShort');
      mistakes = true;
    }
    if (!validator.isEmpty(pcError)) mistakes = true;
    if (!validator.isEmpty(streetError)) mistakes = true;
    if (!validator.isEmpty(dirnumberError)) mistakes = true;
    if (!validator.isEmpty(inError)) mistakes = true;
    if (!validator.isEmpty(nicknameError)) mistakes = true;
    if (!validator.isEmpty(phoneBOError)) mistakes = true;
    if (!validator.isEmpty(contactError)) mistakes = true;
    if (!validator.isEmpty(assistantError)) mistakes = true;
    if (!validator.isEmpty(betweenStreetsError)) mistakes = true;
    if (mistakes === true) {
      Toast(t('Falta información requerida, favor de llenar los campos marcados con rojo'), 'error');
      this.setState({
        betweenStreetsError, pcError, streetError, colonyError, inError, nicknameError, assistantError, contactError, dirnumberError, phoneBOError,
      });
    } else {
      this.handleGetDetailToUse(action, schedule);
    }
  }

  handleGetDetailToUse = (action, schedule) => {
    const {
      colonyBy, betweenStreets, postalCode, state, country, street, dirnumber, inside, nickname, contact, phoneBO, finalUser, parkingCheckedN, dateCheckedN, colony,
    } = this.state;
    const { id, onboarding } = this.props;
    let {
      addressData, parking, appointment, parcelBy, assistant,
    } = this.state;
    if (!onboarding) {
      parking = 1;
      appointment = 1;
      parcelBy = 1;
      assistant = contact;
    } else {
      if (parkingCheckedN === true) parking = 0;
      else parking = 1;
      if (dateCheckedN === true) appointment = 0;
      else appointment = 1;
    }
    const selectedAddress = colony.find(({ value }) => value === colonyBy);
    addressData = {
      id,
      name: nickname,
      street,
      number: dirnumber,
      unit: inside,
      phone: phoneBO,
      parking,
      appointment,
      mainContact: contact,
      auxiliaryContact: assistant,
      endUserId: finalUser,
      courierServiceId: parcelBy,
      betweenStreets,
      schedules: schedule,
      colony: colonyBy,
      postalCode,
      city: selectedAddress.city,
      delegation: selectedAddress.delegation,
      state,
      country,
    };
    if (action === 1) delete addressData.id;
    if (!parcelBy) { delete addressData.courierServiceId; }
    if (!finalUser) { delete addressData.endUserId; }
    if (action === 1) this.handleSave(addressData);
    if (action === 2) this.handleUpdate(addressData);
  }

  handleSave = addressData => {
    const { t, showComponents, getNewAddress } = this.props;
    const { nickname } = this.state;
    let newBranchOffice = '';
    deliveryAddress.addAddress(addressData).then(branchOffice => {
      if (getNewAddress) {
        newBranchOffice = {
          key: branchOffice.id,
          text: nickname,
          value: branchOffice.id,
          city: addressData.city,
          colony: addressData.colony,
          state: addressData.state,
          street: addressData.street,
          number: addressData.number,
        };
      }
      if (getNewAddress) getNewAddress(newBranchOffice);
      showComponents(true, branchOffice.id, nickname);
      Toast(t('branchOffice.message.confirmBranchOfficeAdd'), 'success');
    })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleUpdate = addressData => {
    const { t, getNewAddress } = this.props;
    let newBranchOffice = [];
    deliveryAddress.updateAddress(addressData).then(() => {
      Toast(t('branchOffice.message.confirmBranchOfficeUpdate'), 'success');
      if (getNewAddress) {
        newBranchOffice = {
          key: addressData.id,
          text: addressData,
          value: addressData.id,
          city: addressData.city,
          colony: addressData.colony,
          state: addressData.state,
          street: addressData.street,
          number: addressData.number,
        };
      }
      if (getNewAddress) getNewAddress(newBranchOffice);
    })
      .catch(error => {
        let messageError = '';
        if (!error) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  getItemBy = (key, value, catalog) => (
    catalog.find(item => item[key] === value) || {}
  )

  handleColonyChange = ({ target }) => {
    const { value } = target;
    const { colony } = this.state;
    const { delegation, state } = this.getItemBy('value', value, colony);
    this.setState({
      colonyBy: value,
      city: delegation,
      state,
      colonyError: '',
    });
  }

  handleFinalUserChange = ({ target }) => {
    const { value } = target;
    this.setState({ finalUser: value, finalUserError: '' });
  }

  render() {
    const { t, id, onboarding } = this.props;
    const {
      dateCheckedY, dateCheckedN, parkingCheckedY, parkingCheckedN, colony, colonyBy, colonyError, street,
      streetError, dirnumber, dirnumberError, city, state, inside, inError, parcelBy, finalUserArray, nickname,
      nicknameError, phoneBO, phoneBOError, parcel, contactError, assistant, contact, assistantError, postalCode, pcError,
      validateDate, validateParking, finalUser, finalUserError, betweenStreets, betweenStreetsError, show,
    } = this.state;
    return (
      <Grid container>
        <Grid.Row>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={16}>
            <TextField
              fullWidth
              maxLength={5}
              value={postalCode || ''}
              error={pcError || ''}
              label={t('branchOffice.pc')}
              onChange={this.handlePCChange}
            />
          </Grid.Column>
          <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={9}>
            <Button color="blue" onClick={this.handleValidatePC}>{t('branchOffice.validatepc')}</Button>
          </Grid.Column>
          <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={5}>
            <Button color="blue" onClick={this.handleClickCancel}>
              {t('cancel')}
              {' '}
            </Button>
          </Grid.Column>
        </Grid.Row>
        {show
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={16}>
                <Header textAlign="center" as="h4">
                  {t('branchOffice.city')}
                  {' '}
                  {city}
                </Header>
              </Grid.Column>
              <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={16}>
                <Header textAlign="center" as="h4">
                  {t('branchOffice.state')}
                  {' '}
                  {state}
                </Header>
              </Grid.Column>
              <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={16}>
                <Header textAlign="center" as="h4">
                  {' '}
                  {t('branchOffice.country')}
                  {' '}
                </Header>
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  select
                  fullWidth
                  name="colonyBy"
                  label={t('branchOffice.colony')}
                  value={colonyBy || ''}
                  error={colonyError || ''}
                  onChange={this.handleColonyChange}
                  options={colony}
                  required
                />
              </Grid.Column>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={55}
                  value={street || ''}
                  error={streetError || ''}
                  label={t('branchOffice.street')}
                  onChange={this.handleStreetChange}
                  required
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={10} computer={10} tablet={12} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={55}
                  value={betweenStreets || ''}
                  error={betweenStreetsError || ''}
                  label="Entre las calles:"
                  onChange={this.handleBetweenStreetsChange}
                  required
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show
          ? (
            <Grid.Row columns={3}>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={30}
                  value={nickname || ''}
                  error={nicknameError || ''}
                  label={t('branchOffice.as')}
                  onChange={this.handleNicknameChange}
                  required
                />
              </Grid.Column>
              <Grid.Column largeScreen={2} computer={2} tablet={5} mobile={15} className="number-ext-padding-right-cero">
                <TextField
                  fullWidth
                  maxLength={5}
                  value={dirnumber || ''}
                  error={dirnumberError || ''}
                  label={t('branchOffice.number')}
                  onChange={this.handleNumberChange}
                  required
                />
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={1} className="popup-icon-number-ext-padding">
                <Popup
                  trigger={<Icon name="info circle" circular color="blue" />}
                  content={t('branchOffice.numberExtInfo')}
                />
              </Grid.Column>
              <Grid.Column largeScreen={2} computer={2} tablet={6} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={5}
                  value={inside || ''}
                  error={inError || ''}
                  label={t('branchOffice.in')}
                  onChange={this.handleIntChange}
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={10}
                  value={phoneBO || ''}
                  error={phoneBOError || ''}
                  hint={t('multibrand.descriptionPhone')}
                  label={t('branchOffice.phoneBO')}
                  onChange={this.handlePhoneBOChange}
                  required
                />
              </Grid.Column>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  select
                  fullWidth
                  name="finalUser"
                  label={t('branchOffice.selectFinalUser')}
                  value={finalUser || ''}
                  error={finalUserError || ''}
                  onChange={this.handleFinalUserChange}
                  options={finalUserArray}
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show && onboarding
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
                <Header as="h4" color={validateDate}>{t('schedule.needDate')}</Header>
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={3}>
                <Checkbox
                  radio
                  label={t('schedule.yes')}
                  name="checkboxRadioGroup"
                  value={1}
                  checked={dateCheckedY}
                  onChange={this.handleClickDate}
                />
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={3}>
                <Checkbox
                  radio
                  label={t('schedule.no')}
                  name="checkboxRadioGroup"
                  value={0}
                  checked={dateCheckedN}
                  onChange={this.handleClickDate}
                />
              </Grid.Column>
              <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={8}>
                <Header as="h4" color={validateParking}>{t('schedule.haveParking')}</Header>
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={3}>
                <Checkbox
                  radio
                  label={t('schedule.yes')}
                  name="checkboxRadioGroup"
                  value={1}
                  checked={parkingCheckedY}
                  onChange={this.handleClickParking}
                />
              </Grid.Column>
              <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={3}>
                <Checkbox
                  radio
                  label={t('schedule.no')}
                  name="checkboxRadioGroup"
                  value={0}
                  checked={parkingCheckedN}
                  onChange={this.handleClickParking}
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  fullWidth
                  maxLength={35}
                  value={contact || ''}
                  error={contactError || ''}
                  label={t('branchOffice.contact')}
                  onChange={this.handleContactChange}
                  required
                />
              </Grid.Column>
              {onboarding
                ? (
                  <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                    <TextField
                      fullWidth
                      maxLength={35}
                      value={assistant || ''}
                      error={assistantError || ''}
                      label={t('branchOffice.assistant')}
                      onChange={this.handleAssistantChange}
                      required
                    />
                  </Grid.Column>
                )
                : null}
            </Grid.Row>
          )
          : null}
        {show && onboarding
          ? (
            <Grid.Row>
              <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={16}>
                <TextField
                  select
                  fullWidth
                  name="parcelBy"
                  label={t('branchOffice.parcel')}
                  value={parcelBy || ''}
                  onChange={({ target }) => this.setState({ parcelBy: target.value })}
                  options={parcel}
                />
              </Grid.Column>
            </Grid.Row>
          )
          : null}
        {show
          ? (
            <Grid.Row>
              <Divider section />
              <Schedule
                id={!id ? '' : id}
                returnSchedule={(action, schedule) => this.validations(action, schedule)}
                onboarding={onboarding}
              />
              <Divider section />
              {' '}

            </Grid.Row>
          )
          : null}
      </Grid>
    );
  }
}

Address.propTypes = {
  getNewAddress: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showComponents: PropTypes.func.isRequired,
  onboarding: PropTypes.bool.isRequired,
};

export default translate('common', { wait: true })(Address);
