import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Modal, Grid,
} from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { translate } from 'react-i18next';

import Toast from '../../../common/components/toast';
import { TextField } from '../../../common/components/materials';
import * as validate from './validation';
import request from '../../services';

const emptyErrors = {
  customerId: false,
};

function ModalDetails(props) {
  const {
    actions, t, env,
  } = props;
  const { open, handleModalClose, handleModalSave } = actions;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(emptyErrors);
  const [selectedUser, setSelectedUser] = useState({
    id: null,
    customerId: '',
    customerName: 0,
    createdAt: '',
  });

  function defineErrorMessages(error) {
    if (error.code === callError.general.RESOURCE_NOT_FOUND) {
      Toast(t('user.permissionsNotFound'), 'error');
    } else Toast(t(`error-codes.${error.code}`), 'error');
  }

  async function addUser() {
    const newUser = await request.addAccount(
      selectedUser.customerId,
      env,
    ).catch(defineErrorMessages);
    if (newUser && newUser.id) {
      Toast(t('user.userAdded'), 'success');
    }
  }

  function userAction() {
    return addUser();
  }

  const handleOnBlurCustomerId = event => {
    const { value } = event.target;
    const errorMessage = validate.isCustomerId(value);
    setErrorMessages({ ...errorMessages, customerId: t(errorMessage) });
  };

  function validateForm() {
    const { customerIdError } = validate.form(selectedUser);
    setErrorMessages({
      customerId: t(customerIdError),
    });
    return !customerIdError;
  }

  const handleOnSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      await userAction();
      setIsLoading(false);
      handleModalSave();
    }
  };

  const handleOnClose = () => {
    setErrorMessages(emptyErrors);
    handleModalClose();
  };

  return (
    <Modal open={open} closeOnEscape={open}>
      <Modal.Header>
        {' '}
        {t('user.userInformation')}
        {' '}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column onBlur={handleOnBlurCustomerId} largeScreen={7} computer={7} tablet={6} mobile={15}>
              <TextField
                fullWidth
                maxLength={10}
                value={selectedUser.customerId || ''}
                error={errorMessages.customerId || ''}
                label={t('user.serviceAccount.idCustomer')}
                onChange={({ target }) => setSelectedUser({ ...selectedUser, customerId: target.value })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleOnClose}>
          {' '}
          {t('cancel')}
          {' '}
        </Button>
        <Button onClick={handleOnSubmit} loading={isLoading} color="blue">
          {t('add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalDetails.propTypes = {
  actions: PropTypes.shape({
    handleModalClose: PropTypes.func,
    handleModalSave: PropTypes.func,
    open: PropTypes.bool,
  }).isRequired,
  t: PropTypes.func.isRequired,
  env: PropTypes.string,
};

ModalDetails.defaultProps = {
  env: '',
};

export default translate('common', { wait: true })(ModalDetails);
