export const styleHeader = {
  font: { sz: 14, name: 'Exo', color: { rgb: 'FFFFFFFF' } },
  alignment: { wrapText: true },
  fill: { patternType: 'solid', fgColor: { rgb: 'FF808080' } },
};
export const sheetHeaders = {
  customerId: { header: 'No. Cliente', width: 12 },
  user: { header: 'Usuario', width: 12 },
  email: { header: 'Correo', width: 50 },
  lastLogin: { header: 'Último Inicio', width: 50 },
  totalLogin: { header: 'Total de entradas', width: 50 },
};
export const styleRow = {
  font: { sz: 12, name: 'News Gothic MT' },
  alignment: { wrapText: true },
};
