import React, { Component } from 'react';
import {
  Form,
  Input,
  Button,
  Header,
  Grid,
  Container,
  Divider,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import Toast from '../../../common/components/toast';
import SiclikTable from '../../../common/components/table';
import services from '../../services';

class PageTestApis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerKey: '',
      secret: '',
      sku: '',
      brand: '',
      keyword: '',
      report: [],
      loading: false,
      headers: [
        { key: 'sku', isKey: true },
        { key: 'brand', name: 'Brand' },
        { key: 'sku', name: 'Sku' },
        { key: 'title', name: 'Title' },
        { key: 'category', name: 'Category' },
        { key: 'price', name: 'Price' },
        { key: 'currency', name: 'Currency' },
      ],
    };
  }

  handleInputChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value,
    });
  };

  handleFormSubmit = event => {
    event.preventDefault();
  };

  handleGetResponseAPI = async () => {
    const {
      customerKey, secret, sku, brand, keyword, report,
    } = this.state;
    const { t } = this.props;
    const payload = {
      sku,
      brand,
      keyword,
      customerKey,
      secret,
    };
    this.setState({ report: [], loading: false });
    try {
      const { items } = await services.getSiclikSearch(payload);
      const resultArray = items.map(item => {
        const newConfig = {
          sku: item.sku,
          title: item.title,
          category: item.category,
          brand: item.brand,
          currency: item.currency,
          price: item.price,
        };
        return newConfig;
      });
      this.setState({ report: resultArray });
      Toast(t('user.testApi.successConnection'), 'success');
      return (report.length > 0 ? { report } : { report: [] });
    } catch (error) {
      let messageError = '';
      if (!error.code) messageError = t('error-codes.default');
      else messageError = t(`error-codes.${error.code}`);
      if (error.code === 1005) {
        Toast(t('user.testApi.emptyData'), 'warning');
      } if (error.code === 203) {
        Toast(t('user.testApi.reviewData'), 'warning');
      } if (error.code === 1000) {
        Toast(t('user.testApi.alertEmptyData'), 'warning');
      } else {
        Toast(messageError, 'error');
      }
      return ({ report: [] });
    }
  };

  render() {
    const { t } = this.props;
    const {
      customerKey, secret, sku, brand, keyword, loading, report, headers,
    } = this.state;
    return (
      <>
        <Grid container centered stackable textAlign="center">
          <Grid.Row style={{ paddingTop: '4rem' }}>
            <Header as="h4">{t('user.testApi.description')}</Header>
          </Grid.Row>
          <Grid.Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid.Column width={6}>
              <Form onSubmit={this.handleFormSubmit} style={{ textAlign: 'left' }}>
                <Form.Field style={{ marginBottom: '1rem' }}>
                  {t('user.testApi.customerKey')}
                  <Input
                    name="customerKey"
                    fluid
                    compact
                    maxLength={40}
                    value={customerKey}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Field>
                <Form.Field style={{ marginBottom: '1rem' }}>
                  {t('user.testApi.secret')}
                  <Input
                    name="secret"
                    fluid
                    compact
                    maxLength={50}
                    value={secret}
                    onChange={this.handleInputChange}
                    required
                  />
                </Form.Field>
                <Form.Field style={{ marginBottom: '1rem' }}>
                  {t('user.testApi.sku')}
                  <Input
                    name="sku"
                    fluid
                    compact
                    maxLength={20}
                    value={sku || ''}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginBottom: '1rem' }}>
                  {t('user.testApi.brand')}
                  <Input
                    name="brand"
                    fluid
                    compact
                    maxLength={20}
                    value={brand || ''}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginBottom: '1rem' }}>
                  {t('user.testApi.keyword')}
                  <Input
                    name="keyword"
                    fluid
                    compact
                    maxLength={40}
                    value={keyword || ''}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
                <Form.Field style={{ marginTop: '1rem', marginBottom: '4rem' }}>
                  <Button
                    type="submit"
                    compact
                    primary
                    fluid
                    icon="flask"
                    content={t('user.testApi.testConnection')}
                    onClick={this.handleGetResponseAPI}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            {loading ? null : (
              <Grid.Row centered>
                <Grid.Column>
                  <Container style={{ overflowX: 'scroll' }}>
                    <SiclikTable
                      data={report}
                      headers={headers}
                      pageSize={10}
                    />
                  </Container>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid.Row>
          <Divider />
        </Grid>
      </>
    );
  }
}

export default translate('common', { wait: true })(PageTestApis);
