import React, { useState } from 'react';
import {
  Icon, Menu, Popup, Divider,
} from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import services from '../services';

const headerStyle = {
  color: '#004c94',
  marginTop: '0',
  marginBottom: '-12px',
};

const itemStyle = {
  marginTop: '-5px',
  marginBottom: '-5px',
};

function setWishListFormat(wishList) {
  return {
    key: wishList.id,
    value: wishList.id,
    name: wishList.name,
  };
}

function BuyNowMenu({ t, openBuyNow }) {
  const [open, setOpen] = useState(false);
  const [userWishlist, setUserWishlist] = useState([]);

  // function handleGoListManager() {
  //   setOpen(false);
  //   goWishListManager();
  // }

  function handleSelectedList(list) {
    setOpen(false);
    openBuyNow(list);
  }

  async function getUserWishList() {
    try {
      const { wishList } = await services.getWishList();
      setUserWishlist(wishList);
    } catch (error) {
      setUserWishlist([{
        id: 'default',
        name: t('wishList.itemDetails.empty'),
      }]);
    }
  }

  const handleOnOpen = () => {
    setOpen(true);
    getUserWishList();
  };

  function RenderMenu() {
    return (
      <Menu vertical fluid text className="wishListMenu_header">
        {/* <Menu.Item>
          <Menu.Header style={headerStyle} content={t('wishList.title')} />
          <Divider inverted />
          <Menu.Item style={itemStyle} onClick={handleGoListManager}>
            <Icon name="cogs" color="blue" />
            {t('wishList.manage')}
          </Menu.Item>
        </Menu.Item> */}
        <Menu.Item>
          <Menu.Header style={headerStyle} content={t('wishList.buyNow.header')} />
          <Divider inverted />
          {userWishlist.map(list => {
            const { key, value, name } = setWishListFormat(list);
            return (
              <Menu.Item
                style={itemStyle}
                key={key}
                value={value}
                name={name}
                onClick={() => handleSelectedList(list)}
              />
            );
          })}
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Popup
      content={<RenderMenu />}
      position="bottom right"
      on="click"
      onClose={() => setOpen(false)}
      onOpen={handleOnOpen}
      openOnTriggerClick
      open={open}
      trigger={<Icon color="green" name="shipping fast" size="big" link />}
    />
  );
}

BuyNowMenu.propTypes = {
  openBuyNow: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(BuyNowMenu);
