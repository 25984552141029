import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const service = {};

service.getFinalUser = () => (
  request({
    route: apiRoutes.getFinalUsers(),
    method: 'GET',
  })
);

service.getShoppingCart = () => (
  request({
    route: apiRoutes.getShoppingCart(),
    method: 'GET',
  })
);

service.getActiveOrder = () => (
  request({
    route: apiRoutes.getActiveOrder(),
    method: 'GET',
  })
);

service.getExchangeRates = () => (
  request({
    route: apiRoutes.getExchangeRates(),
    method: 'GET',
  })
);

service.getCFDI = () => (
  request({
    route: apiRoutes.getCfdi(),
    method: 'GET',
  })
);

service.getDeliveryConfig = () => (
  request({
    route: apiRoutes.getDeliveryConfig(),
    method: 'GET',
  })
);

service.updateProductQuantity = (sku, body) => (
  request({
    route: apiRoutes.updateProductQuantity(sku),
    method: 'PUT',
    body,
  })
);

service.updateCartComment = body => (
  request({
    route: apiRoutes.updateCartComment(),
    method: 'PUT',
    body,
  })
);

service.getBranchOfficeAddress = () => (
  request({
    route: apiRoutes.getBranchOfficeAddress(),
    method: 'GET',
  })
);

service.getAvailableCredit = () => (
  request({
    route: apiRoutes.getCustomerCredit(),
    method: 'GET',
  })
);

service.updateShoppingCart = body => (
  request({
    route: apiRoutes.updateShoppingCart(),
    method: 'PUT',
    body,
  })
);

service.addDeliveryConfig = body => (
  request({
    route: apiRoutes.addDeliveryConfig(),
    method: 'POST',
    body,
  })
);

service.shoppingCartToQuote = body => (
  request({
    route: apiRoutes.shoppingCartToQuote(),
    method: 'PUT',
    body,
  })
);

service.contactAgent = body => (
  request({
    route: apiRoutes.contactAgent(body),
    method: 'POST',
    body,
  })
);

service.deleteShoppingCartProduct = (sku, outlet) => (
  request({
    route: apiRoutes.deleteShoppingCartProduct(sku, outlet),
    method: 'DELETE',
  })
);

service.deleteShoppingCart = () => (
  request({
    route: apiRoutes.deleteShoppingCart(),
    method: 'Delete',
  })
);

service.deleteAttachment = (orderId, attachId) => (
  request({
    route: apiRoutes.deleteAttachment(orderId, attachId),
    method: 'Delete',
  })
);

service.deleteAWSObject = url => (
  request({
    route: apiRoutes.deleteFileS3(),
    method: 'POST',
    body: { objectKey: url },
  })
);

service.getDataFormMakers = orderId => (
  request({
    route: apiRoutes.getDataFormMakers(orderId),
    method: 'GET',
  })
);

service.skuStockCheckout = sku => (
  request({
    route: apiRoutes.stockCheckout(sku),
    method: 'GET',
  })
);

service.getStockBySku = sku => (
  request({
    route: apiRoutes.getStockById(sku),
    method: 'GET',
  })
);

service.getOutletById = sku => (
  request({
    route: apiRoutes.getOutletById(sku),
    method: 'GET',
  })
);

service.getRequiredProductsBySku = sku => (
  request({
    route: apiRoutes.getRequiredProductsBySku(sku),
    method: 'GET',
  })
);

service.validateIquoteCart = quoteId => (
  request({
    route: apiRoutes.validateIquoteCart(quoteId),
    method: 'GET',
  })
);

export default service;
