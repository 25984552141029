import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Radio, Header, Grid, Button, Checkbox, Icon, TextArea, Form, Dropdown, Placeholder,
} from 'semantic-ui-react';
import DatePicker from '@material-ui/core/TextField';
import { TextField } from '../../common/components/materials';

const Description = ({
  t, code, handleCodeChange, randmonCode, id, rpCode, handleRpCodeChange, quantity, handleChangeQuantity, optionDays,
  description, handleChangeDescription, discountTypes, handleChangeType, type, optionCurrency, currency, handleChangeCurrency,
  handleChangeConditionDays, comment, handleChangeComment, startDate, handleStartDate, endDate, handleEndDate, handleCheckedStock,
  handleChangeStartDate, handleChangeEndtDate, behaviorTypes, handleChangeBehavior, handleCheckedCombinied, checkedCombinied, checkedStock,
}) => (
  <Grid container>
    <Grid.Row style={{ paddingBottom: '1rem' }}>
      <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
        <Header as="h4">
          {t('discounts.typeOfDiscount')}
        </Header>
      </Grid.Column>
    </Grid.Row>
    {discountTypes.length
      ? (
        <Grid.Row style={{ paddingBottom: '1rem' }}>
          {discountTypes.map(discountType => (
            <Grid.Column largeScreen={4} computer={4} tablet={5} mobile={8}>
              <Radio
                label={discountType.type}
                name="radioGroupType"
                value={discountType.id}
                checked={discountType.checked}
                onChange={handleChangeType}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      )
      : (
        <Grid.Row style={{ paddingBottom: '1rem' }}>
          <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
            <Placeholder>
              <Placeholder.Header>
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Header>
            </Placeholder>
          </Grid.Column>
        </Grid.Row>
      )}
    <Grid.Row>
      <Grid.Column largeScreen={8} computer={8} tablet={9} mobile={16}>
        <Header as="h4">
          {t('discounts.generalData')}
          {' '}
        </Header>
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={16}>
              <TextField
                fullWidth
                label={t('discounts.code')}
                value={code.value || ''}
                error={code.error || ''}
                onChange={handleCodeChange}
                maxLength={15}
                required
              />
            </Grid.Column>
            <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={16}>
              {!id
                ? <Button onClick={randmonCode}>{t('discounts.generate')}</Button>
                : null}
            </Grid.Column>
          </Grid.Row>
          {type !== 5
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={15} computer={15} tablet={9} mobile={16}>
                  <TextField
                    fullWidth
                    label={t('discounts.rp')}
                    value={rpCode.value || ''}
                    error={rpCode.error || ''}
                    onChange={handleRpCodeChange}
                    maxLength={20}
                  />
                </Grid.Column>
              </Grid.Row>
            )
            : null}
          <Grid.Row>
            <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
              <TextField
                fullWidth
                label={t('discounts.description')}
                value={description.value || ''}
                error={description.error || ''}
                onChange={handleChangeDescription}
                maxLength={250}
                required
              />
            </Grid.Column>
          </Grid.Row>
          {type !== 3
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={16}>
                  <TextField
                    fullWidth
                    label={type !== 5 ? t('discounts.quantity') : t('discounts.shippingFinalPrice')}
                    value={quantity.value >= 0 ? quantity.value : ''}
                    error={quantity.error || ''}
                    onChange={handleChangeQuantity}
                    maxLength={6}
                    required
                  />
                </Grid.Column>
                {type !== 2
                  ? (
                    <Grid.Column largeScreen={5} computer={5} tablet={4} mobile={16}>
                      <Dropdown
                        placeholder={t('discounts.currency')}
                        options={optionCurrency}
                        value={currency.value}
                        error={currency.error}
                        onChange={handleChangeCurrency}
                        fluid
                        selection
                        search
                      />
                    </Grid.Column>
                  )
                  : null}
              </Grid.Row>
            )
            : null}
          {type === 3
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={11} computer={11} tablet={11} mobile={16}>
                  {t('discounts.condition')}
                  <Dropdown
                    placeholder={t('discounts.creditDays')}
                    options={optionDays}
                    value={quantity.value}
                    error={quantity.error}
                    onChange={handleChangeConditionDays}
                    fluid
                    selection
                    search
                  />
                </Grid.Column>
              </Grid.Row>
            )
            : null}
          <Grid.Row>
            <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
              <Form>
                <TextArea
                  value={comment.value}
                  error={comment.error}
                  onChange={handleChangeComment}
                  placeholder={t('discounts.comments')}
                />
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row />
        </Grid>
      </Grid.Column>
      <Grid.Column largeScreen={8} computer={8} tablet={7} mobile={16}>
        <Header as="h4">
          <Icon name="calendar alternate" />
          {t('discounts.deadLine')}
        </Header>
        <Grid>
          <Grid.Row>
            <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={6}>
              <Checkbox disabled={startDate.disabled} checked={startDate.active} label={t('discounts.start')} onChange={handleChangeStartDate} />
            </Grid.Column>
            <Grid.Column largeScreen={2} computer={2} tablet={2} mobile={8}>
              <DatePicker
                type="date"
                value={startDate.value}
                onChange={handleStartDate}
                InputLabelProps={{ shrink: true }}
                disabled={!startDate.active}
                error={startDate.error}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={3} computer={3} tablet={4} mobile={6}>
              <Checkbox disabled={endDate.disabled} checked={endDate.active} label={t('discounts.expires')} onChange={handleChangeEndtDate} />
            </Grid.Column>
            <Grid.Column largeScreen={1} computer={1} tablet={1} mobile={8}>
              <DatePicker
                type="date"
                value={endDate.value}
                onChange={handleEndDate}
                InputLabelProps={{ shrink: true }}
                disabled={!endDate.active}
                error={endDate.error}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={16}>
              <Header as="h4">
                {t('discounts.behavior')}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {behaviorTypes.map(behaviorType => (
              <Grid.Column largeScreen={5} computer={5} tablet={7} mobile={9}>
                <Radio
                  label={behaviorType.type}
                  name="radioGroupBehavior"
                  value={behaviorType.id}
                  checked={behaviorType.checked}
                  disabled={behaviorType.disabled}
                  onChange={handleChangeBehavior}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
          {type !== 3 && type !== 5
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
                  <Checkbox
                    label={t('discounts.accumulative')}
                    onClick={handleCheckedCombinied}
                    checked={checkedCombinied}
                    styleType="info"
                  />
                </Grid.Column>
              </Grid.Row>
            )
            : null}
          {type !== 3 && type !== 4 && type !== 5
            ? (
              <Grid.Row>
                <Grid.Column largeScreen={15} computer={15} tablet={15} mobile={16}>
                  <Checkbox
                    label={t('discounts.stockRequired')}
                    onClick={handleCheckedStock}
                    checked={checkedStock}
                    styleType="info"
                  />
                </Grid.Column>
              </Grid.Row>
            )
            : null}
        </Grid>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

Description.defaultProps = {
  code: { value: '', error: '' },
  rpCode: { value: '', error: '' },
  description: { value: '', error: '' },
  startDate: { value: '', active: false, error: '' },
  endDate: { value: '', active: false, error: '' },
  type: { amount: false, percent: false, error: '' },
  quantity: { value: '', error: '' },
  behavior: 'exchange',
  checkedCombinied: false,
  active: false,
  randmonCode: null,
  comment: { value: '', error: '' },
  handleChangeComment: null,
  handleChangeDescription: null,
  handleChangeStartDate: null,
  handleChangeEndtDate: null,
  handleStartDate: null,
  handleEndDate: null,
  handleChangeQuantity: null,
  handleChangeBehavior: null,
  handleChangeType: null,
  handleCheckedCombinied: null,
};

Description.propTypes = {
  active: PropTypes.bool,
  behavior: PropTypes.shape({
    exchange: PropTypes.bool,
    segmentation: PropTypes.bool,
  }),
  behaviorTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checkedCombinied: PropTypes.bool,
  checkedStock: PropTypes.bool.isRequired,
  code: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  comment: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  currency: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  description: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  discountTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  endDate: PropTypes.shape({
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  error: PropTypes.string.isRequired,
  handleChangeBehavior: PropTypes.func,
  handleChangeComment: PropTypes.func,
  handleChangeConditionDays: PropTypes.func.isRequired,
  handleChangeCurrency: PropTypes.func.isRequired,
  handleChangeDescription: PropTypes.func,
  handleChangeEndtDate: PropTypes.func,
  handleChangeQuantity: PropTypes.func,
  handleChangeStartDate: PropTypes.func,
  handleChangeType: PropTypes.func,
  handleCheckedCombinied: PropTypes.func,
  handleCheckedStock: PropTypes.func.isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  handleEndDate: PropTypes.func,
  handleRpCodeChange: PropTypes.func.isRequired,
  handleStartDate: PropTypes.func,
  id: PropTypes.string.isRequired,
  optionCurrency: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  optionDays: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  quantity: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.number,
  }),
  randmonCode: PropTypes.func,
  rpCode: PropTypes.shape({
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  startDate: PropTypes.shape({
    active: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.string,
    value: PropTypes.string,
  }),
  type: PropTypes.shape({
    amount: PropTypes.bool,
    error: PropTypes.string,
    percent: PropTypes.bool,
  }),
  value: PropTypes.string.isRequired,
};

export default translate('common', { wait: true })(Description);
