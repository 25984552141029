import React, { Component } from 'react';
import { translate } from 'react-i18next';
import { Grid, Header, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import productService from '../services';

class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warehouses: [],
      loader: true,
      error: '',
    };
  }

  componentDidMount() {
    const { sku, isOutlet } = this.props;
    if (isOutlet) {
      this.getOutletAvailability(encodeURIComponent(sku));
    } else {
      this.getProductAvailability(encodeURIComponent(sku));
    }
  }

  getProductAvailability = sku => {
    const { t } = this.props;
    productService.getStockBySku(sku)
      .then(response => {
        let warehouses = response.stock;
        const existElectronic = response.stock.some(item => item.warehouse === 'MI09' || item.warehouse === 'GI09' || item.warehouse === 'YI09');
        if (existElectronic) {
          warehouses = response.stock.filter(item => item.warehouse === 'MI09' || item.warehouse === 'GI09' || item.warehouse === 'YI09');
        } else {
          warehouses = response.stock.filter(item => item.warehouse !== 'MI09' && item.warehouse !== 'GI09' && item.warehouse !== 'YI09');
        }
        this.setState({ warehouses, loader: false });
      })
      .catch(error => {
        const errorString = error.code === 1005 ? 'products.error.1005' : `error-code.${error.code}`;
        this.setState({ loader: false, error: t(errorString) });
      });
  };

  getOutletAvailability = sku => {
    const { t } = this.props;
    productService.getOutletById(sku)
      .then(response => {
        this.setState({ warehouses: response.stock, loader: false });
      })
      .catch(error => {
        const errorString = error.code === 1005 ? 'products.error.1005' : `error-code.${error.code}`;
        this.setState({ loader: false, error: t(errorString) });
      });
  };

  renderWarehouses = () => {
    const { error, warehouses } = this.state;
    const { t } = this.props;
    return !error
      ? warehouses.map(warehouse => (
        <Grid.Row>
          <Grid.Column width={8}>
            <p className="fullBlackText fontSizeText">{t(`availability.warehouses.${warehouse.warehouse}`)}</p>
          </Grid.Column>
          <Grid.Column width={8}>
            <p className="fullBlackText fontSizeText">{warehouse.stock}</p>
          </Grid.Column>
        </Grid.Row>
      )) : (
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <p className="fullBlackText fontSizeText">{error}</p>
          </Grid.Column>
        </Grid.Row>
      );
  };

  render() {
    const { loader } = this.state;
    const { t } = this.props;
    return (
      <Grid padded style={{ width: '18rem' }}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header color="blue" className="fontSizeTitle">{t('availability.branchOffice')}</Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header color="blue" className="fontSizeTitle">{t('availability.quantity')}</Header>
          </Grid.Column>
        </Grid.Row>
        {
          !loader
            ? this.renderWarehouses()
            : <Loader active inline="centered" />
        }
      </Grid>
    );
  }
}

Availability.propTypes = {
  sku: PropTypes.string.isRequired,
  isOutlet: PropTypes.bool,
};

Availability.defaultProps = {
  isOutlet: false,
};

export default translate('common', { wait: true })(Availability);
