import React, { useState } from 'react';
import { translate } from 'react-i18next';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import {
  Container,
  Menu,
  Search,
  Button,
  Grid,
} from 'semantic-ui-react';

import service from '../services';
import { generateSearchPath } from '../../common/utils';
import Toast from '../../common/components/toast';

const translatePath = 'productConfig.menuActions';

function MenuBar(props) {
  const {
    t,
    setItemContent,
    setItem,
    item,
    loading,
  } = props;
  const [searchQuery, setSearchQuery] = useState(item?.sku || '');
  const [searchLoader, setSearchLoader] = useState(false);
  const [foundResult, setFoundResult] = useState([]);
  const [searchitemLoader, setSearchitemLoader] = useState(false);

  const searchBySku = async sku => {
    try {
      setSearchitemLoader(true);
      return await service.getItemBySku(sku);
    } catch (error) {
      Toast(t('error-codes.1300'), 'error');
      return null;
    } finally {
      setSearchitemLoader(false);
    }
  };

  const setUrlParams = (sku, brand) => {
    const currentPath = window.location.pathname;
    const queryParams = queryString.parseUrl(currentPath);
    queryParams.query.sku = sku;
    queryParams.query.brand = brand;
    const url = generateSearchPath({ type: 'url', value: queryParams.query }, { onlyParams: true });
    window.history.pushState(null, null, url);
  };

  const handleOnEditItem = async (sku, brand) => {
    if (sku === item.sku && item.brand !== '') {
      setUrlParams(sku, brand);
      await setItemContent(sku, brand);
    } else {
      const foundItem = await searchBySku(sku);
      if (foundItem) {
        setUrlParams(sku, brand);
        setItem(foundItem);
        await setItemContent(foundItem.sku, foundItem.maker.brand);
      }
    }
  };

  const handleSearch = async (e, { value }) => {
    try {
      setSearchQuery(value);
      if (value !== '') {
        setSearchLoader(true);
        const { suggestions } = await service.searchItemSuggestions(value);
        const result = suggestions.map(suggestionItem => ({
          title: suggestionItem.sku,
          description: suggestionItem.brand,
        }));
        setFoundResult(result);
      }
    } catch (error) {
      Toast(t('toast.error.general'), 'error');
    } finally {
      setSearchLoader(false);
    }
  };

  const handleOnResultSelect = (e, { result }) => {
    setSearchQuery(result.title);
    setItem({ sku: result.title, brand: result.description });
  };

  const handleOnSelectFromList = async (e, { result }) => {
    await setSearchQuery(result.title);
    setItem({ sku: result.title, brand: result.description });
    handleOnEditItem(result.title, result.description);
  };

  return (
    <Container fluid style={{ marginBottom: '-20px' }}>
      <Grid>
        <Grid.Row only="computer tablet">
          <Grid.Column>
            <Menu borderless size="tiny">
              <Menu.Item header>{t(`${translatePath}.title`)}</Menu.Item>
              <Menu.Item>
                <Search
                  placeholder={t(`${translatePath}.placeholder`)}
                  loading={searchLoader}
                  value={searchQuery}
                  onSearchChange={handleSearch}
                  onResultSelect={handleOnResultSelect}
                  results={foundResult}
                />
              </Menu.Item>
              <Menu.Item>
                <Button
                  primary
                  disabled={searchQuery === '' || loading || searchitemLoader}
                  content={t(`${translatePath}.searchButton`)}
                  loading={loading || searchitemLoader}
                  onClick={() => handleOnEditItem(item.sku, item.brand)}
                />
              </Menu.Item>
              <Menu.Item position="right" header as="h5">
                <span style={{ color: '#3f9c35' }}>{t(`${translatePath}.selectedItem`)}</span>
                {' '}
                <span>{item && item?.sku ? item.sku : t(`${translatePath}.noItemSelected`)}</span>
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Menu borderless size="tiny">
              <Menu.Item header>SKU</Menu.Item>
              <Menu.Item>
                <Search
                  placeholder={t(`${translatePath}.searchProduct`)}
                  loading={searchLoader}
                  value={searchQuery}
                  onSearchChange={handleSearch}
                  onResultSelect={handleOnSelectFromList}
                  results={foundResult}
                />
              </Menu.Item>
            </Menu>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

MenuBar.propTypes = {
  item: PropTypes.shape({
    sku: PropTypes.string,
    brand: PropTypes.string,
  }),
  setItemContent: PropTypes.func.isRequired,
  setItem: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

MenuBar.defaultProps = {
  loading: false,
  item: {},
};

export default translate('common', { wait: true })(MenuBar);
