import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, List, Image, Input, Dropdown, Button, Icon, Modal, Popup, Divider, Responsive, Label,
} from 'semantic-ui-react';
import { CSSTransition } from 'react-transition-group';
import { callError } from '@compusoluciones-pdigitales/status-codes';

import { currencyFormat } from '../../common/utils';
import Quotation from '../../common/components/quotation';
import shippingRoutes from '../services';
import Toast from '../../common/components/toast';
import '../styles.css';

class ShippingDetail extends Component {
  static getDerivedStateFromProps(props) {
    if (props.arrayProductsData.length !== 0) {
      return {
        arrayProductsResponse: props.arrayProductsData,

      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      branchOfficesInformation: [],
      arrayProductsResponse: [],
      appearHome: true,
      selectShippingAddressModal: false,
      defaultBranch: '',
    };
  }

  componentDidMount() {
    this.handleSendPropsToIndex();
    this.handleGetDefaultAddress();
  }

  getParcelPrice = (body, index) => {
    const { arrayProductsResponse } = this.state;
    const { getArrayShipping } = this.props;
    let sku = [];
    let arrayData = arrayProductsResponse;
    arrayData = arrayData.map(currentData => {
      const data = { ...currentData };
      if (body !== null) sku = body.skus.map(skus => skus.sku).toString();
      const { addressId } = body;
      const { parcel } = body;
      const { UPSinsure } = body;
      const { warehouseCs } = body;
      const parcelPrice = Number(body.parcelPrice);
      const { origins } = body;
      const skuToUpdate = arrayData.findIndex(findElement => findElement.sku === sku);
      if (data.sku === sku) {
        data.quotation.map(qt => {
          const elementToUpdate = currentData.quotation.findIndex(findElement => findElement.index === index);
          if (qt.sku === sku && qt.addressId === addressId && qt.index === index) {
            arrayData[skuToUpdate].quotation[elementToUpdate].parcelprice = parcelPrice;
            arrayData[skuToUpdate].quotation[elementToUpdate].UPSinsure = UPSinsure;
            arrayData[skuToUpdate].quotation[elementToUpdate].warehouseCs = warehouseCs;
            arrayData[skuToUpdate].quotation[elementToUpdate].origins = origins;
            arrayData[skuToUpdate].quotation[elementToUpdate].addAddressValidation = false;
            arrayData[skuToUpdate].quotation[elementToUpdate].parcel = parcel;
          }
          if (qt.sku === sku && qt.addressId === addressId && qt.index === index && parcel > 0) {
            arrayData[skuToUpdate].quotation[elementToUpdate].parcelCheked = true;
            arrayData[skuToUpdate].quotation[elementToUpdate].parcel = parcel;
          }
          return qt;
        });
      }
      return data;
    });
    this.handleCalculateTotalCost(arrayData);
    getArrayShipping(arrayProductsResponse);
    this.setState({ arrayProductsResponse: arrayData });
  }

  handleSendPropsToIndex = () => {
    const { arrayProductsResponse } = this.state;
    const { getArrayShipping } = this.props;
    getArrayShipping(arrayProductsResponse);
    this.handleCalculateTotalCost(arrayProductsResponse);
  }

  handleCalculateTotalCost = arrayData => {
    const { getTotalCost } = this.props;
    const total = [];
    arrayData.map(data => {
      for (let i = 0; i < data.quotation.length; i++) {
        if (data.quotation[i].length !== 0) {
          total.push({ price: data.quotation[i].parcelprice });
          const sumQuotation = total.reduce((sum, price) => sum + price.price, 0);
          getTotalCost(sumQuotation);
        }
      }
      return data;
    });
  }

  handleBranchOfficesOnChange = (e, { name, value, id }) => {
    const { arrayProductsResponse } = this.state;
    let arrayData = arrayProductsResponse;
    const skuToUpdate = arrayData.findIndex(findElement => findElement.sku === name);
    arrayData = arrayData.map(arraydata => {
      if (arraydata.sku === name) {
        arraydata.quotation.map(currentQt => {
          if (id === currentQt.index) {
            const elementToUpdate = arraydata.quotation.findIndex(findElement => findElement.index === id);
            arrayData[skuToUpdate].quotation[elementToUpdate].addressId = value;
          }
          return currentQt;
        });
      }
      return arraydata;
    });
    this.setState({ arrayProductsResponse: arrayData });
  }

  handleAmountToSendChange = (e, {
    value, name, id, outlet,
  }) => {
    const { tr: t } = this.props;
    const { arrayProductsResponse } = this.state;
    let arrayData = arrayProductsResponse;
    const skuToUpdate = arrayData.findIndex(findElement => findElement.sku === name);
    const quantityValue = value.replace(/\D/g, '');
    let sumQuotationQuantity = 0;
    arrayData = arrayData.map(item => {
      if (name === item.sku && item.outlet === outlet) {
        const maxLength = item.quantity;
        item.quotation.map(currentQt => {
          const elementToUpdate = item.quotation.findIndex(findElement => findElement.index === id);
          if (name === item.sku && id === currentQt.index) {
            arrayData[skuToUpdate].quotation[elementToUpdate].quantity = Number(quantityValue);
            arrayData[skuToUpdate].quotation[elementToUpdate].sku = name;
          }
          for (let q = 0; q < item.quotation.length; q++) {
            if (item.quotation[q].length !== 0) {
              sumQuotationQuantity = item.quotation.reduce((acc, quantity) => acc + Number(quantity.quantity), 0);
              arrayData[skuToUpdate].restAmountToSend = maxLength - sumQuotationQuantity;
            }
            if (currentQt.quantity === 0) arrayData[skuToUpdate].quotation[elementToUpdate].quantity = '';
          }
          return currentQt;
        });
        if (Number(quantityValue) > maxLength || Number(quantityValue).length > item.quantity.length || sumQuotationQuantity > maxLength) {
          arrayData[skuToUpdate].amountToSendError = t('multi-shipping.errors.exceededQuantity');
          arrayData[skuToUpdate].errors = true;
        } else {
          arrayData[skuToUpdate].errors = false;
          arrayData[skuToUpdate].amountToSendError = '';
          delete arrayData[skuToUpdate].addAddressValidationError;
        }
        if (Number(quantityValue) < 1 && quantityValue !== '') {
          arrayData[skuToUpdate].amountToSendError = t('multi-shipping.errors.invalidQuantity');
          arrayData[skuToUpdate].errors = true;
        }
        if (item.restAmountToSend >= 1) { arrayData[skuToUpdate].addAddressValidation = false; }
      }
      return item;
    });
    this.setState({ arrayProductsResponse: arrayData });
  }

  handleGetAddress = value => {
    const { tr: t } = this.props;
    shippingRoutes.getBranchOfficeAddress()
      .then(response => {
        const arraybranchOffices = response.branchOffices;
        arraybranchOffices.map(currentAlter => {
          const alter = { ...currentAlter };
          if (alter.appointment === false) {
            alter.appointment = 'No';
          } else {
            alter.appointment = 'Si';
          }
          if (alter.parking === false) {
            alter.parking = 'No';
          } else {
            alter.parking = 'Si';
          }
          return alter;
        });
        const arrayAddressInformation = arraybranchOffices.filter(addressInfo => addressInfo.id === value);
        this.setState({ branchOfficesInformation: arrayAddressInformation });
      })
      .catch(error => {
        if (error.code !== 1005) {
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t(`error-codes.${error.code}`);
          Toast(messageError, 'error');
        }
      });
  }

  handleGetDefaultAddress = () => {
    const { tr: t } = this.props;
    const validateAddress = true;
    shippingRoutes.getBranchOfficeAddress()
      .then(response => {
        const customerAddress = response.branchOffices;
        const validatedCustomerAddress = customerAddress.map(currentRequiredField => {
          const requiredField = { ...currentRequiredField };
          requiredField.validateAddress = validateAddress;
          requiredField.key = requiredField.id;
          requiredField.text = requiredField.name;
          requiredField.value = requiredField.id;
          if (requiredField.name === '' || requiredField.street === ''
            || requiredField.number === '' || requiredField.phone === ''
            || requiredField.mainContact === '' || requiredField.auxiliaryContact === '') {
            requiredField.validateAddress = false;
          }
          return requiredField;
        });
        const filteredAddress = validatedCustomerAddress.filter(valid => valid.validateAddress === true);
        const defaultBranchId = !filteredAddress ? '' : filteredAddress[0].id;
        this.setState({ defaultBranch: defaultBranchId });
      })
      .catch(error => {
        let messageError = '';
        if (!error.code) messageError = t('error-codes.default');
        else messageError = t(`error-codes.${error.code}`);
        Toast(messageError, 'error');
      });
  }

  handleAddNewAddress = sku => {
    const { arrayProductsResponse } = this.state;
    const arrayDataRes = arrayProductsResponse;
    const { tr: t } = this.props;
    let messageError = '';
    arrayDataRes.map(currentData => {
      const i = { ...currentData };
      if (sku === i.sku && i.restAmountToSend > 0) {
        i.cont++;
        i.quotation.push({
          index: sku + i.cont, amountToSendError: '', parcelCheked: false, quantity: null, sku: '', UPSinsure: false, agentPrice: false,
        });
        delete i.addAddressValidationError;
      }
      if (sku === i.sku && i.restAmountToSend <= 0) {
        if (i.restAmountToSend <= 0) messageError = t('multi-shipping.errors.insufficientAmount');
        Toast(messageError, 'warning');
      } else {
        delete i.addAddressValidationError;
        return i;
      }
      return i;
    });
    this.setState({ arrayProductsResponse: arrayDataRes });
  }

  handleRemoveAddedAddress = ({ sku, outlet }, index) => {
    const { arrayProductsResponse } = this.state;
    const arrayDataRes = arrayProductsResponse;
    arrayDataRes.map(currentData => {
      const i = { ...currentData };
      if (sku === i.sku && outlet === i.outlet) {
        i.quotation.map(item => {
          if (item.index === index && item.quantity != null) {
            i.restAmountToSend += item.quantity;
          }
          return item;
        });
        i.quotation.pop();
        i.addAddressValidation = false;
        i.disabledAmountToSend = false;
      }
      return i;
    });
    this.setState({ arrayProductsResponse: arrayDataRes });
    this.handleCalculateTotalCost(arrayProductsResponse);
  }

  handleAddProducts = (e, { value, sku, isOutlet }) => {
    const { arrayProductsResponse } = this.state;
    const { tr: t, getArrayShipping, getShoppingCartCount } = this.props;
    let arrayData = arrayProductsResponse;
    const skuToUpdate = arrayData.findIndex(findElement => findElement.sku === sku);
    arrayData = arrayProductsResponse.map(currentItem => {
      const originalQuantity = currentItem.quantity;
      if (sku === currentItem.sku && isOutlet === currentItem.outlet) {
        arrayData[skuToUpdate].quantity = Number(value);
        arrayData[skuToUpdate].addAddressValidation = false;
        if (value > 0) {
          const body = { quantity: value, outlet: isOutlet };
          shippingRoutes.updateProductQuantity(encodeURIComponent(sku), body).then(() => {
            Toast(t('shoppingCart.quantityUpdated'), 'success');
            getShoppingCartCount();
          })
            .catch(error => {
              arrayData[skuToUpdate].quantity = originalQuantity;
              if (error.code === callError.products.INSUFFICIENT_OUTLET_STOCK) {
                Toast(t('shoppingCart.errors.noStock'), 'error');
              } else Toast(t('shoppingCart.errors.quantity'), 'error');
            });
        }
        currentItem.quotation.map(qt => {
          arrayData[skuToUpdate].restAmountToSend = value - qt.quantity;
          if (value < qt.quantity) {
            arrayData[skuToUpdate].amountToSendError = t('multi-shipping.errors.exceededQuantity');
            arrayData[skuToUpdate].errors = true;
          } else {
            arrayData[skuToUpdate].errors = false;
            arrayData[skuToUpdate].amountToSendError = '';
            delete arrayData[skuToUpdate].addAddressValidationError;
          }
          if (value <= 0) {
            arrayData[skuToUpdate].quantityError = t('multi-shipping.errors.invalidQuantity');
            arrayData[skuToUpdate].errors = true;
          } else {
            arrayData[skuToUpdate].quantityError = '';
          }
          return qt;
        });
      }
      return currentItem;
    });
    this.setState({ arrayProductsResponse: arrayData });
    getArrayShipping(arrayData);
  }

  handleOnUpdate = (e, { width }) => {
    if (width <= 425) {
      this.setState({ width, mobileWidth: '4em', marginBottom: '2em' });
    } else this.setState({ width, mobileWidth: '8.5em', marginBottom: '' });
  }

  render() {
    const {
      openModal, branchOfficesInformation, arrayProductsResponse, appearHome, selectShippingAddressModal,
      defaultBranch, mobileWidth, width, marginBottom,
    } = this.state;
    const { tr: t, arrayBranchOfficeAddress } = this.props;
    return (
      <Grid container>
        <Responsive as={Grid.Row} fireOnMount onUpdate={this.handleOnUpdate} />
        <Grid.Row>
          <Grid.Column largeScreen={6} computer={6} tablet={5} mobile={6}>
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('multi-shipping.products')}</Header>
            </Responsive>
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('multi-shipping.amountToSend')}</Header>
            </Responsive>
            <Grid.Row />
          </Grid.Column>
          <Grid.Column largeScreen={5} computer={5} tablet={5} mobile={5}>
            <Responsive as={Header} minWidth={768}>
              <Header as="h4" color="blue">{t('multi-shipping.shippingMethod')}</Header>
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        {arrayProductsResponse.map(item => (
          <Grid.Row>
            <Grid.Column largeScreen={6} computer={6} tablet={5} mobile={16}>
              <CSSTransition
                in={appearHome}
                appear
                timeout={2000}
                classNames="fade"
              >
                <Grid.Row>
                  <List relaxed="very" size="medium">
                    <List.Item key={`${item.sku}-${item.outlet || 'normal'}`}>
                      <div>
                        {item.outlet
                          ? <Label as="a" content={t('products.outlet')} color="red" ribbon />
                          : null}
                        <Image src={item.image} size="small" />
                      </div>
                      <List.Content>
                        <List.Header className="header_productName">
                          <Header as="h5" color="blue">{item.name}</Header>
                        </List.Header>
                        <List.Description>
                          <Header as="h5" color="black">
                            {t('multi-shipping.sku')}
                            {' '}
                            {item.sku}
                          </Header>
                        </List.Description>
                        <List.Description>
                          <Header as="h4" color="black">
                            {t('multi-shipping.quantity')}
                            &nbsp;
                            <Input
                              className="input-quantity"
                              style={{ webkitInnerSpinButton: 'none' }}
                              type="number"
                              defaultValue={item.quantity}
                              size="large"
                              transparent
                              sku={item.sku}
                              isOutlet={item.outlet}
                              onChange={this.handleAddProducts}
                              error={item.quantityError}
                            />
                            <Grid.Row>
                              <p style={{ color: 'red' }}>{item.quantityError}</p>
                            </Grid.Row>
                          </Header>
                          <Responsive maxWidth={768}>
                            <br />
                            {' '}
                            <br />
                          </Responsive>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Row>
              </CSSTransition>
            </Grid.Column>
            <Divider />
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={6}>
              {item.quotation.map(itemArray => (
                <CSSTransition
                  in={appearHome}
                  appear
                  timeout={1000}
                  classNames="fade"
                >
                  <Grid>
                    <Grid.Row>
                      <Input
                        transparent
                        className="inputAmountToSend"
                        id={itemArray.index}
                        defaultValue={itemArray.quantity}
                        value={itemArray.quantity}
                        disabled
                        onChange={this.handleAmountToSendChange}
                        name={item.sku}
                        outlet={item.outlet}
                        placeholder={width <= 425 ? '' : t('multi-shipping.amountToSend')}
                        size="large"
                        error={item.amountToSendError}
                        type="text"
                        pattern="[0-9]"
                        style={{ maxWidth: mobileWidth }}
                      />
                      {item.quotation.length > 1
                        ? (
                          <Popup
                            trigger={(
                              <Icon
                                name="trash"
                                size="small"
                                onClick={() => this.handleRemoveAddedAddress(item, itemArray.index)}
                              />
                            )}
                            content={t('multi-shipping.cancelNewAddress')}
                          />
                        )
                        : ''}
                      <Grid.Row>
                        <p style={{ color: 'red' }}>{item.amountToSendError}</p>
                      </Grid.Row>
                      <Responsive as={Grid.Row} minWidth={Responsive.onlyMobile.minWidth} mobile={8} maxWidth={425}>
                        <p>Cantidad a enviar</p>
                      </Responsive>
                    </Grid.Row>
                    <Grid.Row className="drop-add-branch-office">
                      <Dropdown
                        size=""
                        id={itemArray.index}
                        name={item.sku}
                        disabled
                        placeholder={t('multi-shipping.selectBranchOficce')}
                        defaultValue={itemArray.addressId}
                        options={arrayBranchOfficeAddress}
                        onChange={this.handleBranchOfficesOnChange}
                        search
                        selection
                        fluid
                      />
                      {itemArray.addressId != null
                        ? (
                          <Icon
                            name="info circle"
                            size="large"
                            onClick={() => {
                              this.handleGetAddress(itemArray.addressId);
                              this.setState({ openModal: true });
                            }}
                          />
                        )
                        : <Icon name="info circle" size="large" onClick={() => this.setState({ selectShippingAddressModal: true })} />}
                    </Grid.Row>
                  </Grid>
                </CSSTransition>
              ))}
              <Grid.Row>
                <Modal open={openModal} size="small">
                  <Icon name="window close" onClick={() => this.setState({ openModal: false })} />
                  {branchOfficesInformation.map(branchOfficeName => (
                    <Modal.Header color="blue">
                      {t('multi-shipping.branchOffice')}
                      {' '}
                      {branchOfficeName.name}
                    </Modal.Header>
                  ))}
                  <Modal.Content>
                    {branchOfficesInformation.map(information => (
                      <Modal.Description>
                        <Header as="h5">
                          {t('multi-shipping.address')}
                          {' '}
                          {information.street}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.postalCode')}
                          {information.postalCode}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.city')}
                          {information.city}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.needsAppointment')}
                          {information.appointment}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.parking')}
                          {' '}
                          {information.parking}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.contact')}
                          {' '}
                          {information.mainContact}
                        </Header>
                        <Header as="h5">
                          {t('multi-shipping.phone')}
                          {' '}
                          {information.phone}
                        </Header>
                        <br />
                      </Modal.Description>
                    ))}
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="black" onClick={() => this.setState({ openModal: false })}>Salir</Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Row>
              <Grid.Row>
                <Modal open={selectShippingAddressModal} closeIcon size="small">
                  <Icon name="window close" onClick={() => this.setState({ selectShippingAddressModal: false })} />
                  <Modal.Header>{t('multi-shipping.branchOfficeInformation')}</Modal.Header>
                  <Modal.Content>
                    <Modal.Description>
                      <Header as="h5">{t('multi-shipping.selectBranchOffice')}</Header>
                    </Modal.Description>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="black" onClick={() => this.setState({ selectShippingAddressModal: false })}>Salir</Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Row>
            </Grid.Column>
            <Divider />
            <Grid.Column largeScreen={5} computer={5} tablet={6} mobile={10} floated="right">
              <Grid.Row>
                {item.quotation.map(i => (
                  <Grid.Row style={{ marginBottom }}>
                    {i.agentPrice === false
                      ? (
                        <Quotation
                          skus={[{ sku: i.sku, quantity: i.quantity, outlet: i.outlet }]}
                          addressId={i.addressId}
                          tr={t}
                          response={body => this.getParcelPrice(body, i.index)}
                          parcelValue={i.parcel}
                          warehouseValue={i.warehouseCs}
                          isSingle={false}
                          defaultBranch={!i.addressId ? defaultBranch : ''}
                          needInsuranceUps={item.maker === 'Apple'}
                        />
                      )

                      : (
                        <Label color="blue" tag size="medium" className="quotation-logistics-agent">
                          {t('multi-shipping.logisticsPrice')}
                          {' '}
                          {currencyFormat(i.parcelprice)}
                        </Label>
                      )}
                    <Responsive maxWidth={425}>
                      <br />
                      <br />
                      <br />
                    </Responsive>
                  </Grid.Row>
                ))}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        ))}
        <Divider section />
      </Grid>
    );
  }
}

ShippingDetail.propTypes = {
  arrayBranchOfficeAddress: PropTypes.arrayOf(PropTypes.shape({})),
  getArrayShipping: PropTypes.func,
  getTotalCost: PropTypes.func,
  tr: PropTypes.func,
  arrayProductsData: PropTypes.arrayOf(PropTypes.shape({})),
  getShoppingCartCount: PropTypes.func.isRequired,
};

ShippingDetail.defaultProps = {
  arrayBranchOfficeAddress: [],
  getArrayShipping: () => {},
  getTotalCost: () => {},
  tr: () => {},
  arrayProductsData: [],
};

export default translate('common', { wait: true })(ShippingDetail);
