import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Header, Placeholder,
} from 'semantic-ui-react';
import ProductDetails from './product-details';
import { controlStringLength } from '../../../common/utils';
import '../../styles.css';

class DescriptionComponent extends Component {
  constructor(props) {
    super(props);
    const { productId } = this.props;
    this.state = {
      productId,
    };
  }

  render() {
    const {
      productId, products: productsState,
    } = this.state;
    const {
      price, promotionPrice, productName, sku, promotionDeadline, arrayObjectStock,
      arrayObjectStockOc, currencyPrice, makersPercents, maker,
      hasNotStock, deliverDate, updateCartCount,
    } = this.props;
    const MINPERCENT = makersPercents[maker] || 0.01;
    const minPrice = price * MINPERCENT;
    const isValidPrice = (price.toFixed(2) > 0 && (!promotionPrice || (promotionPrice > minPrice)));

    return (
      <Grid padded>
        <Grid.Row centered>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} stretched>
            <Header as="h3" color="blue">{controlStringLength(productName, 45)}</Header>
          </Grid.Column>
          <Grid.Column
            largeScreen={16}
            computer={16}
            tablet={16}
            mobile={16}
          >
            {
              productName ? (
                <ProductDetails
                  products={productsState}
                  productId={productId}
                  sku={sku}
                  price={price}
                  promotionPrice={promotionPrice}
                  promotionDeadline={promotionDeadline}
                  isValidPrice={isValidPrice}
                  arrayObjectStock={arrayObjectStock}
                  arrayObjectStockOc={arrayObjectStockOc}
                  currencyPrice={currencyPrice}
                  hasNotStock={hasNotStock}
                  deliverDate={deliverDate}
                  maker={maker}
                  updateCartCount={updateCartCount}
                />
              ) : (
                <Placeholder>
                  <Placeholder.Line full />
                  <Placeholder.Line full />
                  <Placeholder.Line full />
                </Placeholder>
              )
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
DescriptionComponent.defaultProps = {
};

DescriptionComponent.propTypes = {
  arrayObjectStock: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  arrayObjectStockOc: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currencyPrice: PropTypes.string.isRequired,
  hasNotStock: PropTypes.bool.isRequired,
  maker: PropTypes.string.isRequired,
  makersPercents: PropTypes.shape({}).isRequired,
  price: PropTypes.number.isRequired,
  product: PropTypes.shape({
    category: PropTypes.string,
    maker: PropTypes.string,
  }).isRequired,
  productId: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  promotionDeadline: PropTypes.string.isRequired,
  promotionPrice: PropTypes.number.isRequired,
  sku: PropTypes.string.isRequired,
  deliverDate: PropTypes.string.isRequired,
  updateCartCount: PropTypes.func.isRequired,
};

export default translate('common', { wait: true })(DescriptionComponent);
