/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import securityService from '@compusoluciones-pdigitales/siclik-security';
import {
  Menu, Segment, Grid, Icon, Header, Divider,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import * as session from '../common/sessions';
import ManageProductDetail from './components/manage-product-detail';
import Users from '../customer-directory/components/users';
import ServiceAccounts from '../customer-directory/components/service-accounts';
import DiscountView from '../discount-view';
import Discount from '../discount';
import Marketing from '../marketing-configuration';
import ProductKeywords from '../product-keywords';
import ProductCategorization from '../product-categorization';
import ProductSynchronization from '../product-sync';
import AdminGroups from '../groups';
import testData from '../test-data';
import Autorizadores from '../authorizers-users';
import Agents from '../agents';
import GridConfiguration from '../grid-configuration';
import CustomersB2b2c from './components/customers-b2c';
import AdminCouponsUsers from '../admin-coupons-users';
import Dashboard from './components/dashboard';
import Notifications from './components/notifications';
import allCategories from './categories';
import ContentProduct from './components/sync-content';
import EditContent from './components/edit-content';
import UploadImages from './components/upload-images';
import CouponsSearch from '../coupons-search';
import UsuariosDistribuidor from '../customers-by-user';
import UploadBulkImages from './components/upload-bulk-images';
import LogisticConfiguration from './components/logistic-configuration';

const {
  categoriesProduct,
  categoriesSearcher,
  categoriesDiscounts,
  categories,
  categoriesmkt,
} = allCategories;

class adminConfiguration extends Component {
  constructor(props) {
    super(props);
    const { access } = session.get();
    const params = new URLSearchParams(window.location.search);
    const section = params.get('section');
    const marketingAccess = securityService.validate(access, [
      securityService.getAccess.marketing,
      securityService.getAccess.administrativo,
    ], { oneAccess: true });
    this.state = {
      activeItem: section || '',
      marketingAccess,
      headersGroups: [],
      informationGroups: [],
      headersClients: [],
      informationClients: [],
    };
  }

  componentDidMount() {
    this.handleGetDiscountHeaders();
    this.handleGetDiscountInformation();
  }

  handleGetDiscountHeaders = () => this.setState({
    headersGroups: testData.headerGroups,
    headersModalClients: testData.headerClientsOfGroups,
    headersClients: testData.headerDiscountsClients,
  });

  handleGetDiscountInformation = () => {
    this.setState({
      informationGroups: testData.discountsGroupsArray,
      informationClients: testData.clientsOfGroups,
    });
  }

  handleCategorySelection = (e, { name }) => {
    const url = new URL(window.location.href);
    url.searchParams.set('section', name);
    window.history.pushState({}, '', url);
    this.setState({ activeItem: name });
  };

  handleChangeDiscountView = discountEvent => {
    if (discountEvent === true) {
      this.setState({ activeItem: 'discountView' });
    } else this.setState({ activeItem: 'Cupones' });
  }

  handleGetDiscountId = id => this.setState({ discountId: id });

  handleOnChangeMenu = menuName => {
    this.setState({ activeItem: menuName });
  };

  handleSelectedComponent = activeItem => {
    const {
      discountId, headersModalClients, headersClients, headersGroups, informationClients, informationGroups, marketingAccess,
    } = this.state;
    const { t } = this.props;
    switch (activeItem) {
      case 'ElegirContenido':
        return <ContentProduct onChangeMenu={this.handleOnChangeMenu} />;
      case 'EditarContenido':
        return <EditContent />;
      case 'Imagenes':
        return <UploadImages />;
      case 'CargaMasiva':
        return <UploadBulkImages />;
      case 'Atributos':
        return (
          <ProductKeywords />
        );
      case 'Categorizacion':
        return (
          <ProductCategorization />
        );
      case 'Sincronizador':
        return (
          <ProductSynchronization />
        );
      case 'DirectorioAgentes':
        return (
          <Agents
            changeView={() => { }}
          />
        );
      case 'UsuariosDistribuidor':
        return (
          <UsuariosDistribuidor
            changeView={() => { }}
          />
        );
      case 'GridConfiguration':
        return <GridConfiguration />;
      case 'AltaAgentes':
        return <Users customerId="G000000" />;
      case 'AltaServicioCuenta':
        return <ServiceAccounts />;
      case 'Autorizadores':
        return (<Autorizadores customerId="G000000" />);
      case 'Administradores':
        return (<AdminCouponsUsers customerId="G000000" />);
      case 'BuscadorCupones':
        return (<CouponsSearch customerId="G000000" />);
      case 'Cupones':
        return (
          <DiscountView
            changeView={event => this.handleChangeDiscountView(event)}
            discountId={id => this.handleGetDiscountId(id)}
          />
        );
      case 'Grupos':
        return (
          <AdminGroups
            headersGroups={headersGroups}
            headersClients={headersClients}
            headersModalClients={headersModalClients}
            informationGroups={informationGroups}
            informationClients={informationClients}
            newGroup={this.newGroup}
          />
        );
      case 'discountView':
        return (
          <Discount
            changeView={event => this.handleChangeDiscountView(event)}
            id={discountId}
          />
        );
      case 'Promocionales':
        return <Marketing t={t} />;
      case 'AdministrarProducto':
        return <ManageProductDetail />;
      case 'Notificaciones':
        return <Notifications />;
      case 'Logistica':
        return <LogisticConfiguration />;
      case 'ClientesB2B2C':
        return <CustomersB2b2c customerId="G000000" />;
      case 'Home':
        return <Dashboard changeItem={this.handleCategorySelection} />;
      default:
        return <Dashboard changeItem={this.handleCategorySelection} marketingAccess={marketingAccess} />;
    }
  };

  handleRenderMenuItemWeb = (name, nameIcon, content) => {
    const { activeItem } = this.state;
    return (
      <Menu.Item
        name={name}
        onClick={this.handleCategorySelection}
        active={activeItem === name}
      >
        <Header color="white" className="subtitles-menu">
          <Icon name={nameIcon} className="icon-subtitle" />
          <Header.Content>{content}</Header.Content>
        </Header>
      </Menu.Item>
    );
  };

  handleRenderMenuItemMobile = (name, nameIcon) => {
    const { activeItem } = this.state;
    return (
      <Menu.Item
        name={name}
        onClick={this.handleCategorySelection}
        active={activeItem === name}
        className="menu-icon"
      >
        <Icon name={nameIcon} className="icon-subtitle" />
      </Menu.Item>
    );
  };

  render() {
    const { activeItem, marketingAccess } = this.state;
    const { t } = this.props;
    return (
      <Grid padded>
        <Grid.Row className="no-padding">
          <Grid.Column computer={3} tablet={4} className="no-padding blueBackground" only="computer tablet">
            <Menu fluid vertical tabular color="blue" inverted>
              {this.handleRenderMenuItemWeb('Home', 'home', 'Inicio')}
              <Divider className="margin-divider" />
              <Header color="white" className="titles-menu padding-titles-menu no-margin" content={t('adminConfiguration.products')} />
              {categoriesProduct.map(cat => (
                this.handleRenderMenuItemWeb(cat.name, cat.icon, cat.content)
              ))}
              <Divider className="margin-divider" />
              <Header color="white" className="titles-menu padding-titles-menu no-margin" content={t('adminConfiguration.discounts')} />
              {categoriesDiscounts.map(cat => (
                this.handleRenderMenuItemWeb(cat.name, cat.icon, cat.content)
              ))}
              <Divider className="margin-divider" />
              <Header color="white" className="titles-menu padding-titles-menu no-margin" content={t('adminConfiguration.searcher')} />
              {categoriesSearcher.map(cat => (
                this.handleRenderMenuItemWeb(cat.name, cat.icon, cat.content)
              ))}
              <Divider className="margin-divider" />
              {categories.map(cat => (
                this.handleRenderMenuItemWeb(cat.name, cat.icon, cat.content)
              ))}
              {marketingAccess ? (
                <>
                  {categoriesmkt.map(cat => (
                    this.handleRenderMenuItemWeb(cat.name, cat.icon, cat.content)
                  ))}
                </>
              ) : null}
            </Menu>
          </Grid.Column>
          <Grid.Column mobile={2} className="no-padding blueBackground" only="mobile">
            <Menu fluid vertical tabular color="blue" inverted>
              {this.handleRenderMenuItemMobile('Home', 'home', 'Inicio')}
              {categoriesProduct.concat(categoriesDiscounts, categoriesSearcher, categories).map(cat => (
                this.handleRenderMenuItemMobile(cat.name, cat.icon)
              ))}
              {marketingAccess ? (
                <>
                  {categoriesmkt.map(cat => (
                    this.handleRenderMenuItemMobile(cat.name, cat.icon)
                  ))}
                </>
              ) : null}
            </Menu>
          </Grid.Column>
          <Grid.Column stretched computer={13} tablet={12} mobile={14} className="padding-segment">
            <Segment>
              {this.handleSelectedComponent(activeItem)}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default translate('common', { wait: true })(adminConfiguration);
adminConfiguration.defaultProps = {
  t: null,
};

adminConfiguration.propTypes = {
  t: PropTypes.func,
};
