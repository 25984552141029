import PropTypes from 'prop-types';
import React, { Component } from 'react';
import cookie from 'js-cookie';
import validator from 'validator';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { Helmet } from 'react-helmet';
import { translate } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Header, Grid, Button, Modal, Dropdown,
} from 'semantic-ui-react';
import service from './services';
import constants from './constants';
import Toast from '../common/components/toast';
import { TextField } from '../common/components/materials';
import routes from '../route-names';
import * as session from '../common/sessions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    const { match } = this.props;
    const { params } = match;
    const platform = !params.platform ? null : params.platform;
    // const params = new URLSearchParams(this.props.location.search);
    // const platform = params.get('plataforma');
    this.state = {
      email: '',
      password: '',
      platform,
      customerId: '',
      passwordError: '',
      customerError: false,
      emailError: '',
      failedAttemptsCounter: 0,
      captchaVisibility: false,
      captchaValue: null,
      sitekey: '6LcHiMIUAAAAAMWX6k8ZnJGGz71ZwZW9qnS8bnrw',
      options: [
        { key: 0, text: 'No se encontraron empresas', value: 0 },
      ],
      verificationCode: '',
      verificationCodeError: '',
      openVerificationCode: '',
      reviewedFactor: true,
      userId: '',
      showPassword: false,
    };
  }

  onKeyPress = e => {
    if (e.which === 13) {
      this.handleLogin(e);
    }
  }

  onSubmit = () => {
    const { onSubmit } = this.props;
    const recaptchaValue = this.recaptchaRef.current.getValue();
    onSubmit(recaptchaValue);
  }

  handleEmailChange = event => {
    const { value: email } = event.target;
    const { t } = this.props;
    const emptyError = t('login.error.empty');
    if (email.length === 0) {
      this.setState({ email, emailError: emptyError });
    } else {
      this.setState({ email, emailError: '' });
    }
  };

  handlePasswordChange = event => {
    const { value: password } = event.target;
    const { t } = this.props;
    const emptyError = t('login.error.empty');
    if (password.length === 0) {
      this.setState({ password, passwordError: emptyError });
    } else {
      this.setState({ password, passwordError: '' });
    }
  };

  handleVerificationCodeChange = event => {
    const { value: verificationCode } = event.target;
    const { t } = this.props;
    const emptyError = t('login.error.empty');
    if (verificationCode.length === 0) {
      this.setState({ verificationCode, verificationCodeError: emptyError });
    } else {
      this.setState({ verificationCode, verificationCodeError: '' });
    }
  };

  validateFields = () => {
    const { email } = this.state;
    const { t } = this.props;
    let exist = false;
    const fields = { emailError: '' };
    if (!validator.isEmail(email)) {
      exist = true;
      fields.emailError = t('login.error.email');
    }
    return { exist, fields };
  };

  handleLogin = event => {
    const { reviewedFactor } = this.state;
    let { failedAttemptsCounter } = this.state;
    event.preventDefault();
    const { t } = this.props;
    const { email, password, customerId } = this.state;
    const errors = this.validateFields();
    if (errors.exist) {
      this.setState({ ...errors.fields });
      return;
    }
    service.login({
      email, password: password.trim(), customerId, reviewedFactor,
    })
      .then(response => {
        const { accessToken, refreshToken } = response;
        const userId = session.decode(accessToken).id;
        // const { twoFactor: testFactor } = session.decode(accessToken);
        const twoFactor = false; // Se deshabilita 2FA
        if (twoFactor && !reviewedFactor) {
          this.setState({ openVerificationCode: true, userId });
        } else {
          session.setCookies(accessToken, refreshToken);
          ReactGA.set({ userId });
          ReactGA.event({
            category: 'Authentication',
            action: 'user-id available',
            label: userId,
          });
          ReactGA4.event({
            category: 'Authentication',
            action: 'user-id available',
            label: userId,
          });
          this.setState({ passwordError: '' }, () => this.goNextPage(accessToken));
        }
      })
      .catch(error => {
        if (error.code) {
          if (error.code === 1103) {
            const options = error.details.customers.map(customer => (
              ({ key: customer.id, text: customer.name, value: customer.id })
            ));
            this.setState({ options, open: true });
          } else {
            this.setState({ passwordError: t(`error-codes.${error.code}`) });
          }
          if (error.code === 1101) {
            failedAttemptsCounter += 1;
            this.setState({ failedAttemptsCounter });
            if (failedAttemptsCounter >= 3) this.handleValidateCaptchaFailedAttempts();
          }
        }
      });
  };

  redirectToSuscribe = async () => {
    const token = await service.tokenSuscribe().then(response => response.token).catch(() => '');
    window.location.replace(`${constants.URLSUSCRIBE}${token}`);
  };

  goNextPage = undecodeToken => {
    const { platform } = this.state;
    const { location, history } = this.props;
    const urlParams = new URLSearchParams(location.search);
    const from = urlParams.get('from');
    let modifiedRedirect = '';
    try {
      modifiedRedirect = from || location.state.from.pathname;
    } catch (error) {
      modifiedRedirect = false;
    }
    const token = session.decode(undecodeToken);
    if (token.customer.id === 'G000000') {
      history.push(routes.quotationsAgent.route);
    } else if (token.terms) {
      if (token.profile) {
        if (token.customer.id === 'G000000') {
          history.push(routes.quotationsAgent.route);
        } else {
          switch (modifiedRedirect || platform) {
            case 'comercio':
              history.push(routes.homeComercio.route);
              break;
            case 'suscribe':
              this.redirectToSuscribe();
              break;
            default:
              if (modifiedRedirect) history.push(modifiedRedirect);
              else history.push(routes.dashboard.route);
              break;
          }
        }
      } else {
        history.push(routes.profile.route);
      }
    } else {
      history.push(routes.onBoarding.route);
    }
  }

  handleChangeEnterprise = (e, { value }) => this.setState({ customerId: value, customerError: false });

  handleClick = event => {
    const { customerId } = this.state;
    if (customerId) {
      this.handleLogin(event);
    } else {
      Toast('Selecciona una empresa para continuar', 'error');
      this.setState({ customerError: true });
    }
  }

  handleLogout = () => {
    this.setState({ password: '' });
    if (process.env.REACT_APP_ENV === 'production') {
      cookie.remove('token', { secure: true });
      cookie.remove('element', { secure: true });
    } else {
      cookie.remove('token');
      cookie.remove('element');
    }
  }

  handleCaptchaOnChange = value => {
    if (value !== null) {
      this.setState({ failedAttemptsCounter: 0, captchaValue: value });
    }
  }

  handleValidateCaptchaFailedAttempts = () => {
    const { captchaValue } = this.state;
    if (captchaValue !== null) this.setState({ failedAttemptsCounter: 0 });
    else this.setState({ captchaVisibility: true });
  }

  handleOnExpiredCaptcha = value => {
    if (value === undefined) this.setState({ failedAttemptsCounter: 3, captchaValue: value });
  }

  validateCode = async (code, userId) => {
    try {
      const { authenticated } = await service.validateCode(code, userId);
      return authenticated;
    } catch (error) {
      return false;
    }
  }

  handleClikVerificate = async event => {
    const { t } = this.props;
    this.setState({ verificationLoader: true });
    const { verificationCode, userId } = this.state;
    const result = await this.validateCode(verificationCode, userId);
    if (result) {
      this.setState({ reviewedFactor: true });
      this.handleClick(event);
    } else {
      Toast(t('login.error.invalidVerificationCode'), 'error');
      this.setState({ verificationCode: '', reviewedFactor: false, verificationLoader: false });
    }
  }

  handleClickShowPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  }

  render() {
    const { t } = this.props;
    const {
      email, password, passwordError, emailError, open, customerId, options, customerError, captchaVisibility,
      failedAttemptsCounter, sitekey, openVerificationCode, verificationCode, verificationCodeError, verificationLoader,
      showPassword,
    } = this.state;
    return (
      <Grid padded>
        <Helmet
          title="siclik - inicia sesión"
          meta={[
            {
              name: 'description',
              content: 'Es la plataforma que concentra todos nuestros comercios electrónicos para comprar y rentar productos y servicios tecnológicos.',
            },
          ]}
        />
        <Grid.Row centered>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
            <Header size="huge" style={{ letterSpacing: '.2em', paddingBottom: '1.8rem' }}>{t('login.welcome')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="no-padding-bottom" centered>
          <Grid.Column width={16}>
            <TextField
              id="user"
              fullWidth
              label={t('login.user')}
              InputProps={{ autoComplete: 'off' }}
              error={emailError || ''}
              value={email || ''}
              onChange={this.handleEmailChange}
              onKeyPress={this.onKeyPress}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={16} style={{ paddingBottom: '1.5rem' }}>
            <TextField
              id="password"
              fullWidth
              label={t('login.password')}
              error={passwordError || ''}
              value={password || ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{ autoComplete: 'new-password' }}
              onChange={this.handlePasswordChange}
              onKeyPress={this.onKeyPress}
              rightIcon={showPassword ? 'eye' : 'eye slash'}
              iconChange={this.handleClickShowPassword}
            />
          </Grid.Column>
        </Grid.Row>
        {
          failedAttemptsCounter < 3
            ? (
              <Grid.Row centered>
                <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={10} textAlign="center">
                  <Button color="lightBlack" onClick={this.handleLogin} fluid size="large">{t('login.access')}</Button>
                </Grid.Column>
              </Grid.Row>
            )
            : null
        }
        {
          captchaVisibility === true
            ? (
              <Grid.Row centered>
                <form onSubmit={this.onSubmit}>
                  <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16} textAlign="center">
                    <ReCAPTCHA
                      ref={this.recaptchaRef}
                      sitekey={sitekey}
                      onChange={this.handleCaptchaOnChange}
                      onExpired={this.handleOnExpiredCaptcha}
                    />
                  </Grid.Column>
                </form>
              </Grid.Row>
            )
            : null
        }
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <a href={routes.reset.route}>{t('login.forgot')}</a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <a href="https://www.youtube.com/watch?v=5GMP0u3dQ7k">{t('login.watchVideo')}</a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16} textAlign="center">
            <a href="https://appsd.compusoluciones.com/csAltaClientesVer2/Cliente/SolicitudAlta.aspx ">{t('login.singUp')}</a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Modal size="mini" open={open} onClose={this.close}>
            <Modal.Header>Empresa</Modal.Header>
            <Modal.Content>
              <p>{t('login.selectReseller')}</p>
              <Dropdown
                onChange={this.handleChangeEnterprise}
                options={options}
                placeholder={t('login.selectResellerPlaceholder')}
                selection
                error={customerError}
                value={customerId}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="lightBlack" onClick={this.handleClick}>{t('login.access')}</Button>
              <Button onClick={() => this.setState({ customerId: '', open: false })}>Regresar</Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
        <Grid.Row>
          <Modal size="mini" open={openVerificationCode} onClose={this.close}>
            <Modal.Header>{t('login.twoFactor')}</Modal.Header>
            <Modal.Content>
              <p>{t('login.twoFactorInstructions')}</p>
              <TextField
                label={t('login.twoFactorLabel')}
                error={verificationCodeError}
                value={verificationCode}
                onChange={this.handleVerificationCodeChange}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="lightBlack" disabled={verificationLoader} onClick={this.handleClikVerificate}>{t('login.verify')}</Button>
              <Button onClick={() => this.setState({ verificationCode: '', openVerificationCode: false, verificationLoader: false })}>{t('login.goBack')}</Button>
            </Modal.Actions>
          </Modal>
        </Grid.Row>
      </Grid>
    );
  }
}

Login.propTypes = {
  onSubmit: PropTypes.func,
};

Login.defaultProps = {
  onSubmit: () => { },
};

export default translate('common', { wait: true })(Login);
