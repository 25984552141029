import React from 'react';
import { Image, Grid } from 'semantic-ui-react';

const assetUrl = `${process.env.REACT_APP_URL_AWS}/imagenes/assets/siclik/`;
const logo = `${assetUrl}siclik_compusoluciones.png`;

const PublicHeader = () => (
  <Grid verticalAlign="middle" padded textAlign="center">
    <Grid.Row className="menu-bar">
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
        <Image src={logo} size="medium" wrapped />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

PublicHeader.propTypes = {
};

export default PublicHeader;
