import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, Transition, Button, Label, Responsive,
} from 'semantic-ui-react';
import './style.css';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      minHeight: 0,
      width: 0,
    };
  }

  componentDidMount() {
    const { duration } = this.props;
    if (duration > 0) {
      this.interval = setInterval(this.nextSlide, duration);
    }
  }

  componentWillUnmount() {
    const { duration } = this.props;
    if (duration) clearInterval(this.interval);
  }

  nextSlide = () => {
    const { currentIndex } = this.state;
    const { elements, onSlideChange } = this.props;
    const index = (currentIndex + 1) % elements.length;
    if (onSlideChange) onSlideChange(elements[index], index);
    this.setState({ currentIndex: index });
  }

  prevSlide = () => {
    const { currentIndex } = this.state;
    const { elements, onSlideChange } = this.props;
    const index = ((currentIndex - 1) % elements.length) < 0
      ? elements.length - 1
      : (currentIndex - 1) % elements.length;
    if (onSlideChange) onSlideChange(elements[index], index);
    this.setState({ currentIndex: index });
  }

  gotToSlide = index => {
    const { duration } = this.props;
    if (duration) clearInterval(this.interval);
    this.setState({ currentIndex: index });
  }

  nextClicked = e => {
    const { duration, stopOnClick } = this.props;
    if (duration > 0 && stopOnClick) clearInterval(this.interval);
    e.target.blur();
    this.nextSlide();
  }

  prevClicked = e => {
    const { duration, stopOnClick } = this.props;
    if (duration > 0 && stopOnClick) clearInterval(this.interval);
    e.target.blur();
    this.prevSlide();
  }

  handleOnUpdate = (e, { width }) => {
    const { aspectRatio } = this.props;
    const minHeight = aspectRatio > 0 ? Math.round(width / aspectRatio) : 0;
    this.setState({ width, minHeight });
  }

  render() {
    const {
      elements, animation, showIndicators, showNextPrev, gridStyle,
    } = this.props;
    const { currentIndex, minHeight, width } = this.state;
    return (
      <Responsive as={Grid} padded style={gridStyle} fireOnMount onUpdate={this.handleOnUpdate}>
        <Grid.Row style={{ minHeight }} className="carousel no-padding">
          <Grid.Column className="no-padding-x">
            <Transition key={`index_${+new Date()}`} transitionOnMount duration={2000} animation={animation}>
              {elements.find((element, index) => (currentIndex === index)).render({ width })}
            </Transition>
            {
              (showNextPrev)
                ? (
                  <Grid className="overlay-grid-left" style={{ height: '100%' }}>
                    <Grid.Row className="no-padding">
                      <Grid.Column width={16} className="no-padding">
                        <Button className="prev" onClick={e => this.prevClicked(e)} icon="caret left" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )
                : null
            }
            {
              (showIndicators)
                ? (
                  <Grid className="overlay-grid-bottom" style={{}}>
                    <Grid.Row className="no-padding">
                      <Grid.Column width={16} className="no-padding">
                        <div className="carousel-indicators">
                          {(elements).map((elemnt, index) => {
                            if (currentIndex === index) {
                              return (
                                <Label as="a" onClick={() => this.gotToSlide(index)} circular color="black" empty />
                              );
                            }
                            return (
                              <Label as="a" onClick={() => this.gotToSlide(index)} circular color="grey" empty />
                            );
                          })}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )
                : null
            }
            {
              (showNextPrev)
                ? (
                  <Grid className="overlay-grid-right" style={{ height: '100%' }}>
                    <Grid.Row className="no-padding">
                      <Grid.Column width={16} className="no-padding">
                        <Button className="next" onClick={e => this.nextClicked(e)} icon="caret right" />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                )
                : null
            }
          </Grid.Column>
        </Grid.Row>
      </Responsive>
    );
  }
}

Carousel.propTypes = {
  elements: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      render: PropTypes.func,
      action: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      }),
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  animation: PropTypes.string,
  duration: PropTypes.number,
  aspectRatio: PropTypes.number,
  onSlideChange: PropTypes.func,
  showNextPrev: PropTypes.bool,
  showIndicators: PropTypes.bool,
  stopOnClick: PropTypes.bool,
  gridStyle: PropTypes.shape({}),
};

Carousel.defaultProps = {
  animation: 'fade right',
  duration: 0,
  aspectRatio: 0,
  onSlideChange: null,
  showNextPrev: false,
  showIndicators: false,
  stopOnClick: false,
  gridStyle: {},
};

export default Carousel;
