import React, { useState, useContext } from 'react';
import { Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { CartContext } from '../context/cart-context';

import StepsBuyNow from './components/ActivitiesMenu';
import ShippingStep from './components/ShippingStep';
import ConfirmOrderStep from './components/ConfirmOrderStep';
import ActionButtons from './components/ActionButtons';
import OrderErrorMessage from './components/OrderErrorMessages';

import './style.css';

import {
  buyQuickCart,
  createCart,
  getBranchOffices,
  shoppingCartToQuote,
  sendToCart,
} from './request';

const SHIPPING = {
  shipping: true,
  confirmOrder: false,
};

const ORDER_CONFIRM = {
  shipping: false,
  confirmOrder: true,
};

function getDeliveryItems(items = []) {
  const deliveryItems = items.filter(item => item.hasDelivery);
  return deliveryItems.map(({ sku, quantity }) => ({ sku, quantity }));
}

function hasDeliveryItems(items) {
  return items.some(item => item.hasDelivery);
}

function hasItems(items) {
  return items.length > 0;
}

function BuyNow(props) {
  const {
    open, onClose, wishListName, items, t,
  } = props;
  const { updateCartCount } = useContext(CartContext);
  const [buyCartLoader, setBuyCartLoader] = useState(false);
  const [sendCartLoader, setSendCartLoader] = useState(false);
  const [branchOffices, setBranchOffices] = useState([]);
  const [buyCartError, setBuyCartError] = useState(false);
  const [selectedBranchOffice, setSelectedBranchOffice] = useState('');
  const [selectedShipping, setSelectedShipping] = useState(null);
  const [userCart, setUserCart] = useState(false);
  const [activeStep, setActiveStep] = useState({
    shipping: false,
    confirmOrder: false,
  });

  const loadFirstStep = () => {
    setUserCart(false);
    setBuyCartError(false);
    setActiveStep(SHIPPING);
  };

  const loadSecondStep = () => {
    setActiveStep(ORDER_CONFIRM);
  };

  const handleOnClose = () => {
    onClose();
    setBranchOffices([]);
    setSelectedBranchOffice('');
    setSelectedShipping(null);
    setBuyCartLoader(false);
    loadFirstStep();
  };

  async function loadStepWithDeliveryItems() {
    try {
      loadFirstStep();
      const userBranchOffices = await getBranchOffices();
      setBranchOffices(userBranchOffices);
    } catch (error) {
      setBranchOffices([]);
    }
  }

  const handleOnMount = async () => {
    if (hasDeliveryItems(items) || !hasItems(items)) {
      loadStepWithDeliveryItems();
    } else {
      loadSecondStep();
    }
  };

  const handleBuyCart = async () => {
    try {
      setBuyCartLoader(true);
      await buyQuickCart(userCart);
      handleOnClose();
    } catch (error) {
      setBuyCartError(true);
      setBuyCartLoader(false);
    }
  };

  const handleSendToCart = async () => {
    try {
      setSendCartLoader(true);
      await shoppingCartToQuote();
      await sendToCart(items);
      await updateCartCount();
      setSendCartLoader(false);
      handleOnClose();
    } catch (error) {
      setSendCartLoader(false);
    }
  };

  const handleCartGenerated = cart => {
    if (!cart) loadFirstStep();
    setUserCart(cart);
  };

  return (
    <Modal onMount={handleOnMount} size="small" open={open} closeIcon onClose={handleOnClose} dimmer="blurring">
      <Modal.Header as="h5">
        {' '}
        {t('wishList.buyNow.header')}
        :
        {' '}
        {wishListName}
        {' '}
      </Modal.Header>
      <Modal.Content>
        <StepsBuyNow steps={activeStep} />
        <ShippingStep
          active={activeStep.shipping}
          t={t}
          branchOffices={branchOffices}
          setSelectedBranchOffice={setSelectedBranchOffice}
          selectedBranchOffice={selectedBranchOffice}
          selectedShipping={setSelectedShipping}
          items={getDeliveryItems(items)}
        />
        <ConfirmOrderStep
          active={activeStep.confirmOrder}
          wishListName={wishListName}
          selectedShipping={selectedShipping}
          items={items}
          generateQuickCart={createCart}
          cartGenerated={handleCartGenerated}
        />
        <OrderErrorMessage
          hidden={!buyCartError}
        />
      </Modal.Content>
      <Modal.Actions>
        <ActionButtons
          buyCartError={buyCartError}
          hasCart={userCart}
          hasShipping={!selectedShipping || selectedShipping.parcel === ''}
          hasItems={hasDeliveryItems(items)}
          activeStep={activeStep}
          loadFirstStep={loadFirstStep}
          loadSecondStep={loadSecondStep}
          sendListToCart={handleSendToCart}
          buyQuickCart={handleBuyCart}
          sendCartLoader={sendCartLoader}
          buyCartLoader={buyCartLoader}
        />
      </Modal.Actions>
    </Modal>
  );
}

BuyNow.defaultProps = {
  wishListName: '',
};

BuyNow.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  wishListName: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    sku: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired).isRequired,
};

export default translate('common', { wait: true })(BuyNow);
