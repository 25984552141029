import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Segment, Checkbox, Popup, Icon, Dropdown, Divider, Header, Label,
} from 'semantic-ui-react';
import validator from 'validator';
import SiclikTable from '../../../common/components/table';
import { TextField } from '../../../common/components/materials';
import '../../styles.css';

class TableWholesaleprice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availabilityHeaders: [
        { key: 'id', isKey: true },
        { key: 'min', name: 'Desde' },
        { key: 'max', name: 'Hasta' },
        { key: 'price', name: 'Precio Unidad' },
      ],
      mapIdHpInputView: false,
      hpMapIdError: '',
      hpMapId: '',
    };
  }

  handleSelectHpId = (e, { checked }) => {
    const { handleGetsteppedPriceSelection } = this.props;
    this.setState({ mapIdHpInputView: checked });
    handleGetsteppedPriceSelection(checked);
  }

  handleHpMapIdChange = event => {
    const { t, handleGetHpMapId } = this.props;
    const maxLength = 20;
    let errors = false;
    const { value: hpMapId } = event.target;
    if (hpMapId.length > 0) {
      if ((hpMapId.trim()).length > maxLength) {
        errors = true;
        this.setState({ hpMapId, hpMapIdError: t('productDetails.errors.toLong') });
      }
      if (!validator.isNumeric(hpMapId)) {
        errors = true;
        this.setState({ hpMapId, hpMapIdError: t('multibrand.errors.notNumeric') });
      }
    }
    if (errors !== true) {
      this.setState({ hpMapId, hpMapIdError: '' });
    }
    handleGetHpMapId(hpMapId, errors);
  }

  render() {
    const { t, steppedPriceHp } = this.props;
    const {
      availabilityHeaders, hpMapId, hpMapIdError, mapIdHpInputView,
    } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Label className="no-cursor" as="a" color="blue" ribbon>
              <Dropdown
                open
                text={(
                  <Header color="white" as="h4">
                    {t('Precios por volumen')}
                    &nbsp;
                  </Header>
                )}
                icon={null}
                className="no-cursor"
              >
                <Dropdown.Menu>
                  <Dropdown.Header
                    content={(
                      <SiclikTable
                        singleSelection
                        data={steppedPriceHp}
                        headers={availabilityHeaders}
                      />
                  )}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Divider />
        </Grid.Row>
        <Grid.Row>
          <Divider />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Segment compact>
              <Checkbox
                label="Ingresar Map ID"
                onChange={this.handleSelectHpId}
              />
              <Popup
                on="click"
                pinned
                trigger={<Icon link circular name="info" />}
              >
                Es importante contar con un Map ID aprobado y vigente para hacer validos los precios publicados. Si aún no cuentas con un Map ID, ingresa
                {' '}
                <a href="https://hpconnect.itcs.hp.com/Login" target="_blank" rel="noreferrer"><b>aquí</b></a>
                {' '}
                para solicitarlo. Para más información, acércate con tu ejecutivo de venta
              </Popup>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {mapIdHpInputView ? (
          <Grid.Row>
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <TextField
                id="hpMapId"
                fullWidth
                maxLength={20}
                label="Map ID"
                required
                error={hpMapIdError || ''}
                value={hpMapId || ''}
                onChange={this.handleHpMapIdChange}
              />
            </Grid.Column>
          </Grid.Row>
        )
          : ''}
      </Grid>
    );
  }
}
TableWholesaleprice.defaultProps = {
  // eslint-disable-next-line no-console
  t: () => console.log('Missing function t'),
  handleGetHpMapId: () => { },
  handleGetsteppedPriceSelection: () => { },
};

TableWholesaleprice.propTypes = {
  steppedPriceHp: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleGetsteppedPriceSelection: PropTypes.arrayOf(PropTypes.shape({})),
  t: PropTypes.func,
  handleGetHpMapId: PropTypes.func,
};
export default translate('common', { wait: true })(TableWholesaleprice);
