import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import './styles.css';
import PublicHeader from './components/header';

class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.loginDiv = React.createRef();
    this.state = {
    };
  }

  render() {
    const { children } = this.props;
    return (
      <div className="login-container">
        <div className="login-header"><PublicHeader /></div>
        <div className="login-children">
          <Grid verticalAlign="middle" centered>
            <Grid.Row centered>
              <Grid.Column mobile={16} tablet={16} computer={14} widescreen={14} largeScreen={14}>
                {children}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }
}

LoginLayout.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default LoginLayout;
