import { request } from '../common/fetch-utils';
import apiRoutes from '../apiRoutes';

const users = {};

users.getUsersByCustomer = customerId => (
  request({
    route: apiRoutes.getCustomerUsers(customerId),
  })
);

users.getAdminCouponsUsers = () => (
  request({
    route: apiRoutes.getUsersCoupons(),
    method: 'GET',
  })
);

users.addAdminCoupons = body => (
  request({
    route: apiRoutes.addAdminCoupons(),
    method: 'POST',
    body,
  })
);

users.disableAdminCoupons = id => (
  request({
    route: apiRoutes.disableAdminCoupons(id),
    method: 'DELETE',
  })
);

export default users;
