import React from 'react';
import { translate } from 'react-i18next';
import {
  Grid, Header, Container, Divider, Popup, Label,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import SiclikTable from '../../common/components/table';
import { currencyFormat } from '../../common/utils';

function handleRenderCouponDetails(coupons, currency, t) {
  return (
    <Popup
      size="huge"
      trigger={<Label as="a" tag color="blue">{t('checkout.seeCoupon')}</Label>}
      flowing
      on="click"
    >
      {coupons.map(item => (
        <Grid centered columns={2}>
          <Grid.Column largeScreen={7} computer={7} tablet={7} mobile={7}>
            <Header as="h4" color="blue">
              {t('checkout.couponCode')}
&nbsp;
              <Header as="h5" color="black">
                {' '}
                {item.code}
              </Header>
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9}>
            <Header as="h4" color="blue">
              {t('checkout.discountApplied')}
&nbsp;
              <Header as="h5" color="green">{item.discount ? currencyFormat(item.discount, currency) : item.days}</Header>
            </Header>
          </Grid.Column>
          <Divider horizontal />
        </Grid>
      ))}
    </Popup>
  );
}

function setStock(stock) {
  const stockObjectCopy = stock;
  const counter = (previousValue, currentValue) => previousValue + currentValue;
  const totalStock = stockObjectCopy.map(item => item.stock).reduce(counter);
  const totalStockGdl = stock.find(item => item.warehouse === 'GI01') ? stock.find(item => item.warehouse === 'GI01').stock : 0;
  const totalStockMty = stock.find(item => item.warehouse === 'YI01') ? stock.find(item => item.warehouse === 'YI01').stock : 0;
  const totalStockMx = stock.find(item => item.warehouse === 'MI01') ? stock.find(item => item.warehouse === 'MI01').stock : 0;
  const totalStockEm = stock.find(item => item.warehouse === 'MI55') ? stock.find(item => item.warehouse === 'MI55').stock : 0;
  return {
    totalStock, totalStockGdl, totalStockMty, totalStockMx, totalStockEm,
  };
}

function setContentPopup(stocksArray, t) {
  const componentItem = stocksArray.map(item => {
    const totalStock = Object.values(item)[0];
    const itemKey = Object.keys(item)[0];
    let warehouseLabel = '';
    switch (itemKey) {
      case 'totalStockGdl': warehouseLabel = t('productDetails.guadalajara'); break;
      case 'totalStockMty': warehouseLabel = t('productDetails.monterrey'); break;
      case 'totalStockMx': warehouseLabel = t('productDetails.mexico'); break;
      case 'totalStockEm': warehouseLabel = t('productDetails.edomexico'); break;
      default: break;
    }
    return (
      <Grid.Column textAlign="center" largeScreen={4} computer={4} tablet={4} mobile={4}>
        <Header style={{ fontFamily: 'News Gothic MT' }} color="fullBlack" as="h6">{warehouseLabel}</Header>
        <p className="fullBlackText">
          <b>{totalStock === null || Number(totalStock) <= 0 ? 0 : totalStock}</b>
          {' '}
          {t('productDetails.pieces')}
        </p>
      </Grid.Column>
    );
  });
  return componentItem;
}

function setPopup(productStock, t) {
  const {
    totalStock, totalStockGdl, totalStockMty, totalStockMx, totalStockEm,
  } = setStock(productStock);
  const warehouses = [{ totalStockGdl }, { totalStockMty }, { totalStockMx }, { totalStockEm }];
  return (
    <Popup
      size="tiny"
      trigger={(
        <Label as="a">
          <Header as="h5" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">
            <Header as="h5" className="available" style={{ fontFamily: 'News Gothic MT' }} color="fullBlack">
              <u>{totalStock === null || Number(totalStock) <= 0 ? t('products.notAvailable') : totalStock}</u>
            </Header>
          </Header>
        </Label>
      )}
      flowing
      hoverable
    >
      {
      totalStock === null
        ? <Header className="header_price" color="fullBlack">{t('products.notAvailable')}</Header>
        : (
          <Grid centered divided columns={4}>
            {setContentPopup(warehouses, t)}
          </Grid>
        )
    }
    </Popup>
  );
}

const Orders = props => {
  const { t, currency: propsCurrency } = props;
  const { ordersData } = props;
  const currency = propsCurrency === 2 ? 'USD' : 'MXN';
  const orders = ordersData.map(currentOrder => {
    const order = { ...currentOrder };
    order.items.forEach(item => item.coupons.forEach(coupon => { order.creditDays = coupon.days; }));
    return order;
  });

  const ordersHeaders = [
    { key: 'id', isKey: true },
    { key: 'sku', name: 'SKU' },
    { key: 'maker', name: 'Fabricante' },
    { key: 'name', name: 'Nombre' },
    { key: 'quantity', name: 'Cantidad' },
    { key: 'stock', name: 'Stock', format: cell => (setPopup(cell, t)) },
    { key: 'price', name: 'Precio', format: cell => (currencyFormat(cell, currency)) },
    { key: 'finalPrice', name: 'Precio final unidad', format: cell => (currencyFormat(cell, currency)) },
    { key: 'coupons', name: 'Cupones', format: cell => (cell.length === 0 ? '' : handleRenderCouponDetails(cell, currency, t)) },
  ];

  return (
    <Grid>
      {orders.map(order => (
        <Grid.Row columns={2}>
          <Grid>
            <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8}>
              <Header as="h4" color="blue">
                {t('checkout.addressee')}
                <Header as="h5" color="blue" style={{ display: 'inline' }}>{order.branchOffice}</Header>
              </Header>
            </Grid.Column>
            <Grid.Column largeScreen={4} computer={4} tablet={4} mobile={4} textAlign="right">
              <Header as="h5" color="black">
                IVA:&nbsp;
                <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(order.iva, currency)}</Header>
              </Header>
            </Grid.Column>
            <Grid.Column largeScreen={3} computer={3} tablet={3} mobile={4} textAlign="right">
              <Header as="h5" color="black">
                Subtotal:&nbsp;
                <Header as="h5" color="green" style={{ display: 'inline' }}>{currencyFormat(order.subtotal, currency)}</Header>
              </Header>
            </Grid.Column>
            {order.creditDays ? <Grid.Column largeScreen={9} computer={9} tablet={9} mobile={9} /> : null}
            {
              order.creditDays
                ? (
                  <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={4} textAlign="right">
                    <Header as="h5" color="black">
                      Dias totales de crédito:&nbsp;
                      <Header as="h5" color="green" style={{ display: 'inline' }}>{order.creditDays}</Header>
                    </Header>
                  </Grid.Column>
                )
                : null
            }
            <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
              <Container>
                <SiclikTable
                  selectable
                  data={order.items}
                  headers={ordersHeaders}
                  pageSize={5}
                />
                <Divider section />
              </Container>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      ))}
    </Grid>
  );
};

Orders.defaultProps = {
  currency: 1,
  ordersData: [],
};

Orders.propTypes = {
  currency: PropTypes.number,
  ordersData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default translate('common', { wait: true })(Orders);
