import PropTypes from 'prop-types';
import React from 'react';
import { translate } from 'react-i18next';
import { Grid, Header, Divider } from 'semantic-ui-react';
import { currencyFormat } from '../../common/utils';

function Summary(props) {
  const {
    t, subtotal, shippingCost, iva, currency, total, discount,
  } = props;
  /* let hasDelivery = false;
 let shippingPromotion = false;
  const date = new Date();
  const fullDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  if ((fullDate === '12/11/2020' || fullDate === '19/11/2020') && subtotal < 20000) shippingPromotion = true;
  const onlyDeliveryItems = items.filter(item => item.hasDelivery).map(item => item.sku);
  if (onlyDeliveryItems.length > 0) hasDelivery = true; */
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={14}>
          <Header as="h4">{t('shoppingCart.summary')}</Header>
        </Grid.Column>
      </Grid.Row>
      { discount > 0 ? (
        <Grid.Row>
          <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
            <Header as="h5">{t('shoppingCart.totalProducts')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
            <Header as="h5">{currencyFormat(subtotal + discount, currency)}</Header>
          </Grid.Column>
        </Grid.Row>
      ) : null}
      { discount > 0 ? (
        <Grid.Row>
          <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
            <Header as="h5">{t('shoppingCart.discount')}</Header>
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
            <Header as="h5">
              -
              {currencyFormat(discount, currency)}
            </Header>
          </Grid.Column>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Divider style={{ margin: '0.5rem' }} />
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <Grid.Row className="no-padding-top">
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
          <Header as="h5">{t('shoppingCart.subtotal')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
          <Header as="h5">{currencyFormat(subtotal, currency)}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
          <Header as="h5">{t('shoppingCart.shipping')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
          <Header as="h5">{shippingCost <= 0.1 && shippingCost > 0 ? 'Gratis' : currencyFormat(shippingCost, currency)}</Header>
        </Grid.Column>
        { /* shippingPromotion && hasDelivery ?
          <Grid.Column largeScreen={14} computer={14} tablet={14} mobile={14} textAlign="left">
            <Label basic color="blue" pointing>
              ¡Tu envío será de {currencyFormat(50)}!
              Se verá reflejado al proceder al pago.
            </Label>
          </Grid.Column> : null */
        }
      </Grid.Row>
      <Grid.Row>
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
          <Header as="h5">{t('shoppingCart.iva')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
          <Header as="h5">{currencyFormat(iva, currency)}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
          <Divider style={{ margin: '0.5rem' }} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="no-padding-top">
        <Grid.Column largeScreen={6} computer={6} tablet={6} mobile={6} textAlign="left">
          <Header as="h4" color="green">{t('shoppingCart.total')}</Header>
        </Grid.Column>
        <Grid.Column largeScreen={8} computer={8} tablet={8} mobile={8} textAlign="right">
          <Header as="h5" color="green">{currencyFormat(total, currency)}</Header>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

Summary.propTypes = {
  currency: PropTypes.string,
  discount: PropTypes.number,
  // items: PropTypes.arrayOf(PropTypes.shape({})),
  iva: PropTypes.number,
  shippingCost: PropTypes.number,
  subtotal: PropTypes.number,
  t: PropTypes.func,
  total: PropTypes.number,
};

Summary.defaultProps = {
  currency: '',
  discount: 0,
  // items: [],
  iva: 0,
  shippingCost: 0,
  subtotal: 0,
  t: () => {},
  total: 0,
};

export default translate('common', { wait: true })(Summary);
