import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import service from '../../services';
import Toast from '../../../common/components/toast';

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
    };
  }

  handleClickSave = async () => {
    const { t, handleCloseModal, handleAddSpecificationsRefresh } = this.props;
    const { specificationsArray } = this.state;
    const attributesData = await Promise.all(specificationsArray.map(attribute => (
      {
        sku: attribute.SKU,
        type: attribute.Titulo,
        value: attribute.Valor,
      }
    )));
    if (attributesData.length > 0) {
      this.setState({ loader: true });
      service.addProductsSpecifications({ specifications: attributesData })
        .then(response => {
          const message = t('adminConfiguration.uploadSuccess');
          if (response) Toast(message, 'success');
          this.setState({ loader: false });
          handleCloseModal(false);
          handleAddSpecificationsRefresh();
        })
        .catch(error => {
          this.setState({ loader: false });
          let messageError = '';
          if (!error.code) messageError = t('error-codes.default');
          else messageError = t('adminConfiguration.errors.uploadError');
          Toast(messageError, 'error');
        });
    }
  }

  handleInputChange = event => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value });
    const sheets = [];
    if (name === 'file') {
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(target.files[0]);
      reader.onloadend = e => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        workbook.SheetNames.forEach(sheetName => {
          const xlRowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          sheets.push({ data: xlRowObject, sheetName });
        });
        this.setState({ specificationsArray: sheets[0].data });
      };
    }
  }

  render() {
    const { loader } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <input
            required
            type="file"
            name="file"
            id="file"
            onChange={this.handleInputChange}
            placeholder="Archivo de excel"
            accept=".xlsx"
          />
        </Grid.Row>
        <Grid.Row>
          <Button color="primary" loading={loader} onClick={this.handleClickSave}>Guardar</Button>
        </Grid.Row>
      </Grid>
    );
  }
}
UploadFile.defaultProps = {
  t: null,
  handleCloseModal: () => { },
  handleAddSpecificationsRefresh: () => { },
};

UploadFile.propTypes = {
  handleAddSpecificationsRefresh: PropTypes.func,
  handleCloseModal: PropTypes.func,
  t: PropTypes.func,
};

export default translate('common', { wait: true })(UploadFile);
