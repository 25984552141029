import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Modal, Grid,
} from 'semantic-ui-react';
import { callError } from '@compusoluciones-pdigitales/status-codes';
import { translate } from 'react-i18next';

import Toast from '../../../common/components/toast';
import { TextField } from '../../../common/components/materials';
import * as validate from './validation';
import request from '../../services';

const emptyErrors = {
  description: false,
};

function ModalAddAccess(props) {
  const {
    actions, t, customerId, env,
  } = props;
  const { open, handleModalClose, handleModalSave } = actions;

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState(emptyErrors);
  const [selectedAccess, setSelectedAccess] = useState({
    description: '',
  });

  function defineErrorMessages(error) {
    if (error.code === callError.general.RESOURCE_NOT_FOUND) {
      Toast(t('user.permissionsNotFound'), 'error');
    } else Toast(t(`error-codes.${error.code}`), 'error');
  }

  async function addAccess() {
    const newUser = await request.addAccess(
      {
        customerId,
        description: selectedAccess.description,
      },
      env,
    ).catch(defineErrorMessages);
    if (newUser && newUser.customerKey) {
      Toast(t('user.accessAccount.accessAdded'), 'success');
      handleModalClose();
    }
  }

  function accessAction() {
    return addAccess();
  }

  const handleOnBlurDescription = event => {
    const { value } = event.target;
    const errorMessage = validate.isDescription(value);
    setErrorMessages({ ...errorMessages, description: t(errorMessage) });
  };

  function validateForm() {
    const { descriptionError } = validate.formAccess(selectedAccess);
    setErrorMessages({
      description: t(descriptionError),
    });
    return !descriptionError;
  }

  const handleOnSubmit = async () => {
    if (validateForm()) {
      setIsLoading(true);
      await accessAction();
      setIsLoading(false);
      handleModalSave();
    }
  };

  const handleOnClose = () => {
    setErrorMessages(emptyErrors);
    handleModalClose();
  };

  return (
    <Modal open={open} closeOnEscape={open}>
      <Modal.Header>
        {' '}
        {t('user.accessAccount.informationAdd')}
        {' '}
      </Modal.Header>
      <Modal.Content>
        <Grid container>
          <Grid.Row centered>
            <Grid.Column onBlur={handleOnBlurDescription} largeScreen={7} computer={7} tablet={6} mobile={15}>
              <TextField
                fullWidth
                maxLength={50}
                value={selectedAccess.description || ''}
                error={errorMessages.description || ''}
                label={t('user.accessAccount.descriptionHeader')}
                onChange={({ target }) => setSelectedAccess({ ...selectedAccess, description: target.value })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleOnClose}>
          {' '}
          {t('cancel')}
          {' '}
        </Button>
        <Button onClick={handleOnSubmit} loading={isLoading} color="blue">
          {t('add')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ModalAddAccess.propTypes = {
  actions: PropTypes.shape({
    handleModalClose: PropTypes.func,
    handleModalSave: PropTypes.func,
    open: PropTypes.bool,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
  env: PropTypes.string,
};

ModalAddAccess.defaultProps = {
  env: '',
};

export default translate('common', { wait: true })(ModalAddAccess);
