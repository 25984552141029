import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Grid, Popup, Icon, Divider,
} from 'semantic-ui-react';
import {
  WhatsappShareButton,
  EmailShareButton,
  FacebookShareButton,
  WhatsappIcon,
  EmailIcon,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class ShareProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copied: false,
    };
  }

  handleCopy = () => {
    this.setState({ copied: true });
  }

  render() {
    const { url } = this.props;
    const { copied } = this.state;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column largeScreen={16} computer={16} tablet={16} mobile={16}>
            <Popup
              on="click"
              position="bottom"
              trigger={(
                <Icon link circular name="share alternate" size="big" />
                )}
            >
              <Popup.Header>Compartir</Popup.Header>
              <Divider />
              <Popup.Content>
                <Grid.Row>
                  <WhatsappShareButton url={url}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </Grid.Row>
                <Grid.Row>
                  <EmailShareButton url={url}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </Grid.Row>
                <Grid.Row>
                  <FacebookShareButton url={url}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                </Grid.Row>
                <Grid.Row>
                  <TelegramShareButton url={url}>
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </Grid.Row>
                <Grid.Row>
                  <CopyToClipboard text={url} onCopy={this.handleCopy}>
                    <Icon circular link name="copy outline" size="large" />
                  </CopyToClipboard>
                  { copied ? 'Copiado' : '' }
                </Grid.Row>
              </Popup.Content>
            </Popup>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

ShareProductDetails.defaultProps = {
  url: '',
};

ShareProductDetails.propTypes = {
  url: PropTypes.string,
};
export default translate('common', { wait: true })(ShareProductDetails);
