import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import Cxc from './components/cxc';
import PDFVisualizer from './components/PdfViz';

class crossViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  // eslint-disable-next-line class-methods-use-this
  onUpdate() {
    const iframe = document.getElementById('iframeTarget');
    const divContent = document.getElementById('contentFrame');

    if (window.frames.document.body) {
      iframe.height = ' 2300px';
      divContent.style.height = '2300px';
    }
  }

  render() {
    const { originId } = this.props;
    switch (originId) {
      case 1: return (<Cxc />);
      case 2: return (<PDFVisualizer type="terms" />);
      case 3: return (<PDFVisualizer type="fqa" />);
      case 4: return (<PDFVisualizer type="mpoCxC" />);
      case 5: return (<PDFVisualizer type="devoluciones" />);
      case 6: return (<PDFVisualizer type="formasdepago" />);
      case 7: return (<PDFVisualizer type="metodosenvio" />);
      default: return ('');
    }
  }
}

crossViewer.defaultProps = {
  originId: 1,
};

crossViewer.propTypes = {
  originId: PropTypes.number,
};
export default translate('common', { wait: true })(crossViewer);
