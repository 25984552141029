/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import ItemsWishList from '../../components/Items';

import { useItems } from './ItemsProvider';
import { useWishLists } from '../WishLists/ListsProvider';

export default function Items(props) {
  const { t, getShoppingCartCount } = props;
  const { selectedList } = useWishLists();
  const {
    getWishListItems, filteredItems, sendItemToCart, itemsLoader, removeItem, updateQuantity,
  } = useItems();

  const handleSendItemToCart = async item => {
    await sendItemToCart(item);
    getShoppingCartCount();
  };

  useEffect(() => {
    if (Object.keys(selectedList).length && selectedList.id) {
      getWishListItems(selectedList);
    }
  }, [selectedList.id]);

  return (
    <ItemsWishList
      itemsLoader={itemsLoader}
      removeItem={removeItem}
      t={t}
      updateQuantity={updateQuantity}
      wishListId={selectedList.id}
      items={filteredItems}
      sendItemToCart={handleSendItemToCart}
    />
  );
}
