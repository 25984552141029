import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css';

class BlankLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { children } = this.props;
    return (
      <div className="blank-container">
        {children}
      </div>
    );
  }
}

BlankLayout.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default BlankLayout;
